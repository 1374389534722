import { useState, useEffect, useRef } from "react";
import "./discoverCard.scss";
import {
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

interface cardI {
  title: string;
  image: string;
  submenu: any;
}

export const DiscoverCard = ({ title, image, submenu }: cardI) => {
  const [scrolled, setScrolled] = useState("top");
  const innerListRef = useRef<HTMLHeadingElement>(null);
  const history = useHistory();
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  useEffect(() => {
    innerListRef.current?.addEventListener("scroll", () => {
      let scrollPx = innerListRef.current?.scrollTop;
      if (scrollPx === 0) {
        setScrolled("top");
      } else if (scrollPx && scrollPx > 0 && scrollPx < 262) {
        setScrolled("mid");
      } else {
        setScrolled("bottom");
      }
    });

    return () => {
      innerListRef.current?.removeEventListener("scroll", () => {});
    };
  }, []);
  return (
    <div
      className="dicover_card"
      style={{
        /*         backgroundImage: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.25)), url(${image})`,
         */ backgroundImage: ` url(${image})`,

        backgroundPosition: "center",
      }}
    >
      <div className="discover_card_submenu">
        <div className="discover_card_list">
          <h2 className="h2_subtitle">{title}</h2>
          {innerListRef.current &&
            innerListRef.current.scrollHeight > 414 &&
            (scrolled === "mid" ? (
              <div className="arrows"></div>
            ) : scrolled === "top" ? (
              <div className="arrows">
                <FontAwesomeIcon icon={faCaretDown} size={"2x"} />
              </div>
            ) : (
              <div className="arrows">
                <FontAwesomeIcon icon={faCaretUp} size={"2x"} />
              </div>
            ))}
          <div ref={innerListRef}>
            {submenu?.map((item: any, i: number) => (
              <p
                key={i}
                className="paragraphe discover_item_list"
                onClick={() =>
                  history.push(
                    `/responsible/${item.id}/${
                      user && user.fabric_type === "knit" ? "knit" : "denim"
                    }`
                  )
                }
              >
                {item.name}
              </p>
            ))}
          </div>
        </div>
        {title !== "Sustainability" && (
          <div className="discover_card_footer">
            <p className="paragraphe ">Discover All</p>
            <FontAwesomeIcon icon={faArrowRight} color="white" />
          </div>
        )}
      </div>
      <h1 className="h2_huge discover_card_title">{title}</h1>
    </div>
  );
};
