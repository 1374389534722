import {
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  ADD_ORDER_REQUEST,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_OPTION_REQUEST,
  UPDATE_OPTION_SUCCESS,
  UPDATE_OPTION_FAIL,
  GET_OPTION_REQUEST,
  GET_OPTION_SUCCESS,
  GET_OPTION_FAIL,
  SELECT_OPTION,
  SELECT_ARTICLE,
  DELETE_OPTION_REQUEST,
  DELETE_OPTION_SUCCESS,
  DELETE_OPTION_FAIL,
  DUPLICATE_OPTION_REQUEST,
  DUPLICATE_OPTION_SUCCESS,
  DUPLICATE_OPTION_FAIL,
  DELETE_ARTICLE_REQUEST,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAIL,
  UPDATE_ARTICLE_REQUEST,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAIL,
  CREATE_ARTICLE_REQUEST,
  CREATE_ARTICLE_SUCCESS,
  DELETE_OPTION_FILE_REQUEST,
  DELETE_OPTION_FILE_SUCCESS,
  DELETE_OPTION_FILE_FAIL,
  GET_COLLABORATOR_REQUEST,
  GET_COLLABORATOR_SUCCESS,
  GET_COLLABORATOR_FAIL,
  ADD_COLLABORATOR_REQUEST,
  ADD_COLLABORATOR_SUCCESS,
  ADD_COLLABORATOR_FAIL,
  GET_ORDER_COLLABORATOR_REQUEST,
  GET_ORDER_COLLABORATOR_SUCCESS,
  GET_ORDER_COLLABORATOR_FAIL,
  DELETE_COLLABORATOR_REQUEST,
  DELETE_COLLABORATOR_SUCCESS,
  DELETE_COLLABORATOR_FAIL,
  GET_ONE_ORDER_REQUEST,
  GET_ONE_ORDER_SUCCESS,
  GET_ONE_ORDER_FAIL,
  GET_STATUS_REQUEST,
  GET_STATUS_SUCCESS,
  GET_STATUS_FAIL,
  ADD_FEEDBACK_REQUEST,
  ADD_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_FAIL,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAIL,
  GET_ONE_ARTICLE_REQUEST,
  GET_ONE_ARTICLE_SUCCESS,
  GET_ONE_ARTICLE_FAIL,
  SEND_REQ_ACCESS_REQUEST,
  SEND_REQ_ACCESS_SUCCESS,
  SEND_REQ_ACCESS_FAIL,
  ACCEPT_REQ_ACCESS_REQUEST,
  ACCEPT_REQ_ACCESS_SUCCESS,
  ACCEPT_REQ_ACCESS_FAIL,
  ADD_DEMCO_PRODUCT_ORDER_REQUEST,
  ADD_DEMCO_PRODUCT_ORDER_SUCCESS,
  ADD_DEMCO_PRODUCT_ORDER_FAIL,
  GET_NORMAL_ORDER_REQUEST,
  GET_NORMAL_ORDER_SUCCESS,
  GET_NORMAL_ORDER_FAIL,
  ADD_NORMAL_ORDER_COMMENT_REQUEST,
  ADD_NORMAL_ORDER_COMMENT_SUCCESS,
  ADD_NORMAL_ORDER_COMMENT_FAIL,
  CREATE_CUSTOMIZED_PRODUCT_REQUEST,
  CREATE_CUSTOMIZED_PRODUCT_SUCCESS,
  CREATE_CUSTOMIZED_PRODUCT_FAIL,
  SEND_CUSTOMIZED_PRODUCT_REQUEST,
  SEND_CUSTOMIZED_PRODUCT_SUCCESS,
  SEND_CUSTOMIZED_PRODUCT_FAIL,
  SAVE_CUSTOMIZED_PRODUCT_REQUEST,
  SAVE_CUSTOMIZED_PRODUCT_SUCCESS,
  SAVE_CUSTOMIZED_PRODUCT_FAIL,
  UPDATE_QUANTITY_NORMAL_PRODUCT_REQUEST,
  UPDATE_QUANTITY_NORMAL_PRODUCT_SUCCESS,
  UPDATE_QUANTITY_NORMAL_PRODUCT_FAIL,
  CHANGE_ORDER_TYPE_TAB,
  GET_COMPANY_ORDER_REQUEST,
  GET_COMPANY_ORDER_SUCCESS,
  GET_COMPANY_ORDER_FAIL,
  DIRECRT_PRODUCT_SUCCESS,

  GET_HERO_REQUEST,
  GET_HERO_SUCCESS,
  GET_HERO_FAIL,
} from "./types";
import OrderServices from "./services";
import store from "../../store/store";
import { history } from "../../index";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import { handleBottomDrawer } from "../actionCreators";
import axiosInstance from "../../config/axios-instance";

/* export const selectOrder = (selectedOrder: any) => ({
  type: "SELECT_ORDER",
  payload: selectedOrder,
}); */

export const selectOrder = (selectedOrder: string) => async (dispatch: any) => {
  await dispatch({
    type: "SELECT_ORDER",
    payload: selectedOrder,
  });
};
// Get User Order
export const getOrders = (status: string) => async (dispatch: any) => {
  await dispatch({
    type: GET_ORDER_REQUEST,
  });

  try {
    const response = await OrderServices.getAllOrders(status);
    await dispatch({
      type: GET_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_ORDER_FAIL,
      payload: error.response,
    });
  }
};

// Get One Order
export const getOneOrder = (id: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_ONE_ORDER_REQUEST,
  });

  try {
    const response = await OrderServices.getOneOrder(id);
    await dispatch({
      type: GET_ONE_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_ONE_ORDER_FAIL,
      payload: error.response,
    });
  }
};

// Add New Order
export const addOrder =
  (data: any, fromDrawer?: any) => async (dispatch: any) => {
    await dispatch({
      type: ADD_ORDER_REQUEST,
    });
    try {
      const response = await OrderServices.addOrder(data);
      console.log(data);
      await dispatch({
        type: ADD_ORDER_SUCCESS,
        payload: response.data,
      });
      await dispatch(handleBottomDrawer());

      MyNotif("Order created successfully.", "success");
      window.location.reload();
      // if (data.collaborator.length) {
      //   await dispatch(
      //     addCollaborator(response.data.id, data.collaborator, fromDrawer)
      //   );
      // }
    } catch (error: any) {
      dispatch({
        type: ADD_ORDER_FAIL,
        payload: error.response,
      });

      if (error.response.data.errors?.name) {
        MyNotif(error.response.data.errors?.name[0], "danger");
      }
      if (error.response.data.errors?.delivery_date) {
        MyNotif(error.response.data.errors?.delivery_date[0], "danger");
      }
    }
  };

// Clear New Order state
export const clearNewOrder = () => (dispatch: any) => {
  return dispatch({
    type: "CLEAR_NEW_ORDER",
  });
};

// Delete Order
export const deleteOrder = (id: number) => async (dispatch: any) => {
  await dispatch({
    type: DELETE_ORDER_REQUEST,
  });
  try {
    const response = await OrderServices.deleteOrder(id);
    await dispatch({
      type: DELETE_ORDER_SUCCESS,
      payload: id,
    });
    MyNotif("Order deleted successfully", "success");
  } catch (error: any) {
    dispatch({
      type: DELETE_ORDER_FAIL,
      payload: error.response,
    });
  }
};

// Update Order
export const updateOrder =
  (data: any, id: number, history?: any, fn?: any) => async (dispatch: any) => {
    await dispatch({
      type: UPDATE_ORDER_REQUEST,
    });
    try {
      console.log(data);

      const response = await OrderServices.updateOrder(data, id);

      await dispatch({
        type: UPDATE_ORDER_SUCCESS,
        payload: response.data,
      });

      // dispatch(handleBottomDrawer());
      if (fn) {
        fn();
      }
      if (history) {
        setTimeout(() => {
          //history.back();
          history.push("/my-orders/direct-orders/in-progress");
        }, 2000);
      }
      MyNotif("Order updated successfully", "success");
    } catch (error: any) {
      dispatch({
        type: UPDATE_ORDER_FAIL,
        payload: error.response,
      });
    }
  };

// Update Option
export const updateOption =
  (data: any, id: number) => async (dispatch: any) => {
    await dispatch({
      type: UPDATE_OPTION_REQUEST,
    });
    try {
      const response = await OrderServices.updateOption(data, id);
      await dispatch({
        type: UPDATE_OPTION_SUCCESS,
        payload: response.data,
      });
      MyNotif("Option updated successfully", "success");
    } catch (error: any) {
      dispatch({
        type: UPDATE_OPTION_FAIL,
        payload: error.response,
      });
    }
  };
// Update Direct product
  export const   updateDirectProduct=
  (data: any, id: number) => async (dispatch: any) => {
    await dispatch({
      type: UPDATE_OPTION_REQUEST,
    });
    try {
      const response = await OrderServices.updateDirectProduct(data, id);
      await dispatch({
        type: UPDATE_OPTION_SUCCESS,
        payload: response.data,
      });
      MyNotif("Option updated successfully", "success");
    } catch (error: any) {
      dispatch({
        type: UPDATE_OPTION_FAIL,
        payload: error.response,
      });
    }
  };

//  Get One Option
export const getOneOption = (id: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_OPTION_REQUEST,
  });
  try {
    const response = await OrderServices.getOption(id);
    await dispatch({
      type: GET_OPTION_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_OPTION_FAIL,
      payload: error.response,
    });
  }
};

// Select Option
export const selectOption =
  (data: any, articleId: number) => (dispatch: any) => {
    return dispatch({
      type: SELECT_OPTION,
      payload: { data, articleId },
    });
  };

// Select Article
export const selectArticle = (id: number) => (dispatch: any) => {
  return dispatch({
    type: SELECT_ARTICLE,
    payload: id,
  });
};

// Delete Option
export const deleteOneOption = (option: any) => async (dispatch: any) => {
  await dispatch({
    type: DELETE_OPTION_REQUEST,
    payload: option,
  });
  try {
    const response = await OrderServices.deleteOption(option.id);
    await dispatch({
      type: DELETE_OPTION_SUCCESS,
      payload: option,
    });
    MyNotif("Option deleted successfully", "success");
  } catch (error: any) {
    dispatch({
      type: DELETE_OPTION_FAIL,
      payload: error.response,
    });
  }
};

// Delete Option FILE
export const deleteOptionFile =
  (option: any, arrFiles: any) => async (dispatch: any) => {
    await dispatch({
      type: DELETE_OPTION_FILE_REQUEST,
      payload: option,
    });
    try {
      const response = await OrderServices.deleteOptionFile(
        option.id,
        arrFiles
      );
      await dispatch({
        type: DELETE_OPTION_FILE_SUCCESS,
        payload: {
          option,
          arrFiles,
        },
      });
    } catch (error: any) {
      dispatch({
        type: DELETE_OPTION_FILE_FAIL,
        payload: error.response,
      });
    }
  };

// Duplicate Option
export const createNewOption: any = (option: any) => async (dispatch: any) => {
  await dispatch({
    type: DUPLICATE_OPTION_REQUEST,
  });
  try {
    const response = await OrderServices.createNewOption(option);
    await dispatch({
      type: DUPLICATE_OPTION_SUCCESS,
      payload: response.data,
    });
    MyNotif("New option created successfully", "success");
  } catch (error: any) {
    dispatch({
      type: DUPLICATE_OPTION_FAIL,
      payload: error.response,
    });
  }
};

export const createNewDirectProduct: any =
  (option: any) => async (dispatch: any) => {
    try {
      const response = await OrderServices.createNewDirectProduct(option);
      await dispatch({
        type: DIRECRT_PRODUCT_SUCCESS,
        payload: response.data,
      });
      MyNotif("New Direct product successfully", "success");
    } catch (error: any) {}
  };

  export const createNewClientProduct: any =
  (option: any) => async (dispatch: any) => {
    try {
      const response = await OrderServices.createNewClientProduct(option);
      await dispatch({
        type: DIRECRT_PRODUCT_SUCCESS,
        payload: response.data,
      });
      MyNotif("New Direct product successfully", "success");
    } catch (error: any) {}
  };


  
// DELETE ARTICLE
export const deleteOneArticle = (article: any) => async (dispatch: any) => {
  await dispatch({
    type: DELETE_ARTICLE_REQUEST,
    payload: article,
  });
  try {
    const response = await OrderServices.deleteArticle(article.id);
    await dispatch({
      type: DELETE_ARTICLE_SUCCESS,
      payload: article,
    });
    MyNotif("Article deleted successfully", "success");
  } catch (error: any) {
    dispatch({
      type: DELETE_ARTICLE_FAIL,
      payload: error.response,
    });
  }
};

// Update Article
export const updateArticle =
  (data: any, id: number) => async (dispatch: any) => {
    await dispatch({
      type: UPDATE_ARTICLE_REQUEST,
    });
    try {
      const response = await OrderServices.updateArticle(data, id);
      await dispatch({
        type: UPDATE_ARTICLE_SUCCESS,
        payload: response.data,
      });
      MyNotif("Article updated successfully", "success");
    } catch (error: any) {
      dispatch({
        type: UPDATE_ARTICLE_FAIL,
        payload: error.response,
      });
    }
  };

// Create Article
export const createArticle =
  (data: any, fromNewOrder?: any) => async (dispatch: any) => {
    await dispatch({
      type: CREATE_ARTICLE_REQUEST,
    });
    try {
      const response = await OrderServices.createArticle(data);
      await dispatch({
        type: CREATE_ARTICLE_SUCCESS,
        payload: {
          articleToDuplicate: data,
          data: response.data,
          fromNewOrder,
        },
      });
      MyNotif("New Article Created", "success");
      if (!fromNewOrder) {
        // OPTIONS
        let order = await store
          .getState()
          .orders.orders.find(
            (order: any) => order.id === response.data.order_id
          );
        let oneArticle = await order.articles.find(
          (el: any) => el.id === data.id
        );
        let options = await oneArticle.options;
        let updatedOptions = await options.map((option: any) => {
          delete option.id;
          return {
            ...option,
            order_article_id: response.data.id,
          };
        });
        await updatedOptions.map((el: any) => dispatch(createNewOption(el)));
        dispatch(getOrders("waiting"));
      }
    } catch (error: any) {
      MyNotif("Article name is required.", "danger");
    }
  };

// Get Collaborators
export const getCollaborators = (name: string) => async (dispatch: any) => {
  await dispatch({
    type: GET_COLLABORATOR_REQUEST,
  });
  try {
    const response = await OrderServices.getCollaborators(name);
    await dispatch({
      type: GET_COLLABORATOR_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    await dispatch({
      type: GET_COLLABORATOR_FAIL,
      payload: error.response,
    });
  }
};

// Add Collaborator
export const addCollaborator =
  (orderId: number, collaborators: any, fromDrawer?: any) =>
  async (dispatch: any) => {
    await dispatch({
      type: ADD_COLLABORATOR_REQUEST,
    });
    try {
      const response = await OrderServices.addCollaborator(
        orderId,
        collaborators
      );
      await dispatch({
        type: ADD_COLLABORATOR_SUCCESS,
        payload: response.data,
        order_id: orderId,
      });

      await dispatch(getOrderCollaborators(orderId));
      MyNotif("Collaborator added to this order successfully", "success");
      // if (fromDrawer) {
      //   history.push(`/edit-order/${orderId}`);
      // } else {
      // history.push(`/new-order`);
      // }
    } catch (error: any) {
      await dispatch({
        type: ADD_COLLABORATOR_FAIL,
        payload: error.response,
      });
    }
  };

// Get Order's Collaborator
export const getOrderCollaborators =
  (orderId: number) => async (dispatch: any) => {
    await dispatch({
      type: GET_ORDER_COLLABORATOR_REQUEST,
    });
    try {
      // dispatch(handleBottomDrawer());
      const response = await OrderServices.getOrderCollaborators(orderId);
      await dispatch({
        type: GET_ORDER_COLLABORATOR_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      await dispatch({
        type: GET_ORDER_COLLABORATOR_FAIL,
        payload: error.response,
      });
    }
  };

export const deleteCollaborator =
  (orderId: number, ids: any) => async (dispatch: any) => {
    await dispatch({
      type: DELETE_COLLABORATOR_REQUEST,
    });
    try {
      let arrIds = ids.map((el: any) => el.id);
      const response = await OrderServices.deleteCollaborator(orderId, arrIds);
      await dispatch({
        type: DELETE_COLLABORATOR_SUCCESS,
        payload: ids,
        orderId,
      });
      MyNotif("Collaborator deleted successfully", "success");
    } catch (error: any) {
      await dispatch({
        type: DELETE_COLLABORATOR_FAIL,
        payload: error.response,
      });
    }
  };

// Get All Status
export const getStatus = () => async (dispatch: any) => {
  await dispatch({
    type: GET_STATUS_REQUEST,
  });
  try {
    const response = await OrderServices.getStatus();
    await dispatch({
      type: GET_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    await dispatch({
      type: GET_STATUS_FAIL,
      payload: error.response,
    });
  }
};

// Add Feedback to Cancelled Article
export const addFeedback = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: ADD_FEEDBACK_REQUEST,
  });
  try {
    const response = await OrderServices.addFeedback(data);
    await dispatch({
      type: ADD_FEEDBACK_SUCCESS,
      payload: response.data,
    });
    MyNotif("Order canceled and feedback were sent successfully.", "success");
  } catch (error: any) {
    await dispatch({
      type: ADD_FEEDBACK_FAIL,
      payload: error.response,
    });
  }
};

// Add Comment to Article for each status
export const addComment = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: ADD_COMMENT_REQUEST,
  });
  try {
    const response = await OrderServices.addComment(data);
    await dispatch({
      type: ADD_COMMENT_SUCCESS,
      payload: response.data,
    });
    await dispatch(getOneArticle(data.article_id));
    MyNotif("Comment added successfully.", "success");
  } catch (error: any) {
    await dispatch({
      type: ADD_COMMENT_FAIL,
      payload: error.response,
    });
  }
};

// Get One Article
export const getOneArticle = (articleId: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_ONE_ARTICLE_REQUEST,
  });
  try {
    const response = await OrderServices.getOneArticle(articleId);
    await dispatch({
      type: GET_ONE_ARTICLE_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    await dispatch({
      type: GET_ONE_ARTICLE_FAIL,
      payload: error.response,
    });
  }
};

// Send Access Request
export const sendAccessRequest =
  (direct_order_id: number, data: any) => async (dispatch: any) => {
    await dispatch({
      type: SEND_REQ_ACCESS_REQUEST,
    });
    try {
      const response = await OrderServices.sendAccessRequest(
        direct_order_id,
        data
      );
      await dispatch({
        type: SEND_REQ_ACCESS_SUCCESS,
        payload: response.data,
      });
      MyNotif("Request sent successfully.", "success");
    } catch (error: any) {
      await dispatch({
        type: SEND_REQ_ACCESS_FAIL,
        payload: error.response,
      });
    }
  };

// Send Access Request
export const acceptAccessRequest =
  (direct_order_id: number, client_id: number) => async (dispatch: any) => {
    await dispatch({
      type: ACCEPT_REQ_ACCESS_REQUEST,
      payload: { client_id, direct_order_id },
    });
    try {
      const response = await OrderServices.acceptAccessRequest(
        direct_order_id,
        client_id
      );
      await dispatch({
        type: ACCEPT_REQ_ACCESS_SUCCESS,
        payload: { client_id, direct_order_id },
      });
    } catch (error: any) {
      await dispatch({
        type: ACCEPT_REQ_ACCESS_FAIL,
        payload: error.response,
      });
    }
  };

// Decline Access Request
export const declineAccessRequest =
  (direct_order_id: number, client_id: number) => async (dispatch: any) => {
    await dispatch({
      type: ACCEPT_REQ_ACCESS_REQUEST,
    });
    try {
      const response = await OrderServices.declineAccessRequest(
        direct_order_id,
        client_id
      );
      await dispatch({
        type: ACCEPT_REQ_ACCESS_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      await dispatch({
        type: ACCEPT_REQ_ACCESS_FAIL,
        payload: error.response,
      });
    }
  };



  export const sendFolderAccessRequest =
  (folder_id: number, data: any) => async (dispatch: any) => {
    await dispatch({
      type: SEND_REQ_ACCESS_REQUEST,
    });
    try {
      const response = await OrderServices.sendFolderAccessRequest(
        folder_id,
        data
      );
      await dispatch({
        type: SEND_REQ_ACCESS_SUCCESS,
        payload: response.data,
      });
      MyNotif("Request sent successfully.", "success");
    } catch (error: any) {
      await dispatch({
        type: SEND_REQ_ACCESS_FAIL,
        payload: error.response,
      });
    }
  };

// Send Access Request
export const acceptFolderAccessRequest =
  (folder_id: number, client_id: number) => async (dispatch: any) => {
    await dispatch({
      type: ACCEPT_REQ_ACCESS_REQUEST,
      payload: { client_id, folder_id },
    });
    try {
      const response = await OrderServices.acceptFolderAccessRequest(
        folder_id,
        client_id
      );
      await dispatch({
        type: ACCEPT_REQ_ACCESS_SUCCESS,
        payload: { client_id, folder_id },
      });
    } catch (error: any) {
      await dispatch({
        type: ACCEPT_REQ_ACCESS_FAIL,
        payload: error.response,
      });
    }
  };

// Decline Access Request
export const declineFolderAccessRequest =
  (folder_id: number, client_id: number) => async (dispatch: any) => {
    await dispatch({
      type: ACCEPT_REQ_ACCESS_REQUEST,
    });
    try {
      const response = await OrderServices.declineFolderAccessRequest(
        folder_id,
        client_id
      );
      await dispatch({
        type: ACCEPT_REQ_ACCESS_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      await dispatch({
        type: ACCEPT_REQ_ACCESS_FAIL,
        payload: error.response,
      });
    }
  };


// Add Demco Product to Order
export const addProdDemcoToOrder =
  (
    arrayProduct: any,
    showToast: boolean = true,
    collection_name?: string,

    date_delivery?: string,
    collaborator?: any,
    saison?: string,
    year?: string,
    gender?: string,
    theme?: string,
    orderDetails?: string
  ) =>
  async (dispatch: any) => {
    await dispatch({
      type: ADD_DEMCO_PRODUCT_ORDER_REQUEST,
    });
    try {
      const response = await OrderServices.createNormalOrder(
        arrayProduct,
        collection_name,
        date_delivery,
        collaborator,
        saison,
        year,
        gender,
        theme,
        orderDetails
      );
      await dispatch({
        type: ADD_DEMCO_PRODUCT_ORDER_SUCCESS,
        payload: response.data,
      });
      if (showToast) {
        MyNotif("Your order has been successfully sent.", "success");
      }
      return response.data.id;
    } catch (error: any) {
      dispatch({
        type: ADD_DEMCO_PRODUCT_ORDER_FAIL,
        payload: error.response,
      });
    }
  };

// Get Normal Order
export const getNormalOrder = () => async (dispatch: any) => {
  await dispatch({
    type: GET_NORMAL_ORDER_REQUEST,
  });
  try {
    const response = await OrderServices.getNormalOrder();
    await dispatch({
      type: GET_NORMAL_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_NORMAL_ORDER_FAIL,
      payload: error.response,
    });
  }
};


export const getHeroHome = () => async (dispatch: any) => {
  await dispatch({
    type: GET_HERO_REQUEST,
  });
  try {
    const response = await OrderServices.getHeroHome();
    await dispatch({
      type: GET_HERO_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_HERO_FAIL,
      payload: error.response,
    });
  }
};

// Get One Normal Order
export const getOneNormalOrder = (id: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_ONE_ARTICLE_REQUEST,
  });
  try {
    const response = await OrderServices.getOneNormalOrder(id);
    await dispatch({
      type: GET_ONE_ARTICLE_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_ONE_ARTICLE_FAIL,
      payload: error.response,
    });
  }
};

export const addNormalOrderComment = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: ADD_NORMAL_ORDER_COMMENT_REQUEST,
  });
  try {
    const response = await OrderServices.addNormalOrderComment(data);
    await dispatch({
      type: ADD_NORMAL_ORDER_COMMENT_SUCCESS,
      payload: response.data,
    });
    MyNotif("Comment added successfully.", "success");
  } catch (error: any) {
    await dispatch({
      type: ADD_NORMAL_ORDER_COMMENT_FAIL,
      payload: error.response,
    });
  }
};

// Add Feedback to Cancelled Article
export const addNormalOrderFeedback = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: ADD_FEEDBACK_REQUEST,
  });
  try {
    const response = await OrderServices.addNormalOrderFeedback(data);
    await dispatch({
      type: ADD_FEEDBACK_SUCCESS,
      payload: response.data,
    });
    MyNotif("Order canceled and feedback were sent successfully.", "success");
  } catch (error: any) {
    await dispatch({
      type: ADD_FEEDBACK_FAIL,
      payload: error.response,
    });
  }
};

// Create Customized Product & order it or save it to collection folder
export const createCustomizedProduct =
  (data: any, isUpdate: boolean) => async (dispatch: any) => {
    await dispatch({
      type: CREATE_CUSTOMIZED_PRODUCT_REQUEST,
    });
    try {
      const response = await OrderServices.createCustomizedProduct(
        data,
        isUpdate
      );
      await dispatch({
        type: CREATE_CUSTOMIZED_PRODUCT_SUCCESS,
        payload: response.data,
      });
      // if (to_order) {
      //   await dispatch(orderCustomizedProduct(response.data.id));
      // } else if (folder_id && !to_order) {
      //   await dispatch(saveCustomizedProduct(folder_id, response.data.id));
      // }
    } catch (error: any) {
      await dispatch({
        type: CREATE_CUSTOMIZED_PRODUCT_FAIL,
        payload: error.response,
      });
      MyNotif(
        Object.keys(error.response.data.errors)
          .map((el: any) => el)
          .join(", ") + " are required fields.",
        "danger"
      );
    }
  };

// Order Customized Product
export const orderCustomizedProduct = (id: any) => async (dispatch: any) => {
  await dispatch({
    type: SEND_CUSTOMIZED_PRODUCT_REQUEST,
  });
  try {
    const response = await OrderServices.orderCustomizedProduct(id);
    await dispatch({
      type: SEND_CUSTOMIZED_PRODUCT_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    await dispatch({
      type: SEND_CUSTOMIZED_PRODUCT_FAIL,
      payload: error.response,
    });
    MyNotif("Required fields are missing.", "danger");
  }
};

// Sace Customized Product to a folder
export const saveCustomizedProduct =
  (folder_id: any, product_id: any) => async (dispatch: any) => {
    await dispatch({
      type: SAVE_CUSTOMIZED_PRODUCT_REQUEST,
    });
    try {
      const response = await OrderServices.saveCustomizedProduct(
        folder_id,
        product_id
      );
      await dispatch({
        type: SAVE_CUSTOMIZED_PRODUCT_SUCCESS,
        payload: response.data,
      });
      MyNotif("Product saved successfully", "success");
      dispatch(handleBottomDrawer());
    } catch (error: any) {
      await dispatch({
        type: SAVE_CUSTOMIZED_PRODUCT_FAIL,
        payload: error.response,
      });
      MyNotif("Required fields are missing.", "danger");
    }
  };

export const clearSelectedOption = () => (dispatch: any) => {
  return dispatch({
    type: "CLEAR_SELECTED_OPTION",
  });
};

export const updateNormalOrderProduct =
  (data: any, order_id: number) => async (dispatch: any) => {
    await dispatch({
      type: UPDATE_QUANTITY_NORMAL_PRODUCT_REQUEST,
    });
    try {
      const response = await OrderServices.updateNormalOrderProduct(
        data,
        order_id
      );
      await dispatch({
        type: UPDATE_QUANTITY_NORMAL_PRODUCT_SUCCESS,
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_QUANTITY_NORMAL_PRODUCT_FAIL,
      });
    }
  };

export const changeOrderType = (type: any) => (dispatch: any) => {
  return dispatch({
    type: CHANGE_ORDER_TYPE_TAB,
    payload: type,
  });
};

// Get Order As Commercial
export const getOrdersCommerical =
  (company_id: string, page: number) => async (dispatch: any) => {
    await dispatch({
      type: GET_COMPANY_ORDER_REQUEST,
    });
    try {
      const response = await OrderServices.getOrdersCommerical(
        company_id,
        page
      );
      await dispatch({
        type: GET_COMPANY_ORDER_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_COMPANY_ORDER_FAIL,
        payload: error.response,
      });
    }
  };

// Get One Order as Commercial
export const getOneDOrderCommerical =
  (do_id: string) => async (dispatch: any) => {
    await dispatch({
      type: GET_ORDER_REQUEST,
    });

    try {
      const response = await OrderServices.getOneDOrderCommerical(do_id);
      await dispatch({
        type: GET_ORDER_SUCCESS,
        payload: [response.data],
      });
    } catch (error: any) {
      dispatch({
        type: GET_ORDER_FAIL,
        payload: error.response,
      });
    }
  };

export const approveOptionAndComment = (data: any) => {
  let token = localStorage.getItem("token");
  if (data.status_id === 6) {
    return axiosInstance({
      method: "put",
      url: `/article_options/${data.article_option_id}`,
      data: {
        status_id: 6,
        feedback: data?.feedback || "",
        comment: data?.comment,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return axiosInstance({
    method: "post",
    url: `/order_articles/comments`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const rejectOrAcceptProto = ({
  id,
  response,
  comment,
}: {
  id: number;
  response: "reject" | "accept";
  comment: string;
}) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/article_options/${id}/proto/${response}`,
    data: {
      comment,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(() => response);
};

export const approveProductAndComment = (data: any) => {
  let token = localStorage.getItem("token");
  if (data.status_id === 6) {
    return axiosInstance({
      method: "put",
      url: `/orders/${data.order_id}`,
      data: {
        status_id: 6,
        product_id: data.product_id,
        // feedback: data?.feedback || "",
        // comment: data?.comment,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return axiosInstance({
    method: "post",
    url: `/orders/comments`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const rejectOrAcceptProtoDemcoProduct = ({
  id,
  response,
  order_id,
  comment,
  status_id,
}: {
  id: number;
  response: "reject" | "accept";
  order_id: string;
  comment: string;
  status_id?: number;
}) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/orders/${order_id}/proto/${response}`,
    data: {
      product_id: id,
      comment,
      status_id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(() => response);
};

export const getCustomizedProduct = (id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/customized_products/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const rejectOrAcceptProtoCustomized = ({
  id,
  response,
  comment,
  status_id,
  customized_product_id,
}: {
  id: number;
  response: "reject" | "accept";
  comment: string;
  status_id: any;
  customized_product_id: any;
}) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/customized_products/${id}/proto/${response}`,
    data: {
      comment,
      status_id,
      customized_product_id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(() => response);
};

export const approveCustomizedProductAndComment = (data: any) => {
  let token = localStorage.getItem("token");
  if (data.status_id === 6) {
    return axiosInstance({
      method: "post",
      url: `/customized_products/${data.product_id}/update`,
      data: {
        status_id: 6,
        // product_id: data.product_id,
        feedback: data?.feedback || "",
        comment: data?.comment,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return axiosInstance({
    method: "post",
    url: `/customized_products/comments`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
