import { Button, DatePicker } from "antd";
import moment, { Moment } from "moment";
import { useHistory } from "react-router-dom";

export default function MyOrdersHeader({
  path,
  tabKey,
  setOrderStatus,
  orderStatus,
  selectedDate,
  setSelectedDate,
}: {
  path: string;
  tabKey: string;
  setOrderStatus: (value: string) => void;
  orderStatus: string;
  selectedDate: Moment | null;
  setSelectedDate: any;
}) {
  const history = useHistory();
  return (
      <>
          <div style={{display:"flex",justifyContent:"center"}}>
          <h1 className={"title_myorder"}>my orders</h1>
          </div>
          <div className="my_orders_header_type ">

              <div className="my_orders_header_type_btns">
                  <Button
                      type="ghost"
                      onClick={() => {
                          history.push(`${path}/${tabKey}/in-progress`);
                          setOrderStatus("in-progress");
                      }}
                      className={orderStatus === "in-progress" ? "--selected" : ""}
                  >
                      In progress
                  </Button>
                  <Button
                      type="ghost"
                      onClick={() => {
                          history.push(`${path}/${tabKey}/completed`);
                          setOrderStatus("completed");
                      }}
                      className={orderStatus === "completed" ? "--selected" : ""}
                  >
                      Completed
                  </Button>
                  <Button
                      type="ghost"
                      onClick={() => {
                          history.push(`${path}/${tabKey}/cancelled`);
                          setOrderStatus("cancelled");
                      }}
                      className={orderStatus === "cancelled" ? "--selected" : ""}
                  >
                      Cancelled
                  </Button>
              </div>
          </div>
          {/*     <div className="my_orders_header">
            <div className="my_orders_header_left" >

              <h1 className={"title_myorder"} >my orders</h1>

              <div className="my_orders_header_btns mt-2 mt-md-0">
                <Button
                  type="ghost"
                  onClick={() => {
                    history.push(`${path}/${tabKey}/in-progress`);
                    setOrderStatus("in-progress");
                  }}
                  className={orderStatus === "in-progress" ? "--selected" : ""}
                >
                  In progress
                </Button>
                <Button
                  type="ghost"
                  onClick={() => {
                    history.push(`${path}/${tabKey}/completed`);
                    setOrderStatus("completed");
                  }}
                  className={orderStatus === "completed" ? "--selected" : ""}
                >
                  Completed
                </Button>
                <Button
                  type="ghost"
                  onClick={() => {
                    history.push(`${path}/${tabKey}/cancelled`);
                    setOrderStatus("cancelled");
                  }}
                  className={orderStatus === "cancelled" ? "--selected" : ""}
                >
                  Cancelled
                </Button>
              </div>
            </div>
          </div>*/}
      </>
  );
}
