export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

export const GET_MORE_NOTIFICATIONS_REQUEST = "GET_MORE_NOTIFICATIONS_REQUEST";
export const GET_MORE_NOTIFICATIONS_SUCCESS = "GET_MORE_NOTIFICATIONS_SUCCESS";
export const GET_MORE_NOTIFICATIONS_FAIL = "GET_MORE_NOTIFICATIONS_FAIL";

export const GET_MY_NOTIF_SETTINGS_REQUEST = "GET_MY_NOTIF_SETTINGS_REQUEST";
export const GET_MY_NOTIF_SETTINGS_SUCCESS = "GET_MY_NOTIF_SETTINGS_SUCCESS";
export const GET_MY_NOTIF_SETTINGS_FAIL = "GET_MY_NOTIF_SETTINGS_FAIL";

export const CHANGE_MY_NOTIF_SETTINGS_REQUEST =
  "CHANGE_MY_NOTIF_SETTINGS_REQUEST";
export const CHANGE_MY_NOTIF_SETTINGS_SUCCESS =
  "CHANGE_MY_NOTIF_SETTINGS_SUCCESS";
export const CHANGE_MY_NOTIF_SETTINGS_FAIL = "CHANGE_MY_NOTIF_SETTINGS_FAIL";

export const CHANGE_ONE_NOTIF_SETTINGS_REQUEST =
  "CHANGE_ONE_NOTIF_SETTINGS_REQUEST";
export const CHANGE_ONE_NOTIF_SETTINGS_SUCCESS =
  "CHANGE_ONE_NOTIF_SETTINGS_SUCCESS";
export const CHANGE_ONE_NOTIF_SETTINGS_FAIL = "CHANGE_ONE_NOTIF_SETTINGS_FAIL";

export const PUSH_NEW_NOTIFICATION = "PUSH_NEW_NOTIFICATION";
export const NEW_NOTIFICATION_SEEN = "NEW_NOTIFICATION_SEEN";
