import { faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { AddOrderHeader } from "../components/AddOrder/AddOrderHeader";
import "../sass/AddNewOrder.scss";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { OrderArticlesList } from "../components/AddOrder/OrderArticlesList";
import BottomDrawer from "../components/BottomDrawer/BottomDrawer";
import {
  getOneDOrderCommerical,
  getOrderCollaborators,
  getOrders,
  updateOrder,
} from "../actions/order-actions/actions";
import { OptionDetails } from "../components/OptionDetails/OptionDetails";
import { AnimatePresence, motion } from "framer-motion";
import { handleBottomDrawer } from "../actions/actionCreators";
import order_sent from "../img/order_sent.jpg";
import { Button, Drawer } from "antd";
import { OrderArticleItem } from "../components/AddOrder/OrderArticleItem";
import { NewArticleAccordion } from "../components/AddOrder/NewArticleAccordion";
import { v4 as uuidv4 } from "uuid";
import Head from "../components/Head";

interface Props {}

export const EditOrder = (props: Props) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [listNewArticles, setListNewArticles] = useState<any>([]);
  const [error, setError] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [orderToEdit, setOrderToEdit] = useState<any>(null);
  const [editDetails, setEditDetails] = useState(false);
  const [optionSelected, setOptionSelected] = useState<any>(null);
  const [mode, setMode] = useState("dazadzzzzzzz");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const history = useHistory();
  const params: any = useParams();
  const dispatch = useDispatch();
  const {
    orders,
    selectedOption,
    collaborators,
    getOrderCollaboratorsLoading,
  } = useSelector((state: any) => state.orders);
  const drawers = useSelector((state: any) => state.drawers);
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  useEffect(() => {
    if (!isAdmin) {
      dispatch(getOrders("waiting"));
    } else {
      dispatch(getOneDOrderCommerical(params.id));
    }
  }, [dispatch, user, isAdmin]);

  useEffect(() => {
    if (orders.length) {
      if (!isAdmin) {
        const id = params.id;
        const oneOrder = orders.find((el: any) => el.id == id);
        setOrderToEdit(oneOrder);
        // dispatch(getOrderCollaborators(oneOrder.id));
      } else {
        setOrderToEdit(orders);
      }
    }
  }, [isAdmin, orders]);

  useEffect(() => {
    setOptionSelected(selectedOption);
  }, [selectedOption]);

  // Change New Article Name Recently Created (static)
  const handleChange = (value: string, id: string) => {
    setListNewArticles(
      listNewArticles.map((el: any) =>
        el.id === id ? { ...el, name: value } : el
      )
    );
  };

  // Cancel New Article Recently Created (static)
  const deleteNewArticle = (id: string) => {
    setListNewArticles(listNewArticles.filter((el: any) => el.id !== id));
  };

  // Add New Article Collapse (Static)
  const addNewArticleCollapse = () => {
    setListNewArticles([{ id: uuidv4(), name: "" }, ...listNewArticles]);
    setDisableBtn(true);
  };

  // useEffect(() => {
  //   // if (newOrder) {
  //   // setOrderCreated(newOrder);
  //   dispatch(getOrderCollaborators(params.id));
  //   // }
  // }, [params.id]);

  return !orderToEdit ? (
    <p>...loaaaaaaaading</p>
  ) : (
    <div className="add_order">
      <Head title="Edit Direct Order" />
      {width > 575 ? (
        <>
          <AddOrderHeader
            edit={true}
            width={width}
            orderToEdit={orderToEdit}
            collaborators={orderToEdit.collaborators}
            // getOrderCollaboratorsLoading={getOrderCollaboratorsLoading}
            setOrderToEdit={setOrderToEdit}
          />
          <div className="row">
            <div className="col-xs-12 col-sm-3 p-4 order_list_side add add_order_details_left_side">
              <div className="order_details_text">
                <div className="order_details_text_content mb-2 mt-0">
                  <h3 className="m-0 mr-2">Details</h3>
                  {editDetails ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      onClick={() => {
                        dispatch(
                          updateOrder(
                            {
                              details: isAdmin
                                ? orderToEdit[0].details
                                : orderToEdit.details,
                            },
                            isAdmin ? orderToEdit[0].id : orderToEdit.id
                          )
                        );
                        setEditDetails(false);
                      }}
                    />
                  ) : (
                    user.id === orderToEdit.client_id && (
                      <FontAwesomeIcon
                        icon={faPen}
                        onClick={() => setEditDetails(true)}
                      />
                    )
                  )}
                </div>
                {editDetails ? (
                  <textarea
                    value={orderToEdit.details}
                    className="my-2"
                    rows={4}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setOrderToEdit({
                        ...orderToEdit,
                        details: e.target.value,
                      })
                    }
                  />
                ) : (
                  <p className="my-2">{orderToEdit.details}</p>
                )}
              </div>
              <div className="articles_list_header my-3">
                <h3>Articles</h3>
                <button
                  className="add_new_article_direct_order"
                  disabled={disableBtn}
                  onClick={addNewArticleCollapse}
                >
                  Add New Article
                </button>
              </div>
              <div className="articles_list w-100">
                {listNewArticles.length
                  ? listNewArticles.map((el: any) => (
                      <NewArticleAccordion
                        order_id={isAdmin ? orderToEdit[0].id : orderToEdit.id}
                        key={el.id}
                        article={el}
                        handleChange={(x: string, y: string) =>
                          handleChange(x, y)
                        }
                        deleteNewArticle={(id: string) => deleteNewArticle(id)}
                        setDisableBtn={setDisableBtn}
                        setError={setError}
                        error={error}
                      />
                    ))
                  : null}
              </div>

              <OrderArticlesList
                orderToEdit={orderToEdit}
                setOptionSelected={setOptionSelected}
                optionSelected={optionSelected}
                edit={true}
              />
            </div>
            <div className="col-xs-12 col-sm-9 p-0 order_details_container add_order_details_right_side">
              {optionSelected && (
                <OptionDetails
                  setOptionSelected={setOptionSelected}
                  optionSelected={optionSelected}
                  width={width}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <AddOrderHeader
            edit={true}
            width={width}
            orderToEdit={orderToEdit}
            setOrderToEdit={setOrderToEdit}
          />
          <div className="row">
            {!optionSelected && optionSelected !== "new" ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="col-xs-12 col-sm-3 p-4 order_list_side add add_order_details_left_side"
              >
                <div className="order_details_text">
                  <div className="order_details_text_content mb-2 mt-0">
                    <h3 className="m-0 mr-2">Details</h3>
                    {editDetails ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        onClick={() => {
                          dispatch(
                            updateOrder(
                              { details: orderToEdit.details },
                              orderToEdit.id
                            )
                          );
                          setEditDetails(false);
                        }}
                      />
                    ) : (
                      user.id === orderToEdit.client_id && (
                        <FontAwesomeIcon
                          icon={faPen}
                          onClick={() => setEditDetails(true)}
                        />
                      )
                    )}
                  </div>
                  {editDetails ? (
                    <textarea
                      value={orderToEdit.details}
                      className="my-2"
                      rows={4}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setOrderToEdit({
                          ...orderToEdit,
                          details: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <p className="my-2">{orderToEdit.details}</p>
                  )}
                </div>
                <div className="articles_list_header my-3">
                  <h3>Articles</h3>
                  <button>Add New Article</button>
                </div>
                <OrderArticlesList
                  orderToEdit={orderToEdit}
                  setOptionSelected={setOptionSelected}
                  optionSelected={optionSelected}
                  edit={true}
                />
              </motion.div>
            ) : (
              <div className="col-12">
                <OptionDetails
                  optionSelected={optionSelected}
                  width={width}
                  setOptionSelected={setOptionSelected}
                />
              </div>
            )}
            {/* {optionSelected && (
                <div className="col-12">
                  <OptionDetails
                    optionSelected={optionSelected}
                    width={width}
                    setOptionSelected={setOptionSelected}
                  />
                </div>
              )} */}
          </div>
         {/* <button
            className="order_btn_mobile"
            onClick={() => setIsOpenDrawer(true)}
          >
            Order
          </button>*/}
        </>
      )}
      {/* {drawers.openBottom && (
        <BottomDrawer mode="add_new_article" setMode={setMode} />
      )} */}

      <Drawer
        className="order_sent_drawer order_sent_drawer_mobile"
        placement={"bottom"}
        closable={true}
        onClose={() => setIsOpenDrawer(false)}
        visible={isOpenDrawer}
        key={"bottom"}
      >
        <AnimatePresence exitBeforeEnter={false}>
          <div>
            {!isSent ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <h1 className="my-3">
                  Are you sure you want to send this order ?
                </h1>
                <div className="my-3">
                  <Button
                    size={"large"}
                    className="mr-2"
                    onClick={() => {
                      dispatch(
                        updateOrder({ status: "completed" }, orderToEdit.id)
                      );
                      setIsSent(true);
                      setTimeout(() => {
                        setIsSent(false);
                        setIsOpenDrawer(false);
                      }, 2000);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    size={"large"}
                    className="ml-2"
                    onClick={() => setIsOpenDrawer(false)}
                  >
                    No
                  </Button>
                </div>{" "}
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="my-2">
                  <h1>Your order has been sent</h1>
                  <p>
                    We will train you in the coming days on the advancement of
                    your product.{" "}
                  </p>
                </div>
                <img
                  src={order_sent}
                  alt="order sent"
                  className="img-fluid my-2"
                />
              </motion.div>
            )}
          </div>
        </AnimatePresence>
      </Drawer>
    </div>
  );
};
