import { Select, Input, Tag, Switch } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const { Option } = Select;

const TagsTable = ({
  handleChange,
  productToUpdate,
  setProductToUpdate,
  data,
  setData,
  options,
  selectFavBy,
  isMasked,
  mode,
}: any) => {
  const { user } = useSelector((state: any) => state.auth);
  const [foryou, setForyou] = useState([]);
  const getProducts = async () => {
    let token = localStorage.getItem("token");
    let similar = process.env.REACT_APP_API_URL + `/admin/companies`;

    const similarRequest = axios.get(similar, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    axios
      .all([similarRequest])
      .then(
        axios.spread((...responses) => {
          const similar_products = responses[0].data.data;
          setForyou(similar_products);
        })
      )
      .catch((errors) => {});
  };
  useEffect(() => {
    console.log(options);
    getProducts();
  }, []);

  return (
    <>
      <tr>
        <th>Collections</th>
        <td>
          <Select
            disabled={!user.permissions.includes("product.update")}
            mode="multiple"
            showArrow
            placeholder="Select Collection"
            tagRender={({ label, onClose, closable }: any) => (
              <Tag
                color="#00468c"
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
                key={label}
              >
                {label}
              </Tag>
            )}
            defaultValue={
              productToUpdate.collections &&
              productToUpdate.collections.map((el: any) => el.name)
            }
            style={{ width: "100%" }}
            onChange={(x: any, value: any) => {
              setProductToUpdate({
                ...productToUpdate,
                collections: value,
              });
              setData({
                ...data,
                collections: value.map((x: any) => x.id),
              });
            }}
            options={
              options.collections_options &&
              options.collections_options.map((collection: any) => {
                return {
                  ...collection,
                  value: collection.name,
                  key: collection.id,
                };
              })
            }
          />
        </td>
      </tr>
      <tr>
        <th>Fabric</th>
        <td>
          <Select
            disabled={!user.permissions.includes("product.update")}
            mode="multiple"
            showArrow
            placeholder="Select Fabric"
            tagRender={({ label, onClose, closable }: any) => (
              <Tag
                color="#00468c"
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
                key={label}
              >
                {label}
              </Tag>
            )}
            defaultValue={
              productToUpdate.fabrics &&
              productToUpdate.fabrics.map((el: any) => el.name)
            }
            style={{ width: "100%" }}
            onChange={(x: any, value: any) => {
              setProductToUpdate({
                ...productToUpdate,
                fabrics: value,
              });
              setData({
                ...data,
                fabrics: value.map((x: any) => x.id),
              });
            }}
            options={
              options?.fabrics_options &&
              options?.fabrics_options.map((fabric: any) => {
                return {
                  ...fabric,
                  value: fabric.name,
                  key: fabric.id,
                };
              })
            }
          />
        </td>
      </tr>
      <tr>
        <th>Dress Code</th>
        <td>
          <Select
            disabled={!user.permissions.includes("product.update")}
            defaultValue={
              productToUpdate.dress_code_id &&
              String(productToUpdate.dress_code_id)
            }
            style={{ width: "100%" }}
            placeholder="Select Dress Code"
            onChange={(val: any) => selectFavBy(val, "dress_code_id")}
          >
            {options.dress_codes_options &&
              options.dress_codes_options.map((el: any) => (
                <Option value={String(el.id)} key={el.id}>
                  {el.name}
                </Option>
              ))}
          </Select>
        </td>
      </tr>
      {/*   <tr>
        <th>Fit</th>
        <td>
          <Input
            disabled={!user.permissions.includes("product.update")}
            type="text"
            placeholder="Fit"
            name="fit"
            onChange={handleChange}
            value={productToUpdate.fit}
          />
        </td>
      </tr> */}
      <tr>
        <th>Total Price</th>
        <td>
          <Input
            disabled={!user.permissions.includes("product.update")}
            type="text"
            placeholder="Total Price"
            name="total_price"
            onChange={handleChange}
            value={productToUpdate.total_price}
          />
        </td>
      </tr>
      <tr>
        <th>Tags</th>
        <td>
          <Select
            disabled={!user.permissions.includes("product.update")}
            mode="multiple"
            showArrow
            placeholder="Select tags"
            tagRender={({ label, onClose, closable }: any) => (
              <Tag
                color="#00468c"
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
                key={label}
              >
                {label}
              </Tag>
            )}
            defaultValue={
              productToUpdate.tags &&
              productToUpdate.tags.map((el: any) => el.name)
            }
            style={{ width: "100%" }}
            onChange={(x: any, value: any) => {
              setProductToUpdate({
                ...productToUpdate,
                tags: value,
              });
              setData({ ...data, tags: value.map((x: any) => x.id) });
            }}
            options={
              options.tags_options &&
              options.tags_options.map((tag: any) => {
                return { ...tag, value: tag.name, key: tag.id };
              })
            }
          />
        </td>
      </tr>
      <tr>
        <th>Labels</th>
        <td>
          <Select
            disabled={!user.permissions.includes("product.update")}
            mode="multiple"
            showArrow
            placeholder="Select labels"
            tagRender={({ label, onClose, closable }: any) => (
              <Tag
                color="#00468c"
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
                key={label}
              >
                {label}
              </Tag>
            )}
            defaultValue={
              productToUpdate.labels &&
              productToUpdate.labels.map((el: any) => el.name)
            }
            style={{ width: "100%" }}
            onChange={(x: any, value: any) => {
              setProductToUpdate({
                ...productToUpdate,
                labels: value,
              });
              setData({
                ...data,
                labels: value.map((x: any) => x.id),
              });
            }}
            options={
              options.labels_options &&
              options.labels_options.map((tag: any) => {
                return { ...tag, value: tag.name, key: tag.id };
              })
            }
          />
        </td>
      </tr>

      <tr>
        <th>For you</th>
        <td>
          <Select
            disabled={!user.permissions.includes("product.update")}
            mode="multiple"
            showArrow
            placeholder="Select companies"
            tagRender={({ label, onClose, closable }: any) => (
              <Tag
                color="#00468c"
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
                key={label}
              >
                {label}
              </Tag>
            )}
            defaultValue={
              productToUpdate.companies_suggested_products &&
              productToUpdate.companies_suggested_products.map(
                (el: any) => el.name
              )
            }
            style={{ width: "100%" }}
            onChange={(x: any, value: any) => {
              setProductToUpdate({
                ...productToUpdate,
                foryou: value,
              });
              setData({
                ...data,
                foryou: value.map((x: any) => x.id),
              });
            }}
            options={
              foryou &&
              foryou.map((tag: any) => {
                return { ...tag, value: tag.name, key: tag.id };
              })
            }
          />
        </td>
      </tr>
      <tr>
        <th>Favorite By</th>
        <td>
          <Select
            disabled={!user.permissions.includes("product.update")}
            defaultValue={
              productToUpdate.favorite_by_id &&
              String(productToUpdate.favorite_by_id)
            }
            style={{ width: "100%" }}
            placeholder="Select favorite by who"
            onChange={(val: any) => selectFavBy(val, "favorite_by_id")}
          >
            <Option value="">Select favorite by who</Option>
            <Option value="3">Alison De Meirsman</Option>
            <Option value="4">Bartek Nowak</Option>
            <Option value="5">Johnny De Meirsman</Option>
            <Option value="6">Patrick Raes</Option>
            <Option value="7">Jelloul Bouguila</Option>
          </Select>
        </td>
      </tr>
      <tr>
        <th>Published</th>
        <td>
          <Switch
            defaultChecked={!productToUpdate.masked}
            onChange={(checked: boolean) => {
              isMasked(!checked, "masked");
            }}
          />
        </td>
      </tr>
      {mode === "knit" && (
        <>
          <tr>
            <th>Total Price BCI Cotton</th>
            <td>
              <Input
                disabled={!user.permissions.includes("product.update")}
                type="number"
                min={0}
                placeholder="Total Price"
                name="total_price"
                onChange={handleChange}
                value={productToUpdate.total_price}
              />
            </td>
          </tr>
          <tr>
            <th>Total Price Organic Cotton</th>
            <td>
              <Input
                disabled={!user.permissions.includes("product.update")}
                type="number"
                min={0}
                placeholder="Total Price"
                name="total_price_two"
                onChange={handleChange}
                value={productToUpdate.total_price_two}
              />
            </td>
          </tr>
          <tr>
            <th>Total Price</th>
            <td>
              <Input
                disabled={!user.permissions.includes("product.update")}
                type="number"
                min={0}
                placeholder="Total Price"
                name="total_pricing"
                onChange={handleChange}
                value={productToUpdate.total_pricing}
              />
            </td>
          </tr>
          {/*    <tr>
            <th>Fabric Price</th>
            <td>
              <Input
                disabled={!user.permissions.includes("product.update")}
                type="number"
                min={0}
                placeholder="Fabric Price"
                name="fabric_price"
                onChange={handleChange}
                value={productToUpdate.fabric_price}
              />
            </td>
          </tr> */}
        </>
      )}
    </>
  );
};

export default TagsTable;
