import React, { useState, useEffect, useRef } from "react";
import "./ProductCard.scss";

import placeholder from "../../img/img_placeholder.svg";
import sustainableLogo from "../../img/sustainableLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";

import { Checkbox } from "antd";
import {
  addProductToSelected,
  clearSelectedFolders,
} from "../../actions/folders-actions/actions";
import { Link } from "react-router-dom";

type cardProps = {
  productPage?: boolean;
  newProduct?: boolean;
  // isGreen?: boolean;
  product?: any;
  item: {
    main: string;
    onHover: string;
  };
  changeCurrentProduct?: (id: any) => void;
  setsubFolderId?: (p: any) => void;
  setMovingProduct?: (p: any) => void;
  setProductsToOrder: (p: any) => void;
  productsToOrder: any;
  selectable?: boolean;
  index: number;
};

export const DraggableMyProductCard: React.FC<cardProps> = ({
  item,
  productPage = false,
  newProduct = false,
  // isGreen = false,
  product,
  setProductsToOrder,
  productsToOrder,
  index,
  selectable,
}) => {
  const selection = useSelector((state: any) => state.selection);
  const folders = useSelector((state: any) => state.folders);
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [checkedCheck, setCheckedCheck] = useState(false);
  const rightLine: any = useRef();
  const imgProd = () => {
    if (productPage) {
      if (hovered && item.main && item.onHover) {
        return process.env.REACT_APP_STORAGE_URL + item.onHover;
      } else if (hovered && item.main && !item.onHover) {
        return process.env.REACT_APP_STORAGE_URL + item.main;
      } else if (hovered && !item.main && !item.onHover) {
        return placeholder;
      } else if (!hovered && !item.main && !item.onHover) {
        return placeholder;
      } else {
        return process.env.REACT_APP_STORAGE_URL + item.main;
      }
    } else {
      if (!hovered) {
        return item.main;
      } else {
        return item.onHover;
      }
    }
  };

  // useEffect(() => {})

  useEffect(() => {
    if (rightLine.current) {
      setWidth(parseInt(rightLine.current.offsetWidth));
      setHeight(parseInt(rightLine.current.offsetHeight));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (rightLine.current) {
        setWidth(parseInt(rightLine.current.offsetWidth));
        setHeight(parseInt(rightLine.current.offsetHeight));
      }
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [width, height]);

  // COLOR STROKE
  const colorStroke = () => {
    let sustain = product.labels.find((el: any) => el.name === "SUSTAINABLE");
    let newLabel = product.labels.find((el: any) => el.name === "NEW");
    let favorite = product.labels.find(
      (el: any) => el.name === "DEMCO'S FAVORITE"
    );
    if (product.labels && newLabel) {
      return "#00468c";
    } else if (product.labels && sustain) {
      return "#9A9C44";
    } else if (product.labels && favorite) {
      return "#9A3996";
    }
  };

  const addProductToSelectedProducts = (idProduct: any) => {
    var productToOrder = productsToOrder.find(
      (el: any) => el.id === product.id
    );
    var newProductsToOrder;
    if (productToOrder) {
      newProductsToOrder = productsToOrder.filter(
        (el: any) => el.id === product.id
      );
      setProductsToOrder(newProductsToOrder);
    } else {
      newProductsToOrder = [...productsToOrder, product];
      setProductsToOrder(newProductsToOrder);
    }
    // setProductsToOrder(idProduct);
    dispatch(addProductToSelected(idProduct));
    setCheckedCheck(!checkedCheck);
  };

  const handleChange = () => {
    dispatch(clearSelectedFolders());
  };
  useEffect(() => {
    if (productPage) {
      if (
        folders &&
        folders.selectedProducts &&
        folders.selectedProducts.find((p: any) => p.id === product.id)
      ) {
        setCheckedCheck(true);
      } else {
        setCheckedCheck(false);
      }
    }
  }, [folders.selectedProducts]);

  const draggableProductData = {
    draggableId: `draggable-product${product.id}`,
    parentId: product.idParentFolder,
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    border: "1px solid #eee",
    borderRadius: "10px",
    // change background colour if dragging
    borderColor: isDragging ? "lightblue" : "#dcdbdc",
    background: "white",
    marginBottom: "20px",
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  /* 
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    height: "1px",
    border: isDragging ? "1px solid  #11ffee00" : "1px solid #11ffee00",
    borderRadius: isDragging ? "500px" : "10px",
    // change background colour if dragging
    borderColor: isDragging ? "#11ffee00" : "#11ffee00",
    background: isDragging ? "#11ffee00" : "#11ffee00",
    marginBottom: "20px",
    color: "red",

    // styles we need to apply on draggables
    ...draggableStyle,
  }); */
  return (
    <Draggable
      key={product.id}
      draggableId={JSON.stringify(draggableProductData)}
      index={index}
      disableInteractiveElementBlocking
      isDragDisabled={true}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <div
            className={"draggable-product"}
            ref={rightLine}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            /*      style={{
              userSelect: "none",
              border: "1px solid #eee",
              borderRadius: "10px",
              background: "white",
              marginBottom: "20px",
            }} */
          >
            <img className="drag-img-prod" src={imgProd()} alt="" />

            <div className="product_card_footer draggable-ft-prod">
              <div>
                <div className="labels_list">
                  {productPage &&
                    product.labels &&
                    product.labels.map((el: any, i: number) =>
                      el.name === "SUSTAINABLE" ? (
                        <div className="sustain_product_label" key={i}>
                          <img src={sustainableLogo} alt="sustainable" />
                          <label>{el.name}</label>
                        </div>
                      ) : !product.labels.find(
                          (el: any) => el.name === "SUSTAINABLE"
                        ) &&
                        !product.labels.find((el: any) => el.name === "NEW") &&
                        product.favorite_by ? (
                        <div className="favorite_product_label" key={i}>
                          {/* <img src={sustainableLogo} alt="sustainable" /> */}
                          <p className="favorite_by">
                            {product.favorite_by.name}' favorite
                          </p>
                          {/* <label>{el.name}</label> */}
                        </div>
                      ) : // : el.name === "DEMCO'S FAVORITE" ? (
                      //     <div className="demco_product_label" key={i}>
                      //       <img src={demcoFavLogo} alt="Demco's favorite" />
                      //       <label>{el.name}</label>
                      //     </div>
                      //   )
                      null
                    )}
                </div>
                {productPage && product && (
                  <>
                    <h4
                      className="h4_medium text-center"
                      style={{ fontWeight: 400 }}
                    >
                      {product.name}
                    </h4>
                  </>
                  // <h4 className="h4_medium" style={{ fontWeight: 600 }}>
                  //   {product.name}
                  // </h4>
                )}
              </div>

              {productPage &&
                product &&
                product.favorite_by &&
                product.favorite_by.avatar && (
                  <img
                    src={
                      process.env.REACT_APP_STORAGE_URL +
                      product.favorite_by.avatar
                    }
                    className="favorite_by_img"
                  />
                )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
