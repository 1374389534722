import { Button, Popconfirm, Tooltip } from "antd";
import {
  HistoryOutlined,
  CheckOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useMutation } from "react-query";
import { MyNotif } from "../NotifIcons/NotifIcons";
import axiosInstance from "../../config/axios-instance";
import useWindowWidth from "../../config/useWindowWidth";
import { useState } from "react";
import ConfirmDrawer from "./ConfirmDrawer";

export default function DropdownActions({
  meet,
  refetch,
  confirmCancel,
  meetingToCancel,
  deleteLoading,
}: any) {
  const [visible, setVisible] = useState(false);
  const [meetToConfirm, setMeetToConfirm] = useState(null);
  const width = useWindowWidth();

  const onClose = () => setVisible(false);

  const acceptMeeting = async () => {
    let token = localStorage.getItem("token");
    setMeetToConfirm(meet.id);
    const { data: response } = await axiosInstance({
      method: "post",
      url: `/admin/bookings/${meet.id}`,
      data: {
        status: "accepted",
        client_id: meet.client.id,
        _method: "patch",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await MyNotif("Meeting accepted successfully.", "success");
    refetch();
    setMeetToConfirm(null);
    return response.data;
  };

  const { mutate: mutateUpdateBooking, isLoading: updateLoading } =
    useMutation(acceptMeeting);

  function confirm() {
    mutateUpdateBooking();
  }
  return (
    <>
      {!(meet.status === "new" && meet.details.user) && (
        <Popconfirm
          title="Confirm this meeting？"
          okText="Yes"
          cancelText="No"
          onConfirm={confirm}
          disabled={meet.status === "waiting" && !meet.details.user}
        >
          <Tooltip
            title="Confirm"
            placement="left"
            overlayInnerStyle={{
              opacity: 0.8,
              color: "black",
              display: width > 1000 ? "none" : "inherit",
            }}
            color="white"
          >
            <Button
              disabled={
                meet.details.updated_by === "demco" && !meet.details.user
              }
              type="ghost"
              className="simple-blue-btn mr-2"
              icon={
                updateLoading && meetToConfirm === meet.id ? (
                  <LoadingOutlined style={{ verticalAlign: "1px" }} />
                ) : (
                  <CheckOutlined style={{ verticalAlign: "1px" }} />
                )
              }
            >
              {width > 1000 &&
                (updateLoading && meetToConfirm === meet.id
                  ? "Confirming..."
                  : "Confirm")}
            </Button>
          </Tooltip>
        </Popconfirm>
      )}
      {!(meet.status === "waiting" && meet.details.user) ? (
        <Tooltip
          title="Reschedule"
          placement="right"
          overlayInnerStyle={{
            opacity: 0.8,
            color: "black",
            display: width > 1000 ? "none" : "inherit",
          }}
          color="white"
        >
          <Button
            type="ghost"
            style={{ color: "black", borderRadius: 5 }}
            icon={<HistoryOutlined style={{ verticalAlign: "1px" }} />}
            onClick={() => setVisible(true)}
          >
            {width > 1000 && "Reschedule"}
          </Button>
        </Tooltip>
      ) : (
        <Popconfirm
          title="Cancel this meeting？"
          okText="Yes"
          cancelText="No"
          onConfirm={() => confirmCancel(meet)}
        >
          <Tooltip
            title="Cancel"
            placement="right"
            overlayInnerStyle={{
              opacity: 0.8,
              color: "black",
              display: width > 1000 ? "none" : "inherit",
            }}
            color="white"
          >
            <Button
              type="ghost"
              style={{
                background: "transparent",
                color: "black",
                borderRadius: 5,
              }}
              className="mr-2"
              icon={
                deleteLoading && meetingToCancel === meet.id ? (
                  <LoadingOutlined style={{ verticalAlign: "1px" }} />
                ) : (
                  <CloseCircleOutlined style={{ verticalAlign: "1.7px" }} />
                )
              }
            >
              {width > 1000 &&
                (deleteLoading && meetingToCancel === meet.id
                  ? "Cancelling..."
                  : "Cancel")}
            </Button>
          </Tooltip>
        </Popconfirm>
      )}

      <ConfirmDrawer
        visible={visible}
        onClose={onClose}
        meet={meet}
        setVisible={setVisible}
        refetch={refetch}
      />
    </>
  );
}
