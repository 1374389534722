import { useRef, useState } from "react";
import { PlayCircleOutlined } from "@ant-design/icons";
import image_bg_video from "../../img/logo_green.svg";
const VideoComponent = ({ post }: any) => {
  const [isPlayVideo, setIsPlayVideo] = useState(false);
  const vidRef = useRef<any>();

  const playVideo = () => {
    if (!isPlayVideo) {
      vidRef.current.play();
     /* if (vidRef.current.requestFullscreen) {
        vidRef.current.requestFullscreen();
      } else if (vidRef.current.mozRequestFullScreen) { // Firefox
        vidRef.current.mozRequestFullScreen();
      } else if (vidRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
        vidRef.current.webkitRequestFullscreen();
      } else if (vidRef.current.msRequestFullscreen) { // IE/Edge
        vidRef.current.msRequestFullscreen();
      }*/
    } else {
      vidRef.current.pause();
    }
    setIsPlayVideo(!isPlayVideo);
  };
  return (
    <>
 

      <video
        ref={vidRef}
        controls={isPlayVideo}
        key={post.id}
        style={{ width: "100%" }}
        className="item video_post mb-0"
        onClick={playVideo}
        controlsList="nodownload"
        poster={post?.cover ? process.env.REACT_APP_STORAGE_URL + post?.cover:image_bg_video }
      >
        <source
          src={
            post?.file && typeof post?.file === "object"
              ? URL.createObjectURL(post?.file)
              : process.env.REACT_APP_STORAGE_URL + post?.file
          }
          type="video/webm"
        />
      </video>
     {!isPlayVideo && (
          <button className="play"  style={{
            position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
          }}onClick={playVideo}>
            <PlayCircleOutlined />
          </button>
      )} 
    </>
  );
};

export default VideoComponent;
