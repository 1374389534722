import { Pagination, Spin, Avatar, Tooltip, Drawer } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../config/axios-instance";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import "../components/AllClients/AllClient.scss";
import { Link, useLocation, useHistory } from "react-router-dom";
import company_placeholder from "../img/company_placeholder.png";
import { useSelector } from "react-redux";
import Head from "../components/Head";

const AllCompanies = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();
  const { user } = useSelector((state: any) => state.auth);
  const [showDrawer, setShowDrawer] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQueryy();

  const fetchClients = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/companies?page=${query.get("page") || 1}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { isLoading, isError, data, error } = useQuery(
    ["companies", query.get("page")],
    () => fetchClients(),
    { keepPreviousData: true }
  );

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  const filteredData = data?.data.data.filter((el: any) =>
    el.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function goto(el: any) {
    history.push(`/foryou-all-companies/${el.id}/1`);
  }
  return isLoading ? (
    <div className="loading_orders">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <div className="my_orders">
      <Head title="For You" />
      <div className="container">
        <div className="d-flex align-items-center justify-content-between pr-1 mb-1">
          <div>
            <h1 className="mb-0 mt-3 list_of_companies_title">
              List of Companies
            </h1>
          </div>
          <div></div>
        </div>
        <div className="row mt-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search your company..."
            className="option_details_input"
          />
        </div>
        <table className="table mt-2">
          <tbody>
            {filteredData?.map((el: any, i: number) => (
              <tr key={i} style={{ height: "66px" }} onClick={() => goto(el)}>
                <td>
                  <div className="d-flex align-items-center">
                    <img
                      className="mr-3"
                      src={
                        el.logo
                          ? process.env.REACT_APP_STORAGE_URL + el.logo
                          : company_placeholder
                      }
                      width="30"
                    />
                    <p className="mb-0">{el.name}</p>
                  </div>
                </td>

                {/*  <td align="center">
                  <Link to={`/foryou-all-companies/${el.id}/1`}>
                    See details
                  </Link>
                </td>*/}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              {/*    <td colSpan={5}>
                <Pagination
                  defaultCurrent={1}
                  defaultPageSize={10}
                  total={data && data.data.total}
                  hideOnSinglePage
                  responsive
                  onChange={(page: any) =>
                    history.push(`/foryou-all-companies?page=${page}`)
                  }
                />
              </td> */}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default AllCompanies;
