import { useEffect, useState } from "react";
import "../sass/SearchResult.scss";
import { Link, useParams } from "react-router-dom";
import { Drawer, Spin, Card, Popconfirm } from "antd";
import { useSelector, useDispatch } from "react-redux";
// import { ProductCard } from "../components/ProductCard/ProductCard";
import Folder from "../components/Folder/Folder";
import { FilterOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import {
  filterByTyping,
  getProductsNextPage,
  loadMoreSearchResult,
} from "../actions/products-actions/actions";
import {
  clearOneFolder,
  clearRightSideFolder,
  getAllFolders,
} from "../actions/folders-actions/actions";
import { AnimatePresence, motion } from "framer-motion";
import { SearchFilter } from "../components/Search/SearchFilter";
import Head from "../components/Head";
import LazyImg from "../components/Library/LazyImage";
import placeholder_jeans from "../img/placeholder_jeans.svg";
import watermak from "../img/watermark.png";
import customizeIcon from "../img/customize_icon.svg";
import useWindowWidth from "../config/useWindowWidth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faSpinner, faStar } from "@fortawesome/free-solid-svg-icons";

import {
  addProductToSelection,
  removeProductFromSelection,
} from "../actions/myselection-actions/actions";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import sustainableLogo from "../img/sustainableLogo.svg";
import icon3D from "../img/3d_icon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import axiosInstance from "../config/axios-instance";
import { MyNotif } from "../components/NotifIcons/NotifIcons";

const { Meta } = Card;
interface Props {}

const variants = {
  enter: (): object => {
    return {
      opacity: 0,
    };
  },
  center: (): object => {
    return {
      opacity: 1,
    };
  },
  exit: () => {
    return {
      opacity: 0,
    };
  },
};

export const SearchResult = (props: Props) => {
  const [collectionSwitch, setCollectionSwitch] = useState(true);
  const [productsSwitch, setProductsSwitch] = useState(true);
  const [fabricSwitch, setFabricSwitch] = useState(true);
  const [washSwitch, setWashSwitch] = useState(true);
  const [result, setResult] = useState([]);
  const [items, setItems] = useState<any>();
  const [forYouCompany, setForYouCompany] = useState<any>(null);

  const [collectionResult, setCollectionResult] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [hasMore, sethasMore] = useState(true);
  const params: any = useParams();
  const dispatch = useDispatch();
  const { isLoading, products, next, isLoadingMore, total } = useSelector(
    (state: any) => state.products
  );
  const { folders } = useSelector((state: any) => state.folders);
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  useEffect(() => {
    const company_id = localStorage.getItem("company_id");

    if (!forYouCompany && company_id) {
      getForYouCompany(company_id);
    }
  }, []);

  const getForYouCompany = async (company_id: string) => {
    let token = localStorage.getItem("token");
    if (company_id) {
      await axiosInstance({
        method: "get",
        url: `/admin/forYouCompany/${company_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        setForYouCompany(response.data);
      });
      return "ok";
    }
  };

  useEffect(() => {
    dispatch(clearRightSideFolder());
    dispatch(clearOneFolder());
    dispatch(filterByTyping(params.keyword));
    if (!folders) {
      dispatch(getAllFolders());
    } else {
      setCollectionResult(
        folders.filter((el: any) =>
          String(el.name.toLowerCase()).includes(params.keyword.toLowerCase())
        )
      );
    }
    // eslint-disable-next-line
  }, [params.keyword]);

  useEffect(() => {
    // setResult(products);
    setCollectionResult(
      folders &&
        folders.filter((el: any) =>
          String(el.name.toLowerCase()).includes(params.keyword.toLowerCase())
        )
    );
    // eslint-disable-next-line
  }, [isLoading, params]);

  useEffect(() => {
    if (folders) {
      setCollectionResult(
        folders.filter((el: any) =>
          String(el.name.toLowerCase()).includes(params.keyword.toLowerCase())
        )
      );
    }
    // eslint-disable-next-line
  }, [folders]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  const turnOnAllFilters = () => {
    setCollectionSwitch(true);
    setProductsSwitch(true);
    setFabricSwitch(true);
    setWashSwitch(true);
  };

  useEffect(() => {
    return () => {};
  });
  /* 
  useEffect(() => {
    const getComments = async () => {
      const res = await fetch(
        process.env.REACT_APP_API_URL +
          `/products` +
          "?" +
          "page=" +
          2 +
          "&" +
          "name=" +
          params.keyword
        // For json server use url below
        // `http://localhost:3004/comments?_page=1&_limit=20`
      );
      const data = await res.json();

      setItems(data);
      console.log(items);
    };

    getComments();
  }, []); */

  //infinity scroll
  /*  const fetchData = async () => {
    console.log("isLoadingNext" + params.keyword);
    // getProductsNextPage(next.substr(69));

    dispatch(loadMoreSearchResult(next + "&name=" + params.keyword));
  }; */

  const fetchData = async () => {
    //   console.log("isLoadingNext" + isLoadingNext);

    if (next) {
      dispatch(
        //localhost
        //  getProductsNextPage(next_page.substr(40) + "&" + queries.join("&"))

        //test version
        getProductsNextPage(next.substr(45) + "&name=" + params.keyword)

        //prod version
        /*   getProductsNextPage(
          next_page.substr(process.env.REACT_APP_URL_LENGTH) +
            "&" +
            queries.join("&")
        ) */
      );
    }
  };
  return (
    <div className="result_search">
      {width >= 1000 && (
        <aside className="result_search_filter">
          <Head title="Search Result" />
          <h2 className="h2_subtitle_blue">Search results</h2>
          <SearchFilter
            collectionSwitch={collectionSwitch}
            productsSwitch={productsSwitch}
            fabricSwitch={fabricSwitch}
            washSwitch={washSwitch}
            products={products}
            setCollectionSwitch={setCollectionSwitch}
            setResult={setResult}
            setProductsSwitch={setProductsSwitch}
            setWashSwitch={setWashSwitch}
            setFabricSwitch={setFabricSwitch}
          />
        </aside>
      )}
      <AnimatePresence exitBeforeEnter={false}>
        <main className="result_search_content">
          {width < 1000 && (
            <button
              className="filter_product_button"
              onClick={() => setOpenFilterDrawer(true)}
            >
              <FilterOutlined className="mr-2" /> Filter
            </button>
          )}
          <div className="result_search_content_header mb-2">
            {/* FILTER */}
            <Drawer
              className="result_search_filter_drawer"
              title="FILTER"
              placement={"left"}
              closable={true}
              onClose={() => setOpenFilterDrawer(false)}
              visible={openFilterDrawer}
              key={"left"}
              width={"23rem"}
            >
              <div className="result_search_filter result_search_filter_drawer_content p-3">
                <h2 className="h2_subtitle_blue">Search results</h2>
                <SearchFilter
                  collectionSwitch={collectionSwitch}
                  productsSwitch={productsSwitch}
                  fabricSwitch={fabricSwitch}
                  washSwitch={washSwitch}
                  products={products}
                  setCollectionSwitch={setCollectionSwitch}
                  setResult={setResult}
                  setProductsSwitch={setProductsSwitch}
                  setWashSwitch={setWashSwitch}
                  setFabricSwitch={setFabricSwitch}
                />
              </div>
            </Drawer>
            <p className="mb-0">
              You're Searching For: <span>{params.keyword}</span>
            </p>
            <div className="result_text d-flex flex-column align-items-start">
              <span>{total} Results Found</span>
              <span className="show_all_result" onClick={turnOnAllFilters}>
                Show All
              </span>
            </div>
          </div>

          {collectionSwitch && user && !isAdmin && (
            <motion.div
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              className="result_search_collections"
            >
              <div className="result_search_title">
                <h6>Collections</h6>
              </div>
              <div className="row result_search_data">
                {collectionResult && collectionResult.length ? (
                  collectionResult.map((el: any, i: number) => (
                    <Folder folder={el} key={i} fromSearch={true} />
                  ))
                ) : (
                  <h6>No available collections with the searched term</h6>
                )}
              </div>
            </motion.div>
          )}
          {productsSwitch && (
            <motion.div
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              className="result_search_products"
            >
              <div className="result_search_title">
                <h6>Products</h6>
              </div>
              {products.length === 0 && (
                <h6>No available products with the searched term</h6>
              )}
              <InfiniteScroll
                dataLength={products.length} //This is important field to render the next data
                next={fetchData}
                hasMore={hasMore}
                loader={""}
              >
                <div className="products-grid">
                  {products.map((el: any, i: number) => (
                    <ProductCard
                      key={el.id}
                      index={i}
                      productPage={true}
                      product={el}
                      params={params}
                      foryou={forYouCompany}
                      item={{
                        main: el.images[0] ? el.images[0].path : "",
                        onHover: el.images[1] ? el.images[1].path : "",
                      }}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </motion.div>
          )}
          {/*  {productsSwitch && next && (
            <div style={{ textAlign: "center" }}>
              <button
                className="load_more_btn my-5"
                style={{ fontSize: "1rem", padding: "0.5rem 1rem" }}
                onClick={() =>
                  dispatch(
                    loadMoreSearchResult(next + "&name=" + params.keyword)
                  )
                }
              >
                {isLoadingMore ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: "1rem", color: "white" }}
                        spin
                      />
                    }
                  />
                ) : (
                  "LOAD MORE"
                )}
              </button>
            </div>
          )} */}
        </main>
        {/* {next && <button >{isLoadingMore ? "loading..." : "Load More"}</button>} */}
      </AnimatePresence>
    </div>
  );
};

export const ProductCard = ({
  productPage,
  item,
  product,
  params,
  foryou,
}: any) => {
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const selection = useSelector((state: any) => state.selection);
  const [forYouCompany, setForYouCompany] = useState<any>(null);
  const [FY, setFY] = useState<any>(null);
  const [starCheck, setStarCheck] = useState<any>(false);
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const colorStroke = () => {
    let sustain = product?.labels?.find((el: any) => el.name === "SUSTAINABLE");
    let newLabel = product?.labels?.find((el: any) => el.name === "NEW");
    let favorite = product?.labels?.find(
      (el: any) => el.name === "DEMCO'S FAVORITE"
    );
    if (product?.labels && newLabel) {
      return newLabel.name;
    } else if (product?.labels && sustain) {
      return sustain.name;
    } else if (product?.labels && favorite) {
      return favorite.name;
    } else {
      return "none";
    }
  };

  useEffect(() => {
    var filteredProduct = foryou?.products.filter(
      (prod: any) => prod.id === product.id
    );

    if (filteredProduct) {
      setFY(filteredProduct[0]?.id);
    }
  }, [foryou]);

  const handleCancel = (e: any) => {
    e.preventDefault();
  };

  const confirm = async (e: any, product_id: any) => {
    e.preventDefault();

    let token = localStorage.getItem("token");
    let company_id = localStorage.getItem("company_id");
    if (company_id) {
      setStarCheck(true);
      await axiosInstance({
        method: "post",
        url: `/admin/attachforyou/${company_id}`,
        data: {
          product_id: product_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        if (response.status === 200) {
          MyNotif(`Product attached successfully  `, "success");
          setFY(product_id);
          setStarCheck(false);
          //   refetch();
        }
      });
      return "ok";
    } else {
      MyNotif(`Please select the company`, "error");
    }
  };

  const confirmdeattch = async (e: any, product_id: any) => {
    e.preventDefault();

    let token = localStorage.getItem("token");
    let company_id = localStorage.getItem("company_id");
    if (company_id) {
      setStarCheck(true);
      await axiosInstance({
        method: "post",
        url: `/admin/detachforyou/${company_id}`,
        data: {
          product_id: product_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        if (response.status === 200) {
          setStarCheck(false);
          MyNotif(`Product detached successfully  `, "success");
          setFY(0);
          //   setStarCheck(0);
          //   refetch();
        }
      });
      return "ok";
    } else {
      MyNotif(`Please select the company`, "error");
    }
  };

  return (
    <Card
      style={{
        width: width < 1200 ? "100%" : "14rem",
        // padding: 15,
      }}
      bordered={false}
      bodyStyle={{ padding: "0px" }}
      className="grid-item-product"
      cover={
        <div className={`product_item_new ${colorStroke()} mb-2`}>
          <LazyImg
            alt={item.name}
            style={{
              position: "absolute",
              zIndex: 2,
              opacity: 0.2,
              objectFit: "contain",
              width: "100%",
              height: "260px",
            }}
            src={watermak}
            thumb={watermak}
          />
          <LazyImg
            className="img-front"
            alt={item.name}
            style={{
              objectFit: "contain",
              width: "100%",
              height: "260px",
            }}
            src={
              item.main
                ? process.env.REACT_APP_STORAGE_URL + item.main
                : placeholder_jeans
            }
            thumb={
              item.main
                ? process.env.REACT_APP_STORAGE_URL + item.main
                : placeholder_jeans
            }
          />
          <LazyImg
            style={{ height: "260px", objectFit: "contain" }}
            className="img-back"
            alt={item.name}
            src={
              item.onHover
                ? process.env.REACT_APP_STORAGE_URL + item.onHover
                : placeholder_jeans
            }
            thumb={
              item.onHover
                ? process.env.REACT_APP_STORAGE_URL + item.onHover
                : placeholder_jeans
            }
          />
          {product?.labels?.find((el: any) => el.name === "NEW") && (
            <div className="new_product_label">
              <label>NEW</label>
            </div>
          )}
          {product?.is_3d === 1 && (
            <div
              style={{
                width: "40px",
                height: "40px",
                background: "rgba(255, 255, 255, 0.2)",
                position: "absolute",
                top: "5px",
                left: "5px",
              }}
              className="d-flex align-items-center justify-content-center p-0"
            >
              <img
                src={icon3D}
                alt="3D product icon"
                style={{ maxWidth: "80%", height: "auto" }}
              />
            </div>
          )}

          {productPage &&
            user &&
            isAdmin &&
            localStorage.getItem("company_name") != null && (
              <>
                {FY == product.id ? (
                  <Popconfirm
                    placement="top"
                    title={`Do you want to proceed with detaching the product from ${
                      localStorage.getItem("company_name") != null
                        ? localStorage.getItem("company_name")
                        : ""
                    }`}
                    onConfirm={(e) => confirmdeattch(e, product.id)}
                    okText="Yes"
                    cancelText="No"
                    onCancel={(e) => {
                      handleCancel(e);
                    }}
                  >
                    {/*    <button
                              className="customize_icon"
                              style={{
                                position: "absolute",
                                bottom: "5px",
                                right: "2px",
                                color: "#979A9D",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              For You
                            </button> */}

                    <FontAwesomeIcon
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        right: "5px",
                      }}
                      icon={starCheck ? faSpinner : faStar}
                      className={
                        starCheck
                          ? "favorite_icon favorite_loading favorite_icon_blue"
                          : "favorite_icon  favorite_icon_blue"
                      }
                      spin={starCheck}
                    />
                  </Popconfirm>
                ) : (
                  <>
                    {" "}
                    <Popconfirm
                      placement="top"
                      title={`Do you want to proceed with attaching the product to ${
                        localStorage.getItem("company_name") != null
                          ? localStorage.getItem("company_name")
                          : ""
                      }`}
                      onConfirm={(e) => confirm(e, product.id)}
                      okText="Yes"
                      cancelText="No"
                      onCancel={(e) => {
                        handleCancel(e);
                      }}
                    >
                      {/*   <button
                                className="customize_icon"
                                style={{
                                  position: "absolute",
                                  bottom: "5px",
                                  right: "2px",

                                  color: "#00468C",
                                  fontWeight: "bold",
                                  fontSize: "20px",
                                }}
                              >
                                For You
                              </button> */}
                      <FontAwesomeIcon
                        style={{
                          position: "absolute",
                          bottom: "5px",
                          right: "5px",
                        }}
                        icon={starCheck ? faSpinner : faStar}
                        className={
                          starCheck
                            ? "favorite_icon favorite_loading"
                            : "favorite_icon"
                        }
                        spin={starCheck}
                      />
                    </Popconfirm>
                  </>
                )}
              </>
            )}
          {productPage && user && !isAdmin && (
            <div
            // className="product_card_icons"
            // style={{
            //   position: "absolute",
            //   top: 0,
            //   width: "100%",
            //   flexDirection: "row-reverse",
            // }}
            >
              <Link
                to={`/customization/new/${product.id}`}
                // className="customize_icon"
                style={{
                  position: "absolute",
                  right: "5px",
                  bottom: "5px",
                  zIndex: 2,
                }}
              >
                <img src={customizeIcon} alt="customize product icon" />
              </Link>

              {selection.selectionProducts &&
              selection.selectionProducts.some(
                (el: any) => el.id === product.id
              ) ? (
                <FontAwesomeIcon
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                  icon={
                    selection.productToAddId === product.id &&
                    selection.isLoading
                      ? faSpinner
                      : faHeart
                  }
                  className={
                    selection.productToAddId === product.id &&
                    selection.isLoading
                      ? "favorite_icon favorite_loading"
                      : "favorite_icon favorite_icon_red"
                  }
                  spin={
                    selection.productToAddId === product.id &&
                    selection.isLoading
                  }
                  onClick={(event: any) => {
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch(removeProductFromSelection(product.id));
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                  icon={
                    selection.productToAddId === product.id &&
                    selection.isLoading
                      ? faSpinner
                      : farHeart
                  }
                  spin={
                    selection.productToAddId === product.id &&
                    selection.isLoading
                  }
                  className={
                    selection.productToAddId === product.id &&
                    selection.isLoading
                      ? "favorite_icon favorite_loading"
                      : "favorite_icon"
                  }
                  onClick={(event: any) => {
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch(addProductToSelection(product.id));
                  }}
                />
              )}
            </div>
          )}
        </div>
      }
    >
      <Meta
        description={
          productPage &&
          product && (
            <div className="d-flex mt-2">
              <div className="labels_list">
                {productPage &&
                  product.labels &&
                  product.labels.map((el: any, i: number) =>
                    el.name === "SUSTAINABLE" ? (
                      <div className="sustain_product_label" key={i}>
                        <img src={sustainableLogo} alt="sustainable" />
                        <label>{el.name}</label>
                      </div>
                    ) : !product.labels.find(
                        (el: any) => el.name === "SUSTAINABLE"
                      ) &&
                      !product.labels.find((el: any) => el.name === "NEW") &&
                      product.favorite_by ? (
                      <div className="favorite_product_label" key={i}>
                        <p className="favorite_by">
                          {product.favorite_by.name}' favorite
                        </p>
                      </div>
                    ) : null
                  )}
                <Link
                  to={
                    params
                      ? `/search/result/${params.keyword}/${product.name}/${product.id}`
                      : `/search/result/${product.id}/${product.name}/${product.id}`
                  }
                  // style={{ textDecoration: "none" }}
                  className="link-decoration"
                  // className="col-6 col-xl-2 col-md-4"
                  // className="col d-flex justify-content-between mb-4"
                >
                  <div>
                    <h4 className="h4_medium mb-0" style={{ fontSize: "1rem" }}>
                      {product.code}
                    </h4>
                    <h4
                      className="h4_medium"
                      style={{ fontWeight: 400, fontSize: "1rem" }}
                    >
                      {product.name}
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="d-flex">
                {productPage &&
                  product &&
                  product.favorite_by &&
                  product.favorite_by.avatar && (
                    <img
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        product.favorite_by.avatar
                      }
                      className="favorite_by_img"
                    />
                  )}
              </div>
            </div>
          )
        }
      />
    </Card>
  );
};
