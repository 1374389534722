import Select from "react-select";
import { useSelector } from "react-redux";

interface Props {
  handleChange: (x: any) => void;
  options: any;
  DropdownIndicator: any;
  customStyles: any;
  genders: any;
  setfodlerSelected: any;
  setSelectDivision: any;
}

export const AddArticleBottomDrawer = ({
  handleChange,
  options,
  DropdownIndicator,
  customStyles,
  genders,
  setfodlerSelected,
  setSelectDivision,
}: Props) => {
  const { user } = useSelector((state: any) => state.auth);
  return (
    <div style={{ width: "100%" }} className="container-fluid">
      <div className="form_add_article">
        <div className="form_add_article_left col-12 col-md-6">
          <div className="field field_article field_article_name">
            <input
              type="text"
              name="name"
              id="ArticleName"
              placeholder="     "
              onChange={handleChange}
            />
            <label htmlFor="ArticleName" className="folder_label">
              Article Name
            </label>
          </div>

          {/* <div className="field field_article">
            <Select
              options={user.company.divisions.map((el: any) => {
                return {
                  label: el.name,
                  value: el.id,
                };
              })}
              styles={customStyles}
              //@ts-ignore
              onChange={setSelectDivision}
              placeholder={<div style={{ color: "black" }}>Division</div>}
              components={{ DropdownIndicator }}
            />
            <label htmlFor="division" className="account_divion_label">
              Division
            </label>
          </div> */}
          <div className="field field_article">
            <Select
              options={genders}
              styles={customStyles}
              //@ts-ignore
              onChange={setfodlerSelected}
              placeholder={<div style={{ color: "black" }}>Gender </div>}
              components={{ DropdownIndicator }}
            />
          </div>
        </div>
        <div className="form_add_article_right col-12 col-md-6">
          <div className="field field_article">
            <input
              type="text"
              name="company"
              id="companyName"
              placeholder="  "
              value={user && user.company.name}
              disabled
              onChange={handleChange}
            />
            <label htmlFor="companyName" className="folder_label">
              Company's Name
            </label>
          </div>
          <div className="field field_article">
            <input
              type="text"
              name="season"
              id="Season"
              placeholder="  "
              onChange={handleChange}
            />
            <label htmlFor="Season" className="folder_label">
              Season
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
