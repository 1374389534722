import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { AddNewOptionItem } from "./AddNewOptionItem";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedOption,
  createNewOption,
} from "../../actions/order-actions/actions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, notification, Select, Radio } from "antd";
import { DropZoneFile } from "./DropZoneFile";
import { CloseCircleOutlined } from "@ant-design/icons";
import Alert from "../Alert/Alert";
import { useParams } from "react-router-dom";

interface Props {
  width: number;
  setOptionSelected: (x: any) => void;
  emptyFields: any;
  setEmptyFields: (x: any) => void;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const AddNewOption = ({
  width,
  setOptionSelected,
  emptyFields,
  setEmptyFields,
}: Props) => {
  // all files
  const [files, setFiles] = useState<any>({
    style_file: null,
    fabric_file: null,
    wash_file: null,
    trims_file: null,
    thread_file: null,
  });
  // the selected select input (get the select name)
  const [selectedSelect, setSelectedSelect] = useState<any>(null);
  const [option, setOption] = useState<any>({
    name: "",
    style: "",
    fabric: "",
    wash: "",
    trims: "",
    thread: "",
    qty: "",
    size_qty: "",
    crotch: "",
    delivery_date: "",
  });
  const [alerts, setAlerts] = useState<any>(null);

  const { selectedArticle, orders, selectedOption, updateOptionLoading } =
    useSelector((state: any) => state.orders);
  const dispatch = useDispatch();
  const params: any = useParams();
  var order = orders.find((obj: any) => {
    return obj.id == params.id;
  });

  // attach files
  const attachFile = (name: any, file: any) => {
    setFiles({ ...files, [name]: file });
  };

  // Detach file
  const detachFile = (fieldName: any) => {
    setFiles({ ...files, [fieldName]: null });
  };

  // Clear alerts
  useEffect(() => {
    // dispatch(setAlerts(alerts))
    if (alerts) {
      setTimeout(() => {
        setAlerts(null);
      }, 5000);
    }
  }, [alerts]);

  // handle select input value
  const selectHandleChange = (e: any) => {
    setOption({
      ...option,
      [selectedSelect]: selectedSelect === "qty" ? +e : e,
    });
  };

  //   handle input values
  const handleChange = (e: any) => {
    setOption({
      ...option,
      [e.target.name]: e.target.value,
      [e.target.size_qty]: e.target.value,
      [e.target.crotch]: e.target.value,
      [e.target.delivery_date]: e.target.value,
    });
  };

  // get the select name
  const getSelectName = (name: string) => {
    setSelectedSelect(name);
  };
  const ref = useRef(null);

  const [inputList, setInputList] = useState([{ tail: "", qty: "" }]);

  const addInput = () => {
    setInputList([...inputList, { tail: "", qty: "" }]);
  };
  const removeInput = (i: any) => {
    console.log(i);
    const list = [...inputList];
    list.splice(i, 1);
    setInputList([...list]);
  };

  const { user, isLoading, isAdmin } = useSelector((state: any) => state.auth);

  if (order.type == "denim") {
    var keys = ["style", "fabric", "wash",  "size", "length"];
  } else {
    var keys = ["style", "fabric", "color",  "size", "length"];
  }
  let file_keys = [
    "style_file",
    "fabric_file",
    "wash_file",
    "trims_file",
    "thread_file",
  ];

  const chartContainer = useRef(null);
  const [chartLoaded, setChartLoaded] = useState(false);
  const [chart, setChart] = useState(null);

  useEffect(() => {
    // Check if Chart.js script is already loaded
    if (!document.querySelector('script#chartjs-script')) {
      // Create script element
      const script = document.createElement('script');
      script.id = 'chartjs-script';
      script.src = 'https://cdn.jsdelivr.net/npm/chart.js';
      script.onload = () => setChartLoaded(true); // Set flag when script is loaded

      // Append the script to the document body
      document.body.appendChild(script);

      // Remove the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    } else {
      // If the script is already in the document, set the flag to true
      setChartLoaded(true);
    }
  }, []);

  useEffect(() => {
    // When Chart.js has loaded and the container ref is attached, instantiate the chart
    if (chartLoaded && chartContainer.current && !chart) {
      //@ts-ignore
      const newChart = new Chart(chartContainer.current, {
        type: 'bar',
        data: {
          labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [
            {
              label: '# of Votes',
              data: [12, 19, 3, 5, 2, 3],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
      setChart(newChart);
    }

    // Clean up the chart instance when the component unmounts
    return () => {
      if (chart) {
        //@ts-ignore
        chart.destroy();
      }
    };
  }, [chartLoaded, chart]);



  return (
      <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          className="order_option_details"
      >

{/*
        <canvas ref={chartContainer}/>
*/}
        <div
            className="d-flex align-items-center mb-2 pl-md-3"
            onClick={() => width <= 575 && setOptionSelected(null)}
        >
          {width <= 575 && <FontAwesomeIcon icon={faArrowLeft}/>}
          <input
              type="text"
              placeholder="Option name here"
              name="name"
              className="option_details_input option_details_title option_title_input_new ml-1 mb-0 ml-md-0"
              onChange={(e: any) => setOption({...option, name: e.target.value})}
              style={{
                border:
                    emptyFields && emptyFields.includes("name")
                        ? "1px solid #ff00005e"
                        : "1px solid #cececea9",
              }}
          />
        </div>
        <div className="col-7 mt-3 one_option_details">
          <Radio.Group
              name="radiogroup"
              defaultValue={2}
              onChange={(e: any) =>
                  setOption({...option, isSimple: e.target.value})
              }
              buttonStyle="solid"
          >
            <Radio.Button value={2}>Sample</Radio.Button>
            <Radio.Button
                value={1}
                style={{width: "100px", textAlign: "center"}}
            >
              3D
            </Radio.Button>
          </Radio.Group>
        </div>

        {keys.map((el: any, i: number) => (
            <div className="row one_option_details" key={i}>
              <div className="col-12">
                <label className="my-2 label_option_direct_order" style={{textTransform: "capitalize"}}>
                  {el === "qty" ? "Quantity" : el === "style" ? "Style Name" : el}
                </label>
              </div>

              <div className="col-12 col-md-7 option_details_select_wrapper">
                {el === "wash" ? (
                    <AddNewOptionItem
                        optionKey={el}
                        selectHandleChange={selectHandleChange}
                        getSelectName={getSelectName}
                        option={option}
                        emptyFields={emptyFields}
                    />
                ) : (
                    <input
                        type="text"
                        onChange={handleChange}
                        name={el}
                        style={{
                          border:
                              emptyFields && emptyFields.includes(el)
                                  ? "1px solid #ff00005e"
                                  : "1px solid #cececea9",
                          width: "100%",
                        }}
                        className={`option_details_input`}
                        // style={{
                        //   width: "100%",
                        // }}
                    />
                )}
              </div>
              <div className="col-12 col-md-5 mt-3 mt-md-0">
                {i <= 2 && (
                    <DropZoneFile
                        setAlerts={setAlerts}
                        attachFile={attachFile}
                        detachFile={detachFile}
                        optionKey={el}
                        files={files}
                    />
                )}
              </div>
            </div>
        ))}

        {/*  <>
        {inputList.map((el: any, i: number) => (
          <div className="row ">
            <div className="col-4 mt-3">
              {" "}
              <input
                type="text"
                className={`option_details_input`}
                placeholder="size"
              />
            </div>
            <div className="col-4 mt-3">
              {" "}
              <input
                type="text"
                className={`option_details_input`}
                placeholder="qty"
              />
            </div>
            <div className="col-4 mt-3">
              {inputList.length !== 1 && (
                <button
                  onClick={() => removeInput(i)}
                  className="add_new_article_direct_order"
                >
                  remove
                </button>
              )}
            </div>
          </div>
        ))}

        <button
          onClick={addInput}
          className=" option_details_btns  option_save "
        >
          add
        </button>
      </> */}

        {/*    <div className="col-7 mt-3 one_option_details">
        <label className="my-2">SIZE 'S QUANTITY</label>
        <input
          type="text"
          className={`option_details_input`}
          onChange={(e: any) =>
            setOption({ ...option, size_qty: e.target.value })
          }
          placeholder="size:qty"
        />
      </div>

      <div className="col-7 mt-3 one_option_details">
        <label className="my-2">Length</label>
        <input
          type="text"
          className={`option_details_input`}
          onChange={(e: any) =>
            setOption({ ...option, crotch: e.target.value })
          }
          placeholder="28"
        />
      </div>
      <div className="col-7 mt-3 one_option_details">
        <label className="my-2">Delivery date</label>
        <input
          type="date"
          className={`option_details_input`}
          onChange={(e: any) =>
            setOption({ ...option, delivery_date: e.target.value })
          }
        />
      </div> */}
        <div
            className="option_details_btns my-5 my-md-0 mt-md-5 ml-5 "
            style={{marginRight: "5%"}}
        >
          <button
              className="option_btn option_save  mr-3"
              onClick={() => {
                if (
                    !option.name
                    /*  !option.style ||
                    !option.fabric */
                    //  !option.qty
                ) {
                  const optionKeys = ["name"].filter(
                      (el: any) => option[el] === ""
                  );

                  setEmptyFields(optionKeys);
                  setTimeout(() => {
                    setEmptyFields([]);
                  }, 4000);
                  notification.error({
                    placement: "bottomLeft",
                    bottom: 50,
                    duration: 3,
                    message: "",
                    description: "Please fill in the required fields",
                    className: "danger_notif",
                    icon: <CloseCircleOutlined/>,
                  });
                } else {
                  let formData = new FormData();
                  // formData.append("details", JSON.stringify(data));
                  Object.keys(files)
                      .filter((el: any) => files[el])
                      .map((el: any) => formData.append(el, files[el]));
                  Object.keys(option)
                      .filter((el: any) => option[el])
                      .map((el: any) => formData.append(el, option[el]));

                  formData.append("order_article_id", selectedArticle);
                  console.log(option);
                  dispatch(createNewOption(formData));

                  dispatch(
                      createNewOption({
                        style: option.style,
                        isSimple: option.isSimple,
                        fabric: option.fabric,
                        trims: option.trims,
                        thread: option.thread,
                        qty: 1,
                        order_article_id: selectedArticle,
                        style_file: option.style_file,
                        fabric_file: option.fabric_file,
                        wash_file: option.wash_file,
                        trims_file: option.trims_file,
                        thread_file: option.thread_file,
                        name: option.name + " -copy",
                      })
                  );

                  // dispatch(clearSelectedOption());
                  setEmptyFields([]);
                  // setOption({});
                }
              }}
          >
            {updateOptionLoading ? (
                <Spin indicator={antIcon}/>
            ) : (
                <>Save & duplicate</>
            )}
          </button>
          <button
              className="option_btn option_save main_option "
              onClick={() => {
                if (
                    !option.name
                    /*  !option.style ||
                    !option.fabric */
                    //  !option.qty
                ) {
                  const optionKeys = ["name"].filter(
                      (el: any) => option[el] === ""
                  );

                  setEmptyFields(optionKeys);
                  setTimeout(() => {
                    setEmptyFields([]);
                  }, 4000);
                  notification.error({
                    placement: "bottomLeft",
                    bottom: 50,
                    duration: 3,
                    message: "",
                    description: "Please fill in the required fields",
                    className: "danger_notif",
                    icon: <CloseCircleOutlined/>,
                  });
                } else {
                  let formData = new FormData();
                  // formData.append("details", JSON.stringify(data));
                  Object.keys(files)
                      .filter((el: any) => files[el])
                      .map((el: any) => formData.append(el, files[el]));
                  Object.keys(option)
                      .filter((el: any) => option[el])
                      .map((el: any) => formData.append(el, option[el]));

                  formData.append("order_article_id", selectedArticle);
                  dispatch(createNewOption(formData));
                  // dispatch(clearSelectedOption());
                  setEmptyFields([]);
                  // setOption({});
                }
              }}
          >
            {updateOptionLoading ? <Spin indicator={antIcon}/> : <>Save </>}
          </button>
        </div>
      </motion.div>
  );
};
