function ShoppingCart() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 28 28"
    >
      <path
        fill="#182844"
        d="M12.938-12H9.52l-6.038-7.1a1.125 1.125 0 00-1.582-.132 1.125 1.125 0 00-.129 1.586L6.567-12H-6.567l4.8-5.646a1.125 1.125 0 00-.133-1.586 1.125 1.125 0 00-1.586.129L-9.52-12h-3.417a.563.563 0 00-.562.563v1.125a.563.563 0 00.563.563h.875l1.293 9.309A2.25 2.25 0 00-8.541 1.5H8.541A2.25 2.25 0 0010.77-.441l1.292-9.309h.875a.563.563 0 00.563-.562v-1.125a.563.563 0 00-.562-.563zM8.541-.75H-8.541l-1.25-9H9.791zM1.125-7.125A1.125 1.125 0 000-8.25a1.125 1.125 0 00-1.125 1.125v3.75A1.125 1.125 0 000-2.25a1.125 1.125 0 001.125-1.125zm5.25 0A1.125 1.125 0 005.25-8.25a1.125 1.125 0 00-1.125 1.125v3.75A1.125 1.125 0 005.25-2.25a1.125 1.125 0 001.125-1.125zm-10.5 0A1.125 1.125 0 00-5.25-8.25a1.125 1.125 0 00-1.125 1.125v3.75A1.125 1.125 0 00-5.25-2.25a1.125 1.125 0 001.125-1.125z"
        data-name="Tracé 3315"
        transform="translate(13.5 19.5)"
      ></path>
    </svg>
  );
}

export default ShoppingCart;
