import axiosInstance from "../../config/axios-instance";

// Get all orders
const getAllOrders = (status: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/direct_orders?status=${status}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get One Order
const getOneOrder = (id: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/direct_orders/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// ADD NEW Order
const addOrder = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: "/direct_orders",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Delete Order
const deleteOrder = (id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "delete",
    url: `/direct_orders/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Update Order
const updateOrder = (data: any, id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "put",
    url: `/direct_orders/${id}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Update Option
const updateOption = (data: any, id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/article_options/${id}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Update Option
const updateDirectProduct = (data: any, id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/article_options/updateDirectProduct/${id}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};



// Get One Option
const getOption = (id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/article_options/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Delete One Option
const deleteOption = (id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "delete",
    url: `/article_options/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// relauch one Option

const relauchOption = (id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/direct_orders/relaunch/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Duplicate Option
const createNewOption = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/article_options`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createNewDirectProduct = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/article_options/addDirectProduct/`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createNewClientProduct = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/article_options/addProductClient/`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};




// Delete Article
const deleteArticle = (id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "delete",
    url: `/order_articles/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Delete Option Files
const deleteOptionFile = (id: number, data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/article_options/${id}/delete/files`,
    data: {
      files: data,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Update Article
const updateArticle = (data: any, id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "put",
    url: `/order_articles/${id}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Create Article
const createArticle = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/order_articles`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get All Collaborator With Name
const getCollaborators = (name: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/collaborators?name=${name}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Add Collaborator
const addCollaborator = (orderId: number, collaborators: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/direct_orders/${orderId}/collaborators/invite`,
    data: {
      collaborators,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get Order's Collaborator
const getOrderCollaborators = (orderId: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/direct_orders/${orderId}/collaborators`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Delete Collaborator
const deleteCollaborator = (orderId: number, ids: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/direct_orders/${orderId}/collaborators/update`,
    data: {
      collaborators: ids,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get Status
const getStatus = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/article_statuses`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Add Feedback
const addFeedback = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/order_articles/feedbacks`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Add Comment
const addComment = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/order_articles/comments`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get One Article
const getOneArticle = (articleId: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/order_articles/${articleId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Send Access Request
const sendAccessRequest = (direct_order_id: number, data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/direct_orders/${direct_order_id}/collaborators/request`,
    data: { client_id: data },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Accept Access Request
const acceptAccessRequest = (direct_order_id: number, client_id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/direct_orders/${direct_order_id}/collaborators/${client_id}/accept`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Decline Access Request
const declineAccessRequest = (direct_order_id: number, client_id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/direct_orders/${direct_order_id}/collaborators/${client_id}/decline`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


// Send Access Request
const sendFolderAccessRequest = (folder_id: number, data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/folders/${folder_id}/collaborators/request`,
    data: { client_id: data },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Accept Access Request
const acceptFolderAccessRequest = (folder_id: number, client_id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/folders/${folder_id}/collaborators/${client_id}/accept`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Decline Access Request
const declineFolderAccessRequest = (folder_id: number, client_id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/folders/${folder_id}/collaborators/${client_id}/decline`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Create Normal Order
const createNormalOrder = (
  arrayProducts: any,
  collection_name?: string,
  date_delivery?: string,
  collaborator?: any,
  saison?: string,
  year?: string,
  gender?: string,
  theme?: string,
  orderDetails?: string
) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/orders`,
    data: {
      products: arrayProducts,
      collection_name,
      date_delivery,
      collaborator,
      saison,
      year,
      gender,
      theme,
      orderDetails,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get Normal Orders
const getNormalOrder = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/orders`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


const getHeroHome = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/home/hero`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get One Normal Order
const getOneNormalOrder = (id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/orders/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Add Normal Order Comment
const addNormalOrderComment = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/orders/comments`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Add Normal Order Feedback
const addNormalOrderFeedback = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/orders/feedbacks`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Change Status of normal order
const changeStatusNormalOrder = (
  order_id: number,
  product_id: number,
  status: number
) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/orders/${order_id}/products/${product_id}/status`,
    data: {
      status_id: status,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Create Customized Product
const createCustomizedProduct = (data: any, isUpdate: boolean, id?: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: isUpdate
      ? `/customized_products/${id}/update`
      : `/customized_products`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Order Customized Product
const orderCustomizedProduct = (product_id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/customized_products/${product_id}/order`,
    // data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Save Customized Product to a folder
const saveCustomizedProduct = (folder_id: number, product_id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/customized_products/${product_id}/save`,
    data: {
      folder_id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// update product in normal order
const updateNormalOrderProduct = (data: any, order_id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/orders/${order_id}`,
    data: { ...data, _method: "patch" },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get all orders commercial
const getOrdersCommerical = (company_id: string, page: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/admin/direct_orders/companies/${company_id}?status=waiting&page=${page}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get all orders commercial
const getOneDOrderCommerical = (do_id: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/direct_orders/${do_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const orderNewCustomizedProduct = (ids: Array<number>) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/customized_products/order`,
    data: {
      customized_product_ids: ids,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Create Normal Order form my selection
export const createNormalOrderMySelection = (
  arrayProducts: any,
  collection_name?: string,
  date_delivery?: string,
  saison?: string,
  year?: string,
  gender?: string,
  theme?: string,
  orderDetails?: string
) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/orders`,
    data: {
      products: arrayProducts,
      collection_name,
      date_delivery,
      saison,
      year,
      gender,
      theme,
      orderDetails,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteNormalOrderMySelection = (ids: Array<number>) => {
  let token = localStorage.getItem("token");
  console.log(ids);
  return axiosInstance({
    method: "POST",
    url: `/myselections/remove`,
    data: {
      product_selected_ids: ids,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteCustomizedProduct = (ids: Array<number>) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "delete",
    url: `/customized_products/deleteDraft`,
    data: {
      customized_product_ids: ids,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateCustomizedProduct = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "put",
    url: `/customized_products/${data.id}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const OrderServices = {
  getAllOrders,
  updateOrder,
  updateOption,
  getOption,
  deleteOption,
  createNewOption,
  deleteArticle,
  updateArticle,
  createArticle,
  deleteOptionFile,
  relauchOption,
  addOrder,
  deleteOrder,
  getCollaborators,
  addCollaborator,
  getOrderCollaborators,
  deleteCollaborator,
  getOneOrder,
  getStatus,
  addFeedback,
  addComment,
  getOneArticle,
  sendAccessRequest,
  acceptAccessRequest,
  declineAccessRequest,
  createNormalOrder,
  getNormalOrder,
  getOneNormalOrder,
  addNormalOrderComment,
  addNormalOrderFeedback,
  changeStatusNormalOrder,
  createCustomizedProduct,
  orderCustomizedProduct,
  saveCustomizedProduct,
  updateNormalOrderProduct,
  getOrdersCommerical,
  getOneDOrderCommerical,
  createNewDirectProduct,
  createNewClientProduct,
  sendFolderAccessRequest,
  acceptFolderAccessRequest,
  declineFolderAccessRequest,
  updateDirectProduct,
  getHeroHome
};

export default OrderServices;
