import { useEffect, useState } from "react";
import {
  Drawer,
  Select,
  TimePicker,
  Input,
  Button,
  Typography,
  Alert,
  DatePicker,
} from "antd";
import moment from "moment";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import axiosInstance from "../../config/axios-instance";
import { MyNotif } from "../NotifIcons/NotifIcons";
import Client from "../AllClients/Client";

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

export default function DrawerBooking({
  editMode,
  onClose,
  visible,
  selectedDate,
  loadingCompanies,
  handleCompany,
  companies,
  company,
  handleClient,
  loadingClients,
  clients,
  collaborators,
  onChangeStartTime,
  onChangeEndTime,
  onChangeTimeZone,
  onChangeSubject,
  onChangeLink,
  setSubject,
  setTimeZone,
  setlink,
  link,
  subject,
  setNotes,
  notes,
  mutateCreateBooking,
  createBookingError,
  createLoading,
  selectedEvent,
  setSelectedEvent,
  setVisible,
  refetch,
  duration,
  timeZone,
  selectedClient,
  collaboratorsCommercial,
  onChangeCollaboratorsCommercial,
  onChangeTeams,
  teams,
  selectedDate2,
  onChangeStartTime2,
}: any) {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [rescheduleIt, setRescheduleIt] = useState(false);
  const [status, setStatus] = useState(false);
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const [data, setData] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);

  const [location, setLocation] = useState<any>(null);

  const format = "HH:mm";

  console.log(teams);

  const onChangeLocation = (value: any) => {
    console.log(value);
    setLocation(value);
  };
  useEffect(() => {
    if (selectedEvent) {
      setData(selectedEvent.start);
      setEnd(new Date(selectedEvent.details.date2).toISOString());
      // handleCompany(selectedEvent?.company.id);
      setStatus(selectedEvent?.details?.status === "accepted" ? true : false);
    }
  }, [visible, selectedEvent, end]);

  useEffect(() => {
    if (status) {
      handleCompany(selectedEvent?.company?.id);
      setIsConfirmed(
        selectedEvent?.details?.status === "accepted" ? true : false
      );
    } else {
      setIsConfirmed(false);
    }
  }, [status]);

  const verifyLastUpdatedBy = () => {
    if (user && isAdmin) {
      return "client";
    } else {
      return "demco";
    }
  };

  const updateMeeting = async (type: string) => {
    let token = localStorage.getItem("token");
    if (type === "cancel") {
      await axiosInstance({
        method: "delete",
        url: `/admin/bookings/${selectedEvent.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else if (type === "confirm") {
      await axiosInstance({
        method: "post",
        url: `/admin/bookings/${selectedEvent.id}`,
        data: {
          status: "accepted",
          client_id: selectedEvent.details.client.id,
          _method: "patch",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      await axiosInstance({
        method: "post",
        url: `/admin/bookings/${selectedEvent.id}`,
        data: {
          duration: selectedEvent.duration,
          // date: new Date(selectedEvent.start),
          date: moment(data).utc(),
          date2: moment(end).utc(),
          notes: selectedEvent.notes,
          subject: selectedEvent.subject,
          status: selectedEvent.details.status === "new" ? "new" : "waiting",
          client_id: selectedEvent.details.client_id,
          _method: "patch",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    MyNotif(
      type === "cancel"
        ? "Meeting cancelled successfully."
        : "Meeting updated!",
      "success"
    );
    // setVisible(false);
    setIsConfirmed(false);
    setSelectedEvent(null);
    setRescheduleIt(false);
    onClose();
    refetch();
  };

  const {
    mutate: mutateUpdateBooking,
    isLoading: updateLoading,
    variables,
  } = useMutation((type: string) => updateMeeting(type));

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  return (
    <Drawer
      placement={"right"}
      closable={true}
      onClose={() => {
        setIsConfirmed(false);
        setSelectedEvent(null);
        setTimeout(() => {
          onClose();
        }, 200);
      }}
      visible={visible}
      key={"right"}
      width={width >768 ? "400" : "300"}
      className="booking-drawer"
      maskClosable={false}
    >
      <div className="left-calendar-container">
        <h1 className="h1-blue-hj title-drawer-booking" style={{ marginTop: "20px" }}>
          Meeting details
        </h1>
        <p className={"label-booking"}>
          Selected Date:{" "}
          <strong>
            {moment(
              editMode && selectedEvent ? selectedEvent.start : selectedDate
            ).format("MMMM, dddd Do YYYY")}
          </strong>
        </p>
        {user && isAdmin && (
          <>
            <h3 className="h2-blue-hj label-booking">Select Company</h3>
            <Select
              disabled={
                // isConfirmed || (selectedEvent && !selectedEvent.details.user)
                isConfirmed || editMode
              }
              className="w-100 label-booking"
              loading={loadingCompanies}
              onChange={handleCompany}
              value={company || (selectedEvent && selectedEvent?.company?.id)}
              placeholder="Select company"
            >
              {companies &&
                companies.data.data.map((company: any) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
            </Select>
          </>
        )}
        {((editMode &&
          selectedEvent &&
          selectedEvent?.company?.id &&
          isAdmin) ||
          (company && isAdmin)) && (
          <>
            <h3 className="h2-blue-hj label-booking">Select Client</h3>
            <Select
              disabled={
                // isConfirmed || (selectedEvent && !selectedEvent.details.user)
                isConfirmed || editMode
              }
              className="w-100 label-booking"
              onChange={handleClient}
              loading={loadingClients}
              value={
                selectedClient || (selectedEvent && selectedEvent.client.name)
              }
              placeholder="Select client"

            >
              {clients &&
                clients.map((client: any) => (
                  <Option key={client.id} value={client.id}>
                    {client.name}
                  </Option>
                ))}
            </Select>
          </>
        )}

        {selectedEvent && editMode && rescheduleIt && !isConfirmed && (
          <>
            <h3 className="h2-blue-hj label-booking">Select Date</h3>
            <DatePicker
              disabled={isConfirmed || !rescheduleIt}
              defaultValue={moment(selectedEvent.start)}
              showToday={false}
              disabledDate={function disabledDate(current: any) {
                // Can not select days before today and today
                return (
                  (current &&
                    current <= moment().subtract(1, "d").endOf("day")) ||
                  ["Saturday", "Sunday"].find(
                    (date) => date === moment(current).format("dddd")
                  )
                );
              }}
              onChange={(x: any) =>
                setData(
                  moment(x)
                    .set({
                      hour: moment(data).get("hour"),
                      minute: moment(data).get("minute"),
                      second: moment(data).get("second"),
                    })
                    .utc()
                    .format()
                )
              }
              style={{ width: "100%" }}
            />
          </>
        )}
        <h3 className="h2-blue-hj label-booking">Start meeting time</h3>
        {editMode ? (
          <>
            <TimePicker
              disabled={isConfirmed || (editMode && !rescheduleIt)}
              style={{ width: "100%" }}
              onChange={(value: any) => onChangeStartTime(value.utc(), true)}
              value={selectedEvent && moment(selectedEvent.start).utc()}
              showSecond={false}
              disabledHours={() => [
                0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23,
              ]}
              hideDisabledOptions
              minuteStep={15}
              format={format}
              placeholder="08:00"
              className={"label-booking"}
            />
          </>
        ) : (
          <>
            <TimePicker
              disabled={isConfirmed || (editMode && !rescheduleIt)}
              style={{ width: "100%" }}
              onChange={(value: any) => onChangeStartTime(value, false)}
              showSecond={false}
              disabledHours={() => [
                0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23,
              ]}
              hideDisabledOptions
              minuteStep={15}
              format={format}
              placeholder="08:00"
              className={"label-booking"}
            />
          </>
        )}

        <h3 className="h2-blue-hj label-booking">End meeting time</h3>
        {editMode ? (
          <>
            <TimePicker
              disabled={isConfirmed || (editMode && !rescheduleIt)}
              style={{ width: "100%" }}
              onChange={(value: any) => onChangeStartTime2(value.utc(), true)}
              value={selectedEvent && moment(end)}
              showSecond={false}
              disabledHours={() => [
                0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23,
              ]}
              hideDisabledOptions
              minuteStep={15}
              format={format}
              placeholder="09:00"
              className={"label-booking"}
            />
          </>
        ) : (
          <>
            <TimePicker
              disabled={isConfirmed || (editMode && !rescheduleIt)}
              style={{ width: "100%" }}
              onChange={(value: any) => {
                onChangeStartTime2(value, false);
              }}
              showSecond={false}
              disabledHours={() => [
                0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23,
              ]}
              hideDisabledOptions
              minuteStep={15}
              format={format}
              placeholder="09:00"
              className={"label-booking"}
            />
          </>
        )}

        <h3 className="h2-blue-hj label-booking">Time Zone</h3>
        <Select
          disabled={isConfirmed || editMode}
          style={{ width: "100%" }}
          onChange={(value: any) => onChangeTimeZone(value, editMode)}
          value={
            editMode && selectedEvent
              ? selectedEvent.details.timezone
              : timeZone
          }
          placeholder="Select TimeZone"
          className={"label-booking"}
        >
          <option value="-12">(GMT -12:00) Eniwetok, Kwajalein</option>
          <option value="-11">(GMT -11:00) Midway Island, Samoa</option>
          <option value="-10">(GMT -10:00) Hawaii</option>
          <option value="-09">(GMT -9:00) Alaska</option>
          <option value="-08">
            (GMT -8:00) Pacific Time (US &amp; Canada)
          </option>
          <option value="-07">
            (GMT -7:00) Mountain Time (US &amp; Canada)
          </option>
          <option value="-06">
            (GMT -6:00) Central Time (US &amp; Canada), Mexico City
          </option>
          <option value="-05">
            (GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima
          </option>
          <option value="-04">
            (GMT -4:00) Atlantic Time (Canada), Caracas, La Paz
          </option>
          <option value="-03">
            (GMT -3:00) Brazil, Buenos Aires, Georgetown
          </option>
          <option value="-02">(GMT -2:00) Mid-Atlantic</option>
          <option value="-01">(GMT -1:00) Azores, Cape Verde Islands</option>
          <option value="+00" selected>
            (GMT) Western Europe Time, London, Lisbon, Casablanca
          </option>
          <option value="+01">
            (GMT +1:00) Brussels, Copenhagen, Madrid, Paris
          </option>
          <option value="+02">(GMT +2:00) Kaliningrad, South Africa</option>
          <option value="+03">
            (GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg
          </option>
          <option value="+04">
            (GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi
          </option>
          <option value="+05">
            (GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent
          </option>
          <option value="+06">(GMT +6:00) Almaty, Dhaka, Colombo</option>
          <option value="+07">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
          <option value="+08">
            (GMT +8:00) Beijing, Perth, Singapore, Hong Kong
          </option>
          <option value="+09">
            (GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk
          </option>
          <option value="+10">
            (GMT +10:00) Eastern Australia, Guam, Vladivostok
          </option>
          <option value="+11">
            (GMT +11:00) Magadan, Solomon Islands, New Caledonia
          </option>
          <option value="+12">
            (GMT +12:00) Auckland, Wellington, Fiji, Kamchatka
          </option>
          <option value="+13">(GMT +13:00) Apia, Nukualofa</option>
          <option value="+14">(GMT +14:00) Line Islands, Tokelau</option>
        </Select>

        {/*   <h3 className="h2-blue-hj">Duration</h3>
        <Select
          disabled={isConfirmed || (editMode && !rescheduleIt)}
          style={{ width: "100%" }}
          onChange={(value: any) => onChangeEndTime(value, editMode)}
          value={editMode && selectedEvent ? selectedEvent.duration : duration}
          placeholder="Select duration"
        >
          <Option value="1">One hour</Option>
          <Option value="1.5">One and half hour</Option>
          <Option value="2">Two hours</Option>
        </Select>  */}

        <h3 className="h2-blue-hj label-booking">Your meeting Subject</h3>
        <Input
          placeholder="Subject Meeting"
          disabled={isConfirmed || editMode}
          onChange={(e: any) =>
            editMode && selectedEvent
              ? setSelectedEvent({ ...selectedEvent, subject: e.target.value })
              : setSubject(e.target.value)
          }
          value={editMode && selectedEvent ? selectedEvent.subject : subject}
          className={"label-booking"}
        />

        <h3 className="h2-blue-hj label-booking">Location</h3>
        <Select
          style={{ width: "100%" }}
          onChange={(value: any) => onChangeLocation(value)}
          placeholder="Select Location"
          disabled={isConfirmed || editMode}
          // defaultValue={"Meeting Room"}
            className={"label-booking"}
        >
          <Option value="1" key={1}>
            Meerting Room
          </Option>
          <Option value="2" key={2} selected={true}>
            VC Link
          </Option>
        </Select>
        {location == 2 ? (
          <>
            <h3 className="h2-blue-hj label-booking">VC Link</h3>
            <Input
              placeholder="Google Meet , Zoom"
              disabled={isConfirmed || editMode}
              onChange={(e: any) =>
                editMode && selectedEvent
                  ? setSelectedEvent({ ...selectedEvent, link: e.target.value })
                  : setlink(e.target.value)
              }
              value={
                editMode && selectedEvent ? selectedEvent.details.link : link
              }
            />
          </>
        ) : (
          <>
            <h3 className="h2-blue-hj label-booking">Meeting Room</h3>
            <Input
              placeholder="Room ..."
              disabled={isConfirmed || editMode}
              onChange={(e: any) =>
                editMode && selectedEvent
                  ? setSelectedEvent({ ...selectedEvent, link: e.target.value })
                  : setlink(e.target.value)
              }
              value={
                editMode && selectedEvent ? selectedEvent.details.link : link
              }
            />
          </>
        )}
        {/*  <Select
          disabled={
            isConfirmed || editMode
            // || (selectedEvent && !selectedEvent.details.user)
          }
          showSearch
          // size="large"
          style={{ width: "100%" }}
          placeholder="Select your meeting Subject"
          optionFilterProp="children"
          onChange={(value: any) => onChangeSubject(value, editMode)}
          value={editMode && selectedEvent ? selectedEvent.subject : subject}
          filterOption={(input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Select.Option value="ID presentation">ID presentation</Select.Option>
          <Select.Option value="Dedicated development presentation">
            Dedicated development presentation
          </Select.Option>
          <Select.Option value="PROTO">PROTO</Select.Option>
          <Select.Option value="PRE SMS">PRE SMS</Select.Option>
          <Select.Option value="SMS">SMS</Select.Option>
          <Select.Option value="OK PROD">OK PROD</Select.Option>
          <Select.Option value="PRODUCTION">PRODUCTION</Select.Option>
        </Select> */}
        {user && !isAdmin && (
          <>
            <h3 className="h2-blue-hj label-booking">Select Collaborators</h3>
            <Select
              /*    disabled={
              // isConfirmed || (selectedEvent && !selectedEvent.details.user)
              isConfirmed || editMode
            } */
              mode="multiple"
              className="w-100 label-booking"
              onChange={handleClient}
              /* loading={loadingClients} */
              /*  value={
              selectedClient || (selectedEvent && selectedEvent.client.name)
            } */
              placeholder="e.g Alison"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {collaborators &&
                collaborators.map((collaborator: any) => (
                  <Option key={collaborator.id} value={collaborator.id}>
                    {collaborator.name}
                  </Option>
                ))}
            </Select>
          </>
        )}

        {/*     {user && isAdmin && company && (
          <>
            <h3 className="h2-blue-hj">Select Collaborators</h3>
            <Select
              disabled={
                // isConfirmed || (selectedEvent && !selectedEvent.details.user)
                isConfirmed || editMode
              }
              mode="multiple"
              className="w-100"
              onChange={(value: any) =>
                onChangeCollaboratorsCommercial(value, editMode)
              }
              placeholder="e.g Alison"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {collaboratorsCommercial &&
                collaboratorsCommercial.map((collaborator: any) => (
                  <Option key={collaborator.id} value={collaborator.id}>
                    {collaborator.name}
                  </Option>
                ))}
            </Select>
          </>
        )} */}

        {user && isAdmin && company && (
          <>
            <h3 className="h2-blue-hj">Select Teams</h3>
            <Select
              disabled={
                // isConfirmed || (selectedEvent && !selectedEvent.details.user)
                isConfirmed || editMode
              }
              mode="multiple"
              className="w-100"
              onChange={(value: any) => onChangeTeams(value, editMode)}
              placeholder="e.g Alison"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {teams &&
                teams?.map((collaborator: any) => (
                  <Option key={collaborator.id} value={collaborator.id}>
                    {collaborator.name}
                  </Option>
                ))}
            </Select>
          </>
        )}

        <h3 className="h2-blue-hj label-booking">Add your request</h3>
        <TextArea
          rows={4}
          disabled={
            isConfirmed || editMode
            // || (selectedEvent && !selectedEvent.details.user)
          }
          onChange={(e: any) =>
            editMode && selectedEvent
              ? setSelectedEvent({ ...selectedEvent, notes: e.target.value })
              : setNotes(e.target.value)
          }
          value={editMode && selectedEvent ? selectedEvent.notes : notes}
          className="mb-3 label-booking"
        />
        {
          selectedEvent?.details?.updated_by === verifyLastUpdatedBy() &&
            !isConfirmed &&
            !rescheduleIt && (
              // (!meet.details.user && meet.status === "new" && (
              <Button
                // type="button"
                disabled={variables === "cancel"}
                className="mt-1 reschedule-btn-drawer confirm w-100"
                onClick={() => mutateUpdateBooking("confirm")}
              >
                {variables === "confirm" && updateLoading
                  ? "Confirming..."
                  : "Confirm Meeting"}
              </Button>
            )
          // ))
        }
        {!isConfirmed && !rescheduleIt && selectedEvent && (
          // !(
          //   user.id === meet.details.client_id &&
          //   meet.details.status === "waiting"
          // ) &&
          <Button
            // type="button"
            // disabled={!data}
            className={`reschedule-btn-drawer discard w-100 mt-3`}
            // onClick={() => mutateUpdateBooking("waiting")}
            onClick={() => {
              if (!rescheduleIt) {
                setRescheduleIt(true);
              } else {
                mutateUpdateBooking("waiting");
              }
            }}
          >
            {variables === "waiting" && updateLoading
              ? "Rescheduling..."
              : "Reschedule Meeting"}
          </Button>
        )}
        {!isConfirmed && !rescheduleIt && selectedEvent && (
          <Button
            type="link"
            className="d-flex justify-content-center mx-auto mt-3"
            onClick={() => mutateUpdateBooking("cancel")}
          >
            {variables === "cancel" && updateLoading
              ? "Cancelling..."
              : "Cancel Meeting"}
          </Button>
        )}
        {isConfirmed && (
          <Button
            className="reschedule-btn-drawer confirm w-100"
            onClick={() => mutateUpdateBooking("cancel")}
          >
            {variables === "cancel" && updateLoading
              ? "Cancelling..."
              : "Cancel Meeting"}
          </Button>
        )}
        {rescheduleIt && (
          <div style={{ display: "flex", width: "100%" }}>
            <Button
              className="reschedule-btn-drawer discard"
              onClick={() => {
                setIsConfirmed(false);
                setSelectedEvent(null);
                setRescheduleIt(false);
                setTimeout(() => {
                  onClose();
                }, 200);
              }}
            >
              Discard
            </Button>
            <Button
              style={{
                marginLeft: "1rem",
              }}
              className="reschedule-btn-drawer confirm"
              onClick={() => {
                if (!rescheduleIt) {
                  setRescheduleIt(true);
                } else {
                  mutateUpdateBooking("waiting");
                }
              }}
              loading={updateLoading && variables === "waiting"}
            >
              {variables === "waiting" && updateLoading
                ? "Rescheduling..."
                : "Confirm"}
            </Button>
          </div>
        )}

        {!selectedEvent && (
          <Button
            onClick={
              editMode
                ? () => mutateUpdateBooking("update")
                : () => mutateCreateBooking(data)
            }
            // type="button"
            className="reschedule-btn-drawer confirm w-100"
            loading={updateLoading}
          >
            {editMode
              ? variables === "update" && updateLoading
                ? "Updating..."
                : "Update Meeting"
              : createLoading
              ? "Loading..."
              : "Book now"}
          </Button>
        )}
        {/* {!isConfirmed ? (
          <>
            <button
              onClick={() =>
                editMode ? mutateUpdateBooking("update") : mutateCreateBooking()
              }
              type="button"
              className="submit-booking-btn"
            >
              {editMode
                ? variables === "update" && updateLoading
                  ? "Updating..."
                  : "Update Meeting"
                : createLoading
                ? "Loading..."
                : "Book now"}
            </button>
            {editMode && (
              <Button
                type="link"
                className="d-flex justify-content-center mx-auto"
                onClick={() => mutateUpdateBooking("cancel")}
              >
                {variables === "cancel" && updateLoading
                  ? "Cancelling..."
                  : "Cancel Meeting"}
              </Button>
            )}
          </>
        ) : (
          <button
            className="d-flex justify-content-center mx-auto submit-booking-btn mt-3"
            onClick={() => mutateUpdateBooking("cancel")}
          >
            {variables === "cancel" && updateLoading
              ? "Cancelling..."
              : "Cancel Meeting"}
          </button>
        )} */}
        {createBookingError &&
          Object.keys(createBookingError.response.data.errors).map(
            (error: any, i: number) => (
              <Alert
                key={i}
                message={createBookingError.response.data.errors[error]}
                type="error"
                showIcon
                style={{
                  borderRadius: "5px",
                }}
              />
            )
          )}
      </div>
    </Drawer>
  );
}
