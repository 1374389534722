function Library() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 28 28"
    >
      <path
        fill="#182844"
        d="M9-1.5H6.75v.469a.281.281 0 01-.281.281h-17.438a.281.281 0 01-.281-.281v-11.438a.281.281 0 01.281-.281h.469V-15h-.75a2.25 2.25 0 00-2.25 2.25v12a2.25 2.25 0 002.25 2.25h18A2.25 2.25 0 009-.75zm1.969-15.75a.281.281 0 01.281.281v11.438a.281.281 0 01-.281.281H-6.469a.281.281 0 01-.281-.281v-11.438a.281.281 0 01.281-.281zm.281-2.25h-18A2.25 2.25 0 00-9-17.25v12A2.25 2.25 0 00-6.75-3h18a2.25 2.25 0 002.25-2.25v-12a2.25 2.25 0 00-2.25-2.25zm-12.375 5.25A1.875 1.875 0 00-3-16.125a1.875 1.875 0 00-1.875 1.875A1.875 1.875 0 00-3-12.375a1.875 1.875 0 001.875-1.875zM-4.5-9.75v2.25H9v-3.75L5.648-14.6a.563.563 0 00-.8 0L0-9.75l-1.852-1.85a.563.563 0 00-.8 0z"
        data-name="Tracé 3320"
        transform="translate(13.5 19.5)"
      ></path>
    </svg>
  );
}

export default Library;
