import { Pagination } from "antd";
import React from "react";
import { ArticlesTableRow } from "./ArticlesTableRow";

interface Props {
  filteredOrders: any;
  handleChange: any;
  orderType: any;
  setAlerts: (x: any) => void;
}

export const CancelledArticlesTable = ({
  filteredOrders,
  handleChange,
  setAlerts,
  orderType,
}: Props) => {
  return (
    <div className="container-fluid">
      <table className="table mt-2 articles_table">
        <thead>
          <tr>
            <th scope="col">Items</th>
            <th scope="col">Sent Date</th>
            <th scope="col">Original Order</th>
            <th style={{ width: "25%" }}></th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.length !== 0 &&
            filteredOrders.map((el: any) => (
              <ArticlesTableRow
                article={el}
                key={el.id}
                setAlerts={(x: any) => setAlerts(x)}
                cancelled={true}
                orderType={
                  orderType === "direct_order"
                    ? "direct"
                    : orderType === "normal_order"
                    ? "normal"
                    : "customized"
                }
              />
            ))}
        </tbody>
        <tfoot>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={6}
            total={filteredOrders.length}
            hideOnSinglePage
            onChange={handleChange}
          />
        </tfoot>
      </table>
      {!filteredOrders.length && (
        <p className="text-center bg-white border p-3 rounded">
          No cancelled articles found
        </p>
      )}
    </div>
  );
};
