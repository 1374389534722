import React, {useEffect, useRef, useState} from "react";
import AxiosInstance from "../../config/axios-instance";
import {useSelector} from "react-redux";
import {Col, Row, Select} from "antd";


export const OrderNumber = () => {
   /* const chartRef = useRef(null);
    const [chart, setChart] = useState(null);




    useEffect(() => {
        // Load the Chart.js library if not already loaded
        const loadChartJs = () => {
            //@ts-ignore
            if (typeof Chart === 'undefined' && !document.querySelector('script#chartjs-total-script')) {
                const script = document.createElement('script');
                script.id = 'chartjs-total-script';
                script.src = 'https://cdn.jsdelivr.net/npm/chart.js';
                script.onload = () => fetchData();
                document.body.appendChild(script);
                //@ts-ignore
            } else if (typeof Chart !== 'undefined') {
                fetchData();
            }
        };

        // Fetch the chart data
        const fetchData = async () => {
            try {
                const responseOrder = await AxiosInstance.get(`/report-number-total`);
                const data = responseOrder.data; // Expected format: { regular: 100, direct: 50, custom: 25 }

                createDoughnutChart(Object.values(data), Object.keys(data));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Create the chart using the fetched data
        const createDoughnutChart = (data: any, labels: any) => {
            if (chartRef.current && data.length) {
                //@ts-ignore
                const newChart = new Chart(chartRef.current, {
                    type: 'doughnut',
                    data: {
                        labels: ['Demco Orders', 'Direct Orders','Customized Orders'],
                        datasets: [{
                            label: 'Number',
                            data: [50,30,20],
                            backgroundColor: [
                                'rgb(24,40,68)',
                                'rgb(165,171,54)',
                                'rgba(0, 70, 140, 0.10)'
                            ],
                            borderColor: [
                                'rgb(24,40,68)',
                                'rgb(165,171,54)',
                                'rgba(0, 70, 140, 0.10)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false
                    }
                });
                setChart(newChart);
            }
        };

        // Run once to load the chart
        loadChartJs();

        // Cleanup on unmount
        return () => {
            if (chart) {
                //@ts-ignore
                chart.destroy();
            }
        };
    }, []);*/

    const { user, isAdmin } = useSelector((state: any) => state.auth);

    const chartContainer = useRef(null);
    const [chartLoaded, setChartLoaded] = useState(false);
    const [chart, setChart] = useState(null);


    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState<any>(null);
    const [selectedWeek, setSelectedWeek] = useState<any>(null);
    const [weeksInSelectedMonth, setWeeksInSelectedMonth] = useState<any>([]);
    useEffect(() => {
        // Reset selected week when month is changed
        setSelectedWeek(null);

        // Calculate weeks in the selected month
        const weeks = calculateWeeksInMonth(selectedYear, selectedMonth);
        setWeeksInSelectedMonth(weeks);

        // If the month and year are selected, update the chart
        if (selectedYear && selectedMonth) {
            // Replace with actual chart update function
            updateChart('month', selectedYear, selectedMonth);
        }
    }, [selectedMonth, selectedYear]);

    const calculateWeeksInMonth = (year:any, month:any) => {
        if (!year || !month) return [];

        // Assuming you consider the first week as the one containing the 1st of the month
        const lastDayOfMonth = new Date(year, month, 0).getDate();
        const weeks = [];
        for (let i = 1; i <= lastDayOfMonth; i += 7) {
            const weekStart = new Date(year, month - 1, i);
            const weekEnd = new Date(year, month - 1, Math.min(i + 6, lastDayOfMonth));

            // Format dates as dd/mm/yyyy
            const formattedStartDate = `${weekStart.getDate()}/${weekStart.getMonth() + 1}/${weekStart.getFullYear()}`;
            const formattedEndDate = `${weekEnd.getDate()}/${weekEnd.getMonth() + 1}/${weekEnd.getFullYear()}`;

            weeks.push(`${formattedStartDate}-${formattedEndDate}`);
        }
        return weeks;
    };

    const currentYear = new Date().getFullYear();

    // Create an array of years from two years before the current year to the current year
    const years = Array.from({ length: 3 }, (_, index) => currentYear - (2 - index));

    const months = [
        { label: 'January', value: '1' },
        { label: 'February', value: '2' },
        { label: 'March', value: '3' },
        { label: 'April', value: '4' },
        { label: 'May', value: '5' },
        { label: 'June', value: '6' },
        { label: 'July', value: '7' },
        { label: 'August', value: '8' },
        { label: 'September', value: '9' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' }
    ];
    useEffect(() => {
        if (chartLoaded) {
            // load chart here as we previously described
            updateChart('month');
        }
        // Ensure to run this hook when `chartLoaded` changes
    }, [chartLoaded]); // Dependency array


    useEffect(() => {
        // Check if Chart.js script is already loaded
        //@ts-ignore
        if (typeof Chart === 'undefined' && !document.querySelector('script#chartjs-total-script')) {
            const script = document.createElement('script');
            script.src = 'https://cdn.jsdelivr.net/npm/chart.js';
            script.onload = () => setChartLoaded(true); // Set flag when script is loaded

            // Append the script to the document body
            document.body.appendChild(script);

            // Remove the script when the component unmounts
            return () => {
                document.body.removeChild(script);
            };
        } else {
            // If the script is already in the document, set the flag to true
            setChartLoaded(true);

        }
    }, []);

    const fetchData = async (timeframe:any, year:any, month:any, week:any) => {
        try {

            if(user){
                let token = localStorage.getItem("token");

                const responseOrder = await AxiosInstance.get(`/report-number-total/${year}/${month}/null?week=${week}`);
                const data = responseOrder.data; // Expected format: { regular: 100, direct: 50, custom: 25 }





                return {
                    labels: [
                        'Demco Orders',
                        'Direct Orders',
                        'Customized Orders'
                    ],
                    datasets: [
                        // Assuming the data holds a structure compatible with Chart.js datasets
                        {
                            label: 'Number',
                            data: [data.orders,  data.direct_orders, data.customized_orders],

                            backgroundColor: [
                                '#182844',
                                '#A5AB36',
                                '#7D7E72'
                            ],
                            borderWidth: 1,
                            tension: 0.2
                        },

                        // Add more datasets as needed
                    ],
                };
            }
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const updateChart = async (timeframe:any, year = selectedYear, month = null, week = null) => {
        const chartData = await fetchData(timeframe, year, month, week);
        if (chart) {
            //@ts-ignore
            chart.data.labels = chartData?.labels;
            //@ts-ignore
            chart.data.datasets = chartData?.datasets;
            //@ts-ignore
            chart.update();
        } else if (chartContainer.current) {
            //@ts-ignore
            const newChart = new Chart(chartContainer.current, {
                type: 'doughnut',
                data: chartData,
                options: {

                    responsive: true,
                    maintainAspectRatio: false,
                },
            });
            setChart(newChart);
        }
    };

    useEffect(() => {

        // updateChart('month'); // Load the default chart data on mount

        // Clean up the chart instance when the component unmounts
        return () => {
            if (chart) {
                //@ts-ignore
                chart.destroy();
            }
        };
    }, [chartLoaded, chart]);




    const handleTimeframeChange = (e:any) => {
        updateChart(e);
    };

    const handleYearChange = (year:any) => {
        setSelectedYear(year);
        setSelectedMonth(null);
        updateChart(null, year);
    };

    const handleMonthChange = (month:any) => {
        setSelectedMonth(month);
        updateChart("month", selectedYear, month);
    };
    const handleWeekChange = (week:any) => {
        setSelectedWeek(week);
        updateChart('week', selectedYear, selectedMonth, week);

    };
    <Row gutter={[8, 8]} style={{textAlign:'center'}}>
        <Col span={12}>
            <Select defaultValue={selectedYear} onChange={handleYearChange} style={{width: '100%'}}>
                {years.map((year) => (
                    <Select.Option key={year} value={year}>{year}</Select.Option>
                ))}
            </Select>
        </Col>
        <Col span={12}>
            <Select value={selectedMonth} onChange={handleMonthChange} defaultValue={"null"}  style={{width: '100%'}} placeholder="Select Month">
                <Select.Option value="null">-</Select.Option>
                {months.map((month) => (
                    <Select.Option key={month.value} value={month.value}>{month.label}</Select.Option>
                ))}
            </Select>
        </Col>
        {/* <Col span={8}>
                    <Select value={selectedWeek} onChange={handleWeekChange} defaultValue={"null"}  style={{width: '100%'}} placeholder="Select Week" disabled={!selectedYear || !selectedMonth}>
                        <Select.Option value="null">-</Select.Option>
                        {weeksInSelectedMonth.map((week:any, index:any) => (
                            <Select.Option key={index} value={week}>{week}</Select.Option>
                        ))}
                    </Select>
                </Col>*/}
    </Row>

    return (
        <div style={{textAlign:'center'}}>
            <div className={"title-chart"}>
                Total Orders
            </div>
            <Row gutter={[8, 16]} style={{textAlign:'center'}}>
                <Col span={8}>
                    <Select defaultValue={selectedYear} onChange={handleYearChange} style={{width: '100%'}}>
                        {years.map((year) => (
                            <Select.Option key={year} value={year}>{year}</Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={8}>
                    <Select value={selectedMonth} onChange={handleMonthChange} defaultValue={"null"}  style={{width: '100%'}} placeholder="Select Month">
                        <Select.Option value="null">-</Select.Option>
                        {months.map((month) => (
                            <Select.Option key={month.value} value={month.value}>{month.label}</Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={8}>
                    <Select value={selectedWeek} onChange={handleWeekChange} defaultValue={"null"}  style={{width: '100%'}} placeholder="Select Week" disabled={!selectedYear || !selectedMonth}>
                        <Select.Option value="null">-</Select.Option>
                        {weeksInSelectedMonth.map((week:any, index:any) => (
                            <Select.Option key={index} value={week}>{week}</Select.Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <div className="order">
                <canvas ref={chartContainer} width={250} height={250}/>
            </div>
        </div>
    );
}