import * as React from "react";
import AddFolder from "../AddFolder/AddFolder";
import AddToCollection from "../AddToCollection/AddToCollection";
import DeletedItems from "../DeletedItems/DeletedItems";
import { AnimatePresence } from "framer-motion";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import OutsideAlerter from "../SideMenu/OutSideAlerter";

export interface IBottomDrawerProps {
  mode: string;
  setMode?: (mode: string) => void;
  folders?: Array<any>;
  addCustomizedProduct?: boolean;
  customizedOrder?: any;
}

export default function BottomDrawer({
  mode,
  setMode,
  addCustomizedProduct,
  customizedOrder,
}: IBottomDrawerProps) {
  const stateDrawerBottom = useSelector(
    (state: RootStateOrAny) => state.drawers.openBottom
  );

  const drawerContent = () => {
    if (mode === "add_folder") {
      return <AddFolder withRoot={false} setMode={setMode} addFolder={true} />;
    } else if (mode === "add_folder_collection") {
      return <AddFolder withRoot={true} setMode={setMode} addFolder={false} />;
    } else if (mode === "add_new_article") {
      return <AddFolder addArticle={true} withRoot={false} setMode={setMode} />;
    } else if (mode === "add_new_order") {
      return (
        <AddFolder addOrderDrawer={true} withRoot={false} setMode={setMode} />
      );
    } else {
      return (
        <AddToCollection
          mode="add_to_collection"
          setMode={setMode}
          addCustomizedProduct={addCustomizedProduct}
          customizedOrder={customizedOrder}
        />
      );
    }
  };

  return (
    <AnimatePresence initial={true} exitBeforeEnter={true}>
      {/* @ts-ignore */}
      {stateDrawerBottom && <OutsideAlerter>{drawerContent()}</OutsideAlerter>}
    </AnimatePresence>
  );
}
