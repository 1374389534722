import React from "react";

export type IRedoIconProps = {};

const RedoIcon: React.FC<IRedoIconProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_244_1417)">
        <path
          d="M11.9906 0.374747C14.6625 0.374747 17.1234 1.27475 19.0922 2.78881L21.4406 0.440372C22.3781 -0.501816 24 0.163809 24 1.49975V7.49975C24 8.32943 23.3297 8.99975 22.5 8.99975H16.5C15.1641 8.99975 14.4984 7.38256 15.4406 6.44037L17.9531 3.92318C16.2516 2.66693 14.1797 1.9685 12 1.9685C6.45 1.9685 1.96875 6.4685 1.96875 11.9997C1.96875 17.5497 6.46875 22.031 12 22.031C14.5172 22.031 16.8844 21.1029 18.7078 19.4576C18.9281 19.256 19.2703 19.2701 19.4812 19.481L19.8141 19.8138C20.0437 20.0435 20.0297 20.4138 19.7906 20.6294C17.7281 22.4904 14.9953 23.6247 12 23.6247C5.5875 23.6247 0.389063 18.431 0.375 12.0232C0.360937 5.61537 5.5875 0.379434 11.9906 0.374747ZM16.5 7.49975H22.5V1.49975L16.5 7.49975Z"
          fill="#182844"
        />
      </g>
      <defs>
        <clipPath id="clip0_244_1417">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { RedoIcon };
