import {
  GET_CREATIVE_STATION_REQUEST,
  GET_CREATIVE_STATION_SUCCESS,
  GET_CREATIVE_STATION_FAIL,
  GET_MORE_NEWEST_CREATIVE_STATION_REQUEST,
  GET_MORE_NEWEST_CREATIVE_STATION_SUCCESS,
  GET_MORE_NEWEST_CREATIVE_STATION_FAIL,
  GET_MORE_TRENDING_CREATIVE_STATION_REQUEST,
  GET_MORE_TRENDING_CREATIVE_STATION_SUCCESS,
  GET_MORE_TRENDING_CREATIVE_STATION_FAIL,
  GET_MORE_FASHION_CREATIVE_STATION_REQUEST,
  GET_MORE_FASHION_CREATIVE_STATION_SUCCESS,
  GET_MORE_FASHION_CREATIVE_STATION_FAIL,
  GET_ONE_POST_REQUEST,
  GET_ONE_POST_SUCCESS,
  GET_ONE_POST_FAIL,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAIL,
} from "../actions/creative-station-actions/types";

const init_state = {
  threeDVideos: null,
  next_page_threeDVideos: false,
  trending: null,
  next_page_trending: false,
  moodboars: null,
  next_page_moodboars: false,
  single_post: null,
  isLoading: false,
  error: null,
  loadMoreNewestisLoading: false,
  loadMoreTrendingisLoading: false,
  loadMoreFashionisLoading: false,
  getOnePostLoading: false,
  onePost: null,
};

const CreativeStation = (state: any = init_state, action: any) => {
  switch (action.type) {
    case GET_ONE_POST_REQUEST:
      return {
        ...state,
        getOnePostLoading: true,
      };
    case GET_ONE_POST_SUCCESS:
      return {
        ...state,
        getOnePostLoading: false,
        onePost: action.payload,
      };
    case GET_MORE_FASHION_CREATIVE_STATION_REQUEST:
      return {
        ...state,
        loadMoreFashionisLoading: true,
      };
    case GET_MORE_FASHION_CREATIVE_STATION_SUCCESS:
      return {
        ...state,
        loadMoreFashionisLoading: false,
        moodboars: [...state.moodboars, ...action.payload.data],
        next_page_moodboars: action.payload.moodboars.next_page_url,
      };
    case GET_MORE_TRENDING_CREATIVE_STATION_REQUEST:
      return {
        ...state,
        loadMoreTrendingisLoading: true,
      };
    case GET_MORE_TRENDING_CREATIVE_STATION_SUCCESS:
      return {
        ...state,
        loadMoreTrendingisLoading: false,
        trending: [...state.trending, ...action.payload.trending.data],
        next_page_trending: action.payload.trending.next_page_url,
      };
    case GET_MORE_NEWEST_CREATIVE_STATION_REQUEST:
      return {
        ...state,
        loadMoreNewestisLoading: true,
      };
    case GET_MORE_NEWEST_CREATIVE_STATION_SUCCESS:
      return {
        ...state,
        loadMoreNewestisLoading: false,
        threeDVideos: [
          ...state.threeDVideos,
          ...action.payload["3dvideos"].data,
        ],
        next_page_threeDVideos: action.payload["3dvideos"].next_page_url,
      };
    case GET_CREATIVE_STATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CREATIVE_STATION_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        isLoading: false,
        threeDVideos: action.payload["3dvideos"].data,
        next_page_threeDVideos: action.payload["3dvideos"].next_page_url,
        trending: action.payload.trending.data,
        next_page_trending: action.payload.trending.next_page_url,
        moodboars: action.payload.moodboars.data,
        next_page_moodboars: action.payload.moodboars.next_page_url,
      };
    case GET_CREATIVE_STATION_FAIL:
    case GET_MORE_NEWEST_CREATIVE_STATION_FAIL:
    case GET_MORE_TRENDING_CREATIVE_STATION_FAIL:
    case GET_MORE_FASHION_CREATIVE_STATION_FAIL:
    case GET_ONE_POST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        loadMoreNewestisLoading: false,
        loadMoreTrendingisLoading: false,
        loadMoreFashionisLoading: false,
        getOnePostLoading: false,
      };
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        [action.payload.category]: state[action.payload.category].filter(
          (post: any) => post.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

export default CreativeStation;
