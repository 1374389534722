import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { getGenders } from "../../actions/left-menu-actions/actions";
import { SelectGender } from "../Header/SelectGender";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "antd";
import moment from "moment";
interface Props {
  handleChangeOrder: (x: any) => void;
  handleChange2: (x: any, y: any) => void;
  state: any;
  fetchUser: any;
  collaborators: any;
  handleCollabChange: any;
  handleChangeGender: (x: any) => void;
  handlerType: (x: any) => void;
  handleChangeDeliveryDate: (x: any) => void;
}

export const AddOrderBottomDrawer = ({
  handleChangeOrder,
  handleChange2,
  state,
  fetchUser,
  collaborators,
  handleCollabChange,
  handleChangeGender,
  handlerType,
  handleChangeDeliveryDate,
}: Props) => {
  const [allGenders, setAllGenders] = React.useState<any>(null);
  const leftMenu = useSelector((state: any) => state.leftMenu);
  const [selectedDate, setSelectedDate] = useState(null);

  const [minDate, setMinDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [maxDate, setMaxDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 15);
    return date.toISOString().split("T")[0];
  });

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
  };
  const handleDateChange = (e: any) => {
    // Do something with the selected date
    console.log(e.target.value);
  };
  const [delivery_date, setDeliveryDate] = useState("");
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (leftMenu.allGenders.data) {
      setAllGenders(
        leftMenu.allGenders.data.map((el: any) => {
          return {
            label: el.name,
            value: el.id,
          };
        })
      );
    }
  }, [leftMenu.allGenders]);

  React.useEffect(() => {
    dispatch(getGenders());
    console.log(delivery_date);
  }, []);

  const options = [
    { value: "knit", label: "Knit" },
    { value: "denim", label: "Denim" },
  ];

  const customStyles = {
    //@ts-ignore
    option: (styles: any, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#00468c" : null,
        color: isSelected ? "white" : "black",
      };
    },
    control: (base: any) => ({
      ...base,
      border: 0,
      borderBottom: "1px solid #ccc",
      borderRadius: 0,
      padding: 0,
      // This line disable the blue border
      boxShadow: "none",
      "&:hover": {
        outine: "none",
      },
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingLeft: 0,
      paddingBottom: 0,
    }),
    menu: (base: any) => ({
      ...base,
      marginTop: 0,
      color: "#00468c",
    }),
    valueContainer: (base: any) => ({
      ...base,
      paddingLeft: 0,
      fontSize: 18,
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: "none",
    }),
    colors: (base: any) => ({
      ...base,
      primary: "#00468c",
      primary25: "#00468c",
    }),
  };
  const { user, isLoading, isAdmin } = useSelector((state: any) => state.auth);
  const [isFocused, setFocused] = useState(false);
  const handleFocus = (date: Date) => {
    console.log(date);
    if (date) {
      setFocused(true);
    } else {
      setFocused(false);
    }
  };
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faCaretDown} />
      </components.DropdownIndicator>
    );
  };
  return (
    <div style={{ width: "80%" }} className="container-fluid">
      <p className="text-center add_order_drawer_subtitle">
        Enter your order's information
      </p>
      <div className="row">
        <div className="form_add_article_left col-md-6 col-12">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="field field_article field_article_name">
                <input
                  type="text"
                  name="name"
                  id="orderName"
                  placeholder="     "
                  onChange={(e: any) => handleChangeOrder(e)}
                />
                <label htmlFor="orderName" className="folder_label">
                  Name Your Order
                </label>
              </div>
            </div>
            <div className="col-12 mb-md-5" id="datetime">
              {/*    <div className="field">
                <SelectGender
                  allGenders={allGenders}
                  handleChangeGender={handleChangeGender}
                />
              </div> */}

              <div className="field">
                <input
                  type={isFocused ? "date" : "text"}
                  //   type="date"
                  name="delivery_date"
                  id="delivery_date"
                  onChange={handleChangeDeliveryDate}
                  placeholder=" "
                  onFocus={() => handleFocus(new Date())}
                />
                <label htmlFor="delivery_date" className="login_email_label">
                  Delivery date
                </label>
              </div>
            </div>
            <div className="col-12 mb-4 mt-4">
              <div className="field field_article">
                <Select
                  isMulti
                  name="colors"
                  placeholder="Select your collaborators"
                  options={
                    collaborators &&
                    collaborators.map((el: any) => {
                      return {
                        label: el.name,
                        value: el.id,
                      };
                    })
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleCollabChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form_add_article_left col-md-6 col-12">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="field">
                <input
                  type="text"
                  name="season"
                  id="order_season"
                  onChange={(e: any) => handleChangeOrder(e)}
                  placeholder=" "
                />
                <label htmlFor="order_season" className="login_email_label">
                  Season
                </label>
              </div>
            </div>
            {user.fabric_type == "knit_denim" && (
              <div className="col-12 mb-4">
                <div className="field">
                  <Select
                    name="type"
                    placeholder={
                      <div className="react-select-placeholder-input">
                        Fabric type
                      </div>
                    }
                    styles={customStyles}
                    options={
                      options &&
                      options.map((el: any) => {
                        return {
                          label: el.label,
                          value: el.value,
                        };
                      })
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handlerType}
                    components={{ DropdownIndicator }}
                  ></Select>
                </div>
              </div>
            )}
            <div className="col-12 mb-4 field field_article">
              <textarea
                name="details"
                id="details"
                placeholder="  "
                onChange={(e: any) => handleChangeOrder(e)}
                rows={6}
              ></textarea>
              <label htmlFor="details" className="textarea_new_order">
                Details
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
