import {
  Alert,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Drawer,
  Input,
  Space,
  Spin,
  Typography,
} from "antd";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios-instance";
import useWindowWidth from "../../config/useWindowWidth";
import LazyImg from "../Library/LazyImage";
import watermak from "../../img/watermark.png";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import { useEffect, useState } from "react";
import {
  deleteCustomizedProduct,
  orderNewCustomizedProduct,
} from "../../actions/order-actions/services";
import { useSelector } from "react-redux";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import Select from "react-select";

import {
  createArticle,
  getCollaborators,
} from "../../actions/order-actions/actions";
import Bag from "../../icons/Bag";

const { Meta } = Card;
const { Title } = Typography;

export default function MyCustomizedProducts() {
  const [productsToOrder, setProductsToOrder] = useState([]);
  const [date_delivery, setDeliveryDate] = useState("");
  const [saison, setSaison] = useState("");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [year, setYear] = useState("");

  const [gender, setGender] = useState("");

  const [theme, setTheme] = useState("");
  const [disable, setDisable] = useState(false);
  const [collection_name, setOrderName] = useState("");
  const [orderDetails, setOrderDetails] = useState("");
  const [collaborator, setCollaborator] = useState([]);
  const [isRequired, setIsRequired] = useState(false);

  const { collaborators, newOrder, orders, createOrderLoading } = useSelector(
    (state: any) => state.orders
  );

  const handleCollabChange = (e: any) => {
    let arryColl: any = [];
    e &&
      e.map((el: any) => {
        arryColl.push(el.value);
      });
    setCollaborator(arryColl);
  };

  const getMyCustomizedProducts = async () => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      url: "/customized_products/getdraft",
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { data: customized_products, isLoading } = useQuery(
    "my-customized",
    getMyCustomizedProducts
  );

  useEffect(() => {
    // fetch new state of productsToOrder here
  }, [productsToOrder]);

  function handleChangeDeliveryDate(e: any) {
    setDeliveryDate(e.target.value);

    console.log(date_delivery);
  }

  function handleChangeTheme(e: any) {
    setTheme(e.target.value);
  }
  function handleChangeGender(e: any) {
    console.log(e);

    setGender(e);
    console.log(gender);
  }

  function handleChangeYear(e: any) {
    setYear(e.target.value);
  }
  function handleChangeSaison(e: any) {
    console.log(e);
    setSaison(e);
  }

  function handleChangeOrderName(e: any) {
    setOrderName(e.target.value);
  }

  function handleChangeDetails(e: any) {
    setOrderDetails(e.target.value);
  }

  const handleRemoveProduct = (itemToRemove: any) => {
    const updatedProducts = productsToOrder.filter(
      (item: any) => item !== itemToRemove
    );
    setProductsToOrder(updatedProducts);
  };

  const queryClient = useQueryClient();

  function handleChangeSeason(e: any) {
    setSaison(e.target.value);
  }
  const orderNow = (productsToOrder: any) => {
    console.log(productsToOrder);
    console.log(collection_name);
    console.log(collaborator);
    console.log(date_delivery);
    if (date_delivery != "" && collection_name != "") {
      setDisable(true);

      let token = localStorage.getItem("token");
      console.log(disable);
      if (productsToOrder.length == 1) {
        axiosInstance({
          method: "post",
          url: `/customized_products/order`,
          data: {
            customized_product_ids: productsToOrder,

            collection_name,
            date_delivery,
            collaborator,
            saison,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(function (response) {
          window.location.href = `/my-orders/customized-orders/in-progress`;
        });
      } else {
        axiosInstance({
          method: "post",
          url: `/customized_products/order`,
          data: {
            customized_product_ids: productsToOrder,
            collection_name,
            date_delivery,
            collaborator,
            saison,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(function (response) {
          window.location.href = `/my-orders/customized-orders/in-progress`;
        });
      }
      setDisable(false);
    } else {
      setIsRequired(true);
    }
  };

  /*   const { mutateAsync: orderNow, isLoading: orderLoading } = useMutation(
    orderNewCustomizedProduct,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("my-customized");
        setProductsToOrder([]);
      },
    }
  ); */

  const { mutateAsync: deleteProducts, isLoading: deleteLoading } = useMutation(
    deleteCustomizedProduct,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("my-customized");
        setProductsToOrder([]);
      },
    }
  );

  return (
    <div style={{ padding: "20px", paddingBottom: "50px" }} className="selection_container">
      <div>
        <div className="row" style={{ justifyContent: "space-between" }}>
          <div>
            <Title level={2} style={{ color: "#00468c", marginBottom: "0px" }}>
              My customized products
            </Title>
            <p style={{ opacity: 0.8, marginBottom: "20px" }}>
              To order or delete one or more product, please select those you
              want.
            </p>
          </div>
          <div>
            <div>
              <button
                className="selectAll"
                onClick={() => {
                  setProductsToOrder(customized_products?.data);
                  setIsOpenDrawer(true);
                  //openDrawer();
                }}
              >
                <Bag />{" "}
                <span style={{ marginLeft: "10px" }}>Order Collection</span>
              </button>
            </div>
          </div>
        </div>
        <br />
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div className="products-grid">
            {customized_products?.data.map((el: any, i: number) => (
              <div key={el.id}>
                <CustomizedCard
                  product={{
                    front: el.product_front_image,
                    back: el.product_back_image,
                    name: el.name,
                    code: el.product_code,
                    id: el.id,
                  }}
                  setProductsToOrder={setProductsToOrder}
                  productsToOrder={productsToOrder}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {productsToOrder.length > 0 && (
        <Alert
          message={`${productsToOrder.length} Product(s) selected`}
          type="info"
          style={{
            position: "fixed",
            left: "50%",
            //    top: "100%",

            bottom: "-12px",
            width: "30vw",
            borderRadius: "8px",
            border: "2px solid #809ec2",
            color: "#00468c",
            backgroundColor: "#f4f7ff",
            minWidth: "400px",
            transform: "translate(-50%,-50%)",
            zIndex: 20,
          }}
          action={
            <Space direction="horizontal">
              <Button
                size="middle"
                type="ghost"
                style={{
                  color: "#00468c",
                  borderColor: "#00468c",
                  borderRadius: "5px",
                }}
                onClick={() => deleteProducts(productsToOrder)}
                loading={deleteLoading}
              >
                Delete
              </Button>
              {/*    <Button
                size="middle"
                type="primary"
                style={{
                  background: "#00468c",
                  borderColor: "#00468c",
                  borderRadius: "5px",
                }}
                onClick={() => orderNow(productsToOrder)}
                loading={orderLoading}
              >
                Order now
              </Button> */}
              <Button
                size="middle"
                type="primary"
                style={{
                  background: "#00468c",
                  borderColor: "#00468c",
                  borderRadius: "5px",
                }}
                onClick={() => setIsOpenDrawer(true)}
                /*           loading={orderLoading} */
              >
                Order now
              </Button>
            </Space>
          }
          closable
          onClose={() => setProductsToOrder([])}
        />
      )}

      <Drawer
        // className="order_sent_drawer"
        placement={"bottom"}
        closable={true}
        onClose={() => {
          setProductsToOrder([]);
          setIsOpenDrawer(false);
        }}
        visible={isOpenDrawer}
        key={"bottom"}
        style={{ paddingTop: "25px" }}
      >
        <div className="row">
          {/* left container */}

          <div
            className="col-6 "
            style={{
              paddingTop: "88px",
              paddingLeft: "112px",
              paddingRight: "112px",
            }}
          >
            <div className="titledrawerConfirm">Selected products</div>
            <div style={{ fontSize: "16px", marginBottom: "32px" }}>
              Please review your selected items below before confirming your
              order.
            </div>
            {productsToOrder.length > 0 ? (
              <div
                className="border"
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#F8FAFC",
                  padding: "12px",
                }}
              >
                {console.log(productsToOrder)}
                {productsToOrder.map((item: any, key: any) => {
                  return (
                    <>
                      <div
                        className="row rowProdcut"
                        style={{ position: "relative" }}
                      >
                        <div
                          style={{
                            borderRadius: "8px",
                            marginRight: "16px",
                          }}
                        >
                          <img
                            src={process.env.REACT_APP_STORAGE_URL + item.front}
                            width="100px"
                            height="72px"
                            className="orderBoxImage"
                          />
                        </div>
                        <div className="">
                          <span className="titleorderContainerH1">
                            {item.name}
                          </span>
                          <br></br>
                          <span className="titleorderContainerH2">
                            {item.code}
                          </span>
                        </div>
                        <div style={{ position: "absolute", right: "0" }}>
                          <button
                            onClick={() => {
                              handleRemoveProduct(item);
                              if (productsToOrder.length == 1) {
                                setProductsToOrder([]);
                                setIsOpenDrawer(false);
                              }
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "5px",
                              backgroundColor: "#E5ECF3",
                              padding: "16px",
                              width: "32px",
                              height: "32px",
                            }}
                          >
                            <DeleteOutlined />
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            ) : (
              <>The list is empty </>
            )}
          </div>
          <div
            className="col-6 "
            style={{
              paddingTop: "88px",
              paddingLeft: "112px",
              paddingRight: "112px",
            }}
          >
            {" "}
            <div className="titledrawerConfirm">Order details</div>
            <div style={{ fontSize: "16px", marginBottom: "32px" }}>
              Complete your order by providing the details below.
            </div>
            <div>
              <label className="mr-3 mb-1">Collection name</label>
              <Input
                type="text"
                style={{
                  borderRadius: "5px",
                  marginBottom: "16px",
                }}
                onChange={handleChangeOrderName}
              ></Input>
              {isRequired && collection_name === "" && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
            <label className="mr-3 mb-1">Season</label>
            <Input
              type="text"
              style={{
                borderRadius: "5px",
                marginBottom: "16px",
              }}
              defaultValue={saison}
              onChange={handleChangeSeason}
            ></Input>
            <label className="mr-3 mb-0">Select Collaborators</label>
            <Select
              isMulti
              name="colors"
              placeholder="Select your collaborators"
              options={
                collaborators &&
                collaborators.map((el: any) => {
                  return {
                    label: el.name,
                    value: el.id,
                  };
                })
              }
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleCollabChange}
            />
            <div>
              <label className="mr-3 mb-0 mt-3">Delivery date</label>
              <DatePicker
                disabledDate={(current) =>
                  current.isBefore(moment().add(15, "days"))
                }
                format={"DD/MM/YYYY"}
                onChange={(date: any, dateString: any) =>
                  setDeliveryDate(dateString)
                }
                style={{
                  borderRadius: "5px",
                  width: "100%",
                  marginBottom: "16px",
                }}
              />

              {isRequired && date_delivery === "" && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
            <div>
              <button
                className="view-details"
                onClick={() => orderNow(productsToOrder)}
                disabled={disable}
                style={{ marginTop: "16px" }}
              >
                {disable ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 24, color: "white" }}
                        spin
                      />
                    }
                  />
                ) : (
                  <>Confirm order</>
                )}
              </button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

const CustomizedCard = ({
  product,
  setProductsToOrder,
  productsToOrder,
}: any) => {
  const width = useWindowWidth();
  const history = useHistory();

  const toggle = () => {
    const id = product.id;
    const isExist = productsToOrder.find((el: any) => el.id === id);

    let updatedList = [...productsToOrder];
    if (!isExist) {
      updatedList = [...productsToOrder, product];
    } else {
      updatedList.splice(productsToOrder.indexOf(id), 1);
    }
    setProductsToOrder(updatedList);
  };

  return (
    <Card
      style={{
        width: width < 1200 ? "100%" : "14rem",
        // padding: 15,
        paddingTop: 15,
        borderRadius: "16px",
      }}
      bordered={true}
      // bodyStyle={{ padding: "0px" }}
      className="grid-item-product"
      cover={
        <div className={`product_item_new mb-2`}>
          <LazyImg
            alt={product.name}
            style={{
              position: "absolute",
              zIndex: 2,
              opacity: 0.2,
              objectFit: "contain",
              width: "100%",
              height: "260px",
            }}
            src={watermak}
            thumb={watermak}
          />
          <LazyImg
            className="img-front"
            alt={product?.name}
            style={{
              objectFit: "contain",
              width: "100%",
              height: "260px",
            }}
            src={
              product?.front
                ? process.env.REACT_APP_STORAGE_URL + product?.front
                : placeholder_jeans
            }
            thumb={
              product?.front
                ? process.env.REACT_APP_STORAGE_URL + product?.front
                : placeholder_jeans
            }
          />
          <LazyImg
            style={{ height: "260px", objectFit: "contain" }}
            className="img-back"
            alt={product?.name}
            src={
              product?.back
                ? process.env.REACT_APP_STORAGE_URL + product?.back
                : placeholder_jeans
            }
            thumb={
              product?.back
                ? process.env.REACT_APP_STORAGE_URL + product?.back
                : placeholder_jeans
            }
          />
        </div>
      }
    >
      <Meta
        description={
          <div
            className="d-flex mt-2 justify-content-between"
            style={{ height: "60px" }}
          >
            <div className="labels_list">
              <div
               /*  onClick={() =>
                  history.push(`/customization/edit/${product.id}`)
                } */
              //  style={{ cursor: "pointer" }}
              >
                <h4 className="h4_medium mb-0" style={{ fontSize: "1rem" }}>
                  {product?.code}
                </h4>
                <h4
                  className="h4_medium"
                  style={{ fontWeight: 400, fontSize: "1rem" }}
                >
                  {product?.name}
                </h4>
              </div>
            </div>
            <div className="align-self-center">
              <Checkbox
                onChange={toggle}
                //  checked={productsToOrder.includes(product.id)}
                checked={productsToOrder.some((p: any) => p.id === product.id)}
              />
            </div>
          </div>
        }
      />
    </Card>
  );
};
