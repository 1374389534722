import { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  handleBottomDrawer,
  handleBottomDrawerDeletedItems,
} from "../../actions/actionCreators";
import {
  clearFolderToMoveToOnDrag,
  clearSelectedFolders,
  deleteFolder,
  getAllFolders,
  getArchivedFolders,
} from "../../actions/folders-actions/actions";

import HeartOutline from "../../icons/HeartOutline";
import DroppableFolderMiniature from "../FolderMiniature/DroppableFolderMiniature";
import DeletedItems from "../DeletedItems/DeletedItems";
import { AnimatePresence } from "framer-motion";
import Attention from "../../icons/Attention";
import Trash from "../../icons/Trash";
import { selectSelection } from "../../actions/myselection-actions/actions";
import { Spinner } from "../Spinner";
import { Tooltip } from "antd";

export interface IProductsPageCollectionFoldersProps {
  setfolder: (x: any) => void;
  subFolderId: any;
  setsubfolders: (t: any) => void;
  setParentFolderId: (t: any) => void;
}

export default function ProductsPageCollectionFolders({
  setfolder,
  subFolderId,
  setsubfolders,
  setParentFolderId,
}: IProductsPageCollectionFoldersProps) {
  const dispatch = useDispatch();
  const drawers = useSelector((state: RootStateOrAny) => state.drawers);
  const folders = useSelector((state: RootStateOrAny) => state.folders);
  const selection = useSelector((state: RootStateOrAny) => state.selection);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    dispatch(getAllFolders());
    setHeight(window.innerHeight);
  }, [window.innerHeight]);
  useEffect(() => {
    dispatch(getArchivedFolders());
    selection.directRender && dispatch(selectSelection());
  }, []);
  useEffect(() => {
    dispatch(getArchivedFolders());
  }, [folders.folders]);
  useEffect(() => {
    // dispatch(getF)
  }, [folders.folderUpdated]);

  return (
    <div className="my_collection_folders">
      <div className="my_collection_folders_header">
        <div className="my_collection_header">
          <h2 className="collection_header_title">My collections</h2>
        </div>

        {/*   <div className="folder_miniature folder_my_selection">
     <div
      className="folder_miniature_header"
      style={{ justifyContent: "flex-start" }}
     >
      {<HeartOutline className="heart_outline" />}
      <h2 className="folder_name">My Selections</h2>
     </div>
    </div> */}
      </div>

      <div className="collection_list_folders">
        {folders && folders.folders && !folders.isLoading ? (
          folders.folders
            .filter((el: any) => !el.parent_id)
            .map((el: any, i: number) => (
              <DroppableFolderMiniature
                setParentFolderId={setParentFolderId}
                setsubfolders={setsubfolders}
                subFolderId={subFolderId}
                setfolder={setfolder}
                folder={el}
                key={i}
              />
            ))
        ) : (
          <Spinner />
        )}
      </div>

      <AnimatePresence initial={true} exitBeforeEnter={true}>
        {drawers.openDeletedItems && <DeletedItems />}
      </AnimatePresence>
    </div>
  );
}
