import { Avatar, Button, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Folder from "../../icons/Folder";
import Jeans from "../../icons/Jeans";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import { DirectOrderTableRowMobile } from "./DirectOrderTableRowMobile";

interface Props {
  order: any;
  getStatusLoading: any;
  articleStatus: any;
  statusMsg: any;
  width: any;
}

export const DirectOrderTableRow = ({
  order,
  getStatusLoading,
  articleStatus,
  statusMsg,
  width,
}: Props) => {
  return width > 768 ? (
    <tr>
      <td scope="row">
        {order.articles.length > 1 ? <Folder /> : <Jeans />}
        <span className="ml-2 myorder_order_name">{order.name}</span>
      </td>
      <td>
        {order.updated_at
          .slice(0, 10)
          .replace(/[-]/g, "/")
          .split("/")
          .reverse()
          .join("/")}
      </td>
      <td>
        <Avatar.Group
          maxCount={2}
          size="large"
          maxStyle={{ color: "white", backgroundColor: "#7a9ec5" }}
        >
          {order.collaborators.map((collab: any) => (
            <Tooltip title={collab.name} key={collab.id} placement="bottom">
              {collab.logo ? (
                <Avatar
                  style={{
                    boxShadow: "1px 0px 5px rgba(0,0,0,0.4)",
                  }}
                  src={process.env.REACT_APP_STORAGE_URL + collab.logo}
                />
              ) : (
                <Avatar
                  style={{
                    backgroundColor: "#ddd",
                    boxShadow: "1px 0px 5px rgba(0,0,0,0.2)",
                  }}
                  icon={<UserOutlined style={{ verticalAlign: "2px" }} />}
                />
              )}
            </Tooltip>
          ))}
        </Avatar.Group>
      </td>
      <td>
        <p className="mb-0">
          {order.articles.length} article{order.articles.length > 1 ? "s" : ""}
        </p>
        {/* {order.articles.length > 1 ? (
          "---"
        ) : getStatusLoading ? (
          <p>Loading...</p>
        ) : (
          <Link
            to={`/my-orders/${order.id}/${
              order.articles[0] && order.articles[0].id
            }`}
          >
            <Button
              style={{
                color: articleStatus === "PROTO" ? "#7C7C7C" : "#00468C",
                background:
                  articleStatus === "PROTO"
                    ? "#E0E0E0"
                    : "rgba(0, 70, 140, 0.2)",
              }}
              className="status_btn_order"
            >
              {articleStatus}
            </Button>
          </Link>
        )} */}
      </td>
      <td>
        {/* {order.articles.length > 1 ? ( */}
        <Link to={`/my-orders/${order.id}`}>
          <Button
            type="ghost"
            className="see_more_order_details_btn"
            style={{ height: "50px !important" }}
          >
            See More
          </Button>
        </Link>
        {/* ) : ( */}
        {/* <span style={{ color: statusMsg.color }} className="status_msg">
            {statusMsg.msg}
          </span>
        )} */}
      </td>
    </tr>
  ) : (
    <DirectOrderTableRowMobile
      order={order}
      getStatusLoading={getStatusLoading}
      articleStatus={articleStatus}
      statusMsg={statusMsg}
      width={width}
    />
  );
};
