import axiosInstance from "../../config/axios-instance";

export const getMyDirectOrders = (selectedDate: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: selectedDate
      ? `/direct_orders/bydate?date=${selectedDate}`
      : `/direct_orders?status=completed`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMyDemcoOrders = (selectedDate: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: selectedDate
      ? `/orders/bydate?date=${selectedDate}`
      : `/orders?status=completed`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMyCustomizedOrders = (selectedDate: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: selectedDate
      ? `/customized_products/getcustomizedOrder/bydate?date=${selectedDate}`
      : `/customized_products/getcustomizedOrder`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCompletedCustomizedOrders = (selectedDate: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: selectedDate
      ? `/customized_products/getcompletedcustomizedOrder/bydate?date=${selectedDate}`
      : `/customized_products/getcompletedcustomizedOrder`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSingleCustomizedOrders = (orderId: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/customized_products/${orderId}/showcustomizedProduct`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDirectOderContent = (id: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/direct_orders/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getOneDemcoOrder = (id: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/orders/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

/* export const getCancelledOptions = (selectedDate: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: selectedDate
      ? `/direct_orders/cancelled/bydate?date=${selectedDate}`
      : `/direct_orders/cancelled`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}; */

export const getCancelledOptions = (selectedDate: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: selectedDate
      ? `/direct_orders/cancelled/bydate?date=${selectedDate}`
      : `/direct_orders/cancelled`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getCompletdOptions = (selectedDate: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: selectedDate
      ? `/direct_orders/completd/bydate?date=${selectedDate}`
      : `/direct_orders/completd`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCompletdDemcoOrder = (selectedDate: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: selectedDate
      ? `/orders/completed/bydate?date=${selectedDate}`
      : `/orders/completed`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getMyCancelledDemcoOrders = (selectedDate: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: selectedDate
      ? `/orders/cancelled/bydate?date=${selectedDate}`
      : `/orders/cancelled`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMyCancelledCustomizedOrders = (selectedDate: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: selectedDate
      ? `/customized_products/cancelled/bydate?date=${selectedDate}`
      : `/customized_products/cancelled`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSingleArticle = (articleId: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/order_articles/${articleId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const restoreOption = (optionId: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/article_options/${optionId}/update_status`,
    data: {
      status_id: 1,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const restoreOptionLeft = (optionId: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/article_options/${optionId}/update_status_left`,
    data: {
      status_id: 1,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const restoreProduct = ({
  order_id,
  product_id,
}: {
  order_id: number;
  product_id: number;
}) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/orders/${order_id}/products/${product_id}/status`,
    data: {
      status_id: 1,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const restoreProductLeft = ({
  order_id,
  product_id,
}: {
  order_id: number;
  product_id: number;
}) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/orders/${order_id}/products/${product_id}/status/left`,
    data: {
      status_id: 1,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const relaunchOption = ({
  order_id,
  product_id,
}: {
  order_id: number;
  product_id: number;
}) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/demco_orders/relaunch/${order_id}`,
    data: {
      status_id: 5,
      product_id: product_id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const relaunchDirectOption = (order_id: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/direct_orders/relaunch/${order_id}`,
    data: {
      status_id: 5,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const relaunchCustomProduct = ({
  order_id,
  product_id,
}: {
  order_id: number;
  product_id: number;
}) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/customized_orders/relaunch/${order_id}`,
    data: {
      data: {
        status_id: 5,
        product_id: product_id,
      },
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteOneProduct = ({
  order_id,
  product_id,
}: {
  order_id: number;
  product_id: number;
}) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "delete",
    url: `/orders/${order_id}/products/${product_id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const relauchOneProduct = ({
  order_id,
  product_id,
}: {
  order_id: number;
  product_id: number;
}) => {
  let token = localStorage.getItem("token");
  console.log(product_id);
  return axiosInstance({
    method: "post",
    url: `/demco_orders/relaunch/${order_id}`,
    data: {
      product_id: product_id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const restoreCustomizedOrder = (product_id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/customized_products/${product_id}/update`,
    data: {
      status_id: 1,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const restoreCustomizedproduct = ({
  order_id,
  product_id,
}: {
  order_id: number;
  product_id: number;
}) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/customized_products/${order_id}/restore/${product_id}`,
    data: {
      status_id: 1,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const restoreCustomizedOrderLeft = (product_id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/customized_products/${product_id}/update/left`,
    data: {
      status_id: 1,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteCustomizedOrder = (product_id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "delete",
    url: `/customized_products/${product_id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getOption = (id: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/article_options/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSingleDemcoOrder = ({
  order_id,
  product_id,
}: {
  order_id: string;
  product_id: string;
}) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/orders/${order_id}/products?product_id=${product_id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
