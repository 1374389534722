import { Button, Drawer, Radio, Spin } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFeedback,
  updateArticle,
  addNormalOrderFeedback,
} from "../../actions/order-actions/actions";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useParams } from "react-router-dom";
import OrderServices from "../../actions/order-actions/services";
import axiosInstance from "../../config/axios-instance";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { history } from "../..";
import { LoadingOutlined } from "@ant-design/icons";
import Close from "../../icons/Close";

interface Props {
  setIsVisible: any;
  isVisible: any;
  article: any;
  setAlerts?: any;
  productToCancel: any;
  orderType?: string;
  notApprovedOption?: any;
  approvingAndCommentLoading?: boolean;
  rejectOrAcceptProtoLoading?: boolean;
}

export const CancelOrderDrawer = ({
  setIsVisible,
  isVisible,
  article,
  setAlerts,
  productToCancel,
  orderType,
  notApprovedOption,
  approvingAndCommentLoading,
  rejectOrAcceptProtoLoading,
}: Props) => {
  const [cause, setCause] = useState("");
  const [changedMind, setChangedMind] = useState(false);
  const [notNow, setNotNow] = useState(false);
  const [other, setOther] = useState(false);
  const [msg, setMsg] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams<any>();

  const { oneArticle } = useSelector((state: any) => state.orders);

  const handleChangeCheckbox = (e: any) => {
    switch (e.target.name) {
      case "changed_mind":
        if (changedMind) {
          setCause("");
        } else {
          setCause("Changed my mind");
        }
        setChangedMind(!changedMind);
        break;
      case "not_now":
        if (notNow) {
          setCause("");
        } else {
          setCause("Not now");
        }
        setNotNow(!notNow);
        break;
      default:
        if (other) {
          setCause("");
        } else {
          setCause(e.target.name);
        }
        setOther(!other);
    }
  };

  const skipFeedback = () => {
    const isDirectOrder = location.pathname.includes("direct-order");
    const isCustomized = location.pathname.includes("customized-product");
    let token = localStorage.getItem("token");

    if (orderType === "direct_order") {
      notApprovedOption();
      // return axiosInstance({
      //   method: "put",
      //   url: `/article_options/${productToCancel}`,
      //   data: {
      //     status_id: 6,
      //   },
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // }).then(() => {
      //   setIsVisible(false);
      //   MyNotif("This option was cancelled.", "success");
      //   history.back();
      // });
    } else if (orderType === "demco_order") {
      notApprovedOption();
      // return axiosInstance({
      //   method: "put",
      //   url: `/orders/${productToCancel}`,
      //   data: {
      //     status_id: 6,
      //   },
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // }).then(() => {
      //   setIsVisible(false);
      //   MyNotif("This product was cancelled.", "success");
      //   // history.back();
      // });
    } else {
      notApprovedOption();
      // return axiosInstance({
      //   method: "put",
      //   url: `/customized_products/${params.productId}`,
      //   data: {
      //     status_id: 6,
      //   },
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // }).then(() => {
      //   setIsVisible(false);
      //   MyNotif("This product was cancelled.", "success");
      //   history.back();
      // });
    }
  };
  const cancelAndSendFeedback = async () => {
    const isDirectOrder = location.pathname.includes("direct-order");
    const isCustomized = location.pathname.includes("customized-product");
    let token = localStorage.getItem("token");

    if (orderType === "customized_order") {
      notApprovedOption(cause);
      // return axiosInstance({
      //   method: "put",
      //   url: `/customized_products/${params.productId}`,
      //   data: {
      //     status_id: 6,
      //     feedback: cause,
      //   },
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // }).then(() => {
      //   setIsVisible(false);
      //   MyNotif("This product was cancelled.", "success");
      //   history.back();
      // });
    } else if (orderType === "direct_order") {
      notApprovedOption(cause);
      // return axiosInstance({
      //   method: "put",
      //   url: `/article_options/${productToCancel}`,
      //   data: {
      //     status_id: 6,
      //     feedback: cause,
      //   },
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // }).then(() => {
      //   setIsVisible(false);
      //   MyNotif("This option was cancelled.", "success");
      //   history.back();
      // });
    } else {
      notApprovedOption(cause);
      // return axiosInstance({
      //   method: "put",
      //   url: `/orders/${params.id}`,
      //   data: {
      //     status_id: 6,
      //   },
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // }).then(() => {
      //   return axiosInstance({
      //     method: "put",
      //     url: `/orders/feedbacks`,
      //     data: {
      //       product_id: params.articleId,
      //       order_id: params.id,
      //       feedback: cause,
      //     },
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }).then(() => {
      //     setIsVisible(false);
      //     MyNotif("This product was cancelled.", "success");
      //     history.back();
      //   });
      // });
    }
    // if (!location.pathname.includes("/my-orders/original-product")) {
    //   await dispatch(updateArticle({ status_id: 6 }, productToCancel));
    //   await dispatch(
    //     addFeedback({ article_id: productToCancel, feedback: cause })
    //   );
    // } else {
    //   await OrderServices.changeStatusNormalOrder(
    //     oneArticle.id,
    //     productToCancel,
    //     6
    //   );
    //   await dispatch(
    //     addNormalOrderFeedback({
    //       product_id: productToCancel,
    //       order_id: oneArticle.id,
    //       feedback: cause,
    //     })
    //   );
    // }
    // setIsVisible(false);
  };

  return (
    <Drawer
      placement={"bottom"}
      className="cancel_drawer"
      closable={true}
      maskClosable={false}
      closeIcon={<Close />}
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      key={"bottom"}
    >
      <AnimatePresence exitBeforeEnter={false}>
        <div className="container cancel_drawer_container">
          <div className="my-3">
            <h1 className="my-2">Your order is canceled</h1>
            <p className="my-4">
              The following information are for our records and will not prevent
              from cancelling your order, you will find this file in the
              canceled order.
            </p>
          </div>
          <Radio.Group
            id="other"
            onChange={(e: any) => setCause(e.target.value)}
            className="d-flex justify-content-around"
          >
            <Radio value={"changed my mind"}>Changed my mind</Radio>
            <Radio value={"not now"}>Not now</Radio>
            <Radio value={"Comment"}>Other</Radio>
          </Radio.Group>
          {/* <div className="cancel_options_container w-75 mx-auto">
            <div className="d-flex align-items-center my-1 py-1">
              <input
                type="radio"
                className="filter_checkbox"
                id="changed_mind"
                name="changed_mind"
                checked={changedMind}
                onClick={handleChangeCheckbox}
              />
              <label htmlFor="changed_mind" className="mb-0 ml-2">
                Changed My Mind
              </label>
            </div>
            <div className="d-flex align-items-center my-1 py-1">
              <input
                type="radio"
                className="filter_checkbox"
                id="not_now"
                name="not_now"
                checked={notNow}
                onClick={handleChangeCheckbox}
              />
              <label htmlFor="not_now" className="mb-0 ml-2">
                Not Now
              </label>
            </div>
            <div className="d-flex align-items-center my-1 py-1">
              <input
                type="radio"
                className="filter_checkbox"
                id="other"
                name="other"
                checked={other}
                onClick={handleChangeCheckbox}
              />
              <label htmlFor="other" className="mb-0 ml-2">
                Other
              </label>
            </div>
          </div> */}
          {cause !== "changed my mind" && cause !== "not now" && (
            <motion.input
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              type="text"
              className="cancel_other_input my-4"
              value={cause}
              onChange={(e: any) => setCause(e.target.value)}
            />
          )}
        </div>
        <div className="proto_footer_btns cancel_drawer_btns my-4">
          {approvingAndCommentLoading || rejectOrAcceptProtoLoading ? (
            // approvingAndCommentLoading
            <Spin
              tip="Sending"
              style={{ color: "#00468c" }}
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 35,
                    color: "#00468c",
                    marginBottom: "5px",
                  }}
                />
              }
            />
          ) : (
            <>
              <Button
                type="default"
                className="cancel_btn"
                onClick={skipFeedback}
                // loading={approvingAndCommentLoading || rejectOrAcceptProtoLoading}
              >
                Skip
              </Button>
              <Button
                type="default"
                className="approve_btn"
                onClick={cancelAndSendFeedback}
              >
                Send
              </Button>
            </>
          )}
        </div>
      </AnimatePresence>
    </Drawer>
  );
};
