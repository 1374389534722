export interface IPenProps {
  className?: string;
}

function Faq({ className }: IPenProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 28 28"
    >
      <path
        d="M8.813-10.5a.891.891,0,0,1,.938-.937.914.914,0,0,1,.938.938.94.94,0,0,1-.937.938A.914.914,0,0,1,8.813-10.5Zm.145-7.125H10.4A2.226,2.226,0,0,1,12.619-15.4a2.175,2.175,0,0,1-1.27,1.988l-.891.445v.22a.726.726,0,0,1-.75.75.781.781,0,0,1-.75-.75v-.684a.788.788,0,0,1,.455-.67l1.308-.652a.721.721,0,0,0,.4-.647.722.722,0,0,0-.722-.722H8.958a.575.575,0,0,0-.577.572.746.746,0,0,1-.75.75.724.724,0,0,1-.75-.75A2.078,2.078,0,0,1,8.958-17.625ZM16.5-6l-6,0L5.4-3.112A.538.538,0,0,1,5.062-3,.561.561,0,0,1,4.5-3.562V-6H3A3,3,0,0,1,0-9v-9.042A3,3,0,0,1,3-21H16.5a3,3,0,0,1,3,2.958V-9A3,3,0,0,1,16.5-6Zm0-2.245A.755.755,0,0,0,17.25-9v-9a.757.757,0,0,0-.75-.75H3a.755.755,0,0,0-.75.75v9A.754.754,0,0,0,3-8.25H6.75v1.786L9.909-8.25ZM14.25-4.5V-3a.755.755,0,0,0,.75.75h5.091L23.25-.464V-2.25H27A.758.758,0,0,0,27.75-3v-9a.755.755,0,0,0-.75-.75H21V-15h6a2.994,2.994,0,0,1,3,2.958V-3a3,3,0,0,1-3,3H25.5V2.438A.561.561,0,0,1,24.938,3a.538.538,0,0,1-.333-.112L19.5,0,15,0a3,3,0,0,1-3-3V-4.5Z"
        transform="translate(0 21)"
        fill="#182844"
      />
    </svg>
  );
}

export default Faq;
