import "./FolderDetails.scss";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Folder from "../../icons/Folder";
import RootFolders from "../../icons/RootFolders";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { handleLeftFolderDetails } from "../../actions/actionCreators";
import { motion } from "framer-motion";
import { ProductCard } from "../ProductCard/ProductCard";
import { Link } from "react-router-dom";

export interface IFolderDetailsProps {}

export default function FolderDetails(props: IFolderDetailsProps) {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const folders = useSelector((state: RootStateOrAny) => state.folders);
  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width, folders.folderDetails]);
  const variants = {
    enter: { x: width },
    center: { x: 0 },
    exit: { x: width, display: "none" },
  };
  return (
    <motion.div
      className="folder_details_container"
      transition={{ type: "tween" }}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      <div
        className="close_btn"
        onClick={() => {
          dispatch(handleLeftFolderDetails());
        }}
      >
        <p>Close</p>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      {folders.folderDetails && (
        <div className="folder_details_content_container">
          <div className="folder_header_container">
            <div className="folder_header">
              <Folder />
              <p>{folders.folderDetails.name}</p>
            </div>
            {/* do not delete */}
            {/* <div className="folder_root_container">
              <RootFolders />
              <p>folder Root</p>
            </div> */}
          </div>
          {folders.folderDetails.description && (
            <div className="folder_description_container">
              <p className="folder_description_title">Description</p>
              <p className="folder_description_text paragraphe">
                {folders.folderDetails.description}
              </p>
            </div>
          )}
          {folders.folderDetails.tags ? (
            <div className="folder_tags_container">
              <p className="folder_tags_title">Tags</p>
              <div className="tags_list">
                {folders.folderDetails.tags.map((el: any) => (
                  <p className="tag">el</p>
                ))}
              </div>
            </div>
          ) : (
            <div className="folder_tags_container">
              <p className="folder_tags_title">Tags</p>
              <p className="paragraphe">
                No tags Available for this Collection
              </p>
            </div>
          )}
          <div className="folder_products_container">
            <p className="folder_products_title">
              Contains {folders.folderDetails.products.length} products
            </p>
            <div className="products_list">
              {folders.folderDetails.products.map((el: any, i: number) => (
                <Link
                  className="product_card col-md-auto col-12"
                  to={`/product/MySelection/${el.id}`}
                >

                  
                  <ProductCard
                    index={i}
                    item={{
                      main: el.images[0] ? el.images[0].path : "",
                      onHover: el.images[1] ? el.images[1].path : "",
                    }}
                    product={el}
                    key={i}
                    productPage={true}
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
}
