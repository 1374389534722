import {
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  ADD_ORDER_REQUEST,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_OPTION_REQUEST,
  UPDATE_OPTION_SUCCESS,
  UPDATE_OPTION_FAIL,
  GET_OPTION_REQUEST,
  GET_OPTION_SUCCESS,
  GET_OPTION_FAIL,
  SELECT_OPTION,
  SELECT_ARTICLE,
  DELETE_OPTION_REQUEST,
  DELETE_OPTION_SUCCESS,
  DELETE_OPTION_FAIL,
  DELETE_OPTION_FILE_SUCCESS,
  DELETE_OPTION_FILE_FAIL,
  DUPLICATE_OPTION_REQUEST,
  DUPLICATE_OPTION_SUCCESS,
  DUPLICATE_OPTION_FAIL,
  DELETE_ARTICLE_REQUEST,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAIL,
  UPDATE_ARTICLE_REQUEST,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAIL,
  CREATE_ARTICLE_REQUEST,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FAIL,
  GET_COLLABORATOR_REQUEST,
  GET_COLLABORATOR_SUCCESS,
  GET_COLLABORATOR_FAIL,
  GET_ORDER_COLLABORATOR_REQUEST,
  GET_ORDER_COLLABORATOR_SUCCESS,
  GET_ORDER_COLLABORATOR_FAIL,
  DELETE_COLLABORATOR_REQUEST,
  DELETE_COLLABORATOR_SUCCESS,
  DELETE_COLLABORATOR_FAIL,
  GET_ONE_ORDER_REQUEST,
  GET_ONE_ORDER_SUCCESS,
  GET_ONE_ORDER_FAIL,
  GET_STATUS_REQUEST,
  GET_STATUS_SUCCESS,
  GET_STATUS_FAIL,
  ADD_FEEDBACK_REQUEST,
  ADD_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_FAIL,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAIL,
  GET_ONE_ARTICLE_REQUEST,
  GET_ONE_ARTICLE_SUCCESS,
  SEND_REQ_ACCESS_REQUEST,
  SEND_REQ_ACCESS_SUCCESS,
  SEND_REQ_ACCESS_FAIL,
  ACCEPT_REQ_ACCESS_REQUEST,
  ACCEPT_REQ_ACCESS_SUCCESS,
  ACCEPT_REQ_ACCESS_FAIL,
  ADD_DEMCO_PRODUCT_ORDER_REQUEST,
  ADD_DEMCO_PRODUCT_ORDER_SUCCESS,
  ADD_DEMCO_PRODUCT_ORDER_FAIL,
  GET_NORMAL_ORDER_REQUEST,
  GET_NORMAL_ORDER_SUCCESS,
  GET_NORMAL_ORDER_FAIL,
  ADD_NORMAL_ORDER_COMMENT_REQUEST,
  ADD_NORMAL_ORDER_COMMENT_SUCCESS,
  ADD_NORMAL_ORDER_COMMENT_FAIL,
  SAVE_CUSTOMIZED_PRODUCT_REQUEST,
  SAVE_CUSTOMIZED_PRODUCT_SUCCESS,
  SAVE_CUSTOMIZED_PRODUCT_FAIL,
  CHANGE_ORDER_TYPE_TAB,
  GET_COMPANY_ORDER_REQUEST,
  GET_COMPANY_ORDER_SUCCESS,
  GET_COMPANY_ORDER_FAIL,
  ADD_COLLABORATOR_SUCCESS,
} from "../actions/order-actions/types";

const initState = {
  isLoading: false,
  orders: [],
  newOrder: null,
  errors: null,
  selectedOption: null,
  getOptionLoading: false,
  selectedArticle: null,
  updateOptionLoading: false,
  deleteOptionLoading: false,
  toDeleteArticle: null,
  deleteArticleLoading: false,
  updateArticleLoading: false,
  createArticleLoading: false,
  createOrderLoading: false,
  deleteOrderLoading: false,
  collaborators: null,
  getCollaboratorsLoading: false,
  getOrderCollaboratorsLoading: false,
  deleteCollaboratorLoading: false,
  getOneOrderLoading: false,
  oneOrder: null,
  getStatusLoading: false,
  status: [],
  feedback: null,
  feedbackLoading: false,
  comments: null,
  commentsLoading: false,
  oneArticle: null,
  oneArticleLoading: false,
  sendRequestLoading: false,
  acceptRequestLoading: false,
  requestAccessToAccept: null,
  addDemcoProductOrderLoading: false,
  saveCustomizedProductLoading: false,
  getCompanysOrderLoading: false,
  next_page: null,
  prev_page: null,
  total: null,
  order_type: {
    type: "direct_order",
    key: "1",
  },
  clickedOrder: null,
};

const OrderReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case ADD_COLLABORATOR_SUCCESS:
      return {
        ...state,
        orders: state.orders.map((el: any) =>
          el.id === action.order_id
            ? { ...el, collaborators: [...action.payload, ...el.collaborators] }
            : el
        ),
      };
    case "SELECT_ORDER":
      console.log("Action:", action); // Log the action

      return {
        ...state,
        selectedOrder: action.payload,
      };
    case SAVE_CUSTOMIZED_PRODUCT_SUCCESS:
      return {
        ...state,
        saveCustomizedProductLoading: false,
      };

    case SAVE_CUSTOMIZED_PRODUCT_REQUEST:
      return {
        ...state,
        saveCustomizedProductLoading: true,
      };
    case ADD_NORMAL_ORDER_COMMENT_SUCCESS:
      return {
        ...state,
        commentsLoading: false,
        oneArticle: {
          ...state.oneArticle,
          products: state.oneArticle.products.map((product: any) =>
            product.id === action.payload.product_id
              ? {
                  ...product,
                  status: {
                    //@ts-ignore
                    ...product.status,
                    comments: [action.payload, ...product.status.comments],
                  },
                }
              : product
          ),
        },
      };
    case ADD_DEMCO_PRODUCT_ORDER_REQUEST:
      return {
        ...state,
        addDemcoProductOrderLoading: true,
      };
    case ADD_DEMCO_PRODUCT_ORDER_SUCCESS:
      return {
        ...state,
        addDemcoProductOrderLoading: false,
      };
    case ACCEPT_REQ_ACCESS_REQUEST:
      return {
        ...state,
        acceptRequestLoading: true,
        requestAccessToAccept: action.payload,
      };
    case ACCEPT_REQ_ACCESS_SUCCESS:
      return {
        ...state,
        acceptRequestLoading: false,
        requestAccessToAccept: null,
        orders: state.orders.map((order: any) =>
          order.id === action.payload.direct_order_id
            ? {
                ...order,
                collaborators: [
                  ...order.collaborators,
                  action.payload.client_id,
                ],
              }
            : order
        ),
      };
    case SEND_REQ_ACCESS_REQUEST:
      return {
        ...state,
        sendRequestLoading: true,
      };
    case SEND_REQ_ACCESS_SUCCESS:
      return {
        ...state,
        sendRequestLoading: false,
      };
    case GET_ONE_ARTICLE_REQUEST:
      return {
        ...state,
        oneArticleLoading: true,
      };
    case GET_ONE_ARTICLE_SUCCESS:
      return {
        ...state,
        oneArticle: action.payload,
        oneArticleLoading: false,
      };
    case ADD_COMMENT_REQUEST:
    case ADD_NORMAL_ORDER_COMMENT_REQUEST:
      return {
        ...state,
        commentsLoading: true,
      };
    case ADD_COMMENT_SUCCESS:
      return {
        ...state,
        commentsLoading: false,
        comments: action.payload,
      };
    case ADD_FEEDBACK_REQUEST:
      return {
        ...state,
        feedbackLoading: true,
      };
    case ADD_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackLoading: false,
      };
    case GET_STATUS_REQUEST:
      return {
        ...state,
        getStatusLoading: true,
      };
    case GET_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload,
        getStatusLoading: false,
      };
    case GET_ONE_ORDER_REQUEST:
      return {
        ...state,
        getOneOrderLoading: true,
      };
    case GET_ONE_ORDER_SUCCESS:
      return {
        ...state,
        getOneOrderLoading: false,
        oneOrder: action.payload,
      };
    case DELETE_COLLABORATOR_REQUEST:
      return {
        ...state,
        deleteCollaboratorLoading: true,
      };
    case DELETE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        collaborators: action.payload,
        orders: state.orders.map((el: any) =>
          el.id === action.orderId
            ? {
                ...el,
                collaborators: action.payload,
              }
            : el
        ),
        deleteCollaboratorLoading: false,
      };
    case GET_ORDER_COLLABORATOR_REQUEST:
      return {
        ...state,
        getOrderCollaboratorsLoading: true,
      };
    case GET_ORDER_COLLABORATOR_SUCCESS:
      return {
        ...state,
        getOrderCollaboratorsLoading: false,
        collaborators: action.payload,
      };
    case GET_COLLABORATOR_REQUEST:
      return {
        ...state,
        getCollaboratorsLoading: true,
      };
    case GET_COLLABORATOR_SUCCESS:
      return {
        ...state,
        getCollaboratorsLoading: false,
        collaborators: action.payload,
      };
    case DELETE_ORDER_REQUEST:
      return {
        ...state,
        deleteOrderLoading: true,
      };
    case "CLEAR_NEW_ORDER":
      return {
        ...state,
        newOrder: null,
      };
    case ADD_ORDER_REQUEST:
      return {
        ...state,
        createOrderLoading: true,
      };
    case CREATE_ARTICLE_REQUEST:
      return {
        ...state,
        createArticleLoading: true,
      };
    case UPDATE_ARTICLE_REQUEST:
      return {
        ...state,
        updateArticleLoading: true,
      };
    case DELETE_ARTICLE_REQUEST:
      return {
        ...state,
        deleteArticleLoading: true,
        toDeleteArticle: action.payload.id,
      };
    case DELETE_OPTION_REQUEST:
      return {
        ...state,
        deleteOptionLoading: {
          isLoading: true,
          id: action.payload.id,
        },
      };
    case DUPLICATE_OPTION_REQUEST:
    case UPDATE_OPTION_REQUEST:
      return {
        ...state,
        updateOptionLoading: true,
      };
    case GET_OPTION_REQUEST:
      return {
        ...state,
        getOptionLoading: true,
      };
    case GET_COMPANY_ORDER_REQUEST:
      return {
        ...state,
        getCompanysOrderLoading: true,
      };
    case GET_ORDER_REQUEST:
    case GET_NORMAL_ORDER_REQUEST:
    case UPDATE_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NORMAL_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orders: action.payload.data,
      };
    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        createOrderLoading: false,
        newOrder: action.payload,
        orders: [{ ...action.payload, articles: [] }, ...state.orders],
        collaborators: action.payload.collaborators,
      };
    case GET_COMPANY_ORDER_SUCCESS:
      return {
        ...state,
        getCompanysOrderLoading: false,
        orders: action.payload.data,
        next_page: action.payload.next_page_url,
        prev_page: action.payload.prev_page_url,
        total: action.payload.total,
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newOrder: null,
        orders: action.payload,
        selectedArticle:
          action.payload[0] && action.payload[0].articles[0]
            ? action.payload[0].articles[0].id
            : null,

        // selectedArticle: action.payload.data.find((el: any) => el.articles).id,
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        deleteOrderLoading: false,
        orders: state.orders.filter(
          (order: any) => order.id !== action.payload
        ),
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orders: state.orders.map((el: any) =>
          el.id === action.payload.id ? { ...el, ...action.payload } : el
        ),
      };
    case CREATE_ARTICLE_SUCCESS:
      if (action.payload.fromNewOrder) {
        return {
          ...state,
          createArticleLoading: false,
          orders: state.orders.map((el: any) =>
            el.id === action.payload.data.order_id
              ? {
                  ...el,
                  articles: [
                    { ...action.payload.data, options: [] },
                    ...el.articles,
                  ],
                }
              : el
          ),
        };
      } else {
        return {
          ...state,
          createArticleLoading: false,
          // orders: state.orders.map((order: any) =>
          //   order.id === action.payload.data.order_id
          //     ? { ...order, articles: [...order.articles, action.payload.data] }
          //     : order
          // ),
          // orders: state.orders.map((order: any) =>
          //   order.id === action.payload.data.order_id
          //     ? {
          //         ...order,
          //         articles: order.articles.concat(action.payload.data),
          //       }
          //     : order
          // ),
        };
      }
    case UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        updateArticleLoading: false,
        orders: state.orders.map((order: any) =>
          order.id === action.payload.order_id
            ? {
                ...order,
                articles: order.articles.map((el: any) =>
                  el.id === action.payload.id
                    ? { ...el, ...action.payload }
                    : el
                ),
              }
            : order
        ),
      };
    case UPDATE_OPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedOption: action.payload,
        updateOptionLoading: false,
        orders: state.orders.map((order: any) => {
          return {
            ...order,
            articles: order.articles.map((article: any) =>
              article.id === action.payload.order_article_id
                ? {
                    ...article,
                    options: article.options.map((option: any) =>
                      option.id === action.payload.id ? action.payload : option
                    ),
                  }
                : article
            ),
          };
        }),
      };
    case DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        toDeleteArticle: null,
        deleteArticleLoading: false,
        orders: state.orders.map((el: any) => {
          return {
            ...el,
            articles: el.articles.filter(
              (article: any) => article.id !== action.payload.id
            ),
          };
        }),
      };
    case DELETE_OPTION_SUCCESS:
      return {
        ...state,
        orders: state.orders.map((el: any) => {
          return {
            ...el,
            articles: el.articles.map((article: any) =>
              article.id === Number(action.payload.order_article_id)
                ? {
                    ...article,
                    options: article.options.filter(
                      (x: any) => x.id !== action.payload.id
                    ),
                  }
                : article
            ),
          };
        }),
        selectedOption:
          state.selectedOption.id === action.payload.id
            ? "new"
            : state.selectedOption,
        deleteOptionLoading: false,
      };
    case DELETE_OPTION_FILE_SUCCESS:
      let optionUpdated = action.payload.arrFiles.map((elem: any) => {
        return {
          ...action.payload.option,
          [elem]: null,
        };
      });
      return {
        ...state,
        orders: state.orders.map((el: any) => {
          return {
            ...el,
            articles: el.articles.map((article: any) =>
              article.id === action.payload.option.order_article_id
                ? {
                    ...article,
                    options: article.options.map((option: any) =>
                      option.id === optionUpdated[0].id
                        ? optionUpdated[0]
                        : option
                    ),
                  }
                : article
            ),
          };
        }),
      };
    case "CLEAR_SELECTED_OPTION":
      return {
        ...state,
        selectedOption: null,
      };
    case DUPLICATE_OPTION_SUCCESS:
      return {
        ...state,
        errors: null,
        updateOptionLoading: false,
        selectedOption: action.payload,
        orders: state.orders.map((el: any) => {
          return {
            ...el,
            articles: el.articles.map((article: any) =>
              article.id === Number(action.payload.order_article_id)
                ? {
                    ...article,
                    options: article.options.concat(action.payload),
                  }
                : article
            ),
          };
        }),
      };
    case GET_ORDER_FAIL:
    case UPDATE_ORDER_FAIL:
    case UPDATE_OPTION_FAIL:
    case GET_OPTION_FAIL:
    case DELETE_OPTION_FAIL:
    case DUPLICATE_OPTION_FAIL:
    case DELETE_ARTICLE_FAIL:
    case DELETE_OPTION_FILE_FAIL:
    case UPDATE_ARTICLE_FAIL:
    case CREATE_ARTICLE_FAIL:
    case ADD_ORDER_FAIL:
    case DELETE_ORDER_FAIL:
    case GET_COLLABORATOR_FAIL:
    case GET_ORDER_COLLABORATOR_FAIL:
    case DELETE_COLLABORATOR_FAIL:
    case GET_ONE_ORDER_FAIL:
    case GET_STATUS_FAIL:
    case ADD_FEEDBACK_FAIL:
    case ADD_COMMENT_FAIL:
    case SEND_REQ_ACCESS_FAIL:
    case ACCEPT_REQ_ACCESS_FAIL:
    case ADD_DEMCO_PRODUCT_ORDER_FAIL:
    case GET_NORMAL_ORDER_FAIL:
    case ADD_NORMAL_ORDER_COMMENT_FAIL:
    case SAVE_CUSTOMIZED_PRODUCT_FAIL:
    case GET_COMPANY_ORDER_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        updateOptionLoading: false,
        deleteOptionLoading: false,
        deleteArticleLoading: false,
        toDeleteArticle: null,
        updateArticleLoading: false,
        createArticleLoading: false,
        createOrderLoading: false,
        deleteOrderLoading: false,
        getCollaboratorsLoading: false,
        getOrderCollaboratorsLoading: false,
        deleteCollaboratorLoading: false,
        getOneOrderLoading: false,
        getStatusLoading: false,
        feedbackLoading: false,
        commentsLoading: false,
        sendRequestLoading: false,
        acceptRequestLoading: false,
        requestAccessToAccept: null,
        addDemcoProductOrderLoading: false,
        saveCustomizedProductLoading: false,
        getCompanysOrderLoading: false,
      };
    case SELECT_ARTICLE:
      return {
        ...state,
        selectedArticle: action.payload,
      };
    case GET_OPTION_SUCCESS:
      return {
        ...state,
        selectedOption: action.payload,
        getOptionLoading: false,
        orders: state.orders.map((order: any) => {
          return {
            ...order,
            articles: order.articles.map((article: any) =>
              article.id == action.payload.order_article_id
                ? {
                    ...article,
                    options: article.options.map((option: any) =>
                      option.id == action.payload.id ? action.payload : option
                    ),
                  }
                : article
            ),
          };
        }),
      };
    case SELECT_OPTION:
      return {
        ...state,
        selectedOption: action.payload.data,
        selectedArticle: action.payload.articleId,
        getOptionLoading: false,
      };
    case CHANGE_ORDER_TYPE_TAB:
      return {
        ...state,
        order_type: action.payload,
      };
    default:
      return state;
  }
};

export default OrderReducer;
