import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./VideoModal.scss";

export const VideoModal = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="WE258-tFr9o"
        onClose={() => setOpen(false)}
      />

      <button className="header__play_btn" onClick={() => setOpen(true)}>
        <FontAwesomeIcon icon={faPlayCircle} className="mr-2" />
        Play full video
      </button>
    </React.Fragment>
  );
};
