import { ProductCard } from "./ProductCard";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ProductList.scss";
import AliceCarousel from "react-alice-carousel";
import { useSelector } from "react-redux";
import "react-alice-carousel/lib/alice-carousel.css";
import axiosInstance from "../../config/axios-instance";
import { useEffect, useState } from "react";
import customization_tool from "../../img/customization_tool.png";

import axios from "axios";

const denimItems = [
  {
    main: "/assets/denim/denim4_front.png",
    onHover: "/assets/denim/denim4_back.png",
  },
  {
    main: "/assets/denim/denim1_front.png",
    onHover: "/assets/denim/denim1_back.png",
  },
  {
    main: "/assets/denim/denim2_front.png",
    onHover: "/assets/denim/denim2_back.png",
  },
  {
    main: "/assets/denim/denim3_front.png",
    onHover: "/assets/denim/denim3_back.png",
  },
  {
    main: "/assets/denim/denim5_front.png",
    onHover: "/assets/denim/denim5_back.png",
  },
];

const customItems = [
    {
      main: "/assets/custom/customization_tool.png",
      onHover: "/assets/custom/customization_tool.png",
    },
    {
      main: "/assets/custom/customization_tool.png",
      onHover: "/assets/custom/customization_tool.png",
    },
    {
      main: "/assets/custom/customization_tool.png",
      onHover: "/assets/custom/customization_tool.png",
    },
  
  ];
const knitItems = [
  {
    main: "/assets/knit/knit1_front.png",
    onHover: "/assets/knit/knit1_back.png",
  },
  {
    main: "/assets/knit/knit2_front.png",
    onHover: "/assets/knit/knit2_back.png",
  },
  {
    main: "/assets/knit/knit3_front.png",
    onHover: "/assets/knit/knit3_back.png",
  },
  {
    main: "/assets/knit/knit4_front.png",
    onHover: "/assets/knit/knit4_back.png",
  },
  {
    main: "/assets/knit/knit5_front.png",
    onHover: "/assets/knit/knit5_back.png",
  },
];

type arrowTypes = {
  onClick?: (e: React.MouseEvent) => void;
};

const CustomLeftArrow = ({ onClick }: arrowTypes) => (
  <FontAwesomeIcon
    icon={faCaretLeft}
    onClick={onClick}
    className="custom_left_arrow"
  />
);
const CustomRightArrow = ({ onClick }: arrowTypes) => (
  <FontAwesomeIcon
    className="custom_right_arrow"
    onClick={onClick}
    icon={faCaretRight}
  />
);

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  768: { items: 1 },
  1024: { items: 2 },
  1920: { items: 3 },
};
export interface BannerInfoProps {
  bannerInfo: any;
}
export const ProductCustomList = ( bannerInfo : any) => {
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const [itemsDenim, setItems] = useState([]);

     const fetchDenim = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/clients/me/products`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setItems(res.data);
      console.log(itemsDenim);
    });
  }; 

  const [itemsProducts, setIteams] = useState([]);
  useEffect(() => {

    let token = localStorage.getItem("token");
    axios
      .get(process.env.REACT_APP_API_URL + `/products/lastProduct?fabric_type=${user.fabric_type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setIteams(res.data);
      });
  }, []);

  const responsive = {
    0: { items: 1 },
    1024: { items: 1 },
  };

  const items = [
    /*     <img src={jeansImg} />,
     */ /*<img src={customization_tool} width={"100%"} height={"100%"}/>,*/
    <img src={process.env.REACT_APP_STORAGE_URL+bannerInfo.customContent?.media} />,
    // <img src={jeansImgW} />
  ];

  return (
    <AliceCarousel
      autoPlay={false}
      autoPlayInterval={50000}
      infinite={false}
      disableButtonsControls={true}
      items={items}
      disableDotsControls={true}
      responsive={responsive}
    />
  );
};
