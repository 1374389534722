import { Button } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import { Link } from "react-router-dom";
import Jeans from "../../icons/Jeans";

export default function DirectOrderContentRow({ item, url }: any) {
  const cancelledCount = Math.abs(
    item.article_options?.length - item.options?.length
  );
  return (
    <tr>
      <td scope="row">
        <Jeans />
        <span className="ml-2 myorder_order_name">{item.name}</span>
      </td>
      <td>{moment(item.updated_at).format("DD/MM/YYYY")}</td>
      <td>
        <p style={{ marginBottom: "0px", fontSize: "0.8rem" }}>
          {item.article_options?.length} ongoing option
          {item.article_options?.length > 1 ||
          item.article_options?.length === 0
            ? "s"
            : ""}
        </p>
        <p style={{ marginBottom: "0px", fontSize: "0.8rem" }}>
          {" "}
          {cancelledCount} cancelled option
          {cancelledCount > 1 || cancelledCount === 0 ? "s" : ""}
        </p>
      </td>
      <td>
        {item.article_options?.length === 0 ? (
          <p className="mb-0" style={{ color: "red" }}>
            All options are cancelled
          </p>
        ) : (
          <Link to={`${url}/${item.id}`}>
            <Button
              type="ghost"
              className="see_more_order_details_btn"
              style={{ height: "50px !important" }}
            >
              See More
            </Button>
          </Link>
        )}
      </td>
    </tr>
  );
}
