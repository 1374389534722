import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { DirectOrderTableRow } from "./DirectOrderTableRow";
import { NormalOrderTableRow } from "./NormalOrderTableRow";

interface Props {
  order: any;
  width: any;
}

export const MyOrdersTableRow = ({ order, width }: Props) => {
  const [articleStatus, setArticleStatus] = useState<any>(null);
  const [statusMsg, setStatusMsg] = useState({
    color: "black",
    msg: "",
  });
  const { status, getStatusLoading } = useSelector(
    (state: any) => state.orders
  );

  //   Get the article status
  useEffect(() => {
    if (
      order.articles &&
      order.articles[0] &&
      order.articles[0].article_status_id &&
      !getStatusLoading
    ) {
      setArticleStatus(
        status.find((x: any) => x.id === order.articles[0].article_status_id)
          .name
      );
    }
  }, [order]);

  //   Status Msg to display
  useEffect(() => {
    if (articleStatus) {
      switch (articleStatus) {
        case "PROTO":
          setStatusMsg({
            color: "#BCBCBD",
            msg: "Your prototype is being prepared",
          });
          break;
        case "PRODUCTION":
        case "OK PROD":
          setStatusMsg({ color: "#06C170", msg: "Your order is in process" });
          break;
        case "CANCELLED":
          setStatusMsg({ color: "#FF3A3A", msg: "Your order is canceled" });
          break;
        default:
          setStatusMsg({
            color: "#FF872A",
            msg: "We are waiting for your answer",
          });
      }
    }
  }, [articleStatus]);

  return order.articles ? (
    <DirectOrderTableRow
      order={order}
      getStatusLoading={getStatusLoading}
      articleStatus={articleStatus}
      statusMsg={statusMsg}
      width={width}
    />
  ) : order.products ? (
    <NormalOrderTableRow
      order={order}
      getStatusLoading={getStatusLoading}
      articleStatus={articleStatus}
      statusMsg={statusMsg}
    />
  ) : null;
};
