import { Avatar } from "antd";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";

export default function CustomizedOrderProducts({ data }: any) {
  console.log(data);
  const history = useHistory();
  return (
    data &&
    data?.data.data.map((product: any, i: number) => (
      <tr key={i}>
        <td>
          <Avatar
            src={process.env.REACT_APP_STORAGE_URL + product.front_image}
            size={54}
            alt={product.name}
            shape="square"
          />
          <Link
            to={`/product/details/${product.name}/${product.id}`}
            className="ml-3"
          >
            {product.code
              ? product.code + " " + product.name + " " + product.style
              : product.name + " (" + product.style + ")"}
          </Link>
        </td>
        <td>
          <p className="mb-0">{product.status.name}</p>
        </td>
        <td>
          <p className="mb-0">
            {moment(data && product.created_at).format("MMMM DD YYYY")}
          </p>
        </td>
        <td align="center">
          <Link
            to={`${history.location.pathname}/${product.id}/progress/customized`}
          >
            See details
          </Link>
        </td>
      </tr>
    ))
  );
}
