import FabricFilter from "./FabricFilter";
import TrimsFilter from "./TrimsFilter";
import {CloseOutlined, FilterOutlined} from "@ant-design/icons";
// import fabricIcon from "../../img/fabric-icon.svg";
// import trimsIcon from "../../img/trims-icon.svg";
// import { Button } from "antd";

const Sidebar = ({
  isTrims,
  setIsDenim,
  isDenim,
  colors,
  colorsIsLoading,
  elasticityIsLoading,
  elasticity,
  weightIsLoading,
  weight,
  fiberIsLoading,
  fibers,
  checkboxChange,
  setMin_price_before,
  setMax_price_before,
  queryFabricType,
  compositionsIsLoading,
  compositions,
  fabriccolors,
  fabricmill,
  price,
  fabricType,
  specialFiber,
  prices,
  handleChangePriceAfter,
  handleChangeWeightAfter,
  handleChangeElasticityAfter,
  weight_value,
  elasticity_value,
}: any) => {
  return (
    <div id="side" className="pt-2">
      <a href="#pop" style={{position:"absolute",right:0}}>

        <CloseOutlined  style={{color:"#182844"}} className=" material-icons-sharp menu-icon mr-2" />


{/*
      <span className="material-icons-sharp menu-icon">menu</span>
*/}
      </a>
      <div className="side-content">
        <nav>
          {/*           <h1 id="nav-header">DEMCO’s library </h1>
           */}{" "}
          {/* BUTTONS FABRIC & TRIMS DON'T DELETE THIS SECTION */}
          {/* <div className="d-flex my-4">
            <Button
              icon={<img src={fabricIcon} className="mr-2" />}
              className={`library-btn d-flex align-items-center mr-2 p-4 rounded ${
                !isTrims ? "library-active" : undefined
              }`}
              onClick={() => setIsTrims(false)}
            >
              Our fabrics
            </Button>
            <Button
              icon={<img src={trimsIcon} className="mr-2" />}
              className={`library-btn d-flex align-items-center mr-2 p-4 rounded ${
                isTrims ? "library-active" : undefined
              }`}
              onClick={() => setIsTrims(true)}
            >
              Our trims
            </Button>
          </div> */}
          <div className="sidebar-ul">
            {isTrims ? (
              // TRIMS SIDEBAR
              <TrimsFilter />
            ) : (
              // FABRIC SIDEBAR
              <FabricFilter
                setIsDenim={setIsDenim}
                isDenim={isDenim}
                colors={colors}
                colorsIsLoading={colorsIsLoading}
                elasticityIsLoading={elasticityIsLoading}
                elasticity={elasticity}
                weightIsLoading={weightIsLoading}
                weight={weight}
                // fiberIsLoading={fiberIsLoading}
                // fibers={fibers}
                prices={prices}
                checkboxChange={checkboxChange}
                queryFabricType={queryFabricType}
                compositionsIsLoading={compositionsIsLoading}
                compositions={compositions}
                fabricmill={fabricmill}
                fabriccolors={fabriccolors}
                fabricType={fabricType}
                specialFiber={specialFiber}
                handleChangePriceAfter={handleChangePriceAfter}
                handleChangeWeightAfter={handleChangeWeightAfter}
                handleChangeElasticityAfter={handleChangeElasticityAfter}
                weight_value={weight_value}
                elasticity_value={elasticity_value}
              />
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
