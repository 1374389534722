import React from "react";

export type ISelectIconProps = {};

const SelectIcon: React.FC<ISelectIconProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_244_1407)">
        <path
          d="M19.707 18.2929L14.4139 12.9999L17.707 9.70692C17.952 9.46192 18.0529 9.10792 17.9729 8.77092C17.8929 8.43392 17.6449 8.16192 17.3159 8.05192L5.31595 4.05192C4.95595 3.93292 4.55995 4.02592 4.29295 4.29392C4.02495 4.56092 3.93195 4.95692 4.05095 5.31592L8.05095 17.3159C8.16095 17.6439 8.43295 17.8929 8.76995 17.9729C8.84695 17.9909 8.92295 17.9999 8.99995 17.9999C9.26195 17.9999 9.51795 17.8959 9.70695 17.7069L12.9999 14.4139L18.2929 19.7059L19.707 18.2929ZM9.43695 15.1479L6.58095 6.58192L15.1489 9.43692L9.43695 15.1479Z"
          fill="#182844"
        />
      </g>
      <defs>
        <clipPath id="clip0_244_1407">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { SelectIcon };
