import { useEffect, useState } from "react";

import "./certification.css";

function Welcome(props) {
  const [certification, setItems] = useState();
  useEffect(() => {
    const getCertification = async () => {
      const res = await fetch(
        process.env.REACT_APP_API_URL + `/all-certification`
      );
      const data = await res.json();
      console.log(data);
      setItems(data);
    };

    getCertification();
  }, []);

  useEffect(() => {
    /*    const scrollContainer = document.getElementById("slider");

    scrollContainer.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      scrollContainer.scrollLeft += evt.deltaY;
    }); */

    const slider = document.getElementById("slider");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1.5; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });
  }, []);
  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 1000;
  };
  const slideright = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 1000;
  };
  return (
    <>
      <section
        class="timeline "
        id="slider"
        style={{
          cursor: "pointer",
        }}
      >
        <ol>
          {certification?.map((item, i) => (
            <li key={i}>
              <div className="content"  key={i}>
                <time>{item.year}</time> {item.name}
                <p  key={i}>{item.description}</p>
                <p>
                  {item.images?.map((item, i) => (
                    <img
                      src={process.env.REACT_APP_STORAGE_URL + item.path}
                      alt=""
                      className="image"
                    />
                  ))}
                </p>
              </div>
            </li>
          ))}
          <li></li>
        </ol>
      </section>
      {/*  <div class="inner-element">
        <button class="arrow arrow__prev disabled" onClick={slideLeft}>
          <img src="{}" alt="prev timeline arrow" />
        </button>
        <div class="space" />
        <button class="arrow arrow__next" onClick={slideright}>
          <img src="{}" alt="next timeline arrow" />
        </button>
      </div> */}
    </>
  );
}
export default Welcome;
