import {
  faArrowLeft,
  faCheck,
  faEllipsisV,
  faMinus,
  faPen,
  faPlus,
  faShoppingBag,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Popconfirm,
  Tooltip,
  Spin,
  Select,
  Drawer,
  Avatar,
  DatePicker,
  Input,
} from "antd";
import {ArrowLeftOutlined, MinusOutlined} from "@ant-design/icons";

import React, { useEffect, useState } from "react";
import "../../sass/AddNewOrder.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addCollaborator,
  clearSelectedOption,
  deleteCollaborator,
  deleteOneOption,
  deleteOrder,
  getOrderCollaborators,
  updateOrder,
} from "../../actions/order-actions/actions";
import { AnimatePresence, motion } from "framer-motion";
import { AddCollabDrawer } from "./AddCollabDrawer";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import { LoadingOutlined } from "@ant-design/icons";
// import { useHistory } from "react-router-dom";
import order_sent from "../../img/order_sent.jpg";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { history } from "../../index";
import { useParams } from "react-router-dom";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteOneArticle } from "../../actions/order-actions/actions";
import { default as SelectCollaborator } from "react-select";
import "./AddOrder.scss";
import axiosInstance from "../../config/axios-instance";
const { Option } = Select;

interface Props {
  width: number;
  order?: any;
  orderToEdit?: any;
  edit: boolean;
  collaborators?: any;
  // getOrderCollaboratorsLoading?: any;
  setOrderToEdit?: any;
}

export const AddOrderHeader = ({
  width,
  orderToEdit,
  edit,
  order,
  // getOrderCollaboratorsLoading,
  setOrderToEdit,
}: Props) => {
  const [sendingOrderLoading, setSendingOrderLoading] = useState(false);
  const [addInput, setAddInput] = useState(false);
  const [editName, setEditName] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [updated, setUpdated] = useState<any>(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [delivery_date, setDelivery_date] = useState("");
  const [collection_name, setOrderName] = useState(orderToEdit.name);
  const [season, setSeason] = useState(orderToEdit.season);
  const [state, setState] = useState<any>({
    value: [],
    data: [],
    fetching: false,
  });
  const [lastFetchId, setLastFetchId] = useState(0);

  const dispatch = useDispatch();
  const params: any = useParams();
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const [listCollaborators, setCollaborators] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const { collaborators, getOrderCollaboratorsLoading } = useSelector(
    (state: any) => state.orders
  );
  // const history = useHistory();
  const variants = {
    enter: () => {
      return {
        opacity: 0,
        width: 0,
      };
    },
    center: () => {
      return {
        opacity: 1,
        width: "auto",
      };
    },
    exit: () => {
      return {
        opacity: 0,
        width: 0,
      };
    },
  };

  function openDrawer() {
    fetchCollaborators();
    setIsOpenDrawer(true);
  }
  const deleteArt = (article: any) => {
    dispatch(deleteOneArticle(article));
  };

  const deleteOpt = (option: any) => {
    dispatch(deleteOneOption(option));
  };

  useEffect(() => {
    // if (newOrder) {
    // setOrderCreated(newOrder);
    dispatch(getOrderCollaborators(params.id));
    // }
  }, [params.id]);

  const confirmDeleteOrder = () => {
    if (orderToEdit) {
      if (isAdmin) {
        dispatch(deleteOrder(orderToEdit[0].id));
      } else {
        dispatch(deleteOrder(orderToEdit.id));
      }
    } else {
      dispatch(deleteOrder(order.id));
    }
    // history.push("/direct-order");
    history.back();
  };

  useEffect(() => {
    if (edit && !order) {
      if (isAdmin) {
        setUpdated(orderToEdit[0].name);
      } else {
        setUpdated(orderToEdit.name);
      }
    } else if (!edit && order) {
      setUpdated(order.name);
    } else {
      setUpdated("");
    }
  }, [order]);

  const handleChange = (e: any) => {
    setUpdated(e.target.value);
  };

  function handleChangeOrderName(e: any) {
    setOrderName(e.target.value);
  }

  function handleChangeSeason(e: any) {
    setSeason(e.target.value);
  }
  const fetchUser = (value: any) => {
    setLastFetchId(lastFetchId + 1);
    const fetchId = lastFetchId;
    setState({ ...state, data: [], fetching: true });
    let token = localStorage.getItem("token");
    fetch(process.env.REACT_APP_API_URL + `/collaborators?name=${value}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((body) => {
        if (fetchId !== lastFetchId) {
          // for fetch callback order
          return;
        }
        const users = body
          .map((user: any) => ({
            id: user.id,
            name: user.name,
            picture: user.logo,
          }))
          .filter(
            (el: any) =>
              !state.value.find((x: any) => x.key === el.id) &&
              !orderToEdit.collaborators.find(
                (collab: any) => collab.id === el.id
              )
          );
        setState({ ...state, data: users, fetching: false });
      });
  };

  const handleChangeSelect = (value: any) => {
    setState({
      value,
      data: [],
      fetching: false,
    });
  };

  const fetchCollaborators = () => {
    // setLoadingClients(true);
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/collaborators`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res: any) => {
        // setLoadingClients(false);
        setCollaborators(res.data);
        //  setCollaborators(res.data);
      })
      .catch((err: any) => {
        //  setLoadingClients(false);
        console.log(err);
      });
  };

  return width > 575 ? (
    <header className="add_order_header">
      <div className="add_order_name">
      {/*  <button
          className="mr-3"
          onClick={() => {
            dispatch(clearSelectedOption());
            // history.push("/direct-order");
            history.back();
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>*/}

        <>

          <div className={
            "faq_go_back"
          }
               style={{ cursor: "pointer" ,marginRight:"10px"}}
               onClick={() => {
                 dispatch(clearSelectedOption());
                 // history.push("/direct-order");
                 history.back();
               }}
          >

            <ArrowLeftOutlined

            ></ArrowLeftOutlined>{" "}
            <button
                className={"go_back_text"}

            >
              Go back
            </button>
          </div>

        </>
        {/* <img
          src={backArrow}
          className="back_arrow_folder mr-2"
          alt="back arrow"
          
        /> */}
        {isAdmin ? (
          <p>{updated}</p>
        ) : editName ? (
          <>
            <input
              value={updated && updated}
              className="edit_order_name_input"
              onChange={handleChange}
            />
            <FontAwesomeIcon
              icon={faCheck}
              onClick={() => {
                if (edit) {
                  if (isAdmin) {
                    dispatch(updateOrder({ name: updated }, orderToEdit[0].id));
                  } else {
                    dispatch(updateOrder({ name: updated }, orderToEdit.id));
                  }
                  setEditName(false);
                }
              }}
            />
          </>
        ) : (
          <>
            <p className={"direct_order_name_header"}> {updated}</p>
            {edit ? (
              orderToEdit &&
              (orderToEdit.client_id === user.id ||
                (isAdmin &&
                  orderToEdit[0].commercials.find(
                    (x: any) => x.id === user.id
                  ))) && (
                <FontAwesomeIcon
                  icon={faPen}
                  onClick={() => setEditName(true)}
                />
              )
            ) : (
              <FontAwesomeIcon icon={faPen} onClick={() => setEditName(true)} />
            )}
          </>
        )}
      </div>
      <div className="add_order_actions">
        {!showDrawer && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="add_order_collabs"
          >
            {edit && getOrderCollaboratorsLoading ? (
              <div className="new_collab_img_container">
                <div className="new_collab_loading">
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                </div>
              </div>
            ) : orderToEdit.collaborators &&
              orderToEdit.collaborators.length >= 5 ? (
              orderToEdit.collaborators
                .filter((el: any) => el.id !== user.id)
                .slice(0, 5)
                // .fitler((x: any) => x)
                .map((el: any, i: number) => (
                  <Popconfirm
                    placement="topRight"
                    title={"Are you sure you want to delete this member ?"}
                    // onConfirm={(el: any) => confirm(el)}
                    onConfirm={() => {
                      dispatch(
                        deleteCollaborator(
                          edit ? orderToEdit.id : order.id,
                          orderToEdit.collaborators.filter(
                            (x: any) => x.id !== el.id
                          )
                        )
                      );
                    }}
                    okText="Yes"
                    cancelText="No"
                    key={i}
                  >
                    <div className="new_collab_img_container">
                      <img
                        src={
                          el.logo
                            ? process.env.REACT_APP_STORAGE_URL + el.logo
                            : avatar_placeholder
                        }
                        alt="user"
                        className="new_collab"
                      />
                      <div className="minus_div">
                        <FontAwesomeIcon icon={faMinus} />
                      </div>
                    </div>
                  </Popconfirm>
                ))
            ) : isAdmin ? (
              <Avatar.Group
                maxCount={5}
                size="large"
                maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              >
                {orderToEdit[0].collaborators.map((colab: any) => (
                  <Tooltip title={colab.name} placement="bottom" key={colab.id}>
                    <Avatar
                      src={
                        colab.logo
                          ? process.env.REACT_APP_STORAGE_URL + colab.logo
                          : avatar_placeholder
                      }
                    />
                  </Tooltip>
                ))}
              </Avatar.Group>
            ) : (
              <Avatar.Group
                maxCount={5}
                size="large"
                maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              >
                {orderToEdit.collaborators &&
                  orderToEdit.collaborators
                    .filter((el: any) => el.id !== user.id)
                    .concat(orderToEdit.commercials)
                    .map((colab: any) => {
                      return orderToEdit &&
                        orderToEdit?.client_id === user.id ? (
                        <Popconfirm
                          placement="topRight"
                          disabled={colab?.type === "commercial"}
                          title={
                            "Are you sure you want to delete this member ?"
                          }
                          onConfirm={() => {
                            dispatch(
                              deleteCollaborator(
                                edit ? orderToEdit?.id : order?.id,
                                orderToEdit?.collaborators?.filter(
                                  (x: any) => x.id !== colab?.id
                                )
                              )
                            );
                          }}
                          okText="Yes"
                          cancelText="No"
                          key={colab?.id}
                          className={
                            colab?.hasOwnProperty("type")
                              ? undefined
                              : "collaborateur-avatar"
                          }
                        >
                          <Tooltip
                            title={colab?.name}
                            placement="bottom"
                            key={colab?.id}
                          >
                            <Avatar
                              style={{ cursor: "pointer" }}
                              src={
                                colab?.logo
                                  ? process.env.REACT_APP_STORAGE_URL +
                                    colab?.logo
                                  : colab?.avatar
                                  ? process.env.REACT_APP_STORAGE_URL +
                                    colab?.avatar
                                  : avatar_placeholder
                              }
                            />
                          </Tooltip>
                        </Popconfirm>
                      ) : (
                        <Tooltip
                          title={colab?.name}
                          placement="bottom"
                          key={colab?.id}
                        >
                          <Avatar
                            style={{ cursor: "pointer" }}
                            src={
                              colab?.logo
                                ? process.env.REACT_APP_STORAGE_URL +
                                  colab?.logo
                                : colab?.avatar
                                ? process.env.REACT_APP_STORAGE_URL +
                                  colab?.avatar
                                : avatar_placeholder
                            }
                          />
                        </Tooltip>
                      );
                    })}
              </Avatar.Group>
              // orderToEdit.collaborators &&
              // orderToEdit.collaborators
              //   .filter((el: any) => el.id !== user.id)
              //   // .concat(orderToEdit.commercials[0])
              //   .map((el: any) => {
              //     return orderToEdit && orderToEdit.client_id === user.id ? (
              //       <Popconfirm
              //         placement="topRight"
              //         disabled={el.avatar}
              //         title={"Are you sure you want to delete this member ?"}
              //         onConfirm={() => {
              //           dispatch(
              //             deleteCollaborator(
              //               edit ? orderToEdit.id : order.id,
              //               orderToEdit.collaborators.filter(
              //                 (x: any) => x.id !== el.id
              //               )
              //             )
              //           );
              //         }}
              //         okText="Yes"
              //         cancelText="No"
              //         key={el.id}
              //       >
              //         <Tooltip placement="bottom" title={el.name}>
              //           <div className="new_collab_img_container">
              //             <img
              //               src={
              //                 el.logo
              //                   ? process.env.REACT_APP_STORAGE_URL + el.logo
              //                   : el.avatar
              //                   ? process.env.REACT_APP_STORAGE_URL + el.avatar
              //                   : avatar_placeholder
              //               }
              //               style={{ objectFit: "cover" }}
              //               alt="user"
              //               className="new_collab"
              //             />
              //             {!el.avatar && (
              //               <div className="minus_div">
              //                 <FontAwesomeIcon icon={faMinus} />
              //               </div>
              //             )}
              //           </div>
              //         </Tooltip>
              //       </Popconfirm>
              //     ) : (
              //       <div className="new_collab_img_container">
              //         <Tooltip placement="bottom" title={el.name}>
              //           <img
              //             src={
              //               el.logo
              //                 ? process.env.REACT_APP_STORAGE_URL + el.logo
              //                 : avatar_placeholder
              //             }
              //             style={{ objectFit: "cover" }}
              //             alt="user"
              //             className="new_collab"
              //           />
              //         </Tooltip>
              //       </div>
              //     );
              //   })
            )}
            {/* {orderToEdit &&
              orderToEdit.commercials &&
              orderToEdit.commercials.map((com: any) => (
                <Tooltip placement="bottom" title={com.name} key={com.id}>
                  <div className="new_collab_img_container">
                    <img
                      src={
                        com.avatar
                          ? process.env.REACT_APP_STORAGE_URL + com.avatar
                          : avatar_placeholder
                      }
                      style={{ objectFit: "cover" }}
                      alt="user"
                      className="new_collab"
                    />
                  </div>
                </Tooltip>
              ))} */}
            {collaborators && collaborators.length > 6 && (
              <div className="new_collab_img_container see_more_collab_container">
                <button
                  className="add_order_new_collab see_more_collab"
                  onClick={() => setShowDrawer(true)}
                >
                  <span>{collaborators.length - 6}+</span>
                </button>
              </div>
            )}
            {/* Add COllaborator */}
            <AnimatePresence exitBeforeEnter={false}>
              {addInput && (
                <motion.div
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{ type: "just" }}
                  className="add_collab_popover"
                >
                  {/* <input
                    type="text"
                    placeholder="collaborator name"
                    name="collaborator"
                    onChange={getCollaboratorName}
                  /> */}
                  <Select
                    mode="multiple"
                    labelInValue
                    value={state.value}
                    placeholder="Select users"
                    notFoundContent={
                      state.fetching ? <Spin size="small" /> : null
                    }
                    filterOption={false}
                    onSearch={fetchUser}
                    onChange={handleChangeSelect}
                    style={{ width: "100%" }}
                  >
                    {state.data.map((d: any) => (
                      <Option key={d.id} value={d.id}>
                        {d.name}
                      </Option>
                    ))}
                  </Select>
                  <button
                    onClick={(e: any) => {
                      e.stopPropagation();
                      if (addInput) {
                        let addedIds = state.value.map((el: any) => el.value);
                        let allIds = [...new Set(addedIds)];
                        dispatch(
                          addCollaborator(
                            edit ? orderToEdit.id : order.id,
                            allIds
                          )
                        );
                        setAddInput(false);
                        setOrderToEdit({
                          ...orderToEdit,
                          collaborators:
                            !isAdmin &&
                            orderToEdit.collaborators.concat(...state.value),
                        });
                        setState({ ...state, value: [] });
                      } else {
                        setAddInput(false);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
            {((orderToEdit && user.id === orderToEdit.client_id) ||
              (order && user.id === order.client_id)) &&
              !addInput && (
                <button
                  className="add_order_new_collab"
                  onClick={() => setAddInput(!addInput)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              )}
          </motion.div>
        )}
        {/*   {collaborators && (
          <AddCollabDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            // collaborators={collaborators}
            orderToEdit={orderToEdit}
          />
        )} */}
        {edit ? (
          orderToEdit
    &&    (user.id === orderToEdit.client_id ||
            (isAdmin &&
              orderToEdit[0].commercials.find(
                (el: any) => el.id === user.id
              )))

          && (
            <>
              {/* <button className="add_order_save">
                <FontAwesomeIcon icon={faSave} />
              </button> */}
              <Popconfirm
                placement="bottomRight"
                title={"Are you sure to send this order?"}
                onConfirm={async () => {
                  if (
                    orderToEdit &&
                    (isAdmin
                      ? orderToEdit[0].articles.length !== 0 &&
                        orderToEdit[0].articles[0].options.length !== 0
                      : orderToEdit.articles.length !== 0 &&
                        orderToEdit.articles[0].options.length !== 0)
                  ) {
                    const fn = () => {
                      setIsOpenDrawer(true);
                      setTimeout(() => {
                        setIsOpenDrawer(false);
                      }, 2000);
                    };
                    setSendingOrderLoading(true);
                    await dispatch(
                      updateOrder(
                        { status: "completed" },
                        isAdmin
                          ? orderToEdit
                            ? orderToEdit[0].id
                            : order.id
                          : orderToEdit
                          ? orderToEdit.id
                          : order.id,
                        history,
                        fn
                      )
                    );
                    setSendingOrderLoading(false);
                  } else {
                    MyNotif(
                      "This order is empty, please create articles and options.",
                      "danger"
                    );
                  }
                }}
                okText="Yes"
                cancelText="No"
              >
                <button className="add_new_order">
                  <FontAwesomeIcon
                    icon={sendingOrderLoading ? faSpinner : faShoppingBag}
                    spin={sendingOrderLoading}
                  />
                  {sendingOrderLoading ? "Sending..." : "Order"}
                </button>
              </Popconfirm>

              {/*   <button className="add_new_order" onClick={() => openDrawer()}>
                <FontAwesomeIcon
                  icon={sendingOrderLoading ? faSpinner : faShoppingBag}
                  spin={sendingOrderLoading}
                />
                Order
              </button> */}

              <Drawer
                className="order_sent_drawer"
                placement={"bottom"}
                closable={true}
                onClose={() => {
                  setIsOpenDrawer(false);
                  history.push("/direct-order");
                }}
                visible={isOpenDrawer}
                key={"bottom"}
              >
                <div className="my-2">
                  <h1>Your order has been sent</h1>
                  <p>
                    We will train you in the coming days on the advancement of
                    your product.{" "}
                  </p>
                </div>
                <img
                  src={order_sent}
                  alt="order sent"
                  className="img-fluid my-2"
                />
              </Drawer>

              <Popconfirm
                placement="bottomRight"
                title={"Are you sure to delete this order?"}
                onConfirm={confirmDeleteOrder}
                okText="Yes"
                cancelText="No"
              >
                <button className="delete_order">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </Popconfirm>
            </>
          )
        ) : (
          <>
            {/* <button className="add_order_save">
              <FontAwesomeIcon icon={faSave} />
            </button> */}
            <button
              className="add_new_order"
              onClick={() =>
                dispatch(
                  updateOrder(
                    {
                      status: "completed",
                      /*       delivery_date: delivery_date,
                      name: collection_name,
                      season: season, */
                    },
                    orderToEdit ? orderToEdit.id : order.id
                  )
                )
              }
            >
              <FontAwesomeIcon icon={faShoppingBag} />
              Order
            </button>
            <Popconfirm
              placement="bottomRight"
              title={"Are you sure to delete this order?"}
              onConfirm={confirmDeleteOrder}
              okText="Yes"
              cancelText="No"
            >
              <button className="delete_order">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </Popconfirm>
          </>
        )}
      </div>
    </header>
  ) : (
    <header className="add_order_header pl-3">
      <div className="add_order_name">
        <button className="mr-3" onClick={() => history.push("/direct-order")}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        {editName ? (
          <>
            <input
              value={updated && updated}
              className="edit_order_name_input"
              onChange={handleChange}
            />
            <FontAwesomeIcon
              icon={faCheck}
              onClick={() => {
                dispatch(updateOrder({ name: updated }, orderToEdit.id));
                setEditName(false);
              }}
            />
          </>
        ) : (
          <>
            <p>{updated}</p>

            <FontAwesomeIcon icon={faPen} onClick={() => setEditName(true)} />
          </>
        )}
      </div>
      {/* <Dropdown overlay={menu}> */}
      <div>
        <button>
          <FontAwesomeIcon
              icon={faEllipsisV}
              onClick={() => setShowDrawer(true)}
          />
        </button>
        <button className="mobile_order">
          <Popconfirm
              placement="bottomRight"
              title={"Are you sure to send this order?"}
              onConfirm={async () => {
                if (
                    orderToEdit &&
                    (isAdmin
                        ? orderToEdit[0].articles.length !== 0 &&
                        orderToEdit[0].articles[0].options.length !== 0
                        : orderToEdit.articles.length !== 0 &&
                        orderToEdit.articles[0].options.length !== 0)
                ) {
                  const fn = () => {
                    setIsOpenDrawer(true);
                    setTimeout(() => {
                      setIsOpenDrawer(false);
                    }, 2000);
                  };
                  setSendingOrderLoading(true);
                  await dispatch(
                      updateOrder(
                          {status: "completed"},
                          isAdmin
                              ? orderToEdit
                                  ? orderToEdit[0].id
                                  : order.id
                              : orderToEdit
                                  ? orderToEdit.id
                                  : order.id,
                          history,
                          fn
                      )
                  );
                  setSendingOrderLoading(false);
                } else {
                  MyNotif(
                      "This order is empty, please create articles and options.",
                      "danger"
                  );
                }
              }}
              okText="Yes"
              cancelText="No"
          >

            <FontAwesomeIcon
                icon={sendingOrderLoading ? faSpinner : faShoppingBag}
                spin={sendingOrderLoading}
            />

          </Popconfirm>
        </button>
          <Popconfirm
              placement="bottomRight"
              title={"Are you sure to delete this order?"}
              onConfirm={confirmDeleteOrder}
              okText="Yes"
              cancelText="No"
          >
            <button className="delete_order">
              <FontAwesomeIcon icon={faTrash}/>
            </button>
          </Popconfirm>

      </div>
      {showDrawer && (
          <AddCollabDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          // collaborators={collaborators}
          orderToEdit={orderToEdit}
          mobile
        />
      )}
      {/* </Dropdown> */}
    </header>
  );
};
