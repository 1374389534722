import { Col, Card } from "antd";
import image_placeholder from "../../img/img_placeholder.svg";

const { Meta } = Card;

const LoadingLibraryContent = () => {
  // @ts-ignore
  return Array.apply(null, { length: 12 }).map((_: any, i: number) => (
    <Col
      className="gutter-row"
      key={i}
      xs={12}
      sm={8}
      lg={6}
      xl={4}
      style={{ marginTop: 15 }}
    >
      <Card
        loading
        style={{ height: "100%" }}
        cover={<img src={image_placeholder} />}
      >
        <Meta />
      </Card>
    </Col>
  ));
};

export default LoadingLibraryContent;
