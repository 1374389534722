import React, { useState, useEffect } from "react";
import Trash from "../../icons/Trash";
import { Spinner } from "../Spinner";
import { Popconfirm, Spin } from "antd";
import moment from "moment";
import AddTeammateDrawer from "./AddTeammateDrawer";
import { useMutation, useQuery } from "react-query";
import axiosInstance from "../../config/axios-instance";
import {
  LoadingOutlined,
  EditOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { MyNotif } from "../NotifIcons/NotifIcons";
import "./TeamManagement.scss";
import EditTeammateDrawer from "./EditTeammateDrawer";
import { useSelector } from "react-redux";
import { Tabs } from "antd";

export default function TeamManagement() {
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [memberToEdit, setMemberToEdit] = useState(null);
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  const [width, setWidth] = useState(window.innerWidth);
  console.log(user);
  const getCompanyAndMembers = () => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "get",
      url: `/clients/me/company`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const {
    data: members,
    isLoading: loadingMembers,
    refetch: refetchMembers,
  } = useQuery("members", getCompanyAndMembers, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerEdit, setOpenDrawerEdit] = useState(false);
  const [is_client, setIsClient] = useState(false);

  const [company, setCompany] = useState<any>("");

  const removeMember = (clientId: any) => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "delete",
      url: `/clients/me/company/client/${clientId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const getCompany = () => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "get",
      url: `/clients/me/company/${user.company.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => setCompany(response.data));
  };

  useEffect(() => {
    getCompany();
  }, []);
  const { mutateAsync: deleteMember, isLoading: deleteMemberLoading } =
    useMutation(removeMember, {
      onSuccess: () => {
        MyNotif("Member deleted.", "success");
        setMemberToDelete(null);
        refetchMembers();
      },
      onError: (err: any) => {
        MyNotif("Something went wrong!", "danger");
        setMemberToDelete(null);
      },
    });

  return (
    <div style={{ background: "#f4f7ff4a", minHeight: "100vh" }}>
      <div className="container team_management_container">
        <div className="d-flex align-items-center justify-content-between pr-1 mb-4">
          <h1 className="team_management_title mb-0">TEAM MANAGEMENT</h1>

          <div style={{ display: "flex", gap: "10px" }}>
            <button
              className="invite_btn px-3 px-md-3 mt-3"
              onClick={() => {
                setIsClient(false);
                setOpenDrawer(true);
              }}
            >
              <span className={"add-user-text"}>Invite new member</span>
              <span className="add-user-icon">
                <UserAddOutlined />
              </span>
            </button>
            <button
              className="invite_btn px-3 px-md-3 mt-3"
              onClick={() => {
                setOpenDrawer(true);
                setIsClient(true);
              }}
            >
              <span className={"add-user-text"}>Add your client</span>
              <span className="add-user-icon">
                <UserAddOutlined />
              </span>
            </button>
          </div>
        </div>

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Member" key="1">
            <div className="row  mt-5">
              <div className="col-3 item_col_title">Name</div>
              <div className="col-2 item_col_title email_display">
                Job Title
              </div>
              <div className="col-4 item_col_title ">Email</div>
              <div className="col-2 item_col_title">Last Active</div>
              <div className="col-1 item_col_title">Actions</div>
            </div>
            {loadingMembers ? (
              <Spinner />
            ) : (
              members?.data.clients
                .filter(
                  (client: any) =>
                    members?.data.owner_id !== client.id &&
                    client.is_client_clients !== 1
                )
                .map((client: any) => (
                  <div
                    key={client.id}
                    className={"row item_container item_container_last my-3"}
                    style={{ borderRadius: "0.5rem" }}
                  >
                    {width > 768 ? (
                      <>
                        {" "}
                        <div className="col-3 item_element member_name ">
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL + client.logo
                            }
                            alt=""
                            className="member_img"
                          />
                          <p className="ml-2" style={{ fontSize: "0.9rem" }}>
                            {client.name}
                          </p>
                        </div>
                        <div className="col-2 item_element pl-0">
                          <p
                            className="email_member"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {client.job_title}
                          </p>
                        </div>
                        <div className="col-4 item_element pl-0">
                          <p
                            className="email_member"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {client.email}
                          </p>
                        </div>
                        <div className="col-2 item_element member_last_active pl-0">
                          <p style={{ fontSize: "0.9rem" }}>
                            {client.last_login &&
                              moment(client.last_login).format(
                                "DD/MM/YYYY H:mm"
                              )}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="col-9 item_element member_name ">
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL + client.logo
                            }
                            alt=""
                            className="member_img"
                          />

                          <div className="member_infos">
                            <p className="member_name_text">{client.name}</p>

                            <p
                              className="email_member "
                              style={{ width: "50%" }}
                            >
                              {client.email}
                            </p>
                            <p style={{ fontSize: "0.9rem" }}>
                              {client.last_login &&
                                moment(client.last_login).format(
                                  "DD/MM/YYYY H:mm"
                                )}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      className={
                        width > 768
                          ? "col-1 item_element item_actions"
                          : "col-3 item_element item_actions"
                      }
                    >
                      {members?.data.owner_id !== client.id && (
                        <>
                          <Popconfirm
                            title="Are you sure to delete this member?"
                            onConfirm={() => {
                              setMemberToDelete(client.id);
                              deleteMember(client.id);
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            {deleteMemberLoading &&
                            memberToDelete === client.id ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    style={{
                                      color: "#00468c",
                                    }}
                                    spin
                                  />
                                }
                              />
                            ) : (
                              <button className="button_delete_member">
                                <Trash />
                              </button>
                            )}
                          </Popconfirm>
                        </>
                      )}
                    </div>
                  </div>
                ))
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Client" key="2">
            <div className="row  mt-5">
              <div className="col-3 item_col_title">Name</div>
              <div className="col-2 item_col_title email_display">
                Job Title
              </div>
              <div className="col-4 item_col_title ">Email</div>
              <div className="col-2 item_col_title">Last Active</div>
              <div className="col-1 item_col_title">Actions</div>
            </div>
            {loadingMembers ? (
              <Spinner />
            ) : (
              members?.data.clients
                .filter(
                  (client: any) =>
                    client.approved === 1 && client.is_client_clients === 1
                )
                .map((client: any) => (
                  <div
                    key={client.id}
                    className={"row item_container item_container_last my-3"}
                    style={{ borderRadius: "0.5rem" }}
                  >
                    {width > 768 ? (
                      <>
                        {" "}
                        <div className="col-3 item_element member_name ">
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL + client.logo
                            }
                            alt=""
                            className="member_img"
                          />
                          <p className="ml-2" style={{ fontSize: "0.9rem" }}>
                            {client.name}
                          </p>
                        </div>
                        <div className="col-2 item_element pl-0">
                          <p
                            className="email_member"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {client.job_title}
                          </p>
                        </div>
                        <div className="col-4 item_element pl-0">
                          <p
                            className="email_member"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {client.email}
                          </p>
                        </div>
                        <div className="col-2 item_element member_last_active pl-0">
                          <p style={{ fontSize: "0.9rem" }}>
                            {client.last_login &&
                              moment(client.last_login).format(
                                "DD/MM/YYYY H:mm"
                              )}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="col-9 item_element member_name ">
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL + client.logo
                            }
                            alt=""
                            className="member_img"
                          />

                          <div className="member_infos">
                            <p className="member_name_text">{client.name}</p>

                            <p
                              className="email_member "
                              style={{ width: "50%" }}
                            >
                              {client.email}
                            </p>
                            <p style={{ fontSize: "0.9rem" }}>
                              {client.last_login &&
                                moment(client.last_login).format(
                                  "DD/MM/YYYY H:mm"
                                )}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      className={
                        width > 768
                          ? "col-1 item_element item_actions"
                          : "col-3 item_element item_actions"
                      }
                    >
                      {members?.data.owner_id !== client.id && (
                        <>
                          <Popconfirm
                            title="Are you sure to delete this member?"
                            onConfirm={() => {
                              setMemberToDelete(client.id);
                              deleteMember(client.id);
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            {deleteMemberLoading &&
                            memberToDelete === client.id ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    style={{
                                      color: "#00468c",
                                    }}
                                    spin
                                  />
                                }
                              />
                            ) : (
                              <button className="button_delete_member">
                                <Trash />
                              </button>
                            )}
                          </Popconfirm>
                        </>
                      )}
                    </div>
                  </div>
                ))
            )}
          </Tabs.TabPane>
        </Tabs>

        <AddTeammateDrawer
          visible={openDrawer}
          onClose={() => setOpenDrawer(false)}
          refetchMembers={refetchMembers}
          company={company}
          is_client={is_client}
        />

        <EditTeammateDrawer
          visible={openDrawerEdit}
          onClose={() => setOpenDrawerEdit(false)}
          client={memberToEdit}
          refetchMembers={refetchMembers}
        />
      </div>
    </div>
  );
}
