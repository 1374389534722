import white_cirlcle from "../../img/white_cirlcle.png";
import { useSelector } from "react-redux";
interface Props {
  item?: any;
  name?: string;
  addQuery?: (e: any) => void;
}

export const FilterItem = ({ item, name, addQuery }: Props) => {
  const { filters } = useSelector((state: any) => state.products);
  return (
    <div className="d-flex justify-content-between flex-row-reverse align-items-center my-1 py-1">
      <input
        type="checkbox"
        className="filter_checkbox"
        id={item.id}
        name={name}
        checked={filters.find((el: any) => el.name === item.name)}
        onClick={(e) => addQuery && addQuery(e.target)}
      />
      <label className="m-0">
        {name === "colors" && (
          <img
            src={
              item.image
                ? process.env.REACT_APP_STORAGE_URL + item.image
                : white_cirlcle
            }
            alt={item.name}
            width="24"
            style={{ borderRadius: "50%", marginRight: 10 }}
          />
        )}
        <span style={{ textTransform: "capitalize" }} >{item.name}</span>
        {/* {name === "colors" && <span> ({item.products_count})</span>} */}
      </label>
    </div>
  );
};
