import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useSelector } from "react-redux";
import AccessoryCollapse from "./AccessoryCollapse";

const EditAccessories = ({
  productToUpdate,
  genExtraForAccess,
  setProductToUpdate,
  setData,
  data,
  options,
  handleChangeAccess,
  addNewAccess,
}: any) => {
  const { user } = useSelector((state: any) => state.auth);
  return (
    <>
      {productToUpdate.accessories.length === 0 ? (
        <h6 className="text-center p-2 mt-2 text-muted product_doesnt_have">
          This product doesn't have accessory
        </h6>
      ) : (
        productToUpdate.accessories.map((accessory: any, index: number) => (
          <AccessoryCollapse
            key={index}
            index={index}
            accessory={accessory}
            productToUpdate={productToUpdate}
            genExtraForAccess={genExtraForAccess}
            setProductToUpdate={setProductToUpdate}
            setData={setData}
            data={data}
            options={options}
            handleChangeAccess={handleChangeAccess}
          />
        ))
      )}
      <Button
        type="primary"
        disabled={!user.permissions.includes("product.update")}
        icon={<PlusOutlined style={{ verticalAlign: 0 }} />}
        className="add_new_one"
        onClick={addNewAccess}
      >
        Add Another Accessory
      </Button>
    </>
  );
};

export default EditAccessories;
