import {
  Avatar,
  Button,
  Collapse,
  DatePicker,
  Drawer,
  Input,
  List,
  Modal,
  Select,
  Spin,
  Steps,
  Tag,
  Tooltip,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  approveCustomizedProductAndComment,
  getCustomizedProduct,
  rejectOrAcceptProtoCustomized,
} from "../../actions/order-actions/actions";
import {LoadingOutlined, CloudDownloadOutlined, DownloadOutlined} from "@ant-design/icons";
import proto_illustration from "../../img/Prototype_2.png";
import backArrow from "../../img/backArrow.png";
import { history } from "../..";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { CancelOrderDrawer } from "../../components/MyOrderConfirmation/CancelOrderDrawer";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import "../../sass/MyOrderDetails.scss";
import { FileLightBox } from "../../components/OrderDetails/FileLightBox";
import {
  CheckOutlined,
  MinusOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import axiosInstance from "../../config/axios-instance";

const { Step } = Steps;
const { Option } = Select;

export default function CustomizedOrderProgress() {
  const [isOwner, setIsOwner] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [comment, setComment] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [notApproveIsVisible, setNotApproveIsVisible] = useState(false);
  const [approved, setApproved] = useState(false);
  const [current, setCurrent] = useState(0);
  const [startDate, setStartDate] = useState("");

  const [matrix, setmatrix] = useState([]);

  const [inputs, setInputs] = useState(["", "", ""]);
  const [objectStep, setObjectStep] = useState<any>("");

  const [size_qty, setSizeQty] = useState("");
  const [delivery_date, setDeliviry_date] = useState("");

  const [open2, setOpen2] = useState(false);

  const [isEditableFabric, setIsEditableFabric] = useState(false);
  const [detailsFabric, setDetailsFabric] = useState("");
  const [isEditableStyle, setIsEditableStyle] = useState(false);
  const [detailsStyle, setDetailsStyle] = useState("");

  const toggleEditFabric = () => {
    setIsEditableFabric((prevIsEditable) => !prevIsEditable);
  };

  const setDetaisFabric = (e: any) => {
    setDetailsFabric(e.target.value);
  };

  const toggleEditStyle = () => {
    setIsEditableStyle((prevIsEditable) => !prevIsEditable);
  };

  const setDetaisStyle = (e: any) => {
    setDetailsStyle(e.target.value);
  };

  function handleChangeStartDate(e: any) {
    setStartDate(e.target.value);
  }
  const showDrawer2 = () => {
    setOpen2(true);
  };
  // const arr = ["", "", ""];

  const handleCancel = () => {
    setOpen2(false);
  };

  const onChange = (value: number) => {
    console.log("onChange:", value);
    setCurrent(value);
  };
  const width = useBreakpoint();
  const { user } = useSelector((state: any) => state.auth);

  const { product_id, order_id } = useParams<{
    product_id: string;
    order_id: string;
  }>();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const {
    data: product,
    isLoading: loadingProduct,
    isError,
    refetch,
  } = useQuery(
    `customized-product-${product_id}`,
    () => getCustomizedProduct(+product_id),
    {
      retry: false,
    }
  );
  const [step, setSetp] = useState(product?.data?.status?.id);

  console.log(product);

  useEffect(() => {
    if (product) {
      setIsOwner(product?.data.client.id === user.id);
    }
  }, [product]);

  const selectHandleChange = (e: any) => {
    setQuantity(e.target.value);
  };

  const showCancelDrawer = () => {
    setIsVisible(true);
  };

  const notApprovedOption = async (feedback: string = "") => {
    console.log(`Product ${product?.data.name} is not approved!!`);
    if (!feedback) {
      await approveOptAndComment({
        product_id,
        comment: `Not Approved: ${comment}`,
        status_id: 6,
      });
    } else {
      await approveOptAndComment({
        product_id,
        comment: `Not Approved: ${comment}`,
        status_id: 6,
        feedback,
      });
    }
    await rejectOrAcceptProto({
      response: "reject",
      id: +product_id,
      comment: `Not Approved: ${comment}`,
      status_id: product?.data.status.id,
      customized_product_id: product_id,
    });

    setIsVisible(false);
    setComment("");
    setNotApproveIsVisible(true);
    setTimeout(() => {
      setNotApproveIsVisible(false);
    }, 2000);
  };

  const {
    mutateAsync: approveOptAndComment,
    isLoading: approvingAndCommentLoading,
  } = useMutation(approveCustomizedProductAndComment, {
    onSuccess: (res) => {
      MyNotif(` Successfully Saved`, "success");
      refetch();
    },
  });

  const { mutateAsync: approveOpt } = useMutation(
    approveCustomizedProductAndComment,
    {
      onSuccess: (res) => {
        refetch();
      },
    }
  );

  const {
    mutateAsync: rejectOrAcceptProto,
    isLoading: rejectOrAcceptProtoLoading,
  } = useMutation(rejectOrAcceptProtoCustomized, {
    onSuccess: (res) => {
      MyNotif(`Successfully Approved`, "success");
      refetch();
    },
  });

  useEffect(() => {
    if (product) {
      let object = product?.data.orders_status.find((o: any) => {
        return o.status_id == product?.data?.status?.id;
      });
      setObjectStep(object);
      const initialInputs = [];
      if (object?.size_qty) {
        var array = JSON.parse(object?.size_qty);
        if (array?.length > 0) {
          for (let i = 0; i < array?.length; i += 3) {
            initialInputs.push(...array?.slice(i, i + 3));
          }
          setInputs(initialInputs);
        }
      }
    }
    showMatrix(product?.data.status.id);
    console.log(product);
    setSetp(product?.data.status.id);
  }, [product]);

  const handleAddInput = () => {
    const newInputs = [...inputs, "", "", ""];
    setInputs(newInputs);
  };

  const handleInputChange = (event: any, index: any) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
  };

  const handleRemoveInput = (index: any) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 3);
    setInputs(newInputs);
  };

  const showMatrix = (name: any) => {
    // console.log(inputs);
    let found = false;

    product?.data.orders_status.map((el: any) => {
      // Calculate the number of rows
      console.log(name);

      if (el.size_qty && el.status_id == name) {
        const length = JSON.parse(el.size_qty).length;
        const rows = Math.ceil(length / 3);

        // Create a new matrix from the input values
        const newMatrix = [];
        let count = 0;

        for (let i = 0; i < rows; i++) {
          const row = [];
          for (let j = 0; j < 3; j++) {
            row.push(JSON.parse(el.size_qty)[count] || "");
            count++;
          }
          newMatrix.push(row);
        }

        // Update the matrix state
        //@ts-ignore
        setmatrix(newMatrix);
        found = true;
      }
      if (!found) {
        // Reset matrix state
        setmatrix([]);
      }
    });
  };

  const nextProto = async (id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/custom_products/next/${product_id}`,
      data: {
        status_id: id,
        product_id: product_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();

    return "ok";
  };

  const nextStep = async (id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/custom_products/next/${product_id}`,

      data: {
        status_id: id + 1,
        product_id: product_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
    return "ok";
  };

  const saveDetails = async (status_id: any, prod_id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/customized_orders/savedetailsfabric/${product_id}`,
      data: {
        product_id: prod_id,
        status_id: status_id,
        fabric_details: detailsFabric,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    refetch();
    return "ok";
  };

  const saveDetailsStyle = async (status_id: any, prod_id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/customized_orders/savedetailsstyle/${product_id}`,
      data: {
        product_id: prod_id,
        status_id: status_id,
        style_details: detailsStyle,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    refetch();
    return "ok";
  };
  /* const approveOption = async () => {
    console.log(
      `The product ${product?.data.name} is approved and comment is created and the quantity is ${quantity}!!`
    );
    setNotApproveIsVisible(true);
    setApproved(true);
    await approveOptAndComment({
      customized_product_id: product_id,
      comment: `Approved: ${comment}`,
      status_id: product?.data.status.id,
      qty: Number(quantity) ?? 0,
      size_qty: size_qty,
      delivery_date: delivery_date,
      product_id: product?.data.product_id,
    });
    await rejectOrAcceptProto({
      response: "accept",
      id: +product_id,
      comment: `Approved: ${comment}`,
    });
    setComment("");
    setTimeout(() => {
      setNotApproveIsVisible(false);
    }, 2000);
  };
 */

  const approveOption = async () => {
    console.log(
      `The product ${product?.data.name} is approved and comment is created and the quantity is ${quantity}!!`
    );
    setNotApproveIsVisible(true);
    setApproved(true);

    await approveOpt({
      customized_product_id: product_id,
      comment: `${comment}`,
      status_id: product?.data.status.id,
      qty: Number(quantity) ?? 0,
      size_qty: inputs,
      delivery_date: delivery_date,
      product_id: product?.data.product_id,
      start_production_date: startDate,
      order_id: order_id,
    });

    setComment("");

    await rejectOrAcceptProto({
      response: "accept",
      id: +product_id,
      comment: `Approved: ${comment}`,
      status_id: product?.data.status.id,
      customized_product_id: product_id,
    });
    setComment("");
    setTimeout(() => {
      setNotApproveIsVisible(false);
    }, 2000);
  };
  const saveChange = async () => {
    console.log(
      `The product ${product?.data.name} is approved and comment is created and the quantity is ${quantity}!!`
    );
    setNotApproveIsVisible(true);
    setApproved(true);
    await approveOptAndComment({
      customized_product_id: product_id,
      comment: `${comment}`,
      status_id: product?.data.status.id,
      qty: Number(quantity) ?? 0,
      size_qty: inputs,
      delivery_date: delivery_date,
      product_id: product?.data.product_id,
      start_production_date: startDate,
      order_id: order_id,
    });

    setComment("");
    setTimeout(() => {
      setNotApproveIsVisible(false);
    }, 2000);
  };
  const approveProductAndSetQuantity = async () => {
    console.log(
      `Option ${product?.data.name} is approved and the quantity is ${quantity}!!`
    );
    await approveOption();
    await setQuantity("");
  };

  if (loadingProduct) {
    return (
      <div
        style={{ width: "100%", height: "50vh" }}
        className="d-flex justify-content-center align-items-center">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="row pt-0">
        <div
            className="col-12 col-md-5 p-0 align-items-center"
            style={{background: "#fcfdff", height: "100%"}}>
          <iframe
              src={
                product?.data.proto
                    ? product?.data.proto
                    : product?.data.original_product.link_360
              }
              style={{
                width: "100%",
                height: "calc(100vh - 227px)",
                border: "none",
              }}
          />

          <div
              className="row "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
            {/*   <button onClick={showDrawer} className="view-details">
              View Tech Pack
            </button>{" "} */}
            {/*   <Link
              to={`/techpackcutom/${product_id}`}
              target="_blank"
              className="view-details"
            >
             Export tech pack
            </Link> */}
          </div>



          <div
              className="row mt-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
            {" "}

            <a
                href={
                    process.env.REACT_APP_API_URL +
                    `/generate-pdf/custom-order/${product_id}`
                }
                className="exportPDF2">
              Export tech pack <DownloadOutlined style={{fontSize: "16px"}}/>
              {" "}
            </a>
          </div>
        </div>
        {isError ? (
            <div className="col-12 col-md-7 proto_container pl-3 text-center mt-5">
              <h1>Product Not Found</h1>
              <p style={{maxWidth: "40rem", margin: "15px auto"}}>
                This product was cancelled, to see more details please visit the
                cancelled products list by clicking the button below
              </p>
              <Link to="/my-orders/customized-orders/cancelled">
                <Button className="approve_btn">Go to cancelled products</Button>
              </Link>
            </div>
        ) : (
            <div className="col-12 col-md-7 proto_container pl-3">
              <div className="proto_header m-4">
                <img
                    src={backArrow}
                    className="back_arrow_folder"
                    alt="back arrow"
                    onClick={() => history.back()}
                />
                <h1>My Orders</h1>
              <div style={{ position: "absolute", right: "10px" }}>
                <Avatar.Group
                  maxCount={5}
                  size="large"
                  maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
                  {product?.data?.collaborators?.map((colab: any) => (
                    <Tooltip
                      title={colab.name}
                      placement="bottom"
                      key={colab.id}>
                      <Avatar
                        src={process.env.REACT_APP_STORAGE_URL + colab.logo}
                      />
                    </Tooltip>
                  ))}
                </Avatar.Group>
              </div>
            </div>
            <div className="proto_breadcrumb_container my-4">
              {/*    <div className="d-flex align-items-center mb-2">
                <Avatar
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    product?.data.client.logo
                  }
                  size="default"
                />
                <p className="proto_breadcrumb mb-0 ml-2">{`${
                  product?.data.client.name
                } > ${product?.data.ref} > ${
                  (product?.data.code || "") + " " + product?.data.name
                }`}</p>
              </div> */}
              <div className="row">
                <div className="col-10">
                  <div className="d-flex align-items-center mb-2">
                    <Avatar
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        product?.data.client.logo
                      }
                      size="default"
                    />
                    <p className="proto_breadcrumb mb-0 ml-2">{`${
                      product?.data.client.name
                    } > ${product?.data.ref} > ${
                      (product?.data.code || "") + " " + product?.data.name
                    }`}</p>
                  </div>
                </div>
                <div className="col-2">
                  {objectStep &&
                    objectStep?.proto_confirmation === "ACCEPTED" && (
                      <Tag
                        icon={<CheckOutlined />}
                        style={{
                          borderRadius: "15px",
                          padding: "5px 9px 5px 9px",
                        }}
                        color="#A5AB36">
                        Approved
                      </Tag>
                    )}
                </div>
              </div>
              {product?.data.status.name !== "CANCELLED" && (
                <p className="proto_msg_welcome ">
                  Click in step to see more details
                </p>
              )}
            </div>

            <div className="steps_progress mt-4 d-flex justify-content-center">
              <Steps
                className={
                  product?.data.status.id === 6
                    ? "d-flex justify-content-center cancelled"
                    : ""
                }
                current={product?.data.status.id - 1}
                labelPlacement={"vertical"}
                direction={width.md ? "horizontal" : "vertical"}>
                {product?.data.status.id !== 6 && (
                  <>
                    <Step
                      title="PROTO"
                      onClick={() => setSetp(1)}
                      description={
                        product?.data.status.id >= 1 ? "View History" : ""
                      }
                      style={{
                        cursor: product?.data.status.id >= 1 ? "pointer" : "",
                      }}
                    />
                    <Step
                      title="PRE SMS"
                      onClick={() => {
                        setSetp(2);
                        showMatrix(2);
                      }}
                      description={
                        product?.data.status.id >= 2 ? "View History" : ""
                      }
                      style={{
                        cursor: product?.data.status.id >= 2 ? "pointer" : "",
                      }}
                    />
                    <Step
                      title="SMS"
                      onClick={() => {
                        setSetp(3);
                        showMatrix(3);
                      }}
                      description={
                        product?.data.status.id >= 3 ? "View History" : ""
                      }
                      style={{
                        cursor: product?.data.status.id >= 3 ? "pointer" : "",
                      }}
                    />
                    <Step
                      title="OK PROD"
                      onClick={() => {
                        setSetp(4);
                        showMatrix(4);
                      }}
                      description={
                        product?.data.status.id >= 4 ? "View History" : ""
                      }
                      style={{
                        cursor: product?.data.status.id >= 4 ? "pointer" : "",
                      }}
                    />
                    <Step
                      title="PRODUCTION"
                      onClick={() => {
                        setSetp(5);
                        showMatrix(5);
                      }}
                      description={
                        product?.data.status.id >= 5 ? "View History" : ""
                      }
                      style={{
                        cursor: product?.data.status.id >= 5 ? "pointer" : "",
                      }}
                    />
                  </>
                )}
              </Steps>
            </div>
            <div className="proto_footer">
              {product?.data.status.name !== "CANCELLED" && (
                <div className="mt-4">
                {/*  <h1 className="my-3">
                    {(product?.data?.original_product?.code || "") +
                      " " +
                      product?.data?.original_product?.name}
                  </h1>
*/}
                  
                  {step != product?.data.status.id && (
                    <>
                      <div className="proto_footer mt-5">
                        {product?.data.orders_status.map((el: any) => {
                          return (
                            <>

{el.status_id == step && el.status_id!==5 && (
                                  <div>
                         

                         <table
                      style={{
                        width: "100%",
                        marginBottom: "25px",
                      }}
                      className={"table-progress"}
                         >
                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span style={{ fontWeight: "bold" }}>Fabric </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <div
                            style={{
                              justifyContent: "space-between",
                              display: "flex",
                            }}>
                            <span
                              style={{
                                fontWeight: "bold",
                              }}>
                              {" "}
                              Details{" "}
                            </span>
                            <button
                              disabled={isEditableFabric}
                              className={
                                isEditableFabric
                                  ? " add_details_disabled"
                                  : "add_details"
                              }
                              onClick={toggleEditFabric}>
                              <PlusOutlined></PlusOutlined>
                            </button>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span>
                            {product?.data?.fabrics?.map((el: any) => {
                              return (
                                <>
                                  <ul id="myUL2">
                                    <li>{el.name}</li>
                                  </ul>
                                </>
                              );
                            })}
                          </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          {isEditableFabric ? (
                            <>
                              <div>
                                <textarea
                                  style={{ width: "100%" }}
                                  rows={3}
                                  className="textarea_content"
                                  defaultValue={
                                    el.details_fabric
                                  }
                                  onChange={(e) => setDetaisFabric(e)}
                                />
                                <div
                                  className="row"
                                  style={{
                                    float: "right",
                                  }}>
                                  <button
                                    onClick={() => {
                                      setIsEditableFabric(false);
                                    }}
                                    className="check_details"
                                    style={{
                                      marginRight: "10px",
                                    }}>
                                    <CloseOutlined
                                      style={{
                                        color: "#f37b7b",
                                      }}></CloseOutlined>
                                  </button>
                                  <button
                                    onClick={() => {
                                      saveDetails(
                                        el.status_id,
                                        product?.data.product_id
                                      );
                                      setIsEditableFabric(false);
                                    }}
                                    className="check_details">
                                    <CheckOutlined
                                      style={{
                                        color: "#00BA6C",
                                      }}></CheckOutlined>
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  whiteSpace: "pre-line",
                                }}>
                                {el.details_fabric ?? "-"}
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span style={{ fontWeight: "bold" }}>Style </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <div
                            style={{
                              justifyContent: "space-between",
                              display: "flex",
                            }}>
                            <span
                              style={{
                                fontWeight: "bold",
                              }}>
                              {" "}
                              Details{" "}
                            </span>
                            <button
                              disabled={isEditableStyle}
                              className={
                                isEditableStyle
                                  ? " add_details_disabled"
                                  : "add_details"
                              }
                              onClick={toggleEditStyle}>
                              <PlusOutlined></PlusOutlined>
                            </button>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span> {product?.data?.name} </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          {isEditableStyle ? (
                            <>
                              <div>
                                <textarea
                                  style={{ width: "100%" }}
                                  rows={3}
                                  className="textarea_content"
                                  defaultValue={
                                    el.details_style
                                  }
                                  onChange={(e) => setDetaisStyle(e)}
                                />
                                <div
                                  className="row"
                                  style={{
                                    float: "right",
                                  }}>
                                  <button
                                    onClick={() => {
                                      setIsEditableStyle(false);
                                    }}
                                    className="check_details"
                                    style={{
                                      marginRight: "10px",
                                    }}>
                                    <CloseOutlined
                                      style={{
                                        color: "#f37b7b",
                                      }}></CloseOutlined>
                                  </button>
                                  <button
                                    onClick={() => {
                                      saveDetailsStyle(
                                        el.status_id,
                                        product?.data.product_id
                                      );
                                      setIsEditableStyle(false);
                                    }}
                                    className="check_details">
                                    <CheckOutlined
                                      style={{
                                        color: "#00BA6C",
                                      }}></CheckOutlined>
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  whiteSpace: "pre-line",
                                }}>
                                {el.details_style ?? "-"}
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    </table>
                                  </div>
                                )}

                              {el.status_id == step && el.status_id != 1 ? (
                                <>
                                  <div
                                    style={{
                                      marginBottom: "16px",
                                      color: "#00468C",
                                      marginRight: "9px",
                                      fontWeight: "bold",
                                    }}>
                                    Quantity :
                                    {(() => {
                                      switch (el.status_id) {
                                        case 1:
                                          return <span> PROTO</span>;
                                          break;
                                        case 2:
                                          return <span> PRE SMS</span>;
                                          break;
                                        case 3:
                                          return <span> SMS</span>;
                                          break;
                                        case 4:
                                          return <span> OK PROD</span>;
                                          break;

                                        case 5:
                                          return <span> PRODUCTION</span>;
                                          break;
                                      }
                                    })()}
                                  </div>
                                  {el.status_id != 5 ? (
                                    <div style={{ marginBottom: "16px" }}>
                                      {" "}
                                      {matrix.length > 0 ? (
                                        <div>
                                          <table style={{ width: "100%" }} className={"table-progress"}>
                                            <th
                                              style={{
                                                border: "1px solid #CCCCCC",
                                              }}>
                                              Length
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid #CCCCCC",
                                              }}>
                                              Size
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid #CCCCCC",
                                              }}>
                                              Qty
                                            </th>
                                            <tbody>
                                              {matrix.map(
                                                (row: any, rowIndex: any) => (
                                                  <tr
                                                    key={rowIndex}
                                                    style={{
                                                      border:
                                                        "1px solid #CCCCCC",
                                                    }}>
                                                    {row.map(
                                                      (
                                                        cell: any,
                                                        cellIndex: any
                                                      ) => (
                                                        <td
                                                          key={cellIndex}
                                                          style={{
                                                            border:
                                                              "1px solid #CCCCCC",
                                                          }}>
                                                          {cell}
                                                        </td>
                                                      )
                                                    )}
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <div className="p-3">-</div>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "16px",
                                        }}>
                                        <div
                                          style={{
                                            color: "#00468C",
                                            marginRight: "9px",
                                            fontWeight: "bold",
                                          }}>
                                          Quantity Production:
                                        </div>
                                        <div>{el.qty}</div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "16px",
                                        }}>
                                        <div
                                          style={{
                                            color: "#00468C",
                                            marginRight: "9px",
                                            fontWeight: "bold",
                                          }}>
                                          Actual Delivery Date:
                                        </div>
                                        <div>{el.shipping_date}</div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}

                        {step == 1 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                marginBottom: "16px",
                              }}>
                              <div
                                style={{
                                  color: "#00468C",
                                  marginRight: "9px",
                                  fontWeight: "bold",
                                }}>
                                Quantity Proto:
                              </div>
                              <div>1</div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {product?.data.orders_status.map((el: any) => {
                          {
                            return (
                              <>
                                {el.status_id == step && el.status_id !== 5 ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "16px",
                                      }}>
                                      <div
                                        style={{
                                          color: "#00468C",
                                          marginRight: "9px",
                                          fontWeight: "bold",
                                        }}>
                                        Delivery date :
                                      </div>
                                      <div>
                                        {product && product?.data.delivery_date
                                          ? moment(
                                              product?.data.delivery_date
                                            ).format("DD-MM-YYYY")
                                          : "-"}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          }
                        })}
                        {product?.data.allComment.map((el: any) => {
                          return (
                            <>
                              {el.status_id == step ? (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "16px",
                                    }}>
                                    {el.client_id == product?.data.client.id &&
                                    el.commercial_id == null ? (
                                      <>
                                        <Avatar
                                          src={
                                            process.env.REACT_APP_STORAGE_URL +
                                            product?.data.client.logo
                                          }
                                          size="default"
                                          style={{
                                            marginRight: "5px",
                                          }}
                                        />{" "}
                                        <div
                                          style={{
                                            backgroundColor: "#F8FAFC",
                                            borderRadius: "5px",
                                            border: "1px solid #CDD5DF",
                                            width: "100%",
                                            padding: "16px",
                                          }}>
                                          <div
                                            className="row"
                                            style={{
                                              justifyContent: "space-between",
                                            }}>
                                            <div
                                              style={{
                                                lineHeight: "24px",
                                                fontWeight: "bold",
                                              }}>
                                              {product?.data.client.name}
                                            </div>
                                            <div>
                                              <p>
                                                {moment(el.created_at).format(
                                                  "DD-MM-YYYY HH:mm"
                                                )}{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div>{el.comment}</div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <Avatar
                                          src={
                                            process.env.REACT_APP_STORAGE_URL +
                                            el.commercial.avatar
                                          }
                                          size="default"
                                          style={{
                                            marginRight: "5px",
                                          }}
                                        />{" "}
                                        <div
                                          style={{
                                            backgroundColor: "#F8FAFC",
                                            borderRadius: "5px",
                                            border: "1px solid #CDD5DF",
                                            width: "100%",
                                            padding: "16px",
                                          }}>
                                          <div
                                            className="row"
                                            style={{
                                              justifyContent: "space-between",
                                            }}>
                                            <div
                                              style={{
                                                lineHeight: "24px",
                                                fontWeight: "bold",
                                              }}>
                                              {el.commercial.name}
                                            </div>
                                            <div>
                                              <p>
                                                {moment(el.created_at).format(
                                                  "DD-MM-YYYY HH:mm"
                                                )}{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div>{el.comment}</div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}

                  {step == product?.data.status.id &&
                    objectStep?.proto_confirmation === "ACCEPTED" && (
                      <>
                        <div className="proto_footer mt-5">
                          {product?.data.orders_status.map((el: any) => {
                            return (
                              <>
                                {el.status_id == step && el.status_id != 1 ? (
                                  <>
                                    <div
                                      style={{
                                        marginBottom: "16px",
                                        color: "#00468C",
                                        marginRight: "9px",
                                        fontWeight: "bold",
                                      }}>
                                      {(() => {
                                        switch (el.status_id) {
                                          case 1:
                                            return <span>Size : PROTO</span>;
                                            break;
                                          case 2:
                                            return <span>Size : PRE SMS</span>;
                                            break;
                                          case 3:
                                            return <span>Size : SMS</span>;
                                            break;
                                          case 4:
                                            return <span> Size : OK PROD</span>;
                                            break;
                                        }
                                      })()}
                                    </div>
                                    {el.status_id != 5 ? (
                                      <div style={{ marginBottom: "16px" }}>
                                        {" "}
                                        {matrix.length > 0 ? (
                                          <div>
                                            <table style={{ width: "100%" }} className={"table-progress"}>
                                              <th
                                                style={{
                                                  border: "1px solid #CCCCCC",
                                                }}>
                                                Size
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid #CCCCCC",
                                                }}>
                                                Length
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid #CCCCCC",
                                                }}>
                                                Qty
                                              </th>
                                              <tbody>
                                                {matrix.map(
                                                  (row: any, rowIndex: any) => (
                                                    <tr
                                                      key={rowIndex}
                                                      style={{
                                                        border:
                                                          "1px solid #CCCCCC",
                                                      }}>
                                                      {row.map(
                                                        (
                                                          cell: any,
                                                          cellIndex: any
                                                        ) => (
                                                          <td
                                                            key={cellIndex}
                                                            style={{
                                                              border:
                                                                "1px solid #CCCCCC",
                                                            }}>
                                                            {cell}
                                                          </td>
                                                        )
                                                      )}
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        ) : (
                                          <div className="p-3">-</div>
                                        )}
                                      </div>
                                    ) : (
                                      <>
                                        {" "}
                                        {/*    <div
                                          style={{
                                            display: "flex",
                                            marginBottom: "16px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              color: "#00468C",
                                              marginRight: "9px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Quantity Production:
                                          </div>
                                          <div>{el.qty}</div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            marginBottom: "16px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              color: "#00468C",
                                              marginRight: "9px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Actual Delivery Date:
                                          </div>
                                          {console.log(el)}
                                          <div>
                                            {moment(
                                              el.start_production_date
                                            ).format("DD-MM-YYYY")}
                                          </div>
                                        </div> */}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                          {step == 5 &&
                            product?.data.orders_status.filter(
                              (el: any) =>
                                el.status_id === 5 &&
                                el.proto_confirmation === "ACCEPTED"
                            ).length > 0 && (
                              <>
                                  <div
                            style={{
                              marginTop: "-50px",
                              marginBottom: "16px",
                            }}>
                            <table
                              style={{ width: "100%", marginBottom: "25px" }} className={"table-progress"}>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Fabric{" "}
                                  </span>{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Details{" "}
                                  </span>{" "}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {product?.data?.fabrics?.map(
                                    (el: any) => {
                                      return (
                                        <>
                                          <ul id="myUL2">
                                            <li>{el.name}</li>
                                          </ul>
                                        </>
                                      );
                                    }
                                  )}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {product?.data.orders_status
                                    .filter((el: any) => el.status_id !== 5)
                                    .map((el: any) => {
                                      return (
                                        <>
                                          <ul id="myUL2">
                                            <li
                                              style={{
                                                whiteSpace: "pre-line",
                                              }}>
                                              {el.details_fabric ?? "-"}
                                            </li>
                                          </ul>
                                        </>
                                      );
                                    })}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Style{" "}
                                  </span>{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Details{" "}
                                  </span>{" "}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {product?.data?.name}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {product?.data.orders_status
                                    .filter((el: any) => el.status_id !== 5)
                                    .map((el: any) => {
                                      return (
                                        <>
                                          <ul id="myUL2">
                                            <li
                                              style={{
                                                whiteSpace: "pre-line",
                                              }}>
                                              {el.details_style ?? "-"}
                                            </li>
                                          </ul>
                                        </>
                                      );
                                    })}
                                </td>
                              </tr>
                            </table>
                          </div>
                                <table
                                  style={{
                                    width: "100%",
                                    marginBottom: "25px",
                                  }}
                                  className={"table-progress"}
                                >
                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "25%",
                                        fontWeight: "bold",
                                      }}>
                                      Production
                                    </td>{" "}
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "25%",
                                        fontWeight: "bold",
                                      }}>
                                      Delivery Date
                                    </td>{" "}
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "25%",
                                        fontWeight: "bold",
                                      }}>
                                      Actual Delivery Date
                                    </td>{" "}
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "25%",
                                        fontWeight: "bold",
                                      }}>
                                      Quantity
                                    </td>{" "}
                                  </tr>

                                  {product?.data.orders_status
                                    .sort((a: any, b: any) =>
                                      a.sort_order > b.sort_order ? 1 : -1
                                    )

                                    .filter(
                                      (el: any) =>
                                        el.status_id === 5 &&
                                        el.proto_confirmation === "ACCEPTED"
                                    )
                                    .map((el: any, index: number) => {
                                      return (
                                        <>
                                          {product?.data.status.id == step &&
                                            el.proto_confirmation ===
                                              "ACCEPTED" && (
                                              <tr>
                                                <td
                                                  style={{
                                                    border: "1px solid #CCCCCC",
                                                    padding: "9px",
                                                  }}>
                                                  {product?.data.orders_status.filter(
                                                    (el: any) =>
                                                      el.status_id === 5
                                                  ).length - index}
                                                </td>{" "}
                                                <td
                                                  style={{
                                                    border: "1px solid #CCCCCC",
                                                    padding: "9px",
                                                  }}>
                                                  {moment(
                                                    el.delivery_date
                                                  ).format("DD-MM-YYYY ")}
                                                </td>{" "}
                                                <td
                                                  style={{
                                                    border: "1px solid #CCCCCC",
                                                    padding: "9px",
                                                  }}>
                                                  {moment(
                                                    el.start_production_date
                                                  ).format("DD-MM-YYYY ")}
                                                </td>{" "}
                                                <td
                                                  style={{
                                                    border: "1px solid #CCCCCC",
                                                    padding: "9px",
                                                  }}>
                                                  {el.qty}
                                                </td>{" "}
                                              </tr>
                                            )}
                                        </>
                                      );
                                    })}
                                </table>
                              </>
                            )}
                          {/* {product?.data.orders_status.map((el: any) => {
                            {
                              return (
                                <>
                                  {el.status_id == step && el.status_id != 5 ? (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "16px",
                                        }}>
                                        <div
                                          style={{
                                            color: "#00468C",
                                            marginRight: "9px",
                                            fontWeight: "bold",
                                          }}>
                                          Delivery date :
                                        </div>
                                        <div>
                                          {product && el.delivery_date
                                            ? moment(el.delivery_date).format(
                                                "DD-MM-YYYY"
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          })} */}
                          {step == 1 ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "16px",
                                }}>
                                <div
                                  style={{
                                    color: "#00468C",
                                    marginRight: "9px",
                                    fontWeight: "bold",
                                  }}>
                                  Quantity Proto:
                                </div>
                                <div>1</div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {product?.data.orders_status.map((el: any) => {
                            {
                              return (
                                <>
                                  {el.status_id == step &&
                                  el.status_id !== 5 ? (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "16px",
                                        }}>
                                        <div
                                          style={{
                                            color: "#00468C",
                                            marginRight: "9px",
                                            fontWeight: "bold",
                                          }}>
                                          Delivery date :
                                        </div>
                                        <div>
                                          {product &&
                                          product?.data.delivery_date
                                            ? moment(
                                                product?.data.delivery_date
                                              ).format("DD-MM-YYYY")
                                            : "-"}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          })}
                          {product?.data.allComment.map((el: any) => {
                            return (
                              <>
                                {el.status_id == step ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "16px",
                                      }}>
                                      {el.client_id ==
                                        product?.data.client.id &&
                                      el.commercial_id == null ? (
                                        <>
                                          <Avatar
                                            src={
                                              process.env
                                                .REACT_APP_STORAGE_URL +
                                              product?.data.client.logo
                                            }
                                            size="default"
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          />{" "}
                                          <div
                                            style={{
                                              backgroundColor: "#F8FAFC",
                                              borderRadius: "5px",
                                              border: "1px solid #CDD5DF",
                                              width: "100%",
                                              padding: "16px",
                                            }}>
                                            <div
                                              className="row"
                                              style={{
                                                justifyContent: "space-between",
                                              }}>
                                              <div
                                                style={{
                                                  lineHeight: "24px",
                                                  fontWeight: "bold",
                                                }}>
                                                {product?.data.client.name}
                                              </div>
                                              <div>
                                                <p>
                                                  {moment(el.created_at).format(
                                                    "DD-MM-YYYY HH:mm"
                                                  )}{" "}
                                                </p>
                                              </div>
                                            </div>
                                            <div>{el.comment}</div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <Avatar
                                            src={
                                              process.env
                                                .REACT_APP_STORAGE_URL +
                                              el.commercial.avatar
                                            }
                                            size="default"
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          />{" "}
                                          <div
                                            style={{
                                              backgroundColor: "#F8FAFC",
                                              borderRadius: "5px",
                                              border: "1px solid #CDD5DF",
                                              width: "100%",
                                              padding: "16px",
                                            }}>
                                            <div
                                              className="row"
                                              style={{
                                                justifyContent: "space-between",
                                              }}>
                                              <div
                                                style={{
                                                  lineHeight: "24px",
                                                  fontWeight: "bold",
                                                }}>
                                                {el.commercial.name}
                                              </div>
                                              <div>
                                                <p>
                                                  {moment(el.created_at).format(
                                                    "DD-MM-YYYY HH:mm"
                                                  )}{" "}
                                                </p>
                                              </div>
                                            </div>
                                            <div>{el.comment}</div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}

                  {product?.data.orders_status
                    .filter(
                      (el: any) =>
                        el.status_id !== 5 &&
                        el.proto_confirmation !== "ACCEPTED"
                    )
                    .map((el: any) => {
                      return (
                        <>
                          {product?.data.status.id == step &&
                            el.proto_confirmation !== "ACCEPTED" && (
                              <div className="proto_footer">
                                {product?.data.status.name !== "CANCELLED" &&
                                  isOwner &&
                                  (product?.data.status.name === "SMS" ||
                                  product?.data.status.name === "OK PROD" ||
                                  product?.data.status.name === "PRODUCTION" ||
                                  product?.data.status.name === "PRE SMS" ||
                                  product?.data.status.name === "PROTO" ? (
                                    <div>

<table
                      style={{
                        width: "100%",
                        marginBottom: "25px",
                      }}
                      className={"table-progress"}
>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span style={{ fontWeight: "bold" }}>Fabric </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <div
                            style={{
                              justifyContent: "space-between",
                              display: "flex",
                            }}>
                            <span
                              style={{
                                fontWeight: "bold",
                              }}>
                              {" "}
                              Details{" "}
                            </span>
                            <button
                              disabled={isEditableFabric}
                              className={
                                isEditableFabric
                                  ? " add_details_disabled"
                                  : "add_details"
                              }
                              onClick={toggleEditFabric}>
                              <PlusOutlined></PlusOutlined>
                            </button>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span>
                            {product?.data?.fabrics?.map((el: any) => {
                              return (
                                <>
                                  <ul id="myUL2">
                                    <li>{el.name}</li>
                                  </ul>
                                </>
                              );
                            })}
                          </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          {isEditableFabric ? (
                            <>
                              <div>
                                <textarea
                                  style={{ width: "100%" }}
                                  rows={3}
                                  className="textarea_content"
                                  defaultValue={
                                    el.details_fabric
                                  }
                                  onChange={(e) => setDetaisFabric(e)}
                                />
                                <div
                                  className="row"
                                  style={{
                                    float: "right",
                                  }}>
                                  <button
                                    onClick={() => {
                                      setIsEditableFabric(false);
                                    }}
                                    className="check_details"
                                    style={{
                                      marginRight: "10px",
                                    }}>
                                    <CloseOutlined
                                      style={{
                                        color: "#f37b7b",
                                      }}></CloseOutlined>
                                  </button>
                                  <button
                                    onClick={() => {
                                      saveDetails(
                                        el.status_id,
                                        product?.data.product_id
                                      );
                                      setIsEditableFabric(false);
                                    }}
                                    className="check_details">
                                    <CheckOutlined
                                      style={{
                                        color: "#00BA6C",
                                      }}></CheckOutlined>
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  whiteSpace: "pre-line",
                                }}>
                                {el.details_fabric ?? "-"}
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span style={{ fontWeight: "bold" }}>Style </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <div
                            style={{
                              justifyContent: "space-between",
                              display: "flex",
                            }}>
                            <span
                              style={{
                                fontWeight: "bold",
                              }}>
                              {" "}
                              Details{" "}
                            </span>
                            <button
                              disabled={isEditableStyle}
                              className={
                                isEditableStyle
                                  ? " add_details_disabled"
                                  : "add_details"
                              }
                              onClick={toggleEditStyle}>
                              <PlusOutlined></PlusOutlined>
                            </button>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span> {product?.data?.name} </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          {isEditableStyle ? (
                            <>
                              <div>
                                <textarea
                                  style={{ width: "100%" }}
                                  rows={3}
                                  className="textarea_content"
                                  defaultValue={
                                    el.details_style
                                  }
                                  onChange={(e) => setDetaisStyle(e)}
                                />
                                <div
                                  className="row"
                                  style={{
                                    float: "right",
                                  }}>
                                  <button
                                    onClick={() => {
                                      setIsEditableStyle(false);
                                    }}
                                    className="check_details"
                                    style={{
                                      marginRight: "10px",
                                    }}>
                                    <CloseOutlined
                                      style={{
                                        color: "#f37b7b",
                                      }}></CloseOutlined>
                                  </button>
                                  <button
                                    onClick={() => {
                                      saveDetailsStyle(
                                        el.status_id,
                                        product?.data.product_id
                                      );
                                      setIsEditableStyle(false);
                                    }}
                                    className="check_details">
                                    <CheckOutlined
                                      style={{
                                        color: "#00BA6C",
                                      }}></CheckOutlined>
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  whiteSpace: "pre-line",
                                }}>
                                {el.details_style ?? "-"}
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    </table>
                                      <div
                                        style={{
                                          padding: "24px",
                                          backgroundColor: "#F1F5FE",
                                          borderRadius: "5px",
                                        }}
                                        className={"action-comment"}
                                      >
                                        {product?.data.status.name === "SMS" ||
                                        product?.data.status.name ===
                                          "OK PROD" ||
                                        product?.data.status.name ===
                                          "PRODUCTION" ||
                                        product?.data.status.name ===
                                          "PRE SMS" ? (
                                          <div>
                                            {product?.data.status.name !==
                                            "PRODUCTION" ? (
                                              <div
                                                style={{
                                                  marginBottom: "15px",
                                                }}>

                                                  <>
                                                {inputs.map((input, index) =>
                                                  index % 3 === 0 ? (
                                                    <div
                                                      key={index}
                                                      className="row grid_size">
                                                      <div className=" col-9">
                                                        <div className="row">
                                                          <div
                                                            className=" col-4"
                                                            style={{
                                                              display: "flex",
                                                              /*    marginRight:
                                                              "1.2em", */
                                                              width: "30%",
                                                            }}>
                                                            <span
                                                              style={{
                                                                backgroundColor:
                                                                  "#182844",
                                                                color: "white",
                                                                padding: "8px",
                                                                borderTopLeftRadius:
                                                                  "5px",
                                                                borderBottomLeftRadius:
                                                                  "5px",
                                                                borderColor:
                                                                  "#98A2B3",
                                                              }}>
                                                              Size
                                                            </span>
                                                            <Input
                                                              value={input}
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                handleInputChange(
                                                                  event,
                                                                  index
                                                                )
                                                              }
                                                              style={{
                                                                width: "150px",
                                                                borderTopRightRadius:
                                                                  "5px",
                                                                borderBottomRightRadius:
                                                                  "5px",
                                                              }}
                                                            />
                                                          </div>

                                                          {inputs[index + 1] !==
                                                            undefined && (
                                                            <div
                                                              className=" col-4"
                                                              style={{
                                                                display: "flex",
                                                                /*    marginRight:
                                                                "1.2em", */
                                                                width: "30%",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  backgroundColor:
                                                                    "#182844",
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "8px",
                                                                  borderTopLeftRadius:
                                                                    "5px",
                                                                  borderBottomLeftRadius:
                                                                    "5px",
                                                                }}>
                                                                Length
                                                              </span>
                                                              <Input
                                                                value={
                                                                  inputs[
                                                                    index + 1
                                                                  ]
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) =>
                                                                  handleInputChange(
                                                                    event,
                                                                    index + 1
                                                                  )
                                                                }
                                                                style={{
                                                                  width:
                                                                    "150px",
                                                                  borderTopRightRadius:
                                                                    "5px",
                                                                  borderBottomRightRadius:
                                                                    "5px",
                                                                }}
                                                              />
                                                            </div>
                                                          )}
                                                          {inputs[index + 2] !==
                                                            undefined && (
                                                            <div
                                                              className=" col-4"
                                                              style={{
                                                                display: "flex",
                                                                /*  marginRight:
                                                                "1.2em", */
                                                                width: "30%",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  backgroundColor:
                                                                    "#182844",
                                                                  color:
                                                                    "white",
                                                                  padding:
                                                                    "8px",
                                                                  borderTopLeftRadius:
                                                                    "5px",
                                                                  borderBottomLeftRadius:
                                                                    "5px",
                                                                }}>
                                                                Qty
                                                              </span>
                                                              <Input
                                                                value={
                                                                  inputs[
                                                                    index + 2
                                                                  ]
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) =>
                                                                  handleInputChange(
                                                                    event,
                                                                    index + 2
                                                                  )
                                                                }
                                                                style={{
                                                                  width:
                                                                    "150px",
                                                                  borderTopRightRadius:
                                                                    "5px",
                                                                  borderBottomRightRadius:
                                                                    "5px",
                                                                }}
                                                              />
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>

                                                      <div className="col-3">
                                                        {" "}
                                                        {inputs.length - 3 ? (
                                                          <button
                                                            className="remove_input"
                                                            onClick={() =>
                                                              handleRemoveInput(
                                                                index
                                                              )
                                                            }>
                                                            <MinusOutlined />
                                                          </button>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        {index ===
                                                          inputs.length - 3 && (
                                                          <button
                                                            className="add_input"
                                                            style={{
                                                              marginLeft:
                                                                "1.2em",
                                                            }}
                                                            onClick={
                                                              handleAddInput
                                                            }>
                                                            <PlusOutlined></PlusOutlined>
                                                          </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : null
                                                )}
                                                </>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="final_order_select my-3">
                                                  <label
                                                    className="mr-3 mb-0"
                                                    style={{
                                                      color: "#344054",
                                                    }}>
                                                    Quantity
                                                  </label>

                                                  <Input
                                                    value={quantity || el.qty}
                                                    onChange={(e: any) =>
                                                      setQuantity(
                                                        e.target.value
                                                      )
                                                    }></Input>
                                                </div>{" "}
                                                <div className="final_order_select my-3">
                                                  <label
                                                    className="mr-3 mb-0"
                                                    style={{
                                                      color: "#344054",
                                                    }}>
                                                    {" "}
                                                    Actual Delivery Date
                                                  </label>
                                                  <Input
                                                    type="date"
                                                    value={
                                                      startDate ||
                                                      moment(
                                                        el.start_production_date
                                                      ).format("YYYY-MM-DD")
                                                    }
                                                    onChange={
                                                      handleChangeStartDate
                                                    }
                                                    style={{
                                                      borderRadius: "5px",
                                                      marginBottom: "15px",
                                                    }}></Input>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        <div className="final_order_select my-3">
                                          <label
                                            className="mr-3 mb-0"
                                            style={{ color: "#344054" }}>
                                            Delivery date
                                          </label>

                                          <DatePicker
                                            defaultValue={moment(
                                              product?.data.delivery_date ===
                                                null
                                                ? new Date()
                                                : product?.data.delivery_date
                                            )}
                                            disabledDate={(current) =>
                                              current.isBefore(
                                                moment().add(15, "days")
                                              )
                                            }
                                            format={"YYYY-MM-DD"}
                                            onChange={(
                                              date: any,
                                              dateString: any
                                            ) => setDeliviry_date(dateString)}
                                            style={{
                                              borderRadius: "5px",
                                              width: "100%",
                                            }}
                                          />
                                        </div>

                                        <>
                                          {product &&
                                            product.data.status.comments
                                              .length > 0 && (
                                              <>
                                                <div
                                                  style={{
                                                    color: "#00468C",
                                                    marginRight: "9px",
                                                    fontWeight: "bold",
                                                    marginBottom: "16px",
                                                  }}>
                                                  Comments
                                                </div>
                                                {product.data.status.comments.map(
                                                  (el: any) => {
                                                    return (
                                                      <>
                                                        {el.status_id == step &&
                                                        el.name != "PROTO" ? (
                                                          <>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                marginBottom:
                                                                  "16px",
                                                              }}>
                                                              {el.client_id ==
                                                                product?.data
                                                                  .client.id &&
                                                              el.commercial_id ==
                                                                null ? (
                                                                <>
                                                                  <Avatar
                                                                    src={
                                                                      process
                                                                        .env
                                                                        .REACT_APP_STORAGE_URL +
                                                                      product
                                                                        ?.data
                                                                        .client
                                                                        .logo
                                                                    }
                                                                    size="default"
                                                                    style={{
                                                                      marginRight:
                                                                        "5px",
                                                                    }}
                                                                  />{" "}
                                                                  <div
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#F8FAFC",
                                                                      borderRadius:
                                                                        "5px",
                                                                      border:
                                                                        "1px solid #CDD5DF",
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "16px",
                                                                    }}>
                                                                    <div
                                                                      className="row"
                                                                      style={{
                                                                        justifyContent:
                                                                          "space-between",
                                                                      }}>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "24px",
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}>
                                                                        {
                                                                          product
                                                                            ?.data
                                                                            .client
                                                                            .name
                                                                        }
                                                                      </div>
                                                                      <div>
                                                                        <p>
                                                                          {moment(
                                                                            el.created_at
                                                                          ).format(
                                                                            "DD-MM-YYYY HH:mm"
                                                                          )}{" "}
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                    <div>
                                                                      {
                                                                        el.comment
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <Avatar
                                                                    src={
                                                                      process
                                                                        .env
                                                                        .REACT_APP_STORAGE_URL +
                                                                      el
                                                                        .commercial
                                                                        .avatar
                                                                    }
                                                                    size="default"
                                                                    style={{
                                                                      marginRight:
                                                                        "5px",
                                                                    }}
                                                                  />{" "}
                                                                  <div
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#F8FAFC",
                                                                      borderRadius:
                                                                        "5px",
                                                                      border:
                                                                        "1px solid #CDD5DF",
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "16px",
                                                                    }}>
                                                                    <div
                                                                      className="row"
                                                                      style={{
                                                                        justifyContent:
                                                                          "space-between",
                                                                      }}>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "24px",
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}>
                                                                        {
                                                                          el
                                                                            .commercial
                                                                            .name
                                                                        }
                                                                      </div>
                                                                      <div>
                                                                        <p>
                                                                          {moment(
                                                                            el.created_at
                                                                          ).format(
                                                                            "DD-MM-YYYY HH:mm"
                                                                          )}{" "}
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                    <div>
                                                                      {
                                                                        el.comment
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              )}
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </>
                                            )}
                                        </>
                                        <textarea
                                          rows={4}
                                          name="comment"
                                          placeholder="Add your comment"
                                          className="comment_proto_order my-4"
                                          onChange={(e: any) =>
                                            setComment(e.target.value)
                                          }
                                          value={comment}></textarea>
                                        <div
                                          className=" footer_btns_form"
                                          style={{ marginTop: "16px" }}>
                                          <Button
                                            type="default"
                                            className="save-changes"
                                            loading={approvingAndCommentLoading}
                                            onClick={saveChange}
                                            disabled={
                                              el.proto_confirmation ===
                                              "ACCEPTED"
                                            }>
                                            Save Changes
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  ))}
                                {isOwner &&
                                  (product?.data.status.name !== "CANCELLED" ? (
                                    <div className="proto_footer_btns my-4">
                                      {product?.data.status.id < 4 && (
                                        <Button
                                          type="default"
                                          className="cancel_btn"
                                          onClick={() => showCancelDrawer()}>
                                          Cancel
                                        </Button>
                                      )}

                                      <Button
                                        type="default"
                                        className="approve_btn"
                                        loading={rejectOrAcceptProtoLoading}
                                        onClick={() => approveOption()}
                                        disabled={
                                          el.proto_confirmation === "ACCEPTED"
                                        }>
                                        Approve & send
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      className="proto_footer_btns my-4"
                                      style={{ justifyContent: "center" }}>
                                      <Button
                                        type="default"
                                        className="approve_btn"
                                        onClick={() => history.back()}>
                                        Go back to orders
                                      </Button>
                                    </div>
                                  ))}
                              </div>
                            )}
                        </>
                      );
                    })}

                  {product?.data.orders_status
                    .filter(
                      (el: any) =>
                        el.status_id === 5 &&
                        el.proto_confirmation !== "ACCEPTED"
                    )
                    .map((el: any) => {
                      return (
                        <>
                          {5 == step &&
                            el.proto_confirmation !== "ACCEPTED" &&
                            el.id ==
                              product?.data.orders_status[
                                product?.data.orders_status.length - 1
                              ].id && (
                              <div className="proto_footer">
                                {product?.data.status.name !== "CANCELLED" &&
                                  isOwner &&
                                  (product?.data.status.name ===
                                  "PRODUCTION" ? (
                                    <div>
                                      <div
                                        style={{
                                          padding: "24px",
                                          backgroundColor: "#F1F5FE",
                                          borderRadius: "5px",
                                        }}>
                                        <div>
                                          <>
                                            <div className="final_order_select my-3">
                                              <label
                                                className="mr-3 mb-0"
                                                style={{ color: "#344054" }}>
                                                Quantity
                                              </label>

                                              <Input
                                                value={quantity || el.qty}
                                                onChange={(e: any) =>
                                                  setQuantity(e.target.value)
                                                }></Input>
                                            </div>{" "}
                                            <div className="final_order_select my-3">
                                              <label
                                                className="mr-3 mb-0"
                                                style={{ color: "#344054" }}>
                                                {" "}
                                                Actual Delivery Date
                                              </label>
                                              <Input
                                                type="date"
                                                value={
                                                  startDate ||
                                                  moment(
                                                    el.start_production_date
                                                  ).format("YYYY-MM-DD")
                                                }
                                                onChange={handleChangeStartDate}
                                                style={{
                                                  borderRadius: "5px",
                                                  marginBottom: "15px",
                                                }}></Input>
                                            </div>
                                          </>
                                        </div>

                                        <div className="final_order_select my-3">
                                          <label
                                            className="mr-3 mb-0"
                                            style={{ color: "#344054" }}>
                                            Delivery date
                                          </label>

                                          <DatePicker
                                            defaultValue={moment(
                                              product?.data.delivery_date ===
                                                null
                                                ? new Date()
                                                : product?.data.delivery_date
                                            )}
                                            disabledDate={(current) =>
                                              current.isBefore(
                                                moment().add(15, "days")
                                              )
                                            }
                                            format={"YYYY-MM-DD"}
                                            onChange={(
                                              date: any,
                                              dateString: any
                                            ) => setDeliviry_date(dateString)}
                                            style={{
                                              borderRadius: "5px",
                                              width: "100%",
                                            }}
                                          />
                                        </div>

                                        <>
                                          {product &&
                                            product.data.status.comments
                                              .length > 0 && (
                                              <>
                                                <div
                                                  style={{
                                                    color: "#00468C",
                                                    marginRight: "9px",
                                                    fontWeight: "bold",
                                                    marginBottom: "16px",
                                                  }}>
                                                  Comments
                                                </div>
                                                {product.data.status.comments.map(
                                                  (el: any) => {
                                                    return (
                                                      <>
                                                        {el.status_id == step &&
                                                        el.name != "PROTO" ? (
                                                          <>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                marginBottom:
                                                                  "16px",
                                                              }}>
                                                              {el.client_id ==
                                                                product?.data
                                                                  .client.id &&
                                                              el.commercial_id ==
                                                                null ? (
                                                                <>
                                                                  <Avatar
                                                                    src={
                                                                      process
                                                                        .env
                                                                        .REACT_APP_STORAGE_URL +
                                                                      product
                                                                        ?.data
                                                                        .client
                                                                        .logo
                                                                    }
                                                                    size="default"
                                                                    style={{
                                                                      marginRight:
                                                                        "5px",
                                                                    }}
                                                                  />{" "}
                                                                  <div
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#F8FAFC",
                                                                      borderRadius:
                                                                        "5px",
                                                                      border:
                                                                        "1px solid #CDD5DF",
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "16px",
                                                                    }}>
                                                                    <div
                                                                      className="row"
                                                                      style={{
                                                                        justifyContent:
                                                                          "space-between",
                                                                      }}>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "24px",
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}>
                                                                        {
                                                                          product
                                                                            ?.data
                                                                            .client
                                                                            .name
                                                                        }
                                                                      </div>
                                                                      <div>
                                                                        <p>
                                                                          {moment(
                                                                            el.created_at
                                                                          ).format(
                                                                            "DD-MM-YYYY HH:mm"
                                                                          )}{" "}
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                    <div>
                                                                      {
                                                                        el.comment
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <Avatar
                                                                    src={
                                                                      process
                                                                        .env
                                                                        .REACT_APP_STORAGE_URL +
                                                                      el
                                                                        .commercial
                                                                        .avatar
                                                                    }
                                                                    size="default"
                                                                    style={{
                                                                      marginRight:
                                                                        "5px",
                                                                    }}
                                                                  />{" "}
                                                                  <div
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#F8FAFC",
                                                                      borderRadius:
                                                                        "5px",
                                                                      border:
                                                                        "1px solid #CDD5DF",
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "16px",
                                                                    }}>
                                                                    <div
                                                                      className="row"
                                                                      style={{
                                                                        justifyContent:
                                                                          "space-between",
                                                                      }}>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "24px",
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}>
                                                                        {
                                                                          el
                                                                            .commercial
                                                                            .name
                                                                        }
                                                                      </div>
                                                                      <div>
                                                                        <p>
                                                                          {moment(
                                                                            el.created_at
                                                                          ).format(
                                                                            "DD-MM-YYYY HH:mm"
                                                                          )}{" "}
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                    <div>
                                                                      {
                                                                        el.comment
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              )}
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </>
                                            )}
                                        </>
                                        <textarea
                                          rows={4}
                                          name="comment"
                                          placeholder="Add your comment"
                                          className="comment_proto_order my-4"
                                          onChange={(e: any) =>
                                            setComment(e.target.value)
                                          }
                                          value={comment}></textarea>
                                        <div
                                          className=" footer_btns_form"
                                          style={{ marginTop: "16px" }}>
                                          <Button
                                            type="default"
                                            className="save-changes"
                                            loading={approvingAndCommentLoading}
                                            onClick={saveChange}
                                            disabled={
                                              el.proto_confirmation ===
                                              "ACCEPTED"
                                            }>
                                            Save Changes
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  ))}
                                {isOwner &&
                                  (product?.data.status.name !== "CANCELLED" ? (
                                    <div className="proto_footer_btns my-4">
                                      {product?.data.status.id < 4 && (
                                        <Button
                                          type="default"
                                          className="cancel_btn"
                                          onClick={() => showCancelDrawer()}>
                                          Cancel
                                        </Button>
                                      )}

                                      <Button
                                        type="default"
                                        className="approve_btn"
                                        loading={rejectOrAcceptProtoLoading}
                                        onClick={() => approveOption()}
                                        disabled={
                                          el.proto_confirmation === "ACCEPTED"
                                        }>
                                        Approve & send
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      className="proto_footer_btns my-4"
                                      style={{ justifyContent: "center" }}>
                                      <Button
                                        type="default"
                                        className="approve_btn"
                                        onClick={() => history.back()}>
                                        Go back to orders
                                      </Button>
                                    </div>
                                  ))}
                              </div>
                            )}
                        </>
                      );
                    })}
                </div>
              )}

              {product?.data.orders_status.map((el: any) => {
                return (
                  <>
                    {product?.data.status.id === el.status_id &&
                      el.proto_confirmation === "ACCEPTED" && (
                        <>
                          <div className="proto_footer_btns ">
                            {el.status_id == 1 && (
                              <Button
                                type="default"
                                className="approve_btn"
                                onClick={showDrawer2}>
                                Next
                              </Button>
                            )}
                            {el.status_id != 1 && el.status_id != 5 && (
                              <Button
                                type="default"
                                className="approve_btn"
                                onClick={() => nextStep(el.status_id)}>
                                Next
                              </Button>
                            )}
                          </div>
                        </>
                      )}
                  </>
                );
              })}

              {product?.data.status.id === 6 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <h4
                    className="text-center"
                    style={{ color: "red", fontWeight: 400 }}>
                    This product has been cancelled
                  </h4>
                  <div className="proto_footer_btns my-4">
                    <Button
                      type="default"
                      className="approve_btn"
                      onClick={() => history.back()}>
                      Go back to orders
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <Modal
        title="Congratulations"
        visible={open2}
        closable={true}
        centered
        onCancel={handleCancel}
        footer={[
          <div className="proto_footer_btns p-3">
            <Button
              type="default"
              className="approve_btn"
              onClick={() => nextProto(2)}>
              {" "}
              PRE SMS{" "}
            </Button>

            <Button
              type="default"
              className="approve_btn"
              onClick={() => nextProto(3)}>
              {" "}
              SMS{" "}
            </Button>
          </div>,
        ]}>
        <p  className={"pop-up-text"}> You Approved your Proto. Where do you wish to GO?</p>
      </Modal>

      <Drawer
        title="Product details"
        placement={"bottom"}
        closable={true}
        onClose={onClose}
        visible={open}>
        <Collapse defaultActiveKey="1" key="1">
          <Collapse.Panel header={""} key="1" showArrow={false}>
            {product && (
              <div className="row border-top" key={1}>
                <div className="col-12 col-lg-6 border-right borderLeft p-0">
                  <div className="row">
                    <div className="col-12 p-0">
                      <p className="m-0 p-2 collapse_header border-bottom font-weight-bold">
                        {product?.data?.name}
                      </p>
                    </div>

                    <div className="col-12 p-0 border-bottom option_details_collapse">
                      <p className="m-0 p-2">
                        <strong>Fabric: </strong>
                        {product?.data?.fabric}
                      </p>
                    </div>
                    <div className="col-12 p-0 border-bottom option_details_collapse">
                      <p className="m-0 p-2">
                        <strong>Fabric details: </strong>
                        {product?.data?.fabric_details}
                      </p>
                    </div>

                    <div className="col-12 p-0 border-bottom option_details_collapse">
                      <p className="m-0 p-2">
                        <strong>Style: </strong>
                        {product?.data?.style}
                      </p>
                    </div>

                    <div className="col-12 p-0 border-bottom option_details_collapse">
                      <p className="m-0 p-2">
                        <strong>Style details: </strong>
                        {product?.data?.style_details}
                      </p>
                    </div>
                    <div className="col-12 p-0 border-bottom option_details_collapse">
                      <p className="m-0 p-2">
                        <strong>Pictures of Customized Product : </strong>
                        <a
                          href={
                            process.env.REACT_APP_API_URL +
                            `/download-files/${product_id}`
                          }
                          style={{ color: "#00468C", fontSize: "16px" }}>
                          Download <CloudDownloadOutlined />
                        </a>{" "}
                        {console.log(product?.data)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 border-right border-bottom p-0">
                  <div className="row">
                    <div className="col-12 p-0">
                      <p className="m-0 p-2 collapse_header bg-white border-bottom  font-weight-bold">
                        Files Attached
                      </p>
                    </div>

                    <div className="col-12 pt-3 files_wrapper">
                      <div className="row flex-wrap">
                        {product?.data?.style_files &&
                          product?.data.fabric_files &&
                          product?.data.color_files && (
                            <>
                              {JSON.parse(product?.data?.style_files).map(
                                (el: any, i: number) => (
                                  <FileLightBox file={[el.file]} key={i} />
                                )
                              )}
                              {JSON.parse(product?.data.fabric_files).map(
                                (el: any, i: number) => (
                                  <FileLightBox file={[el.file]} key={i} />
                                )
                              )}
                              {JSON.parse(product?.data.color_files).map(
                                (el: any, i: number) => (
                                  <FileLightBox file={[el.file]} key={i} />
                                )
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Collapse.Panel>
        </Collapse>
      </Drawer>
      <CancelOrderDrawer
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        article={product}
        notApprovedOption={notApprovedOption}
        // setAlerts={setAlerts}
        orderType={"customized_order"}
        productToCancel={product?.data.id}
        approvingAndCommentLoading={approvingAndCommentLoading}
        rejectOrAcceptProtoLoading={rejectOrAcceptProtoLoading}
      />
    </div>
  );
}

const SelectQuantity = ({
  quantity,
  selectHandleChange,
  defaultQty,
  disabled,
}: any) => {
  return (
    <div className="final_order_select my-3">
      <label className=" mb-0" style={{ marginRight: "50px" }}>
        Quantity
      </label>
      {/*     <Select
        defaultValue={quantity || defaultQty}
        style={{
          width: "100%",
        }}
        onChange={selectHandleChange}
        dropdownStyle={{
          borderRadius: 10,
          fontWeight: 600,
          fontSize: 20,
        }}
        suffixIcon={() => <FontAwesomeIcon icon={faCaretDown} />}
      >
        <Option value="">Select the number of pieces</Option>
        <Option value="1">1 Pc</Option>
        <Option value="2">2 Pcs</Option>
        <Option value="3">3 Pcs</Option>
        <Option value="4">4 Pcs</Option>
        <Option value="5">5 Pcs</Option>
      </Select> */}
      <Input
        defaultValue={quantity || defaultQty}
        style={{
          width: "100%",
        }}
        onChange={selectHandleChange}
        type="number"
        disabled={disabled}></Input>
    </div>
  );
};

const CommentsListAndForm = ({ product, setComment, order, comment }: any) => {
  return (
    <>
      <div>
        <h1 style={{ fontSize: "1rem", fontWeight: 400 }}>Comments: </h1>
        <List
          itemLayout="horizontal"
          dataSource={product.status.comments}
          renderItem={(item: any) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={process.env.REACT_APP_STORAGE_URL + order.client.logo}
                  />
                }
                title={
                  <span>
                    {/* <span>{product.status.name}</span> */}
                    {item.comment.includes("NOT")
                      ? " NOT APPROVED BY "
                      : " APPROVED BY "}
                    {order.client.name}
                  </span>
                }
                description={item.comment}
              />
            </List.Item>
          )}
        />
      </div>
      <textarea
        rows={4}
        name="comment"
        value={comment}
        placeholder="Add a comment"
        className="comment_proto_order my-4"
        onChange={(e: any) => setComment(e.target.value)}></textarea>
    </>
  );
};
