import { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./collection-folder-on-drag.scss";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getFilterAttributes } from "../../actions/products-actions/actions";
import { Collapse, Divider, Slider, Skeleton } from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { FilterItem } from "./FilterItem";
import {
  getCategoriesUnderGender,
  getCollections,
} from "../../actions/left-menu-actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ProductsPageCollectionFolders from "./ProductsPageCollectionFolders";
import { getAllFolders } from "../../actions/folders-actions/actions";
import axiosInstance from "../../config/axios-instance";

const { Panel } = Collapse;

export const Filter = ({
  isDragging,
  addQuery,
  handleChangePriceAfter,
  handleChangePrice,
  min_price,
  max_price,
  max_priceBefore,
  min_priceBefore,
  max_elasticity,
  min_elasticity,
  handleChangeElasticity,
  setMax_elasticity_before,
  setMin_elasticity_before,
  max_elasticity_before,
  min_elasticity_before,
  //weight
  max_weight,
  min_weight,
  handleChangeWeight,
  setMax_weight_before,
  setMin_weight_before,
  max_weight_before,
  min_weight_before,
}: any) => {
  const TechniquesFilter = (techniques: any) => {
    return (
      <>
        <Panel
          header={"Technique"}
          key="60"
          collapsible={isLoadingFilter && "disabled"}
        >
          {techniques.length !== 0 &&
            techniques.map((el: any) =>
              el.children &&
              el.children.length === 0 &&
              el.technic_id === null ? (
                <FilterItem
                  item={el}
                  key={el.id}
                  name={"technics"}
                  addQuery={addQuery}
                />
              ) : (
                el.technic_id === null && (
                  <>
                    <FilterItem
                      item={el}
                      key={el.id}
                      name={"technics"}
                      addQuery={addQuery}
                    />
                    <div style={{ marginLeft: 20 }}>
                      {el.children &&
                        el.children.map((technique: any) => (
                          <FilterItem
                            item={technique}
                            key={technique.id}
                            name={"technics"}
                            addQuery={addQuery}
                          />
                        ))}
                    </div>
                  </>
                )
              )
            )}
        </Panel>
        <Divider key={uuidv4()} />
      </>
    );
  };

  const [subElements, setsubElements] = useState({
    parent: null,
    children: [],
  });
  const [subElementChildren, setsubElementsChildren] = useState({
    parent: null,
    children: [],
  });
  const [fabricTypes, setFabricTypes] = useState([]);
  const params: any = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    fibers,
    colors,
    allcolors,
    washes,
    elasticities,
    weights,
    responsible_choices,
    techniques,
    compositions,
    special_fibers,
    fabric_types,
    products,
    productsAll,
    eim_scores,
    prices,
    fabric_chars,
    dress_codes,
    labels,
    genders,
    mills,
    product_fabric_types,
    fits,
    isLoadingFilter,
    genders_filter,
    elasticity_fabric,
    weight_fabric,
    all_product_fabric_types,
  } = useSelector((state: any) => state.products);
  const { categories, collections, isLoadingCollections } = useSelector(
    (state: any) => state.leftMenu
  );

  const CollectionSubElements = (e: any, collection: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    var tab: any = [];
    const compare = (a: any, b: any) => {
      return a.sort_order - b.sort_order;
    };
    collections.map((el: any) => {
      if (collection.id === el.collection_id) {
        tab.push(el);
      }
    });
    let ordered = tab.sort(compare);
    setsubElements({ parent: collection.id, children: ordered });
  };
  const CollectionSubElementChildren = (e: any, collection: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    var tab: any = [];
    collections.map((el: any) => {
      if (collection.id === el.collection_id) {
        tab.push(el);
      }
    });
    setsubElementsChildren({ parent: collection.id, children: tab });
  };

  useEffect(() => {
    if (location.pathname.includes("/product")) {
      dispatch(getCategoriesUnderGender(params.genderId, params.fabric));
    }
    dispatch(
      getFilterAttributes(params.fabric, params.categoryId, params.genderId)
    );
  }, [params.fabric]);
  const { user, isLoading, isAdmin } = useSelector((state: any) => state.auth);

  useEffect(() => {
    console.log(weight_fabric);

    dispatch(getCollections());
  }, []);

  const handleDenim = () => {
    if (location.pathname.includes("/for-you/knit")) {
      history.push("/for-you/denim");
    } else if (location.pathname.includes("/new")) {
      history.push("/new/denim");
    } else if (location.pathname.includes("/collection")) {
      history.push(`/collection/${params.collectionId}/denim`);
    }
  };

  const handleKnit = () => {
    if (location.pathname.includes("/for-you/denim")) {
      history.push("/for-you/knit");
    } else if (location.pathname.includes("/new")) {
      history.push("/new/knit");
    } else if (location.pathname.includes("/collection")) {
      history.push(`/collection/${params.collectionId}/knit`);
    }
  };

  const uniqByKeepLast = (a: any, key: any) => {
    return [...new Map(a.map((x: any) => [key(x), x])).values()];
  };

  const [folder, setfolder] = useState<any>(undefined);
  const [parentFolderId, setParentFolderId] = useState<any | undefined>();
  const [subFolderId, setsubFolderId] = useState<any | undefined>();
  const [subfolders, setsubfolders] = useState<any>([]);

  const folders = useSelector((state: RootStateOrAny) => state.folders);
  useEffect(() => {
    dispatch(getAllFolders());
  }, []);

  useEffect(() => {
    if (location.pathname.includes("collection")) {
      if (collections.length !== 0) {
        let parentId = collections.find(
          (el: any) => el.id === Number(params.collectionId)
        ).collection_id;
        if (parentId) {
          let grandParentId = collections.find(
            (el: any) => el.id == parentId
          ).collection_id;

          if (grandParentId) {
            let x = collections.find((el: any) => el.id == grandParentId);
            CollectionSubElements(null, x);
            CollectionSubElementChildren(
              null,
              collections.find((el: any) => el.id === parentId)
            );
          } else {
            let x = collections.find((el: any) => el.id == parentId);
            setsubElements({
              ...subElements,
              parent: x.id,
              children: x.children,
            });
          }
        }
      }
    }
  }, [collections]);

  const [moodboard, setMooadboard] = useState<Array<any>>([]);

  const fetchMoadboard = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/moodboards/client/last`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setMooadboard(res.data);
    });
  };

  useEffect(() => {
    fetchMoadboard();
  }, []);
  return (
    <aside style={{ position: "relative" }}>
      <div
        className={
          isDragging
            ? "collection-folder-on-drag"
            : "collection-folder-on-drag closed"
        }
      >
        {folders && folders.folders && (
          <ProductsPageCollectionFolders
            setParentFolderId={setParentFolderId}
            setsubfolders={setsubfolders}
            subFolderId={subFolderId}
            setfolder={setfolder}
          />
        )}
      </div>

      <Collapse
        defaultActiveKey={["1", "1023", "11"]}
        ghost
        expandIconPosition={"right"}
        expandIcon={(iconProp) =>
          isLoadingFilter ? (
            <FontAwesomeIcon icon={faSpinner} />
          ) : iconProp.isActive ? (
            <FontAwesomeIcon icon={faMinus} />
          ) : (
            <FontAwesomeIcon icon={faPlus} />
          )
        }
      >
        {!location.pathname.includes("/for-you") &&
          !location.pathname.includes("/new") &&
          !location.pathname.includes("/products") &&
          !location.pathname.includes("/collection") &&
          !location.pathname.includes("/responsible") &&
          !location.pathname.includes("/foryou-all-users") && (
            <>
              <Panel
                header={params.gendre.toUpperCase()}
                key="1023"
                collapsible={isLoadingFilter && "disabled"}
              >
                {categories &&
                  uniqByKeepLast(categories, (k: any) => k.name).map(
                    (el: any, i: number) => (
                      <p
                        key={i}
                        className="categories_links"
                        style={{
                          color:
                            Number(params.categoryId) === el.id
                              ? "#00468c"
                              : "black",
                          textDecoration:
                            Number(params.categoryId) === el.id
                              ? "underline"
                              : "none",
                          fontWeight:
                            Number(params.categoryId) === el.id ? 600 : 400,
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          history.push(
                            `/product/${params.fabric}/${params.gendre}/${params.genderId}/${el.name}/${el.id}`
                          );
                          dispatch(
                            getFilterAttributes(
                              params.fabric,
                              el.id,
                              params.genderId
                            )
                          );
                        }}
                      >
                        {el.name}
                      </p>
                    )
                  )}
              </Panel>
              <Divider key={uuidv4()} />
            </>
          )}
        {location.pathname.includes("/collection") && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header="Collections"
              key="1"
            >
              {collections && isLoadingCollections
                ? Array.from({ length: 1 }, (_, index) => index + 1).map(
                    (_, i) => (
                      <Skeleton
                        active={true}
                        key={i}
                        title={false}
                        paragraph={{ rows: 6 }}
                      />
                    )
                  )
                : uniqByKeepLast(collections, (k: any) => k.name)
                    .sort((a: any, b: any) =>
                      a.sort_order > b.sort_order ? 1 : -1
                    )
                    .filter((k: any) => !k.collection_id)
                    .map((el: any, i: number) => {
                      return (
                        <div className="category_link_container" key={i}>
                          <p
                            className="categories_links"
                            style={{
                              marginBottom:
                                subElements.parent === el.id ? "10px" : "15px",
                              color:
                                Number(params.collectionId) === el.id
                                  ? "#00468c"
                                  : "black",
                              textDecoration:
                                Number(params.collectionId) === el.id
                                  ? "underline"
                                  : "none",
                              textTransform: "capitalize",
                            }}
                          >
                            <span
                              style={{
                                fontWeight:
                                  Number(params.collectionId) === el.id
                                    ? 600
                                    : 400,
                              }}
                              onClick={() =>
                                history.push(
                                  params.fabric === "denim"
                                    ? `/collection/${el.id}/denim`
                                    : `/collection/${el.id}/knit`
                                )
                              }
                            >
                              {el.name}
                            </span>
                            {el.children.sort((a: any, b: any) =>
                              a.sort_order > b.sort_order ? 1 : -1
                            ).length > 0 &&
                              (subElements.parent === el.id ? (
                                <DownOutlined
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setsubElements({
                                      parent: null,
                                      children: [],
                                    });
                                    setsubElementsChildren({
                                      parent: null,
                                      children: [],
                                    });
                                  }}
                                />
                              ) : (
                                <RightOutlined
                                  onClick={(e: any) =>
                                    CollectionSubElements(e, el)
                                  }
                                />
                              ))}
                          </p>
                          {subElements.parent === el.id &&
                            subElements.children.length > 0 && (
                              <div className="sub_elements">
                                {subElements.children &&
                                  subElements.children.map(
                                    (el: any, i: number) => {
                                      return (
                                        <div
                                          className="sub_element_container"
                                          key={i}
                                        >
                                          <p
                                            className="sub_element"
                                            style={{
                                              color:
                                                Number(params.collectionId) ===
                                                el.id
                                                  ? "#00468c"
                                                  : "black",
                                              textDecoration:
                                                Number(params.collectionId) ===
                                                el.id
                                                  ? "underline"
                                                  : "none",
                                            }}
                                            onClick={() =>
                                              history.push(
                                                params.fabric === "denim"
                                                  ? `/collection/${el.id}/denim`
                                                  : `/collection/${el.id}/knit`
                                              )
                                            }
                                          >
                                            <span
                                              style={{
                                                fontWeight:
                                                  Number(
                                                    params.collectionId
                                                  ) === el.id
                                                    ? 600
                                                    : 400,
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {el.name}
                                            </span>
                                            {el.children &&
                                              el.children.length > 0 &&
                                              (subElementChildren.parent ===
                                              el.id ? (
                                                <DownOutlined
                                                  onClick={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setsubElementsChildren({
                                                      parent: null,
                                                      children: [],
                                                    });
                                                  }}
                                                />
                                              ) : (
                                                <RightOutlined
                                                  onClick={(e: any) =>
                                                    CollectionSubElementChildren(
                                                      e,
                                                      el
                                                    )
                                                  }
                                                />
                                              ))}
                                          </p>
                                          {subElementChildren.parent ===
                                            el.id &&
                                            subElementChildren.children.length >
                                              0 && (
                                              <div className="sub_element_children">
                                                {subElementChildren.children &&
                                                  subElementChildren.children.map(
                                                    (el: any, i: number) => (
                                                      <div
                                                        className="sub_element_child_container"
                                                        key={i}
                                                      >
                                                        <p
                                                          className="sub_element_child"
                                                          style={{
                                                            color:
                                                              Number(
                                                                params.collectionId
                                                              ) === el.id
                                                                ? "#00468c"
                                                                : "black",
                                                            textDecoration:
                                                              Number(
                                                                params.collectionId
                                                              ) === el.id
                                                                ? "underline"
                                                                : "none",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontWeight:
                                                                Number(
                                                                  params.collectionId
                                                                ) === el.id
                                                                  ? 600
                                                                  : 400,
                                                              textTransform:
                                                                "capitalize",
                                                            }}
                                                            onClick={() => {
                                                              Number(el.id) !=
                                                                Number(
                                                                  params.collectionId
                                                                ) &&
                                                                history.push(
                                                                  params.fabric ===
                                                                    "denim"
                                                                    ? `/collection/${el.id}/denim`
                                                                    : `/collection/${el.id}/knit`
                                                                );
                                                            }}
                                                          >
                                                            {el.name}
                                                          </span>
                                                        </p>
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                            )}
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            )}
                        </div>
                      );
                    })}
            </Panel>
            <Divider key={uuidv4()} />
          </>
        )}

        {/* filter products by fabric type : still with some bugs * ! dont delete/}*/}
        {/*  {(location.pathname.includes("/for-you") ||
          location.pathname.includes("/new")) && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Fabric Type"}
              key="11"
            >
              <div className="d-flex justify-content-between flex-row-reverse align-items-center my-1 py-1">
                <input
                  type="radio"
                  className="filter_checkbox"
                  id="denim"
                  name="fabric"
                  checked={params.fabric === "denim" && true}
                  onClick={handleDenim}
                />
                <label htmlFor="denim" className="m-0">
                  <span>Denim</span>
                </label>
              </div>
              <div className="d-flex justify-content-between flex-row-reverse align-items-center my-1 py-1">
                <input
                  type="radio"
                  className="filter_checkbox"
                  id="knit"
                  name="fabric"
                  checked={params.fabric === "knit" && true}
                  onClick={handleKnit}
                />
                <label htmlFor="knit" className="m-0">
                  <span>Knit</span>
                </label>
              </div>
            </Panel>
            <Divider key={uuidv4()} />
          </>
        )} */}

        {/*   <Panel
          collapsible={isLoadingFilter && "disabled"}
          header={"Labels"}
          key="500"
        >
          {labels.length
            ? uniqByKeepLast(labels, (k: any) => k.name).map((el: any) => (
                <>
                  {el.name !== "LIMITED EDITION" && (
                    <FilterItem
                      item={el}
                      key={el.id}
                      name={"labels"}
                      addQuery={addQuery}
                    />
                  )}
                </>
              ))
            : labels
                .filter((el: any) => el.fabric_type === params.fabric)
                .map((el: any) => (
                  <>
                    {el.name !== "LIMITED EDITION" && (
                      <FilterItem
                        item={el}
                        key={el.id}
                        name={"labels"}
                        addQuery={addQuery}
                      />
                    )}
                  </>
                ))}
        </Panel>
        <Divider key={uuidv4()} /> */}

        {params.fabric && params.fabric === "denim" && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Mills"}
              key={uuidv4()}
            >
              {mills.length &&
                mills.map((el: any) => (
                  <>
                    <FilterItem
                      item={el}
                      key={el.id}
                      name={"mills"}
                      addQuery={addQuery}
                    />
                  </>
                ))}
            </Panel>
            <Divider key={uuidv4()} />
          </>
        )}
        {/*  {!location.pathname.includes("/products") && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Colours"}
              key="2"
            > */}
        {/*    {colors.length &&
          (location.pathname === "/for-you" || location.pathname === "/new")
            ? uniqByKeepLast(colors, (k: any) => k.name).map((el: any) => (
                <FilterItem
                  item={el}
                  key={el.id}
                  name={"colors"}
                  addQuery={addQuery}
                />
              ))
            : colors
                .filter((el: any) => el.fabric_type === params.fabric)
                .map((el: any) => (
                  <FilterItem
                    item={el}
                    key={el.id}
                    name={"colors"}
                    addQuery={addQuery}
                  />
                ))} */}
        {/* {colors.length &&
                colors
                  .filter(
                    (el: any) => el.fabric_type === params.fabric.toLowerCase()
                  )
                  .map((el: any) => (
                    <FilterItem
                      item={el}
                      key={el.id}
                      name={"colors"}
                      addQuery={addQuery}
                    />
                  ))}*/}

        {/*   {allcolors &&
                allcolors
                  .filter(
                    (el: any) => el.fabric_type === params.fabric?.toLowerCase()
                  )
                  .map((el: any) => (
                    <FilterItem
                      item={el}
                      key={el.id}
                      name={"allcolors"}
                      addQuery={addQuery}
                    />
                  ))}
            </Panel>
            <Divider key={uuidv4()} />
          </>
        )} */}
        {location.pathname.includes("/foryou-all-users") ||
          (location.pathname.includes("/products") && (
            <>
              <Panel
                collapsible={isLoadingFilter && "disabled"}
                header={"Colours"}
                key="2"
              >
                {/*    {colors.length &&
          (location.pathname === "/for-you" || location.pathname === "/new")
            ? uniqByKeepLast(colors, (k: any) => k.name).map((el: any) => (
                <FilterItem
                  item={el}
                  key={el.id}
                  name={"colors"}
                  addQuery={addQuery}
                />
              ))
            : colors
                .filter((el: any) => el.fabric_type === params.fabric)
                .map((el: any) => (
                  <FilterItem
                    item={el}
                    key={el.id}
                    name={"colors"}
                    addQuery={addQuery}
                  />
                ))} */}

                {allcolors &&
                  allcolors.map((el: any) => (
                    <FilterItem
                      item={el}
                      key={el.id}
                      name={"allcolors"}
                      addQuery={addQuery}
                    />
                  ))}
              </Panel>
              <Divider key={uuidv4()} />
            </>
          ))}
        {/*  <Divider key={uuidv4()} />
        {params.fabric && params.fabric === "denim" && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Fabric characteristics"}
              key="25"
            >
              {fabric_chars.length &&
                fabric_chars.map((el: any) => (
                  <>
                    {el.is_masked != 1 && (
                      <FilterItem
                        item={el}
                        key={el.id}
                        name={"fabric_chars"}
                        addQuery={addQuery}
                      />
                    )}
                  </>
                ))}
            </Panel>
            <Divider key={uuidv4()} />
          </>
        )} */}
        {!location.pathname.includes("/products") && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Special fibers"}
              key="9"
            >
              <>
                {special_fibers.length &&
                (location.pathname === "/for-you" ||
                  location.pathname.includes("/foryou-all-users") ||
                  location.pathname === "/new")
                  ? special_fibers.map((el: any) => (
                      <>
                        <FilterItem
                          item={el}
                          key={el.id}
                          name={"special_fibers"}
                          addQuery={addQuery}
                        />
                      </>
                    ))
                  : special_fibers
                      .filter(
                        (el: any) =>
                          el.fabric_type === params.fabric.toLowerCase()
                        // (el: any) => el.fabric_type === params.fabric.toLowerCase()
                      )
                      .map((el: any) => (
                        <>
                          <FilterItem
                            item={el}
                            key={el.id}
                            name={"special_fibers"}
                            addQuery={addQuery}
                          />
                        </>
                      ))}
                {/* {compositions.length > 0 &&
              compositions.map((el: any) => (
                <FilterItem
                  item={el}
                  key={el.id}
                  name={"compositions"}
                  addQuery={addQuery}
                />
              ))} */}
              </>
            </Panel>
            <Divider key={uuidv4()} />
          </>
        )}
        {/*   <Panel
          collapsible={isLoadingFilter && "disabled"}
          header={"Weight"}
          key="5"
        >
          {weights.length &&
          (location.pathname === "/for-you" || location.pathname === "/new")
            ? uniqByKeepLast(weights, (k: any) => k.name).map((el: any) => (
                <FilterItem
                  item={el}
                  key={el.id}
                  name={"weights"}
                  addQuery={addQuery}
                />
              ))
            : weights
                .filter((el: any) => el.fabric_type === params.fabric)
                .map((el: any) => (
                  <FilterItem
                    item={el}
                    key={el.id}
                    name={"weights"}
                    addQuery={addQuery}
                  />
                ))}
        </Panel>
        <Divider key={uuidv4()} /> */}

        {(location.pathname === "/for-you" ||
          location.pathname.includes("/new/denim") ||
          location.pathname.includes("/products") ||
          params.fabric === "denim") && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Elasticity"}
              key="20"
            >
              <div className="price_range_wrapper">
                <Slider
                  range
                  defaultValue={[
                    elasticity_fabric?.min_elasticity_value,
                    elasticity_fabric?.max_elasticity_value,
                  ]}
                  min={elasticity_fabric?.min_elasticity_value}
                  max={elasticity_fabric?.max_elasticity_value}
                  onChange={(val: any) => {
                    let [min, max] = val;
                    setMin_elasticity_before(min);
                    setMax_elasticity_before(max);
                  }}
                  onAfterChange={handleChangeElasticity}
                  value={[min_elasticity_before, max_elasticity_before]}
                />
                <div className="d-flex justify-content-between prices_filter">
                  <p>{min_elasticity}</p>
                  <p>{max_elasticity}</p>
                </div>
              </div>
            </Panel>
            <Divider key={uuidv4()} />

            {/*   <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"EIM score"}
              key="515"
            >
              {eim_scores.map(
                (el: any) =>
                  el.name !== "Red" && (
                    <>
                      <FilterItem
                        item={el}
                        key={el.id}
                        name={"eim_scores"}
                        addQuery={addQuery}
                      />
                    </>
                  )
              )}
            </Panel>
            <Divider key={uuidv4()} /> */}
          </>
        )}

        <Panel
          collapsible={isLoadingFilter && "disabled"}
          header={"Weight"}
          key="20-Weight"
        >
          <div className="price_range_wrapper">
            <Slider
              range
              defaultValue={[
                weight_fabric?.min_weight_value,
                weight_fabric?.max_weight_value,
              ]}
              min={weight_fabric?.min_weight_value}
              max={weight_fabric?.max_weight_value}
              onChange={(val: any) => {
                let [min, max] = val;
                setMin_weight_before(min);
                setMax_weight_before(max);
              }}
              onAfterChange={handleChangeWeight}
              value={[min_weight_before, max_weight_before]}
            />
            <div className="d-flex justify-content-between prices_filter">
              <p>{min_weight}</p>
              <p>{max_weight}</p>
            </div>
          </div>
        </Panel>
        <Divider key={uuidv4()} />
        {params.fabric && params.fabric === "denim" && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Wash"}
              key="3"
            >
              {washes.length &&
              (location.pathname === "/for-you" ||
                location.pathname === "/new" ||
                location.pathname.includes("/products"))
                ? uniqByKeepLast(washes, (k: any) => k.name).map((el: any) => (
                    <FilterItem
                      item={el}
                      key={el.id}
                      name={"washes"}
                      addQuery={addQuery}
                    />
                  ))
                : washes
                    .filter((el: any) => el.fabric_type === params.fabric)
                    .map((el: any) => (
                      <FilterItem
                        item={el}
                        key={el.id}
                        name={"washes"}
                        addQuery={addQuery}
                      />
                    ))}
            </Panel>
            <Divider key={uuidv4()} />
          </>
        )}
        {!user?.is_client_clients && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Price"}
              key="7price"
            >
              <div className="price_range_wrapper">
                <Slider
                  step={0.01}
                  range
                  defaultValue={[prices.min_price, prices.max_price]}
                  max={prices.max_price}
                  min={prices.min_price}
                  onAfterChange={handleChangePrice}
                  onChange={handleChangePriceAfter}
                  value={[min_priceBefore, max_priceBefore]}
                />
                <div className="d-flex justify-content-between prices_filter">
                  <p>{min_price} €</p>
                  <p>{max_price} €</p>
                </div>
              </div>
            </Panel>
            <Divider key={uuidv4()} />
          </>
        )}
        {params.fabric &&
          params.fabric === "Knit" &&
          TechniquesFilter(techniques)}

        {/*   {responsible_choices.length && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Our responsible choice"}
              key="12"
            >
              {responsible_choices.length &&
              (location.pathname === "/for-you" || location.pathname === "/new")
                ? uniqByKeepLast(responsible_choices, (k: any) => k.name).map(
                    (el: any) => (
                      <FilterItem
                        item={el}
                        key={el.id}
                        name={"responsible_choices"}
                        addQuery={addQuery}
                      />
                    )
                  )
                : responsible_choices
                    .filter((el: any) => el.fabric_type === params.fabric)
                    .map((el: any) => (
                      <FilterItem
                        item={el}
                        key={el.id}
                        name={"responsible_choices"}
                        addQuery={addQuery}
                      />
                    ))}
            </Panel>
            <Divider key={uuidv4()} />
          </>
        )} */}
        {params.fabric && (
          <>
            {console.log(product_fabric_types)}
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Fabric types"}
              key={uuidv4()}
            >
              {product_fabric_types.length &&
                product_fabric_types.map((el: any) => (
                  <FilterItem
                    item={el}
                    key={el.id}
                    name={"product_fabric_types"}
                    addQuery={addQuery}
                  />
                ))}
            </Panel>
            <Divider key={uuidv4()} />
          </>
        )}

        {location.pathname.includes("/foryou-all-users") && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Fabric types"}
              key={uuidv4()}
            >
              {all_product_fabric_types.length &&
                all_product_fabric_types.map((el: any) => (
                  <FilterItem
                    item={el}
                    key={el.id}
                    name={"all_product_fabric_types"}
                    addQuery={addQuery}
                  />
                ))}
            </Panel>
            <Divider key={uuidv4()} />
          </>
        )}
        {params.fabric && params.fabric === "knit" && (
          <>
            {/*     <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Composition"}
              key="7"
            >
              {compositions.length &&
                compositions.map((el: any) => (
                  <FilterItem
                    item={el}
                    key={el.id}
                    name={"compositions"}
                    addQuery={addQuery}
                  />
                ))}
            </Panel>
            <Divider key={uuidv4()} /> */}
          </>
        )}

        {/* <>
          <Divider key={uuidv4()} />
          <Panel collapsible={isLoadingFilter && "disabled"} header={"Technique"} key="8">
            {techniques.length &&
              techniques.map((el: any) => {
                el.children.length > 0 ? (
                  <>
                    <FilterItem
                      item={el}
                      key={el.id}
                      name={"technics"}
                      addQuery={addQuery}
                    />
                    <div style={{ marginLeft: 20 }}>
                      {el.children.map((technique: any) => (
                        <FilterItem
                          item={technique}
                          key={technique.id}
                          name={"technics"}
                          addQuery={addQuery}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <FilterItem
                    item={el}
                    key={el.id}
                    name={"technics"}
                    addQuery={addQuery}
                  />
                );
              })}
          </Panel>
        </> */}

        {/* {params.fabric && params.fabric === "knit" && ( */}
        {params.fabric &&
          params.fabric === "denim" &&
          TechniquesFilter(techniques)}

        {params.fabric &&
          params.fabric === "mix" &&
          TechniquesFilter(techniques)}
        {/* )} */}

        {/* <Panel collapsible={isLoadingFilter && "disabled"} header={"EIM Score"} key="515">
          {eim_scores.length &&
          (location.pathname === "/for-you" || location.pathname === "/new")
            ? uniqByKeepLast(eim_scores, (k: any) => k.name).map((el: any) => (
                <FilterItem
                  item={el}
                  key={el.id}
                  name={"eim_scores"}
                  addQuery={addQuery}
                />
              ))
            : eim_scores.map((el: any) => (
                <FilterItem
                  item={el}
                  key={el.id}
                  name={"eim_scores"}
                  addQuery={addQuery}
                />
              ))}
        </Panel>
        <Divider key={uuidv4()} /> */}

        {/* <Divider key={uuidv4()} />
        <Panel collapsible={isLoadingFilter && "disabled"} header={"Fiber"} key="6">
          {fibers.length &&
            fibers.map((el: any) => (
              <FilterItem
                item={el}
                key={el.id}
                name={"fibers"}
                addQuery={addQuery}
              />
            ))}
        </Panel> */}

        {/* {(location.pathname === "/for-you" ||
          location.pathname.includes("/new") ||
          location.pathname.includes("/collection")) && (
          <>
            <Panel
              collapsible={isLoadingFilter && "disabled"}
              header={"Gender"}
              key="501"
            >
              {genders_filter &&
                Object.keys(genders_filter)
                  .reverse()
                  .map((key: string) => {
                    return genders_filter[key].map(
                      (el: any) =>
                        el.name !== "Kids" && (
                          <FilterItem
                            item={el}
                            key={el.id}
                            name={"genders"}
                            addQuery={addQuery}
                          />
                        )
                    );
                  })}

            </Panel>
            <Divider key={uuidv4()} />
          </>
        )} */}

        {/*  <Panel
          collapsible={isLoadingFilter && "disabled"}
          header={"Dress code"}
          key="28"
        >
          {dress_codes.map((el: any) => (
            <FilterItem
              item={el}
              key={el.id}
              name={"dress_codes"}
              addQuery={addQuery}
            />
          ))}
        </Panel>
        <Divider key={uuidv4()} />

        <Panel
          collapsible={isLoadingFilter && "disabled"}
          header={"Fit"}
          key="83"
        >
          {fits.length &&
          (location.pathname === "/for-you" || location.pathname === "/new")
            ? fits.map((el: any) => (
                <FilterItem
                  item={el}
                  key={el.id}
                  name={"fits"}
                  addQuery={addQuery}
                />
              ))
            : fits
                .filter((el: any) => el.fabric_type === params.fabric)
                .map((el: any) => (
                  <FilterItem
                    item={el}
                    key={el.id}
                    name={"fits"}
                    addQuery={addQuery}
                  />
                ))}
        </Panel>
        <Divider key={uuidv4()} /> */}
      </Collapse>
    </aside>
  );
};
