import selection1 from "../../img/selection1.png";
import selection2 from "../../img/selection2.png";
import selection3 from "../../img/selection3.png";
import Close from "../../icons/Close";
import "./Notifications.scss";
import { SelectionNotif } from "./SelectionNotif";
import { CollectionNotif } from "./CollectionNotif";
import { ArticleNotif } from "./ArticleNotif";
import { Link } from "react-router-dom";
import { handleRightDrawer } from "../../actions/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getNotifications } from "../../actions/notifications-actions/actions";
import { getNotificationsAdmin } from "../../actions/notifications-actions/actions";

import { getGenders } from "../../actions/left-menu-actions/actions";
import { AddedProduct } from "./AddedProduct";
import { ProgressPrototypeNotif } from "./ProgressPrototypeNotif";
import ShoppingBag from "../../icons/ShoppingBag";
import moment from "moment";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import { InvitationCollab } from "./InvitationCollab";
import { OrderSentNotif } from "./OrderSentNotif";
import { DirectOrderChanged } from "./DirectOrderChanged";
import { NewCollectionDemco } from "./NewCollectionDemco";
import { SelectionIsWaiting } from "./SelectionIsWaiting";
import { RequestAccess } from "./RequestAccess";
import BookingNotification from "./BookingNotification";
import BookingCancel from "./BookingCancel";
import BookingUpdated from "./BookingUpdated";
import ClientConnected from "./ClientConnected";
import TeammateConnected from "./TeammateConnected";
import ClientClientsInvitation from "./ClientClientsInvitation";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import DirectOrderCreated from "./DirectOrderCreated";
import ProtoUploaded from "./ProtoUploaded";
import CustomizedProductSent from "./CustomizedProductSent";
import ProtoAccepted from "./ProtoAccepted";
import ProtoRejected from "./ProtoRejected";
import { useMutation, useQuery } from "react-query";
import axiosInstance from "../../config/axios-instance";
import { loadConnectedUser } from "../../actions/auth-actions/actions";
import NewPost from "./NewPost";
import { NewMoodboard } from "./newMoodboard";
import { NewCommentNotif } from "./NewCommentNotif";
import ProductForYouAdded from "./ProductForYouAdded";
import { UpdateOrder } from "./UpdateOrder";
import { NewResponsibleChoice } from "./NewResponsibleChoice";
import { ConfirmStep } from "./ConfirmStep";
import CollaboratorApproved from "./CollaboratorApproved";
import { CollectionNotification } from "./CollectionNotification";
import { RequestFolderAccess } from "./RequestFolderAccess";

interface Props {
  closeDrawer: () => void;
}

export const Notifications = ({ closeDrawer }: Props) => {
  // const { notifications, isLoading } = useSelector(
  //   (state: any) => state.notifications
  // );
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  const { notifications, isLoading, next, loadingMore } = useSelector(
    (state: any) => state.notifications
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAdmin) {
      dispatch(getNotifications());
    } else {
      dispatch(getNotificationsAdmin());
    }
    dispatch(getGenders());
  }, []);

  const getNotification = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/notifications`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const getNotificationAdmin = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/notifications`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  // const readAllNotifications = () => {
  //   let token = localStorage.getItem("token");
  //   return axiosInstance({
  //     method: "get",
  //     url: `/notifications/read-all`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // };

  // const { mutate } = useMutation(readAllNotifications);
  /*   const { data, isLoading } = useQuery("notifications", getNotification);
   */
  const notificationType = (notif: any, position: number) => {
    switch (notif.type) {
      case "App\\Notifications\\DirectOrderProgress":
        if (notif.data.status !== "waiting") {
          return (
            <ProgressPrototypeNotif
              key={notif.id}
              notif={notif}
              inPage={false}
              position={position <= user?.unread_notifications_count}
            />
          );
        } else return;
      case "App\\Notifications\\NewProductCreatedByDemco":
        return (
          <AddedProduct
            notif={notif}
            key={notif.id}
            inPage={false}
            position={position <= user?.unread_notifications_count}
            fabric={false}
          />
        );
      case "App\\Notifications\\DirectOrderChanged":
        if (notif.data.order_article) {
          return (
            notif.data.order_article.article_status_id !== 1 && (
              <DirectOrderChanged
                notif={notif}
                key={notif.id}
                inPage={false}
                position={position <= user?.unread_notifications_count}
              />
            )
          );
        } else {
          return (
            <DirectOrderChanged
              notif={notif}
              key={notif.id}
              inPage={false}
              position={position <= user?.unread_notifications_count}
            />
          );
        }
      case "App\\Notifications\\DirectOrderSent":
        return (
          <OrderSentNotif
            notif={notif}
            key={notif.id}
            inPage={false}
            position={position <= user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\NewCollectionCreatedByDemco":
        return (
          <NewCollectionDemco
            key={notif.id}
            notif={notif}
            inPage={false}
            position={position <= user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\SelectionNotification":
        return (
          <SelectionIsWaiting
            notif={notif}
            key={notif.id}
            inPage={false}
            position={position <= user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\RequestDirectOrderAccess":
        return (
          <RequestAccess
            notif={notif}
            key={notif.id}
            inPage={false}
            position={position <= user?.unread_notifications_count}
          />
        );

        case "App\\Notifications\\RequestFolderAccess":
          return (
            <RequestFolderAccess
              notif={notif}
              key={notif.id}
              inPage={false}
              position={position <= user?.unread_notifications_count}
            />
          );
      case "App\\Notifications\\ClientUpdated":
        return null;
      case "App\\Notifications\\MeetingCreatedNotification":
        return (
          <BookingNotification
            notif={notif}
            key={notif.id}
            inPage={false}
            position={position <= user?.unread_notifications_count}
            status={"new"}
          />
        );
      case "App\\Notifications\\MeetingAcceptedNotification":
        return (
          <BookingNotification
            notif={notif}
            key={notif.id}
            inPage={false}
            position={position <= user?.unread_notifications_count}
            status={"accepted"}
          />
        );
      case "App\\Notifications\\NewFabricAdded":
        return (
          <AddedProduct
            notif={notif}
            key={notif.id}
            inPage={false}
            position={position <= user?.unread_notifications_count}
            fabric={true}
          />
        );
      case "App\\Notifications\\MeetingCanceledNotification":
        return (
          <BookingCancel
            notif={notif}
            key={notif.id}
            inPage={false}
            position={position <= user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\MeetingUpdatedNotification":
        return (
          <BookingUpdated
            notif={notif}
            key={notif.id}
            inPage={false}
            position={position <= user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\ClientConnected":
        return (
          <ClientConnected
            notif={notif}
            inPage={false}
            position={position <= user?.unread_notifications_count}
            key={notif.id}
          />
        );
      case "App\\Notifications\\CompanyUserConnected":
        return (
          <TeammateConnected
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\DirectOrderCreated":
        return (
          <DirectOrderCreated
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\ProtoUploaded":
        return (
          <ProtoUploaded
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\CustomizedProductSent":
        return (
          <CustomizedProductSent
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\ProtoAccepted":
        return (
          <ProtoAccepted
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\ProtoRejected":
        return (
          <ProtoRejected
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\NewPost":
        return (
          <NewPost
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\NewMoodboardAdded":
        return (
          <NewMoodboard
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );

      case "App\\Notifications\\NewCommentNotifAdded":
        return (
          <NewCommentNotif
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );

      case "App\\Notifications\\ProductForYou":
        return (
          <ProductForYouAdded
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );

      case "App\\Notifications\\UpdateOrder":
        return (
          <UpdateOrder
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\NewResponsibleChoice":
        return (
          <NewResponsibleChoice
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\ConfirmStep":
        return (
          <ConfirmStep
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\CollectionNotification":
        return (
          <CollectionNotification
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\CollaboratorApproved":
        return (
          <CollaboratorApproved
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );

      case "App\\Notifications\\ClientClientsNotification":
        return (
            <ClientClientsInvitation
                inPage={false}
                position={position <= user?.unread_notifications_count}
                notif={notif}
                key={notif.id}
            />
        );




      // RequestForCollaboration
      default:
        return (
          <InvitationCollab
            notif={notif}
            key={notif.id}
            inPage={false}
            position={position <= user?.unread_notifications_count}
          />
        );
    }
  };

  return (
      <aside className="notifications_aside">
        <header>
          <div className="close_btn" onClick={closeDrawer}>
            <p>Close</p>
            <Close/>
          </div>
        </header>
        {/*
      <h1 className="h2_subtitle_black">Notifications</h1>
*/}

        <h1 className="h2_subtitle_black_notification_title">Notifications</h1>
        <main>
          {isLoading ? (
              <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                <Spin
                    indicator={
                      <LoadingOutlined
                          style={{fontSize: 40, color: "#00468c"}}
                          spin
                      />
                    }
                />
              </div>
          ) : (
              notifications?.map((el: any, index: number) =>
                  notificationType(el, index + 1)
              )
          )}
        </main>
        <div className="myselection_see_all">
          <Link
              to="/notifications"
              onClick={() => dispatch(handleRightDrawer())}
              style={{width: "100%"}}
          >
            <button className="submit_button_white" style={{width: "100%"}}>
              See All
            </button>
          </Link>
        </div>
      </aside>
  );
};
