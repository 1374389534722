export interface IIconProps {
  className?: string;
}

function Folders({ className }: IIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 25 20"
      className={className}
    >
      <path
        fill="#00468c"
        d="M23.125-15h-7.5l-2.134-2.134a1.25 1.25 0 00-.884-.366H6.875A1.875 1.875 0 005-15.625v3.125H1.875A1.875 1.875 0 000-10.625V.625A1.875 1.875 0 001.875 2.5h16.25A1.875 1.875 0 0020 .625V-2.5h3.125A1.875 1.875 0 0025-4.375v-8.75A1.875 1.875 0 0023.125-15zm-5 15.625H1.875v-11.25H5v6.25A1.875 1.875 0 006.875-2.5h11.25zm5-5H6.875v-11.25h5.473l2.134 2.134a1.25 1.25 0 00.884.366h7.759z"
        data-name="Tracé 3323"
        opacity="0.5"
        transform="translate(0 17.5)"
      ></path>
    </svg>
  );
}

export default Folders;
