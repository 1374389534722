import { Button, Input } from "antd";
import React from "react";
import FabricCharsCollapse from "./FabricCharsCollapse";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

function EditFabricChars({
  productToUpdate,
  genExtraFabricChar,
  setProductToUpdate,
  setData,
  data,
  options,
  handleChange,
  handleChangeFabricChar,
  addNewFabricChar,
}: any) {
  const { user } = useSelector((state: any) => state.auth);

  return (
    <div>
      {/* {productToUpdate.fabric_chars.length === 0 ? (
        <h6 className="text-center p-2 mt-2 text-muted product_doesnt_have">
          This product doesn't have fabric
        </h6>
      ) : (
        productToUpdate.fabric_chars.map((fc: any, index: number) => (
          <FabricCharsCollapse
            key={index}
            index={index}
            fc={fc}
            genExtraFabricChar={genExtraFabricChar}
            setProductToUpdate={setProductToUpdate}
            productToUpdate={productToUpdate}
            setData={setData}
            data={data}
            options={options}
            handleChangeFabricChar={handleChangeFabricChar}
          />
        ))
      )} */}
      {/*   <Button
        type="primary"
        disabled={
          !user.permissions.includes("product.fabric.management") &&
          !user.permissions.includes("product.update")
        }
        icon={<PlusOutlined style={{ verticalAlign: 0 }} />}
        className="add_new_one"
        onClick={addNewFabricChar}
      >
        Add Another Fabric
      </Button> */}
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <th>Composition</th>
            <td>
              <Input
                type="text"
                placeholder="Composition"
                value={productToUpdate.fabric_composition}
                name="fabric_composition"
                disabled={
                  !user.permissions.includes("product.fabric.management") &&
                  !user.permissions.includes("product.update")
                }
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <th>Elasticity</th>
            <td>
              <Input
                type="number"
                disabled={
                  !user.permissions.includes("product.fabric.management") &&
                  !user.permissions.includes("product.update")
                }
                placeholder="Elasticity percentage"
                value={Math.round(productToUpdate.elasticity * 100)}
                step={1}
                max={100}
                min={0}
                addonAfter="%"
                name="elasticity"
                onChange={(e: any) => {
                  setProductToUpdate({
                    ...productToUpdate,
                    elasticity: +e.target.value / 100,
                  });
                  setData({ ...data, elasticity: +e.target.value / 100 });
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EditFabricChars;
