import "../sass/NotificationPage.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  getNotificationsAdmin,
  loadMoreNotifications,
} from "../actions/notifications-actions/actions";
import { ProgressPrototypeNotif } from "../components/Notifications/ProgressPrototypeNotif";
import { AddedProduct } from "../components/Notifications/AddedProduct";
import { DirectOrderChanged } from "../components/Notifications/DirectOrderChanged";
import { OrderSentNotif } from "../components/Notifications/OrderSentNotif";
import { InvitationCollab } from "../components/Notifications/InvitationCollab";
import { NewMoodboard } from "../components/Notifications/newMoodboard";

import { NewCollectionDemco } from "../components/Notifications/NewCollectionDemco";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { SelectionIsWaiting } from "../components/Notifications/SelectionIsWaiting";
import { RequestAccess } from "../components/Notifications/RequestAccess";
import Head from "../components/Head";
import BookingNotification from "../components/Notifications/BookingNotification";
import BookingCancel from "../components/Notifications/BookingCancel";
import BookingUpdated from "../components/Notifications/BookingUpdated";
import ClientConnected from "../components/Notifications/ClientConnected";
import TeammateConnected from "../components/Notifications/TeammateConnected";
import DirectOrderCreated from "../components/Notifications/DirectOrderCreated";
import ProtoUploaded from "../components/Notifications/ProtoUploaded";
import CustomizedProductSent from "../components/Notifications/CustomizedProductSent";
import ProtoAccepted from "../components/Notifications/ProtoAccepted";
import ProtoRejected from "../components/Notifications/ProtoRejected";
import NewPost from "../components/Notifications/NewPost";
import { NewCommentNotif } from "../components/Notifications/NewCommentNotif";
import ProductForYouAdded from "../components/Notifications/ProductForYouAdded";
import { UpdateOrder } from "../components/Notifications/UpdateOrder";
import { NewResponsibleChoice } from "../components/Notifications/NewResponsibleChoice";
import { ConfirmStep } from "../components/Notifications/ConfirmStep";
import CollaboratorApproved from "../components/Notifications/CollaboratorApproved";
import { CollectionNotification } from "../components/Notifications/CollectionNotification";
import { RequestFolderAccess } from "../components/Notifications/RequestFolderAccess";
import ClientClientsInvitation from "../components/Notifications/ClientClientsInvitation";

interface Props {}

export const Notifications = (props: Props) => {
  const dispatch = useDispatch();
  const { notifications, isLoading, next, loadingMore } = useSelector(
    (state: any) => state.notifications
  );
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (!isAdmin) {
      dispatch(getNotifications());
    } else {
      dispatch(getNotificationsAdmin());
    }
    // eslint-disable-next-line
  }, []);

  const notificationType = (notif: any, position: number) => {
    console.log(notif.type);

    switch (notif.type) {
      case "App\\Notifications\\NewMoodboardAdded":
        return (
          <NewMoodboard
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\DirectOrderProgress":
        if (notif.data.status !== "waiting") {
          return (
            <ProgressPrototypeNotif
              key={notif.id}
              notif={notif}
              inPage={true}
              position={position < user?.unread_notifications_count}
            />
          );
        } else return;
      case "App\\Notifications\\NewProductCreatedByDemco":
        return (
          <AddedProduct
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\DirectOrderChanged":
        if (notif.data.order_article) {
          return (
            notif.data.order_article.article_status_id !== 1 && (
              <DirectOrderChanged
                notif={notif}
                key={notif.id}
                inPage={true}
                position={position < user?.unread_notifications_count}
              />
            )
          );
        } else {
          return (
            <DirectOrderChanged
              notif={notif}
              key={notif.id}
              inPage={true}
              position={position < user?.unread_notifications_count}
            />
          );
        }
      case "App\\Notifications\\DirectOrderSent":
        return (
          <OrderSentNotif
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\NewCollectionCreatedByDemco":
        return (
          <NewCollectionDemco
            key={notif.id}
            notif={notif}
            inPage={true}
            position={position < user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\SelectionNotification":
        return (
          <SelectionIsWaiting
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\RequestDirectOrderAccess":
        return (
          <RequestAccess
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
          />
        );

        case "App\\Notifications\\RequestFolderAccess":
          return (
            <RequestFolderAccess
              notif={notif}
              key={notif.id}
              inPage={false}
              position={position <= user?.unread_notifications_count}
            />
          );
      case "App\\Notifications\\ClientUpdated":
        return null;
      case "App\\Notifications\\MeetingCreatedNotification":
        return (
          <BookingNotification
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
            status={"new"}
          />
        );
      case "App\\Notifications\\MeetingAcceptedNotification":
        return (
          <BookingNotification
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
            status={"accepted"}
          />
        );
      case "App\\Notifications\\NewFabricAdded":
        return (
          <AddedProduct
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
            fabric={true}
          />
        );
      case "App\\Notifications\\MeetingCanceledNotification":
        return (
          <BookingCancel
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\MeetingUpdatedNotification":
        return (
          <BookingUpdated
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\ClientConnected":
        return (
          <ClientConnected
            notif={notif}
            inPage={true}
            position={position < user?.unread_notifications_count}
            key={notif.id}
          />
        );
      case "App\\Notifications\\CompanyUserConnected":
        return (
          <TeammateConnected
            inPage={true}
            position={position < user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\DirectOrderCreated":
        return (
          <DirectOrderCreated
            inPage={true}
            position={position < user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\ProtoUploaded":
        return (
          <ProtoUploaded
            inPage={true}
            position={position < user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\CustomizedProductSent":
        return (
          <CustomizedProductSent
            inPage={true}
            notif={notif}
            key={notif.id}
            position={position < user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\ProtoAccepted":
        return (
          <ProtoAccepted
            inPage={true}
            notif={notif}
            key={notif.id}
            position={position < user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\ProtoRejected":
        return (
          <ProtoRejected
            inPage={true}
            notif={notif}
            key={notif.id}
            position={position < user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\NewPost":
        return (
          <NewPost
            inPage={true}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );

      case "App\\Notifications\\NewMoodboardAdded":
        return (
          <NewMoodboard
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
          />
        );
      case "App\\Notifications\\NewCommentNotifAdded":
        return (
          <NewCommentNotif
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );

      case "App\\Notifications\\ProductForYou":
        return (
          <ProductForYouAdded
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );

      case "App\\Notifications\\UpdateOrder":
        return (
          <UpdateOrder
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\NewResponsibleChoice":
        return (
          <NewResponsibleChoice
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );

      case "App\\Notifications\\ConfirmStep":
        return (
          <ConfirmStep
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );

      case "App\\Notifications\\CollectionNotification":
        return (
          <CollectionNotification
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\CollaboratorApproved":
        return (
          <CollaboratorApproved
            inPage={false}
            position={position <= user?.unread_notifications_count}
            notif={notif}
            key={notif.id}
          />
        );
      case "App\\Notifications\\ClientClientsNotification":
        return (
            <ClientClientsInvitation
                inPage={false}
                position={position <= user?.unread_notifications_count}
                notif={notif}
                key={notif.id}
            />
        );


      default:
        return (
          <InvitationCollab
            notif={notif}
            key={notif.id}
            inPage={true}
            position={position < user?.unread_notifications_count}
          />
        );
    }
  };

  return (
    <div className="notif_page">
      <Head title="Notifications" />
      <div className="container">
        <div className="row mt-4 mb-2">
          <div className="col-12">
            <h1 className="notif_page_title my-2">Notifications</h1>
          </div>
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          notifications &&
          notifications.map((notif: any, index: number) => (
            <>
              {console.log(notif)}
              <div className="row" key={notif.id}>
                <div className="col-12">{notificationType(notif, index)}</div>
              </div>
            </>
          ))
        )}
        <div className="row">
          <div className="col-12 text-center">
            {next && (
              <Button
                type="ghost"
                className="load_more_notif_btn rounded bg-white my-2 mb-3"
                onClick={() => dispatch(loadMoreNotifications(next))}
              >
                {loadingMore ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  "Load more"
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
