import axiosInstance from "../../config/axios-instance";

const getProducts = (page: number) => {
  return axiosInstance({
    method: "get",
    url: `/products?page=${page}`,
  });
};

const getCollectionProducts = (page: number) => {
  return axiosInstance({
    method: "get",
    url: `/${page}`,
  });
};

const getAllType = (type: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/${type}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const filterProduct = (url: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const filterProductFabric = (
  fabric: string,
  categoryId: string,
  genderId: string,
  min_price?: any,
  max_price?: any
) => {
  if (min_price && max_price) {
    return axiosInstance({
      method: "get",
      url: `/products?fabric_types=${fabric}&genders=${genderId}&categories=${categoryId}&min_price=${min_price}&max_price=${max_price}`,
    });
  } else {
    return axiosInstance({
      method: "get",
      url: `/products?fabric_types=${fabric}&genders=${genderId}&categories=${categoryId}`,
    });
  }
};

const filterByName = (name: string) => {
  return axiosInstance({
    method: "get",
    url: `/products?name=${name}`,
  });
};
const loadMoreSearchResult = (url: string) => {
  return axiosInstance({
    method: "get",
    url,
  });
};

const getOneProduct = (id: number) => {
  return axiosInstance({
    method: "get",
    url: `/products/${id}`,
  });
};

// GET ONE CUSTOMIZED PRODUCT
const getCustomizedProduct = (id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/customized_products/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// UPDATE PRODUCT
const updateProduct = (data: any, id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    // baseURL: `https://demco.halber.space/admin/products/${id}`,
    baseURL: `${process.env.REACT_APP_ADMIN_URL}/products/${id}`,
    data: { ...data, _method: "patch" },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get Filters
const getFilters = (fabric_type: string, categroyId: any, genderId: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/filters?fabric_type=${fabric_type}&categoyid=${categroyId}&genderId=${genderId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const ProductServices = {
  getProducts,
  getAllType,
  filterProduct,
  filterProductFabric,
  filterByName,
  getOneProduct,
  getCollectionProducts,
  getCustomizedProduct,
  loadMoreSearchResult,
  updateProduct,
  getFilters,
};

export default ProductServices;
