import {
  faCheck,
  faEllipsisH,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Popover, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  createArticle,
  deleteOneArticle,
  getOneDOrderCommerical,
  selectArticle,
  selectOption,
  updateArticle,
} from "../../actions/order-actions/actions";
import { LoadingOutlined } from "@ant-design/icons";
import { OptionCard } from "./OptionCard";
import axiosInstance from "../../config/axios-instance";
import { useParams } from "react-router-dom";

const { Panel } = Collapse;

interface Props {
  article: any;
  dispatch: any;
  order: any;
  handleChange: (x: any, y: any) => void;
  optionSelected: any;
  setOptionSelected: () => void;
}

export const OrderArticleItem = ({
  article,
  dispatch,
  order,
  handleChange,
  optionSelected,
  setOptionSelected,
}: Props) => {
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<any>();
  const { id } = useParams<any>();

  const { toDeleteArticle, orders, duplicated, deleteArticleLoading } =
    useSelector((state: any) => state.orders);
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (showInput) {
      inputRef.current.focus();
    }
  }, [showInput]);
  return (
    <Collapse
      defaultActiveKey={order.articles[0].id}
      key={article.id}
      onChange={() => dispatch(selectArticle(article.id))}
    >
      <Panel
        header={
          !showInput ? (
            article.name
          ) : (
            <>
              <input
                type="text"
                ref={inputRef}
                className="article_name_input"
                value={article.name}
                onChange={(e) => handleChange(e, article)}
              />
              <FontAwesomeIcon
                icon={faCheck}
                className="mx-2"
                style={{ color: "#182844" }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  dispatch(updateArticle(article, article.id));
                  setShowInput(false);
                }}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className="ml-1"
                style={{ color: "#182844" }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  setShowInput(false);
                }}
              />
            </>
          )
        }
        key={article.id}
        showArrow={false}
        extra={genExtra(
          article,
          dispatch,
          setShowInput,
          showInput,
          orders,
          duplicated,
          deleteArticleLoading,
          toDeleteArticle,
          id,
          user,
          isAdmin
        )}
      >
        {article.options &&
          article.options.map((option: any, i: number) => (
            <OptionCard
              key={i}
              optionSelected={optionSelected}
              option={option}
              setOptionSelected={setOptionSelected}
            />
          ))}
        <p
          className="add_new_option_text my-4"
          onClick={() => dispatch(selectOption("new", article.id))}
        >
          Add new option
        </p>
      </Panel>
    </Collapse>
  );
};

const genExtra = (
  article: any,
  dispatch: any,
  setShowInput: any,
  showInput: any,
  orders: any,
  duplicated: any,
  deleteArticleLoading: boolean,
  toDeleteArticle: any,
  id: string,
  user: any,
  isAdmin: any
) => {
  // delete article
  const deleteArt = (event: any) => {
    event.stopPropagation();
    dispatch(deleteOneArticle(article));
  };

  // rename article
  const renameArt = (event: any) => {
    event.stopPropagation();
    setShowInput(!showInput);
  };

  // Duplicate Article
  const duplicateArt = async (event: any) => {
    event.stopPropagation();

    await dispatch(
      createArticle(
        {
          ...article,
          name: article.name + " - copy" + Math.floor(Math.random() * 1000),
        },
        false
      )
    );
    if (user && isAdmin) {
      await dispatch(getOneDOrderCommerical(id));
    }
  };
  return (
    <Popover
      placement="right"
      content={() => (
        <div className="popover_edit_order">
          <p onClick={duplicateArt}>Duplicate</p>
          <p onClick={renameArt}>Rename</p>
          <p onClick={deleteArt}>Delete</p>
        </div>
      )}
      trigger="click"
    >
      {deleteArticleLoading && article.id === toDeleteArticle ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <button
          className="article_popover_btn"
          onClick={(event) => event.stopPropagation()}
        >
          <FontAwesomeIcon icon={faEllipsisH} opacity={0.7} color={"#182844"} />
        </button>
      )}
    </Popover>
  );
};
