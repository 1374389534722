import React from "react";

export type IDeleteIconProps = {};

const DeleteIcon: React.FC<IDeleteIconProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_244_1418)">
        <path
          d="M14.25 0C16.7353 0 18.75 2.01472 18.75 4.5H23.25C23.6642 4.5 24 4.83579 24 5.25C24 5.66421 23.6642 6 23.25 6L21 5.99925V19.5C21 21.9853 18.9853 24 16.5 24H7.5C5.01472 24 3 21.9853 3 19.5V6H0.75C0.335786 6 0 5.66421 0 5.25C0 4.83579 0.335786 4.5 0.75 4.5H5.25C5.25 2.01472 7.26472 0 9.75 0H14.25ZM19.5 6H4.5V19.5C4.5 21.1066 5.76298 22.4183 7.35027 22.4963L7.5 22.5H16.5C18.1066 22.5 19.4183 21.237 19.4963 19.6497L19.5 19.5V6ZM9.75 10.5C10.1642 10.5 10.5 10.8358 10.5 11.25V16.5C10.5 16.9142 10.1642 17.25 9.75 17.25C9.33579 17.25 9 16.9142 9 16.5V11.25C9 10.8358 9.33579 10.5 9.75 10.5ZM14.25 10.5C14.6642 10.5 15 10.8358 15 11.25V16.5C15 16.9142 14.6642 17.25 14.25 17.25C13.8358 17.25 13.5 16.9142 13.5 16.5V11.25C13.5 10.8358 13.8358 10.5 14.25 10.5ZM14.25 1.5H9.75C8.14335 1.5 6.83169 2.76298 6.75367 4.35027L6.75 4.5H12H17.25C17.25 2.89335 15.987 1.58169 14.3997 1.50367L14.25 1.5Z"
          fill="#182844"
        />
      </g>
      <defs>
        <clipPath id="clip0_244_1418">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { DeleteIcon };
