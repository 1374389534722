import emptyFolder from "../../img/emptyFolder.png";
import folderIcon from "../../img/folderIcon.png";
import mutipleFolders from "../../img/mutipleFolders.png";
import FolderImage from "../../icons/Folder";
import placeholder from "../../img/img_placeholder.svg";
import { useSelector } from "react-redux";
import "./Folder.scss";
import { FormEvent, useEffect, useState } from "react";
import {
  getRightSideFolder,
  getRightSideSubFolders,
  addFolderToSelectedFolders,
  setFolderToMoveTo,
  addOneFolderToShow,
  clearSelectedFolders,
  clearSelectedProducts,
} from "../../actions/folders-actions/actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Checkbox } from "antd";
import { useHistory } from "react-router-dom";

export interface IFolderProps {
  folder: any;
  addFolderToSelected?: ((id: any) => void) | undefined;
  setsubfolders?: ((id: any) => void) | undefined;
  setparentFolder?: ((folder: any) => void) | undefined;
  setselectedfolders?: ((id: any) => void) | undefined;
  //if true can be selected
  clickable?: boolean;
  addToCollection?: boolean;
  fromSearch?: boolean;
  selectOneFolder?: boolean;
}

export default function Folder({
  folder,
  addFolderToSelected,
  setsubfolders,
  setselectedfolders,
  clickable,
  addToCollection,
  fromSearch,
  setparentFolder,
  selectOneFolder,
}: IFolderProps) {
  const dispatch = useDispatch();
  const [nbfolders, setnbfolders] = useState<any>([]);
  const [subFolders, setsubFolders] = useState<any>([]);
  const [selected, setselected] = useState(false);
  const folders = useSelector((state: any) => state.folders);

  const history = useHistory();

  //@ts-ignore
  const getSubFolders = (event: FormEvent) => {
    event.stopPropagation();
    if (folder.folders_count > 0) {
      setsubfolders &&
        setsubfolders(
          folders.folders.filter((el: any) => el.parent_id === folder.id)
        );
    }
    setselectedfolders && setselectedfolders([]);
    setselected(false);
  };
  useEffect(() => {
    var arr = [];
    if (folder.folders_count) {
      var i = 0;
      while (i < folder.folders_count) {
        arr.push(i);
        i++;
      }
    }
    var arr1 = [];
    setsubFolders(folders.folders.filter((el: any) => el.id === folder.id));
    // addFolderToSelected && addFolderToSelected(folder.id);
    setnbfolders(arr);
  }, [folders.folders]);

  const navigateFolder = () => {
    if (!fromSearch) {
      if (
        folders.moving ||
        folders.movingProductMultiSelection ||
        selectOneFolder
      ) {
        dispatch(setFolderToMoveTo(folder.id));
      } else if (clickable) {
        addFolderToSelected && addFolderToSelected(folder.id);
        setselected(!selected);
      } else if (selectOneFolder) {
        dispatch(setFolderToMoveTo(folder.id));
      } else {
        dispatch(addFolderToSelectedFolders(folder.id));
        setselected(!selected);
      }
    }
  };
  // useEffect(() => {
  //   if (folders.moving) {
  //     if (folders.folderIdToMoveTo === folder.id) {
  //       setselected(true);
  //     } else {
  //       setselected(false);
  //     }
  //   }
  // });
  const handleChange = () => {
    // dispatch(clearSelectedProducts());
  };
  const [checkedCheck, setCheckedCheck] = useState(false);
  useEffect(() => {
    // if (folders.selectedProducts.includes(folder.id)) {
    if (folders.folderIdToMoveTo === folder.id) {
      setCheckedCheck(true);
    } else {
      setCheckedCheck(false);
    }
  }, [folders.folderIdToMoveTo]);

  const openFolder = () => {
    if (fromSearch) {
      history.push("/myCollection");
      dispatch(addOneFolderToShow(folder.id));
    }
  };
  return (
    <button
      className={selected ? "one_folder one_folder_selected" : "one_folder"}
      onClick={openFolder}
    >
      {folder.products.length > 0 || nbfolders.length > 0 ? (
        <div className="list_folder_elements">
          {folder.products.map((product: any, i: number) =>
            i < 7 ? (
              <div key={i} className="product_image_container">
                {product.image ? (
                  <img
                    src={
                      process.env.REACT_APP_STORAGE_URL + product.image.path ||
                      placeholder
                    }
                    alt=""
                    className="img_product_folder"
                  />
                ) : (
                  <img
                    src={placeholder}
                    alt=""
                    className="img_product_folder"
                  />
                )}
              </div>
            ) : null
          )}

          {folder.folders_count && folder.products.length < 7
            ? subFolders
                .filter((el: any) => el)
                .map((el: any, i: number) => (
                  <div
                    key={i}
                    className=" product_image_container folders_indicator"
                  >
                    <FolderImage className="folder" />
                  </div>
                ))
            : null}

          {folder.products.length > 7 && (
            <div className=" product_image_container product_image_container_plus">
              <p className="paragraphe products_plus_folder_value ">
                +{folder.products.length + nbfolders.length - 8}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="list_folder_elements empty_folder">
          <p className="paragraphe">Empty Folder</p>
        </div>
      )}
      {folder.products.length === 0 && folder.folders_count === 0 ? (
        <div className="folder_footer">
          {!fromSearch && (
            <div
              style={{
                borderRight: "1px solid #ccc",
                paddingRight: "10px",
                marginRight: "8px",
              }}
            >
              <Checkbox
                checked={checkedCheck}
                onClick={navigateFolder}
                onChange={handleChange}
              ></Checkbox>
            </div>
          )}
          <img src={emptyFolder} />

          <p
            className="folder_name"
            onClick={(e) => {
              if (!fromSearch) {
                setparentFolder && setparentFolder(folder);
                if (addToCollection) {
                  getSubFolders(e);
                } else if (
                  folder.products.length > 0 ||
                  folder.folders_count > 0
                ) {
                  e.stopPropagation();
                  dispatch(getRightSideFolder(folder.id));
                  dispatch(getRightSideSubFolders(folder.id));
                }
              }
            }}
          >
            {fromSearch ? folder.name : folder.name}
          </p>
        </div>
      ) : folder.folders_count === 0 ? (
        <div className="folder_footer">
          {!fromSearch && (
            <div
              style={{
                borderRight: "1px solid #ccc",
                paddingRight: "10px",
                marginRight: "8px",
              }}
            >
              <Checkbox
                checked={checkedCheck}
                onClick={navigateFolder}
                onChange={handleChange}
              ></Checkbox>
            </div>
          )}
          <img src={folderIcon} />

          <p
            className="folder_name"
            onClick={(e) => {
              if (!fromSearch) {
                setparentFolder && setparentFolder(folder);
                if (addToCollection) {
                  getSubFolders(e);
                } else if (
                  folder.products.length > 0 ||
                  folder.folders_count > 0
                ) {
                  {
                    e.stopPropagation();
                    dispatch(addOneFolderToShow(folder.id));
                  }
                  dispatch(getRightSideFolder(folder.id));
                  dispatch(getRightSideSubFolders(folder.id));
                }
              }
            }}
          >
            {fromSearch ? folder.name : folder.name}
          </p>
        </div>
      ) : (
        <div className="folder_footer">
          {!fromSearch && (
            <div
              style={{
                borderRight: "1px solid #ccc",
                paddingRight: "10px",
                marginRight: "8px",
              }}
            >
              <Checkbox
                checked={checkedCheck}
                onClick={navigateFolder}
                onChange={handleChange}
              ></Checkbox>
            </div>
          )}
          <img src={mutipleFolders} />
          <p
            className="folder_name"
            onClick={(e) => {
              if (!fromSearch) {
                setparentFolder && setparentFolder(folder);
                if (addToCollection) {
                  getSubFolders(e);
                } else if (
                  folder.products.length > 0 ||
                  folder.folders_count > 0
                ) {
                  e.stopPropagation();
                  dispatch(getRightSideFolder(folder.id));
                  dispatch(getRightSideSubFolders(folder.id));
                }
              }
            }}
          >
            {folder.name}
          </p>
        </div>
      )}
    </button>
  );
}
