import { useEffect, useState } from "react";
import { DirectOrderList } from "../components/DirectOrderList/DirectOrderList";
import { motion, AnimatePresence } from "framer-motion";
import "../sass/DirectOrder.scss";
import { OrderDetails } from "../components/OrderDetails/OrderDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderCollaborators,
  getOrders,
} from "../actions/order-actions/actions";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Head from "../components/Head";

interface Props {}

const variants = {
  enter: (): object => {
    return {
      opacity: 0,
    };
  },
  center: (): object => {
    return {
      opacity: 1,
    };
  },
  exit: () => {
    return {
      opacity: 0,
    };
  },
};

export const DirectOrder = (props: Props) => {
  const [sharedOrders, setSharedOrders] = useState(true);
  const [filteredOrders, setFilteredOrders] = useState<any>(null);
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const { orders, isLoading, newOrder } = useSelector(
    (state: any) => state.orders
  );
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const [selectOrder, setSelectOrder] = useState<any>(null);
  const history = useHistory();

  useEffect(() => {
    if (!isAdmin) {
      dispatch(getOrders("waiting"));
    }
  }, []);

  useEffect(() => {
    if (orders.length && width > 575) {
      let canSeeOrder = orders.find(
        (el: any) => el.can_see === undefined || el.can_see === true
      );
      setSelectOrder(canSeeOrder);
    }
  }, [orders]);

  useEffect(() => {
    console.log(sharedOrders);
    if (orders.length) {
      if (sharedOrders) {
        setFilteredOrders(orders);
      } else {
        let myOrders = orders.filter(
          (order: any) =>
            order.client_id === user.id && order.collaborators.length === 1
        );
        setFilteredOrders(myOrders);
      }
    }
  }, [orders, sharedOrders]);

  useEffect(() => {
    if (newOrder) {
      // setOrderCreated(newOrder);
      dispatch(getOrderCollaborators(newOrder.id));
      history.push(`/edit-order/${newOrder.id}`);
    }
  }, [newOrder]);

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  return isLoading ? (
    <div className="loading_orders">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : width <= 575 ? (
    <AnimatePresence exitBeforeEnter={false}>
      <div className="row direct_order">
        {!selectOrder && (
          <motion.div
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            className="col-xs-12 col-sm-3 col-md-3 p-0 order_list_side"
          >
            <DirectOrderList
              sharedOrders={sharedOrders}
              setSharedOrders={(x: boolean) => setSharedOrders(x)}
              orders={filteredOrders}
              isLoading={isLoading}
              setSelectOrder={(x: any) => setSelectOrder(x)}
              selectOrder={selectOrder}
            />
          </motion.div>
        )}
        {selectOrder && (
          <motion.div
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            className="col-12"
          >
            {selectOrder && (
              <OrderDetails
                selectOrder={selectOrder}
                width={width}
                setSelectOrder={setSelectOrder}
              />
            )}
          </motion.div>
        )}
      </div>
    </AnimatePresence>
  ) : (
    <AnimatePresence exitBeforeEnter={false}>
      <Head title="Direct Orders" />
      <div className="row direct_order">
        <div className="col-xs-12 col-sm-3 col-md-3 p-0 order_list_side">
          <DirectOrderList
            sharedOrders={sharedOrders}
            setSharedOrders={(x: boolean) => setSharedOrders(x)}
            isLoading={isLoading}
            orders={filteredOrders}
            setSelectOrder={(x: any) => setSelectOrder(x)}
            selectOrder={selectOrder}
          />
        </div>

        <motion.div
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          className="col-9 p-0 order_details_container"
        >
          {selectOrder && (
            <OrderDetails selectOrder={selectOrder} width={width} />
          )}
        </motion.div>
      </div>
    </AnimatePresence>
  );
};
