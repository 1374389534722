import React, { useEffect, useState } from "react";
import placeholder_jeans from "../../img/placeholder_jeans.svg";

interface Props {
  src: string;
  alt?: string;
  img_class?: string;
}

export const ImgItem = ({ src, alt, img_class }: Props) => {
  const [imgSrc, setImgSrc] = useState(placeholder_jeans || src);
  // const [src, setSrc] = useState<any>(null);

  useEffect(() => {
    const img: any = new Image();
    img.src = src;
    img.addEventListener("load", () => {
      setImgSrc(src);
    });
  }, [imgSrc, placeholder_jeans]);

  return (
    <img
      src={imgSrc}
      className={img_class ? img_class : "img-fluid"}
      alt={alt ? alt : "ok"}
    />
  );
};
