import { useEffect, useState } from "react";
import axiosInstance from "../../config/axios-instance";
import { Swiper, SwiperSlide } from "swiper/react";

import {Link, useHistory} from "react-router-dom";
import { Card } from "antd";
import placeholder_jeans from "../../img/placeholder_jeans.svg";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
/* import SwiperCore from "swiper/react/swiper-react";
 */

import { Pagination, Navigation, Keyboard } from "swiper";

import { Skeleton } from "antd";
import { ImgItem } from "../MasonryBlock/ImgItem";

const { Meta } = Card;
// install Swiper modules
/* SwiperCore.use([Pagination, Navigation, Keyboard]);
 */
const HorizontalProducts = ({ data, isLoading, fabric, type }: any) => {
  const history = useHistory();

  console.log(data);
  return isLoading ? (

          <Swiper modules={[Navigation, Keyboard]} // Pass the modules here
                  slidesPerView={4}
                  spaceBetween={30}
                  navigation={true} // Enable navigation
                  keyboard={true} // Enable keyboard navigation
                  pagination={{
                      clickable: true,

                  }}
                  breakpoints={{
                      360: {
                          width: 360,
                          slidesPerView: 1,
                      },
                      768: {
                          width: 768,
                          slidesPerView: 3.5,
                      },
                  }}
                  className="mySwiper">
              {Array.from({length: 4}, (_, index) => index + 1).map((_, i) => (
                  <SwiperSlide className="d-flex flex-column skeleton_swiper" key={i}>
                      <Card
                          loading={isLoading}
                          bordered={false}
                          style={{width: 200}}
                          cover={
                              <img
                                  style={{
                                      width: "100%",
                                      height: 200,
                                      objectFit: "contain",
                                  }}
                                  src={placeholder_jeans}
                              />
                          }
                      >
                          <Meta title={"Code"} description={"Name"}/>
                      </Card>
                  </SwiperSlide>
              ))}
          </Swiper>
          ) : data ? (
          data.length !== 0 ? (
          <div
              className={
                  type !== "similar" ? "swiper_similar" : "swiper_similar_first"
              }
          >
              <h4 className="total_price_title similar_products_title">
                  {type === "similar"
                      ? fabric === "denim"
                          ? "SAME FABRIC DIFFERENT WASH"
                          : "SIMILAR PRODUCTS"
                      : "TOTAL LOOK"}
              </h4>
              <Swiper
                  modules={[Navigation, Keyboard]} // Pass the modules here
                  slidesPerView={4}
                  spaceBetween={30}
                  navigation={true} // Enable navigation
                  keyboard={true} // Enable keyboard navigation
                  pagination={{
                      clickable: true,

                  }}
                  breakpoints={{
                      360: {
                          width: 360,
                          slidesPerView: 1,
                      },
                      768: {
                          width: 768,
                          slidesPerView: 3.5,
                      },
                  }}
                  className="mySwiper"
              >
                  {data &&
                      data.map((x: any, i: number) => (

                          <>
                              <SwiperSlide
                                  className="sliderHeight">
                                  <div style={{textAlign: "center", width: "250px"}}>
                                      <Link
                                          to={`/product/details/${x.fabric}/${x.gendre}/${x.category}/${x.name}/${x.id}`}
                                          style={{
                                              textAlign: "center",
                                              color: "#182844"
                                          }}
                                      >
                                          <img
                                              src={
                                                  x.front_image
                                                      ? process.env
                                                          .REACT_APP_STORAGE_URL +
                                                      x.front_image
                                                      : placeholder_jeans
                                              }
                                              width="270"
                                              height="300"
                                              /*
                                                                                                                                                  className={"img-responsible-choice"}
                                              */
                                          />
                                          <p
                                              style={{
                                                  fontSize: "12px",
                                                  textAlign: "center",
                                                  textTransform: "capitalize"
                                              }}
                                          >
                                              {x.code} <br/>
                                              {x.name}
                                              <>
                                                  {x.gender_id == 2 ? (
                                                      <>-Women</>
                                                  ) : x.gender_id ==
                                                  1 ? (
                                                      <>-Men</>
                                                  ) : x.gender_id ==
                                                  3 ? (
                                                      <>-Unisex</>
                                                  ) : x.gender_id ==
                                                  4 ? (
                                                      <>-Kids</>
                                                  ) : x.gender_id ==
                                                  5 ? (
                                                      <>-Boys</>
                                                  ) : x.gender_id ==
                                                  6 ? (
                                                      <>-Girls</>
                                                  ) : (
                                                      ""
                                                  )}
                                              </>
                                          </p>
                                      </Link>
                                  </div>
                              </SwiperSlide>
                          </>
                      ))}
              </Swiper>
          </div>
          ) : null
       ) : null;
          };

          export default HorizontalProducts;
