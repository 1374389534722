import React from "react";

export type IZoomIconProps = {};

const ZoomIcon: React.FC<IZoomIconProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M25.8594 23.3594L34.1406 31.6406L31.6406 34.1406L23.3594 25.8594V24.5312L22.8906 24.0625C21.0156 25.7031 18.5156 26.6406 15.8594 26.6406C9.84375 26.6406 5 21.7969 5 15.8594C5 9.84375 9.84375 5 15.8594 5C21.7969 5 26.6406 9.84375 26.6406 15.8594C26.6406 18.5156 25.7031 21.0156 24.0625 22.8906L24.5312 23.3594H25.8594ZM15.8594 23.3594C20 23.3594 23.3594 20 23.3594 15.8594C23.3594 11.7188 20 8.35938 15.8594 8.35938C11.7188 8.35938 8.35938 11.7188 8.35938 15.8594C8.35938 20 11.7188 23.3594 15.8594 23.3594ZM16.6406 11.6406V15H20V16.6406H16.6406V20H15V16.6406H11.6406V15H15V11.6406H16.6406Z"
        fill="#182844"
      />
    </svg>
  );
};

export { ZoomIcon };
