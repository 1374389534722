import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCustomizedProduct,
  getProduct,
} from "../../actions/products-actions/actions";
import { Product3dConfigTabs } from "../product-3d-config-tabs/Product3dConfigTabs";
import { Project3d } from "../project-3d/Project3d";
import "./product-view.scss";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import { useQuery } from "react-query";
import axiosInstance from "../../config/axios-instance";
import BottomDrawer from "../BottomDrawer/BottomDrawer";
import { history } from "../../index";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CustomizationProduct } from "../product-3d-config-tabs/forms/canvas/CustomizationProduct";
import { fabric } from "fabric";
import { Modal, Select, Tabs, Tooltip } from "antd";
import { SelectIcon } from "../product-3d-config-tabs/forms/canvas/icon/SelectIcon";
import { HrIcon } from "../product-3d-config-tabs/forms/canvas/icon/HrIcon";
import { BrushIcon } from "../product-3d-config-tabs/forms/canvas/icon/BrushIcon";
import { EraseIcon } from "../product-3d-config-tabs/icons/EraseIcon";
import { UndoIcon } from "../product-3d-config-tabs/forms/canvas/icon/UndoIcon";
import { RedoIcon } from "../product-3d-config-tabs/forms/canvas/icon/RedoIcon";
import { CopyIcon } from "../product-3d-config-tabs/forms/canvas/icon/CopyIcon";
import { PastIcon } from "../product-3d-config-tabs/forms/canvas/icon/PastIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnnotationIcon } from "../product-3d-config-tabs/forms/canvas/icon/AnnotationIcon";
import { TextIcon } from "../product-3d-config-tabs/forms/canvas/icon/TextIcon";
import { FileIcon } from "../product-3d-config-tabs/forms/canvas/icon/FileIcon";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ExposureIcon } from "../product-3d-config-tabs/forms/canvas/icon/ExposureIcon";
import { DeleteIcon } from "../product-3d-config-tabs/forms/canvas/icon/DeleteIcon";
import { ZoomIcon } from "../product-3d-config-tabs/forms/canvas/icon/ZoomIcon";

export type IAppProps = {};

function Customizationv2() {
  const [originalProduct, setOriginalProduct] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const dispatch = useDispatch();
  const params = useParams<any>();
  const { selectedProduct, isLoading } = useSelector(
    (state: any) => state.products
  );
  const drawers = useSelector((state: any) => state.drawers);

  useEffect(() => {
    // if(selectedProduct){
    if (params.status === "new") {
      dispatch(getProduct(params.productId));
    } else {
      dispatch(getCustomizedProduct(params.productId));
    }
    // }
  }, [params.productId, params.status]);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRef2 = useRef<HTMLCanvasElement>(null);
  const addButtonRefFront = useRef(null);
  const addButtonRefBack = useRef(null);
  const [can, setCanvas] = useState<any>(null);
  const [canback, setCanvasBack] = useState<any>(null);

  const [isPainting, setIsPainting] = useState(false);
  const [textAnnotation, setTextAnnotation] = useState("aaaqs");
  const [clickedImage, setClickedImage] = useState(null);

  const [activeCanvasFront, setActiveCanvasFront] = useState(true);
  const [activeCanvasBack, setActiveCanvasBack] = useState(false);

  const handleCanvasClickBack = () => {
    setActiveCanvasFront(false);
    setActiveCanvasBack(true);
  };

  const handleCanvasClickFront = () => {
    setActiveCanvasFront(true);
    setActiveCanvasBack(false);
  };
  const [eraser, setEraser] = useState(false);
  const [active, setActive] = useState(false);
  const [valueZoom, setZoom] = useState(1);
  const [valueZoomBack, setZoomBack] = useState(1);

  const [goPrint, setGoPrint] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [openBack, setOpenBack] = React.useState(false);
  const [annotationListFront, setAnnotationListFront] = useState<any>([]);
  const [annotationListBack, setAnnotationListBack] = useState<any>([]);
  const [activeAnnotationFront, setActiveAnnotationFront] = useState(false);
  const [activeAnnotationBack, setActiveAnnotationBack] = useState(false);
  const [owndesignlist, setOwnDesignList] = React.useState<any>([]);
  const [arrayFront, setArrayAnnotation] = React.useState<any>([]);
  const [arrayBack, setArrayAnnotationBack] = React.useState<any>([]);

  const showModal = () => {
    setOpen(true);
    fetchLibraries();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const showModalBack = () => {
    setOpenBack(true);
    fetchLibraries();
  };

  const handleCancelBack = () => {
    setOpenBack(false);
  };

  const [isLeftClick, setIsLeftClick] = React.useState(true);

  const [washes, setWashes] = useState<Array<any>>([]);
  const [embellishment, setEmbellishment] = useState<Array<any>>([]);
  const [whiskers, setWhiskers] = useState<Array<any>>([]);

  const [selectedEmbellishment, setSelectedEmbellishment] = useState(null);
  const [selectedWashes, setSelectedWashes] = useState(null);
  const [selectedWhisker, setSelectedWhisker] = useState(null);
  const [chartLoaded, setChartLoaded] = useState(false);

  useEffect(() => {
    // Check if Chart.js script is already loaded
    if (!document.querySelector("script#chartjs-script")) {
      // Create script element
      const script = document.createElement("script");
      script.id = "erase-script";
      script.src =
        "https://unpkg.com/fabric@4.5.1/src/mixins/eraser_brush.mixin.js";
      script.onload = () => setChartLoaded(true); // Set flag when script is loaded

      // Append the script to the document body
      document.body.appendChild(script);
      console.log(chartLoaded);
      // Remove the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    } else {
      // If the script is already in the document, set the flag to true
      setChartLoaded(true);
    }
  }, []);
  const onChange = (key: any) => {
    console.log("aaaa" + key);
  };

  const handleChangeEmbellishment = (event: any) => {
    setSelectedEmbellishment(event);
  };

  const handleChangeWashes = (event: any) => {
    setSelectedWashes(event);
  };
  const handleChangeWhisker = (event: any) => {
    setSelectedWhisker(event);
  };

  const fetchLibraries = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/customized_products/getLibraries`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setEmbellishment(res.data.embllishment_libraries);
      setWashes(res.data.washes_libraries);
      setWhiskers(res.data.whisker_libraries);
    });
  };

  useEffect(() => {
    fetchLibraries();
    //@ts-ignore
    //  var array = [];
    var annotation = 1;
    const canvas = new fabric.Canvas("canvas-font");

    canvas.setWidth(window.innerWidth < 1025 ? 300 : 500);
    canvas.setHeight(window.innerWidth < 1025 ? 300 : 600);
    resizeCanvasFront();

    function resizeCanvasFront() {
      const container = document.querySelector(".container.active-border");
      if (container) {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        canvas.setWidth(containerWidth);
        canvas.setHeight(containerHeight);

        canvas.renderAll();
      }
    }

    setCanvas(canvas);
    loadCanvasData();
    canvas.setZoom(1); // set initial zoom level

    canvas.on("mouse:wheel", function (opt) {
      canvas.selection = false;
      var delta = opt.e.deltaY; // get the amount of change from the mouse wheel
      var zoom = canvas.getZoom(); // get the current zoom level
      zoom *= 0.999 ** delta; // apply the change to the zoom level
      if (zoom > 20) zoom = 20; // set a maximum zoom level
      if (zoom < 1) zoom = 1; // set a minimum zoom level
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom); // zoom to the mouse cursor
      opt.e.preventDefault(); // prevent the default scrolling behavior
      setZoom(zoom);
    });
    function init() {
      fabric.Image.fromURL(
        process.env.REACT_APP_STORAGE_URL + selectedProduct?.front_image,

        function (imgFront) {
          // img.set("erasable", false);
          // imgFront.scaleToWidth(520);
          /*        imgFront.scaleToWidth(window.innerWidth < 1025 ? 300 : 500);
          imgFront.scaleToHeight(window.innerWidth < 1025 ? 270 : 600);*/

          if (window.innerWidth < 1372) {
            console.log(window.innerWidth);
            imgFront.scaleToWidth(300);
          } else {
            console.log(window.innerWidth);
            imgFront.scaleToWidth(520);
          }

          imgFront.clone((img: any) => {
            canvas.renderAll();
          });
          //@ts-ignore
          canvas.setBackgroundImage(imgFront);
          //@ts-ignore
          imgFront.set({ erasable: false });

          const filtersFront = {
            brightness: new fabric.Image.filters.Brightness(),
          };

          // - Brightness
          // Attach the filter to the image
          //@ts-ignore
          imgFront.filters.push(filtersFront.brightness);
          const brightnessInput = document.querySelector("#brightness-front");
          //@ts-ignore
          brightnessInput.oninput = () => {
            //@ts-ignore
            const value = parseFloat(brightnessInput.value);

            // Edit the filter value
            //@ts-ignore
            filtersFront.brightness.brightness = value;
            // Apply the changes
            imgFront.applyFilters();
            // Display the result
            canvas.renderAll();
          };
          //  canvas.renderAll();
        },
        { crossOrigin: "anonymous" }
      );
    }
    if (params.status != "edit") {
      init();
    }
    // Function to copy and paste the selected object

    /*  const copyAndPasteButton = document.querySelector("#copyAndPasteButton");
    //@ts-ignore
    copyAndPasteButton.addEventListener("click", function () {
      canvas.isDrawingMode = false;

      const activeObjects = canvas.getActiveObjects();

      if (activeObjects.length === 0) {
        alert("Please select objects first.");
        return;
      }

      // Array to store the cloned objects
      //@ts-ignore
      const clonedObjects = [];

      // Clone the selected objects and add them to the canvas
      activeObjects.forEach((obj) => {
        const clonedObject = fabric.util.object.clone(obj);

        // Increment the position slightly to visually differentiate the pasted objects
        clonedObject.set({
          left: clonedObject.left + 20,
          top: clonedObject.top + 20,
        });

        canvas.add(clonedObject);
        clonedObjects.push(clonedObject);
      });

      // Deselect all objects
      canvas.discardActiveObject();
      canvas.requestRenderAll();

      // Make the cloned objects active (selected)
      canvas.setActiveObject(
        //@ts-ignore
        new fabric.ActiveSelection(clonedObjects, {
          canvas: canvas,
        })
      );

      // Render the canvas to see the pasted objects
      canvas.renderAll();
    }); */
    //@ts-ignore
    let copiedObjectsFront = [];

    // Function to copy the selected objects
    /* const copyButtonFront = document.querySelector("#copyButtonFront");
    //@ts-ignore
    copyButtonFront.addEventListener("click", function () {
      const activeObjects = canvas.getActiveObjects();

      if (activeObjects.length === 0) {
        alert("Please select objects first.");
        return;
      }

      // Clear the previous copied objects
      copiedObjectsFront = [];

      // Clone the selected objects and store them in the copiedObjectsFront array
      activeObjects.forEach((obj) => {
        const clonedObject = fabric.util.object.clone(obj);
        copiedObjectsFront.push(clonedObject);
      });
    }); */

    // Function to paste the copied objects
    /*    const pasteButtonFront = document.querySelector("#pasteButtonFront");
    //@ts-ignore
    pasteButtonFront.addEventListener("click", function () {
      if (copiedObjectsFront.length === 0) {
        alert("No objects copied.");
        return;
      }

      // Paste the copied objects onto the canvas
      //@ts-ignore
      copiedObjectsFront.forEach((obj) => {
        // Increment the position slightly to visually differentiate the pasted objects
        obj.set({
          left: 20,
          top: 20,
        });

        canvas.add(obj);
      });

      // Deselect all objects
      canvas.discardActiveObject();
      canvas.requestRenderAll();

      // Make the cloned objects active (selected)
      canvas.setActiveObject(
        //@ts-ignore
        new fabric.ActiveSelection(copiedObjectsFront, {
          canvas: canvas,
        })
      );

      // Render the canvas to see the pasted objects
      canvas.renderAll();
    }); */

    const selectToolButton = document.querySelector("#select-tool-front");
    //@ts-ignore
    selectToolButton.addEventListener("click", () => {
      canvas.isDrawingMode = false;
    });

    const brushToolButton = document.querySelector("#brush-tool-front");
    //@ts-ignore
    brushToolButton.addEventListener("click", function () {
      var brush = new fabric.PencilBrush(canvas);
      var opacityValue = 0.5;

      //@ts-ignore

      var colorInput = document.getElementById("color-picker-front").value;
      //   brush.color = colorInput;
      //@ts-ignore
      var widthInput = document.getElementById("brush-size-front").value;
      //@ts-ignore
      var opacityInput = document.getElementById("brush-opacity-front").value;
      const value = parseFloat(widthInput);

      const valueOpacity = parseFloat(opacityInput);
      var brushColor = new fabric.Color(colorInput);
      brushColor.setAlpha(valueOpacity);
      brush.color = brushColor.toRgba();
      //  brush.width = widthInput;
      brush.width = value;

      canvas.freeDrawingBrush = brush;
      canvas.isDrawingMode = true;
      saveCanvasData();
    });

    //@ts-ignore
    document
      .getElementById("add-comment-front")
      .addEventListener("click", function () {
        //@ts-ignore
        var colorInput = document.getElementById("color-picker-front").value;

        var input = new fabric.IText("Enter text", {
          left: 100,
          top: 100,
          fontFamily: "Arial",
          fontSize: 20,
          fill: colorInput,
        });

        canvas.add(input);

        input.enterEditing();

        document.addEventListener("keydown", function (event) {
          if (event.code === "Escape") {
            canvas.remove(input);
          }
        });
      });
    //@ts-ignore
    document
      .getElementById("eraser-tool-front")
      .addEventListener("click", function () {
        //@ts-ignore
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        //@ts-ignore
        var widthInput = document.getElementById("eraser-size-front").value;

        canvas.freeDrawingBrush.width = widthInput;
        canvas.isDrawingMode = true;

        saveCanvasData();
      });

    const removeToolButton = document.querySelector("#remove-object-front");
    //@ts-ignore
    removeToolButton.addEventListener("click", function () {
      canvas.isDrawingMode = false;

      var activeObject = canvas.getActiveObject();
      if (activeObject) {
        canvas.remove(activeObject);
        canvas.renderAll();
      }
      saveCanvasData();
    });
    document.addEventListener("keydown", function (event) {
      if (event.code === "Delete" || event.code === "Backspace") {
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
          canvas.remove(activeObject);
          //@ts-ignore
          console.log(Number(activeObject.text));
          //@ts-ignore
          //  array.splice(Number(activeObject.text), 1);
          //@ts-ignore
          const idToRemove = Number(activeObject.text);

          // Find the index of the item with the given ID
          const index = arrayFront.findIndex(
            (item: any) => item.id === idToRemove
          );

          // Remove the item if it exists in the array
          if (index !== -1) {
            arrayFront.splice(index, 1);
          }
          canvas.renderAll();
          setArrayAnnotation([...arrayFront]);
          localStorage.setItem(
            "arrayAnnotationFront" + selectedProduct?.code,
            arrayFront
          );
        }
      }

      saveCanvasData();
    });

    canvas.on("object:added", function () {
      if (!isRedoing) {
        h = [];
      }
      isRedoing = false;
    });

    var isRedoing = false;
    //@ts-ignore
    var h = [];
    //@ts-ignore
    document
      .getElementById("undo-front")
      .addEventListener("click", function () {
        if (canvas._objects.length > 0) {
          h.push(canvas._objects.pop());
          canvas.renderAll();
        }
        saveCanvasData();
      });
    //@ts-ignore
    document
      .getElementById("redo-front")
      .addEventListener("click", function () {
        if (h.length > 0) {
          isRedoing = true;
          //@ts-ignore
          canvas.add(h.pop());
        }
        saveCanvasData();
      });

    //@ts-ignore
    document.addEventListener("keyup", ({ keyCode, ctrlKey }) => {
      // Check Ctrl key is pressed.
      if (!ctrlKey) {
        return;
      }

      // Check pressed button is Z - Ctrl+Z.
      if (keyCode === 90) {
        if (canvas._objects.length > 0) {
          h.push(canvas._objects.pop());
          canvas.renderAll();
        }
      }

      // Check pressed button is Y - Ctrl+Y.
      if (keyCode === 89) {
        if (h.length > 0) {
          isRedoing = true;
          //@ts-ignore
          canvas.add(h.pop());
        }
      }
    });

    canvas.on("selection:created", function (event) {
      // Get the selected object
      var selectedObject = event.target;
      console.log("sssss");

      // Get the reference to the select element
      const select = document.querySelector("#blend-mode-front");
      // Add onchange event listener to the select element
      //@ts-ignore
      select.onchange = function () {
        // Get the selected option value
        //@ts-ignore
        console.log(this.value);
        //@ts-ignore
        var blendMode = this.value;

        // Set the globalCompositeOperation property of the selected object
        //@ts-ignore
        selectedObject.set("globalCompositeOperation", blendMode);

        // Render the canvas to apply the changes
        canvas.renderAll();
      };
    });

    function saveCanvasData() {
      var canvasData = JSON.stringify(canvas.toJSON());
      console.log(canvasData);
      localStorage.setItem(
        "canvasDataFront" + selectedProduct?.code,
        canvasData
      );
    }

    /*    function loadCanvasData() {
      var canvasData = localStorage.getItem(
        "canvasDataFront" + selectedProduct?.code
      );
      if (canvasData) {
        canvas.loadFromJSON(canvasData, function () {
          canvas.renderAll();
        });
      }
    } */
    console.log(selectedProduct);
    function loadCanvasData() {
      if (params.status != "edit") {
        var canvasData = localStorage.getItem(
          "canvasDataFront" + selectedProduct?.code
        );
        if (canvasData) {
          canvas.loadFromJSON(canvasData, function () {
            canvas.renderAll();
          });
        }
      } else {
        let jsonString = JSON.parse(selectedProduct?.tmp_canvas_front);

        // Remove double quotes at the beginning and end
        if (
          jsonString?.charAt(0) === '"' &&
          jsonString?.charAt(jsonString.length - 1) === '"'
        ) {
          jsonString = jsonString?.substring(1, jsonString?.length - 1);
        }
        jsonString = jsonString?.replace(/\\/g, "");
        if (jsonString) {
          canvas.loadFromJSON(jsonString, function () {
            canvas.renderAll();
          });
        }
      }
    }

    const handleButtonClickFront = () => {
      setActiveAnnotationFront(true);
      canvas.isDrawingMode = false;
      //@ts-ignore
      var text = document.getElementById("text-input-front").value;

      //@ts-ignore
      if (text) {
        //@ts-ignore
        var textObject = new fabric.IText(text, {
          left: 100,
          top: 100,
          // fill: colorInput,
          //@ts-ignore
          fontSize: 12,
        });

        var pin = new fabric.IText(annotation.toString(), {
          left: 100,
          top: 100,
          fill: "white",
          fontSize: 24,
          textBackgroundColor: "#9A9B46",
        });

        //  var group = new fabric.Group([pin]);
        /*     setAnnotationListFront((prevList: any) => [
          ...prevList,
          <div key={prevList.length}>
            <span style={{ backgroundColor: "#9A9B46", color: "white",padding:"2px" }}>
              {`${annotation}`}
            </span>
            : <span style={{fontSize:"20px"}}>{` ${textObject.text}`}</span>
          </div>,
        ]); */
        arrayFront.push({ id: annotation, text: textObject.text });
        canvas.add(pin);
        setArrayAnnotation(arrayFront);
        localStorage.setItem(
          "arrayAnnotationFront" + selectedProduct?.code,
          arrayFront
        );
        annotation++;
        saveCanvasData();
        //@ts-ignore
        document.getElementById("text-input-front").value = "";
        setActiveAnnotationFront(false);
      }
    };
    // Utilisez l'événement "change" pour les éléments de formulaire
    const colorPicker = document.getElementById("color-picker-front");
    //@ts-ignore
    colorPicker.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const brushSizeInput = document.getElementById("brush-size-front");
    //@ts-ignore
    brushSizeInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const EraserSizeInput = document.getElementById("eraser-size-front");
    //@ts-ignore
    EraserSizeInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });
    const brushOpacityInput = document.getElementById("brush-opacity-front");
    //@ts-ignore
    brushOpacityInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });
    const addButton = addButtonRefFront.current;
    if (addButton) {
      //@ts-ignore
      addButton.addEventListener("click", handleButtonClickFront);
    }
    window.addEventListener("resize", resizeCanvasFront);

    return () => {
      window.removeEventListener("resize", resizeCanvasFront);
      if (addButton) {
        //@ts-ignore
        addButton.removeEventListener("click", handleButtonClickFront);
      }
    };
  }, []);

  /* const handleImageClick = (imageUrl: any) => {
    if (can) {
      var src = imageUrl;
      fabric.Image.fromURL(src, function (img) {
        img.set({
          left: 50,
          top: 50,
          scaleX: 0.5,
          scaleY: 0.5,
        });
        img.scale(0.1);
         can.add(img);
        can.setActiveObject(img);
        can.renderAll(); 
        
      });
      saveCanvasData();
    }
  }; */

  const handleImageClick = (imageUrl: any) => {
    if (can) {
      var src = imageUrl;
      var reader = new FileReader();
      reader.onload = function (event) {
        //@ts-ignore
        var imgData = event.target.result;
        //@ts-ignore
        fabric.Image.fromURL(imgData, function (img) {
          img.set({
            left: 50,
            top: 50,
            scaleX: 0.5,
            scaleY: 0.5,
          });
          img.scale(0.1);
          can.add(img);
          can.setActiveObject(img);
          can.renderAll();
          saveCanvasData();
        });
      };
      fetch(src)
        .then((response) => response.blob())
        .then((blob) => {
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.log("Error loading image:", error);
        });
    }
  };

  const uploadFile = (e: any) => {
    var generate_color = "#" + (((1 << 24) * Math.random()) | 0).toString(16);

    var file = e.target.files[0];

    var reader = new FileReader();
    reader.onload = function (event) {
      var imgObj = new Image();
      //@ts-ignore

      imgObj.src = event.target.result;
      //@ts-ignore

      fabric.Image.fromObject(imgObj, function (img) {
        img.set({
          left: 50,
          top: 50,
          scaleX: 0.5,
          scaleY: 0.5,
        });
        img.scale(0.1);
        // img.set('globalCompositeOperation', 'soft-light');
        can.add(img);
        can.setActiveObject(img);
        can.renderAll();
      });
    };
    reader.readAsDataURL(file);

    saveCanvasData();
    //({ file: e.target.files[0], color: generate_color });

    handleCancel();
  };

  function saveCanvasData() {
    if (can) {
      var canvasData = JSON.stringify(can.toJSON());
      localStorage.setItem(
        "canvasDataFront" + selectedProduct?.code,
        canvasData
      );
    }
  }

  useEffect(() => {
    //@ts-ignore
    var array = [];
    var annotation = 1;
    const canvas = new fabric.Canvas("canvas-back");
    /*     canvas.setWidth(500);
    canvas.setHeight(600); */
    canvas.setWidth(window.innerWidth < 1025 ? 300 : 500);
    canvas.setHeight(window.innerWidth < 1025 ? 300 : 600);
    resizeCanvas();

    function resizeCanvas() {
      const container = document.querySelector(".container.active-border");
      if (container) {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        canvas.setWidth(containerWidth);
        canvas.setHeight(containerHeight);

        canvas.renderAll();
      }
    }

    setCanvasBack(canvas);
    loadCanvasData();
    canvas.setZoom(1); // set initial zoom level

    canvas.on("mouse:wheel", function (opt) {
      canvas.selection = false;
      var delta = opt.e.deltaY; // get the amount of change from the mouse wheel
      var zoom = canvas.getZoom(); // get the current zoom level
      zoom *= 0.999 ** delta; // apply the change to the zoom level

      if (zoom > 20) zoom = 20; // set a maximum zoom level
      if (zoom < 1) zoom = 1; // set a minimum zoom level
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom); // zoom to the mouse cursor
      opt.e.preventDefault(); // prevent the default scrolling behavior
      setZoomBack(zoom);
    });
    function initBack() {
      if (!selectedProduct?.back_image) return; // Avoid running if no image URL is present
      const imageUrl = `${process.env.REACT_APP_STORAGE_URL}${selectedProduct?.back_image}`;

      fabric.Image.fromURL(
        imageUrl,

        function (img) {
          // img.set("erasable", false);
          if (window.innerWidth < 1372) {
            console.log(window.innerWidth);
            img.scaleToWidth(300);
          } else {
            console.log(window.innerWidth);
            img.scaleToWidth(520);
          }
          /* img.scaleToWidth(window.innerWidth < 1025 ? 300 : 500); */
          /*           img.scaleToHeight(window.innerWidth < 1025 ? 270 : 600);
           */ img.clone((img: any) => {
            canvas.renderAll();
          });
          //@ts-ignore
          canvas.setBackgroundImage(img);
          //@ts-ignore
          img.set({ erasable: false });

          const filters = {
            brightness: new fabric.Image.filters.Brightness(),
          };

          // - Brightness
          // Attach the filter to the image
          //@ts-ignore
          img.filters.push(filters.brightness);
          const brightnessInput = document.querySelector("#brightness-back");
          //@ts-ignore
          brightnessInput.oninput = () => {
            //@ts-ignore
            const value = parseFloat(brightnessInput.value);
            // Edit the filter value
            //@ts-ignore
            filters.brightness.brightness = value;
            // Apply the changes
            img.applyFilters();
            // Display the result
            canvas.renderAll();
          };
          canvas.renderAll();
        },
        { crossOrigin: "anonymous" }
      );
    }

    if (!isLoading && selectedProduct) {
      initBack();
    }
    if (params.status != "edit") {
      initBack();
    } else {
      let jsonString = JSON.parse(selectedProduct?.tmp_canvas_back);

      // Remove double quotes at the beginning and end
      if (
        jsonString?.charAt(0) === '"' &&
        jsonString?.charAt(jsonString?.length - 1) === '"'
      ) {
        jsonString = jsonString?.substring(1, jsonString?.length - 1);
      }
      jsonString = jsonString?.replace(/\\/g, "");
      if (jsonString) {
        canvas.loadFromJSON(jsonString, function () {
          canvas.renderAll();
        });
      }
    }

    const selectToolButton = document.querySelector("#select-tool-back");
    //@ts-ignore
    selectToolButton.addEventListener("click", () => {
      canvas.isDrawingMode = false;
    });

    const brushToolButton = document.querySelector("#brush-tool-back");
    //@ts-ignore
    brushToolButton.addEventListener("click", function () {
      // Déplacez cette partie du code à l'intérieur de l'événement "click"
      //@ts-ignore
      var colorInput = document.getElementById("color-picker-back").value;
      //@ts-ignore
      var widthInput = document.getElementById("brush-size-back").value;
      //@ts-ignore
      var opacityInput = document.getElementById("brush-opacity-back").value;
      const value = parseFloat(widthInput);
      const valueOpacity = parseFloat(opacityInput);
      var brushColor = new fabric.Color(colorInput);
      brushColor.setAlpha(valueOpacity);

      var brush = new fabric.PencilBrush(canvas);
      brush.color = brushColor.toRgba();
      brush.width = value;

      canvas.freeDrawingBrush = brush;
      canvas.isDrawingMode = true;
      saveCanvasData();
    });

    //@ts-ignore
    document
      .getElementById("eraser-tool-back")
      .addEventListener("click", function () {
        //@ts-ignore
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        //@ts-ignore
        var widthInput = document.getElementById("eraser-size-back").value;

        canvas.freeDrawingBrush.width = widthInput;
        canvas.isDrawingMode = true;

        saveCanvasData();
      });

    //@ts-ignore
    document
      .getElementById("add-comment-back")
      .addEventListener("click", function () {
        var input = new fabric.IText("Enter text", {
          left: 100,
          top: 100,
          fontFamily: "Arial",
          fontSize: 20,
          fill: "black",
        });

        canvas.add(input);

        input.enterEditing();

        document.addEventListener("keydown", function (event) {
          if (event.code === "Escape") {
            canvas.remove(input);
          }
        });
      });

    //@ts-ignore
    let copiedObjectsBack = [];

    // Function to copy the selected objects
    const copyButtonBack = document.querySelector("#copyButtonBack");
    //@ts-ignore
    copyButtonBack.addEventListener("click", function () {
      const activeObjects = canvas.getActiveObjects();

      if (activeObjects.length === 0) {
        alert("Please select objects first.");
        return;
      }

      // Clear the previous copied objects
      copiedObjectsBack = [];

      // Clone the selected objects and store them in the copiedObjectsBack array
      activeObjects.forEach((obj) => {
        const clonedObject = fabric.util.object.clone(obj);
        copiedObjectsBack.push(clonedObject);
      });
    });

    // Function to paste the copied objects
    const pasteButtonBack = document.querySelector("#pasteButtonBack");
    //@ts-ignore
    pasteButtonBack.addEventListener("click", function () {
      if (copiedObjectsBack.length === 0) {
        alert("No objects copied.");
        return;
      }

      // Paste the copied objects onto the canvas
      //@ts-ignore
      copiedObjectsBack.forEach((obj) => {
        // Increment the position slightly to visually differentiate the pasted objects
        obj.set({
          left: 20,
          top: 20,
        });

        canvas.add(obj);
      });

      // Deselect all objects
      canvas.discardActiveObject();
      canvas.requestRenderAll();

      // Make the cloned objects active (selected)
      canvas.setActiveObject(
        //@ts-ignore
        new fabric.ActiveSelection(copiedObjectsBack, {
          canvas: canvas,
        })
      );

      // Render the canvas to see the pasted objects
      canvas.renderAll();
    });

    const removeToolButton = document.querySelector("#remove-object-back");
    //@ts-ignore
    removeToolButton.addEventListener("click", function () {
      canvas.isDrawingMode = false;

      var activeObject = canvas.getActiveObject();
      if (activeObject) {
        canvas.remove(activeObject);
        canvas.renderAll();
      }
      saveCanvasData();
    });
    document.addEventListener("keydown", function (event) {
      if (event.code === "Delete" || event.code === "Backspace") {
        var activeObject = canvas.getActiveObject();

        if (activeObject) {
          //@ts-ignore
          const idToRemove = Number(activeObject.text);

          // Find the index of the item with the given ID
          const index = arrayBack.findIndex(
            (item: any) => item.id === idToRemove
          );

          // Remove the item if it exists in the array
          if (index !== -1) {
            arrayBack.splice(index, 1);
          }
          canvas.renderAll();
          setArrayAnnotationBack([...arrayBack]);
          localStorage.setItem(
            "arrayAnnotationBack" + selectedProduct?.code,
            arrayBack
          );

          canvas.remove(activeObject);
          canvas.renderAll();
        }
      }

      saveCanvasData();
    });

    canvas.on("object:added", function () {
      if (!isRedoing) {
        h = [];
      }
      isRedoing = false;
    });

    var isRedoing = false;
    //@ts-ignore
    var h = [];
    //@ts-ignore
    document.getElementById("undo-back").addEventListener("click", function () {
      if (canvas._objects.length > 0) {
        h.push(canvas._objects.pop());
        canvas.renderAll();
      }
      saveCanvasData();
    });
    //@ts-ignore
    document.getElementById("redo-back").addEventListener("click", function () {
      if (h.length > 0) {
        isRedoing = true;
        //@ts-ignore
        canvas.add(h.pop());
      }
      saveCanvasData();
    });

    //@ts-ignore
    document.addEventListener("keyup", ({ keyCode, ctrlKey }) => {
      // Check Ctrl key is pressed.
      if (!ctrlKey) {
        return;
      }

      // Check pressed button is Z - Ctrl+Z.
      if (keyCode === 90) {
        if (canvas._objects.length > 0) {
          h.push(canvas._objects.pop());
          canvas.renderAll();
        }
      }

      // Check pressed button is Y - Ctrl+Y.
      if (keyCode === 89) {
        if (h.length > 0) {
          isRedoing = true;
          //@ts-ignore
          canvas.add(h.pop());
        }
      }
    });

    canvas.on("selection:created", function (event) {
      // Get the selected object
      var selectedObject = event.target;

      // Get the reference to the select element
      const select = document.querySelector("#blend-mode-back");
      // Add onchange event listener to the select element
      //@ts-ignore
      select.onchange = function () {
        // Get the selected option value
        //@ts-ignore
        console.log(this.value);
        //@ts-ignore
        var blendMode = this.value;

        // Set the globalCompositeOperation property of the selected object
        //@ts-ignore
        selectedObject.set("globalCompositeOperation", blendMode);

        // Render the canvas to apply the changes
        canvas.renderAll();
      };
    });

    function saveCanvasData() {
      var canvasData = JSON.stringify(canvas.toJSON());
      localStorage.setItem(
        "canvasDataBack" + selectedProduct?.code,
        canvasData
      );
    }

    function loadCanvasData() {
      if (params.status != "edit") {
        var canvasData = localStorage.getItem(
          "canvasDataBack" + selectedProduct?.code
        );
        if (canvasData) {
          canvas.loadFromJSON(canvasData, function () {
            canvas.renderAll();
          });
        }
      } else {
        let jsonString = JSON.parse(selectedProduct?.tmp_canvas_back);

        // Remove double quotes at the beginning and end
        if (
          jsonString?.charAt(0) === '"' &&
          jsonString?.charAt(jsonString?.length - 1) === '"'
        ) {
          jsonString = jsonString?.substring(1, jsonString?.length - 1);
        }
        jsonString = jsonString?.replace(/\\/g, "");
        if (jsonString) {
          canvas.loadFromJSON(jsonString, function () {
            canvas.renderAll();
          });
        }
      }
    }

    //@ts-ignore
    /* document
      .getElementById("add-text-back")
      .addEventListener("click", function () {
        console.log("ssss");
        canvas.isDrawingMode = false;
        //@ts-ignore
        var text = document.getElementById("text-input-back").value;
        //@ts-ignore
        var colorInput = document.getElementById("color-picker-back").value;

        var textObject = new fabric.IText(text, {
          left: 100,
          top: 100,
          // fill: colorInput,
          //@ts-ignore
          fontSize: 12,
        });

        var pin = new fabric.IText(annotation.toString(), {
          left: 100,
          top: 100,
          fill: "white",
          fontSize: 24,
          textBackgroundColor: "#9A9B46",
        });

        setAnnotationListBack((prevList: any) => [
          ...prevList,
          <div key={prevList.length}>
            <span style={{ backgroundColor: "#9A9B46", color: "white" }}>
              {`${annotation}`}
            </span>
            : {` ${textObject.text}`}
          </div>,
        ]);

        array.push(textObject.text);
        canvas.add(pin);

        annotation++;
        saveCanvasData();
      }); */
    const handleButtonClickBack = () => {
      setActiveAnnotationBack(true);
      canvas.isDrawingMode = false;
      //@ts-ignore
      var text = document.getElementById("text-input-back").value;
      if (text) {
        var textObject = new fabric.IText(text, {
          left: 100,
          top: 100,
          // fill: colorInput,
          //@ts-ignore
          fontSize: 12,
        });

        var pin = new fabric.IText(annotation.toString(), {
          left: 100,
          top: 100,
          fill: "white",
          fontSize: 24,
          textBackgroundColor: "#9A9B46",
        });

        /*  //  var group = new fabric.Group([pin]);
        setAnnotationListBack((prevList: any) => [
          ...prevList,
          <div key={prevList.length}>
            <span style={{ backgroundColor: "#9A9B46", color: "white",padding:"2px" }}>
              {`${annotation}`}
            </span>
            : <span style={{fontSize:"20px"}}>{` ${textObject.text}`}</span>
          </div>,
        ]); */

        //array.push(textObject.text);

        arrayBack.push({ id: annotation, text: textObject.text });
        canvas.add(pin);
        setArrayAnnotationBack(arrayBack);
        localStorage.setItem(
          "arrayAnnotationBack" + selectedProduct?.code,
          arrayBack
        );
        annotation++;
        saveCanvasData();
        //@ts-ignore
        document.getElementById("text-input-back").value = "";
        setActiveAnnotationBack(false);
      }
    };

    // Utilisez l'événement "change" pour les éléments de formulaire
    const colorPicker = document.getElementById("color-picker-back");
    //@ts-ignore
    colorPicker.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const brushSizeInput = document.getElementById("brush-size-back");
    //@ts-ignore
    brushSizeInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const brushOpacityInput = document.getElementById("brush-opacity-back");
    //@ts-ignore
    brushOpacityInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });
    const addButton = addButtonRefBack.current;
    if (addButton) {
      //@ts-ignore
      addButton.addEventListener("click", handleButtonClickBack);
    }
    window.addEventListener("resize", resizeCanvas);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      if (addButton) {
        //@ts-ignore
        addButton.removeEventListener("click", handleButtonClickBack);
      }
    };
  }, []);

  /*   const handleImageClickBack = (imageUrl: any) => {
    if (canback) {
      var src = imageUrl;
      fabric.Image.fromURL(src, function (img) {
        img.set({
          left: 50,
          top: 50,
          scaleX: 0.5,
          scaleY: 0.5,
        });
        img.scale(0.1);
        canback.add(img);
        canback.setActiveObject(img);
        canback.renderAll();
      });
      saveCanvasData();
    }
  }; */

  const handleImageClickBack = (imageUrl: any) => {
    if (canback) {
      var src = imageUrl;
      var reader = new FileReader();
      reader.onload = function (event) {
        //@ts-ignore
        var imgData = event.target.result;
        //@ts-ignore
        fabric.Image.fromURL(imgData, function (img) {
          img.set({
            left: 50,
            top: 50,
            scaleX: 0.5,
            scaleY: 0.5,
          });
          img.scale(0.1);
          canback.add(img);
          canback.setActiveObject(img);
          canback.renderAll();
          saveCanvasData();
        });
      };
      fetch(src)
        .then((response) => response.blob())
        .then((blob) => {
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.log("Error loading image:", error);
        });
    }
  };

  const uploadFileBack = (e: any) => {
    var generate_color = "#" + (((1 << 24) * Math.random()) | 0).toString(16);

    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      var imgObj = new Image();
      //@ts-ignore

      imgObj.src = event.target.result;
      //@ts-ignore

      fabric.Image.fromObject(imgObj, function (img) {
        img.set({
          left: 50,
          top: 50,
          scaleX: 0.5,
          scaleY: 0.5,
        });
        img.scale(0.1);
        // img.set('globalCompositeOperation', 'soft-light');
        canback.add(img);
        canback.setActiveObject(img);
        canback.renderAll();
      });
    };
    reader.readAsDataURL(file);
    //handleImageTypes({ file: e.target.files[0], color: generate_color });

    saveCanvasData();
    handleCancelBack();
  };

  return (
    <>
      <div style={{ paddingTop: "120px" }}>
        <div className="row">
          <div className="col-3">
            <img
              src={
                process.env.REACT_APP_STORAGE_URL + selectedProduct?.front_image
              }
              alt=""
              width="400"
              onClick={handleCanvasClickFront}
              style={{
                cursor: "pointer",
                border:
                  activeCanvasFront === true && activeCanvasBack === false
                    ? "1px solid #09101A"
                    : "none",
              }}
            />

            <br />
            <img
              src={
                process.env.REACT_APP_STORAGE_URL + selectedProduct?.front_image
              }
              alt=""
              width="400"
              onClick={handleCanvasClickBack}
              style={{
                cursor: "pointer",

                border:
                  activeCanvasFront === false && activeCanvasBack === true
                    ? "1px solid #09101A"
                    : "none",
              }}
            />
          </div>
          <div className="col-9">
            {" "}
            <div
              style={{
                display:
                  activeCanvasFront === true && activeCanvasBack === false
                    ? "block"
                    : "none",
                background: "#EEF2F6",
                borderRadius: 16,
              }}
              className="p-2 text-center"
            >
              <span className="mr-2">
                <Tooltip placement="top" title={"Select "}>
                  <button className="btn-tool-bar " id="select-tool-front">
                    <SelectIcon></SelectIcon>
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <span className="mr-2">
                <Tooltip placement="top" title={"Brush"}>
                  <button className="btn-tool-bar " id="brush-tool-front">
                    <BrushIcon></BrushIcon>
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <span
                  className=""
                  style={{
                    border: "1px solid #EEF2F6",
                    padding: "10px",
                    borderRadius: "25px",
                    background: "white",
                  }}
                >
                  {" "}
                  <Tooltip placement="top" title={"Brush Size"}>
                    <span className="mr-2">
                      <input
                        type="range"
                        min="1"
                        max="50"
                        id="brush-size-front"
                        defaultValue="25"
                        className="rangeColor"
                      />
                    </span>
                  </Tooltip>
                  <Tooltip placement="top" title={"Brush Opacity"}>
                    <select
                      id="brush-opacity-front"
                      style={{
                        border: "none",
                        outline: "none",
                        scrollBehavior: "smooth",
                      }}
                      className="select-opacity"
                    >
                      <option value="1">100%</option>
                      <option value="0.9">90%</option>
                      <option value="0.8">80%</option>
                      <option value="0.7">70%</option>
                      <option value="0.6">60%</option>
                      <option value="0.5">50%</option>
                      <option value="0.4">40%</option>
                      <option value="0.3">30%</option>
                      <option value="0.2">20%</option>
                      <option value="0.1">10%</option>
                    </select>
                  </Tooltip>
                </span>
              </span>
              <span className="mr-2">
                <Tooltip placement="top" title={"Select "}>
                  <button className="btn-tool-bar " id="eraser-tool-front">
                    <EraseIcon></EraseIcon>
                  </button>
                </Tooltip>
              </span>

              <Tooltip placement="top" title={"Erase Size"}>
                <span
                  className="mr-2"
                  style={{
                    border: "1px solid #EEF2F6",
                    padding: "10px",
                    borderRadius: "25px",
                    background: "white",
                  }}
                >
                  <input
                    type="range"
                    min="1"
                    max="50"
                    id="eraser-size-front"
                    defaultValue="25"
                    className="rangeColor"
                  />
                </span>
              </Tooltip>
              <span className="mr-2">
                <Tooltip placement="top" title={"Color"}>
                  <button className="btn-tool-bar ">
                    <input
                      className="input-color"
                      type="color"
                      id="color-picker-front"
                    />
                  </button>
                </Tooltip>
              </span>

              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <span className="mr-2">
                <Tooltip placement="top" title={"Undo or Ctrl + Z"}>
                  <button className="btn-tool-bar " id="undo-front">
                    <UndoIcon></UndoIcon>
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <Tooltip placement="top" title={"Redo or Ctrl + Y"}>
                  <button className="btn-tool-bar " id="redo-front">
                    <RedoIcon></RedoIcon>
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <Tooltip placement="top" title={"Copy"}>
                  <button className="btn-tool-bar " id="copyButtonFront">
                    <CopyIcon></CopyIcon>
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <Tooltip placement="top" title={"Past"}>
                  <button className="btn-tool-bar " id="pasteButtonFront">
                    <PastIcon></PastIcon>
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <span className="mr-2">
                {activeAnnotationFront && (
                  <>
                    <span className="mr-2">
                      <input
                        type="text"
                        id="text-input-front"
                        placeholder="Enter your annotation here"
                      />
                    </span>
                  </>
                )}
                <Tooltip placement="top" title={"Annotation"}>
                  <button
                    ref={addButtonRefFront}
                    id="add-text-front"
                    className="btn-tool-bar "
                  >
                    {activeAnnotationFront ? (
                      <>
                        <FontAwesomeIcon icon={faCheck} />
                      </>
                    ) : (
                      <>
                        <AnnotationIcon></AnnotationIcon>
                      </>
                    )}
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <Tooltip placement="top" title={"Text"}>
                  <button className="btn-tool-bar" id="add-comment-front">
                    <TextIcon></TextIcon>
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <span
                style={{
                  border: "1px solid #EEF2F6",
                  padding: "10px",
                  borderRadius: "25px",
                  background: "white",
                }}
                className="mr-2"
              >
                <span className="mr-2" style={{ cursor: "pointer" }}>
                  <Tooltip placement="top" title={"Upload File"}>
                    <button onClick={showModal}>
                      <FileIcon></FileIcon>
                    </button>
                  </Tooltip>
                </span>
                <span className="mr-2">
                  <HrIcon></HrIcon>
                </span>
                <span className="mr-2">
                  <Tooltip placement="top" title={"Opacity file"}>
                    <select id="blend-mode-front" style={{ border: "none" }}>
                      <option value="source-over">100%</option>
                      <option value="overlay">75%</option>
                      <option value="soft-light">50%</option>
                      <option value="lighten">25%</option>
                    </select>
                  </Tooltip>
                </span>
              </span>
              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <Tooltip placement="top" title={"Base Color"}>
                <span
                  style={{
                    border: "1px solid #EEF2F6",
                    padding: "10px",
                    borderRadius: "25px",
                    background: "white",
                  }}
                  className="mr-2"
                >
                  <ExposureIcon></ExposureIcon>{" "}
                  <input
                    type="range"
                    min="-0.5"
                    max="0.5"
                    step="0.01"
                    id="brightness-front"
                    defaultValue="0"
                    className="rangeColor"
                  />
                </span>
              </Tooltip>
              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <span className="mr-2">
                <Tooltip placement="top" title={"Remove or Back espace"}>
                  <button
                    className="btn-tool-bar pr-3 pl-3"
                    id="remove-object-front"
                  >
                    <DeleteIcon></DeleteIcon>{" "}
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <Tooltip
                  placement="top"
                  title={
                    "To zoom in, you need to use the mouse scroll wheel at the location where you want to zoom."
                  }
                >
                  <span
                    style={{
                      border: "1px solid #EEF2F6",
                      padding: "10px",
                      borderRadius: "25px",
                      background: "white",
                    }}
                  >
                    <ZoomIcon></ZoomIcon>
                    <span style={{ marginLeft: "20px" }}>
                      {(valueZoom * 100).toFixed(0)} %
                    </span>
                  </span>
                </Tooltip>
              </span>
            </div>
            <div
              style={{
                display:
                  activeCanvasBack === true && activeCanvasFront === false
                    ? "block"
                    : "none",
                background: "#EEF2F6",
                borderRadius: 16,
              }}
              className="p-2 text-center"
            >
              <span className="mr-2">
                <Tooltip placement="top" title={"Select"}>
                  <button className="btn-tool-bar " id="select-tool-back">
                    <SelectIcon></SelectIcon>
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <Tooltip placement="top" title={"Brush"}>
                <span className="mr-2">
                  <button className="btn-tool-bar " id="brush-tool-back">
                    <BrushIcon></BrushIcon>
                  </button>
                </span>
              </Tooltip>

              <span className="mr-2">
                <span
                  className=""
                  style={{
                    border: "1px solid #EEF2F6",
                    padding: "10px",
                    borderRadius: "25px",
                    background: "white",
                  }}
                >
                  <Tooltip placement="top" title={"Brush Size"}>
                    <span className="mr-2">
                      <input
                        type="range"
                        min="1"
                        max="50"
                        id="brush-size-back"
                        defaultValue="25"
                        className="rangeColor"
                      />
                    </span>
                  </Tooltip>
                  <Tooltip placement="top" title={"Brush Opacity"}>
                    <select
                      id="brush-opacity-back"
                      style={{
                        border: "none",
                        outline: "none",
                        scrollBehavior: "smooth",
                      }}
                      className="select-opacity"
                    >
                      <option value="1">100%</option>
                      <option value="0.9">90%</option>
                      <option value="0.8">80%</option>
                      <option value="0.7">70%</option>
                      <option value="0.6">60%</option>
                      <option value="0.5">50%</option>
                      <option value="0.4">40%</option>
                      <option value="0.3">30%</option>
                      <option value="0.2">20%</option>
                      <option value="0.1">10%</option>
                    </select>
                  </Tooltip>
                </span>
              </span>

              <span className="mr-2">
                <Tooltip placement="top" title={"Select "}>
                  <button className="btn-tool-bar " id="eraser-tool-back">
                    <EraseIcon></EraseIcon>
                  </button>
                </Tooltip>
              </span>

              <Tooltip placement="top" title={"Erase Size"}>
                <span
                  className="mr-2"
                  style={{
                    border: "1px solid #EEF2F6",
                    padding: "10px",
                    borderRadius: "25px",
                    background: "white",
                  }}
                >
                  <input
                    type="range"
                    min="1"
                    max="50"
                    id="eraser-size-back"
                    defaultValue="25"
                    className="rangeColor"
                  />
                </span>
              </Tooltip>
              <Tooltip placement="top" title={"Color"}>
                <span className="mr-2">
                  <button className="btn-tool-bar ">
                    <input
                      className="input-color"
                      type="color"
                      id="color-picker-back"
                    />
                  </button>
                </span>
              </Tooltip>
              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <Tooltip placement="top" title={"Undo"}>
                <span className="mr-2">
                  <button className="btn-tool-bar " id="undo-back">
                    <UndoIcon></UndoIcon>
                  </button>
                </span>
              </Tooltip>
              <Tooltip placement="top" title={"Redo"}>
                <span className="mr-2">
                  <button className="btn-tool-bar " id="redo-back">
                    <RedoIcon></RedoIcon>
                  </button>
                </span>
              </Tooltip>
              <span className="mr-2">
                <Tooltip placement="top" title={"Copy"}>
                  <button className="btn-tool-bar " id="copyButtonBack">
                    <CopyIcon></CopyIcon>
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <Tooltip placement="top" title={"Past"}>
                  <button className="btn-tool-bar " id="pasteButtonBack">
                    <PastIcon></PastIcon>
                  </button>
                </Tooltip>
              </span>
              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <span className="mr-2">
                {activeAnnotationBack && (
                  <>
                    <span className="mr-2">
                      <input
                        type="text"
                        id="text-input-back"
                        placeholder="Enter your annotation here"
                      />
                    </span>
                  </>
                )}
                <Tooltip placement="top" title={"Annotation"}>
                  <button
                    ref={addButtonRefBack}
                    id="add-text-back"
                    className="btn-tool-bar "
                  >
                    {activeAnnotationBack ? (
                      <>
                        <FontAwesomeIcon icon={faCheck} />
                      </>
                    ) : (
                      <>
                        <AnnotationIcon></AnnotationIcon>
                      </>
                    )}
                  </button>
                </Tooltip>
              </span>
              <Tooltip placement="top" title={"Text"}>
                <span className="mr-2">
                  <button className="btn-tool-bar" id="add-comment-back">
                    <TextIcon></TextIcon>
                  </button>
                </span>
              </Tooltip>
              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <span
                style={{
                  border: "1px solid #EEF2F6",
                  padding: "10px",
                  borderRadius: "25px",
                  background: "white",
                }}
                className="mr-2"
              >
                <span className="mr-2" style={{ cursor: "pointer" }}>
                  <Tooltip placement="top" title={"Upload File"}>
                    <button onClick={showModalBack}>
                      <FileIcon></FileIcon>
                    </button>
                  </Tooltip>
                </span>
                <span className="mr-2">
                  <HrIcon></HrIcon>
                </span>
                <span className="mr-2">
                  <Tooltip placement="top" title={"Opacity file"}>
                    <select id="blend-mode-back" style={{ border: "none" }}>
                      <option value="source-over">100%</option>
                      <option value="overlay">75%</option>
                      <option value="soft-light">50%</option>
                      <option value="lighten">25%</option>
                    </select>
                  </Tooltip>
                </span>
              </span>
              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <Tooltip placement="top" title={"Base Color"}>
                <span
                  style={{
                    border: "1px solid #EEF2F6",
                    padding: "10px",
                    borderRadius: "25px",
                    background: "white",
                  }}
                  className="mr-2"
                >
                  <ExposureIcon></ExposureIcon>{" "}
                  <input
                    type="range"
                    min="-0.5"
                    max="0.5"
                    step="0.01"
                    id="brightness-back"
                    defaultValue="0"
                    className="rangeColor"
                  />
                </span>
              </Tooltip>
              <span className="mr-2">
                <HrIcon></HrIcon>
              </span>
              <Tooltip placement="top" title={"Remove or Back espace"}>
                <span className="mr-2">
                  <button
                    className="btn-tool-bar pr-3 pl-3"
                    id="remove-object-back"
                  >
                    <DeleteIcon></DeleteIcon>{" "}
                  </button>
                </span>
              </Tooltip>
              <span className="mr-2">
                <Tooltip
                  placement="top"
                  title={
                    "To zoom in, you need to use the mouse scroll wheel at the location where you want to zoom."
                  }
                >
                  <span
                    style={{
                      border: "1px solid #EEF2F6",
                      padding: "10px",
                      borderRadius: "25px",
                      background: "white",
                    }}
                  >
                    <ZoomIcon></ZoomIcon>
                    <span style={{ marginLeft: "20px" }}>
                      {(valueZoomBack * 100).toFixed(0)} %
                    </span>
                  </span>
                </Tooltip>
              </span>
            </div>
            <div className="row p-3 " id="capteur">
              <div className="col-12 " id="capteur_custom_front">
                <div>
                  <div className="row">
                    <div
                      className={
                        activeCanvasFront
                          ? "col-12 col-md-6 container active-border"
                          : "col-12 col-md-6 container inactive"
                      }
                      style={{
                        display: activeCanvasFront ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <canvas id="canvas-font"></canvas>
                    </div>
                    <div
                      className="col-12 "
                      style={{
                        display: activeCanvasFront ? "flex" : "none",
                      }}
                    >
                      <div id="annotationListFront">
                        <span style={{ fontSize: "20px" }}> Annotation </span>
                        {arrayFront.length == 0 && <></>}
                        {arrayFront.map((item: any, index: any) => (
                          <div key={index}>
                            <span
                              style={{
                                backgroundColor: "#9A9B46",
                                color: "white",
                                padding: "2px",
                              }}
                            >
                              {item.id}
                            </span>
                            <span style={{ fontSize: "20px" }}>
                              {item.text}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 " id="capteur_custom_back">
                <div>
                  <div className="row">
                    <div
                      className={
                        activeCanvasBack
                          ? "col-12 col-md-6 container active-border"
                          : "col-12 col-md-6 container inactive"
                      }
                      style={{
                        display: activeCanvasBack ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <canvas
                        id="canvas-back"
                        className={activeCanvasBack ? "" : "canvas-back"}
                      ></canvas>
                    </div>
                    <div className="col-12">
                      <div
                        id="annotationListBack"
                        style={{
                          display: activeCanvasBack ? "flex" : "none",
                        }}
                      >
                        <span style={{ fontSize: "20px" }}> Annotation </span>
                        {arrayBack.length == 0 && <></>}
                        {arrayBack.map((item: any, index: any) => (
                          <div key={index}>
                            <span
                              style={{
                                backgroundColor: "#9A9B46",
                                color: "white",
                                padding: "2px",
                              }}
                            >
                              {item.id}
                            </span>
                            <span style={{ fontSize: "20px" }}>
                              {item.text}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Add Patch"
        visible={open}
        maskClosable={false}
        closable={false}
        centered
        onCancel={handleCancel}
        footer={[
          <button key="back" onClick={handleCancel} style={{ borderRadius: 5 }}>
            Close
          </button>,
        ]}
      >
        <div>Choose from our preselected list and customize your product</div>
        <Tabs defaultActiveKey="1" onChange={(key) => onChange(key)}>
          <Tabs.TabPane tab="Embellishment" key="1">
            <Select
              defaultValue="Please select option"
              style={{ width: "100%" }}
              onChange={handleChangeEmbellishment}
              options={
                embellishment &&
                embellishment.map((el: any) => {
                  return {
                    label: el.name,
                    value: el.id,
                  };
                })
              }
            />

            {selectedEmbellishment && (
              <div
                className="row m-2"
                style={{ maxHeight: "250px", overflowX: "auto" }}
              >
                {embellishment
                  .find(
                    (entity: any) => entity.id === Number(selectedEmbellishment)
                  )
                  .images.map((image: any) => {
                    return (
                      <div
                        style={{
                          border: "1px solid #E3E8EF",
                          padding: "6px",
                          borderRadius: "8px",
                          marginBottom: "8px",
                          marginRight: "8px",
                        }}
                      >
                        <img
                          width={120}
                          height={120}
                          key={image.id}
                          src={process.env.REACT_APP_STORAGE_URL + image.image}
                          onClick={() => {
                            handleImageClick(
                              process.env.REACT_APP_STORAGE_URL + image.image
                            );
                            handleCancel();
                          }}
                          alt={image.name}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </Tabs.TabPane>
          {selectedProduct?.fabric_type == "knit" && (
            <Tabs.TabPane tab="Washes" key="2">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWashes}
                options={
                  washes &&
                  washes.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWashes && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {washes
                    .find((entity: any) => entity.id === Number(selectedWashes))
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClick(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}
          {selectedProduct?.fabric_type == "denim" && (
            <Tabs.TabPane tab="Whiskers" key="3">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWhisker}
                options={
                  whiskers &&
                  whiskers.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWhisker && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {whiskers
                    .find(
                      (entity: any) => entity.id === Number(selectedWhisker)
                    )
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClick(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}

          <Tabs.TabPane tab="Upload File" key="4">
            <input
              type="file"
              style={{ cursor: "pointer" }}
              onChange={(e) => uploadFile(e)}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>

      <Modal
        title="Add Patch"
        visible={openBack}
        maskClosable={false}
        closable={false}
        centered
        onCancel={handleCancelBack}
        footer={[
          <button
            key="back"
            onClick={handleCancelBack}
            style={{ borderRadius: 5 }}
          >
            Close
          </button>,
        ]}
      >
        <div>Choose from our preselected list and customize your product</div>
        <Tabs defaultActiveKey="1" onChange={(key) => onChange(key)}>
          <Tabs.TabPane tab="Embellishment" key="1">
            <Select
              defaultValue="Please select option"
              style={{ width: "100%" }}
              onChange={handleChangeEmbellishment}
              options={
                embellishment &&
                embellishment.map((el: any) => {
                  return {
                    label: el.name,
                    value: el.id,
                  };
                })
              }
            />

            {selectedEmbellishment && (
              <div
                className="row m-2"
                style={{ maxHeight: "250px", overflowX: "auto" }}
              >
                {embellishment
                  .find(
                    (entity: any) => entity.id === Number(selectedEmbellishment)
                  )
                  .images.map((image: any) => {
                    return (
                      <div
                        style={{
                          border: "1px solid #E3E8EF",
                          padding: "6px",
                          borderRadius: "8px",
                          marginBottom: "8px",
                          marginRight: "8px",
                        }}
                      >
                        <img
                          width={120}
                          height={120}
                          key={image.id}
                          src={process.env.REACT_APP_STORAGE_URL + image.image}
                          onClick={() => {
                            handleImageClickBack(
                              process.env.REACT_APP_STORAGE_URL + image.image
                            );
                            handleCancelBack();
                          }}
                          alt={image.name}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </Tabs.TabPane>
          {selectedProduct?.fabric_type == "knit" && (
            <Tabs.TabPane tab="Washes" key="2">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWashes}
                options={
                  washes &&
                  washes.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWashes && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {washes
                    .find((entity: any) => entity.id === Number(selectedWashes))
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClickBack(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}
          {selectedProduct?.fabric_type == "denim" && (
            <Tabs.TabPane tab="Whiskers" key="3">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWhisker}
                options={
                  whiskers &&
                  whiskers.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWhisker && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {whiskers
                    .find(
                      (entity: any) => entity.id === Number(selectedWhisker)
                    )
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClick(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}

          <Tabs.TabPane tab="Upload File" key="4">
            <input
              type="file"
              style={{ cursor: "pointer" }}
              onChange={(e) => uploadFileBack(e)}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default Customizationv2;
