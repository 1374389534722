import axiosInstance from "../../config/axios-instance";

const getCategoriesUnderGender = (idGender: any, fabric: any) => {
  return axiosInstance({
    method: "get",
    url: `/categories?gender=${idGender}&fabric_type=${fabric}`,
  });
};
const getGendersUnderfabric = (fabric: any) => {
  return axiosInstance({
    method: "get",
    url: `/genders?fabric_types=${fabric}`,
  });
};
const getGenders = () => {
  return axiosInstance({
    method: "get",
    url: `/genders`,
  });
};
const getCollections = () => {
  return axiosInstance({
    method: "get",
    url: `/collections`,
  });
};

const getClients = () => {
  return axiosInstance({
    method: "get",
    url: `/getAllClients`,
  });
};


// const loadUser = () => {
//   let token = localStorage.getItem("token");
//   return axiosInstance({
//     method: "get",
//     url: "/client",
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };

// const forgotPwdSendEmail = (email: string) => {
//   return axiosInstance({
//     method: "post",
//     url: "/forgot-password ",
//     data: {
//       email,
//     },
//   });
// };

// const addNewPwd = (data: any) => {
//   return axiosInstance({
//     method: "post",
//     url: "/new-password",
//     data,
//   });
// };

const CategoriesServices = {
  getCategoriesUnderGender,
  getGendersUnderfabric,
  getGenders,
  getCollections,
  getClients,
};

export default CategoriesServices;
