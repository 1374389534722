import LabelCard from "./LabelCard";
import { LabelData } from "../../StaticData/LabelData";
import "./Label.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import axiosInstance from "../../config/axios-instance";

export interface ILabelListProps {
  width: number;
}

export default function LabelList({ width }: ILabelListProps) {
    const [labelsHome, setlabelsHome] = useState<any>(null);
    const getCommitmentHome = () => {
        let token = localStorage.getItem("token");
        return axiosInstance({
            method: "get",
            url: `/home/labels_home`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res: any) => {
            setlabelsHome(res.data)

        });
    };

    useEffect(() => {
        getCommitmentHome();

    }, []);

  return (
    <div className="label_container">
      <div className="label_title">

              {width >= 768 && (

        <div className="blue_line"></div>
              )  }
        <h2 className="our_label">Our labels</h2>
      </div>
      <div className="label_elements_container">
        {labelsHome?.map((label:any, i:any) => (
          <LabelCard label={label} key={i} />
        ))}
      </div>
   {/*   {width <= 1780 && (
        <div className="d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="mx-4"
            style={{ opacity: 0.5 }}
          />
          <FontAwesomeIcon
            icon={faArrowRight}
            className="mx-4"
            style={{ opacity: 0.5 }}
          />
        </div>
      )}*/}
    </div>
  );
}
