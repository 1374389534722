import { Button, Pagination, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CancelledArticlesTable } from "../components/MyOrders/CancelledArticlesTable";
import axiosInstance from "../config/axios-instance";
import Jeans from "../icons/Jeans";

interface Props {
  date: any;
  orderType: any;
  setAlerts: any;
  orderSource: any;
}

export const MyCustomizedOrder = ({
  date,
  orderType,
  setAlerts,
  orderSource,
}: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [customizedOrders, setCustomizedOrders] = React.useState<any>(null);
  const [errors, setErrors] = React.useState<any>(null);
  const [filteredOrders, setFilteredOrders] = React.useState<any>([]);
  const [values, setValues] = React.useState({
    maxValue: 6,
    minValue: 0,
  });

  React.useEffect(() => {
    const getCustomizedProduct = async () => {
      let token = localStorage.getItem("token");
      setIsLoading(true);
      axiosInstance({
        method: "get",
        url: "/customized_products",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setIsLoading(false);
          setCustomizedOrders(res.data.data);
        })
        .catch((err: any) => {
          setIsLoading(false);
          setErrors(err.response);
        });
    };
    if (orderType === "In progress") {
      getCustomizedProduct();
    }
  }, [orderType]);

  React.useEffect(() => {
    if (
      customizedOrders &&
      orderType === "In progress" &&
      orderSource === "customized"
    ) {
      setFilteredOrders(
        customizedOrders.filter((el: any) => el.updated_at.includes(date))
      );
    }
  }, [customizedOrders, orderSource]);

  // Handle Page Change
  const handleChange = (value: number) => {
    setValues({
      minValue: (value - 1) * 6,
      maxValue: value * 6,
    });
  };

  const genStatusMsg = (status_name: string) => {
    switch (status_name) {
      case "PROTO":
        return {
          color: "#BCBCBD",
          msg: "Your prototype is being prepared",
        };
      case "PRODUCTION":
      case "OK PROD":
        return {
          msg: "Your order is in process",
          color: "#06C170",
        };
      case "CANCELLED":
        return { color: "#FF3A3A", msg: "Your order is canceled" };
      default:
        return {
          color: "#FF872A",
          msg: "We are waiting for your answer",
        };
    }
  };

  // Filter on date change from parent (MyOrders)
  React.useEffect(() => {
    if (customizedOrders && orderType === "Cancelled") {
      setFilteredOrders(
        customizedOrders.filter((product: any) => product.status.id === 6)
      );
    }
  }, [customizedOrders, date, orderType]);

  return orderType === "In progress" ? (
    <div className="container-fluid">
      <table className="table mt-2">
        <thead>
          <tr>
            <th scope="col">Items</th>
            <th scope="col">Sent Date</th>
            <th scope="col">Progress</th>
            <th style={{ width: "25%" }}>Order details</th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? Array.from({ length: 6 }, (_, index) => index + 1).map((_, i) => (
                <tr key={i}>
                  {Array.from({ length: 5 }, (_, index) => index + 1).map(
                    (_, j) => (
                      <td key={j} style={{ background: "white" }}>
                        <Skeleton.Input
                          style={{ width: 200, borderRadius: 10 }}
                          className="my-2"
                          active
                        />
                      </td>
                    )
                  )}
                </tr>
              ))
            : customizedOrders &&
              customizedOrders.length !== 0 &&
              customizedOrders
                .filter((product: any) => product.status.id !== 6)
                .slice(values.minValue, values.maxValue)
                .map((product: any) => (
                  <tr key={product.id}>
                    <td scope="row">
                      <Jeans />
                      <span className="ml-2 myorder_order_name">
                        {product.name}
                      </span>
                    </td>
                    <td>
                      {product.created_at
                        .slice(0, 10)
                        .replace(/[-]/g, "/")
                        .split("/")
                        .reverse()
                        .join("/")}
                    </td>
                    <td>
                      <Link to={`/my-orders/customized-product/${product.id}`}>
                        <Button
                          style={{
                            color:
                              product.status.name === "PROTO"
                                ? "#7C7C7C"
                                : "#00468C",
                            background:
                              product.status.name === "PROTO"
                                ? "#E0E0E0"
                                : "rgba(0, 70, 140, 0.2)",
                          }}
                          className="status_btn_order"
                        >
                          {product.status.name}
                        </Button>
                      </Link>
                    </td>
                    <td>
                      <span
                        style={{
                          color: genStatusMsg(product.status.name).color,
                        }}
                        className="status_msg"
                      >
                        {genStatusMsg(product.status.name).msg}
                      </span>
                    </td>
                  </tr>
                ))}
        </tbody>
        <tfoot>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={6}
            total={
              customizedOrders &&
              customizedOrders.filter((product: any) => product.status.id !== 6)
                .length
            }
            hideOnSinglePage
            onChange={handleChange}
          />
        </tfoot>
      </table>
      {customizedOrders &&
        !customizedOrders.filter((product: any) => product.status.id !== 6)
          .length && (
          <p className="text-center bg-white border p-3 rounded">
            No Orders Found
          </p>
        )}
    </div>
  ) : (
    <CancelledArticlesTable
      filteredOrders={filteredOrders}
      handleChange={handleChange}
      setAlerts={(x: any) => setAlerts(x)}
      orderType={orderSource}
    />
  );
};
