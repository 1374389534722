import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleRightDrawer } from "../../actions/actionCreators";
import progress_icon from "../../img/progress_icon.png";
import moment from "moment";
import { getProduct } from "../../actions/products-actions/actions";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import LazyImg from "../Library/LazyImage";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import Pdf from "../../icons/Pdf";
interface Props {
  notif: any;
  inPage: boolean;
  fabric?: boolean;
  position: boolean;
}

export const NewMoodboard = ({ notif, inPage, fabric, position }: Props) => {
  const dispatch = useDispatch();
  return inPage ? (

      <div
          className="border my-2 px-4 py-3 border"
          style={{borderRadius: "8px"}}
      >
        <a
            href={process.env.REACT_APP_STORAGE_URL + notif.data?.file_storage}
            className="notif_added_prod_container"
            onClick={() => dispatch(handleRightDrawer())}
            style={{
              borderRadius: "8px",
              background: position ? "#e5f1fa" : "white",
              transition: "all 5s ease-in-out",
            }}
            target="_blank"
        >
          <article className="notif_selection order_changes">

            <div className="notif_details flex-grow-1">
              <h2 className="h4_medium mb-0">New moodboard Added</h2>
              <div className="d-flex flex-column">

                <p
                    className="selection_date align-self-end"
                    style={{fontSize: "0.7rem", fontWeight: 400}}
                >
                  {moment(notif.created_at).fromNow()}
                </p>
              </div>
            </div>
          </article>
        </a>
      </div>
        ) : (
        <div
            className="border my-2 px-4 py-3 border"
            style={{borderRadius: "8px"}}
        >
          <a
              href={process.env.REACT_APP_STORAGE_URL + notif.data?.file_storage}
              className="notif_added_prod_container"
              onClick={() => dispatch(handleRightDrawer())}
              style={{
                borderRadius: "8px",
                background: position ? "#e5f1fa" : "white",
                transition: "all 5s ease-in-out",
              }}
              target="_blank"
          >
            <article className="notif_selection order_changes">

              <div className="notif_details flex-grow-1">
                <h2 className="h4_medium mb-0">New moodboard Added</h2>

                <div className="d-flex flex-column">


                  <p
                      className="selection_date align-self-end"
                      style={{fontSize: "0.7rem", fontWeight: 400}}
                  >
                    {moment(notif.created_at).fromNow()}
                  </p>
                </div>
              </div>
            </article>
          </a>
        </div>

        );
        };
