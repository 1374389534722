import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import { handleRightDrawer } from "../../actions/actionCreators";
import { Button } from "antd";
import {
  acceptAccessRequest,
  declineAccessRequest,
} from "../../actions/order-actions/actions";
import { useState } from "react";

interface Props {
  notif: any;
  inPage: boolean;
  position: boolean;
}

export const RequestAccess = ({ inPage, notif, position }: Props) => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const { requestAccessToAccept, acceptRequestLoading } = useSelector(
    (state: any) => state.orders
  );
  const dispatch = useDispatch();
  return inPage ? (
    <div className="notif_item_page p-2 my-2">
      <div className="d-flex align-items-center icon_notif_container">

        <h2 className="h4_medium mb-0 mx-2">
          {notif.data.client ? notif.data.client.name : "User"} wants to
          collaborate with you on
        </h2>
        <p className="notif_name mb-0" style={{ fontSize: 18 }}>
          {notif.data.direct_order
            ? notif.data.direct_order.name
            : "This Order"}
        </p>
      </div>

      <div className="d-flex align-items-center">
        <div className="mr-5">
          {isAccepted ? (
            <p className="mb-0 text-muted">Request Accepted</p>
          ) : isRejected ? (
            <p className="mb-0 text-muted">Request Rejected</p>
          ) : notif.accepted && !notif.rejected ? (
            <p className="mb-0 text-muted">Request Accepted</p>
          ) : !notif.accepted && notif.rejected ? (
            <p className="mb-0 text-muted">Request Rejected</p>
          ) : (
            <>
              <Button
                className="mr-2 decline_request_btn"
                onClick={() => {
                  dispatch(
                    declineAccessRequest(
                      notif.data.direct_order.id,
                      notif.data.client.id
                    )
                  );
                  setIsRejected(true);
                }}
              >
                Decline
              </Button>
              <Button
                className="accept_request_btn"
                onClick={(e: any) => {
                  e.stopPropagation();
                  dispatch(
                    acceptAccessRequest(
                      notif.data.direct_order.id,
                      notif.data.client.id
                    )
                  );
                  setIsAccepted(true);
                }}
              >
                Confirm
              </Button>
            </>
          )}
        </div>

        <p className="selection_date mb-0">
          {moment(notif.created_at).fromNow()}
        </p>
      </div>
    </div>
  ) : (
    /* </Link> */
    <div
      className="notif_added_prod_container border my-2 pl-4"
      style={{
        borderRadius: "8px",
        background: position ? "#e5f1fa" : "white",
        transition: "all 5s ease-in-out",
      }}
    >
      <article className="notif_selection order_changes access_request_notif my-3">

        <div className="notif_details">
          <h2 className="h4_medium">
            {notif.data.client ? notif.data.client.name : "User"} wants to
            collaborate with you on
          </h2>
          <div>
            <p className="selection_date mb-0" style={{ fontSize: 18 }}>
              {notif.data.direct_order
                ? notif.data.direct_order.name
                : "This Order"}
            </p>

            <div className="my-2 btns_access_drawer">
              {isAccepted ? (
                <p className="mb-0 text-muted">Request Accepted</p>
              ) : isRejected ? (
                <p className="mb-0 text-muted">Request Rejected</p>
              ) : notif.accepted && !notif.rejected ? (
                <p className="mb-0 text-muted">Request Accepted</p>
              ) : !notif.accepted && notif.rejected ? (
                <p className="mb-0 text-muted">Request Rejected</p>
              ) : (
                <>
                  <Button
                    className="mr-2 decline_request_btn"
                    onClick={() => {
                      dispatch(
                        declineAccessRequest(
                          notif.data.direct_order.id,
                          notif.data.client.id
                        )
                      );
                      setIsRejected(true);
                    }}
                  >
                    Decline
                  </Button>
                  <Button
                    className="accept_request_btn d"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      dispatch(
                        acceptAccessRequest(
                          notif.data.direct_order.id,
                          notif.data.client.id
                        )
                      );
                      setIsAccepted(true);
                    }}
                  >
                    Confirm
                  </Button>
                </>
              )}
            </div>
            <p
              className="selection_date"
              style={{ fontSize: "0.7rem", fontWeight: 400 }}
            >
              {moment(notif.created_at).fromNow()}
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};
