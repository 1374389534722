import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./SideMenu.scss";
import "./SideMenuExtension.scss";
import { Link } from "react-router-dom";
import getInspired from "../../img/getInspired.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { handleLeftDrawer } from "../../actions/actionCreators";
import {
  closeSideMenuExtension,
  getCategoriesUnderGender,
} from "../../actions/left-menu-actions/actions";
import plus from "../../img/plus.svg";
import PlusIcon from "../../icons/PlusIcon";
import { Skeleton } from "antd";
import { MenuCategory } from "./MenuCategory";

export interface ISideMenuExtensionProps {
  showMenuExt: boolean | string;
  menuchoice: string;
  gender: any;
}

const SideMenuExtension = forwardRef((props: ISideMenuExtensionProps, ref) => {
  const [dataToShow, setDataToShow] = useState<Array<any> | undefined>(
    undefined
  );

  const [subDataToShow, setSubDataToShow] = useState<Array<any> | undefined>(
    undefined
  );

  const [subMenuChoice, setSubMenuChoice] = useState<any>("");

  const [collectionSubElments, setCollectionSubElments] = useState<any>("");

  const [collectionSubElmentsChildrens, setCollectionSubElmentsChildrens] =
    useState<any>({ parent: "", childrens: [] });

  const dispatch = useDispatch();

  const showToast = () => {
    setSubDataToShow(undefined);
  };

  useImperativeHandle(ref, () => {
    return {
      showToast: showToast,
    };
  });

  const leftMenu = useSelector((state: any) => state.leftMenu);

  const uniqByKeepLast = (a: any, key: any) => {
    return [...new Map(a.map((x: any) => [key(x), x])).values()];
  };

  const CollectionSubElements = (collection: any) => {
    var tab: any = [];
    leftMenu.collections.map((el: any) => {
      if (collection.id === el.collection_id) {
        tab.push(el);
      }
    });
    setCollectionSubElments(tab);
  };

  return (
    <div
      className="sub_menu_extension"
      {...props}
      style={!props.showMenuExt ? { display: "none" } : {}}
    >
      <div
        className="close_btn"
        onClick={() => {
          dispatch(handleLeftDrawer());
          dispatch(closeSideMenuExtension());
        }}
      >
        <p>Close</p>
        <FontAwesomeIcon icon={faTimes} />
      </div>

      <div className="sub_menu_elements">
        {props.gender === "collection"
          ? leftMenu.collections &&
            leftMenu.collections
              .sort((a: any, b: any) => (a.sort_order > b.sort_order ? 1 : -1))
              .filter((el: any) => !el.collection_id)
              .map((el: any, i: number) => (
                <div key={i} className="sub_menu_element">
                  <Link
                    className="sub_menu_element_val"
                    to={
                      el.children.length === 0
                        ? props.menuchoice === "denim"
                          ? `/collection/${el.id}/denim`
                          : `/collection/${el.id}/knit`
                        : { hash: "#" }
                    }
                    onClick={() => {
                      if (el.children.length > 0) {
                        // setCollectionSubElments(el.children);
                        CollectionSubElements(el);
                        setCollectionSubElmentsChildrens({
                          parent: "",
                          childrens: [],
                        });
                      } else {
                        dispatch(handleLeftDrawer());
                      }
                    }}
                  >
                    {el.name}
                  </Link>
                  {el.children.length > 0 && <PlusIcon />}
                </div>
              ))
          : props.gender.name && props.gender.name === "Kids"
          ? leftMenu.allGenders.data
              .filter((el: any) => el.name.toLowerCase() === "kids")[0]
              .childs.map((el: any, i: number) => (
                <div key={i} className="sub_menu_element">
                  <Link
                    className="sub_menu_element_val"
                    to="#"
                    onClick={() => {
                      dispatch(
                        getCategoriesUnderGender(el.id, props.menuchoice)
                      );
                      setSubMenuChoice(el);
                    }}
                  >
                    {el.name}
                  </Link>
                  <PlusIcon />
                </div>
              ))
          : leftMenu.isLoadingCategories
          ? Array.from({ length: 1 }, (_, index) => index + 1).map((_, i) => (
              <Skeleton active={true} key={i} />
            ))
          : uniqByKeepLast(leftMenu.categories, (k: any) => k.name).map(
              (el: any, i: number) => (
                <MenuCategory
                  key={i}
                  subSubElements={el}
                  setSubDataToShow={setSubDataToShow}
                  menuchoice={props.menuchoice}
                  gender={props.gender}
                />
              )
            )}
      </div>
      <div className="sub_sub_menu_elements">
        {props.gender.name === "Kids" && leftMenu.isLoadingCategories
          ? Array.from({ length: 1 }, (_, index) => index + 1).map(
              (_, i: number) => <Skeleton active={true} key={i} />
            )
          : props.gender.name === "Kids" &&
            leftMenu.categories &&
            uniqByKeepLast(leftMenu.categories, (k: any) => k.name).map(
              (el: any, i: number) => (
                <Link
                  key={i}
                  to={`/product/${props.menuchoice}/${subMenuChoice.name}/${subMenuChoice.id}/${el.name}/${el.id}`}
                  className="sub_sub_menu_element"
                  onClick={() => dispatch(handleLeftDrawer())}
                >
                  {el.name}
                </Link>
              )
            )}
      </div>
      {collectionSubElments && props.gender === "collection" && (
        <div className="sub_sub_menu_elements">
          {collectionSubElments.map((el: any, i: number) => (
            <div className="sub_menu_element_child" key={i}>
              <Link
                to={
                  el.children.length === 0
                    ? props.menuchoice === "denim"
                      ? `/collection/${el.id}/denim`
                      : `/collection/${el.id}/knit`
                    : { hash: "#" }
                }
                className="sub_sub_menu_element"
                onClick={() => {
                  if (el.id === collectionSubElmentsChildrens.parent) {
                    setCollectionSubElmentsChildrens({
                      parent: "",
                      childrens: [],
                    });
                  } else if (el.children.length > 0) {
                    setCollectionSubElmentsChildrens({
                      parent: el.id,
                      childrens: el.children,
                    });
                  } else {
                    dispatch(handleLeftDrawer());
                  }
                }}
              >
                {el.name}
              </Link>
              {el.children.length > 0 && <PlusIcon />}
              {collectionSubElmentsChildrens.parent === el.id && (
                <div className="sub_menu_elements_childrens">
                  {collectionSubElmentsChildrens.childrens.map(
                    (el: any, i: number) => (
                      <Link
                        to={
                          props.menuchoice === "denim"
                            ? `/collection/${el.id}/denim`
                            : `/collection/${el.id}/knit`
                        }
                        className="sub_menu_child"
                        style={
                          i ===
                          collectionSubElmentsChildrens.childrens.length - 1
                            ? { paddingBottom: 0 }
                            : {}
                        }
                        onClick={() => dispatch(handleLeftDrawer())}
                      >
                        {el.name}
                      </Link>
                    )
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <div
        className="get_inspired_container"
        style={{
          backgroundImage: `linear-gradient(
            to right bottom,
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.3)
          ) ,  url("${getInspired}")`,
        }}
      >
        <h1 className="get_inspired">
          <span>get</span> Inspired
        </h1>
      </div>
    </div>
  );
});
export default SideMenuExtension;
