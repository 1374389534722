import {
  faCheck,
  faKey,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, Select, Spin } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCollaborator,
  deleteCollaborator,
} from "../../actions/order-actions/actions";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";

const { Option } = Select;

interface Props {
  showDrawer: any;
  setShowDrawer: any;
  // collaborators: any;
  orderToEdit: any;
  mobile?: boolean;
}

export const AddCollabDrawer = ({
  showDrawer,
  setShowDrawer,
  // collaborators,
  orderToEdit,
  mobile,
}: Props) => {
  const [state, setState] = useState<any>({
    value: [],
    data: [],
    fetching: false,
  });
  const [lastFetchId, setLastFetchId] = useState(0);
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const { collaborators } = useSelector((state: any) => state.orders);

  const fetchUser = (value: any) => {
    setLastFetchId(lastFetchId + 1);
    const fetchId = lastFetchId;
    setState({ ...state, data: [], fetching: true });
    let token = localStorage.getItem("token");
    fetch(process.env.REACT_APP_API_URL + `/collaborators?name=${value}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((body) => {
        if (fetchId !== lastFetchId) {
          // for fetch callback order
          return;
        }
        const users = body.map((user: any) => ({
          id: user.id,
          name: user.name,
          picture: user.logo,
        }));
        setState({ ...state, data: users, fetching: false });
      });
  };

  const handleChangeSelect = (value: any) => {
    setState({
      value,
      data: [],
      fetching: false,
    });
  };

  return (
    <Drawer
      className="drawer_collaboration"
      // width={"25%"}
      placement={"right"}
      closable={true}
      onClose={() => setShowDrawer(false)}
      visible={showDrawer}
    >
      <div className="collab_drawer_header d-flex flex-column">
        <h2 className="mb-2">add new collaborator</h2>
        <div className="add_collab_popover">
          <Select
            mode="multiple"
            labelInValue
            value={state.value}
            placeholder="Select users"
            notFoundContent={state.fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={fetchUser}
            onChange={handleChangeSelect}
            style={{ width: "100%" }}
          >
            {state.data.map((d: any) => (
              <Option key={d.id} value={d.id}>
                {d.name}
              </Option>
            ))}
          </Select>
          <button
            onClick={(e: any) => {
              e.stopPropagation();
              let addedIds = state.value.map((el: any) => el.value);
              let allIds = [...new Set(addedIds)];
              dispatch(addCollaborator(orderToEdit.id, allIds));
              setShowDrawer(false);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
      <div className="collab_full_list">
        <h2 className="mb-2">Collaborators</h2>
        {collaborators.map((el: any) => (
          <div className="collab_img_container my-3" key={el.id}>
            <div>
              <img
                src={
                  el.logo
                    ? process.env.REACT_APP_STORAGE_URL + el.logo
                    : avatar_placeholder
                }
                alt="user"
                className="new_collab mr-3"
              />
              <p className="m-0">{el.name}</p>
            </div>
            <FontAwesomeIcon
              icon={
                orderToEdit && orderToEdit.client_id !== el.id
                  ? faTimesCircle
                  : faKey
              }
              onClick={() =>
                orderToEdit &&
                orderToEdit.client_id !== el.id &&
                dispatch(
                  deleteCollaborator(
                    orderToEdit.id,
                    collaborators.filter((collab: any) => collab.id !== el.id)
                  )
                )
              }
            />
          </div>
        ))}
      </div>
    </Drawer>
  );
};
