import React, { useState } from "react";
import {
  faFolderPlus,
  faUsers,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faUser as faFilledUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../Button/Button";
import { useSelector, useDispatch } from "react-redux";

import "./DirectOrderList.scss";
import { DirectOrderItem } from "./DirectOrderItem";
import { Collapse, Pagination, Tooltip } from "antd";
import { handleBottomDrawer } from "../../actions/actionCreators";
import BottomDrawer from "../BottomDrawer/BottomDrawer";
import { SharedIcon } from "../../icons/SharedIcon";
import { useQuery } from "react-query";
import axiosInstance from "../../config/axios-instance";
import { getOrdersCommerical } from "../../actions/order-actions/actions";
import SkeletonOrders from "./SkeletonOrders";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {history} from "../../index";

const { Panel } = Collapse;

interface Props {
  setSelectOrder: (x: any) => void;
  orders: object[];
  selectOrder: any;
  isLoading: boolean;
  sharedOrders: any;
  setSharedOrders: (x: boolean) => void;
}

export const DirectOrderList = ({
  setSelectOrder,
  orders,
  selectOrder,
  isLoading,
  sharedOrders,
  setSharedOrders,
}: Props) => {
  const [mode, setMode] = useState("sss");
  const dispatch = useDispatch();
  const drawers = useSelector((state: any) => state.drawers);
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const {
    orders: reducerOrders,
    getCompanysOrderLoading,
    total,
    next_page,
    prev_page,
  } = useSelector((state: any) => state.orders);
  const fetchClients = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/companies`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { isLoading: loading, data } = useQuery(
    "companies",
    () => fetchClients(),
    {
      keepPreviousData: true,
      enabled: isAdmin,
    }
  );

  return (
    <>
      <aside className="direct_order_list">
        <div className="direct_order_list_header_wrapper">
          {/* <div className="direct_order_list_header mb-3">
            <h2 className="direct_order_list_title">Direct Order</h2>
          </div> */}
        </div>
        {user && !isAdmin && (
          <div className="direct_order_list_header_wrapper">
            <div className="direct_order_list_header mb-3">
{/*
              <h2 className="direct_order_list_title">Direct Order</h2>
*/}
              <>

                <div className={
                  "faq_go_back"
                }
                     style={{ cursor: "pointer" }}
                     onClick={() => history.back()}
                >

                  <ArrowLeftOutlined

                  ></ArrowLeftOutlined>{" "}
                  <button
                      className={"go_back_text"}

                  >
                    Go back
                  </button>
                </div>

              </>
              <div className="direct_order_header_icons">
                {!sharedOrders ? (
                  <Tooltip title="Shared Orders">
                    <button
                      onClick={() => setSharedOrders(true)}
                      className="shared_order_btn"
                    >
                      <SharedIcon />
                    </button>
                  </Tooltip>
                ) : (
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="direct_order_header_icon"
                  />
                )}
                {sharedOrders ? (
                  <Tooltip title="My orders">
                    <FontAwesomeIcon
                      icon={faFilledUser}
                      className="direct_order_header_icon"
                      onClick={() => setSharedOrders(false)}
                      style={{ zIndex: 11 }}
                    />
                  </Tooltip>
                ) : (
                  <FontAwesomeIcon
                    icon={faUser}
                    className="direct_order_header_icon"
                    style={{ zIndex: 11 }}
                  />
                )}
              </div>
            </div>
            <Button
              innerButton="Add New Order"
              buttonClass="submit_button_blue submit_button_blue_add_folder direct_order_add_new_order"
              icon={<FontAwesomeIcon icon={faFolderPlus} />}
              onClick={() => {
                dispatch(handleBottomDrawer());
                setMode("add_new_order");
              }}
            ></Button>
          </div>
        )}
        <div className="direct_order_list_body_wrapper">
          {isLoading ? (
            // @ts-ignore
            <SkeletonOrders items={4} />
          ) : orders && !isAdmin ? (
            orders.map((el: any, i: number) => (
              <DirectOrderItem
                data={el}
                key={i}
                setSelectOrder={setSelectOrder}
                selectOrder={selectOrder}
              />
            ))
          ) : isAdmin && loading ? (
            // @ts-ignore
            <SkeletonOrders items={4} companies={true} />
          ) : (
            data && (
              <Collapse
                accordion={true}
                bordered={false}
                className="direct_order_collapse"
                style={{ background: "transparent" }}
                onChange={(x: any) => {
                  if (x) {
                    dispatch(getOrdersCommerical(x, 1));
                  }
                }}
              >
                {data.data.data.map((company: any, i: number) => (
                  <Panel
                    key={company.id}
                    header={company.name}
                    forceRender
                    showArrow={false}
                    extra={
                      <img
                        width="30px"
                        style={{ borderRadius: "50%" }}
                        src={process.env.REACT_APP_STORAGE_URL + company.logo}
                      />
                    }
                  >
                    {getCompanysOrderLoading ? (
                      // @ts-ignore
                      <SkeletonOrders items={4} />
                    ) : reducerOrders && reducerOrders.length > 0 ? (
                      reducerOrders.map((y: any) => (
                        <DirectOrderItem
                          data={y}
                          key={y.id}
                          setSelectOrder={setSelectOrder}
                          selectOrder={selectOrder}
                        />
                      ))
                    ) : (
                      <p className="text-center text-muted">No direct orders</p>
                    )}
                    {(next_page || prev_page) && (
                      <Pagination
                        size="small"
                        defaultCurrent={1}
                        defaultPageSize={10}
                        total={reducerOrders && total}
                        className="text-center my-3"
                        hideOnSinglePage
                        responsive
                        onChange={(page: any) =>
                          dispatch(getOrdersCommerical(company.id, page))
                        }
                      />
                    )}
                  </Panel>
                ))}
              </Collapse>
            )
          )}
        </div>
      </aside>
      {drawers.openBottom && (
        <BottomDrawer mode="add_new_order" setMode={setMode} />
      )}
    </>
  );
};
