import ArticleContentRow from "./ArticleContentRow";
import DemcoProductOrderContentRow from "./DemcoProductOrderContentRow";
import DirectOrderContentRow from "./DirectOrderContentRow";
import TableRowCustomizedContentOrder from "./TableRowCustomizedContentOrder";
import TableRowCustomizedOrder from "./TableRowCustomizedOrder";
import TableRowDemcoProduct from "./TableRowDemcoProduct";
import TableRowDirectOrder from "./TableRowDirectOrder";

export default function TableRow({
  item,
  orderType,
  url,
  data,
}: {
  item: any;
  orderType: string;
  url: string;
  data?: any;
}) {
  switch (orderType) {
    case "direct_orders":
      return <TableRowDirectOrder item={item} url={url} />;
    case "demco-product-orders":
      return <TableRowDemcoProduct item={item} url={url} />;
    case "customized-orders":
      return <TableRowCustomizedOrder item={item} url={url} />;
    case "customized_product_content":
      return <TableRowCustomizedContentOrder item={item} url={url} />;
    case "direct_orders_content":
      return <DirectOrderContentRow item={item} url={url} />;
    case "demco_product_content":
      return <DemcoProductOrderContentRow item={item} url={url} data={data} />;
    case "direct_orders_article_content":
      return <ArticleContentRow item={item} url={url} />;
    default:
      return null;
  }
}
