import "./buttons.scss";

type buttonProps = {
  buttonClass: string;
  innerButton: any;
  icon?: any;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  margin?: string;
  reverse?: boolean;
};
export const Button = ({
  buttonClass,
  innerButton,
  icon,
  disabled = false,
  onClick,
  margin,
  reverse,
}: buttonProps) =>
  reverse ? (
    <button
      onClick={onClick}
      className={buttonClass}
      style={{ marginTop: margin }}
      disabled={disabled}
    >
      <img src={icon} alt="muti_color icon" /> {innerButton}
    </button>
  ) : (
    <button
      onClick={onClick}
      className={buttonClass}
      style={{ marginTop: margin }}
      disabled={disabled}
    >
      {innerButton}
      {"  "} {icon}
    </button>
  );
