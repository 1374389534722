export const GET_CREATIVE_STATION_REQUEST = "GET_CREATIVE_STATION_REQUEST";
export const GET_CREATIVE_STATION_SUCCESS = "GET_CREATIVE_STATION_SUCCESS";
export const GET_CREATIVE_STATION_FAIL = "GET_CREATIVE_STATION_FAIL";

export const GET_MORE_NEWEST_CREATIVE_STATION_REQUEST =
  "GET_MORE_NEWEST_CREATIVE_STATION_REQUEST";
export const GET_MORE_NEWEST_CREATIVE_STATION_SUCCESS =
  "GET_MORE_NEWEST_CREATIVE_STATION_SUCCESS";
export const GET_MORE_NEWEST_CREATIVE_STATION_FAIL =
  "GET_MORE_NEWEST_CREATIVE_STATION_FAIL";

export const GET_MORE_TRENDING_CREATIVE_STATION_REQUEST =
  "GET_MORE_TRENDING_CREATIVE_STATION_REQUEST";
export const GET_MORE_TRENDING_CREATIVE_STATION_SUCCESS =
  "GET_MORE_TRENDING_CREATIVE_STATION_SUCCESS";
export const GET_MORE_TRENDING_CREATIVE_STATION_FAIL =
  "GET_MORE_TRENDING_CREATIVE_STATION_FAIL";

export const GET_MORE_FASHION_CREATIVE_STATION_REQUEST =
  "GET_MORE_FASHION_CREATIVE_STATION_REQUEST";
export const GET_MORE_FASHION_CREATIVE_STATION_SUCCESS =
  "GET_MORE_FASHION_CREATIVE_STATION_SUCCESS";
export const GET_MORE_FASHION_CREATIVE_STATION_FAIL =
  "GET_MORE_FASHION_CREATIVE_STATION_FAIL";

export const GET_ONE_POST_REQUEST = "GET_ONE_POST_REQUEST";
export const GET_ONE_POST_SUCCESS = "GET_ONE_POST_SUCCESS";
export const GET_ONE_POST_FAIL = "GET_ONE_POST_FAIL";

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAIL = "DELETE_POST_FAIL";
