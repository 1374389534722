import { Button } from "antd";
import TechnicsCollapse from "./TechnicsCollapse";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const EditTechnics = ({
  productToUpdate,
  genExtraForTech,
  setProductToUpdate,
  setData,
  data,
  options,
  handleChangeTech,
  addNewTech,
}: any) => {
  const { user } = useSelector((state: any) => state.auth);
  return (
    <>
      {productToUpdate.techniques.length === 0 ? (
        <h6 className="text-center p-2 mt-2 text-muted product_doesnt_have">
          This product doesn't have technic
        </h6>
      ) : (
        productToUpdate.techniques.map((tech: any, index: number) => (
          <TechnicsCollapse
            key={index}
            index={index}
            tech={tech}
            productToUpdate={productToUpdate}
            genExtraForTech={genExtraForTech}
            setProductToUpdate={setProductToUpdate}
            setData={setData}
            data={data}
            options={options}
            handleChangeTech={handleChangeTech}
          />
        ))
      )}
      <Button
        type="primary"
        disabled={!user.permissions.includes("product.update")}
        icon={<PlusOutlined style={{ verticalAlign: 0 }} />}
        className="add_new_one"
        onClick={addNewTech}
      >
        Add Another Technic
      </Button>
    </>
  );
};

export default EditTechnics;
