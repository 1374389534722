function Calender() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 28 28"
    >
      <path
        fill="#182844"
        d="M-3.562-7.5A.564.564 0 00-3-8.062v-1.875a.564.564 0 00-.562-.562h-1.875A.564.564 0 00-6-9.937v1.875a.564.564 0 00.563.563zM1.5-8.062v-1.875a.564.564 0 00-.562-.563H-.937a.564.564 0 00-.562.563v1.875a.564.564 0 00.562.562H.938a.564.564 0 00.562-.562zm4.5 0v-1.875a.564.564 0 00-.562-.562H3.563A.564.564 0 003-9.937v1.875a.564.564 0 00.563.563h1.875A.564.564 0 006-8.062zm-4.5 4.5v-1.875A.564.564 0 00.938-6H-.937a.564.564 0 00-.562.563v1.875A.564.564 0 00-.937-3H.938a.564.564 0 00.562-.562zm-4.5 0v-1.875A.564.564 0 00-3.562-6h-1.875A.564.564 0 00-6-5.437v1.875A.564.564 0 00-5.437-3h1.875A.564.564 0 00-3-3.562zm9 0v-1.875A.564.564 0 005.438-6H3.563A.564.564 0 003-5.437v1.875A.564.564 0 003.563-3h1.875A.564.564 0 006-3.562zm4.5-12.188A2.251 2.251 0 008.25-18H6v-2.437A.564.564 0 005.438-21H3.563a.564.564 0 00-.563.563V-18h-6v-2.437A.564.564 0 00-3.562-21h-1.875a.564.564 0 00-.563.563V-18h-2.25a2.251 2.251 0 00-2.25 2.25V.75A2.251 2.251 0 00-8.25 3h16.5A2.251 2.251 0 0010.5.75zM8.25.469a.282.282 0 01-.281.281H-7.969A.282.282 0 01-8.25.469V-13.5h16.5z"
        data-name="Tracé 3318"
        transform="translate(10.5 21)"
      ></path>
    </svg>
  );
}
export default Calender;
