import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import folder from "../../img/folder.png";
import { Button } from "../Button/Button";
import { FoldersStaticData } from "../../StaticData/FoldersStaticData";
import "./AddToCollection.scss";
import {
  handleBottomDrawer,
  handleBottomAddToCollection,
  handleBottomDrawerAddFolder,
} from "../../actions/actionCreators";
import Folder from "../Folder/Folder";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {
  getAllFolders,
  addProducttoFolder,
  handleMovingState,
  editFolder,
  changeFolderRoot,
  clearSelectedFolders,
  clearfolderToMoveTo,
  handleMovingStateProduct,
  moveProductToFolder,
  handleMovingStateProducts,
  clearSelectedProducts,
  getOneFolder,
} from "../../actions/folders-actions/actions";
import backArrow from "../../img/backArrow.png";
import { setAlert } from "../../actions/alerts-actions/actions";
import { v4 as uuidv4 } from "uuid";
import BottomDrawer from "../BottomDrawer/BottomDrawer";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { createCustomizedProduct } from "../../actions/order-actions/actions";
import { LoadingOutlined } from "@ant-design/icons";
import folder_content from "../../img/folder_content_2.png";
import { Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import PlusIcon from "../../icons/PlusIcon";

export interface IAddToCollectionProps {
  setMode: ((mode: string) => void) | undefined;
  mode?: string;
  addCustomizedProduct?: boolean;
  customizedOrder?: any;
}

export default function AddToCollection({
  setMode,
  mode,
  addCustomizedProduct,
  customizedOrder,
}: IAddToCollectionProps) {
  const dispatch = useDispatch();
  const folders = useSelector((state: RootStateOrAny) => state.folders);
  const drawers = useSelector((state: RootStateOrAny) => state.drawers);
  const { saveCustomizedProductLoading } = useSelector(
    (state: RootStateOrAny) => state.orders
  );
  const [height, setHeight] = useState(window.innerHeight);
  const [subfolders, setsubfolders] = useState<any>([]);
  const [parentFolder, setparentFolder] = useState<any>(null);
  const { user, isAdmin } = useSelector((state: RootStateOrAny) => state.auth);
  const [selectedfolders, setselectedfolders] = useState<Array<any>>([]);
  const params = useParams();
  const addFolderToSelected = (item: any) => {
    const exists = selectedfolders.includes(item);
    if (exists) {
      setselectedfolders(
        selectedfolders.filter((c: any) => {
          return c !== item;
        })
      );
    } else {
      var result = selectedfolders;
      result.push(item);
      setselectedfolders(result);
    }
  };

  const addProducttoFolders = () => {
    selectedfolders.map((folderId: string) => {
      dispatch(addProducttoFolder(folderId, folders.idProduct));
    });
    var idAlert = uuidv4();
    dispatch(
      setAlert(
        "Product added to collection(s) successfully",
        "success",
        idAlert
      )
    );
  };

  const variants = {
    enter: { y: height },
    center: { y: 0 },
    exit: { y: height },
  };
  const variants2 = {
    enter: { y: 0 },
    center: { y: height },
    exit: { y: 0 },
  };

  useEffect(() => {
    dispatch(getAllFolders());
  }, []);

  useEffect(() => {
    setHeight(window.innerHeight);
  }, [window.innerHeight]);

  const [showCollectionForm, setShowCollectionForm] = useState(false);

  return (
      <>

        <motion.div
            className="add_to_collection_container"
            transition={{type: "tween"}}
            variants={mode === "add_to_collection" ? variants2 : variants}
            initial="enter"
            animate="center"
            exit="exit"
        >

          <div className="header_add_to_collection" style={{textAlign:"center" }}>
            <span className={"add-to-collection-title"}>Add to collection !</span>
            <p className={'add-to-collection-description'}>Select the collection where you want to move your
              products</p>
          </div>
          <div style={{display:"contents" ,marginRight:"52px"}}>

            <div className="row " style={{alignSelf:"end" }}>
              <button
                  onClick={() => setShowCollectionForm(true)}

                  className="order-btn-h-add-new-collection">
                <span className="button-text">Create New Collection</span>
                <span className="button-icon"><PlusIcon></PlusIcon></span>
              </button>
            </div>
          </div>
          {/*
          <div className="add_to_collection_top">
            {subfolders.length > 0 && subfolders[0].parent_id && (
                <img
                    src={backArrow}
                    alt=""
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      setsubfolders([]);
                      setselectedfolders([]);
                      setparentFolder(
                          parentFolder.parent_id
                              ? folders.folders.filter(
                                  (el: any) => el.id === parentFolder.parent_id
                              )[0]
                              : null
                      );
                      setsubfolders(
                          folders.folders.filter(
                              (el: any) => el.parent_id === parentFolder.parent_id
                          )
                      );
                    }}
                />
            )}


             <div className="add_to_collection_top_right">
            <h2 className="add_to_collection_title">
              {!parentFolder
                ? folders.moving
                  ? "Move To"
                  : "Add to collection"
                : null}
              {parentFolder && parentFolder.name}
            </h2>
            <p className="paragraphe add_to_collection_desc">
              {folders.moving
                ? "Select the collection where you want to move your folders"
                : "Select the collection where you want to move your products"}
            </p>

            <div className="add_to_collection_title justify-content-end">
              <Button
                innerButton="Add New Collection"
                buttonClass="submit_button_blue submit_button_blue_add_folder"
                onClick={() => setShowCollectionForm(true)}
                icon={<FontAwesomeIcon icon={faFolderPlus} />}
              />
            </div>
          </div>
          </div>
*/}

          <div className="list_folders">
            {/* <div
          className="add_new_folder"
          onClick={() => {
            setMode && setMode("add_folder");
            // dispatch(handleBottomDrawerAddFolder());
          }}
        >
          <img src={folder} alt="" className="add_new_folder_img" />
          <p className="add_new_folder_text">Add New Folder</p>
        </div> */}
            {folders.folders.length === 0 && (
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                  <img src={folder_content} alt="foders image" className="mb-5"/>
                  <h2 className="collection_welcome mb-3">
                    Create your first collection
                  </h2>
                </div>
            )}
            {subfolders.length > 0
                ? subfolders.map((folder: any, index: number) => (
                    <Folder
                        clickable={true}
                        addToCollection={true}
                        selectOneFolder={true}
                        folder={folder}
                        key={index}
                        addFolderToSelected={addFolderToSelected}
                        setsubfolders={setsubfolders}
                        setselectedfolders={setselectedfolders}
                        setparentFolder={setparentFolder}
                    />
                ))
                : folders.folders &&
                folders.folders
                    //.filter((el: any) => !el.parent_id)
                    .filter((el: any) => el.client_id == user.id)
                    .map((folder: any, index: number) => (
                        <Folder
                            clickable={true}
                            selectOneFolder={true}
                            addToCollection={true}
                            folder={folder}
                            key={index}
                            addFolderToSelected={addFolderToSelected}
                            setsubfolders={setsubfolders}
                            setselectedfolders={setselectedfolders}
                            setparentFolder={setparentFolder}
                        />
                    ))}
          </div>

          <div className="buttons">
            <Button
                innerButton="Cancel"
                buttonClass="submit_button_white submit_button_white_cancel"
                onClick={() => {
                  if (drawers.openBottomMoveFolders) {
                    dispatch(handleBottomAddToCollection());
                    // dispatch(handleMovingState());
                    dispatch(clearSelectedFolders());
                    dispatch(clearSelectedProducts());
                    dispatch(clearfolderToMoveTo());
                    dispatch(handleMovingStateProducts());
                  } else dispatch(handleBottomDrawer());
                }}
            />
            {folders.folders.length > 0 && (
                <Button
                    innerButton={
                      saveCustomizedProductLoading ? (
                          <Spin
                              indicator={
                                <LoadingOutlined

                                    spin
                                />
                              }
                          />
                      ) : (
                          "Confirm"
                      )
                    }

                    buttonClass="submit_button_white submit_button_white_confirm bg_confirm"
                    onClick={() => {
                      if (addCustomizedProduct) {
                        if (!folders.folderIdToMoveTo) {
                          MyNotif("Please select a folder", "danger");
                        } else {
                          let formData = new FormData();

                          Object.keys(customizedOrder)
                              .filter((x: any) => !x.includes("files"))
                              .map(
                                  (el: any) =>
                                      customizedOrder[el] &&
                                      formData.append(el, customizedOrder[el])
                              );

                          if (customizedOrder.style_files.length) {
                            for (
                                let i = 0;
                                i < customizedOrder.style_files.length;
                                i++
                            ) {
                              formData.append(
                                  `style_files[${i}]`,
                                  customizedOrder.style_files[i]
                              );
                            }
                          }
                          if (customizedOrder.fabric_files.length) {
                            for (
                                let i = 0;
                                i < customizedOrder.fabric_files.length;
                                i++
                            ) {
                              formData.append(
                                  `fabric_files[${i}]`,
                                  customizedOrder.fabric_files[i]
                              );
                            }
                          }
                          if (customizedOrder.color_files.length) {
                            for (
                                let i = 0;
                                i < customizedOrder.color_files.length;
                                i++
                            ) {
                              formData.append(
                                  `color_files[${i}]`,
                                  customizedOrder.color_files[i]
                              );
                            }
                          }

                          // create a customized product and save it in a folder
                          dispatch(createCustomizedProduct(formData, false));
                        }
                      } else if (!folders.folderIdToMoveTo && folders.moving) {
                        MyNotif("Please select a folder.", "danger");
                      } else if (folders.moving) {
                        folders.selectedFolders.map((el: any) => {
                          dispatch(changeFolderRoot(folders.folderIdToMoveTo, el));
                          dispatch(getAllFolders());
                          dispatch(handleBottomAddToCollection());
                          dispatch(handleMovingState());
                        });
                      } else if (folders.movingProductMultiSelection) {
                        dispatch(handleBottomAddToCollection());
                        folders.selectedProducts.map((el: any) => {
                          dispatch(
                              moveProductToFolder(
                                  folders.folderIdToMoveTo,
                                  el.idParentFolder,
                                  el.id
                              )
                          );

                          dispatch(getAllFolders());
                          dispatch(handleMovingStateProducts());
                          dispatch(clearSelectedProducts());
                          dispatch(clearfolderToMoveTo());
                        });
                      } else if (folders.folderIdToMoveTo) {
                        dispatch(
                            addProducttoFolder(
                                folders.folderIdToMoveTo,
                                folders.idProduct
                            )
                        );
                        dispatch(getOneFolder(folders.folderIdToMoveTo));
                        console.log("get one folder now!!");
                        dispatch(handleBottomDrawer());
                        // dispatch(handleBottomAddToCollection());
                        dispatch(clearfolderToMoveTo());
                      } else {
                        addProducttoFolders();
                        dispatch(handleBottomDrawer());
                        // dispatch(handleBottomAddToCollection());
                      }
                    }}
                />
            )}
          </div>
        </motion.div>
        {drawers.openBottom && showCollectionForm && (
            <>
              {" "}
              <BottomDrawer folders={folders} mode="add_folder_collection"/>
            </>
        )}
      </>
  );
}
