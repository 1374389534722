import React, { useState, useEffect } from "react";
import "./LoginForm.scss";
import logo_green from "../../img/logo_green.svg";
import { Button } from "../Button/Button";
import { motion, AnimatePresence, useCycle } from "framer-motion";
import backArrow from "../../img/backArrow.png";
import { loginUser } from "../../actions/auth-actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { JoinForm } from "./JoinForm";
import { EmailReset } from "./EmailReset";
import { CodePassword } from "./CodePassword";
import { NewPassword } from "./NewPassword";
import { Spinner } from "../Spinner";
import {ArrowLeftOutlined} from "@ant-design/icons";

//@ts-ignore
export interface ILoginFormProps {
  setSuccess: (val: boolean) => void;
  success: boolean;
}
export default function LoginForm(props: ILoginFormProps) {
  const [code, setCode] = useState([".", ".", ".", "."]);
  const [forgot, setForgot] = useState<string>("center");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [reset, setReset] = useState<string | boolean>(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.auth);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  //@ts-ignore
  const handleChangeCode = (otp) => setCode({ otp });

  useEffect(() => {
    if (props.success) setForgot("center");
  }, [props.success]);

  return (
    <div className="login_form_container col-5 ">
      {forgot === "center" ? (
        <AnimatePresence initial={false} exitBeforeEnter={true}>
          <div className="login_form_container_initial">
            <div className="login_form_forgot_top">
              <img
                src={logo_green}
                alt="logo Demco"
                className="login_form_logo"
              />
            </div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="title_header"
            >
              <h2 className="h2_subtitle_light_blue_login">take off with us</h2>
              <h2 className="h2_subtitle_blue_login_2 h2_subtitle_blue_login">
                WELCOME
              </h2>
              <form className="main_login_form">
                <div className="field">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder=""
                    onChange={handleChange}
                    style={{
                      borderBottom: auth.error && "1px solid red",
                    }}
                  />
                  <label htmlFor="email" className="login_email_label">
                    Email Address
                  </label>
                  {auth.error && (
                    <motion.i
                      initial={{ x: 20 }}
                      animate={{ x: 0 }}
                      transition={{ type: "spring", stiffness: 500 }}
                      className="fas fa-exclamation-triangle"
                    ></motion.i>
                  )}
                </div>

                <div className="field">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder=""
                    onChange={handleChange}
                    style={{
                      borderBottom: auth.error && "1px solid red",
                    }}
                  />
                  <label htmlFor="password" className="login_password_label">
                    Password
                  </label>
                  {auth.error && (
                    <motion.i
                      initial={{ x: 20 }}
                      animate={{ x: 0 }}
                      transition={{ type: "spring", stiffness: 500 }}
                      className="fas fa-exclamation-triangle"
                    ></motion.i>
                  )}
                </div>

                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="forget_password"
                  // @ts-ignore
                  onClick={() => {
                    setForgot("something");
                  }}
                >
                  Forgot Your Password?
                </motion.p>

                {auth.isLoading ? (
                  <Spinner />
                ) : (
                  <Button
                    buttonClass="submit_button_white_full_width"
                    innerButton="Sign in"
                    onClick={() => dispatch(loginUser(data))}
                  />
                )}
              </form>
            </motion.div>
            <p className=" join_question">
              Don’t have an account ?{" "}
              <span
                className="resend_text"
                onClick={
                  //@ts-ignore
                  () => setForgot("join")
                }
                style={{fontWeight:"bold"}}
              >
                {" "}
                Join DEMCO{" "}
              </span>
            </p>
          </div>
        </AnimatePresence>
      ) : forgot === "join" ? (
        <JoinForm setSuccess={props.setSuccess} setForgot={setForgot} />
      ) : (
        <AnimatePresence initial={true} exitBeforeEnter={true}>
          <motion.div className="login_form_container_initial  h-100 d-flex flex-column justify-content-around">
            <div className="login_form_forgot_top">
              <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  className="back_arrow_back_link"
              >
              {/*  <img
                    src={backArrow}
                    alt="Back Arrow"
                    onClick={() => {
                      setReset(false);
                      setForgot("center");
                    }}
                />
                <p
                    className="back_sign_in"
                    onClick={() => {
                      setForgot("center");
                      setReset(false);
                    }}
                >
                  Back to Sign in
                </p>*/}

                <>

                  <div className={
                    "login_go_back"
                  }

                       onClick={() => {
                         setReset(false);
                         setForgot("center");
                       }}>

                    <ArrowLeftOutlined
                        onClick={() => {
                          setReset(false);
                          setForgot("center");
                        }}
                    ></ArrowLeftOutlined>{" "}
                    <button
                        className={"go_back_text"}

                    >
                      Go back
                    </button>
                  </div>

                </>
              </motion.div>
              <img
                  src={logo_green}
                  alt="logo Demco"
                  className="login_form_logo"
              />




            </div>
            {!reset ? (
                <EmailReset
                    setReset={setReset}
                    setEmail={(x) => setEmail(x)}
                    email={email}
                />
            ) : reset === "SendCode" ? (
                <CodePassword
                    setReset={setReset}
                    handleChangeCode={handleChangeCode}
                    code={code}
                    email={email}
                />
            ) : (
                <NewPassword
                    setForgot={setForgot}
                    setPassword={setPassword}
                    setConfirmPwd={setConfirmPwd}
                    password={password}
                    confirmPwd={confirmPwd}
                    code={code}
                    email={email}
                />
            )}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}
