import "./chatButton.scss";
import React, {useCallback, useEffect, useState} from 'react';
import {CloudDownloadOutlined} from "@ant-design/icons";
import CloseChatButton from "../../icons/CloseChatButton";
import ChatButtonIcon from "../../icons/ChatButtonIcon";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {useLocation} from "react-router-dom";


export const ChatButton = () =>

{

    const [isChatVisible, setChatVisible] = useState(false);
    const location: any = useLocation();

    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ right: 20, bottom: 20 });
    const [relPosition, setRelPosition] = useState({ x: 0, y: 0 });
    const [dragStart, setDragStart] = useState<any>(null);

    const toggleChat = useCallback(() => {
        setChatVisible(!isChatVisible);
    }, [isChatVisible]);
    const onMouseDown = useCallback((e:any) => {
        const { clientX, clientY } = e;
        setDragStart({
            startX: clientX,
            startY: clientY,
            startRight: position.right,
            startBottom: position.bottom
        });
        e.preventDefault();
    }, [position]);

    const onMouseUp = useCallback((e:any) => {
        if (dragStart) {
            // Check if there was any movement. If not, it is a click.
            if (dragStart.startX === e.clientX && dragStart.startY === e.clientY) {
                toggleChat();
            }
            setDragStart(null);
        }
        e.preventDefault(); // This prevents unintentional text selection.
    }, [dragStart, toggleChat]);

    const _onMouseMove = useCallback((e:any) => {
        if (dragStart) {
            setPosition({
                right: dragStart.startRight - (e.clientX - dragStart.startX),
                bottom: dragStart.startBottom - (e.clientY - dragStart.startY)
            });
            e.preventDefault();
        }
    }, [dragStart]);




    useEffect(() => {
        if (dragStart !== null) {
            document.addEventListener('mousemove', _onMouseMove);
            document.addEventListener('mouseup', onMouseUp);

            return () => {
                document.removeEventListener('mousemove', _onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
            };
        }
    }, [dragStart, _onMouseMove, onMouseUp]);
    const onDragEnd = (result:any) => {
        // Implement logic to handle the drop result if needed
        // For simplicity, we won't do anything here.
    };


    return(
        <>
            <div

            >
               <button

                    className={"chat-button"}
                   style={{

                   right: `${position.right}px`,
                   bottom: `${position.bottom}px`,
                   cursor: isChatVisible ? 'pointer' : 'grab',

                   zIndex:999
               }}
                       onMouseDown={onMouseDown}>{
                    isChatVisible ? <>
                            <CloseChatButton/>
                        </> :
                        <>
                          <ChatButtonIcon/>
                        </>
                }

                </button>

                {isChatVisible && (

                        <iframe
                            id="chatIframe"
                            src="https://embed.fixie.ai/agents/a94b1714-fff3-46d7-bc15-55a2ffb689e0?chatTitle=IDWEB+Smart+Assistant++%F0%9F%9A%80+"
                            height="395px"
                            width="295px"
                            allow="clipboard-write"
                            style={{
                                position: 'fixed',
                                bottom: `${position.bottom + 50 + 10}px`, // offset by the height of draggable div + margin
                                right: `${position.right}px`,
                                width: '350px',
                                height: '60%',
                                border: '1px solid rgb(204, 204, 204)',
                                zIndex: 999,
                                display: 'block',
                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 5px 40px',
                                borderRadius: '16px',
                            }}
                        ></iframe>

                )}
            </div>

        </>
    )
}