import { Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { handleRightDrawer } from "../../actions/actionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import logo_green from "../../img/logo_green.svg";

const BookingCancel = ({ notif, inPage, position }: any) => {
  const dispatch = useDispatch();
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  return inPage ? (
    <div className="notif_item_page p-2 my-2">
      <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center icon_notif_container">

        </div>
        <div>
          <h2 className="h4_medium m-0">
            {`Your meeting with ${
              user && isAdmin ? notif.data.client.name : "DEMCO team"
            }
            has been cancelled`}
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{ fontWeight: 500, fontSize: "0.7rem" }}
              className="selection_date"
            >
              <span
                style={{
                  fontWeight: 700,
                  fontSize: "0.7rem",
                  textDecoration: "underline",
                }}
                className="selection_date"
              >
                Subject:
              </span>
              {" " + notif.data.subject}
            </span>
            <span
              style={{ fontWeight: 500, fontSize: "0.7rem" }}
              className="selection_date"
            >
              <span
                style={{
                  fontWeight: 700,
                  fontSize: "0.7rem",
                  textDecoration: "underline",
                }}
                className="selection_date"
              >
                Date:
              </span>
              {" " + moment(notif.data.date).format("dddd D MMM yyyy - HH:mm")}
            </span>
          </div>
        </div>
      </div>
      <p className="selection_date m-0">{moment(notif.created_at).fromNow()}</p>
    </div>
  ) : (
    <div
      className="notif_added_prod_container my-2 border p-4"
      style={{
        borderRadius: "8px",
        background: position ? "#e5f1fa" : "white",
        transition: "all 5s ease-in-out",
      }}
    >
      <article className="notif_selection order_changes w-100">
        <div>

        </div>
        <div className="notif_details" style={{ flex: 1 }}>
          <h2 className="h4_medium">
            {`Your meeting with ${
              user && isAdmin ? notif.data.client.name : "DEMCO team"
            }
           has been cancelled`}
          </h2>
          <div className="d-flex justify-content-between align-items-end flex-row-reverse">
            <p
              className="selection_date mb-0"
              style={{ fontSize: "0.7rem", fontWeight: 400 }}
            >
              {moment(notif.created_at).fromNow()}
            </p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{ fontWeight: 300, fontSize: "0.7rem" }}
                className="selection_date"
              >
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: "0.7rem",
                    textDecoration: "underline",
                  }}
                  className="selection_date"
                >
                  Subject:
                </span>
                {" " + notif.data.subject}
              </span>
              <span
                style={{ fontWeight: 300, fontSize: "0.7rem" }}
                className="selection_date"
              >
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: "0.7rem",
                    textDecoration: "underline",
                  }}
                  className="selection_date"
                >
                  Date:
                </span>
                {" " +
                  moment(notif.data.date).format("dddd D MMM yyyy - HH:mm")}
              </span>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default BookingCancel;
