import { Button } from "../Button/Button";
import { motion } from "framer-motion";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "../Spinner";
import { useMutation } from "react-query";
import AuthServices from "../../actions/auth-actions/services";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { MyNotif } from "../NotifIcons/NotifIcons";

interface Props {
  setReset: (arg: string) => void;
  setEmail: (arg: string) => void;
  email: string;
}

export const EmailReset = ({ setReset, setEmail, email }: Props) => {
  const [error, setError] = useState<any>("");
  const { isLoading } = useSelector((state: any) => state.auth);
  // eslint-disable-next-line
  const emailPattern = /^([a-z\d\.-]{3,})@([a-z]+)\.([a-z]{2,6})$/;

  const { mutateAsync: sendEmailReset, isLoading: sendEmailResetLoading } =
    useMutation(AuthServices.emailReset, {
      onError: (err: any) => {
        setError("Please put a valid email address");
        MyNotif(err.response.data.error, "danger");
      },
      onSuccess: () => {
        setError("");
        setReset("SendCode");
      },
    });

  const sendEmail = (e: any) => {
    e.preventDefault();
    if (email && emailPattern.test(email)) {
      sendEmailReset(email);
    } else {
      setError("Please put a valid email address");
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <div style={{ width: "100%" }} className="d-flex flex-column">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <h2 className="h2_subtitle_blue h2_subtitle_blue_reset">
          Reset password
        </h2>
        <p className="paragraphe parag_rest_password">
          Enter your email below and we will assist you to reset your password.
        </p>
        <form
          className="login_form_forgot_password d-flex flex-column"
          onSubmit={sendEmail}
        >
          <div className="field">
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Your email"
              value={email}
              style={{
                borderBottom: error && "1px solid red",
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="email" className="login_email_label">
              Email Address
            </label>
            {error && (
              <motion.i
                initial={{ x: 20 }}
                animate={{ x: 0 }}
                transition={{ type: "spring", stiffness: 500 }}
                className="fas fa-exclamation-triangle"
              ></motion.i>
            )}
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              buttonClass="submit_button_white_full_width mt-4"
              innerButton={
                sendEmailResetLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  "Reset Password"
                )
              }
              // onClick={sendEmailAddress}
            />
          )}
        </form>
      </motion.div>
    </div>
  );
};
