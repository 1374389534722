import { useState } from "react";
import {
  useRouteMatch,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import {Button, Tabs} from "antd";
import MyOrdersHeader from "./my orders/MyOrdersHeader";
import DirectOrdersContainer from "./my orders/DirectOrdersContainer";
import DemcoProductOrdersContainer from "./my orders/DemcoProductOrdersContainer";
import CustomizedOrdersContainer from "./my orders/CustomizedOrdersContainer";
import "../sass/MyOrders.scss";
import "../sass/MyOrderConfirmation.scss";

const { TabPane } = Tabs;

export default function MyOrders2() {
  const history = useHistory();
  const [key, setKey] = useState(
    history.location.pathname.split("/")[2] || "direct-orders"
  );
  const [orderStatus, setOrderStatus] = useState(
    history.location.pathname.split("/")[3] || "in-progress"
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const { path, isExact, url } = useRouteMatch();

  const handleChangeTab = (activeKey: string) => {
    setKey(activeKey);
    history.push(`${url}/${activeKey}/${orderStatus}`);
  };

  if (isExact) {
    return <Redirect to={`${url}/direct-orders/in-progress`} />;
  }

  return (
    <div className="my_orders">
      {history.location.pathname.split("/").length === 4 && (
        <MyOrdersHeader
          path={url}
          tabKey={key}
          setOrderStatus={setOrderStatus}
          orderStatus={orderStatus}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
      {history.location.pathname.split("/").length === 4 && (
          <>

          <div className="my_orders_header_type mt-4" >
            <div className="my_orders_header_type_btns">
              <Button
                  type="ghost"
                  onClick={() => handleChangeTab("direct-orders")}

                  className={key === "direct-orders" ? "--selected" : ""}

              >
                Direct Orders
              </Button>
              <Button
                  type="ghost"
                  onClick={() => handleChangeTab("demco-product-orders")}

                  className={key === "demco-product-orders"? "--selected" : ""}

              >
                Demco  Orders
              </Button>
              <Button
                  type="ghost"
                  onClick={() => handleChangeTab("customized-orders")}

                  className={key === "customized-orders" ? "--selected" : ""}

              >
                Customized Orders
              </Button>
            </div>
          </div>
            {key == "direct-orders" ?
                (
                    <>
                      <Route path={`${path}/direct-orders/:status`}>
                        <DirectOrdersContainer
                            key={key}
                            orderStatus={orderStatus}
                            selectedDate={selectedDate}
                        />
                      </Route>
                    </>
                ) : key == "demco-product-orders" ?
                    (
                        <><Route path={`${path}/demco-product-orders/:status`}>
                          <DemcoProductOrdersContainer
                              key={key}
                              orderStatus={orderStatus}
                              selectedDate={selectedDate}
                          />
                        </Route></>
                    )
                    :

                    (
                        <><Route path={`${path}/customized-orders/:status`}>
                          <CustomizedOrdersContainer
                              key={key}
                              orderStatus={orderStatus}
                              selectedDate={selectedDate}
                          />
                        </Route></>
                    )


            }
            {/*    <Switch>
          <Tabs
            defaultActiveKey={key}
            onChange={(activeKey: string) => handleChangeTab(activeKey)}
            size="large"
          >
            <TabPane
              tab={"Direct Orders"}
              key={"direct-orders"}
              style={{ paddingBottom: "40px" }}
            >
              <Route path={`${path}/direct-orders/:status`}>
                <DirectOrdersContainer
                  key={key}
                  orderStatus={orderStatus}
                  selectedDate={selectedDate}
                />
              </Route>
            </TabPane>
            <TabPane
              tab={"Demco  Orders"}
              key={"demco-product-orders"}
              style={{ paddingBottom: "40px" }}
            >
              <Route path={`${path}/demco-product-orders/:status`}>
                <DemcoProductOrdersContainer
                  key={key}
                  orderStatus={orderStatus}
                  selectedDate={selectedDate}
                />
              </Route>
            </TabPane>
            <TabPane
              tab={"Customized Orders"}
              key={"customized-orders"}
              style={{ paddingBottom: "40px" }}
            >
              <Route path={`${path}/customized-orders/:status`}>
                <CustomizedOrdersContainer
                  key={key}
                  orderStatus={orderStatus}
                  selectedDate={selectedDate}
                />
              </Route>
            </TabPane>
          </Tabs>
          <Route>
            <h1>Page Not Found</h1>
          </Route>
        </Switch>*/}
          </>
      )}
      {history.location.pathname.split("/").length > 4 && (
        <Switch>
          <Route path={`${path}/direct-orders/:status`}>
            <DirectOrdersContainer
              key={key}
              orderStatus={orderStatus}
              selectedDate={selectedDate}
            />
          </Route>
          <Route path={`${path}/demco-product-orders/:status`}>
            <DemcoProductOrdersContainer
              key={key}
              orderStatus={orderStatus}
              selectedDate={selectedDate}
            />
          </Route>
          <Route path={`${path}/customized-orders/:status`}>
            <CustomizedOrdersContainer
              key={key}
              orderStatus={orderStatus}
              selectedDate={selectedDate}
            />
          </Route>
          <Route>
            <h1>Page Not Found</h1>
          </Route>
        </Switch>
      )}
    </div>
  );
}
