// import "../sass/MyCollection.scss";
import "../sass/DirectOrder.scss";
import BottomDrawer from "../components/BottomDrawer/BottomDrawer";
import { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { getAllFolders } from "../actions/folders-actions/actions";
import { AnimatePresence } from "framer-motion";
import Head from "../components/Head";
import MyCollectionContent2 from "../components/MyCollectionContent/MyCollectionContent2";

export interface IMyCollectionProps {}

export default function MyCollection2(props: IMyCollectionProps) {
  const dispatch = useDispatch();
  const folders = useSelector((state: RootStateOrAny) => state.folders);
  const drawers = useSelector((state: RootStateOrAny) => state.drawers);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  useEffect(() => {
    if (folders.oneFolder) {
    }
  }, [folders.oneFolder]);
  useEffect(() => {
    if (!folders.folders) {
      dispatch(getAllFolders());
    }
  }, [folders.oneFolder]);
  return (
    <div className="my_collection_container container-fluid">
      <Head title="My Collections" />
      <AnimatePresence exitBeforeEnter={false}>
        <MyCollectionContent2 />
        {drawers.openBottom && (
          <BottomDrawer folders={folders} mode="add_folder_collection" />
        )}
      </AnimatePresence>
    </div>
  );
}
