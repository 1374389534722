import axiosInstance from "../../config/axios-instance";

const getMySelection = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: "/selections",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const addProductToSelection = (idProduct: any) => {
  let token = localStorage.getItem("token");

  return axiosInstance({
    method: "post",
    url: `/selections/add/${idProduct}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const removeProductFromSelection = (idProduct: any) => {
  let token = localStorage.getItem("token");

  return axiosInstance({
    method: "post",
    url: `/selections/remove/${idProduct}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const FoldersServices = {
  getMySelection,
  addProductToSelection,
  removeProductFromSelection,
};

export default FoldersServices;
