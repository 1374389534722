import { Button } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import Jeans from "../../icons/Jeans";

export default function DemcoProductOrderContentRow({ item, url, data }: any) {
  const genStatusMsg = (status_name: string) => {
    switch (status_name) {
      case "PROTO":
        return {
          color: "#BCBCBD",
          msg: "Your prototype is being prepared",
        };
      case "PRODUCTION":
      case "OK PROD":
        return {
          msg: "Your order is in process",
          color: "#06C170",
        };
      case "CANCELLED":
        return { color: "#FF3A3A", msg: "Your order is canceled" };
      default:
        return {
          color: "#FF872A",
          msg: "We are waiting for your answer",
        };
    }
  };

  return (
    <tr>
      <td scope="row">
        <Jeans />
        <span className="ml-2 myorder_order_name">
          {item.code ? item.code + " " + item.name : item.name}
        </span>
      </td>
      <td>{moment(data.updated_at).format("DD/MM/YYYY")}</td>
      <td>
        <Link to={`${url}/${item.id}/progress`}>
          <Button
            style={{
              color:
                item && item.status.name === "PROTO" ? "#7C7C7C" : "#00468C",
              background:
                item && item.status.name === "PROTO"
                  ? "#E0E0E0"
                  : "rgba(0, 70, 140, 0.2)",
            }}
            className="status_btn_order"
          >
            {item.status.name}
          </Button>
        </Link>
      </td>
      <td>
        <span
          style={{
            color: item && genStatusMsg(item.status.name).color,
          }}
          className="status_msg"
        >
          {item && genStatusMsg(item.status.name).msg}
        </span>
      </td>
    </tr>
  );
}
