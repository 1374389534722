import React, { useState, useEffect, useRef } from "react";
import "./ProductCard.scss";
import customizeIcon from "../../img/customize_icon.svg";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import placeholder from "../../img/img_placeholder.svg";
import sustainableLogo from "../../img/sustainableLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import {
  addProductToSelection,
  removeProductFromSelection,
} from "../../actions/myselection-actions/actions";
import cursor_grab from "../../img/cursor_grab.png";
import { Checkbox } from "antd";
import {
  addProductToSelected,
  clearSelectedFolders,
} from "../../actions/folders-actions/actions";
import { Link } from "react-router-dom";

type cardProps = {
  productPage?: boolean;
  newProduct?: boolean;
  // isGreen?: boolean;
  product?: any;
  item: {
    main: string;
    onHover: string;
  };
  changeCurrentProduct?: (id: any) => void;
  setsubFolderId?: (p: any) => void;
  setMovingProduct?: (p: any) => void;
  selectable?: boolean;
  index: number;
};

export const ProductCard: React.FC<cardProps> = ({
  item,
  productPage = false,
  newProduct = false,
  // isGreen = false,
  product,

  index,
  selectable,
}) => {
  const selection = useSelector((state: any) => state.selection);
  const folders = useSelector((state: any) => state.folders);
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [checkedCheck, setCheckedCheck] = useState(false);
  const rightLine: any = useRef();
  const imgProd = () => {
    if (productPage) {
      if (hovered && item.main && item.onHover) {
        return process.env.REACT_APP_STORAGE_URL + item.onHover;
      } else if (hovered && item.main && !item.onHover) {
        return process.env.REACT_APP_STORAGE_URL + item.main;
      } else if (hovered && !item.main && !item.onHover) {
        return placeholder;
      } else if (!hovered && !item.main && !item.onHover) {
        return placeholder;
      } else {
        return process.env.REACT_APP_STORAGE_URL + item.main;
      }
    } else {
      if (!hovered) {
        return item.main;
      } else {
        return item.onHover;
      }
    }
  };

  // useEffect(() => {})

  useEffect(() => {
    if (rightLine.current) {
      setWidth(parseInt(rightLine.current.offsetWidth));
      setHeight(parseInt(rightLine.current.offsetHeight));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (rightLine.current) {
        setWidth(parseInt(rightLine.current.offsetWidth));
        setHeight(parseInt(rightLine.current.offsetHeight));
      }
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [width, height]);

  // COLOR STROKE
  const colorStroke = () => {
    let sustain = product.labels.find((el: any) => el.name === "SUSTAINABLE");
    let newLabel = product.labels.find((el: any) => el.name === "NEW");
    let favorite = product.labels.find(
      (el: any) => el.name === "DEMCO'S FAVORITE"
    );
    if (product.labels && newLabel) {
      return "#00468c";
    } else if (product.labels && sustain) {
      return "#9A9C44";
    } else if (product.labels && favorite) {
      return "#9A3996";
    }
  };

  const addProductToSelectedProducts = (idProduct: any) => {
    dispatch(addProductToSelected(idProduct));
    setCheckedCheck(!checkedCheck);
  };

  const handleChange = () => {
    dispatch(clearSelectedFolders());
  };
  useEffect(() => {
    if (productPage) {
      if (folders.selectedProducts.includes(product.id)) {
        setCheckedCheck(true);
      } else {
        setCheckedCheck(false);
      }
    }
  }, [folders.selectedProducts]);

  return (
    <div>
      <div
        className={productPage ? "product_card_page" : "product_card"}
        style={{
          backgroundImage: `url(${imgProd()})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          cursor: `url("${cursor_grab}"),auto !important`,
        }}
        ref={rightLine}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}

        //onDragStart={(e) => handletDragStart(e, { grpI: 0, itemI: product.id })}
      >
        {selectable && (
          <div className="checkbox_product_container">
            <Checkbox
              checked={checkedCheck}
              onClick={() => addProductToSelectedProducts(product)}
              onChange={handleChange}
            ></Checkbox>
          </div>
        )}

        <div className="border_line">
          <svg width="200" height="200">
            {productPage &&
            product.labels &&
            product.labels.find((el: any) => el.name === "NEW") ? (
              <>
                <line
                  className="top"
                  x1="1290"
                  y1="0"
                  x2="0"
                  y2="0"
                  stroke="#00468c"
                />
                <line
                  className="left"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="-2094"
                  stroke="#00468c"
                />
              </>
            ) : (
              productPage &&
              product.labels &&
              product.labels.find((el: any) => el.name === "SUSTAINABLE") && (
                <>
                  <line
                    className="top"
                    x1="1290"
                    y1="0"
                    x2="0"
                    y2="0"
                    stroke="#9a9c44"
                  />
                  <line
                    className="left"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="-2094"
                    stroke="#9a9c44"
                  />
                </>
              )
            )}
            {productPage &&
              product.labels.length &&
              product.labels.length < 4 && (
                <line
                  className="bottom"
                  x1="0"
                  y1={height}
                  x2="-1290"
                  y2={height}
                  stroke={colorStroke()}
                />
              )}
            {productPage &&
            product.labels &&
            product.labels.find((el: any) => el.name === "NEW") ? (
              <line
                className="right"
                // x1="430"
                x1={width}
                y1="1396"
                // x2="430"
                x2={width}
                // y2="698"
                y2={height}
                stroke="#00468c"
              />
            ) : (
              productPage &&
              product.labels &&
              product.labels.find((el: any) => el.name === "SUSTAINABLE") && (
                <line
                  className="right"
                  // x1="430"
                  x1={width}
                  y1="1396"
                  // x2="430"
                  x2={width}
                  // y2="698"
                  y2={height}
                  stroke="#9a9c44"
                />
              )
            )}
          </svg>
        </div>
        {productPage && user && !isAdmin && (
          <div className="product_card_icons">
            {/* do not delete !!! */}
            {/* <Link
              to={`/customization/new/${product.id}`}
              className="customize_icon"
            >
              <img src={customizeIcon} alt="customize product icon" />
            </Link> */}
            {selection.selectionProducts &&
            selection.selectionProducts.some(
              (el: any) => el.id === product.id
            ) ? (
              <FontAwesomeIcon
                icon={faHeart}
                className="favorite_icon favorite_icon_red"
                onClick={(event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                  // event.nativeEvent.stopImmediatePropagation();
                  dispatch(removeProductFromSelection(product.id));
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={farHeart}
                className="favorite_icon"
                onClick={(event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                  // event.nativeEvent.stopImmediatePropagation();
                  dispatch(addProductToSelection(product.id));
                }}
              />
            )}
          </div>
        )}
        {productPage &&
          product.labels &&
          product.labels.find((el: any) => el.name === "NEW") && (
            <div className="new_product_label">
              <label>NEW</label>
            </div>
          )}
      </div>
      <div className="product_card_footer">
        <div>
          <div className="labels_list">
            {productPage &&
              product.labels &&
              product.labels.map((el: any, i: number) =>
                el.name === "SUSTAINABLE" ? (
                  <div className="sustain_product_label" key={i}>
                    <img src={sustainableLogo} alt="sustainable" />
                    <label>{el.name}</label>
                  </div>
                ) : !product.labels.find(
                    (el: any) => el.name === "SUSTAINABLE"
                  ) &&
                  !product.labels.find((el: any) => el.name === "NEW") &&
                  product.favorite_by ? (
                  <div className="favorite_product_label" key={i}>
                    {/* <img src={sustainableLogo} alt="sustainable" /> */}
                    <p className="favorite_by">
                      {product.favorite_by.name}' favorite
                    </p>
                    {/* <label>{el.name}</label> */}
                  </div>
                ) : // : el.name === "DEMCO'S FAVORITE" ? (
                //     <div className="demco_product_label" key={i}>
                //       <img src={demcoFavLogo} alt="Demco's favorite" />
                //       <label>{el.name}</label>
                //     </div>
                //   )
                null
              )}
          </div>
          {productPage && product && (
            <>
              <h4 className="h4_medium mb-0 mt-2">{product.code}</h4>
              <h4 className="h4_medium" style={{ fontWeight: 400 }}>
                {product.name}
              </h4>
            </>
          )}
        </div>

        {productPage &&
          product &&
          product.favorite_by &&
          product.favorite_by.avatar && (
            <img
              src={
                process.env.REACT_APP_STORAGE_URL + product.favorite_by.avatar
              }
              className="favorite_by_img"
            />
          )}
      </div>
    </div>
  );
};
