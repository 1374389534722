import {Button, Card, Col, Popconfirm, Row, Spin, Tabs, Tooltip} from "antd";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import LazyImg from "../components/Library/LazyImage";
import axiosInstance from "../config/axios-instance";
import bg_image from "../img/bg_library.jpg";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { history } from "../index";
import { useSelector } from "react-redux";
import {DeleteOutlined, EditOutlined, LoadingOutlined} from "@ant-design/icons";
import useWindowWidth from "../config/useWindowWidth";
import Meta from "antd/lib/card/Meta";
import watermak from "../img/watermark.png";
import placeholder_jeans from "../img/placeholder_jeans.svg";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "../sass/Library.scss";
import React, { useEffect } from "react";
import { Spinner } from "../components/Spinner";
import {v4 as uuidv4} from "uuid";
const { TabPane } = Tabs;

export default function LibraryItem() {
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const { id }: any = useParams();

  const { isLoading, data }: any = useQuery(["fabric", id], () => fetchType());

  const { isLoadingSameFabric, dataSameFabric }: any = useQuery(["sameFabric", id], () => fetchSameFabric());
  const params: any = useParams();

  useEffect(() => {
    // You can call your Laravel API to increment the product view counter here

    const trackVisit = async () => {
      try {
        /*    await axiosInstance.post('/products/track-visit', {
                      product_id: params.productId
                    });*/

        console.log(params.id);
        let token = localStorage.getItem("token");
        return axiosInstance({
          method: "post",
          url: `fabrics/track-visit`,
          data: {
            fabric_id: params.id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error("Error tracking visit", error);
      }
    };

    trackVisit();
  }, []);

  const fetchType = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/fabrics/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };


  const fetchSameFabric = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/same-name/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  useEffect(() => {
    const slider = document.getElementById("slider_products");
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    /*   slider?.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider?.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });

    slider?.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
 */
    /*  slider?.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1.5; // scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    }); */

    // Wheel Action
    slider?.addEventListener("wheel", (e) => {
      e.preventDefault();
      slider.scrollLeft += e.deltaY * 3;
    });
  }, []);

  return (

    /*     <div className="bookings-page">
      <header
        style={{
          backgroundImage: `url(${bg_image})`,
          height: 400,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Button
          style={{
            color: "#00468c",
            border: "1px solid #00468c",
            borderRadius: 5,
            position: "absolute",
            top: "22%",
          }}
          className="px-4 ml-5"
          icon={
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="mr-2"
              style={{ color: "#00468c" }}
            />
          }
          onClick={() => history.back()}
        >
          Back
        </Button>
      </header>
      <div className="fabric-details-container">
        {isLoading ? (
          <div className="d-flex align-items-center justify-content-center">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />}
            />
          </div>
        ) : Object.keys(data.data).length === 0 ? (
          <h1 className="h1_huge text-center p-2">
            Something is wrong with this fabric
          </h1>
        ) : (
          <div className="fabric-details-wrapper p-3">
            <div
              style={{ width: "fit-content", marginTop: "-125px" }}
              className="d-flex flex-column"
            >
              <Tabs defaultActiveKey="1" tabPosition="bottom">
                {data.data.images.map((img: any) => (
                  <TabPane
                    key={img.id}
                    tab={
                      <div className="tab-img">
                        <LazyImg
                          src={process.env.REACT_APP_STORAGE_URL + img.image}
                          style={{
                            width: "40px",
                            height: "40px",
                            border: "3px solid white",
                            objectFit: "cover",
                            borderRadius: "2px",
                          }}
                        />
                      </div>
                    }
                  >
                    <div className="image-view">
                      <LazyImg
                        key={img.id}
                        src={process.env.REACT_APP_STORAGE_URL + img.image}
                        style={{
                          width: "100%",
                          maxWidth: "300px",
                          height: "100%",
                          mexHeight: "300px",
                          border: "10px solid white",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </TabPane>
                ))}
              </Tabs>
            </div>

            <div className="col-4" style={{ marginLeft: "-3%" }}>
              <p className=" fabric-item-name mt-2 mb-1">{data.data.name}</p>
              <div className="">{data.data.description}</div>
            </div>

            <table className="fabric-details-table mb-3">
              {data?.data.fabric_mill && (
                <tr>
                  <th style={{ verticalAlign: "middle" }}>Fabric Mill</th>
                  <td>{data?.data.fabric_mill.name}</td>
                </tr>
              )}
              <tr>
                <td colSpan={2} className="text-center">
                  {data?.data.fabric_colors.map((el: any) => {
                    return (
                      <>
                        <Tooltip title={el.fabric_color?.name}>
                          <Link to={`/library/${el.id}`}>
                            <img
                              src={
                                process.env.REACT_APP_STORAGE_URL +
                                el.fabric_color?.logo
                              }
                              style={{
                                width: "40px",
                                height: "40px",
                                border: "3px solid white",
                                objectFit: "cover",
                                borderRadius: "2px",
                              }}
                            />
                          </Link>
                        </Tooltip>
                      </>
                    );
                  })}
                </td>
              </tr>

              {data?.data.fabric_color?.name && (
                <tr>
                  <th style={{ verticalAlign: "middle" }}> Fabric Color</th>
                  <td>{data?.data.fabric_color?.name}</td>
                </tr>
              )}

              {data?.data.fabric_types?.name && (
                <tr>
                  <th style={{ verticalAlign: "middle" }}> Fabric Type</th>
                  <td>{data?.data.fabric_types?.name}</td>
                </tr>
              )}

              {data?.data.width && (
                <tr>
                  <th style={{ verticalAlign: "middle" }}> Width</th>
                  <td>{data?.data.width}</td>
                </tr>
              )}
              {data?.data.composition_value && (
                <tr>
                  <th style={{ verticalAlign: "middle" }}>Composition</th>
                  <td style={{ verticalAlign: "middle" }}>
                    {data?.data.composition_value}
                  </td>
                </tr>
              )}
              {data && data.data.fabric_type === "denim" && (
                <>
                  {data?.data.elasticity_value && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>Elasticity</th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.elasticity_value}
                      </td>
                    </tr>
                  )}
                </>
              )}

              {data?.data.weight_value && (
                <tr>
                  <th style={{ verticalAlign: "middle" }}>Weight</th>
                  <td style={{ verticalAlign: "middle" }}>
                    {data?.data.weight_value}
                  </td>
                </tr>
              )}

              {data && data.data.fabric_type === "denim" && (
                <>
                  {data?.data.growth && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>Growth</th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.growth}
                      </td>
                    </tr>
                  )}
                  {data?.data.retrait_warp_max && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>
                        Shrinkage Warp
                      </th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.retrait_warp_max}
                      </td>
                    </tr>
                  )}
                  {data?.data.retrait_weft_max && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>
                        Shrinkage Weft
                      </th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.retrait_weft_max}
                      </td>
                    </tr>
                  )}

                  {data?.data.tearing_warp && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>Tearing Warp</th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.tearing_warp}
                      </td>
                    </tr>
                  )}

                  {data?.data.tearing_weft && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>Tearing Weft</th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.tearing_weft}
                      </td>
                    </tr>
                  )}

                  {data?.data.price && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>Price</th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.price}
                      </td>
                    </tr>
                  )}

                  {data?.data.price_150 && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>
                        Washed price (/150)
                      </th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.price_150}
                      </td>
                    </tr>
                  )}
                </>
              )}

              {data && data.data.fabric_type === "knit" && (
                <>
                  {data?.data.price_kg && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>Price/kg</th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.price_kg}
                      </td>
                    </tr>
                  )}
                  {data?.data.price_metre && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>Price/metre</th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.price_metre}
                      </td>
                    </tr>
                  )}
                  {data?.data.price_bci && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>
                        Price BCI Cotton
                      </th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.price_bci}
                      </td>
                    </tr>
                  )}

                  {data?.data.price_organic && (
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>
                        Price Organic cotton
                      </th>
                      <td style={{ verticalAlign: "middle" }}>
                        {data?.data.price_organic}
                      </td>
                    </tr>
                  )}
                </>
              )}
            </table>
          </div>
        )}

        <div className="p-5">
          <div className="row">
            {data?.data.products.map((product: any) => {
              return (
                <>
                  <ProductCard
                    product={{
                      front: product.front_image ? product.front_image : "",
                      back: product.back_image ? product.back_image : "",
                      name: product.name,
                      code: product.code,
                      id: product.id,
                    }}
                  />
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div> */

    <div className="products_page_container">
      <div className={`container-fluid `}>
        <div style={{ padding: "25px" }}>
          {isLoading ? (
            <div className="d-flex align-items-center justify-content-center">
              {/*<Spin
                indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />}
              />*/}
              <Spinner></Spinner>
            </div>
          ) : Object.keys(data.data).length === 0 ? (
            <h1 className="h1_huge text-center p-2">
              Something is wrong with this fabric
            </h1>
          ) : (
            <div className="fabric-details-wrapper">
              <div className="d-flex flex-column width-image-fabric">
                <Tabs
                  defaultActiveKey="1"
                  tabPosition="bottom"
                  style={{
                    border: "1px solid #CDD5DF ",
                    padding: "24px",
                    borderRadius: "8px",
                  }}
                  id={"tab-image"}
                >
                  {data.data.images.map((img: any) => (
                    <TabPane
                      key={img.id}
                      tab={
                        <div className="tab-img">
                          <LazyImg
                            src={process.env.REACT_APP_STORAGE_URL + img.image}
                            style={{
                              width: "50px",
                              height: "50px",
                              // border: "3px solid white",
                              objectFit: "cover",
                              borderRadius: "2px",
                            }}
                          />
                        </div>
                      }
                    >
                      <div className="image-view">
                        <LazyImg
                          key={img.id}
                          src={process.env.REACT_APP_STORAGE_URL + img.image}
                          style={{
                            width: "100%",
                            // maxWidth: "400px",
                            height: "100%",
                            mexHeight: "400px",
                            // border: "10px solid white",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                      </div>
                    </TabPane>
                  ))}
                </Tabs>
              </div>

              {data && data.data.fabric_type === "denim" && (
                <>
                  <div className={"width-description-fabric"}>
                    <h1 style={{}} className={"title_fabric"}>
                      {data?.data.name}
                    </h1>
                    <h3 className={"title_fabric_sup"}>
                      {data?.data.fabric_mill?.name} -{" "}
                      <span>{data?.data?.color}</span>{" "}
                    </h3>
                    <div
                      className="paragraphe"
                      style={{ paddingBottom: "32px" }}
                    >
                      {data.data.description}
                    </div>
                    <div style={{ paddingBottom: "32px" }}>
                      <h5
                        style={{ paddingBottom: "8px" }}
                        className={"title_details"}
                      >
                        Other Fabric colours available
                      </h5>
                      {data?.data.other_fabrics.map((el: any) => {
                        return (
                          <>
                            <Tooltip title={el?.color}>
                              <Link to={`/library/${el.id}`}>
                                <span>
                                  <img
                                    src={
                                      process.env.REACT_APP_STORAGE_URL +
                                      el.images[0]?.image
                                    }
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                      border: "3px solid white",
                                      objectFit: "cover",
                                      borderRadius: "8px",
                                    }}
                                  />
                                </span>
                              </Link>
                            </Tooltip>
                          </>
                        );
                      })}
                    </div>
                    <h5 className={"title_details"}>Details</h5>

                    <table className="fabric-details-table mb-3 mt-3 ">
                      <tr>
                        <td
                          style={{
                            verticalAlign: "middle",
                            position: "relative",
                          }}
                        >
                          {" "}
                          <span style={{ fontWeight: 500 }}>
                            {" "}
                            Fabric Type :{" "}
                          </span>
                          <span style={{ left: "25%" }}>
                            {data?.data.fabric_types?.name}
                          </span>
                        </td>
                        {data?.data.width && (
                          <td style={{ verticalAlign: "middle" }}>
                            <span style={{ fontWeight: 500 }}> Width : </span>
                            {data?.data.width} Cm
                          </td>
                        )}
                      </tr>

                      <tr>
                        <td style={{ verticalAlign: "middle" }}>
                          <span style={{ fontWeight: 500 }}>
                            {" "}
                            Composition :{" "}
                          </span>
                          {data?.data.composition_value}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {data && data.data.fabric_type === "denim" && (
                            <>
                              {" "}
                              <span style={{ fontWeight: 500 }}>
                                Elasticity :{" "}
                              </span>
                              {data?.data.elasticity_value} %
                            </>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td style={{ verticalAlign: "middle" }}>
                          <span style={{ fontWeight: 500 }}> Weight :</span>{" "}
                          {data?.data.weight_value} Oz
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <span style={{ fontWeight: 500 }}> Growth : </span>{" "}
                          {data?.data.growth} %
                        </td>
                      </tr>

                      <tr>
                        <td style={{ verticalAlign: "middle" }}>
                          <span style={{ fontWeight: 500 }}>
                            {" "}
                            Shrinkage Warp :{" "}
                          </span>
                          {data?.data.retrait_warp_max} %
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <span style={{ fontWeight: 500 }}>
                            {" "}
                            Shrinkage Weft :{" "}
                          </span>
                          {data?.data.retrait_weft_max} %
                        </td>
                      </tr>

                      <tr>
                        <td style={{ verticalAlign: "middle" }}>
                          <span style={{ fontWeight: 500 }}>
                            {" "}
                            Tearing Warp :{" "}
                          </span>
                          {data?.data.tearing_warp} %
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <span style={{ fontWeight: 500 }}>
                            {" "}
                            Tearing Weft :{" "}
                          </span>{" "}
                          {data?.data.tearing_weft} %
                        </td>
                      </tr>
                      {!user?.is_client_clients && (
                        <>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>
                              <span style={{ fontWeight: 500 }}> Price :</span>{" "}
                              {data?.data.price} €
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <span style={{ fontWeight: 500 }}>
                                {" "}
                                Washed price (/150) :{" "}
                              </span>
                              {data?.data.price_150} €
                            </td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td
                          colSpan={2}
                          style={{ border: "none" }}
                          className="product_in_that_fabric"
                        ></td>
                      </tr>
                    </table>
                    {(data?.data?.products.length >0) && (
                        <>
                    <span className="mb-3">
                      <h5
                        style={{
                          paddingBottom: "8px",
                          paddingTop: "32px",
                        }}
                        className={"title_details prod_that_fabric"}
                      >
                        PRODUCTS IN THAT FABRIC
                      </h5>
                    </span>
                    <div
                      id="slider_products"
                      style={{
                        maxWidth: "900px",
                        height: "400px",
                        display: "flex",
                        overflowX: "scroll",
                        scrollBehavior: "smooth",
                      }}
                    >
                      {data?.data?.products.map((product: any, i: any) => {
                        return (
                          <>
                            <div className="item">
                              <Link
                                to={`/product/details/${product.name}/${product.id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <ProductCard
                                  product={{
                                    front: product.front_image
                                      ? product.front_image
                                      : "",
                                    back: product.back_image
                                      ? product.back_image
                                      : "",
                                    name: product.name,
                                    code: product.code,
                                    id: product.id,
                                  }}
                                />
                              </Link>
                            </div>
                          </>
                        );
                      })}
                    </div>
                 </>
                  )}


                    {(data?.data?.same_fabric.length >0) && (
                        <>
                    <span className="mb-3">
                      <h5
                          style={{
                            paddingBottom: "8px",
                            paddingTop: "32px",
                          }}
                          className={"title_details prod_that_fabric"}
                      >
                        SIMILAR FABRIC
                      </h5>
                    </span>


                          <Row gutter={15}>
                            {data?.data?.same_fabric.map((item: any, index: any) => (
                                <Col
                                    className="gutter-row"
                                    key={uuidv4()}
                                    xs={12}
                                    sm={8}
                                    lg={6}
                                    xl={4}
                                    style={{ marginTop: 15 }}
                                >
                                  <Card
                                      style={{ cursor: "pointer" }}
                                      bodyStyle={{ padding: "15px 0" }}
                                      className="library-card"
                                      bordered={false}
                                      key={uuidv4()}
                                      cover={
                                        <LazyImg
                                            alt={item.id}
                                            src={
                                              item.images[0]?.image
                                                  ? process.env.REACT_APP_STORAGE_URL +
                                                  item.images[0]?.image
                                                  : null
                                            }
                                            thumb={
                                              item.images[0]?.image
                                                  ? process.env.REACT_APP_STORAGE_URL +
                                                  item.images[0]?.image
                                                  : null
                                            }
                                            style={{
                                              objectFit: "cover",
                                              borderRadius: 0,
                                              height: 263,
                                            }}
                                        />
                                      }

                                  >
                                    <Meta
                                        title={
                                          <Link
                                              to={`/library/${item.id}`}
                                              className="fabric-name-link"
                                          >
                                            {item.name}
                                          </Link>
                                        }
                                    />
                                  </Card>
                                </Col>
                            ))}
                          </Row>


                        </>
                    )}
                    {/*  <table className="fabric-details-table mb-3 mt-3 ">
                          <tr>
                              <td
                                  style={{
                                      verticalAlign: "middle",
                                      position: "relative",
                                  }}
                              >
                                  {" "}
                                  <span style={{fontWeight: "bold"}}>
                            {" "}
                                      Fabric Type :{" "}
                          </span>
                                  <span style={{left: "25%"}}>
                            {data?.data.fabric_types?.name}
                          </span>
                              </td>
                              {data?.data.width && (
                                  <td style={{verticalAlign: "middle"}}>
                            <span style={{fontWeight: "bold"}}>
                              {" "}
                                Width :{" "}
                            </span>
                                      {data?.data.width}
                                  </td>
                              )}
                          </tr>

                          <tr>
                              <td style={{verticalAlign: "middle",}}>
                          <span style={{fontWeight: "bold"}}>
                            {" "}
                              Composition :{" "}
                          </span>
                                  {data?.data.composition_value}
                              </td>
                              <td style={{verticalAlign: "middle",}}>
                                  {data && data.data.fabric_type === "denim" && (
                                      <>
                                          {" "}
                                          <span style={{fontWeight: "bold"}}>
                                Elasticity :{" "}
                              </span>
                                          {data?.data.elasticity_value}
                                      </>
                                  )}
                              </td>
                          </tr>

                          <tr>
                              <td style={{verticalAlign: "middle",}}>
                                  <span style={{fontWeight: "bold"}}> Weight :</span>{" "}
                                  {data?.data.weight_value}
                              </td>
                              <td style={{verticalAlign: "middle",}}>
                                  <span style={{fontWeight: "bold"}}> Growth : </span>{" "}
                                  {data?.data.growth}
                              </td>
                          </tr>

                          <tr>
                              <td style={{verticalAlign: "middle",}}>
                          <span style={{fontWeight: "bold"}}>
                            {" "}
                              Shrinkage Warp :{" "}
                          </span>
                                  {data?.data.retrait_warp_max}
                              </td>
                              <td style={{verticalAlign: "middle",}}>
                          <span style={{fontWeight: "bold"}}>
                            {" "}
                              Shrinkage Weft :{" "}
                          </span>
                              </td>
                          </tr>

                          <tr>
                              <td style={{verticalAlign: "middle",}}>
                          <span style={{fontWeight: "bold"}}>
                            {" "}
                              Tearing Warp :{" "}
                          </span>
                                  {data?.data.tearing_warp}
                              </td>
                              <td style={{verticalAlign: "middle",}}>
                          <span style={{fontWeight: "bold"}}>
                            {" "}
                              Tearing Weft :{" "}
                          </span>{" "}
                                  {data?.data.tearing_weft}
                              </td>
                          </tr>

                          <tr>
                              <td style={{verticalAlign: "middle"}}>
                                  <span style={{fontWeight: "bold"}}> Price :</span>{" "}
                                  {data?.data.price}
                              </td>
                              <td style={{verticalAlign: "middle"}}>
                          <span style={{fontWeight: "bold"}}>
                            {" "}
                              Washed price (/150) :{" "}
                          </span>
                                  {data?.data.price_150}
                              </td>
                          </tr>
                          <tr>
                              <td
                                  colSpan={2}
                                  style={{border: "none"}}
                                  className="product_in_that_fabric"
                              >
                          <span className="mb-3">
                            <h5
                                style={{
                                    paddingBottom: "8px",
                                    paddingTop: "32px",
                                }}
                                className={"title_details"}
                            >
                              PRODUCTS IN THAT FABRIC
                            </h5>
                          </span>
                                  <div
                                      id="slider_products"
                                      style={{
                                          width: "1000px",
                                          height: "400px",
                                          display: "flex",
                                          overflowX: "scroll",
                                          scrollBehavior: "smooth",
                                      }}
                                  >
                                      {data?.data?.products.map(
                                          (product: any, i: any) => {
                                              return (
                                                  <>
                                                      <div className="item">
                                                          <ProductCard
                                                              product={{
                                                                  front: product.front_image
                                                                      ? product.front_image
                                                                      : "",
                                                                  back: product.back_image
                                                                      ? product.back_image
                                                                      : "",
                                                                  name: product.name,
                                                                  code: product.code,
                                                                  id: product.id,
                                                              }}
                                                          />
                                                      </div>
                                                  </>
                                              );
                                          }
                                      )}
                          </div>
                        </td>
                      </tr>
                    </table>*/}
                  </div>

                  {/* <table className="fabric-details-table mb-3">
                    {data?.data.fabric_mill && (
                      <tr>
                        <td style={{ verticalAlign: "middle",  }}>
                          Fabric Mill : {data?.data.fabric_mill.name}
                        </td>
                        <td style={{ verticalAlign: "middle",  }}>
                          Fabric Name : {data?.data.name}
                        </td>
                      </tr>
                    )}
                    {data.data.description && (
                      <tr>
                        <td colSpan={2} className="text-center">
                          <span>Fabric Description </span>
                          <div>{data.data.description}</div>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={2} className="text-center">
                        <span>Fabric Colour </span>
                        <div>{data?.data?.color}</div>
                      </td>
                    </tr>

                    {data?.data.other_fabrics && (
                      <tr>
                        <td colSpan={2} className="text-center">
                          <span>Other colours available</span>

                          <div>
                            {data?.data.other_fabrics.map((el: any) => {
                              return (
                                <>
                                  <Tooltip title={el?.color}>
                                    <Link to={`/library/${el.id}`}>
                                      <span>
                                        <img
                                          src={
                                            process.env.REACT_APP_STORAGE_URL +
                                            el.images[0]?.image
                                          }
                                          style={{
                                            width: "60px",
                                            height: "60px",
                                            border: "3px solid white",
                                            objectFit: "cover",
                                            borderRadius: "8px",
                                          }}
                                        />
                                      </span>
                                    </Link>
                                  </Tooltip>
                                </>
                              );
                            })}
                          </div>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td style={{ verticalAlign: "middle",  }}>
                        {" "}
                        Fabric Type :{data?.data.fabric_types?.name}{" "}
                      </td>
                      {data?.data.width && (
                        <td style={{ verticalAlign: "middle",  }}>
                          Width :{data?.data.width}
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td style={{ verticalAlign: "middle",  }}>
                        Composition : {data?.data.composition_value}
                      </td>
                      <td style={{ verticalAlign: "middle",  }}>
                        {data && data.data.fabric_type === "denim" && (
                          <>Elasticity :{data?.data.elasticity_value}</>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ verticalAlign: "middle",  }}>
                        Weight : {data?.data.weight_value}
                      </td>
                      <td style={{ verticalAlign: "middle",  }}>
                        Growth : {data?.data.growth}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ verticalAlign: "middle",  }}>
                        Shrinkage Warp :{data?.data.retrait_warp_max}
                      </td>
                      <td style={{ verticalAlign: "middle",  }}>
                        Shrinkage Weft :
                      </td>
                    </tr>

                    <tr>
                      <td style={{ verticalAlign: "middle",  }}>
                        Tearing Warp :{data?.data.tearing_warp}
                      </td>
                      <td style={{ verticalAlign: "middle",  }}>
                        Tearing Weft: {data?.data.tearing_weft}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ verticalAlign: "middle" }}>
                        Price : {data?.data.price}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        Washed price (/150) : {data?.data.price_150}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div
                          style={{
                            width: "800px",
                            height: "400px",
                            display: "flex",
                            overflowX: "auto",
                          }}
                        >
                          {data?.data?.products.map((product: any, i: any) => {
                            return (
                              <>
                                <div className="item">
                                  <ProductCard
                                    product={{
                                      front: product.front_image
                                        ? product.front_image
                                        : "",
                                      back: product.back_image
                                        ? product.back_image
                                        : "",
                                      name: product.name,
                                      code: product.code,
                                      id: product.id,
                                    }}
                                  />
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  </table> */}
                </>
              )}

              {data && data.data.fabric_type === "knit" && (
                <>
                  <div className={"col-auto "}>
                    <h1 className={"title_fabric"}>{data?.data.name}</h1>
                    <h3 className={"title_fabric_sup"}>
                      {data?.data.fabric_mill.name} -{" "}
                      <span>{data?.data?.color}</span>{" "}
                    </h3>
                    <div
                      className="paragraphe"
                      style={{ paddingBottom: "32px" }}
                    >
                      {data.data.description}
                    </div>
                    <div style={{ paddingBottom: "32px" }}>
                      <h5
                        style={{ paddingBottom: "8px" }}
                        className={"title_details"}
                      >
                        Other Fabric colours available
                      </h5>
                      {data?.data.other_fabrics.map((el: any) => {
                        return (
                          <>
                            <Tooltip title={el?.color}>
                              <Link to={`/library/${el.id}`}>
                                <span>
                                  <img
                                    src={
                                      process.env.REACT_APP_STORAGE_URL +
                                      el.images[0]?.image
                                    }
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                      border: "3px solid white",
                                      objectFit: "cover",
                                      borderRadius: "8px",
                                    }}
                                  />
                                </span>
                              </Link>
                            </Tooltip>
                          </>
                        );
                      })}
                    </div>
                    <h5 className={"title_details"}>Details</h5>
                    <table className="fabric-details-table mb-3 mt-3">
                      <tr>
                        <td style={{ verticalAlign: "middle" }}>
                          {" "}
                          <span style={{ fontWeight: 500 }}>
                            {" "}
                            Fabric Type :{" "}
                          </span>
                          {data?.data.fabric_types?.name}{" "}
                        </td>
                        {data?.data.width && (
                          <td style={{ verticalAlign: "middle" }}>
                            <span style={{ fontWeight: 500 }}> Width : </span>
                            {data?.data.width} Cm
                          </td>
                        )}
                      </tr>

                      <tr>
                        <td style={{ verticalAlign: "middle" }}>
                          <span style={{ fontWeight: 500 }}>
                            {" "}
                            Composition :{" "}
                          </span>
                          {data?.data.composition_value} %
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <span style={{ fontWeight: 500 }}> Weight :</span>{" "}
                          {data?.data.weight_value} Oz
                        </td>
                      </tr>
                      {!user?.is_client_clients && (
                        <>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>
                              <span style={{ fontWeight: 500 }}>
                                {" "}
                                Price/kg :{" "}
                              </span>
                              {data?.data.price_kg} €
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <span style={{ fontWeight: 500 }}>
                                {" "}
                                Price/metre :{" "}
                              </span>
                              {data?.data.price_metre} €
                            </td>
                          </tr>

                          <tr>
                            <td style={{ verticalAlign: "middle" }}>
                              <span style={{ fontWeight: 500 }}>
                                {" "}
                                Price BCI Cotton :{" "}
                              </span>{" "}
                              {data?.data.price_bci} €
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <span style={{ fontWeight: 500 }}>
                                {" "}
                                Price Organic cotton :{" "}
                              </span>
                              {data?.data.price_organic} €
                            </td>
                          </tr>
                        </>
                      )}
                      {/*   {data?.data?.products.length > 0 && (
                                <tr>
                                    <td
                                        colSpan={2}
                                        style={{border: "none"}}
                                        className="product_in_that_fabric"
                                    >
                            <span className="mb-3">
                              <h5
                                  style={{
                                      paddingBottom: "8px",
                                      paddingTop: "32px",
                                  }}
                              >
                                PRODUCTS IN THAT FABRIC
                              </h5>
                            </span>
                                        <div
                                            id="slider_products"
                                            style={{
                                                maxWidth: "900px",
                                                height: "400px",
                                                display: "flex",
                                                overflowX: "scroll",
                                                scrollBehavior: "smooth",
                                            }}
                                        >
                                            {data?.data?.products.map(
                                                (product: any, i: any) => {
                                                    return (
                                                        <>
                                                            <div className="item">
                                                                <ProductCard
                                                                    product={{
                                                                        front: product.front_image
                                                                            ? product.front_image
                                                                            : "",
                                                                        back: product.back_image
                                                                            ? product.back_image
                                                                            : "",
                                                                        name: product.name,
                                                                        code: product.code,
                                                                        id: product.id,
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            )}*/}
                    </table>

                    <span className="mb-3">
                      <h5
                        style={{
                          paddingBottom: "8px",
                          paddingTop: "32px",
                        }}
                        className={"title_details prod_that_fabric"}
                      >
                        PRODUCTS IN THAT FABRIC
                      </h5>
                    </span>
                    <div
                      id="slider_products"
                      style={{
                        maxWidth: "900px",
                        height: "400px",
                        display: "flex",
                        overflowX: "scroll",
                        scrollBehavior: "smooth",
                      }}
                    >
                      {data?.data?.products.map((product: any, i: any) => {
                        return (
                          <>
                            <div className="item">
                              <Link
                                to={`/product/details/${product.name}/${product.id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <ProductCard
                                  product={{
                                    front: product.front_image
                                      ? product.front_image
                                      : "",
                                    back: product.back_image
                                      ? product.back_image
                                      : "",
                                    name: product.name,
                                    code: product.code,
                                    id: product.id,
                                  }}
                                />
                              </Link>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              {/*   {data && data.data.fabric_type === "knit" && (
                <table className="fabric-details-table mb-3">
                  {data?.data.fabric_mill && (
                    <tr>
                      <td style={{ verticalAlign: "middle",  }}>
                        Fabric Mill : {data?.data.fabric_mill.name}
                      </td>
                      <td style={{ verticalAlign: "middle",  }}>
                        Fabric Name : {data?.data.name}
                      </td>
                    </tr>
                  )}
                  {data.data.description && (
                    <tr>
                      <td colSpan={2} className="text-center">
                        <span>Fabric Description </span>
                        <div>{data.data.description}</div>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td colSpan={2} className="text-center">
                      <span>Fabric Colour </span>
                      <div>{data?.data?.color}</div>
                    </td>
                  </tr>

                  {data?.data.other_fabrics && (
                    <tr>
                      <td colSpan={2} className="text-center">
                        <span>Other colours available</span>

                        <div>
                          {data?.data.other_fabrics.map((el: any) => {
                            return (
                              <>
                                <Tooltip title={el?.color}>
                                  <Link to={`/library/${el.id}`}>
                                    <span>
                                      <img
                                        src={
                                          process.env.REACT_APP_STORAGE_URL +
                                          el.images[0]?.image
                                        }
                                        style={{
                                          width: "60px",
                                          height: "60px",
                                          border: "3px solid white",
                                          objectFit: "cover",
                                          borderRadius: "8px",
                                        }}
                                      />
                                    </span>
                                  </Link>
                                </Tooltip>
                              </>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td style={{ verticalAlign: "middle",  }}>
                      {" "}
                      Fabric Type :{data?.data.fabric_types?.name}{" "}
                    </td>
                    {data?.data.width && (
                      <td style={{ verticalAlign: "middle",  }}>
                        Width :{data?.data.width}
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td style={{ verticalAlign: "middle",  }}>
                      Composition : {data?.data.composition_value}
                    </td>
                    <td style={{ verticalAlign: "middle",  }}>
                      {data && data.data.fabric_type === "denim" && (
                        <>Elasticity :{data?.data.elasticity_value}</>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      Price/kg : {data?.data.price_kg}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      Price/metre : {data?.data.price_metre}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      Price BCI Cotton : {data?.data.price_bci}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      Price Organic cotton : {data?.data.price_organic}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div
                        style={{
                          width: "800px",
                          height: "400px",
                          display: "flex",
                          overflowX: "auto",
                        }}
                      >
                        {data?.data?.products.map((product: any, i: any) => {
                          return (
                            <>
                              <div className="item">
                                <ProductCard
                                  product={{
                                    front: product.front_image
                                      ? product.front_image
                                      : "",
                                    back: product.back_image
                                      ? product.back_image
                                      : "",
                                    name: product.name,
                                    code: product.code,
                                    id: product.id,
                                  }}
                                />
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                </table>
              )} */}
              {/*     <table className="fabric-details-table mb-3">
                {data?.data.fabric_mill && (
                  <tr>
                    <td style={{ verticalAlign: "middle",  }}>
                      Fabric Mill : {data?.data.fabric_mill.name}
                    </td>
                    <td style={{ verticalAlign: "middle",  }}>
                      Fabric Name : {data?.data.name}
                    </td>
                  </tr>
                )}
                {data.data.description && (
                  <tr>
                    <td colSpan={2} className="text-center">
                      <span>FABRIC Description </span>
                      <div>{data.data.description}</div>
                    </td>
                  </tr>
                )}

                <tr>
                  <td colSpan={2} className="text-center">
                    <span>Fabric Colour </span>
                    <div>{data?.data?.color}</div>
                  </td>
                </tr>

                {data?.data.other_fabrics && (
                  <tr>
                    <td colSpan={2} className="text-center">
                      <span>Other colours available</span>

                      <div>
                        {data?.data.other_fabrics.map((el: any) => {
                          return (
                            <>
                              <Tooltip title={el?.color}>
                                <Link to={`/library/${el.id}`}>
                                  <span>
                                    <img
                                      src={
                                        process.env.REACT_APP_STORAGE_URL +
                                        el.images[0]?.image
                                      }
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                        border: "3px solid white",
                                        objectFit: "cover",
                                        borderRadius: "8px",
                                      }}
                                    />
                                  </span>
                                </Link>
                              </Tooltip>
                            </>
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                )}

                {data?.data.fabric_types?.name && (
                  <tr>
                    <td style={{ verticalAlign: "middle",  }}>
                      {" "}
                      Fabric Type :{data?.data.fabric_types?.name}{" "}
                    </td>
                    {data?.data.width && (
                      <td style={{ verticalAlign: "middle",  }}>
                        Width :{data?.data.width}
                      </td>
                    )}
                  </tr>
                )}

                <tr>
                  <td style={{ verticalAlign: "middle",  }}>
                    Composition : {data?.data.composition_value}
                  </td>
                  <td style={{ verticalAlign: "middle",  }}>
                    {data && data.data.fabric_type === "denim" && (
                      <>Elasticity :{data?.data.elasticity_value || "-"}</>
                    )}
                  </td>
                </tr>

                {data?.data.weight_value && (
                  <tr>
                    <th style={{ verticalAlign: "middle" }}>Weight</th>
                    <td style={{ verticalAlign: "middle" }}>
                      {data?.data.weight_value}
                    </td>
                  </tr>
                )}

                {data && data.data.fabric_type === "denim" && (
                  <>
                    {data?.data.growth && (
                      <tr>
                        <th style={{ verticalAlign: "middle" }}>Growth</th>
                        <td style={{ verticalAlign: "middle" }}>
                          {data?.data.growth}
                        </td>
                      </tr>
                    )}
                    {data?.data.retrait_warp_max && (
                      <tr>
                        <th style={{ verticalAlign: "middle" }}>
                          Shrinkage Warp
                        </th>
                        <td style={{ verticalAlign: "middle" }}>
                          {data?.data.retrait_warp_max}
                        </td>
                      </tr>
                    )}
                    {data?.data.retrait_weft_max && (
                      <tr>
                        <th style={{ verticalAlign: "middle" }}>
                          Shrinkage Weft
                        </th>
                        <td style={{ verticalAlign: "middle" }}>
                          {data?.data.retrait_weft_max}
                        </td>
                      </tr>
                    )}

                    {data?.data.tearing_warp && (
                      <tr>
                        <th style={{ verticalAlign: "middle" }}>
                          Tearing Warp
                        </th>
                        <td style={{ verticalAlign: "middle" }}>
                          {data?.data.tearing_warp}
                        </td>
                      </tr>
                    )}

                    {data?.data.tearing_weft && (
                      <tr>
                        <th style={{ verticalAlign: "middle" }}>
                          Tearing Weft
                        </th>
                        <td style={{ verticalAlign: "middle" }}>
                          {data?.data.tearing_weft}
                        </td>
                      </tr>
                    )}

                  
                  </>
                )}

                {data && data.data.fabric_type === "knit" && (
                  <>
                    {data?.data.price_kg && (
                      <tr>
                        <th style={{ verticalAlign: "middle" }}>Price/kg</th>
                        <td style={{ verticalAlign: "middle" }}>
                          {data?.data.price_kg}
                        </td>
                      </tr>
                    )}
                    {data?.data.price_metre && (
                      <tr>
                        <th style={{ verticalAlign: "middle" }}>Price/metre</th>
                        <td style={{ verticalAlign: "middle" }}>
                          {data?.data.price_metre}
                        </td>
                      </tr>
                    )}
                    {data?.data.price_bci && (
                      <tr>
                        <th style={{ verticalAlign: "middle" }}>
                          Price BCI Cotton
                        </th>
                        <td style={{ verticalAlign: "middle" }}>
                          {data?.data.price_bci}
                        </td>
                      </tr>
                    )}

                    {data?.data.price_organic && (
                      <tr>
                        <th style={{ verticalAlign: "middle" }}>
                          Price Organic cotton
                        </th>
                        <td style={{ verticalAlign: "middle" }}>
                          {data?.data.price_organic}
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </table> */}
            </div>
          )}

          <div className="p-5">
            <div className="row"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ProductCard = ({ product }: any) => {
  const width = useWindowWidth();
  return (
    <Card
      style={{
        width: "170px",
        height: "100%",
        paddingTop: "15",
        borderRadius: "16px",
        margin: "0 15px 14px",
      }}
      bordered={true}
      // bodyStyle={{ padding: "0px" }}
      className="grid-item-product"
      cover={
        <div className={`product_item_new mb-2`}>
          <LazyImg
            alt={product.name}
            style={{
              position: "absolute",
              zIndex: 2,
              opacity: 0.2,
              objectFit: "contain",
              width: "100%",
              height: "260px",
            }}
            src={watermak}
            thumb={watermak}
          />
          <LazyImg
            className="img-front"
            alt={product?.name}
            style={{
              objectFit: "contain",
              width: "100%",
              height: "260px",
            }}
            src={
              product?.front
                ? process.env.REACT_APP_STORAGE_URL + product?.front
                : placeholder_jeans
            }
            thumb={
              product?.front
                ? process.env.REACT_APP_STORAGE_URL + product?.front
                : placeholder_jeans
            }
          />
          <LazyImg
            style={{ height: "260px", objectFit: "contain" }}
            className="img-back"
            alt={product?.name}
            src={
              product?.back
                ? process.env.REACT_APP_STORAGE_URL + product?.back
                : placeholder_jeans
            }
            thumb={
              product?.back
                ? process.env.REACT_APP_STORAGE_URL + product?.back
                : placeholder_jeans
            }
          />
        </div>
      }
    >
      <Meta
        description={
          <div
            className="d-flex mt-2 justify-content-between"
            style={{ height: "60px" }}
          >
            <div className="labels_list">
              <Link
                to={`/product/details/${product.name}/${product.id}`}
                style={{ textDecoration: "none" }}
              >
                <div style={{ cursor: "pointer" }}>
                  <h4 className="h4_medium mb-0" style={{ fontSize: "1rem" }}>
                    {product?.code}
                  </h4>
                  <h4
                    className="h4_medium"
                    style={{ fontWeight: 400, fontSize: "1rem" }}
                  >
                    {product?.name}
                  </h4>
                </div>
              </Link>
            </div>
          </div>
        }
      />
    </Card>
  );
};
