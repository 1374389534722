import { Reducer, AnyAction } from "redux";
import {
  SET_ALERT,
  REMOVE_ALERT,
  CLEAR_ALL_ALERTS,
} from "../actions/alerts-actions/types";

const init_state: any = {
  alerts: [],
};

const alertsReducer: Reducer<any, AnyAction> = (
  state: any = init_state,
  action
): boolean => {
  switch (action.type) {
    case SET_ALERT:
      //@ts-ignore
      return { ...state, alerts: [...state.alerts, action.payload] };
    case REMOVE_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter(
          (alert: any) => alert.id !== action.payload
        ),
      };
    case CLEAR_ALL_ALERTS:
      return {
        ...state,
        alerts: [],
      };

    default:
      return state;
  }
};

export default alertsReducer;
