export const ADD_PRODUCT_TO_SELECTION_REQUEST =
  "ADD_PRODUCT_TO_SELECTION_REQUEST";
export const ADD_PRODUCT_TO_SELECTION_SUCCESS =
  "ADD_PRODUCT_TO_SELECTION_SUCCESS";
export const ADD_PRODUCT_TO_SELECTION_FAIL = "ADD_PRODUCT_TO_SELECTION_FAIL";

export const REMOVE_PRODUCT_FROM_SELECTION_REQUEST =
  "REMOVE_PRODUCT_FROM_SELECTION_REQUEST";
export const REMOVE_PRODUCT_FROM_SELECTION_SUCCESS =
  "REMOVE_PRODUCT_FROM_SELECTION_SUCCESS";
export const REMOVE_PRODUCT_FROM_SELECTION_FAIL =
  "REMOVE_PRODUCT_FROM_SELECTION_FAIL";

export const GET_SELECTION_REQUEST = "GET_SELECTION_REQUEST";
export const GET_SELECTION_SUCCESS = "GET_SELECTION_SUCCESS";
export const GET_SELECTION_FAIL = "GET_SELECTION_FAIL";

export const SELECT_SELECTION = "SELECT_SELECTION";
export const DESELECT_SELECTION = "DESELECT_SELECTION";

export const DIRECT_RENDER = "DIRECT_RENDER";
