export interface IPenProps {
  className?: string;
}

function Question({ color }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="Path_3409"
        data-name="Path 3409"
        d="M10-17.5A10,10,0,0,0,0-7.5a10,10,0,0,0,10,10,10,10,0,0,0,10-10A10,10,0,0,0,10-17.5ZM10,.625A8.134,8.134,0,0,1,1.875-7.5,8.134,8.134,0,0,1,10-15.625,8.134,8.134,0,0,1,18.125-7.5,8.134,8.134,0,0,1,10,.625Zm0-5a1.234,1.234,0,0,0-1.25,1.25A1.213,1.213,0,0,0,10-1.875a1.256,1.256,0,0,0,1.25-1.25A1.256,1.256,0,0,0,10-4.375ZM11.293-12.5h-2A2.718,2.718,0,0,0,6.563-9.766a.951.951,0,0,0,.938.938.951.951,0,0,0,.938-.937.837.837,0,0,1,.824-.859h2a.907.907,0,0,1,.93.859.784.784,0,0,1-.43.707L9.531-7.7a.963.963,0,0,0-.469.82v.625A.951.951,0,0,0,10-5.312a.951.951,0,0,0,.938-.937v-.078L12.7-7.422a2.787,2.787,0,0,0,1.328-2.344A2.675,2.675,0,0,0,11.293-12.5Z"
        transform="translate(0 17.5)"
        fill={color}
      />
    </svg>
  );
}

export default Question;
