import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_MORE_NOTIFICATIONS_REQUEST,
  GET_MORE_NOTIFICATIONS_SUCCESS,
  GET_MORE_NOTIFICATIONS_FAIL,
  GET_MY_NOTIF_SETTINGS_REQUEST,
  GET_MY_NOTIF_SETTINGS_SUCCESS,
  GET_MY_NOTIF_SETTINGS_FAIL,
  CHANGE_MY_NOTIF_SETTINGS_REQUEST,
  CHANGE_MY_NOTIF_SETTINGS_SUCCESS,
  CHANGE_MY_NOTIF_SETTINGS_FAIL,
  CHANGE_ONE_NOTIF_SETTINGS_REQUEST,
  CHANGE_ONE_NOTIF_SETTINGS_SUCCESS,
  CHANGE_ONE_NOTIF_SETTINGS_FAIL,
  PUSH_NEW_NOTIFICATION,
  NEW_NOTIFICATION_SEEN,
} from "../actions/notifications-actions/types";

const initState = {
  notifications: null,
  newNotificationReceived: false,
  isLoading: false,
  loadingMore: false,
  next: false,
  error: null,
  getSettingsLoading: false,
  changeAllSettingsLoading: false,
  changeOneNotifLoading: false,
  notifToUpdate: null,
  settings: null,
};

const genObjs = (payload: any) => {
  let keys = Object.keys(payload);
  console.log(payload);
  let objs: any[] = [];

  objs = keys.map((el: any) => {
    var str = "";
    switch (el) {
      case "new_products_added":
        str = "New products are added";
        break;
      case "fabrics_notitications":
        str = "New fabrics available on the Demco's library";
        break;
      case "limited_edition_product":
        str = "Limited edition product is added";
        break;
      case "new_collection_added":
        str = "A new collection is added ";
        break;
      case "new_collection_added_mail":
        str = "A new collection is added ";
        break;

      case "order_progress":
        str = "To track my order's progress";
        break;
      case "last_selection":
        str = "To check my Last Selection";
        break;
      case "direct_order_changes":
        str = "To check the Direct Order changes";
        break;
      case "direct_order_sending":
        str = "When a Direct Order is sent";
        break;
      case "collaboration_requests":
        str = "To check the collaboration requests";
        break;
      case "new_collaborators_added":
        str = "Add new collaborators request confirmed";
        break;
      case "new_collaborators_added_mail":
        str = "Add new collaborators request confirmed";
        break;
      case "creative_station_update":
        str = "Something new in the Creative Station";
        break;
      case "creative_station_update_mail":
        str = "Something new in the Creative Station";
        break;
      case "news_letter":
        str = "Newsletters by Email";
        break;
      case "new_comment":
        str = "Follow the comments on the orders ";
        break;
      case "new_comment_mail":
        str = "Follow the comments on the orders ";
        break;
      case "update_order":
        str = "Update or any changes on Order Status ";
        break;
      case "update_order_mail":
        str = "Update or any changes on Order Status ";
        break;
      case "metting_notif":
        str = "Follow meeting updates";
        break;
      case "metting_notif_mail":
        str = "Follow meeting updates";
        break;
      case "launch_order":
        str = "Launch Order";
        break;
      case "launch_order_mail":
        str = "Launch Order";
        break;
      case "responsible_choice":
        str = "Our Responsible choices selection have been updated";
        break;
      case "responsible_choice_mail":
        str = "Our Responsible choices selection have been updated";
        break;
      case "for_you":
        str = "For YOU selection have been updated ";
        break;
      case "for_you_mail":
        str = "For YOU selection have been updated ";
        break;
      case "reminder_collection":
        str = "Reminder on collection or customized products";
        break;
      case "reminder_collection_mail":
        str = "Reminder on collection or customized products";
        break;

      default:
        str = "ok";
    }

    /*    return {
      name: str,
      notif: el === "fabrics_notitications" ? "fabrics" : el,
      status: payload[el],
      status_mail: 0,
    }; */

    let newObj: any = {
      name: str,
      notif: el === "fabrics_notitications" ? "fabrics" : el,
      status: payload[el],
    };

    // Check if there is a corresponding "_mail" property
    if (payload[`${el}_mail`] !== undefined) {
      newObj.status_mail = payload[`${el}_mail`];
    }

    if ("status_mail" in newObj) {
      return newObj;
    }
  });
  console.log(objs);
  objs = objs.filter((el: any) => el !== undefined);

  return objs;
  //return objs;
};

const NotificationReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case NEW_NOTIFICATION_SEEN:
      return {
        ...state,
        newNotificationReceived: false,
      };
    case PUSH_NEW_NOTIFICATION:
      let _notifications = state.notifications ? state.notifications : [];
      return {
        ...state,
        newNotificationReceived: true,
        notifications: [action.payload, ..._notifications].slice(0, 6),
        // notifications: [action.payload, ...state.notifications],
      };
    case CHANGE_ONE_NOTIF_SETTINGS_REQUEST:
      return {
        ...state,
        changeOneNotifLoading: true,
        notifToUpdate: action.payload,
      };
    case CHANGE_ONE_NOTIF_SETTINGS_SUCCESS:
      return {
        ...state,
        changeOneNotifLoading: false,
        settings: genObjs(action.payload),
        notifToUpdate: null,
      };
    case CHANGE_MY_NOTIF_SETTINGS_REQUEST:
      return {
        ...state,
        changeAllSettingsLoading: true,
      };
    case CHANGE_MY_NOTIF_SETTINGS_SUCCESS:
      return {
        ...state,
        changeAllSettingsLoading: false,
        settings: genObjs(action.payload),
      };
    case GET_MY_NOTIF_SETTINGS_REQUEST:
      return {
        ...state,
        getSettingsLoading: true,
      };
    case GET_MY_NOTIF_SETTINGS_SUCCESS:
      return {
        ...state,
        getSettingsLoading: false,
        settings: genObjs(action.payload),
      };
    case GET_MORE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loadingMore: true,
      };
    case GET_MORE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        notifications: [...state.notifications, ...action.payload.data],
        next: action.payload.next_page_url
          ? action.payload.next_page_url
          : false,
      };
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notifications: action.payload.data,
        next: action.payload.next_page_url
          ? action.payload.next_page_url
          : false,
      };
    case GET_NOTIFICATIONS_FAIL:
    case GET_MORE_NOTIFICATIONS_FAIL:
    case GET_MY_NOTIF_SETTINGS_FAIL:
    case CHANGE_MY_NOTIF_SETTINGS_FAIL:
    case CHANGE_ONE_NOTIF_SETTINGS_FAIL:
      return {
        ...state,
        isLoading: false,
        loadingMore: false,
        getSettingsLoading: false,
        changeAllSettingsLoading: false,
        changeOneNotifLoading: false,
        notifToUpdate: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
