function Bell() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 28 28"
    >
      <path
        fill="#182844"
        d="M10.1-4.018c-.906-.973-2.6-2.437-2.6-7.232a7.4 7.4 0 00-6-7.273v-.977A1.5 1.5 0 000-21a1.5 1.5 0 00-1.5 1.5v.977a7.4 7.4 0 00-6 7.273c0 4.8-1.695 6.259-2.6 7.232A1.465 1.465 0 00-10.5-3 1.5 1.5 0 00-9-1.5H9A1.5 1.5 0 0010.5-3a1.464 1.464 0 00-.4-1.018zm-17.435.268c.995-1.311 2.082-3.484 2.087-7.473v-.027A5.25 5.25 0 010-16.5a5.25 5.25 0 015.25 5.25v.027c.002 3.989 1.09 6.162 2.085 7.473zM0 3a3 3 0 003-3h-6a3 3 0 003 3z"
        data-name="Tracé 3317"
        transform="translate(10.5 21)"
      ></path>
    </svg>
  );
}

export default Bell;
