import { Skeleton } from "antd";

const ProductSkeleton = ({ oneProduct }: any) => {
  return (
    <div className="product_details_block container-fluid">
      <div className="product_details_contanier row">
        <div className="product_images_wrapper col-6 ">
          <Skeleton.Avatar
            active={true}
            shape={"square"}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="product_details_infos_scroll col-6">
          <div className="bread_crumb_product"></div>
          <div className="product_details_infos">
            <Skeleton.Input style={{ width: 200 }} active />

            <Skeleton active />

            <div className="details">
              <div className="details_fabric">
                <div>
                  <h3 className="fabric_title">Fabric</h3>

                  <Skeleton active />
                </div>

                <table>
                  <tbody>
                    {oneProduct &&
                    oneProduct.fabric_type === "knit" &&
                    oneProduct.fabric_types.length > 0 ? (
                      <tr>
                        <th>Type of fabric</th>
                        <td>
                          <Skeleton.Input style={{ width: 200 }} active />
                        </td>
                      </tr>
                    ) : (
                      oneProduct &&
                      oneProduct.fabric_type === "denim" &&
                      oneProduct.fabric_chars.length > 0 && (
                        <tr>
                          <th>Type of fabric</th>
                          <td>
                            <Skeleton.Input style={{ width: 200 }} active />
                          </td>
                        </tr>
                      )
                    )}
                    {oneProduct && oneProduct.fabric_composition && (
                      <tr>
                        <th>Composition</th>
                        <td>
                          <Skeleton.Input style={{ width: 200 }} active />
                        </td>
                      </tr>
                    )}
                    <tr>
                      {oneProduct &&
                      oneProduct.elasticity !== null &&
                      oneProduct.fabric_type === "denim" ? (
                        <>
                          <th>Elasticity</th>
                          <td>
                            <Skeleton.Input style={{ width: 200 }} active />
                          </td>
                        </>
                      ) : (
                        oneProduct &&
                        oneProduct.fabric_type === "knit" && (
                          <>
                            <th>Colour</th>
                            <td>
                              <Skeleton.Input style={{ width: 200 }} active />
                            </td>
                          </>
                        )
                      )}
                    </tr>

                    {oneProduct &&
                    oneProduct.fabric_type === "knit" &&
                    oneProduct.fabric_types.length > 0 &&
                    oneProduct.fabric_types[0].weight ? (
                      <tr>
                        <th>Weight</th>
                        <td>
                          <Skeleton.Input style={{ width: 200 }} active />
                        </td>
                      </tr>
                    ) : (
                      oneProduct &&
                      oneProduct.fabric_type === "denim" &&
                      oneProduct.fabric_chars.length > 0 &&
                      oneProduct.fabric_chars[0].weight && (
                        <tr>
                          <th>Weight</th>
                          <td>
                            <Skeleton.Input style={{ width: 200 }} active />
                          </td>
                        </tr>
                      )
                    )}

                    {oneProduct && oneProduct.fabric_price !== 0 && (
                      <tr>
                        <th>Price</th>
                        <td>
                          <Skeleton.Input style={{ width: 200 }} active />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* {tech && oneProduct.technic_id && (
                <div className="details_wash my-4">
                  <h3 className="wash_title mb-2">Technique</h3>
                  <table style={{ maxWidth: 590 }}>
                    <tbody>
                      <tr>
                        <th>Technique 1</th>
                        <td>{tech.name}</td>
                        <td>{tech.price}€</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )} */}

              {/* DO NOT DELETE !!!! */}

              <div
                className="details_wash"
                style={
                  oneProduct &&
                  (oneProduct?.eim_Score ||
                    oneProduct?.washes?.length ||
                    oneProduct?.colors?.length > 0)
                    ? {}
                    : { display: "none" }
                }
              >
                {/* {oneProduct &&
                  (oneProduct.eim_Score || oneProduct.color_id) && ( */}
                <h3 className="wash_title mb-2">Wash</h3>
                {/* // )} */}
                <table>
                  <tbody>
                    {/* oneProduct.fabric_type === "denim" && */}
                    {oneProduct && (
                      <>
                        {oneProduct?.colors?.length > 0 && (
                          <tr>
                            <th>Colour</th>
                            <td>
                              <Skeleton.Input style={{ width: 200 }} active />
                            </td>
                          </tr>
                        )}
                        {oneProduct && oneProduct?.wash_description && (
                          <tr>
                            <th>Type of Wash / Dye</th>
                            <td>
                              <Skeleton.Input style={{ width: 200 }} active />
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                    {oneProduct && oneProduct?.eim_score && (
                      <tr>
                        <th>Eim Score</th>
                        <td>
                          <Skeleton.Input style={{ width: 200 }} active />
                        </td>
                      </tr>
                    )}
                    {/* works but waiting for prices with washes */}
                    {oneProduct &&
                      oneProduct?.washes?.length > 0 &&
                      oneProduct?.washes?.find((w: any) => w.wash_price) && (
                        <tr>
                          <th>Price </th>
                          <td>
                            <Skeleton.Input style={{ width: 200 }} active />
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              {oneProduct && oneProduct.fit && (
                <div className="details_fit">
                  <h3 className="fit_title">FIT</h3>
                  <Skeleton.Input style={{ width: 200 }} active />
                </div>
              )}
              <hr />
              <div className="total_price_container">
                <h3 className="total_price_title">TOTAL PRICE</h3>
                <Skeleton.Input style={{ width: 200 }} active />
              </div>
              {oneProduct && oneProduct.tags && oneProduct.tags.length > 0 ? (
                <div className="tags_container">
                  <h3 className="tags_title">TAGS</h3>
                  <div className="tag_list">
                    <div className="row">
                      {Array.from({ length: 3 }, (_, index) => index + 1).map(
                        (_, i) => (
                          <div className="col-12 col-md-3" key={i}>
                            <Skeleton.Input
                              style={{ width: 100, marginRight: 20 }}
                              active
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSkeleton;
