import { Select, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function DebounceSelect({ fetchOptions, isLoading, ...props }: any) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  const { user } = useSelector((state: any) => state.auth);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: any) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };
    return loadOptions;
  }, [fetchOptions]);
  return (
    <Select
      disabled={!user.permissions.includes("product.update")}
      labelInValue
      filterOption={false}
      loading={isLoading}
      onSearch={debounceFetcher}
      tagRender={({ label, onClose, closable }: any) => (
        <Tag
          color="#00468c"
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          {label}
        </Tag>
      )}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

const SimilarProductSelect = ({
  prod,
  type,
  onChangeSimilarProducts,
  isLoading,
}: any) => {
  async function fetchProductList(keyword: string) {
    return fetch(process.env.REACT_APP_API_URL + `/products?name=${keyword}`)
      .then((response) => response.json())
      .then((res) =>
        res.data.map((product: any, i: number) => ({
          label: `${product.code === null ? "" : product.code} ${product.name}`,
          value: product.id,
          key: i,
        }))
      );
  }

  return (
    <tr>
      <th>{type === "similar_products" ? "Similar Products" : "Total Look"}</th>
      <td>
        <DebounceSelect
          mode="multiple"
          value={prod[type].map((el: any, i: number) => {
            if (el.code || el.name) {
              return {
                label: `${el.code === null ? "" : el.code} ${el.name}`,
                value: el.id,
                key: i,
              };
            } else {
              return el;
            }
          })}
          placeholder={
            type === "similar_products" ? "Similar Products" : "Total Look"
          }
          isLoading={isLoading}
          fetchOptions={fetchProductList}
          onChange={(newValue: any) => {
            onChangeSimilarProducts(newValue, type);
          }}
          style={{
            width: "100%",
          }}
        />
      </td>
    </tr>
  );
};

export default SimilarProductSelect;
