import { faArrowLeft, faEdit, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Collapse } from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getOrderCollaborators, sendAccessRequest} from "../../actions/order-actions/actions";
import empty_do from "../../img/empty-do_2.png";
import React, {useEffect, useState} from "react";
import { FileLightBox } from "./FileLightBox";
import request_acess from "../../img/acess_request_2.png";
const { Panel } = Collapse;

interface Props {
  selectOrder: any;
  width: number;
  setSelectOrder?: any;
}

export const OrderDetails = ({ selectOrder, width, setSelectOrder }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [is_sent, SetisSend] = useState(false);
  const { user } = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log(user)
  }, []);
  return (
      <>

      { selectOrder.can_see===true && user.type!=="commercial"  ?
          <>
    <div className="order_content">
      <div className="order_selected_header mb-3">
        <div
          className="d-flex align-items-center order_selected_title_header"
          onClick={() => width <= 757 && setSelectOrder(null)}
        >
          {width <= 575 && (
            <button
              className="back_btn_order_details mr-3"
              onClick={() => setSelectOrder(null)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            // <FontAwesomeIcon
            //   icon={faArrowLeft}

            // />
          )}
          <h1 className="ml-md-0 mb-0 ">{selectOrder.name} </h1>
        </div>

        <a
          // to={`/edit-order/${selectOrder.id}`}
          target="_self"
          onClick={() => {
            dispatch(getOrderCollaborators(selectOrder.id));
            window.location.href = `/edit-order/${selectOrder.id}`;
          }}
        >
          <button className="edit_btn_order">
            <FontAwesomeIcon icon={faEdit} />
            {width > 575 && "Edit Order"}
          </button>
        </a>
      </div>
      {selectOrder &&  selectOrder.can_see===true &&
      selectOrder.articles &&
      selectOrder.articles.length > 0 ? (
        selectOrder.articles.map((el: any, i: number) => (
          <Collapse defaultActiveKey={selectOrder.articles[0].id} key={el.id}>
            <Panel header={el.name} key={el.id} showArrow={false}>
              {el.options &&
                el.options.map((option: any) => (
                  <div className="row border-top" key={option.id}>
                    <div className="col-12 col-lg-6 border-right p-0">
                      <div className="row">
                        <div className="col-12 p-0">
                          <p className="m-0 p-2 collapse_header border-bottom ">
                            {option.name}
                          </p>
                        </div>

                        <table style={{width: "100%",padding:"8px"}}>
                          <tr>
                            <td className={"table_details_option_td"}><strong>Style Name </strong></td>
                            <td className={"table_details_option_td"}> {option.style}</td>
                          </tr>
                          <tr>
                            <td className={"table_details_option_td"}><strong>Fabric </strong></td>
                            <td className={"table_details_option_td"}>    {option.fabric}</td>
                          </tr>
                          <tr>
                            <td className={"table_details_option_td"}><strong>Wash </strong></td>
                            <td className={"table_details_option_td"}> {option.wash}</td>
                          </tr>
                          <tr>
                            <td className={"table_details_option_td"}><strong>Quantity </strong></td>
                            <td className={"table_details_option_td"}>   {option.qty}</td>
                          </tr>

                        </table>
                   {/*     <div className="col-12 p-0 border-bottom option_details_collapse">
                          <p className="m-0 p-2">
                            <strong>Style Name: </strong>
                            {option.style}
                          </p>
                        </div>
                        <div className="col-12 p-0 border-bottom option_details_collapse">
                          <p className="m-0 p-2">
                            <strong>Fabric: </strong>
                            {option.fabric}
                          </p>
                        </div>
                        <div className="col-12 p-0 border-bottom option_details_collapse">
                          <p className="m-0 p-2">
                            <strong>Wash: </strong>
                            {option.wash}
                          </p>
                        </div>
                        <div className="col-12 p-0 border-bottom option_details_collapse">
                          <p className="m-0 p-2">
                            <strong>Trims: </strong>
                            {option.trims}
                          </p>
                        </div>
                        <div className="col-12 p-0 border-bottom option_details_collapse">
                          <p className="m-0 p-2">
                            <strong>Thread: </strong>
                            {option.thread}
                          </p>
                        </div>
                        <div className="col-12 p-0 option_details_collapse">
                          <p className="m-0 p-2">
                            <strong>Quantity: </strong>
                            {option.qty}
                          </p>
                        </div>*/}
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 p-0">
                      <div className="row">
                        <div className="col-12 p-0">
                          <p className="m-0 p-2 collapse_header bg-white border-bottom font-weight-bold">
                            Files
                          </p>
                        </div>
                        <div className="col-12 pt-3 files_wrapper">
                          <div className="row flex-wrap">
                            {[
                              "style_file",
                              "fabric_file",
                              "wash_file",
                 /*             "trims_file",
                              "thread_file",*/
                            ].map((el: any, i: number) => (
                              <FileLightBox file={option[el]} key={i} />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Panel>
          </Collapse>
        ))
      ) : (
          <>
          <div className="d-flex align-items-center justify-content-center">
            <img src={empty_do} alt="Create your first article"/><br/>


          </div>
        <h3 className="d-flex align-items-center justify-content-center mt-3">Your order is empty</h3>
          </>
      )}
    </div>
          </> :
          !selectOrder.request_waiting && user.type!=="commercial" ? <>

            <div className="empty_collection_direct_order ">
              <img src={request_acess} alt="foders image" className="mb-2 folders_image"/>

              <h2 className="collection_welcome mb-2">
                You need access to enter this collection
              </h2>

              {!is_sent ? <>

                    <button className="request_access_btn"
                            onClick={() => {
                          //    sendFolderAccessRequest(selectedOrder?.folder?.id, user.id)
                              dispatch(sendAccessRequest(selectOrder.id, user.id));
                            }}>
                      Request access
                    </button>
                  </>
                  : <>
                    <p className="paragraphe collection_welcome_description mb-5 mt-4">
                      Request sent
                    </p>
                  </>
              }


            </div>
          </> :  user.type!=="commercial" && <>


              <div className="empty_collection_direct_order ">
                <img src={request_acess} alt="foders image" className="mb-1 folders_image"/>

                <p className="paragraphe collection_welcome_description mb-5 mt-2">
                  Request sent
                </p>
              </div>
          </>}

        {user.type=="commercial" && <>
          <>
            <div className="order_content">
              <div className="order_selected_header mb-3">
                <div
                    className="d-flex align-items-center order_selected_title_header"
                    onClick={() => width <= 757 && setSelectOrder(null)}
                >
                  {width <= 575 && (
                      <button
                          className="back_btn_order_details mr-3"
                          onClick={() => setSelectOrder(null)}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </button>
                      // <FontAwesomeIcon
                      //   icon={faArrowLeft}

                      // />
                  )}
                  <h1 className="ml-md-0 mb-0 ">{selectOrder.name} </h1>
                </div>

                <a
                    // to={`/edit-order/${selectOrder.id}`}
                    target="_self"
                    onClick={() => {
                      dispatch(getOrderCollaborators(selectOrder.id));
                      window.location.href = `/edit-order/${selectOrder.id}`;
                    }}
                >
                  <button className="edit_btn_order">
                    <FontAwesomeIcon icon={faEdit} />
                    {width > 575 && "Edit Order"}
                  </button>
                </a>
              </div>
              {selectOrder &&
              selectOrder.articles &&
              selectOrder.articles.length > 0 ? (
                  selectOrder.articles.map((el: any, i: number) => (
                      <Collapse defaultActiveKey={selectOrder.articles[0].id} key={el.id}>
                        <Panel header={el.name} key={el.id} showArrow={false}>
                          {el.options &&
                              el.options.map((option: any) => (
                                  <div className="row border-top" key={option.id}>
                                    <div className="col-12 col-lg-6 border-right p-0">
                                      <div className="row">
                                        <div className="col-12 p-0">
                                          <p className="m-0 p-2 collapse_header border-bottom ">
                                            {option.name}
                                          </p>
                                        </div>

                                        <table style={{width: "100%",padding:"8px"}}>
                                          <tr>
                                            <td className={"table_details_option_td"}><strong>Style Name </strong></td>
                                            <td className={"table_details_option_td"}> {option.style}</td>
                                          </tr>
                                          <tr>
                                            <td className={"table_details_option_td"}><strong>Fabric </strong></td>
                                            <td className={"table_details_option_td"}>    {option.fabric}</td>
                                          </tr>
                                          <tr>
                                            <td className={"table_details_option_td"}><strong>Wash </strong></td>
                                            <td className={"table_details_option_td"}> {option.wash}</td>
                                          </tr>
                                          <tr>
                                            <td className={"table_details_option_td"}><strong>Quantity </strong></td>
                                            <td className={"table_details_option_td"}>   {option.qty}</td>
                                          </tr>

                                        </table>

                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-6 p-0">
                                      <div className="row">
                                        <div className="col-12 p-0">
                                          <p className="m-0 p-2 collapse_header bg-white border-bottom font-weight-bold">
                                            Files
                                          </p>
                                        </div>
                                        <div className="col-12 pt-3 files_wrapper">
                                          <div className="row flex-wrap">
                                            {[
                                              "style_file",
                                              "fabric_file",
                                              "wash_file",
                                              /*             "trims_file",
                                                           "thread_file",*/
                                            ].map((el: any, i: number) => (
                                                <FileLightBox file={option[el]} key={i} />
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              ))}
                        </Panel>
                      </Collapse>
                  ))
              ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={empty_do} alt="Create your first article" />
                  </div>
              )}
            </div>
          </>
        </>}
      </>
  );
};
