import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { handleLeftDrawer } from "../../actions/actionCreators";
import plus from "../../img/plus.svg";

interface Props {
  subSubElements: any;
  setSubDataToShow: any;
  menuchoice: any;
  gender: any;
}

export const MenuCategory = ({
  subSubElements,
  setSubDataToShow,
  menuchoice,
  gender,
}: Props) => {
  const dispatch = useDispatch();
  return subSubElements.subSubElements ? (
    <div className="sub_menu_element">
      <Link
        className="sub_menu_element_val"
        to={{ hash: "#" }}
        onClick={() => {
          setSubDataToShow(subSubElements.subSubElements);
        }}
      >
        {subSubElements.name}
      </Link>
      <img src={plus} alt="plus-image" className="plus" />
    </div>
  ) : (
    <Link
      to={`/product/${menuchoice}/${gender.name}/${gender.id}/${subSubElements.name}/${subSubElements.id}`}
      className="sub_menu_element"
      onClick={() => dispatch(handleLeftDrawer())}
    >
      {subSubElements.name}
    </Link>
  );
};
