import { Spin } from "antd";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import axiosInstance from "../../config/axios-instance";
import OrderHeader from "./OrderHeader";
import DirectOrderTableBody from "./DirectOrderTableBody";
import NormalOrderTableBody from "./NormalOrderTableBody";
import CustomizedOrderTableBody from "./CustomizedOrderTableBody";
import CustomizedOrderProducts from "./CustomizedOrderProducts";
import backArrow from "../../img/backArrow.png";
import { history } from "../..";

const OrderOverview = () => {
  const params = useParams<any>();

  const orderType = () => {
    switch (params.tab_key) {
      case "1":
        return "direct_orders";
      case "2":
        return "orders";
      default:
        return "customized_products";
    }
  };

  const fetchOrder = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url:
        orderType() === "customized_products"
          ? `/admin/customized_orders/products/${params.order_id}`
          : `/${orderType()}/${params.order_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { isLoading, data } = useQuery("single_order", () => fetchOrder(), {
    keepPreviousData: true,
  });

  const tableBody = (data: any) => {
    switch (params.tab_key) {
      case "1":
        return <DirectOrderTableBody data={data} />;
      case "2":
        return <NormalOrderTableBody data={data} />;
      default:
        return <CustomizedOrderProducts data={data} />;
    }
  };
  return isLoading ? (
    <div
      className="loading_orders"
      style={{ height: "50vh", width: "50vw", margin: "0 auto" }}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <div className="my_orders">
      <div className="container">
        {params.tab_key !== "3" && (
          <OrderHeader
            order={data && data.data}
            order_type={params.tab_key}
            goTo=""
          />
        )}
        {/* {params.tab_key !== "3" ? ( */}
        {params.tab_key === "3" && (
          // <div>
          //   <img
          //     src={backArrow}
          //     alt="Back Arrow"
          //     width={"40px"}
          //     height={"40px"}
          //     style={{ cursor: "pointer", marginTop: "30px" }}
          //     //  onClick={() => setSelectScreen("")}
          //   />
          //   <p>My orders</p>
          // </div>
          <div className="proto_header m-2 mt-4">
            <img
              src={backArrow}
              className="back_arrow_folder"
              alt="back arrow"
              onClick={() => history.back()}
            />
            <h1>Orders</h1>
          </div>
        )}
        <table className="table">
          <thead>
            <tr>
              {params.tab_key !== "3" && <th>ID</th>}
              <th>Name</th>
              {/* <th>{params.tab_key === "2" ? "Status" : "Options"}</th> */}
              {/*               <th>{"Status"}</th>
               */}{" "}
              {params.tab_key == "1" ? (
                <th>Nbr of Options</th>
              ) : (
                <th>{"Status"}</th>
              )}
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{tableBody(data)}</tbody>
        </table>
        {/* ) : (
          <div className="mt-3 pb-3">
            <CustomizedOrderTableBody data={data} />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default OrderOverview;
