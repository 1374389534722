import { useRouteMatch, Route, Switch } from "react-router-dom";
import "../../sass/MyOrders.scss";
import DemcoProductDetails from "./DemcoProductDetails";
import DemcoProductOrders from "./DemcoProductOrders";
import DemcoProductProgress from "./DemcoProductProgress";

export default function DemcoProductOrdersContainer({
  orderStatus,
  selectedDate,
}: any) {
  const { path, url } = useRouteMatch();

  return (
    <div className="my_orderss">
      <Switch>
        <Route path={`${path}`} exact>
          <DemcoProductOrders
            orderType="demco-product-orders"
            url={url}
            orderStatus={orderStatus}
            selectedDate={selectedDate}
          />
        </Route>
        <Route exact path={`${path}/:id`}>
          <DemcoProductDetails orderStatus={orderStatus} />
        </Route>
        <Route path={`${path}/:order_id/:product_id/progress`}>
          <DemcoProductProgress />
        </Route>
        <Route>
          <h1>Page Not Found</h1>
        </Route>
      </Switch>
    </div>
  );
}
