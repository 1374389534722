import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCustomizedProduct,
  getProduct,
} from "../../actions/products-actions/actions";
import { Product3dConfigTabs } from "../product-3d-config-tabs/Product3dConfigTabs";
import { Project3d } from "../project-3d/Project3d";
import "./product-view.scss";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import { useQuery } from "react-query";
import axiosInstance from "../../config/axios-instance";
import BottomDrawer from "../BottomDrawer/BottomDrawer";
import {history} from "../../index";
import {ArrowLeftOutlined} from "@ant-design/icons";

export type IAppProps = {};

function ProductView() {
  const [originalProduct, setOriginalProduct] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const dispatch = useDispatch();
  const params = useParams<any>();
  const { selectedProduct, isLoading } = useSelector(
    (state: any) => state.products
  );
  const drawers = useSelector((state: any) => state.drawers);

  useEffect(() => {
    // if(selectedProduct){
    if (params.status === "new") {
      dispatch(getProduct(params.productId));
    } else {
      dispatch(getCustomizedProduct(params.productId));
    }
    // }
  }, [params.productId, params.status]);

  /*   function getItem() {
    setImage(localStorage.getItem("SiteData"));
  } */

  /*   useEffect(() => {
    var intervalId = window.setInterval(function () {
      console.log("ok");
      setImage(localStorage.getItem("SiteData"));
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [image]); */
  // const { isLoading: loading, data } = useQuery(
  //   ["singleproduct", selectedProduct],
  //   async () => {
  //     if (selectedProduct) {
  //       return axiosInstance({
  //         url: `/products/${selectedProduct.product_id}`,
  //         method: "GET",
  //       }).then((res: any) => {
  //         setOriginalProduct(res.data);
  //         return {};
  //       });
  //     }
  //   },
  //   {
  //     enabled: params.status !== "new" ? true : false,
  //   }
  // );

  const product = {
    name: "IDWS22JE2 FLARED JEANS",
  };
  return (
    selectedProduct && (
      <div className="product-view-container">
        <div className="product-view-left" style={{ background: "#EFEFEF" }}>
          {/* <Project3d /> */}
          {isLoading ? (
            <p>Loading...</p>
          ) : (
              <div style={{position: "relative"}}>
                {/*      <img
                src={process.env.REACT_APP_STORAGE_URL + image}
                alt=""
                width={"150px"}
                height={"150px"}
                style={{
                  borderRadius: "50%",
                  position: "absolute",
                  top: "20%",
                  left: "55%",
                  zIndex: 1,
                }}
                // onClick={getItem}
              /> */}
                {/*     <img
                src={
                  params.status === "new" && selectedProduct
                    ? process.env.REACT_APP_STORAGE_URL +
                      selectedProduct?.front_image
                    : params.status === "edit" && selectedProduct
                    ? process.env.REACT_APP_STORAGE_URL +
                      selectedProduct?.original_product?.front_image
                    : placeholder_jeans
                }
                style={{
                  position: "relative",
                  width: "100%",
                  height: "89.7vh",
                  objectFit: "contain",
                }}
                alt=""
              /> */}

                <div className={
                  "go_back"
                }

                     onClick={(e: any) => {
                       e.preventDefault();
                       history.back();
                     }}
                     style={{fontSize: "1rem", position: "absolute", zIndex: 99, margin:"25px"}}
                >

                  <ArrowLeftOutlined

                  ></ArrowLeftOutlined>{" "}

                </div>

                <iframe
                    src={
                      params.status === "new" && selectedProduct
                          ? selectedProduct?.link_360
                          : params.status === "edit" && selectedProduct
                              ? selectedProduct?.original_product?.link_360
                              : placeholder_jeans
                    }
                    className={"iframe-view"}
                ></iframe>
              </div>
          )}
          {/* {selectedProduct && selectedProduct.images ? (
            <img
              src={
                selectedProduct &&
                selectedProduct.images[0] &&
                selectedProduct.images[0].path
                  ? process.env.REACT_APP_STORAGE_URL +
                    selectedProduct.images[0].path
                  : null
              }
              style={{
                position: "relative",
                width: "100%",
                height: "89.7vh",
                objectFit: "contain",
              }}
              alt=""
            />
          ) : (
            originalProduct && (
              <img
                src={
                  originalProduct.images[0] && originalProduct.images[0].path
                    ? process.env.REACT_APP_STORAGE_URL +
                      originalProduct.images[0].path
                    : null
                }
                style={{
                  position: "relative",
                  width: "100%",
                  height: "89.7vh",
                  objectFit: "contain",
                }}
                alt=""
              />
            )
          )} */}
        </div>
        <div className="product-view-right" style={{ position: "relative" }}>
          <Product3dConfigTabs
            {...{ selectedProduct }}
            // originalProduct={originalProduct}
          />
        </div>
        {/* {drawers.openBottom && (
          <BottomDrawer
            mode={"add_to_collection"}
            addCustomizedProduct
            customizedOrder={{ ...data, product_id: selectedProduct.id }}
          />
        )} */}
      </div>
    )
  );
}

export default ProductView;
