import "../../sass/MyCollection.scss";
import "./MyCollectionHeader.scss";
import Bag from "../../icons/Bag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  clearOneFolder,
  getAllFolders,
  editFolder,
} from "../../actions/folders-actions/actions";
import { handleLeftFolderDetails } from "../../actions/actionCreators";
import { getFolderDetails } from "../../actions/folders-actions/actions";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { addProdDemcoToOrder } from "../../actions/order-actions/actions";
import { Spin, Drawer, Popconfirm, Input, DatePicker } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { MyNotif } from "../NotifIcons/NotifIcons";
import order_sent from "../../img/order_sent.jpg";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import {
  createArticle,
  getCollaborators,
} from "../../actions/order-actions/actions";
import Select from "react-select";

export interface IMyCollectionHeaderProps {
  showFunctions?: boolean;
  showMinFunctions?: boolean;
  rightSideFolder: boolean;
  folderToShow?: any;
}

export default function MyCollectionHeader({
  showFunctions,
  rightSideFolder,
  folderToShow,
}: IMyCollectionHeaderProps) {
  const dispatch = useDispatch();
  const { addDemcoProductOrderLoading } = useSelector(
    (state: any) => state.orders
  );
  const folders = useSelector((state: RootStateOrAny) => state.folders);
  const sortedFolders = useSelector(
    (state: RootStateOrAny) => state.folders.sortedFolders
  );
  const drawers = useSelector((state: RootStateOrAny) => state.drawers);
  const [folder, setfolder] = useState<any>("folder");
  const [name, setName] = useState("");
  const [folderId, setfolderId] = useState("");
  const [edit, setEdit] = useState(false);
  const [subfolders, setsubfolders] = useState<any>([]);
  const [showdrawer, setshowdrawer] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [showDrawer, setShowDrawer] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [date_delivery, setDeliveryDate] = useState("");
  const [disable, setDisable] = useState(false);
  const [orderName, setOrderName] = useState("");
  const [productsToOrder, setProductsToOrder] = useState([]);
  const [collaborator, setCollaborator] = useState([]);
  const [saison, setSaison] = useState("");
  const [isRequired, setIsRequired] = useState(false);

  const { collaborators, newOrder, orders, createOrderLoading } = useSelector(
    (state: any) => state.orders
  );
  const substructSubProducts = (folder: any, products: any) => {
    console.log("folder: ", folder);
    if (folder && folder.products) {
      products.push(folder.products);
    }

    if (folder && folder.folders && folder.folders.length > 0) {
      folder.folders.forEach((_folder: any) => {
        substructSubProducts(_folder, products);
      });
    }

    /*  setProductsToOrder(products);
    console.log(productsToOrder); */
    return;
    // return folders;
  };

  useEffect(() => {
    dispatch(getCollaborators(""));
  }, []);

  const handleChange = (e: any) => {
    setName(e.target.value);
  };
  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  useEffect(() => {
    if (folders.oneFolder && !rightSideFolder) {
      setfolder(folders.oneFolder);
      if (folderToShow) {
        setName(folders.oneFolder.name);
        setfolderId(folderToShow.id);
      } else {
        setName(folders.oneFolder.name);
        setfolderId(folders.oneFolder.id);
      }

      setsubfolders(
        folders.folders.filter(
          (el: any) => el.parent_id === folders.oneFolder.id
        )
      );
    } else if (folders.rightSideFolder && rightSideFolder) {
      setfolder(folders.rightSideFolder);
      if (folderToShow) {
        setName(folders.rightSideFolder.name);
        setfolderId(folders.rightSideFolder.id);
      } else {
        setName(folders.rightSideFolder.name);
        setfolderId(folders.rightSideFolder.id);
      }

      setsubfolders(
        folders.folders.filter(
          (el: any) => el.parent_id === folders.oneFolder.id
        )
      );
    } else {
      setfolder(folders.oneFolder);
      if (folderToShow) {
        setName(folders.oneFolder.name);
        setfolderId(folderToShow.id);
      } else {
        setName(folders.oneFolder.name);
        setfolderId(folders.oneFolder.id);
      }
    }
  }, [folders.oneFolder, folders.rightSideFolder, folderToShow]);
  useEffect(() => {
    if (!folders.folders) {
      dispatch(getAllFolders());
    }
  }, [folders.oneFolder]);

  function handleChangeOrderName(e: any) {
    setOrderName(e.target.value);
  }

  function openDrawer() {
    const folderId = folders.oneFolder.id;
    let arr: any = [];
    const selectedFolderFromSortedFolders = folders.folders.find(
      (el: any) => el.id === folderId
    );
    console.log(selectedFolderFromSortedFolders.products);
    setProductsToOrder(selectedFolderFromSortedFolders.products);
    substructSubProducts(selectedFolderFromSortedFolders, arr);

    setIsOpenDrawer(true);
  }

  const handleRemoveProduct = (itemToRemove: any) => {
    const updatedProducts = productsToOrder.filter(
      (item: any) => item !== itemToRemove
    );
    setProductsToOrder(updatedProducts);
  };

  const handleCollabChange = (e: any) => {
    let arryColl: any = [];
    e &&
      e.map((el: any) => {
        arryColl.push(el.value);
      });
    setCollaborator(arryColl);
  };

  function handleChangeSeason(e: any) {
    setSaison(e.target.value);
  }

  // SEND ORDER
  const sendOrder = () => {
    // if (folders.oneFolder.products.length > 0) {
    /*     const folderId = folders.oneFolder.id;
    let arr: any = [];
    const selectedFolderFromSortedFolders = folders.folders.find(
      (el: any) => el.id === folderId
    );
      c
    substructSubProducts(selectedFolderFromSortedFolders, arr); */
    console.log(name);
    if (date_delivery != "" && name != "") {
      setDisable(true);
      let nameoforder = orderName == "" ? name : orderName;
      if (productsToOrder.length == 1) {
        const response = dispatch(
          addProdDemcoToOrder(
            productsToOrder.flat().map((el: any) => {
              return {
                ...el,
                qty: 0,
              };
            }),
            false,
            nameoforder,
            date_delivery,
            collaborator,
            saison
          )
        );
        setDisable(false);
        setIsOpenDrawer(true);

        window.location.href = `/my-orders/demco-product-orders/in-progress`;
      } else {
        dispatch(
          addProdDemcoToOrder(
            productsToOrder.flat().map((el: any) => {
              return {
                ...el,
                qty: 0,
              };
            }),
            false,
            nameoforder,
            date_delivery,
            collaborator,
            saison
          )
        );
        setDisable(false);
        setIsOpenDrawer(true);
        window.location.href = `/my-orders/demco-product-orders/in-progress`;
      }
    } else {
      setIsRequired(true);
      setDisable(false);
    }
    /*   setTimeout(() => {
      setIsOpenDrawer(false);
    }, 3000); */
    // }
    //  else {
    //   MyNotif("No products in this collection.", "danger");
    // }
  };

  return (
    <>
      {folders.oneFolder &&
        (folders.oneFolder.products.length > 0 || subfolders.length > 0) && (
          // keep for customized products
          // ||
          // folders.oneFolder.customized_products.length > 0
          <div style={{ width: "100%" }} className="folder_header">
            <div className="folder_name_header_container d-flex align-items-center">
              {width <= 575 && (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="mr-2"
                  onClick={() => dispatch(clearOneFolder())}
                />
              )}
              {folders.oneFolder && edit ? (
                <>
                  <input
                    value={name}
                    className="edit_folder_name_input"
                    onChange={handleChange}
                  />

                  <FontAwesomeIcon
                    icon={faCheck}
                    onClick={() => {
                      if (edit) {
                        dispatch(editFolder({ name }, folderId));
                        setName(name);
                        setEdit(false);
                        folders.oneFolder &&
                          folders.rightSideFolder &&
                          folderToShow &&
                          setEdit(false);
                      }
                    }}
                  />
                </>
              ) : (
                <p className="folder_name_header">
                  <span
                    onClick={() => {
                      dispatch(getFolderDetails(folderId));
                      dispatch(handleLeftFolderDetails());
                    }}
                  >
                    {name}
                  </span>
                  <FontAwesomeIcon icon={faPen} onClick={() => setEdit(true)} />
                </p>
              )}
            </div>
            {showFunctions ? (
              !folders.rightSideFolder &&
              !folders.isLoadingRightFolder && (
                <div className="folder_functionnalities">
                  {/*     <Popconfirm
                    title="Are you sure you want to order this collection?"
                    onConfirm={sendOrder}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                  >
                    <div className="folder_function">
                      {addDemcoProductOrderLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 24 }} spin />
                          }
                        />
                      ) : (
                        <>
                          <Bag />
                          <p>Order Collection</p>
                        </>
                      )}
                    </div>
                  </Popconfirm> */}

                  <button
                    className="folder_function"
                    onClick={() => {
                      openDrawer();
                    }}
                  >
                    {" "}
                    <Bag />
                    <p>Order Collection</p>
                  </button>
                </div>
              )
            ) : (
              <div className="folder_functionnalities">
                {/* <div className="folder_function">
                  <Download />
                </div> */}
                <div className="folder_function" onClick={sendOrder}>
                  {addDemcoProductOrderLoading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  ) : (
                    <>
                      <Bag />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      {/* <Drawer
        className="order_sent_drawer"
        placement={"bottom"}
        closable={true}
        onClose={() => {
          setIsOpenDrawer(false);
        }}
        visible={isOpenDrawer}
        key={"bottom"}
      >
        <div className="my-2">
          <h1>Your order has been sent</h1>
          <p>
            We will train you in the coming days on the advancement of your
            product.{" "}
          </p>
        </div>
        <img src={order_sent} alt="order sent" className="img-fluid my-2" />
      </Drawer> */}

      <Drawer
        // className="order_sent_drawer"
        placement={"bottom"}
        closable={true}
        onClose={() => {
          /*         dispatch(clearSelectedProducts());
          setProductsToOrder([]); */
          setIsOpenDrawer(false);
        }}
        visible={isOpenDrawer}
        key={"bottom"}
        style={{ paddingTop: "25px" }}
      >
        <div className="row">
          {/* left container */}

          <div
            className="col-6 "
            style={{
              paddingTop: "88px",
              paddingLeft: "112px",
              paddingRight: "112px",
            }}
          >
            <div className="titledrawerConfirm">Selected products</div>
            <div style={{ fontSize: "16px", marginBottom: "32px" }}>
              Please review your selected items below before confirming your
              order.
            </div>
            {productsToOrder.length > 0 ? (
              <div
                className="border"
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#F8FAFC",
                  padding: "12px",
                }}
              >
                {productsToOrder.map((item: any, key: any) => {
                  return (
                    <>
                      <div
                        className="row rowProdcut"
                        style={{ position: "relative" }}
                      >
                        <div
                          style={{
                            borderRadius: "8px",
                            marginRight: "16px",
                          }}
                        >
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              item.front_image
                            }
                            width="100px"
                            height="72px"
                            className="orderBoxImage"
                          />
                        </div>
                        <div className="">
                          <span className="titleorderContainerH1">
                            {item.name}
                          </span>
                          <br></br>
                          <span className="titleorderContainerH2">
                            {item.code}
                          </span>
                        </div>
                        <div style={{ position: "absolute", right: "0" }}>
                          <button
                            onClick={() => {
                              handleRemoveProduct(item);
                              if (productsToOrder.length == 1) {
                                setProductsToOrder([]);
                                setIsOpenDrawer(false);
                              }
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "5px",
                              backgroundColor: "#E5ECF3",
                              padding: "16px",
                              width: "32px",
                              height: "32px",
                            }}
                          >
                            <DeleteOutlined />
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            ) : (
              <>The list is empty </>
            )}
          </div>
          <div
            className="col-6 "
            style={{
              paddingTop: "88px",
              paddingLeft: "112px",
              paddingRight: "112px",
            }}
          >
            {" "}
            <div className="titledrawerConfirm">Order details</div>
            <div style={{ fontSize: "16px", marginBottom: "32px" }}>
              Complete your order by providing the details below.
            </div>
            <div>
              <label className="mr-3 mb-1">Collection name</label>
              <Input
                type="text"
                style={{
                  borderRadius: "5px",
                  marginBottom: "16px",
                }}
                defaultValue={name}
                onChange={handleChangeOrderName}
              ></Input>
              {isRequired && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
            <label className="mr-3 mb-1">Season</label>
            <Input
              type="text"
              style={{
                borderRadius: "5px",
                marginBottom: "16px",
              }}
              defaultValue={saison}
              onChange={handleChangeSeason}
            ></Input>
            <label className="mr-3 mb-0">Select Collaborators</label>
            <Select
              isMulti
              name="colors"
              placeholder="Select your collaborators"
              options={
                collaborators &&
                collaborators.map((el: any) => {
                  return {
                    label: el.name,
                    value: el.id,
                  };
                })
              }
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleCollabChange}
            />
            <div>
              <label className="mr-3 mb-0 mt-3">Delivery date</label>
              <DatePicker
                disabledDate={(current) =>
                  current.isBefore(moment().add(15, "days"))
                }
                format={"DD/MM/YYYY"}
                onChange={(date: any, dateString: any) =>
                  setDeliveryDate(dateString)
                }
                style={{
                  borderRadius: "5px",
                  width: "100%",
                  marginBottom: "16px",
                }}
              />
              {isRequired && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
            {/* <label className="mr-3 mb-0">Season</label>
            <Select
              size="large"
              className="selectGender"
              style={{ width: "100%", marginBottom: "16px" }}
              onSelect={(value: any) => setSaison(value)}
            >
              <Select.Option value="">--</Select.Option>
              <Select.Option value="AW">AW</Select.Option>
              <Select.Option value="FW">FW</Select.Option>
              <Select.Option value="SS">SS</Select.Option>
              <Select.Option value="SU">SU</Select.Option>
              <Select.Option value="PF">PF</Select.Option>
            </Select>
            <label className="mr-3 mb-0">Year</label>
            <Input
              type="text"
              style={{
                borderRadius: "5px",
                marginBottom: "16px",
              }}
              onChange={handleChangeYear}
            ></Input>
            <label className="mr-3 mb-0">Gender</label>
            <Select
              size="large"
              className="selectGender"
              style={{ width: "100%", marginBottom: "16px" }}
              onSelect={(value: any) => {
                setGender(value);
                console.log(gender);
              }}
            >
              <Select.Option value="">--</Select.Option>
              <Select.Option value="Men">Men</Select.Option>
              <Select.Option value="Woman">Woman</Select.Option>
              <Select.Option value="Kids">Kids</Select.Option>
            </Select>
          
            <label className="mr-3 mb-0">Theme</label>
            <Input
              type="text"
              style={{
                borderRadius: "5px",
                marginBottom: "24px",
              }}
              onChange={handleChangeTheme}
            ></Input>
            <br />
            <label className="mr-3 mb-0">Details</label>
            <TextArea
              showCount
              maxLength={100}
              style={{ marginBottom: 16, borderRadius: "5px" }}
              className="textareaDetailsOrder"
              onChange={handleChangeDetails}
              placeholder="your details "
            /> */}
            <div>
              <button
                className="view-details"
                style={{ marginTop: "16px" }}
                onClick={sendOrder}
                disabled={disable}
              >
                {disable ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 24, color: "white" }}
                        spin
                      />
                    }
                  />
                ) : (
                  <>Confirm order</>
                )}
              </button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
