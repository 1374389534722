export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST ";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS ";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL ";

export const FORGOT_PWD_REQUEST = "FORGOT_PWD_REQUEST ";
export const FORGOT_PWD_SUCCESS = "FORGOT_PWD_SUCCESS ";
export const FORGOT_PWD_FAIL = "FORGOT_PWD_FAIL ";

export const ADD_NEW_PWD_REQUEST = "ADD_NEW_PWD_REQUEST ";
export const ADD_NEW_PWD_SUCCESS = "ADD_NEW_PWD_SUCCESS ";
export const ADD_NEW_PWD_FAIL = "ADD_NEW_PWD_FAIL ";

export const RESET_PWD_REQUEST = "RESET_PWD_REQUEST ";
export const RESET_PWD_SUCCESS = "RESET_PWD_SUCCESS ";
export const RESET_PWD_FAIL = "RESET_PWD_FAIL ";

export const RESET_PWD_CONNECTED_REQUEST = "RESET_PWD_CONNECTED_REQUEST ";
export const RESET_PWD_CONNECTED_SUCCESS = "RESET_PWD_CONNECTED_SUCCESS ";
export const RESET_PWD_CONNECTED_FAIL = "RESET_PWD_CONNECTED_FAIL ";

export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST ";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS ";
export const UPDATE_ACCOUNT_FAIL = "UPDATE_ACCOUNT_FAIL ";

export const LOGOUT_USER = "LOGOUT_USER";
export const CLEAR_MSG = "CLEAR_MSG";

export const LAST_ACTIVE_REQUEST = "LAST_ACTIVE_REQUEST"
export const LAST_ACTIVE_SUCCESS = "LAST_ACTIVE_SUCCESS"
export const LAST_ACTIVE_FAIL = "LAST_ACTIVE_FAIL"
