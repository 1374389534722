import { useState, useEffect } from "react";
import "./Navbar.scss";
import logoGreen from "../../img/logo_green.svg";
import logoWhite from "../../img/logo_white.svg";
import company_placeholder from "../../img/company_placeholder.png";
import { Link, NavLink } from "react-router-dom";
import SideMenu from "../SideMenu/SideMenu";
import { RightDrawer } from "../RightDrawer/RightDrawer";
import { Dispatch } from "redux";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  handleLeftDrawer,
  handleRightDrawer,
} from "../../actions/actionCreators";
import { handleSearchScreen } from "../../actions/search-actions/actions";
import { MobileSideMenu } from "../SideMenu/MobileSideMenu";
import { getSelectionProducts } from "../../actions/myselection-actions/actions";
import { newNotificationSeen } from "../../actions/notifications-actions/actions";
import { Badge, Dropdown, Menu, Popconfirm, Space } from "antd";
import { finish, goNextStep } from "../../actions/guided-tour-actions/actions";
import { QueryClient, useMutation } from "react-query";
import axiosInstance from "../../config/axios-instance";
import { loadConnectedUser } from "../../actions/auth-actions/actions";
import { getFilterAttributes } from "../../actions/products-actions/actions";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { idText } from "typescript";

interface Props {
  top?: boolean;
}

export const Navbar = ({ top }: Props) => {
  const [mode, setMode] = useState("my_space");
  const [itemsDenim, setItemsDenim] = useState([]);
  const [itemsKnit, setItemsKnit] = useState([]);

  const [width, setWidth] = useState(window.innerWidth);
  const dispatch: Dispatch<any> = useDispatch();
  const drawerState = useSelector((state: RootStateOrAny) => state.drawers);
  const { user, isLoading, isAdmin } = useSelector((state: any) => state.auth);
  const { newNotificationReceived } = useSelector(
    (state: any) => state.notifications
  );
  const { step } = useSelector((state: any) => state.tour);

  const openDrawer = (mode: string) => {
    dispatch(handleRightDrawer());
    setMode(mode);
  };

  const closeDrawer = () => {
    dispatch(handleRightDrawer());
  };

  /*   useEffect(() => {
    dispatch(getFilterAttributes("knit"));
  }); */

  const fetchDenim = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/filters/reponsible?fabric_type=denim`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setItemsDenim(res.data.our_responsible_choice);
    });
  };
  const fetchKnit = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/filters/reponsible?fabric_type=knit`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setItemsKnit(res.data.our_responsible_choice);
    });
  };
  useEffect(() => {
    /*     fetchDenim();
    fetchKnit(); */
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  useEffect(() => {
    user && !isAdmin && dispatch(getSelectionProducts());
  }, []);
  console.log(isAdmin);
  // avatar to display, if user dont have avatar, show company's logo. If the company dont have a logo, show placeholder
  const showAvatar = () => {
    if (user && user.logo) {
      return process.env.REACT_APP_STORAGE_URL + user.logo;
    } else if (user && user.company && user.company.logo) {
      return process.env.REACT_APP_STORAGE_URL + user.company.logo;
    } else if (user && user.avatar) {
      return process.env.REACT_APP_STORAGE_URL + user.avatar;
    } else {
      return company_placeholder;
    }
  };

  const readAllNotifications = () => {
    let token = localStorage.getItem("token");
    if (isAdmin) {
      return axiosInstance({
        method: "post",
        url: `/notifications/read-all`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      return axiosInstance({
        method: "post",
        url: `/notifications/read-all-client`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };

  const queryClient = new QueryClient();

  const { mutateAsync } = useMutation(readAllNotifications, {
    onSuccess(data, variables, context) {
      dispatch(loadConnectedUser(true));
      queryClient.invalidateQueries("notifications");
    },
  });
  const menu = (
    <Menu>
      {" "}
      <Menu.SubMenu title="DENIM">
        {itemsDenim.map((el: any) => (
          <>
            <Menu.Item>
              {" "}
              <NavLink
                activeClassName="active-link"
                to={`/responsible/${el.id}/denim`}
              >
                {el.name}
              </NavLink>
            </Menu.Item>
          </>
        ))}
      </Menu.SubMenu>{" "}
      <Menu.SubMenu title="KINT">
        {itemsKnit.map((el: any) => (
          <Menu.Item>
            <NavLink
              activeClassName="active-link"
              to={`/responsible/${el.id}/knit`}
            >
              {el.name}
            </NavLink>
          </Menu.Item>
        ))}
      </Menu.SubMenu>{" "}
    </Menu>
  );

  return width > 880 ? (
    <nav className={!top ? "menu__white menu" : "menu"}>
      <>
        <RightDrawer
          mode={mode}
          closeDrawer={closeDrawer}
          open={drawerState.openRight}
        />
        {user && !isAdmin && !user.last_login && step === 7 && (
          <div
            className="my-tooltip-right bg-white rounded border mt-3 py-2 px-3"
            style={{
              left: "50%",
              top: "100%",
              transform: "translate(-80%, 0)",
              zIndex: 10,
              position: "absolute",
              right: 0,
              width: "400px",
              boxShadow: "0px 10px 15px rgba(0, 70, 140, 0.1)",
              padding: "15px 20px",
              color: "black",
            }}
          >
            <p>
              Here is a short cut to edit your profile, keep an eye on your
              order’s progress, organize your collections on the most efficient
              way, book a meeting with our team, manage and invite your team
              members and constantly check our newest fabrics
            </p>
            <div className="d-flex justify-content-end">
              <button
                className="border mr-2 bg-white"
                style={{
                  borderRadius: "5px",
                }}
                onClick={() => dispatch(finish())}
              >
                Skip
              </button>
              <button
                style={{
                  background: "#00468c",
                  border: "1px solid #00468c",
                  borderRadius: "5px",
                  color: "white",
                }}
                onClick={() => {
                  dispatch(finish());
                  dispatch(goNextStep());
                }}
              >
                Thank you
              </button>
            </div>
          </div>
        )}
      </>
      <div className="menu__leftSide">
        <Link to="/home">
          <img src={!top ? logoWhite : logoGreen} alt="Demco logo" />
        </Link>
        <div className="menu__items">
          <NavLink
            to={{ hash: "#" }}
            activeClassName="active-link"
            onClick={() => dispatch(handleLeftDrawer())}
            className={"navbar-font"}
          >
            Products
          </NavLink>
          {user && !isAdmin && (
            <>
              <NavLink
                activeClassName="active-link"
                to={
                  user && user.fabric_type === "denim"
                    ? `/for-you/denim`
                    : "/for-you/knit"
                }
                className={"navbar-font"}
              >
                For you
              </NavLink>
            </>
          )}

          {user && isAdmin && (
            <>
              <NavLink
                activeClassName="active-link"
                to="/foryou-all-users"
                className={"navbar-font"}
              >
                For you
              </NavLink>
            </>
          )}

          <>
            <NavLink
              activeClassName="active-link"
              to="/responsible"
              className={"navbar-font"}
            >
              Our Responsible choice
            </NavLink>
          </>
          {user && isAdmin && user.type !== "agent" && (
            <NavLink
              activeClassName="active-link"
              to="/all-clients"
              className={"navbar-font"}
            >
              Orders
            </NavLink>
          )}
          {user && isAdmin && user.type === "agent" && (
            <NavLink
              activeClassName="active-link"
              to="/library"
              className={"navbar-font"}
            >
              Libraries
            </NavLink>
          )}
          {user && (user.type === "commercial" || !user.type) && (
            <NavLink
              activeClassName="active-link"
              to="/direct-order"
              className={"navbar-font"}
            >
              Direct order
            </NavLink>
          )}
          {/*   <NavLink activeClassName="active-link" to="/direct-order" className={"navbar-font"}>
            Direct order
          </NavLink>*/}

          {/*   {user && isAdmin && (
            <NavLink activeClassName="active-link" to="/creative-station">
              CREATIVE STATION
            </NavLink>
          )} */}

          {user && (
            <>
              <NavLink
                activeClassName="active-link"
                to="/creative-station"
                className={"navbar-font"}
              >
                Creative station
              </NavLink>
            </>
          )}
        </div>
      </div>
      <div className="menu__rightSide">
        <div
          className="menu__icons"
          style={{
            justifyContent: isAdmin ? "flex-end" : "space-between",
            alignItems: "flex-end",
          }}
        >
          <svg
            onClick={() => dispatch(handleSearchScreen())}
            xmlns="http://www.w3.org/2000/svg"
            width="24.001"
            height="24.001"
            className="mr-3"
            viewBox="0 0 24.001 24.001"
          >
            <path
              fill="#000"
              d="M23.836 1.575l-6.047-6.047a.556.556 0 00-.4-.164h-.483A9.707 9.707 0 0019.5-11.25 9.748 9.748 0 009.75-21 9.748 9.748 0 000-11.25 9.748 9.748 0 009.75-1.5a9.725 9.725 0 006.614-2.588v.478a.576.576 0 00.164.4l6.047 6.047a.563.563 0 00.8 0l.464-.464a.563.563 0 00-.003-.798zM9.75-3a8.244 8.244 0 01-8.25-8.25 8.244 8.244 0 018.25-8.25A8.244 8.244 0 0118-11.25 8.244 8.244 0 019.75-3z"
              data-name="Tracé 3310"
              transform="translate(0 21)"
            ></path>
          </svg>
          {/* {true ? ( */}
          <Badge count={user?.unread_notifications_count}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="24"
              viewBox="0 0 21 24"
              className="mr-3"
              onClick={() => {
                dispatch(newNotificationSeen());
                openDrawer("notifications");
                mutateAsync();
              }}
            >
              <path
                fill="#000"
                d="M10.5 1.5A1.5 1.5 0 019 0H7.5a3 3 0 003 3 3 3 0 003-3H12a1.5 1.5 0 01-1.5 1.5zm9.815-6.806C19-6.554 18-7.86 18-12.286a7.314 7.314 0 00-6.752-7.141v-.823A.75.75 0 0010.5-21a.75.75 0 00-.75.75v.823A7.313 7.313 0 003-12.286C3-7.86 2-6.554.685-5.306A2.182 2.182 0 00.158-2.9 2.237 2.237 0 002.25-1.5h16.5a2.237 2.237 0 002.092-1.4 2.181 2.181 0 00-.527-2.406zM18.75-3H2.25a.712.712 0 01-.531-1.219C3.353-5.775 4.5-7.516 4.5-12.285a5.86 5.86 0 016-5.715 5.86 5.86 0 016 5.714c0 4.751 1.135 6.5 2.779 8.066A.712.712 0 0118.75-3z"
                data-name="Tracé 3311"
                transform="translate(0 21)"
              ></path>
            </svg>
          </Badge>
          {/* ) : ( */}
          {/* <svg
              onClick={() => {
                openDrawer("notifications");
                mutateAsync();
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="24"
              viewBox="0 0 21 24"
              className="mr-3"
            >
              <path
                fill="#fff"
                d="M10.5 1.5A1.5 1.5 0 019 0H7.5a3 3 0 003 3 3 3 0 003-3H12a1.5 1.5 0 01-1.5 1.5zm9.815-6.806C19-6.554 18-7.86 18-12.286a7.314 7.314 0 00-6.752-7.141v-.823A.75.75 0 0010.5-21a.75.75 0 00-.75.75v.823A7.313 7.313 0 003-12.286C3-7.86 2-6.554.685-5.306A2.182 2.182 0 00.158-2.9 2.237 2.237 0 002.25-1.5h16.5a2.237 2.237 0 002.092-1.4 2.181 2.181 0 00-.527-2.406zM18.75-3H2.25a.712.712 0 01-.531-1.219C3.353-5.775 4.5-7.516 4.5-12.285a5.86 5.86 0 016-5.715 5.86 5.86 0 016 5.714c0 4.751 1.135 6.5 2.779 8.066A.712.712 0 0118.75-3z"
                data-name="Tracé 3311"
                transform="translate(0 21)"
              ></path>
            </svg> */}
          {/* )} */}
          <div style={{ position: "relative" }}>
            {user && !isAdmin && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="21"
                viewBox="0 0 24 21"
                onClick={() => openDrawer("my_selection")}
              >
                <path
                  fill="#000"
                  d="M21.67-18.061a6.411 6.411 0 00-8.747.633l-.923.956-.923-.952c-1.912-1.978-5.77-3.169-8.747-.637a6.726 6.726 0 00-.464 9.741l9.07 9.366A1.475 1.475 0 0012 1.5a1.456 1.456 0 001.059-.455l9.07-9.366a6.717 6.717 0 00-.459-9.74zm-.614 8.7L12.019 0 2.944-9.361a5.221 5.221 0 01.356-7.556c2.569-2.194 5.588-.6 6.694.539l2 2.067 2-2.067c1.088-1.125 4.134-2.719 6.694-.539a5.225 5.225 0 01.368 7.556z"
                  data-name="Tracé 3312"
                  transform="translate(.001 19.5)"
                ></path>
              </svg>
            )}
            {user && !isAdmin && !user.last_login && step === 6 && (
              <div
                className="my-tooltip bg-white rounded border mt-3 py-2 px-3"
                style={{
                  position: "absolute",
                  right: 0,
                  width: "400px",
                  boxShadow: "0px 10px 15px rgba(0, 70, 140, 0.1)",
                  padding: "15px 20px",
                  color: "black",
                }}
              >
                <p>
                  Have a quick access to your favorite products, order them or
                  drop them in one of your collections
                </p>
                <div className="d-flex justify-content-end">
                  <button
                    className="border mr-2 bg-white"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => dispatch(finish())}
                  >
                    Skip
                  </button>
                  <button
                    style={{
                      background: "#00468c",
                      border: "1px solid #00468c",
                      borderRadius: "5px",
                      color: "white",
                    }}
                    onClick={() => {
                      openDrawer("my_space");
                      dispatch(goNextStep());
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="menu__userInfo">
          {/*  <div onClick={() => openDrawer("my_space")}>
            <p>Hello!</p>
            <span>{user && user.name}</span>
          </div>*/}
          {user && (
            <img
              src={isLoading ? company_placeholder : showAvatar()}
              alt="brand logo"
              onClick={() => openDrawer("my_space")}
            />
          )}
          {/*  <svg
            onClick={() => openDrawer("my_space")}
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="16.688"
            viewBox="0 0 21 16.688"
          >
            <path
              fill="#000"
              d="M20.719-15.656a.281.281 0 00.281-.281v-1.125a.281.281 0 00-.281-.281H.281a.281.281 0 00-.281.281v1.125a.281.281 0 00.281.281zm0 7.5A.281.281 0 0021-8.437v-1.125a.281.281 0 00-.281-.281H.281A.281.281 0 000-9.562v1.125a.281.281 0 00.281.281zm0 7.5A.281.281 0 0021-.937v-1.125a.281.281 0 00-.281-.281H.281A.281.281 0 000-2.062v1.125a.281.281 0 00.281.281z"
              data-name="Tracé 3313"
              transform="translate(0 17.344)"
            ></path>
          </svg>*/}
        </div>
      </div>
      {drawerState.openLeft && <SideMenu />}
    </nav>
  ) : (
    <nav className={!top ? "menu__white menu mobile_menu" : "menu mobile_menu"}>
      <RightDrawer
        mode={mode}
        closeDrawer={closeDrawer}
        open={drawerState.openRight}
      />
      <div
        className="menu__leftSide mobile_leftSide"
        onClick={() => dispatch(handleLeftDrawer())}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22.75"
          height="18.078"
          viewBox="0 0 22.75 18.078"
          style={{ cursor: "pointer" }}
        >
          <path
            fill={!top ? "white" : "black"}
            d="M22.445-16.961a.3.3 0 00.3-.3v-1.219a.3.3 0 00-.3-.3H.3a.3.3 0 00-.3.3v1.219a.3.3 0 00.3.3zm0 8.125a.3.3 0 00.3-.3v-1.219a.3.3 0 00-.3-.3H.3a.3.3 0 00-.3.3v1.219a.3.3 0 00.3.3zm0 8.125a.3.3 0 00.3-.3v-1.223a.3.3 0 00-.3-.3H.3a.3.3 0 00-.3.3v1.219a.3.3 0 00.3.3z"
            data-name="Tracé 4291"
            transform="translate(0 18.789)"
          ></path>
        </svg>

        <Link to="/home">
          <img src={!top ? logoWhite : logoGreen} alt="Demco logo" />
        </Link>
      </div>
      <div className="mobile_icons">
        <svg
          onClick={() => dispatch(handleSearchScreen())}
          xmlns="http://www.w3.org/2000/svg"
          width="24.001"
          height="24.001"
          viewBox="0 0 24.001 24.001"
        >
          <path
            fill={!top ? "white" : "black"}
            d="M23.836 1.575l-6.047-6.047a.556.556 0 00-.4-.164h-.483A9.707 9.707 0 0019.5-11.25 9.748 9.748 0 009.75-21 9.748 9.748 0 000-11.25 9.748 9.748 0 009.75-1.5a9.725 9.725 0 006.614-2.588v.478a.576.576 0 00.164.4l6.047 6.047a.563.563 0 00.8 0l.464-.464a.563.563 0 00-.003-.798zM9.75-3a8.244 8.244 0 01-8.25-8.25 8.244 8.244 0 018.25-8.25A8.244 8.244 0 0118-11.25 8.244 8.244 0 019.75-3z"
            data-name="Tracé 3310"
            transform="translate(0 21)"
          ></path>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="24"
          viewBox="0 0 21 24"
          onClick={() => openDrawer("notifications")}
        >
          <path
            fill={!top ? "white" : "black"}
            d="M10.5 1.5A1.5 1.5 0 019 0H7.5a3 3 0 003 3 3 3 0 003-3H12a1.5 1.5 0 01-1.5 1.5zm9.815-6.806C19-6.554 18-7.86 18-12.286a7.314 7.314 0 00-6.752-7.141v-.823A.75.75 0 0010.5-21a.75.75 0 00-.75.75v.823A7.313 7.313 0 003-12.286C3-7.86 2-6.554.685-5.306A2.182 2.182 0 00.158-2.9 2.237 2.237 0 002.25-1.5h16.5a2.237 2.237 0 002.092-1.4 2.181 2.181 0 00-.527-2.406zM18.75-3H2.25a.712.712 0 01-.531-1.219C3.353-5.775 4.5-7.516 4.5-12.285a5.86 5.86 0 016-5.715 5.86 5.86 0 016 5.714c0 4.751 1.135 6.5 2.779 8.066A.712.712 0 0118.75-3z"
            data-name="Tracé 3311"
            transform="translate(0 21)"
          ></path>
        </svg>

        {user && !isAdmin && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="21"
            viewBox="0 0 24 21"
            onClick={() => openDrawer("my_selection")}
          >
            <path
              fill={!top ? "white" : "black"}
              d="M21.67-18.061a6.411 6.411 0 00-8.747.633l-.923.956-.923-.952c-1.912-1.978-5.77-3.169-8.747-.637a6.726 6.726 0 00-.464 9.741l9.07 9.366A1.475 1.475 0 0012 1.5a1.456 1.456 0 001.059-.455l9.07-9.366a6.717 6.717 0 00-.459-9.74zm-.614 8.7L12.019 0 2.944-9.361a5.221 5.221 0 01.356-7.556c2.569-2.194 5.588-.6 6.694.539l2 2.067 2-2.067c1.088-1.125 4.134-2.719 6.694-.539a5.225 5.225 0 01.368 7.556z"
              data-name="Tracé 3312"
              transform="translate(.001 19.5)"
            ></path>
          </svg>
        )}
        {user && (
          <img
            src={isLoading ? company_placeholder : showAvatar()}
            alt="brand logo"
            onClick={() => openDrawer("my_space")}
          />
        )}
      </div>
      {drawerState.openLeft && <MobileSideMenu />}
    </nav>
  );
};
