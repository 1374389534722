import { Avatar, Button, Collapse, Tag, Tooltip } from "antd";
import moment from "moment";
import Folder from "../../icons/Folder";
import Jeans from "../../icons/Jeans";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./style.css";
import { useEffect, useState } from "react";
import {
  PlusSquareOutlined,
  PlusOutlined,
  MinusSquareOutlined,
  MinusOutlined,
  FolderOutlined,
  FolderAddOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderPlus,
  faFolderOpen,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

const { Panel } = Collapse;

export default function TableRowDirectOrder({ item, url }: any) {
  const [showTable, setShowTable] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [showTr, setShowTr] = useState("");

  const genStatusMsg = (status_name: string) => {
    console.log(status_name);
    switch (status_name) {
      case "PROTO":
        return {
          color: "#BCBCBD",
          msg: "Waiting for your confirmation",
        };
      case "PRODUCTION":
      case "OK PROD":
        return {
          msg: "Waiting for your confirmation",
          color: "#BCBCBD",
        };
      case "CANCELLED":
        return { color: "#FF3A3A", msg: "Your order is canceled" };
      default:
        return {
          color: "#BCBCBD",
          msg: "Waiting for your confirmation",
        };
    }
  };
  const handleClick = (rowId: any) => {
    // Only set showTable to true for the clicked row
    setShowTr(rowId);
  };

  return (
    <>
      {item.articles.filter(
        (article: any) =>
          article.article_options && article.article_options.length > 0
      ).length > 0 && (
        <tr onClick={() => setShowTable(!showTable)}>
          <td
            width={"5%"}
            className={showTable ? "top_article active_tr" : "top_article"}
          >
            <button onClick={() => setShowTable(!showTable)}>
              {showTable ? (
                <>
                  <FolderOpenOutlined
                    style={{ color: "#00468C", fontSize: "20px" }}
                  />
                </>
              ) : (
                <>
                  <FolderAddOutlined
                    style={{ color: "#00468C", fontSize: "20px" }}
                  />
                  {/*                   <PlusSquareOutlined style={{ color: "#00468C" }} />
                   */}{" "}
                </>
              )}
            </button>
          </td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ width: "10%", paddingLeft: 0 }}
          >
            <span style={{ color: "#8894A1" }} className={"ref_size"}>
              {item.ref}
            </span>
          </td>

          <td
            scope="row"
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ width: "15%", paddingLeft: "0px" }}
          >
            <span>{item.name}</span>
          </td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: "0px" }}
          >
            {moment(item.updated_at).format("DD/MM/YYYY")}
          </td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: "0px" }}
          >
            {/*    {item.delivery_date
              ? moment(item.delivery_date).format("DD/MM/YYYY")
              : "-"} */}
          </td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: "0px" }}
          >
            <p className="mb-0" style={{ marginLeft: "50px" }}>
              {
                item.articles.filter(
                  (article: any) =>
                    article.article_options &&
                    article.article_options.length > 0
                ).length
              }
            </p>
          </td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
          ></td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
          ></td>
          <td className={showTable ? "top_article active_tr" : "top_article"}>
            <Avatar.Group
              maxCount={2}
              size="large"
              maxStyle={{ color: "white", backgroundColor: "#7a9ec5" }}
            >
              {item.collaborators.map((collab: any) => (
                <Tooltip title={collab.name} key={collab.id} placement="bottom">
                  {collab.logo ? (
                    <Avatar
                      style={{
                        boxShadow: "1px 0px 5px rgba(0,0,0,0.4)",
                      }}
                      src={process.env.REACT_APP_STORAGE_URL + collab.logo}
                    />
                  ) : (
                    <Avatar
                      style={{
                        backgroundColor: "#ddd",
                        boxShadow: "1px 0px 5px rgba(0,0,0,0.2)",
                      }}
                      icon={<UserOutlined style={{ verticalAlign: "2px" }} />}
                    />
                  )}
                </Tooltip>
              ))}
            </Avatar.Group>
          </td>
        </tr>
      )}
      {showTable && (
        <>
          <td
            colSpan={10}
            style={{
              backgroundColor: "#FCFDFF",
              padding: "0rem",
              borderTop: "none",
            }}
          >
            <div className="scrollDiv">
              <table className="tableArticle" style={{ width: "100%" }}>
                {item.articles
                  .filter((elm: any) => elm.article_options?.length > 0)
                  .map((elm: any) => (
                    <>
                      <tr
                        key={elm.id}
                        onClick={() => {
                          handleClick(elm.id);
                          setShowOption(!showOption);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td
                          width={"5%"}
                          style={{
                            backgroundColor: "#FCFDFF",
                            borderTop: "none",
                          }}
                        >
                          <button
                            onClick={() => {
                              handleClick(elm.id);
                              setShowOption(!showOption);
                            }}
                          >
                            {showOption && showTr == elm.id ? (
                              <>
                                <FolderOpenOutlined
                                  style={{
                                    color: "#00468C",
                                    fontSize: "20px",
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <FolderAddOutlined
                                  style={{
                                    color: "#00468C",
                                    fontSize: "20px",
                                  }}
                                />
                              </>
                            )}
                          </button>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#FCFDFF",
                            borderTop: "none",
                            paddingLeft: 0,
                          }}
                        >
                          <span>{elm.name}</span>
                        </td>
                      </tr>
                      {showOption &&
                        showTr == elm.id &&
                        elm.article_options?.length > 0 && (
                          <tr>
                            <td
                              colSpan={10}
                              style={{
                                backgroundColor: "#FCFDFF",
                                padding: "0.2rem",
                                paddingLeft: "4%",
                              }}
                            >
                              {/*    <table className="tableArticle">
                                {elm.article_options?.map((option: any) => (
                                  <>
                                    <Link
                                      to={`${url}/${item.id}/${elm.id}/${option.id}/progress`}
                                    >
                                      <tr>
                                        <td
                                          style={{
                                            //   width: "50%",
                                            paddingLeft: "20px",
                                            borderTop: "none",
                                          }}
                                          className="responsive-width"
                                        >
                                          {option.name}
                                        </td>

                                        <td
                                          style={{
                                            //   width: "5%",
                                            paddingLeft: "0px",
                                            borderTop: "none",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            //    width: "21%",
                                            paddingLeft: "0px",
                                            borderTop: "none",
                                          }}
                                          className="delivery_td"
                                        >
                                          {" "}
                                          {option?.status?.pivot?.delivery_date
                                            ? moment(
                                                option?.status?.pivot
                                                  ?.delivery_date
                                              ).format("DD/MM/YYYY")
                                            : "-"}
                                        </td>
                                        <td
                                          style={{
                                            //  width: "8%",
                                            paddingLeft: "0px",
                                            borderTop: "none",
                                          }}
                                          className="details_status"
                                        >
                                          <Tag
                                            color="processing"
                                            style={{
                                              borderRadius: "50px",
                                              cursor: "pointer",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {option.status.name}
                                          </Tag>
                                        </td>
                                        <td
                                          style={{
                                            // width: "22%",
                                            paddingLeft: "0px",
                                            borderTop: "none",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: genStatusMsg(
                                                option.status.name
                                              ).color,
                                            }}
                                            className="status_msg"
                                          >
                                            {
                                              genStatusMsg(option.status.name)
                                                .msg
                                            }
                                          </span>
                                        </td>
                                      </tr>
                                    </Link>
                                  </>
                                ))}
                              </table> */}

                              {elm.article_options?.map((option: any) => (
                                <>
                                  <Link
                                    to={`${url}/${item.id}/${elm.id}/${option.id}/progress`}
                                  >
                                    <div className="row row_option_name_progress">
                                      <div className="option_name_prog_direct_order">
                                        {option.name}
                                      </div>
                                      <div className="deleviry_date_prog_direct_order">
                                        {option?.status?.pivot?.delivery_date
                                          ? moment(
                                              option?.status?.pivot
                                                ?.delivery_date
                                            ).format("DD/MM/YYYY")
                                          : "-"}
                                      </div>

                                      <div className="status_prog_direct_order">
                                        <Tag
                                          color="#182844"
                                          style={{
                                            borderRadius: "50px",
                                            cursor: "pointer",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {option.status.name}
                                        </Tag>
                                      </div>

                                      <div>
                                        <span
                                          style={{
                                            color: genStatusMsg(
                                              option.status.name
                                            ).color,
                                          }}
                                          className="status_msg"
                                        >
                                          {genStatusMsg(option.status.name).msg}
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </>
                              ))}
                            </td>
                          </tr>
                        )}
                    </>
                  ))}
              </table>
            </div>
          </td>
        </>
      )}

      {/*  <tr>
        <td width={"20%"}>
          <ul id="myUL">
            <li>
              <span
                className="caret"
                style={{ color: "#8894A1", fontSize: "16px" }}
              >
                {item.ref}
              </span>

              {item.articles && item.articles.length > 0 ? (
                <>
                  {" "}
                  <ul className="nested">
                    {item.articles.map((elm: any) => (
                      <li
                        style={{
                          fontSize: "14px",
                          marginLeft: "0px",
                        }}
                      >
                        <span className="caret">{elm.name}</span>
                        <ul className="nested">
                          {elm.options.map((option: any) => (
                            <>
                              <li
                                style={{
                                  fontSize: "14px",
                                  marginLeft: "10px",
                                  marginTop: "5px",
                                }}
                              >
                                <table className="border-details">
                                  <tr>
                                    <td>
                                      <Link
                                        to={`${url}/${item.id}/${elm.id}/${option.id}/progress`}
                                      >
                                        {option.name}
                                      </Link>
                                    </td>
                                    <td>
                                      <Tag
                                        color="processing"
                                        style={{
                                          borderRadius: "50px",
                                          cursor: "pointer",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {option.status.name}
                                      </Tag>
                                    </td>
                                  </tr>
                                </table>
                              </li>
                            </>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  {" "}
                  <ul className="nested" style={{ color: "#7C7C7C" }}>
                    No Articles
                  </ul>
                  <></>
                </>
              )}
            </li>
          </ul>
        </td>

        <td scope="row" className="top_article">
          <span>{item.name}</span>
        </td>
        <td className="top_article">
          {moment(item.updated_at).format("DD/MM/YYYY")}
        </td>
        <td className="top_article">
          <Avatar.Group
            maxCount={2}
            size="large"
            maxStyle={{ color: "white", backgroundColor: "#7a9ec5" }}
          >
            {item.collaborators.map((collab: any) => (
              <Tooltip title={collab.name} key={collab.id} placement="bottom">
                {collab.logo ? (
                  <Avatar
                    style={{
                      boxShadow: "1px 0px 5px rgba(0,0,0,0.4)",
                    }}
                    src={process.env.REACT_APP_STORAGE_URL + collab.logo}
                  />
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: "#ddd",
                      boxShadow: "1px 0px 5px rgba(0,0,0,0.2)",
                    }}
                    icon={<UserOutlined style={{ verticalAlign: "2px" }} />}
                  />
                )}
              </Tooltip>
            ))}
          </Avatar.Group>
        </td>
        <td className="top_article">
          <p className="mb-0">
            {item.articles.length} article{item.articles.length > 1 ? "s" : ""}
          </p>
        </td>
      </tr> */}
    </>
  );
}
