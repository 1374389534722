import {
  faCheck,
  faEllipsisH,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Popover } from "antd";
import React, { ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { createArticle } from "../../actions/order-actions/actions";

const { Panel } = Collapse;

interface Props {
  order_id: number;
  article: {
    id: string;
    name: string;
  };
  error: boolean;
  setDisableBtn: (val: boolean) => void;
  setError: (val: boolean) => void;
  deleteNewArticle: (name: string) => void;
  handleChange: (e: string, key: string) => void;
}

export const NewArticleAccordion = ({
  deleteNewArticle,
  handleChange,
  article,
  setDisableBtn,
  order_id,
  setError,
  error,
}: Props) => {
  const [showInput, setShowInput] = React.useState(true);
  const inputRef = React.useRef<any>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (showInput) {
      inputRef.current.focus();
    }
  }, [showInput]);

  return (
    <Collapse
      defaultActiveKey={["1"]}
      style={{ width: "100%" }}
      // onChange={() => dispatch(selectArticle(article.id))}
    >
      <Panel
        key="1"
        header={
          !showInput ? (
            article.name
          ) : (
            <div className="d-flex align-items-center justify-content-between">
              <input
                type="text"
                ref={inputRef}
                className="article_name_input"
                value={article.name}
                style={{ borderColor: error ? "red" : "rgba(0, 70, 140, 0.5)" }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.value, article.id)
                }
              />
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  className="mx-2"
                  style={{ color: "#00468c" }}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    if (article.name) {
                      dispatch(
                        createArticle({ name: article.name, order_id }, true)
                      );
                      setShowInput(false);
                      setDisableBtn(false);
                      deleteNewArticle(article.id);
                      setError(false);
                    } else {
                      setError(true);
                    }
                  }}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className="ml-1"
                  style={{ color: "#00468c" }}
                  onClick={(event: any) => {
                    deleteNewArticle(article.id);
                    event.stopPropagation();
                    setShowInput(false);
                    setDisableBtn(false);
                    setError(false);
                  }}
                />
              </div>
            </div>
          )
        }
        showArrow={false}
        // extra={genExtra()}
      >
        {/* {article.options &&
            article.options.map((option: any, i: number) => (
                <OptionCard
                key={i}
                optionSelected={optionSelected}
                option={option}
                setOptionSelected={setOptionSelected}
                />
            ))}
            <p
            className="add_new_option_text my-4"
            onClick={() => dispatch(selectOption("new", article.id))}
            >
            Add new option
            </p> */}
      </Panel>
    </Collapse>
  );
};

// const genExtra = () => {
//   return (
//     <Popover
//       placement="right"
//       content={() => (
//         <div className="popover_edit_order">
//           <p>Rename</p>
//           <p>Delete</p>
//         </div>
//       )}
//       trigger="click"
//     >
//       {/* {deleteArticleLoading && article.id === toDeleteArticle ? (
//           <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
//         ) : ( */}
//       <button
//         className="article_popover_btn"
//         onClick={(event) => event.stopPropagation()}
//       >
//         <FontAwesomeIcon icon={faEllipsisH} opacity={0.7} color={"#00468c"} />
//       </button>
//       {/* )} */}
//     </Popover>
//   );
// };
