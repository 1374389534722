import { useDispatch, useSelector } from "react-redux";
import { handleRightDrawer } from "../../actions/actionCreators";
import moment from "moment";
import Folder from "../../icons/Folder";
import { Link } from "react-router-dom";
import { directRenderSelection } from "../../actions/myselection-actions/actions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder_jeans from "../../img/placeholder_jeans.svg";

interface Props {
  notif: any;
  inPage: boolean;
  position: boolean;
}

export const SelectionIsWaiting = ({ inPage, notif, position }: Props) => {
  const dispatch = useDispatch();
  const { selectionProducts } = useSelector((state: any) => state.selection);
  return inPage ? (
    <Link
      to={"/myCollection2"}
      onClick={() => dispatch(directRenderSelection())}
      className="notif_item_page p-2 my-2"
    >
      <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center">
          {/*
          <div className="img_group_selection_notif_page icon_notif_container">
            {selectionProducts &&
              selectionProducts
                .slice(0, 2)
                .map((el: any, i: number) => (
                  <LazyLoadImage
                    key={i}
                    effect="blur"
                    className="added_product_img ml-2"
                    src={
                      el.images.length
                        ? process.env.REACT_APP_STORAGE_URL + el.images[0].path
                        : placeholder_jeans
                    }
                    alt={notif.data.name + notif.data.id}
                    wrapperClassName={"notif_img_wrapper"}
                  />
                ))}
          </div>
*/}
          {/* <Folder /> */}
        </div>
        <h2 className="text_notification m-0">
          You left behind some really nice products on Your Selection 🥰
        </h2>
      </div>
      <p className="selection_date m-0">{moment(notif.created_at).fromNow()}</p>
    </Link>
  ) : (
    <Link
      to={"/myCollection2"}
      onClick={() => {
        dispatch(handleRightDrawer());
        dispatch(directRenderSelection());
      }}
      className="notif_added_prod_container"
    >
      <article
        className="notif_selection order_changes selection_notif_drawer my-3 notif_added_prod_container my-2 border p-4"
        style={{
          borderRadius: "8px",
          background: position ? "#e5f1fa" : "white",
          transition: "all 5s ease-in-out",
        }}
      >
        {/* <div className="img_group_selection_notif">
          {selectionProducts &&
            selectionProducts
              .slice(0, 2)
              .map((el: any) => (
                <LazyLoadImage
                  effect="blur"
                  className="added_product_img ml-2"
                  src={
                    el.images.length
                      ? process.env.REACT_APP_STORAGE_URL + el.images[0].path
                      : placeholder_jeans
                  }
                  alt={notif.data.name + notif.data.id}
                  wrapperClassName={"notif_img_wrapper"}
                />
              ))}
        </div>*/}
        <div className="notif_details ml-0">
          <h2 className="text_notification">
            You left behind some really nice products on Your Selection 🥰
          </h2>
          <div>
            <p
              className="selection_date"
              style={{ fontSize: "0.7rem", fontWeight: 400 }}
            >
              {moment(notif.created_at).fromNow()}
            </p>
          </div>
        </div>
      </article>
    </Link>
  );
};
