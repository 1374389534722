import { useQuery } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../config/axios-instance";
import { ProductCard } from "./SearchResult";
import Head from "../components/Head";
import { useState } from "react";
import useWindowWidth from "../config/useWindowWidth";
import { AnimatePresence, motion } from "framer-motion";
import { FilterOutlined } from "@ant-design/icons";
import { Card, Drawer, Skeleton } from "antd";
import placeholder_jeans from "../img/placeholder_jeans.svg";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import "../sass/TagsPage.scss";
import "../sass/SearchResult.scss";
import InfiniteScroll from "react-infinite-scroll-component";

const { Meta } = Card;

const variants = {
  enter: (): object => {
    return {
      opacity: 0,
    };
  },
  center: (): object => {
    return {
      opacity: 1,
    };
  },
  exit: () => {
    return {
      opacity: 0,
    };
  },
};

export default function TagResult() {
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [allTags, setAllTags] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [loadingMoreTags, setLoadingMoreTags] = useState(false);
  const [productsPage, setProductsPage] = useState(1);
  const [productsByTag, setProductsByTag] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const width = useWindowWidth();
  const history = useHistory();

  function useQueryParams() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQueryParams();

  const fetchMoreTags = (paginate: number) => {
    setLoadingMoreTags(true);
    let token = localStorage.getItem("token");
    setPage(paginate);
    return axiosInstance({
      method: "get",
      url: `/tags_paginated?id=${query.get("q")}&page=${paginate}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setAllTags({ ...allTags, data: [...allTags.data, ...res.data.data] });
        setLoadingMoreTags(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingMoreTags(false);
      });
  };

  const fetchTags = (paginate: number = 1) => {
    let token = localStorage.getItem("token");
    setPage(paginate);
    return axiosInstance({
      method: "get",
      url: `/tags_paginated?page=${paginate}&id=${query.get("q")}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => setAllTags(res.data));
  };

  // const fetchMoreProducts = (page: number) => {
  //   let token = localStorage.getItem("token");
  //   return axiosInstance({
  //     method: "get",
  //     url: `/products/${query.get("q")}/tag`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // };
  const fetchProducts = async () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/products/${query.get("q")}/tag?page=${productsPage}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      if (productsPage === 1) {
        setProductsByTag(res.data);
      } else {
        setProductsByTag({
          ...productsByTag,
          data: [...productsByTag.data, ...res.data.data],
          next_page_url: res.data.next_page_url,
        });
      }
    });
  };

  const { isLoading, error } = useQuery<any>(
    [`productsByTag-${query.get("q")}`, productsPage],
    () => fetchProducts(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { isLoading: loadingTags, data: tags } = useQuery<any>(
    `tags-page`,
    () => fetchTags(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchData = async () => {
    setProductsPage((c) => c + 1);
  };

  if (error) return <p>Something wrong happened</p>;

  return (
    <div className="result_search">
      {width >= 1000 && (
        <aside
          className="result_search_filter"
          // style={{ maxWidth: "23rem", overflow: "scroll", position: "fixed" }}
        >
          <Head title="Search Result" />
          <h2 className="h2_subtitle_blue">Other Tags</h2>
          {loadingTags ? (
            <div className="d-flex flex-column w-100">
              {Array.from({ length: 14 }, (_, index) => index + 1).map(
                (_, j) => (
                  <div key={j}>
                    <Skeleton.Input
                      className="my-1 w-100"
                      size="large"
                      active
                    />
                  </div>
                )
              )}
            </div>
          ) : (
            <div className="d-flex flex-wrap">
              {allTags?.data.map((tag: any) => (
                <button
                  className={`tag-btn mr-2 my-1 ${
                    tag.id === Number(query.get("q")) ? "is-active" : ""
                  }`}
                  key={tag.id}
                  onClick={() =>
                    history.push(`/products?q=${tag.id}&tag=${tag.name}`)
                  }
                >
                  {tag.name}
                </button>
              ))}
              <button
                className="tag-btn load-tags mr-2 my-1 d-flex align-items-center"
                onClick={() => fetchMoreTags(page + 1)}
              >
                {loadingMoreTags ? "Loading..." : "Load more"}
                {loadingMoreTags ? (
                  <LoadingOutlined spin className="ml-2" />
                ) : (
                  <PlusOutlined className="ml-2" />
                )}
              </button>
            </div>
          )}
        </aside>
      )}

      <AnimatePresence exitBeforeEnter={false}>
        <main className="result_search_content" style={{ overflowY: "unset" }}>
          {width < 1000 && (
            <button
              className="filter_product_button"
              onClick={() => setOpenFilterDrawer(true)}
            >
              <FilterOutlined className="mr-2" /> Filter
            </button>
          )}
          <div className="result_search_content_header mb-2">
            <Drawer
              className="result_search_filter_drawer"
              title="FILTER"
              placement={"left"}
              closable={true}
              onClose={() => setOpenFilterDrawer(false)}
              visible={openFilterDrawer}
              key={"left"}
              width={"23rem"}
            >
              <div className="result_search_filter result_search_filter_drawer_content p-3">
                <h2 className="h2_subtitle_blue">Other Tags</h2>
                {loadingTags ? (
                  <div className="d-flex flex-column w-100">
                    {Array.from({ length: 14 }, (_, index) => index + 1).map(
                      (_, j) => (
                        <div key={j}>
                          <Skeleton.Input
                            className="my-1 w-100"
                            size="large"
                            active
                          />
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className="d-flex flex-wrap">
                    {allTags?.data.map((tag: any) => (
                      <button
                        className={`tag-btn mr-2 my-1 ${
                          tag.id === Number(query.get("q")) ? "is-active" : ""
                        }`}
                        key={tag.id}
                        onClick={() =>
                          history.push(`/products?q=${tag.id}&tag=${tag.name}`)
                        }
                      >
                        {tag.name}
                      </button>
                    ))}
                    <button
                      className="tag-btn load-tags mr-2 my-1 d-flex align-items-center"
                      onClick={() => fetchMoreTags(page + 1)}
                    >
                      {loadingMoreTags ? "Loading..." : "Load more"}
                      {loadingMoreTags ? (
                        <LoadingOutlined spin className="ml-2" />
                      ) : (
                        <PlusOutlined className="ml-2" />
                      )}
                    </button>
                  </div>
                )}
              </div>
            </Drawer>
            <div className="mb-4">
              {width > 768 && (
                <span className="mr-2">You're Searching For:</span>
              )}
              <span
                className="tag-btn"
                style={{ background: "#f4f7ff", color: "#00468c" }}
              >
                {query.get("tag")}
              </span>
            </div>
            <div className="result_text d-flex flex-column align-items-start">
              <span>
                {productsByTag.data && productsByTag?.total} Results Found
              </span>
            </div>
          </div>
          <motion.div
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            className="result_search_products"
          >
            <div className="rows result_search_datas">
              {/* {isLoading ? (
                <div className="row" style={{ gap: "2.2rem" }}>
                  {Array.from({ length: 10 }, (_, index) => index + 1).map(
                    (_, i) => (
                      <div className="col-12 col-md-2" key={i}>
                        <div className="d-flex flex-column">
                          <Card
                            loading={loading}
                            bordered={false}
                            style={{ width: "100%" }}
                            bodyStyle={{ padding: "5px" }}
                            cover={
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                                src={placeholder_jeans}
                              />
                            }
                          >
                            <Meta title={"Code"} description={"Name"} />
                          </Card>
                        </div>
                      </div>
                    )
                  )}{" "}
                </div>
              ) : ( */}
              <InfiniteScroll
                dataLength={productsPage * 30} //This is important field to render the next data
                next={fetchData}
                hasMore={productsByTag?.next_page_url}
                loader={<h4>Loading...</h4>}
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
                // below props only if you need pull down functionality
                // className="row result_search_data"
                className="products-grid"
                style={{ overflowY: "hidden" }}
              >
                {productsByTag.data &&
                  productsByTag?.data.map((el: any, i: number) => (
                    <ProductCard
                      key={el.id}
                      index={i}
                      productPage={true}
                      params={false}
                      product={el}
                      item={{
                        main: el.images[0] ? el.images[0].path : "",
                        onHover: el.images[1] ? el.images[1].path : "",
                      }}
                    />
                  ))}
              </InfiniteScroll>
              {/* )} */}
            </div>
          </motion.div>
        </main>
      </AnimatePresence>
    </div>
  );
}
