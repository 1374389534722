import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import logo_green from "../../img/logo_green.svg";
import backArrow from "../../img/backArrow.png";
import { JoinFormCompany } from "./JoinFormCompany";
import { JoinFormContactInfo } from "./JoinFormContactInfo";
import {ArrowLeftOutlined} from "@ant-design/icons";

//@ts-ignore
export interface IJoinFormProps {
  setSuccess: (val: boolean) => void;
  setForgot: (x: string) => void;
}

export const JoinForm = ({ setSuccess, setForgot }: IJoinFormProps) => {
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    company: "",
    job_title: "",
    country: "",
  });

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    // <AnimatePresence initial={false} exitBeforeEnter={true}>
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      className="login_form_container_initial "
    >
      <div className="d-flex justify-content-between ">
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            className="back_arrow_back_link"
        >
          {/*  <img
              src={backArrow}
              alt="Back Arrow"
              style={{cursor: "pointer"}}
              onClick={() => setForgot("center")}
          />
          <p className="back_sign_in ml-1">Back to Sign in</p>*/}
          <div className={
            "login_go_back"
          }

               onClick={() => {
                 setForgot("center");
               }}>

            <ArrowLeftOutlined
                onClick={() => {
                  setForgot("center");
                }}
            ></ArrowLeftOutlined>{" "}
            <button
                className={"go_back_text"}

            >
              Go back
            </button>
          </div>
        </motion.div>
        <div className="login_form_forgot_top login_form_forgot_top_join">
          <img src={logo_green} alt="logo Demco" className="login_form_logo"/>
        </div>

      </div>

      <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
      >
        <h2 className="h2_subtitle_blue h2_subtitle_blue_join">Join DEMCO</h2>
        <p className="paragraphe">
          Please complete the information below to join us !{" "}
        </p>
        <form>
      {/*    {step === 0 ? (
              <JoinFormContactInfo
                  setStep={setStep}
                  handleChange={handleChange}
                  data={data}
              setErrors={setErrors}
              errors={errors}
            />
          ) : (
            <JoinFormCompany
              setStep={setStep}
              setSuccess={setSuccess}
              handleChange={handleChange}
              data={data}
              setErrors={setErrors}
              errors={errors}
              setForgot={(x: string) => setForgot(x)}
            />
          )}*/}
       {/*   <JoinFormContactInfo
              setStep={setStep}
              handleChange={handleChange}
              data={data}
              setErrors={setErrors}
              errors={errors}
          />*/}
          <JoinFormCompany
              setStep={setStep}
              setSuccess={setSuccess}
              handleChange={handleChange}
              data={data}
              setErrors={setErrors}
              errors={errors}
              setForgot={(x: string) => setForgot(x)}
          />
        </form>
      </motion.div>
    </motion.div>
    // </AnimatePresence>
  );
};
