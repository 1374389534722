import moment from "moment";
import { Avatar } from "antd";
import { Link, useHistory } from "react-router-dom";

const NormalOrderTableBody = ({ data }: any) => {
  const history = useHistory();
  return (
    data &&
    data.data.products.map((product: any, i: number) => (
      <tr key={i}>
        <td>
          <p className="mb-0">{product.id}</p>
        </td>
        <td>
          <Avatar
            src={process.env.REACT_APP_STORAGE_URL + product.front_image}
            size={54}
            alt={product.name}
            shape="square"
          />
          <Link
            to={`/product/details/${product.name}/${product.id}`}
            className="ml-3"
          >
            {product.code ? product.code + " " + product.name : product.name}
          </Link>
        </td>
        <td>
          <p className="mb-0">{product.status.name}</p>
        </td>
        <td>
          <p className="mb-0">
            {moment(data && data.data.created_at).format("MMMM DD YYYY")}
          </p>
        </td>
        <td align="center">
          <Link
            to={`${history.location.pathname}/${product.id}/progress/order`}
          >
            See details
          </Link>
        </td>
      </tr>
    ))
  );
};

export default NormalOrderTableBody;
