export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAIL = "GET_ORDER_FAIL";

export const GET_ONE_ORDER_REQUEST = "GET_ONE_ORDER_REQUEST";
export const GET_ONE_ORDER_SUCCESS = "GET_ONE_ORDER_SUCCESS";
export const GET_ONE_ORDER_FAIL = "GET_ONE_ORDER_FAIL";

export const ADD_ORDER_REQUEST = "ADD_ORDER_REQUEST";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";

export const UPDATE_OPTION_REQUEST = "UPDATE_OPTION_REQUEST";
export const UPDATE_OPTION_SUCCESS = "UPDATE_OPTION_SUCCESS";
export const UPDATE_OPTION_FAIL = "UPDATE_OPTION_FAIL";

export const GET_OPTION_REQUEST = "GET_OPTION_REQUEST";
export const GET_OPTION_SUCCESS = "GET_OPTION_SUCCESS";
export const GET_OPTION_FAIL = "GET_OPTION_FAIL";

export const DELETE_OPTION_REQUEST = "DELETE_OPTION_REQUEST";
export const DELETE_OPTION_SUCCESS = "DELETE_OPTION_SUCCESS";
export const DELETE_OPTION_FAIL = "DELETE_OPTION_FAIL";

export const DELETE_OPTION_FILE_REQUEST = "DELETE_OPTION_FILE_REQUEST";
export const DELETE_OPTION_FILE_SUCCESS = "DELETE_OPTION_FILE_SUCCESS";
export const DELETE_OPTION_FILE_FAIL = "DELETE_OPTION_FILE_FAIL";

export const DUPLICATE_OPTION_REQUEST = "DUPLICATE_OPTION_REQUEST";
export const DUPLICATE_OPTION_SUCCESS = "DUPLICATE_OPTION_SUCCESS";
export const DIRECRT_PRODUCT_SUCCESS = "DIRECRT_PRODUCT_SUCCESS";
export const DUPLICATE_OPTION_FAIL = "DUPLICATE_OPTION_FAIL";

export const SELECT_OPTION = "SELECT_OPTION";
export const SELECT_ARTICLE = "SELECT_ARTICLE";

export const DELETE_ARTICLE_REQUEST = "DELETE_ARTICLE_REQUEST";
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS";
export const DELETE_ARTICLE_FAIL = "DELETE_ARTICLE_FAIL";

export const UPDATE_ARTICLE_REQUEST = "UPDATE_ARTICLE_REQUEST";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE_FAIL = "UPDATE_ARTICLE_FAIL";

export const CREATE_ARTICLE_REQUEST = "CREATE_ARTICLE_REQUEST";
export const CREATE_ARTICLE_SUCCESS = "CREATE_ARTICLE_SUCCESS";
export const CREATE_ARTICLE_FAIL = "CREATE_ARTICLE_FAIL";

export const GET_COLLABORATOR_REQUEST = "GET_COLLABORATOR_REQUEST";
export const GET_COLLABORATOR_SUCCESS = "GET_COLLABORATOR_SUCCESS";
export const GET_COLLABORATOR_FAIL = "GET_COLLABORATOR_FAIL";

export const ADD_COLLABORATOR_REQUEST = "ADD_COLLABORATOR_REQUEST";
export const ADD_COLLABORATOR_SUCCESS = "ADD_COLLABORATOR_SUCCESS";
export const ADD_COLLABORATOR_FAIL = "ADD_COLLABORATOR_FAIL";

export const DELETE_COLLABORATOR_REQUEST = "DELETE_COLLABORATOR_REQUEST";
export const DELETE_COLLABORATOR_SUCCESS = "DELETE_COLLABORATOR_SUCCESS";
export const DELETE_COLLABORATOR_FAIL = "DELETE_COLLABORATOR_FAIL";

export const GET_ORDER_COLLABORATOR_REQUEST = "GET_ORDER_COLLABORATOR_REQUEST";
export const GET_ORDER_COLLABORATOR_SUCCESS = "GET_ORDER_COLLABORATOR_SUCCESS";
export const GET_ORDER_COLLABORATOR_FAIL = "GET_ORDER_COLLABORATOR_FAIL";

export const GET_STATUS_REQUEST = "GET_STATUS_REQUEST";
export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS";
export const GET_STATUS_FAIL = "GET_STATUS_FAIL";

export const ADD_FEEDBACK_REQUEST = "ADD_FEEDBACK_REQUEST";
export const ADD_FEEDBACK_SUCCESS = "ADD_FEEDBACK_SUCCESS";
export const ADD_FEEDBACK_FAIL = "ADD_FEEDBACK_FAIL";

export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAIL = "ADD_COMMENT_FAIL";

export const GET_ONE_ARTICLE_REQUEST = "GET_ONE_ARTICLE_REQUEST";
export const GET_ONE_ARTICLE_SUCCESS = "GET_ONE_ARTICLE_SUCCESS";
export const GET_ONE_ARTICLE_FAIL = "GET_ONE_ARTICLE_FAIL";

export const SEND_REQ_ACCESS_REQUEST = "SEND_REQ_ACCESS_REQUEST";
export const SEND_REQ_ACCESS_SUCCESS = "SEND_REQ_ACCESS_SUCCESS";
export const SEND_REQ_ACCESS_FAIL = "SEND_REQ_ACCESS_FAIL";

export const ACCEPT_REQ_ACCESS_REQUEST = "ACCEPT_REQ_ACCESS_REQUEST";
export const ACCEPT_REQ_ACCESS_SUCCESS = "ACCEPT_REQ_ACCESS_SUCCESS";
export const ACCEPT_REQ_ACCESS_FAIL = "ACCEPT_REQ_ACCESS_FAIL";

export const ADD_DEMCO_PRODUCT_ORDER_REQUEST =
  "ADD_DEMCO_PRODUCT_ORDER_REQUEST";
export const ADD_DEMCO_PRODUCT_ORDER_SUCCESS =
  "ADD_DEMCO_PRODUCT_ORDER_SUCCESS";
export const ADD_DEMCO_PRODUCT_ORDER_FAIL = "ADD_DEMCO_PRODUCT_ORDER_FAIL";

export const GET_NORMAL_ORDER_REQUEST = "GET_NORMAL_ORDER_REQUEST";
export const GET_NORMAL_ORDER_SUCCESS = "GET_NORMAL_ORDER_SUCCESS";
export const GET_NORMAL_ORDER_FAIL = "GET_NORMAL_ORDER_FAIL";

export const ADD_NORMAL_ORDER_COMMENT_REQUEST =
  "ADD_NORMAL_ORDER_COMMENT_REQUEST";
export const ADD_NORMAL_ORDER_COMMENT_SUCCESS =
  "ADD_NORMAL_ORDER_COMMENT_SUCCESS";
export const ADD_NORMAL_ORDER_COMMENT_FAIL = "ADD_NORMAL_ORDER_COMMENT_FAIL";

export const CREATE_CUSTOMIZED_PRODUCT_REQUEST =
  "CREATE_CUSTOMIZED_PRODUCT_REQUEST";
export const CREATE_CUSTOMIZED_PRODUCT_SUCCESS =
  "CREATE_CUSTOMIZED_PRODUCT_SUCCESS";
export const CREATE_CUSTOMIZED_PRODUCT_FAIL = "CREATE_CUSTOMIZED_PRODUCT_FAIL";

export const SEND_CUSTOMIZED_PRODUCT_REQUEST =
  "SEND_CUSTOMIZED_PRODUCT_REQUEST";
export const SEND_CUSTOMIZED_PRODUCT_SUCCESS =
  "SEND_CUSTOMIZED_PRODUCT_SUCCESS";
export const SEND_CUSTOMIZED_PRODUCT_FAIL = "SEND_CUSTOMIZED_PRODUCT_FAIL";

export const SAVE_CUSTOMIZED_PRODUCT_REQUEST =
  "SAVE_CUSTOMIZED_PRODUCT_REQUEST";
export const SAVE_CUSTOMIZED_PRODUCT_SUCCESS = "SAVE_CUSTOMIZED_PRODUCTUCCESS";
export const SAVE_CUSTOMIZED_PRODUCT_FAIL = "SAVE_CUSTOMIZED_PRODUCT_FAIL";

export const UPDATE_QUANTITY_NORMAL_PRODUCT_REQUEST =
  "UPDATE_QUANTITY_NORMAL_PRODUCT_REQUEST";
export const UPDATE_QUANTITY_NORMAL_PRODUCT_SUCCESS =
  "UPDATE_QUANTITY_NORMAL_PRODUCT_SUCCESS";
export const UPDATE_QUANTITY_NORMAL_PRODUCT_FAIL =
  "UPDATE_QUANTITY_NORMAL_PRODUCT_FAIL";

export const CHANGE_ORDER_TYPE_TAB = "CHANGE_ORDER_TYPE_TAB";

export const GET_COMPANY_ORDER_REQUEST = "GET_COMPANY_ORDER_REQUEST";
export const GET_COMPANY_ORDER_SUCCESS = "GET_COMPANY_ORDER_SUCCESS";
export const GET_COMPANY_ORDER_FAIL = "GET_COMPANY_ORDER_FAIL";


export const GET_HERO_REQUEST = "GET_HERO_REQUEST";
export const GET_HERO_SUCCESS = "GET_HERO_SUCCESS";
export const GET_HERO_FAIL = "GET_HERO_FAIL";