import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_ALL_TYPES_REQUEST,
  GET_ALL_TYPES_SUCCESS,
  GET_ALL_TYPES_FAIL,
  FILTER_PRODUCTS_REQUEST,
  FILTER_PRODUCTS_SUCCESS,
  FILTER_PRODUCTS_FAIL,
  GET_PRODUCTS_FILTERED_REQUEST,
  GET_PRODUCTS_FILTERED_SUCCESS,
  GET_PRODUCTS_FILTERED_FAIL,
  ADD_FILTER,
  DELETE_FILTER,
  ONTYPING_PRODUCTS_FILTER_REQUEST,
  ONTYPING_PRODUCTS_FILTER_SUCCESS,
  ONTYPING_PRODUCTS_FILTER_FAIL,
  GET_ONE_PRODUCT_REQUEST,
  GET_ONE_PRODUCT_SUCCESS,
  GET_ONE_PRODUCT_FAIL,
  GET_PRODUCTS_NEXT_REQUEST,
  GET_PRODUCTS_NEXT_SUCCESS,
  GET_PRODUCTS_NEXT_FAIL,
  FILTER_PRODUCTS_KEEP_ALL_REQUEST,
  FILTER_PRODUCTS_KEEP_ALL_SUCCESS,
  FILTER_PRODUCTS_KEEP_ALL_FAIL,
  CLEAR_ALL_FILTERS,
  LOAD_PRODUCTS_FILTER_REQUEST,
  LOAD_PRODUCTS_FILTER_SUCCESS,
  LOAD_PRODUCTS_FILTER_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  GET_FILTER_REQUEST,
  GET_FILTER_SUCCESS,
  GET_FILTER_FAIL,
} from "./types";
import ProductServices from "./services";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";

// GET ALL PRODUCTS
export const getAllProducts = (page: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_PRODUCTS_REQUEST,
  });
  try {
    const response = await ProductServices.getProducts(page);
    await dispatch({
      type: GET_PRODUCTS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_PRODUCTS_FAIL,
      payload: error,
    });
  }
};

// GET ALL PRODUCTS
export const getProductsNextPage = (page: any) => async (dispatch: any) => {
  await dispatch({
    type: GET_PRODUCTS_NEXT_REQUEST,
  });
  try {
    const response = await ProductServices.getProducts(page);
    await dispatch({
      type: GET_PRODUCTS_NEXT_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_PRODUCTS_NEXT_FAIL,
      payload: error,
    });
  }
};

// GET ALL PRODUCTS
export const getCollectionProductsNextPage =
  (url: any) => async (dispatch: any) => {
    await dispatch({
      type: GET_PRODUCTS_NEXT_REQUEST,
    });
    try {
      const response = await ProductServices.getCollectionProducts(url);
      await dispatch({
        type: GET_PRODUCTS_NEXT_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_PRODUCTS_NEXT_FAIL,
        payload: error,
      });
    }
  };

// GET PRODUCTS FILTERED
export const getProductsFiltered =
  (
    fabric_type: string,
    CategoryId: string,
    genderId: string,
    min_price?: any,
    max_price?: any
  ) =>
  async (dispatch: any) => {
    await dispatch({
      type: GET_PRODUCTS_FILTERED_REQUEST,
    });
    try {
      const response = await ProductServices.filterProductFabric(
        fabric_type,
        CategoryId,
        genderId,
        min_price,
        max_price
      );
      await dispatch({
        type: GET_PRODUCTS_FILTERED_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_PRODUCTS_FILTERED_FAIL,
        payload: error,
      });
    }
  };

// GET TYPE
export const getType = (type: string) => async (dispatch: any) => {
  await dispatch({
    type: GET_ALL_TYPES_REQUEST,
  });
  try {
    const response = await ProductServices.getAllType(type);
    await dispatch({
      type: GET_ALL_TYPES_SUCCESS,
      payload: {
        data: response.data,
        type,
      },
    });
  } catch (error: any) {
    dispatch({
      type: GET_ALL_TYPES_FAIL,
      payload: error.response,
    });
  }
};

// GET FILTER
export const getFilterAttributes =
  (fabric_type: string, categroyId: any, genderId: any) =>
  async (dispatch: any) => {
    await dispatch({
      type: GET_FILTER_REQUEST,
    });
    try {
      const response = await ProductServices.getFilters(
        fabric_type,
        categroyId,
        genderId
      );
      await dispatch({
        type: GET_FILTER_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      // MyNotif("Something wrong while loading filter.", "danger");
      dispatch({
        type: GET_FILTER_FAIL,
        payload: error.response,
      });
    }
  };

// POST FILTER
export const filter = (url: string) => async (dispatch: any) => {
  await dispatch({
    type: FILTER_PRODUCTS_REQUEST,
  });
  try {
    const response = await ProductServices.filterProduct(url);
    await dispatch({
      type: FILTER_PRODUCTS_SUCCESS,
      payload: {
        data: response.data,
        url,
      },
    });
  } catch (error: any) {
    dispatch({
      type: FILTER_PRODUCTS_FAIL,
      payload: error.response,
    });
  }
};

// POST FILTER
export const filterAndKeepAll = (url: string) => async (dispatch: any) => {
  await dispatch({
    type: FILTER_PRODUCTS_KEEP_ALL_REQUEST,
  });
  try {
    const response = await ProductServices.filterProduct(url);
    await dispatch({
      type: FILTER_PRODUCTS_KEEP_ALL_SUCCESS,
      payload: {
        data: response.data,
        url,
      },
    });
  } catch (error: any) {
    dispatch({
      type: FILTER_PRODUCTS_KEEP_ALL_FAIL,
      payload: error.response,
    });
  }
};

export const addFilter = (filter: any) => (dispatch: any) => {
  return dispatch({
    type: ADD_FILTER,
    payload: filter,
  });
};

export const deleteFilter = (filter: any) => (dispatch: any) => {
  return dispatch({
    type: DELETE_FILTER,
    payload: filter,
  });
};

export const clearAllFilters = () => (dispatch: any) => {
  return dispatch({
    type: CLEAR_ALL_FILTERS,
    payload: filter,
  });
};

export const filterByTyping = (name: string) => async (dispatch: any) => {
  await dispatch({
    type: ONTYPING_PRODUCTS_FILTER_REQUEST,
  });

  try {
    const response = await ProductServices.filterByName(name);
    await dispatch({
      type: ONTYPING_PRODUCTS_FILTER_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: ONTYPING_PRODUCTS_FILTER_FAIL,
      payload: error.response,
    });
  }
};
export const loadMoreSearchResult = (url: string) => async (dispatch: any) => {
  await dispatch({
    type: LOAD_PRODUCTS_FILTER_REQUEST,
  });

  try {
    const response = await ProductServices.loadMoreSearchResult(url);
    await dispatch({
      type: LOAD_PRODUCTS_FILTER_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: LOAD_PRODUCTS_FILTER_FAIL,
      payload: error.response,
    });
  }
};

export const getProduct = (id: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_ONE_PRODUCT_REQUEST,
  });
  try {
    const response = await ProductServices.getOneProduct(id);
    await dispatch({
      type: GET_ONE_PRODUCT_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_ONE_PRODUCT_FAIL,
      payload: error.response,
    });
  }
};
export const getCustomizedProduct = (id: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_ONE_PRODUCT_REQUEST,
  });
  try {
    const response = await ProductServices.getCustomizedProduct(id);
    await dispatch({
      type: GET_ONE_PRODUCT_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_ONE_PRODUCT_FAIL,
      payload: error.response,
    });
  }
};

// Next product
export const nextProd = (product: any) => (dispatch: any) => {
  return dispatch({
    type: GET_ONE_PRODUCT_SUCCESS,
    payload: product,
  });
};
// Prev product
export const prevProd = (product: any) => (dispatch: any) => {
  return dispatch({
    type: GET_ONE_PRODUCT_SUCCESS,
    payload: product,
  });
};

// UPDATE PRODUCT
export const updateProduct =
  (data: any, id: number, setEditMode: any) => async (dispatch: any) => {
    await dispatch({
      type: UPDATE_PRODUCT_REQUEST,
    });
    try {
      const response = await ProductServices.updateProduct(data, id);
      await dispatch({
        type: UPDATE_PRODUCT_SUCCESS,
        payload: response.data,
      });
      setEditMode(false);
      MyNotif("Successfully modified product", "success");
    } catch (error: any) {
      dispatch({
        type: UPDATE_PRODUCT_FAIL,
        payload: error.response,
      });
      MyNotif("Something went wrong!", "danger");
    }
  };
