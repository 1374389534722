import React, { ReactElement, useState, useEffect } from "react";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../Button/Button";
//@ts-ignore
import jeansredesign from "../../video/circularjeansid.mp4";
import { VideoModal } from "../VideoModal/VideoModal";
import TextArea from "antd/lib/input/TextArea";
import { DatePicker, Popconfirm, Select, Skeleton, Spin } from "antd";
import { useHistory } from "react-router-dom";
import {
  addOrder,
  getCollaborators,
  getOrderCollaborators,
  getHeroHome,
} from "../../actions/order-actions/actions";
import Avatar from "antd/lib/avatar/avatar";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { SelectGender } from "./SelectGender";
import { getGenders } from "../../actions/left-menu-actions/actions";
import { LoadingOutlined } from "@ant-design/icons";
import {
  finish,
  goNextStep,
  goPrevStep,
} from "../../actions/guided-tour-actions/actions";
import { default as SelectType, components } from "react-select";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import axiosInstance from "../../config/axios-instance";
const { Option } = Select;

export default function Header(): ReactElement {
  const [data, setData] = useState<any>({
    name: "",
    details: "",
    collaborator: [],
  });
  const [lastFetchId, setLastFetchId] = useState(0);
  const [state, setState] = useState<any>({
    users: [],
    value: [],
    fetching: false,
  });
  const [allGenders, setAllGenders] = useState<any>(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [heroContent, setHeroContent] = useState<any>(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { newOrder, collaborators, createOrderLoading } = useSelector(
    (state: any) => state.orders
  );
  const leftMenu = useSelector((state: any) => state.leftMenu);
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const { step } = useSelector((state: any) => state.tour);

  useEffect(() => {
    if (newOrder) {
      dispatch(getOrderCollaborators(newOrder.id));
      history.push(`/edit-order/${newOrder.id}`);
    }
  }, [newOrder]);

  useEffect(() => {
    if (user && !user.last_login) {
      let overlay = Array.from(document.body.children).find((el: any) =>
        el.textContent.includes("You can save product here ")
      );
      if (overlay) {
        // @ts-ignore
        overlay.style.height = "100%";
        // @ts-ignore
        overlay.style.background = "#00000059";
        // @ts-ignore
        overlay.style.position = "fixed";
        // @ts-ignore
        document.body.style.overflow = "hidden";
      }
    }
  }, [user]);

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (collaborators) {
      const users = collaborators.map((user: any) => ({
        id: user.id,
        name: user.name,
        picture: user.logo,
      }));
      setState({ ...state, users, fetching: false });
    }
  }, [collaborators]);

  // GET COLLEAGUES
  const fetchUser = (value: any) => {
    setLastFetchId(lastFetchId + 1);
    setState({ ...state, users: [], fetching: true });
    dispatch(getCollaborators(value));
  };

  const handleChange2 = (value: any) => {
    setData({ ...data, collaborator: value.map((el: any) => el.value) });
    setState({
      ...state,
      value,
      users: [],
      fetching: false,
    });
  };

  const videoSource = jeansredesign;
  /*  const videoSource =
    "https://demcointerv2.s3.eu-central-1.amazonaws.com/profile/252/circularjeansid.mp4"; */

  const getHeroHome = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/home/hero`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setHeroContent(res.data);
    });
  };

  useEffect(() => {
    getHeroHome();
  }, []);
  const inlineVideo = () => {
    return (
      <div
        className="video_wrapper"
        dangerouslySetInnerHTML={{
          __html: `
      <video id="videoBG"  webkit-playsinline="true" autoplay="true"    preload="metadata" muted="true"  loop playsinline="true" id="video-id-auto">
      <source src=${videoSource} type="video/mp4" ></source>
     </video>
     
    `,
        }}
      />
    );
  };

  React.useEffect(() => {
    dispatch(getGenders());
    const video = document.getElementById("video-id-auto");
    //@ts-ignore
  }, []);

  React.useEffect(() => {
    if (leftMenu.allGenders.data) {
      setAllGenders(
        leftMenu.allGenders.data.map((el: any) => {
          return {
            label: el.name,
            value: el.id,
          };
        })
      );
    }
  }, [leftMenu.allGenders]);

  React.useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  const customStyles = {
    //@ts-ignore
    option: (styles: any, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#00468c" : null,
        color: isSelected ? "white" : "black",
      };
    },
    control: (base: any) => ({
      ...base,
      border: 0,
      borderBottom: "1px solid #ccc",
      borderRadius: 0,
      padding: 0,
      // This line disable the blue border
      boxShadow: "none",
      "&:hover": {
        outine: "none",
      },
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingLeft: 0,
      paddingBottom: 0,
    }),
    menu: (base: any) => ({
      ...base,
      marginTop: 0,
      color: "#00468c",
    }),
    valueContainer: (base: any) => ({
      ...base,
      paddingLeft: 0,
      fontSize: 18,
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: "none",
    }),
    colors: (base: any) => ({
      ...base,
      primary: "#00468c",
      primary25: "#00468c",
    }),
  };

  const options = [
    { value: "knit", label: "Knit" },
    { value: "denim", label: "Denim" },
  ];

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faCaretDown} />
      </components.DropdownIndicator>
    );
  };
  const [isFocused, setFocused] = useState(false);
  const handleFocus = (date: Date) => {
    console.log(date);
    if (date) {
      setFocused(true);
    } else {
      setFocused(false);
    }
  };

  const [minDate, setMinDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [maxDate, setMaxDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 15);
    return date.toISOString().split("T")[0];
  });

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
  };

  useEffect(() => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 15
    )
      .toISOString()
      .split("T")[0];
    console.log(minDate);
    setMinDate(minDate);
  }, []);

  return (
    <header className="home_header">
      {/*{inlineVideo()}*/}
      <div
        className="video_wrapper"
        dangerouslySetInnerHTML={{
          __html: `
            <video 
                webkit-playsinline 
                autoplay 
                preload="metadata" 
                muted 
                loop 
                playsinline
            >
                <source src="${process.env.REACT_APP_STORAGE_URL}${heroContent?.hero_video}" type="video/mp4" />
            </video>
        `,
        }}
      />
      {/* <img src={mouse} alt="scroll down" className="mouse_scroll" /> */}
      {width >= 769 && (
        <div className="mouse_scroll d-flex flex-column align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.5"
            height="30"
            viewBox="0 0 22.5 30"
          >
            <path
              id="Path_3389"
              data-name="Path 3389"
              d="M13.125-26.25H9.375A9.379,9.379,0,0,0,0-16.875v11.25A9.379,9.379,0,0,0,9.375,3.75h3.75A9.379,9.379,0,0,0,22.5-5.625v-11.25A9.379,9.379,0,0,0,13.125-26.25Zm7.5,20.625a7.512,7.512,0,0,1-7.5,7.5H9.375a7.512,7.512,0,0,1-7.5-7.5v-11.25a7.512,7.512,0,0,1,7.5-7.5h3.75a7.512,7.512,0,0,1,7.5,7.5ZM11.25-21.562A2.817,2.817,0,0,0,8.438-18.75v1.875a2.814,2.814,0,0,0,2.813,2.813,2.814,2.814,0,0,0,2.813-2.812V-18.75A2.817,2.817,0,0,0,11.25-21.562Zm.938,4.688a.938.938,0,0,1-.937.938.938.938,0,0,1-.937-.937V-18.75a.938.938,0,0,1,.938-.937.938.938,0,0,1,.938.938Z"
              transform="translate(0 26.25)"
              fill="#fff"
            />
          </svg>
          <p className="m-0 mt-1">Scroll Down</p>
        </div>
      )}

      <div className="header__content">
        <div className="header__titles">
          <div>
            {!heroContent ? (
              <>
                {" "}
                <Skeleton active={true} />
              </>
            ) : (
              <>
                <h1
                  className={`${
                    isAdmin ? "text-center" : "text-center"
                  } h1_huge_light`}
                >
                  {heroContent?.hero_title1} <br />
                  <span>{heroContent?.hero_title2}</span>
                </h1>
              </>
            )}
          </div>
          {user && isAdmin && (
            <div className="text-center">
              <VideoModal />
            </div>
          )}
          {/*   {user && !isAdmin && (
            <div className=" header_btns_wrapper">
              <Link to="/myCollection2">
                <button className="submit_button_white">
                  Manage Your Collections
                </button>
              </Link>
              <VideoModal />


            </div>
          )}*/}
          {user && !isAdmin && (
            <div className="row text-center justify-content-between action-hero">
              <div className={"col-md-6 col-sm-6"}>
                <Link to="/myCollection2">
                  <button className="submit_button_white_hero">
                    Manage Your Collections
                  </button>
                </Link>
              </div>
              <div className={"col-md-6 col-sm-6"}>
                <VideoModal />
              </div>
            </div>
          )}
        </div>
        {/*   {user && !isAdmin && (
          <div className="header__order">
            <div className="header__order_card">
              <h1 className="h1_title h1_title_bold">DIRECT ORDER</h1>
              <p className="paragraphe">Enter your order's information </p>
              {user.fabric_type != "knit_denim" && (
                <div className="field">
                  <input
                    type="text"
                    name="name"
                    id="order_name"
                    onChange={handleChange}
                    placeholder=" "
                  />
                  <label htmlFor="order_name" className="login_email_label">
                    Name Your Order
                  </label>
                </div>
              )}
              {user.fabric_type == "knit_denim" && (
                <div className="row align-items-end mb-3">
                  <div className="col-6 p-0 pr-3">
                    <div className="field">
                      <input
                        type="text"
                        name="name"
                        id="order_name"
                        onChange={handleChange}
                        placeholder=" "
                      />
                      <label htmlFor="order_name" className="login_email_label">
                        Name Your Order
                      </label>
                    </div>
                  </div>
                  <div className="col-6 p-0 pl-3">
                    <div className="field">
                      <SelectType
                        name="type"
                        placeholder={
                          <div className="react-select-placeholder-input">
                            Fabric type
                          </div>
                        }
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                        options={
                          options &&
                          options.map((el: any) => {
                            return {
                              label: el.label,
                              value: el.value,
                            };
                          })
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handlerType}
                      ></SelectType>
                    </div>
                  </div>
                </div>
              )}

              <div className="row align-items-end mb-3">
                <div className="col-6 p-0 pr-3">
                  <div className="field">
                        <SelectGender
                      allGenders={allGenders}
                      handleChangeGender={handleChangeGender}
                    />
                    <input
                      type={isFocused ? "date" : "text"}
                      //   type="date"
                      name="delivery_date"
                      id="delivery_date"
                      onChange={handleChangeDeliveryDate}
                      placeholder=" "
                      onFocus={() => handleFocus(new Date())}

                    />
                    <label
                      htmlFor="delivery_date"
                      className="login_email_label"
                    >
                      Delivery date
                    </label>
                  </div>
                </div>
                <div className="col-6 p-0 pl-3">
                  <div className="field">
                    <input
                      type="text"
                      name="season"
                      id="order_season"
                      onChange={handleChange}
                      placeholder=" "
                    />
                    <label htmlFor="order_season" className="login_email_label">
                      Season
                    </label>
                  </div>
                </div>
              </div>

              <TextArea
                placeholder="Details"
                autoSize={{ minRows: 5, maxRows: 6 }}
                className="textarea_direct_order"
                name="details"
                onChange={handleChange}
              />

              <Select
                mode="multiple"
                labelInValue
                value={state.value}
                placeholder="Select Collaborator"
                notFoundContent={state.fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={fetchUser}
                onChange={handleChange2}
                style={{ width: "100%" }}
              >
                {state.users.map((d: any) => (
                  <Option key={d.id} value={d.id}>
                    {d.picture ? (
                      <>
                        <Avatar
                          size="small"
                          src={process.env.REACT_APP_STORAGE_URL + d.picture}
                          icon={<UserOutlined />}
                        />{" "}
                        {d.name}{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <Avatar size="small" icon={<UserOutlined />} /> {d.name}
                      </>
                    )}
                  </Option>
                ))}
              </Select>
              {createOrderLoading ? (
                <Button
                  buttonClass="submit_button_blue"
                  innerButton={
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 24, color: "white" }}
                          spin
                        />
                      }
                    />
                  }
                  onClick={() => dispatch(addOrder(data))}
                />
              ) : (
                <Button
                  buttonClass="submit_button_blue"
                  innerButton="Continue"
                  onClick={() => dispatch(addOrder(data))}
                />
              )}
            </div>
            <Popconfirm
              placement="bottomLeft"
              visible={step === 3}
              overlayStyle={{ maxWidth: 360, maxHeight: 95 }}
              title={
                <span style={{ whiteSpace: "pre-line" }}>
                  Add collaborators and create your first direct order faster
                  from here!
                </span>
              }
              onConfirm={() => dispatch(goNextStep())}
              onCancel={() => dispatch(goPrevStep())}
              okText="Next"
              cancelText="Previous"
            />
          </div>
        )}*/}
      </div>
    </header>
  );
}
