export const GET_FOLDERS_REQUEST = "GET_FOLDERS_REQUEST";
export const GET_FOLDERS_SUCCESS = "GET_FOLDERS_SUCCESS";
export const GET_FOLDERS_FAIL = "GET_FOLDERS_FAIL";

export const DELETE_FOLDER_REQUEST = "DELETE_FOLDER_REQUEST";
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";
export const DELETE_FOLDER_FAIL = "DELETE_FOLDER_FAIL";

export const GET_ARCHIVED_FOLDERS_REQUEST = "GET_ARCHIVED_FOLDERS_REQUEST";
export const GET_ARCHIVED_FOLDERS_SUCCESS = "GET_ARCHIVED_FOLDERS_SUCCESS";
export const GET_ARCHIVED_FOLDERS_FAIL = "GET_ARCHIVED_FOLDERS_FAIL";

export const GET_ONE_FOLDER_REQUEST = "GET_ONE_FOLDER_REQUEST ";
export const GET_ONE_FOLDER_SUCCESS = "GET_ONE_FOLDER_SUCCESS ";
export const GET_ONE_FOLDER_FAIL = "GET_ONE_FOLDER_FAIL ";

export const GET_FOLDER_DETAILS_REQUEST = "GET_FOLDER_DETAILS_REQUEST ";
export const GET_FOLDER_DETAILS_SUCCESS = "GET_FOLDER_DETAILS_SUCCESS ";
export const GET_FOLDER_DETAILS_FAIL = "GET_FOLDER_DETAILS_FAIL ";

export const CLEAR_FOLDER_DETAILS = "CLEAR_FOLDER_DETAILS ";

export const ADD_ONE_FOLDER_REQUEST = "ADD_ONE_FOLDER_REQUEST ";
export const ADD_ONE_FOLDER_SUCCESS = "ADD_ONE_FOLDER_SUCCESS ";
export const ADD_ONE_FOLDER_FAIL = "ADD_ONE_FOLDER_FAIL ";

export const GET_RIGHT_FOLDER_REQUEST = "GET_RIGHT_FOLDER_REQUEST ";
export const GET_RIGHT_FOLDER_SUCCESS = "GET_RIGHT_FOLDER_SUCCESS ";
export const GET_RIGHT_FOLDER_FAIL = "GET_RIGHT_FOLDER_FAIL ";

export const GET_RIGHT_SUB_FOLDERS_REQUEST = "GET_RIGHT_SUB_FOLDERS_REQUEST ";
export const GET_RIGHT_SUB_FOLDERS_SUCCESS = "GET_RIGHT_SUB_FOLDERS_SUCCESS ";
export const GET_RIGHT_SUB_FOLDERS_FAIL = "GET_RIGHT_SUB_FOLDERS_FAIL ";

export const ADD_PRODUCT_TO_FOLDER_REQUEST = "ADD_PRODUCT_TO_FOLDER_REQUEST ";
export const ADD_PRODUCT_TO_FOLDER_SUCCESS = "ADD_PRODUCT_TO_FOLDER_SUCCESS ";
export const ADD_PRODUCT_TO_FOLDER_FAIL = "ADD_PRODUCT_TO_FOLDER_FAIL ";

export const MOVE_PRODUCT_TO_FOLDER_REQUEST = "MOVE_PRODUCT_TO_FOLDER_REQUEST ";
export const MOVE_PRODUCT_TO_FOLDER_SUCCESS = "MOVE_PRODUCT_TO_FOLDER_SUCCESS ";
export const MOVE_PRODUCT_TO_FOLDER_FAIL = "MOVE_PRODUCT_TO_FOLDER_FAIL ";

export const ARCHIVE_FOLDER_REQUEST = "ARCHIVE_FOLDER_REQUEST ";
export const ARCHIVE_FOLDER_SUCCESS = "ARCHIVE_FOLDER_SUCCESS ";
export const ARCHIVE_FOLDER_FAIL = "ARCHIVE_FOLDER_FAIL ";

export const RESTORE_FOLDER_REQUEST = "RESTORE_FOLDER_REQUEST ";
export const RESTORE_FOLDER_SUCCESS = "RESTORE_FOLDER_SUCCESS ";
export const RESTORE_FOLDER_FAIL = "RESTORE_FOLDER_FAIL ";

export const UPDATE_FOLDER_REQUEST = "UPDATE_FOLDER_REQUEST ";
export const UPDATE_FOLDER_SUCCESS = "UPDATE_FOLDER_SUCCESS ";
export const UPDATE_FOLDER_FAIL = "UPDATE_FOLDER_FAIL ";

export const DELETE_PRODUCTS_FROM_FOLDER_REQUEST =
  "DELETE_PRODUCTS_FROM_FOLDER_REQUEST ";
export const DELETE_PRODUCTS_FROM_FOLDER_SUCCESS =
  "DELETE_PRODUCTS_FROM_FOLDER_SUCCESS ";
export const DELETE_PRODUCTS_FROM_FOLDER_FAIL =
  "DELETE_PRODUCTS_FROM_FOLDER_FAIL ";

export const CHANGE_FOLDER_ROOT_REQUEST = "CHANGE_FOLDER_ROOT_REQUEST ";
export const CHANGE_FOLDER_ROOT_SUCCESS = "CHANGE_FOLDER_ROOT_SUCCESS ";
export const CHANGE_FOLDER_ROOT_FAIL = "CHANGE_FOLDER_ROOT_FAIL ";

export const GET_PRODUCT_ID_TO_ADD = "GET_PRODUCT_ID_TO_ADD ";

export const HANDLE_MOVING_STATE = "HANDLE_MOVING_STATE ";

export const ADD_FOLDER_TO_SELECTED = "ADD_FOLDER_TO_SELECTED ";

export const HANDLE_MOVING_STATE_PRODUCT = "HANDLE_MOVING_STATE_PRODUCT ";

export const DESABLE_MOVING_STATE_PRODUCT = "DESABLE_MOVING_STATE_PRODUCT ";

export const HANDLE_MOVING_STATE_PRODUCTS = "HANDLE_MOVING_STATE_PRODUCTS ";

export const DESABLE_DELETE_PRODUCTS_DONE = "DESABLE_DELETE_PRODUCTS_DONE ";

export const ADD_PRODUCT_TO_SELECTED = "ADD_PRODUCT_TO_SELECTED ";

export const ADD_FOLDER_TO_SHOW = "ADD_FOLDER_TO_SHOW ";

export const SET_FOLDER_ID_TO_MOVE_TO = "SET_FOLDER_ID_TO_MOVE_TO ";

export const SET_FOLDER_ID_TO_MOVE_TO_ON_DRAG =
  "SET_FOLDER_ID_TO_MOVE_TO_ON_DRAG ";

export const CLEAR_SELECTED_FOLDERS = "CLEAR_SELECTED_FOLDERS ";

export const CLEAR_SELECTED_PRODUCTS = "CLEAR_SELECTED_PRODUCTS ";

export const CLEAR_FOLDER_TO_MOVE_TO = "CLEAR_FOLDER_TO_MOVE_TO ";

export const CLEAR_FOLDER_TO_MOVE_TO_ON_DRAG =
  "CLEAR_FOLDER_TO_MOVE_TO_ON_DRAG ";

export const CLEAR_ONE_FOLDER = "CLEAR_ONE_FOLDER ";

export const CLEAR_RIGHT_SIDE_FOLDER = "CLEAR_RIGHT_SIDE_FOLDER ";

// export const FORGOT_PWD_REQUEST = "FORGOT_PWD_REQUEST ";
// export const FORGOT_PWD_SUCCESS = "FORGOT_PWD_SUCCESS ";
// export const FORGOT_PWD_FAIL = "FORGOT_PWD_FAIL ";

// export const ADD_NEW_PWD_REQUEST = "ADD_NEW_PWD_REQUEST ";
// export const ADD_NEW_PWD_SUCCESS = "ADD_NEW_PWD_SUCCESS ";
// export const ADD_NEW_PWD_FAIL = "ADD_NEW_PWD_FAIL ";

// export const LOGOUT_USER = "LOGOUT_USER";
