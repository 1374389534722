export interface IPenProps {
  className?: string;
}

function Pdf({ className }: IPenProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 28 28"
    >
      <path
        d="M17.123-16.623l-3.5-3.5A3.014,3.014,0,0,0,11.5-21H3a3,3,0,0,0-3,3V0A3,3,0,0,0,3,3H15a3.009,3.009,0,0,0,3-3V-14.5A3,3,0,0,0,17.123-16.623ZM15.75,0A.75.75,0,0,1,15,.75H3A.75.75,0,0,1,2.251,0V-17.994a.75.75,0,0,1,.75-.75h7.5V-15A1.5,1.5,0,0,0,12-13.5h3.708V0ZM9.342-8.6,9-8.242,8.649-8.6a2.4,2.4,0,0,0-3.273-.236A2.527,2.527,0,0,0,5.2-5.18L8.6-1.671a.549.549,0,0,0,.793,0L12.8-5.18a2.523,2.523,0,0,0-.181-3.658A2.363,2.363,0,0,0,9.342-8.6Z"
        transform="translate(0 21)"
        fill="#182844"
      />
    </svg>
  );
}

export default Pdf;
