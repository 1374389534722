import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGOUT_USER,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  FORGOT_PWD_REQUEST,
  FORGOT_PWD_SUCCESS,
  FORGOT_PWD_FAIL,
  ADD_NEW_PWD_REQUEST,
  ADD_NEW_PWD_SUCCESS,
  ADD_NEW_PWD_FAIL,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL,
  RESET_PWD_CONNECTED_REQUEST,
  RESET_PWD_CONNECTED_SUCCESS,
  RESET_PWD_CONNECTED_FAIL,
  CLEAR_MSG,
  LAST_ACTIVE_REQUEST,
  LAST_ACTIVE_SUCCESS,
  LAST_ACTIVE_FAIL,
} from "./types";
import AuthServices from "./services";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";

// Login User
export const loginUser = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: LOGIN_USER_REQUEST,
  });
  try {
    const response = await AuthServices.loginUser(data);
    await dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    console.log(e);
    dispatch({
      type: LOGIN_USER_FAIL,
      payload: e.response,
    });
  }
};

// Load Connected User Information
export const loadConnectedUser =
  (refetch: boolean) => async (dispatch: any) => {
    if (!refetch) {
      await dispatch({
        type: LOAD_USER_REQUEST,
      });
    }

    try {
      const response = await AuthServices.loadUser();
      await dispatch({
        type: LOAD_USER_SUCCESS,
        payload: response.data,
      });
    } catch (e: any) {
      dispatch({
        type: LOAD_USER_FAIL,
        payload: e.response,
      });
    }
  };

// Forgot Password
export const forgotPwd =
  (email: string, changeStatus: any) => async (dispatch: any) => {
    await dispatch({
      type: FORGOT_PWD_REQUEST,
    });

    try {
      const response = await AuthServices.forgotPwdSendEmail(email);
      await dispatch({
        type: FORGOT_PWD_SUCCESS,
        payload: `An email has been sent to ${email}`,
      });
      changeStatus("SendCode");
    } catch (e: any) {
      dispatch({
        type: FORGOT_PWD_FAIL,
        payload: "Please put a valid email address.",
      });
    }
  };

// Reset Password
export const resetPassword = (data: object) => async (dispatch: any) => {
  await dispatch({
    type: ADD_NEW_PWD_REQUEST,
  });
  try {
    const response = await AuthServices.addNewPwd(data);
    await dispatch({
      type: ADD_NEW_PWD_SUCCESS,
    });
  } catch (e: any) {
    dispatch({
      type: ADD_NEW_PWD_FAIL,
      payload: e,
    });
  }
};

// Reset Password
export const resetPasswordConnected =
  (data: object, setIsLoading: any) => async (dispatch: any) => {
    await dispatch({
      type: RESET_PWD_CONNECTED_REQUEST,
    });
    try {
      const response = await AuthServices.resetPasswordConnected(data);
      await dispatch({
        type: RESET_PWD_CONNECTED_SUCCESS,
      });
      setIsLoading(false);
    } catch (e: any) {
      MyNotif(e.response.data, "danger");
      dispatch({
        type: RESET_PWD_CONNECTED_FAIL,
        payload: e.response.data,
      });
      setIsLoading(false);
    }
  };

// update account
export const updateAccountAction =
  (data: any, idUser: string, isAdmin: boolean) => async (dispatch: any) => {
    await dispatch({
      type: UPDATE_ACCOUNT_REQUEST,
    });
    try {
      const response = await AuthServices.updateAccount(data, idUser, isAdmin);
      await dispatch({
        type: UPDATE_ACCOUNT_SUCCESS,
        payload: response.data,
      });
      MyNotif("Account updated successfully.", "success");
    } catch (e: any) {
      MyNotif("Something went wrong.", "danger");
      dispatch({
        type: UPDATE_ACCOUNT_FAIL,
        payload: e,
      });
    }
  };

// Logout User
export const logout = () => (dispatch?: any) => {
  dispatch({
    type: LOGOUT_USER,
  });
};
// cLEAR MSG
export const clearMsg = () => (dispatch?: any) => {
  dispatch({
    type: CLEAR_MSG,
  });
};

// LAST ACTIVE
export const lastActive = () => async (dispatch: any) => {
  await dispatch({
    type: LAST_ACTIVE_REQUEST,
  });
  try {
    const response = await AuthServices.lastActive();
    await dispatch({
      type: LAST_ACTIVE_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: LAST_ACTIVE_FAIL,
      payload: e,
    });
  }
};
