import { Tooltip } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./lazerCanvas.scss";
type Coordinate = {
  x: number;
  y: number;
};

export interface IBackLazerCanvasProps {
  size: number;
  intensity: number;
  mouseMoveBackLazer: (mousePosition: { x: number; y: number }) => void;
  initialDrawing: Array<{ x: number; y: number }>;
  erase: any;
  clearButton: any;
}
const BackLazerCanvas: React.FC<IBackLazerCanvasProps> = ({
  size,
  intensity,
  mouseMoveBackLazer,
  initialDrawing,
  erase,
  clearButton,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRef2 = useRef<HTMLCanvasElement>(null);

  const [isPainting, setIsPainting] = useState(false);
  const [eraser, setEraser] = useState(false);
  const [active, setActive] = useState(false);

  const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(
    undefined
  );
  const [isLeftClick, setIsLeftClick] = React.useState(true);
  const params = useParams<{ status: string }>();

  const { selectedProduct } = useSelector((state: any) => state.products);

  const startPaint = useCallback((event: MouseEvent) => {
    const coordinates = getCoordinates(event);
    if (event.which === 1) {
      setIsLeftClick(true);
    } else if (event.which === 3) {
      setIsLeftClick(false);
    }
    if (coordinates) {
      setMousePosition(coordinates);
      setIsPainting(true);
    }
  }, []);

  React.useEffect(() => {
    if (canvasRef && selectedProduct) {
      const canvas: any = canvasRef.current;

      //@ts-ignore
      var drawingElement = document.getElementById("myCanvas2Back");
      //@ts-ignore
      var drawingCtx: any = drawingElement.getContext("2d");
      //@ts-ignore
      var renderingCtx: any = canvas.getContext("2d");
      var imageObj1 = new Image();
      imageObj1.crossOrigin = "Anonymous";
      imageObj1.src =
        process.env.REACT_APP_STORAGE_URL +
        (params.status === "edit"
          ? selectedProduct.original_product.back_image
          : selectedProduct.back_image); //"/assets/jean-front.png";
      imageObj1.onload = function () {
        var wrh = imageObj1.width / imageObj1.height;
        var newWidth = canvas.width;
        var newHeight = newWidth / wrh;
        if (newHeight > canvas.height) {
          newHeight = canvas.height;
          newWidth = newHeight * wrh;
        }
        var xOffset =
          newWidth < canvas.width ? (canvas.width - newWidth) / 2 : 0;
        var yOffset =
          newHeight < canvas.height ? (canvas.height - newHeight) / 2 : 0;
        // context.drawImage(imageObj1, xOffset, yOffset, newWidth+20, newHeight);
        renderingCtx.drawImage(
          imageObj1,
          window.innerWidth < 769 ? xOffset - 80 : xOffset,
          window.innerWidth < 769 ? yOffset - 50 : yOffset,
          window.innerWidth < 769 ? newWidth + 180 : newWidth + 20,
          window.innerWidth < 769 ? newHeight + 120 : newHeight
        );
      };
    }
  }, [canvasRef, selectedProduct]);

  React.useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    canvas.addEventListener("mousedown", startPaint);
    return () => {
      canvas.removeEventListener("mousedown", startPaint);
    };
  }, [startPaint]);

  const clearAll = () => {
    setActive(true);
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;
    const context = canvas.getContext("2d");
    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      if (canvasRef && selectedProduct) {
        const canvas: any = canvasRef.current;

        //@ts-ignore
        var drawingElement = document.getElementById("myCanvas2Back");
        //@ts-ignore
        var drawingCtx: any = drawingElement.getContext("2d");
        //@ts-ignore
        var renderingCtx: any = canvas.getContext("2d");
        renderingCtx.clearRect(0, 0, canvas.width, canvas.height);
        drawingCtx.clearRect(0, 0, canvas.width, canvas.height);

        var imageObj1 = new Image();
        imageObj1.crossOrigin = "Anonymous";
        imageObj1.src =
          process.env.REACT_APP_STORAGE_URL +
          (params.status === "edit"
            ? selectedProduct.original_product.back_image
            : selectedProduct.back_image); //"/assets/jean-front.png";
        imageObj1.onload = function () {
          var wrh = imageObj1.width / imageObj1.height;
          var newWidth = canvas.width;
          var newHeight = newWidth / wrh;
          if (newHeight > canvas.height) {
            newHeight = canvas.height;
            newWidth = newHeight * wrh;
          }
          var xOffset =
            newWidth < canvas.width ? (canvas.width - newWidth) / 2 : 0;
          var yOffset =
            newHeight < canvas.height ? (canvas.height - newHeight) / 2 : 0;
          // context.drawImage(imageObj1, xOffset, yOffset, newWidth+20, newHeight);
          renderingCtx.drawImage(
            imageObj1,
            window.innerWidth < 769 ? xOffset - 80 : xOffset,
            window.innerWidth < 769 ? yOffset - 50 : yOffset,
            window.innerWidth < 769 ? newWidth + 180 : newWidth + 20,
            window.innerWidth < 769 ? newHeight + 120 : newHeight
          );
        };
      }
    }
  };
  const paint = useCallback(
    (event: MouseEvent) => {
      if (isPainting) {
        const newMousePosition = getCoordinates(event);
        if (mousePosition && newMousePosition && erase == false) {
          drawLine(mousePosition, newMousePosition);
          setMousePosition(newMousePosition);
          mouseMoveBackLazer(mousePosition);
        } /* else {
          if (mousePosition && newMousePosition && !isLeftClick) {
            clearLine(mousePosition, newMousePosition);
            setMousePosition(newMousePosition);
          }
        } */ else {
          if (mousePosition && newMousePosition && erase == true) {
            cLine(mousePosition, newMousePosition);
            setMousePosition(newMousePosition);
          }
        }
      }
    },
    [isPainting, mousePosition, isLeftClick]
  );

  useEffect(() => {
    // console.log(mousePositionArray);
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;
    canvas.addEventListener("mousemove", paint);
    canvas.oncontextmenu = function (e) {
      e.preventDefault();
      paint(e);
    };
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
    return () => {
      canvas.removeEventListener("mousemove", paint);
    };
  }, [paint]);

  const exitPaint = useCallback(() => {
    setIsPainting(false);
    setMousePosition(undefined);
  }, []);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;

    canvas.addEventListener("mouseup", exitPaint);
    canvas.addEventListener("mouseleave", exitPaint);

    initialDrawing?.forEach((position: Coordinate, index: number) => {
      if (index < initialDrawing.length) {
        drawInitialLines(position, initialDrawing[index + 1]);
      }
    });

    return () => {
      canvas.removeEventListener("mouseup", exitPaint);
      canvas.removeEventListener("mouseleave", exitPaint);
    };
  }, [exitPaint]);

  const getCoordinates = (event: MouseEvent): Coordinate | undefined => {
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    let rect = canvas.getBoundingClientRect();
    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };
  };
  const clearLine = (
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;
    const context = canvas.getContext("2d");
    if (context) {
      context.clearRect(newMousePosition.x, newMousePosition.y, 20, 20);
    }
  };

  const [localIntensity, setLocalIntensity] = React.useState(intensity);
  const [localSize, setLocalSize] = React.useState(size);
  React.useEffect(() => {
    setLocalSize(size);
  }, [size]);

  useEffect(() => clearAll(), [clearButton]);

  React.useEffect(() => {
    setLocalIntensity(intensity / 100);
  }, [intensity]);
  const cLine = React.useCallback(
    (originalMousePosition: Coordinate, newMousePosition: Coordinate) => {
      if (!canvasRef.current) {
        return;
      }
      const canvas: HTMLCanvasElement = canvasRef.current;
      const context = canvas.getContext("2d");

      //@ts-ignore
      var drawingElement = document.getElementById("myCanvas2Back");
      //@ts-ignore
      var drawingCtx: any = drawingElement.getContext("2d");
      //@ts-ignore
      var renderingCtx: any = canvas.getContext("2d");
      if (drawingCtx) {
        //  context.globalCompositeOperation = "source-atop";

        drawingCtx.globalCompositeOperation = "destination-out";

        drawingCtx.strokeStyle = "#95283A";
        drawingCtx.lineJoin = "round";

        drawingCtx.lineWidth = localSize ? localSize : 20;

        drawingCtx.beginPath();
        drawingCtx.moveTo(originalMousePosition?.x, originalMousePosition?.y);
        drawingCtx.lineTo(newMousePosition?.x, newMousePosition?.y);
        drawingCtx.closePath();
        drawingCtx.stroke();
        // draw to visible canvas
        var imageObj1 = new Image();
        imageObj1.crossOrigin = "Anonymous";
        imageObj1.src =
          process.env.REACT_APP_STORAGE_URL +
          (params.status === "edit"
            ? selectedProduct.original_product.back_image
            : selectedProduct.back_image); //"/assets/jean-front.png";
        var wrh = imageObj1.width / imageObj1.height;
        var newWidth = canvas.width;
        var newHeight = newWidth / wrh;
        if (newHeight > canvas.height) {
          newHeight = canvas.height;
          newWidth = newHeight * wrh;
        }
        var xOffset =
          newWidth < canvas.width ? (canvas.width - newWidth) / 2 : 0;
        var yOffset =
          newHeight < canvas.height ? (canvas.height - newHeight) / 2 : 0;

        renderingCtx.clearRect(0, 0, canvas.width, canvas.height);
        renderingCtx.drawImage(
          imageObj1,
          window.innerWidth < 769 ? xOffset - 80 : xOffset,
          window.innerWidth < 769 ? yOffset - 50 : yOffset,
          window.innerWidth < 769 ? newWidth + 180 : newWidth + 20,
          window.innerWidth < 769 ? newHeight + 120 : newHeight
        );
        renderingCtx.globalCompositeOperation = "source-atop";
        renderingCtx.drawImage(drawingElement, 0, 0);

        // reset
        renderingCtx.globalCompositeOperation = "source-over";
      }
    },
    [localIntensity, localSize, initialDrawing]
  );
  const drawLine = React.useCallback(
    (originalMousePosition: Coordinate, newMousePosition: Coordinate) => {
      if (!canvasRef.current) {
        return;
      }
      const canvas: HTMLCanvasElement = canvasRef.current;
      const context = canvas.getContext("2d");

      //@ts-ignore
      var drawingElement = document.getElementById("myCanvas2Back");
      //@ts-ignore
      var drawingCtx: any = drawingElement.getContext("2d");
      //@ts-ignore
      var renderingCtx: any = canvas.getContext("2d");
      if (drawingCtx) {
        //  context.globalCompositeOperation = "source-atop";

        drawingCtx.globalCompositeOperation = "source-over";

        drawingCtx.strokeStyle = localIntensity
          ? `rgba(149, 40, 58,${localIntensity})`
          : "#95283A";
        drawingCtx.lineJoin = "round";

        drawingCtx.lineWidth = localSize ? localSize : 20;

        drawingCtx.beginPath();
        drawingCtx.moveTo(originalMousePosition?.x, originalMousePosition?.y);
        drawingCtx.lineTo(newMousePosition?.x, newMousePosition?.y);
        drawingCtx.closePath();
        drawingCtx.stroke();
        // draw to visible canvas
        var imageObj1 = new Image();
        imageObj1.crossOrigin = "Anonymous";
        imageObj1.src =
          process.env.REACT_APP_STORAGE_URL +
          (params.status === "edit"
            ? selectedProduct.original_product.back_image
            : selectedProduct.back_image); //"/assets/jean-front.png";
        var wrh = imageObj1.width / imageObj1.height;
        var newWidth = canvas.width;
        var newHeight = newWidth / wrh;
        if (newHeight > canvas.height) {
          newHeight = canvas.height;
          newWidth = newHeight * wrh;
        }
        var xOffset =
          newWidth < canvas.width ? (canvas.width - newWidth) / 2 : 0;
        var yOffset =
          newHeight < canvas.height ? (canvas.height - newHeight) / 2 : 0;

        renderingCtx.clearRect(0, 0, canvas.width, canvas.height);
        renderingCtx.drawImage(
          imageObj1,
          window.innerWidth < 769 ? xOffset - 80 : xOffset,
          window.innerWidth < 769 ? yOffset - 50 : yOffset,
          window.innerWidth < 769 ? newWidth + 180 : newWidth + 20,
          window.innerWidth < 769 ? newHeight + 120 : newHeight
        );
        renderingCtx.globalCompositeOperation = "source-atop";
        renderingCtx.drawImage(drawingElement, 0, 0);

        // reset
        renderingCtx.globalCompositeOperation = "source-over";
      }
    },
    [localIntensity, localSize, initialDrawing]
  );
  const drawInitialLines = React.useCallback(
    (originalMousePosition: Coordinate, newMousePosition: Coordinate) => {
      if (!canvasRef.current) {
        return;
      }
      const canvas: HTMLCanvasElement = canvasRef.current;
      const context = canvas.getContext("2d");
      if (context) {
        context.globalCompositeOperation = "destination-over";

        context.strokeStyle = localIntensity
          ? `rgba(149, 40, 58,${localIntensity})`
          : "#95283A";
        context.lineJoin = "round";

        context.lineWidth = localSize ? localSize : 20;

        context.beginPath();
        context.moveTo(originalMousePosition?.x, originalMousePosition?.y);
        context.lineTo(newMousePosition?.x, newMousePosition?.y);
        context.closePath();
        context.stroke();
      }
    },
    [localIntensity, localSize, initialDrawing]
  );

  let brushCursor = document.querySelector<any>(".custom-brush-cursor");
  let canvasContainer = document.querySelector<any>(".canvas-container");

  const cursorChange = (e: MouseEvent) => {
    brushCursor.style.top = e.pageY + "px";
    brushCursor.style.left = e.pageX + "px";
  };

  React.useEffect(() => {
    if (brushCursor) {
      window.addEventListener("mousemove", cursorChange);
      canvasContainer.addEventListener("hover", () => {
        brushCursor.classList.add("hover-class");
      });
    }
  }, [brushCursor]);

  /*   React.useEffect(() => {
    const canvas: any = canvasRef2.current;
    const context: any = canvas.getContext("2d");
    var imageObj1 = new Image();
    imageObj1.crossOrigin = "Anonymous";
    imageObj1.src =
      process.env.REACT_APP_STORAGE_URL +
      (params.status === "edit"
        ? selectedProduct.original_product.front_image
        : selectedProduct.front_image); //"/assets/jean-front.png";
    imageObj1.onload = function () {
      var wrh = imageObj1.width / imageObj1.height;
      var newWidth = canvas.width;
      var newHeight = newWidth / wrh;
      if (newHeight > canvas.height) {
        newHeight = canvas.height;
        newWidth = newHeight * wrh;
      }
      var xOffset = newWidth < canvas.width ? (canvas.width - newWidth) / 2 : 0;
      var yOffset =
        newHeight < canvas.height ? (canvas.height - newHeight) / 2 : 0;
      // context.drawImage(imageObj1, xOffset, yOffset, newWidth+20, newHeight);
      context.drawImage(
        imageObj1,
        window.innerWidth < 769 ? xOffset - 80 : xOffset,
        window.innerWidth < 769 ? yOffset - 50 : yOffset,
        window.innerWidth < 769 ? newWidth + 180 : newWidth + 20,
        window.innerWidth < 769 ? newHeight + 120 : newHeight
      );
    };
    //@ts-ignore
    const renderingElement: any = canvasRef2.current;
    //let renderingElement = document.getElementById("myCanvas");
    //@ts-ignore
    let drawingElement = renderingElement.cloneNode();
    //@ts-ignore
    let drawingCtx = drawingElement.getContext("2d");
    //@ts-ignore
    let renderingCtx = renderingElement.getContext("2d");

    // let img = new Image();
    let brushSize = 25;
    let brushColor = "#000000";
    let drawingMode = "brush";
    //@ts-ignore
    let lastX;
    //@ts-ignore
    let lastY;
    let moving = false;

    //@ts-ignore
    let eraseButton = document.getElementById("erase");
    //@ts-ignore
    let brushButton = document.getElementById("brush");
    //@ts-ignore
    let exportButton = document.getElementById("export");
    //@ts-ignore
    if (eraseButton) {
      eraseButton.addEventListener("click", () => {
        console.log("erase");
        drawingMode = "erase";
      });
    }
    //@ts-ignore
    if (brushButton) {
      brushButton.addEventListener("click", () => {
        console.log("brush");
        drawingMode = "brush";
      });
    }
    //@ts-ignore
    renderingElement.addEventListener("mousedown", (ev) => {
      const newMousePosition = getCoordinates(ev);
      moving = true;
      //@ts-ignore
      lastX = ev.pageX - renderingElement.offsetLeft; //@ts-ignore
      lastY = ev.pageY - renderingElement.offsetTop;
    });
    //@ts-ignore
    renderingElement.addEventListener("mouseup", (ev) => {
      moving = false;
      //@ts-ignore
      lastX = ev.pageX - renderingElement.offsetLeft;
      //@ts-ignore
      lastY = ev.pageY - renderingElement.offsetTop;
    });
    //@ts-ignore
    renderingElement.addEventListener("mousemove", (ev) => {
      if (moving) {
        if (drawingMode === "brush") {
          drawingCtx.globalCompositeOperation = "source-over";
        } else {
          drawingCtx.globalCompositeOperation = "destination-out";
        }
        //@ts-ignore
        let currentX = ev.pageX - renderingElement.offsetLeft;
        //@ts-ignore
        let currentY = ev.pageY - renderingElement.offsetTop;

        drawingCtx.beginPath();
        drawingCtx.lineJoin = "round";
        //@ts-ignore
        drawingCtx.moveTo(lastX, lastY);
        drawingCtx.lineTo(currentX, currentY);
        drawingCtx.closePath();
        drawingCtx.strokeStyle = brushColor;
        drawingCtx.lineWidth = brushSize;
        drawingCtx.stroke();

        lastX = currentX;
        lastY = currentY;

        // draw to visible canvas
        renderingCtx.clearRect(
          0,
          0,
          //@ts-ignore
          renderingElement.width,
          //@ts-ignore
          renderingElement.height
        );
        var wrh = imageObj1.width / imageObj1.height;
        var newWidth = canvas.width;
        var newHeight = newWidth / wrh;
        if (newHeight > canvas.height) {
          newHeight = canvas.height;
          newWidth = newHeight * wrh;
        }
        var xOffset =
          newWidth < canvas.width ? (canvas.width - newWidth) / 2 : 0;
        var yOffset =
          newHeight < canvas.height ? (canvas.height - newHeight) / 2 : 0;
        renderingCtx.drawImage(
          imageObj1,
          window.innerWidth < 769 ? xOffset - 80 : xOffset,
          window.innerWidth < 769 ? yOffset - 50 : yOffset,
          window.innerWidth < 769 ? newWidth + 180 : newWidth + 20,
          window.innerWidth < 769 ? newHeight + 120 : newHeight
        );
        renderingCtx.globalCompositeOperation = "source-atop";
        renderingCtx.drawImage(drawingElement, 0, 0);

        // reset
        renderingCtx.globalCompositeOperation = "source-over";
      }
    });
  }, []);
 */
  return (
    <>
      <div className="canvas-container" style={{ marginTop: "-50px" }}>
        <div className="custom-brush-cursor" />
        <canvas
          id="lazer-canvas-back"
          width={
            window.innerWidth < 769
              ? window.innerWidth / 2
              : window.innerWidth / 2 - 105
          }
          height={window.innerHeight - 130 - 120}
          ref={canvasRef}
        />
        <canvas
          id="myCanvas2Back"
          width={
            window.innerWidth < 769
              ? window.innerWidth / 2
              : window.innerWidth / 2 - 105
          }
          height={window.innerHeight - 130 - 120}
          hidden
        ></canvas>
        <div
          style={{
            textAlign: "center",
            marginTop: "-35px",
            paddingBottom: "20px",
          }}
        >
          <Tooltip
            placement="top"
            color="#00468C"
            title={
              <>
                <div>Delete all</div>
              </>
            }
          >
            <button
              onClick={clearAll}
              className="btn-action"
              style={{ padding: "5px 15px 5px 15px" }}
            >
              <i className="fa fa-redo"></i> Clear
            </button>
          </Tooltip>
        </div>
      </div>
    </>
  );
};
export { BackLazerCanvas };
