import { Collapse, InputNumber, Select } from "antd";
import { useSelector } from "react-redux";

const { Panel } = Collapse;
const { Option } = Select;

const AccessoryCollapse = ({
  index,
  accessory,
  productToUpdate,
  genExtraForAccess,
  setProductToUpdate,
  setData,
  data,
  options,
  handleChangeAccess,
}: any) => {
  const { user } = useSelector((state: any) => state.auth);
  function comparer(otherArray: any) {
    return function (current: any) {
      return (
        otherArray.filter(function (other: any) {
          return other.id == current.id;
        }).length == 0
      );
    };
  }
  return (
    <Collapse defaultActiveKey={["0"]} key={index + 2}>
      <Panel
        header={accessory.name || `Accessory num ${index + 1}`}
        key={index}
        extra={genExtraForAccess(index)}
      >
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <th>Accessory</th>
              <td>
                <Select
                  disabled={!user.permissions.includes("product.update")}
                  value={accessory.name}
                  style={{ width: "100%" }}
                  placeholder="Select Type of Fabric"
                  onChange={(val: any) => {
                    setProductToUpdate({
                      ...productToUpdate,
                      accessories: productToUpdate.accessories.map(
                        (access: any) =>
                          access.id === accessory.id ? JSON.parse(val) : access
                      ),
                    });
                    setData({
                      ...data,
                      accessories: data.accessories.map((access: any) =>
                        access.accessory_id === accessory.id
                          ? {
                              ...access,
                              accessory_id: JSON.parse(val).id,
                            }
                          : access
                      ),
                    });
                  }}
                >
                  {options.accessories_options &&
                    options.accessories_options
                      .filter(comparer(productToUpdate.accessories))
                      .map((el: any) => (
                        <Option value={JSON.stringify(el)} key={el.id}>
                          {el.name}
                        </Option>
                      ))}
                </Select>
              </td>
            </tr>
            <tr>
              <th>Price</th>
              <td>
                <InputNumber
                  disabled={!user.permissions.includes("product.update")}
                  style={{ width: "100%" }}
                  min={0}
                  defaultValue={accessory.price}
                  name="technic_price"
                  onChange={(num: number) =>
                    handleChangeAccess(num, accessory.id)
                  }
                />
              </td>
            </tr>
            <tr>
              {" "}
              <th>Unit</th>
              <td>
                <Select
                  disabled={!user.permissions.includes("product.update")}
                  defaultValue={accessory.unit}
                  style={{ width: "100%" }}
                  placeholder="Select"
                  //  onChange={(val: any) => selectFavBy(val, "favorite_by_id")}
                >
                  <Option value="Meter">Per Meter</Option>
                  <Option value="Piece">Per Piece</Option>
                </Select>
              </td>
            </tr>
          </tbody>
        </table>
      </Panel>
    </Collapse>
  );
};

export default AccessoryCollapse;
