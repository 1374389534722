function Information() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 27 26"
    >
      <path
        fill="#182844"
        d="M4.5-4.472V-10.5a2.988 2.988 0 00-.774-2.01 5.026 5.026 0 001.337-3.428A5.06 5.06 0 000-21a5.06 5.06 0 00-5.062 5.063 5.028 5.028 0 00.791 2.72A3 3 0 00-6-10.5v1.843a3 3 0 001.5 2.6v1.587A3 3 0 00-6-1.875V0a3 3 0 003 3h6a3 3 0 003-3v-1.875a3 3 0 00-1.5-2.597zM0-18.75a2.813 2.813 0 012.813 2.813A2.813 2.813 0 010-13.125a2.813 2.813 0 01-2.812-2.812A2.813 2.813 0 010-18.75zM3.75 0A.75.75 0 013 .75h-6A.75.75 0 01-3.75 0v-1.875a.75.75 0 01.75-.75h.75v-5.282H-3a.75.75 0 01-.75-.75V-10.5a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v7.875H3a.75.75 0 01.75.75z"
        data-name="Tracé 3314"
        transform="translate(6 21)"
      ></path>
    </svg>
  );
}

export default Information;
