import * as React from "react";
import "../../sass/MyCollection.scss";
import ToolBarFolders from "../ToolBarFolders/ToolBarFolders";
import folder_content from "../../img/folder_content_2.png";
import backArrow from "../../img/backArrow.png";
import { Button } from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  clearOneFolder,
  getAllFolders,
  getRightSideFolder,
  getRightSideSubFolders,
  clearRightSideFolder,
  getOneFolder,
  changeFolderRoot,
  clearFolderToMoveToOnDrag,
  deleteFolder,
  archiveFolder,
  clearSelectedFolders,
  addProducttoFolder,
  editFolder,
  moveProductToFolder,
  handleMovingState,
  handleMovingStateProduct,
  desableMovingProductDone,
  desableDeleteProductsDone,
  clearSelectedProducts,
  deleteProductsfromFolder,
  addOneFolderToShow,
  clearFolderDetails,
} from "../../actions/folders-actions/actions";
import {
  handleBottomDrawer,
  handleLeftFolderDetails,
} from "../../actions/actionCreators";
import CollectionFolders2 from "../CollectionFolders/CollectionFolders2";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import MyCollectionHeader from "./MyCollectionHeader";
import FolderDetails from "../FolderDetails/FolderDetails";
import Bag from "../../icons/Bag";
import Download from "../../icons/Download";
import AddToCollection from "../AddToCollection/AddToCollection";
import Selection from "../Selection/Selection";
import { getSelectionProducts } from "../../actions/myselection-actions/actions";
import { Spinner } from "../Spinner";
import DraggableFolder from "../DraggableFolder/DraggableFolder";
import ToolBarProducts from "../ToolBarProducts/ToolBarProducts";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DraggableProductCard } from "../ProductCard/DraggableProductCard";
import { DraggableMyProductCard } from "../ProductCard/DraggableMyProductCard";

import CustomizedProductMiniature from "./CustomizedProductMiniature";
import { useLocation } from "react-router-dom";
import MyCustomizedProducts from "../MyCustomizedProducts/MyCustomizedProducts";
import Moodboards from "../Moodboards/Moodboards";
import AllCollection from "../MyDirectOrder/AllCollection";
import axiosInstance from "../../config/axios-instance";
import { useQuery } from "react-query";

export interface IMyCollectionContentProps {}

export default function MyCollectionContent2(props: IMyCollectionContentProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const folders = useSelector((state: RootStateOrAny) => state.folders);
  const drawers = useSelector((state: RootStateOrAny) => state.drawers);
  const alerts = useSelector((state: RootStateOrAny) => state.alerts);
  const selection = useSelector((state: RootStateOrAny) => state.selection);
  const { user, isAdmin } = useSelector((state: RootStateOrAny) => state.auth);
  const [folder, setfolder] = useState<any>(undefined);
  const [folderToShow, setfolderToShow] = useState<any>(undefined);
  const [editName, setEditName] = useState("");
  // const [edit, setEdit] = useState(false);
  const [subfolders, setsubfolders] = useState<any>([]);
  const [subfoldersright, setsubfoldersright] = useState<any>([]);
  // const [nbrClickAllowed, setnbrClickAllowed] = useState<any>([]);
  // const [showdrawer, setshowdrawer] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [productsToOrder, setProductsToOrder] = useState([]);
  const [selectScreen, setSelectScreen] = useState("");

  // const handleChange = (e: any) => {
  //   setEditName(e.target.value);
  // };
  console.log("selectScreen" + selectScreen);
  const variants = {
    enter: (): object => {
      return {
        opacity: 0,
      };
    },
    center: (): object => {
      return {
        opacity: 1,
      };
    },
    exit: () => {
      return {
        opacity: 0,
      };
    },
  };
  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  useEffect(() => {
    if (folders.rightSideFolder) {
      setfolderToShow(folders.rightSideFolder);
      setEditName(folders.rightSideFolder.name);

      setsubfoldersright(
        folders.folders.filter(
          (el: any) => el.parent_id === folders.rightSideFolder.id
        )
      );
    } else if (folders.oneFolder) {
      setfolderToShow(folders.oneFolder);
      setEditName(folders.oneFolder.name);
      if (width <= 1024) {
        setsubfoldersright(
          folders.folders.filter(
            (el: any) => el.parent_id === folders.oneFolder.id
          )
        );
      }
      setsubfolders(
        folders.folders.filter(
          (el: any) => el.parent_id === folders.oneFolder.id
        )
      );
    }
  }, [folders.oneFolder, folders.rightSideFolder]);
  useEffect(() => {
    if (!folders.folders) {
      dispatch(getAllFolders());
    }
  }, [folders.oneFolder]);
  useEffect(() => {
    user && !isAdmin && dispatch(getSelectionProducts());
    dispatch(clearSelectedFolders());
  }, []);
  useEffect(() => {
    // dispatch(getOneFolder(folders.oneFolder.id));
    if (folders.folders) {
      setsubfolders(
        folders.folders.filter(
          (el: any) => el.parent_id === folders.oneFolder.id
        )
      );

      dispatch(clearFolderToMoveToOnDrag());
      if (folders.rightSideFolder) {
        dispatch(getRightSideSubFolders(folders.rightSideFolder.id));
      }
    }
  }, [
    folders.msg,
    folders.folders,
    folders.folderUpdated,
    folders.changingRoot,
  ]);
  useEffect(() => {
    if (folders.movingProductDone) {
      if (folders.oneFolder) {
        dispatch(getOneFolder(folders.oneFolder.id));
        dispatch(desableMovingProductDone());
      }
      if (folders.rightSideFolder) {
        dispatch(getRightSideFolder(folders.rightSideFolder.id));
        dispatch(desableMovingProductDone());
      }
    }
  }, [folders.movingProductDone]);
  useEffect(() => {
    if (folders.deleteProductsDone) {
      if (folders.oneFolder) {
        dispatch(getOneFolder(folders.oneFolder.id));
        dispatch(desableDeleteProductsDone());
      }
      if (folders.rightSideFolder) {
        dispatch(getRightSideFolder(folders.rightSideFolder.id));
        dispatch(desableDeleteProductsDone());
      }
    }
  }, [folders.deleteProductsDone]);

  const draggableElementRef = React.useRef();
  const [subFolderId, setsubFolderId] = useState<any | undefined>();
  const [parentFolderId, setParentFolderId] = useState<any | undefined>();
  const [currentProductId, setCurrentProductId] = useState<any | undefined>();
  const [deleting, setDeleting] = useState(false);

  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (folders.rightSideFolder) {
      if (
        folders.rightSideFolder.products.length === 0 &&
        folders.rightSideSubFolders.length === 0
      ) {
        dispatch(clearRightSideFolder());
      }
    }
  }, [folders.rightSideFolder]);

  const onDragEnd = (draggable: any) => {
    const { destination, draggableId } = draggable;

    if (draggableId) {
      if (
        destination &&
        destination.droppableId &&
        destination.droppableId.includes("droppable-trash")
      ) {
        if (draggableId.includes("draggable-folder")) {
          const folderId = draggableId.substring("draggable-folder".length);
          dispatch(archiveFolder(folderId));
        }
        if (draggableId.includes("draggable-product")) {
          const productData = JSON.parse(draggableId);

          const productId = productData.draggableId.substring(
            "draggable-product".length
          );
          const currentproductParentId = productData.parentId;
          dispatch(
            deleteProductsfromFolder([productId], currentproductParentId)
          );
        }
      }
      if (draggableId.includes("draggable-folder")) {
        const folderId = draggableId.substring("draggable-folder".length);

        if (
          destination &&
          destination.droppableId &&
          destination.droppableId !== draggableId
        ) {
          const parentId = destination.droppableId.substring(
            "droppable-minature".length
          );
          if (parentId !== folderId) {
            dispatch(changeFolderRoot(parentId, folderId));
          }
        }
      }

      if (draggableId.includes("draggable-product")) {
        const productData = JSON.parse(draggableId);

        const productId = productData.draggableId.substring(
          "draggable-product".length
        );
        const currentproductParentId = productData.parentId;
        if (destination && destination.droppableId) {
          if (destination.droppableId.includes("droppable-minature")) {
            const newParentIdDroppable = destination.droppableId.substring(
              "droppable-minature".length
            );
            if (newParentIdDroppable !== currentproductParentId) {
              dispatch(
                moveProductToFolder(
                  newParentIdDroppable,
                  currentproductParentId,
                  productId
                )
              );
            }
          }

          if (destination.droppableId.includes("droppable-folder")) {
            const newParentIdDroppable = destination.droppableId.substring(
              "droppable-folder".length
            );
            if (newParentIdDroppable !== currentproductParentId) {
              dispatch(
                moveProductToFolder(
                  newParentIdDroppable,
                  currentproductParentId,
                  productId
                )
              );
            }
          }
        }
      }
    }
  };

  const getParentIdAndChildId = (id: any) => {
    let hasParentFolder = folders.folders.filter((el: any) => el.id === id)[0]
      .parent_id;
    if (hasParentFolder) {
      getParentIdAndChildId(hasParentFolder);
    } else {
      dispatch(getOneFolder(id));
      setsubfolders(folders.folders.filter((el: any) => el.parent_id === id));
    }
  };

  useEffect(() => {
    if (folders.folderIdToShow) {
      let hasParentFolder = folders.folders.filter(
        (el: any) => el.id === folders.folderIdToShow
      )[0].parent_id;
      if (!hasParentFolder) {
        dispatch(getOneFolder(folders.folderIdToShow));
      } else {
        dispatch(getRightSideFolder(folders.folderIdToShow));
        getParentIdAndChildId(hasParentFolder);
      }
    }
  }, [folders.folderIdToShow]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(addOneFolderToShow(null));
    }, 1000);
  }, []);

  useEffect(() => {
    if (folders.folderDetails) {
      dispatch(clearFolderDetails());
    }
    if (drawers.openFolderDetais) {
      dispatch(handleLeftFolderDetails());
    }
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="my-collections-container"
        isDropDisabled
        ignoreContainerClipping
        mode="standard"
        direction="horizontal"
      >
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <div className="collection_content_container">
              <CollectionFolders2
                setHover={setHover}
                hover={hover}
                setParentFolderId={setParentFolderId}
                setsubfolders={setsubfolders}
                subFolderId={subFolderId}
                setfolder={setfolder}
                selectScreen={selectScreen}
                setSelectScreen={setSelectScreen}
              />
              <div
                /*                   className="collections_contents"
                 */ style={{ overflowY: "auto", display: "flex" }}
              >
                <AnimatePresence exitBeforeEnter={false}>
                  {folders.selectedFolders.length > 0 && <ToolBarFolders />}
                </AnimatePresence>
                <AnimatePresence exitBeforeEnter={false}>
                  {folders.selectedProducts.length > 0 && (
                    <ToolBarProducts
                      setProductsToOrder={setProductsToOrder}
                      productsToOrder={productsToOrder}
                    />
                  )}
                </AnimatePresence>
                {selectScreen === "selections" ? (
                  <Selection width={width} />
                ) : selectScreen === "collections2" ? (
                  <AllCollection />
                ) : selectScreen === "customization" ? (
                  <MyCustomizedProducts />
                ) : selectScreen === "moodboards" ? (
                  <Moodboards />
                ) : folders.isLoadingOneFodler ? (
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <motion.div
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    className={
                      folders.rightSideFolder || folders.isLoadingRightFolder
                        ? "right_side_folders_products_reduced right_side_folders_products"
                        : "right_side_folders_products"
                    }
                  >
                    {/*     <MyCollectionHeader
                      showFunctions={true}
                      rightSideFolder={false}
                    /> */}
                    {
                      // folders.folders ? (
                      folders.oneFolder ? (
                        folders.oneFolder.products.length === 0 &&
                        subfolders.length === 0 ? (
                          // keep for customized products
                          // && folders.oneFolder.customized_products.length === 0
                          <div className="empty_collection ">
                            <img
                              src={folder_content}
                              alt="folders image"
                              className="mb-5 folders_image"
                            />
                            {/*   <h2 className="collection_welcome mb-3">
                              {folders.oneFolder.name}
                            </h2> */}
                            <p className="paragraphe collection_welcome_description mb-5 mt-4">
                              Fill your collection with your favorite products.
                            </p>
                            {!folders.folders && (
                              <Button
                                innerButton="Add New Collection"
                                buttonClass="submit_button_blue submit_button_blue_add_folder mt-5"
                                onClick={() => {}}
                                icon={<FontAwesomeIcon icon={faFolderPlus} />}
                                // reverse={false}
                              />
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="empty_collection ">
                              <img
                                src={folder_content}
                                alt="foders image"
                                className="mb-5 folders_image"
                              />
                              <h2 className="collection_welcome mb-3">
                                Start creating your collection
                              </h2>
                            </div>
                          </>
                        )
                      ) : (
                        <div className="empty_collection ">
                          <img
                            src={folder_content}
                            alt="foders image"
                            className="mb-5 folders_image"
                          />
                          <h2 className="collection_welcome mb-3">
                            Start creating your collection
                          </h2>

                          {folders.folders && folders.folders.length === 0 && (
                            <Button
                              innerButton="Add New Collection"
                              buttonClass="submit_button_blue submit_button_blue_add_folder mt-5"
                              onClick={() => {
                                dispatch(handleBottomDrawer());
                              }}
                              icon={<FontAwesomeIcon icon={faFolderPlus} />}
                              // reverse={false}
                            />
                          )}
                        </div>
                      )
                    }
                  </motion.div>
                )}
              </div>
            </div>

            <AnimatePresence initial={true} exitBeforeEnter={true}>
              {drawers.openFolderDetais && <FolderDetails />}
            </AnimatePresence>

            <AnimatePresence initial={true} exitBeforeEnter={true}>
              {/* @ts-ignore */}
              {drawers.openBottomMoveFolders && <AddToCollection />}
            </AnimatePresence>
            {/* {drawers.openBottomMoveFolders && <BottomDrawer mode="" />} */}
            {/* {provided.placeholder} */}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
