import { useEffect, useState } from "react";
import backArrow from "../../img/backArrow.png";
import { withRouter, Link, useParams, useLocation } from "react-router-dom";
import "./ProductDetails.scss";
import { handleLeftDrawer } from "../../actions/actionCreators";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import BottomDrawer from "../BottomDrawer/BottomDrawer";
import { getAllFolders } from "../../actions/folders-actions/actions";
import {
  getFilterAttributes,
  getProduct,
  getProductsFiltered,
  getType,
} from "../../actions/products-actions/actions";
import { handleSearchScreen } from "../../actions/search-actions/actions";
import { history } from "../../index";
import { v4 as uuidv4 } from "uuid";

import ProductSkeleton from "./ProductSkeleton";
import ProductHeader from "./ProductHeader";
import DenimProduct from "./DenimProduct";
import KnitProduct from "./KnitProduct";
import ProductFooter from "./ProductFooter";
import ProductImage from "./ProductImage";
import axiosInstance from "../../config/axios-instance";
import { Breadcrumb } from "antd";
import MixProduct from "./MixProduct";

export interface IProductDetailsProps {}

const ProductDetails = (props: IProductDetailsProps) => {
  const [totalLook, setTotalLook] = useState<any>(null);
  const [similarProducts, setSimilarProducts] = useState<any>(null);
  const [isLoadingTotalLook, setIsLoadingTotalLook] = useState(false);
  const [isLoadingSimilar, setIsLoadingSimilar] = useState(false);
  const [error, setError] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [editMode, setEditMode] = useState(false);
  const [mode, setMode] = useState("add_to_collection");
  const [oneProduct, setOneProduct] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const folders = useSelector((state: RootStateOrAny) => state.folders);
  const drawers = useSelector((state: RootStateOrAny) => state.drawers);
  const { nextProduct, isLoading, selectedProduct, isLoadingFilter } =
    useSelector((state: RootStateOrAny) => state.products);

  //@ts-ignore
  const pathnames = props.location.pathname
    .split("/")
    .filter((el: any) => el !== "details")
    .map((el: string) => {
      return el.charAt(0).toUpperCase() + el.slice(1);
    })
    .filter((x: string) => x);

  const dispatch = useDispatch();
  const params: any = useParams();
  const location = useLocation();

  useEffect(() => {
    // You can call your Laravel API to increment the product view counter here

    const trackVisit = async () => {
      try {
    /*    await axiosInstance.post('/products/track-visit', {
          product_id: params.productId
        });*/


        let token = localStorage.getItem("token");
        return axiosInstance({
          method: "post",
          url: `products/track-visit`,
          data: {
            product_id: params.productId
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error("Error tracking visit", error);
      }
    };

    trackVisit();
  }, []);

  useEffect(() => {
    dispatch(getAllFolders());
    dispatch(getProduct(params.productId));
  }, [params.productId]);

  useEffect(() => {
    if (selectedProduct) {
      dispatch(
        getProductsFiltered(
          selectedProduct?.fabric_type,
          selectedProduct?.categories?.[0]?.id,
          selectedProduct?.gender_id
        )
      );
    }
  }, [selectedProduct]);

  // useEffect(() => {
  //   if (selectedProduct) {
  //   }
  // }, []);
  useEffect(() => {
    setOneProduct(selectedProduct);
    if (selectedProduct) {
      dispatch(getFilterAttributes(selectedProduct.fabric_type, "17", "1"));
    }
  }, [selectedProduct, params.productId]);

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  const getTotalLook = async () => {
    let token = localStorage.getItem("token");
    setIsLoadingTotalLook(true);
    await axiosInstance({
      method: "get",
      url: `/products/${params.productId}/total-look`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res && res.data.data) {
          setTotalLook(res.data.data);
          setIsLoadingTotalLook(false);
        }
      })
      .catch((err) => {
        setError(err);
        setIsLoadingTotalLook(false);
      });
  };

  /*useEffect(() => {
    if (selectedProduct) {
      const xxx = async () => {
        await dispatch(
          getProductsFiltered(selectedProduct.fabric_type, "", selectedProduct.gender_id)
        );
      };
      xxx();
    }
  }, [selectedProduct]);*/

  const [similarProduct, setSimilarProduct] = useState([]);
  useEffect(() => {
    if (selectedProduct) {
    const mergedProducts = [].concat(
          ...selectedProduct?.fabrics?.flatMap((obj: any) =>
              obj.products.filter((product: any) => product.id !== selectedProduct.id)
          )
      );

      setSimilarProducts(mergedProducts);
      console.log(selectedProduct)
      console.log("mergedProducts")
      console.log(mergedProducts)
    }
  }, [selectedProduct]);

  const getSimilarProducts = async () => {
    let token = localStorage.getItem("token");
    setIsLoadingSimilar(true);
    await axiosInstance({
      method: "get",
      url: `/products/${params.productId}/similar`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res && res.data.data) {
          setSimilarProducts(res.data.data);
          setIsLoadingSimilar(false);
        }
      })
      .catch((err) => {
        setError(err);
        setIsLoadingSimilar(false);
      });
  };
  useEffect(() => {
    getTotalLook();
    // getSimilarProducts();
  }, [params.productId]);

  return isLoading ? (
    <ProductSkeleton oneProduct={oneProduct} />
  ) : (
    <div className="product_details_block container-fluid">
      <div className="product_details_contanier row">
        <div className="product_images_wrapper col-6 ">
          <ProductImage
            oneProduct={oneProduct}
            width={width}
            setVisible={setVisible}
            visible={visible}
          />
        </div>
        <div className="product_details_infos_scroll col-6">
        {/*  {!editMode && (
            <div className="bread_crumb_product">
              <img
                src={backArrow}
                alt="Back Arrow"
                style={{ cursor: "pointer" }}
                onClick={() => history.back()}
              />

              <Breadcrumb>
                {pathnames.map(
                  (el: string, i: number) =>
                    i !== pathnames.length - 1 &&
                    el !== "Undefined" && (
                      <Breadcrumb.Item key={i}>
                        {i === pathnames.length - 2
                          ? oneProduct &&
                            (oneProduct.code
                              ? oneProduct.code + " " + oneProduct.name
                              : oneProduct.name)
                          : el.charAt(0).toUpperCase() +
                            el.toLowerCase().slice(1)}
                      </Breadcrumb.Item>
                    )
                )}
              </Breadcrumb>
            </div>
          )}*/}
          <div
            className="product_details_infos"
            style={{ paddingTop: editMode ? "20px" : "0px" }}
          >
            {oneProduct && oneProduct.fabric_type === "knit" ? (
              <KnitProduct
                oneProduct={oneProduct}
                editMode={editMode}
                setEditMode={setEditMode}
                key={uuidv4()}
                totalLook={totalLook}
                similarProducts={similarProducts}
                isLoadingTotalLook={isLoadingTotalLook}
                isLoadingSimilar={isLoadingSimilar}
              />
            ) : (oneProduct && oneProduct.fabric_type === "denim") ?  (
              <DenimProduct
                oneProduct={oneProduct}
                editMode={editMode}
                setEditMode={setEditMode}
                key={uuidv4()}
                totalLook={totalLook}
                similarProducts={similarProducts}
                isLoadingTotalLook={isLoadingTotalLook}
                isLoadingSimilar={isLoadingSimilar}
              />
            ):
            <>
              <MixProduct
                  oneProduct={oneProduct}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  key={uuidv4()}
                  totalLook={totalLook}
                  similarProducts={similarProducts}
                  isLoadingTotalLook={isLoadingTotalLook}
                  isLoadingSimilar={isLoadingSimilar}
              />
            </>
            }
          </div>
          {!editMode && (
            <ProductFooter
              isLoadingNext={isLoading}
              width={width}
              oneProduct={oneProduct}
            />
          )}
        </div>
      </div>

      {drawers.openBottom && (
        <BottomDrawer folders={folders} mode={mode} setMode={setMode} />
      )}
      {/* {drawers.openAddFolder && <BottomDrawer mode="add_folder_collection" />} */}
    </div>
  );
};

export default withRouter(ProductDetails);
