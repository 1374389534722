import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { handleBottomDrawer } from "../../actions/actionCreators";
import { getProductId } from "../../actions/folders-actions/actions";
import { useParams, Link, useLocation } from "react-router-dom";
import { Button } from "../Button/Button";
import color_circle from "../../img/color-circle.svg";
import { nextProd, prevProd } from "../../actions/products-actions/actions";
import backward from "../../img/backward.png";
import placeholder from "../../img/img_placeholder.svg";
import forward from "../../img/forward.png";
import { Button as AntButton } from "antd";

const ProductFooter = ({ oneProduct, width, isLoadingNext }: any) => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const location: any = useLocation();

  const { nextProduct, prevProduct } = useSelector(
    (state: RootStateOrAny) => state.products
  );
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  return isLoadingNext ? (
    <p>loading...</p>
  ) : (
    <div
      className={
        user && !isAdmin
          ? "product_fontinnalities_container d-flex"
          : "arrows_admin product_fontinnalities_container d-flex"
      }
      style={{ minHeight: "90px", alignItems: "stretch" }}
    >
      {user && !isAdmin && (
        <div
          style={{ flex: 1, width: "100%" }}
          className="d-flex align-items-stretch"
        >
          <AntButton
            onClick={() => {
              dispatch(handleBottomDrawer());
              dispatch(getProductId(params.productId));
            }}
            className="add-collection-footer"

          >
            Add to collection
          </AntButton>

          <Link
            to={`/customization/new/${oneProduct && oneProduct.id}`}
            className="customization_link"
          >
            <AntButton
              style={{ height: "100%", width: "100%", }}
              className="d-flex align-items-center justify-content-center"
            >
              <img src={color_circle} className="mr-2" />
              Start customization
            </AntButton>
          </Link>
        </div>
        // <div className="d-flex">
        //   <Button
        //     buttonClass="submit_button_white submit_button_white_add no-radius"
        //     innerButton="Add to collection "
        //     onClick={() => {
        //       dispatch(handleBottomDrawer());
        //       dispatch(getProductId(params.productId));
        //     }}
        //   />
        //   <Link to={`/customization/new/${oneProduct && oneProduct.id}`}>
        //     <Button
        //       buttonClass="submit_button_white submit_button_white_cust no-radius"
        //       innerButton={width > 575 ? "Start customization" : ""}
        //       icon={color_circle}
        //       reverse={true}
        //     />
        //   </Link>
        // </div>
      )}
  {/*    <div
        className="btns-group-product d-flex align-items-center"
        style={{ borderRight: "1px solid #C0C8D0" }}
      >
      {!location.pathname.includes("new") &&
          !location.pathname.includes("MySelection") && (
            <>
              <Link
                to={
                  prevProduct
                    ? location.pathname.includes("search")
                      ? `/search/result/${params.keyword}/${prevProduct.name}/${prevProduct.id}`
                      : `/product/details/${params.category}/${params.gendre}/${params.style}/${prevProduct.name}/${prevProduct.id}`
                    : "#"
                }
                onClick={() => prevProduct && dispatch(prevProd(prevProduct))}
              >
                <div className="arrow_contanier arrow_contanier_left">
                  <img src={backward} alt="" className="arrow_prev" />
                  {prevProduct ? (
                    <img
                      src={
                        prevProduct.images.length
                          ? process.env.REACT_APP_STORAGE_URL +
                            prevProduct.images[0].path
                          : placeholder
                      }
                      alt={prevProduct.name}
                      className="prevprod_img"
                      width="50"
                    />
                  ) : (
                    <img src={backward} alt="" className="prevprod_img" />
                  )}
                </div>
              </Link>
              <Link
                to={
                  nextProduct
                    ? location.pathname.includes("search")
                      ? `/search/result/${params.keyword}/${nextProduct.name}/${nextProduct.id}`
                      : `/product/details/${params.category}/${params.gendre}/${params.style}/${nextProduct.name}/${nextProduct.id}`
                    : "#"
                }
                onClick={() => nextProduct && dispatch(nextProd(nextProduct))}
              >
                <div className="arrow_contanier arrow_contanier_right">
                  <img src={forward} alt="" className="arrow_next" />
                  {nextProduct ? (
                    <img
                      src={
                        nextProduct.images.length
                          ? process.env.REACT_APP_STORAGE_URL +
                            nextProduct.images[0].path
                          : placeholder
                      }
                      alt={nextProduct.name}
                      className="nextprod_img"
                      width="50"
                    />
                  ) : (
                    <img src={forward} alt="" className="nextprod_img" />
                  )}
                </div>
              </Link>
            </>
          )}
      </div>*/}
    </div>
  );
};

export default ProductFooter;
