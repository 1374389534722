import {
  GET_SELECTION_REQUEST,
  GET_SELECTION_SUCCESS,
  GET_SELECTION_FAIL,
  ADD_PRODUCT_TO_SELECTION_REQUEST,
  ADD_PRODUCT_TO_SELECTION_SUCCESS,
  REMOVE_PRODUCT_FROM_SELECTION_REQUEST,
  DESELECT_SELECTION,
  SELECT_SELECTION,
  DIRECT_RENDER,
  REMOVE_PRODUCT_FROM_SELECTION_SUCCESS,
} from "../actions/myselection-actions/types";
// import { useDispatch } from "react-redux";
// import { getSelectionProducts } from "../actions/myselection-actions/actions";
const init_state = {
  selectionProducts: null,
  isLoading: false,
  removeProductLoading: false,
  selectionSelected: false,
  directRender: false,
  productToAddId: null,
};

const SelectionReducer = (state: any = init_state, action: any) => {
  // const dispatch = useDispatch();
  switch (action.type) {
    case GET_SELECTION_SUCCESS:
      return {
        ...state,
        selectionProducts: action.payload.products.reverse(),
        isLoading: false,
      };
    case GET_SELECTION_REQUEST:
      return { ...state, isLoading: true };
    case DESELECT_SELECTION:
      return { ...state, selectionSelected: false, directRender: false };
    case SELECT_SELECTION:
      return { ...state, selectionSelected: true };
    case ADD_PRODUCT_TO_SELECTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        productToAddId: action.payload,
      };
    case ADD_PRODUCT_TO_SELECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productToAddId: null,
      };
    // return { ...state, selectionSelected: true };
    case REMOVE_PRODUCT_FROM_SELECTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        removeProductLoading: true,
        productToAddId: action.payload,
      };
    case REMOVE_PRODUCT_FROM_SELECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        removeProductLoading: false,
        productToAddId: null,
      };
    case GET_SELECTION_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case DIRECT_RENDER:
      return {
        ...state,
        directRender: !state.directRender,
        selectionSelected: true,
      };

    default:
      return state;
  }
};

export default SelectionReducer;
