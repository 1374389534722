import { Button } from "antd";
import WashCollapse from "./WashCollapse";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

function EditDenimWash({
  genExtra,
  setProductToUpdate,
  productToUpdate,
  data,
  setData,
  options,
  handleChangeMultiWash,
  handleChange,
  addNewWash,
}: any) {
  const { user } = useSelector((state: any) => state.auth);

  return (
    <div>
      {productToUpdate.washes.length === 0 ? (
        <h6 className="text-center p-2 mt-2 text-muted product_doesnt_have">
          This product doesn't have wash
        </h6>
      ) : (
        productToUpdate.washes.map((el: any, i: number) => (
          <WashCollapse
            key={i}
            genExtra={genExtra}
            i={i}
            el={el}
            setProductToUpdate={setProductToUpdate}
            productToUpdate={productToUpdate}
            data={data}
            setData={setData}
            options={options}
            handleChangeMultiWash={handleChangeMultiWash}
            handleChange={handleChange}
          />
        ))
      )}

      {productToUpdate.with_options ? (
        productToUpdate.washes.length < 2 && (
          <Button
            type="primary"
            icon={<PlusOutlined style={{ verticalAlign: 0 }} />}
            className="add_new_one"
            onClick={addNewWash}
            disabled={
              !user.permissions.includes("product.wash.management") &&
              !user.permissions.includes("product.update")
            }
          >
            Add Another Wash
          </Button>
        )
      ) : (
        <Button
          type="primary"
          icon={<PlusOutlined style={{ verticalAlign: 0 }} />}
          className="add_new_one"
          onClick={addNewWash}
          disabled={
            !user.permissions.includes("product.wash.management") &&
            !user.permissions.includes("product.update")
          }
        >
          Add Another Wash
        </Button>
      )}
    </div>
  );
}

export default EditDenimWash;
