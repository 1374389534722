import React, { useState, useEffect } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import greenDrop from "../../img/green_drop.svg";
import greenEnergy from "../../img/green_energy.svg";
import co2Colored from "../../img/co2Colored.png";
import green_chimicals from "../../img/green_chimicals.svg";
import "./Carousel3D.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../config/axios-instance";

function Carousel3D(width:any ) {
  // useState hooks
  const [goToSlide, setGoToSlide] = useState(0);
  const [offsetRadius] = useState(2);
  const [showNavigation] = useState(false);
  const [hovered, setHovered] = useState(false);

  const [commitmentHome, setcommitmentHome] = useState<any>(null);
  const [threeContent, setThreeContent] = useState<any>(null);

  const getCommitmentHome = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/home/commitment_home`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setcommitmentHome(res.data)

    });
  };

  const getthreeHome = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/home/three_home`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setThreeContent(res.data)

    });
  };

  useEffect(() => {
    getCommitmentHome();
    getthreeHome();

  }, []);

  const [threeContents, setThreeContents] = useState([]);

  useEffect(() => {
    // Fetch the content when the component mounts
    const getthreeHome = () => {
      let token = localStorage.getItem("token");
      return axiosInstance({
        method: "get",
        url: `/home/three_home`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setThreeContents(res.data);
      })
          .catch((err) => {
            console.error("Could not fetch the slides:", err);
          });
    };

    getthreeHome();

    // Set the timer for auto-advancing the slides
    const timer = setInterval(() => {
      if (!hovered && threeContents.length) {
        setGoToSlide(prevSlide => (prevSlide + 1) % threeContents.length);
      }
    }, 4000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(timer);
  }, [hovered, threeContents.length]);

  // Map 'threeContents' to generate the slides
  const slides = threeContents.map((content:any, index:any) => ({
    key: content.id, // Assuming each content has a unique 'id'
    content: (
        <div className="carousel_item">
          <img src={process.env.REACT_APP_STORAGE_URL+content.image} alt={content.description} />
          <p>{content.description}</p>
        </div>
    ),
    onClick: () => setGoToSlide(index),
  }));

  return (
      <div className="sustainabiliy">
        {width <= 768 && <div className="blue_line"></div>}
        <h1 className="our_label text-center">
          {commitmentHome?.title_1}
        </h1>
        <p className="sustainability_paragraph">
          {commitmentHome?.description_1}
        </p>
        <p className="sustainability_paragraph">
          {commitmentHome?.description_1}
        </p>

        <div
            className="carousel3D_wrapper"
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
          <Carousel
              slides={slides}
              goToSlide={goToSlide}
              offsetRadius={offsetRadius}
              showNavigation={showNavigation}
              animationConfig={config.gentle}
          />
        </div>

        {/* Icons navigation conditional rendering */}
      </div>
  );
}

export default Carousel3D;

