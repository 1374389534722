import * as React from "react";
import emptyFolder from "../../img/emptyFolder.png";
import cursor_grab from "../../img/cursor_grab.png";
import folderIcon from "../../img/folderIcon.png";
import mutipleFolders from "../../img/mutipleFolders.png";
import FolderImage from "../../icons/Folder";
import placeholder from "../../img/img_placeholder.svg";
import { useSelector } from "react-redux";
import "./DraggableFolder.scss";
import { FormEvent, useEffect, useState } from "react";
import {
  getRightSideFolder,
  getRightSideSubFolders,
  addFolderToSelectedFolders,
  setFolderToMoveTo,
  clearSelectedProducts,
} from "../../actions/folders-actions/actions";
import { Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Motion, spring } from "react-motion";
export interface IFolderProps {
  folder: any;
  addFolderToSelected?: ((id: any) => void) | undefined;
  setsubfolders?: ((id: any) => void) | undefined;
  setselectedfolders?: ((id: any) => void) | undefined;
  //if true can be selected
  width?: number;
  clickable?: boolean;
  addToCollection?: boolean;
  changeCurrentSubFolderId: (id: any) => void;

  index: number;
}

export default function DraggableFolder({
  folder,
  addFolderToSelected,
  setsubfolders,
  setselectedfolders,
  width,
  clickable,
  addToCollection,
  index,
}: IFolderProps) {
  const dispatch = useDispatch();
  const [nbfolders, setnbfolders] = useState<any>([]);
  const [selected, setselected] = useState(false);
  const folders = useSelector((state: any) => state.folders);
  //@ts-ignore
  const getSubFolders = (event: FormEvent) => {
    event.stopPropagation();
    if (folder.folders_count > 0) {
      setsubfolders &&
        setsubfolders(
          folders.folders.filter((el: any) => el.parent_id === folder.id)
        );
    }
    setselectedfolders && setselectedfolders([]);
    setselected(false);
  };
  useEffect(() => {
    var arr = [];
    if (folder.folders_count) {
      var i = 0;
      while (i < folder.folders_count) {
        arr.push(i);
        i++;
      }
    }
    // addFolderToSelected && addFolderToSelected(folder.id);
    setnbfolders(arr);
  }, []);

  const navigateFolder = (e: any) => {
    e.stopPropagation();
    setCheckedCheck(!checkedCheck);
    if (folders.moving) {
      dispatch(setFolderToMoveTo(folder.id));
    } else if (clickable) {
      addFolderToSelected && addFolderToSelected(folder.id);
      setselected(!selected);
    } else {
      dispatch(addFolderToSelectedFolders(folder.id));
      setselected(!selected);
    }
  };
  const handleChange = () => {
    dispatch(clearSelectedProducts());
  };
  useEffect(() => {
    if (folders.moving) {
      if (folders.folderIdToMoveTo === folder.id) {
        setselected(true);
      } else {
        setselected(false);
      }
    }
  });
  useEffect(() => {
    if (folders.selectedFolders.find((f: any) => f === folder.id)) {
      setselected(true);
    }
  }, [width]);
  const [checkedCheck, setCheckedCheck] = useState(false);
  useEffect(() => {
    if (folders.selectedFolders.includes(folder.id)) {
      setCheckedCheck(true);
    } else {
      setCheckedCheck(false);
    }
  }, [folders.selectedFolders]);

  const getItemStyle = (
    isDragging: boolean,
    isDraggingOver: any,
    draggableStyle: any
  ) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: "10px",
    // change background colour if dragging
    borderColor: isDragging ? "lightblue" : "#dcdbdc",

    // styles we need to apply on draggables
    ...draggableStyle,
    //transform: isDragging ? "scale(.5) !important" : "scale(.5) !important",
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    borderRadius: 10,
    padding: "1px",
    border: isDraggingOver ? "2px solid #4ed578" : "none",
  });
  return (
    <Droppable droppableId={`droppable-folder${folder.id}`}>
      {(providedDrop, snapshotDrop) => (
        <div
          {...providedDrop.droppableProps}
          ref={providedDrop.innerRef}
          style={getListStyle(snapshotDrop.isDraggingOver)}
        >
          <Draggable
            key={folder.id}
            draggableId={`draggable-folder${folder.id}`}
            index={index}
            disableInteractiveElementBlocking
            isDragDisabled={true}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={
                  selected
                    ? "one_folder one_folder_selected active"
                    : "one_folder active"
                }
                style={getItemStyle(
                  snapshot.isDragging,
                  snapshot.draggingOver,
                  provided.draggableProps.style
                )}
                // onClick={navigateFolder}
                onClick={(e) => {
                  if (addToCollection) {
                    getSubFolders(e);
                  } else if (
                    folder.products.length > 0 ||
                    folder.folders_count > 0
                  ) {
                    e.stopPropagation();
                    /*  dispatch(getRightSideFolder(folder.id));
          dispatch(getRightSideSubFolders(folder.id)); */
                  }
                }}
              >
                {folder.products.length > 0 || nbfolders.length > 0 ? (
                  <div className="list_folder_elements">
                    {folder.products.map((product: any, i: number) =>
                      i < 7 ? (
                        <div key={i} className="product_image_container">
                          {product.image ? (
                            <img
                              src={
                                process.env.REACT_APP_STORAGE_URL +
                                  product.image.path || placeholder
                              }
                              alt=""
                              className="img_product_folder"
                            />
                          ) : (
                            <img
                              src={placeholder}
                              alt=""
                              className="img_product_folder"
                            />
                          )}
                        </div>
                      ) : null
                    )}

                    {folder.folders_count && folder.products.length < 7
                      ? nbfolders.map((el: any, i: number) => (
                          <div
                            key={i}
                            className=" product_image_container folders_indicator"
                          >
                            <FolderImage className="folder" />
                          </div>
                        ))
                      : null}

                    {folder.products.length > 7 && (
                      <div className=" product_image_container product_image_container_plus">
                        <p className="paragraphe products_plus_folder_value ">
                          +{folder.products.length + nbfolders.length - 8}
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="list_folder_elements empty_folder">
                    <p className="paragraphe">Empty Folder</p>
                  </div>
                )}
                {folder.products.length === 0 && folder.folders_count === 0 ? (
                  <div className="folder_footer">
                    <div
                      style={{
                        borderRight: "1px solid #ccc",
                        paddingRight: "10px",
                        marginRight: "8px",
                      }}
                    >
                      {/*  <Checkbox
                        checked={checkedCheck}
                        onClick={navigateFolder}
                        onChange={handleChange}
                      ></Checkbox> */}
                    </div>
                    <img src={emptyFolder} />
                    <p
                      className="folder_name"
                      onClick={(e) => {
                        if (addToCollection) {
                          getSubFolders(e);
                        } else if (
                          folder.products.length > 0 ||
                          folder.folders_count > 0
                        ) {
                          e.stopPropagation();
                          dispatch(getRightSideFolder(folder.id));
                          dispatch(getRightSideSubFolders(folder.id));
                        }
                      }}
                    >
                      {folder.name}
                    </p>
                  </div>
                ) : folder.folders_count === 0 ? (
                  <div className="folder_footer">
                    <div
                      style={{
                        borderRight: "1px solid #ccc",
                        paddingRight: "10px",
                        marginRight: "8px",
                      }}
                    >
                      {/*  <Checkbox
                        checked={checkedCheck}
                        onClick={navigateFolder}
                        onChange={handleChange}
                      ></Checkbox> */}
                    </div>
                    <img src={folderIcon} />
                    <p
                      className="folder_name"
                      onClick={(e) => {
                        if (addToCollection) {
                          getSubFolders(e);
                        } else if (
                          folder.products.length > 0 ||
                          folder.folders_count > 0
                        ) {
                          e.stopPropagation();
                          dispatch(getRightSideFolder(folder.id));
                          dispatch(getRightSideSubFolders(folder.id));
                        }
                      }}
                    >
                      {folder.name}
                    </p>
                  </div>
                ) : (
                  <div className="folder_footer">
                    <div
                      style={{
                        borderRight: "1px solid #ccc",
                        paddingRight: "10px",
                        marginRight: "8px",
                      }}
                    >
                      {/*    <Checkbox
                        checked={checkedCheck}
                        onClick={navigateFolder}
                        onChange={handleChange}
                      ></Checkbox> */}
                    </div>
                    <img src={mutipleFolders} />
                    <p
                      className="folder_name"
                      onClick={(e) => {
                        if (addToCollection) {
                          getSubFolders(e);
                        } else if (
                          folder.products.length > 0 ||
                          folder.folders_count > 0
                        ) {
                          e.stopPropagation();
                          dispatch(getRightSideFolder(folder.id));
                          dispatch(getRightSideSubFolders(folder.id));
                        }
                      }}
                    >
                      {folder.name}
                    </p>
                  </div>
                )}
              </div>
            )}
          </Draggable>
          <div style={{ maxHeight: "0px" }}>{providedDrop.placeholder}</div>
        </div>
      )}
    </Droppable>
  );
}
