import Close from "../../icons/Close";
import "./MySelection.scss";
import { SelectionItem } from "./SelectionItem";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { handleRightDrawer } from "../../actions/actionCreators";
import { directRenderSelection } from "../../actions/myselection-actions/actions";
import { useState } from "react";

interface Props {
  closeDrawer: () => void;
}

export const MySelection = ({ closeDrawer }: Props) => {
  const [orderedItemsId, setOrderedItemsId] = useState<number[]>([]);
  const selectionR = useSelector((state: any) => state.selection);
  const dispatch = useDispatch();

  const orderSelection = (id: number) => {
    setOrderedItemsId([...orderedItemsId, id]);
  };

  return (
      <aside className="myselection_aside">
          <header>
              <div className="close_btn" onClick={closeDrawer}>
                  <p>Close</p>
                  <Close/>
              </div>
          </header>
{/*
          <h1 className="h2_subtitle_black">My selection</h1>
*/}
          <h1 className="h2_subtitle_black_wish_liste">My selection</h1>
          <main style={{flexGrow: 1}}>
              {selectionR.selectionProducts &&
              selectionR.selectionProducts.length > 0 ? (
                  selectionR.selectionProducts
                      .filter(
                          (x: any, i: number) =>
                              !orderedItemsId.find((el: any) => el === x.id)
                      )
                      .slice(0, 5)
                      .map((el: any, i: number) => (
                          <SelectionItem
                              item={el}
                              key={i}
                              orderSelection={(x: number) => orderSelection(x)}
                          />
                      ))
              ) : (
                  <div style={{textAlign: "center", marginTop: "50px"}}>
                      <p className="empty_selection_msg">{`Your Selection is Empty.`}</p>
                      <p className="empty_selection_msg">
                          {`Fill it with your favorites Products.`}
                      </p>
                  </div>
              )}
          </main>
          {selectionR.selectionProducts &&
              selectionR.selectionProducts.length > 5 && (
                  <div className="myselection_see_all">
                      <Link
                          to="/myCollection?section=myselection"
                          style={{
                              width: "100%",
                          }}
                          onClick={() => {
                              dispatch(handleRightDrawer());
                              dispatch(directRenderSelection());
                          }}
                      >
                          <button
                              className="submit_button_white btn_see_all"

                          >
                              See all
                          </button>
                      </Link>
                  </div>
              )}
      </aside>
  );
};
