import { Reducer, AnyAction } from "redux";
import {
  GET_CAT_UNDER_GENDER_SUCCESS,
  GET_GENDERS_UNDER_FABRIC_SUCCESS,
  CLOSE_SIDE_MENU_EXTENSION_REQUEST,
  GET_CAT_UNDER_GENDER_REQUEST,
  GET_GENDERS_UNDER_FABRIC_REQUEST,
  GET_ALL_GENDERS_SUCCESS,
  GET_ALL_COLLECTIONS_SUCCESS,
  GET_ALL_COLLECTIONS_REQUEST,
} from "../actions/left-menu-actions/types";

const init_state: any = {
  categories: [],
  genders: [],
  allGenders: [],
  collections: [],
  denimGenders: undefined,
  knitGenders: undefined,
  isLoadingCategories: false,
  isLoadingGenders: false,
  isLoadingCollections: false,
};

const leftMenuReducer: Reducer<any, AnyAction> = (
  state: any = init_state,
  action
): boolean => {
  switch (action.type) {
    case GET_CAT_UNDER_GENDER_REQUEST:
      return { ...state, isLoadingCategories: true };
    case GET_GENDERS_UNDER_FABRIC_REQUEST:
      return { ...state, isLoadingGenders: true };
    case GET_ALL_COLLECTIONS_REQUEST:
      return { ...state, isLoadingCollections: true };
    case GET_CAT_UNDER_GENDER_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        isLoadingCategories: false,
      };
    case GET_GENDERS_UNDER_FABRIC_SUCCESS:
      return {
        ...state,
        genders: action.payload.data,
        isLoadingGenders: false,
        [action.payload.fabric + "Genders"]: action.payload.data,
      };
    case CLOSE_SIDE_MENU_EXTENSION_REQUEST:
      return { ...state };
    case GET_ALL_GENDERS_SUCCESS:
      return { ...state, allGenders: action.payload };
    case GET_ALL_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: action.payload.data,
        isLoadingCollections: false,
      };
    default:
      return state;
  }
};

export default leftMenuReducer;
