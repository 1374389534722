import { useQuery } from "react-query";
import { useRouteMatch, Route, Switch, useParams } from "react-router-dom";
import { getOneDemcoOrder } from "../../actions/my-orders/services";
import "../../sass/MyOrders.scss";
import MyOrdersTable from "./MyOrdersTable";
import backArrow from "../../img/backArrow.png";
import { history } from "../..";

export default function DemcoProductDetails({
  orderStatus,
}: {
  orderStatus: string;
}) {
  const { path, url } = useRouteMatch();
  const params = useParams<{ id: string; status: string }>();

  const { data, isLoading, refetch } = useQuery(
    `demco_product_${params.id}`,
    () => getOneDemcoOrder(params.id)
  );

  const columns = ["Product Name", "Sent Date", "Progress"];

  return (
    <div className="my_orderss">
      {!isLoading && (
        <div className="myorder_details_header my-3">
          <img
            src={backArrow}
            className="back_arrow_folder"
            alt="back arrow"
            onClick={() => history.back()}
          />
          <h1 className="ml-2">
            {data?.data.reference}{" "}
            <span style={{ fontWeight: 400 }}>
              ({data?.data.products.length}{" "}
              {data?.data.products.length
                ? data.data.products.length > 1
                  ? "Products"
                  : "Product"
                : data?.data.products.length > 1
                ? "Products"
                : "Product"}
              )
            </span>
          </h1>
        </div>
      )}
      <Switch>
        <Route path={`${path}`} exact>
          <MyOrdersTable
            columns={columns}
            isLoading={isLoading}
            orderType="demco_product_content"
            data={data?.data}
            url={url}
            orderStatus={orderStatus}
            refetch={refetch}
          />
        </Route>
        {/* <Route path={`${path}/:option_id`}>
          <h1>option progress will be here 🥱</h1>
        </Route> */}
        <Route>
          <h1>Page Not Found</h1>
        </Route>
      </Switch>
    </div>
  );
}
