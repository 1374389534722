export const SharedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="17.5"
      viewBox="0 0 25 17.5"
    >
      <path
        id="Tracé_3387"
        data-name="Tracé 3387"
        d="M8.75-8.75a3.124,3.124,0,0,0,3.125-3.125A3.124,3.124,0,0,0,8.75-15a3.124,3.124,0,0,0-3.125,3.125A3.124,3.124,0,0,0,8.75-8.75Zm0-5a1.875,1.875,0,0,1,1.875,1.875A1.875,1.875,0,0,1,8.75-10a1.875,1.875,0,0,1-1.875-1.875A1.875,1.875,0,0,1,8.75-13.75ZM0-7.5a4.373,4.373,0,0,0,4.375-4.375A4.373,4.373,0,0,0,0-16.25a4.373,4.373,0,0,0-4.375,4.375A4.373,4.373,0,0,0,0-7.5ZM0-15a3.129,3.129,0,0,1,3.125,3.125A3.129,3.129,0,0,1,0-8.75a3.129,3.129,0,0,1-3.125-3.125A3.129,3.129,0,0,1,0-15ZM9.531-7.5H7.969A2.826,2.826,0,0,0,6.363-7a5.294,5.294,0,0,1,.992.875,1.555,1.555,0,0,1,.613-.129H9.531A1.8,1.8,0,0,1,11.25-4.375a.627.627,0,0,0,.625.625.627.627,0,0,0,.625-.625A3.054,3.054,0,0,0,9.531-7.5ZM-8.75-8.75a3.124,3.124,0,0,0,3.125-3.125A3.124,3.124,0,0,0-8.75-15a3.124,3.124,0,0,0-3.125,3.125A3.124,3.124,0,0,0-8.75-8.75Zm0-5a1.875,1.875,0,0,1,1.875,1.875A1.875,1.875,0,0,1-8.75-10a1.875,1.875,0,0,1-1.875-1.875A1.875,1.875,0,0,1-8.75-13.75ZM3.129-6.719c-1.3,0-1.629.469-3.129.469s-1.824-.469-3.129-.469A4.305,4.305,0,0,0-6.734-4.887,4.476,4.476,0,0,0-7.5-2.375v1.75A1.875,1.875,0,0,0-5.625,1.25H5.625A1.875,1.875,0,0,0,7.5-.625v-1.75a4.476,4.476,0,0,0-.766-2.512A4.305,4.305,0,0,0,3.129-6.719ZM6.25-.625A.627.627,0,0,1,5.625,0H-5.625A.627.627,0,0,1-6.25-.625v-1.75A3.246,3.246,0,0,1-5.7-4.188,3.057,3.057,0,0,1-3.133-5.469C-2.062-5.469-1.68-5,0-5s2.062-.469,3.129-.469A3.057,3.057,0,0,1,5.7-4.188a3.245,3.245,0,0,1,.551,1.813v1.75ZM-6.363-7a2.845,2.845,0,0,0-1.605-.5H-9.531A3.054,3.054,0,0,0-12.5-4.375a.627.627,0,0,0,.625.625.627.627,0,0,0,.625-.625A1.8,1.8,0,0,1-9.531-6.25h1.563a1.555,1.555,0,0,1,.613.129A5.419,5.419,0,0,1-6.363-7Z"
        transform="translate(12.5 16.25)"
        fill="#7a9ec5"
      />
    </svg>
  );
};
