import React, {useEffect, useState} from "react";
import { Input} from "antd";


import {OrderChart} from "../components/Chart/OrderChart";
import {OrderNumber} from "../components/Chart/OrderNumber";
import {ClientConnected} from "../components/Chart/ClientConnected";
import {SelectionChart} from "../components/Chart/SelectionChart";
import {CollectionChart} from "../components/Chart/CollectionChart";
import {CustomizedProductChart} from "../components/Chart/CustomizedProductChart";
import {NumberProductAdded} from "../components/Chart/NumberProductAdded";
import {ViewProduct} from "../components/Chart/ViewProduct";
import {View3D} from "../components/Chart/View3D";
import {CollectionLaunchChart} from "../components/Chart/CollectionLaunchChart";
import axiosInstance from "../config/axios-instance";
import axios from "axios";
import {useQuery} from "react-query";
import {FabricVisted} from "../components/Chart/FabricVisted";

const {Search} = Input;

interface Props {
}

export const Report = (props: Props) => {

    const [seasonRes, setSeasonRes] = useState<any>(null);
    const [seasonCollectionRes, setCollectionSeasonRes] = useState<any>(null);


    const fetchSeason = () => {
        let token = localStorage.getItem("token");
        return axiosInstance({
            method: "get",
            url: `/collections/home/published`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res: any) => {
           // console.log(res.data)
            setSeasonRes(res.data)
        });
    };

    useEffect(() => {
        fetchSeason();


    }, []);

    const fetchSeasonCollection = () => {
        let token = localStorage.getItem("token");
        return axiosInstance({
            method: "get",
            url: `/collections/season/report`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res: any) => {
            // console.log(res.data)
            setCollectionSeasonRes(res.data)
        });
    };

    useEffect(() => {
        fetchSeasonCollection();


    }, []);




    return (
        <>
            <div style={{paddingTop: "100px"}}
            >
                <div className={"titleReport"}>
                    Dashboard ID Webshop
                </div>
                <div className={"grid-container p-5"}>


                    <OrderChart></OrderChart>

                    <OrderNumber></OrderNumber>

                    <ClientConnected></ClientConnected>
                    <SelectionChart seasonCollectionRes={seasonCollectionRes}></SelectionChart>


                       <CustomizedProductChart></CustomizedProductChart>
                        <NumberProductAdded seasonCollectionRes={seasonRes}></NumberProductAdded>
                     <ViewProduct seasonCollectionRes={seasonRes}></ViewProduct>
                    <View3D seasonCollectionRes={seasonRes}></View3D>
                    <FabricVisted seasonCollectionRes={seasonRes}></FabricVisted>

                </div>
            </div>
        </>)
};
