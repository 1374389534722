import {
  HANDLE_LEFT,
  HANDLE_RIGHT,
  HANDLE_BOTTOM,
  HANDLE_BOTTOM_DELETED_ITEMS,
  HANDLE_LEFT_FOLDER_DETAILS,
  HANDLE_BOTTOM_ADD_TO_COLLECTION,
  HANDLE_BOTTOM_ADD_FOLDER,
} from "../actions/actionTypes";
import { LOGOUT_USER } from "../actions/auth-actions/types";

const initialState = {
  openRight: false,
  openLeft: false,
  openBottom: false,
  openDeletedItems: false,
  openFolderDetais: false,
  openBottomMoveFolders: false,
  openAddFolder: false,
};

const MenuReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HANDLE_LEFT:
      if (state.openLeft === false) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
      return {
        ...state,
        openLeft: !state.openLeft,
      };
    case HANDLE_BOTTOM_DELETED_ITEMS:
      if (state.openDeletedItems === false) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
      return {
        ...state,
        openDeletedItems: !state.openDeletedItems,
      };
    case HANDLE_BOTTOM_ADD_TO_COLLECTION:
      if (state.openDeletedItems === false) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
      return {
        ...state,
        openBottomMoveFolders: !state.openBottomMoveFolders,
      };
    case HANDLE_LEFT_FOLDER_DETAILS:
      if (state.openFolderDetais === false) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
      return {
        ...state,
        openFolderDetais: !state.openFolderDetais,
      };
    case HANDLE_RIGHT:
      if (state.openRight === false) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
      return {
        ...state,
        openRight: !state.openRight,
      };
    case HANDLE_BOTTOM:
      if (state.openBottom === false) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
      return {
        ...state,
        openBottom: !state.openBottom,
      };
    case HANDLE_BOTTOM_ADD_FOLDER:
      if (state.openAddFolder === false) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
      return {
        ...state,
        openAddFolder: !state.openAddFolder,
      };

    case LOGOUT_USER:
      return {
        ...state,
        openRight: false,
        openLeft: false,
      };
    default:
      return state;
  }
};

export default MenuReducer;
