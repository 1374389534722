import {
  Button,
  Collapse,
  DatePicker,
  Drawer,
  Input,
  List,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Steps,
  Tag,
  Tooltip,
} from "antd";
import {DownloadOutlined, LoadingOutlined} from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import axiosInstance from "../../config/axios-instance";
import proto_illustration from "../../img/Prototype_2.png";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import React, { useEffect, useState } from "react";
import backArrow from "../../img/backArrow.png";
import { history } from "../..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import Avatar from "antd/lib/avatar/avatar";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  DatabaseOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import moment from "moment";
import { backgroundImage } from "html2canvas/dist/types/css/property-descriptors/background-image";
import { CancelOrderDrawer } from "../MyOrderConfirmation/CancelOrderDrawer";
import { MyNotif } from "../NotifIcons/NotifIcons";
import {
  approveOptionAndComment,
  approveProductAndComment,
  rejectOrAcceptProtoDemcoProduct,
} from "../../actions/order-actions/actions";
import { getSingleDemcoOrder } from "../../actions/my-orders/services";
import OrderServices from "../../actions/order-actions/services";
const { Step } = Steps;
const { Option } = Select;

const NormalOrderProgress = () => {
  const params = useParams<any>();
  const routerHistory = useHistory();

  const { order_id, product_id } = useParams<{
    order_id: string;
    product_id: string;
  }>();

  const {
    data: productdemco,
    isLoading: loadingProduct,
    isError,
    refetch,
  } = useQuery(
    `product-${product_id}`,
    () => getSingleDemcoOrder({ order_id, product_id }),
    {
      retry: false,
    }
  );

  const { data: order, isLoading: loadingOrder } = useQuery(
    `order-${order_id}`,
    () => OrderServices.getOneNormalOrder(+order_id)
  );

  const [product, setProduct] = useState<any>({ data: null, product: null });
  console.log(productdemco);
  const [isLoading, setIsLoading] = useState(false);

  const [isOpened, setIsOpened] = useState(false);
  function edit() {
    setIsOpened((wasOpened) => !wasOpened);
  }
  const [comment, setComment] = useState("");
  const [proto, setProto] = useState("");
  const [startDate, setStartDate] = useState("");
  const [shippingDate, setShippingDate] = useState("");
  const [datetirmswearhouse, setTirmswearhouse] = useState("");
  const [datefabricwearhouse, setFabricwearhouse] = useState("");
  const [step, setSetp] = useState(product?.product?.status?.id);
  const [selectdStep, setSelectdStep] = useState("");
  const [qty, setQty] = useState("");
  const [size_qty, setSizeQty] = useState("");
  const [deliverydate, setDeliveryDate] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [saveDisable, setSaveChangeDisable] = useState(false);
  const [confirmstep, setConfirmStepDisable] = useState(false);

  const [inputs, setInputs] = useState(["", "", ""]);
  const [objectStep, setObjectStep] = useState<any>("");
  const [matrix, setmatrix] = useState([]);

  const [open2, setOpen2] = useState(false);
  const [isEditableFabric, setIsEditableFabric] = useState(false);
  const [detailsFabric, setDetailsFabric] = useState("");
  const [isEditableStyle, setIsEditableStyle] = useState(false);
  const [detailsStyle, setDetailsStyle] = useState("");

  const toggleEditFabric = () => {
    setIsEditableFabric((prevIsEditable) => !prevIsEditable);
  };

  const setDetaisFabric = (e: any) => {
    setDetailsFabric(e.target.value);
  };

  const toggleEditStyle = () => {
    setIsEditableStyle((prevIsEditable) => !prevIsEditable);
  };

  const setDetaisStyle = (e: any) => {
    setDetailsStyle(e.target.value);
  };
  const showDrawer2 = () => {
    setOpen2(true);
  };
  const handleCancel = () => {
    setOpen2(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  function handleChangeProto(e: any) {
    setProto(e.target.value);
  }

  function handleChangeStartDate(e: any) {
    setStartDate(e.target.value);
  }
  function handleChangeShippingDate(e: any) {
    setShippingDate(e.target.value);
  }

  function handleChangeDateTirmswearhouse(e: any) {
    setTirmswearhouse(e.target.value);
  }

  function handleChangeDateFabricwearhouse(e: any) {
    setFabricwearhouse(e.target.value);
  }
  function handleChangeDeliveryDate(e: any) {
    setDeliveryDate(e.target.value);
  }
  const showCancelDrawer = () => {
    setIsVisible(true);
  };
  const {
    mutateAsync: approveOptAndComment,
    isLoading: approvingAndCommentLoading,
  } = useMutation(approveProductAndComment);

  const { mutateAsync: approveOpt } = useMutation(approveProductAndComment);

  const {
    mutateAsync: rejectOrAcceptProto,
    isLoading: rejectOrAcceptProtoLoading,
  } = useMutation(rejectOrAcceptProtoDemcoProduct, {
    onSuccess: (res) => {
      MyNotif(`Product ${res}ed and comments added successfully`, "success");
      window.location.reload();
    },
  });
  const valider = async (status: any, product_id: any) => {
    let token = localStorage.getItem("token");
    setSaveChangeDisable(true);

    await axiosInstance({
      method: "put",
      url: `/orders_produts/admin/${params.order_id}`,
      data: {
        status_id: status,
        order_id: params.order_id,
        product_id: product_id,
        proto: proto,
        start_production_date: startDate,
        shipping_date: shippingDate,
        qty: qty,
        size_qty: inputs,
        delivery_date: deliverydate,
        date_trims_wearhouse: datetirmswearhouse,
        date_fabric_wearhouse: datefabricwearhouse,
        comment: comment,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(function (response) {
      MyNotif(` Successfully Saved `, "success");
      setComment("");
      setSaveChangeDisable(false);

      //@ts-ignore

      refetch();
    });
    window.location.reload();
    return "ok";
  };

  const confirmStep = async (id: any) => {
    let token = localStorage.getItem("token");
    setConfirmStepDisable(true);

    await axiosInstance({
      method: "put",
      url: `/orders_produts/confirmstep/${id}`,
      data: {
        confirm: 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(function (response) {
      MyNotif(`Confirmed `, "success");
      setConfirmStepDisable(false);

      //@ts-ignore

      refetch();
    });
    // window.location.reload();
    return "ok";
  };
  const next = async (status: any, product_id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/orders_produts/next/${params.order_id}`,
      data: {
        status_id: status,
        product_id: product_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
    return "ok";
  };
  const notApprovedOption = async (feedback: string = "") => {
    console.log(`Article ${product?.product.name} is not approved!!`);
    var order_id = product?.data?.id;
    var product_id = product?.product?.id;
    if (!feedback) {
      await approveOptAndComment({
        order_id,
        product_id,
        // comment: `Not Approved: ${comment}`,
        status_id: 6,
      });
    } else {
      await approveOptAndComment({
        order_id,
        product_id,
        // comment: `Not Approved: ${comment}`,
        status_id: 6,
        // feedback,
      });
    }
    await rejectOrAcceptProto({
      response: "reject",
      id: +product_id,
      order_id,
      comment: `Not Approved: ${comment}`,
    });

    setIsVisible(false);
    setComment("");
    setTimeout(() => {}, 2000);
  };

  const approve = async (id: any, status_id: any, product_id: any) => {
    let token = localStorage.getItem("token");

    await axiosInstance({
      method: "put",
      url: `/orders_produts/admin/${params.order_id}`,
      data: {
        status_id: status_id,
        order_id: params.order_id,
        product_id: product_id,
        proto: proto,
        start_production_date: startDate,
        shipping_date: shippingDate,
        qty: qty,
        size_qty: inputs,
        delivery_date: deliverydate,
        date_trims_wearhouse: datetirmswearhouse,
        date_fabric_wearhouse: datefabricwearhouse,
        comment: comment,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(function (response) {
      refetch();
    });

    await axiosInstance({
      method: "post",
      url: `/orders_produts/addCommentApprove/${params.order_id}`,
      data: {
        client_id: id,
        status_id: status_id,
        product_id: product_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(function (response) {
      MyNotif(`Successfully Approved `, "success");
    });
    /*  if (status_id !== 5) {
      const { data: reponse2 } = await axiosInstance({
        method: "put",
        url: `/orders_produts/next/${params.order_id}`,
        data: {
          status_id: status_id + 1,
          product_id: product_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } */
    window.location.reload();
    return "ok";
  };
  const fetchOrder = () => {
    let token = localStorage.getItem("token");
    setIsLoading(true);
    return axiosInstance({
      method: "get",
      url: `/orders/${params.order_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setIsLoading(false);
      setProduct({
        data: res.data,
        product: res.data.products.find(
          (el: any) => el.id == params.product_id
        ),
      });
    });
  };
  const { confirm } = Modal;
  const onChange = (value: any) => {
    console.log(`selected ${value}`);
    setSelectdStep(value);
    showConfirm(value);
  };

  function showConfirm(value: any) {
    confirm({
      title: "You are moving to the selected step, Proceed... ?",
      onOk() {
        approve(
          product?.product?.client?.id,
          product?.product?.status?.id,
          product?.product?.id
        );
        // next(value, product?.product?.id);
      },
      onCancel() {
        console.log("Cancel");
        setSelectdStep("1");
      },
    });
  }

  const onSearch = (value: any) => {
    console.log("search:", value);
  };
  const { data } = useQuery("single_order", () => fetchOrder(), {
    keepPreviousData: true,
  });

  // console.log(product?.product.status.id);

  useEffect(() => {
    if (productdemco) {
      let object = productdemco.data.orders_status.find((o: any) => {
        return o.status_id == productdemco.data.status?.id;
      });
      setObjectStep(object);
      const initialInputs = [];
      if (object?.size_qty) {
        var array = JSON.parse(object?.size_qty);
        if (array?.length > 0) {
          for (let i = 0; i < array?.length; i += 3) {
            initialInputs.push(...array?.slice(i, i + 3));
          }
          setInputs(initialInputs);
        }
      }
    }
    console.log(productdemco?.data.status?.id);
    showMatrix(productdemco?.data.status?.id);

    setSetp(productdemco?.data.status?.id);
  }, [product]);

  const handleAddInput = () => {
    const newInputs = [...inputs, "", "", ""];
    setInputs(newInputs);
  };

  const handleInputChange = (event: any, index: any) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
  };

  const handleRemoveInput = (index: any) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 3);
    setInputs(newInputs);
  };

  const showMatrix = (name: any) => {
    // console.log(inputs);
    let found = false;

    productdemco?.data.orders_status.map((el: any) => {
      // Calculate the number of rows
      console.log(name);

      if (el.size_qty && el.status_id == name) {
        const length = JSON.parse(el.size_qty).length;
        const rows = Math.ceil(length / 3);

        // Create a new matrix from the input values
        const newMatrix = [];
        let count = 0;

        for (let i = 0; i < rows; i++) {
          const row = [];
          for (let j = 0; j < 3; j++) {
            row.push(JSON.parse(el.size_qty)[count] || "");
            count++;
          }
          newMatrix.push(row);
        }

        // Update the matrix state
        //@ts-ignore
        setmatrix(newMatrix);
        found = true;
      }
      if (!found) {
        // Reset matrix state
        setmatrix([]);
      }
    });
  };

  const nextProto = async (id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/orders_produts/next/${order_id}`,
      data: {
        status_id: id,
        product_id: product_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();

    return "ok";
  };

  const nextStep = async (id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/orders_produts/next/${order_id}`,
      data: {
        status_id: id + 1,
        product_id: product_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
    return "ok";
  };

  const saveDetails = async (status_id: any) => {
    let token = localStorage.getItem("token");

    console.log(status_id);

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/orders/savedetailsfabric/${order_id}`,
      data: {
        product_id: product_id,
        status_id: status_id,
        fabric_details: detailsFabric,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    refetch();
    return "ok";
  };

  const saveDetailsStyle = async (status_id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/orders/savedetailsstyle/${order_id}`,
      data: {
        product_id: product_id,
        status_id: status_id,
        style_details: detailsStyle,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    refetch();
    return "ok";
  };
  const stepName = ["PROTO", "PRE SMS", "SMS", "OK PROD", "PRODUCTION"];

  return isLoading ? (
    <div
      style={{ height: "50vh", width: "50vw", margin: "0 auto" }}
      className="d-flex justify-content-center align-items-center">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <div className="row myorder_confirmation" style={{ minHeight: "100vh" }}>
      <div
          className="col-12 col-md-5 p-0  align-items-center"
          style={{background: "#fcfdff", height: "100%"}}>
        {/* <img
            src={
              product.product.proto
                ? process.env.REACT_APP_STORAGE_URL + product.product.proto
                : proto_illustration
            }
            alt="prototype"
            className="proto_img img-fluid"
            style={{
              objectFit:
                product.product && product.product.proto ? "contain" : "cover",
            }}
          /> */}
        {/*        <iframe
          src={
            product?.product.proto
              ? process?.env?.REACT_APP_STORAGE_URL + product?.product.proto
              : proto_illustration
          }
          style={{
            width: "100%",
            height: "calc(100vh - 227px)",
            border: "none",
          }}
        /> */}


        <iframe
            src={
              product?.product?.proto
                  ? product?.product?.proto
                  : product?.product?.link_360
            }
            style={{
              width: "100%",
              height: "calc(100vh - 227px)",
              border: "none",
            }}
            id="iframe"
        />

        {/*  <div
          className="row "
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button onClick={showDrawer} className="view-details">
            View Tech Pack
          </button>{" "}
        </div> */}

        {/*    <div
          className="row "
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {" "}
          <a
            href={
              process.env.REACT_APP_API_URL +
              `/generate-pdf/demco-order/${params.order_id}/${params.product_id}`
            }
            style={{ color: "white" }}>
            <button className="exportPDF">Export tech pack</button>{" "}
          </a>{" "}
        </div>*/}

        <div
            className="row mt-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
          {" "}

          <a
              href={
                  process.env.REACT_APP_API_URL +
                  `/generate-pdf/demco-order/${params.order_id}/${params.product_id}`
              }
              className="exportPDF2">
            Export tech pack <DownloadOutlined style={{fontSize: "16px"}}/>
            {" "}
          </a>
        </div>
      </div>
      <div className="col-12 col-md-7 proto_container pl-3">
        <div
            className=" m-4"
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}>
          <div className="proto_header">
            <img
                src={backArrow}
                className="back_arrow_folder"
                alt="back arrow"
                onClick={() => history?.back()}
            />
            <h1>My orders</h1>
          </div>
          <div>
            {product?.product && (
                <div>
                <Tooltip
                  placement="bottom"
                  title={isOpened == false ? "Update" : "View"}>
                  <Button
                    type="ghost"
                    shape="circle"
                    size="large"
                    className="update_product_btn"
                    icon={
                      isOpened == false ? (
                        <EditOutlined />
                      ) : (
                        <EyeOutlined></EyeOutlined>
                      )
                    }
                    onClick={edit}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div className=" proto_breadcrumb_container my-4">
          <div className="d-flex justify-content-between">
            <div className="col-9">
              {product?.data && (
                <p className="proto_breadcrumb">{`${
                  product?.data?.client?.name
                }/${product?.product?.name || ""}`}</p>
              )}{" "}
            </div>
            {product?.product?.orders_status.map((el: any) => {
              return (
                <>
                  {el.proto_confirmation === "ACCEPTED" &&
                    el.status_id == product?.product?.status?.id &&
                    el.id ==
                      product?.product?.orders_status[
                        product?.product?.orders_status.length - 1
                      ].id && (
                      <div className="col-2">
                        <Tag
                          icon={<CheckOutlined />}
                          style={{
                            borderRadius: "15px",
                            padding: "5px 9px 5px 9px",
                          }}
                          color="#A5AB36">
                          Approved
                        </Tag>
                      </div>
                    )}
                </>
              );
            })}
          </div>
        </div>

        <div className="steps_progress mt-4">
          <Steps
            current={product?.product?.status?.id - 1}
            labelPlacement={"vertical"}
            className={
              product?.product === undefined
                ? "d-flex justify-content-center cancelled"
                : ""
            }>
            {product?.product ? (
              <>
                <Step
                  title="PROTO"
                  onClick={() => {
                    setSetp(1);
                  }}
                  style={{
                    cursor:
                      product?.product?.status?.id >= 1 && isOpened == false
                        ? "pointer"
                        : "",
                  }}
                  description={
                    product?.product?.status?.id >= 1 && isOpened == false
                      ? "View History"
                      : ""
                  }
                />
                <Step
                  title="PRE SMS"
                  onClick={() => {
                    setSetp(2);
                    showMatrix(2);
                  }}
                  style={{
                    cursor:
                      product?.product?.status?.id >= 2 && isOpened == false
                        ? "pointer"
                        : "",
                  }}
                  description={
                    product?.product?.status?.id >= 2 && isOpened == false
                      ? "View History"
                      : ""
                  }
                />
                <Step
                  title="SMS"
                  onClick={() => {
                    setSetp(3);
                    showMatrix(3);
                  }}
                  style={{
                    cursor:
                      product?.product?.status?.id >= 3 && isOpened == false
                        ? "pointer"
                        : "",
                  }}
                  description={
                    product?.product?.status?.id >= 3 && isOpened == false
                      ? "View History"
                      : ""
                  }
                />
                <Step
                  title="OK PROD"
                  onClick={() => {
                    setSetp(4);
                    showMatrix(4);
                  }}
                  style={{
                    cursor:
                      product?.product?.status?.id >= 4 && isOpened == false
                        ? "pointer"
                        : "",
                  }}
                  description={
                    product?.product?.status?.id >= 4 && isOpened == false
                      ? "View History"
                      : ""
                  }
                />
                <Step
                  title="PRODUCTION"
                  onClick={() => {
                    setSetp(5);
                    showMatrix(5);
                  }}
                  style={{
                    cursor:
                      product?.product?.status?.id >= 5 && isOpened == false
                        ? "pointer"
                        : "",
                  }}
                  description={
                    product?.product?.status?.id >= 5 && isOpened == false
                      ? "View History"
                      : ""
                  }
                />
              </>
            ) : (
              <>
                <Step title="CANCELLED" />
              </>
            )}
          </Steps>
        </div>
        {!product?.product && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <h4
              className="text-center"
              style={{ color: "red", fontWeight: 400 }}>
              This product has been cancelled
            </h4>
            <div className="proto_footer_btns my-4">
              <Button
                type="default"
                className="approve_btn"
                onClick={() =>
                  routerHistory.push(`/all-clients/${params.id}/2`)
                }>
                Go back to orders
              </Button>
            </div>
          </div>
        )}

        {isOpened == false ? (
          <>
            {" "}
            <div className="proto_footer mt-5">
              <div className="mt-4">
                <h1 className="my-3">
                  {product?.product && product?.product?.name} {""}
                  {product?.product && product?.product?.code}
                </h1>
                {/*                 <p>{product?.product && product?.product?.description}</p>
                 */}{" "}
                {step != productdemco?.data.status.id && (
                  <>
                    <div className="proto_footer mt-5">
                      {productdemco?.data.orders_status.map((el: any) => {
                        return (
                          <>
                            {el.status_id == step && el.status_id != 1 ? (
                              <>
                                <table
                                  style={{
                                    width: "100%",
                                    marginBottom: "25px",
                                  }}
                                  className={"table-progress"}
                                >
                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        Fabric{" "}
                                      </span>{" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <div
                                        style={{
                                          justifyContent: "space-between",
                                          display: "flex",
                                        }}>
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                          }}>
                                          {" "}
                                          Details{" "}
                                        </span>
                                        <button
                                          disabled={isEditableFabric}
                                          className={
                                            isEditableFabric
                                              ? " add_details_disabled"
                                              : "add_details"
                                          }
                                          onClick={toggleEditFabric}>
                                          <PlusOutlined></PlusOutlined>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span>
                                        {productdemco?.data?.fabrics.map(
                                          (el: any) => {
                                            return (
                                              <>
                                                <ul id="myUL2">
                                                  <li>{el.name}</li>
                                                </ul>
                                              </>
                                            );
                                          }
                                        )}
                                      </span>{" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      {isEditableFabric ? (
                                        <>
                                          <div>
                                            <textarea
                                              style={{ width: "100%" }}
                                              rows={3}
                                              className="textarea_content"
                                              defaultValue={
                                                productdemco?.data.orders_status.find(
                                                  (o: any) =>
                                                    o.status_id === step
                                                ).details_fabric
                                              }
                                              onChange={(e) =>
                                                setDetaisFabric(e)
                                              }
                                            />
                                            <div
                                              className="row"
                                              style={{
                                                float: "right",
                                              }}>
                                              <button
                                                onClick={() => {
                                                  setIsEditableFabric(false);
                                                }}
                                                className="check_details"
                                                style={{
                                                  marginRight: "10px",
                                                }}>
                                                <CloseOutlined
                                                  style={{
                                                    color: "#f37b7b",
                                                  }}></CloseOutlined>
                                              </button>
                                              <button
                                                onClick={() => {
                                                  saveDetails(el.status_id);
                                                  setIsEditableFabric(false);
                                                }}
                                                className="check_details">
                                                <CheckOutlined
                                                  style={{
                                                    color: "#00BA6C",
                                                  }}></CheckOutlined>
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            style={{
                                              whiteSpace: "pre-line",
                                            }}>
                                            {productdemco?.data.orders_status.find(
                                              (o: any) => o.status_id === step
                                            ).details_fabric ?? "-"}
                                          </div>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        Style{" "}
                                      </span>{" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <div
                                        style={{
                                          justifyContent: "space-between",
                                          display: "flex",
                                        }}>
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                          }}>
                                          {" "}
                                          Details{" "}
                                        </span>
                                        <button
                                          disabled={isEditableStyle}
                                          className={
                                            isEditableStyle
                                              ? " add_details_disabled"
                                              : "add_details"
                                          }
                                          onClick={toggleEditStyle}>
                                          <PlusOutlined></PlusOutlined>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span> {productdemco?.data?.name} </span>{" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      {isEditableStyle ? (
                                        <>
                                          <div>
                                            <textarea
                                              style={{ width: "100%" }}
                                              rows={3}
                                              className="textarea_content"
                                              defaultValue={
                                                productdemco?.data.orders_status.find(
                                                  (o: any) =>
                                                    o.status_id === step
                                                ).details_style
                                              }
                                              onChange={(e) =>
                                                setDetaisStyle(e)
                                              }
                                            />
                                            <div
                                              className="row"
                                              style={{
                                                float: "right",
                                              }}>
                                              <button
                                                onClick={() => {
                                                  setIsEditableStyle(false);
                                                }}
                                                className="check_details"
                                                style={{
                                                  marginRight: "10px",
                                                }}>
                                                <CloseOutlined
                                                  style={{
                                                    color: "#f37b7b",
                                                  }}></CloseOutlined>
                                              </button>
                                              <button
                                                onClick={() => {
                                                  saveDetailsStyle(
                                                    el.status_id
                                                  );
                                                  setIsEditableStyle(false);
                                                }}
                                                className="check_details">
                                                <CheckOutlined
                                                  style={{
                                                    color: "#00BA6C",
                                                  }}></CheckOutlined>
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            style={{
                                              whiteSpace: "pre-line",
                                            }}>
                                            {productdemco?.data.orders_status.find(
                                              (o: any) => o.status_id === step
                                            ).details_style ?? "-"}
                                          </div>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </table>

                                <div
                                  style={{
                                    marginBottom: "16px",
                                    marginTop: "16px",
                                    color: "#00468C",
                                    marginRight: "9px",
                                    fontWeight: "bold",
                                  }}>
                                  {(() => {
                                    switch (el.status_id) {
                                      case 1:
                                        return <span>Size : PROTO</span>;
                                        break;
                                      case 2:
                                        return <span>Size : PRE SMS</span>;
                                        break;
                                      case 3:
                                        return <span>Size : SMS</span>;
                                        break;
                                      case 4:
                                        return <span> Size : OK PROD</span>;
                                        break;
                                    }
                                  })()}
                                </div>
                                {el.status_id != 5 ? (
                                  <div style={{ marginBottom: "16px" }}>
                                    {" "}
                                    {matrix.length > 0 ? (
                                      <div>
                                        <table style={{ width: "100%" }}  className={"table-progress"}>
                                          <th
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            Size
                                          </th>
                                          <th
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            Length
                                          </th>
                                          <th
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            Qty
                                          </th>
                                          <tbody>
                                            {matrix.map(
                                              (row: any, rowIndex: any) => (
                                                <tr
                                                  key={rowIndex}
                                                  style={{
                                                    border: "1px solid #CCCCCC",
                                                    padding: "9px",
                                                  }}>
                                                  {row.map(
                                                    (
                                                      cell: any,
                                                      cellIndex: any
                                                    ) => (
                                                      <td
                                                        key={cellIndex}
                                                        style={{
                                                          border:
                                                            "1px solid #CCCCCC",
                                                          padding: "9px",
                                                        }}>
                                                        {cell}
                                                      </td>
                                                    )
                                                  )}
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    ) : (
                                      <div className="p-3">-</div>
                                    )}
                                  </div>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "16px",
                                      }}>
                                      <div
                                        style={{
                                          color: "#00468C",
                                          marginRight: "9px",
                                          fontWeight: "bold",
                                        }}>
                                        Quantity Production:
                                      </div>
                                      <div>{el.qty}</div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "16px",
                                      }}>
                                      <div
                                        style={{
                                          color: "#00468C",
                                          marginRight: "9px",
                                          fontWeight: "bold",
                                        }}>
                                        Actual Delivery Date:
                                      </div>
                                      <div>{el.shipping_date}</div>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}

                      {step == 1 ? (
                        <>
                          <table
                            style={{
                              width: "100%",
                              marginBottom: "25px",
                            }}
                            className={"table-progress"}
                          >
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Fabric{" "}
                                </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <div
                                  style={{
                                    justifyContent: "space-between",
                                    display: "flex",
                                  }}>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}>
                                    {" "}
                                    Details{" "}
                                  </span>
                                  <button
                                    disabled={isEditableFabric}
                                    className={
                                      isEditableFabric
                                        ? " add_details_disabled"
                                        : "add_details"
                                    }
                                    onClick={toggleEditFabric}>
                                    <PlusOutlined></PlusOutlined>
                                  </button>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span>
                                  {productdemco?.data?.fabrics.map(
                                    (el: any) => {
                                      return (
                                        <>
                                          <ul id="myUL2">
                                            <li>{el.name}</li>
                                          </ul>
                                        </>
                                      );
                                    }
                                  )}
                                </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                {isEditableFabric ? (
                                  <>
                                    <div>
                                      <textarea
                                        style={{ width: "100%" }}
                                        rows={3}
                                        className="textarea_content"
                                        defaultValue={
                                          productdemco?.data.orders_status.find(
                                            (o: any) => o.status_id === step
                                          ).details_fabric
                                        }
                                        onChange={(e) => setDetaisFabric(e)}
                                      />
                                      <div
                                        className="row"
                                        style={{
                                          float: "right",
                                        }}>
                                        <button
                                          onClick={() => {
                                            setIsEditableFabric(false);
                                          }}
                                          className="check_details"
                                          style={{
                                            marginRight: "10px",
                                          }}>
                                          <CloseOutlined
                                            style={{
                                              color: "#f37b7b",
                                            }}></CloseOutlined>
                                        </button>
                                        <button
                                          onClick={() => {
                                            saveDetails(1);
                                            setIsEditableFabric(false);
                                          }}
                                          className="check_details">
                                          <CheckOutlined
                                            style={{
                                              color: "#00BA6C",
                                            }}></CheckOutlined>
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        whiteSpace: "pre-line",
                                      }}>
                                      {productdemco?.data.orders_status.find(
                                        (o: any) => o.status_id === step
                                      ).details_fabric ?? "-"}
                                    </div>
                                  </>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Style{" "}
                                </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <div
                                  style={{
                                    justifyContent: "space-between",
                                    display: "flex",
                                  }}>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}>
                                    {" "}
                                    Details{" "}
                                  </span>
                                  <button
                                    disabled={isEditableStyle}
                                    className={
                                      isEditableStyle
                                        ? " add_details_disabled"
                                        : "add_details"
                                    }
                                    onClick={toggleEditStyle}>
                                    <PlusOutlined></PlusOutlined>
                                  </button>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span> {productdemco?.data?.name} </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                {isEditableStyle ? (
                                  <>
                                    <div>
                                      <textarea
                                        style={{ width: "100%" }}
                                        rows={3}
                                        className="textarea_content"
                                        defaultValue={
                                          productdemco?.data.orders_status.find(
                                            (o: any) => o.status_id === step
                                          ).details_style
                                        }
                                        onChange={(e) => setDetaisStyle(e)}
                                      />
                                      <div
                                        className="row"
                                        style={{
                                          float: "right",
                                        }}>
                                        <button
                                          onClick={() => {
                                            setIsEditableStyle(false);
                                          }}
                                          className="check_details"
                                          style={{
                                            marginRight: "10px",
                                          }}>
                                          <CloseOutlined
                                            style={{
                                              color: "#f37b7b",
                                            }}></CloseOutlined>
                                        </button>
                                        <button
                                          onClick={() => {
                                            saveDetailsStyle(1);
                                            setIsEditableStyle(false);
                                          }}
                                          className="check_details">
                                          <CheckOutlined
                                            style={{
                                              color: "#00BA6C",
                                            }}></CheckOutlined>
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        whiteSpace: "pre-line",
                                      }}>
                                      {productdemco?.data.orders_status.find(
                                        (o: any) => o.status_id === step
                                      ).details_style ?? "-"}
                                    </div>
                                  </>
                                )}
                              </td>
                            </tr>
                          </table>

                          <div
                            style={{
                              display: "flex",
                              marginBottom: "16px",
                              marginTop: "16px",
                            }}>
                            <div
                              style={{
                                color: "#00468C",
                                marginRight: "9px",
                                fontWeight: "bold",
                              }}>
                              Quantity Proto:
                            </div>
                            <div>1</div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {productdemco?.data.orders_status.map((el: any) => {
                        {
                          return (
                            <>
                              {el.status_id == step && el.status_id !== 5 ? (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "16px",
                                    }}>
                                    <div
                                      style={{
                                        color: "#00468C",
                                        marginRight: "9px",
                                        fontWeight: "bold",
                                      }}>
                                      Delivery date :
                                    </div>
                                    <div>
                                      {productdemco &&
                                      product?.data?.delivery_date
                                        ? moment(
                                            product?.data?.delivery_date
                                          ).format("DD-MM-YYYY")
                                        : "-"}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        }
                      })}
                      {productdemco?.data &&
                        productdemco?.data.allComment.length > 0 && (
                          <>
                            <div
                              style={{
                                color: "#00468C",
                                marginRight: "9px",
                                fontWeight: "bold",
                                marginBottom: "16px",
                              }}>
                              Comments
                            </div>
                            {productdemco?.data.allComment.map((el: any) => {
                              return (
                                <>
                                  {console.log(el)}
                                  {el.status_id == step ? (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "16px",
                                        }}>
                                        {el.client_id ==
                                          product?.data.client.id &&
                                        el.commercial_id == null ? (
                                          <>
                                            <Avatar
                                              src={
                                                process.env
                                                  .REACT_APP_STORAGE_URL +
                                                product?.data.client.logo
                                              }
                                              size="default"
                                              style={{
                                                marginRight: "5px",
                                              }}
                                            />{" "}
                                            <div
                                              style={{
                                                backgroundColor: "#F8FAFC",
                                                borderRadius: "5px",
                                                border: "1px solid #CDD5DF",
                                                width: "100%",
                                                padding: "16px",
                                              }}>
                                              <div
                                                className="row"
                                                style={{
                                                  justifyContent:
                                                    "space-between",
                                                }}>
                                                <div
                                                  style={{
                                                    lineHeight: "24px",
                                                    fontWeight: "bold",
                                                  }}>
                                                  {product?.data.client.name}
                                                </div>
                                                <div>
                                                  <p>
                                                    {moment(
                                                      el.created_at
                                                    ).format(
                                                      "DD-MM-YYYY HH:mm"
                                                    )}{" "}
                                                  </p>
                                                </div>
                                              </div>
                                              <div>{el.comment}</div>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <Avatar
                                              src={
                                                process.env
                                                  .REACT_APP_STORAGE_URL +
                                                el.commercial.avatar
                                              }
                                              size="default"
                                              style={{
                                                marginRight: "5px",
                                              }}
                                            />{" "}
                                            <div
                                              style={{
                                                backgroundColor: "#F8FAFC",
                                                borderRadius: "5px",
                                                border: "1px solid #CDD5DF",
                                                width: "100%",
                                                padding: "16px",
                                              }}>
                                              <div
                                                className="row"
                                                style={{
                                                  justifyContent:
                                                    "space-between",
                                                }}>
                                                <div
                                                  style={{
                                                    lineHeight: "24px",
                                                    fontWeight: "bold",
                                                  }}>
                                                  {el?.commercial?.name}
                                                </div>
                                                <div>
                                                  <p>
                                                    {moment(
                                                      el.created_at
                                                    ).format(
                                                      "DD-MM-YYYY HH:mm"
                                                    )}{" "}
                                                  </p>
                                                </div>
                                              </div>
                                              <div>{el.comment}</div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })}
                          </>
                        )}
                    </div>
                  </>
                )}
                {step == productdemco?.data.status.id &&
                  objectStep &&
                  objectStep?.proto_confirmation !== "ACCEPTED" && (
                    <>
                      <div className="proto_footer mt-5">
                        {productdemco?.data.orders_status.map((el: any) => {
                          return (
                            <>
                              {el.status_id == step && el.status_id != 1 ? (
                                <>
                                  {el.status_id != 5 && (
                                    <table
                                      style={{
                                        width: "100%",
                                        marginBottom: "25px",
                                      }}
                                      className={"table-progress"}
                                    >
                                      <tr>
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                            width: "50%",
                                          }}>
                                          <span style={{ fontWeight: "bold" }}>
                                            Fabric{" "}
                                          </span>{" "}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                            width: "50%",
                                          }}>
                                          <div
                                            style={{
                                              justifyContent: "space-between",
                                              display: "flex",
                                            }}>
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                              }}>
                                              {" "}
                                              Details{" "}
                                            </span>
                                            <button
                                              disabled={isEditableFabric}
                                              className={
                                                isEditableFabric
                                                  ? " add_details_disabled"
                                                  : "add_details"
                                              }
                                              onClick={toggleEditFabric}>
                                              <PlusOutlined></PlusOutlined>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                            width: "50%",
                                          }}>
                                          <span>
                                            {productdemco?.data?.fabrics.map(
                                              (el: any) => {
                                                return (
                                                  <>
                                                    <ul id="myUL2">
                                                      <li>{el.name}</li>
                                                    </ul>
                                                  </>
                                                );
                                              }
                                            )}
                                          </span>{" "}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                            width: "50%",
                                          }}>
                                          {isEditableFabric ? (
                                            <>
                                              <div>
                                                <textarea
                                                  style={{ width: "100%" }}
                                                  rows={3}
                                                  className="textarea_content"
                                                  defaultValue={
                                                    productdemco?.data.orders_status.find(
                                                      (o: any) =>
                                                        o.status_id === step
                                                    ).details_fabric
                                                  }
                                                  onChange={(e) =>
                                                    setDetaisFabric(e)
                                                  }
                                                />
                                                <div
                                                  className="row"
                                                  style={{
                                                    float: "right",
                                                  }}>
                                                  <button
                                                    onClick={() => {
                                                      setIsEditableFabric(
                                                        false
                                                      );
                                                    }}
                                                    className="check_details"
                                                    style={{
                                                      marginRight: "10px",
                                                    }}>
                                                    <CloseOutlined
                                                      style={{
                                                        color: "#f37b7b",
                                                      }}></CloseOutlined>
                                                  </button>
                                                  <button
                                                    onClick={() => {
                                                      saveDetails(el.status_id);
                                                      setIsEditableFabric(
                                                        false
                                                      );
                                                    }}
                                                    className="check_details">
                                                    <CheckOutlined
                                                      style={{
                                                        color: "#00BA6C",
                                                      }}></CheckOutlined>
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                }}>
                                                {productdemco?.data.orders_status.find(
                                                  (o: any) =>
                                                    o.status_id === step
                                                ).details_fabric ?? "-"}
                                              </div>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                            width: "50%",
                                          }}>
                                          <span style={{ fontWeight: "bold" }}>
                                            Style{" "}
                                          </span>{" "}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                            width: "50%",
                                          }}>
                                          <div
                                            style={{
                                              justifyContent: "space-between",
                                              display: "flex",
                                            }}>
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                              }}>
                                              {" "}
                                              Details{" "}
                                            </span>
                                            <button
                                              disabled={isEditableStyle}
                                              className={
                                                isEditableStyle
                                                  ? " add_details_disabled"
                                                  : "add_details"
                                              }
                                              onClick={toggleEditStyle}>
                                              <PlusOutlined></PlusOutlined>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                            width: "50%",
                                          }}>
                                          <span>
                                            {" "}
                                            {productdemco?.data?.name}{" "}
                                          </span>{" "}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                            width: "50%",
                                          }}>
                                          {isEditableStyle ? (
                                            <>
                                              <div>
                                                <textarea
                                                  style={{ width: "100%" }}
                                                  rows={3}
                                                  className="textarea_content"
                                                  defaultValue={
                                                    productdemco?.data.orders_status.find(
                                                      (o: any) =>
                                                        o.status_id === step
                                                    ).details_style
                                                  }
                                                  onChange={(e) =>
                                                    setDetaisStyle(e)
                                                  }
                                                />
                                                <div
                                                  className="row"
                                                  style={{
                                                    float: "right",
                                                  }}>
                                                  <button
                                                    onClick={() => {
                                                      setIsEditableStyle(false);
                                                    }}
                                                    className="check_details"
                                                    style={{
                                                      marginRight: "10px",
                                                    }}>
                                                    <CloseOutlined
                                                      style={{
                                                        color: "#f37b7b",
                                                      }}></CloseOutlined>
                                                  </button>
                                                  <button
                                                    onClick={() => {
                                                      saveDetailsStyle(
                                                        el.status_id
                                                      );
                                                      setIsEditableStyle(false);
                                                    }}
                                                    className="check_details">
                                                    <CheckOutlined
                                                      style={{
                                                        color: "#00BA6C",
                                                      }}></CheckOutlined>
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                }}>
                                                {productdemco?.data.orders_status.find(
                                                  (o: any) =>
                                                    o.status_id === step
                                                ).details_style ?? "-"}
                                              </div>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    </table>
                                  )}

                                  <div
                                    style={{
                                      marginBottom: "16px",
                                      marginTop: "16px",
                                      color: "#00468C",
                                      marginRight: "9px",
                                      fontWeight: "bold",
                                    }}>
                                    {(() => {
                                      switch (el.status_id) {
                                        case 1:
                                          return <span>Size : PROTO</span>;
                                          break;
                                        case 2:
                                          return <span>Size : PRE SMS</span>;
                                          break;
                                        case 3:
                                          return <span>Size : SMS</span>;
                                          break;
                                        case 4:
                                          return <span> Size : OK PROD</span>;
                                          break;
                                      }
                                    })()}
                                  </div>
                                  {el.status_id != 5 ? (
                                    <div style={{ marginBottom: "16px" }}>
                                      {" "}
                                      {matrix.length > 0 ? (
                                        <div>
                                          <table style={{ width: "100%" }}>
                                            <th
                                              style={{
                                                border: "1px solid #CCCCCC",
                                                padding: "9px",
                                              }}
                                              className={"table-progress"}
                                            >
                                              Length
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid #CCCCCC",
                                                padding: "9px",
                                              }}>
                                              Size
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid #CCCCCC",
                                                padding: "9px",
                                              }}>
                                              Qty
                                            </th>
                                            <tbody>
                                              {matrix.map(
                                                (row: any, rowIndex: any) => (
                                                  <tr
                                                    key={rowIndex}
                                                    style={{
                                                      border:
                                                        "1px solid #CCCCCC",
                                                    }}>
                                                    {row.map(
                                                      (
                                                        cell: any,
                                                        cellIndex: any
                                                      ) => (
                                                        <td
                                                          key={cellIndex}
                                                          style={{
                                                            border:
                                                              "1px solid #CCCCCC",
                                                            padding: "9px",
                                                          }}>
                                                          {cell}
                                                        </td>
                                                      )
                                                    )}
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <div className="p-3">-</div>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      {/*    <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "16px",
                                        }}>
                                        <div
                                          style={{
                                            color: "#00468C",
                                            marginRight: "9px",
                                            fontWeight: "bold",
                                          }}>
                                          Quantity Productionssss:
                                        </div>
                                        <div>{el.qty}</div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "16px",
                                        }}>
                                        <div
                                          style={{
                                            color: "#00468C",
                                            marginRight: "9px",
                                            fontWeight: "bold",
                                          }}>
                                          Actual Delivery Date:
                                        </div>
                                        <div>{el.shipping_date}</div>
                                      </div> */}
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}

                        {step === 5 &&
                          productdemco?.data.orders_status.filter(
                            (el: any) =>
                              el.status_id === 5 &&
                              el.proto_confirmation !== "ACCEPTED"
                          ).length > 0 && (
                            <>
                            
                            <div
                            style={{
                              marginTop: "-50px",
                              marginBottom: "16px",
                            }}>
                            <table
                              style={{ width: "100%", marginBottom: "25px" }}  className={"table-progress"}>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Fabric{" "}
                                  </span>{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Details{" "}
                                  </span>{" "}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {productdemco?.data?.fabrics?.map(
                                    (el: any) => {
                                      return (
                                        <>
                                          <ul id="myUL2">
                                            <li>{el.name}</li>
                                          </ul>
                                        </>
                                      );
                                    }
                                  )}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {productdemco?.data.orders_status
                                    .filter((el: any) => el.status_id !== 5)
                                    .map((el: any) => {
                                      return (
                                        <>
                                          <ul id="myUL2">
                                            <li
                                              style={{
                                                whiteSpace: "pre-line",
                                              }}>
                                              {el.details_fabric ?? "-"}
                                            </li>
                                          </ul>
                                        </>
                                      );
                                    })}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Style{" "}
                                  </span>{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Details{" "}
                                  </span>{" "}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {productdemco?.data?.name}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {productdemco?.data.orders_status
                                    .filter((el: any) => el.status_id !== 5)
                                    .map((el: any) => {
                                      return (
                                        <>
                                          <ul id="myUL2">
                                            <li
                                              style={{
                                                whiteSpace: "pre-line",
                                              }}>
                                              {el.details_style ?? "-"}
                                            </li>
                                          </ul>
                                        </>
                                      );
                                    })}
                                </td>
                              </tr>
                            </table>
                          </div>
                              <table
                                style={{
                                  width: "100%",
                                  marginBottom: "25px",
                                }}
                                className={"table-progress"}
                              >
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                      width: "33%",
                                      fontWeight: "bold",
                                    }}>
                                    Production
                                  </td>{" "}
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                      width: "33%",
                                      fontWeight: "bold",
                                    }}>
                                    Delivery Date
                                  </td>{" "}
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                      width: "33%",
                                      fontWeight: "bold",
                                    }}>
                                    Quantity
                                  </td>{" "}
                                </tr>

                                {productdemco?.data.orders_status
                                  /* .sort((a: any, b: any) =>
                                                  a.sort_order > b.sort_order
                                                    ? 1
                                                    : -1
                                                ) */ .filter(
                                    (el: any) =>
                                      el.status_id === 5 &&
                                      el.proto_confirmation !== "ACCEPTED"
                                  )
                                  .map((el: any, index: number) => {
                                    return (
                                      <>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            {productdemco?.data.orders_status.filter(
                                              (el: any) => el.status_id === 5
                                            ).length - index}
                                          </td>{" "}
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            {moment(el.delivery_date).format(
                                              "DD-MM-YYYY "
                                            )}
                                          </td>{" "}
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            {el.qty}
                                          </td>{" "}
                                        </tr>
                                      </>
                                    );
                                  })}
                              </table>
                            </>
                          )}

                        {step == 1 ? (
                          <>
                            {/*    {productdemco?.data.fabrics.length > 0 && (
                              <div>
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        Style{" "}
                                      </span>{" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        Details{" "}
                                      </span>{" "}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                      }}>
                                      <span>{productdemco?.data.name} </span>{" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                      }}>
                                      <span>
                                        {" "}
                                        {productdemco?.data.description}{" "}
                                      </span>{" "}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        Fabric{" "}
                                      </span>{" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        Details{" "}
                                      </span>{" "}
                                    </td>
                                  </tr>

                                  {productdemco?.data.fabrics.map((el: any) => {
                                    return (
                                      <>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            <span>{el.name} </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            <span> {el.description} </span>{" "}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </table>
                              </div>
                            )} */}

                            <div
                              style={{
                                display: "flex",
                                marginBottom: "16px",
                                marginTop: "16px",
                              }}>
                              <div
                                style={{
                                  color: "#00468C",
                                  marginRight: "9px",
                                  fontWeight: "bold",
                                }}>
                                Quantity Proto:
                              </div>
                              <div>1</div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {productdemco?.data.orders_status.map((el: any) => {
                          {
                            return (
                              <>
                                {el.status_id == step && el.status_id !== 5 ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "16px",
                                      }}>
                                      <div
                                        style={{
                                          color: "#00468C",
                                          marginRight: "9px",
                                          fontWeight: "bold",
                                        }}>
                                        Delivery date :
                                      </div>
                                      <div>
                                        {productdemco &&
                                        product?.data?.delivery_date
                                          ? moment(
                                              product?.data?.delivery_date
                                            ).format("DD-MM-YYYY")
                                          : "-"}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          }
                        })}
                        {productdemco?.data &&
                          productdemco?.data.allComment.length > 0 && (
                            <>
                              <div
                                style={{
                                  color: "#00468C",
                                  marginRight: "9px",
                                  fontWeight: "bold",
                                  marginBottom: "16px",
                                }}>
                                Comments
                              </div>
                              {productdemco?.data.allComment.map((el: any) => {
                                return (
                                  <>
                                    {console.log(el)}
                                    {el.status_id == step ? (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            marginBottom: "16px",
                                          }}>
                                          {el.client_id ==
                                            product?.data.client.id &&
                                          el.commercial_id == null ? (
                                            <>
                                              <Avatar
                                                src={
                                                  process.env
                                                    .REACT_APP_STORAGE_URL +
                                                  product?.data.client.logo
                                                }
                                                size="default"
                                                style={{
                                                  marginRight: "5px",
                                                }}
                                              />{" "}
                                              <div
                                                style={{
                                                  backgroundColor: "#F8FAFC",
                                                  borderRadius: "5px",
                                                  border: "1px solid #CDD5DF",
                                                  width: "100%",
                                                  padding: "16px",
                                                }}>
                                                <div
                                                  className="row"
                                                  style={{
                                                    justifyContent:
                                                      "space-between",
                                                  }}>
                                                  <div
                                                    style={{
                                                      lineHeight: "24px",
                                                      fontWeight: "bold",
                                                    }}>
                                                    {product?.data.client.name}
                                                  </div>
                                                  <div>
                                                    <p>
                                                      {moment(
                                                        el.created_at
                                                      ).format(
                                                        "DD-MM-YYYY HH:mm"
                                                      )}{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div>{el.comment}</div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <Avatar
                                                src={
                                                  process.env
                                                    .REACT_APP_STORAGE_URL +
                                                  el.commercial.avatar
                                                }
                                                size="default"
                                                style={{
                                                  marginRight: "5px",
                                                }}
                                              />{" "}
                                              <div
                                                style={{
                                                  backgroundColor: "#F8FAFC",
                                                  borderRadius: "5px",
                                                  border: "1px solid #CDD5DF",
                                                  width: "100%",
                                                  padding: "16px",
                                                }}>
                                                <div
                                                  className="row"
                                                  style={{
                                                    justifyContent:
                                                      "space-between",
                                                  }}>
                                                  <div
                                                    style={{
                                                      lineHeight: "24px",
                                                      fontWeight: "bold",
                                                    }}>
                                                    {el?.commercial?.name}
                                                  </div>
                                                  <div>
                                                    <p>
                                                      {moment(
                                                        el.created_at
                                                      ).format(
                                                        "DD-MM-YYYY HH:mm"
                                                      )}{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div>{el.comment}</div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          )}
                      </div>
                    </>
                  )}
                {step == productdemco?.data.status.id &&
                  objectStep &&
                  objectStep?.proto_confirmation === "ACCEPTED" && (
                    <>
                      <div className="proto_footer mt-5">
                        {productdemco?.data.orders_status.map((el: any) => {
                          return (
                            <>
                              {el.status_id == step && el.status_id != 1 ? (
                                <>
                                  {productdemco?.data.fabrics.length > 0 && (
                                    <div>
                                      <table style={{ width: "100%" }}  className={"table-progress"}>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span
                                              style={{ fontWeight: "bold" }}>
                                              Style{" "}
                                            </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span
                                              style={{ fontWeight: "bold" }}>
                                              {" "}
                                              Details{" "}
                                            </span>{" "}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            <span>
                                              {productdemco?.data.name}{" "}
                                            </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            <span>
                                              {" "}
                                              {
                                                productdemco?.data.description
                                              }{" "}
                                            </span>{" "}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span
                                              style={{ fontWeight: "bold" }}>
                                              Fabric{" "}
                                            </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span
                                              style={{ fontWeight: "bold" }}>
                                              {" "}
                                              Details{" "}
                                            </span>{" "}
                                          </td>
                                        </tr>

                                        {productdemco?.data.fabrics.map(
                                          (el: any) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td
                                                    style={{
                                                      border:
                                                        "1px solid #CCCCCC",
                                                      padding: "9px",
                                                    }}>
                                                    <span>{el.name} </span>{" "}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border:
                                                        "1px solid #CCCCCC",
                                                      padding: "9px",
                                                    }}>
                                                    <span>
                                                      {" "}
                                                      {el.description}{" "}
                                                    </span>{" "}
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                      </table>
                                    </div>
                                  )}

                                  <div
                                    style={{
                                      marginBottom: "16px",
                                      marginTop: "16px",
                                      color: "#00468C",
                                      marginRight: "9px",
                                      fontWeight: "bold",
                                    }}>
                                    {(() => {
                                      switch (el.status_id) {
                                        case 1:
                                          return <span>Size : PROTO</span>;
                                          break;
                                        case 2:
                                          return <span>Size : PRE SMS</span>;
                                          break;
                                        case 3:
                                          return <span>Size : SMS</span>;
                                          break;
                                        case 4:
                                          return <span> Size : OK PROD</span>;
                                          break;
                                      }
                                    })()}
                                  </div>
                                  {el.status_id != 5 && (
                                    <>
                                      <div style={{ marginBottom: "16px" }}>
                                        {" "}
                                        {matrix.length > 0 ? (
                                          <div>
                                            <table style={{ width: "100%" }}  className={"table-progress"}>
                                              <th
                                                style={{
                                                  border: "1px solid #CCCCCC",
                                                  padding: "9px",
                                                }}>
                                                Length
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid #CCCCCC",
                                                  padding: "9px",
                                                }}>
                                                Size
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid #CCCCCC",
                                                  padding: "9px",
                                                }}>
                                                Qty
                                              </th>
                                              <tbody>
                                                {matrix.map(
                                                  (row: any, rowIndex: any) => (
                                                    <tr
                                                      key={rowIndex}
                                                      style={{
                                                        border:
                                                          "1px solid #CCCCCC",
                                                      }}>
                                                      {row.map(
                                                        (
                                                          cell: any,
                                                          cellIndex: any
                                                        ) => (
                                                          <td
                                                            key={cellIndex}
                                                            style={{
                                                              border:
                                                                "1px solid #CCCCCC",
                                                              padding: "9px",
                                                            }}>
                                                            {cell}
                                                          </td>
                                                        )
                                                      )}
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        ) : (
                                          <div className="p-3">-</div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}

               
                        
                       

                        {step == 5 &&
                          productdemco?.data.orders_status.filter(
                            (el: any) =>
                              el.status_id === 5 &&
                              el.proto_confirmation === "ACCEPTED"
                          ).length > 0 && (
                            <>

<div
                            style={{
                              marginTop: "-50px",
                              marginBottom: "16px",
                            }}>
                            <table
                              style={{ width: "100%", marginBottom: "25px" }}  className={"table-progress"}>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Fabric{" "}
                                  </span>{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Details{" "}
                                  </span>{" "}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {productdemco?.data?.fabrics?.map(
                                    (el: any) => {
                                      return (
                                        <>
                                          <ul id="myUL2">
                                            <li>{el.name}</li>
                                          </ul>
                                        </>
                                      );
                                    }
                                  )}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {productdemco?.data.orders_status
                                    .filter((el: any) => el.status_id !== 5)
                                    .map((el: any) => {
                                      return (
                                        <>
                                          <ul id="myUL2">
                                            <li
                                              style={{
                                                whiteSpace: "pre-line",
                                              }}>
                                              {el.details_fabric ?? "-"}
                                            </li>
                                          </ul>
                                        </>
                                      );
                                    })}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Style{" "}
                                  </span>{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Details{" "}
                                  </span>{" "}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {productdemco?.data?.name}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                  }}>
                                  {productdemco?.data.orders_status
                                    .filter((el: any) => el.status_id !== 5)
                                    .map((el: any) => {
                                      return (
                                        <>
                                          <ul id="myUL2">
                                            <li
                                              style={{
                                                whiteSpace: "pre-line",
                                              }}>
                                              {el.details_style ?? "-"}
                                            </li>
                                          </ul>
                                        </>
                                      );
                                    })}
                                </td>
                              </tr>
                            </table>
                          </div>
                              <table
                                style={{
                                  width: "100%",
                                  marginBottom: "25px",
                                }}
                                className={"table-progress"}
                              >
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                      width: "33%",
                                      fontWeight: "bold",
                                    }}>
                                    Production
                                  </td>{" "}
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                      width: "33%",
                                      fontWeight: "bold",
                                    }}>
                                    Delivery Date
                                  </td>{" "}
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                      width: "33%",
                                      fontWeight: "bold",
                                    }}>
                                    Quantity
                                  </td>{" "}
                                </tr>

                                {productdemco?.data.orders_status
                                  .sort((a: any, b: any) =>
                                    a.sort_order > b.sort_order ? 1 : -1
                                  )

                                  .filter(
                                    (el: any) =>
                                      el.status_id === 5 &&
                                      el.proto_confirmation === "ACCEPTED"
                                  )
                                  .map((el: any, index: number) => {
                                    return (
                                      <>
                                        {productdemco?.data.status.id == step &&
                                          el.proto_confirmation ===
                                            "ACCEPTED" && (
                                            <tr>
                                              <td
                                                style={{
                                                  border: "1px solid #CCCCCC",
                                                  padding: "9px",
                                                }}>
                                                {productdemco?.data.orders_status.filter(
                                                  (el: any) =>
                                                    el.status_id === 5
                                                ).length - index}
                                              </td>{" "}
                                              <td
                                                style={{
                                                  border: "1px solid #CCCCCC",
                                                  padding: "9px",
                                                }}>
                                                {moment(
                                                  el.delivery_date
                                                ).format("DD-MM-YYYY ")}
                                              </td>{" "}
                                              <td
                                                style={{
                                                  border: "1px solid #CCCCCC",
                                                  padding: "9px",
                                                }}>
                                                {el.qty}
                                              </td>{" "}
                                            </tr>
                                          )}
                                      </>
                                    );
                                  })}
                              </table>
                            </>
                          )}

                          

                        {step == 1 ? (
                          <>
                            {productdemco?.data.fabrics.length > 0 && (
                              <div>
                                <table style={{ width: "100%" }}  className={"table-progress"}>
                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        Style{" "}
                                      </span>{" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        Details{" "}
                                      </span>{" "}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                      }}>
                                      <span>{productdemco?.data.name} </span>{" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                      }}>
                                      <span>
                                        {" "}
                                        {productdemco?.data.description}{" "}
                                      </span>{" "}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        Fabric{" "}
                                      </span>{" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                        width: "50%",
                                      }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        Details{" "}
                                      </span>{" "}
                                    </td>
                                  </tr>

                                  {productdemco?.data.fabrics.map((el: any) => {
                                    return (
                                      <>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            <span>{el.name} </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                            }}>
                                            <span> {el.description} </span>{" "}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </table>
                              </div>
                            )}

                            <div
                              style={{
                                display: "flex",
                                marginBottom: "16px",
                                marginTop: "16px",
                              }}>
                              <div
                                style={{
                                  color: "#00468C",
                                  marginRight: "9px",
                                  fontWeight: "bold",
                                }}>
                                Quantity Proto:
                              </div>
                              <div>1</div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {productdemco?.data.orders_status.map((el: any) => {
                          {
                            return (
                              <>
                                {el.status_id == step && el.status_id !== 5 ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "16px",
                                      }}>
                                      <div
                                        style={{
                                          color: "#00468C",
                                          marginRight: "9px",
                                          fontWeight: "bold",
                                        }}>
                                        Delivery date :
                                      </div>
                                      <div>
                                        {productdemco &&
                                        product?.data?.delivery_date
                                          ? moment(
                                              product?.data?.delivery_date
                                            ).format("DD-MM-YYYY")
                                          : "-"}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          }
                        })}
                        {productdemco?.data &&
                          productdemco?.data.comments.length > 0 && (
                            <>
                              <div
                                style={{
                                  color: "#00468C",
                                  marginRight: "9px",
                                  fontWeight: "bold",
                                  marginBottom: "16px",
                                }}>
                                Comments
                              </div>
                              {productdemco?.data.comments.map((el: any) => {
                                return (
                                  <>
                                    {console.log(productdemco?.data)}
                                    {el.status_id == step &&
                                    el.name != "PROTO" ? (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            marginBottom: "16px",
                                          }}>
                                          {el.client_id ==
                                            product?.data.client.id &&
                                          el.commercial_id == null ? (
                                            <>
                                              <Avatar
                                                src={
                                                  process.env
                                                    .REACT_APP_STORAGE_URL +
                                                  product?.data.client.logo
                                                }
                                                size="default"
                                                style={{
                                                  marginRight: "5px",
                                                }}
                                              />{" "}
                                              <div
                                                style={{
                                                  backgroundColor: "#F8FAFC",
                                                  borderRadius: "5px",
                                                  border: "1px solid #CDD5DF",
                                                  width: "100%",
                                                  padding: "16px",
                                                }}>
                                                <div
                                                  className="row"
                                                  style={{
                                                    justifyContent:
                                                      "space-between",
                                                  }}>
                                                  <div
                                                    style={{
                                                      lineHeight: "24px",
                                                      fontWeight: "bold",
                                                    }}>
                                                    {product?.data.client.name}
                                                  </div>
                                                  <div>
                                                    <p>
                                                      {moment(
                                                        el.created_at
                                                      ).format(
                                                        "DD-MM-YYYY HH:mm"
                                                      )}{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div>{el.comment}</div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <Avatar
                                                src={
                                                  process.env
                                                    .REACT_APP_STORAGE_URL +
                                                  el.commercial.avatar
                                                }
                                                size="default"
                                                style={{
                                                  marginRight: "5px",
                                                }}
                                              />{" "}
                                              <div
                                                style={{
                                                  backgroundColor: "#F8FAFC",
                                                  borderRadius: "5px",
                                                  border: "1px solid #CDD5DF",
                                                  width: "100%",
                                                  padding: "16px",
                                                }}>
                                                <div
                                                  className="row"
                                                  style={{
                                                    justifyContent:
                                                      "space-between",
                                                  }}>
                                                  <div
                                                    style={{
                                                      lineHeight: "24px",
                                                      fontWeight: "bold",
                                                    }}>
                                                    {el?.commercial?.name}
                                                  </div>
                                                  <div>
                                                    <p>
                                                      {moment(
                                                        el.created_at
                                                      ).format(
                                                        "DD-MM-YYYY HH:mm"
                                                      )}{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div>{el.comment}</div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          )}
                      </div>
                    </>
                  )}
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="proto_breadcrumb_container">
              {productdemco?.data.orders_status
                .filter((el: any) => el.status_id !== 5)
                .map((el: any) => {
                  return (
                    <>
                      {productdemco?.data.status.id == step &&
                        el.proto_confirmation !== "ACCEPTED" && (
                          <div className="proto_footer">
                            {productdemco?.data.status.name !== "CANCELLED" &&
                              (productdemco?.data.status.name === "SMS" ||
                              productdemco?.data.status.name === "OK PROD" ||
                              productdemco?.data.status.name === "PRODUCTION" ||
                              productdemco?.data.status.name === "PRE SMS" ||
                              productdemco?.data.status.name === "PROTO" ? (
                                <div>
                                  <div
                                    style={{
                                      padding: "24px",
                                      backgroundColor: "#F1F5FE",
                                      borderRadius: "5px",
                                    }}
                                  className={"action-comment"}
                                  >
                                    {product?.product.status.name === "SMS" ||
                                    product?.product.status.name ===
                                      "OK PROD" ||
                                    product?.product.status.name ===
                                      "PRODUCTION" ||
                                    product?.product.status.name ===
                                      "PRE SMS" ? (
                                      <div>
                                        {product?.product.status.name !==
                                        "PRODUCTION" ? (
                                          <div
                                            style={{
                                              marginBottom: "15px",
                                            }}>
                                            <label className="mr-3 mb-0">
                                              {" "}
                                              Url 360 °
                                            </label>
                                            <Input
                                              type="text"
                                              defaultValue={proto || el.proto}
                                              onChange={handleChangeProto}
                                              style={{
                                                borderRadius: "5px",
                                                marginBottom: "15px",
                                              }}
                                              disabled={!el.confirmed}></Input>

                                            {inputs.map((input, index) =>
                                              index % 3 === 0 ? (
                                                <div
                                                  key={index}
                                                  className="row grid_size">
                                                  <div className=" col-9">
                                                    <div className="row">
                                                      <div
                                                        className=" col-4"
                                                        style={{
                                                          display: "flex",
                                                          /*  marginRight: "1.2em", */
                                                          width: "30%",
                                                        }}>
                                                        <span
                                                          style={{
                                                            backgroundColor:
                                                              "#182844",
                                                            color: "white",
                                                            padding: "8px",
                                                            borderTopLeftRadius:
                                                              "5px",
                                                            borderBottomLeftRadius:
                                                              "5px",
                                                            borderColor:
                                                              "#98A2B3",
                                                          }}>
                                                          Size
                                                        </span>
                                                        <Input
                                                          value={input}
                                                          onChange={(event) =>
                                                            handleInputChange(
                                                              event,
                                                              index
                                                            )
                                                          }
                                                          style={{
                                                            width: "150px",
                                                            borderTopRightRadius:
                                                              "5px",
                                                            borderBottomRightRadius:
                                                              "5px",
                                                          }}
                                                          disabled={
                                                            !el.confirmed
                                                          }
                                                        />
                                                      </div>

                                                      {inputs[index + 1] !==
                                                        undefined && (
                                                        <div
                                                          className=" col-4"
                                                          style={{
                                                            display: "flex",
                                                            /* marginRight: "1.2em", */
                                                            width: "30%",
                                                          }}>
                                                          <span
                                                            style={{
                                                              backgroundColor:
                                                                "#182844",
                                                              color: "white",
                                                              padding: "8px",
                                                              borderTopLeftRadius:
                                                                "5px",
                                                              borderBottomLeftRadius:
                                                                "5px",
                                                            }}>
                                                            Length
                                                          </span>
                                                          <Input
                                                            value={
                                                              inputs[index + 1]
                                                            }
                                                            onChange={(event) =>
                                                              handleInputChange(
                                                                event,
                                                                index + 1
                                                              )
                                                            }
                                                            style={{
                                                              width: "150px",
                                                              borderTopRightRadius:
                                                                "5px",
                                                              borderBottomRightRadius:
                                                                "5px",
                                                            }}
                                                            disabled={
                                                              !el.confirmed
                                                            }
                                                          />
                                                        </div>
                                                      )}
                                                      {inputs[index + 2] !==
                                                        undefined && (
                                                        <div
                                                          className=" col-4"
                                                          style={{
                                                            display: "flex",
                                                            /* marginRight: "1.2em", */
                                                            width: "30%",
                                                          }}>
                                                          <span
                                                            style={{
                                                              backgroundColor:
                                                                "#182844",
                                                              color: "white",
                                                              padding: "8px",
                                                              borderTopLeftRadius:
                                                                "5px",
                                                              borderBottomLeftRadius:
                                                                "5px",
                                                            }}>
                                                            Qty
                                                          </span>
                                                          <Input
                                                            value={
                                                              inputs[index + 2]
                                                            }
                                                            onChange={(event) =>
                                                              handleInputChange(
                                                                event,
                                                                index + 2
                                                              )
                                                            }
                                                            style={{
                                                              width: "150px",
                                                              borderTopRightRadius:
                                                                "5px",
                                                              borderBottomRightRadius:
                                                                "5px",
                                                            }}
                                                            disabled={
                                                              !el.confirmed
                                                            }
                                                          />
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className="col-3">
                                                    {" "}
                                                    {inputs.length - 3 ? (
                                                      <button
                                                        className="remove_input"
                                                        onClick={() =>
                                                          handleRemoveInput(
                                                            index
                                                          )
                                                        }>
                                                        <MinusOutlined />
                                                      </button>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {index ===
                                                      inputs.length - 3 && (
                                                      <button
                                                        className="add_input"
                                                        style={{
                                                          marginLeft: "1.2em",
                                                        }}
                                                        onClick={
                                                          handleAddInput
                                                        }>
                                                        <PlusOutlined></PlusOutlined>
                                                      </button>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : null
                                            )}
                                          </div>
                                        ) : (
                                          <>
                                            <div className="my-3">
                                              <label
                                                className="mr-3 mb-0"
                                                style={{
                                                  color: "#344054",
                                                }}>
                                                Quantity
                                              </label>

                                              <Input
                                                value={qty || el.qty}
                                                onChange={(e: any) =>
                                                  setQty(e.target.value)
                                                }
                                                disabled={
                                                  !el.confirmed
                                                }></Input>
                                            </div>

                                            <label className="mr-3 mb-0">
                                              {" "}
                                              Actual Delivery Date
                                            </label>
                                            <Input
                                              type="date"
                                              value={
                                                startDate ||
                                                moment(
                                                  el.start_production_date
                                                ).format("YYYY-MM-DD")
                                              }
                                              onChange={handleChangeStartDate}
                                              style={{
                                                borderRadius: "5px",
                                                marginBottom: "15px",
                                              }}
                                              disabled={!el.confirmed}></Input>
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {product?.product.status.name ===
                                      "PROTO" && (
                                      <>
                                        <label className="mr-3 mb-0">
                                          {" "}
                                          Url 360 °
                                        </label>
                                        <Input
                                          type="text"
                                          defaultValue={proto || el.proto}
                                          onChange={handleChangeProto}
                                          style={{
                                            borderRadius: "5px",
                                            marginBottom: "15px",
                                          }}
                                          disabled={!el.confirmed}></Input>
                                      </>
                                    )}
                                    <label
                                      className="mr-3 mb-0"
                                      style={{ color: "#344054" }}>
                                      Delivery date
                                    </label>

                                    <DatePicker
                                      defaultValue={moment(
                                        product?.data?.delivery_date === null
                                          ? new Date()
                                          : product?.data?.delivery_date
                                      )}
                                      disabledDate={(current) =>
                                        current.isBefore(
                                          moment().add(15, "days")
                                        )
                                      }
                                      format={"YYYY-MM-DD"}
                                      onChange={(date: any, dateString: any) =>
                                        setDeliveryDate(dateString)
                                      }
                                      style={{
                                        borderRadius: "5px",
                                        width: "100%",
                                      }}
                                      disabled={!el.confirmed}
                                    />

                                    <>
                                      {productdemco?.data &&
                                        productdemco?.data.comments.length >
                                          0 && (
                                          <>
                                            <div
                                              style={{
                                                color: "#00468C",
                                                marginRight: "9px",
                                                fontWeight: "bold",
                                                marginBottom: "16px",
                                                marginTop: "16px",
                                              }}>
                                              Comments
                                            </div>
                                            {productdemco?.data.comments.map(
                                              (el: any) => {
                                                return (
                                                  <>
                                                    {console.log(
                                                      productdemco?.data
                                                    )}
                                                    {el.status_id == step &&
                                                    el.name != "PROTO" ? (
                                                      <>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            marginBottom:
                                                              "16px",
                                                          }}>
                                                          {el.client_id ==
                                                            product?.data.client
                                                              .id &&
                                                          el.commercial_id ==
                                                            null ? (
                                                            <>
                                                              <Avatar
                                                                src={
                                                                  process.env
                                                                    .REACT_APP_STORAGE_URL +
                                                                  product?.data
                                                                    .client.logo
                                                                }
                                                                size="default"
                                                                style={{
                                                                  marginRight:
                                                                    "5px",
                                                                }}
                                                              />{" "}
                                                              <div
                                                                style={{
                                                                  backgroundColor:
                                                                    "#F8FAFC",
                                                                  borderRadius:
                                                                    "5px",
                                                                  border:
                                                                    "1px solid #CDD5DF",
                                                                  width: "100%",
                                                                  padding:
                                                                    "16px",
                                                                }}>
                                                                <div
                                                                  className="row"
                                                                  style={{
                                                                    justifyContent:
                                                                      "space-between",
                                                                  }}>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "24px",
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}>
                                                                    {
                                                                      product
                                                                        ?.data
                                                                        .client
                                                                        .name
                                                                    }
                                                                  </div>
                                                                  <div>
                                                                    <p>
                                                                      {moment(
                                                                        el.created_at
                                                                      ).format(
                                                                        "DD-MM-YYYY HH:mm"
                                                                      )}{" "}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                                <div>
                                                                  {el.comment}
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <Avatar
                                                                src={
                                                                  process.env
                                                                    .REACT_APP_STORAGE_URL +
                                                                  el.commercial
                                                                    .avatar
                                                                }
                                                                size="default"
                                                                style={{
                                                                  marginRight:
                                                                    "5px",
                                                                }}
                                                              />{" "}
                                                              <div
                                                                style={{
                                                                  backgroundColor:
                                                                    "#F8FAFC",
                                                                  borderRadius:
                                                                    "5px",
                                                                  border:
                                                                    "1px solid #CDD5DF",
                                                                  width: "100%",
                                                                  padding:
                                                                    "16px",
                                                                }}>
                                                                <div
                                                                  className="row"
                                                                  style={{
                                                                    justifyContent:
                                                                      "space-between",
                                                                  }}>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "24px",
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}>
                                                                    {
                                                                      el
                                                                        ?.commercial
                                                                        ?.name
                                                                    }
                                                                  </div>
                                                                  <div>
                                                                    <p>
                                                                      {moment(
                                                                        el.created_at
                                                                      ).format(
                                                                        "DD-MM-YYYY HH:mm"
                                                                      )}{" "}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                                <div>
                                                                  {el.comment}
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                    </>
                                    <textarea
                                      rows={4}
                                      name="comment"
                                      placeholder="Add your comment"
                                      className="comment_proto_order my-4"
                                      onChange={(e: any) =>
                                        setComment(e.target.value)
                                      }
                                      value={comment}
                                      disabled={!el.confirmed}></textarea>
                                    <div className=" footer_btns_form2">
                                      {el.confirmed == 1 && (
                                        <Button
                                          type="default"
                                          className="save-changes"
                                          loading={saveDisable}
                                          onClick={() =>
                                            valider(
                                              product?.product?.status?.id,
                                              product?.product?.id
                                            )
                                          }
                                          disabled={
                                            el.proto_confirmation === "ACCEPTED"
                                          }>
                                          Save Changes
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              ))}
                            {product?.product.status.name !== "CANCELLED" ? (
                              <div className="proto_footer_btns my-4">
                                {product?.product.status.id < 4 && (
                                  <Button
                                    type="default"
                                    className="cancel_btn"
                                    onClick={() => showCancelDrawer()}>
                                    Cancel
                                  </Button>
                                )}
                                {el.confirmed == 0 && (
                                  <Button
                                    type="default"
                                    className="approve_btn"
                                    loading={confirmstep}
                                    onClick={() => confirmStep(el.id)}
                                    disabled={el.confirmed === 1}>
                                    {el.confirmed === 1
                                      ? "Confirmed"
                                      : "Confirm"}
                                  </Button>
                                )}

                                {el.confirmed == 1 && (
                                  <Button
                                    type="default"
                                    className="approve_btn"
                                    loading={rejectOrAcceptProtoLoading}
                                    onClick={() =>
                                      approve(
                                        product?.data?.client?.id,
                                        product?.product?.status?.id,
                                        product?.product?.id
                                      )
                                    }
                                    disabled={
                                      el.proto_confirmation === "ACCEPTED"
                                    }>
                                    Approve & send
                                  </Button>
                                )}
                              </div>
                            ) : (
                              <div
                                className="proto_footer_btns my-4"
                                style={{ justifyContent: "center" }}>
                                <Button
                                  type="default"
                                  className="approve_btn"
                                  onClick={() => history.back()}>
                                  Go back to orders
                                </Button>
                              </div>
                            )}
                          </div>
                        )}
                    </>
                  );
                })}

              {productdemco?.data.orders_status
                .filter(
                  (el: any) =>
                    el.status_id === 5 && el.proto_confirmation !== "ACCEPTED"
                )
                .map((el: any) => {
                  return (
                    <>
                      {productdemco?.data.status.id == step &&
                        el.proto_confirmation !== "ACCEPTED" &&
                        el.id ==
                          productdemco?.data.orders_status[
                            productdemco?.data.orders_status.length - 1
                          ].id && (
                          <div className="proto_footer">
                            {productdemco?.data.status.name === "PRODUCTION" ? (
                              <div>
                                <div
                                  style={{
                                    padding: "24px",
                                    backgroundColor: "#F1F5FE",
                                    borderRadius: "5px",
                                  }}>
                                  <div>
                                    {product?.product.status.name ===
                                      "PRODUCTION" && (
                                      <>
                                        <div className="my-3">
                                          <label
                                            className="mr-3 mb-0"
                                            style={{
                                              color: "#344054",
                                            }}>
                                            Quantity
                                          </label>

                                          <Input
                                            value={qty || el.qty}
                                            onChange={(e: any) =>
                                              setQty(e.target.value)
                                            }
                                            disabled={!el.confirmed}></Input>
                                        </div>

                                        <label className="mr-3 mb-0">
                                          {" "}
                                          Actual Delivery Date
                                        </label>
                                        <Input
                                          type="date"
                                          value={
                                            startDate ||
                                            moment(
                                              el.start_production_date
                                            ).format("YYYY-MM-DD")
                                          }
                                          onChange={handleChangeStartDate}
                                          style={{
                                            borderRadius: "5px",
                                            marginBottom: "15px",
                                          }}
                                          disabled={!el.confirmed}></Input>
                                      </>
                                    )}
                                  </div>

                                  <label
                                    className="mr-3 mb-0"
                                    style={{ color: "#344054" }}>
                                    Delivery date
                                  </label>

                                  <DatePicker
                                    defaultValue={moment(
                                      product?.data?.delivery_date === null
                                        ? new Date()
                                        : product?.data?.delivery_date
                                    )}
                                    disabledDate={(current) =>
                                      current.isBefore(moment().add(15, "days"))
                                    }
                                    format={"YYYY-MM-DD"}
                                    onChange={(date: any, dateString: any) =>
                                      setDeliveryDate(dateString)
                                    }
                                    style={{
                                      borderRadius: "5px",
                                      width: "100%",
                                    }}
                                    disabled={!el.confirmed}
                                  />

                                  <>
                                    {productdemco?.data &&
                                      productdemco?.data.comments.length >
                                        0 && (
                                        <>
                                          <div
                                            style={{
                                              color: "#00468C",
                                              marginRight: "9px",
                                              fontWeight: "bold",
                                              marginBottom: "16px",
                                              marginTop: "16px",
                                            }}>
                                            Comments
                                          </div>
                                          {productdemco?.data.comments.map(
                                            (el: any) => {
                                              return (
                                                <>
                                                  {console.log(
                                                    productdemco?.data
                                                  )}
                                                  {el.status_id == step &&
                                                  el.name != "PROTO" ? (
                                                    <>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          marginBottom: "16px",
                                                        }}>
                                                        {el.client_id ==
                                                          product?.data.client
                                                            .id &&
                                                        el.commercial_id ==
                                                          null ? (
                                                          <>
                                                            <Avatar
                                                              src={
                                                                process.env
                                                                  .REACT_APP_STORAGE_URL +
                                                                product?.data
                                                                  .client.logo
                                                              }
                                                              size="default"
                                                              style={{
                                                                marginRight:
                                                                  "5px",
                                                              }}
                                                            />{" "}
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#F8FAFC",
                                                                borderRadius:
                                                                  "5px",
                                                                border:
                                                                  "1px solid #CDD5DF",
                                                                width: "100%",
                                                                padding: "16px",
                                                              }}>
                                                              <div
                                                                className="row"
                                                                style={{
                                                                  justifyContent:
                                                                    "space-between",
                                                                }}>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "24px",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}>
                                                                  {
                                                                    product
                                                                      ?.data
                                                                      .client
                                                                      .name
                                                                  }
                                                                </div>
                                                                <div>
                                                                  <p>
                                                                    {moment(
                                                                      el.created_at
                                                                    ).format(
                                                                      "DD-MM-YYYY HH:mm"
                                                                    )}{" "}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                              <div>
                                                                {el.comment}
                                                              </div>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <Avatar
                                                              src={
                                                                process.env
                                                                  .REACT_APP_STORAGE_URL +
                                                                el.commercial
                                                                  .avatar
                                                              }
                                                              size="default"
                                                              style={{
                                                                marginRight:
                                                                  "5px",
                                                              }}
                                                            />{" "}
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  "#F8FAFC",
                                                                borderRadius:
                                                                  "5px",
                                                                border:
                                                                  "1px solid #CDD5DF",
                                                                width: "100%",
                                                                padding: "16px",
                                                              }}>
                                                              <div
                                                                className="row"
                                                                style={{
                                                                  justifyContent:
                                                                    "space-between",
                                                                }}>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "24px",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}>
                                                                  {
                                                                    el
                                                                      ?.commercial
                                                                      ?.name
                                                                  }
                                                                </div>
                                                                <div>
                                                                  <p>
                                                                    {moment(
                                                                      el.created_at
                                                                    ).format(
                                                                      "DD-MM-YYYY HH:mm"
                                                                    )}{" "}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                              <div>
                                                                {el.comment}
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                  </>
                                  <textarea
                                    rows={4}
                                    name="comment"
                                    placeholder="Add your comment"
                                    className="comment_proto_order my-4"
                                    onChange={(e: any) =>
                                      setComment(e.target.value)
                                    }
                                    value={comment}
                                    disabled={!el.confirmed}></textarea>
                                  <div className=" footer_btns_form2">
                                    {el.confirmed == 1 && (
                                      <Button
                                        type="default"
                                        className="save-changes"
                                        loading={saveDisable}
                                        onClick={() =>
                                          valider(
                                            product?.product?.status?.id,
                                            product?.product?.id
                                          )
                                        }
                                        disabled={
                                          el.proto_confirmation === "ACCEPTED"
                                        }>
                                        Save Changes
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {product?.product.status.name !== "CANCELLED" ? (
                              <div className="proto_footer_btns my-4">
                                {product?.product.status.id < 4 && (
                                  <Button
                                    type="default"
                                    className="cancel_btn"
                                    onClick={() => showCancelDrawer()}>
                                    Cancel
                                  </Button>
                                )}
                                {el.confirmed == 0 && (
                                  <Button
                                    type="default"
                                    className="approve_btn"
                                    loading={confirmstep}
                                    onClick={() => confirmStep(el.id)}
                                    disabled={el.confirmed === 1}>
                                    {el.confirmed === 1
                                      ? "Confirmed"
                                      : "Confirm"}
                                  </Button>
                                )}

                                {el.confirmed == 1 && (
                                  <Button
                                    type="default"
                                    className="approve_btn"
                                    loading={rejectOrAcceptProtoLoading}
                                    onClick={() =>
                                      approve(
                                        product?.data?.client?.id,
                                        product?.product?.status?.id,
                                        product?.product?.id
                                      )
                                    }
                                    disabled={
                                      el.proto_confirmation === "ACCEPTED"
                                    }>
                                    Approve & send
                                  </Button>
                                )}
                              </div>
                            ) : (
                              <div
                                className="proto_footer_btns my-4"
                                style={{ justifyContent: "center" }}>
                                <Button
                                  type="default"
                                  className="approve_btn"
                                  onClick={() => history.back()}>
                                  Go back to orders
                                </Button>
                              </div>
                            )}
                          </div>
                        )}
                    </>
                  );
                })}
              {product?.product.orders_status.map((el: any) => {
                return (
                  <>
                    {product?.product.status.id === el.status_id &&
                      el.proto_confirmation === "ACCEPTED" && (
                        <>
                          <div className="proto_footer_btns ">
                            {el.status_id == 1 && (
                              <Button
                                type="default"
                                className="approve_btn"
                                onClick={showDrawer2}>
                                Next
                              </Button>
                            )}
                            {el.status_id != 1 && el.status_id != 5 && (
                              <Button
                                type="default"
                                className="approve_btn"
                                onClick={() => nextStep(el.status_id)}>
                                Next
                              </Button>
                            )}
                          </div>
                        </>
                      )}
                  </>
                );
              })}
            </div>
          </>
        )}

        <Modal
          title="Congratulations"
          visible={open2}
          closable={true}
          centered
          onCancel={handleCancel}
          footer={[
            <div className="proto_footer_btns p-3">
              <Button
                type="default"
                className="approve_btn"
                onClick={() => nextProto(2)}>
                {" "}
                PRE SMS{" "}
              </Button>

              <Button
                type="default"
                className="approve_btn"
                onClick={() => nextProto(3)}>
                {" "}
                SMS{" "}
              </Button>
            </div>,
          ]}>
          <p  className={"pop-up-text"}> You Approved your Proto. Where do you wish to GO?</p>
        </Modal>

        <Drawer
          title="Product details"
          placement={"bottom"}
          closable={true}
          onClose={onClose}
          visible={open}>
          <Collapse defaultActiveKey="1" key="1">
            <Collapse.Panel
              header={product?.product?.name}
              key="1"
              showArrow={false}>
              {product && (
                <div className="row border-top" key={1}>
                  <div className="col-12 col-lg-6 border-right borderLeft p-0">
                    <div className="row">
                      <div className="col-12 p-0">
                        <p className="m-0 p-2 collapse_header border-bottom font-weight-bold">
                          Details Product
                        </p>
                      </div>
                      <div className="col-12 p-0 border-bottom option_details_collapse">
                        <p className="m-0 p-2">
                          <strong>Code: </strong>
                          {product?.product?.code}
                        </p>
                      </div>
                      <div className="col-12 p-0 border-bottom option_details_collapse">
                        <p className="m-0 p-2">
                          <strong>Fabric Type: </strong>
                          {productdemco?.data?.fabric_type == "knit" ? (
                            <>
                              {productdemco?.data?.fabric_types?.length > 0 &&
                                productdemco?.data?.fabric_types.map(
                                  (el: any, key: number) =>
                                    key ===
                                      productdemco?.data?.fabric_types?.length -
                                        1 && el.fabric_name != null
                                      ? `${el.fabric_name}`
                                      : `-`
                                )}
                            </>
                          ) : (
                            <>
                              {productdemco?.data?.fabric_chars?.length > 0 &&
                                productdemco?.data?.fabric_chars.map(
                                  (el: any, key: number) =>
                                    key ===
                                      productdemco?.data?.fabric_chars?.length -
                                        1 && el.fabric_name != null
                                      ? `${el.fabric_name}`
                                      : `-`
                                )}
                            </>
                          )}
                        </p>
                      </div>

                      <div className="col-12 p-0 border-bottom option_details_collapse">
                        <p className="m-0 p-2">
                          <strong>Fit: </strong>
                          {product?.product?.fit}
                        </p>
                      </div>

                      <div className="col-12 p-0 border-bottom option_details_collapse">
                        <p className="m-0 p-2">
                          <strong>Price: </strong>
                          {product?.product?.total_price}
                        </p>
                      </div>

                      <div className="col-12 p-0 border-bottom option_details_collapse">
                        <p className="m-0 p-2">
                          <strong>Fabric Composition: </strong>
                          {product?.product?.fabric_composition}
                        </p>
                      </div>
                      <div className="col-12 p-0 border-bottom option_details_collapse">
                        <p className="m-0 p-2">
                          <strong>Description: </strong>
                          <div>{product?.product?.description}</div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 p-0 border-bottom border-right">
                    <div className="row">
                      <div className="col-12 p-0">
                        <p className="m-0 p-2 collapse_header bg-white border-bottom font-weight-bold">
                          Files
                        </p>
                      </div>
                      {/*  <div className="col-12 pt-3 files_wrapper">
                      <div className="row flex-wrap">
                        {[
                          "style_file",
                          "fabric_file",
                          "wash_file",
                          "trims_file",
                          "thread_file",
                        ].map((el: any, i: number) => (
                          <FileLightBox file={option?.data[el]} key={i} />
                        ))}
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              )}
            </Collapse.Panel>
          </Collapse>
        </Drawer>
        <CancelOrderDrawer
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          article={product}
          notApprovedOption={notApprovedOption}
          orderType={"demco_order"}
          productToCancel={product?.product?.id}
          approvingAndCommentLoading={approvingAndCommentLoading}
          rejectOrAcceptProtoLoading={rejectOrAcceptProtoLoading}
        />
      </div>
    </div>
  );
};

export default NormalOrderProgress;
