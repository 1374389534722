import React from "react";
import { Row, Col, Tooltip } from "antd";
import ReactSlider from "react-slider";
import "./canvas-configurators.scss";
import { EditBrushIcon } from "../../icons/EditBrushIcon";
import { DamageIndicatorIcon } from "../../icons/DamageIndicatorIcon";
import { Dot } from "../Dot";
import { EraseIcon } from "../../icons/EraseIcon";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export type ILazerCanvasConfiguratorProps = {
  setLazerSize: (x: number) => void;
  setLazerIntensity: (x: number) => void;
  setPaintLaser: any;
  paintLazer: any;
  setCurrent: any;
  current: any;
  setEraser: any;
};
const defaultDamageList = [
  {
    id: "0",
    img: "/damage-assets/1.png",
    color: "#ff872a",
  },
  { id: "1", img: "/damage-assets/2.png", color: "#06c170" },
  {
    id: "2",
    img: "/damage-assets/3.png",
    color: "#7a9ec5",
  },
];
const LazerCanvasConfigurator: React.FC<ILazerCanvasConfiguratorProps> = ({
  setLazerIntensity,
  setLazerSize,
  setPaintLaser,
  paintLazer,
  current,
  setCurrent,
  setEraser,
}) => {
  const [currentSizeValue, setCurrentSizeValue] = React.useState(10);
  const [stateErase, setStateErase] = React.useState(false);
  const [currentIntensityValue, setCurrentIntensityValue] = React.useState(20);
  const [owndesignlist, setOwnDesignList] = React.useState(defaultDamageList);
  React.useEffect(() => {
    console.log(owndesignlist);
    setLazerSize(currentSizeValue);
  }, [currentSizeValue]);

  React.useEffect(() => {
    console.log(currentIntensityValue);
    setLazerIntensity(currentIntensityValue);
  }, [currentIntensityValue]);

  React.useEffect(() => {
    console.log(currentIntensityValue);
  }, [currentIntensityValue]);
  ////////////////////////////////////////////////////////////////////////////

  const handleFileChange = (event: any) => {
    if (event && event.target && event.target.files && event.target.files[0]) {
      setOwnDesignList([
        ...owndesignlist,
        {
          img: URL.createObjectURL(event.target.files[0]),
          id: "added",
          color: "#" + (((1 << 24) * Math.random()) | 0).toString(16),
        },
      ]);
    }
  };
  /* 
  <button className="choose-cursor-btn" onClick={()=>setPaintLaser(true)}>
     <DamageIndicatorIcon  />
     </button>*/
  return (
    <>
      <Row style={{ width: "100%" }}>
        {/*         <button onClick={() => setEraser(false)}>draw</button>
        <button onClick={() => setEraser(true)}>erase</button> */}

        <Col span={24} className="configurator-responsive">
          <Row justify="center" style={{ marginBottom: "15px" }}>
            <div
              style={{
                textAlign: "center",
                maxWidth: "130px",
                lineHeight: 1.5,
                fontSize: "10px",
              }}
            >
              {/*  <ExclamationCircleOutlined
                style={{ fontSize: "25px", color: "#00468C" }}
              />{" "}
              <br />
              <br /> */}
              Hover over the icons to understand more about how it works
            </div>
          </Row>
          <hr />
          <Row justify="center">
            <Tooltip
              placement="top"
              color="#00468C"
              title={
                <>
                  <div>
                    Use the brush to customize your sample, while choosing its
                    size and intensity, by drawing on the front and back
                    picture.
                  </div>
                </>
              }
            >
              <button
                className={
                  !stateErase ? "choose-cursor-btn-active" : "choose-cursor-btn"
                }
                onClick={() => {
                  setEraser(false);
                  setStateErase(false);
                }}
              >
                <EditBrushIcon />
              </button>
            </Tooltip>
            <Tooltip
              placement="top"
              color="#00468C"
              title={
                <>
                  <div>
                    Use the eraser, while choosing its size, to remove parts of
                    your drawings on the front and back picture.
                  </div>
                </>
              }
            >
              <button
                className={
                  stateErase ? "choose-cursor-btn-active" : "choose-cursor-btn"
                }
                onClick={() => {
                  setEraser(true);
                  setStateErase(true);
                }}
              >
                <EraseIcon />
              </button>
            </Tooltip>
          </Row>
          <hr />
          <Row
            style={{ width: "100%" }}
            justify="space-between"
            className="conf-responsive"
          >
            <Col span={12} className="size-col">
              <Row
                justify="center"
                style={{ marginBottom: "20px" }}
                className="size-col-resp"
              >
                <div className="title-config" style={{ textAlign: "center" }}>
                  Size
                </div>
              </Row>
              <Row
                justify="center"
                style={{ marginBottom: "1rem" }}
                className="cirlcle-size"
              >
                <div className="circle-color small" />
              </Row>
              <Row justify="center">
                <ReactSlider
                  className="vertical-slider-configurator"
                  thumbClassName="thumb"
                  trackClassName="track"
                  min={5}
                  orientation={
                    window.innerWidth < 769 ? "horizontal" : "vertical"
                  }
                  max={30}
                  value={currentSizeValue}
                  onChange={(value: any) => setCurrentSizeValue(value)}
                  renderThumb={(props, state) => (
                    <div {...props}>
                      <div>{currentSizeValue}</div>
                    </div>
                  )}
                />
              </Row>
              <Row
                justify="center"
                style={{ marginTop: "1rem" }}
                className="circle-strong"
              >
                <div className="circle-color " />
              </Row>
            </Col>
            <Col span={12} className="intensity-col">
              <Row
                justify="center"
                style={{
                  /*  marginBottom: "1rem", */ marginBottom: "15px",
                  marginRight: "14px",
                }}
              >
                <div className="title-config" style={{ textAlign: "center" }}>
                  Intensity
                </div>
              </Row>
              <Row
                justify="center"
                style={{ marginBottom: "0.7rem" }}
                className="intensity-row"
              >
                <div className="sub-title-config">
                  {window.innerWidth < 769 ? "Ltr" : "Lighter"}
                </div>
              </Row>
              <Row justify="center">
                <ReactSlider
                  className="vertical-slider-configurator"
                  thumbClassName="thumb"
                  trackClassName="track"
                  min={1}
                  orientation={
                    window.innerWidth < 769 ? "horizontal" : "vertical"
                  }
                  max={99}
                  value={currentIntensityValue}
                  onChange={(value: any) => setCurrentIntensityValue(value)}
                  renderThumb={(props, state) => (
                    <div {...props}>
                      <div>{currentIntensityValue}</div>
                    </div>
                  )}
                />
              </Row>
              <Row
                justify="center"
                style={{ marginTop: "1.2rem", marginBottom: "0.5rem" }}
              >
                <div className="sub-title-config">
                  {window.innerWidth < 769 ? "Str" : "Stronger"}{" "}
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export { LazerCanvasConfigurator };

/*
:
    <Row style={{ width: "100%",  }}>
       <div className="scrollable-items-previews">
     {owndesignlist.map((item, index) => (
       <div className="btn-with-dot-indicators" key={index}>
         <button
           className="btn-damage-chooser-mos"
           //  onClick={() => handleSelectedTexture(item, index)}
         >
           <img src={item.img} alt={`id alt icon demco${index}`} />
         </button>
         <Dot color={item.color} />
       </div>
     ))}
     <div className="upload-ow-design-wrapper">
       <button className="upload-ow-design-btn">
         <svg
           xmlns="http://www.w3.org/2000/svg"
           width="18"
           height="18"
           viewBox="0 0 18 18"
         >
           <path
             id="Path_3307"
             data-name="Path 3307"
             d="M17.625-10.125h-7.5v-7.5A.375.375,0,0,0,9.75-18H8.25a.375.375,0,0,0-.375.375v7.5H.375A.375.375,0,0,0,0-9.75v1.5a.375.375,0,0,0,.375.375h7.5v7.5A.375.375,0,0,0,8.25,0h1.5a.375.375,0,0,0,.375-.375v-7.5h7.5A.375.375,0,0,0,18-8.25v-1.5A.375.375,0,0,0,17.625-10.125Z"
             transform="translate(0 18)"
             fill="#00468c"
           />
         </svg>
       </button>
       <input
         onChange={handleFileChange}
         type="file"
         name="myfile"
       />
     </div>
   </div>
      </Row>*/
