import { Reducer, AnyAction } from "redux";
import {
  GET_FOLDERS_REQUEST,
  GET_FOLDERS_SUCCESS,
  GET_ONE_FOLDER_REQUEST,
  GET_ONE_FOLDER_SUCCESS,
  GET_ONE_FOLDER_FAIL,
  ADD_ONE_FOLDER_SUCCESS,
  ADD_PRODUCT_TO_FOLDER_SUCCESS,
  GET_PRODUCT_ID_TO_ADD,
  CLEAR_ONE_FOLDER,
  UPDATE_FOLDER_SUCCESS,
  GET_RIGHT_FOLDER_FAIL,
  GET_RIGHT_FOLDER_REQUEST,
  GET_RIGHT_FOLDER_SUCCESS,
  GET_RIGHT_SUB_FOLDERS_SUCCESS,
  CLEAR_RIGHT_SIDE_FOLDER,
  GET_FOLDER_DETAILS_SUCCESS,
  ADD_FOLDER_TO_SELECTED,
  HANDLE_MOVING_STATE,
  SET_FOLDER_ID_TO_MOVE_TO,
  CHANGE_FOLDER_ROOT_REQUEST,
  CHANGE_FOLDER_ROOT_SUCCESS,
  GET_ARCHIVED_FOLDERS_REQUEST,
  GET_ARCHIVED_FOLDERS_SUCCESS,
  ARCHIVE_FOLDER_SUCCESS,
  RESTORE_FOLDER_REQUEST,
  RESTORE_FOLDER_SUCCESS,
  CLEAR_SELECTED_FOLDERS,
  CLEAR_FOLDER_TO_MOVE_TO,
  DELETE_FOLDER_SUCCESS,
  SET_FOLDER_ID_TO_MOVE_TO_ON_DRAG,
  CLEAR_FOLDER_TO_MOVE_TO_ON_DRAG,
  ADD_FOLDER_TO_SHOW,
  MOVE_PRODUCT_TO_FOLDER_SUCCESS,
  ADD_PRODUCT_TO_SELECTED,
  HANDLE_MOVING_STATE_PRODUCT,
  CLEAR_SELECTED_PRODUCTS,
  DELETE_PRODUCTS_FROM_FOLDER_SUCCESS,
  HANDLE_MOVING_STATE_PRODUCTS,
  DESABLE_MOVING_STATE_PRODUCT,
  DESABLE_DELETE_PRODUCTS_DONE,
  CLEAR_FOLDER_DETAILS,
} from "../actions/folders-actions/types";

const sortFolders = (arr: any) => {
  let tree = [],
    mappedArr: any = {},
    arrElem,
    mappedElem;
  // First map the nodes of the array to an object -> create a hash table.
  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.id] = arrElem;
    mappedArr[arrElem.id]["folders"] = [];
  }
  for (let parent_id in mappedArr) {
    if (mappedArr.hasOwnProperty(parent_id)) {
      mappedElem = mappedArr[parent_id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.parent_id) {
        if (mappedArr[mappedElem["parent_id"]]) {
          mappedArr[mappedElem["parent_id"]]["folders"].push(mappedElem);
        }
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
};

const init_state: any = {
  archivedFolders: [],
  oneFolder: false,
  rightSideFolder: false,
  rightSideSubFolders: false,
  folderDetails: false,
  folders: undefined,
  selectedFolders: [],
  selectedProducts: [],
  folderIdToMoveTo: null,
  moving: false,
  movingProduct: false,
  movingProductMultiSelection: false,
  movingProductDone: false,
  isLoading: false,
  isLoadingOneFodler: false,
  isLoadingRightFolder: false,
  isLoadingArchive: false,
  error: null,
  idProduct: null,
  folderUpdated: false,
  msg: null,
  changingRoot: false,
  folderToMoveToOnDrag: null,
  folderIdToShow: null,
  sortedFolders: undefined,
};

const foldersReducer: Reducer<any, AnyAction> = (
  state: any = init_state,
  action
): boolean => {
  var result;
  switch (action.type) {
    case DELETE_PRODUCTS_FROM_FOLDER_SUCCESS:
      return {
        ...state,
        deleteProductsDone: true,
      };
    case DESABLE_DELETE_PRODUCTS_DONE:
      return {
        ...state,
        deleteProductsDone: true,
      };
    case CHANGE_FOLDER_ROOT_REQUEST:
      return {
        ...state,
        changingRoot: true,
      };
    case ADD_FOLDER_TO_SHOW:
      return {
        ...state,
        folderIdToShow: action.payload,
      };
    case SET_FOLDER_ID_TO_MOVE_TO_ON_DRAG:
      return {
        ...state,
        folderToMoveToOnDrag: action.payload,
      };
    case CLEAR_FOLDER_TO_MOVE_TO_ON_DRAG:
      return {
        ...state,
        folderToMoveToOnDrag: null,
      };
    case RESTORE_FOLDER_REQUEST:
      return {
        ...state,
        isLoadingArchive: true,
      };
    case DELETE_FOLDER_SUCCESS:
      return {
        ...state,
        msg: action.payload.message,
      };
    case ARCHIVE_FOLDER_SUCCESS:
      return {
        ...state,
        msg: action.payload,
      };
    case RESTORE_FOLDER_SUCCESS:
      return {
        ...state,
        isLoadingArchive: false,
      };
    case GET_FOLDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        msg: null,
      };
    case HANDLE_MOVING_STATE:
      return {
        ...state,
        moving: !state.moving,
      };
    case MOVE_PRODUCT_TO_FOLDER_SUCCESS:
      return {
        ...state,
        movingProductDone: true,
      };
    case DESABLE_MOVING_STATE_PRODUCT:
      return {
        ...state,
        movingProductDone: false,
      };
    case HANDLE_MOVING_STATE_PRODUCT:
      return {
        ...state,
        movingProduct: !state.movingProduct,
      };
    case HANDLE_MOVING_STATE_PRODUCTS:
      return {
        ...state,
        movingProductMultiSelection: !state.movingProductMultiSelection,
      };
    case CLEAR_SELECTED_FOLDERS:
      return {
        ...state,
        selectedFolders: [],
      };
    case CLEAR_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: [],
      };
    case CLEAR_FOLDER_TO_MOVE_TO:
      return {
        ...state,
        folderIdToMoveTo: null,
      };
    case CLEAR_FOLDER_DETAILS:
      return {
        ...state,
        folderDetails: false,
      };
    case SET_FOLDER_ID_TO_MOVE_TO:
      if (state.folderIdToMoveTo === action.payload) {
        return {
          ...state,
          folderIdToMoveTo: null,
        };
      } else {
        return {
          ...state,
          folderIdToMoveTo: action.payload,
        };
      }

    case ADD_FOLDER_TO_SELECTED:
      const exists = state.selectedFolders.includes(action.payload);

      if (exists) {
        return {
          ...state,
          selectedFolders: state.selectedFolders.filter((c: any) => {
            return c !== action.payload;
          }),
        };
      } else {
        result = state.selectedFolders;
        result.push(action.payload);
        return {
          ...state,
          selectedFolders: result,
        };
      }
    case ADD_PRODUCT_TO_SELECTED:
      const existProduct = state.selectedProducts.find(
        (p: any) => p.id === action.payload.id
      );

      if (existProduct) {
        return {
          ...state,
          selectedProducts: state.selectedProducts.filter(
            (c: any) => c.id !== action.payload.id
          ),
        };
      } else {
        result = state.selectedProducts;
        result.push(action.payload);
        return {
          ...state,
          selectedProducts: result,
        };
      }
    case CHANGE_FOLDER_ROOT_SUCCESS:
      return {
        ...state,
        selectedFolders: [],
        folderIdToMoveTo: null,
        folderUpdated: action.payload,
        msg: action.payload,
        changingRoot: false,
        // folderToMoveToOnDrag: null,
      };
    case GET_ONE_FOLDER_REQUEST:
      return {
        ...state,
        isLoadingOneFodler: true,
      };
    case GET_RIGHT_FOLDER_REQUEST:
      return {
        ...state,
        isLoadingRightFolder: true,
      };
    case GET_FOLDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        folders: action.payload.map((el: any) => {
          return {
            ...el,
            products: el.products.map((product: any) => {
              return { ...product, idParentFolder: el.id };
            }),
          };
        }),
        sortedFolders: sortFolders(action.payload),
        folderUpdated: false,
      };
    case GET_ONE_FOLDER_FAIL:
      return {
        ...state,
        isLoadingOneFodler: false,
      };
    case GET_RIGHT_FOLDER_FAIL:
      return {
        ...state,
        isLoadingRightFolder: false,
      };
    case GET_ARCHIVED_FOLDERS_REQUEST:
      return {
        ...state,
        isLoadingArchive: true,
      };
    case GET_ARCHIVED_FOLDERS_SUCCESS:
      return {
        ...state,
        isLoadingArchive: false,
        archivedFolders: action.payload,
      };

    case GET_FOLDER_DETAILS_SUCCESS:
      return {
        ...state,
        folderDetails: action.payload,
      };
    case GET_ONE_FOLDER_SUCCESS:
      return {
        ...state,
        oneFolder: {
          ...action.payload,
          products: action.payload.products.map((el: any) => {
            return { ...el, idParentFolder: action.payload.id };
          }),
        },
        sortedFolders: state?.sortedFolders
          ? sortFolders([...state.sortedFolders, action.payload])
          : undefined,
        isLoadingOneFodler: false,
      };
    case CLEAR_ONE_FOLDER:
      return {
        ...state,
        oneFolder: false,
      };
    case CLEAR_RIGHT_SIDE_FOLDER:
      return {
        ...state,
        rightSideFolder: false,
      };

    case ADD_ONE_FOLDER_SUCCESS:
      return {
        ...state,
        folders: [...state.folders, action.payload],
      };
    case GET_PRODUCT_ID_TO_ADD:
      return {
        ...state,
        idProduct: action.payload,
      };
    case ADD_PRODUCT_TO_FOLDER_SUCCESS:
      return {
        ...state,
        folders: state.folders.map((el: any) =>
          el.id === action.payload.id ? action.payload : el
        ),
      };
    case UPDATE_FOLDER_SUCCESS:
      return {
        ...state,
        folderUpdated: true,
      };
    case GET_RIGHT_FOLDER_SUCCESS:
      return {
        ...state,
        isLoadingRightFolder: false,
        rightSideFolder: {
          ...action.payload,
          products: action.payload.products.map((el: any) => {
            return { ...el, idParentFolder: action.payload.id };
          }),
        },
      };
    case GET_RIGHT_SUB_FOLDERS_SUCCESS:
      return {
        ...state,
        rightSideSubFolders: state.folders.filter(
          (el: any) => el.parent_id === action.payload
        ),
      };
    default:
      return state;
  }
};

export default foldersReducer;
