import { useState, useEffect, useRef } from "react";
import "./Search.scss";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchScreen } from "../../actions/search-actions/actions";
import { filterByTyping } from "../../actions/products-actions/actions";
import Close from "../../icons/Close";
import placeholder from "../../img/img_placeholder.svg";
import { Link, useHistory } from "react-router-dom";
import { getAllFolders } from "../../actions/folders-actions/actions";
import axiosInstance from "../../config/axios-instance";
import debounce from "../../config/debounce";

interface Props {}

export const Search = (props: Props) => {
  const [products, setProducts] = useState<any>(null);
  const [keyword, setKeyword] = useState("");
  const inputRef: any = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { searchIsLoading, total } = useSelector(
    (state: any) => state.products
  );

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const closeSearch = () => {
    dispatch(handleSearchScreen());
    document.body.style.overflow = "auto";
  };
  const [loading, setLoading] = useState(false);
  const search = (e: any) => {
    setKeyword(e.target.value);
    setLoading(true);
    if (!e.target.value) return;
    axiosInstance({
      method: "get",
      url: `/products?name=${e.target.value}`,
    })
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
      })
      .catch((err: any) => setLoading(false));
    // dispatch(filterByTyping(e.target.value));
  };

  const goToResult = (e: any) => {
    e.preventDefault();
    dispatch(getAllFolders());
    if (keyword) {
      history.push(`/search/${keyword}`);
      dispatch(handleSearchScreen());
      document.body.style.overflow = "auto";
    }
  };
  return (
    <AnimatePresence exitBeforeEnter={true} initial={true}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="search"
      >
        <header>
          <div className="row">
          <div className="close_btn" onClick={closeSearch} style={{cursor:"pointer",padding:"25px",position:"fixed",right:0}}>
            <Close />
          </div>
          </div>
        </header>
        <main>
          {!searchIsLoading &&
            products &&
            products.data.length === 0 &&
            keyword && (
              <div className="msg_zero_result">
                <h2 className="h2_subtitle zero_result">
                  We couldn't find any results for your search.
                </h2>
                <p>Please try again</p>
              </div>
            )}
          <form onSubmit={goToResult} style={{ width: "100%" }}>
            <input
              type="text"
              placeholder="What are you looking for ?"
              className="search_input"
              ref={inputRef}
              onChange={debounce(search, 1000)}
            />
            <button type="submit" style={{ display: "none" }}>
              search
            </button>
          </form>

          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="search_list"
          >
            {searchIsLoading ? (
              <h2 className="h2_subtitle">Searching...</h2>
            ) : (
              keyword &&
              products &&
              products.data.slice(0, 6).map((el: any, index: number) => (
                <Link
                  to={`/search/result/${keyword}/${el.name}/${el.id}`}
                  onClick={() => dispatch(handleSearchScreen())}
                  key={index}
                >
                  <motion.article
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="search_list_item"
                  >
                    <img
                      src={
                        el.images.length
                          ? process.env.REACT_APP_STORAGE_URL +
                            el.images[0].path
                          : placeholder
                      }
                      alt={el.name}
                    />
                    <p>{el.code ? el.code + " " + el.name : el.name}</p>
                  </motion.article>
                </Link>
              ))
            )}
            {loading && <p>Loading...</p>}
            {!searchIsLoading &&
              products &&
              products.data.length !== 0 &&
              keyword && (
                <Link
                  to={`/search/${keyword}`}
                  className="h2_subtitle"
                  onClick={closeSearch}
                >
                  {products.data.length > 6
                    ? `${products.total - 6} Other result`
                    : "See Details"}
                </Link>
              )}
          </motion.section>
        </main>
      </motion.div>
    </AnimatePresence>
  );
};
