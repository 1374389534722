import axiosInstance from "../../config/axios-instance";

const loginUser = (data: any) => {
  return axiosInstance({
    method: "post",
    url: "/login",
    data,
  });
};

const loadUser = () => {
  let token = localStorage.getItem("token");
  let role = localStorage.getItem("role");
  return axiosInstance({
    method: "get",
    url: `/${role || "client"}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const forgotPwdSendEmail = (email: string) => {
  return axiosInstance({
    method: "post",
    url: "/forgot-password ",
    data: {
      email,
    },
  });
};

const addNewPwd = (data: any) => {
  return axiosInstance({
    method: "post",
    url: "/new-password",
    data,
  });
};

const resetPasswordConnected = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/reset-password`,
    data,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateAccount = (data: any, idUser: string, isAdmin: boolean) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: isAdmin ? `/admin/update/${idUser}` : `/clients/${idUser}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const lastActive = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/clients/me/company/last_active_members`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const emailReset = (email: string) => {
  return axiosInstance({
    method: "post",
    url: `/forgot-password`,
    data: {
      email,
    },
  });
};

const confirmCode = (reset_code: string) => {
  return axiosInstance({
    method: "post",
    url: `/confirm-code`,
    data: {
      reset_code,
    },
  });
};

const newPassword = (data: {
  email: string;
  password: string;
  password_confirmation: string;
}) => {
  return axiosInstance({
    method: "post",
    url: `/new-password`,
    data,
  });
};

const AuthServices = {
  loginUser,
  loadUser,
  forgotPwdSendEmail,
  addNewPwd,
  updateAccount,
  resetPasswordConnected,
  lastActive,
  emailReset,
  confirmCode,
  newPassword,
};

export default AuthServices;
