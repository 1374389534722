import { useRouteMatch, Route, Switch } from "react-router-dom";
import "../../sass/MyOrders.scss";
import CustomizedOrderDetails from "./CustomizedOrderDetails";
import CustomizedOrderProgress from "./CustomizedOrderProgress";
import CustomizedOrders from "./CustomizedOrders";

export default function CustomizedOrdersContainer({
  orderStatus,
  selectedDate,
}: any) {
  const { path, url } = useRouteMatch();

  return (
    <div className="my_orderss">
      <Switch>
        <Route path={`${path}`} exact>
          <CustomizedOrders
            orderType="customized-orders"
            url={url}
            orderStatus={orderStatus}
            selectedDate={selectedDate}
          />
        </Route>
        <Route exact path={`${path}/:id`}>
          <CustomizedOrderDetails orderStatus={orderStatus} />
        </Route>
        <Route path={`${path}/:order_id/:product_id`}>
          <CustomizedOrderProgress />
        </Route>
        <Route>
          <h1>Page Not Found</h1>
        </Route>
      </Switch>
    </div>
  );
}
