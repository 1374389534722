export const preventScreenShot = () => {
  document.addEventListener("keyup", function (e) {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode === 44) {
      stopPrntScr();
    }
  });
  function stopPrntScr() {
    var inpFld: any = document.createElement("input");
    inpFld.setAttribute("value", ".");
    inpFld.setAttribute("width", "0");
    inpFld.style.height = "0px";
    inpFld.style.width = "0px";
    inpFld.style.border = "0px";
    document.body.appendChild(inpFld);
    inpFld.select();
    document.execCommand("copy");
    inpFld.remove(inpFld);
  }
  function AccessClipboardData() {
    try {
      //@ts-ignore
      window.clipboardData.setData("text", "Access   Restricted");
    } catch (err) {}
  }
  //@ts-ignore
  setInterval(AccessClipboardData(), 300);
  // document.addEventListener("contextmenu", (event) => event.preventDefault());
};
