import { useEffect, useState } from "react";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { OrderArticleItem } from "./OrderArticleItem";

interface Props {
  orderToEdit?: any;
  setOptionSelected?: any;
  optionSelected?: any;
  recentOrder?: any;
  edit: boolean;
}

export const OrderArticlesList = ({
  orderToEdit,
  setOptionSelected,
  optionSelected,
  edit,
  recentOrder,
}: Props) => {
  const [order, setOrder] = useState<any>(null);
  const dispatch = useDispatch();

  const { createArticleLoading, orders, duplicated } = useSelector(
    (state: any) => state.orders
  );
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (edit) {
      if (user && isAdmin) {
        setOrder(orderToEdit[0]);
      } else {
        setOrder(orderToEdit);
      }
    }
  }, [orderToEdit]);

  useEffect(() => {
    if (!edit) {
      setOrder(orders[0]);
    }
  }, [orders]);

  // useEffect(() => {
  //   if(duplicated){

  //   }
  // }, [duplicated])

  const handleChange = (e: any, article: any) => {
    if (order) {
      setOrder({
        ...order,
        articles: order.articles.map((el: any) =>
          el.id === article.id ? { ...el, name: e.target.value } : el
        ),
      });
    }
  };

  return (
    <div className="articles_list w-100">
      {
        // edit ? (
        order &&
          (user && isAdmin
            ? order.articles.map(
                (article: any) =>
                  article && (
                    <OrderArticleItem
                      setOptionSelected={setOptionSelected}
                      optionSelected={optionSelected}
                      handleChange={(x: any, y: any) => handleChange(x, y)}
                      article={article}
                      key={article.id}
                      dispatch={dispatch}
                      order={order}
                    />
                  )
              )
            : order.articles.map(
                (article: any) =>
                  article && (
                    <OrderArticleItem
                      setOptionSelected={setOptionSelected}
                      optionSelected={optionSelected}
                      handleChange={(x: any, y: any) => handleChange(x, y)}
                      article={article}
                      key={article.id}
                      dispatch={dispatch}
                      order={order}
                    />
                  )
              ))
      }
      {/* : (
        <h1>NEW</h1>
      )} */}
      {createArticleLoading && (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      )}
    </div>
  );
};
