import axiosInstance from "../../config/axios-instance";

// Get aLL pOSTS
const getCreativeStation = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: "/posts",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get load more posts
const loadMoreCreativeStation = (url: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Get One Post
const getOnePost = (id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/posts/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Delete post
const deletePost = (id: number) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "delete",
    url: `/posts/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const CreativeStaionServices = {
  getCreativeStation,
  loadMoreCreativeStation,
  getOnePost,
  deletePost,
};

export default CreativeStaionServices;
