import { SET_ALERT, REMOVE_ALERT, CLEAR_ALL_ALERTS } from "./types";

// set alert
export const setAlert = (msg: string, type: string, id: string) => (
  dispatch?: any
) => {
  dispatch({
    type: SET_ALERT,
    payload: { msg, type, id },
  });
};

// clear all alerts
export const clearAllAlerts = () => (dispatch?: any) => {
  dispatch({
    type: CLEAR_ALL_ALERTS,
  });
};

// delete alert
export const removeAlert = (id: string) => (dispatch?: any) => {
  dispatch({
    type: REMOVE_ALERT,
    payload: id,
  });
};
