import * as actionTypes from "./actionTypes";

// examples of action creators

// export const addArticle = (article: IArticle): ArticleAction => {
//   return {
//     type: actionTypes.ADD,
//     article,
//   };
// };

// Open / Close Right Drawer
export const handleRightDrawer = () => {
  return {
    type: actionTypes.HANDLE_RIGHT,
  };
};

// Open / Close Left Drawer
export const handleLeftDrawer = () => {
  return {
    type: actionTypes.HANDLE_LEFT,
  };
};

// Open / Close Left Drawer
export const handleLeftDrawer2 = () => {
  return {
    type: actionTypes.HANDLE_LEFT2,
  };
};
// Open / Close bottom Drawer
export const handleBottomDrawer = () => {
  return {
    type: actionTypes.HANDLE_BOTTOM,
  };
};
// Open / Close bottom Drawer add folder
export const handleBottomDrawerAddFolder = () => {
  return {
    type: actionTypes.HANDLE_BOTTOM_ADD_FOLDER,
  };
};

// Open / Close bottom Drawer deleted items
export const handleBottomDrawerDeletedItems = () => {
  return {
    type: actionTypes.HANDLE_BOTTOM_DELETED_ITEMS,
  };
};

// Open / Close bottom Drawer deleted items
export const handleLeftFolderDetails = () => {
  return {
    type: actionTypes.HANDLE_LEFT_FOLDER_DETAILS,
  };
};

// Open / Close bottom Drawer deleted items
export const handleBottomAddToCollection = () => {
  return {
    type: actionTypes.HANDLE_BOTTOM_ADD_TO_COLLECTION,
  };
};
