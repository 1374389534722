import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lastActive } from "../actions/auth-actions/actions";
import BannerInfo from "../components/BannerInfo/BannerInfo";
import { DiscoverList } from "../components/DiscoverList/DiscoverList";
import LabelList from "../components/Label/LabelList";
import Footer from "../components/Footer/Footer";
import ourCertifications from "../img/certifications.svg";
import jeans from "../img/jeans.webp";
import Header from "../components/Header/Header";
import {
  bannerInfosData,
  bannerInfosDifference,
  bannerInfosCustomization,
} from "../StaticData/BannerInfoData";
import diffImg from "../img/WhatMakesUsDifferent.jpg";
import "../sass/Home.scss";
import { ProductList } from "../components/ProductCard/ProductList";
import { ProductCustomList } from "../components/ProductCard/ProductCustomList";

import { Features } from "../components/Features/Features";
import { DirectOrderCarousel } from "../components/DirectOrderCarousel/DirectOrderCarousel";
import Carousel3D from "../components/Carousel3D/Carousel3D";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { getSelectionProducts } from "../actions/myselection-actions/actions";
import Head from "../components/Head";
import Modal from "antd/lib/modal/Modal";
import { finish, goNextStep } from "../actions/guided-tour-actions/actions";
import { Avatar, Button, List, Skeleton } from "antd";
import { NavHashLink } from "react-router-hash-link";
import Popup from "../components/Popup/Popup";
import onboarding_img from "../img/onboarding_img.png";
import customization_tool from "../img/customization_tool.png";
//@ts-ignore
import creative_team from "../video/creative_team.mp4";
import Certification from "../components/Certification/Certification";
import axiosInstance from "../config/axios-instance";

export const Home = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const { step } = useSelector((state: any) => state.tour);

  const [foryouContent, setForyouContent] = useState<any>(null);
  const [directContent, setdirectContent] = useState<any>(null);
  const [customContent, setcustomContent] = useState<any>(null);
  const [creativeContent, setcreativeContent] = useState<any>(null);

  /*   const getForyouContent = () => {
        let token = localStorage.getItem("token");
        return axiosInstance({
            method: "get",
            url: `/home/foryou`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res: any) => {
            setForyouContent(res.data)

        });
    };

    const getDirectContent = () => {
        let token = localStorage.getItem("token");
        return axiosInstance({
            method: "get",
            url: `/home/direct_order`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res: any) => {
            setdirectContent(res.data)

        });
    };
    const getCustomContent = () => {
        let token = localStorage.getItem("token");
        return axiosInstance({
            method: "get",
            url: `/home/custom_home`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res: any) => {
            setcustomContent(res.data)

        });
    };
    const getCreativeContent = () => {
        let token = localStorage.getItem("token");
        return axiosInstance({
            method: "get",
            url: `/home/creative_home`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res: any) => {
            setcreativeContent(res.data)

        });
    };
    useEffect(() => {
        getForyouContent();
        getDirectContent();
        getCustomContent();
        getCreativeContent();
    }, []); */

  const fetchContent = (endpoint: string, setter: any) => {
    const token = localStorage.getItem("token") || "";

    axiosInstance({
      method: "get",
      url: `/home/${endpoint}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setter(res.data);
      })
      .catch((error) => {
        console.error(`Error fetching ${endpoint}:`, error);
        // Optionally set an error state or notify the user
      });
  };

  useEffect(() => {
    fetchContent("foryou", setForyouContent);
    fetchContent("direct_order", setdirectContent);
    fetchContent("custom_home", setcustomContent);
    fetchContent("creative_home", setcreativeContent);
  }, []);

  useEffect(() => {
    user && !isAdmin && dispatch(getSelectionProducts());
    user && !isAdmin && dispatch(lastActive());
  }, []);

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  const videoSource =
    "https://cdn.videvo.net/videvo_files/video/free/2015-07/small_watermarked/A0405_H1505_H2_807_0064_preview.webm";
  return (
    <>
      <div className="home">
        <Head title="DEMCO - COMMITTED TO SUSTAINABILITY" />
        <Header />
        <DiscoverList />
        {/* Products may interest you */}
        <div id="for-you" style={{ position: "relative" }}>
          {!foryouContent ? (
            <>
              {" "}
              <div className="row  mt-5 mb-5">
                <div className="col-3">
                  {" "}
                  <Skeleton.Avatar active={true} shape={"square"} />
                </div>
                <div className="col-9">
                  {" "}
                  <Skeleton active={true} />
                </div>
              </div>
            </>
          ) : (
            <>
              <Features
                mediaArea={<ProductList />}
                bannerArea={
                  /*
                                                        <BannerInfo bannerInfo={bannerInfosData[0]} type={"for you"}/>
                            */
                  <BannerInfo bannerInfo={foryouContent} type={"for you"} />
                }
                mediaSide="left"
                position={"center"}
              />
            </>
          )}
          {user && !isAdmin && !user.last_login && step === 3 && (
            <Popup
              content="Take a deeper look at the products we suggested exclusively for you based on your preferences"
              next_hash="direct-order"
              position="right"
            />
          )}
        </div>

        {/* Direct Order */}
        {!directContent ? (
          <>
            <div className="row  mt-5 mb-5">
              <div className="col-9">
                {" "}
                <Skeleton active={true} />
              </div>
              <div className="col-3">
                {" "}
                <Skeleton.Avatar active={true} shape={"square"} />
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div id="direct-order" style={{ position: "relative" }}>
              <Features
                mediaArea={
                  <DirectOrderCarousel
                    directContent={directContent}
                    type={"direct-order"}
                  />
                }
                bannerArea={
                  <BannerInfo
                    bannerInfo={directContent}
                    type={"direct orders"}
                  />
                }
                mediaSide="right"
                position={"center"}
              />
              {user && !isAdmin && !user.last_login && step === 4 && (
                <Popup
                  content="Create your desired articles and options in collaboration with you team members, save it for later or place your order in one click"
                  next_hash="creative-station"
                  position="left"
                />
              )}
            </div>
          </>
        )}

        <div id="customization" style={{ position: "relative" }}>
          {!customContent ? (
            <>
              <div className="row  mt-5 mb-5">
                <div className="col-3">
                  {" "}
                  <Skeleton.Avatar active={true} shape={"square"} />
                </div>
                <div className="col-9">
                  {" "}
                  <Skeleton active={true} />
                </div>
              </div>
            </>
          ) : (
            <>
              <Features
                mediaArea={<ProductCustomList customContent={customContent} />}
                bannerArea={
                  <BannerInfo
                    bannerInfo={customContent}
                    type={"customization"}
                  />
                }
                mediaSide="left"
                position={"center"}
              />
            </>
          )}
          {user && !isAdmin && !user.last_login && step === 2 && (
            <Popup
              content="Customize your favorite products, play with colors and styles add your own touch and place your order in one click"
              next_hash="for-you"
              position="left"
            />
          )}
        </div>

        <div id="creativeteam" style={{ position: "relative" }}>
          {!customContent ? (
            <>
              <div className="row  mt-5 mb-5">
                <div className="col-9">
                  {" "}
                  <Skeleton active={true} />
                </div>
                <div className="col-3">
                  {" "}
                  <Skeleton.Avatar active={true} shape={"square"} />
                </div>
              </div>
            </>
          ) : (
            <>
              <Features
                mediaArea={
                  <DirectOrderCarousel
                    directContent={creativeContent}
                    type={"creative"}
                  />
                }
                bannerArea={
                  <BannerInfo
                    bannerInfo={creativeContent}
                    type={"Creative team"}
                  />
                }
                mediaSide="right"
                position={"center"}
              />
            </>
          )}
          {user && !isAdmin && !user.last_login && step === 4 && (
            <Popup
              content="Create your desired articles and options in collaboration with you team members, save it for later or place your order in one click"
              next_hash="creative-station"
              position="left"
            />
          )}
        </div>

        {/* <div className="row home_difference mt-5">

                    <div className="col-12 col-md-5  home_banner_wrapper_difference ">
                      <BannerInfo bannerInfo={creativeContent}/>
                        <BannerInfo bannerInfo={bannerInfosDifference}/>
                    </div>
                    <div className="col-12 col-md-7  home_difference_img  ">

                        <div
                            className="video_wrapper"
                            dangerouslySetInnerHTML={{
                                __html: `
      <video id="videoBGCreativeStation"  webkit-playsinline="true" autoplay="true"    preload="metadata" muted="true"  loop playsinline="true" id="video-id-auto">
      <source src=${process.env.REACT_APP_STORAGE_URL}${creativeContent?.media} type="video/mp4" ></source>
     </video>
     
    `,
                            }}
                        />
                    </div>

                </div>*/}

        <Carousel3D width={width} />

        <LabelList width={width} />
        <div className="label_container">
          <div className="label_title">
            {width >= 768 && <div className="blue_line"></div>}
            <h2 className="our_label text-center">Our certifications</h2>
          </div>

          <Certification />
        </div>
        <Footer />
        <Modal
          centered
          className="onboarding-modal"
          visible={user && !isAdmin && !user.last_login && step === 1}
          bodyStyle={{ padding: "0px" }}
          closable={false}
          width={width < 768 ? "350px" : "650px"}
          footer={null}
          // footer={[
          //   <Button
          //     style={{
          //       borderRadius: "5px",
          //     }}
          //     onClick={() => dispatch(finish())}
          //   >
          //     Skip
          //   </Button>,
          //   <NavHashLink
          //     smooth
          //     to="/home#customization"
          //     onClick={() => dispatch(goNextStep())}
          //     className="ml-3"
          //   >
          //     <Button
          //       style={{
          //         background: "#00468c",
          //         borderColor: "#00468c",
          //         borderRadius: "5px",
          //         color: "white",
          //       }}
          //     >
          //       Continue
          //     </Button>
          //   </NavHashLink>,
          // ]}
        >
          <div
            style={{
              height: width < 768 ? "450px" : "280px",
              display: "grid",
              gridTemplateColumns: width < 768 ? "1fr" : "0.45fr 1fr",
              gridAutoRows: width < 768 ? "1fr" : "auto",
              columnGap: "2rem",
              placeItems: "center",
            }}
            className="p-3 p-md-0"
          >
            <div
              style={{ height: "100%", width: "100%", position: "relative" }}
              className={width < 768 ? "img-onboarding" : undefined}
            >
              <img
                src={onboarding_img}
                style={{
                  position: "absolute",
                  bottom: "0px",
                  left: width < 768 ? "50%" : "0px",
                  width: width < 768 ? "57%" : "120%",
                  transform: width < 768 ? "translateX(-50%)" : "none",
                }}
              />
            </div>
            <div
              className="pt-0 pt-md-2 p-md-2"
              style={{ fontFamily: "Poppins" }}
            >
              <h2
                style={{
                  fontSize: width < 768 ? "1.2rem" : "1.5rem",
                  fontWeight: "bold",
                  color: "#00468c",
                }}
              >
                Welcome to the ID Webshop.
              </h2>
              <p
                className="mb-0 mt-3"
                style={{
                  fontWeight: 500,
                  color: "black",
                  fontSize: width < 768 ? "0.8rem" : "0.9rem",
                }}
              >
                We've put together a step-by-step guide
              </p>
              <p
                className="mb-4"
                style={{
                  fontWeight: 500,
                  color: "black",
                  fontSize: width < 768 ? "0.8rem" : "0.9rem",
                }}
              >
                which covers everything you need to get going
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: width < 768 ? "column-reverse" : "row",
                }}
              >
                <button
                  style={{
                    borderRadius: "5px",
                    border: "1px solid black",
                    background: "white",
                    color: "black",
                    textTransform: "uppercase",
                    flexGrow: width < 768 ? 1 : 0.33,
                  }}
                  className="py-2 mr-0 mr-md-3 mt-2 mt-md-0"
                  onClick={() => dispatch(finish())}
                >
                  Skip
                </button>
                <NavHashLink
                  smooth
                  to="/home#customization"
                  onClick={() => dispatch(goNextStep())}
                  style={{
                    borderRadius: "5px",
                    border: "1px solid #00468c",
                    background: "#00468c",
                    color: "white",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    flexGrow: width < 768 ? 1 : 0.66,
                    textAlign: "center",
                    textDecoration: "none",
                  }}
                  className="py-2"
                >
                  Show me
                </NavHashLink>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
