function MooveTool() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        id="Path_3390"
        data-name="Path 3390"
        d="M2.236-.364a.328.328,0,0,0,0-.464l-.193-.193a.328.328,0,0,0-.464,0L.465.106H.438V-4.812H5.356v.027L4.229-3.671a.328.328,0,0,0,0,.464l.193.193a.328.328,0,0,0,.464,0l2.017-2a.328.328,0,0,0,0-.464l-2.017-2a.328.328,0,0,0-.464,0l-.193.193a.328.328,0,0,0,0,.464L5.356-5.715v.027H.438v-4.919H.465L1.579-9.479a.328.328,0,0,0,.464,0l.193-.193a.328.328,0,0,0,0-.464l-2-2.017a.328.328,0,0,0-.464,0l-2,2.017a.328.328,0,0,0,0,.464l.193.193a.328.328,0,0,0,.464,0l1.114-1.127h.027v4.919H-5.356v-.027l1.127-1.114a.328.328,0,0,0,0-.464l-.193-.193a.328.328,0,0,0-.464,0l-2.017,2a.328.328,0,0,0,0,.464l2.017,2a.328.328,0,0,0,.464,0l.193-.193a.328.328,0,0,0,0-.464L-5.356-4.785v-.027H-.437V.106H-.465L-1.579-1.021a.328.328,0,0,0-.464,0l-.193.193a.328.328,0,0,0,0,.464l2,2.017a.328.328,0,0,0,.464,0Z"
        transform="translate(7 12.25)"
        fill="#00468C"
      />
    </svg>
  );
}

export default MooveTool;
