import { Avatar } from "antd";
import moment from "moment";

const ClientConnected = ({ inPage, notif, position }: any) => {
  return inPage ? (
    <div className="notif_item_page p-2 my-2">
      <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center icon_notif_container">

        </div>
        <h2 className="h4_medium m-0">
          {`Your client ${notif.data.name} from ${notif.data.company.name} is connected`}
        </h2>
      </div>
      <p className="selection_date m-0">{moment(notif.created_at).fromNow()}</p>
    </div>
  ) : (
    <div
      className="notif_added_prod_container my-2 border p-4"
      style={{
        borderRadius: "8px",
        background: position ? "#e5f1fa" : "white",
        transition: "all 5s ease-in-out",
      }}
    >
      <article className="notif_selection order_changes w-100">
        <div>

        </div>
        <div className="notif_details" style={{ flex: 1 }}>
          <h2 className="h4_medium">
            {`Your client ${notif.data.name} from ${notif.data.company.name} is connected`}
          </h2>
          <div className="d-flex justify-content-between align-items-center flex-row-reverse">
            <p
              className="selection_date mb-0"
              style={{ fontSize: "0.7rem", fontWeight: 400 }}
            >
              {moment(notif.created_at).fromNow()}
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default ClientConnected;
