import { AnimatePresence, motion } from "framer-motion";
import { handleLeftDrawer } from "../../actions/actionCreators";
import OutsideAlerter from "./OutSideAlerter";
import { useDispatch, useSelector } from "react-redux";
import logo_green from "../../img/logo_green.svg";
import {Link, NavLink, useHistory, useParams} from "react-router-dom";
import { Collapse, Skeleton, Divider } from "antd";
import { useEffect, useState } from "react";
import {
  closeSideMenuExtension,
  getCategoriesUnderGender,
  getCollections,
  getGenders,
  getGendersUnderfabric,
} from "../../actions/left-menu-actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { MenuCategory } from "./MenuCategory";
import PlusIcon from "../../icons/PlusIcon";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import "./SideMenu.scss";
const { Panel } = Collapse;


interface Props {}

const variants = {
  enter: { x: -362 },
  center: { x: 0 },
  exit: { x: -362 },
};

const MenuItemGender = ({
  item,
  setGender,
  setShowMenuExt,
  menuchoice,
}: any) => {
  const dispatch = useDispatch();
  return (
    <p
      className="menu_element_mobile"
      onClick={() => {
        dispatch(getCategoriesUnderGender(item.id, menuchoice));
        setGender(item);
        setShowMenuExt(true);
      }}
    >
      {item.name}
    </p>
  );
};

export const MobileSideMenu = (props: Props) => {
  const [gender, setGender] = useState<any>("");
  const [menuchoice, setMenuChoise] = useState("denim");
  const [showMenuExt, setShowMenuExt] = useState<boolean | string>(false);
  const [subDataToShow, setSubDataToShow] = useState<any>("");
  const [subElements, setsubElements] = useState({
    parent: null,
    children: [],
  });
  const [subElementChildren, setsubElementsChildren] = useState({
    parent: null,
    children: [],
  });
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const leftMenu = useSelector((state: any) => state.leftMenu);
  const { collections, isLoadingCollections } = useSelector(
    (state: any) => state.leftMenu
  );
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const [isOpened, setIsOpened] = useState(false);
  function toggle() {
    setIsOpened((wasOpened) => !wasOpened);
  }

  const CollectionSubElements = (collection: any) => {
    // e.preventDefault();
    // e.stopPropagation();
    var tab: any = [];
    collections.map((el: any) => {
      if (collection.id === el.collection_id) {
        tab.push(el);
      }
    });
    setsubElements({ parent: collection.id, children: tab });
  };
  const CollectionSubElementChildren = (e: any, collection: any) => {
    e.preventDefault();
    e.stopPropagation();
    var tab: any = [];
    collections.map((el: any) => {
      if (collection.id === el.collection_id) {
        tab.push(el);
      }
    });
    setsubElementsChildren({ parent: collection.id, children: tab });
  };

  useEffect(() => {
    setMenuChoise(user.fabric_type === "knit" ? "knit" : "denim");
  }, []);

  useEffect(() => {
    if (!leftMenu[menuchoice + "Genders"]) {
      dispatch(getGendersUnderfabric(menuchoice));
      dispatch(getGenders());
      dispatch(getCollections());
    }
  }, [menuchoice]);

  const uniqByKeepLast = (a: any, key: any) => {
    return [...new Map(a.map((x: any) => [key(x), x])).values()];
  };

  const [collectionSubElmentsChildrens, setCollectionSubElmentsChildrens] =
    useState<any>({ parent: "", childrens: [] });

  const [collectionSubElments, setCollectionSubElments] = useState<any>("");

  console.log(collections);

  return (
    <OutsideAlerter>
      <>
        <AnimatePresence initial={true} exitBeforeEnter={true}>
          <motion.div
            className="side_menu"
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ type: "tween" }}
          >
            <Link to="/home" onClick={() => dispatch(handleLeftDrawer())}>
              <img src={logo_green} alt="" className="logo_side_menu" />
            </Link>
            {!showMenuExt ? (
              <>
              <div
                  className="asidemenumobile"
              >
                <Collapse
                  ghost
                  expandIconPosition={"right"}
                  expandIcon={(iconProp) =>
                    iconProp.isActive ? (
/*
                      <FontAwesomeIcon  className={"prodcutcollapse"} icon={faMinus} />
*/                <MinusOutlined />

                    ) : (
                        <PlusOutlined />
                   /*   <FontAwesomeIcon className={"prodcutcollapse"}  icon={faPlus} />*/
                    )
                  }
                >
                  <Panel header="Products" key="1" >
                    <div className="denim_knit">
                      <p
                        className={
                          menuchoice === "denim"
                            ? "denim_knit_text_mobile selected_menu"
                            : "denim_knit_text_mobile"
                        }
                        onClick={() => {
                          setMenuChoise("denim");

                          dispatch(closeSideMenuExtension());
                          setShowMenuExt(false);
                        }}
                      >
                        DENIM
                      </p>
                      <div className="denim_knit_seperator"></div>
                      <p
                        className={
                          menuchoice === "knit"
                            ? "denim_knit_text_mobile selected_menu"
                            : "denim_knit_text_mobile"
                        }
                        onClick={() => {
                          setMenuChoise("knit");
                          dispatch(closeSideMenuExtension());
                          setShowMenuExt(false);
                        }}
                      >
                        KNIT
                      </p>
                    </div>
                    <div className="menu_elements mobile_menu_elements">
                      <p
                        className="menu_element_mobile menu_element_new"
                        onClick={() => {
                          setShowMenuExt("New");
                          // dispatch(closeSideMenuExtension());
                          dispatch(handleLeftDrawer());
                        }}
                      >
                        <Link
                          to={
                            menuchoice === "knit" ? `/new/knit` : `/new/denim`
                          }
                        >
                          New
                        </Link>
                      </p>
                      {leftMenu.isLoadingGenders ||
                      !leftMenu[menuchoice + "Genders"]
                        ? Array.from(
                            { length: 1 },
                            (_, index) => index + 1
                          ).map((_, i: number) => (
                            <Skeleton active={true} key={i} />
                          ))
                        : leftMenu[menuchoice + "Genders"] && (
                         /*   <>
                              <MenuItemGender
                                item={leftMenu[menuchoice + "Genders"][1]}
                                setGender={setGender}
                                setShowMenuExt={setShowMenuExt}
                                menuchoice={menuchoice}
                              />
                              <MenuItemGender
                                item={leftMenu[menuchoice + "Genders"][0]}
                                setGender={setGender}
                                setShowMenuExt={setShowMenuExt}
                                menuchoice={menuchoice}
                              />
                              <MenuItemGender
                                item={leftMenu[menuchoice + "Genders"][2]}
                                setGender={setGender}
                                setShowMenuExt={setShowMenuExt}
                                menuchoice={menuchoice}
                              />
                            </>*/
                          <>
                            <p
                                className="menu_element_mobile menu_element_kids"
                                onClick={() => {
                                  dispatch(handleLeftDrawer());
                                }}
                            >
                              <Link to="/product/Knit/Women/2/TOPS%20&%20T-SHIRTS/12">
                                {leftMenu[menuchoice + "Genders"][1].name}
                              </Link>
                            </p>
                            <p
                                className="menu_element_mobile menu_element_kids"
                                onClick={() => {
                                  dispatch(handleLeftDrawer());
                                }}
                            >
                              <Link to="/product/knit/Men/1/POLO%20SHIRTS/20">
                                {leftMenu[menuchoice + "Genders"][0].name}
                              </Link>
                            </p>
                            <p
                                className="menu_element_mobile menu_element_kids"
                                onClick={() => {
                                  dispatch(handleLeftDrawer());
                                }}
                            >
                              <Link to="/product/knit/Unisex/3/PANTS%20&%20SHORTS/6">
                                {leftMenu[menuchoice + "Genders"][2]?.name}
                              </Link>
                            </p>
                          </>
                          )}
                      {menuchoice === "denim" && (
                      /*  <p
                          className="menu_element_mobile"
                          onClick={() => {
                            dispatch(getCategoriesUnderGender(4, menuchoice));
                            setGender(leftMenu.allGenders.data[3]);
                            setShowMenuExt(true);
                          }}
                        >
                          Kids
                        </p>*/
                          <>
                            <p className="menu_element_mobile" onClick={toggle}>
                              Kids
                            </p>

                            {isOpened && (
                                <>
                                  <p
                                      className="menu_element_mobile menu_element_kids"
                                      style={{ marginLeft: "25px" }}
                                      onClick={() => {
                                        //setShowMenuExt("New");
                                        dispatch(handleLeftDrawer());
                                      }}
                                  >
                                    <Link to="/product/denim/Boys/5/JEANS/1">Boys</Link>
                                  </p>
                                  <p
                                      className="menu_element_mobile menu_element_kids"
                                      style={{ marginLeft: "25px" }}
                                      onClick={() => {
                                        //setShowMenuExt("New");
                                        dispatch(handleLeftDrawer());
                                      }}
                                  >
                                    <Link to="/product/denim/Girls/6/JEANS/1">Girls</Link>
                                  </p>
                                </>
                            )}
                          </>
                      )}
                      <p
                        className="menu_element_mobile menu_element_collection"
                        onClick={() => {
                          setShowMenuExt(true);
                          setGender("collection");
                        }}
                      >
                        Collection
                      </p>
                    </div>
                  </Panel>
                </Collapse>
              </div>
                <Divider />

                <div className="menu_list_mobile menu_elements">
                  {user && !isAdmin && (
                      <>
                        <Link
                            to={
                              user && user.fabric_type === "denim"
                                  ? `/for-you/denim`
                                  : "/for-you/knit"
                            }
                            className="menu_element_mobile"
                            onClick={() => dispatch(handleLeftDrawer())}
                        >
                          For you
                        </Link>
                        <Divider/>
                      </>
                  )}

                  <>
                    <Link
                        to="/responsible"
                        className="menu_element_mobile"
                        onClick={() => dispatch(handleLeftDrawer())}
                    >
                      Our responsible choice
                    </Link>
                    <Divider/>
                  </>
                  {user && isAdmin && user.type !== "agent" && (
                      <>
                        <Link
                            className="menu_element_mobile"
                            to="/all-clients"
                            onClick={() => dispatch(handleLeftDrawer())}
                        >
                          Orders
                        </Link>
                        <Divider/>
                      </>
                  )}
                  {user && isAdmin && user.type === "agent" && (
                      <>
                        <Link
                            className="menu_element_mobile"
                            onClick={() => dispatch(handleLeftDrawer())}
                            to="/library?fabric_type=denim&page=1"
                        >
                          DEMCO’s library
                        </Link>
                        <Divider/>
                      </>
                  )}
                  {((user && user.type === "commercial") || !isAdmin) && (
                      <>
                        <Link
                            to="/direct-order"
                            className="menu_element_mobile"
                            onClick={() => dispatch(handleLeftDrawer())}
                        >
                          Direct order
                        </Link>
                        <Divider/>
                      </>
                  )}
                  <Link
                      to="/creative-station"
                      className="menu_element_mobile"
                      onClick={() => dispatch(handleLeftDrawer())}
                  >
                    Creative station
                  </Link>
                  {user && isAdmin && <>
                  <p
                      style={{position: "absolute", bottom: 40, right: 20}}
                      onClick={() => {
                        //setShowMenuExt("New");
                        dispatch(handleLeftDrawer());
                      }}
                  >

                    <Link
                        //    href="/add-new-product"
                        to="/list-products"
                        className="add-new-product-button-mobile"
                    >
                     +
                    </Link>
                  </p>
                  </>}
                </div>
              </>
            ) : (
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                  <header
                      className="mobile_menu_extension_header"
                      onClick={() => setShowMenuExt(false)}
                  >
                    <FontAwesomeIcon icon={faArrowLeft}/>
                    <p>{gender.name || "Collection"}</p>
                  </header>

                  <main className="mobile_menu_extension_body">
                  <div className="sub_menu_elements">
                    {gender === "collection"
                      ? collections && isLoadingCollections
                        ? Array.from(
                            { length: 1 },
                            (_, index) => index + 1
                          ).map((_, i) => (
                            <Skeleton
                              active={true}
                              key={i}
                              title={false}
                              paragraph={{ rows: 6 }}
                            />
                          ))
                        : collections
                            .sort((a: any, b: any) =>
                              a.sort_order > b.sort_order ? 1 : -1
                            )
                            .filter((el: any) => !el.collection_id)
                            .map((el: any, i: number) => (
                              <div
                                key={i}
                                className="sub_menu_element"
                                style={{
                                  margin:
                                    el.children.length > 0
                                      ? "10px 0"
                                      : "15px 0",
                                }}
                              >
                                {el.children.length === 0 ? (
                                  <Link
                                    className="sub_menu_element_val"
                                    to={
                                      el.children.length === 0
                                        ? menuchoice === "denim"
                                          ? `/collection/${el.id}/denim`
                                          : `/collection/${el.id}/knit`
                                        : { hash: "#" }
                                    }
                                    onClick={() => {
                                      if (el.children.length > 0) {
                                        // setCollectionSubElments(el.children);
                                        CollectionSubElements(el);
                                        setCollectionSubElmentsChildrens({
                                          parent: "",
                                          childrens: [],
                                        });
                                      } else {
                                        dispatch(handleLeftDrawer());
                                      }
                                    }}
                                    style={{ fontSize: "1rem" }}
                                  >
                                    {el.name}
                                  </Link>
                                ) : (
                                  <Collapse
                                    collapsible="header"
                                    ghost
                                    accordion
                                    className="submenu-mobile-collapse mb-1"
                                    expandIconPosition="right"
                                    expandIcon={({ isActive }) =>
                                      isActive ? (
                                        <MinusOutlined
                                          style={{ paddingTop: "7px" }}
                                        />
                                      ) : (
                                        <PlusOutlined
                                          style={{ paddingTop: "7px" }}
                                        />
                                      )
                                    }
                                  >
                                    <Panel
                                      header={
                                        <span style={{ fontSize: "1rem" }}>
                                          {el.name}
                                        </span>
                                      }
                                      key={el.id}
                                      style={{ padding: "0px" }}
                                    >
                                      {el.children
                                        .sort((a: any, b: any) =>
                                          a.sort_order > b.sort_order ? 1 : -1
                                        )
                                        .map((child: any) => {
                                          return collections.find(
                                            (x: any) =>
                                              x.collection_id === child.id
                                          ) ? (
                                            <Collapse
                                              key={child.id}
                                              ghost
                                              accordion
                                              className="submenu-mobile-collapse mb-1 mt-1"
                                              expandIconPosition="right"
                                              expandIcon={({ isActive }) =>
                                                isActive ? (
                                                  <MinusOutlined
                                                    style={{
                                                      paddingTop: "7px",
                                                    }}
                                                  />
                                                ) : (
                                                  <PlusOutlined
                                                    style={{
                                                      paddingTop: "7px",
                                                    }}
                                                  />
                                                )
                                              }
                                            >
                                              <Panel
                                                header={
                                                  <span
                                                    style={{ fontSize: "1rem" }}
                                                  >
                                                    {child.name}
                                                  </span>
                                                }
                                                key={child.id}
                                              >
                                                {collections
                                                  .filter(
                                                    (x: any) =>
                                                      x.collection_id ===
                                                      child.id
                                                  )
                                                  .map((xx: any) => (
                                                    <Link
                                                      to={
                                                        menuchoice === "denim"
                                                          ? `/collection/${xx.id}/denim`
                                                          : `/collection/${xx.id}/knit`
                                                      }
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.85)",
                                                      }}
                                                      onClick={() =>
                                                        dispatch(
                                                          handleLeftDrawer()
                                                        )
                                                      }
                                                    >
                                                      <p
                                                        style={{
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        {xx.name}
                                                      </p>
                                                    </Link>
                                                  ))}
                                              </Panel>
                                            </Collapse>
                                          ) : (
                                            <Link
                                              to={
                                                menuchoice === "denim"
                                                  ? `/collection/${child.id}/denim`
                                                  : `/collection/${child.id}/knit`
                                              }
                                              style={{
                                                fontSize: "1.1rem",
                                                color: "rgba(0, 0, 0, 0.85)",
                                              }}
                                              className="mb-2"
                                              onClick={() =>
                                                dispatch(handleLeftDrawer())
                                              }
                                            >
                                              <p
                                                style={{ whiteSpace: "nowrap" }}
                                              >
                                                {child.name}
                                              </p>
                                            </Link>
                                          );
                                        })}
                                    </Panel>
                                  </Collapse>
                                )}
                                {/* {el.children.length > 0 && <PlusIcon />} */}
                              </div>
                            ))
                      : // uniqByKeepLast(collections, (k: any) => k.name)
                      // .filter((k: any) => !k.collection_id)
                      // .map((el: any, i: number) => {
                      //   return (
                      //     <div
                      //       className="category_link_container"
                      //       key={i}
                      //     >
                      //       <p
                      //         key={i}
                      //         className="categories_links"
                      //         style={
                      //           subElements.parent === el.id
                      //             ? { marginBottom: "10px" }
                      //             : {}
                      //         }
                      //       >
                      //         <span
                      //           onClick={() => {
                      //             history.push(
                      //               params.fabric === "denim"
                      //                 ? `/collection/${el.id}/denim`
                      //                 : `/collection/${el.id}/knit`
                      //             );
                      //             dispatch(handleLeftDrawer());
                      //           }}
                      //         >
                      //           {el.name.toUpperCase()}
                      //         </span>

                      //         {el.children.length > 0 &&
                      //           (subElements.parent === el.id ? (
                      //             <DownOutlined
                      //               onClick={(e: any) => {
                      //                 e.preventDefault();
                      //                 e.stopPropagation();
                      //                 setsubElements({
                      //                   parent: null,
                      //                   children: [],
                      //                 });
                      //                 setsubElementsChildren({
                      //                   parent: null,
                      //                   children: [],
                      //                 });
                      //               }}
                      //             />
                      //           ) : (
                      //             <RightOutlined
                      //               onClick={(e: any) =>
                      //                 CollectionSubElements(e, el)
                      //               }
                      //             />
                      //           ))}
                      //       </p>
                      //       {subElements.parent === el.id &&
                      //         subElements.children.length > 0 && (
                      //           <div className="sub_elements">
                      //             {subElements.children &&
                      //               subElements.children.map(
                      //                 (el: any) => (
                      //                   <div className="sub_element_container">
                      //                     <p
                      //                       className="sub_element"
                      //                       onClick={() => {
                      //                         history.push(
                      //                           params.fabric === "denim"
                      //                             ? `/collection/${el.id}/denim`
                      //                             : `/collection/${el.id}/knit`
                      //                         );
                      //                         dispatch(
                      //                           handleLeftDrawer()
                      //                         );
                      //                       }}
                      //                     >
                      //                       <span>{el.name}</span>
                      //                       {el.children.length > 0 &&
                      //                         (subElementChildren.parent ===
                      //                         el.id ? (
                      //                           <DownOutlined
                      //                             onClick={(e: any) => {
                      //                               e.preventDefault();
                      //                               e.stopPropagation();
                      //                               setsubElementsChildren(
                      //                                 {
                      //                                   parent: null,
                      //                                   children: [],
                      //                                 }
                      //                               );
                      //                             }}
                      //                           />
                      //                         ) : (
                      //                           <RightOutlined
                      //                             onClick={(e: any) =>
                      //                               CollectionSubElementChildren(
                      //                                 e,
                      //                                 el
                      //                               )
                      //                             }
                      //                           />
                      //                         ))}
                      //                     </p>
                      //                     {subElementChildren.parent ===
                      //                       el.id &&
                      //                       subElementChildren.children
                      //                         .length > 0 && (
                      //                         <div className="sub_element_children">
                      //                           {subElementChildren.children &&
                      //                             subElementChildren.children.map(
                      //                               (el: any) => (
                      //                                 <div className="sub_element_child_container">
                      //                                   <p className="sub_element_child">
                      //                                     <span
                      //                                       onClick={() => {
                      //                                         history.push(
                      //                                           params.fabric ===
                      //                                             "denim"
                      //                                             ? `/collection/${el.id}/denim`
                      //                                             : `/collection/${el.id}/knit`
                      //                                         );
                      //                                         dispatch(
                      //                                           handleLeftDrawer()
                      //                                         );
                      //                                       }}
                      //                                     >
                      //                                       {el.name}
                      //                                     </span>
                      //                                   </p>
                      //                                 </div>
                      //                               )
                      //                             )}
                      //                         </div>
                      //                       )}
                      //                   </div>
                      //                 )
                      //               )}
                      //           </div>
                      //         )}
                      //     </div>
                      //   );
                      // })
                      gender.name && gender.name === "Kids"
                      ? leftMenu.allGenders.data
                          .filter(
                            (el: any) => el.name.toLowerCase() === "kids"
                          )[0]
                          .childs.map((el: any, i: number) => (
                            <div key={i} className="sub_menu_element">
                              <Link
                                className="sub_menu_element_val"
                                to={{ hash: "#" }}
                                onClick={() => {
                                  setSubDataToShow(el);
                                  // setGender(el);
                                  dispatch(
                                    getCategoriesUnderGender(el.id, menuchoice)
                                  );
                                }}
                              >
                                {el.name}
                              </Link>
                              {/* <img src={plus} alt="" className="plusIcon" /> */}
                              <FontAwesomeIcon
                                icon={faPlus}
                                className="plusIcon"
                              />
                            </div>
                          ))
                      : leftMenu.isLoadingCategories
                      ? Array.from({ length: 1 }, (_, index) => index + 1).map(
                          (_) => <Skeleton active={true} />
                        )
                      : uniqByKeepLast(
                          leftMenu.categories,
                          (k: any) => k.name
                        ).map((el: any, i: number) => (
                          <MenuCategory
                            key={i}
                            subSubElements={el}
                            setSubDataToShow={setSubDataToShow}
                            menuchoice={menuchoice}
                            gender={gender}
                          />
                        ))}
                  </div>
                  <div className="sub_sub_menu_elements">
                    {gender.name === "Kids" && leftMenu.isLoadingCategories
                      ? Array.from({ length: 1 }, (_, index) => index + 1).map(
                          (_, i: number) => <Skeleton active={true} key={i} />
                        )
                      : gender.name === "Kids" &&
                        leftMenu.categories &&
                        leftMenu.categories.map((el: any, i: number) => (
                          <Link
                            key={i}
                            // to="#"
                            to={`/product/${menuchoice}/${subDataToShow.name}/${subDataToShow.id}/${el.name}/${el.id}`}
                            className="sub_sub_menu_element"
                            onClick={() => dispatch(handleLeftDrawer())}
                          >
                            {el.name}
                          </Link>
                        ))}
                  </div>
                </main>
              </motion.div>
            )}

          </motion.div>
        </AnimatePresence>

      </>
    </OutsideAlerter>
  );
};
