import { Switch } from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changeOneSettingStatusAdmin,
  changeStatusNotifSettings,
  getMyNotifSettingsAdmin,
} from "../actions/notifications-actions/actions";
import "../sass/NotifSettings.scss";
import logoGreen from "../img/logo_green.svg";
import Head from "../components/Head";
import {Spinner} from "../components/Spinner";

interface Props {}

export const NotifSettingsAdmin = (props: Props) => {
  const [options1, setOptions1] = useState<any>([]);
  const [options2, setOptions2] = useState<any>([]);
  const [activateAll1, setActivateAll1] = useState(true);
  const [activateAll2, setActivateAll2] = useState(true);
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const {
    getSettingsLoading,
    settings,
    changeAllSettingsLoading,
    notifToUpdate,
    changeOneNotifLoading,
  } = useSelector((state: any) => state.notifications);
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(getMyNotifSettingsAdmin());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (settings) {
      let filteredSettings = settings.filter(
        (el: any) =>
          el.notif !== "user_id" &&
          el.notif !== "created_at" &&
          el.notif !== "updated_at" &&
          el.notif !== "id" &&
          el.notif !== "new_products_added" &&
          el.notif !== "order_progress" &&
          el.notif !== "last_selection" &&
          el.notif !== "direct_order_changes" &&
          el.notif !== "direct_order_sending" &&
          el.notif !== "collaboration_requests" &&
          el.notif !== "new_collaborators_added" &&
          el.notif !== "news_letter" &&
          el.notif !== "own_updates" &&
          el.notif !== "limited_edition_product"
      );
      let firstGroup = filteredSettings;
      /*         .slice(0, 1)
        .concat(filteredSettings[12])
        .concat(filteredSettings.slice(1, 9)); */
      let secondGroup = filteredSettings.slice(9, 13);
      setOptions1(firstGroup);
      setOptions2(secondGroup);
      setActivateAll1(firstGroup.some((el: any) => el?.status));
      setActivateAll2(secondGroup.some((el: any) => el?.status));
    }
    // eslint-disable-next-line
  }, [settings]);

  const toggleAll1 = () => {
    if (activateAll1) {
      let deactivated = options1
        .filter((el: any) => el.status)
        .map((el: any) => el.notif);
      dispatch(changeStatusNotifSettings(deactivated));
      setActivateAll1(!activateAll1);
    } else {
      let activated = options1
        .filter((notif: any) => !notif.status)
        .map((el: any) => el.notif);
      dispatch(changeStatusNotifSettings(activated));
      setActivateAll1(!activateAll1);
    }
  };

  const toggleAll2 = () => {
    if (activateAll2) {
      let deactivated = options2
        .filter((notif: any) => notif.status)
        .map((el: any) => el.notif);
      dispatch(changeStatusNotifSettings(deactivated));
      setActivateAll2(!activateAll2);
    } else {
      let activated = options2
        .filter((notif: any) => !notif.status)
        .map((el: any) => el.notif);
      dispatch(changeStatusNotifSettings(activated));
      setActivateAll2(!activateAll2);
    }
  };

  return getSettingsLoading ? (
      <div className="notification_settings">
        <Spinner></Spinner>
      </div>

  ) : (
      user && (
          <div className="notification_settings">
            <div className="account_header px-0 px-md-5">
          <Head title="Notification Settings" />
          <img
            src={
              isAdmin
                ? user.avatar
                  ? process.env.REACT_APP_STORAGE_URL + user.avatar
                  : logoGreen
                : user.logo
                ? process.env.REACT_APP_STORAGE_URL + user.logo
                : process.env.REACT_APP_STORAGE_URL + user.company.logo
            }
            alt="brand logo"
            className="company_logo"
          />
              <div className="account_header_text">
                {/*  <p className="user_info">
              {!isAdmin ? user.company.name : "DEMCO"}
              <span className="personnal_info"> / Notification Settings</span>
            </p>
            <p className="notif_header_msg">
              Decide what and when you want to receive as notifications from our
              platform.
            </p>*/}

                <p className="user_info">
                  {user.name }
                </p>
                <p className="notif_header_msg">
                  Choose what notifications you want to receive and when from our platform.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 px-md-5 px-0">
                <div className="settings_wrapper_header">
                  <h2 className="h2_subtitle_blue" style={{textTransform:"uppercase"}}>Notifications</h2>
                </div>
              </div>
            </div>
        {/*   <div className="row">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <div className="settings_wrapper px-md-5 px-0">
              <div className="settings_wrapper_body">
       
                {options1.map((el: any) => (
                    <>
                    {(el &&  el?.name !=="ok") &&  
                  <div key={el?.name} className="settings_wrapper_option my-2">
                    <label
                      style={{ opacity: el?.checked ? 1 : 0.6 }}
                      onClick={() => dispatch(changeOneSettingStatusAdmin(el.notif))}
                    >
                      {el?.name}
                    </label>
                    <Switch
                      loading={
                        (changeOneNotifLoading && notifToUpdate === el.notif) ||
                        changeAllSettingsLoading
                      }
                      defaultChecked
                      checked={el?.status}
                      onChange={() =>
                        dispatch(changeOneSettingStatusAdmin(el.notif))
                      }
                    />
                  </div>
                  }
                  </>
                ))}
              </div>
            </div>
          </div>
        
        </div> */}
        <div className="row">
          <div className="col-12 px-0 px-md-2">
            <div className="settings_wrapper px-md-5 px-0">
              <div className="settings_wrapper_body">
                {/*   <div className="settings_wrapper_body_title mb-2">
                  <h3>Send me a notification:</h3>
                  
                  <p>IDWebshop</p>
                  <p>
                  Email
                  </p>
                </div>
               {options1.map((el: any) => (
                  <div key={el.name} className="settings_wrapper_option my-2">
                    {console.log(el)}
                    <label
                      style={{ opacity: el.checked ? 1 : 0.6 }}
                    //  onClick={() => dispatch(changeOneSettingStatus(el.notif))}
                    >
                      {el.name}
                    </label>
                    <Switch
                      loading={
                        (changeOneNotifLoading && notifToUpdate === el.notif) ||
                        changeAllSettingsLoading
                      }
                      defaultChecked
                      checked={el.status}
                      onChange={() =>
                        dispatch(changeOneSettingStatus(el.notif))
                      }
                    />
                     <Switch
                      loading={
                        (changeOneNotifLoading && notifToUpdate === el.notif+'_mail') ||
                        changeAllSettingsLoading
                      }
                      defaultChecked
                      checked={el.status_mail}
                      onChange={() =>
                        dispatch(changeOneSettingStatus(el.notif+'_mail' ))
                      }
                    /> 
                  </div>
                ))} */}

                <div className="col-12">
                  <div className="  row ">
                    <div className="col-6">
                      <h4>
                        {" "}
                        <label className={"header_table_notification"}>Send me a notification:</label>
                      </h4>
                    </div>
                    <div className="col-3">
                      <label  className={"header_table_notification"}>IDWebshop</label>
                    </div>
                    <div className="col-3 text-right">
                      <label className={"header_table_notification"}>Email</label>
                    </div>
                  </div>
                  {options1.map((el: any) => (
                    <>
                      <div key={el.name} className="row my-2">
                        <div className="col-6">
                          <label
                            style={{ opacity: el.checked ? 1 : 0.6 }}
                            //  onClick={() => dispatch(changeOneSettingStatus(el.notif))}
                            className={"body_table_notification"}
                          >
                            {el.name}
                          </label>
                        </div>
                        <div className="col-3">
                          <Switch
                            loading={
                              (changeOneNotifLoading &&
                                notifToUpdate === el.notif) ||
                              changeAllSettingsLoading
                            }
                            defaultChecked
                            checked={el.status}
                            onChange={() =>
                              dispatch(changeOneSettingStatusAdmin(el.notif))
                            }
                          />
                        </div>
                        <div className="col-3 text-right">
                          <Switch
                            loading={
                              (changeOneNotifLoading &&
                                notifToUpdate === el.notif + "_mail") ||
                              changeAllSettingsLoading
                            }
                            defaultChecked
                            checked={el.status_mail}
                            onChange={() =>
                              dispatch(
                                changeOneSettingStatusAdmin(el.notif + "_mail")
                              )
                            }
                          />
                        </div>
                      </div>
                      <hr style={{ width: "100%" }} />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
