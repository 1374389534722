import { useState, useEffect } from "react";
import "./FolderMiniature.scss";
import Folders from "../../icons/Folders";
import { Droppable, Draggable } from "react-beautiful-dnd";

import { useDispatch, useSelector } from "react-redux";
import {
  getOneFolder,
  clearRightSideFolder,
  clearSelectedFolders,
  clearSelectedProducts,
  archiveFolder,
  clearOneFolder,
} from "../../actions/folders-actions/actions";
import { deselectSelection } from "../../actions/myselection-actions/actions";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "antd";
import Trash from "../../icons/Trash";
import folderIcon from "../../img/folderIcon.png";

export interface IFolderMiniatureProps {
  folder?: any;
  setfolder: (folderName: string | undefined) => void;
  subFolderId: any;
  setsubfolders: (t: any) => void;
  setParentFolderId: (t: any) => void;
}

export default function FolderMiniature({
  folder,
  setsubfolders,
}: IFolderMiniatureProps) {
  const dispatch = useDispatch();
  const folders = useSelector((state: any) => state.folders);
  const [width, setWidth] = useState(window.innerWidth);
  const [nbrsubfolders, setnbrsubfolders] = useState(0);
  // const [subFolderId, setsubFolderId] = useState(subFolder);
  useEffect(() => {
    setWidth(window.innerWidth);
  }, [window.innerWidth]);
  useEffect(() => {
    setnbrsubfolders(
      folders.folders.filter(
        (el: any) => el.parent_id === folder.id && !el.is_archived
      ).length
    );
  }, []);
  useEffect(() => {
    if (folders.folders) {
      setsubfolders([]);
    }
  }, [
    folders.msg,
    folders.folders,
    folders.folderUpdated,
    folders.changingRoot,
  ]);

  //await setParentFolderId(parentFodlerId);

  const getListStyle = (isDraggingOver: boolean) => ({
    borderColor: isDraggingOver ? "#4ed578" : "#c8d4f0",
  });
  /*   if (folder && folder.id) {
    return (
      <Droppable
        droppableId={`droppable-minature${folder.id}`}
        direction="vertical"
      >
        {(provided, snapshot) => (
          <Draggable
            key={folder.id}
            draggableId={`draggable-folder${folder.id}`}
            index={folder.id}
            disableInteractiveElementBlocking
          >
            {(providedDrag, snapshotDrag) => (
              <div
                ref={providedDrag.innerRef}
                {...providedDrag.draggableProps}
                {...providedDrag.dragHandleProps}
              >
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={
                    folders.oneFolder.id === folder.id
                      ? "folder_miniature folder_miniature_selected "
                      : "folder_miniature"
                  }
                  onClick={() => {
                    dispatch(clearSelectedFolders());
                    dispatch(deselectSelection());
                    dispatch(clearRightSideFolder());
                    folder.name && dispatch(getOneFolder(folder.id));
                    dispatch(clearSelectedProducts());
                  }}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <div className="folder_miniature_header">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {folder.folders_count ? (
                        <Folders className="folders_icon" />
                      ) : (
                        // <Folder className="folders_icon" />
                        <img src={folderIcon} alt="" />
                      )}
                      <h2 className="folder_name">{folder.name}</h2>
                    </div>

                    <Popover
                      placement="right"
                      content={() => (
                        <div className="popover_archive_folder">
                          <p
                            onClick={() => {
                              dispatch(archiveFolder(folder.id));
                              dispatch(clearSelectedFolders());
                              if (folder.id === folders.oneFolder.id) {
                                dispatch(clearOneFolder());
                              }
                              if (folder.id === folders.rightSideFolder.id) {
                                dispatch(clearRightSideFolder());
                              }
                            }}
                          >
                            <Trash /> Archive
                          </p>
                        </div>
                      )}
                      trigger="click"
                    >
                      <button
                        className="article_popover_btn"
                        onClick={(event) => event.stopPropagation()}
                      >
                        <FontAwesomeIcon
                          icon={faEllipsisH}
                          opacity={0.7}
                          color={"#00468c"}
                        />
                      </button>
                    </Popover>
                  </div>
                  <p className="paragraphe folder_miniature_description">
                    <span>Contains {nbrsubfolders} subfolders - </span>
                    <span>
                      {width > 1024 && "Created on"}{" "}
                      {folder.created_at.substring(0, 10)}
                    </span>
                  </p>
                  <div style={{ maxHeight: "50px" }}>
                    {provided.placeholder}
                  </div>
                </div>
              </div>
            )}
          </Draggable>
        )}
      </Droppable>
    );
  } else {
    return <div />;
  } */
  if (folder && folder.id) {
    return (
      <Droppable
        droppableId={`droppable-minature${folder.id}`}
        direction="vertical"
      >
        {(provided, snapshot) => (
          <Draggable
            key={folder.id}
            draggableId={`draggable-folder${folder.id}`}
            index={folder.id}
            disableInteractiveElementBlocking
            isDragDisabled={true}
          >
            {(providedDrag, snapshotDrag) => (
              <div
                ref={providedDrag.innerRef}
                {...providedDrag.draggableProps}
                {...providedDrag.dragHandleProps}
              >
                {console.log(folders.oneFolder)}
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={
                    folders.oneFolder.id === folder.id
                      ? "folder_miniature folder_miniature_selected "
                      : "folder_miniature"
                  }
                  /*  onClick={() => {
                    dispatch(clearSelectedFolders());
                    dispatch(deselectSelection());
                    dispatch(clearRightSideFolder());
                    folder.name && dispatch(getOneFolder(folder.id));
                    dispatch(clearSelectedProducts());
                  }} */
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute", right: "0" }}>
                      <Popover
                        placement="right"
                        content={() => (
                          <div className="popover_archive_folder">
                            <p
                              onClick={() => {
                                dispatch(archiveFolder(folder.id));
                                dispatch(clearSelectedFolders());
                                if (folder.id === folders.oneFolder.id) {
                                  dispatch(clearOneFolder());
                                }
                                if (folder.id === folders.rightSideFolder.id) {
                                  dispatch(clearRightSideFolder());
                                }
                              }}
                            >
                              <Trash /> Archive
                            </p>
                          </div>
                        )}
                        trigger="click"
                      >
                        <button
                          className="article_popover_btn"
                          onClick={(event) => event.stopPropagation()}
                        >
                          <FontAwesomeIcon
                            icon={faEllipsisH}
                            opacity={0.7}
                            color={"#00468c"}
                          />
                        </button>
                      </Popover>
                    </div>
                  </div>

                  <>
                    <ul id="myUL">
                      <li>
                        <span
                          onClick={() => {
                            dispatch(clearSelectedFolders());
                            dispatch(deselectSelection());
                            dispatch(clearRightSideFolder());
                            folder.name && dispatch(getOneFolder(folder.id));
                            dispatch(clearSelectedProducts());
                          }}
                          className="caret"
                        >
                          {folder.name}
                        </span>

                        {folder.folders_sub && folder.folders_sub.length > 0 ? (
                          <>
                            {" "}
                            <ul className="nested">
                              {folder.folders_sub.map((elm: any) => (
                                <>
                                  {" "}
                                  <li
                                    onClick={() => {
                                      dispatch(clearSelectedFolders());
                                      dispatch(deselectSelection());
                                      dispatch(clearRightSideFolder());
                                      elm.name &&
                                        dispatch(getOneFolder(elm.id));
                                      dispatch(clearSelectedProducts());
                                    }}
                                    className="nestedli"
                                    /*    className={folder?.folders_sub.map(
                                      (el: any, i: number) => {
                                        el.id === elm.id
                                          ? "folder_miniature folder_miniature_selected "
                                          : "folder_miniature";
                                      }
                                    )} */
                                    style={{ margin: "6px" }}
                                  >
                                    {elm.name}
                                  </li>
                                </>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <>
                            {" "}
                            <ul className="nested" style={{ color: "#7C7C7C" }}>
                              No subfolders
                            </ul>
                            <></>
                          </>
                        )}
                      </li>
                    </ul>
                  </>
                </div>
              </div>
            )}
          </Draggable>
        )}
      </Droppable>
    );
  } else {
    return <div />;
  }
}
