import { Pagination, Spin, Avatar, Tooltip, Drawer } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../config/axios-instance";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import "../components/AllClients/AllClient.scss";
import { Link, useLocation, useHistory } from "react-router-dom";
import company_placeholder from "../img/company_placeholder.png";
import { useSelector } from "react-redux";
import Head from "../components/Head";
import {Spinner} from "../components/Spinner";

const AllCompaniesForYou = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();
  const { user } = useSelector((state: any) => state.auth);
  const [showDrawer, setShowDrawer] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQueryy();

  const fetchClients = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/companies?page=${query.get("page") || 1}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const handleLinkClick = (el: any) => {
    history.push(`/foryou-all-users/${el.id}`);
    // You can add a slight delay before reloading the page if needed
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  const { isLoading, isError, data, error } = useQuery(
    ["companies", query.get("page")],
    () => fetchClients(),
    { keepPreviousData: true }
  );

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  const filteredData = data?.data.data.filter((el: any) =>
    el.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  function goto(el:any) {
    history.push(`/foryou-all-users/${el.id}`);
    handleLinkClick(el)
  }
  return isLoading ? (
  /*  <div className="loading_orders">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>*/

      <Spinner />
  ) : (
    <div className="my_orders">
      <Head title="For You" />
      <div className="container">
        <div className="d-flex align-items-center justify-content-between pr-1 mb-1">
          <div>
            <h1
              className="mb-0 mt-3"
              style={{ color: "#00468c", fontSize: "30px", fontWeight: "bold" }}
            >
              List of Companies
            </h1>
          </div>
          <div></div>
        </div>
        <div className="row mt-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search your company..."
            className="option_details_input"
          />
        </div>
        <table className="table mt-2">
          <tbody>
            {filteredData?.map((el: any, i: number) => (
              <tr key={i} style={{ height: "66px" }} onClick={()=>goto(el)}>
                <td>
                  <div className="d-flex align-items-center">
                    <img
                      className="mr-3"
                      src={
                        el.logo
                          ? process.env.REACT_APP_STORAGE_URL + el.logo
                          : company_placeholder
                      }
                      width="30"
                    />
                    <p className="mb-0">{el.name}</p>
                  </div>
                </td>

             {/*   <td align="center">
                  <Link
                    to={`/foryou-all-users/${el.id}`}
                    onClick={() => handleLinkClick(el)}
                  >
                    See details
                  </Link>
                </td>*/}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr></tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default AllCompaniesForYou;
