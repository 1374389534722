import React, {useCallback, useEffect, useState} from "react";
import { Button } from "../Button/Button";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../config/axios-instance";
import { MyNotif } from "../NotifIcons/NotifIcons";
interface Props {
  setStep: (x: number) => void;
  setSuccess: (val: boolean) => void;
  handleChange: (e: any) => void;
  data: any;
  setErrors: (x: any) => void;
  errors: any;
  setForgot: (x: string) => void;
}

export const JoinFormCompany = ({
  setStep,
  setSuccess,
  handleChange,
  data,
  setErrors,
  errors,
  setForgot,
}: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState("");
  const [requestErrors, setRequestErrors] = React.useState<any>([]);

  const sendRequestDemco = async (e: any) => {
    e.preventDefault();
    await setIsLoading(true);
    if (data.company && data.email && data.country) {
      const submissionData = {
        ...data,
        'g-recaptcha-response': recaptchaValue,
      };

      await axiosInstance({
        method: "post",
      //  url: "/join",
        url: "/joindemco",
        data:submissionData,
      })
        .then((res) => {
          if (res && res.data) {
            setSuccess(true);
            setIsLoading(false);
            setTimeout(() => {
              setSuccess(false);
            }, 2000);
            setForgot("center");
            setErrors([]);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setRequestErrors(err.response.data.errors);
          console.log(requestErrors.email);
          MyNotif(requestErrors.email, "danger");
          if (!recaptchaValue) {
            console.error("ReCAPTCHA token is missing!")
          }
         /*   setRequestError(err); */
        });
    } else {
      let keys = ["company", "country","email"];
      setIsLoading(false);
      await setErrors(keys.filter((el: string) => !data[el]));


    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

  }, []);
/*
//@ts-ignore
  window.onloadCallback = () => {
    //@ts-ignore
     window.grecaptcha?.render('recaptcha-container', {
      'sitekey': SITE_KEY,
      'callback': handleRecaptchaChange,
    });
  };
*/

  const [recaptchaValue, setRecaptchaValue] = useState<any>(null);
  //6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI => test
  //6LdeTIYpAAAAAFWb5rqexQ2YpNa1NtmzPCetqlYr => my
  const SITE_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

  const handleRecaptchaChange = useCallback(value => {
    // Here we capture the reCAPTCHA token that we can send to the backend for verification
    setRecaptchaValue(value);
  // console.log(value)
  }, []);

  const handleSubmit = (event:any )=> {
    event.preventDefault();
    // Assuming you have a state set up for form values, submit those along with the captcha result.
    // Remember to include the recaptcha response token (`recaptchaValue`) in your payload.

    // Example payload:
    const formData = {
      /* form data */
      captcha: recaptchaValue,
    };

    // Post to your backend server for verification and processing
    console.log(formData);
    // Remember to reset the recaptcha after form submit if you're not redirecting or reloading the page
    //@ts-ignore
    window.grecaptcha && window.grecaptcha.reset();
  };

  return (
      <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          className="mt-2"
      >
        <form onSubmit={sendRequestDemco}>
          <div className="field">
            <input
                type="text"
                name="name"
                id="name"
                onChange={(e: any) => handleChange(e)}
                placeholder=" "
                value={data.name}
                style={{borderColor: errors.includes("name") ? "red" : "#ccc"}}
            />
            <label htmlFor="name" className="login_email_label">
              Full Name
            </label>
          </div>
          <div className="field">
            <input
                type="email"
                name="email"
                id="email"
                onChange={(e: any) => handleChange(e)}
                placeholder=" "
                value={data.email}
                style={{borderColor: errors.includes("email") ? "red" : "#ccc"}}
            />
            <label htmlFor="email" className="login_email_label">
              Email Address
            </label>
          </div>
          <div className="field">
            <input
                type="text"
                name="company"
                id="company"
                onChange={(e: any) => handleChange(e)}
                placeholder=" "
                value={data.company}
                style={{
                  borderColor: errors.includes("company") ? "red" : "#ccc",
                }}
            />
            <label htmlFor="company" className="login_password_label">
              Company Name
            </label>
          </div>
          <div className="field">
            <input
                type="text"
                name="job_title"
                id="job_title"
                onChange={(e: any) => handleChange(e)}
                placeholder=" "
                value={data.job_title}
                style={{borderColor: errors.includes("job_title") ? "red" : "#ccc"}}
            />
            <label htmlFor="job_title" className="login_email_label">
              Job Title
            </label>
          </div>

          <div className="field">
            <input
                type="text"
                name="country"
                id="country"
                onChange={(e: any) => handleChange(e)}
                placeholder=" "
                value={data.country}
                style={{borderColor: errors.includes("country") ? "red" : "#ccc"}}
            />
            <label htmlFor="country" className="login_password_label">
              Country
            </label>
          </div>
          <p>{requestError && requestError}</p>
          <div className="g-recaptcha" data-sitekey={SITE_KEY} data-callback={handleRecaptchaChange}></div>
          <input type="hidden" id="g-recaptcha-response" value={recaptchaValue}/>

          <div className="d-flex justify-content-center join_demco_btns">
            {/*  <Button
          buttonClass="submit_button_white_full_width submit_button_white_full_width_login company_join_btn"
          innerButton="Back"
          onClick={() => setStep(0)}
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
        />*/}
            <Button
                buttonClass="submit_button_white_full_width submit_button_white_full_width_login company_join_btn"
                innerButton={isLoading ? "Sending..." : "Send"}
                onClick={sendRequestDemco}
                icon={<FontAwesomeIcon icon={faCheck}/>}
            />
          </div>
        </form>
      </motion.div>
  );
};
