import React, { useRef } from "react";
import { useDropzone } from "react-dropzone";
import { FileLigthBoxCustomization } from "./FileLigthBoxCustomization";
import "../product-3d-config-tabs.scss";
interface CdzI {
  attachFile: any;
  detachFile: any;
  files: any;
  objectKey: string;
}

const CustomizationDropZone = ({
  attachFile,
  detachFile,
  files,
  objectKey,
}: CdzI) => {
  const containerRef = useRef<any>();
  const { getRootProps, getInputProps }: any = useDropzone({
    accept: "image/*, .pdf,.xlsx",
    onDrop: (acceptedFiles: any, e: any) => {
      attachFile(getInputProps().ref.current.name, [
        ...files,
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    maxFiles: 10,
    onDragEnter: () => (containerRef.current.style.background = "#e5f1fa"),
    onDragLeave: () => (containerRef.current.style.background = "#f4f7ff"),
  });

  const acceptedFileItems =
    files &&
    files.length !== 0 &&
    files.map((file: any, i: number) => (
      <FileLigthBoxCustomization
        file={file}
        key={i}
        customization={true}
        detachFile={detachFile}
        objectKey={objectKey}
      />
    ));

  return (
    <section className="container my-4 " ref={containerRef}>
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ maxHeight: 50 }}
      >
        <input {...getInputProps({ name: objectKey })} />
        <p className={"drop-zone-text"}>Drag and drop some files here, or click to select files</p>
      </div>
      <aside>
        <ul
          className={
            objectKey === "style_files"
              ? "attached-files-grid p-0 mt-3 mb-2"
              : "d-flex p-0 flex-wrap mt-3 mb-2"
          }
        >
          {acceptedFileItems}
        </ul>
        {/* <ul className="d-flex p-0 flex-wrap mt-3 mb-2">{acceptedFileItems}</ul> */}
      </aside>
    </section>
  );
};
export default CustomizationDropZone;
