import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute: React.FC<{
  component: any;
  path: string;
  exact?: boolean;
}> = (props) => {
  const auth = useSelector((state: any) => state.auth);

  return auth.isLoggedIn ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/" />
  );
};

export default PrivateRoute;
