import { Input, Select, Tooltip, Menu, Dropdown, Modal } from "antd";
import {useEffect, useState} from "react";
import { useForm, Controller } from "react-hook-form";
import { TextEditor } from "../components/TextEditor/TextEditor";
import { LongTextEditor } from "../components/TextEditor/LongTextEditor";
import { useMutation, useQuery } from "react-query";
import {
  EyeOutlined,
  DownOutlined,
  DeleteOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import axiosInstance from "../config/axios-instance";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MyNotif } from "../components/NotifIcons/NotifIcons";
import PreviewPost from "../components/MasonryBlock/PreviewPost";
import { useSelector } from "react-redux";
import Head from "../components/Head";

const { Option } = Select;

export const AddCreativeStation = () => {
  const [preview, setPreview] = useState("");
  const [publishLoading, setPublishLoading] = useState(false);
  const [savingLoading, setSavingLoading] = useState(false);
  const history = useHistory();
  const params = useParams<any>();
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  const [seasonCollectionRes, setSeasonCollectionRes] = useState<any>(null);

  let collections = "/collections/home/published";

  const fetchSeason = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/collections/home/published`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      // console.log(res.data)
      setSeasonCollectionRes(res.data)
    });
  };

  useEffect(() => {
    fetchSeason();


  }, []);

  const { isLoading: loading, data: onePost } = useQuery<any>(
    `post-${params.post_id}`,
    () => fetchPost(),
    {
      keepPreviousData: true,
      enabled: history.location.pathname.includes("update"),
    }
  );

  const { register, control, handleSubmit, errors, setValue, getValues } =
    useForm<any>();
  //   {
  //   defaultValues: {
  //     type: history.location.pathname.includes("update") ? onePost?.type : "",
  //     name: history.location.pathname.includes("update") ? onePost?.name : "",
  //     category: history.location.pathname.includes("update")
  //       ? onePost?.category
  //       : "",
  //     short_description: history.location.pathname.includes("update")
  //       ? onePost?.short_description
  //       : "",
  //     long_description: history.location.pathname.includes("update")
  //       ? onePost?.long_description
  //       : "",
  //     file: history.location.pathname.includes("update") ? onePost?.file : "",
  //   },
  // }

  console.log(getValues("file"));

  const [showLongEditor, setShowLongEditor] = useState(true);
  const [showLongEditorVideo, setShowLongEditorVideo] = useState(true);
  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQueryy();

  const createPost = async (formData: any) => {
    let token = localStorage.getItem("token");
    const { data: response } = await axiosInstance({
      method: "post",
      url: "/posts",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await MyNotif("Article published successfully.", "success");
    await history.push("/creative-station");
    return response.data;
  };

  const updatePost = async (formData: any) => {
    let token = localStorage.getItem("token");
    const { data: response } = await axiosInstance({
      method: "post",
      url: `/posts/${params.post_id}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await MyNotif("Article updated successfully.", "success");
    setPublishLoading(false);
    setSavingLoading(false);
    if (query.get("draft")) {
      await history.push("/creative-station/draft");
    } else {
      await history.push("/creative-station");
    }
    return response.data;
  };

  const deletePost = async () => {
    let token = localStorage.getItem("token");
    const { data: response } = await axiosInstance({
      method: "delete",
      url: `/posts/${params.post_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await MyNotif("Article deleted successfully.", "success");
    await history.push("/creative-station");
    return response.data;
  };

  const { mutate: mutateCreatePost, isLoading } = useMutation((formData: any) =>
    createPost(formData)
  );

  const { mutate: mutateUpdatePost } = useMutation((formData: any) =>
    updatePost(formData)
  );

  const { mutate: mutateDeletePost, isLoading: deleteLoading } =
    useMutation(deletePost);

  const onSubmit = (data: any) => {
    let formData = new FormData();
    Object.keys(data).map((el: string) => formData.append(el, data[el]));
    if (history.location.pathname.includes("update")) {
      if (
        user &&
        isAdmin &&
        user.permissions.includes("creative-station.update")
      ) {
        setSavingLoading(true);
        formData.append("author", "ID Team");
        formData.append("_method", "patch");
        return mutateUpdatePost(formData);
      } else {
        alert("You are not authorized!!");
      }
    } else {
      if (
        user &&
        isAdmin &&
        user.permissions.includes("creative-station.create")
      ) {
        formData.append("author", "ID Team");
        formData.append("in_draft", "0");
        return mutateCreatePost(formData);
      } else {
        alert("You are not authorized!!");
      }
    }
  };

  const errorBorder = (field: string) => {
    return {
      borderColor:
        errors[field]?.type === "required" ? "0px 1px red" : "#d9d9d9",
      boxShadow:
        errors[field]?.type === "required"
          ? "0 0 0 2px rgb(255,0,0, 0.4)"
          : "none",
    };
  };

  const [createdAt, setCreatedAt] = useState("");

  const fetchPost = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/posts/${params.post_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      const {
        id,
        name,
        category,
        session,
        type,
        short_description,
        long_description,
        file,
        created_at,
      } = res.data;
      setValue("long_description", long_description);
      setValue("id", id);
      setValue("name", name);
      setValue("category", category);
      setValue("short_description", short_description);
      setValue("type", type);
      setValue("session", session);

      // setValue("author", author);
      setValue("file", file);
      setCreatedAt(created_at);
      if (type === "article") {
        setShowLongEditor(true);
      } else {
        setShowLongEditor(false);
      }
    });
  };

  const saveToDraft = () => {
    let data = getValues();
    let formData = new FormData();
    Object.keys(data).map((el: string) => formData.append(el, data[el]));
    if (history.location.pathname.includes("update")) {
      formData.append("in_draft", "1");
      formData.append("_method", "patch");
      mutateUpdatePost(formData);
    } else {
      formData.append("in_draft", "true");
      mutateCreatePost(formData);
    }
  };

  const menuItemStyles: any = {
    fontWeight: 500,
    width: 150,
    padding: "10px 30px",
    textAlign: "center",
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const isImage = (filename: string) => {
    let extension = filename.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "png":
        return true;
      case "jpg":
        return true;
      case "jpeg":
        return true;
      case "svg":
        return true;
      case "webp":
        return true;
      default:
        return false;
    }
  };

  const menu = (
    <Menu className="rounded">
      {query.get("draft") !== "true" && (
        <Menu.Item
          key="1"
          className="d-flex align-items-center justify-content-start"
          style={menuItemStyles}
          onClick={saveToDraft}
        >
          <SnippetsOutlined
            style={{ verticalAlign: "0px", fontSize: 16 }}
            className="mr-3"
          />
          Save Draft
        </Menu.Item>
      )}
      <Menu.Item
        key="2"
        className="d-flex align-items-center justify-content-start"
        style={menuItemStyles}
        onClick={showModal}
      >
        <EyeOutlined
          style={{ verticalAlign: "0px", fontSize: 16 }}
          className="mr-3"
        />
        Preview
      </Menu.Item>
      {history.location.pathname.includes("update") && (
        <Menu.Item
          key="3"
          className="d-flex align-items-center justify-content-start"
          style={menuItemStyles}
          onClick={() => mutateDeletePost()}
        >
          <DeleteOutlined
            style={{ verticalAlign: "0px", fontSize: 16 }}
            className="mr-3"
          />
          Delete
        </Menu.Item>
      )}
    </Menu>
  );

  const publishNow = () => {
    let data = getValues();
    let formData = new FormData();
    Object.keys(data).map((el: string) => formData.append(el, data[el]));
    formData.append("in_draft", "0");
    formData.append("_method", "patch");
    setPublishLoading(true);
    mutateUpdatePost(formData);
  };

  return (
    <div className="my_orders pt-0 pb-5">
      <Head title="New Article" />
      <form
        style={{ paddingTop: "126px" }}
        className="container"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="d-flex flex-column mt-3">
          <label>Type</label>
          <Controller
            name="type"
            defaultValue=""
            control={control}
            render={(field) => (
              <Select
                onClick={(e: any) => {
                  console.log(e.target.textContent )
                  setShowLongEditor(e.target.textContent === "Article")
                  setShowLongEditorVideo(e.target.textContent === "Video")
                }             }
                size="large"
                {...field}
                style={errorBorder("type")}
                className="rounded_select_antd"
              >
                <Option value="image">
                  <Tooltip
                    title="An image article contains only one image and a description."
                    overlayInnerStyle={{
                      borderRadius: 5,
                    }}
                  >
                    Image
                  </Tooltip>
                </Option>
                <Option value="video">
                  <Tooltip
                    title="A video article contains only one video and a description."
                    overlayInnerStyle={{
                      borderRadius: 5,
                    }}
                  >
                    Video
                  </Tooltip>
                </Option>
                <Option value="article">
                  <Tooltip
                    title="The article contains content, description and image."
                    overlayInnerStyle={{
                      borderRadius: 5,
                    }}
                  >
                    Article
                  </Tooltip>
                </Option>
              </Select>
            )}
            rules={{ required: true }}
          />
        </div>
        <div className="d-flex flex-column mt-3">
          <label>Title</label>
          <Controller
            name="name"
            defaultValue=""
            control={control}
            render={(field) => (
              <Input
                {...field}
                size="large"
                type="text"
                style={errorBorder("name")}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        <div className="d-flex flex-column mt-3">
          <label>Season</label>
          <Controller
            name="session"
            defaultValue=""
            control={control}
            render={(field) => (
             /* <Input
                {...field}
                size="large"
                type="text"
                style={errorBorder("session")}
              />*/

                <Select
                    onClick={(e: any) => {
                      console.log(e.target.textContent )
                      setShowLongEditor(e.target.textContent === "Article")
                      setShowLongEditorVideo(e.target.textContent === "Video")
                    } }
                    size="large"
                    {...field}
                    style={errorBorder("session")}
                    className="rounded_select_antd"
                >
                  {
                    seasonCollectionRes?.filter((item:any) => item.collection_id === 25)
                        .map((item: any, i: number) => (
                            <Option value={item.name} key={i}>{item.name}</Option>
                        ))


                  }

                </Select>
            )}
            rules={{ required: true }}
          />
        </div>
        {/* <div className="d-flex flex-column mt-3">
          <label>Author</label>
          <Controller
            name="author"
            defaultValue=""
            control={control}
            render={(field) => (
              <Input
                size="large"
                type="text"
                placeholder="author"
                {...field}
                style={errorBorder("author")}
              />
            )}
            rules={{ required: true }}
          />
        </div> */}
        <div className="d-flex flex-column mt-3">
          <label>Category</label>
          <Controller
            name="category"
            defaultValue=""
            control={control}
            render={(field) => (
              <Select
                size="large"
                {...field}
                style={errorBorder("category")}
                className="rounded_select_antd"
              >
                <Option value="moodboars">MOODBOARDS</Option>
                <Option value="3dvideos">3D VIDEOS</Option>
                <Option value="trending">TRENDING</Option>
              </Select>
            )}
            rules={{ required: true }}
          />
        </div>

        {showLongEditor && (
            <div className="d-flex flex-column mt-3">
              <label>Cover</label>
              <Controller
                  name="cover"
                  control={control}
                  render={(field) => (
                      <Input
                          type="file"
                          {...field}
                          onChange={(e: any) => {
                            setValue("cover", e.target.files[0]);
                          }}
                          style={errorBorder("cover")}
                          size="large"
                          value={getValues("cover")?.filename}
                      />
                  )}
                  rules={{ required: true }}
              />
            </div>
        )}
        {showLongEditorVideo && (
          <div className="d-flex flex-column mt-3">
            <label>Cover</label>
            <Controller
              name="cover"
              control={control}
              render={(field) => (
                <Input
                  type="file"
                  {...field}
                  onChange={(e: any) => {
                    setValue("cover", e.target.files[0]);
                  }}
                  style={errorBorder("cover")}
                  size="large"
                  value={getValues("cover")?.filename}
                />
              )}
              rules={{ required: true }}
            />
          </div>
        )}
        <div className="d-flex flex-column mt-3">
          <label>Description</label>
          <TextEditor
            setValue={setValue}
            register={register}
            initValue={getValues("short_description")}
          />
        </div>
        {showLongEditor && (
          <div className="d-flex flex-column mt-3">
            <label>Content</label>
            <LongTextEditor
              setValue={setValue}
              register={register}
              initValue={getValues("long_description")}
            />
          </div>
        )}
        <div className="d-flex flex-column mt-3">
          <label>File (JPG,PNG,MP4,PDF) MAX Size : 200 MB</label>
          <Controller
            name="file"
            control={control}
            render={(field) => (
              <Input
                type="file"
                {...field}
                onChange={(e: any) => {
                  setValue("file", e.target.files[0]);
                  setPreview(URL.createObjectURL(e.target.files[0]));
                }}
                style={errorBorder("file")}
                size="large"
                value={getValues("file")?.filename}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        <div className="row mt-5">
          <div
            className="col-4  d-flex rounded"
            style={{ position: "relative" }}
          >
            {history.location.pathname.includes("update") && !preview ? (
              /*  getValues("file") && isImage(getValues("file")) ? (
                <img
                  src={process.env.REACT_APP_STORAGE_URL + getValues("file")}
                  width="300"
                />
              ) : (
                getValues("file") && (
                  <video
                    controls={true}
                    style={{ width: "100%", objectFit: "contain" }}
                    className="item video_post mb-0"
                  >
                    <source
                      src={
                        process.env.REACT_APP_STORAGE_URL + getValues("file")
                      }
                      type="video/mp4"
                    />
                  </video>
                )
              ) */

              getValues("file") && isImage(getValues("file")) ? (
                <img
                  src={process.env.REACT_APP_STORAGE_URL + getValues("file")}
                  width="300"
                />
              ) : getValues("file")?.split(".")[1] === "pdf" ? (
                <iframe
                  src={process.env.REACT_APP_STORAGE_URL + getValues("file")}
                  frameBorder="0"
                  height="100%"
                  width="100%"
                ></iframe>
              ) : (
                <video
                  controls={true}
                  style={{ width: "100%", objectFit: "contain" }}
                  className="item video_post mb-0"
                >
                  <source
                    src={process.env.REACT_APP_STORAGE_URL + getValues("file")}
                    type="video/mp4"
                  />
                </video>
              )
            ) : (
              preview &&
              (getValues("file")?.type.split("/")[0] === "image" ? (
                <>
                  <img
                    src={preview}
                    width="300"
                    height="300"
                    style={{ objectFit: "contain" }}
                  />
                </>
              ) : getValues("file")?.type.split("/")[0] === "video" ? (
                <>
                  <video
                    controls={true}
                    style={{ width: "100%" }}
                    className="item video_post mb-0"
                  >
                    <source src={preview} type="video/webm" />
                  </video>
                </>
              ) : (
                getValues("file")?.name.split(".")[1] == "pdf" && (
                  <>
                    <iframe
                      src={preview}
                      frameBorder="0"
                      height="100%"
                      width="100%"
                    ></iframe>
                  </>
                )
              ))
            )}
            {preview && (
              <button
                className="delete_post_small_btn"
                onClick={() => {
                  setValue("file", null);
                  setPreview("");
                }}
              >
                <DeleteOutlined style={{ verticalAlign: 0, color: "white" }} />
              </button>
            )}
          </div>
          <div className="col-8 pr-0 d-flex justify-content-end align-items-start">
            <input
              type="button"
              className="btn_cr btn_cr_white mx-1 px-5 py-3"
              value="Cancel"
              onClick={() => window.history.back()}
            />
            {query.get("draft") === "true" && (
              <input
                type="button"
                onClick={publishNow}
                className="btn_cr btn_cr_white mx-1 px-5 py-3"
                value={publishLoading ? "Publishing..." : "Publish"}
                disabled={publishLoading}
              />
            )}
            <Dropdown.Button
              htmlType="submit"
              overlay={menu}
              className="btn_cr btn_cr_blue ml-1"
              icon={<DownOutlined style={{ verticalAlign: 0, fontSize: 16 }} />}
              trigger={["click"]}
              overlayClassName="publish_overlay"
            >
              {!deleteLoading &&
                (history.location.pathname.includes("update")
                  ? savingLoading
                    ? "Saving..."
                    : "Save"
                  : isLoading
                  ? "Publishing..."
                  : "Publish")}
              {deleteLoading ? "Deleting..." : ""}
            </Dropdown.Button>
          </div>
        </div>
      </form>
      <Modal
        title="Preview Mode"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"90%"}
        centered
        footer={null}
        className="preview_mode_modal"
      >
        <PreviewPost onePost={{ ...getValues(), createdAt }} />
      </Modal>
    </div>
  );
};
