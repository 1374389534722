import loading from "../img/loading.svg";

export const Spinner = () => {
  return (
    <div
      className="spinner"
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 55,
      }}
    >
      <img src={loading} alt="loading..." width="100" />
    </div>
  );
};
