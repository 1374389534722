import React from "react";

export type ITextIconProps = {};

const TextIcon: React.FC<ITextIconProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_244_1409)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 3C7.5 2.80109 7.57902 2.61032 7.71967 2.46967C7.86032 2.32902 8.05109 2.25 8.25 2.25C9.543 2.25 10.6095 2.6805 11.34 3.099C11.601 3.2475 11.8215 3.396 12 3.528C12.1785 3.396 12.399 3.2475 12.66 3.099C13.5987 2.55216 14.6637 2.25954 15.75 2.25C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3C16.5 3.19891 16.421 3.38968 16.2803 3.53033C16.1397 3.67098 15.9489 3.75 15.75 3.75C14.793 3.75 13.9845 4.0695 13.404 4.401C13.1752 4.53134 12.9566 4.67876 12.75 4.842V11.25H13.5C13.6989 11.25 13.8897 11.329 14.0303 11.4697C14.171 11.6103 14.25 11.8011 14.25 12C14.25 12.1989 14.171 12.3897 14.0303 12.5303C13.8897 12.671 13.6989 12.75 13.5 12.75H12.75V19.158C12.9 19.278 13.122 19.4385 13.404 19.599C13.9845 19.9305 14.793 20.25 15.75 20.25C15.9489 20.25 16.1397 20.329 16.2803 20.4697C16.421 20.6103 16.5 20.8011 16.5 21C16.5 21.1989 16.421 21.3897 16.2803 21.5303C16.1397 21.671 15.9489 21.75 15.75 21.75C14.6637 21.7405 13.5987 21.4478 12.66 20.901C12.432 20.7718 12.2117 20.6295 12 20.475C11.7884 20.63 11.568 20.7728 11.34 20.9025C10.4013 21.4493 9.3363 21.742 8.25 21.7515C8.05109 21.7515 7.86032 21.6725 7.71967 21.5318C7.57902 21.3912 7.5 21.2004 7.5 21.0015C7.5 20.8026 7.57902 20.6118 7.71967 20.4712C7.86032 20.3305 8.05109 20.2515 8.25 20.2515C9.207 20.2515 10.0155 19.932 10.596 19.6005C10.878 19.44 11.0985 19.2795 11.25 19.1595V12.75H10.5C10.3011 12.75 10.1103 12.671 9.96967 12.5303C9.82902 12.3897 9.75 12.1989 9.75 12C9.75 11.8011 9.82902 11.6103 9.96967 11.4697C10.1103 11.329 10.3011 11.25 10.5 11.25H11.25V4.842C11.0434 4.67876 10.8248 4.53134 10.596 4.401C9.88386 3.98383 9.07528 3.75946 8.25 3.75C8.05109 3.75 7.86032 3.67098 7.71967 3.53033C7.57902 3.38968 7.5 3.19891 7.5 3Z"
          fill="#182844"
        />
        <path
          d="M15 7.5H21C21.3978 7.5 21.7794 7.65804 22.0607 7.93934C22.342 8.22064 22.5 8.60218 22.5 9V15C22.5 15.3978 22.342 15.7794 22.0607 16.0607C21.7794 16.342 21.3978 16.5 21 16.5H15V18H21C21.7956 18 22.5587 17.6839 23.1213 17.1213C23.6839 16.5587 24 15.7956 24 15V9C24 8.20435 23.6839 7.44129 23.1213 6.87868C22.5587 6.31607 21.7956 6 21 6H15V7.5ZM9 7.5V6H3C2.20435 6 1.44129 6.31607 0.87868 6.87868C0.316071 7.44129 0 8.20435 0 9L0 15C0 15.7956 0.316071 16.5587 0.87868 17.1213C1.44129 17.6839 2.20435 18 3 18H9V16.5H3C2.60218 16.5 2.22064 16.342 1.93934 16.0607C1.65804 15.7794 1.5 15.3978 1.5 15V9C1.5 8.60218 1.65804 8.22064 1.93934 7.93934C2.22064 7.65804 2.60218 7.5 3 7.5H9Z"
          fill="#182844"
        />
      </g>
      <defs>
        <clipPath id="clip0_244_1409">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { TextIcon };
