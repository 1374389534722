import { Button, DatePicker } from "antd";
import { useState } from "react";
import moment from "moment";

interface Props {
  date: any;
  orderType: any;
  setDate: (dateString: any) => void;
  setOrderType: (name: any) => void;
}

export const MyOrdersHeader = ({
  date,
  setDate,
  setOrderType,
  orderType,
}: Props) => {
  return (
    <div className="my_orders_header">
      <div className="my_orders_header_left">
        <h1>my orders</h1>
        <div className="my_orders_header_btns mt-2 mt-md-0">
          <Button
            type="ghost"
            onClick={() => setOrderType("In progress")}
            className={orderType === "In progress" ? "active" : undefined}
          >
            In progress
          </Button>
          <Button
            type="ghost"
            onClick={() => setOrderType("Cancelled")}
            className={orderType === "Cancelled" ? "active" : undefined}
          >
            Cancelled
          </Button>
        </div>
      </div>
      <div className="my_orders_header_right mt-2 mt-md-0">
        <span className="mr-2" onClick={() => setDate("")}>
          See All
        </span>
        <DatePicker
          className="date_my_orders"
          defaultValue={moment(date === "" ? new Date() : date)}
          format={"DD/MM/YYYY"}
          onChange={(date: any, dateString: any) => setDate(dateString)}
        />
      </div>
    </div>
  );
};
