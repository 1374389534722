import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { AddOrderHeader } from "../components/AddOrder/AddOrderHeader";
import "../sass/AddNewOrder.scss";
import { OrderArticlesList } from "../components/AddOrder/OrderArticlesList";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNewOrder,
  getOrderCollaborators,
} from "../actions/order-actions/actions";
import { handleBottomDrawer } from "../actions/actionCreators";
import BottomDrawer from "../components/BottomDrawer/BottomDrawer";
import { OptionDetails } from "../components/OptionDetails/OptionDetails";
interface Props {}

export const AddNewOrder = (props: Props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [order, setOrder] = useState<any>(null);
  const [mode, setMode] = useState("dazadzzzzzzz");
  const [optionSelected, setOptionSelected] = useState(null);

  const dispatch = useDispatch();
  const {
    newOrder,
    collaborators,
    getOrderCollaboratorsLoading,
    selectedOption,
  } = useSelector((state: any) => state.orders);
  const drawers = useSelector((state: any) => state.drawers);

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  useEffect(() => {
    setOrder(newOrder);
  }, []);

  useEffect(() => {
    if (newOrder) {
      dispatch(getOrderCollaborators(newOrder.id));
    }
    dispatch(clearNewOrder());
  }, [newOrder]);

  useEffect(() => {
    if (selectedOption) {
      setOptionSelected(selectedOption);
    }
  }, [selectedOption]);

  return (
    <div className="add_order">
      {width > 575 ? (
        <>
          <AddOrderHeader
            width={width}
            edit={false}
            order={order}
            collaborators={collaborators}
            // getOrderCollaboratorsLoading={getOrderCollaboratorsLoading}
          />
          <div className="row">
            <div className="col-xs-12 col-sm-3 col-md-3 p-2 pl-md-5 pr-md-3 pt-md-3 order_list_side add add_order_details_left_side">
              <div className="order_details_text">
                <div className="order_details_text_content mb-2 mt-0">
                  <h3 className="m-0 mr-2">Details</h3>
                  <FontAwesomeIcon icon={faPen} />
                </div>
                <p className="my-2">{order && order.details}</p>
              </div>
              <div className="articles_list_header my-3">
                <h3>Articles</h3>
                <button
                  onClick={() => {
                    dispatch(handleBottomDrawer());
                    setMode("add_new_article");
                  }}
                >
                  Add New Article
                </button>
              </div>
              <OrderArticlesList
                setOptionSelected={setOptionSelected}
                edit={false}
                recentOrder={order}
              />
            </div>
            <div className="col-xs-12 col-sm-9 p-0 order_details_container add_order_details_right_side">
              <div className="order_option_details">
                <OptionDetails
                  setOptionSelected={setOptionSelected}
                  optionSelected={selectedOption}
                  width={width}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <AddOrderHeader width={width} edit={false} order={order} />
          <div className="row">
            <div className="col-xs-12 col-sm-3 col-md-3 p-4 order_list_side add add_order_details_left_side">
              <div className="order_details_text">
                <div className="order_details_text_content mb-2 mt-0">
                  <h3 className="m-0 mr-2">Details</h3>
                  <FontAwesomeIcon icon={faPen} />
                </div>
                <p className="my-2">{order && order.details}</p>
              </div>
              <div className="articles_list_header my-3">
                <h3>Articles</h3>
                <button
                  onClick={() => {
                    dispatch(handleBottomDrawer());
                    setMode("add_new_article");
                  }}
                >
                  Add New Article
                </button>
              </div>
              <OrderArticlesList
                setOptionSelected={setOptionSelected}
                edit={false}
                recentOrder={order}
              />
            </div>
            <div className="col-9 p-0 order_details_container add_order_details_right_side">
              <div className="order_option_details">
                <OptionDetails
                  setOptionSelected={setOptionSelected}
                  optionSelected={selectedOption}
                  width={width}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {drawers.openBottom && (
        <BottomDrawer mode="add_new_article" setMode={setMode} />
      )}
    </div>
  );
};
