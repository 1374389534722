import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginImages } from "../components/Login/loginImgaes";
import "../sass/Login.scss";
import LoginForm from "../components/Login/LoginForm";
import SuccessPage from "../components/Login/SuccessPage";
import Head from "../components/Head";
//@ts-ignore
import login from "../video/login.mp4";
export interface ILoginProps {}

export interface ILoginProps {
  history: any;
}

export default function Login({ history }: ILoginProps) {
  const [position, setPosition] = useState(0);
  const { isLoggedIn, user, token } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  const [success, setSuccess] = useState<boolean>(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (position === 3) setPosition(0);
      else setPosition(position + 1);
    }, 10000);
    return () => clearInterval(interval);
  }, [position]);

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/home");
    }
  }, [isLoggedIn]);

  return (
    /*   <div className="loginPageContainer container-fluid ">
      <Head title="DEMCO - COMMITTED TO SUSTAINABILITY" />
      {success && (
        //@ts-ignore
        <SuccessPage click={setSuccess} />
      )}
  

      <div
        className="bg_images_login row"
        dangerouslySetInnerHTML={{
          __html: `
      <video id="videoBG"  webkit-playsinline="true" autoplay="true"    preload="metadata" muted="true"  loop playsinline="true" id="video-id-auto">
      <source src=${creative_team} type="video/mp4" ></source>
     </video>
     
    `,
        }}
      />
      <div
        style={{
          backgroundImage: `linear-gradient(
            to right bottom,
            rgba(0, 55, 110, 0.3),
            rgba(0, 55, 110, 0.3)
          ) ,  url("${loginImages[position].img}")`,
        }}
        className="bg_images_login row"
      >
        {
          // @ts-ignore
          <LoginForm setSuccess={setSuccess} success={success} />
        }
        <div className="login_page_text col-7 d-md-none d-xl-block">
          <h1 className="slogan">
            <span className="slogan_light_text">Committed to </span>
            Sustainability!
          </h1>
          <h2 className="slogan_description">{loginImages[position].Text}</h2>
        </div>
      </div>
    </div> */

    <div className=" container-fluid">
      <div className=" row" style={{ position: "relative" }}>
        <video
          autoPlay
          muted
          loop
          style={{ height: "100vh", width: "100%", objectFit: "cover" }}
        >
          <source src={login} type="video/mp4" />
          {/* Ajoutez d'autres formats vidéo pris en charge ici, le cas échéant */}
        </video>
        <div style={{ position: "absolute" }}>
          {/* Vous pouvez également inclure un formulaire de connexion ici */}
          <div style={{ marginTop: "10%" }}>
            {
              // @ts-ignore
              <LoginForm setSuccess={setSuccess} success={success} />
            }
          </div>
          {/*    <div className=" col-7 d-md-none d-xl-block">
            <h1 className="slogan">
              <span className="slogan_light_text">Committed to </span>
              Sustainability!
            </h1>
            <h2 className="slogan_description">{loginImages[position].Text}</h2>
          </div> */}
        </div>
      </div>
    </div>
  );
}
