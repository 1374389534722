import { FINISH, NEXT_STEP, PREV_STEP } from "./types";

export const goNextStep = () => {
  return {
    type: NEXT_STEP,
  };
};
export const goPrevStep = () => {
  return {
    type: PREV_STEP,
  };
};
export const finish = () => {
  return {
    type: FINISH,
  };
};
