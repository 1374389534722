function TeamManagement() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 28 28"
    >
      <path
        id="Tracé_3319"
        data-name="Tracé 3319"
        d="M-.22-9.023A9.033,9.033,0,0,1,1.111-11.47,2.959,2.959,0,0,1,0-11.25a3,3,0,0,1-3-3,3,3,0,0,1,3-3,3,3,0,0,1,3,3,2.958,2.958,0,0,1-.22,1.116,8.907,8.907,0,0,1,2.447-1.331A5.23,5.23,0,0,0,0-19.5a5.247,5.247,0,0,0-5.25,5.25A5.236,5.236,0,0,0-.22-9.023ZM-10.5-10.5a3.749,3.749,0,0,0,3.75-3.75A3.749,3.749,0,0,0-10.5-18a3.749,3.749,0,0,0-3.75,3.75A3.749,3.749,0,0,0-10.5-10.5Zm0-5.25A1.5,1.5,0,0,1-9-14.25a1.5,1.5,0,0,1-1.5,1.5,1.5,1.5,0,0,1-1.5-1.5A1.5,1.5,0,0,1-10.5-15.75ZM.952-.75h-7.7v-2.1A3.159,3.159,0,0,1-3.684-6a9.527,9.527,0,0,0,2.972.713A8.207,8.207,0,0,1-.6-7.566a7.478,7.478,0,0,1-2.25-.525A1.951,1.951,0,0,0-3.6-8.25,5.4,5.4,0,0,0-9-2.85v2.1A2.251,2.251,0,0,0-6.75,1.5H3.281A9.015,9.015,0,0,1,.952-.75Zm-8.7-7.58A3.924,3.924,0,0,0-9.937-9h-1.125A3.943,3.943,0,0,0-15-5.062a1.128,1.128,0,0,0,1.125,1.125A1.128,1.128,0,0,0-12.75-5.062,1.692,1.692,0,0,1-11.062-6.75h1.125a1.662,1.662,0,0,1,.577.117A6.956,6.956,0,0,1-7.744-8.33ZM13.617-5a5.5,5.5,0,0,0,0-2l1.209-.7a.339.339,0,0,0,.155-.4,7.034,7.034,0,0,0-1.556-2.691A.342.342,0,0,0,13-10.852l-1.209.7a5.5,5.5,0,0,0-1.73-1v-1.4a.341.341,0,0,0-.267-.333,7.073,7.073,0,0,0-3.1,0,.341.341,0,0,0-.267.333v1.4a5.5,5.5,0,0,0-1.73,1l-1.209-.7a.343.343,0,0,0-.422.066A7.034,7.034,0,0,0,1.509-8.1a.345.345,0,0,0,.155.4L2.873-7a5.5,5.5,0,0,0,0,2l-1.209.7a.339.339,0,0,0-.155.4A7.07,7.07,0,0,0,3.066-1.214a.342.342,0,0,0,.422.066l1.209-.7a5.5,5.5,0,0,0,1.73,1v1.4a.341.341,0,0,0,.267.333,7.073,7.073,0,0,0,3.1,0,.341.341,0,0,0,.267-.333v-1.4a5.5,5.5,0,0,0,1.73-1l1.209.7a.343.343,0,0,0,.422-.066A7.034,7.034,0,0,0,14.981-3.9a.345.345,0,0,0-.155-.4ZM8.25-3.727A2.276,2.276,0,0,1,5.977-6,2.279,2.279,0,0,1,8.25-8.273,2.279,2.279,0,0,1,10.523-6,2.276,2.276,0,0,1,8.25-3.727Z"
        transform="translate(15 19.5)"
        fill="#182844"
      />
    </svg>
  );
}

export default TeamManagement;
