import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
  Select,
  Divider,
  Popconfirm,
  Steps,
} from "antd";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios-instance";
import useWindowWidth from "../../config/useWindowWidth";
import LazyImg from "../Library/LazyImage";
import watermak from "../../img/watermark.png";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import React, { useCallback, useEffect, useState } from "react";
import empty_do from "../../img/empty-do_2.png";
import "./AllCollection.scss";
import {
  faCaretDown,
  faFolderPlus,
  fas,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import folder_content from "../../img/folder_content_2.png";
import request_acess from "../../img/acess_request_2.png";

import {
  deleteCustomizedProduct,
  orderNewCustomizedProduct,
} from "../../actions/order-actions/services";
import { RootStateOrAny, useSelector } from "react-redux";
import {
  LoadingOutlined,
  DeleteOutlined,
  PlusOutlined,
  SendOutlined,
  UpOutlined,
  DownOutlined,
  RightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import MyCustomSelect from "react-select";

import {
  createArticle,
  createNewOption,
  getCollaborators,
  createNewDirectProduct,
  selectOrder,
  createNewClientProduct,
  updateDirectProduct,
  sendFolderAccessRequest,
} from "../../actions/order-actions/actions";
import Bag from "../../icons/Bag";
import {
  handleBottomDrawer,
  handleLeftDrawer,
} from "../../actions/actionCreators";
import { useDispatch } from "react-redux";
import { AddNewOptionItem } from "../OptionDetails/AddNewOptionItem";
import { DropZoneFile } from "../OptionDetails/DropZoneFile";
import {
  getAllFolders,
  getArchivedFolders,
} from "../../actions/folders-actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { getType } from "../../actions/products-actions/actions";
import { DropZoneFile2 } from "../OptionDetails/DropZoneFile2";
const { Option } = Select;

const { Meta } = Card;
const { Title } = Typography;
const { Step } = Steps;
export default function AllCollection() {
  const { user } = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  const [mode, setMode] = useState(true);
  const [selectedObject, setSelectedObject] = useState<any>(null);
  const [formErrors, setFormErrors] = useState<any>([]);
  const [next, setNext] = useState(false);
  const [is_sent, SetisSend] = useState(false);

  const [productsToOrder, setProductsToOrder] = useState([]);
  const [date_delivery, setDeliveryDate] = useState("");
  const [saison, setSaison] = useState("");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenCheckClientProduct, setIsOpenCheckClientProduct] =
    useState(false);

  const [selectedCustomizedProducts, setSelectedCustomizedProducts] =
    useState<any>([]);
  const [selectedDirectProducts, setSelectedDirectProducts] = useState<any>([]);

  const [selectedDemcoProducts, setSelectedDemcoProducts] = useState<any>([]);
  const [selectedClientProducts, setSelectedClientProducts] = useState<any>([]);

  const [selectedSelect, setSelectedSelect] = useState<any>(null);
  const folders = useSelector((state: RootStateOrAny) => state.folders);

  const [clientProductRequird, setClientProductRequird] = useState<any>([]);

  const [inputValueOptioName, setInputValueOptioName] = useState<any>(null);
  const [inputValueOptionType, setInputValueOptionType] = useState<any>(null);

  const [inputValueClientOptionName, setInputValueClientOptionName] =
    useState<any>(null);
  const [inputValueClientOptionType, setInputValueClientOptionType] =
    useState<any>(null);

  const [inputValueOptionStyleName, setInputValueOptionStyleName] =
    useState<any>(null);
  const [inputValueOptionFabricName, setInputValueOptionFabricName] =
    useState<any>(null);
  const [inputValueOptionWash, setInputValueOptionWash] = useState<any>(null);
  const [inputValueOptionSize, setInputValueOptionSize] = useState<any>(null);
  const [inputValueOptionLength, setInputValueOptionLength] =
    useState<any>(null);
  const [fieldRequird, setFieldRequird] = useState({
    name: false,
    type: false,
    style: false,
    fabric: false,
    wash: false,
    size_qty: false,
    crotch: false,
  });

  const [isToggledOne, setIsToggledOne] = useState(false);
  const [isToggledTwo, setIsToggledTwo] = useState(false);
  const [isToggledThree, setIsToggledThree] = useState(false);
  const [isToggledFour, setIsToggledFour] = useState(false);
  const { washes } = useSelector((state: any) => state.products);

  useEffect(() => {
    dispatch(getType("washes"));
    setSelectedClientProducts([]);
  }, []);

  // Handler function to update the input value on change
  const handleInputChangeOptionName = (event: any) => {
    setInputValueOptioName(event.target.value);
    setOption({ ...option, name: inputValueOptioName });
  };

  // Handler function to update the input value on change
  const handleInputChangeClientOptionName = (event: any) => {
    setInputValueClientOptionName(event.target.value);
    setOption({ ...option, name: inputValueClientOptionName });
  };

  const handleInputChangeOptionType = (event: any) => {
    setInputValueOptionType(event);
    setOption({ ...option, type: inputValueOptionType });
  };

  const handleInputChangeClientOptionType = (event: any) => {
    setInputValueOptionType(event);
    setOption({ ...option, type: inputValueClientOptionType });
  };

  const handleInputChangeStyleName = (event: any) => {
    setInputValueOptionStyleName(event.target.value);
    setOption({ ...option, style: inputValueOptionStyleName });
  };
  const handleInputChangeFabricName = (event: any) => {
    setInputValueOptionFabricName(event.target.value);
    setOption({ ...option, fabric: inputValueOptionFabricName });
  };

  const handleInputChangeWash = (event: any) => {
    setInputValueOptionWash(event);
    setOption({ ...option, wash: inputValueOptionWash });
  };
  const handleInputChangeSize = (event: any) => {
    setInputValueOptionSize(event.target.value);
    setOption({ ...option, size: inputValueOptionSize });
  };
  const handleInputChangeLength = (event: any) => {
    setInputValueOptionLength(event.target.value);
    setOption({ ...option, length: inputValueOptionLength });
  };

  useEffect(() => {
    dispatch(getAllFolders());
  }, []);

  const sendFolderAccessRequest = async (folder_id: number, data: any) => {
    let token = localStorage.getItem("token");
    await axiosInstance({
      method: "post",
      url: `/folders/${folder_id}/collaborators/request`,
      data: { client_id: data },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        MyNotif("Request send successfully", "success");
        SetisSend(true);
      }
    });
    SetisSend(false);
  };

  const handleCheckboxChangeCustomizedProduct = (productId: any) => {
    console.log(productId);
    // Check if the product is already selected
    if (
      selectedCustomizedProducts.some(
        (selectedProduct: any) => selectedProduct.id === productId.id
      )
    ) {
      setSelectedCustomizedProducts((prevSelected: any) =>
        prevSelected.filter(
          (selectedProduct: any) => selectedProduct.id !== productId.id
        )
      );
    } else {
      // If not selected, add it to the list
      setSelectedCustomizedProducts((prevSelected: any) => [
        ...prevSelected,
        productId,
      ]);
    }
  };

  const handleCheckboxChangeDemcoProduct = (productId: any) => {
    // Check if the product is already selected
    if (
      selectedDemcoProducts.some(
        (selectedProduct: any) => selectedProduct.id === productId.id
      )
    ) {
      // If selected, remove it from the list
      setSelectedDemcoProducts((prevSelected: any) =>
        prevSelected.filter(
          (selectedProduct: any) => selectedProduct.id !== productId.id
        )
      );
    } else {
      // If not selected, add it to the list
      setSelectedDemcoProducts((prevSelected: any) => [
        ...prevSelected,
        productId,
      ]);
    }
  };

  const handleCheckboxChangeDirectProduct = (productId: any) => {
    // Check if the product is already selected

    if (
      selectedDirectProducts.some(
        (selectedProduct: any) => selectedProduct.id === productId.id
      )
    ) {
      // If selected, remove it from the list
      setSelectedDirectProducts((prevSelected: any) =>
        prevSelected.filter(
          (selectedProduct: any) => selectedProduct.id !== productId.id
        )
      );
    } else {
      // If not selected, add it to the list
      setSelectedDirectProducts((prevSelected: any) => [
        ...prevSelected,
        productId,
      ]);
    }
  };

  const handleCheckboxChangeClientProduct = (productId: any) => {
    // Check if the product is already selected

    if (
      selectedClientProducts.some(
        (selectedProduct: any) => selectedProduct.id === productId.id
      )
    ) {
      // If selected, remove it from the list
      setSelectedClientProducts((prevSelected: any) =>
        prevSelected.filter(
          (selectedProduct: any) => selectedProduct.id !== productId.id
        )
      );
    } else {
      // If not selected, add it to the list
      setSelectedClientProducts((prevSelected: any) => [
        ...prevSelected,
        productId,
      ]);
    }
  };
  const checkCollection = async () => {
    // Do something with the selected product IDs

    try {
      const token = localStorage.getItem("token");

      // Use Promise.all to wait for all requests to complete
      const requests = selectedClientProducts.map((element: any) => {
        return axiosInstance({
          method: "get",
          url: `/article_options/${element.id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      });

      const responses = await Promise.all(requests);

      // Filter and update state based on response
      const newData = responses
        .filter(
          (response: any) =>
            response.status === 200 &&
            response.data.wash === null &&
            response.data.size === null
        )
        .map((response: any) => response.data);

      setClientProductRequird([...clientProductRequird, ...newData]);

      if (newData.length > 0) {
        setIsOpenCheckClientProduct(true);
        setFormErrors([]);
      } else {
        setIsOpenDrawer(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [option, setOption] = useState<any>({
    type: "",
    name: "",
    style: "",
    fabric: "",
    wash: "",
    trims: "",
    thread: "",
    qty: "",
    size_qty: "",
    crotch: "",
    delivery_date: "",
  });

  const [files, setFiles] = useState<any>({
    style_file: null,
    fabric_file: null,
    wash_file: null,
    trims_file: null,
    thread_file: null,
  });

  const [files2, setFiles2] = useState<any>([]);

  const [alerts, setAlerts] = useState<any>(null);
  const attachFile = (name: any, file: any) => {
    setFiles({ ...files, [name]: file });
  };

  const attachFile2 = (name: any, file: any) => {
    setFiles2({ ...files2, [name]: file });
  };

  const detachFile2 = (fieldName: any) => {
    setFiles2({ ...files2, [fieldName]: null });
  };

  // Detach file
  const detachFile = (fieldName: any) => {
    setFiles({ ...files, [fieldName]: null });
  };
  var keys = ["style", "fabric", "wash", "size", "length"];

  const selectHandleChange = (e: any) => {
    setOption({
      ...option,
      [selectedSelect]: selectedSelect === "qty" ? +e : e,
    });
  };

  const selectHandleChangType = (value: any) => {
    console.log(value);
    setOption({ ...option, type: value });
  };

  const selectHandleChangeWashes = (value: any) => {
    console.log(value);
    setOption({ ...option, wash: value });
  };

  const selectHandleChangeStyle = (value: any) => {
    console.log(value);
    setOption({ ...option, style: value.target.value });
  };
  const selectHandleChangeFabric = (value: any) => {
    setOption({ ...option, fabric: value.target.value });
  };

  const selectHandleChangeSize = (value: any) => {
    setOption({ ...option, size_qty: value.target.value });
  };

  const selectHandleChangeLength = (value: any) => {
    setOption({ ...option, crotch: value.target.value });
  };

  const getSelectName = (name: string) => {
    setSelectedSelect(name);
  };

  const handleChangeOption = (e: any) => {
    setOption({
      ...option,
      [e.target.name]: e.target.value,
      [e.target.size_qty]: e.target.value,
      [e.target.crotch]: e.target.value,
      [e.target.delivery_date]: e.target.value,
    });
  };

  const addToCollection = () => {
    console.log(files);
    if (
      option.name != "" &&
      option.type != "" &&
      option.style !== "" &&
      option.fabric !== "" &&
      option.wash !== "" &&
      option.size_qty !== ""
    ) {
      let formData = new FormData();
      // formData.append("details", JSON.stringify(data));
      formData.append("order_name", selectedOrder?.name);
      formData.append("folder_id", selectedOrder?.id);

      Object.keys(files)
        .filter((el: any) => files[el])
        .map((el: any) => formData.append(el, files[el]));
      Object.keys(option)
        .filter((el: any) => option[el])
        .map((el: any) => formData.append(el, option[el]));

      dispatch(createNewDirectProduct(formData));
      handleOrderClick(selectedOrder?.id);
      setOption({
        type: "",
        name: "",
        style: "",
        fabric: "",
        wash: "",
        trims: "",
        thread: "",
        qty: "",
        size_qty: "",
        crotch: "",
        delivery_date: "",
      });
      hideModal();
    } else {
      setFieldRequird({
        name: option.name === "",
        type: option.type === "",
        style: option.style === "",
        fabric: option.fabric === "",
        wash: option.wash === "",
        size_qty: option.size_qty === "",
        crotch: option.crotch === "",
      });
    }
  };

  const editCollection = (id: any) => {
    let formData = new FormData();
    // formData.append("details", JSON.stringify(data));
    formData.append("order_name", selectedOrder?.name);
    formData.append("folder_id", selectedOrder?.id);

    Object.keys(files)
      .filter((el: any) => files[el])
      .map((el: any) => formData.append(el, files[el]));
    Object.keys(option)
      .filter((el: any) => option[el])
      .map((el: any) => formData.append(el, option[el]));

    // dispatch(updateDirectProduct(formData, id));

    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/article_options/updateDirectProduct/${id}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        setOption({
          type: "",
          name: "",
          style: "",
          fabric: "",
          wash: "",
          trims: "",
          thread: "",
          qty: "",
          size_qty: "",
          crotch: "",
          delivery_date: "",
        });
        MyNotif("Option updated successfully", "success");

        hideModal();
        handleOrderClick(selectedOrder?.id);
      }
    });
  };

  const editCollectionClient = (id: any) => {
    let formData = new FormData();
    // formData.append("details", JSON.stringify(data));
    formData.append("order_name", selectedOrder?.name);
    formData.append("folder_id", selectedOrder?.id);

    Object.keys(files)
      .filter((el: any) => files[el])
      .map((el: any) => formData.append(el, files[el]));
    Object.keys(option)
      .filter((el: any) => option[el])
      .map((el: any) => formData.append(el, option[el]));

    /*  dispatch(updateDirectProduct(formData, id)); */
    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/article_options/updateDirectProduct/${id}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        setOption({
          type: "",
          name: "",
          style: "",
          fabric: "",
          wash: "",
          trims: "",
          thread: "",
          qty: "",
          size_qty: "",
          crotch: "",
          delivery_date: "",
        });
        MyNotif("Option updated successfully", "success");

        setOpenClientProduct(false);
        handleOrderClick(selectedOrder?.id);
      }
    });
  };

  const addToCollectionClientProduct = () => {
    let formData = new FormData();
    // formData.append("details", JSON.stringify(data));
    formData.append("order_name", selectedOrder?.name);
    formData.append("folder_id", selectedOrder?.id);

    formData.append("type", "denim");
    Object.keys(files)
      .filter((el: any) => files[el])
      .map((el: any) => formData.append(el, files[el]));
    Object.keys(option)
      .filter((el: any) => option[el])
      .map((el: any) => formData.append(el, option[el]));
    dispatch(createNewClientProduct(formData));
    handleOrderClick(selectedOrder?.id);
    hideModalClientProduct();
  };

  const [year, setYear] = useState("");

  const [gender, setGender] = useState("");

  const [theme, setTheme] = useState("");
  const [disable, setDisable] = useState(false);
  const [collection_name, setOrderName] = useState("");
  const [orderDetails, setOrderDetails] = useState("");
  const [collaborator, setCollaborator] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const [selected, setSelected] = useState<any>(null);

  const [formData, setFormData] = useState<any>([]);

  const handleChange2 = (index: any, field: any, value: any, id: any) => {
    // Update the state with the new input value
    setFormData((prevData: any) => {
      const newData = [...prevData];
      newData[index] = {
        ...(newData[index] || {}), // Preserve existing data
        [field]: value,
        id,
      };
      return newData;
    });
  };

  // Function to handle form submission
  const handleSubmitMoreInfo = () => {
    // Access all input values from formData

    let files2Array: any[] = []; // Helper function to extract the file type (style or fabric)
    const extractFileType = (key: string): string => {
      if (key.includes("style")) {
        return "style_file";
      } else if (key.includes("fabric")) {
        return "fabric_file";
      } else if (key.includes("wash")) {
        return "wash_file";
      }
      return ""; // Default case, handle appropriately based on your needs
    };

    // Loop through files and organize them in the result array
    for (const key in files2) {
      const fileId: string = key.split("_")[0]; // Extracting the identifier (517, 518, etc.)
      const fileType: string = extractFileType(key);

      // Check if the entry for this identifier exists in the result array
      const existingEntry: any | undefined = files2Array.find(
        (entry) => entry.id === fileId
      );

      if (existingEntry) {
        // If the entry exists, add the file to the existing entry
        existingEntry[fileType] = files2[key];
      } else {
        // If the entry doesn't exist, create a new entry
        const newEntry: Record<string, any> = {
          id: fileId,
          [fileType]: files2[key],
        };
        files2Array.push(newEntry);
      }
    }

    console.log("Form Data:", formData);
    console.log("Uploaded Files:", files2Array);

    const errors = clientProductRequird.map((el: any, index: any) => {
      const errorFields = [];
      console.log(!formData[index]?.style);
      if (!formData[index]?.isSimple) {
        errorFields.push("isSimple");
      }
      if (!formData[index]?.style) {
        errorFields.push("Style");
      }

      if (!formData[index]?.fabric) {
        errorFields.push("Fabric");
      }
      if (!formData[index]?.wash) {
        errorFields.push("Wash");
      }
      if (!formData[index]?.size_qty) {
        errorFields.push("size_qty");
      }
      if (!formData[index]?.crotch) {
        errorFields.push("crotch");
      }

      return {
        id: el.id,
        errors: errorFields,
      };
    });
    // Display error messages
    setFormErrors(errors);
    console.log(errors);

    if (errors.every((error: any) => error.errors.length === 0)) {
      let token = localStorage.getItem("token");
      axiosInstance({
        method: "post",
        url: `/article_options/add_more_info_client_product`,
        data: {
          formData: formData,
          files2Array: files2Array,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        //window.location.href = `/my-orders`;
        setIsOpenCheckClientProduct(false);
        setFormErrors([]);
        setIsOpenDrawer(true);
      });
    }

    // Perform any other actions with the data
  };

  const [open, setOpen] = useState(false);
  const [openClientProduct, setOpenClientProduct] = useState(false);

  const [optionName, setOptionName] = useState("");

  const handleChange = (e: any) => {
    console.log(e.target.value);
    setOption({ ...option, name: e.target.value });
  };

  const showModal = () => {
    setMode(true);
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };

  const showModalClientProduct = () => {
    setMode(true);
    setOpenClientProduct(true);
  };
  const hideModalClientProduct = () => {
    setOpenClientProduct(false);
  };

  const { collaborators, newOrder, orders, createOrderLoading, selectedOrder } =
    useSelector((state: any) => state.orders);

  useEffect(() => {
    dispatch(selectOrder(selected));
  }, [selected]);
  const handleOrderClick = async (selectedOrder: any) => {
    let token = localStorage.getItem("token");

    const response = await axiosInstance({
      method: "get",
      url: `/folders/` + selectedOrder,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSelected(response.data);
    setSelectedClientProducts([]);
  };

  const handleCollabChange = (e: any) => {
    let arryColl: any = [];
    e &&
      e.map((el: any) => {
        arryColl.push(el.value);
      });
    setCollaborator(arryColl);
  };

  const getMyCustomizedProducts = async () => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      url: "/customized_products/getdraft",
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { data: customized_products, isLoading } = useQuery(
    "my-customized",
    getMyCustomizedProducts
  );

  useEffect(() => {
    // fetch new state of productsToOrder here
  }, [productsToOrder]);

  function handleChangeDeliveryDate(e: any) {
    setDeliveryDate(e.target.value);

    console.log(date_delivery);
  }

  function handleChangeTheme(e: any) {
    setTheme(e.target.value);
  }
  function handleChangeGender(e: any) {
    console.log(e);

    setGender(e);
    console.log(gender);
  }

  function handleChangeYear(e: any) {
    setYear(e.target.value);
  }
  function handleChangeSaison(e: any) {
    console.log(e);
    setSaison(e);
  }

  function handleChangeOrderName(e: any) {
    setOrderName(e.target.value);
  }

  function handleChangeDetails(e: any) {
    setOrderDetails(e.target.value);
  }

  const handleRemoveDirectProduct = (itemToRemove: any) => {
    const updatedProducts = selectedDirectProducts.filter(
      (item: any) => item !== itemToRemove
    );
    setSelectedDirectProducts(updatedProducts);
  };

  const handleRemoveClientProduct = (itemToRemove: any) => {
    const updatedProducts = selectedClientProducts.filter(
      (item: any) => item !== itemToRemove
    );
    setSelectedClientProducts(updatedProducts);
  };

  const handleRemoveDemcoProduct = (itemToRemove: any) => {
    const updatedProducts = selectedDemcoProducts.filter(
      (item: any) => item !== itemToRemove
    );
    setSelectedDemcoProducts(updatedProducts);
  };

  const handleRemoveCustomizedProduct = (itemToRemove: any) => {
    const updatedProducts = selectedCustomizedProducts.filter(
      (item: any) => item !== itemToRemove
    );
    setSelectedCustomizedProducts(updatedProducts);
  };

  const queryClient = useQueryClient();

  function handleChangeSeason(e: any) {
    setSaison(e.target.value);
  }
  const orderNow = (productsToOrder: any) => {
    if (date_delivery != "") {
      setDisable(true);
      console.log(disable);

      let token = localStorage.getItem("token");
      axiosInstance({
        method: "post",
        url: `/launch_order`,
        data: {
          selectedClientProducts: selectedClientProducts,
          selectedDirectProducts: selectedDirectProducts,
          selectedDemcoProducts: selectedDemcoProducts,
          selectedCustomizedProducts: selectedCustomizedProducts,
          collection_name: selectedOrder?.name,
          date_delivery,
          collaborator,
          saison: selectedOrder?.season,
          folder_id: selectedOrder?.id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        if (response.status === 200) {
          setDisable(false);
          setIsOpenDrawer(false);

          window.location.href = `/my-orders`;
        }
      });
    } else {
      setIsRequired(true);
    }
  };

  /*   const { mutateAsync: orderNow, isLoading: orderLoading } = useMutation(
      orderNewCustomizedProduct,
      {
        onSuccess: () => {
          queryClient.invalidateQueries("my-customized");
          setProductsToOrder([]);
        },
      }
    ); */

  const { mutateAsync: deleteProducts, isLoading: deleteLoading } = useMutation(
    deleteCustomizedProduct,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("my-customized");
        setProductsToOrder([]);
      },
    }
  );

  const deleteDirectProduct = async (id: any) => {
    let token = localStorage.getItem("token");

    const response = await axiosInstance({
      method: "post",
      url: `/article_options/removeDirectProduct/` + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        MyNotif("Delete with sucess", "success");
        handleOrderClick(selectedOrder?.id);
      } else {
        MyNotif("Error", "danger");
      }
    });
  };

  const deleteDemcoProduct = async (id: any) => {
    let token = localStorage.getItem("token");

    const response = await axiosInstance({
      method: "delete",
      url: `/folders/${selectedOrder?.id}/products`,
      data: {
        products: [id],
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        MyNotif("Delete with sucess", "success");
        handleOrderClick(selectedOrder?.id);
      } else {
        MyNotif("Error", "danger");
      }
    });
  };

  const deleteCustomProduct = async (id: any) => {
    let token = localStorage.getItem("token");

    const response = await axiosInstance({
      method: "delete",
      url: `/customized_products/deleteDraft`,
      data: {
        customized_product_ids: [id],
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        MyNotif("Delete with sucess", "success");
        handleOrderClick(selectedOrder?.id);
      } else {
        MyNotif("Error", "danger");
      }
    });
  };

  const getProduct = async (id: any) => {
    let token = localStorage.getItem("token");

    const response = await axiosInstance({
      method: "get",
      url: `/article_options/${id}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        setInputValueOptioName(response.data.name);
        setInputValueOptionType(response.data.type);
        setInputValueOptionStyleName(response.data.style);
        setInputValueOptionFabricName(response.data.fabric);
        setInputValueOptionWash(response.data.wash);

        setInputValueOptionSize(response.data.size_qty);

        setInputValueOptionLength(response.data.length);

        setSelectedObject(response.data);
      } else {
        MyNotif("Error", "danger");
      }
    });
  };
  const cancel = (e: any) => {};
  return (
    <div
      style={{ padding: "20px", paddingBottom: "50px" }}
      className="selection_container"
    >
      {selectedOrder?.id ? (
        <>
          {" "}
          <div>
            {selectedOrder &&
              (selectedOrder?.products?.length ||
                selectedOrder?.direct_products?.length ||
                selectedOrder?.client_products?.length ||
                selectedOrder?.customized_products?.length) > 0 && (
                <div
                  className="row"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>
                    <span className={"title_myselection"}>
                      {selectedOrder?.name}
                    </span>
                  </div>
                  <div>
                    <div>
                      {/*    <button
                          className="selectAll"
                          onClick={(e: any) => {
                            if (
                                selectedCustomizedProducts.length > 0 ||
                                selectedDemcoProducts.length > 0 ||
                                selectedDirectProducts.length > 0 ||
                                selectedClientProducts.length > 0
                            ) {
                              e.preventDefault();
                              checkCollection();
                            } else {
                              MyNotif("Please Select a product", "danger");
                            }
                          }}>
                        <Bag/>
                        <span style={{marginLeft: "10px"}}>
                          Launch Collection
                        </span>
                      </button>*/}
                      {!user.is_client_clients && (
                        <>
                          <button
                            className={"launch_collection"}
                            onClick={(e: any) => {
                              if (
                                selectedCustomizedProducts.length > 0 ||
                                selectedDemcoProducts.length > 0 ||
                                selectedDirectProducts.length > 0 ||
                                selectedClientProducts.length > 0
                              ) {
                                e.preventDefault();
                                checkCollection();
                              } else {
                                MyNotif("Please Select a product", "danger");
                              }
                            }}
                          >
                            <SendOutlined className={"icon_send"} />
                            <span
                              style={{ marginLeft: "10px" }}
                              className={"text_button"}
                            >
                              Launch Collection
                            </span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            <br />

            {selectedOrder && (
              <>
                <div style={{ overflowX: "hidden" }}>
                  <div className={"grid-container-collection"}>
                    <div>
                      <div
                        className={isToggledOne ? "divCol_0" : "divCol"}
                        key={1}
                      >
                        <div className="divColHead" key={1}>
                          {isToggledOne ? (
                            <div
                              onClick={() => setIsToggledOne(!isToggledOne)}
                              style={{ cursor: "pointer", fontWeight: 600 }}
                            >
                              <RightOutlined />
                            </div>
                          ) : (
                            <div
                              onClick={() => setIsToggledOne(!isToggledOne)}
                              style={{ cursor: "pointer", fontWeight: 600 }}
                            >
                              <DownOutlined />{" "}
                            </div>
                          )}
                          <div className={"size_title"}>
                            Direct Products (
                            {selectedOrder?.direct_products?.length})
                          </div>
                          <button className="btndivColHead" onClick={showModal}>
                            <PlusOutlined style={{ fontSize: "16px" }} />
                          </button>
                        </div>

                        <div className=" divCol2 scrol_order">
                          {selectedOrder?.direct_products?.map(
                            (product: any) => {
                              return (
                                <>
                                  <Card
                                    style={{
                                      width: "100%",
                                      paddingTop: 15,
                                      borderRadius: "16px",
                                    }}
                                    bordered={true}
                                    className="grid-item-product"
                                    cover={
                                      <div className={`product_item_new mb-2`}>
                                        <LazyImg
                                          alt={product.name}
                                          style={{
                                            position: "absolute",
                                            zIndex: 2,
                                            opacity: 0.2,
                                            objectFit: "contain",
                                            width: "100%",
                                            height: "260px",
                                          }}
                                          src={watermak}
                                          thumb={watermak}
                                        />
                                        <LazyImg
                                          className="img-front"
                                          alt={product?.name}
                                          style={{
                                            objectFit: "contain",
                                            width: "100%",
                                            height: "260px",
                                          }}
                                          src={placeholder_jeans}
                                          thumb={placeholder_jeans}
                                        />
                                        <LazyImg
                                          style={{
                                            height: "260px",
                                            objectFit: "contain",
                                          }}
                                          className="img-back"
                                          alt={product?.name}
                                          src={placeholder_jeans}
                                          thumb={placeholder_jeans}
                                        />
                                      </div>
                                    }
                                  >
                                    <Popconfirm
                                      title="Delete the product"
                                      onConfirm={(e: any) => {
                                        deleteDirectProduct(product.id);
                                      }}
                                      onCancel={cancel}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <DeleteOutlined
                                        style={{
                                          position: "absolute",
                                          top: 10,
                                          right: 10,
                                          zIndex: 3, // Ensure the delete button is above other content
                                          fontSize: "24px",
                                          cursor: "pointer",
                                          //   color:"red"
                                        }}
                                      />
                                    </Popconfirm>

                                    <Meta
                                      description={
                                        <div
                                          className="d-flex mt-2 justify-content-between"
                                          style={{
                                            height: "30px",
                                            // justifyContent: "center",
                                          }}
                                        >
                                          <div className="labels_list">
                                            <div>
                                              <h4
                                                className={"size_title"}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  setMode(false);
                                                  setOpen(true);
                                                  getProduct(product?.id);
                                                }}
                                              >
                                                {product?.name}
                                              </h4>
                                            </div>
                                          </div>
                                          <div className="align-self-center">
                                            <Checkbox
                                              checked={selectedDirectProducts.some(
                                                (selectedProduct: any) =>
                                                  selectedProduct.id ===
                                                  product.id
                                              )}
                                              onChange={() =>
                                                handleCheckboxChangeDirectProduct(
                                                  product
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      }
                                    />
                                  </Card>
                                  <br />
                                </>
                              );
                            }
                          )}
                        </div>
                        <div className="">
                          <Drawer
                            className="order_sent_drawer_customized"
                            placement={"bottom"}
                            closable={false}
                            onClose={hideModal}
                            visible={open}
                            key={"bottom"}
                            style={{ zIndex: mode ? 99 : 1001 }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center "
                              style={{ marginTop: "25px" }}
                            >
                              {mode == true && (
                                <h1 className={"title_model_mycollecion"}>
                                  Add New Direct product
                                </h1>
                              )}
                              {mode == false && (
                                <h1 className={"title_model_mycollecion"}>
                                  Edit product
                                </h1>
                              )}
                            </div>
                            <Divider />

                            <div
                              className="my-2 scrol_order"
                              style={{
                                padding: "0px 25px 25px 25px",
                              }}
                            >
                              <>
                                <div className=" one_option_details col-12">
                                  <label
                                    className="my-2 label_drawer_mycollection"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    Type
                                  </label>
                                </div>
                                <div className="option_details_select_wrapper">
                                  <Select
                                    style={{
                                      width: "100%",
                                    }}
                                    // onChange={selectHandleChangType}

                                    onChange={(e: any) => {
                                      if (mode == false)
                                        handleInputChangeOptionType(e);
                                      else selectHandleChangType(e);
                                    }}
                                    value={
                                      mode == false
                                        ? inputValueOptionType
                                        : undefined
                                    }
                                    dropdownStyle={{
                                      borderRadius: 10,
                                      fontWeight: 600,
                                      fontSize: 20,
                                    }}
                                    suffixIcon={() => (
                                      <FontAwesomeIcon icon={faCaretDown} />
                                    )}
                                  >
                                    <>
                                      <Option value="denim">Denim</Option>
                                      <Option value="knit">Knit</Option>
                                    </>
                                  </Select>
                                  {fieldRequird.type && (
                                    <span style={{ color: "red" }}>
                                      Type is required
                                    </span>
                                  )}

                                  <div className="row one_option_details">
                                    <div className="col-12">
                                      <label
                                        className="my-2 label_drawer_mycollection"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Name
                                      </label>
                                    </div>

                                    <input
                                      type="text"
                                      placeholder=""
                                      name="name"
                                      className="option_details_input option_details_title option_title_input_new label_drawer_mycollection ml-1 mb-0 ml-md-0"
                                      onChange={(e: any) => {
                                        if (mode == false)
                                          handleInputChangeOptionName(e);
                                        else {
                                          setOption({
                                            ...option,
                                            name: e.target.value,
                                          });
                                        }
                                      }}
                                      defaultValue={
                                        mode == false ? inputValueOptioName : ""
                                      }
                                    />
                                    {fieldRequird.name && (
                                      <span
                                        style={{ color: "red" }}
                                        className={"label_drawer_mycollection"}
                                      >
                                        Name is required
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-7 mt-3 one_option_details">
                                  <Radio.Group
                                    name="radiogroup"
                                    defaultValue={2}
                                    onChange={(e: any) => console.log(e)}
                                    buttonStyle="solid"
                                    id={"sample_3d"}
                                  >
                                    <Radio.Button value={2}>
                                      Sample
                                    </Radio.Button>
                                    <Radio.Button
                                      value={1}
                                      style={{
                                        width: "100px",
                                        textAlign: "center",
                                      }}
                                    >
                                      3D
                                    </Radio.Button>
                                  </Radio.Group>
                                </div>
                                <div className="row one_option_details">
                                  <div className="col-12">
                                    <label
                                      className="my-2 label_drawer_mycollection"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Style
                                    </label>
                                  </div>
                                  <div className="col-12 col-md-7 option_details_select_wrapper">
                                    <input
                                      type="text"
                                      name={"style"}
                                      className={`option_details_input label_drawer_mycollection`}
                                      //  onChange={(e: any)=>selectHandleChangeStyle(e)}

                                      onChange={(e: any) => {
                                        if (mode == false)
                                          handleInputChangeStyleName(e);
                                        else {
                                          selectHandleChangeStyle(e);
                                        }
                                      }}
                                      defaultValue={
                                        mode == false
                                          ? inputValueOptionStyleName
                                          : ""
                                      }
                                    />

                                    {fieldRequird.style && (
                                      <span
                                        style={{ color: "red" }}
                                        className={"label_drawer_mycollection"}
                                      >
                                        Style is required
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-12 col-md-5 mt-3 mt-md-0">
                                    <DropZoneFile
                                      setAlerts={setAlerts}
                                      attachFile={attachFile}
                                      detachFile={detachFile}
                                      optionKey={"style"}
                                      files={files}
                                    />
                                    {mode == false && (
                                      <span
                                        style={{ opacity: 0.4 }}
                                        className={"label_drawer_mycollection"}
                                      >
                                        (if not changed you previous file will
                                        be attached)
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-12">
                                    <label
                                      className="my-2 label_drawer_mycollection"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Fabric
                                    </label>
                                  </div>
                                  <div className="col-12 col-md-7 option_details_select_wrapper">
                                    <input
                                      type="text"
                                      name={"fabric"}
                                      className={`option_details_input label_drawer_mycollection`}
                                      onChange={(e: any) => {
                                        if (mode == false)
                                          handleInputChangeFabricName(e);
                                        else {
                                          selectHandleChangeFabric(e);
                                        }
                                      }}
                                      defaultValue={
                                        mode == false
                                          ? inputValueOptionFabricName
                                          : ""
                                      }
                                    />
                                    {fieldRequird.fabric && (
                                      <span
                                        style={{ color: "red" }}
                                        className={"label_drawer_mycollection"}
                                      >
                                        Fabric is required
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-12 col-md-5 mt-3 mt-md-0">
                                    <DropZoneFile
                                      setAlerts={setAlerts}
                                      attachFile={attachFile}
                                      detachFile={detachFile}
                                      optionKey={"fabric"}
                                      files={files}
                                    />
                                    {mode == false && (
                                      <span
                                        style={{ opacity: 0.4 }}
                                        className={"label_drawer_mycollection"}
                                      >
                                        (if not changed you previous file will
                                        be attached)
                                      </span>
                                    )}
                                  </div>{" "}
                                  <div className="col-12">
                                    <label
                                      className="my-2 label_drawer_mycollection"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Wash
                                    </label>
                                  </div>
                                  <div className="col-12 col-md-7 option_details_select_wrapper">
                                    <Select
                                      //  onChange={selectHandleChangeWashes}
                                      onChange={(e: any) => {
                                        if (mode == false)
                                          handleInputChangeWash(e);
                                        else {
                                          selectHandleChangeWashes(e);
                                        }
                                      }}
                                      value={
                                        mode == false
                                          ? inputValueOptionWash
                                          : undefined
                                      }
                                      dropdownStyle={{
                                        borderRadius: 10,
                                        fontWeight: 600,
                                        fontSize: 20,
                                      }}
                                      suffixIcon={() => (
                                        <FontAwesomeIcon icon={faCaretDown} />
                                      )}
                                      style={{ width: "100%" }}
                                    >
                                      {washes.map((el: any, i: number) => (
                                        <Option value={el.name} key={el.id}>
                                          {el.name}
                                        </Option>
                                      ))}
                                    </Select>
                                    {fieldRequird.wash && (
                                      <span
                                        style={{ color: "red" }}
                                        className={"label_drawer_mycollection"}
                                      >
                                        Wash is required
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-12 col-md-5 mt-3 mt-md-0">
                                    <DropZoneFile
                                      setAlerts={setAlerts}
                                      attachFile={attachFile}
                                      detachFile={detachFile}
                                      optionKey={"wash"}
                                      files={files}
                                    />
                                    {mode == false && (
                                      <span
                                        style={{ opacity: 0.4 }}
                                        className={"label_drawer_mycollection"}
                                      >
                                        (if not changed you previous file will
                                        be attached)
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-12">
                                    <label
                                      className="my-2 label_drawer_mycollection"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Size
                                    </label>
                                  </div>
                                  <div className="col-12 col-md-7 option_details_select_wrapper">
                                    <input
                                      type="text"
                                      name="size"
                                      className={`option_details_input label_drawer_mycollection`}
                                      onChange={(e: any) => {
                                        if (mode == false)
                                          handleInputChangeSize(e);
                                        else {
                                          selectHandleChangeSize(e);
                                        }
                                      }}
                                      defaultValue={
                                        mode == false
                                          ? inputValueOptionSize
                                          : ""
                                      }
                                    />
                                    {fieldRequird.size_qty && (
                                      <span
                                        style={{ color: "red" }}
                                        className={"label_drawer_mycollection"}
                                      >
                                        Size is required
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-12">
                                    <label
                                      className="my-2 label_drawer_mycollection"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Length
                                    </label>
                                  </div>
                                  <div className="col-12 col-md-7 option_details_select_wrapper">
                                    <input
                                      type="text"
                                      name={"length"}
                                      className={`option_details_input label_drawer_mycollection`}
                                      onChange={(e: any) => {
                                        if (mode == false)
                                          handleInputChangeLength(e);
                                        else {
                                          selectHandleChangeLength(e);
                                        }
                                      }}
                                      defaultValue={
                                        mode == false
                                          ? inputValueOptionLength
                                          : ""
                                      }
                                    />
                                    {fieldRequird.crotch && (
                                      <span
                                        style={{ color: "red" }}
                                        className={"label_drawer_mycollection"}
                                      >
                                        Length is required
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            </div>

                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ gap: "20px" }}
                            >
                              <button
                                //   innerButton="Cancel"
                                onClick={hideModal}
                                className="submit_button_white  "
                              >
                                Discard
                              </button>
                              {mode == true && (
                                <button
                                  onClick={addToCollection}
                                  className="submit_button_add_to_collection"
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <Spin
                                      indicator={
                                        <LoadingOutlined
                                          style={{
                                            fontSize: 24,
                                            color: "#03468c",
                                          }}
                                          spin
                                        />
                                      }
                                    />
                                  ) : (
                                    "Add to collection"
                                  )}
                                </button>
                              )}
                              {mode == false && (
                                <button
                                  onClick={() =>
                                    editCollection(selectedObject.id)
                                  }
                                  className="submit_button_add_to_collection"
                                  disabled={isLoading}
                                >
                                  {isLoading ? (
                                    <Spin
                                      indicator={
                                        <LoadingOutlined
                                          style={{
                                            fontSize: 24,
                                            color: "#03468c",
                                          }}
                                          spin
                                        />
                                      }
                                    />
                                  ) : (
                                    "Edit product"
                                  )}
                                </button>
                              )}
                            </div>
                          </Drawer>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className={isToggledTwo ? "divCol_0" : "divCol"}
                        key={2}
                      >
                        <div className="divColHead" key={2}>
                          {isToggledTwo ? (
                            <div
                              onClick={() => setIsToggledTwo(!isToggledTwo)}
                              style={{ cursor: "pointer", fontWeight: "bold" }}
                            >
                              <RightOutlined />
                            </div>
                          ) : (
                            <div
                              onClick={() => setIsToggledTwo(!isToggledTwo)}
                              style={{ cursor: "pointer", fontWeight: "bold" }}
                            >
                              <DownOutlined />{" "}
                            </div>
                          )}
                          <div className={"size_title"}>
                            Client Products (
                            {selectedOrder?.client_products?.length})
                          </div>
                          <button
                            className="btndivColHead"
                            onClick={showModalClientProduct}
                          >
                            <PlusOutlined style={{ fontSize: "16px" }} />
                          </button>
                        </div>
                        <div className=" divCol2 scrol_order">
                          {selectedOrder?.client_products?.map(
                            (product: any) => {
                              return (
                                <>
                                  <Card
                                    style={{
                                      width: "100%",
                                      paddingTop: 15,
                                      borderRadius: "16px",
                                    }}
                                    bordered={true}
                                    className="grid-item-product"
                                    cover={
                                      <div className={`product_item_new mb-2`}>
                                        <LazyImg
                                          alt={product.name}
                                          style={{
                                            position: "absolute",
                                            zIndex: 2,
                                            opacity: 0.2,
                                            objectFit: "contain",
                                            width: "100%",
                                            height: "260px",
                                          }}
                                          src={watermak}
                                          thumb={watermak}
                                        />
                                        <LazyImg
                                          className="img-front"
                                          alt={product?.name}
                                          style={{
                                            objectFit: "contain",
                                            width: "100%",
                                            height: "260px",
                                          }}
                                          src={
                                            product?.front_image_file
                                              ? process.env
                                                  .REACT_APP_STORAGE_URL +
                                                product?.front_image_file
                                              : placeholder_jeans
                                          }
                                          thumb={
                                            product?.front_image_file
                                              ? process.env
                                                  .REACT_APP_STORAGE_URL +
                                                product?.front_image_file
                                              : placeholder_jeans
                                          }
                                        />
                                        <LazyImg
                                          style={{
                                            height: "260px",
                                            objectFit: "contain",
                                          }}
                                          className="img-back"
                                          alt={product?.name}
                                          src={
                                            product?.back_image_file
                                              ? process.env
                                                  .REACT_APP_STORAGE_URL +
                                                product?.back_image_file
                                              : placeholder_jeans
                                          }
                                          thumb={
                                            product?.back_image_file
                                              ? process.env
                                                  .REACT_APP_STORAGE_URL +
                                                product?.back_image_file
                                              : placeholder_jeans
                                          }
                                        />
                                      </div>
                                    }
                                  >
                                    <Popconfirm
                                      title="Delete the product"
                                      onConfirm={(e: any) => {
                                        deleteDirectProduct(product.id);
                                      }}
                                      onCancel={cancel}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <DeleteOutlined
                                        style={{
                                          position: "absolute",
                                          top: 10,
                                          right: 10,
                                          zIndex: 3, // Ensure the delete button is above other content
                                          fontSize: "24px",
                                          cursor: "pointer",
                                          //   color:"red"
                                        }}
                                      />
                                    </Popconfirm>

                                    <Meta
                                      description={
                                        <div
                                          className="d-flex mt-2 justify-content-between"
                                          style={{
                                            height: "30px",
                                            // justifyContent: "center",
                                          }}
                                        >
                                          <div className="labels_list">
                                            <div>
                                              <h4
                                                className={"size_title"}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  setMode(false);
                                                  setOpenClientProduct(true);
                                                  getProduct(product?.id);
                                                }}
                                              >
                                                {product?.name}
                                              </h4>
                                            </div>
                                          </div>
                                          <div className="align-self-center">
                                            <Checkbox
                                              checked={selectedClientProducts.some(
                                                (selectedProduct: any) =>
                                                  selectedProduct.id ===
                                                  product.id
                                              )}
                                              onChange={() =>
                                                handleCheckboxChangeClientProduct(
                                                  product
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      }
                                    />
                                  </Card>
                                  <br />
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>

                      <div className="">
                        <Drawer
                          className="order_sent_drawer_customized"
                          placement={"bottom"}
                          closable={false}
                          onClose={hideModal}
                          visible={openClientProduct}
                          key={"bottom"}
                        >
                          <div className="d-flex align-items-center justify-content-center mt-3">
                            {mode == true && (
                              <h1 className={"title_model_mycollecion"}>
                                Add New Client product
                              </h1>
                            )}
                            {mode == false && (
                              <h1 className={"title_model_mycollecion"}>
                                Edit Client product
                              </h1>
                            )}
                          </div>
                          <Divider />
                          <div
                            className="my-2 scrol_order"
                            style={{
                              padding: "0px 25px 25px 25px",
                            }}
                          >
                            <>
                              <div className=" one_option_details col-12">
                                <label
                                  className="my-2 label_drawer_mycollection"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  Type
                                </label>
                              </div>
                              <div className="option_details_select_wrapper">
                                <Select
                                  style={{
                                    width: "100%",
                                  }}
                                  onChange={(e: any) => {
                                    if (mode == false)
                                      handleInputChangeClientOptionType(e);
                                    else {
                                      selectHandleChangType(e);
                                    }
                                  }}
                                  value={
                                    mode == false
                                      ? inputValueOptionType
                                      : undefined
                                  }
                                  dropdownStyle={{
                                    borderRadius: 10,
                                    fontWeight: 600,
                                    fontSize: 20,
                                  }}
                                  suffixIcon={() => (
                                    <FontAwesomeIcon icon={faCaretDown} />
                                  )}
                                >
                                  <>
                                    <Option value="denim">Denim</Option>
                                    <Option value="knit">Knit</Option>
                                  </>
                                </Select>
                                <div></div>
                                <div className="row one_option_details">
                                  <div className="col-12">
                                    <label
                                      className="my-2 label_drawer_mycollection"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Name
                                    </label>
                                  </div>

                                  <input
                                    type="text"
                                    placeholder=""
                                    name="name"
                                    className="option_details_input option_details_title option_title_input_new ml-1 mb-0 ml-md-0 label_drawer_mycollection"
                                    onChange={(e: any) => {
                                      if (mode == false)
                                        handleInputChangeClientOptionName(e);
                                      else {
                                        setOption({
                                          ...option,
                                          name: e.target.value,
                                        });
                                      }
                                    }}
                                    defaultValue={
                                      mode == false ? inputValueOptioName : ""
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <div className="row one_option_details">
                                    <div className="col-12">
                                      <label
                                        className="my-2 label_drawer_mycollection"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Front image
                                      </label>
                                    </div>
                                  </div>
                                  <DropZoneFile
                                    setAlerts={setAlerts}
                                    attachFile={attachFile}
                                    detachFile={detachFile}
                                    optionKey={"front_image"}
                                    files={files}
                                  />
                                  {mode == false && (
                                    <span
                                      style={{ opacity: 0.4 }}
                                      className={"label_drawer_mycollection"}
                                    >
                                      (if not changed you previous file will be
                                      attached)
                                    </span>
                                  )}
                                </div>
                                <div className="col-6">
                                  <div className="row one_option_details">
                                    <div className="col-12">
                                      <label
                                        className="my-2 label_drawer_mycollection"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Back image
                                      </label>
                                    </div>
                                  </div>

                                  <DropZoneFile
                                    setAlerts={setAlerts}
                                    attachFile={attachFile}
                                    detachFile={detachFile}
                                    optionKey={"back_image"}
                                    files={files}
                                  />
                                  {mode == false && (
                                    <span
                                      style={{ opacity: 0.4 }}
                                      className={"label_drawer_mycollection"}
                                    >
                                      (if not changed you previous file will be
                                      attached)
                                    </span>
                                  )}
                                </div>
                              </div>
                            </>
                          </div>

                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ gap: "20px" }}
                          >
                            <button
                              //   innerButton="Cancel"
                              onClick={hideModalClientProduct}
                              className="submit_button_white discard-mycollecion"
                            >
                              Discard
                            </button>
                            {mode == true && (
                              <button
                                onClick={addToCollectionClientProduct}
                                className="submit_button_add_to_collection "
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        style={{
                                          fontSize: 24,
                                          color: "#03468c",
                                        }}
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  "Add to collection"
                                )}
                              </button>
                            )}
                            {mode == false && (
                              <button
                                onClick={() =>
                                  editCollectionClient(selectedObject.id)
                                }
                                className="submit_button_add_to_collection"
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        style={{
                                          fontSize: 24,
                                          color: "#03468c",
                                        }}
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  "Edit product"
                                )}
                              </button>
                            )}
                          </div>
                        </Drawer>
                      </div>
                    </div>
                    <div>
                      <div
                        className={isToggledThree ? "divCol_0" : "divCol"}
                        key={3}
                      >
                        <div className="divColHead" key={3}>
                          {isToggledThree ? (
                            <div
                              onClick={() => setIsToggledThree(!isToggledThree)}
                              style={{ cursor: "pointer", fontWeight: "bold" }}
                            >
                              <RightOutlined />
                            </div>
                          ) : (
                            <div
                              onClick={() => setIsToggledThree(!isToggledThree)}
                              style={{ cursor: "pointer", fontWeight: "bold" }}
                            >
                              <DownOutlined />{" "}
                            </div>
                          )}
                          <div className={"size_title"}>
                            Demco Products ({selectedOrder?.products?.length})
                          </div>
                          <button
                            className="btndivColHead"
                            onClick={() => dispatch(handleLeftDrawer())}
                          >
                            <PlusOutlined style={{ fontSize: "16px" }} />
                          </button>
                        </div>

                        <div className=" divCol2 scrol_order">
                          {selectedOrder?.products?.map((product: any) => {
                            return (
                              <>
                                <Card
                                  style={{
                                    width: "100%",
                                    paddingTop: 15,
                                    borderRadius: "16px",
                                  }}
                                  bordered={true}
                                  className="grid-item-product"
                                  cover={
                                    <div
                                      className={`product_item_new mb-2`}
                                      style={{ minHeight: "250px" }}
                                    >
                                      <LazyImg
                                        alt={product.name}
                                        style={{
                                          position: "absolute",
                                          zIndex: 2,
                                          opacity: 0.2,
                                          objectFit: "contain",
                                          width: "100%",
                                          height: "260px",
                                        }}
                                        src={watermak}
                                        thumb={watermak}
                                      />
                                      <LazyImg
                                        className="img-front"
                                        alt={product?.name}
                                        style={{
                                          objectFit: "contain",
                                          width: "100%",
                                          height: "260px",
                                        }}
                                        src={
                                          product?.front_image
                                            ? process.env
                                                .REACT_APP_STORAGE_URL +
                                              product?.front_image
                                            : placeholder_jeans
                                        }
                                        thumb={
                                          product?.front_image
                                            ? process.env
                                                .REACT_APP_STORAGE_URL +
                                              product?.front_image
                                            : placeholder_jeans
                                        }
                                      />
                                      <LazyImg
                                        style={{
                                          height: "260px",
                                          objectFit: "contain",
                                        }}
                                        className="img-back"
                                        alt={product?.name}
                                        src={
                                          product?.back_image
                                            ? process.env
                                                .REACT_APP_STORAGE_URL +
                                              product?.back_image
                                            : placeholder_jeans
                                        }
                                        thumb={
                                          product?.back_image
                                            ? process.env
                                                .REACT_APP_STORAGE_URL +
                                              product?.back_image
                                            : placeholder_jeans
                                        }
                                      />
                                    </div>
                                  }
                                >
                                  <Popconfirm
                                    title="Delete the product"
                                    onConfirm={(e: any) => {
                                      deleteDemcoProduct(product.id);
                                    }}
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <DeleteOutlined
                                      style={{
                                        position: "absolute",
                                        top: 10,
                                        right: 10,
                                        zIndex: 3, // Ensure the delete button is above other content
                                        fontSize: "24px",
                                        cursor: "pointer",
                                        //   color:"red"
                                      }}
                                    />
                                  </Popconfirm>
                                  <Meta
                                    description={
                                      <div
                                        className="d-flex mt-2 justify-content-between"
                                        style={{
                                          height: "30px",
                                          // justifyContent: "center",
                                        }}
                                      >
                                        <div className="labels_list">
                                          <div>
                                            <h4 className={"size_title"}>
                                              {product?.name}
                                            </h4>
                                          </div>
                                        </div>
                                        <div className="align-self-center">
                                          <Checkbox
                                            checked={selectedDemcoProducts.some(
                                              (selectedProduct: any) =>
                                                selectedProduct.id ===
                                                product.id
                                            )}
                                            onChange={() =>
                                              handleCheckboxChangeDemcoProduct(
                                                product
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    }
                                  />
                                </Card>
                                <br />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className={isToggledFour ? "divCol_0" : "divCol"}
                        key={4}
                      >
                        <div className="divColHead" key={4}>
                          {isToggledFour ? (
                            <div
                              onClick={() => setIsToggledFour(!isToggledFour)}
                              style={{ cursor: "pointer", fontWeight: "bold" }}
                            >
                              <RightOutlined />
                            </div>
                          ) : (
                            <div
                              onClick={() => setIsToggledFour(!isToggledFour)}
                              style={{ cursor: "pointer", fontWeight: "bold" }}
                            >
                              <DownOutlined />{" "}
                            </div>
                          )}
                          <div className={"size_title"}>
                            Customized Products (
                            {selectedOrder?.customized_products?.length})
                          </div>
                          <button
                            className="btndivColHead"
                            //  onClick={showModalClientProduct}
                            onClick={() => dispatch(handleLeftDrawer())}
                          >
                            <PlusOutlined style={{ fontSize: "16px" }} />
                          </button>
                        </div>

                        <div className=" divCol2 scrol_order">
                          {selectedOrder?.customized_products?.map(
                            (product: any) => {
                              return (
                                <>
                                  <Card
                                    style={{
                                      width: "100%",
                                      paddingTop: 15,
                                      borderRadius: "16px",
                                    }}
                                    bordered={true}
                                    className="grid-item-product"
                                    cover={
                                      <div
                                        className={`product_item_new mb-2`}
                                        style={{ minHeight: "250px" }}
                                      >
                                        <LazyImg
                                          alt={product.name}
                                          style={{
                                            position: "absolute",
                                            zIndex: 2,
                                            opacity: 0.2,
                                            objectFit: "contain",
                                            width: "100%",
                                            height: "260px",
                                          }}
                                          src={watermak}
                                          thumb={watermak}
                                        />
                                        <LazyImg
                                          className="img-front"
                                          alt={product?.name}
                                          style={{
                                            objectFit: "contain",
                                            width: "100%",
                                            height: "260px",
                                          }}
                                          src={
                                            product?.images?.path
                                              ? process.env
                                                  .REACT_APP_STORAGE_URL +
                                                product?.images.path
                                              : placeholder_jeans
                                          }
                                          thumb={
                                            product?.images?.path
                                              ? process.env
                                                  .REACT_APP_STORAGE_URL +
                                                product?.images?.path
                                              : placeholder_jeans
                                          }
                                        />
                                        <LazyImg
                                          style={{
                                            height: "260px",
                                            objectFit: "contain",
                                          }}
                                          className="img-back"
                                          alt={product?.name}
                                          src={
                                            product?.images?.path
                                              ? process.env
                                                  .REACT_APP_STORAGE_URL +
                                                product?.images?.path
                                              : placeholder_jeans
                                          }
                                          thumb={
                                            product?.back
                                              ? process.env
                                                  .REACT_APP_STORAGE_URL +
                                                product?.images.path
                                              : placeholder_jeans
                                          }
                                        />
                                      </div>
                                    }
                                  >
                                    <Popconfirm
                                      title="Delete the product"
                                      onConfirm={(e: any) => {
                                        deleteCustomProduct(product);
                                      }}
                                      onCancel={cancel}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <DeleteOutlined
                                        style={{
                                          position: "absolute",
                                          top: 10,
                                          right: 10,
                                          zIndex: 3, // Ensure the delete button is above other content
                                          fontSize: "24px",
                                          cursor: "pointer",
                                          //   color:"red"
                                        }}
                                      />
                                    </Popconfirm>
                                    <Meta
                                      description={
                                        <div
                                          className="d-flex mt-2 justify-content-between"
                                          style={{
                                            height: "30px",
                                            //justifyContent: "center",
                                          }}
                                        >
                                          <div className="labels_list">
                                            <div>
                                              <h4 className={"size_title"}>
                                                {product?.name}
                                              </h4>
                                            </div>
                                          </div>
                                          <div className="align-self-center">
                                            <Checkbox
                                              checked={selectedCustomizedProducts.some(
                                                (selectedProduct: any) =>
                                                  selectedProduct.id ===
                                                  product.id
                                              )}
                                              onChange={() =>
                                                handleCheckboxChangeCustomizedProduct(
                                                  product
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      }
                                    />
                                  </Card>
                                  <br />
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : selectedOrder?.message ? (
        <>
          {selectedOrder?.folder?.request_waiting !== undefined &&
          !selectedOrder?.folder?.request_waiting ? (
            <>
              <div className="empty_collection ">
                <img
                  src={request_acess}
                  alt="foders image"
                  className="mb-2 folders_image"
                />

                <h2 className="collection_welcome mb-2">
                  You need access to enter this collection
                </h2>
                {!is_sent ? (
                  <>
                    <button
                      className="request_access_btn"
                      onClick={() => {
                        sendFolderAccessRequest(
                          selectedOrder?.folder?.id,
                          user.id
                        );
                      }}
                    >
                      Request access
                    </button>
                  </>
                ) : (
                  <>
                    <p className="paragraphe collection_welcome_description mb-5 mt-4">
                      Request sent
                    </p>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="empty_collection ">
                <img
                  src={request_acess}
                  alt="foders image"
                  className="mb-1 folders_image"
                />
                <h2 className="collection_welcome mb-1">
                  You need access to enter this collection
                </h2>
                <p className="paragraphe collection_welcome_description mb-5 mt-2">
                  Request sent
                </p>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {" "}
          <div className="empty_collection ">
            <img
              src={folder_content}
              alt="foders image"
              className="mb-5 folders_image"
            />
            <h2 className="collection_welcome mb-3">
              Start creating your collection
            </h2>
          </div>
        </>
      )}

      {productsToOrder.length > 0 && (
        <Alert
          message={`${productsToOrder.length} Product(s) selected`}
          type="info"
          style={{
            position: "fixed",
            left: "50%",
            //    top: "100%",

            bottom: "-12px",
            width: "30vw",
            borderRadius: "8px",
            border: "2px solid #809ec2",
            color: "#00468c",
            backgroundColor: "#f4f7ff",
            minWidth: "400px",
            transform: "translate(-50%,-50%)",
            zIndex: 20,
          }}
          action={
            <Space direction="horizontal">
              <Button
                size="middle"
                type="ghost"
                style={{
                  color: "#00468c",
                  borderColor: "#00468c",
                  borderRadius: "5px",
                }}
                onClick={() => deleteProducts(productsToOrder)}
                loading={deleteLoading}
              >
                Delete
              </Button>

              <Button
                size="middle"
                type="primary"
                style={{
                  background: "#00468c",
                  borderColor: "#00468c",
                  borderRadius: "5px",
                }}
                onClick={() => setIsOpenDrawer(true)}
              >
                Order now
              </Button>
            </Space>
          }
          closable
          onClose={() => {
            setProductsToOrder([]);
          }}
        />
      )}

      <Drawer
        // className="order_sent_drawer"
        placement={"bottom"}
        closable={true}
        onClose={() => {
          setOrderName("");
          setProductsToOrder([]);
          setSelectedDirectProducts([]);
          setSelectedClientProducts([]);
          setSelectedDemcoProducts([]);
          setSelectedCustomizedProducts([]);
          setIsOpenDrawer(false);
          setNext(false);
        }}
        visible={isOpenDrawer}
        key={"bottom"}
        style={{ paddingTop: "25px" }}
      >
        {width > 768 ? (
          <>
            <div className="row">
              {/* left container */}
              <div
                className="col-6 "
                style={{
                  paddingTop: "88px",
                  paddingLeft: "112px",
                  paddingRight: "112px",
                }}
              >
                <div className="titledrawerConfirm">Selected products</div>
                <div style={{ fontSize: "16px", marginBottom: "32px" }}>
                  Please review your selected items below before confirming your
                  order.
                </div>

                {selectedDirectProducts.length > 0 && (
                  <div>
                    <label htmlFor="">Direct product</label>
                    <div
                      className="border"
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F8FAFC",
                        padding: "12px",
                      }}
                    >
                      {selectedDirectProducts.map((item: any, key: any) => {
                        return (
                          <>
                            <div
                              className="row rowProdcut"
                              style={{ position: "relative" }}
                            >
                              <div
                                style={{
                                  borderRadius: "8px",
                                  marginRight: "16px",
                                }}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_STORAGE_URL +
                                    item.front
                                  }
                                  width="100px"
                                  height="72px"
                                  className="orderBoxImage"
                                  onClick={() => {
                                    setMode(false);
                                    setOpen(true);
                                    getProduct(item?.id);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div
                                className=""
                                onClick={() => {
                                  setMode(false);
                                  setOpen(true);
                                  getProduct(item?.id);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="titleorderContainerH1">
                                  {item?.name}
                                </span>
                              </div>
                              <div style={{ position: "absolute", right: "0" }}>
                                <button
                                  onClick={() => {
                                    handleRemoveDirectProduct(item);
                                    if (selectedDirectProducts.length == 1) {
                                      setSelectedDirectProducts([]);
                                    }
                                  }}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    backgroundColor: "#E5ECF3",
                                    padding: "16px",
                                    width: "32px",
                                    height: "32px",
                                  }}
                                >
                                  <DeleteOutlined />
                                </button>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
                {selectedClientProducts.length > 0 && (
                  <div>
                    <label htmlFor="">Client product</label>
                    <div
                      className="border"
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F8FAFC",
                        padding: "12px",
                      }}
                    >
                      {selectedClientProducts.map((item: any, key: any) => {
                        return (
                          <>
                            <div
                              className="row rowProdcut"
                              style={{ position: "relative" }}
                            >
                              <div
                                style={{
                                  borderRadius: "8px",
                                  marginRight: "16px",
                                }}
                              >
                                <img
                                  onClick={() => {
                                    setMode(false);
                                    setOpen(true);
                                    getProduct(item?.id);
                                  }}
                                  src={
                                    process.env.REACT_APP_STORAGE_URL +
                                    item.front
                                  }
                                  width="100px"
                                  height="72px"
                                  className="orderBoxImage"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div
                                className=""
                                onClick={() => {
                                  setMode(false);
                                  setOpen(true);
                                  getProduct(item?.id);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="titleorderContainerH1">
                                  {item?.name}
                                </span>
                                <br></br>
                              </div>
                              <div style={{ position: "absolute", right: "0" }}>
                                <button
                                  onClick={() => {
                                    handleRemoveClientProduct(item);
                                    if (selectedClientProducts.length == 1) {
                                      setSelectedClientProducts([]);
                                    }
                                  }}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    backgroundColor: "#E5ECF3",
                                    padding: "16px",
                                    width: "32px",
                                    height: "32px",
                                  }}
                                >
                                  <DeleteOutlined />
                                </button>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}

                {selectedCustomizedProducts.length > 0 && (
                  <div>
                    <label htmlFor="">Customized product</label>
                    <div
                      className="border"
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F8FAFC",
                        padding: "12px",
                      }}
                    >
                      {selectedCustomizedProducts.map((item: any, key: any) => {
                        return (
                          <>
                            <div
                              className="row rowProdcut"
                              style={{ position: "relative" }}
                            >
                              <div
                                style={{
                                  borderRadius: "8px",
                                  marginRight: "16px",
                                }}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_STORAGE_URL +
                                    item.front
                                  }
                                  width="100px"
                                  height="72px"
                                  className="orderBoxImage"
                                />
                              </div>
                              <div className="">
                                <span className="titleorderContainerH1">
                                  {item.name}
                                </span>
                                <br></br>
                                <span className="titleorderContainerH2">
                                  {item.code}
                                </span>
                              </div>
                              <div style={{ position: "absolute", right: "0" }}>
                                <button
                                  onClick={() => {
                                    handleRemoveCustomizedProduct(item);
                                    if (
                                      selectedCustomizedProducts.length == 1
                                    ) {
                                      setSelectedCustomizedProducts([]);
                                    }
                                  }}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    backgroundColor: "#E5ECF3",
                                    padding: "16px",
                                    width: "32px",
                                    height: "32px",
                                  }}
                                >
                                  <DeleteOutlined />
                                </button>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
                {selectedDemcoProducts.length > 0 && (
                  <div>
                    <label htmlFor="">Demco product</label>
                    <div
                      className="border"
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#F8FAFC",
                        padding: "12px",
                      }}
                    >
                      {selectedDemcoProducts.map((item: any, key: any) => {
                        return (
                          <>
                            <div
                              className="row rowProdcut"
                              style={{ position: "relative" }}
                            >
                              <div
                                style={{
                                  borderRadius: "8px",
                                  marginRight: "16px",
                                }}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_STORAGE_URL +
                                    item.front
                                  }
                                  width="100px"
                                  height="72px"
                                  className="orderBoxImage"
                                />
                              </div>
                              <div className="">
                                <span className="titleorderContainerH1">
                                  {item.name}
                                </span>
                                <br></br>
                                <span className="titleorderContainerH2">
                                  {item.code}
                                </span>
                              </div>
                              <div style={{ position: "absolute", right: "0" }}>
                                <button
                                  onClick={() => {
                                    handleRemoveDemcoProduct(item);
                                    if (selectedDemcoProducts.length == 1) {
                                      setSelectedDemcoProducts([]);
                                    }
                                  }}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    backgroundColor: "#E5ECF3",
                                    padding: "16px",
                                    width: "32px",
                                    height: "32px",
                                  }}
                                >
                                  <DeleteOutlined />
                                </button>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>

              <div
                className="col-6 "
                style={{
                  paddingTop: "88px",
                  paddingLeft: "112px",
                  paddingRight: "112px",
                }}
              >
                {" "}
                <div className="titledrawerConfirm">Order details</div>
                <div style={{ fontSize: "16px", marginBottom: "32px" }}>
                  Complete your order by providing the details below.
                </div>
                <div>
                  <label className="mr-3 mb-0 mt-3">Delivery date</label>
                  <DatePicker
                    disabledDate={(current) =>
                      current.isBefore(moment().add(15, "days"))
                    }
                    format={"DD/MM/YYYY"}
                    onChange={(date: any, dateString: any) =>
                      setDeliveryDate(dateString)
                    }
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      marginBottom: "16px",
                    }}
                  />

                  {isRequired && date_delivery === "" && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
                <div>
                  <button
                    className="view-details"
                    onClick={() => orderNow(productsToOrder)}
                    disabled={disable}
                    style={{ marginTop: "16px" }}
                  >
                    {disable ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 24, color: "white" }}
                            spin
                          />
                        }
                      />
                    ) : (
                      <>Confirm order</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ marginTop: "15%" }} className={"steps_launch_order"}>
              <Steps current={next ? 1 : 0} labelPlacement={"vertical"}>
                <Step title="Bag" />
                <Step
                  title="Confirmation"
                  style={{
                    fontSize: "12px",
                  }}
                />
              </Steps>
            </div>

            <div className="row">
              {!next && (
                <>
                  <div
                    className="col-12 "
                    style={{
                      paddingTop: "30px",
                    }}
                  >
                    <div className="titledrawerConfirmlaunchorder">
                      Selected products
                    </div>
                    <div
                      style={{ marginBottom: "32px" }}
                      className={"titledrawerConfirmlaunchorderDiscription"}
                    >
                      Please review your selected items below before confirming
                      your order.
                    </div>
                    {selectedDirectProducts.length > 0 && (
                      <div>
                        <label htmlFor="">Direct product</label>
                        <div
                          className="border"
                          style={{
                            borderRadius: "8px",
                            backgroundColor: "#F8FAFC",
                            padding: "12px",
                          }}
                        >
                          {selectedDirectProducts.map((item: any, key: any) => {
                            return (
                              <>
                                <div
                                  className="row rowProdcut"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    style={{
                                      borderRadius: "8px",
                                      marginRight: "16px",
                                    }}
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_STORAGE_URL +
                                        item.front
                                      }
                                      width="100px"
                                      height="72px"
                                      className="orderBoxImage"
                                      onClick={() => {
                                        setMode(false);
                                        setOpen(true);
                                        getProduct(item?.id);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                  <div
                                    className=""
                                    onClick={() => {
                                      setMode(false);
                                      setOpen(true);
                                      getProduct(item?.id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="titleorderContainerH1">
                                      {item?.name}
                                    </span>
                                  </div>
                                  <div
                                    style={{ position: "absolute", right: "0" }}
                                  >
                                    <button
                                      onClick={() => {
                                        handleRemoveDirectProduct(item);
                                        if (
                                          selectedDirectProducts.length == 1
                                        ) {
                                          setSelectedDirectProducts([]);
                                        }
                                      }}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        backgroundColor: "#E5ECF3",
                                        padding: "16px",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </button>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {selectedClientProducts.length > 0 && (
                      <div>
                        <label htmlFor="">Client product</label>
                        <div
                          className="border"
                          style={{
                            borderRadius: "8px",
                            backgroundColor: "#F8FAFC",
                            padding: "12px",
                          }}
                        >
                          {selectedClientProducts.map((item: any, key: any) => {
                            return (
                              <>
                                <div
                                  className="row rowProdcut"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    style={{
                                      borderRadius: "8px",
                                      marginRight: "16px",
                                    }}
                                  >
                                    <img
                                      onClick={() => {
                                        setMode(false);
                                        setOpen(true);
                                        getProduct(item?.id);
                                      }}
                                      src={
                                        process.env.REACT_APP_STORAGE_URL +
                                        item.front
                                      }
                                      width="100px"
                                      height="72px"
                                      className="orderBoxImage"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                  <div
                                    className=""
                                    onClick={() => {
                                      setMode(false);
                                      setOpen(true);
                                      getProduct(item?.id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="titleorderContainerH1">
                                      {item?.name}
                                    </span>
                                    <br></br>
                                  </div>
                                  <div
                                    style={{ position: "absolute", right: "0" }}
                                  >
                                    <button
                                      onClick={() => {
                                        handleRemoveClientProduct(item);
                                        if (
                                          selectedClientProducts.length == 1
                                        ) {
                                          setSelectedClientProducts([]);
                                        }
                                      }}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        backgroundColor: "#E5ECF3",
                                        padding: "16px",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </button>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {selectedCustomizedProducts.length > 0 && (
                      <div>
                        <label htmlFor="">Customized product</label>
                        <div
                          className="border"
                          style={{
                            borderRadius: "8px",
                            backgroundColor: "#F8FAFC",
                            padding: "12px",
                          }}
                        >
                          {selectedCustomizedProducts.map(
                            (item: any, key: any) => {
                              return (
                                <>
                                  <div
                                    className="row rowProdcut"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      style={{
                                        borderRadius: "8px",
                                        marginRight: "16px",
                                      }}
                                    >
                                      <img
                                        src={
                                          process.env.REACT_APP_STORAGE_URL +
                                          item.front
                                        }
                                        width="100px"
                                        height="72px"
                                        className="orderBoxImage"
                                      />
                                    </div>
                                    <div className="">
                                      <span className="titleorderContainerH1">
                                        {item.name}
                                      </span>
                                      <br></br>
                                      <span className="titleorderContainerH2">
                                        {item.code}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        position: "absolute",
                                        right: "0",
                                      }}
                                    >
                                      <button
                                        onClick={() => {
                                          handleRemoveCustomizedProduct(item);
                                          if (
                                            selectedCustomizedProducts.length ==
                                            1
                                          ) {
                                            setSelectedCustomizedProducts([]);
                                          }
                                        }}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          borderRadius: "5px",
                                          backgroundColor: "#E5ECF3",
                                          padding: "16px",
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      >
                                        <DeleteOutlined />
                                      </button>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                    {selectedDemcoProducts.length > 0 && (
                      <div>
                        <label htmlFor="">Demco product</label>
                        <div
                          className="border"
                          style={{
                            borderRadius: "8px",
                            backgroundColor: "#F8FAFC",
                            padding: "12px",
                          }}
                        >
                          {selectedDemcoProducts.map((item: any, key: any) => {
                            return (
                              <>
                                <div
                                  className="row rowProdcut"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    style={{
                                      borderRadius: "8px",
                                      marginRight: "16px",
                                    }}
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_STORAGE_URL +
                                        item.front
                                      }
                                      width="100px"
                                      height="72px"
                                      className="orderBoxImage"
                                    />
                                  </div>
                                  <div className="">
                                    <span className="titleorderContainerH1">
                                      {item.name}
                                    </span>
                                    <br></br>
                                    <span className="titleorderContainerH2">
                                      {item.code}
                                    </span>
                                  </div>
                                  <div
                                    style={{ position: "absolute", right: "0" }}
                                  >
                                    <button
                                      onClick={() => {
                                        handleRemoveDemcoProduct(item);
                                        if (selectedDemcoProducts.length == 1) {
                                          setSelectedDemcoProducts([]);
                                        }
                                      }}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        backgroundColor: "#E5ECF3",
                                        padding: "16px",
                                        width: "32px",
                                        height: "32px",
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </button>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div className={"row mt-3"}>
                      <button
                        onClick={() => setNext(true)}
                        className={"next_back_mycollection"}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </>
              )}

              {next && (
                <>
                  <div
                    className="col-12 "
                    style={{
                      paddingTop: "30px",
                    }}
                  >
                    <div className="titledrawerConfirmlaunchorder">
                      Order details
                    </div>
                    <div
                      style={{ marginBottom: "32px" }}
                      className={"titledrawerConfirmlaunchorderDiscription"}
                    >
                      Complete your order by providing the details below.
                    </div>
                    <div>
                      <label className="mr-3 mb-0 mt-3">Delivery date</label>
                      <DatePicker
                        disabledDate={(current) =>
                          current.isBefore(moment().add(15, "days"))
                        }
                        format={"DD/MM/YYYY"}
                        onChange={(date: any, dateString: any) =>
                          setDeliveryDate(dateString)
                        }
                        style={{
                          borderRadius: "5px",
                          width: "100%",
                          marginBottom: "16px",
                        }}
                      />

                      {isRequired && date_delivery === "" && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>

                    <div className={"row"}>
                      <div className={"col-6"}>
                        <button
                          onClick={() => setNext(false)}
                          className={"next_back_mycollection"}
                        >
                          Back
                        </button>
                      </div>
                      <div className={"col-6"}>
                        <button
                          className="confirm_order_collection"
                          onClick={() => orderNow(productsToOrder)}
                          disabled={disable}
                        >
                          {disable ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24, color: "white" }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            <>Confirm order</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </Drawer>

      <Modal
        title="Add more information"
        visible={isOpenCheckClientProduct}
        closable={false}
        width={"80%"}
        style={{ height: "100%" }}
        footer={[
          <div className="proto_footer_btns">
            <button
              className="selectAll"
              onClick={() => {
                setClientProductRequird([]);
                setIsOpenCheckClientProduct(false);
                setFormData([]);
              }}
            >
              Cancel
            </button>
            <button
              className="addToCollection" //
              onClick={handleSubmitMoreInfo}
            >
              Submit
            </button>
          </div>,
        ]}
      >
        <div className="scrol_order" style={{ maxHeight: "70vh" }}>
          <div className="titledrawerConfirm">Order details</div>
          <div style={{ fontSize: "16px", marginBottom: "32px" }}>
            Complete your order by providing the details below.
          </div>
          {clientProductRequird.map((el: any, index: any) => {
            return (
              <>
                <div className="row" key={index}>
                  <div className="col-12">
                    <div
                      className="my-2 scrol_order"
                      style={{
                        padding: "0px 25px 25px 25px",
                      }}
                    >
                      <>
                        <div className=" one_option_details col-12">
                          <label
                            className="my-2"
                            style={{ textTransform: "capitalize" }}
                          >
                            Name : {el.name}
                          </label>
                        </div>

                        <div className="col-7 mt-3 one_option_details">
                          <Radio.Group
                            name="radiogroup"
                            defaultValue={2}
                            value={formData[index]?.isSimple || ""}
                            onChange={(e: any) =>
                              handleChange2(
                                index,
                                "isSimple",
                                e.target.value,
                                el.id
                              )
                            }
                            buttonStyle="solid"
                          >
                            <Radio.Button value={2}>Sample</Radio.Button>
                            <Radio.Button
                              value={1}
                              style={{
                                width: "100px",
                                textAlign: "center",
                              }}
                            >
                              3D
                            </Radio.Button>
                          </Radio.Group>

                          {formErrors[index]?.errors.includes("isSimple") && (
                            <div>
                              {" "}
                              <span style={{ color: "red" }}>
                                This field is required.
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="row one_option_details">
                          <div className="col-12">
                            <label
                              className="my-2"
                              style={{ textTransform: "capitalize" }}
                            >
                              Style
                            </label>
                          </div>
                          <div className="col-12 col-md-7 option_details_select_wrapper">
                            <input
                              type="text"
                              name={"style" + index}
                              className={`option_details_input`}
                              //   onChange={handleChange2}
                              value={formData[index]?.style || ""}
                              onChange={(e: any) =>
                                handleChange2(
                                  index,
                                  "style",
                                  e.target.value,
                                  el.id
                                )
                              }
                            />
                            {formErrors[index]?.errors.includes("Style") && (
                              <span style={{ color: "red" }}>
                                This field is required.
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-5 mt-3 mt-md-0">
                            <DropZoneFile2
                              setAlerts={setAlerts}
                              attachFile={attachFile2}
                              detachFile={detachFile2}
                              optionKey={`${el.id}_style`}
                              files={files2}
                            />
                          </div>
                          <div className="col-12">
                            <label
                              className="my-2"
                              style={{ textTransform: "capitalize" }}
                            >
                              Fabric
                            </label>
                          </div>
                          <div className="col-12 col-md-7 option_details_select_wrapper">
                            <input
                              type="text"
                              name={"fabric"}
                              className={`option_details_input`}
                              value={formData[index]?.fabric || ""}
                              onChange={(value) =>
                                handleChange2(
                                  index,
                                  "fabric",
                                  value.target.value,
                                  el.id
                                )
                              }
                            />
                            {formErrors[index]?.errors.includes("Fabric") && (
                              <span style={{ color: "red" }}>
                                This field is required.
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-5 mt-3 mt-md-0">
                            <DropZoneFile2
                              setAlerts={setAlerts}
                              attachFile={attachFile2}
                              detachFile={detachFile2}
                              optionKey={`${el.id}_fabric`}
                              files={files2}
                            />
                          </div>{" "}
                          <div className="col-12">
                            <label
                              className="my-2"
                              style={{ textTransform: "capitalize" }}
                            >
                              Wash
                            </label>
                          </div>
                          <div className="col-12 col-md-7 option_details_select_wrapper">
                            <Select
                              dropdownStyle={{
                                borderRadius: 10,
                                fontWeight: 600,
                                fontSize: 20,
                              }}
                              style={{ width: "100%" }}
                              suffixIcon={() => (
                                <FontAwesomeIcon icon={faCaretDown} />
                              )}
                              value={formData[index]?.wash || ""}
                              onChange={(value) =>
                                handleChange2(index, "wash", value, el.id)
                              }
                            >
                              {washes.map((el: any, i: number) => (
                                <Option value={el.name} key={el.id}>
                                  {el.name}
                                </Option>
                              ))}
                            </Select>
                            {formErrors[index]?.errors.includes("Wash") && (
                              <span style={{ color: "red" }}>
                                This field is required.
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-5 mt-3 mt-md-0">
                            <DropZoneFile2
                              setAlerts={setAlerts}
                              attachFile={attachFile2}
                              detachFile={detachFile2}
                              optionKey={`${el.id}_wash`}
                              files={files2}
                            />
                          </div>
                          <div className="col-12">
                            <label
                              className="my-2"
                              style={{ textTransform: "capitalize" }}
                            >
                              Size
                            </label>
                          </div>
                          <div className="col-12 col-md-7 option_details_select_wrapper">
                            <input
                              type="text"
                              name="size"
                              className={`option_details_input`}
                              value={formData[index]?.size_qty || ""}
                              onChange={(value) =>
                                handleChange2(
                                  index,
                                  "size_qty",
                                  value.target.value,
                                  el.id
                                )
                              }
                            />
                            {formErrors[index]?.errors.includes("size_qty") && (
                              <span style={{ color: "red" }}>
                                This field is required.
                              </span>
                            )}
                          </div>
                          <div className="col-12">
                            <label
                              className="my-2"
                              style={{ textTransform: "capitalize" }}
                            >
                              Length
                            </label>
                          </div>
                          <div className="col-12 col-md-7 option_details_select_wrapper">
                            <input
                              type="text"
                              name={"length"}
                              className={`option_details_input`}
                              value={formData[index]?.crotch || ""}
                              onChange={(value: any) =>
                                handleChange2(
                                  index,
                                  "crotch",
                                  value.target.value,
                                  el.id
                                )
                              }
                            />
                            {formErrors[index]?.errors.includes("crotch") && (
                              <span style={{ color: "red" }}>
                                This field is required.
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </Modal>
    </div>
  );
}
