import {
  Button,
  Col,
  Drawer,
  Input,
  Row,
  Form,
  Select,
  DatePicker,
  Spin, Switch,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useMutation, useQuery } from "react-query";
import CategoriesServices from "../../actions/left-menu-actions/services";
import axiosInstance from "../../config/axios-instance";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { LoadingOutlined } from "@ant-design/icons";
import "./TeamManagement.scss";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
const { Option } = Select;

interface IData {
  fullname: string;
  email: string;
  title: string;
  fabric_type: string;
  genders: number[];
  is_clients:boolean
}

export default function AddTeammateDrawer({
  visible,
  onClose,
  refetchMembers,
  company,
  is_client
}: any) {
  const width = useBreakpoint();
  const params = useParams<any>();

  const { user, isLoading, isAdmin } = useSelector((state: any) => state.auth);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values: IData) => {
    console.log("Success:", values);
    sendInvitation(values);
  };

  const { data: genders, isLoading: gendersIsLoading } = useQuery(
    "genders",
    CategoriesServices.getGenders
  );

  const inviteMember = (data: IData) => {
    let token = localStorage.getItem("token");
      if(params.id){
        return axiosInstance({
         method: "post",
          url: `/clients/me/company/inviteMembers/${params.id}/${user.type}`,
          data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }else if (is_client){
    return axiosInstance({
      method: "post",
      url: `/clients/me/company/invite/client`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
      }else { return axiosInstance({
          method: "post",
          url: `/clients/me/company/invite`,
          data,
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
  }
  };

/*   const getCompany = () => {
    let token = localStorage.getItem("token");
    
    return axiosInstance({
      method: "get",
      url: `/clients/me/company/${params.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response)=>
      setCompany(response.data)
    );
  };

  useEffect(()=>{
      getCompany();
  },[]) */

  const { mutateAsync: sendInvitation, isLoading: invitationLoading } =
    useMutation(inviteMember, {
      onSuccess: () => {
        MyNotif("Invitation sent.", "success");
        onClose();
        refetchMembers();
      },
      onError: (err: any) => {
        MyNotif(err?.response?.data?.errors?.email[0], "danger");
      },
    });

  return (
    <Drawer
      //   title="Invite new member"
      placement={"right"}
      //   closable={true}
      onClose={onClose}
      closable={true}
      visible={visible}
      width={width.md ? "33%" : "100%"}
      bodyStyle={{ padding: 20, paddingBottom: 80, background: "#fcfdff " }}
    >
      <h1 className="h1-blue-hj invit_new_member_title" >
          {is_client ? "Add your Client":"Invite new member" }
      </h1>
      <Form
        layout="vertical"
        hideRequiredMark
        style={{ padding: 24, paddingTop: 0 }}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        initialValues={{ remember: true }}
        className="invite-teammate-drawer"
      >

<Row>
          <Col span={24}>
            <Form.Item
              name="company_name"
              label="Company name"
              style={{ color: "#182844" }}
              className="h2-blue-hj my-2 add_client_label"
              
            
            >
              <Input placeholder="Please enter user name"  defaultValue={company?.name} value={company?.name} disabled/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Full name"
              style={{ color: "#182844" }}
              className="h2-blue-hj my-2 add_client_label"
              rules={[
                { required: true, message: "Please enter user full name" },
              ]}
            >
              <Input placeholder="Please enter user name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="email"
              label="Email address"
              style={{ color: "#182844" }}
              className="h2-blue-hj my-2 add_client_label"
              rules={[
                { required: true, message: "Please enter user email address" },
              ]}
            >
              <Input placeholder="Please enter user email address" />
            </Form.Item>
          </Col>
        </Row>
          {is_client &&
              <Row>
          <Col span={24}>
            <Form.Item
                name="is_client"
                label="Client client's"
                style={{ color: "#182844" }}
                className="h2-blue-hj my-2 add_client_label"

            >
              <Switch  defaultChecked />
            </Form.Item>
          </Col>
        </Row>
          }
        <Row>
          <Col span={24}>
            <Form.Item
              name="job_title"
              label="Job title"
              style={{ color: "#182844" }}
              className="h2-blue-hj my-2 add_client_label"
              rules={[
                { required: true, message: "Please enter user job title" },
              ]}
            >
              <Input placeholder="Please enter user job title" />
            </Form.Item>
          </Col>
        </Row>
        {isAdmin && <>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="fabric_type"
              label="Fabric type"
              className="h2-blue-hj my-2 add_client_label"
              style={{ color: "#182844" }}
              rules={[
                { required: true, message: "Please select a fabric type" },
              ]}
            >
              <Select
                placeholder="Please select a fabric type"
                className="select-invite-teammate"
              >
                <Option value="denim">Denim</Option>
                <Option value="knit">Knit</Option>
                <Option value="knit_denim">Both</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="genders"
              label="Gender"
              className="h2-blue-hj my-2 add_client_label"
              style={{ color: "#182844" }}
              rules={[
                {
                  required: true,
                  message: "Please choose at least one gender",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please choose at least one gender"
                className="select-invite-teammate"
                loading={gendersIsLoading}
                onChange={(x: any, y: any) => console.log(x)}
                style={{ width: "100%" }}
              >
                {genders?.data.map((item: any) =>
                  item.childs.length > 0 ? (
                    item.childs.map((child: any) => (
                      <Select.Option value={child.id} key={child.id}>
                        {child.name}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>  
        </>}

        <Row>
          <Col span={24}>
            <Form.Item
              name="country"
              style={{ color: "#182844" }}
              className="h2-blue-hj my-2 add_client_label"
              label="Country"
            
            >
              <Input  defaultValue={company?.country} value={company?.country} disabled/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // flexWrap: "wrap",
            }}
            className="d-flex flex-column flex-md-row"
          >
            <button
              type="reset"
              className="cancel_btn flex-grow-1 my-3 mr-md-2"
              disabled={invitationLoading}
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="invite_btn flex-grow-1 my-3 ml-md-2"
              disabled={invitationLoading}
            >
              {invitationLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 22,
                        color: "white",
                        verticalAlign: "middle",
                      }}
                      spin
                    />
                  }
                />
              ) : (
                "Send the request"
              )}
            </button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
