function Trash() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.25"
      height="14"
      viewBox="0 0 12.25 14"
      className="trash_icon"
    >
      <path
        id="Path_3391"
        data-name="Path 3391"
        d="M5.906-10.5H3.063l-.919-1.225a1.309,1.309,0,0,0-1.05-.525H-1.094a1.309,1.309,0,0,0-1.05.525L-3.062-10.5H-5.906a.219.219,0,0,0-.219.219v.438a.219.219,0,0,0,.219.219h.517L-4.482.555A1.315,1.315,0,0,0-3.175,1.75H3.175A1.315,1.315,0,0,0,4.482.555l.908-10.18h.517a.219.219,0,0,0,.219-.219v-.437A.219.219,0,0,0,5.906-10.5Zm-7.35-.7a.439.439,0,0,1,.35-.175H1.094a.439.439,0,0,1,.35.175l.525.7H-1.969ZM3.609.476a.435.435,0,0,1-.434.4H-3.176a.436.436,0,0,1-.434-.4l-.9-10.1H4.512Z"
        transform="translate(6.125 12.25)"
        fill="#00468C"
      />
    </svg>
  );
}

export default Trash;
