import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import ConfirmDrawerClient from "./ConfirmDrawerClient";

export default function CalendarView({
  events,
  handleSelect,
  setEditMode,
  setVisible,
  setSelectedDate,
  setSubject,
  setNotes,
  setDuration,
  setSelectedEvent,
  selectedEvent,
  refetch,
}: any) {
  const localizer = momentLocalizer(moment);
  const { user } = useSelector((state: any) => state.auth);

  const genStatus = useCallback((status: any) => {
    switch (status) {
      case "new":
        return {
          bg: "#e6f7ff",
          color: "#0983e4",
        };
      case "waiting":
        return {
          bg: "#FBECD8",
          color: "#f99723",
        };
      case "accepted":
        return {
          bg: "#f6ffed",
          color: "#389e81",
        };
      case "done":
        return {
          bg: "#00468c",
          color: "white",
        };
      default:
        return {
          bg: "#fff1f0",
          color: "#cf1322",
        };
    }
  }, []);

  const [clientConfirmDrawer, setClientConfirmDrawer] = useState(false);

  const onCloseConfirmDrawerClient = () => {
    setClientConfirmDrawer(false);
  };

  return (
    <>
      <Calendar
        selectable
        localizer={localizer}
        events={events}
        defaultView="month"
        views={["month", "work_week", "day"]}
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={new Date()}
        style={{ height: "800px" }}
        onSelectSlot={user.type !== "admin" && handleSelect}
        onSelectEvent={(event: any) => {
          if (
            user &&
            event.details.status !== "rejected" &&
            (event.details.user_id === user.id ||
              (user.permissions &&
                user.permissions.includes("meetings.management")))
          ) {
            setEditMode(true);
            setVisible(true);
            setSelectedEvent(event);
            setSelectedDate(moment(event.start).format("dddd-DD-MMMM-YYYY"));
            setSubject(event.title);
            setNotes(event.notes);
            setDuration(event.duration);
          } else if (
            user &&
            user.type !== "admin" &&
            event.details.status !== "rejected"
            //&& event.details.client_id === user.id
          ) {
            setClientConfirmDrawer(true);
            setSelectedEvent(event);
          }
          // if (user && user.type !== "admin") {
          //   setEditMode(true);
          //   setVisible(true);
          //   setSelectedEvent(event);
          //   setSelectedDate(moment(event.start).format("dddd-DD-MMMM-YYYY"));
          //   setSubject(event.title);
          //   setNotes(event.notes);
          //   setDuration(event.duration);
          // }
        }}
        dayPropGetter={(date: Date) => {
          const dayOfWeek = moment(date).format("dddd");
          if (dayOfWeek === "Saturday" || dayOfWeek === "Sunday") {
            return {
              style: { backgroundColor: "#f4f4f4", cursor: "not-allowed" },
              onClick: (e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
              },
            };
          } else {
            return {
              style: { backgroundColor: "white", cursor: "pointer" },
            };
          }
        }}
        popup={true}
        tooltipAccessor={(event: any) => event.status}
        eventPropGetter={(event: any) => {
          var style = {
            backgroundColor: genStatus(event.status).bg,
            color: genStatus(event.status).color,
            opacity: 0.8,
            border: "none",
            fontSize: "10px",
            display: "block",
            //  padding: "5px 5px 5px 10px",
            borderRadius: 2,
          };
          return {
            style,
          };
        }}
      />
      <ConfirmDrawerClient
        clientConfirmDrawer={clientConfirmDrawer}
        setClientConfirmDrawer={setClientConfirmDrawer}
        onCloseConfirmDrawerClient={onCloseConfirmDrawerClient}
        meet={selectedEvent}
        refetch={refetch}
      />
    </>
  );
}
