import { useEffect, useState } from "react";
import {
  Drawer,
  Select,
  TimePicker,
  Input,
  Button,
  Typography,
  Alert,
} from "antd";
import moment from "moment";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import axiosInstance from "../../config/axios-instance";
import { MyNotif } from "../NotifIcons/NotifIcons";

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

export default function DisabledDrawerBooking({
  editMode,
  onClose,
  visible,
  selectedDate,
  loadingCompanies,
  handleCompany,
  companies,
  company,
  handleClient,
  loadingClients,
  clients,
  onChangeStartTime,
  onChangeEndTime,
  onChangeSubject,
  subject,
  setNotes,
  notes,
  mutateCreateBooking,
  createBookingError,
  createLoading,
  selectedEvent,
  setSelectedEvent,
  setVisible,
  refetch,
  duration,
  selectedClient,
}: any) {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [status, setStatus] = useState(false);
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const format = "HH:mm";

  useEffect(() => {
    if (selectedEvent) {
      // handleCompany(selectedEvent?.company.id);
      setStatus(selectedEvent?.details?.status === "accepted" ? true : false);
    }
  }, [visible, selectedEvent]);

  useEffect(() => {
    if (status) {
      handleCompany(selectedEvent?.company.id);
      setIsConfirmed(
        selectedEvent?.details?.status === "accepted" ? true : false
      );
    } else {
      setIsConfirmed(false);
    }
  }, [status]);

  return (
    <Drawer
      placement={"right"}
      closable={false}
      onClose={() => {
        setIsConfirmed(false);
        setSelectedEvent(null);
        setTimeout(() => {
          onClose();
        }, 200);
      }}
      visible={visible}
      key={"right"}
      width="400"
      className="booking-drawer"
    >
      <div className="left-calendar-container">
        <h1 className="h1-blue-hj" style={{ marginTop: "20px" }}>
          Meeting details
        </h1>
        <p>
          Selected Date:{" "}
          <strong>
            {moment(
              editMode && selectedEvent ? selectedEvent.start : selectedDate
            ).format("MMMM, dddd Do YYYY")}
          </strong>
        </p>
        {user && isAdmin && (
          <>
            <h3 className="h2-blue-hj">Select Company</h3>
            <Select
              disabled
              className="w-100"
              loading={loadingCompanies}
              value={company || (selectedEvent && selectedEvent.company.id)}
              placeholder="Select company"
            >
              {companies &&
                companies.data.data.map((company: any) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
            </Select>
          </>
        )}
        {((editMode && selectedEvent && selectedEvent.company.id && isAdmin) ||
          (company && isAdmin)) && (
          <>
            <h3 className="h2-blue-hj">Select Client</h3>
            <Select
              disabled
              className="w-100"
              loading={loadingClients}
              value={
                selectedClient || (selectedEvent && selectedEvent.client.id)
              }
              placeholder="Select client"
            >
              {clients &&
                clients.map((client: any) => (
                  <Option key={client.id} value={client.id}>
                    {client.name}
                  </Option>
                ))}
            </Select>
          </>
        )}
        <h3 className="h2-blue-hj">Start meeting time</h3>
        <TimePicker
          disabled
          style={{ width: "100%" }}
          value={selectedEvent && moment(selectedEvent.start)}
          showSecond={false}
          disabledHours={() => [
            0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23,
          ]}
          hideDisabledOptions
          minuteStep={15}
          format={format}
        />
        <h3 className="h2-blue-hj">Duration</h3>
        <Select
          disabled
          style={{ width: "100%" }}
          value={editMode && selectedEvent ? selectedEvent.duration : duration}
          placeholder="Select duration"
        >
          <Option value="1">One hour</Option>
          <Option value="1.5">One and half hour</Option>
          <Option value="2">Two hours</Option>
        </Select>
        <h3 className="h2-blue-hj">Your meeting Subject</h3>
        <Select
          disabled
          showSearch
          size="large"
          style={{ width: "100%" }}
          placeholder="Select your meeting Subject"
          optionFilterProp="children"
          value={editMode && selectedEvent ? selectedEvent.subject : subject}
          filterOption={(input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Select.Option value="ID presentation">ID presentation</Select.Option>
          <Select.Option value="Dedicated development presentation">
            Dedicated development presentation
          </Select.Option>
          <Select.Option value="PROTO">PROTO</Select.Option>
          <Select.Option value="PRE SMS">PRE SMS</Select.Option>
          <Select.Option value="SMS">SMS</Select.Option>
          <Select.Option value="OK PROD">OK PROD</Select.Option>
          <Select.Option value="PRODUCTION">PRODUCTION</Select.Option>
        </Select>
        <h3 className="h2-blue-hj">Add your request</h3>
        <TextArea
          rows={4}
          disabled
          value={editMode && selectedEvent ? selectedEvent.notes : notes}
        />
      </div>
    </Drawer>
  );
}
