import React, { useState, useEffect, useCallback, useRef } from "react";
import {Tabs, Row, Col, Result, Collapse, Divider, Input, Button, Drawer, Card} from "antd";
import { useParams, useLocation, Link } from "react-router-dom";
import "../components/CollectionProducts/CollectionProducts.scss";
import Question from "../icons/Question";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import axiosInstance from "../config/axios-instance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faSearch, } from "@fortawesome/free-solid-svg-icons";
import faqbaseimage from "../img/faqbaseimage.svg";
import { v4 as uuidv4 } from "uuid";
import {UserOutlined, SearchOutlined, ArrowLeftOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import backArrow from "../img/backArrow.png";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import Close from "../icons/Close";
import no_result from "../img/no_result.png";

import {HrIcon} from "../components/product-3d-config-tabs/forms/canvas/icon/HrIcon";
const { Search } = Input;

interface Props {}

export const Faq = (props: Props) => {
  const location = useLocation();
  const params: any = useParams();
  const { Panel } = Collapse;
  const [width, setWidth] = useState(window.innerWidth);
  const [faqCategory, setFaqCategory] = useState<Array<any>>([]);
  const [faq, setFaq] = useState<Array<any>>([]);
  const [search, setSearch] = useState<Array<any>>([]);
  const [active, setActive] = useState("-1");
  const [noResult, setResult] = useState(false);
  const [activeAllQuestion, setActiveAllQuestion] = useState<any>(null);

  const [activeQuestion, setActiveQuestion] = useState<any>(null);
  const [activeQuestionSearch, setActiveQuestionSearch] = useState<any>(null);
  const [firstShow, setFirstShow] = useState("");

  const [question, setQuestion] = useState<any>(null);
  const [questionMobile, setQuestionMobile] = useState<any>(null);

  const [open, setOpen] = useState(true);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const style = {
    padding: '8px',
    border: "1px solid #CECECE",
    marginLeft: "25px",
    borderRadius: "5px",
    maxWidth: "100%",
    height: "auto",
  };

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQueryy();
  const fetchFaqCategory = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/faq/categories/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setFaqCategory(res.data);
    });
  };

  const fetchFaqs = (id: any) => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/faq/categories/` + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setFaq(res.data);
    });
  };

  const onChange = (key: string) => {
    console.log(key)
    setActive(key);
    fetchFaqs(key);
    setActiveQuestion(null);
    setcheckQuestion("");

    setFirstShow(key);
  };
  /* 
  const search = (e: any) => {
    setKeyword(e.target.value);
    setLoading(true);
    if (!e.target.value) return;
    axiosInstance({
      method: "get",
      url: `/products?name=${e.target.value}`,
    })
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err: any) =>{});
    // dispatch(filterByTyping(e.target.value));
  }; */

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log(value);
    axiosInstance({
      method: "get",
      url: `/faqsearch?search=${value}`,
    })
      .then((res) => {
        if (value && res.data.length > 0) {
          setSearch(res.data);
          setResult(false);
        } else if (res.data.length == 0 || value === "") {
          setQuestion(null);
          setResult(true);
          setActiveQuestionSearch("");
        }
        if (value === "") {
          setResult(false);
          setActive("-1");
          setSearch([]);
          setActiveQuestionSearch("");
        }
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    fetchFaqCategory();
  }, [location.pathname]);

  const fetchFaq = (id: any) => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/faq/` + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setActiveQuestion(res.data);
    });
  };

  const [activeKey, setActiveKey] = useState<any>([]);
  const [checkQuestion, setcheckQuestion] = useState("");

  function handlePanelClick(key: any) {
    setActiveKey(activeKey.includes(key) ? [] : [key]);
  }

  function changeActiveAll(question: any) {
    setActiveQuestion(question);
    //  setActiveAllQuestion(question.id);
    setActiveAllQuestion(question.id);
  }

  /*  return (
    <>
      <div style={{ paddingTop: "110px" }}>
        <div
          style={{
            height: "200px",
            backgroundColor: "#F8FAFF",
            textAlign: "center",
          }}
        >
          <div
            style={{
              paddingTop: "56px",
              fontSize: "20px",
              color: "#03468C",
              fontFamily: "Poppins",
            }}
          >
            Frequently asked questions
          </div>
          <div style={{ marginTop: "16px" }}>
            <form style={{ width: "100%" }}>
              <input
                type="text"
                placeholder="What are you looking for ?"
                name="name"
                className="option_details_input   ml-1 mb-0 ml-md-0"
                style={{ width: "50%" }}
                onChange={handleChange}
              />
            </form>
          </div>
        </div>
      </div>
      <div>
        {noResult == true ? (
          <>
            <div style={{ textAlign: "center" }}>
              {" "}
              <Result
                status="404"
                title="No Result"
                style={{ width: "100%" }}
                subTitle="Sorry, there's no result."
              />
            </div>
          </>
        ) : (
          <>
            <div>
              {search.length > 0 ? (
                <>
                  {search.map((q: any) => {
                    return (
                      <>
                        <div style={{ marginLeft: "27%" }}>
                          <div
                            style={{
                              border: "1px solid #CECECE",
                              marginLeft: "25px",
                              marginBottom: "25px",
                              borderRadius: "5px",
                              width: "60%",
                              padding: "25px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              {q.question}
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: q.description,
                              }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <Tabs
                    defaultActiveKey="0"
                    activeKey={active}
                    centered
                    onChange={onChange}
                    id="tabs"
                  >
                    <Tabs.TabPane tab="All" key={"0"}>
                      <div style={{ marginLeft: "10%" }}>
                        <Row gutter={[48, 48]}>
                          {faqCategory.map((f: any) => {
                            return (
                              <>
                                <Col
                                  span={5}
                                  style={{
                                    border: "1px solid #CECECE",
                                    marginLeft: "25px",
                                    borderRadius: "5px",
                                    maxWidth: "100%",
                                    height: "auto",
                                    padding: "20px",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "25px",
                                      color: "#00468C",
                                    }}
                                  >
                                    {f.name}
                                  </div>
                                  {f.faqs.slice(0, 4).map((faq: any) => {
                                    return (
                                      <div
                                        style={{
                                          fontSize: "14px",
                                          marginBottom: "10px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <Question></Question>{" "}
                                        <Link
                                          to={`/faq/${faq.id}`}
                                          style={{
                                            color: "black",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {faq.question}
                                        </Link>
                                      </div>
                                    );
                                  })}
                                  <a
                                    onClick={() => onChange("" + f.id + "")}
                                    style={{
                                      color: "black",
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Show All
                                  </a>
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                      </div>
                    </Tabs.TabPane>
                    {faqCategory.map((el: any) => {
                      return (
                        <Tabs.TabPane tab={el.name} key={el.id}>
                          {faq.map((el: any) => {
                            return (
                              <>
                                <div className="container">
                                  {el.faqs.map((f: any) => {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            border: "1px solid #CECECE",
                                            marginBottom: "25px",
                                            borderRadius: "5px",
                                            padding: "25px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {f.question}
                                          </div>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: f.description,
                                            }}
                                          />
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </>
                            );
                          })}
                        </Tabs.TabPane>
                      );
                    })}
                  </Tabs>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  ); */

  return (
    <>

      <div style={{ paddingTop: "100px" }}>
        <div
        className={"faqheader"}
        >
          <div
            style={{
              paddingTop: "56px",
              fontSize: "20px",
              color: "#03468C",
              fontFamily: "Poppins",
            }}
            className={"headerContent"}
          ></div>

          <div style={{ marginTop: "16px" }}>
            {" "}
            <div className="row">
              <div
                className="col-3 d-flex align-items-center back_cs"
                style={{ textAlign: "center" }}
              >
                {active != "-1" && (
                    <>

                      <div className={
                        "faq_go_back"
                      }

                           onClick={() => {
                             setActive("-1");
                           }}>

                      <ArrowLeftOutlined
                          onClick={() => {
                            setActive("-1");
                          }}
                      ></ArrowLeftOutlined>{" "}
                      <button
                          className={"go_back_text"}

                      >
                       Go back
                      </button>
                      </div>

                    </>
                )}
              </div>

         {/*     {active == "-1" && (

              <Search placeholder="What are you looking for ?" allowClear onChange={handleChange}    style={{ width: "50%" }}  className={"searchfaq"}/>

              )}*/}


              {active == "-1" && (

                    <div className="search-bar searchfaq"  style={{ width: "50%" }}  >
                      <div className="input-container">
                        <input type="text" placeholder="What are you looking for?" onChange={handleChange} className={"search_input"}/>
                        <HrIcon></HrIcon>
                        <button type="submit" className={"submit_input"}>  <SearchOutlined/></button>
                      </div>
                    </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        {noResult == true ? (
          <>
            <div style={{ textAlign: "center" }}>
              {" "}
             {/* <Result
                status="404"
                title="No Result"
                style={{ width: "100%" }}
                subTitle="Sorry, there's no result."
              />*/}
              <img src={no_result}/>
              <p className="paragraphe mt-4">Sorry, there was no result found 😞</p>
            </div>
          </>
        ) : (
          <>
            <div>
              {search.length > 0 ? (
                <>
                  <Row>
                    <Col span={12}  >
                      {" "}
                      <div
                        style={{
                          marginLeft: "25px",
                          paddingTop: "15px",
                          color: "#182844",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        Search Result
                      </div>
                      {search.map((q: any) => {
                        return (
                          <>
                            <a
                              onClick={() => {
                                setQuestion(q);
                                setActiveQuestionSearch(q.id);
                              }}
                              style={{
                                color: "#182844",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  marginTop: "20px",
                                }}
                              >
                                <div
                                  style={{}}
                                  className={
                                    activeQuestionSearch == q.id
                                      ? "activeSearchSelect"
                                      : "activeSearch"
                                  }
                                >
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {q.question}
                                  </div>
                                </div>
                              </div>
                            </a>
                          </>
                        );
                      })}
                    </Col>
                    <Col span={12} >
                      {" "}
                      {question && (
                        <>
                          {" "}
                          <div className="row1">
                            <div className="row title_container_post">
                              <div className="col-12">
                                <div
                                  style={{
                                    fontSize: "20px",
                                    color: "#03468C",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {question.question}
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: question.description,
                                  }}
                                ></div>
                                {question.video && (
                                  <video
                                    src={
                                      process.env.REACT_APP_STORAGE_URL +
                                      question.video
                                    }
                                    controls
                                    width="400"
                                    height="240"
                                  >
                                    {" "}
                                  </video>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                  <>

                    {active == "-1" && (
                        <div className={"p-5"}>
                          <Row gutter={[48, 48]} style={{justifyContent: "center"}}>
                            <div className={"list-faq-category"}>


                              {faqCategory.map((f: any) => {
                                return (
                                    <>
                                      <Card className={"card-faq"}>
                                        <div
                                            style={{

                                            }}
                                            className={"title_card_faq"}
                                            onClick={() => {
                                              setQuestionMobile(f)
                                              onChange("" + f.id + "");
                                              fetchFaq(f?.faqs[0]?.id);
                                              setcheckQuestion(f?.faqs[0]?.id);
                                            }}
                                        >
                                          {f.name}
                                        </div>
                                        <div className={"faqhomemobile"}>
                                          {f.faqs.slice(0, 1).map((faqs: any) => {
                                            return (
                                                <div
                                                    style={{
                                                      fontSize: "16px",
                                                      marginBottom: "10px",
                                                      marginTop: "10px",
                                                    }}
                                                >
                                                  <Question color={"#182844"}></Question>{" "}
                                                  <a
                                                      onClick={() => {
                                                        onChange("" + f.id + "");
                                                        changeActiveAll(faqs);
                                                        setcheckQuestion("");
                                                      }}
                                                      style={{
                                                        color: "black",
                                                        fontSize: "16px",
                                                        marginLeft: "5px",
                                                      }}
                                                  >
                                                    {" "}
                                                    {faqs.question}
                                                  </a>
                                                </div>
                                            );
                                          })}
                                          <a
                                              onClick={() => {
                                                onChange("" + f.id + "");
                                                fetchFaq(f?.faqs[0]?.id);
                                                setcheckQuestion(f?.faqs[0]?.id);
                                              }}
                                              className={"home_question_faq"}

                                          >
                                            Show All
                                          </a>
                                        </div>
                                      </Card>
                                    </>
                                );
                              })}
                            </div>

                          </Row>
                        </div>
                    )}
                  </>
              )}
            </div>
            {active != "-1" && (
                <div className={"responsive_faq"}>
                  {" "}
                  <div className={"web"}>
                    {faq.map((el: any) => {
                      return (
                          <>
                            {" "}
                            <div className="products_page_container_faq">
                              <div className={`container-fluid products_page`}>
                                <div
                                    className="filterfaq"

                                >
                                  <aside
                                      className="asidefaq"
                                      style={{height: "100vh"}}
                                  >
                                    <div
                                        style={{
                                          paddingLeft: "15px",
                                          paddingRight: "15px",
                                          paddingTop: "15px",
                                        }}
                                    ></div>


                                    {faqCategory.map((f: any, key: any) => {
                                      return (
                                          <Collapse
                                              defaultActiveKey={active}
                                              ghost
                                              expandIconPosition={"right"}
                                              expandIcon={(iconProp) =>
                                                  iconProp.isActive ? (
                                                      <FontAwesomeIcon icon={faMinus}/>
                                                  ) : (
                                                      <FontAwesomeIcon icon={faPlus}/>
                                                  )
                                              }
                                          >
                                            <>
                                              <Panel header={f.name} key={f.id}>
                                                {" "}
                                                {f.faqs.map((faq: any) => {
                                                  return (
                                                      <div
                                                          style={{
                                                            fontSize: "16px",
                                                            marginBottom: "10px",
                                                            marginTop: "-7px",
                                                            padding: "8px 15px 8px 15px",
                                                            borderRadius: "8px",
                                                          }}
                                                          onClick={() => {
                                                            setcheckQuestion(faq.id);
                                                            setQuestion(null);
                                                            setActiveAllQuestion(null);
                                                            fetchFaq(faq.id);
                                                            handlePanelClick(faq.id);
                                                          }}
                                                          className={
                                                            checkQuestion == faq.id ||
                                                            activeAllQuestion == faq.id
                                                                ? "active-question"
                                                                : ""
                                                          }
                                                      >
                                                        <Question
                                                            color={
                                                              checkQuestion == faq.id ||
                                                              activeAllQuestion == faq.id
                                                                  ? "#f8faff"
                                                                  : "#182844"
                                                            }
                                                        ></Question>{" "}
                                                        <button
                                                            style={{
                                                              color: "black",
                                                              fontSize: "14px",
                                                            }}
                                                        >
                                                          {faq.question}
                                                        </button>
                                                      </div>
                                                  );
                                                })}
                                              </Panel>
                                              <Divider key={uuidv4()}/>
                                            </>
                                          </Collapse>
                                      );
                                    })}
                                  </aside>
                                </div>
                                {activeQuestion == null ? (
                                    <div className="row1">
                                      <div className="row title_container_post">
                                        <div
                                            className="col-12"
                                            style={{textAlign: "center"}}
                                        >
                                          <>
                                            {search.length > 0 ? (
                                                <>
                                                  <div
                                                      style={{
                                                        fontSize: "20px",
                                                        color: "#182844",
                                                        fontFamily: "Poppins",
                                                        textAlign: "left",
                                                        marginLeft: "25px",
                                                      }}
                                                  >
                                                    Search result
                                                  </div>
                                                  {search.map((q: any) => {
                                                    return (
                                                        <>
                                                          <a
                                                              onClick={() => {
                                                                setQuestion(q);
                                                                setActiveQuestionSearch(q.id);
                                                              }}
                                                              style={{
                                                                color: "#182844",
                                                                fontSize: "14px",
                                                                fontWeight: "bold",
                                                              }}
                                                          >
                                                            <div
                                                                style={{
                                                                  textAlign: "left",
                                                                  marginTop: "20px",
                                                                }}
                                                            >
                                                              <div
                                                                  style={{}}
                                                                  className={
                                                                    activeQuestionSearch ==
                                                                    q.id
                                                                        ? "activeSearchSelect"
                                                                        : "activeSearch"
                                                                  }
                                                              >
                                                                <div
                                                                    style={{
                                                                      fontSize: "14px",
                                                                      fontWeight: "bold",
                                                                    }}
                                                                >
                                                                  {q.question}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </a>
                                                        </>
                                                    );
                                                  })}
                                                </>
                                            ) : (
                                                <>
                                                  <div
                                                      style={{
                                                        fontSize: "20px",
                                                        color: "#182844",
                                                        fontFamily: "Poppins",
                                                      }}
                                                  >
                                                    Frequently asked questions
                                                  </div>
                                                  <img
                                                      src={faqbaseimage}
                                                      alt="faqbaseimage"
                                                  />
                                                </>
                                            )}
                                          </>
                                        </div>
                                      </div>
                                    </div>
                                ) : (
                                    <>
                                      <div className="row1">
                                        <div className="row title_container_post p-3">
                                          <div className="col-12">
                                            <div
                                                style={{
                                                  fontSize: "24px",
                                                  color: "#182844",
                                                  fontFamily: "Poppins",
                                                }}
                                            >
                                              {activeQuestion.question}
                                            </div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                  __html: activeQuestion.description,
                                                }}
                                                className={"question_description"}
                                            ></div>
                                            {activeQuestion.video && (
                                                <video
                                                    src={
                                                        process.env.REACT_APP_STORAGE_URL +
                                                        activeQuestion.video
                                                    }
                                                    controls
                                                    width="400"
                                                    height="240"
                                                >
                                                  {" "}
                                                </video>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                )}
                                {question && (
                                    <>
                                      {" "}
                                      <div
                                          className="row1"
                                          style={{
                                            borderLeft: "1px solid #E0E0E0",
                                          }}
                                      >
                                        <div className="row title_container_post">
                                          <div className="col-12">
                                            <div
                                                style={{
                                                  fontSize: "20px",
                                                  color: "#182844",
                                                  fontFamily: "Poppins",
                                                }}
                                            >
                                              {question.question}
                                            </div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                  __html: question.description,
                                                }}
                                                className={"question_description"}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                )}
                              </div>
                            </div>
                          </>
                      );
                    })}
                  </div>
                  <div className={"mobile"}>
                    <div className="scrollmenu">

                      {faqCategory.map((c: any, key: any) => {
                        return (
                            <>
                              <a onClick={() => {
                                setQuestionMobile(c)
                              }}
                                className={questionMobile?.id===c?.id ? "activeMobileFaq":""}
                                 style={{fontSize:"16px"}}
                              >
                              {c.name}

                              </a>

                            </>

                        );
                      })}
                    </div>

                {/*  <span onClick={showDrawer}>topic</span>
                 <Drawer
                        title="FAQ Category"
                        placement={"left"}
                        closable={false}
                        onClose={onClose}
                        visible={open}
                        key={"left"}
                        className={"drawerfaqmobile"}
                    >
                      {faqCategory.map((f: any, key: any) => {
                        return (
                            <>
                              <div onClick={() => {
                                setQuestionMobile(f)
                              }}>
                                {f.name}

                              </div>
                              <Divider key={uuidv4()}/>
                            </>

                        );
                      })}
                    </Drawer>*/}

                    {questionMobile &&
                        <Collapse  expandIconPosition={"right"}        expandIcon={(iconProp) =>
                            iconProp.isActive ? (
                                             <MinusOutlined />

                            ) : (
                                <PlusOutlined />
                            )
                        }>
                          {
                            questionMobile?.faqs?.map((el: any, key: number) => {

                              return (
                                  <>
                                    <Panel header={el?.question} key={key}>
                                      <div
                                          dangerouslySetInnerHTML={{
                                            __html: el?.description,
                                          }}
                                          className={"question_description"}
                                      ></div>
                                      <div style={{display:"flex"}}>
                                      {el.video && (
                                          <video
                                              src={
                                                  process.env.REACT_APP_STORAGE_URL +
                                                  el.video
                                              }
                                              controls
                                              width="400"
                                              height="240"
                                          >
                                            {" "}
                                          </video>
                                      )}
                                      </div>
                                    </Panel>
                                  </>
                              )
                            })
                          }


                        </Collapse>
                    }
                  </div>
                </div>
            )}
          </>
        )}
      </div>


    </>
  );
};
