import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import store from "./store/store";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { loadConnectedUser } from "./actions/auth-actions/actions";
import { createBrowserHistory } from "history";
import "antd/dist/antd.css";
import Echo from "laravel-echo";
import logoGreen from "./img/logo_green.svg";
import { preventScreenShot } from "./preventScreenShot";
import TagManager from "react-gtm-module";
import socketio from "socket.io-client";

// (window as any).io = require("socket.io-client");
// (window as any).Echo = new Echo({
//   broadcaster: "socket.io",
//   // host: "https://demco.halber.space:6003",
//   host: "https://api.demcointer.com",
//   client: socketio,
//   // host: "http://localhost:6001",
//   auth: {
//     headers: {
//       Accept: "application/json",
//     },
//   },
// });

const tagManagerArgs: any = {
  gtmId: "GTM-P4P47G9",
  // gtmId: "GTM-5PB9HRK",
};

TagManager.initialize(tagManagerArgs);

const WrappedApp = ({ children }: any) => {
  const { isLoggedIn, isLoadingUser } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  let token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      dispatch(loadConnectedUser(false));
    }

    // PREVENT SCREENSHOT SCRIPT
    preventScreenShot();
  }, []);
  //@ts-ignore
  return isLoadingUser && token ? (
    <div
      style={{ height: "100vh", width: "100%", background: "white" }}
      className="d-flex align-items-center justify-content-center"
    >
      <img src={logoGreen} alt="demco logo" className="loading_logo_demco" />
      {/* <Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} /> */}
    </div>
  ) : (
    children
  );
};

export let history = createBrowserHistory();

if (document.getElementById("root")) {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <WrappedApp>
          <App />
        </WrappedApp>
      </Router>
    </Provider>,
    document.getElementById("root")
  );
}
