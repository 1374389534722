import { Pagination, Spin, Avatar, Tooltip, Popconfirm, Modal, Row, Col, Form, Select  } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../config/axios-instance";
import {LoadingOutlined, UserOutlined, EyeOutlined, SearchOutlined} from "@ant-design/icons";
import "../components/AllClients/AllClient.scss";
import { Link, useLocation, useHistory } from "react-router-dom";
import company_placeholder from "../img/company_placeholder.png";
import { useSelector } from "react-redux";
import Head from "../components/Head";
import Trash from "../icons/Trash";
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import "../components/TeamManagement/TeamManagement.scss";
import AddNewCompany from "../components/Companies/AddNewCompany";
import EditCompany from "../components/Companies/EditCompany";
import { MyNotif } from "../components/NotifIcons/NotifIcons";
import CategoriesServices from "../actions/left-menu-actions/services";
import { color } from "html2canvas/dist/types/css/types/color";
import AddNewClient from "../components/Companies/AddNewClient";
import {Spinner} from "../components/Spinner";
import customizeIcon from "../img/customize_icon.svg";
import {UserAddOutlined} from "@ant-design/icons";
import {HrIcon} from "../components/product-3d-config-tabs/forms/canvas/icon/HrIcon";

const { Option } = Select;

const AllCompany = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleAddClient, setVisibleAddClient] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [company, setCompanyToEdit] = useState<any>("");
  const [company_id, setCompanyid] = useState<any>("");
  const [fabric_type, setFabicType] = useState<any>("");
  const [gender, setGenders] = useState<any>([]);

  const { data: genders, isLoading: gendersIsLoading } = useQuery(
    "genders",
    CategoriesServices.getGenders
  );
  const handleSearchChange = (event:any) => {
    setSearchTerm(event.target.value);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
   // sendInvitation(values);
  };

  const [open, setOpen] = useState(false);

  const history = useHistory();
  const { user } = useSelector((state: any) => state.auth);

  const showModal = (id:any) => {
    setCompanyid(id)
    setOpen(true);
  };

  const handleOk = () => {


    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "post",
      url: `/admin/approvecompany/${company_id}`,
      data:{fabric_type,gender},
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response)=>{
      MyNotif("Company approved successfully.", "success");
      handleCancel();
      refetch();
    });
  /*   setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000); */
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const onCloseAdd = () => {
    setVisibleAdd(false);
  };

  const onCloseEdit = () => {
    setVisibleEdit(false);
  };


  const onCloseAddClient = () => {
    setVisibleAddClient(false);
  };

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQueryy();

  const fetchClients = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/companies?page=${query.get("page") || 1}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  /*   const { isLoading, isError, data, error } = useQuery(
    ["companies", query.get("page")],
    () => fetchClients(),
    { keepPreviousData: true }
  ); */

  const getCompany = () => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "get",
      url: `/admin/companies?page=${query.get("page") || 1}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const deleteCompany = (id:any) => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "delete",
      url: `/admin/deletecompany/${id}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response)=>{
      MyNotif("Company deleted successfully.", "success");

      refetch();
    });
  };

  const approveCompany = (id:any) => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "post",
      url: `/admin/approvecompany/${id}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response)=>{
      MyNotif("Company approved successfully.", "success");

      refetch();
    });
  };
  const handleChangeFabric = (value:any)=>{
    setFabicType(value)
  }

  const handleChangeGender = (values:any)=>{
    setGenders(values) 
 }

const fetchData = (url:any) => {
  let token = localStorage.getItem("token");

    return axiosInstance({
      method: "get",
      url: url,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      // Handle the response and update the component state with the fetched data
      const fetchedData = response.data;
      // Update the state with the fetched data
      // ...
    })
    .catch((error) => {
      // Handle any errors that occur during the API call
      console.error('Error fetching data:', error);
    });
};
  const {
    data: data,
    isLoading: isLoading,
    refetch: refetch,
  } = useQuery("members", getCompany, {
    refetchOnWindowFocus: false,
  });


  const filteredData = data?.data.data.filter((el:any) =>
  el.name.toLowerCase().includes(searchTerm.toLowerCase())
);
  useEffect(() => {
    console.log(data?.data.data)
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);


  function goto(el:any) {
    history.push(`/teamcompany/${el.id}`);
  }

  return isLoading ? (
   <div className="loading_orders">
     {/* <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />*/}


     <Spinner />
    </div>

     /* */
  ) : (
    <div className="my_orders">
      <Head title="Orders" />
      <div className="container">
        <div className="row
        ">
         
        </div>
        <div className="d-flex align-items-center justify-content-between pr-1 mb-1">
          <div> 
         
          <span className="mb-0 mt-3 list_of_companies_title" >List of Companies</span>
         {/*  <button
            className="invite_btn px-3 px-md-3 mt-3"
            onClick={() => setVisibleAdd(true)}>
            Add New Company
          </button> */}</div>
         <div>


           <button
               className="invite_btn px-3 px-md-3 mt-3"
               onClick={() => setVisibleAddClient(true)}>

             <span className={"add-user-text "}>
           Add New Client</span>
             <span className="add-user-icon"><UserAddOutlined /></span>
           </button>
         </div>
        </div>
        <div className="row mt-4">
          {/* <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search your company..."
        className="option_details_input"
      />*/}
          <div className="search-bar searchfaq" style={{width: "100%"}}>
            <div className="input-container">
              <input type="text" placeholder="Search your company..." onChange={handleSearchChange}
                     className={"search_input"}/>
              <HrIcon></HrIcon>
              <button type="submit" className={"submit_input"}><SearchOutlined/></button>
            </div>
          </div>
        </div>
        <table className="table mt-2">
          <th className={"font-size-head"}>Company</th>
          <th className={"font-size-head"}>Owner</th>
          <th className={"font-size-head"}>Action</th>
          <tbody>
          {filteredData?.map((el: any, i: number) => (
              <tr key={i} style={{height: "66px"}} onClick={()=>goto(el)}>
                <td>
                  <div className="d-flex align-items-center">
                    <img
                        className="mr-3"
                        src={
                          el.logo
                          ? process.env.REACT_APP_STORAGE_URL + el.logo
                          : company_placeholder
                      }
                      width="30"
                    />
                    <p className="mb-0 font-size-head">{el.name}</p>
                  </div>
                </td>
                <td>
                  {" "}
                  <p className="mb-0 font-size-head">{el.owner?.name}</p>
                </td>
                <td>
                 {/* <Link
                    to={`/teamcompany/${el.id}`}
                    className=" button_member"
                    color="#00468c">
                    <EyeOutlined></EyeOutlined>
                  </Link>*/}
                  <div className={"row"}>
                  {user && user.type !== "commercial" && (
                    <button
                    className={
                        el.approved == 0
                          ? "button_member"
                          : "button_member approved_active"
                      }
                      //onClick={()=>approveCompany(el.id)}
                      onClick={()=>showModal(el.id)}
                                     >
                      <CheckOutlined
                        className={
                          el.approved == 0 ? "not-approved" : "approved"
                        }
                      />
                    </button>
                  )}
                 {/*  <button
                    className=" button_member"

                    onClick={() => {
                        setVisibleEdit(true);
                        setCompanyToEdit(el);
                                          }}>
                    <EditOutlined style={{ color: "#00468c" }} />
                  </button>
 */}
                  <>
                    <Popconfirm
                      title="Are you sure to delete this company?"
                      onConfirm={() => {
                               deleteCompany(el.id);
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No">
                      <button className="button_member">
                        <Trash />
                      </button>
                    </Popconfirm>
                  </>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5}>
              {/*   <Pagination
                  defaultCurrent={1}
                  defaultPageSize={10}
                  total={data && data.data.total}
                  hideOnSinglePage
                  responsive
                 //  onChange={(page: any) =>
                  //  history.push(`/all-company?page=${page}`)
                 // }
                 // onChange={(page: number) => {
                  //  const url = `/admin/companies?page=${page}`;
                  //  history.push(`/all-company?page=${page}`)
                   // fetchData(url); // Fetch data again for the updated page
                //  }}
                /> */}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <Modal
        title="Approve Company"
        visible={open}
        onOk={handleOk}
        //confirmLoading={confirmLoading}
        onCancel={handleCancel}
        maskClosable={false}
        keyboard={false}
      >
        <div>
        <Form
        layout="vertical"
        hideRequiredMark
        style={{ padding: 24, paddingTop: 0 }}
         onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        initialValues={{ remember: true }}
        className="invite-teammate-drawer"
        id={"approve_company_team"}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="fabric_type"
              label="Fabric type"
              className="h2-blue-hj my-2"
              style={{ color: "#182844FF" }}
              rules={[
                { required: true, message: "Please select a fabric type" },
              ]}
            >
              <Select
                placeholder="Please select an owner"
                className="select-invite-teammate"
                onChange={(e)=>handleChangeFabric(e)}
              >
                <Option value="denim">Denim</Option>
                <Option value="knit">Knit</Option>
                <Option value="knit_denim">Both</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="genders"
              label="Gender"
              className="h2-blue-hj my-2"
              style={{ color: "#182844FF" }}
              rules={[
                {
                  required: true,
                  message: "Please choose at least one gender",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please choose at least one gender"
                className="select-invite-teammate"
                loading={gendersIsLoading}
              //  onChange={(x: any, y: any) => console.log(x)}
                style={{ width: "100%" }}
                onChange={handleChangeGender}

              >
                 {genders?.data.map((item: any) =>
                  item?.childs?.length > 0 ? (
                    item?.childs?.map((child: any) => (
                      <Select.Option value={child.id} key={child.id}>
                        {child.name}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
</Form>
        </div>
      </Modal>
        <AddNewCompany
        refetch={refetch}
        onClose={() => {
          onCloseAdd();
        }}
        visible={visibleAdd}></AddNewCompany>
      <EditCompany
        refetch={refetch}
        onClose={() => {
          onCloseEdit();
        }}
        company={company}
        visible={visibleEdit}></EditCompany>

        <AddNewClient
                refetch={refetch}
                onClose={() => {
                  onCloseAddClient();
                }}
                company={company}
                visible={visibleAddClient}></AddNewClient>
    </div>
  );
};

export default AllCompany;
