import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/content.min.css";
import { Editor } from "@tinymce/tinymce-react";
import tinymce from "tinymce";
import { useState } from "react";

export const LongTextEditor = ({ setValue, initValue, register }: any) => {
  const [state, setState] = useState("");
  const handleEditorChange = (content: any, editor: any) => {
    setState(content);
    setValue("long_description", content);
  };
  return (
    <Editor
      apiKey="ui0eppigbtgiohkehra0oc0a4yuqn2hlkgektad0e4vzaimz"
      plugins={[
        // "advlist autolink lists link charmap print preview anchor",
        "searchreplace visualblocks code fullscreen image imagetools",
        // "insertdatetime media table paste wordcount",
      ]}
      {...register("long_description")}
      initialValue={initValue}
      toolbar={[
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
      ]}
      //@ts-ignore
      imagetools_toolbar="rotateleft rotateright | flipv fliph | editimage imageoptions"
      init={{
        height: 500,
        icons: "jam",
        skin: "fabric",
        content_css: "document",
        menubar: false,
        //   file_picker_types: "image",
        //   skin: false,
        //   image_dimensions: true,
        //   // images_upload_handler https://www.tiny.cloud/docs/plugins/opensource/image/
        //   // object_resizing: "true",
        //   resize: "both",

        //   content_css: true,
        //   // automatic_uploads: false,
        file_picker_callback: function (cb, value, meta) {
          var input: any = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.onchange = function () {
            var file = this.files[0];

            var reader: any = new FileReader();
            reader.onload = function () {
              var id = "blobid" + new Date().getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
      onEditorChange={handleEditorChange}
    />
  );
};
