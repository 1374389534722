import { Reducer, AnyAction } from "redux";
import {
  INVITE_MEMBER_REQUEST,
  INVITE_MEMBER_SUCCESS,
  INVITE_MEMBER_FAIL,
  GET_COMPANY_WITH_MEMBERS_REQUEST,
  GET_COMPANY_WITH_MEMBERS_SUCCESS,
  REMOVE_MEMBER_REQUEST,
  REMOVE_MEMBER_SUCCESS,
} from "../actions/members-actions/types";

const init_state: any = {
  company: null,
  isLoading: false,
  memberInvited: null,
  removing: false,
  inviting: false,
};

const MembersReducer: Reducer<any, AnyAction> = (
  state: any = init_state,
  action
): boolean => {
  switch (action.type) {
    case INVITE_MEMBER_REQUEST:
      return { ...state, inviting: true };
    case GET_COMPANY_WITH_MEMBERS_REQUEST:
      return { ...state, isLoading: true };
    case INVITE_MEMBER_SUCCESS:
      return {
        ...state,
        memberInvited: action.payload,
        company: {
          ...state.company,
          clients: [...state.company.clients, action.payload],
        },
        isLoading: false,
        inviting: false,
      };
    case GET_COMPANY_WITH_MEMBERS_SUCCESS:
      return { ...state, company: action.payload, isLoading: false };
    case INVITE_MEMBER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case REMOVE_MEMBER_REQUEST:
      return { ...state, removing: action.payload };
    case REMOVE_MEMBER_SUCCESS:
      return {
        ...state,
        removing: false,
        company: {
          ...state.company,
          clients: state.company.clients.filter(
            (el: any) => el.id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
};

export default MembersReducer;
