import { AnyAction, Reducer } from "redux";
import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_ALL_TYPES_SUCCESS,
  GET_ALL_TYPES_FAIL,
  GET_ALL_TYPES_REQUEST,
  FILTER_PRODUCTS_REQUEST,
  FILTER_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FILTERED_REQUEST,
  GET_PRODUCTS_FILTERED_SUCCESS,
  GET_PRODUCTS_FILTERED_FAIL,
  ADD_FILTER,
  DELETE_FILTER,
  ONTYPING_PRODUCTS_FILTER_REQUEST,
  ONTYPING_PRODUCTS_FILTER_SUCCESS,
  ONTYPING_PRODUCTS_FILTER_FAIL,
  GET_ONE_PRODUCT_REQUEST,
  GET_ONE_PRODUCT_SUCCESS,
  GET_ONE_PRODUCT_FAIL,
  GET_PRODUCTS_NEXT_REQUEST,
  GET_PRODUCTS_NEXT_SUCCESS,
  FILTER_PRODUCTS_KEEP_ALL_REQUEST,
  FILTER_PRODUCTS_KEEP_ALL_SUCCESS,
  CLEAR_ALL_FILTERS,
  LOAD_PRODUCTS_FILTER_REQUEST,
  LOAD_PRODUCTS_FILTER_SUCCESS,
  LOAD_PRODUCTS_FILTER_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  GET_FILTER_REQUEST,
  GET_FILTER_SUCCESS,
  GET_FILTER_FAIL,
} from "../actions/products-actions/types";

const initState: any = {
  products: [],
  productsAll: [],
  selectedProduct: null,
  nextProduct: null,
  prevProduct: null,
  isLoading: true,
  isLoadingNext: false,
  next_page: false,
  prev_page: false,

  prices: { min_price: 0, max_price: 0 },
  elasticity_fabric: { min_elasticity_value: 0, max_elasticity_value: 0 },
  weight_fabric: { min_elasticity_value: 0, max_elasticity_value: 0 },

  fibers: [],
  colors: [],
  allcolors: [],
  washes: [],
  elasticities: [],
  weights: [],
  categories: [],
  compositions: [],
  techniques: [],
  special_fibers: [],
  eim_scores: [
    { id: "green", name: "Green" },
    { id: "orange", name: "Orange" },
    { id: "red", name: "Red" },
  ],
  labels: [],
  responsible_choices: [],
  fabric_chars: [],
  accessories: [],
  genders: [],
  fabrics: [],
  genders_filter: null,
  collections: [],
  dress_codes: [],
  product_fabric_types: [],
  all_product_fabric_types: [],
  tags: [],
  fits: [],
  mills: [],
  searchIsLoading: false,
  filters: null,
  isLoadingMore: false,
  isLoadingUpdate: false,
  isLoadingFilter: false,
  error: null,
};

const productReducer: Reducer<any, AnyAction> = (
  state: any = initState,
  action
): boolean => {
  switch (action.type) {
    case GET_FILTER_REQUEST:
      return {
        ...state,
        isLoadingFilter: true,
      };
    case GET_FILTER_SUCCESS:
      let gendersList = action.payload["Gender"];
      const groupBy = (array: any, key: any) => {
        // Return the end result
        return array.reduce((result: any, currentValue: any) => {
          // If an array already present for key, push it to the array. Else create an array and push the object
          (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
          );
          // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
          return result;
        }, {}); // empty object is the initial value for result object
      };
      console.log(action.payload);
      return {
        ...state,
        isLoadingFilter: false,
        colors: action.payload.Colours,
        allcolors: action.payload.AllColors,
        washes: action.payload.Washes,
        weights: action.payload.Weights,
        mills: action.payload.Mills,
        fabrics: action.payload.Fabrics,
        special_fibers: action.payload["Special fibers"],
        labels: action.payload.Labels,
        responsible_choices: action.payload["Our responsible choice"],
        fits: action.payload.Fit,
        compositions: action.payload.Compositions || [],
        dress_codes: action.payload["Dress code"] || [],
        product_fabric_types: action.payload["Fabric types"] || [],
        all_product_fabric_types: action.payload["All Fabric types"] || [],

        techniques: action.payload.Techniques || [],
        elasticities: action.payload.Elasticities || [],
        fabric_chars: action.payload["Fabric characteristics"] || [],
        genders_filter: groupBy(gendersList, "gender_id") || [],
        genders: action.payload["Gender"] || [],
      };
    case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        isLoadingUpdate: true,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        selectedProduct: action.payload,
        isLoadingUpdate: false,
      };
    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        isLoadingUpdate: false,
        error: action.payload,
      };
    case ONTYPING_PRODUCTS_FILTER_REQUEST:
      return {
        ...state,
        searchIsLoading: true,
      };
    case GET_PRODUCTS_REQUEST:
    case FILTER_PRODUCTS_REQUEST:
    case GET_ONE_PRODUCT_REQUEST:
    case GET_PRODUCTS_FILTERED_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    // case GET_ALL_TYPES_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    case GET_PRODUCTS_NEXT_REQUEST:
      return {
        ...state,
        isLoadingNext: true,
      };
    case FILTER_PRODUCTS_KEEP_ALL_REQUEST:
      return {
        ...state,
        // products: [],
        // productsAll: [],
        isLoading: true,
      };
    case GET_ONE_PRODUCT_SUCCESS:
      let indexOfSelected = state.products.findIndex(
        (el: any) => el.id === action.payload.id
      );
      return {
        ...state,
        isLoading: false,
        selectedProduct: action.payload,
        nextProduct: state.products[indexOfSelected + 1]
          ? state.products[indexOfSelected + 1]
          : null,
        prevProduct: state.products[indexOfSelected - 1]
          ? state.products[indexOfSelected - 1]
          : null,
      };
    case GET_PRODUCTS_SUCCESS:
    case GET_PRODUCTS_FILTERED_SUCCESS:
      if (state.selectedProduct) {
        let indexOfProduct = action.payload.data.findIndex(
          (el: any) => el.id === state.selectedProduct.id
        );
        return {
          ...state,
          isLoading: false,
          products: action.payload.data,
          nextProduct: action.payload.data[indexOfProduct + 1]
            ? action.payload.data[indexOfProduct + 1]
            : null,
          prevProduct: action.payload.data[indexOfProduct - 1]
            ? action.payload.data[indexOfProduct - 1]
            : null,
          next_page: action.payload.next_page_url
            ? action.payload.next_page_url
            : null,
          prev_page: action.payload.prev_page_url,
        };
      }
      return {
        ...state,
      };
    case FILTER_PRODUCTS_KEEP_ALL_SUCCESS:
      console.log(action.payload.data);
      return {
        ...state,
        productsAll: action.payload.data.data,
        prices: action.payload.data.prices,
        elasticity_fabric: action.payload.data.elasticity,
        weight_fabric: action.payload.data.weight,
      };
    case GET_PRODUCTS_NEXT_SUCCESS:
      return {
        ...state,
        isLoadingNext: false,
        products: [...state.products, ...action.payload.data],
        next_page: action.payload.next_page_url,
        prev_page: action.payload.prev_page_url,
      };
    case ONTYPING_PRODUCTS_FILTER_FAIL:
      return {
        ...state,
        searchIsLoading: false,
      };
    case GET_PRODUCTS_FAIL:
    case GET_PRODUCTS_FILTERED_FAIL:
    case GET_ONE_PRODUCT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_ALL_TYPES_SUCCESS:
      if (action.payload.type === "technics") {
        return {
          ...state,
          // isLoading: false,
          techniques: action.payload.data,
        };
      }
      if (action.payload.type === "fabric_char") {
        return {
          ...state,
          // isLoading: false,
          fabric_chars: action.payload.data,
        };
      }

      return {
        ...state,
        // isLoading: false,
        [action.payload.type]: action.payload.data,
      };

    case GET_ALL_TYPES_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case LOAD_PRODUCTS_FILTER_REQUEST:
      return {
        ...state,
        isLoadingMore: true,
      };
    case LOAD_PRODUCTS_FILTER_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        products: [...state.products, ...action.payload.data],
      };
    case LOAD_PRODUCTS_FILTER_FAIL:
      return {
        ...state,
        isLoadingMore: false,
        error: action.payload,
      };
    case ONTYPING_PRODUCTS_FILTER_SUCCESS:
      return {
        ...state,
        searchIsLoading: false,
        products: action.payload.data,
        next: action.payload.next_page_url || null,
        total: action.payload.total,
      };
    case FILTER_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: action.payload.data.data,
        next_page: action.payload.data.next_page_url,
        prev_page: action.payload.data.prev_page_url,
      };
    case ADD_FILTER:
      let filterItems = action.payload.join("=").split("=");
      var ItemKeys;
      var elements;
      let arr = filterItems.map((el: string, index: number) => {
        switch (el) {
          case "colors":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.colors.find((x: any) => x.id === Number(el)),
                filteredBy: "colors",
              };
            });
            return elements;

          case "allcolors":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.allcolors.find((x: any) => x.id === Number(el)),
                filteredBy: "allcolors",
              };
            });
            return elements;
          case "washes":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.washes.find((x: any) => x.id === Number(el)),
                filteredBy: "washes",
              };
            });
            return [...elements];

          case "mills":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.mills.find((x: any) => x.id === Number(el)),
                filteredBy: "mills",
              };
            });
            return [...elements];
          // return {
          //   ...state.washes.find(
          //     (x: any) => x.id === Number(filterItems[index + 1])
          //   ),
          //   filteredBy: "washes",
          // };
          case "elasticities":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.elasticities.find((x: any) => x.id === Number(el)),
                filteredBy: "elasticities",
              };
            });
            return [...elements];
          case "eim_scores":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.eim_scores.find((x: any) => x.id === el),
                filteredBy: "eim_scores",
              };
            });
            return [...elements];

          case "weights":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.weights.find((x: any) => x.id === Number(el)),
                filteredBy: "weights",
              };
            });
            return [...elements];
          case "fibers":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.fibers.find((x: any) => x.id === Number(el)),
                filteredBy: "fibers",
              };
            });
            return [...elements];
          case "compositions":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.compositions.find((x: any) => x.id === Number(el)),
                filteredBy: "compositions",
              };
            });
            return [...elements];
          case "techniques":
          case "technics":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.techniques.find((x: any) => x.id === Number(el)),
                filteredBy: "technics",
              };
            });
            return [...elements];
          case "special_fibers":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.special_fibers.find((x: any) => x.id === Number(el)),
                filteredBy: "special_fibers",
              };
            });
            return [...elements];
          case "fabric_chars":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.fabric_chars.find((x: any) => x.id === Number(el)),
                filteredBy: "fabric_chars",
              };
            });
            return [...elements];
          case "product_fabric_types":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.product_fabric_types.find(
                  (x: any) => x.id === Number(el)
                ),
                filteredBy: "product_fabric_types",
              };
            });
            return [...elements];

          case "all_product_fabric_types":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.all_product_fabric_types.find(
                  (x: any) => x.id === Number(el)
                ),
                filteredBy: "all_product_fabric_types",
              };
            });
            return [...elements];

          case "genders":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.genders.find(
                  (x: any) =>
                    x.id === 4
                      ? state.genders_filter[x.id].find(
                          (y: any) => y.id === Number(el)
                        )
                      : x.id === Number(el)
                  // ? x.childs.find((y: any) => y.id === Number(el))
                  // : x.id === Number(el)
                ),
                filteredBy: "genders",
              };
            });
            return [...elements];

          case "labels":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.labels.find((x: any) => x.id === Number(el)),
                filteredBy: "labels",
              };
            });
            return [...elements];
          case "responsible_choices":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.responsible_choices.find(
                  (x: any) => x.id === Number(el)
                ),
                filteredBy: "responsible_choices",
              };
            });
            return [...elements];
          case "dress_codes":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.dress_codes.find((x: any) => x.id === Number(el)),
                filteredBy: "dress_codes",
              };
            });
            return [...elements];
          case "tags":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.tags.find((x: any) => x.id === Number(el)),
                filteredBy: "tags",
              };
            });
            return [...elements];
          case "fits":
            ItemKeys = filterItems[index + 1].split(",");
            elements = ItemKeys.map((el: string) => {
              return {
                ...state.fits.find((x: any) => x.id === Number(el)),
                filteredBy: "fits",
              };
            });
            return [...elements];
          default:
            return;
        }
      });
      return {
        ...state,
        filters: arr.filter((el: any) => el).flat(),
      };
    case DELETE_FILTER:
      return {
        ...state,
        filters: state.filters.filter(
          (element: any) => element.name !== action.payload
        ),
      };
    case GET_FILTER_FAIL:
      return {
        ...state,
        isLoadingFilter: false,
      };
    case CLEAR_ALL_FILTERS:
      return {
        ...state,
        filters: [],
      };
    default:
      return state;
  }
};

export default productReducer;
