import React, { useEffect, useState } from "react";
import { discoverData } from "../../StaticData/DiscoverData";
import { DiscoverCard } from "../../components/DiscoverCard/DiscoverCard";
import "./DiscoverList.scss";
import axiosInstance from "../../config/axios-instance";
import special from "../../img/special.png";
import SpecialCollection from "../../img/SpecialCollection.jpg";
/* import seasons from "../../img/seasons.png"; */
import axios from "axios";
import { DiscoverItem } from "../DiscoverCard/DiscoverItem";
import { useSelector } from "react-redux";
/* import Garments from "../../img/garmentV2.jpg";
import knit_garment from "../../img/garmentV2.jpg";
import knit_special_collections from "../../img/collection2.jpg";
import seasons from "../../img/session2.jpg"; */
/* import Garments from "../../img/Garments.jpg";
import knit_garment from "../../img/knit_garments.jpg";
import knit_special_collections from "../../img/knit_special_collections.jpg"; */

import Garments from "../../img/gam_1.webp";
import knit_garment from "../../img/gam_1.webp";
import knit_special_collections from "../../img/col_1.webp";
import seasons from "../../img/ses_1.webp";
import { Skeleton } from "antd";

interface Props {}

export const DiscoverList = (props: Props) => {
  const [specialCollectionsRes, setSpecialCollectionsRes] = useState<any>(null);
  const [seasonCollectionRes, setSeasonCollectionRes] = useState<any>(null);
  const [collectionRes, setCollectionRes] = useState<any>(null);
  const [responsibleChoices, setResponsibleChoicesRes] = useState<any>(null);

  const [menObj, setMenObj] = useState<any>({});
  const [womenObj, setWomenObj] = useState<any>({});
  const [unisexObj, setUnisexObj] = useState<any>({});
  const [garmentsRes, setGarmentsRes] = useState<any>(null);
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  let specialCollections = "/collections/56";
  let seasonCollection = "/collections/25";
  let collections = "/collections/home/published";

  let garments = `/categories?gender=${
    user && !isAdmin && user.genders && user.genders.length !== 0
      ? user.genders.find((el: any) => el.id === 1)
        ? "1"
        : user.genders[0].id
      : "1"
  }&fabric_type=${
    user && !isAdmin && user.fabric_type === "knit" ? "knit" : "denim"
  }`;

  let responsible_choices = `/filters/reponsible?fabric_type=${
    user && !isAdmin && user.fabric_type === "knit" ? "knit" : "denim"
  }`;
  console.log(garments);
  const uniqByKeepLast = (a: any, key: any) => {
    return [...new Map(a.map((x: any) => [key(x), x])).values()];
  };
  React.useEffect(() => {
    const specialCollectionsReq = axiosInstance({
      method: "get",
      url: specialCollections,
    });

    const seasonCollectionReq = axiosInstance({
      method: "get",
      url: seasonCollection,
    });
    const garmentsReq = axiosInstance({
      method: "get",
      url: garments,
    });
    const collectionsReq = axiosInstance({
      method: "get",
      url: collections,
    });
    const responsibleChoicesReq = axiosInstance({
      method: "get",
      url: responsible_choices,
    });
    axios
      .all([
        specialCollectionsReq,
        seasonCollectionReq,
        garmentsReq,
        collectionsReq,
        responsibleChoicesReq,
      ])
      .then(
        axios.spread((...responses) => {
          setSpecialCollectionsRes(responses[0].data);
          setSeasonCollectionRes(responses[1].data);
          setGarmentsRes(uniqByKeepLast(responses[2].data, (k: any) => k.name));
          setCollectionRes(responses[3].data);
          setResponsibleChoicesRes(responses[4].data.our_responsible_choice);
        })
      )

      .catch((errors) => {});
  }, []);

  React.useEffect(() => {
    if (user && !isAdmin && user.genders.length !== 0) {
      setMenObj(
        user && !isAdmin && user.genders.find((el: any) => el.name === "Men")
      );
      setWomenObj(
        user && !isAdmin && user.genders.find((el: any) => el.name === "Women")
      );
      setUnisexObj(
        user && !isAdmin && user.genders.find((el: any) => el.name === "Unisex")
      );
    } else {
      setMenObj({
        id: 1,
        name: "Men",
      });
    }
  }, []);
  const [sectionContent, setSectionContent] = useState<any>(null);

  const getsectionContent = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/home/section2`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setSectionContent(res.data);
    });
  };

  useEffect(() => {
    getsectionContent();
  }, []);

  return (
    <div className="discover_list">
      {!sectionContent ? (
        <>
          {" "}
          <Skeleton.Avatar active={true} shape={"square"} />
        </>
      ) : (
        <>
          <div
            className="dicover_card"
            style={{
              /*      backgroundImage: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.25)), url(${
            user && user.fabric_type !== "knit" ? Garments : knit_garment
          })`, */
              backgroundImage: ` url(${
                process.env.REACT_APP_STORAGE_URL + sectionContent?.image_1
              })`,
              backgroundPosition: "center",
            }}
          >
            {garmentsRes && (
              <DiscoverItem
                data={garmentsRes}
                garment
                name={sectionContent?.title_1}
              />
            )}
          </div>
        </>
      )}
      {!sectionContent ? (
        <>
          {" "}
          <Skeleton.Avatar active={true} shape={"square"} />
        </>
      ) : (
        <>
          <div
            className="dicover_card"
            style={{
              /*           backgroundImage: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.25)), url(${knit_special_collections})`,
               */ backgroundImage: ` url(${
                process.env.REACT_APP_STORAGE_URL + sectionContent?.image_2
              })`,

              backgroundPosition: "center",
            }}
          >
            {collectionRes && (
              <DiscoverItem
                data={collectionRes.filter((k: any) => !k.collection_id)}
                name={sectionContent?.title_2}
              />
            )}
          </div>
        </>
      )}

      {/*      <div
        className="dicover_card"
        style={{
          backgroundPosition:
            user && user.fabric_type !== "knit" ? "center" : "30%",
          backgroundImage: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.25)), url(${
            user && user.fabric_type !== "knit"
              ? SpecialCollection
              : knit_special_collections
          })`,
        }}
      >
        {specialCollectionsRes && <DiscoverItem data={specialCollectionsRes} />}
      </div> */}
      {!sectionContent ? (
        <>
          {" "}
          <Skeleton.Avatar active={true} shape={"square"} />
        </>
      ) : (
        <>
          <div
            className="dicover_card"
            style={{
              /*           backgroundImage: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.25)), url(${seasons})`,
               */
              backgroundImage: ` url(${
                process.env.REACT_APP_STORAGE_URL + sectionContent?.image_3
              })`,
              backgroundPosition: "center",
            }}
          >
            {seasonCollectionRes && (
              <DiscoverItem
                data={seasonCollectionRes}
                name={sectionContent?.title_3}
              />
            )}
          </div>
        </>
      )}
      {/*    <div
        className="dicover_card"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.25)), url(${discoverData[1].img})`,
          backgroundPosition: "center",
        }}
      >
        {responsibleChoices && (
          <DiscoverItem data={responsibleChoices} name="Responsible Choices" />
        )}
      </div> */}
      {!sectionContent ? (
        <>
          {" "}
          <Skeleton.Avatar active={true} shape={"square"} />
        </>
      ) : (
        <>
          <DiscoverCard
            title={sectionContent?.title_4}
            submenu={responsibleChoices}
            image={process.env.REACT_APP_STORAGE_URL + sectionContent?.image_4}
          />
        </>
      )}
    </div>
  );
};
