import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Select } from "antd";
import { useEffect, useState } from "react";
import { getType } from "../../actions/products-actions/actions";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

interface Props {
  optionToUpdate: any;
  handleChange: (e: any) => void;
  optionKey: string;
  selectHandleChange: any;
  setSelectedSelect: (e: any) => void;
  optionSelected: any;
}

export const SelectItemOptionDetails = ({
  optionKey,
  handleChange,
  optionToUpdate,
  selectHandleChange,
  setSelectedSelect,
  optionSelected,
}: Props) => {
  const [other, setOther] = useState(false);
  const [washInput, setWashInput] = useState("");
  const [qtyInput, setQtyInput] = useState("");

  const dispatch = useDispatch();
  const { washes } = useSelector((state: any) => state.products);

  // Get DEMCO's washes options
  useEffect(() => {
    dispatch(getType("washes"));
  }, []);

  // Set Other to false when change the option
  useEffect(() => {
    setOther(false);
  }, [optionSelected]);
  // check if user selected other option
  useEffect(() => {
    if (optionToUpdate[optionKey] === "other") {
      setOther(true);
    }
  }, [optionToUpdate]);

  const handleChangeTextInputSelect = (e: any) => {
    if (e.target.name === "qty") {
      setQtyInput(e.target.value);
    } else {
      setWashInput(e.target.value);
    }
  };

  const handelSubmit = (e: any) => {
    e.preventDefault();
    if (optionKey === "qty") {
      selectHandleChange(qtyInput);
    } else {
      selectHandleChange(washInput);
    }
  };

  return (
    <AnimatePresence exitBeforeEnter={true}>
      {other ? (
        // if other is selected, show text input instead if select input
        <motion.input
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          value={optionToUpdate[optionKey]}
          onChange={handleChange}
          name={optionKey}
          className="option_details_input"
        />
      ) : (
        // else select
        <Select
          value={optionToUpdate[optionKey]}
          style={{ width: "100%" }}
          onChange={selectHandleChange}
          onClick={() => setSelectedSelect(optionKey)}
          dropdownStyle={{
            borderRadius: 10,
            fontWeight: 600,
            fontSize: 20,
          }}
          suffixIcon={() => <FontAwesomeIcon icon={faCaretDown} />}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <form
                className="input_in_select_wrapper p-2"
                onSubmit={handelSubmit}
              >
                <input
                  type="text"
                  onChange={handleChangeTextInputSelect}
                  name={optionKey}
                  placeholder={
                    optionKey === "qty"
                      ? "Or you can add your desired quantity"
                      : "Or you can add your own wash name here"
                  }
                  style={{
                    border:
                      // emptyFields && emptyFields.includes(el)
                      // ? "1px solid #ff00005e"
                      "1px solid #cececea9",
                    width: "100%",
                  }}
                  className={`option_details_input input_in_select`}
                  // style={{
                  //   width: "100%",
                  // }}
                />
                <button
                  type="submit"
                  style={{
                    flex: "none",
                    padding: "8px",
                    display: "block",
                    cursor: "pointer",
                  }}
                  className="add_new_wash_btn d-flex align-items-center ml-2"
                >
                  <PlusOutlined className="mr-2" />{" "}
                  {optionKey === "qty"
                    ? "Add your desired quantity"
                    : "Add your wash name"}
                </button>
              </form>
            </div>
          )}
        >
          {optionKey === "qty" ? (
            <>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </>
          ) : optionKey.includes("wash") ? (
            washes.map((el: any) => (
              <Option value={el.name} key={el.id}>
                {el.name}
              </Option>
            ))
          ) : (
            <>
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="Yiminghe">yiminghe</Option>
            </>
          )}
        </Select>
      )}
    </AnimatePresence>
  );
};
