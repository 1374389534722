import { Avatar, Col, Popconfirm, Row, Select } from "antd";
import { Button, Modal } from "antd";

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../config/axios-instance";
import CustomizationDropZone from "./CustomizationDropZone";
import { FabricRadioButton } from "./radios/fabric-radio-button/FabricRadioButton";
import "../product-3d-config-tabs.scss";

import { EnterIcon } from "../icons/EnterIcon";
import { setSyntheticLeadingComments } from "typescript";
import { MyNotif } from "../../NotifIcons/NotifIcons";
const { confirm } = Modal;

const { Option } = Select;
let token = localStorage.getItem("token");
export type IFabricFormProps = {
  setActiveTabKey: (s: string) => void;
  handleChange: any;
  attachFile: any;
  deleteFile: any;
  globalData: any;
  handleFabricImage: any;
};
const defaultList = [
  {
    id: "0",
    name: "none",
    selected: false,
    image: null,
    supplier: null,
    composition_value: null,
  },
];
const FabricForm: React.FC<IFabricFormProps> = ({
  setActiveTabKey,
  handleChange,
  attachFile,
  deleteFile,
  globalData,
  handleFabricImage,
}) => {
  const [radioSelectedIndex, setRadiosSelectedIndex] = React.useState(2);
  const selectRef = React.useRef(null);

  const [selectedItemObject, setSelectedItemObject] = React.useState<{
    id: string;
    name: string;
    selected: boolean;
    fabricUrl: any;
  }>({
    id: "0",
    name: "none",
    selected: false,
    fabricUrl: null,
  });
  const [fabricList, setFabricList] = React.useState<any>(defaultList);
  const [fabric_files, setFabricFiles] = React.useState<any>([]);
  const [fabric_images, setFabricImages] = React.useState<any>([]);
  const [show, setShow] = React.useState<any>(false);
  const [selectedFabric, setSelectedFabric] = React.useState<any>(false);
  const [fabricId, setFabricId] = React.useState<any>(0);

  const { selectedProduct } = useSelector((state: any) => state.products);
  const params = useParams<{ status: string }>();
  // const { register, control, handleSubmit, watch, errors } = useForm();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [otherFabric, setOtherFabric] = React.useState("");
  const [otherFabricEnter, setOtherFabricEnter] = React.useState("");

  const [selectedId, setSelectedId] = React.useState(null);

  const handleFabricImageClick = (id:any,name:any) => {
    setSelectedId(id);
    globalData.fabric_image = name;
    console.log(name)
  };

  React.useEffect(() => {
    axiosInstance({
      method: "get",
      url: `/fabrics?fabric_types=${
        selectedProduct && params.status === "edit"
          ? selectedProduct.original_product?.fabric_type
          : selectedProduct.fabric_type
      }`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response && response.data) {
        const items = response.data.data;
        setFabricList([
          ...fabricList,
          ...items.map((item: any) => {
            console.log(item.images);
            return {
              id: item.id,
              name: item.name,
              selected: "FLEECE" === item.name,
              image: item.image,
              images: item.images,
              supplier: item.supplier,
              composition_value: item.composition_value,
            };
          }),
        ]);
      }
    });
  }, []);

  useEffect(() => {
    if (params.status === "edit") {
      setRadiosSelectedIndex(
        fabricList.findIndex((el: any) => el.name === globalData.fabric)
      );
    }
  }, [globalData, fabricList]);

  const handleSelectionChange = React.useCallback(
    (item, index) => {
      const tempArray = [...fabricList];
      tempArray.forEach((element, index) => {
        if (element && element.id === item.id) {
          tempArray[index].selected = true;
          setSelectedItemObject({
            id: element.id,
            name: element.name,
            selected: element.selected,
            fabricUrl: element.image,
          });
        }
      });

      setFabricList(tempArray);
      setRadiosSelectedIndex(
        params.status === "new"
          ? index
          : fabricList.findIndex((el: any) => el.name === item.name)
      );
    },
    [fabricList]
  );

  const onChange = (value: any) => {
    fabricList
      .filter((el: any) => el.name === value)
      .map((el: any) => {
        handleFabricImage(el.image, el.name);
        setFabricImages(el.images);
      });
    if (value == "other") {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const onSearch = (value: any) => {
    console.log("search:", globalData.fabric);
    setOtherFabric(value);
  };
  const handleInputKeyDown = (event: any) => {
    if (event.keyCode == 13) {
      globalData.other_fabric = otherFabric;
      globalData.fabric = otherFabric;
      setOtherFabricEnter(otherFabric);
      if (otherFabric != "") {
        setShow(true);
        MyNotif(
          "This new fabric '" + otherFabric + "' was added successfully",
          "success"
        );
        setOtherFabric("");
      }
    }
  };
  const onSubmit = (data: any) => {
    //traja3 el data mte3na bch npushiw'ha fi redux
    //PS : el select traja3 object fih label w el value
    const submitData = { ...data, selectedItemObject, fabric_files };

    // we go to the next tab
    setActiveTabKey("3");
  };

  // const attachFile = (files: any) => {
  //   setFabricFiles(files);
  // };

  // // delete attached file
  // const deleteFile = (file: any) => {
  //   setFabricFiles(fabric_files.filter((el: any) => el.name !== file.name));
  // };

  const DisplayContainer = (data: any) => {
    return (
      <div
        style={{
          position: "absolute",
          /*     height: "17px",
          bottom: "25px", */
          right: "5%",
        }}
      >
        <div>
          {data.data.image ? (
            <>
              <Avatar
                size={40}
                src={process.env.REACT_APP_STORAGE_URL + data.data.image}
                shape="square"
              />{" "}
            </>
          ) : (
            <>
              {" "}
              <Avatar size={40} shape="square" />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="col-6">
      <div>
        <label style={{fontSize: "16px", marginBottom: "10px"}}>Fabric</label>

      </div>
      <div style={{height: "100%", display: "flex", flexDirection: "column" }}>
        <Row id="custom-select">
          <Select
            ref={selectRef}
            showSearch
            placeholder="Select Fabric"
            optionFilterProp="children"
            onSelect={onChange}
            onSearch={onSearch}
            value={globalData.fabric}
            onInputKeyDown={handleInputKeyDown}
            filterOption={(input: any, option: any) =>
              /*   option.children
                .toString()
                .toLowerCase()
                .includes(input.toString().toLowerCase()) */
              option.children
                .toString()
                .toLowerCase()
                .includes(input.toString().toLowerCase())
            }
            style={{ width: "100%" }}
            /*size="large"*/
            id="ant-selector"
            notFoundContent={
              <div className="nice-message">
                <EnterIcon /> Press Enter to add this fabric
              </div>
            }
          >
            {fabricList.map((item: any, index: number) => (
              <Option key={index} value={item.name} style={{ padding: 25 }}>
                <div style={{ marginLeft: "170px" }}>
                  <DisplayContainer data={item} />
                </div>
                {item.name}
              </Option>
            ))}
          </Select>
        </Row>
        {show == false && (
          <Row gutter={[24, 24]}>
            {fabric_images?.map((el: any) => {
                  const isSelected = el.id === selectedId;

              return (
                <>
                  {" "}
                  <div style={{ textAlign: "center", marginLeft: "10px" }}>
                    <FabricRadioButton
                      key={el.id}
                      onClick={() => handleFabricImageClick(el.id,el.image)}
                      selected={isSelected}
                      fabricUrl={
                        el && el.image
                          ? process.env.REACT_APP_STORAGE_URL + el.image
                          : null
                      }
                      name={""}
                    />
                  </div>
                </>
              );
            })}
          </Row>
        )}
        {/* <Row gutter={[24, 24]}>
          <Col span={24}>
            {fabricList.map((item: any, index: number) => (
              <FabricRadioButton
                key={index}
                onClick={() => {
                  handleFabricImage(item.image, item.name);
                  handleSelectionChange(item, index);
                }}
                selected={
                  radioSelectedIndex === index && item.selected === true
                }
                fabricUrl={
                  item && item.image
                    ? process.env.REACT_APP_STORAGE_URL + item.image
                    : null
                }
                name={item.name}
              />
            ))}
          </Col>
        </Row> */}
        {/*      {show == true && (
          <Row>
            <input
              className="style_input"
              type="text"
              placeholder="Some fabric"
              style={{ width: "100%", marginTop: "25px" }}
              name="other_fabric"
              value={globalData.other_fabric}
              onChange={(e: any) => handleChange(e)}
              // ref={register({ required: true })}
            />
          </Row>
        )}
 */}

        <Row>
          <textarea
            placeholder="More Details"
            className="textarea-custom"
            rows={3}
            name="fabric_details"
            onChange={(e: any) => handleChange(e)}
            // ref={register({ required: true })}
            value={globalData.fabric_details}
          />
        </Row>
        <Row>
          <CustomizationDropZone
            attachFile={attachFile}
            detachFile={deleteFile}
            files={globalData.fabric_files}
            objectKey={"fabric_files"}
          />
        </Row>
        {/* <div className="btn-configurator-holder border-top">
          <OrderButton title="Order Now" outlined={true} />
        </div> */}
      </div>
    </div>
  );
};

export { FabricForm };
