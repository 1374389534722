import {
  GET_FOLDERS_REQUEST,
  GET_FOLDERS_SUCCESS,
  GET_FOLDERS_FAIL,
  GET_ONE_FOLDER_REQUEST,
  GET_ONE_FOLDER_SUCCESS,
  GET_ONE_FOLDER_FAIL,
  ADD_ONE_FOLDER_REQUEST,
  ADD_ONE_FOLDER_SUCCESS,
  ADD_ONE_FOLDER_FAIL,
  ADD_PRODUCT_TO_FOLDER_REQUEST,
  ADD_PRODUCT_TO_FOLDER_SUCCESS,
  ADD_PRODUCT_TO_FOLDER_FAIL,
  GET_PRODUCT_ID_TO_ADD,
  CLEAR_ONE_FOLDER,
  UPDATE_FOLDER_REQUEST,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_FAIL,
  GET_RIGHT_FOLDER_REQUEST,
  GET_RIGHT_FOLDER_SUCCESS,
  GET_RIGHT_FOLDER_FAIL,
  GET_RIGHT_SUB_FOLDERS_SUCCESS,
  CLEAR_RIGHT_SIDE_FOLDER,
  GET_FOLDER_DETAILS_REQUEST,
  GET_FOLDER_DETAILS_SUCCESS,
  GET_FOLDER_DETAILS_FAIL,
  ADD_FOLDER_TO_SELECTED,
  HANDLE_MOVING_STATE,
  SET_FOLDER_ID_TO_MOVE_TO,
  CHANGE_FOLDER_ROOT_REQUEST,
  CHANGE_FOLDER_ROOT_SUCCESS,
  CHANGE_FOLDER_ROOT_FAIL,
  GET_ARCHIVED_FOLDERS_REQUEST,
  GET_ARCHIVED_FOLDERS_SUCCESS,
  GET_ARCHIVED_FOLDERS_FAIL,
  ARCHIVE_FOLDER_REQUEST,
  ARCHIVE_FOLDER_SUCCESS,
  ARCHIVE_FOLDER_FAIL,
  RESTORE_FOLDER_REQUEST,
  RESTORE_FOLDER_SUCCESS,
  RESTORE_FOLDER_FAIL,
  CLEAR_SELECTED_FOLDERS,
  CLEAR_FOLDER_TO_MOVE_TO,
  DELETE_FOLDER_REQUEST,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_FAIL,
  SET_FOLDER_ID_TO_MOVE_TO_ON_DRAG,
  CLEAR_FOLDER_TO_MOVE_TO_ON_DRAG,
  ADD_FOLDER_TO_SHOW,
  MOVE_PRODUCT_TO_FOLDER_REQUEST,
  MOVE_PRODUCT_TO_FOLDER_SUCCESS,
  MOVE_PRODUCT_TO_FOLDER_FAIL,
  CLEAR_SELECTED_PRODUCTS,
  ADD_PRODUCT_TO_SELECTED,
  HANDLE_MOVING_STATE_PRODUCT,
  DELETE_PRODUCTS_FROM_FOLDER_REQUEST,
  DELETE_PRODUCTS_FROM_FOLDER_SUCCESS,
  DELETE_PRODUCTS_FROM_FOLDER_FAIL,
  HANDLE_MOVING_STATE_PRODUCTS,
  DESABLE_MOVING_STATE_PRODUCT,
  DESABLE_DELETE_PRODUCTS_DONE,
  CLEAR_FOLDER_DETAILS,
} from "./types";
import FoldersServices from "./services";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";

// get all folders of the connected user
export const getAllFolders = () => async (dispatch: any) => {
  await dispatch({
    type: GET_FOLDERS_REQUEST,
  });
  try {
    const response = await FoldersServices.getAllFolders();
    await dispatch({
      type: GET_FOLDERS_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: GET_FOLDERS_FAIL,
      payload: e.response,
    });
  }
};

// get archived folders of the connected user
export const getArchivedFolders = () => async (dispatch: any) => {
  await dispatch({
    type: GET_ARCHIVED_FOLDERS_REQUEST,
  });
  try {
    const response = await FoldersServices.getArchivedFolders();
    await dispatch({
      type: GET_ARCHIVED_FOLDERS_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: GET_ARCHIVED_FOLDERS_FAIL,
      payload: e.response,
    });
  }
};

// archive folder of the connected user
export const archiveFolder = (idFodler: any) => async (dispatch: any) => {
  await dispatch({
    type: ARCHIVE_FOLDER_REQUEST,
  });
  try {
    const response = await FoldersServices.archiveFolder(idFodler);
    await dispatch({
      type: ARCHIVE_FOLDER_SUCCESS,
      payload: response.data,
    });
    dispatch(getAllFolders());
    MyNotif("Product archived successfully.", "success");
  } catch (e: any) {
    dispatch({
      type: ARCHIVE_FOLDER_FAIL,
      payload: e.response,
    });
  }
};

// delete folder
export const deleteFolder = (idFodler: any) => async (dispatch: any) => {
  await dispatch({
    type: DELETE_FOLDER_REQUEST,
  });
  try {
    const response = await FoldersServices.deleteFolder(idFodler);
    await dispatch({
      type: DELETE_FOLDER_SUCCESS,
      payload: response.data,
    });
    dispatch(getAllFolders());
    MyNotif("Folder deleted successfully.", "success");
  } catch (e: any) {
    dispatch({
      type: DELETE_FOLDER_FAIL,
      payload: e.response,
    });
  }
};

// restore folder of the connected user
export const restoreFolder = (idFodler: any) => async (dispatch: any) => {
  await dispatch({
    type: RESTORE_FOLDER_REQUEST,
  });
  try {
    const response = await FoldersServices.restoreFolder(idFodler);
    await dispatch({
      type: RESTORE_FOLDER_SUCCESS,
      payload: response.data,
    });
    dispatch(getAllFolders());
    MyNotif("Folder restored successfully.", "success");
  } catch (e: any) {
    dispatch({
      type: RESTORE_FOLDER_FAIL,
      payload: e.response,
    });
  }
};

// get one folder
export const getOneFolder = (id: any) => async (dispatch: any) => {
  await dispatch({
    type: GET_ONE_FOLDER_REQUEST,
  });
  try {
    const response = await FoldersServices.getOneFolders(id);
    await dispatch({
      type: GET_ONE_FOLDER_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: GET_ONE_FOLDER_FAIL,
      payload: e.response,
    });
  }
};

// get folder details
export const getFolderDetails = (id: any) => async (dispatch: any) => {
  await dispatch({
    type: GET_FOLDER_DETAILS_REQUEST,
  });
  try {
    const response = await FoldersServices.getOneFolders(id);
    await dispatch({
      type: GET_FOLDER_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: GET_FOLDER_DETAILS_FAIL,
      payload: e.response,
    });
  }
};

// get one folder
export const getRightSideFolder = (id: any) => async (dispatch: any) => {
  await dispatch({
    type: GET_RIGHT_FOLDER_REQUEST,
  });
  try {
    const response = await FoldersServices.getOneFolders(id);
    await dispatch({
      type: GET_RIGHT_FOLDER_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: GET_RIGHT_FOLDER_FAIL,
      payload: e.response,
    });
  }
};

// get right side sub folders
export const getRightSideSubFolders = (id: any) => async (dispatch: any) => {
  await dispatch({
    type: GET_RIGHT_SUB_FOLDERS_SUCCESS,
    payload: id,
  });
};

// handle moving state of folders
export const handleMovingState = () => async (dispatch: any) => {
  await dispatch({
    type: HANDLE_MOVING_STATE,
  });
};

// handle moving state product of dnd
export const handleMovingStateProduct = () => async (dispatch: any) => {
  await dispatch({
    type: HANDLE_MOVING_STATE_PRODUCT,
  });
};

// handle moving state product of dnd
export const desableMovingProductDone = () => async (dispatch: any) => {
  await dispatch({
    type: DESABLE_MOVING_STATE_PRODUCT,
  });
};

// deslable state of product done after delete is Done
export const desableDeleteProductsDone = () => async (dispatch: any) => {
  await dispatch({
    type: DESABLE_DELETE_PRODUCTS_DONE,
  });
};

// handle moving state products multi selection
export const handleMovingStateProducts = () => async (dispatch: any) => {
  await dispatch({
    type: HANDLE_MOVING_STATE_PRODUCTS,
  });
};

// ADD FOLDER TO SELECTED
export const addFolderToSelectedFolders =
  (id: any) => async (dispatch: any) => {
    await dispatch({
      type: ADD_FOLDER_TO_SELECTED,
      payload: id,
    });
  };

// add product to seletced
export const addProductToSelected = (product: any) => async (dispatch: any) => {
  await dispatch({
    type: ADD_PRODUCT_TO_SELECTED,
    payload: product,
  });
};

export const setFolderToMoveTo = (id: any) => async (dispatch: any) => {
  await dispatch({
    type: SET_FOLDER_ID_TO_MOVE_TO,
    payload: id,
  });
};

// create one folder
export const createFolder = (newFolder: any) => async (dispatch: any) => {
  await dispatch({
    type: ADD_ONE_FOLDER_REQUEST,
  });
  try {
    if (newFolder.product_id) {
      const response = await FoldersServices.createFolder(newFolder);
      console.log(response.data);
      await dispatch({
        type: ADD_ONE_FOLDER_SUCCESS,
        payload: response.data,
      });
      MyNotif("Folder create & product added to your folder.", "success");
    } else {
      const response = await FoldersServices.createFolder(newFolder);
      console.log(response.data);
      await dispatch({
        type: ADD_ONE_FOLDER_SUCCESS,
        payload: response.data,
      });
      MyNotif("Folder created successfully.", "success");
    }
  } catch (e: any) {
    dispatch({
      type: ADD_ONE_FOLDER_FAIL,
      payload: e.response,
    });
  }
};

//add folder id to add to folders
export const getProductId = (idProduct: any) => (dispatch?: any) => {
  dispatch({
    type: GET_PRODUCT_ID_TO_ADD,
    payload: idProduct,
  });
};

//add folder id to add to folders
export const clearOneFolder = () => (dispatch?: any) => {
  dispatch({
    type: CLEAR_ONE_FOLDER,
  });
};

//add folder id to add to folders
export const addOneFolderToShow = (id: any) => (dispatch?: any) => {
  dispatch({
    type: ADD_FOLDER_TO_SHOW,
    payload: id,
  });
};

//clear right side folder
export const clearRightSideFolder = () => (dispatch?: any) => {
  dispatch({
    type: CLEAR_RIGHT_SIDE_FOLDER,
  });
};

//clear selected folders
export const clearSelectedFolders = () => (dispatch?: any) => {
  dispatch({
    type: CLEAR_SELECTED_FOLDERS,
  });
};

//clear selected folders
export const clearSelectedProducts = () => (dispatch?: any) => {
  dispatch({
    type: CLEAR_SELECTED_PRODUCTS,
  });
};

//clear folder to move to
export const clearfolderToMoveTo = () => (dispatch?: any) => {
  dispatch({
    type: CLEAR_FOLDER_TO_MOVE_TO,
  });
};

//clear folder to move to
export const clearFolderDetails = () => (dispatch?: any) => {
  dispatch({
    type: CLEAR_FOLDER_DETAILS,
  });
};

//
export const setFolderToMoveToOnDrag = (id: any) => (dispatch?: any) => {
  dispatch({
    type: SET_FOLDER_ID_TO_MOVE_TO_ON_DRAG,
    payload: id,
  });
};
//
export const clearFolderToMoveToOnDrag = () => (dispatch?: any) => {
  dispatch({
    type: CLEAR_FOLDER_TO_MOVE_TO_ON_DRAG,
  });
};

// add product to folder
export const addProducttoFolder =
  (idFolder: any, idProduct: any) => async (dispatch: any) => {
    await dispatch({
      type: ADD_PRODUCT_TO_FOLDER_REQUEST,
    });
    try {
      const response = await FoldersServices.addProducttoFolder(
        idFolder,
        idProduct
      );
      await dispatch({
        type: ADD_PRODUCT_TO_FOLDER_SUCCESS,
        payload: response.data,
      });
      MyNotif("Product added successfully.", "success");
    } catch (e: any) {
      dispatch({
        type: ADD_PRODUCT_TO_FOLDER_FAIL,
        payload: e.response,
      });
    }
  };

// add product to folder
export const moveProductToFolder =
  (idFolderMoveTo: any, idFolder: any, idProduct: any) =>
  async (dispatch: any) => {
    await dispatch({
      type: MOVE_PRODUCT_TO_FOLDER_REQUEST,
    });
    try {
      const response = await FoldersServices.moveProductToFolder(
        idFolderMoveTo,
        idFolder,
        idProduct
      );
      await dispatch({
        type: MOVE_PRODUCT_TO_FOLDER_SUCCESS,
        payload: response.data,
      });
      dispatch(getAllFolders());
      MyNotif("Product moved successfully.", "success");
    } catch (e: any) {
      dispatch({
        type: MOVE_PRODUCT_TO_FOLDER_FAIL,
        payload: e.response,
      });
    }
  };

// delete products (array of products) from folder
export const deleteProductsfromFolder =
  (data: any, idFolder: any) => async (dispatch: any) => {
    await dispatch({
      type: DELETE_PRODUCTS_FROM_FOLDER_REQUEST,
    });
    try {
      const response = await FoldersServices.deleteProductFromFolder(
        data,
        idFolder
      );
      await dispatch({
        type: DELETE_PRODUCTS_FROM_FOLDER_SUCCESS,
        payload: response.data,
      });
      await dispatch(getAllFolders());
      MyNotif("Product deleted successfully.", "success");
    } catch (e: any) {
      dispatch({
        type: DELETE_PRODUCTS_FROM_FOLDER_FAIL,
        payload: e.response,
      });
    }
  };

// edit folder
export const editFolder =
  (data: any, idFolder: any) => async (dispatch: any) => {
    await dispatch({
      type: UPDATE_FOLDER_REQUEST,
    });
    try {
      const response = await FoldersServices.editFolder(data, idFolder);
      await dispatch({
        type: UPDATE_FOLDER_SUCCESS,
        payload: response.data,
      });
      dispatch(getAllFolders());
      MyNotif("Folder edited successfully.", "success");
    } catch (e: any) {
      dispatch({
        type: UPDATE_FOLDER_FAIL,
        payload: e.response,
      });
    }
  };

// change folder's root
export const changeFolderRoot =
  (idFolderParent: any, idFolder: any) => async (dispatch: any) => {
    await dispatch({
      type: CHANGE_FOLDER_ROOT_REQUEST,
    });
    try {
      const response = await FoldersServices.changeFolderRoot(
        idFolderParent,
        idFolder
      );
      await dispatch({
        type: CHANGE_FOLDER_ROOT_SUCCESS,
        payload: response.data,
      });
      dispatch(getAllFolders());
      MyNotif("Folder moved successfully.", "success");
    } catch (e: any) {
      dispatch({
        type: CHANGE_FOLDER_ROOT_FAIL,
        payload: e.response,
      });
    }
  };

// invite client to edit folder
export const inviteToEditFolder =
  (clientId: any, idFolder: any) => async (dispatch: any) => {
    await dispatch({
      type: UPDATE_FOLDER_REQUEST,
    });
    try {
      const response = await FoldersServices.editFolder(clientId, idFolder);
      await dispatch({
        type: UPDATE_FOLDER_SUCCESS,
        payload: response.data,
      });
    } catch (e: any) {
      dispatch({
        type: UPDATE_FOLDER_FAIL,
        payload: e.response,
      });
    }
  };
