import EditKnitProduct from "./EditKnitProduct";
import HorizontalProducts from "./HorizontalProducts";
import ProductHeader from "./ProductHeader";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const KnitProduct = ({
  oneProduct,
  totalLook,
  similarProducts,
  editMode,
  setEditMode,
}: any) => {
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  console.log(user);
  return !editMode ? (
    <>
      <ProductHeader
        oneProduct={oneProduct}
        setEditMode={setEditMode}
        editMode={editMode}
      />
      <div className="details">
        <div className="details_fabric">
          <h3 className="wash_title mb-2">Fabric</h3>

          {oneProduct &&
            oneProduct.fabrics.length > 0 &&
            oneProduct.fabrics.map((el: any, index: any) => {
              return (
                <div className="details_fabric">
                  <div>
                    <h5 className="fabric_title">
                      {el?.fabric_mill?.name} {el?.name} - {el?.color}{" "}
                    </h5>
                    {oneProduct && el.description && (
                      <p className="paragraphe fabric_text ">
                        {el.description}
                      </p>
                    )}
                  </div>
                  <table
                    className="fabric-table-details"
                    style={{ width: "100%" }}
                  >
                    <tbody>
                      {el && (
                        <>
                          <tr>
                            <td style={{ width: "33%" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                Fabric Type :{" "}
                              </span>{" "}
                              {el?.fabric_types && (
                                <span>{el?.fabric_types?.name}</span>
                              )}
                            </td>
                            <td style={{ width: "33%" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                Composition :{" "}
                              </span>
                              {el?.composition_value && (
                                <span>{el?.composition_value}</span>
                              )}
                            </td>
                            <td style={{ width: "33%" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                Weight :{" "}
                              </span>
                              {el?.weight_value && (
                                <span>{el?.weight_value}</span>
                              )}
                            </td>
                          </tr>
                          {user.is_client_clients !== 1 && (
                            <>
                              <tr>
                                <td style={{ width: "33%" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Price/kg:{" "}
                                  </span>
                                  {el?.price_kg ? (
                                    <span>{el?.price_kg} €</span>
                                  ) : (
                                    "-"
                                  )}
                                  {el?.price_metre && (
                                    <>
                                      <br></br>
                                      <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        Price/metre :{" "}
                                      </span>
                                      {el?.price_metre ? (
                                        <span>{el?.price_metre} €</span>
                                      ) : (
                                        "-"
                                      )}
                                    </>
                                  )}
                                </td>
                                <td style={{ width: "33%" }}>
                                  <>
                                    <span style={{ fontWeight: "bold" }}>
                                      {" "}
                                      Price BCI Cotton:{" "}
                                    </span>{" "}
                                    {el?.price_bci ? (
                                      <span>{el?.price_bci} €</span>
                                    ) : (
                                      "-"
                                    )}
                                  </>
                                </td>
                                <td style={{ width: "33%" }}>
                                  <>
                                    <span style={{ fontWeight: "bold" }}>
                                      {" "}
                                      Price Organic cotton :{" "}
                                    </span>

                                    {el?.price_organic ? (
                                      <span>{el?.price_organic} €</span>
                                    ) : (
                                      "-"
                                    )}
                                  </>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              );
            })}
          {/*    <div>
            <h3 className="fabric_title mt-4">Fabric</h3>
            {oneProduct && oneProduct.fabric_details && (
              <p className="paragraphe fabric_text ">
                {oneProduct.fabric_details}
              </p>
            )}
          </div> */}

          {/*    <table style={{ width: "100%" }}>
            <tbody>
              {oneProduct && oneProduct.fabric_types.length > 0 ? (
                <tr>
                  <th>Fabric Mill</th>
                  {oneProduct && (
                    <td>
                   
                      {oneProduct.fabric_types.length > 0 &&
                        oneProduct.fabric_types.map((el: any, key: number) =>
                          key === oneProduct.fabric_types.length - 1
                            ? `${el.supplier_name}`
                            : `${el.supplier_name} • `
                        )}
                    </td>
                  )}
                </tr>
              ) : null}
              {oneProduct && oneProduct.fabric_types.length > 0 ? (
                <tr>
                  <th>Fabric name</th>
                  {oneProduct && (
                    <td>
              
                      {oneProduct.fabric_types.length > 0 &&
                        oneProduct.fabric_types.map((el: any, key: number) =>
                          key === oneProduct.fabric_types.length - 1
                            ? `${el.fabric_name}`
                            : `${el.fabric_name} • `
                        )}
                    </td>
                  )}
                </tr>
              ) : null}

              {oneProduct && oneProduct.fabric_types.length > 0 ? (
                <tr>
                  <th>Fabric Type</th>
                  {oneProduct && (
                    <td>
            
                      {oneProduct.fabric_types.length > 0 &&
                        oneProduct.fabric_types.map((el: any, key: number) =>
                          key === oneProduct.fabric_types.length - 1
                            ? `${el.name}`
                            : `${el.name} • `
                        )}
                    </td>
                  )}
                </tr>
              ) : null}

              {oneProduct && oneProduct?.fabric_composition && (
                <tr>
                  <th>Composition</th>
                  {oneProduct && (
                    <td className="pr-5">{oneProduct.fabric_composition}</td>
                  )}
                </tr>
              )}

              {oneProduct && oneProduct.colors.length > 0 && (
                <tr>
                  <>
                    <th>Fabric Colour</th>
                    <td>
                      {oneProduct?.colors.map((el: any, key: number) =>
                        key === oneProduct.colors.length - 1
                          ? `${el.name}`
                          : `${el.name} • `
                      )}
                    </td>
                  </>
                </tr>
              )}

              {oneProduct &&
                oneProduct?.fabric_types?.filter((el: any) => el.weight)
                  ?.length > 0 && (
                  <tr>
                    <th>Weight</th>
                    <td>
                      {oneProduct.fabric_types.length > 1
                        ? oneProduct.fabric_types
                            .filter((el: any) => el.weight)
                            .map((el: any, key: number) =>
                              key ===
                              oneProduct.fabric_types.filter(
                                (el: any) => el.weight
                              ).length -
                                1
                                ? `${el.weight}gsm`
                                : `${el.weight}gsm • `
                            )
                        : `${oneProduct.fabric_types[0].weight}gsm`}
                    </td>
                  </tr>
                )}

              {oneProduct && oneProduct?.fabric_price && (
                <tr>
                  <th>Fabric PRICE</th>
                  {oneProduct && (
                    <td className="pr-5">{`${Math.fround(
                      oneProduct.fabric_price
                    ).toFixed(2)}€`}</td>
                  )}
                </tr>
              )}

              {oneProduct &&
                oneProduct?.fabric_types?.filter((el: any) => el.price)
                  ?.length > 0 && (
                  <tr>
                    <th>BCI Cotton Price</th>
                    <td>
                      {oneProduct.fabric_types
                        .filter((el: any) => el.price)
                        .map(
                          (el: any, key: number) =>
                            el.price && (
                              <span>
                                {key ===
                                oneProduct.fabric_types.filter(
                                  (el: any) => el.price
                                ).length -
                                  1
                                  ? `${Math.fround(el.price).toFixed(2)}€`
                                  : `${Math.fround(el.price).toFixed(2)}€ • `}
                              </span>
                            )
                        )}
                    </td>
                  </tr>
                )}
              {oneProduct &&
                oneProduct?.fabric_types?.filter((el: any) => el.price_two)
                  ?.length > 0 && (
                  <tr>
                    <th>Price Organic cotton</th>
                    <td>
                      {oneProduct?.fabric_types
                        .filter((el: any) => el.price_two)
                        .map(
                          (el: any, key: number) =>
                            el.price_two && (
                              <span>
                                {key ===
                                oneProduct?.fabric_types?.filter(
                                  (el: any) => el.price_two
                                ).length -
                                  1
                                  ? `${Math.fround(el.price_two).toFixed(2)}€`
                                  : `${Math.fround(el.price_two).toFixed(
                                      2
                                    )}€ • `}
                              </span>
                            )
                        )}
                    </td>
                  </tr>
                )}

              {oneProduct &&
                oneProduct?.fabric_types?.filter((el: any) => el.total_price)
                  ?.length > 0 && (
                  <tr>
                    <th>Price</th>
                    <td>
                      {oneProduct?.fabric_types
                        .filter((el: any) => el.total_price)
                        .map(
                          (el: any, key: number) =>
                            el.total_price && (
                              <span>
                                {key ===
                                oneProduct?.fabric_types?.filter(
                                  (el: any) => el.total_price
                                ).length -
                                  1
                                  ? `${Math.fround(el.total_price).toFixed(2)}€`
                                  : `${Math.fround(el.total_price).toFixed(
                                      2
                                    )}€ • `}
                              </span>
                            )
                        )}
                    </td>
                  </tr>
                )}
            </tbody>
          </table> */}
        </div>
        {/* {tech && oneProduct.technic_id && (
          <div className="details_wash my-4">
            <h3 className="wash_title mb-2">Technique</h3>
            <table style={{ maxWidth: 590 }}>
              <tbody>
                <tr>
                  <th>Technique 1</th>
                  <td>{tech.name}</td>
                  <td>{tech.price}€</td>
                </tr>
              </tbody>
            </table>
          </div>
        )} */}

        {/* DO NOT DELETE !!!! */}

        {oneProduct &&
          oneProduct.techniques &&
          oneProduct.techniques.length !== 0 && (
            <div className="details_wash">
              <h3 className="wash_title mb-2">Technique</h3>
              <table
                style={{ width: "100%" }}
                // style={{ maxWidth: 585,  }}
              >
                <tbody>
                  {oneProduct.techniques.map((el: any, i: number) => (
                    <tr key={uuidv4()}>
                      <td style={{ width: "66%" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Technique type :
                        </span>{" "}
                        {el.name}
                      </td>
                      {user.is_client_clients !== 1 && (
                        <>
                          <td style={{ width: "33%" }}>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>
                              Price :{" "}
                            </span>{" "}
                            {`${Math.fround(el.price).toFixed(2)}€`}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

        {oneProduct &&
          oneProduct.accessories &&
          oneProduct.accessories.length !== 0 && (
            <div className="details_wash">
              <h3 className="wash_title mb-2">Accessories </h3>
              <table style={{ width: "100%" }}>
                <tbody>
                  {oneProduct.accessories.map((el: any, i: number) => (
                    <tr key={uuidv4()}>
                      <td style={{ width: "66%" }}>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          Accessory type{" "}
                        </span>
                        {el.name}
                      </td>
                      {user.is_client_clients !== 1 && (
                        <>
                          <td style={{ width: "33%" }}>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>Price : </span>
                            {`${Math.fround(el.price).toFixed(2)}€`}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        <div
          className="details_wash"
          style={
            oneProduct &&
            (oneProduct.eim_Score ||
              oneProduct.washes.length ||
              oneProduct.colors.length > 0)
              ? {}
              : { display: "none" }
          }
        >
          {oneProduct &&
            oneProduct?.washes &&
            oneProduct?.washes?.length !== 0 && (
              <div className="details_wash">
                <h3 className="wash_title mb-2">Wash </h3>
                <table style={{ width: "100%" }}>
                  <tbody>
                    {oneProduct.washes.map((el: any, i: number) => (
                      <>
                        {" "}
                        <tr key={i}>
                          {/*  <td style={{ width: "33%" }}>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>
                              Wash/dye type :{" "}
                            </span>{" "}
                            {el?.name}
                          </td> */}
                          <td style={{ width: "66%" }}>
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              Eim Score :{" "}
                            </span>
                            <span className="mb-0">{el?.eim_score}</span>
                          </td>
                          {user.is_client_clients !== 1 && (
                            <>
                              <td style={{ width: "33%" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  Price :{" "}
                                </span>
                                <>{Math.fround(el?.wash_price).toFixed(2)}€</>
                              </td>
                            </>
                          )}
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

          {/*   {oneProduct.washes.length !== 0 && (
            <>
              <h3 className="wash_title mb-2">Wash</h3>
              <div className="wash_tables_wrapper d-flex">
                {oneProduct && (
                  <div className="wash-details-part d-flex flex-column align-items-stretch w-100">
                    <div className="d-flex">
                      <div className="wash-cell-title border p-3">
                        <h6 className="mb-0">Wash Type / Dye</h6>
                      </div>
                      <div className="details-part d-flex">
                        {oneProduct.washes.map((wash: any, index: number) => (
                          <div
                            key={index}
                            className="wash-cell-value p-3 pr-0 border-top border-right border-bottom"
                            style={{
                              minWidth: `calc(100% / ${oneProduct.washes.length})`,
                            }}
                          >
                            <p className="mb-0">{wash.name}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="wash-cell-title border-left border-right border-bottom p-3">
                        <h6 className="mb-0">EIM Score</h6>
                      </div>
                      <div className="details-part d-flex">
                        {oneProduct.washes.map((wash: any, index: number) => (
                          <div
                            key={index}
                            className="wash-cell-value p-3 pr-0 border-right border-bottom"
                            style={{
                              minWidth: `calc(100% / ${oneProduct.washes.length})`,
                            }}
                          >
                            <p className="mb-0">{wash.eim_score}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="wash-cell-title border-left border-right border-bottom p-3">
                        <h6 className="mb-0">Price</h6>
                      </div>
                      <div className="details-part d-flex">
                        {oneProduct.washes.map((wash: any, index: number) => (
                          <div
                            key={index}
                            className="wash-cell-value p-3 pr-0 border-right border-bottom"
                            style={{
                              minWidth: `calc(100% / ${oneProduct.washes.length})`,
                            }}
                          >
                            <p className="mb-0">
                              {Math.fround(wash.wash_price).toFixed(2)}€
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )} */}
        </div>
        {/*  {oneProduct && oneProduct.fit && (
          <div className="details_fit">
            <h3 className="fit_title">FIT</h3>
            {oneProduct && <p className="fit_val ml-4">{oneProduct.fit}</p>}
          </div>
        )} */}
        <hr />
        {user.is_client_clients !== 1 && (
          <>
            {oneProduct && oneProduct.total_pricing ? (
              <div className="total_price_container">
                <h4 className="total_price_title">Total Price</h4>
                <p className="total_price">{`${Math.fround(
                  oneProduct.total_pricing
                ).toFixed(2)}€`}</p>
              </div>
            ) : null}
            <div className="total_price_container">
              {oneProduct.total_price && (
                <>
                  <h4
                    className="total_price_title"
                    style={{
                      marginBottom: !oneProduct.total_price_two
                        ? "95px!important"
                        : "0",
                    }}
                  >
                    Total Price BCI Cotton
                  </h4>
                  {oneProduct && (
                    <p className="total_price">{`${Math.fround(
                      oneProduct.total_price
                    ).toFixed(2)}€`}</p>
                  )}
                </>
              )}
            </div>
            {oneProduct && oneProduct.total_price_two ? (
              <div className="total_price_container">
                <h4 className="total_price_title">
                  Total Price Organic Cotton{" "}
                </h4>
                <p className="total_price">{`${Math.fround(
                  oneProduct.total_price_two
                ).toFixed(2)}€`}</p>
              </div>
            ) : null}
          </>
        )}
        {/* {oneProduct && oneProduct.fabric_price ? (
          <div className="total_price_container">
            <h4 className="total_price_title">Fabric PRICE</h4>
            <p className="total_price">{`${Math.fround(
              oneProduct.fabric_price
            ).toFixed(2)}€`}</p>
          </div>
        ) : null} */}
        {oneProduct && oneProduct.tags && oneProduct.tags.length > 0 ? (
          <div className="tags_container mt-5">
            <h3 className="tags_title">Tags</h3>
            <div className="tag_list">
              {oneProduct &&
                oneProduct.tags.map((el: any) => (
                  <Link to={`/products?q=${el.id}&tag=${el.name}`} key={el.id}>
                    <p className="tag">{el.name}</p>
                  </Link>
                ))}
            </div>
          </div>
        ) : null}

        {/* {oneProduct && oneProduct.order_min_qty && (
          <div className="min_qt_container">
            <h3 className="min_qt_title">MOQ</h3>
            <p className="min_qt_val">
              {oneProduct && oneProduct.order_min_qty}
            </p>
          </div>
        )} */}
      </div>
      <>
        <HorizontalProducts
          data={totalLook}
          type={"total-look"}
          key={uuidv4()}
          fabric={"knit"}
        />
        <HorizontalProducts
          data={similarProducts}
          type={"similar"}
          key={uuidv4()}
          fabric={"knit"}
        />
      </>
    </>
  ) : (
    <EditKnitProduct
      oneProduct={oneProduct}
      setEditMode={setEditMode}
      editMode={editMode}
    />
  );
};

export default KnitProduct;
