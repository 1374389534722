import React from "react";
import {
  faArrowRight,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

interface Props {
  data: any;
  garment?: boolean;
  name: string;
}

export const DiscoverItem = ({ data, garment, name }: Props) => {
  const [scrolled, setScrolled] = React.useState("top");
  const [specGender, setSpecGender] = React.useState<any>({});
  const innerListRef = React.useRef<HTMLHeadingElement>(null);
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const history = useHistory();

  React.useEffect(() => {
    innerListRef.current?.addEventListener("scroll", () => {
      let scrollPx = innerListRef.current?.scrollTop;
      if (scrollPx === 0) {
        setScrolled("top");
      } else if (scrollPx && scrollPx > 0 && scrollPx < 262) {
        setScrolled("mid");
      } else {
        setScrolled("bottom");
      }
    });
    setSpecGender(
      user && !isAdmin
        ? user.genders &&
          user.genders.length !== 0 &&
          user.genders.find((el: any) => el.id === 1)
          ? user.genders.find((el: any) => el.id === 1)
          : user.genders[0]
        : 1
    );

    return () => {
      innerListRef.current?.removeEventListener("scroll", () => {});
    };
  }, [isAdmin]);
  return (
    <>
      <div className="discover_card_submenu">
        <div className="discover_card_list">
          <h2 className="h2_subtitle discover_item_name">
            {name}
            {/*             {data.name || "Garments"}
             */}{" "}
          </h2>
          {innerListRef.current &&
            innerListRef.current.scrollHeight > 414 &&
            (scrolled === "mid" ? (
              <div className="arrows"></div>
            ) : scrolled === "top" ? (
              <div className="arrows">
                <FontAwesomeIcon icon={faCaretDown} size={"2x"} />
              </div>
            ) : (
              <div className="arrows">
                <FontAwesomeIcon icon={faCaretUp} size={"2x"} />
              </div>
            ))}
          <div ref={innerListRef}>
            {data.children
              ? data.children.length
                ? data.children
                        .filter((item: any) => item.published_at_home === 1)
                        .sort((a: any, b: any) =>
                      a.sort_order > b.sort_order ? 1 : -1
                    )
                    .map((item: any, i: number) => (
                      <p
                        key={i}
                        className="paragraphe discover_item_list"
                        onClick={() =>
                          history.push(
                            `/collection/${item.id}/${
                              user && user.fabric_type === "knit"
                                ? "knit"
                                : "denim"
                            }`
                          )
                        }
                      >
                        {item.name}
                      </p>
                    ))
                : null
              : data.length && name != "Collection"
              ? data.map((item: any, i: number) => (
                  <p
                    key={i}
                    className="paragraphe discover_item_list"
                    onClick={() =>
                      history.push(
                        `/product/${
                          user && user.fabric_type === "knit" ? "knit" : "denim"
                        }/${specGender.name}/${specGender.id}/${item.name}/${
                          item.id
                        }`
                      )
                    }
                  >
                    {item.name}
                  </p>
                ))
              : data
                  .sort((a: any, b: any) =>
                    a.sort_order > b.sort_order ? 1 : -1
                  )
                  .map((item: any, i: number) => (
                    <p
                      key={i}
                      className="paragraphe discover_item_list"
                      onClick={() =>
                        history.push(
                          `/collection/${item.id}/${
                            user && user.fabric_type === "knit"
                              ? "knit"
                              : "denim"
                          }`
                        )
                      }
                    >
                      {item.name}
                    </p>
                  ))}
          </div>
        </div>
        <div className="discover_card_footer">
          <p className="paragraphe ">Discover All</p>
          <FontAwesomeIcon icon={faArrowRight} color="white" />
        </div>
      </div>
      <h1
        className="h2_huge discover_card_title"
        onClick={() =>
          garment
            ? history.push(
                `/product/${
                  user && user.fabric_type === "knit" ? "knit" : "denim"
                }/${
                  user && user.genders.find((el: any) => el.name === "Men")
                    ? "Men"
                    : "Women"
                }/${
                  user && user.genders.find((el: any) => el.name === "Men")
                    ? 1
                    : 2
                }/${data[0].name}/${data[0].id}`
              )
            : history.push(
                `/collection/${data.id}/${
                  user && user.fabric_type === "knit" ? "knit" : "denim"
                }`
              )
        }
      >
        {name}
        {/*         {data.name || "Garments"}
         */}{" "}
      </h1>
    </>
  );
};
