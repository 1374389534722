import {
  Drawer,
  DatePicker,
  TimePicker,
  PageHeader,
  Tag,
  Button,
  Comment,
  Avatar,
  Select,
  Input,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useMutation } from "react-query";
import axiosInstance from "../../config/axios-instance";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import company_placeholder from "../../img/company_placeholder.png";
import { MyNotif } from "../NotifIcons/NotifIcons";

moment.defineLocale("en", {
  week: {
    dow: 1,
  },
});

export default function ConfirmDrawer({
  visible,
  onClose,
  meet,
  setVisible,
  refetch,
}: any) {
  const [data, setData] = useState(meet.start);
  const [endTime, setEndTime] = useState<any>(null);

  const genStatus = (status: any) => {
    switch (status) {
      case "new":
        return {
          text: "New",
          color: "blue",
        };
      case "waiting":
        return {
          text: "Pending",
          color: "orange",
        };
      case "accepted":
        return {
          text: "Confirmed",
          color: "green",
        };
      default:
        return {
          text: "Cancelled",
          color: "red",
        };
    }
  };
  const format = "HH:mm";

  const rescheduleMeeting = async (type: string) => {
    let token = localStorage.getItem("token");
    if (type === "cancel") {
      await axiosInstance({
        method: "delete",
        url: `/admin/bookings/${meet.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      await axiosInstance({
        method: "post",
        url: `/admin/bookings/${meet.id}`,
        data: {
          status: "waiting",
          date: data,
          date2: endTime,
          client_id: meet.details.client_id,
          _method: "patch",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    await MyNotif(
      type === "cancel"
        ? "Meeting cancelled successfully."
        : "Meeting rescheduled!",
      "success"
    );
    setVisible(false);
    refetch();
    // return response.data;
  };

  const [location, setLocation] = useState<any>(null);
  const onChangeLocation = (value: any) => {
    console.log(value);
    setLocation(value);
  };
  const {
    mutate: mutateUpdateBooking,
    isLoading: updateLoading,
    variables,
  } = useMutation((type: string) => rescheduleMeeting(type));
  return (
    <Drawer
      placement={"right"}
      closable={false}
      onClose={onClose}
      visible={visible}
      key={"right"}
      width="400"
      className="booking-drawer"
    >
      <div className="left-calendar-container">
        <h1 className="h1-blue-hj" style={{ marginTop: "20px" }}>
          Meeting details
        </h1>
        <PageHeader
          title={meet.subject}
          className="site-page-header p-0 my-5"
          tags={
            <Tag color={genStatus(meet.status).color}>
              {genStatus(meet.status).text}
            </Tag>
          }
          avatar={{
            src: meet.company
              ? process.env.REACT_APP_STORAGE_URL + meet.company.logo
              : company_placeholder,
          }}
        >
          <Comment
            className="meeting_notes border rounded pl-2 bg-white"
            author={<a>{meet.client.name}</a>}
            avatar={
              <Avatar
                shape="square"
                src={
                  meet.client.logo
                    ? process.env.REACT_APP_STORAGE_URL + meet.client.logo
                    : avatar_placeholder
                }
                alt={meet.client.name}
              />
            }
            content={<p>{meet.notes}</p>}
          />
        </PageHeader>
        <h3 className="h2-blue-hj">Select Date</h3>
        <DatePicker
          defaultValue={moment(meet.start)}
          showToday={false}
          disabledDate={function disabledDate(current: any) {
            // Can not select days before today and today
            return (
              (current && current <= moment().subtract(1, "d").endOf("day")) ||
              ["Saturday", "Sunday"].find(
                (date) => date === moment(current).format("dddd")
              )
            );
          }}
          onChange={(x: any) =>
            setData(
              moment(x)
                .set({
                  hour: moment(data).get("hour"),
                  minute: moment(data).get("minute"),
                  second: moment(data).get("second"),
                })
                .format()
            )
          }
          style={{ width: "100%" }}
        />
        <h3 className="h2-blue-hj">Start meeting time</h3>
        <TimePicker
          onChange={(x: any) =>
            setData(
              moment(data)
                .set({
                  hour: x.get("hour"),
                  minute: x.get("minute"),
                  second: x.get("second"),
                })
                .format()
            )
          }
          style={{ width: "100%" }}
          defaultValue={moment(meet.start).utc()}
          showSecond={false}
          disabledHours={() => [
            0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23,
          ]}
          hideDisabledOptions
          minuteStep={15}
          format={format}
        />

        <h3 className="h2-blue-hj">End meeting time</h3>
        <TimePicker
          onChange={(x: any) =>
            setEndTime(
              moment(data) // Utilisez la même date que la date de début
                .set({
                  hour: x.get("hour"),
                  minute: x.get("minute"),
                  second: x.get("second"),
                })
                .format()
            )
          }
          style={{ width: "100%" }}
          defaultValue={moment(meet.details.date2).utc()}
          showSecond={false}
          disabledHours={() => [
            0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23,
          ]}
          hideDisabledOptions
          minuteStep={15}
          format={format}
        />

        <h3 className="h2-blue-hj">Time Zone</h3>
        <Select
          disabled
          style={{ width: "100%" }}
          onChange={(value: any) => setData(value)}
          value={meet.details.timezone}
          placeholder="Select TimeZone"
        >
          <option value="-12">(GMT -12:00) Eniwetok, Kwajalein</option>
          <option value="-11">(GMT -11:00) Midway Island, Samoa</option>
          <option value="-10">(GMT -10:00) Hawaii</option>
          <option value="-09">(GMT -9:00) Alaska</option>
          <option value="-08">
            (GMT -8:00) Pacific Time (US &amp; Canada)
          </option>
          <option value="-07">
            (GMT -7:00) Mountain Time (US &amp; Canada)
          </option>
          <option value="-06">
            (GMT -6:00) Central Time (US &amp; Canada), Mexico City
          </option>
          <option value="-05">
            (GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima
          </option>
          <option value="-04">
            (GMT -4:00) Atlantic Time (Canada), Caracas, La Paz
          </option>
          <option value="-03">
            (GMT -3:00) Brazil, Buenos Aires, Georgetown
          </option>
          <option value="-02">(GMT -2:00) Mid-Atlantic</option>
          <option value="-01">(GMT -1:00) Azores, Cape Verde Islands</option>
          <option value="+00" selected>
            (GMT) Western Europe Time, London, Lisbon, Casablanca
          </option>
          <option value="+01">
            (GMT +1:00) Brussels, Copenhagen, Madrid, Paris
          </option>
          <option value="+02">(GMT +2:00) Kaliningrad, South Africa</option>
          <option value="+03">
            (GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg
          </option>
          <option value="+04">
            (GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi
          </option>
          <option value="+05">
            (GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent
          </option>
          <option value="+06">(GMT +6:00) Almaty, Dhaka, Colombo</option>
          <option value="+07">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
          <option value="+08">
            (GMT +8:00) Beijing, Perth, Singapore, Hong Kong
          </option>
          <option value="+09">
            (GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk
          </option>
          <option value="+10">
            (GMT +10:00) Eastern Australia, Guam, Vladivostok
          </option>
          <option value="+11">
            (GMT +11:00) Magadan, Solomon Islands, New Caledonia
          </option>
          <option value="+12">
            (GMT +12:00) Auckland, Wellington, Fiji, Kamchatka
          </option>
          <option value="+13">(GMT +13:00) Apia, Nukualofa</option>
          <option value="+14">(GMT +14:00) Line Islands, Tokelau</option>
        </Select>

        <h3 className="h2-blue-hj">Your meeting Subject</h3>
        <Input
          placeholder="Subject Meeting"
          disabled
          onChange={(e: any) => setData(e.target.value)}
          value={meet.subject}
        />

        <h3 className="h2-blue-hj">Location</h3>
        <Select
          style={{ width: "100%" }}
          onChange={(value: any) => onChangeLocation(value)}
          placeholder="Select Location"
          disabled
          // defaultValue={"Meeting Room"}
        >
          <option value="1" key={1}>
            Meerting Room
          </option>
          <option value="2" key={2} selected={true}>
            VC Link
          </option>
        </Select>
        <div className="mb-3">
          {location == 2 ? (
            <>
              <h3 className="h2-blue-hj">VC Link</h3>
              <Input
                disabled
                placeholder="Google Meet , Zoom"
                onChange={(e: any) => setData(e.target.value)}
                value={meet.details.link}
              />
            </>
          ) : (
            <>
              <h3 className="h2-blue-hj">Meeting Room</h3>
              <Input
                placeholder="Room ..."
                disabled
                onChange={(e: any) => setData(e.target.value)}
                value={meet.details.link}
              />
            </>
          )}
        </div>
        <Button
          // type="button"
          disabled={variables === "cancel"}
          className="reschedule-btn-drawer discard w-100 mt-4 my-3"
          onClick={() => mutateUpdateBooking("reschedule")}
          loading={variables === "reschedule" && updateLoading}
        >
          {variables === "reschedule" && updateLoading
            ? "Rescheduling..."
            : "Reschedule"}
        </Button>
        <Button
          type="link"
          className="d-flex justify-content-center mx-auto"
          onClick={() => mutateUpdateBooking("cancel")}
        >
          {variables === "cancel" && updateLoading
            ? "Cancelling..."
            : "Cancel Meeting"}
        </Button>
      </div>
    </Drawer>
  );
}
