import { Pagination, Spin, Avatar, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../config/axios-instance";
import {
  LoadingOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "../components/AllClients/AllClient.scss";
import { Link, useLocation, useHistory } from "react-router-dom";
import company_placeholder from "../img/company_placeholder.png";
import { useSelector } from "react-redux";
import Head from "../components/Head";
import { HrIcon } from "../components/product-3d-config-tabs/forms/canvas/icon/HrIcon";

const AllClients = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();
  const { user } = useSelector((state: any) => state.auth);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQueryy();

  const fetchClients = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/companies?page=${query.get("page") || 1}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { isLoading, isError, data, error } = useQuery(
    ["companies", query.get("page")],
    () => fetchClients(),
    { keepPreviousData: true }
  );

  const filteredData = data?.data.data.filter((el: any) =>
    el.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  function goto(el: any) {
    history.push(`/all-clients/${el.id}/1`);
  }

  return isLoading ? (
    <div className="loading_orders">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <div className="my_orders">
      <Head title="Orders" />
      <div className="container">
        <div className="d-flex align-items-center justify-content-between pr-1 mb-1">
          <div>
            <h1 className="mb-0 mt-3 list_of_companies_title">
              List of Companies
            </h1>
          </div>
          <div></div>
        </div>
        <div className="row mt-4">
          <div className="search-bar searchfaq" style={{ width: "100%" }}>
            <div className="input-container">
              <input
                type="text"
                placeholder="Search your company..."
                onChange={handleSearchChange}
                className={"search_input"}
              />
              <HrIcon></HrIcon>
              <button type="submit" className={"submit_input"}>
                <SearchOutlined />
              </button>
            </div>
          </div>
        </div>
        <table className="table mt-2">
          <tbody>
            {filteredData?.map((el: any, i: number) => (
              <>
                <tr key={i} style={{ height: "66px" }} onClick={() => goto(el)}>
                  <td>
                    <div className="d-flex align-items-center">
                      <img
                        className="mr-3"
                        src={
                          el.logo
                            ? process.env.REACT_APP_STORAGE_URL + el.logo
                            : company_placeholder
                        }
                        width="30"
                      />
                      <p className="mb-0 company_name">{el.name}</p>
                    </div>
                  </td>
                  {user && user.role === "admin" && (
                    <td>
                      <Avatar.Group
                        maxCount={2}
                        size="large"
                        maxStyle={{
                          color: "#f56a00",
                          backgroundColor: "#fde3cf",
                        }}
                      >
                        {el.commercials.map((commercial: any) => (
                          <Tooltip
                            title={commercial.name}
                            key={commercial.id}
                            placement="bottom"
                          >
                            <Avatar
                              icon={
                                !commercial.avatar && (
                                  <UserOutlined style={{ verticalAlign: 0 }} />
                                )
                              }
                              style={{
                                backgroundColor: !commercial.avatar
                                  ? "#00468c"
                                  : "inherit",
                              }}
                              src={
                                commercial.avatar
                                  ? process.env.REACT_APP_STORAGE_URL +
                                    commercial.avatar
                                  : ""
                              }
                            />
                          </Tooltip>
                        ))}
                      </Avatar.Group>
                    </td>
                  )}
                  {/*   <td align="center">
                  <Link to={`/all-clients/${el.id}/1`}>See details</Link>
                </td>*/}
                </tr>
              </>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5}>
                {/*   <Pagination
                  defaultCurrent={1}
                  defaultPageSize={10}
                  total={data && data.data.total}
                  hideOnSinglePage
                  responsive
                  onChange={(page: any) =>
                    history.push(`/all-clients?page=${page}`)
                  }
                /> */}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default AllClients;
