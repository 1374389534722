import "./RightDrawer.scss";
import { AnimatePresence, motion } from "framer-motion";
import OutSideAlerter from "../SideMenu/OutSideAlerter";
import { MySpace } from "../MySpace/MySpace";
import { MySelection } from "../MySelection/MySelection";
import { Notifications } from "../Notifications/Notifications";

interface Props {
  mode: string;
  closeDrawer: () => void;
  open: boolean;
}

const variants = {
  enter: (mode: string): object => {
    return {
      x: 1000,
      zIndex: -1,
      position: "fixed",
      right: 0,
      top: 0,
      height: "100vh",
      minWidth: mode === "my_space" ? "50%" : "33.33%",
      background: "white",
      boxShadow: "-12px 0px 16px #0000002b",
    };
  },
  center: (mode: string): object => {
    return {
      x: 0,
      zIndex: 1,
      minWidth: mode === "my_space" ? "50%" : "33.33%",
      boxShadow: "-12px 0px 16px #0000002b",
    };
  },
  exit: () => {
    return {
      x: 1000,
    };
  },
};

export const RightDrawer = ({ mode, closeDrawer, open }: Props) => {
  const drawerContent = () => {
    if (mode === "my_space") {
      return <MySpace closeDrawer={closeDrawer} />;
    } else if (mode === "my_selection") {
      return <MySelection closeDrawer={closeDrawer} />;
    } else {
      return <Notifications closeDrawer={closeDrawer} />;
    }
  };
  return (
    <AnimatePresence exitBeforeEnter={false} custom={mode}>
      {open && (
        <OutSideAlerter>
          <motion.div
            className={`drawer_wrapper pb-0`}
            variants={variants}
            custom={mode}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ type: "tween", duration: 0.5 }}
          >
            {drawerContent()}
          </motion.div>
        </OutSideAlerter>
      )}
    </AnimatePresence>
  );
};
