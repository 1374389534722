import { useState } from "react";
import shoppingBag from "../../img/shoppingBag.svg";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import { useSelector, useDispatch } from "react-redux";
import { sendAccessRequest } from "../../actions/order-actions/actions";
import { Tooltip } from "antd";
import Bag from "../../icons/Bag";

interface Props {
  data: any;
  setSelectOrder: (x: any) => void;
  selectOrder: any;
}

export const DirectOrderItem = ({
  data: {
    name,
    details,
    articles_count,
    id,
    articles,
    can_see,
    collaborators,
    request_waiting,
  },
  setSelectOrder,
  selectOrder,
}: Props) => {
  const [send, setSend] = useState(false);

  const { user } = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  // send request access
  const sendReqOrder = () => {
   // if (can_see === undefined || can_see === true) {
      setSelectOrder({
        name,
        details,
        articles_count,
        articles,
        id,
        can_see,
        request_waiting
      });
  // }
  };

  return (
    <div
      onClick={sendReqOrder}
     /* className={`${
        can_see === undefined || can_see === true
          ? "order_miniature"
          : "order_miniature_not_collab order_miniature"
      } mt-4 ${
        selectOrder && selectOrder.id === id ? "order_selected" : undefined
      }`}*/
      className={`${
          can_see === undefined || can_see === true
              ? "order_miniature"
              : " order_miniature"
      } mt-4 ${
          selectOrder && selectOrder.id === id ? "order_selected" : undefined
      }`}
      //  className={'order_miniature'}
    >
      <div className="order_miniature_info">
        <div className="order_miniature_name">
          {/* <img src={shoppingBag} alt="order icon" /> */}
          <Bag />
          <h2>{name}</h2>
        </div>
{/*
        <p className="paragraphe">Contains {articles_count} articles</p>
*/}
      </div>
      {
        <div className="collab_list">
          {collaborators && can_see ? (
            <div className="d-flex">
              {collaborators
                .filter((el: any) => el.id !== user.id)
                .slice(0, 3)
                .map((el: any) => (
                  <a
                    key={el.id}
                    className="collab_profile"
                    style={{
                      backgroundImage: `url(${
                        el.logo
                          ? process.env.REACT_APP_STORAGE_URL + el.logo
                          : avatar_placeholder
                      })`,
                    }}
                    //   replace id with the collaborator name
                    id={el.name}
                  ></a>
                ))}
              {collaborators.filter((el: any) => el.id !== user.id).length >
                3 && (
                <Tooltip
                  placement="right"
                  title={collaborators
                    .filter((el: any) => el.id !== user.id)
                    .slice(
                      3,
                      collaborators.filter((el: any) => el.id !== user.id)
                        .length + 1
                    )
                    .map((x: any) => x.name + `, `)}
                >
                  <p className="and_others_collabs mb-0 collab_profile">
                    +
                    {collaborators.filter((el: any) => el.id !== user.id)
                      .length - 3}
                  </p>
                </Tooltip>
              )}
            </div>
          ) : (
            // can_see === false &&
            !send &&
            request_waiting !== undefined &&
            (!request_waiting ? (
            /*  <h2
                className="request_access_order"
                onClick={() => {
                  dispatch(sendAccessRequest(id, user.id));
                  setSend(true);
                }}
              >
                Request access
              </h2>*/
            <></>
            ) : (
             /* <h2 className="request_access_order request_access_order_sent">
                Request Sent
              </h2>*/
                <></>
            ))
          )}

          {send && (
           /* <h2 className="request_access_order request_access_order_sent">
              Request Sent
            </h2>*/
              <></>
          )}
        </div>
      }
    </div>
  );
};
