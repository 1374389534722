import { Col, Row } from "antd";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { DropZoneFile } from "../../OptionDetails/DropZoneFile";
import { OrderButton } from "./buttons/OrderButton";
import CustomizationDropZone from "./CustomizationDropZone";
import DropdownStyles from "./dropdowns/DropdownStyles";
export type IStylingFormProps = {
  setActiveTabKey: (s: string) => void;
  handleChange: any;
  attachFile: any;
  deleteFile: any;
  globalData: any;
  selectedProduct: any;
};

const StylingForm: React.FC<IStylingFormProps> = ({
  setActiveTabKey,
  handleChange,
  attachFile,
  deleteFile,
  globalData,
  selectedProduct,
}) => {
  const params = useParams<{ status: string }>();
  // const [style_files, setStyleFiles] = useState<any>([]);
  // const { register, control, handleSubmit, watch, errors } = useForm({
  //   defaultValues: {
  //     style: params.status === "edit" ? selectedProduct.style : "",
  //     style_details:
  //       params.status === "edit" ? selectedProduct.style_details : "",
  //   },
  // });
  // const onSubmit = (data: any) => {
  //   //traja3 el data mte3na bch npushiw'ha fi redux
  //   //PS : el select traja3 object fih label w el value
  //   console.log(data);

  //   // we go to the next tab
  //   setActiveTabKey("2");
  // };

  // const attachFile = (files: any) => {
  //   setStyleFiles(files);
  // };

  // // delete attached file
  // const deleteFile = (file: any) => {
  //   setStyleFiles(style_files.filter((el: any) => el.name !== file.name));
  // };

  return (
    <div className="col-6">
      <div>
        <label style={{fontSize:"16px",marginBottom:"10px"}}>Style</label>
      </div>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="login__text-field">
          <input
            className="style_input"
            type="text"
            placeholder="Style"
            name="style"
            value={globalData.style}
            onChange={(e: any) => handleChange(e)}
            style={{ height: "30px" }}
            // ref={register({ required: true })}

          />
        </div>
        {/* <DropdownStyles
          classNameWrapper={"custom-select-tabs"}
          classNameInputWrapper="custom-select"
          options={[
            { label: "mzayen", value: "idmte3mzayen" },
            { label: "yfata9", value: "idmte3yfata9" },
          ]}
          name="style"
          placeholder="Style"
          control={control}
        /> */}
        <textarea
          placeholder="More Details"
          className="textarea-custom"
          rows={3}
          name="style_details"
          value={globalData.style_details}
          onChange={(e: any) => handleChange(e)}
          // ref={register({ required: true })}
        />
        <CustomizationDropZone
          attachFile={attachFile}
          detachFile={deleteFile}
          files={globalData.style_files}
          objectKey={"style_files"}
        />

        {/* <div className="btn-configurator-holder">
          <OrderButton title="Order Now" outlined={true} />
        </div> */}
      </div>
    </div>
  );
};

export { StylingForm };
