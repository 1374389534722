import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../actions/auth-actions/actions";
import { useEffect, useState } from "react";
import { Spinner } from "../Spinner";
import AuthServices from "../../actions/auth-actions/services";
import { useMutation } from "react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { Spin } from "antd";

//@ts-ignore
export interface INewPasswordProps {
  setForgot: (val: string) => void;
  setPassword: (val: string) => void;
  setConfirmPwd: (val: string) => void;
  code: any;
  email: string;
  password: string;
  confirmPwd: string;
}

export const NewPassword = ({
  setForgot,
  setPassword,
  setConfirmPwd,
  code,
  email,
  password,
  confirmPwd,
}: INewPasswordProps) => {
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const { msg, isLoading } = useSelector((state: any) => state.auth);

  const data = {
    email,
    // reset_code: code.otp.join(""),
    password: password,
    password_confirmation: confirmPwd,
  };

  const { mutateAsync: sendNewPassword, isLoading: sendNewPasswordLoading } =
    useMutation(AuthServices.newPassword, {
      onError: (err: any) => {
        setError(true);
        MyNotif(err.response.data.error, "danger");
      },
      onSuccess: () => {
        MyNotif(
          `Password for this email ${email} has been changed!`,
          "success"
        );
        setError(false);
        setForgot("center");
      },
    });

  useEffect(() => {
    if (msg && password && confirmPwd) {
      setForgot("center");
    }
  }, [msg]);

  const newPwd = () => {
    if (!password || !confirmPwd || password !== confirmPwd) {
      setError(true);
    } else {
      // dispatch(resetPassword(data));
      sendNewPassword(data);
      setError(false);
    }
  };

  return (
    <AnimatePresence initial={true} exitBeforeEnter={true}>
      <div style={{ width: "100%" }} className="d-flex flex-column">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <h2 className="h2_subtitle_blue h2_subtitle_blue_reset">
            Create New Password
          </h2>
          <p className="paragraphe parag_rest_password">
            Please enter your new password
          </p>
          <form className="login_form_forgot_password">
            <div className="field">
              <input
                type="password"
                name="new_password"
                id="new_password"
                placeholder="Your new password"
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  borderBottom: error ? "1px solid red" : "1px solid #ccc",
                }}
              />
              <label htmlFor="new_password" className="login_password_label">
                New password
              </label>
              {error && (
                <motion.i
                  initial={{ x: 20 }}
                  animate={{ x: 0 }}
                  transition={{ type: "spring", stiffness: 500 }}
                  className="fas fa-exclamation-triangle"
                ></motion.i>
              )}
            </div>
            <div className="field">
              <input
                type="password"
                name="new_re_password"
                id="new_re_password"
                placeholder="Confirm your password"
                onChange={(e) => setConfirmPwd(e.target.value)}
                style={{
                  borderBottom: error ? "1px solid red" : "1px solid #ccc",
                }}
              />
              <label htmlFor="new_re_password" className="login_password_label">
                Confirm your password
              </label>
              {error && (
                <motion.i
                  initial={{ x: 20 }}
                  animate={{ x: 0 }}
                  transition={{ type: "spring", stiffness: 500 }}
                  className="fas fa-exclamation-triangle"
                ></motion.i>
              )}
            </div>
          </form>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="text-center"
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <Link to={{ hash: "#" }}>
              <Button
                buttonClass="submit_button_blue submit_button_white_full_width confirm_button_white_full_width"
                innerButton={
                  sendNewPasswordLoading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  ) : (
                    "Confirm"
                  )
                }
                onClick={newPwd}
              />
            </Link>
          )}
        </motion.div>
      </div>
    </AnimatePresence>
  );
};
