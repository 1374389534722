// Products
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

// Products
export const GET_PRODUCTS_NEXT_REQUEST = "GET_PRODUCTS_NEXT_REQUEST";
export const GET_PRODUCTS_NEXT_SUCCESS = "GET_PRODUCTS_NEXT_SUCCESS";
export const GET_PRODUCTS_NEXT_FAIL = "GET_PRODUCTS_NEXT_FAIL";

export const GET_ONE_PRODUCT_REQUEST = "GET_ONE_PRODUCT_REQUEST";
export const GET_ONE_PRODUCT_SUCCESS = "GET_ONE_PRODUCT_SUCCESS";
export const GET_ONE_PRODUCT_FAIL = "GET_ONE_PRODUCT_FAIL";

// Products filtered
export const GET_PRODUCTS_FILTERED_REQUEST = "GET_PRODUCTS_FILTERED_REQUEST";
export const GET_PRODUCTS_FILTERED_SUCCESS = "GET_PRODUCTS_FILTERED_SUCCESS";
export const GET_PRODUCTS_FILTERED_FAIL = "GET_PRODUCTS_FILTERED_FAIL";

// LABELS
export const GET_ONE_LABEL_SUCCESS = "GET_ONE_LABEL_SUCCESS";
export const GET_ONE_LABEL_FAIL = "GET_ONE_LABEL_FAIL";
export const GET_ALL_LABELS_REQUEST = "GET_ALL_LABELS_REQUEST";
export const GET_ALL_LABELS_SUCCESS = "GET_ALL_LABELS_SUCCESS";
export const GET_ALL_LABELS_FAIL = "GET_ALL_LABELS_FAIL";

// TYPES (Categories, Colors, Elasticities, Fibers, Labels, Washes and Weights )
export const GET_ALL_TYPES_REQUEST = "GET_ALL_TYPES_REQUEST";
export const GET_ALL_TYPES_SUCCESS = "GET_ALL_TYPES_SUCCESS";
export const GET_ALL_TYPES_FAIL = "GET_ALL_TYPES_FAIL";

// FILTER LIST PRODUCTS
export const FILTER_PRODUCTS_REQUEST = "FILTER_PRODUCTS_REQUEST";
export const FILTER_PRODUCTS_SUCCESS = "FILTER_PRODUCTS_SUCCESS";
export const FILTER_PRODUCTS_FAIL = "FILTER_PRODUCTS_FAIL";

export const FILTER_PRODUCTS_KEEP_ALL_REQUEST =
  "FILTER_PRODUCTS_KEEP_ALL_REQUEST";
export const FILTER_PRODUCTS_KEEP_ALL_SUCCESS =
  "FILTER_PRODUCTS_KEEP_ALL_SUCCESS";
export const FILTER_PRODUCTS_KEEP_ALL_FAIL = "FILTER_PRODUCTS_KEEP_ALL_FAIL";

export const ONTYPING_PRODUCTS_FILTER_REQUEST =
  "ONTYPING_PRODUCTS_FILTER_REQUEST";
export const ONTYPING_PRODUCTS_FILTER_SUCCESS =
  "ONTYPING_PRODUCTS_FILTER_SUCCESS";
export const ONTYPING_PRODUCTS_FILTER_FAIL = "ONTYPING_PRODUCTS_FILTER_FAIL";

export const ADD_FILTER = "ADD_FILTER";
export const DELETE_FILTER = "DELETE_FILTER";

export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";

export const LOAD_PRODUCTS_FILTER_REQUEST = "LOAD_PRODUCTS_FILTER_REQUEST";
export const LOAD_PRODUCTS_FILTER_SUCCESS = "LOAD_PRODUCTS_FILTER_SUCCESS";
export const LOAD_PRODUCTS_FILTER_FAIL = "LOAD_PRODUCTS_FILTER_FAIL";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const GET_FILTER_REQUEST = "GET_FILTER_REQUEST";
export const GET_FILTER_SUCCESS = "GET_FILTER_SUCCESS";
export const GET_FILTER_FAIL = "GET_FILTER_FAIL";
