import moment from "moment";
import { Link, useHistory } from "react-router-dom";

const DirectOrderTableBody = ({ data }: any) => {
  const history = useHistory();
  return (
    data &&
    data.data.articles.map((article: any, i: number) => (
      <tr key={i}>
        <td>
          <p className="mb-0">{article.id}</p>
        </td>
        <td>
          <p className="mb-0">{article.name}</p>
        </td>
        <td>
          <p className="mb-0">{article.options.length}</p>
        </td>
        <td>
          <p className="mb-0">
            {moment(article.updated_at).format("MMMM DD YYYY")}
          </p>
        </td>
        <td align="center">
          <Link to={`${history.location.pathname}/${article.id}`}>
            See details
          </Link>
        </td>
      </tr>
    ))
  );
};

export default DirectOrderTableBody;
