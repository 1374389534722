// Action Types
export const ADD = "ADD";
export const HANDLE_RIGHT = "HANDLE_RIGHT";
export const HANDLE_LEFT = "HANDLE_LEFT";
export const HANDLE_LEFT2 = "HANDLE_LEFT2";
export const HANDLE_LEFT_FOLDER_DETAILS = "HANDLE_LEFT_FOLDER_DETAILS";
export const HANDLE_BOTTOM = "HANDLE_BOTTOM";
export const HANDLE_BOTTOM_ADD_FOLDER = "HANDLE_BOTTOM_ADD_FOLDER";
export const HANDLE_BOTTOM_ADD_TO_COLLECTION =
  "HANDLE_BOTTOM_ADD_TO_COLLECTION";
export const HANDLE_BOTTOM_DELETED_ITEMS = "HANDLE_BOTTOM_DELETED_ITEMS";
