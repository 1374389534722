import React from "react";

export type IBrushIconProps = {};

const BrushIcon: React.FC<IBrushIconProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_340_633)">
        <path
          d="M21.8294 0C21.0883 0 20.3941 0.327656 19.8639 0.803906C9.64848 9.92906 8.4316 10.1484 8.9102 13.2183C6.96394 13.4508 5.22629 14.3456 4.2077 17.0302C4.08207 17.362 3.7802 17.5636 3.4277 17.5636C2.83379 17.5636 0.997227 16.0842 0.473633 15.7266C0.473633 20.1305 2.50145 24 7.31738 24C12.8102 24 14.4761 19.8867 14.1171 17.1614C16.7843 16.8328 18.671 14.2312 23.7944 4.66969C24.1549 3.96797 24.4736 3.21 24.4736 2.42156C24.4736 1.00875 23.2029 0 21.8294 0ZM11.5605 20.8852C10.6207 21.9567 9.19332 22.5 7.31738 22.5C4.3277 22.5 2.99129 20.6798 2.40723 18.8114C2.78316 18.9825 3.10941 19.0641 3.4277 19.0641C4.40832 19.0641 5.2652 18.4744 5.6102 17.5627C5.92191 16.7414 6.36254 15.0338 9.53645 14.6545L12.5871 17.0273C12.776 18.4655 12.5285 19.7812 11.5605 20.8852ZM22.4725 3.96094C16.9225 14.3184 15.7371 15.4505 13.375 15.7411L10.4688 13.4808C10.0563 10.8356 9.77504 11.827 20.8666 1.91953C21.1633 1.65281 21.5139 1.5 21.8294 1.5C22.3821 1.5 22.9736 1.87031 22.9736 2.42156C22.9736 2.9175 22.7139 3.48984 22.4725 3.96094Z"
          fill="#182844"
        />
      </g>
      <defs>
        <clipPath id="clip0_340_633">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.473633)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { BrushIcon };
