import { Skeleton } from "antd";

export default function TableSkeleton({ nbColumns }: { nbColumns: number }) {
  return (
    <>
      {Array.from({ length: 6 }, (_, index) => index + 1).map((_, i) => (
        <tr key={i}>
          {Array.from({ length: nbColumns }, (_, index) => index + 1).map(
            (_, j) => (
              <td key={j} style={{ background: "white" }}>
                <Skeleton.Input
                  style={{ width: 200, borderRadius: 10 }}
                  className="my-2"
                  active
                />
              </td>
            )
          )}
        </tr>
      ))}
    </>
  );
}
