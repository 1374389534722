import React from "react";

export type IUndoIconProps = {};

const UndoIcon: React.FC<IUndoIconProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_244_1411)">
        <path
          d="M12.0094 0.374747C9.3375 0.374747 6.87656 1.27475 4.90781 2.78881L2.55937 0.440372C1.62187 -0.501816 0 0.163809 0 1.49975V7.49975C0 8.32943 0.670312 8.99975 1.5 8.99975H7.5C8.83594 8.99975 9.50156 7.38256 8.55938 6.44037L6.04688 3.92318C7.74844 2.66693 9.82031 1.9685 12 1.9685C17.55 1.9685 22.0312 6.4685 22.0312 11.9997C22.0312 17.5497 17.5312 22.031 12 22.031C9.48281 22.031 7.11563 21.1029 5.29219 19.4576C5.07188 19.256 4.72969 19.2701 4.51875 19.481L4.18594 19.8138C3.95625 20.0435 3.97031 20.4138 4.20938 20.6294C6.27188 22.4904 9.00469 23.6247 12 23.6247C18.4125 23.6247 23.6109 18.431 23.625 12.0232C23.6391 5.61537 18.4125 0.379434 12.0094 0.374747ZM7.5 7.49975H1.5V1.49975L7.5 7.49975Z"
          fill="#182844"
        />
      </g>
      <defs>
        <clipPath id="clip0_244_1411">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { UndoIcon };
