import { Reducer, AnyAction } from "redux";
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  FORGOT_PWD_REQUEST,
  FORGOT_PWD_SUCCESS,
  FORGOT_PWD_FAIL,
  ADD_NEW_PWD_REQUEST,
  ADD_NEW_PWD_SUCCESS,
  ADD_NEW_PWD_FAIL,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL,
  RESET_PWD_CONNECTED_FAIL,
  RESET_PWD_CONNECTED_SUCCESS,
  CLEAR_MSG,
} from "../actions/auth-actions/types";
import { FINISH } from "../actions/guided-tour-actions/types";

const init_state: any = {
  token: localStorage.getItem("token"),
  user: null,
  isLoggedIn: false,
  userUpdateIsLoading: false,
  isLoading: false,
  isLoadingUser: true,
  isAdmin: false,
  type: null,
  error: null,
  msg: null,
};

const authReducer: Reducer<any, AnyAction> = (
  state: any = init_state,
  action
): boolean => {
  switch (action.type) {
    case FINISH:
      return {
        ...state,
        user: {
          ...state.user,
          last_login: new Date(),
        },
      };
    case ADD_NEW_PWD_REQUEST:
    case FORGOT_PWD_REQUEST:
    case LOAD_USER_REQUEST:
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_USER_SUCCESS:
      localStorage.setItem("token", action.payload.access_token);
      let isAdmin = Object.keys(action.payload)[0];
      if (isAdmin === "admin") {
        localStorage.setItem("role", "admin");
        return {
          ...state,
          token: action.payload.access_token,
          user: action.payload.admin,
          isLoading: false,
          isLoggedIn: true,
          isAdmin: true,
          error: null,
          msg: null,
          isLoadingUser: false,
        };
      } else {
        localStorage.setItem("role", "client");
        return {
          ...state,
          token: action.payload.access_token,
          user: action.payload.client,
          isLoading: false,
          isLoggedIn: true,
          isAdmin: false,
          error: null,
          msg: null,
          isLoadingUser: false,
        };
      }
    case LOAD_USER_SUCCESS:
      if (action.payload.hasOwnProperty("type")) {
        return {
          ...state,
          user: action.payload,
          isLoading: false,
          isLoggedIn: true,
          isAdmin: true,
          error: null,
          msg: null,
          isLoadingUser: false,
        };
      } else {
        return {
          ...state,
          // token: action.payload.access_token,
          user: action.payload,
          isLoading: false,
          isLoggedIn: true,
          isAdmin: false,
          error: null,
          msg: null,
          isLoadingUser: false,
        };
      }
    case RESET_PWD_CONNECTED_FAIL:
      return { ...state, msg: action.payload };
    case CLEAR_MSG:
      return { ...state, msg: undefined };

    case LOAD_USER_FAIL:
    case LOGIN_USER_FAIL:
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      return {
        ...state,
        token: null,
        isLoggedIn: false,
        isLoading: false,
        user: null,
        error: action.payload?.data,
        msg: null,
        isLoadingUser: false,
      };
    case LOGOUT_USER:
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      document.body.style.overflow = "unset";
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        token: null,
        user: null,
        msg: null,
      };
    case FORGOT_PWD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        msg: action.payload,
      };
    case ADD_NEW_PWD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user: null,
        error: null,
        msg: "Password Reset Successfully",
      };
    case RESET_PWD_CONNECTED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user: null,
        error: null,
        msg: "Password Reset Successfully",
      };
    case ADD_NEW_PWD_FAIL:
      return {
        ...state,
        isLoading: false,
        msg: null,
        error: action.payload.errors
          ? action.payload.errors.password
          : "The reset code is expired.",
      };
    case FORGOT_PWD_FAIL:
      return {
        ...state,
        msg: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        userUpdateIsLoading: true,
      };
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.payload, company: state.user.company },
        msg: null,
        isLoading: false,
        userUpdateIsLoading: false,
      };
    case UPDATE_ACCOUNT_FAIL:
      return {
        ...state,
        userUpdateIsLoading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
