import { useState, useEffect, useCallback } from "react";
import { Tabs, Row, Col, Result } from "antd";
import { useParams, useLocation, useHistory } from "react-router-dom";
import "../components/CollectionProducts/CollectionProducts.scss";
import Question from "../icons/Question";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import axiosInstance from "../config/axios-instance";
import backArrow from "../img/backArrow.png";

interface Props {}

export const FaqQuestion = (props: Props) => {
  const location = useLocation();
  const params: any = useParams();
  console.log(params.id);
  const [faqQuestion, setFaqQuestion] = useState<any>("");
  const [faq, setFaq] = useState<Array<any>>([]);
  const [search, setSearch] = useState<Array<any>>([]);
  const [active, setActive] = useState("0");
  const [noResult, setResult] = useState(false);
  const history = useHistory();

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQueryy();
  const fetchFaqCategory = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/faq/` + params.id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setFaqQuestion(res.data);
    });
  };

  const fetchFaqs = (id: any) => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/faq/categories/` + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setFaq(res.data);
    });
  };

  const onChange = (key: string) => {
    console.log(key);
    setActive(key);
    fetchFaqs(key);
  };
  /* 
  const search = (e: any) => {
    setKeyword(e.target.value);
    setLoading(true);
    if (!e.target.value) return;
    axiosInstance({
      method: "get",
      url: `/products?name=${e.target.value}`,
    })
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err: any) =>{});
    // dispatch(filterByTyping(e.target.value));
  }; */

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log(value);
    axiosInstance({
      method: "get",
      url: `/faq?search=${value}`,
    })
      .then((res) => {
        if (value && res.data.length > 0) {
          setSearch(res.data);
          setResult(false);
        } else if (res.data.length == 0 || value === "") {
          setResult(true);
        }
        if (value === "") {
          setResult(false);
          setActive("0");
          setSearch([]);
        }
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    fetchFaqCategory();
  }, [location.pathname]);
  return (
    <>
      <div style={{ paddingTop: "110px" }}>
        <div
          style={{
            height: "200px",
            backgroundColor: "#F8FAFF",
            textAlign: "center",
          }}
        >
          <div
            style={{
              paddingTop: "46px",
              fontSize: "20px",
              color: "#03468C",
              fontFamily: "Poppins",
            }}
          >
            <div className="col-12 d-flex align-items-center back_cs">
              <img
                src={backArrow}
                className="back_arrow_folder mr-2"
                alt="back arrow"
                onClick={() => history.push("/faq")}
              />
              <h1 style={{ fontSize: 16 }}>Faqs</h1>
            </div>
            Frequently asked questions &gt; {faqQuestion.category?.name}
          </div>
          <div style={{ marginTop: "16px" }}>{faqQuestion.question}</div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "25px" }}>
        <div
          style={{
            border: "1px solid #CECECE",
            marginBottom: "25px",
            borderRadius: "5px",
            padding: "25px",
          }}
          dangerouslySetInnerHTML={{
            __html: faqQuestion.description,
          }}
        ></div>
      </div>
    </>
  );
};
