import { combineReducers } from "redux";
import MenuReducer from "./MenuReducer";
import AuthReducer from "./auth-reducer";
import ProductReducer from "./products-reducer";
import leftMenuReducer from "./MenuDataReducer";
import foldersReducer from "./folders-reducer";
import searchReducer from "./search-reducer";
import alertsReducer from "./alertsReducer";
import SelectionReducer from "./SelectionReducer";
import OrderReducer from "./order-reducer";
import MembersReducer from "./members_reducer";
import NotificationReducer from "./notifications-reducer";
import CreativeStation from "./creative-station-reducer";
import foryouReducer from "./foryouReducer";
import GuidedTourReducer from "./guided-tour-reducer";

export default combineReducers({
  auth: AuthReducer,
  drawers: MenuReducer,
  products: ProductReducer,
  leftMenu: leftMenuReducer,
  search: searchReducer,
  folders: foldersReducer,
  alerts: alertsReducer,
  orders: OrderReducer,
  selection: SelectionReducer,
  members: MembersReducer,
  notifications: NotificationReducer,
  posts: CreativeStation,
  foryou: foryouReducer,
  tour: GuidedTourReducer,
});
