import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeOrderType, getStatus } from "../actions/order-actions/actions";
import { MyOrdersHeader } from "../components/MyOrders/MyOrdersHeader";
import { MyOrdersTable } from "../components/MyOrders/MyOrdersTable";
import "../sass/MyOrders.scss";
import { Tabs } from "antd";
import { MyCustomizedOrder } from "./MyCustomizedOrder";
import Head from "../components/Head";

const { TabPane } = Tabs;

interface Props {}

export const MyOrders = (props: Props) => {
  const { order_type } = useSelector((state: any) => state.orders);
  // const [orderSource, setOrderSource] = useState(order_type);
  const [alerts, setAlerts] = useState<any>(null);
  const [date, setDate] = useState("");
  const [orderType, setOrderType] = useState("In progress");
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  // Delete Alerts
  useEffect(() => {
    if (alerts) {
      setTimeout(() => {
        setAlerts(null);
      }, 5000);
    }
  }, [alerts]);

  useEffect(() => {
    dispatch(getStatus());
  }, []);

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  const callback = (key: any) => {
    switch (key) {
      case "1":
        // setOrderSource("direct_order");
        dispatch(changeOrderType({ key: "1", type: "direct_order" }));
        break;
      case "2":
        // setOrderSource("normal_order");
        dispatch(changeOrderType({ key: "2", type: "normal_order" }));
        break;
      default:
        dispatch(changeOrderType({ key: "3", type: "customized_order" }));
      // setOrderSource("customized");
    }
  };

  return (
    <div className="my_orders">
      <Head title="My Orders" />
      <MyOrdersHeader
        date={date}
        setDate={(dateString: any) => setDate(dateString)}
        setOrderType={(name: any) => setOrderType(name)}
        orderType={orderType}
      />
      <Tabs
        className="mt-2 mt-md-0"
        defaultActiveKey={order_type.key}
        onChange={callback}
        size={"large"}
        tabBarStyle={{ borderBottomColor: "red" }}
      >
        <TabPane tab="Direct Orders" key="1">
          <MyOrdersTable
            date={date.split("/").reverse().join("-")}
            orderType={orderType}
            setAlerts={setAlerts}
            orderSource={order_type.type}
            width={width}
          />
        </TabPane>
        <TabPane tab="Demco Orders" key="2">
          <MyOrdersTable
            date={date.split("/").reverse().join("-")}
            orderType={orderType}
            setAlerts={setAlerts}
            orderSource={order_type.type}
            width={width}
          />
        </TabPane>
        <TabPane tab="Customized Orders" key="3">
          <MyCustomizedOrder
            date={date.split("/").reverse().join("-")}
            orderType={orderType}
            setAlerts={setAlerts}
            orderSource={order_type.type}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
