import moment from "moment";
import axiosInstance from "../config/axios-instance";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { useEffect, useState, useRef } from "react";
import {
  Tooltip,
  Avatar,
  Table,
  Tag,
  Button,
  Space,
  Input,
  Popconfirm,
} from "antd";
import {
  SearchOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  CalendarOutlined,
  TableOutlined,
} from "@ant-design/icons";
import "../sass/bookings-page.scss";
import { MyNotif } from "../components/NotifIcons/NotifIcons";
import company_placeholder from "../img/company_placeholder.png";
import DrawerBooking from "../components/Bookings/DrawerBooking";
import CalendarView from "../components/Bookings/CalendarView";
import AvatarGroup from "../components/Bookings/AvatarGroup";
import DropdownActions from "../components/Bookings/DropdownActions";
import useWindowWidth from "../config/useWindowWidth";
import Head from "../components/Head";
import DisabledDrawerBooking from "../components/Bookings/DisabledDrawerBooking";
import RescheduleAction from "../components/Bookings/RescheduleAction";
function Bookings() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedDate2, setSelectedDate2] = useState<any>(null);

  const [subject, setSubject] = useState("");
  const [link, setlink] = useState("");

  const [collaboratorsCommercialSelected, setCollaboratorsCommercialSelected] =
    useState([]);
  const [collaboratorsTeams, setCollaboratorsTeams] = useState([]);

  const [notes, setNotes] = useState("");
  const [duration, setDuration] = useState("");
  const [timezone, setTimeZone] = useState("");

  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [company, setCompany] = useState<any>(null);
  const [clients, setClients] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);
  const [meetingToCancel, setMeetingToCancel] = useState(null);
  const [calendarView, setCalendarView] = useState(false);
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorsCommercial, setCollaboratorsCommercial] = useState([]);

  const [search, setSearch] = useState({
    searchText: "",
    searchedColumn: "",
  });
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const searchInput = useRef<any>();
  const width = useWindowWidth();

  const { isLoading, isError, data, error, refetch } = useQuery(
    "bookings",
    () => fetchBookings(),
    { keepPreviousData: true }
  );

  const rescheduleMeeting = async (type: string, meet: any) => {
    let token = localStorage.getItem("token");
    if (type === "cancel") {
      await axiosInstance({
        method: "delete",
        url: `/admin/bookings/${meet}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    await MyNotif(
      type === "cancel" ? "Meeting cancelled successfully." : "",
      "success"
    );
    refetch();
  };

  /* function convertHour(timeZone: any, hourUser: any) {
    const [hour, minutes] = hourUser.split(":");

    const dateFact = new Date(2000, 0, 1, hour, minutes);

    const offsetUserMinutes = timeZone * 60;

    const offsetGMTMinutes = 0;

    const differenceOffsetMinutes = offsetUserMinutes - offsetGMTMinutes;

    dateFact.setMinutes(dateFact.getMinutes() - differenceOffsetMinutes);

    const hourCorrespondante = dateFact.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return hourCorrespondante;
  } */
  function convertHour(timeZoneOffset: any, hourUser: any) {
    // Split the hourUser string into hours and minutes
    const [hour, minutes] = hourUser.split(":").map(Number);

    // Check if the input hourUser is valid
    if (
      isNaN(hour) ||
      isNaN(minutes) ||
      hour < 0 ||
      hour >= 24 ||
      minutes < 0 ||
      minutes >= 60
    ) {
      return "Invalid time format";
    }

    // Calculate the offset in minutes
    const offsetMinutes = timeZoneOffset * 60;

    // Create a new Date object for the current date and time
    const currentDate = new Date();

    // Set the hours and minutes based on user input
    currentDate.setHours(hour);
    currentDate.setMinutes(minutes);

    // Apply the time zone offset
    currentDate.setMinutes(currentDate.getMinutes() + offsetMinutes);

    // Format the adjusted time
    const hourCorresponding = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return hourCorresponding;
  }
  //  GET MEETINGS
  const fetchBookings = () => {
    let token = localStorage.getItem("token");
    setLoadingMeetings(true);
    return axiosInstance({
      method: "get",
      url: isAdmin ? `/admin/bookings` : `/bookings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res: any) => {
        setLoadingMeetings(false);
        setEvents(
          res.data.map((el: any) => {
            // Extract the year, month, day, and hour from the original date
            const dataStart = new Date(el.date);

            const yearsStart = dataStart.getFullYear();
            const monthStart = dataStart.getMonth() + 1; // Month is zero-based
            const dayStart = dataStart.getDate();
            let hours = new Date(el.date).getUTCHours();
            //  let hours = new Date(el.date).getUTCHours();

            let minutes = new Date(el.date).getMinutes();
            let hours2 = new Date(el.date2).getUTCHours();

            let minutes2 = new Date(el.date2).getMinutes();
            console.log("meeeting subjetc" + el.subject);

            console.log("start meeting" + el.date);
            console.log("start hours" + hours);

            console.log("time zone" + el.timezone);

            const timeZone = el.timezone;

            if (timeZone >= 0) {
              hours -= timeZone;
            } else {
              hours += Math.abs(timeZone);
            }

            const startDate = new Date(
              Date.UTC(yearsStart, monthStart - 1, dayStart, hours, minutes, 0)
            );

            const startTime2 = startDate.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });

            /*    const startTime = new Date(el.date).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }); */

            const inputDate = new Date(el.date2);

            // Extract the year, month, day, and hour from the original date
            const year = inputDate.getFullYear();
            const month = inputDate.getMonth() + 1; // Month is zero-based
            const day = inputDate.getDate();
            let hour = inputDate.getHours();
            const minut = inputDate.getMinutes();

            if (timeZone >= 0) {
              hour -= timeZone;
            } else {
              hour += Math.abs(timeZone);
            }

            // Create a new date with the same year, month, day, and hour, but modified minutes and seconds
            const modifiedDate = new Date(
              Date.UTC(year, month - 1, day, hour, minut, 0)
            );

            const endTime = modifiedDate.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });

            const heureUtilisateur = hours + ":" + minutes;
            const heureUtilisateur2 = hours2 + ":" + minutes2;

            const heureCorrespondante = convertHour(timeZone, heureUtilisateur);
            const heureCorrespondanteend = convertHour(
              timeZone,
              heureUtilisateur2
            );
            let seconds = new Date(el.date).getSeconds();
            let addedHours =
              el.duration === "2"
                ? [2, 0, 0]
                : el.duration === "1"
                ? [1, 0, 0]
                : [1, 30, 0];
            var start_ex = el.date;
            return {
              id: el.id,
              key: el.id,
              details: el,
              title: isAdmin ? (
                <div key={el.id} className="d-flex align-items-center">
                  <AvatarGroup meet={el} key={el.id} />
                </div>
              ) : (
                <>
                  <span>{el.subject} </span>
                  <br />
                  <span>
                    {startTime2} - {endTime}
                  </span>
                </>
              ),
              start: el.date,
              status: el.status,
              duration: el.duration,
              subject: el.subject,
              notes: el.notes,
              company:
                user &&
                isAdmin &&
                (el.client && el.client.company
                  ? el.client.company
                  : el.client?.name),
              client: el.client,
              // company_logo: el.client.company
              //   ? el.client.company.logo
              //   : company_placeholder,
              end: new Date(
                new Date(el.date).setHours(
                  addedHours[0] + hours,
                  addedHours[1] + minutes,
                  addedHours[2] + seconds
                )
              ),
            };
          })
        );
      })
      .catch((err: any) => {
        console.log(err);
        setLoadingMeetings(false);
      });
  };

  const fetchCompanies = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/companies`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { isLoading: loadingCompanies, data: companies } = useQuery<any>(
    "companies",
    () => fetchCompanies(),
    { keepPreviousData: true, enabled: isAdmin }
  );

  const onClose = () => {
    setEditMode(false);
    setVisible(false);
    setSelectedDate(null);
    setSelectedDate2(null);

    setSubject("");
    setlink("");
    setCollaboratorsCommercialSelected([]);
    setCollaboratorsTeams([]);
    setNotes("");
    setDuration("");
    setTimeZone("");
    setSelectedEvent(null);
    setCompany(null);
    setSelectedClient(null);
    setSelectedCollaborators([]);
  };

  const handleCollaborator = (value: any) => {
    if (editMode) {
      setSelectedEvent({ ...selectedEvent, client_id: value });
      setSelectedCollaborators(value);
    } else {
      setSelectedCollaborators(value);
    }
  };

  const fetchCollaborators = () => {
    // setLoadingClients(true);
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/collaborators`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res: any) => {
        // setLoadingClients(false);
        setCollaborators(res.data);
      })
      .catch((err: any) => {
        //  setLoadingClients(false);
        console.log(err);
      });
  };

  const handleSelect = ({ start, end }: any) => {
    if (
      moment(end).format("dddd") === "Saturday" ||
      moment(end).format("dddd") === "Sunday"
    )
      return;
    setVisible(true);
    setSelectedDate(moment(start));
  };

  const onChangeStartTime = (value: any, x: boolean) => {
    if (value) {
      console.log("VALUE: ", value.format());
      let _value = moment(value);
      console.log("_VALUE: ", moment(_value.format()));

      if (x) {
        setSelectedEvent({
          ...selectedEvent,
          start: moment(selectedEvent.start)
            .set({
              hour: moment(_value.format()).get("hour"),
              minute: moment(_value.format()).get("minute"),
              second: moment(_value.format()).get("second"),
            })
            .format(),
          // .format("DD-MM-YYYY, H:mm:ss"),
        });
      } else {
        console.log(selectedDate);

        setSelectedDate(
          moment(selectedDate).set({
            hour: _value.get("hour"),
            minute: _value.get("minute"),
            second: _value.get("second"),
          })
          // .format("DD-MM-YYYY, H:mm:ss")
        );
      }
    }
  };

  const onChangeStartTime2 = (value: any, x: boolean) => {
    if (value) {
      console.log("VALUE: ", value.format());
      let _value2 = moment(value);
      console.log("_VALUE: ", moment(_value2.format()));
      if (x) {
        setSelectedEvent({
          ...selectedEvent,
          end: moment(selectedEvent.details.date2)
            .set({
              hour: moment(_value2.format()).get("hour"),
              minute: moment(_value2.format()).get("minute"),
              second: moment(_value2.format()).get("second"),
            })
            .format(),
          // .format("DD-MM-YYYY, H:mm:ss"),
        });
      } else {
        console.log(value);
        setSelectedDate2(
          moment(selectedDate).set({
            hour: _value2.get("hour"),
            minute: _value2.get("minute"),
            second: _value2.get("second"),
          })
          // .format("DD-MM-YYYY, H:mm:ss")
        );
      }
    }
  };

  const onChangeEndTime = (value: any, edit: boolean) => {
    if (value) {
      if (edit) {
        setSelectedEvent({
          ...selectedEvent,
          duration: value,
        });
      } else {
        setDuration(value);
      }
    }
  };

  const onChangeTimeZone = (value: any, edit: boolean) => {
    console.log(value);
    if (value) {
      if (edit) {
        setSelectedEvent({
          ...selectedEvent,
          timezone: value,
        });
      } else {
        setTimeZone(value);
      }
    }
  };
  const onChangeSubject = (value: any, edit: boolean) => {
    if (value) {
      if (edit) {
        setSelectedEvent({
          ...selectedEvent,
          subject: value,
        });
      } else {
        setSubject(value);
      }
    }
  };

  const onChangeLink = (value: any, edit: boolean) => {
    if (value) {
      if (edit) {
        setSelectedEvent({
          ...selectedEvent,
          link: value,
        });
      } else {
        setlink(value);
      }
    }
  };

  const onChangeCollaboratorsCommercial = (value: any, edit: boolean) => {
    if (value) {
      if (edit) {
        setSelectedEvent({
          ...selectedEvent,
          collaboratorComm: value,
        });
      } else {
        setCollaboratorsCommercialSelected(value);
      }
    }
  };

  const onChangeTeams = (value: any, edit: boolean) => {
    if (value) {
      if (edit) {
        setSelectedEvent({
          ...selectedEvent,
          teams: value,
        });
      } else {
        setCollaboratorsTeams(value);
      }
    }
  };

  const createBooking = async (data?: any) => {
    let token = localStorage.getItem("token");
    const { data: response } = await axiosInstance({
      method: "post",
      url: isAdmin ? `/admin/bookings` : `/bookings`,
      data: isAdmin
        ? {
            date: selectedDate.format(),
            date2: selectedDate2.format(),
            notes,
            subject,
            duration,
            timezone,
            // user_id: user.id,
            client_id: selectedClient,
            members: collaboratorsCommercialSelected,
            teams: collaboratorsTeams,
            link: link,
          }
        : {
            date: selectedDate.format(),
            date2: selectedDate2.format(),
            notes,
            subject,
            duration,
            timezone,
            members: selectedClient,
            link: link,
            // user_id: user.id,
          },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    MyNotif("Booking created successfully.", "success");
    setVisible(false);
    setDuration("");
    setTimeZone("");
    setSelectedDate(null);
    setSelectedDate2(null);

    setNotes("");
    setSubject("");
    setlink("");
    refetch();
    return "oki";
  };

  const handleCompany = (value: any) => {
    setCompany(value);
  };
  const handleClient = (value: any) => {
    if (editMode) {
      setSelectedEvent({ ...selectedEvent, client_id: value });
      setSelectedClient(value);
      setCollaboratorsCommercial(clients.filter((cl: any) => cl.id !== value));
    } else {
      setSelectedClient(value);
      setCollaboratorsCommercial(clients.filter((cl: any) => cl.id !== value));
    }
  };

  const fetchClients = () => {
    setLoadingClients(true);
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/clients/companies/${company}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res: any) => {
        setLoadingClients(false);
        setClients(res.data.data);
      })
      .catch((err: any) => {
        setLoadingClients(false);
        console.log(err);
      });
  };

  const fetchTeams = () => {
    setLoadingClients(true);
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/commercial/companies/${company}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res: any) => {
        setLoadingClients(false);
        setTeams(res.data.data);
      })
      .catch((err: any) => {
        setLoadingClients(false);
        console.log(err);
      });
  };
  // useEffect(() => {
  //   if (selectedEvent && selectedEvent.company) {
  //     setCompany(selectedEvent.company.id);
  //   }
  // }, [selectedEvent]);

  useEffect(() => {
    fetchCollaborators();
    fetchTeams();
    if (company) {
      fetchClients();
    }
  }, [company]);

  const {
    mutate: mutateCreateBooking,
    isLoading: createLoading,
    error: createBookingError,
  } = useMutation(createBooking);

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearch({ ...search, searchText: "" });
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearch({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const getColumnSearchProps = (dataIndex: any, type: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearch({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      return type === "company"
        ? record.company
          ? record.company[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : ""
        : record.client[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput?.current.select(), 100);
      }
    },
    render: (text: any) => {
      return (
        <Tooltip title={text?.name} placement="bottom">
          <Avatar
            size="default"
            src={
              text?.logo
                ? process.env.REACT_APP_STORAGE_URL + text?.logo
                : company_placeholder
            }
          />
        </Tooltip>
      );
    },
  });

  const updateBooking = async (meet: any) => {
    let token = localStorage.getItem("token");
    const { data: response } = await axiosInstance({
      method: "post",
      url: `/admin/bookings/${meet.id}`,
      data: {
        status: "rejected",
        client_id: meet.client.id,
        _method: "patch",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await MyNotif("Meeting cancelled successfully.", "success");
    await refetch();
    return response.data;
  };

  function confirm(meet: any) {
    mutateDeleteBooking(meet);
    // refetch();
  }
  const deleteBooking = (id: any) => {
    let token = localStorage.getItem("token");
    setMeetingToCancel(id);
    return axiosInstance({
      method: "delete",
      url: `/admin/bookings/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        MyNotif("Meeting cancelled and deleted successfully.", "success");
        setMeetingToCancel(null);
        refetch();
      })
      .catch(() => {
        MyNotif("Error!", "danger");
        setMeetingToCancel(null);
      });
  };

  const { mutate: mutateDeleteBooking, isLoading: deleteLoading } =
    useMutation(deleteBooking);
  const { mutate: mutateUpdateBooking, isLoading: updateLoading } =
    useMutation(updateBooking);

  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      ...getColumnSearchProps("name", "company"),
    },
    {
      title: "Client",
      dataIndex: "details",
      key: "client",
      ...getColumnSearchProps("name", "client"),
      render: (client: any) => <p className="mb-0">{client?.client?.name}</p>,
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "status",
      filters: [
        {
          text: "Done",
          value: "done",
        },
        {
          text: "Pending",
          value: "waiting",
        },
        {
          text: "Confirmed",
          value: "accepted",
        },
        {
          text: "Cancelled",
          value: "cancelled",
        },
        {
          text: "New",
          value: "new",
        },
      ],
      onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
      render: (status: any, record: any) => {
        const genStatus = () => {
          switch (status) {
            case "new":
              return {
                text: "New",
                color: "blue",
              };
            case "waiting":
              return {
                text: "Pending",
                color: "orange",
              };
            case "accepted":
              return {
                text: "Confirmed",
                color: "green",
              };
            case "done":
              return {
                text: "Done",
                color: "#00468c",
              };
            default:
              return {
                text: "Cancelled",
                color: "red",
              };
          }
        };
        return moment(record.start).isAfter(moment(new Date())) ? (
          <Tag color={genStatus().color}>{genStatus().text}</Tag>
        ) : (
          <Tag color="red">Expired</Tag>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "start",
      key: "start",
      render: (start: any) => {
        let x = Number(
          moment(start, "YYYYMMDD").fromNow().replace(/[a-z]/gi, "").trim()
        );
        /*       let hours = new Date(el.date).getUTCHours();
        const heureCorrespondante = convertHour(timeZone, heureUtilisateur); */
        return (
          <Tooltip
            placement="bottom"
            title={moment(start, "YYYYMMDD").fromNow()}
          >
            <span>
              {moment(start).utc().format("ddd Do MMMM YYYY, H:mm")} GMT{" "}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Created by",
      dataIndex: "details",
      key: "created_by",
      // display the name of user who created the meeting
      render: (record: any) =>
        record.user && (
          <div className="d-flex align-items-center">
            <Avatar
              size="default"
              src={
                record.user
                  ? process.env.REACT_APP_STORAGE_URL + record.user.avatar
                  : company_placeholder
              }
            />
            <p className="mb-0 ml-2">{record.user.name}</p>
          </div>
        ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (record: any) =>
        // record.details.user &&
        // !record.details.commercials?.find(
        //   (commercial: any) => commercial.id === record.details?.user?.id
        // ) &&
        moment(record.start).isAfter(moment(new Date())) ? (
          record.status === "accepted" ? (
            <Popconfirm
              title="Cancel this meeting？"
              okText="Yes"
              cancelText="No"
              onConfirm={() => confirm(record.id)}
            >
              <Tooltip
                title="Cancel"
                placement="right"
                overlayInnerStyle={{
                  opacity: 0.8,
                  color: "black",
                  display: width > 1000 ? "none" : "inherit",
                }}
                color="white"
              >
                <Button
                  type="ghost"
                  style={{
                    background: "transparent",
                    color: "black",
                    borderRadius: 5,
                  }}
                  className="mr-2"
                  loading={deleteLoading && meetingToCancel === record.id}
                  icon={
                    <CloseCircleOutlined style={{ verticalAlign: "1.7px" }} />
                  }
                >
                  {width > 1000 &&
                    (deleteLoading && meetingToCancel === record.id
                      ? "Cancelling..."
                      : "Cancel")}
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : record.status === "rejected" ? null : (
            <DropdownActions
              meet={record}
              refetch={refetch}
              confirmCancel={confirm}
              meetingToCancel={meetingToCancel}
              deleteLoading={deleteLoading}
            />
          )
        ) : (
          <>
            <Button
              type="ghost"
              style={{
                background: "transparent",
                color: "black",
                borderRadius: 5,
              }}
              onClick={() => rescheduleMeeting("cancel", record.id)}
              className="mr-2"
              icon={<CloseCircleOutlined style={{ verticalAlign: "1.7px" }} />}
            >
              Cancel
            </Button>
            <RescheduleAction
              meet={record}
              refetch={refetch}
              meetingToCancel={meetingToCancel}
              deleteLoading={deleteLoading}
            />
          </>
        ),
    },
  ];

  const miniColumns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (subject: any) => <span>{subject}</span>,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "age",
      render: (notes: any) => <span>{notes}</span>,
    },
    /*    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (duration: any) => (
        <span>
          {duration} {duration === "2" ? " hours" : " hour"}
        </span>
      ),
    }, */
  ];

  return (
    <div className="bookings-page">
      <Head title="Manage Meetings" />
      <div className={"title-schedule-meeting"}>

      <span>Schedule a Meeting</span>
      </div>
      <div className="container hj-max-width-container">
        {user && isAdmin && (
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="h1_title" style={{ fontSize: 25, fontWeight: 500 }}>
              {calendarView ? "Calendar" : "Meetings List"}
            </h1>
            <Button
              type="text"
              className="goto_btn"
              style={{ fontSize: 15 }}
              onClick={() => setCalendarView(!calendarView)}
              icon={
                calendarView ? (
                  <TableOutlined style={{ verticalAlign: "2px" }} />
                ) : (
                  <CalendarOutlined style={{ verticalAlign: "2px" }} />
                )
              }
            >
              {calendarView ? "Meetings" : "Calendar"}
            </Button>
          </div>
        )}
        <div className="bookings-container">
          <div className="w-100">
            {user && isAdmin && !calendarView ? (
              <Table
                loading={loadingMeetings}
                className="table_bookings_admin"
                columns={
                  user &&
                  isAdmin &&
                  user.permissions.includes("meetings.management")
                    ? columns
                    : columns.filter((col: any) => col.title !== "Action")
                }
                dataSource={events}
                expandable={{
                  expandedRowRender: (record: any) => (
                    <Table
                      columns={miniColumns}
                      pagination={false}
                      footer={() => ""}
                      key={record.id}
                      dataSource={events
                        .filter((x: any) => x.key === record.key)
                        .map((el: any) => ({
                          duration: el.duration,
                          notes: el.notes,
                          subject: el.subject,
                          commercials: el.details.commercials,
                        }))}
                    />
                  ),
                }}
              />
            ) : (
              <CalendarView
                events={events}
                handleSelect={handleSelect}
                setEditMode={setEditMode}
                setVisible={setVisible}
                setSelectedDate={setSelectedDate}
                setSelectedDate2={setSelectedDate2}
                setSubject={setSubject}
                setNotes={setNotes}
                setDuration={setDuration}
                setTimeZone={setTimeZone}
                setSelectedEvent={setSelectedEvent}
                selectedEvent={selectedEvent}
                refetch={refetch}
              />
            )}
          </div>
        </div>
      </div>
      {user &&
      (!isAdmin || user.permissions.includes("meetings.management")) ? (
        <DrawerBooking
          editMode={editMode}
          onClose={onClose}
          visible={visible}
          selectedDate={selectedDate}
          selectedDate2={selectedDate2}
          loadingCompanies={loadingCompanies}
          handleCompany={handleCompany}
          companies={companies}
          company={company}
          handleClient={handleClient}
          selectedClient={selectedClient}
          loadingClients={loadingClients}
          clients={clients}
          handleCollaborator={handleCollaborator}
          collaborators={collaborators}
          onChangeStartTime={onChangeStartTime}
          onChangeStartTime2={onChangeStartTime2}
          onChangeEndTime={onChangeEndTime}
          onChangeTimeZone={onChangeTimeZone}
          onChangeCollaboratorsCommercial={onChangeCollaboratorsCommercial}
          onChangeTeams={onChangeTeams}
          onChangeSubject={onChangeSubject}
          onChangeLink={onChangeLink}
          setSubject={setSubject}
          setlink={setlink}
          link={link}
          subject={subject}
          setNotes={setNotes}
          notes={notes}
          teams={teams}
          collaboratorsCommercial={collaboratorsCommercial}
          mutateCreateBooking={mutateCreateBooking}
          createBookingError={createBookingError}
          createLoading={createLoading}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          setVisible={setVisible}
          refetch={refetch}
          duration={duration}
        />
      ) : (
        <DisabledDrawerBooking
          editMode={editMode}
          onClose={onClose}
          visible={visible}
          selectedDate={selectedDate}
          selectedDate2={selectedDate2}
          loadingCompanies={loadingCompanies}
          handleCompany={handleCompany}
          companies={companies}
          company={company}
          handleClient={handleClient}
          selectedClient={selectedClient}
          loadingClients={loadingClients}
          clients={clients}
          handleCollaborator={handleCollaborator}
          collaborators={collaborators}
          onChangeStartTime={onChangeStartTime}
          onChangeStartTime2={onChangeStartTime2}
          onChangeEndTime={onChangeEndTime}
          onChangeTimeZone={onChangeTimeZone}
          onChangeSubject={onChangeSubject}
          setlink={setlink}
          subject={subject}
          link={link}
          setNotes={setNotes}
          notes={notes}
          teams={teams}
          mutateCreateBooking={mutateCreateBooking}
          createBookingError={createBookingError}
          createLoading={createLoading}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          setVisible={setVisible}
          refetch={refetch}
          duration={duration}
        />
      )}
    </div>
  );
}

export default Bookings;
