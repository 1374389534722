import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_MORE_NOTIFICATIONS_REQUEST,
  GET_MORE_NOTIFICATIONS_SUCCESS,
  GET_MORE_NOTIFICATIONS_FAIL,
  GET_MY_NOTIF_SETTINGS_REQUEST,
  GET_MY_NOTIF_SETTINGS_SUCCESS,
  GET_MY_NOTIF_SETTINGS_FAIL,
  CHANGE_MY_NOTIF_SETTINGS_REQUEST,
  CHANGE_MY_NOTIF_SETTINGS_SUCCESS,
  CHANGE_MY_NOTIF_SETTINGS_FAIL,
  CHANGE_ONE_NOTIF_SETTINGS_REQUEST,
  CHANGE_ONE_NOTIF_SETTINGS_SUCCESS,
  CHANGE_ONE_NOTIF_SETTINGS_FAIL,
  PUSH_NEW_NOTIFICATION,
  NEW_NOTIFICATION_SEEN,
} from "./types";
import NotificationsServices from "./services";

// Get notification
export const getNotifications = () => async (dispatch: any) => {
  await dispatch({
    type: GET_NOTIFICATIONS_REQUEST,
  });
  try {
    const response = await NotificationsServices.getNotification();
    await dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: GET_NOTIFICATIONS_FAIL,
      payload: e.response,
    });
  }
};

// Get notification admin
export const getNotificationsAdmin = () => async (dispatch: any) => {
  await dispatch({
    type: GET_NOTIFICATIONS_REQUEST,
  });
  try {
    const response = await NotificationsServices.getNotificationAdmin();
    await dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: GET_NOTIFICATIONS_FAIL,
      payload: e.response,
    });
  }
};
// Load More Notifications
export const loadMoreNotifications = (url: string) => async (dispatch: any) => {
  await dispatch({
    type: GET_MORE_NOTIFICATIONS_REQUEST,
  });
  try {
    const response = await NotificationsServices.loadMoreNotifications(url);
    await dispatch({
      type: GET_MORE_NOTIFICATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: GET_MORE_NOTIFICATIONS_FAIL,
      payload: e.response,
    });
  }
};

// Get My Notifications settings
export const getMyNotifSettings = () => async (dispatch: any) => {
  await dispatch({
    type: GET_MY_NOTIF_SETTINGS_REQUEST,
  });
  try {
    const response = await NotificationsServices.getMyNotifSettings();
    await dispatch({
      type: GET_MY_NOTIF_SETTINGS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_MY_NOTIF_SETTINGS_FAIL,
      payload: error.response,
    });
  }
};

// Get My Notifications user
export const getMyNotifSettingsAdmin = () => async (dispatch: any) => {
  await dispatch({
    type: GET_MY_NOTIF_SETTINGS_REQUEST,
  });
  try {
    const response = await NotificationsServices.getMyNotifSettingsAdmin();
    await dispatch({
      type: GET_MY_NOTIF_SETTINGS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_MY_NOTIF_SETTINGS_FAIL,
      payload: error.response,
    });
  }
};
// Get My Notifications settings
export const changeStatusNotifSettings =
  (settings: any) => async (dispatch: any) => {
    await dispatch({
      type: CHANGE_MY_NOTIF_SETTINGS_REQUEST,
    });
    try {
      const response = await NotificationsServices.changeStatusNotifSettings(
        settings
      );
      await dispatch({
        type: CHANGE_MY_NOTIF_SETTINGS_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      dispatch({
        type: CHANGE_MY_NOTIF_SETTINGS_FAIL,
        payload: error.response,
      });
    }
  };

// Change one Notif Status
export const changeOneSettingStatus =
  (notif_name: string) => async (dispatch: any) => {
    await dispatch({
      type: CHANGE_ONE_NOTIF_SETTINGS_REQUEST,
      payload: notif_name,
    });
    try {
      const response = await NotificationsServices.changeOneSettingStatus(
        notif_name
      );
      await dispatch({
        type: CHANGE_ONE_NOTIF_SETTINGS_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      dispatch({
        type: CHANGE_ONE_NOTIF_SETTINGS_FAIL,
        payload: error.response,
      });
    }
  };

// Change one Notif Status
export const changeOneSettingStatusAdmin =
  (notif_name: string) => async (dispatch: any) => {
    await dispatch({
      type: CHANGE_ONE_NOTIF_SETTINGS_REQUEST,
      payload: notif_name,
    });
    try {
      const response = await NotificationsServices.changeOneSettingStatusAdmin(
        notif_name
      );
      await dispatch({
        type: CHANGE_ONE_NOTIF_SETTINGS_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      dispatch({
        type: CHANGE_ONE_NOTIF_SETTINGS_FAIL,
        payload: error.response,
      });
    }
  };

// Push new notification
export const pushNewNotification = (data: any) => (dispatch: any) => {
  return dispatch({
    type: PUSH_NEW_NOTIFICATION,
    payload: data,
  });
};

// New notifications seen
export const newNotificationSeen = () => (dispatch: any) => {
  return dispatch({
    type: NEW_NOTIFICATION_SEEN,
  });
};
