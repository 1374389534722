export interface IBagProps {
  className?: string;
}

function Bag({ className }: IBagProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.25"
      height="14"
      viewBox="0 0 12.25 14"
    >
      <path
        fill="#182844"
        d="M3.5-8.75a3.5 3.5 0 00-3.5-3.5 3.5 3.5 0 00-3.5 3.5h-2.625v8.313A2.188 2.188 0 00-3.937 1.75h7.875A2.188 2.188 0 006.125-.437V-8.75zM0-11.375A2.628 2.628 0 012.625-8.75h-5.25A2.628 2.628 0 010-11.375zM5.25-.437A1.314 1.314 0 013.938.875h-7.875A1.314 1.314 0 01-5.25-.437v-7.438h1.75v1.313a.438.438 0 00.438.438.438.438 0 00.438-.437v-1.314h5.25v1.313a.437.437 0 00.438.438.437.437 0 00.436-.438v-1.313h1.75z"
        data-name="Tracé 3388"
        transform="translate(6.125 12.25)"
      ></path>
    </svg>
  );
}

export default Bag;
