import React from "react";

export type IExposureIconProps = {};

const ExposureIcon: React.FC<IExposureIconProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_244_1415)">
        <path
          d="M15 17V19H17V17H19V15H17V13H15V15H13V17H15ZM20 2H4C2.895 2 2 2.895 2 4V20C2 21.105 2.895 22 4 22H20C21.105 22 22 21.105 22 20V4C22 2.895 21.105 2 20 2ZM5 5H11V7H5V5ZM20 20H4L20 4V20Z"
          fill="#182844"
        />
      </g>
      <defs>
        <clipPath id="clip0_244_1415">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { ExposureIcon };
