import { useQuery } from "react-query";
import {
  useRouteMatch,
  Route,
  Switch,
  useParams,
  useHistory,
} from "react-router-dom";
import {
  getDirectOderContent,
  getSingleArticle,
} from "../../actions/my-orders/services";
import "../../sass/MyOrders.scss";
import MyOrdersTable from "./MyOrdersTable";
import backArrow from "../../img/backArrow.png";
import { history } from "../..";
import DirectOrderProgress from "./DirectOrderProgress";
import "../../sass/MyOrders.scss";
import "../../sass/MyOrderConfirmation.scss";
import "../../sass/MyOrderDetails.scss";

const columns = [
  "Article Name",
  "Sent Date",
  "Options Count",
  "Option Details",
];

export default function DirectOrdersDetails({
  orderStatus,
}: {
  orderStatus: string;
}) {
  const { path, url } = useRouteMatch();

  return (
    <div className="my_orderss">
      <Switch>
        <Route path={`${path}`} exact>
          <DirectOrderContent url={url} orderStatus={orderStatus} />
        </Route>
        <Route exact path={`${path}/:article_id`}>
          <ArticleContent url={url} orderStatus={orderStatus} />
        </Route>
        <Route path={`${path}/:article_id/:option_id/progress`}>
          <DirectOrderProgress />
        </Route>
        <Route>
          <h1>Page Not Found</h1>
        </Route>
      </Switch>
    </div>
  );
}

const DirectOrderContent = ({ url, orderStatus }: any) => {
  const params = useParams<{
    id: string;
    status: string;
    article_id: string;
  }>();
  const { data, isLoading, refetch } = useQuery(
    `direct_order_${params.id}`,
    () => getDirectOderContent(params.id),
    {
      enabled: !Boolean(params.article_id),
    }
  );

  return (
    <div>
      {!isLoading && (
        <div className="myorder_details_header my-3">
          <img
            src={backArrow}
            className="back_arrow_folder"
            alt="back arrow"
            onClick={() => history.back()}
          />
          <h1 className="ml-2">
            {data?.data.name}{" "}
            <span style={{ fontWeight: 400 }}>
              ({data?.data.articles.length}{" "}
              {data?.data.articles.length
                ? data.data.articles.length > 1
                  ? "Articles"
                  : "Article"
                : data?.data.articles.length > 1
                ? "Articles"
                : "Article"}
              )
            </span>
          </h1>
        </div>
      )}
      <MyOrdersTable
        columns={columns}
        isLoading={isLoading}
        orderType="direct_orders_content"
        data={data?.data}
        url={url}
        directOrder={true}
        orderStatus={orderStatus}
        refetch={refetch}
      />
    </div>
  );
};

const optionColumns = ["Option Name", "Status", ""];

const ArticleContent = ({ url, orderStatus }: any) => {
  const params = useParams<{
    id: string;
    status: string;
    article_id: string;
  }>();

  const {
    data: article,
    isLoading: loadingSingleArticle,
    refetch,
  } = useQuery(
    `article_${params.id}`,
    () => getSingleArticle(params.article_id),
    {
      enabled: Boolean(params.article_id),
    }
  );

  return (
    <div>
      {!loadingSingleArticle && (
        <div className="myorder_details_header my-3">
          <img
            src={backArrow}
            className="back_arrow_folder"
            alt="back arrow"
            onClick={() => history.back()}
          />
          <h1 className="ml-2">
            {article?.data.name}{" "}
            <span style={{ fontWeight: 400 }}>
              ({article?.data.options.length}{" "}
              {article?.data.options.length
                ? article.data.options.length > 1
                  ? "Options"
                  : "Option"
                : article?.data.options.length > 1
                ? "Options"
                : "Option"}
              )
            </span>
          </h1>
        </div>
      )}
      <MyOrdersTable
        columns={optionColumns}
        isLoading={loadingSingleArticle}
        orderType="direct_orders_article_content"
        data={article?.data}
        url={url}
        directOrder={true}
        orderStatus={orderStatus}
        refetch={refetch}
      />
    </div>
  );
};
