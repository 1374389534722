import moment from "moment";
import { ImgItem } from "./ImgItem";
import VideoComponent from "./VideoComponent";

const PreviewPost = ({ onePost }: any) => {
  function showMarkup(data: string) {
    return { __html: onePost[data] };
  }

  return onePost.type === "video" ? (
    <div className="single_post pt-0">
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-12 d-flex justify-content-center"
            style={{ maxHeight: 500, position: "relative" }}
          >
            <div style={{ position: "relative", width: "50%" }}>
              <VideoComponent post={onePost} />
            </div>
          </div>
          <div className="col-12 title_container_post">
            <div className="row">
              <div className="col-12 post_header">
                <p className="post_type_title">
                  {onePost.category.toUpperCase()}
                </p>
                <h1 className="post_title">{onePost.name}</h1>
                <div className="divider_post"></div>
                <p className="post_by">
                  BY{" "}
                  {(onePost.author && onePost.author.toUpperCase()) || "DEMCO"}
                </p>
                <p className="post_date">
                  {moment(onePost.createdAt).format("DD/MM/YYYY")}
                </p>
                <p
                  dangerouslySetInnerHTML={showMarkup("short_description")}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="single_post pt-0">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 img_container_post">
            <ImgItem
              src={
                typeof onePost.file === "object"
                  ? URL.createObjectURL(onePost.file)
                  : process.env.REACT_APP_STORAGE_URL + onePost.file
              }
            />
          </div>
          <div className="col-12 col-md-6 d-flex flex-column align-self-start">
            <div className="row title_container_post">
              <div className="col-12 post_header">
                <p className="post_type_title">
                  {onePost.category.toUpperCase()}
                </p>
                <h1 className="post_title">{onePost.name}</h1>
                <div className="divider_post"></div>
                <p className="post_by">
                  BY{" "}
                  {(onePost.author && onePost.author.toUpperCase()) || "DEMCO"}
                </p>
                <p className="post_date">
                  {moment(onePost.createdAt).format("DD/MM/YYYY")}
                </p>
                <p
                  dangerouslySetInnerHTML={showMarkup("short_description")}
                ></p>
              </div>
            </div>
          </div>
        </div>
        <hr className="divider_posts" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p
                className="post_article"
                dangerouslySetInnerHTML={showMarkup("long_description")}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPost;
