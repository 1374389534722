import React from "react";
import Jeans from "../../icons/Jeans";
import Folder from "../../icons/Folder";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import { Link } from "react-router-dom";
import { Button } from "antd";

interface Props {
  order: any;
  getStatusLoading: any;
  articleStatus: any;
  statusMsg: any;
  width: any;
}

export const DirectOrderTableRowMobile = ({
  order,
  getStatusLoading,
  articleStatus,
  statusMsg,
  width,
}: Props) => {
  return (
    <tr>
      <td scope="row">
        <div className="my_orders_first_cell d-flex align-items-center">
          {order.articles.length > 1 ? <Folder /> : <Jeans />}
          <span className="myorder_order_name">
            {/* if there's only ONE article in this order display the article name otherwise the order name */}
            {order && order.articles.length > 1
              ? order.name
              : order.articles[0]
              ? order.articles[0].name
              : ""}
          </span>
        </div>
        <p className="m-0 mt-2 text-muted">
          {order.updated_at
            .slice(0, 10)
            .replace(/[-]/g, "/")
            .split("/")
            .reverse()
            .join("/")}
        </p>
        {width < 590 && (
          <div className="mt-2">
            {order.collaborators.map((collab: any) => (
              <a
                key={collab.id}
                className="collab_profile profile_direct_order"
                style={{
                  backgroundImage: `url(${
                    collab.logo
                      ? process.env.REACT_APP_STORAGE_URL + collab.logo
                      : avatar_placeholder
                  })`,
                }}
                //   replace id with the collaborator name
                id={collab.name}
              ></a>
            ))}
          </div>
        )}
      </td>
      {width > 590 && (
        <td className="collab_list_myorders">
          {order.collaborators.map((collab: any) => (
            <a
              key={collab.id}
              className="collab_profile profile_direct_order"
              style={{
                backgroundImage: `url(${
                  collab.logo
                    ? process.env.REACT_APP_STORAGE_URL + collab.logo
                    : avatar_placeholder
                })`,
              }}
              //   replace id with the collaborator name
              id={collab.name}
            ></a>
          ))}
        </td>
      )}
      {width > 590 && (
        <td>
          {order.articles.length > 1 ? (
            "---"
          ) : getStatusLoading ? (
            <p>Loading...</p>
          ) : (
            <Link
              to={`/my-orders/${order.id}/${
                order.articles[0] && order.articles[0].id
              }`}
            >
              <Button
                style={{
                  color: articleStatus === "PROTO" ? "#7C7C7C" : "#00468C",
                  background:
                    articleStatus === "PROTO"
                      ? "#E0E0E0"
                      : "rgba(0, 70, 140, 0.2)",
                }}
                className="status_btn_order"
              >
                {articleStatus}
              </Button>
            </Link>
          )}
        </td>
      )}
      <td>
        {order.articles.length > 1 ? (
          <Link to={`/my-orders/${order.id}`}>
            <Button
              type="ghost"
              className="see_more_order_details_btn"
              style={{ height: "50px !important" }}
            >
              See More
            </Button>
          </Link>
        ) : (
          <span style={{ color: statusMsg.color }} className="status_msg">
            {statusMsg.msg}
          </span>
        )}
      </td>
    </tr>
  );
};
