import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import jeansImgW from "../../img/jeans.png";
import jeansImg from "../../img/DirectOrder.jpg";
import direct_order_image from "../../img/direct_order_image.jpg";

interface Props {}

export const DirectOrderCarousel = ( bannerInfo : any) => {
  const responsive = {
    0: { items: 1 },
    1024: { items: 1 },
  };
  console.log(bannerInfo.type)
  const items = [
   <img src={process.env.REACT_APP_STORAGE_URL+bannerInfo.directContent?.media} />,

  ];
  const itemsCreativeTeam = [
    <div
        className="video_wrapper"
        dangerouslySetInnerHTML={{
          __html: `
      <video id="videoBGCreativeStation"  webkit-playsinline="true" autoplay="true"    preload="metadata" muted="true"  loop playsinline="true" id="video-id-auto">
      <source src=${process.env.REACT_APP_STORAGE_URL}${bannerInfo.directContent?.media} type="video/mp4" ></source>
     </video>
     
    `,
        }}
    />,

  ];
  return (
      <AliceCarousel
          autoPlay={true}
          autoPlayInterval={50000}
          infinite={true}
          disableButtonsControls={true}
          items={bannerInfo.type == "creative" ? itemsCreativeTeam : items}
          disableDotsControls={true}
          responsive={responsive}
      />
  );
};
