import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Folder from "../../icons/Folder";
import Jeans from "../../icons/Jeans";

interface Props {
  order: any;
  getStatusLoading: any;
  articleStatus: any;
  statusMsg: any;
}

export const NormalOrderTableRow = ({
  order,
  getStatusLoading,
  articleStatus,
  statusMsg,
}: Props) => {
  // Generate Status Msg for
  const genStatusMsg = (status_name: string) => {
    switch (status_name) {
      case "PROTO":
        return {
          color: "#BCBCBD",
          msg: "Your prototype is being prepared",
        };
      case "PRODUCTION":
      case "OK PROD":
        return {
          msg: "Your order is in process",
          color: "#06C170",
        };
      case "CANCELLED":
        return { color: "#FF3A3A", msg: "Your order is canceled" };
      default:
        return {
          color: "#FF872A",
          msg: "We are waiting for your answer",
        };
    }
  };

  return (
    <tr>
      <td scope="row">
        {order.products.length > 1 ? <Folder /> : <Jeans />}
        <span className="ml-2 myorder_order_name">
          {/* if there's only ONE product in this order display the product name otherwise the order name */}
          {order && order.products.length > 1
            ? order.reference
            : order.products[0]
            ? order.products[0].name
            : ""}
        </span>
      </td>
      <td>
        {order.created_at
          .slice(0, 10)
          .replace(/[-]/g, "/")
          .split("/")
          .reverse()
          .join("/")}
      </td>
      <td>
        {order.products.length > 1 ? (
          "---"
        ) : (
          <Link
            to={`/my-orders/original-product/${order.id}/${
              order.products[0] && order.products[0].id
            }`}
          >
            <Button
              style={{
                color:
                  order &&
                  order.products.length &&
                  order.products[0].status.name === "PROTO"
                    ? "#7C7C7C"
                    : "#00468C",
                background:
                  order &&
                  order.products.length &&
                  order.products[0].status.name === "PROTO"
                    ? "#E0E0E0"
                    : "rgba(0, 70, 140, 0.2)",
              }}
              className="status_btn_order"
            >
              {order.products.length && order.products[0].status.name}
            </Button>
          </Link>
        )}
      </td>
      <td>
        {order.products.length > 1 ? (
          <Link to={`/my-orders/original-product/${order.id}`}>
            <Button
              type="ghost"
              className="see_more_order_details_btn"
              style={{ height: "50px !important" }}
            >
              See More
            </Button>
          </Link>
        ) : (
          <span
            style={{
              color:
                order &&
                order.products.length &&
                genStatusMsg(order.products[0].status.name).color,
            }}
            className="status_msg"
          >
            {order &&
              order.products.length &&
              genStatusMsg(order.products[0].status.name).msg}
          </span>
        )}
      </td>
    </tr>
  );
};
