import { useQuery } from "react-query";
import {
  getMyCancelledCustomizedOrders,
  getMyCustomizedOrders,
  getCompletedCustomizedOrders
} from "../../actions/my-orders/services";
import MyOrdersTable from "./MyOrdersTable";
import React, { useState } from "react";
import {HrIcon} from "../../components/product-3d-config-tabs/forms/canvas/icon/HrIcon";
import {SearchOutlined} from "@ant-design/icons";

const columns: string[] = [
  "",
  "Reference",
  "Collection Name",
  "Order Date",
  "Delivery Date",
  "Number of article",
  "Status",
  "Description of status",
  "Team",
  /* "Order details", */
];
const canceleldColumns: string[] = [
  "",
  "Reference",
  "Collection Name",
  "Delete Date",
  "Status",
  "Number of article",
  "Action",
  "Team",
  /* "Order details", */
];


const completedColumns = [
  "",
  "Reference",
  "Collection Name",
  "Delivery Date",
  "Number of article",
  "Quantity",
  "Action",
  "Team",

  /*   "Order Details",
   */
];

export default function CustomizedOrders({
  orderType,
  url,
  orderStatus,
  selectedDate,
}: {
  orderType: string;
  url: string;
  orderStatus: string;
  selectedDate: any;
}) {

  const [searchQuery, setSearchQuery] = useState("");

  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["my_direct_orders", orderStatus, selectedDate],
   /*  orderStatus === "cancelled"
      ? () => getMyCancelledCustomizedOrders(selectedDate)
      : () => getMyCustomizedOrders(selectedDate), */

      orderStatus === "cancelled"
      ? () => getMyCancelledCustomizedOrders(selectedDate)
      : orderStatus === "completed"
      ? () => getCompletedCustomizedOrders(selectedDate)
      : () => getMyCustomizedOrders(selectedDate),
    {
      keepPreviousData: true,
    }
  );


  const handleSearchInputChange = (event: any) => {
    setSearchQuery(event.target.value);
  };
  console.log(data?.data?.data);
  const filteredData = data?.data?.data?.filter((order: any) => {
    console.log(order);

/*     const nameMatch = order?.collection_name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase()); */
     const refMatch = order?.reference
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase()); 

      const nameMatch =  order.products.some((p:any) => 
        /* article.article_options?.some(
          (option:any) => option.delivery_date === filteredData
        ) */
        p?.collection_name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase())
      ); 

    return refMatch || nameMatch;
  });
  if (isError) {
    return <p>Error!</p>;
  }

  return (
      <div className={"mt-2"}>
        <div className="search-bar searchfaq" style={{width: "100%"}}>
          <div className="input-container">
            <input type="text" placeholder="Search your order" onChange={handleSearchInputChange}
                   className={"search_input"}/>
            <HrIcon></HrIcon>
            <button type="submit" className={"submit_input"}><SearchOutlined/></button>
          </div>
        </div>
        <MyOrdersTable
            columns={orderStatus === "cancelled"
                ? canceleldColumns
                : orderStatus === "completed"
                    ? completedColumns
                    : columns}
            isLoading={isLoading || isFetching}
            orderType={orderType}
            data={filteredData}
            orderStatus={orderStatus}
            url={url}
            refetch={refetch}
        />
      </div>
  );
}
