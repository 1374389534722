import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
// import { ProductCard } from "../ProductCard/ProductCard";
import { Link, useHistory } from "react-router-dom";
import folder_content from "../../img/folder_content_2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  clearOneFolder,
  createFolder,
  getAllFolders,
} from "../../actions/folders-actions/actions";
import {
  deselectSelection,
  removeProductFromSelection,
} from "../../actions/myselection-actions/actions";
import { faFolderPlus, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import watermak from "../../img/watermark.png";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import "./Selection.scss";
import ExampleProductCard from "../ProductCard/ExampleProductCard";
import { ProductCard } from "../../pages/SearchResult";
import {
  Alert,
  Card,
  Button,
  Checkbox,
  Space,
  Drawer,
  Input,
  DatePicker,
  Spin,
} from "antd";
import Meta from "antd/lib/card/Meta";
import LazyImg from "../Library/LazyImage";
import useWindowWidth from "../../config/useWindowWidth";
import {
  createNormalOrderMySelection,
  deleteNormalOrderMySelection,
} from "../../actions/order-actions/services";
import { useMutation, useQueryClient } from "react-query";
import axiosInstance from "../../config/axios-instance";
import { MyNotif } from "../NotifIcons/NotifIcons";
import TextArea from "antd/lib/input/TextArea";
import {
  LoadingOutlined,
  DeleteOutlined,
  SendOutlined,
} from "@ant-design/icons";
import moment from "moment";
import customizeIcon from "../../img/customize_icon.svg";
import { motion } from "framer-motion";
import folder from "../../img/folder.png";
import { Button as ButtonCustom } from "../Button/Button";
import {
  FolderAddOutlined,
  UserOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import {
  createArticle,
  getCollaborators,
} from "../../actions/order-actions/actions";
import Select from "react-select";
import Bag from "../../icons/Bag";
import { Spinner } from "../Spinner";
import Folder from "../../icons/Folder";
import PlusIcon from "../../icons/PlusIcon";
import { Steps } from "antd";
import { SmileOutlined, SolutionOutlined } from "@ant-design/icons";
const { Step } = Steps;
export interface ISelectionProps {
  width: any;
}

export default function Selection({ width }: ISelectionProps) {
  const selection = useSelector((state: any) => state.selection);
  const [createCollection, setCreateCollection] = useState(false);
  const { user, isAdmin } = useSelector((state: RootStateOrAny) => state.auth);

  const [productsToOrder, setProductsToOrder] = useState([]);
  const [date_delivery, setDeliveryDate] = useState("");
  const [saison, setSaison] = useState("");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenDrawerCollection, setIsOpenDrawerCollection] = useState(false);

  const [addToCollection, setAddToCollection] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [year, setYear] = useState("");
  const [next, setNext] = useState(false);

  const [gender, setGender] = useState("");

  const [theme, setTheme] = useState("");
  const [disable, setDisable] = useState(false);
  const [collection_name, setOrderName] = useState("");
  const [orderDetails, setOrderDetails] = useState("");
  const [collaborator, setCollaborator] = useState([]);
  const [isRequired, setIsRequired] = useState(false);

  const handleCheckboxChange = (folderId: any) => {
    // Update the selected folder ID
    setSelectedFolderId(folderId);
  };
  const folders = useSelector((state: any) => state.folders);

  const [folderData, setfolderData] = useState({
    name: "",
    season: "",
    collaborator: [],
    year: "",
  });

  const handleChangeName = (e: any) => {
    setfolderData({ ...folderData, [e.target.name]: e.target.value });
  };

  const createNewFolder = () => {
    console.log(folderData.name);
    if (folderData.name === "") {
      console.log(folderData.name === "");
      MyNotif("Collection name is required.", "danger");
    } else {
      dispatch(createFolder({ ...folderData }));
      // dispatch(getAllFolders());
      setCreateCollection(false);
    }

    setfolderData({
      name: "",
      season: "",
      collaborator: [],
      year: "",
    });
  };

  useEffect(() => {
    dispatch(getAllFolders());
  }, []);

  const discard = () => {
    setfolderData({
      name: "",
      season: "",
      collaborator: [],
      year: "",
    });
  };

  const { collaborators, newOrder, orders, createOrderLoading } = useSelector(
    (state: any) => state.orders
  );

  const handleCollabChangeFolder = (e: any) => {
    setfolderData({
      ...folderData,
      collaborator: e && e.map((el: any) => el),
    });
  };

  useEffect(() => {
    dispatch(getCollaborators(""));
  }, []);
  const queryClient = useQueryClient();
  const handleCollabChange = (e: any) => {
    let arryColl: any = [];
    e &&
      e.map((el: any) => {
        arryColl.push(el.value);
      });
    setCollaborator(arryColl);
  };

  function handleChangeSeason(e: any) {
    setSaison(e.target.value);
  }
  const orderNow = (productsToOrder: any) => {
    if (date_delivery != "" && collection_name != "") {
      setDisable(true);
      let token = localStorage.getItem("token");
      if (productsToOrder.length == 1) {
        axiosInstance({
          method: "post",
          url: `/orders/myselection`,
          data: {
            products: productsToOrder,
            collection_name,
            date_delivery,
            collaborator,
            saison,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(function (response) {
          // window.location.href = `/my-orders/demco-product-orders/in-progress/${response?.data?.id}/${productsToOrder[0].id}/progress`;
          window.location.href = `/my-orders/demco-product-orders/in-progress`;
        });
      } else {
        axiosInstance({
          method: "post",
          url: `/orders/myselection`,
          data: {
            products: productsToOrder,
            collection_name,
            date_delivery,
            collaborator,
            saison,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(function (response) {
          window.location.href = `/my-orders/demco-product-orders/in-progress`;
        });
      }
      setDisable(false);
    } else {
      setIsRequired(true);
    }
  };

  const setProdToCollection = (productsToOrder: any, id: any) => {
    if (selectedFolderId) {
      setIsLoading(true);

      let token = localStorage.getItem("token");
      axiosInstance({
        method: "post",
        url: `/folders/add_to_collection/${id}`,
        data: {
          products_ids: productsToOrder,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        // window.location.href = `/my-orders/demco-product-orders/in-progress/${response?.data?.id}/${productsToOrder[0].id}/progress`;
        setIsLoading(false);
        MyNotif("Product(s) added successfully.", "success");
        setAddToCollection(false);

        setProductsToOrder([]);
      });
    } else {
      MyNotif("Please select the collection", "danger");
    }
  };

  useEffect(() => {
    // fetch new state of productsToOrder here

    {
      console.log(collaborators);
    }
  }, [productsToOrder]);

  function handleChangeDeliveryDate(e: any) {
    setDeliveryDate(e.target.value);

    console.log(date_delivery);
  }

  function handleChangeTheme(e: any) {
    setTheme(e.target.value);
  }
  function handleChangeGender(e: any) {
    console.log(e);

    setGender(e);
    console.log(gender);
  }

  function handleChangeYear(e: any) {
    setYear(e.target.value);
  }
  function handleChangeSaison(e: any) {
    console.log(e);
    setSaison(e);
  }

  function handleChangeOrderName(e: any) {
    setOrderName(e.target.value);
  }

  function handleChangeDetails(e: any) {
    setOrderDetails(e.target.value);
  }

  const handleRemoveProduct = (itemToRemove: any) => {
    const updatedProducts = productsToOrder.filter(
      (item: any) => item !== itemToRemove
    );
    setProductsToOrder(updatedProducts);
  };

  /* 
  const { mutateAsync: orderNow, isLoading: orderLoading } = useMutation(
    createNormalOrderMySelection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("my-selection");
        setProductsToOrder([]);
      },
    }
  );
 */
  const { mutateAsync: deleteProducts, isLoading: deleteLoading } = useMutation(
    deleteNormalOrderMySelection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("my-selection");
        setProductsToOrder([]);
      },
    }
  );

  const dispatch = useDispatch();

  const currentYear = new Date().getFullYear();

  const options_year = [
    { value: String(currentYear).slice(2), label: String(currentYear) },

    { value: String(currentYear + 1).slice(2), label: String(currentYear + 1) },
    { value: String(currentYear + 2).slice(2), label: String(currentYear + 2) },
    { value: String(currentYear + 3).slice(2), label: String(currentYear + 3) },
    { value: String(currentYear + 4).slice(2), label: String(currentYear + 4) },
  ];

  const options_season = [
    { value: "ps", label: "PS" },
    { value: "ss", label: "SS" },
    { value: "pf", label: "PF" },
    { value: "fw", label: "FW" },
  ];

  const handleSeasonChange = (e: any) => {
    setfolderData({
      ...folderData,
      season: e.value,
    });
  };
  const handleYearChange = (e: any) => {
    setfolderData({
      ...folderData,
      year: e.value,
    });
  };
  return (
    <div className="selection_container">
      {/* <div className="list_products_header"> */}
      {/* {width <= 575 && (
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={() => {
            dispatch(clearOneFolder());
            dispatch(deselectSelection());
          }}
        />
      )}*/}
      {selection &&
      selection.selectionProducts &&
      selection.selectionProducts.length > 0 ? (
        <div>
          <div
            className="row mb-3"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h4 className={"title_myselection"}></h4>

            <div className="row" style={{ gap: "8px" }}>
              {/*   <button
                  className="addToCollection"
                  onClick={() => {
                    if (productsToOrder.length > 0)

                      setAddToCollection(true)
                    else
                      MyNotif('Please select a product', "danger")

                  }}
              >
                {" "}
                <span color=""><FolderOpenOutlined/></span>
                <span style={{marginLeft: "10px"}}>Add To Collection</span>
              </button>
*/}
              {!user.is_client_clients && (
                <>
                  <button
                    className="addToCollectionMySelection"
                    onClick={() => {
                      if (productsToOrder.length > 0) setAddToCollection(true);
                      else MyNotif("Please select a product", "danger");
                    }}
                  >
                    {" "}
                    <FolderOpenOutlined className={"icon_send"} />
                    <span
                      style={{ marginLeft: "10px" }}
                      className={"text_button"}
                    >
                      Add To Collection
                    </span>
                  </button>

                  <button
                    className={"launch_collection"}
                    onClick={() => {
                      if (productsToOrder.length > 0)
                        setIsOpenDrawerCollection(true);
                      else MyNotif("Please select a product", "danger");
                    }}
                  >
                    <SendOutlined className={"icon_send"} />
                    <span
                      style={{ marginLeft: "10px" }}
                      className={"text_button"}
                    >
                      Launch Collection
                    </span>
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="list_products ">
            {selection.selectionProducts.map((el: any, i: number) => (
              <div
                key={el.id}
                style={{ marginRight: "16px", marginBottom: "16px" }}
              >
                <MyselectionCard
                  product={{
                    front: el.images[0] ? el.images[0].path : "",
                    back: el.images[1] ? el.images[1].path : "",
                    name: el.name,
                    code: el.code,
                    id: el.id,
                  }}
                  setProductsToOrder={setProductsToOrder}
                  productsToOrder={productsToOrder}
                  deleteProduct={deleteProducts}
                />
              </div>
            ))}

            {/*       <Drawer
        className="order_sent_drawer_customized"
        placement={"bottom"}
        closable={false}
       visible={addToCollection}
        key={"bottom"}>
        <div className="my-2 head">
          <h1>Add to collection !</h1>
          <p>Select the collection where you want to move your products</p>
        </div>
        <div className="p-5">
          <div className="row" key={'563'}>
            <button
              onClick={() => setCreateCollection(true)}
              className="order-btn-h">
              Create New Collection
            </button>
          </div>
          {folders?.folders?.length > 0 ? (
            <div className="row scrol_order">
              {folders.folders ? (
                folders.folders
                .filter((el: any) => el.client_id == user.id)
                  .map((folder: any, index: number) => (
                    <>
                      <div className="row">
                        <div className="col-md-4 col-sm-3 col-auto col-lg-4 col-xl-5">
                          <div
                            className={"one_folder one_folder_selected"}
                            key={index}>
                            {folder.products.length > 0 ? (
                              <div className="list_folder_elements">
                                {folder.products.map(
                                  (product: any, i: number) =>
                                    i < 7 ? (
                                      <div
                                        key={i}
                                        className="product_image_container">
                                        {product.image ? (
                                          <img
                                            src={
                                              process.env
                                                .REACT_APP_STORAGE_URL +
                                              product.image.path
                                            }
                                            alt=""
                                            className="img_product_folder"
                                          />
                                        ) : (
                                          <img
                                            src={"placeholder"}
                                            alt=""
                                            className="img_product_folder"
                                          />
                                        )}
                                      </div>
                                    ) : null
                                )}

                                {folder.products.length > 7 && (
                                  <div className=" product_image_container product_image_container_plus">
                                    <p className="paragraphe products_plus_folder_value ">
                                      +
                                    </p>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="list_folder_elements empty_folder">
                                <p className="paragraphe">Empty Folder</p>
                              </div>
                            )}

                            <div className="folder_footer">
                              <div
                                style={{
                                  borderRight: "1px solid #ccc",
                                  paddingRight: "10px",
                                  marginRight: "8px",
                                }}>
                                <Checkbox
                                  onChange={() =>
                                    handleCheckboxChange(folder.id)
                                  }
                                  checked={
                                    folder.id === selectedFolderId
                                  }></Checkbox>
                              </div>

                              <p className="folder_name">{folder.name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner />
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="row  justify-content-center">
                <div style={{textAlign:"center"}}>
                  <img
                    src={folder_content}
                    alt="folders image"
                    className="mb-5"
                  />

                  <div className="paragraphe collection_welcome_description">
                  You don't have any collections yet. Please create your collection. Thank you!
                  </div>

                </div>
              </div>
            </>
          )}
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ gap: "20px" }}>
            <button
              onClick={() => {
                 setAddToCollection(false);
                setSelectedFolderId(null); 
              }}
              className="submit_button_white submit_button_white_cancel">
              Discard
            </button>
            {folders?.folders?.length >0 &&
            <button
              onClick={()=>
                setProdToCollection(productsToOrder,selectedFolderId)
                }
              className="submit_button_white submit_button_white_confirm"
              disabled={isLoading}
              >
              {isLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: "#03468c" }}
                      spin
                    />
                  }
                />
              ) : (
                "Add to collection"
              )} 


            </button>
            }
          </div>
        </div>
      </Drawer>*/}
            <Drawer
              className="order_sent_drawer_customized"
              placement={"bottom"}
              closable={false}
              /*         onClose={() => setAddToCollection(false)}
               */ visible={addToCollection}
              key={"bottom"}
            >
              <div className="my-2 head">
                <span className={"add-to-collection-title"}>
                  Add to collection !
                </span>
                <p className={"add-to-collection-description"}>
                  Select the collection where you want to move your products
                </p>
              </div>
              <div className="p-5">
                <div className="row justify-content-end">
                  <button
                    onClick={() => setCreateCollection(true)}
                    className="order-btn-h-add-new-collection"
                  >
                    <span className="button-text">Create New Collection</span>
                    <span className="button-icon">
                      <PlusIcon></PlusIcon>
                    </span>
                  </button>
                </div>
                {folders?.folders?.length > 0 ? (
                  <div className="row scrol_order">
                    {folders.folders ? (
                      folders.folders
                        .filter((el: any) => el.client_id == user.id)
                        .map((folder: any, index: number) => (
                          <>
                            <div className="row">
                              <div className="col-md-4 col-sm-3 col-auto col-lg-4 col-xl-5">
                                <div
                                  className={"one_folder one_folder_selected"}
                                  key={index}
                                >
                                  {folder.products.length > 0 ? (
                                    <div className="list_folder_elements">
                                      {folder.products.map(
                                        (product: any, i: number) =>
                                          i < 7 ? (
                                            <div
                                              key={i}
                                              className="product_image_container"
                                            >
                                              {product.image ? (
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_STORAGE_URL +
                                                    product.image.path
                                                  }
                                                  alt=""
                                                  className="img_product_folder"
                                                />
                                              ) : (
                                                <img
                                                  src={"placeholder"}
                                                  alt=""
                                                  className="img_product_folder"
                                                />
                                              )}
                                            </div>
                                          ) : null
                                      )}

                                      {folder.products.length > 7 && (
                                        <div className=" product_image_container product_image_container_plus">
                                          <p className="paragraphe products_plus_folder_value ">
                                            +
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="list_folder_elements empty_folder">
                                      <p className="paragraphe">Empty Folder</p>
                                    </div>
                                  )}

                                  <div className="folder_footer">
                                    <div
                                      style={{
                                        borderRight: "1px solid #ccc",
                                        paddingRight: "10px",
                                        marginRight: "8px",
                                      }}
                                    >
                                      <Checkbox
                                        onChange={() =>
                                          handleCheckboxChange(folder.id)
                                        }
                                        checked={folder.id === selectedFolderId}
                                      ></Checkbox>
                                    </div>

                                    <p className="folder_name">{folder.name}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="row  justify-content-center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={folder_content}
                          alt="folders image"
                          className="mb-5"
                        />

                        <div className="paragraphe collection_welcome_description">
                          You don't have any collections yet. Please create your
                          collection. Thank you!
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {width > 916 && (
                  <>
                    <div className="d-flex align-items-center justify-content-center footer-add-to-collection">
                      <button
                        //   innerButton="Cancel"
                        onClick={() => {
                          setAddToCollection(false);
                          setSelectedFolderId(null);
                        }}
                        className="submit_button_white_discard_collection "
                      >
                        Discard
                      </button>
                      {folders?.folders?.length > 0 && (
                        <button
                          onClick={() =>
                            setProdToCollection(
                              productsToOrder,
                              selectedFolderId
                            )
                          }
                          className="submit_button_add_to_collection"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24, color: "#03468c" }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            "Add to collection"
                          )}
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>

              {width < 915 && (
                <>
                  <div className={"row footer-add-to-collection_mobile"}>
                    <div className={"col-6"}>
                      <button
                        //   innerButton="Cancel"
                        onClick={() => {
                          setAddToCollection(false);
                          setSelectedFolderId(null);
                        }}
                        style={{ width: "100%" }}
                        className="submit_button_white_discard_collection_mobile "
                      >
                        Discard
                      </button>
                    </div>
                    <div
                      className={"col-6"}
                      style={{ backgroundColor: "#182844" }}
                    >
                      {folders?.folders?.length > 0 && (
                        <button
                          onClick={() =>
                            setProdToCollection(
                              productsToOrder,
                              selectedFolderId
                            )
                          }
                          className="submit_button_add_to_collection_mobile"
                          disabled={isLoading}
                          style={{ width: "100%" }}
                        >
                          {isLoading ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24, color: "#03468c" }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            "Add to collection"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Drawer>

            <Drawer
              className="order_sent_drawer_customized"
              placement={"bottom"}
              closable={false}
              onClose={() => setCreateCollection(false)}
              visible={createCollection}
              key={"bottom"}
            >
              <motion.div
                className={"add_folder_container"}
                style={{
                  height: "calc(100vh - 109px)",
                  justifyContent: "space-between",
                }}
                transition={{ type: "tween" }}
                // variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
              >
                <div className="add_folder_top">
                  {/* <Bag /> */}
                  <img src={folder} alt="folder image" className="folder_img" />

                  <h3>Add New Collection</h3>
                </div>
                <div className="container">
                  <div style={{ width: "100%" }} className="container-fluid">
                    <div className="form_add_folder">
                      <div className="form_add_folder_left col-12 col-md-12">
                        <div className="field">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder=" "
                            onChange={handleChangeName}
                          />
                          <label htmlFor="name" className="folder_label">
                            Collection Name
                          </label>
                        </div>
                        <div className="row ">
                          <div className={"col-md-6"}>
                            <div className="field ">
                              <Select
                                name="seasons"
                                placeholder="Select season"
                                options={options_season}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleSeasonChange}
                              />
                            </div>
                          </div>

                          <div className={"col-md-6"}>
                            <div className="field ">
                              <Select
                                name="years"
                                placeholder="Select year"
                                options={options_year}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleYearChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="field tags_field"
                      style={{ padding: "25px" }}
                    >
                      <Select
                        isMulti
                        name="colors"
                        placeholder="Select your collaborators"
                        options={
                          collaborators &&
                          collaborators?.map((el: any) => {
                            return {
                              label: el.name,
                              value: el.id,
                            };
                          })
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleCollabChangeFolder}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ gap: "20px" }}
                >
                  <ButtonCustom
                    innerButton="Discard"
                    buttonClass="submit_button_white submit_button_white_discard"
                    onClick={() => {
                      discard();
                      setCreateCollection(false);
                    }}
                  />

                  <ButtonCustom
                    innerButton="Create new collection"
                    buttonClass="submit_button_white submit_button_white_confirm"
                    onClick={() => {
                      discard();
                      createNewFolder();
                    }}
                  />
                </div>
              </motion.div>
            </Drawer>
          </div>

          {/*  {productsToOrder.length > 0 && (
            <Alert
              message={`${productsToOrder.length} Product(s) selected`}
              type="info"
              style={{
                position: "fixed",
                left: "50%",
                // top: "100%",
                bottom: "-12px",
                width: "30vw",
                borderRadius: "8px",
                border: "2px solid #809ec2",
                color: "#00468c",
                backgroundColor: "#f4f7ff",
                minWidth: "400px",
                transform: "translate(-50%,-50%)",
                zIndex: 20,
              }}
              action={
                <Space direction="horizontal">
                  <Button
                    size="middle"
                    type="ghost"
                    style={{
                      color: "#00468c",
                      borderColor: "#00468c",
                      borderRadius: "5px",
                    }}
                  
                    onClick={() => deleteProducts(productsToOrder)}
                  >
                    Delete
                  </Button>
                  <Button
                    size="middle"
                    type="primary"
                    style={{
                      background: "#00468c",
                      borderColor: "#00468c",
                      borderRadius: "5px",
                    }}
                    onClick={() => setIsOpenDrawer(true)}
                  >
                    Order now
                  </Button>
                </Space>
              }
              closable
              onClose={() => setProductsToOrder([])}
            />
          )}  */}
        </div>
      ) : (
        selection.selectionProducts &&
        selection.selectionProducts.length === 0 && (
          <div className="empty_collection ">
            <img src={folder_content} alt="foders image" className="mb-5" />
            <h2 className="collection_welcome mb-3">My Selection</h2>
            <p className="paragraphe collection_welcome_description mb-5 mt-4">
              Fill your Selection with your favorite products.
            </p>
            {/* {!folders.folders && (
              <Button
                innerButton="Add New Folder"
                buttonClass="submit_button_blue submit_button_blue_add_folder mt-5"
                onClick={() => {}}
                icon={<FontAwesomeIcon icon={faFolderPlus} />}
              />
            )} */}
          </div>
        )
      )}
      {/* </div> */}

      <Drawer
        className="order_sent_drawer_customized"
        placement={"bottom"}
        closable={true}
        onClose={() => {
          setProductsToOrder([]);
          setIsOpenDrawerCollection(false);
          setNext(false);
        }}
        visible={isOpenDrawerCollection}
        key={"bottom"}
        style={{ paddingTop: "25px" }}
      >
        {width > 768 ? (
          <>
            <div className="row">
              <div
                className="col-6 "
                style={{
                  paddingTop: "88px",
                  paddingLeft: "112px",
                  paddingRight: "112px",
                }}
              >
                <div className="titledrawerConfirmlaunchorder">
                  Selected products
                </div>
                <div
                  style={{ marginBottom: "32px" }}
                  className={"titledrawerConfirmlaunchorderDiscription"}
                >
                  Please review your selected items below before confirming your
                  order.
                </div>
                {productsToOrder.length > 0 ? (
                  <div
                    className="border"
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "#F8FAFC",
                      padding: "12px",
                    }}
                  >
                    {productsToOrder.map((item: any, key: any) => {
                      return (
                        <>
                          <div
                            className="row rowProdcut"
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{
                                borderRadius: "8px",
                                marginRight: "16px",
                              }}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_STORAGE_URL + item.front
                                }
                                width="100px"
                                height="72px"
                                className="orderBoxImage"
                              />
                            </div>
                            <div className="">
                              <span className="titleorderContainerH1myselection">
                                {item.name}
                              </span>
                              <br></br>
                              <span className="titleorderContainerH2myselection">
                                {item.code}
                              </span>
                            </div>
                            <div style={{ position: "absolute", right: "0" }}>
                              <button
                                onClick={() => {
                                  handleRemoveProduct(item);
                                  if (productsToOrder.length == 1) {
                                    setProductsToOrder([]);
                                    setIsOpenDrawer(false);
                                  }
                                }}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "5px",
                                  backgroundColor: "#E5ECF3",
                                  padding: "16px",
                                  width: "32px",
                                  height: "32px",
                                }}
                              >
                                <DeleteOutlined />
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <>The list is empty </>
                )}
              </div>
              <div
                className="col-6 "
                style={{
                  paddingTop: "88px",
                  paddingLeft: "112px",
                  paddingRight: "112px",
                }}
              >
                {" "}
                <div className="titledrawerConfirmlaunchorder">
                  Order details
                </div>
                <div
                  style={{ marginBottom: "32px" }}
                  className={"titledrawerConfirmlaunchorderDiscription"}
                >
                  Complete your order by providing the details below.
                </div>
                <div>
                  <label className="mr-3 mb-1">Collection name</label>
                  <Input
                    type="text"
                    style={{
                      borderRadius: "5px",
                      marginBottom: "16px",
                    }}
                    onChange={handleChangeOrderName}
                  ></Input>
                  {isRequired && collection_name === "" && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
                <label className="mr-3 mb-1">Season</label>
                <Input
                  type="text"
                  style={{
                    borderRadius: "5px",
                    marginBottom: "16px",
                  }}
                  defaultValue={saison}
                  onChange={handleChangeSeason}
                ></Input>
                <label className="mr-3 mb-0">Select Collaborators</label>
                <Select
                  isMulti
                  name="colors"
                  placeholder="Select your collaborators"
                  options={
                    collaborators &&
                    collaborators.map((el: any) => {
                      return {
                        label: el.name,
                        value: el.id,
                      };
                    })
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleCollabChange}
                />
                <div>
                  <label className="mr-3 mb-0 mt-3">Delivery date</label>
                  <DatePicker
                    disabledDate={(current) =>
                      current.isBefore(moment().add(15, "days"))
                    }
                    format={"DD/MM/YYYY"}
                    onChange={(date: any, dateString: any) =>
                      setDeliveryDate(dateString)
                    }
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      marginBottom: "16px",
                    }}
                  />
                  {isRequired && date_delivery === "" && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
                <div>
                  <button
                    className="view-details"
                    onClick={() => orderNow(productsToOrder)}
                    disabled={disable}
                    style={{ marginTop: "16px" }}
                  >
                    {disable ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 24, color: "white" }}
                            spin
                          />
                        }
                      />
                    ) : (
                      <>Confirm order</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ marginTop: "15%" }} className={"steps_launch_order"}>
              <Steps current={next ? 1 : 0} labelPlacement={"vertical"}>
                <Step title="Bag" />
                <Step
                  title="Confirmation"
                  style={{
                    fontSize: "12px",
                  }}
                />
              </Steps>
            </div>
            <div className="row">
              {!next && (
                <>
                  <div
                    className="col-12 "
                    style={{
                      paddingTop: "30px",
                    }}
                  >
                    <div className="titledrawerConfirmlaunchorder">
                      Selected products
                    </div>
                    <div
                      style={{ marginBottom: "32px" }}
                      className={"titledrawerConfirmlaunchorderDiscription"}
                    >
                      Please review your selected items below before confirming
                      your order.
                    </div>
                    {productsToOrder.length > 0 ? (
                      <div
                        className="border"
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "#F8FAFC",
                          padding: "12px",
                        }}
                      >
                        {productsToOrder.map((item: any, key: any) => {
                          return (
                            <>
                              <div
                                className="row rowProdcut"
                                style={{ position: "relative" }}
                              >
                                <div
                                  style={{
                                    borderRadius: "8px",
                                    marginRight: "16px",
                                  }}
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_STORAGE_URL +
                                      item.front
                                    }
                                    width="100px"
                                    height="72px"
                                    className="orderBoxImage"
                                  />
                                </div>
                                <div className="">
                                  <span className="titleorderContainerH1myselection">
                                    {item.name}
                                  </span>
                                  <br></br>
                                  <span className="titleorderContainerH2myselection">
                                    {item.code}
                                  </span>
                                </div>
                                <div
                                  style={{ position: "absolute", right: "0" }}
                                >
                                  <button
                                    onClick={() => {
                                      handleRemoveProduct(item);
                                      if (productsToOrder.length == 1) {
                                        setProductsToOrder([]);
                                        setIsOpenDrawer(false);
                                      }
                                    }}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      backgroundColor: "#E5ECF3",
                                      padding: "16px",
                                      width: "32px",
                                      height: "32px",
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </button>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <div>The list is empty </div>
                    )}
                    <div className={"row mt-3"}>
                      <button
                        onClick={() => setNext(true)}
                        className={"next_back_mycollection"}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </>
              )}
              {next && (
                <>
                  <div
                    className="col-12 "
                    style={{
                      paddingTop: "30px",
                    }}
                  >
                    {" "}
                    <div className="titledrawerConfirmlaunchorder">
                      Order details
                    </div>
                    <div
                      style={{ marginBottom: "32px" }}
                      className={"titledrawerConfirmlaunchorderDiscription"}
                    >
                      Complete your order by providing the details below.
                    </div>
                    <div>
                      <label className="mr-3 mb-1 label_myselection">
                        Collection name
                      </label>
                      <Input
                        type="text"
                        style={{
                          borderRadius: "5px",
                          marginBottom: "16px",
                        }}
                        onChange={handleChangeOrderName}
                      ></Input>
                      {isRequired && collection_name === "" && (
                        <span
                          style={{ color: "red" }}
                          className={"label_myselection"}
                        >
                          This field is required
                        </span>
                      )}
                    </div>
                    <label className="mr-3 mb-1 label_myselection">
                      Season
                    </label>
                    <Input
                      type="text"
                      style={{
                        borderRadius: "5px",
                        marginBottom: "16px",
                      }}
                      defaultValue={saison}
                      onChange={handleChangeSeason}
                    ></Input>
                    <label className="mr-3 mb-0 label_myselection">
                      Select Collaborators
                    </label>
                    <Select
                      isMulti
                      name="colors"
                      placeholder="Select your collaborators"
                      options={
                        collaborators &&
                        collaborators.map((el: any) => {
                          return {
                            label: el.name,
                            value: el.id,
                          };
                        })
                      }
                      className="basic-multi-select-my-selection"
                      classNamePrefix="select"
                      onChange={handleCollabChange}
                    />
                    <div>
                      <label className="mr-3 mb-0 mt-3 label_myselection">
                        Delivery date
                      </label>
                      <DatePicker
                        disabledDate={(current) =>
                          current.isBefore(moment().add(15, "days"))
                        }
                        format={"DD/MM/YYYY"}
                        onChange={(date: any, dateString: any) =>
                          setDeliveryDate(dateString)
                        }
                        style={{
                          borderRadius: "5px",
                          width: "100%",
                          marginBottom: "16px",
                          fontSize: "12px",
                        }}
                      />
                      {isRequired && date_delivery === "" && (
                        <span
                          style={{ color: "red" }}
                          className={"label_myselection"}
                        >
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className={"row"}>
                      <div className={"col-6"}>
                        <button
                          onClick={() => setNext(false)}
                          className={"next_back_mycollection"}
                        >
                          Back
                        </button>
                      </div>
                      <div className={"col-6"}>
                        <button
                          className="confirm_order_collection"
                          onClick={() => orderNow(productsToOrder)}
                          disabled={disable}
                        >
                          {disable ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24, color: "white" }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            <>Confirm order</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </Drawer>
    </div>
  );
}

const MyselectionCard = ({
  product,
  setProductsToOrder,
  productsToOrder,
  deleteProduct,
}: any) => {
  const width = useWindowWidth();
  const history = useHistory();
  const { user, isAdmin } = useSelector((state: RootStateOrAny) => state.auth);

  const toggle = () => {
    const id = product.id;
    const isExist = productsToOrder.find((el: any) => el.id === id);
    console.log(isExist);
    let updatedList = [...productsToOrder];
    if (!isExist) {
      updatedList = [...productsToOrder, product];
    } else {
      updatedList.splice(productsToOrder.indexOf(isExist), 1);
    }

    setProductsToOrder(updatedList);
  };
  return (
    <>
      <Card
        style={{
          width: "100%",
          paddingTop: 15,
          borderRadius: "16px",
        }}
        bordered={true}
        className="grid-item-product"
        cover={
          <div className={`product_item_new mb-2`}>
            <LazyImg
              alt={product.name}
              style={{
                position: "absolute",
                zIndex: 2,
                opacity: 0.2,
                objectFit: "contain",
                width: "100%",
                height: "260px",
              }}
              src={watermak}
              thumb={watermak}
            />
            <LazyImg
              className="img-front"
              alt={product?.name}
              style={{
                objectFit: "contain",
                width: "100%",
                height: "260px",
              }}
              // src={process.env.REACT_APP_STORAGE_URL.product.font}

              src={process.env.REACT_APP_STORAGE_URL + product.front}
              thumb={placeholder_jeans}
            />
            <LazyImg
              style={{
                height: "260px",
                objectFit: "contain",
              }}
              className="img-back"
              alt={product?.name}
              //    src={placeholder_jeans}
              src={process.env.REACT_APP_STORAGE_URL + product.front}
              thumb={placeholder_jeans}
            />
            {/*          <Button
           onClick={() => deleteProduct([{ id: product.id }])}

             className="customize_icon"
             style={{
              position: "absolute",
              // bottom: "5px",
              right: "2px",
            }}
          >
            delete
          </Button> */}

            <Link
              to={`/customization/new/${product.id}`}
              className="customize_icon"
              style={{
                position: "absolute",
                bottom: "5px",
                right: "2px",
              }}
            >
              <img src={customizeIcon} alt="customize product icon" />
            </Link>
          </div>
        }
      >
        <Meta
          description={
            <div
              className="d-flex mt-2 justify-content-between"
              style={{
                height: "30px",
                // justifyContent: "center",
              }}
            >
              <div className="labels_list">
                <div>
                  <h4 className="h4_medium mb-0 myselection_product_name">
                    {product?.name}
                  </h4>
                </div>
              </div>
              {!user.is_client_clients && (
                <div className="align-self-center">
                  <Checkbox
                    onChange={toggle}
                    // checked={productsToOrder.includes(product.id)}
                    checked={productsToOrder.some(
                      (p: any) => p.id === product.id
                    )}
                  />
                </div>
              )}
            </div>
          }
        />
      </Card>
      {/* <Card
      style={{
        width: width < 1200 ? "100%" : "14rem",
        height: "100%",
        paddingTop: 15,
        borderRadius: "16px",
      }}
      bordered={true}
      // bodyStyle={{ padding: "0px" }}
      className="grid-item-product"
      cover={
        <div className={`product_item_new mb-2`}>
          <LazyImg
            alt={product.name}
            style={{
              position: "absolute",
              zIndex: 2,
              opacity: 0.2,
              objectFit: "contain",
              width: "100%",
              height: "260px",
            }}
            src={watermak}
            thumb={watermak}
          />
          <LazyImg
            className="img-front"
            alt={product?.name}
            style={{
              objectFit: "contain",
              width: "100%",
              height: "260px",
            }}
            src={
              product?.front
                ? process.env.REACT_APP_STORAGE_URL + product?.front
                : placeholder_jeans
            }
            thumb={
              product?.front
                ? process.env.REACT_APP_STORAGE_URL + product?.front
                : placeholder_jeans
            }
          />
          <LazyImg
            style={{ height: "260px", objectFit: "contain" }}
            className="img-back"
            alt={product?.name}
            src={
              product?.back
                ? process.env.REACT_APP_STORAGE_URL + product?.back
                : placeholder_jeans
            }
            thumb={
              product?.back
                ? process.env.REACT_APP_STORAGE_URL + product?.back
                : placeholder_jeans
            }
          />
          <Link
            to={`/customization/new/${product.id}`}
            className="customize_icon"
            style={{
              position: "absolute",
              // bottom: "5px",
              right: "2px",
            }}
          >
            <img src={customizeIcon} alt="customize product icon" />
          </Link>
        </div>
      }
    >
      <Meta
        description={
          <div
            className="d-flex mt-2 justify-content-between"
            style={{ height: "60px" }}
          >
            <div className="labels_list">
              <div
              
                style={{ cursor: "pointer" }}
              >
                <h4 className="h4_medium mb-0" style={{ fontSize: "1rem" }}>
                  {product?.code}
                </h4>
                <h4
                  className="h4_medium"
                  style={{ fontWeight: 400, fontSize: "1rem" }}
                >
                  {product?.name}
                </h4>
              </div>
            </div>
            <div className="align-self-center">
              <Checkbox
                onChange={toggle}
                // checked={productsToOrder.includes(product.id)}
                checked={productsToOrder.some((p: any) => p.id === product.id)}
              />
            </div>
          </div>
        }
      />
    </Card> */}
    </>
  );
};
