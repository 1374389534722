import { Button } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Jeans from "../../icons/Jeans";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  deleteOneArticle,
  updateArticle,
  // changeStatusNormalOrder
} from "../../actions/order-actions/actions";
import { v4 as uuidv4 } from "uuid";
import OrderServices from "../../actions/order-actions/services";

interface Props {
  article: any;
  order?: any;
  setAlerts?: any;
  orderType?: any;
  cancelled?: any;
}

export const ArticlesTableRow = ({
  article,
  order,
  setAlerts,
  orderType,
  cancelled,
}: Props) => {
  const [articleStatus, setArticleStatus] = useState<any>(null);
  const [statusMsg, setStatusMsg] = useState({
    color: "black",
    msg: "",
  });
  const { status, orders } = useSelector((state: any) => state.orders);

  const params: any = useParams();
  const dispatch = useDispatch();

  //   Get the article status
  // useEffect(() => {
  //   if (orderType === "direct") {
  //     if (article.article_status_id) {
  //       setArticleStatus(
  //         status.find((x: any) => x.id === article.article_status_id).name
  //       );
  //     }
  //   } else if (orderType === "normal") {
  //     setArticleStatus(order && order.products[0].status.name);
  //   }
  // }, []);

  const generateMsg = (prod: any) => {
    switch (prod.status.name) {
      case "PROTO":
        return {
          color: "#BCBCBD",
          msg: "Your prototype is being prepared",
        };
      case "PRODUCTION":
      case "OK PROD":
        return { color: "#06C170", msg: "Your order is in process" };
        break;
      case "CANCELLED":
        return { color: "#FF3A3A", msg: "Your order is canceled" };
        break;
      default:
        return {
          color: "#FF872A",
          msg: "We are waiting for your answer",
        };
    }
  };

  //   Status Msg to display
  useEffect(() => {
    if (articleStatus) {
      switch (articleStatus) {
        case "PROTO":
          setStatusMsg({
            color: "#BCBCBD",
            msg: "Your prototype is being prepared",
          });
          break;
        case "PRODUCTION":
        case "OK PROD":
          setStatusMsg({ color: "#06C170", msg: "Your order is in process" });
          break;
        case "CANCELLED":
          setStatusMsg({ color: "#FF3A3A", msg: "Your order is canceled" });
          break;
        default:
          setStatusMsg({
            color: "#FF872A",
            msg: "We are waiting for your answer",
          });
      }
    }
  }, [articleStatus]);

  return orderType === "direct" ? (
    <tr>
      <td scope="row">
        <Jeans />
        <span className="ml-2 myorder_order_name">
          {article.code ? article.code + " " + article.name : article.name}
        </span>
      </td>
      <td>
        {order
          ? order.updated_at
              .slice(0, 10)
              .replace(/[-]/g, "/")
              .split("/")
              .reverse()
              .join("/")
          : article.updated_at
              .slice(0, 10)
              .replace(/[-]/g, "/")
              .split("/")
              .reverse()
              .join("/")}
      </td>
      <td>
        {/* {!order ? (
          <Link to={`/my-orders/${params.id}/${article.id}`}>
            <Button
              style={{
                color: articleStatus === "PROTO" ? "#7C7C7C" : "#00468C",
                height: "50px!important",
                borderRadius: "5px!important",
                background:
                  articleStatus === "PROTO"
                    ? "#E0E0E0"
                    : "rgba(0, 70, 140, 0.2)",
              }}
              className="status_btn_order"
            >
              {articleStatus}
            </Button>
          </Link>
        ) : (
          <p className="mb-0">
            {article.options.length > 1 ? "s" : ""}
          </p>
        )} */}
        {/* <p className="m-0">Direct Order</p> */}
        <p className="mb-0">
          {cancelled
            ? "Direct Order"
            : `${article.options.length} option${
                article.options.length > 1 ? "s" : ""
              }`}
        </p>
      </td>
      <td>
        {order ? (
          // <span style={{ color: statusMsg.color }} className="status_msg">
          //   {statusMsg.msg}
          // </span>
          <Link to={`/my-orders/direct-order/${order.id}/${article.id}`}>
            <Button
              type="ghost"
              className="see_more_order_details_btn"
              style={{ height: "50px !important" }}
            >
              See More
            </Button>
          </Link>
        ) : (
          <div className="canceled_article_btns_container">
            <Button
              className="canceled_article_btn"
              icon={<DeleteOutlined />}
              onClick={() => dispatch(deleteOneArticle(article))}
            >
              Delete
            </Button>
            <Button
              className="canceled_article_btn"
              icon={<UploadOutlined />}
              onClick={() => {
                dispatch(updateArticle({ article_status_id: 1 }, article.id));
                setAlerts([
                  {
                    msg: "Article Restored",
                    type: "success",
                    id: uuidv4(),
                  },
                ]);
              }}
            >
              Restore
            </Button>
          </div>
        )}
      </td>
    </tr>
  ) : (
    <tr>
      <td scope="row">
        <Jeans />
        <span className="ml-2 myorder_order_name">
          {article.code ? article.code + " " + article.name : article.name}
        </span>
      </td>
      <td>
        {order &&
          order.created_at
            .slice(0, 10)
            .replace(/[-]/g, "/")
            .split("/")
            .reverse()
            .join("/")}
      </td>
      <td>
        {order ? (
          <Link to={`/my-orders/original-product/${params.id}/${article.id}`}>
            <Button
              style={{
                color:
                  article && article.status.name === "PROTO"
                    ? "#7C7C7C"
                    : "#00468C",
                height: "50px!important",
                borderRadius: "5px!important",
                background:
                  article && article.status.name === "PROTO"
                    ? "#E0E0E0"
                    : "rgba(0, 70, 140, 0.2)",
              }}
              className="status_btn_order"
            >
              {article && article.status.name}
            </Button>
          </Link>
        ) : (
          <p className="m-0">Customized Product</p>
        )}
      </td>
      <td>
        {order ? (
          <span
            style={{ color: generateMsg(article && article).color }}
            className="status_msg"
          >
            {generateMsg(article && article).msg}
          </span>
        ) : (
          <div className="canceled_article_btns_container">
            <Button className="canceled_article_btn" icon={<DeleteOutlined />}>
              Delete
            </Button>
            <Button
              className="canceled_article_btn"
              icon={<UploadOutlined />}
              onClick={() => {
                let id = orders.map((el: any) =>
                  el.products.map((elem: any) =>
                    elem.id === article.id ? el.id : false
                  )
                );
                OrderServices.changeStatusNormalOrder(
                  id.flat().filter((x: any) => x)[0],
                  article.id,
                  1
                );
              }}
            >
              Restore
            </Button>
          </div>
        )}
      </td>
    </tr>
  );
};
