import { useMutation, useQuery } from "react-query";
import { useParams, useHistory } from "react-router";
import axiosInstance from "../../config/axios-instance";
import proto_illustration from "../../img/Prototype_2.png";
import backArrow from "../../img/backArrow.png";

import {
  Select,
  Spin,
  Steps,
  List,
  Avatar,
  Breadcrumb,
  Button,
  Input,
  Popover,
  Tooltip,
  Popconfirm,
  message,
  Modal,
  Badge,
  Tag,
  Drawer,
  Collapse,
  DatePicker,
} from "antd";
import {DownloadOutlined, LoadingOutlined} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faImage } from "@fortawesome/free-solid-svg-icons";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import { UserOutlined } from "@ant-design/icons";
import { history } from "../..";
import useWindowWidth from "../../config/useWindowWidth";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  DatabaseOutlined,
  EyeOutlined,
  CheckSquareOutlined,
  FileImageOutlined
} from "@ant-design/icons";
import logo360 from "../../img/360.png";

//@ts-ignore
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import LightBoxImage from "./LightBoxImage";
import { CancelOrderDrawer } from "../MyOrderConfirmation/CancelOrderDrawer";
import {
  approveOptionAndComment,
  rejectOrAcceptProto,
} from "../../actions/order-actions/actions";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { FileLightBox } from "../OrderDetails/FileLightBox";
const { Step } = Steps;
const { Option } = Select;
const OrderProgress = () => {
  const params = useParams<any>();
  const routerHistory = useHistory();
  const [status, setStatus] = useState("");
  const [qty, setQty] = useState("");
  const [quantity, setQuantity] = useState("");

  const [size_qty, setSizeQty] = useState("");
  const [confirmstep, setConfirmStepDisable] = useState(false);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [isEditableFabric, setIsEditableFabric] = useState(false);
  const [detailsFabric, setDetailsFabric] = useState("");
  const [isEditableStyle, setIsEditableStyle] = useState(false);
  const [detailsStyle, setDetailsStyle] = useState("");
  const [is360, set360] = useState(false);

  const [width2, setWidth] = useState(window.innerWidth);


  const toggleEditFabric = () => {
    setIsEditableFabric((prevIsEditable) => !prevIsEditable);
  };

  const setDetaisFabric = (e: any) => {
    setDetailsFabric(e.target.value);
  };

  const toggleEditStyle = () => {
    setIsEditableStyle((prevIsEditable) => !prevIsEditable);
  };

  const setDetaisStyle = (e: any) => {
    setDetailsStyle(e.target.value);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showDrawer2 = () => {
    setOpen2(true);
  };

  const handleCancel = () => {
    setOpen2(false);
  };


  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width2]);
  const fetchOptions = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/article_options/${params.option_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  const fetchArticle = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/order_articles/${params.article_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  const fetchDirectOrder = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/direct_orders/${params.order_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const downloadZipFileFromLaravel = (type: any) => {
    axiosInstance({
      url: `/download-file-option-order/${params.option_id}/${type}`,
      method: "GET",

      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.zip"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
  const {
    isLoading: loadingOrder,
    data: direct_order,
    refetch,
  } = useQuery("single_order", () => fetchDirectOrder(), {
    keepPreviousData: true,
  });
  const { isLoading: loadingArticle, data: article } = useQuery(
    "article",
    () => fetchArticle(),
    {
      keepPreviousData: true,
    }
  );

  const { isLoading, data: option } = useQuery<any>(
    "option",
    () => fetchOptions(),
    {
      keepPreviousData: true,
    }
  );

  const selectHandleChange = (e: any) => {
    setStatus(e);
  };

  /*   const edit = () => {
    console.log("test");
  }; */

  const ExportPDF = (type: any) => {
    axiosInstance({
      url: `/generate-pdf/direct-order/${params.order_id}/${params.article_id}/${params.option_id}`,
      method: "GET",

      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.zip"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const [isOpened, setIsOpened] = useState(false);
  function edit() {
    setIsOpened((wasOpened) => !wasOpened);
  }

  const [proto, setProto] = useState("");
  const [comment, setComment] = useState("");

  const [startDate, setStartDate] = useState("");
  const [shippingDate, setShippingDate] = useState("");
  const [datetirmswearhouse, setTirmswearhouse] = useState("");
  const [datefabricwearhouse, setFabricwearhouse] = useState("");
  const [deliverydate, setDeliveryDate] = useState("");
  const [selectdStep, setSelectdStep] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [matrix, setmatrix] = useState([]);
  function handleChangeProto(e: any) {
    setProto(e.target.value);
  }

  function handleChangeStartDate(e: any) {
    setStartDate(e.target.value);
  }
  function handleChangeShippingDate(e: any) {
    setShippingDate(e.target.value);
  }

  function handleChangeDateTirmswearhouse(e: any) {
    setTirmswearhouse(e.target.value);
  }

  function handleChangeDateFabricwearhouse(e: any) {
    setFabricwearhouse(e.target.value);
  }
  function handleChangeDeliveryDate(e: any) {
    setDeliveryDate(e.target.value);
  }

  const showCancelDrawer = () => {
    setIsVisible(true);
  };
  const {
    mutateAsync: approveOptAndComment,
    isLoading: approvingAndCommentLoading,
  } = useMutation(approveOptionAndComment);
  const {
    mutateAsync: rejectOrAcceptPrototype,
    isLoading: rejectOrAcceptProtoLoading,
  } = useMutation(rejectOrAcceptProto, {
    onSuccess: (res) => {
      MyNotif(`Option ${res}ed and comments added successfully`, "success");
      refetch();
    },
  });
  const valider = async (status: any) => {
    let token = localStorage.getItem("token");
    console.log(proto);

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/article_options/admin/${params.option_id}`,
      data: {
        status_id: status,
        proto: proto,
        start_production_date: startDate,
        shipping_date: shippingDate,
        date_trims_wearhouse: datetirmswearhouse,
        date_fabric_wearhouse: datefabricwearhouse,
        delivery_date: deliverydate,
        quantity: quantity,
        // size_qty: size_qty,
        size_qty: inputs,
        comment: comment,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
    return "ok";
  };
  const confirmStep = async (id: any) => {
    let token = localStorage.getItem("token");
    setConfirmStepDisable(true);

    await axiosInstance({
      method: "put",
      url: `/article_options/confirmstep/${id}`,
      data: {
        confirm: 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(function (response) {
      MyNotif(`Confirmed `, "success");
      setConfirmStepDisable(false);

      //@ts-ignore

      refetch();
    });
    window.location.reload();
    return "ok";
  };
  const next = async (status: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/article_options/next/${params.option_id}`,
      data: {
        status_id: status,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
    return "ok";
  };

  const approve = async (id: any, status_id: any) => {
    let token = localStorage.getItem("token");

    const { data: responseSave } = await axiosInstance({
      method: "put",
      url: `/article_options/admin/${params.option_id}`,
      data: {
        status_id: status_id,
        proto: proto,
        start_production_date: startDate,
        shipping_date: shippingDate,
        date_trims_wearhouse: datetirmswearhouse,
        date_fabric_wearhouse: datefabricwearhouse,
        delivery_date: deliverydate,
        qty: quantity,

        // size_qty: size_qty,
        size_qty: inputs,
        comment: comment,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data: response } = await axiosInstance({
      method: "post",
      url: `/article_options/addCommentApprove/${params.option_id}`,
      data: {
        client_id: id,

        status_id: status_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(status_id);

    window.location.reload();
    return "ok";
  };
  const onChange = (value: any) => {
    console.log(`selected ${value}`);
    setSelectdStep(value);
    showConfirm(value);
  };
  const { confirm } = Modal;
  function showConfirm(value: any) {
    console.log(value);
    confirm({
      title: "You are moving to the selected step, Proceed... ?",
      onOk() {
        //  approve(direct_order?.data.client.id, option.data.status.id);
        next(option.data.status.id + 1);
      },
      onCancel() {
        console.log("Cancel");
        setSelectdStep("1");
      },
    });
  }

  const onSearch = (value: any) => {
    console.log("search:", value);
  };

  const notApprovedOption = async (feedback: string = "") => {
    console.log(`Option ${option?.data.name} is not approved!!`);
    var option_id = option?.data.id;
    if (!feedback) {
      approveOptAndComment({
        article_option_id: option_id,
        comment: `Not Approved: ${comment}`,
        status_id: 6,
      });
    } else {
      await approveOptAndComment({
        article_option_id: option_id,
        comment: `Not Approved: ${comment}`,
        status_id: 6,
        feedback,
      });
    }
    await rejectOrAcceptPrototype({
      response: "reject",
      id: +option_id,
      comment: `Not Approved: ${comment}`,
    });

    setIsVisible(false);
    setComment("");
    setTimeout(() => {}, 2000);
  };
  const [step, setSetp] = useState(option?.data.status.pivot.name);
  const [inputs, setInputs] = useState(["", "", ""]);
  // const arr = ["", "", ""];

  useEffect(() => {
    if (option) {
      const initialInputs = [];
      var array = JSON.parse(option?.data.status.pivot.size_qty);
      if (array?.length > 0) {
        for (let i = 0; i < array?.length; i += 3) {
          initialInputs.push(...array?.slice(i, i + 3));
        }
        setInputs(initialInputs);
      }
    }
    showMatrix(option?.data.status.pivot.status_id);
    setSetp(option?.data.status.pivot.status_id);
  }, [option]);

  const handleAddInput = () => {
    const newInputs = [...inputs, "", "", ""];
    setInputs(newInputs);
  };

  const handleInputChange = (event: any, index: any) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
  };

  const handleRemoveInput = (index: any) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 3);
    setInputs(newInputs);
  };

  const showMatrix = (id: any) => {
    // console.log(inputs);
    let found = false;
    option?.data.allStatus.map((el: any) => {
      // Calculate the number of rows

      if (el.pivot?.size_qty && el.id == id) {
        const length = JSON.parse(el.pivot?.size_qty).length;
        const rows = Math.ceil(length / 3);
        // Create a new matrix from the input values
        const newMatrix = [];
        let count = 0;

        for (let i = 0; i < rows; i++) {
          const row = [];
          for (let j = 0; j < 3; j++) {
            row.push(JSON.parse(el.pivot?.size_qty)[count] || "");
            count++;
          }
          newMatrix.push(row);
        }

        // Update the matrix state
        //@ts-ignore
        setmatrix(newMatrix);
        found = true;
      }
      if (!found) {
        // Reset matrix state
        setmatrix([]);
      }
    });
  };

  const complete = async (id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/article_options/complete/${option?.data.id}`,
      data: {
        status_id: id,
        completed: "complete",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
    return "ok";
  };

  const resotre = async (id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/article_options/restore/${option?.data.id}`,
      data: {
        status_id: id,
        completed: null,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
    return "ok";
  };

  const saveDetails = async (status_id: any) => {
    let token = localStorage.getItem("token");

    console.log(status_id);

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/article_options/savedetails/${option?.data.id}`,
      data: {
        status_id: status_id,
        fabric_details: detailsFabric,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.reload();
    return "ok";
  };

  const saveDetailsStyle = async (status_id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/article_options/savedetailsstyle/${option?.data.id}`,
      data: {
        status_id: status_id,
        style_details: detailsStyle,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.reload();
    return "ok";
  };

  const width = useWindowWidth();
  const stepName = ["PROTO", "PRE SMS", "SMS", "OK PROD", "PRODUCTION"];
  return isLoading ? (
    <div
      style={{ width: "100%", minHeight: "100vh" }}
      className="d-flex justify-content-center align-items-center">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <div className="row myorder_confirmation" style={{ minHeight: "100vh" }}>
      <div
          className="col-12 col-md-5 p-0  align-items-center"
          style={{background: "#fcfdff", height: "100%"}}>

        {option.data?.status.pivot.proto ?
            <>
          <iframe
              src={
                option.data?.status.pivot.proto

              }
              style={{
                width: "100%",
                height: "calc(100vh - 227px)",
                border: "none",
              }}
          />
        </> :
            <>
              <div style={{
                height: "calc(100vh - 227px)",
                border: "none",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                justifyContent: "center",
                flexDirection:"column"
              }}>

                <img src={proto_illustration} alt="proto_illustration" style={{

                  border: "none",
                }}></img>
                <span style={{textAlign: "center", marginTop: "20px"}}>Your product is being prepared</span>

              </div>
            </>
        }


        <div
            className="row mt-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
          {" "}

          <a
              href={
                  process.env.REACT_APP_API_URL +
                  `/generate-pdf/direct-order/${params.order_id}/${params.article_id}/${params.option_id}`
              }
              className="exportPDF2">
            Export tech pack <DownloadOutlined style={{fontSize: "16px"}}/>
            {" "}
          </a>
        </div>
      </div>
      {/* <div
          className="col-12 col-md-5 p-0  align-items-center"
          style={{ background: "#fcfdff", height: "100%" }}>
          <div>
            {option?.data.is_client_product == 1 && is360 == false ? (
              <iframe
                src={
                  option?.data.status.pivot.proto
                    ? option?.data.status.pivot.proto
                    : proto_illustration
                }
                style={{
                  width: "100%",
                  height: "calc(100vh - 227px)",
                  border: "none",
                }}
              />
            ) : (
              
              <>
               {option?.data.is_client_product == 1 &&  (
                 <div className="product_images_wrapper ">
                 <div
                   className="product_images"
                   style={{ position: "relative" }}>
                   <InnerImageZoom
                     src={
                       process.env.REACT_APP_STORAGE_URL +
                       option?.data.front_image_file
                     }
                     zoomSrc={
                       process.env.REACT_APP_STORAGE_URL +
                       option?.data.front_image_file
                     }
                     fullscreenOnMobile={true}
                     className="lazy-load-image-background zoomed_img"
                     afterZoomIn={() => {
                       document.querySelectorAll(
                         ".lazy-load-image-background"
                       );

                     }}
                     afterZoomOut={() =>
                       document.querySelectorAll(".zoomed_img")
                     }
                   />
                 </div>

                 <div
                   className="product_images"
                   style={{ position: "relative" }}>
                   <InnerImageZoom
                     src={
                       process.env.REACT_APP_STORAGE_URL +
                       option?.data.back_image_file
                     }
                     zoomSrc={
                       process.env.REACT_APP_STORAGE_URL +
                       option?.data.back_image_file
                     }
                     fullscreenOnMobile={true}
                     className="lazy-load-image-background zoomed_img"
                     afterZoomIn={() => {
                       document.querySelectorAll(
                         ".lazy-load-image-background"
                       );

                   
                     }}
                     afterZoomOut={() =>
                       document.querySelectorAll(".zoomed_img")
                     }
                   />
                 </div>
               </div>
               ) }
               
                <br />
              </>
            )}

            {option?.data.is_client_product == 0 && (
              <>
                <iframe
                  src={
                    option?.data.status.pivot.proto
                      ? option?.data.status.pivot.proto
                      : proto_illustration
                  }
                  style={{
                    width: "100%",
                    height: "calc(100vh - 227px)",
                    border: "none",
                  }}
                />{" "}
              </>
            )}
            {option?.data.is_client_product == 1 && (
              <>
                {is360 == false ? (
                  <button className="prototype" onClick={() => set360(true)}>
                    <FileImageOutlined
                      style={{ fontSize: "40px", color: "white" }}
                    />
                  </button>
                ) : (
                  <button className="prototype" onClick={() => set360(false)}>
                    <img src={logo360} alt="360 degree" width="60" />
                  </button>
                )}
              </>
            )}
          </div>
          <div
            className="row "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {" "}
            <a
              href={
                process.env.REACT_APP_API_URL +
                `/generate-pdf/direct-order/${params.order_id}/${params.article_id}/${params.option_id}`
              }
              style={{ color: "white" }}>
              <button className="exportPDF">Export tech pack</button>{" "}
            </a>{" "}
          </div>
        </div> */}
      <div className="col-12 col-md-7 proto_container pl-3">
        <div
          className=" m-4"
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}>
          <div className="proto_header">
            <img
              src={backArrow}
              className="back_arrow_folder"
              alt="back arrow"
              onClick={() => history.back()}
            />
            <h1>Orders</h1>
          </div>
          <div>
            <div className="row">
              <div>
                {" "}
                <Avatar.Group
                  maxCount={5}
                  size="large"
                  maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
                  {" "}
                  {direct_order?.data.collaborators.map((colab: any) => (
                    <Tooltip
                      title={colab.name}
                      placement="bottom"
                      key={colab.id}>
                      {" "}
                      <Avatar
                        src={process.env.REACT_APP_STORAGE_URL + colab.logo}
                      />{" "}
                    </Tooltip>
                  ))}{" "}
                </Avatar.Group>{" "}
              </div>
              <Tooltip
                placement="bottom"
                title={isOpened == false ? "Update" : "View"}>
                <Button
                  type="ghost"
                  shape="circle"
                  size="large"
                  className="update_product_btn"
                  icon={
                    isOpened == false ? (
                      <EditOutlined />
                    ) : (
                      <EyeOutlined></EyeOutlined>
                    )
                  }
                  onClick={edit}
                />
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="proto_breadcrumb_container my-4">
          {direct_order && article && (
            // <p className="proto_breadcrumb">{`${direct_order.data.client.name} / ${article.data.name}`}</p>
            <>
              <div className="row">
                <div className="col-10 action-comment" >
                  <Breadcrumb className="mb-2">
                    <Breadcrumb.Item>
                      <span>
                        <UserOutlined style={{ verticalAlign: "0.7px" }} />{" "}
                        {direct_order.data.client.name}
                      </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span>{direct_order.data.name}</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => history.back()}>
                        {article.data.name}
                      </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span
                        style={{
                          fontWeight: 600,
                          color: "#00468c",
                        }}>
                        {option && option.data.name.toUpperCase()}{" "}
                      </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  {direct_order?.data.season && (
                    <div style={{ marginBottom: "16px" }}>
                      <Tag
                        style={{
                          borderRadius: "15px",
                          padding: "5px 9px 5px 9px",
                        }}
                        color="#182844">
                        {direct_order?.data.season}
                      </Tag>
                    </div>
                  )}
                </div>
                <div className="col-2">
                  {" "}
                  {option?.data.status.pivot.proto_confirmation ===
                    "ACCEPTED" &&
                  option?.data.status.pivot.step === "complete" ? (
                    <>
                      {/*  <div className="row">
                        <Tag
                          icon={<CheckOutlined />}
                          style={{
                            borderRadius: "15px",
                            padding: "5px 9px 5px 9px",
                          }}
                          color="#039855"
                        >
                          Completed
                        </Tag>
                      </div> */}
                    </>
                  ) : (
                    option?.data.status.pivot.proto_confirmation ===
                      "ACCEPTED" && (
                      <Tag
                        icon={<CheckOutlined />}
                        style={{
                          borderRadius: "15px",
                          padding: "5px 9px 5px 9px",
                        }}
                        color="#A5AB36">
                        Approved
                      </Tag>
                    )
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        {option.data.status.id !== 6 && (
          <div className="steps_progress mt-4">
            {/*    <Steps
              current={option.data.status.id - 1}
              labelPlacement={"vertical"}
            >
              <Step title="PROTO" />
              <Step title="PRE SMS" />
              <Step title="SMS" />
              <Step title="OK PROD" />
              <Step title="PRODUCTION" />
            </Steps> */}
            <div>
              <Steps
                current={option.data.status.id - 1}
                labelPlacement={"vertical"}>
                <Step
                  title="PROTO"
                  onClick={() => setSetp(1)}
                  style={{
                    cursor:
                      option?.data.status.id >= 1 && isOpened == false
                        ? "pointer"
                        : "",
                  }}
                  description={
                    option?.data.status.id >= 1 && isOpened == false
                      ? "View History"
                      : ""
                  }
                />
                <Step
                  title="PRE SMS"
                  onClick={() => {
                    setSetp(2);
                    showMatrix(2);
                  }}
                  style={{
                    cursor:
                      option?.data.status.id >= 2 && isOpened == false
                        ? "pointer"
                        : "",
                  }}
                  description={
                    option?.data.status.id >= 2 && isOpened == false
                      ? "View History"
                      : ""
                  }
                />
                <Step
                  title="SMS"
                  onClick={() => {
                    setSetp(3);
                    showMatrix(3);
                  }}
                  style={{
                    cursor:
                      option?.data.status.id >= 3 && isOpened == false
                        ? "pointer"
                        : "",
                  }}
                  description={
                    option?.data.status.id >= 3 && isOpened == false
                      ? "View History"
                      : ""
                  }
                />
                <Step
                  title="OK PROD"
                  onClick={() => {
                    setSetp(4);
                    showMatrix(4);
                  }}
                  style={{
                    cursor:
                      option?.data.status.id >= 4 && isOpened == false
                        ? "pointer"
                        : "",
                  }}
                  description={
                    option?.data.status.id >= 4 && isOpened == false
                      ? "View History"
                      : ""
                  }
                />
                <Step
                  title="PRODUCTION"
                  style={{
                    cursor:
                      option?.data.status.id >= 5 && isOpened == false
                        ? "pointer"
                        : "",
                  }}
                  description={
                    option?.data.status.id >= 5 && isOpened == false
                      ? "View History"
                      : ""
                  }
                  onClick={() => {
                    setSetp(5);
                    showMatrix(5);
                  }}
                />
              </Steps>
            </div>
            {isOpened == false ? (
              <>
                {" "}
                <div className="proto_footer mt-5">
                  {option.data.allStatus
                    .filter((el: any) => el.id !== 5)
                    .map((el: any) => {
                      return (
                        <>
                          {el.id == step && el.id != 1 ? (
                            <>
                              <div>
                                {option.data.status.id !== 5  ? (
                                  <>
                                    {el.pivot.proto_confirmation ===
                                    "ACCEPTED" ? (
                                      <table
                                        style={{
                                          width: "100%",
                                          marginBottom: "25px",
                                        }}
                                        className={"table-progress"}
                                      >
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span
                                              style={{ fontWeight: "bold" }}>
                                              Fabric{" "}
                                            </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <div
                                              style={{
                                                justifyContent: "space-between",
                                                display: "flex",
                                              }}>
                                              <span
                                                style={{ fontWeight: "bold" }}>
                                                {" "}
                                                Details{" "}
                                              </span>
                                              <button
                                                disabled={isEditableFabric}
                                                className={
                                                  isEditableFabric
                                                    ? " add_details_disabled"
                                                    : "add_details"
                                                }
                                                onClick={toggleEditFabric}>
                                                <PlusOutlined></PlusOutlined>
                                              </button>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span>{option?.data.fabric} </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            {isEditableFabric ? (
                                              <>
                                                <div>
                                                  <textarea
                                                    style={{ width: "100%" }}
                                                    rows={3}
                                                    className="textarea_content"
                                                    defaultValue={
                                                      el.pivot.details_fabric
                                                    }
                                                    onChange={(e) =>
                                                      setDetaisFabric(e)
                                                    }
                                                  />
                                                  <div
                                                    className="row"
                                                    style={{ float: "right" }}>
                                                    <button
                                                      onClick={() => {
                                                        setIsEditableFabric(
                                                          false
                                                        );
                                                      }}
                                                      className="check_details"
                                                      style={{
                                                        marginRight: "10px",
                                                      }}>
                                                      <CloseOutlined
                                                        style={{
                                                          color: "#f37b7b",
                                                        }}></CloseOutlined>
                                                    </button>
                                                    <button
                                                      onClick={() => {
                                                        saveDetails(el.id);
                                                        setIsEditableFabric(
                                                          false
                                                        );
                                                      }}
                                                      className="check_details">
                                                      <CheckOutlined
                                                        style={{
                                                          color: "#00BA6C",
                                                        }}></CheckOutlined>
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div
                                                  style={{
                                                    whiteSpace: "pre-line",
                                                  }}>
                                                  {el.pivot.details_fabric}
                                                </div>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span
                                              style={{ fontWeight: "bold" }}>
                                              Style{" "}
                                            </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <div
                                              style={{
                                                justifyContent: "space-between",
                                                display: "flex",
                                              }}>
                                              <span
                                                style={{ fontWeight: "bold" }}>
                                                {" "}
                                                Details{" "}
                                              </span>
                                              <button
                                                disabled={isEditableStyle}
                                                className={
                                                  isEditableStyle
                                                    ? " add_details_disabled"
                                                    : "add_details"
                                                }
                                                onClick={toggleEditStyle}>
                                                <PlusOutlined></PlusOutlined>
                                              </button>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span>{option?.data.style} </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            {isEditableStyle ? (
                                              <>
                                                <div>
                                                  <textarea
                                                    style={{ width: "100%" }}
                                                    rows={3}
                                                    className="textarea_content"
                                                    defaultValue={
                                                      el.pivot.details_style
                                                    }
                                                    onChange={(e) =>
                                                      setDetaisStyle(e)
                                                    }
                                                  />
                                                  <div
                                                    className="row"
                                                    style={{ float: "right" }}>
                                                    <button
                                                      onClick={() => {
                                                        setIsEditableStyle(
                                                          false
                                                        );
                                                      }}
                                                      className="check_details"
                                                      style={{
                                                        marginRight: "10px",
                                                      }}>
                                                      <CloseOutlined
                                                        style={{
                                                          color: "#f37b7b",
                                                        }}></CloseOutlined>
                                                    </button>
                                                    <button
                                                      onClick={() => {
                                                        saveDetailsStyle(el.id);
                                                        setIsEditableStyle(
                                                          false
                                                        );
                                                      }}
                                                      className="check_details">
                                                      <CheckOutlined
                                                        style={{
                                                          color: "#00BA6C",
                                                        }}></CheckOutlined>
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div
                                                  style={{
                                                    whiteSpace: "pre-line",
                                                  }}>
                                                  {el.pivot.details_style}
                                                </div>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      </table>
                                    ) : (
                                      <table
                                        style={{
                                          width: "100%",
                                          marginBottom: "25px",
                                        }}
                                        className={"table-progress"}
                                      >
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span
                                              style={{ fontWeight: "bold" }}>
                                              Fabric{" "}
                                            </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <div
                                              style={{
                                                justifyContent: "space-between",
                                                display: "flex",
                                              }}>
                                              <span
                                                style={{ fontWeight: "bold" }}>
                                                {" "}
                                                Details{" "}
                                              </span>
                                              <button
                                                disabled={isEditableFabric}
                                                className={
                                                  isEditableFabric
                                                    ? " add_details_disabled"
                                                    : "add_details"
                                                }
                                                onClick={toggleEditFabric}>
                                                <PlusOutlined></PlusOutlined>
                                              </button>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span>{option?.data.fabric} </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            {isEditableFabric ? (
                                              <>
                                                <div>
                                                  <textarea
                                                    style={{ width: "100%" }}
                                                    rows={3}
                                                    className="textarea_content"
                                                    defaultValue={
                                                      option?.data?.status
                                                        ?.pivot?.details_fabric
                                                    }
                                                    onChange={(e) =>
                                                      setDetaisFabric(e)
                                                    }
                                                  />
                                                  <div
                                                    className="row"
                                                    style={{ float: "right" }}>
                                                    <button
                                                      onClick={() => {
                                                        setIsEditableFabric(
                                                          false
                                                        );
                                                      }}
                                                      className="check_details"
                                                      style={{
                                                        marginRight: "10px",
                                                      }}>
                                                      <CloseOutlined
                                                        style={{
                                                          color: "#f37b7b",
                                                        }}></CloseOutlined>
                                                    </button>
                                                    <button
                                                      onClick={() => {
                                                        saveDetails(
                                                          option?.data?.status
                                                            ?.id
                                                        );
                                                        setIsEditableFabric(
                                                          false
                                                        );
                                                      }}
                                                      className="check_details">
                                                      <CheckOutlined
                                                        style={{
                                                          color: "#00BA6C",
                                                        }}></CheckOutlined>
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div
                                                  style={{
                                                    whiteSpace: "pre-line",
                                                  }}>
                                                  {
                                                    option?.data?.status?.pivot
                                                      ?.details_fabric
                                                  }
                                                </div>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span
                                              style={{ fontWeight: "bold" }}>
                                              Style{" "}
                                            </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <div
                                              style={{
                                                justifyContent: "space-between",
                                                display: "flex",
                                              }}>
                                              <span
                                                style={{ fontWeight: "bold" }}>
                                                {" "}
                                                Details{" "}
                                              </span>
                                              <button
                                                disabled={isEditableStyle}
                                                className={
                                                  isEditableStyle
                                                    ? " add_details_disabled"
                                                    : "add_details"
                                                }
                                                onClick={toggleEditStyle}>
                                                <PlusOutlined></PlusOutlined>
                                              </button>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            <span>{option?.data.style} </span>{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid #CCCCCC",
                                              padding: "9px",
                                              width: "50%",
                                            }}>
                                            {isEditableStyle ? (
                                              <>
                                                <div>
                                                  <textarea
                                                    style={{ width: "100%" }}
                                                    rows={3}
                                                    className="textarea_content"
                                                    defaultValue={
                                                      option?.data?.status
                                                        ?.pivot?.details_style
                                                    }
                                                    onChange={(e) =>
                                                      setDetaisStyle(e)
                                                    }
                                                  />
                                                  <div
                                                    className="row"
                                                    style={{ float: "right" }}>
                                                    <button
                                                      onClick={() => {
                                                        setIsEditableStyle(
                                                          false
                                                        );
                                                      }}
                                                      className="check_details"
                                                      style={{
                                                        marginRight: "10px",
                                                      }}>
                                                      <CloseOutlined
                                                        style={{
                                                          color: "#f37b7b",
                                                        }}></CloseOutlined>
                                                    </button>
                                                    <button
                                                      onClick={() => {
                                                        saveDetailsStyle(
                                                          option?.data?.status
                                                            ?.id
                                                        );
                                                        setIsEditableStyle(
                                                          false
                                                        );
                                                      }}
                                                      className="check_details">
                                                      <CheckOutlined
                                                        style={{
                                                          color: "#00BA6C",
                                                        }}></CheckOutlined>
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div
                                                  style={{
                                                    whiteSpace: "pre-line",
                                                  }}>
                                                  {
                                                    option?.data?.status?.pivot
                                                      ?.details_style
                                                  }
                                                </div>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      </table>
                                    )}
                                    <div style={{ marginTop: "16px" }}>
                                      Size {el.name}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "16px",
                                      }}>
                                      {" "}
                                      {matrix.length > 0 ? (
                                        <div>
                                          <table style={{ width: "100%" }}  className={"table-progress"}>
                                            <th
                                              style={{
                                                border: "1px solid #CCCCCC",
                                                padding: "9px",
                                              }}>
                                              Size
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid #CCCCCC",
                                                padding: "9px",
                                              }}>
                                              Length
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid #CCCCCC",
                                                padding: "9px",
                                              }}>
                                              Qty
                                            </th>
                                            <tbody>
                                              {matrix.map(
                                                (row: any, rowIndex: any) => (
                                                  <tr
                                                    key={rowIndex}
                                                    style={{
                                                      border:
                                                        "1px solid #CCCCCC",
                                                    }}>
                                                    {row.map(
                                                      (
                                                        cell: any,
                                                        cellIndex: any
                                                      ) => (
                                                        <td
                                                          key={cellIndex}
                                                          style={{
                                                            border:
                                                              "1px solid #CCCCCC",
                                                            padding: "9px",
                                                          }}>
                                                          {cell}
                                                        </td>
                                                      )
                                                    )}
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <div className="p-3">-</div>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                  
                                   {/*  <div
                                      style={{
                                        marginTop: "16px",
                                        color: "#00468C",
                                        marginRight: "9px",
                                        fontWeight: "bold",
                                        marginBottom: "10px",
                                      }}>
                                      Quantity {el.name} :{" "}
                                      <span
                                        style={{
                                          marginTop: "16px",
                                          color: "black",
                                          marginRight: "9px",
                                          fontWeight: "normal",
                                        }}>
                                        {el.pivot.qty}
                                      </span>{" "}
                                    </div> */}
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}

                  {/*       {step === 5 && (
                    <>
                      {option?.data && (
                        <div>
                          <table style={{ width: "100%", marginBottom:"25px" }}>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Fabric{" "}
                                </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  Details{" "}
                                </span>{" "}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span>{option?.data.fabric} </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span> - </span>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Style{" "}
                                </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  Details{" "}
                                </span>{" "}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span>{option?.data.style} </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span> - </span>{" "}
                              </td>
                            </tr>
                          </table>
                        </div>
                      )}
                    </>
                  )} */}

                  {/*    */}
                  {step == 1 && (
                    <>
                      {option?.data && (
                        <div>
                          <table
                            style={{ width: "100%", marginBottom: "25px" }}  className={"table-progress"}>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Fabric{" "}
                                </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <div
                                  style={{
                                    justifyContent: "space-between",
                                    display: "flex",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Details{" "}
                                  </span>
                                  <button
                                    disabled={isEditableFabric}
                                    className={
                                      isEditableFabric
                                        ? " add_details_disabled"
                                        : "add_details"
                                    }
                                    onClick={toggleEditFabric}>
                                    <PlusOutlined></PlusOutlined>
                                  </button>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span>{option?.data.fabric} </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                {isEditableFabric ? (
                                  <>
                                    <div>
                                      <textarea
                                        style={{ width: "100%" }}
                                        rows={3}
                                        className="textarea_content"
                                        defaultValue={
                                          option?.data.allStatus.find(
                                            (o: any) => o.id === 1
                                          ).pivot.details_fabric
                                        }
                                        onChange={(e) => setDetaisFabric(e)}
                                      />
                                      <div
                                        className="row"
                                        style={{ float: "right" }}>
                                        <button
                                          onClick={() => {
                                            setIsEditableFabric(false);
                                          }}
                                          className="check_details"
                                          style={{ marginRight: "10px" }}>
                                          <CloseOutlined
                                            style={{
                                              color: "#f37b7b",
                                            }}></CloseOutlined>
                                        </button>
                                        <button
                                          onClick={() => {
                                            saveDetails(1);
                                            setIsEditableFabric(false);
                                          }}
                                          className="check_details">
                                          <CheckOutlined
                                            style={{
                                              color: "#00BA6C",
                                            }}></CheckOutlined>
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div style={{ whiteSpace: "pre-line" }}>
                                      {
                                        option?.data.allStatus.find(
                                          (o: any) => o.id === 1
                                        ).pivot.details_fabric
                                      }
                                    </div>
                                  </>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Style{" "}
                                </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <div
                                  style={{
                                    justifyContent: "space-between",
                                    display: "flex",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Details{" "}
                                  </span>
                                  <button
                                    disabled={isEditableStyle}
                                    className={
                                      isEditableStyle
                                        ? " add_details_disabled"
                                        : "add_details"
                                    }
                                    onClick={toggleEditStyle}>
                                    <PlusOutlined></PlusOutlined>
                                  </button>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                <span>{option?.data.style} </span>{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #CCCCCC",
                                  padding: "9px",
                                  width: "50%",
                                }}>
                                {isEditableStyle ? (
                                  <>
                                    <div>
                                      <textarea
                                        style={{ width: "100%" }}
                                        rows={3}
                                        className="textarea_content"
                                        defaultValue={
                                          option?.data.allStatus.find(
                                            (o: any) => o.id === 1
                                          ).pivot.details_style
                                        }
                                        onChange={(e) => setDetaisStyle(e)}
                                      />
                                      <div
                                        className="row"
                                        style={{ float: "right" }}>
                                        <button
                                          onClick={() => {
                                            setIsEditableStyle(false);
                                          }}
                                          className="check_details"
                                          style={{ marginRight: "10px" }}>
                                          <CloseOutlined
                                            style={{
                                              color: "#f37b7b",
                                            }}></CloseOutlined>
                                        </button>
                                        <button
                                          onClick={() => {
                                            saveDetailsStyle(1);
                                            setIsEditableStyle(false);
                                          }}
                                          className="check_details">
                                          <CheckOutlined
                                            style={{
                                              color: "#00BA6C",
                                            }}></CheckOutlined>
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div style={{ whiteSpace: "pre-line" }}>
                                      {
                                        option?.data.allStatus.find(
                                          (o: any) => o.id === 1
                                        ).pivot.details_style
                                      }
                                    </div>
                                  </>
                                )}
                              </td>
                            </tr>
                          </table>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "16px",
                          marginTop: "16px",
                        }}>
                        <div
                          style={{
                            color: "#00468C",
                            marginRight: "9px",
                            fontWeight: "bold",
                          }}>
                          Quantity Proto:
                        </div>
                        <div>1</div>
                      </div>
                    </>
                  )}

                  {option.data.allStatus
                    .filter((el: any) => el.id !== 5)
                    .map((el: any) => {
                      {
                        return (
                          <>
                            {el.id == step ? (
                              <>
                                {option.data.status.id == 5 && el.id == step ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "16px",
                                    }}>
                                    <div
                                      style={{
                                        color: "#00468C",
                                        marginRight: "9px",
                                        fontWeight: "bold",
                                      }}>
                                      Actual Delivery Date:
                                    </div>
                                    <div>
                                      {option && el.pivot.start_production_date
                                        ? moment(
                                            el.pivot.start_production_date
                                          ).format("YYYY-MM-DD")
                                        : "-"}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "16px",
                                  }}>
                                  <div
                                    style={{
                                      color: "#00468C",
                                      marginRight: "9px",
                                      fontWeight: "bold",
                                    }}>
                                    Delivery date:
                                  </div>
                                  <div>
                                    {option && el.pivot.delivery_date
                                      ? moment(el.pivot.delivery_date).format(
                                          "YYYY-MM-DD"
                                        )
                                      : "-"}
                                  </div>
                                </div>
                                {option &&
                                  option.data.allComment.length > 0 && (
                                    <>
                                      <div
                                        style={{
                                          color: "#00468C",
                                          marginRight: "9px",
                                          fontWeight: "bold",
                                          marginBottom: "16px",
                                        }}>
                                        Comments:
                                      </div>
                                      {option.data.allComment.map((el: any) => {
                                        return (
                                          <>
                                            {el.status_id == step &&
                                            el.name != "PROTO" ? (
                                              <>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    marginBottom: "16px",
                                                  }}>
                                                  {el.client_id ==
                                                    direct_order?.data.client
                                                      .id &&
                                                  el.commercial_id == null ? (
                                                    <>
                                                      <Avatar
                                                        src={
                                                          process.env
                                                            .REACT_APP_STORAGE_URL +
                                                          direct_order?.data
                                                            .client.logo
                                                        }
                                                        size="default"
                                                        style={{
                                                          marginRight: "16px",
                                                        }}
                                                      />{" "}
                                                      <div
                                                        style={{
                                                          backgroundColor:
                                                            "#F8FAFC",
                                                          borderRadius: "5px",
                                                          border:
                                                            "1px solid #CDD5DF",
                                                          width: "100%",
                                                          padding: "16px",
                                                        }}>
                                                        <div
                                                          className="row"
                                                          style={{
                                                            justifyContent:
                                                              "space-between",
                                                          }}>
                                                          <div
                                                            style={{
                                                              lineHeight:
                                                                "24px",
                                                              fontWeight:
                                                                "bold",
                                                            }}>
                                                            {
                                                              direct_order?.data
                                                                .client.name
                                                            }
                                                          </div>
                                                          <div>
                                                            <p>
                                                              {moment(
                                                                el.created_at
                                                              ).format(
                                                                "DD-MM-YYYY HH:mm"
                                                              )}{" "}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div>{el.comment}</div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Avatar
                                                        src={
                                                          process.env
                                                            .REACT_APP_STORAGE_URL +
                                                          el?.commercial?.avatar
                                                        }
                                                        size="default"
                                                        style={{
                                                          marginRight: "16px",
                                                        }}
                                                      />{" "}
                                                      <div
                                                        style={{
                                                          backgroundColor:
                                                            "#F8FAFC",
                                                          borderRadius: "5px",
                                                          border:
                                                            "1px solid #CDD5DF",
                                                          width: "100%",
                                                          padding: "16px",
                                                        }}>
                                                        <div
                                                          className="row"
                                                          style={{
                                                            justifyContent:
                                                              "space-between",
                                                          }}>
                                                          <div
                                                            style={{
                                                              lineHeight:
                                                                "24px",
                                                              fontWeight:
                                                                "bold",
                                                            }}>
                                                            {
                                                              el.commercial
                                                                ?.name
                                                            }
                                                          </div>
                                                          <div>
                                                            <p>
                                                              {moment(
                                                                el.created_at
                                                              ).format(
                                                                "DD-MM-YYYY HH:mm"
                                                              )}{" "}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div>{el.comment}</div>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      }
                    })}

                  {step === 5 && (
                    <>
                      <div
                        style={{
                          marginTop: "-50px",
                          marginBottom: "16px",
                        }}>
                        <table style={{ width: "100%", marginBottom: "25px" }}  className={"table-progress"}>
                          <tr>
                            <td
                              style={{
                                border: "1px solid #CCCCCC",
                                padding: "9px",
                                width: "50%",
                              }}>
                              <span style={{ fontWeight: "bold" }}>
                                Fabric{" "}
                              </span>{" "}
                            </td>
                            <td
                              style={{
                                border: "1px solid #CCCCCC",
                                padding: "9px",
                                width: "50%",
                              }}>
                              <span style={{ fontWeight: "bold" }}>
                                Details{" "}
                              </span>{" "}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                border: "1px solid #CCCCCC",
                                padding: "9px",
                              }}>
                              {option?.data.fabric}
                            </td>
                            <td
                              style={{
                                border: "1px solid #CCCCCC",
                                padding: "9px",
                              }}>
                              {option.data.allStatus
                                .filter((el: any) => el.status_id !== 5)
                                .map((el: any) => {
                                  return (
                                    <>
                                      <ul id="myUL2">
                                        <li
                                          style={{
                                            whiteSpace: "pre-line",
                                          }}>
                                          {el.pivot.details_fabric ?? "-"}
                                        </li>
                                      </ul>
                                    </>
                                  );
                                })}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                border: "1px solid #CCCCCC",
                                padding: "9px",
                              }}>
                              <span style={{ fontWeight: "bold" }}>Style </span>{" "}
                            </td>
                            <td
                              style={{
                                border: "1px solid #CCCCCC",
                                padding: "9px",
                              }}>
                              <span style={{ fontWeight: "bold" }}>
                                Details{" "}
                              </span>{" "}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                border: "1px solid #CCCCCC",
                                padding: "9px",
                              }}>
                              {option?.data.fabric}
                            </td>
                            <td
                              style={{
                                border: "1px solid #CCCCCC",
                                padding: "9px",
                              }}>
                              {option.data.allStatus
                                .filter((el: any) => el.status_id !== 5)
                                .map((el: any) => {
                                  return (
                                    <>
                                      <ul id="myUL2">
                                        <li
                                          style={{
                                            whiteSpace: "pre-line",
                                          }}>
                                          {el.pivot.details_style ?? "-"}
                                        </li>
                                      </ul>
                                    </>
                                  );
                                })}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <table style={{ width: "100%", marginBottom: "25px" }}  className={"table-progress"}>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #CCCCCC",
                              padding: "9px",
                              width: "25%",
                              fontWeight: "bold",
                            }}>
                            Production
                          </td>{" "}
                          <td
                            style={{
                              border: "1px solid #CCCCCC",
                              padding: "9px",
                              width: "25%",
                              fontWeight: "bold",
                            }}>
                            Delivery Date
                          </td>{" "}
                          <td
                            style={{
                              border: "1px solid #CCCCCC",
                              padding: "9px",
                              width: "25%",
                              fontWeight: "bold",
                            }}>
                            Actual Delivery Date
                          </td>{" "}
                          <td
                            style={{
                              border: "1px solid #CCCCCC",
                              padding: "9px",
                              width: "25%",
                              fontWeight: "bold",
                            }}>
                            Quantity
                          </td>{" "}
                        </tr>
                        {option?.data.allStatus
                          ?.filter((el: any) => el.id === 5)
                          .map((el: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                    }}>
                                    {option?.data.allStatus?.filter(
                                      (el: any) => el.id === 5
                                    ).length - index}
                                  </td>{" "}
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                    }}>
                                    {moment(el.pivot.delivery_date).format(
                                      "DD-MM-YYYY "
                                    )}
                                  </td>{" "}
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                    }}>
                                    {moment(
                                      el.pivot.start_production_date
                                    ).format("DD-MM-YYYY")}
                                  </td>{" "}
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                    }}>
                                    {el.pivot.qty}
                                  </td>{" "}
                                </tr>
                              </>
                            );
                          })}
                      </table>
                    </>
                  )}
                  {step == 5 && (
                    <>
                      {option && option.data.allComment.length > 0 && (
                        <>
                          <div
                            style={{
                              color: "#00468C",
                              marginRight: "9px",
                              fontWeight: "bold",
                              marginBottom: "16px",
                            }}>
                            Comments:
                          </div>
                          {option.data.allComment.map((el: any) => {
                            return (
                              <>
                                {el.status_id == step && el.name != "PROTO" ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "16px",
                                      }}>
                                      {el.client_id ==
                                        direct_order?.data.client.id &&
                                      el.commercial_id == null ? (
                                        <>
                                          <Avatar
                                            src={
                                              process.env
                                                .REACT_APP_STORAGE_URL +
                                              direct_order?.data.client.logo
                                            }
                                            size="default"
                                            style={{
                                              marginRight: "16px",
                                            }}
                                          />{" "}
                                          <div
                                            style={{
                                              backgroundColor: "#F8FAFC",
                                              borderRadius: "5px",
                                              border: "1px solid #CDD5DF",
                                              width: "100%",
                                              padding: "16px",
                                            }}>
                                            <div
                                              className="row"
                                              style={{
                                                justifyContent: "space-between",
                                              }}>
                                              <div
                                                style={{
                                                  lineHeight: "24px",
                                                  fontWeight: "bold",
                                                }}>
                                                {direct_order?.data.client.name}
                                              </div>
                                              <div>
                                                <p>
                                                  {moment(el.created_at).format(
                                                    "DD-MM-YYYY HH:mm"
                                                  )}{" "}
                                                </p>
                                              </div>
                                            </div>
                                            <div>{el.comment}</div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <Avatar
                                            src={
                                              process.env
                                                .REACT_APP_STORAGE_URL +
                                              el?.commercial?.avatar
                                            }
                                            size="default"
                                            style={{
                                              marginRight: "16px",
                                            }}
                                          />{" "}
                                          <div
                                            style={{
                                              backgroundColor: "#F8FAFC",
                                              borderRadius: "5px",
                                              border: "1px solid #CDD5DF",
                                              width: "100%",
                                              padding: "16px",
                                            }}>
                                            <div
                                              className="row"
                                              style={{
                                                justifyContent: "space-between",
                                              }}>
                                              <div
                                                style={{
                                                  lineHeight: "24px",
                                                  fontWeight: "bold",
                                                }}>
                                                {el.commercial?.name}
                                              </div>
                                              <div>
                                                <p>
                                                  {moment(el.created_at).format(
                                                    "DD-MM-YYYY HH:mm"
                                                  )}{" "}
                                                </p>
                                              </div>
                                            </div>
                                            <div>{el.comment}</div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="proto_breadcrumb_container">
                  <div>
                    <div>
                      <div className="mb-4">
                        {option.data.status.pivot.proto_confirmation !==
                          "ACCEPTED" && (
                          <>
                            <div
                              style={{
                                padding: "24px",
                                backgroundColor: "#F1F5FE",
                                borderRadius: "5px",
                              }}
                              className={"action-comment"}
                            >
                              {option.data.status.id !== 5 && (
                                <>
                                  <label className="mr-3 mb-0">
                                    {" "}
                                    Url 360 °
                                  </label>
                                  <Input
                                    type="text"
                                    defaultValue={
                                      proto || option.data.status.pivot.proto
                                    }
                                    onChange={handleChangeProto}
                                    style={{
                                      borderRadius: "5px",
                                      marginBottom: "15px",
                                    }}
                                    disabled={
                                      !option?.data?.status?.pivot?.confirmed
                                    }></Input>
                                </>
                              )}
                              {
                                //option.data.status.id == 5 ||
                                (option.data.status.id == 4 ||
                                  option.data.status.id == 3 ||
                                  option.data.status.id == 2) && (
                                  <>
                                    <div
                                      style={{
                                        marginBottom: "15px",
                                      }}>
                                      {inputs.map((input, index) =>
                                        index % 3 === 0 ? (
                                          <div
                                            key={index}
                                            className="row grid_size">
                                            <div className=" col-9">
                                              <div className="row">
                                                <div
                                                  className=" col-4"
                                                  style={{
                                                    display: "flex",
                                                    /*                                                   marginRight: "1.2em",
                                                     */ width: "30%",
                                                  }}>
                                                  <span
                                                    style={{
                                                      backgroundColor:
                                                        "#182844",
                                                      color: "white",
                                                      padding: "8px",
                                                      borderTopLeftRadius:
                                                        "5px",
                                                      borderBottomLeftRadius:
                                                        "5px",
                                                      borderColor: "#98A2B3",
                                                    }}>
                                                    Size
                                                  </span>
                                                  <Input
                                                    value={input}
                                                    onChange={(event) =>
                                                      handleInputChange(
                                                        event,
                                                        index
                                                      )
                                                    }
                                                    style={{
                                                      width: "150px",
                                                      borderTopRightRadius:
                                                        "5px",
                                                      borderBottomRightRadius:
                                                        "5px",
                                                    }}
                                                    disabled={
                                                      !option?.data?.status
                                                        ?.pivot?.confirmed
                                                    }
                                                  />
                                                </div>

                                                {inputs[index + 1] !==
                                                  undefined && (
                                                  <div
                                                    className=" col-4"
                                                    style={{
                                                      display: "flex",
                                                      /*  marginRight: "1.2em", */
                                                      width: "30%",
                                                    }}>
                                                    <span
                                                      style={{
                                                        backgroundColor:
                                                          "#182844",
                                                        color: "white",
                                                        padding: "8px",
                                                        borderTopLeftRadius:
                                                          "5px",
                                                        borderBottomLeftRadius:
                                                          "5px",
                                                      }}>
                                                      Length
                                                    </span>
                                                    <Input
                                                      value={inputs[index + 1]}
                                                      onChange={(event) =>
                                                        handleInputChange(
                                                          event,
                                                          index + 1
                                                        )
                                                      }
                                                      style={{
                                                        width: "150px",
                                                        borderTopRightRadius:
                                                          "5px",
                                                        borderBottomRightRadius:
                                                          "5px",
                                                      }}
                                                      disabled={
                                                        !option?.data?.status
                                                          ?.pivot?.confirmed
                                                      }
                                                    />
                                                  </div>
                                                )}
                                                {inputs[index + 2] !==
                                                  undefined && (
                                                  <div
                                                    className=" col-4"
                                                    style={{
                                                      display: "flex",
                                                      /* marginRight: "1.2em", */
                                                      width: "30%",
                                                    }}>
                                                    <span
                                                      style={{
                                                        backgroundColor:
                                                          "#182844",
                                                        color: "white",
                                                        padding: "8px",
                                                        borderTopLeftRadius:
                                                          "5px",
                                                        borderBottomLeftRadius:
                                                          "5px",
                                                      }}>
                                                      Qty
                                                    </span>
                                                    <Input
                                                      value={inputs[index + 2]}
                                                      onChange={(event) =>
                                                        handleInputChange(
                                                          event,
                                                          index + 2
                                                        )
                                                      }
                                                      style={{
                                                        width: "150px",
                                                        borderTopRightRadius:
                                                          "5px",
                                                        borderBottomRightRadius:
                                                          "5px",
                                                      }}
                                                      disabled={
                                                        !option?.data?.status
                                                          ?.pivot?.confirmed
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </div>

                                            <div className="col-2">
                                              {" "}
                                              {inputs.length - 3 ? (
                                                <button
                                                  className="remove_input"
                                                  onClick={() =>
                                                    handleRemoveInput(index)
                                                  }>
                                                  <MinusOutlined />
                                                </button>
                                              ) : (
                                                <></>
                                              )}
                                              {index === inputs.length - 3 && (
                                                <button
                                                  className="add_input"
                                                  style={{
                                                    marginLeft: "1.2em",
                                                  }}
                                                  onClick={handleAddInput}>
                                                  <PlusOutlined></PlusOutlined>
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        ) : null
                                      )}
                                    </div>
                                  </>
                                )
                              }

                              {option.data.status.id == 5 && (
                                <>
                                  <div className="final_order_select my-3">
                                    <label
                                      className="mr-3 mb-0"
                                      style={{ color: "#344054" }}>
                                      Quantity
                                    </label>

                                    <Input
                                      value={
                                        quantity || option.data.status.pivot.qty
                                      }
                                      onChange={(e: any) =>
                                        setQuantity(e.target.value)
                                      }
                                      disabled={
                                        !option?.data?.status?.pivot?.confirmed
                                      }></Input>
                                  </div>
                                </>
                              )}
                              <div>
                                <label className="mr-3 mb-0">
                                  Delivery date
                                </label>

                                <DatePicker
                                  defaultValue={moment(
                                    option.data.status.pivot.delivery_date ===
                                      null
                                      ? new Date()
                                      : option.data.status.pivot.delivery_date
                                  )}
                                  disabledDate={(current) =>
                                    current.isBefore(moment().add(15, "days"))
                                  }
                                  format={"YYYY-MM-DD"}
                                  onChange={(date: any, dateString: any) =>
                                    setDeliveryDate(dateString)
                                  }
                                  style={{
                                    borderRadius: "5px",
                                    width: "100%",
                                    marginBottom: "8px",
                                  }}
                                  disabled={
                                    !option?.data?.status?.pivot?.confirmed
                                  }
                                />
                                {option.data.status.id == 5 && (
                                  <>
                                    <label className="mr-3 mb-0">
                                      {" "}
                                      Actual Delivery Date
                                    </label>
                                    <Input
                                      type="date"
                                      value={
                                        startDate ||
                                        moment(
                                          option.data.status.pivot
                                            .start_production_date
                                        ).format("YYYY-MM-DD")
                                      }
                                      onChange={handleChangeStartDate}
                                      style={{
                                        borderRadius: "5px",
                                        marginBottom: "15px",
                                      }}
                                      disabled={
                                        !option?.data?.status?.pivot?.confirmed
                                      }></Input>
                                  </>
                                )}
                                {isOpened == true && (
                                  <div>
                                    <>
                                      {option &&
                                        option.data.allComment.length > 0 && (
                                          <>
                                            <div
                                              style={{
                                                marginBottom: "16px",
                                              }}>
                                              Comments:
                                            </div>
                                            {option.data.allComment.map(
                                              (el: any) => {
                                                return (
                                                  <>
                                                    {el.status_id == step &&
                                                    el.name != "PROTO" ? (
                                                      <>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            marginBottom:
                                                              "16px",
                                                          }}>
                                                          {el.client_id ==
                                                            direct_order?.data
                                                              .client.id &&
                                                          el.commercial_id ==
                                                            null ? (
                                                            <>
                                                              <Avatar
                                                                src={
                                                                  process.env
                                                                    .REACT_APP_STORAGE_URL +
                                                                  direct_order
                                                                    ?.data
                                                                    .client.logo
                                                                }
                                                                size="default"
                                                                style={{
                                                                  marginRight:
                                                                    "16px",
                                                                }}
                                                              />{" "}
                                                              <div
                                                                style={{
                                                                  backgroundColor:
                                                                    "#F8FAFC",
                                                                  borderRadius:
                                                                    "5px",
                                                                  border:
                                                                    "1px solid #CDD5DF",
                                                                  width: "100%",
                                                                  padding:
                                                                    "16px",
                                                                }}>
                                                                <div
                                                                  className="row"
                                                                  style={{
                                                                    justifyContent:
                                                                      "space-between",
                                                                  }}>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "24px",
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}>
                                                                    {
                                                                      direct_order
                                                                        ?.data
                                                                        .client
                                                                        .name
                                                                    }
                                                                  </div>
                                                                  <div>
                                                                    <p>
                                                                      {moment(
                                                                        el.created_at
                                                                      ).format(
                                                                        "DD-MM-YYYY HH:mm"
                                                                      )}{" "}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                                <div>
                                                                  {el.comment}
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <Avatar
                                                                src={
                                                                  process.env
                                                                    .REACT_APP_STORAGE_URL +
                                                                  el.commercial
                                                                    .avatar
                                                                }
                                                                size="default"
                                                                style={{
                                                                  marginRight:
                                                                    "16px",
                                                                }}
                                                              />{" "}
                                                              <div
                                                                style={{
                                                                  backgroundColor:
                                                                    "#F8FAFC",
                                                                  borderRadius:
                                                                    "5px",
                                                                  border:
                                                                    "1px solid #CDD5DF",
                                                                  width: "100%",
                                                                  padding:
                                                                    "16px",
                                                                }}>
                                                                <div
                                                                  className="row"
                                                                  style={{
                                                                    justifyContent:
                                                                      "space-between",
                                                                  }}>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "24px",
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}>
                                                                    {
                                                                      el
                                                                        .commercial
                                                                        .name
                                                                    }
                                                                  </div>
                                                                  <div>
                                                                    <p>
                                                                      {moment(
                                                                        el.created_at
                                                                      ).format(
                                                                        "DD-MM-YYYY HH:mm"
                                                                      )}{" "}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                                <div>
                                                                  {el.comment}
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                    </>
                                    <textarea
                                      rows={4}
                                      name="comment"
                                      placeholder="Add commercial  comment"
                                      className="comment_proto_order my-4"
                                      onChange={(e: any) =>
                                        setComment(e.target.value)
                                      }
                                      disabled={
                                        !option?.data?.status?.pivot?.confirmed
                                      }></textarea>
                                  </div>
                                )}
                              </div>
                              <div className="footer_btns_form2 my-4">
                                {option.data.status.pivot.confirmed == 1 && (
                                  <button
                                    className="view-details"
                                    onClick={() =>
                                      valider(option.data.status.id)
                                    }>
                                    Save Changes
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="proto_footer_btns my-4">
                          {option.data.status.id < 4 &&
                          option.data.status.pivot.proto_confirmation !==
                            "ACCEPTED" ? (
                            <Button
                              type="default"
                              className="cancel_btn"
                              style={{ height: "40px", marginRight: "16px" }}
                              onClick={() => showCancelDrawer()}>
                              Cancel
                            </Button>
                          ) : option.data.status.pivot.proto_confirmation ===
                              "ACCEPTED" &&
                            option.data.status.pivot.step !== "complete" ? (
                            <></>
                          ) : (
                            option.data.status.pivot.step === "complete" && (
                              <>
                                <Button
                                  type="default"
                                  className="cancel_btn"
                                  style={{
                                    height: "40px",
                                    marginRight: "16px",
                                  }}
                                  onClick={() =>
                                    resotre(option?.data.status.id)
                                  }>
                                  Restore
                                </Button>
                              </>
                            )
                          )}
                          {option.data.status.pivot.proto_confirmation ===
                            "ACCEPTED" && option.data.status.id != 5 ? (
                            <>
                              {option?.data.status.id == 1 && (
                                <Button
                                  type="default"
                                  className="approve_btn"
                                  onClick={showDrawer2}>
                                  Next
                                </Button>
                              )}
                              {option?.data.status.id != 1 && (
                                <button
                                  className="view-details"
                                  style={{ height: "40px" }}
                                  onClick={() =>
                                    next(option.data.status.id + 1)
                                  }>
                                  Next Step
                                </button>
                              )}
                            </>
                          ) : option.data.status.pivot.proto_confirmation !==
                            "ACCEPTED" ? (
                            <>
                              {option.data.status.pivot.confirmed == 0 && (
                                <Button
                                  type="default"
                                  className="view-details"
                                  loading={confirmstep}
                                  onClick={() =>
                                    confirmStep(
                                      option?.data?.status?.pivot?.statusable_id
                                    )
                                  }
                                  disabled={
                                    option?.data?.status?.pivot?.confirmed === 1
                                  }>
                                  {option?.data?.status?.pivot?.confirmed === 1
                                    ? "Confirmed"
                                    : "Confirm"}
                                </Button>
                              )}
                              {option.data.status.pivot.confirmed == 1 && (
                                <button
                                  className="view-details"
                                  style={{ height: "40px" }}
                                  onClick={() =>
                                    approve(
                                      direct_order?.data.client.id,
                                      option.data.status.id
                                    )
                                  }>
                                  Approve & send
                                </button>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <div className="proto_footer">
          <Modal
            title="Congratulations"
            visible={open2}
            closable={true}
            centered
            onCancel={handleCancel}
            footer={[
              <div className="proto_footer_btns p-3">
                <Button
                  type="default"
                  className="approve_btn"
                  onClick={() => next(2)}>
                  {" "}
                  PRE SMS{" "}
                </Button>

                <Button
                  type="default"
                  className="approve_btn"
                  onClick={() => next(3)}>
                  {" "}
                  SMS{" "}
                </Button>
              </div>,
            ]}>
            <p  className={"pop-up-text"}> You Approved your Proto. Where do you wish to GO?</p>
          </Modal>

          <CancelOrderDrawer
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            article={article}
            notApprovedOption={notApprovedOption}
            orderType={"direct_order"}
            productToCancel={option?.data.id}
            approvingAndCommentLoading={approvingAndCommentLoading}
            rejectOrAcceptProtoLoading={rejectOrAcceptProtoLoading}
          />
          {option.data.status.id === 6 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <h4
                className="text-center"
                style={{ color: "red", fontWeight: 400 }}>
                This option has been cancelled
              </h4>
              <div className="proto_footer_btns my-4">
                <Button
                  type="default"
                  className="approve_btn"
                  onClick={() =>
                    routerHistory.push(
                      `/all-clients/${params.id}/1/${direct_order?.data.id}`
                    )
                  }>
                  Go back to orders
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderProgress;
