import React from "react";

export type IHrIconProps = {};

const HrIcon: React.FC<IHrIconProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height="32"
      viewBox="0 0 2 32"
      fill="none"
    >
      <line
        x1="0.81543"
        y1="2.18557e-08"
        x2="0.815428"
        y2="32"
        stroke="#CDD5DF"
      />
    </svg>
  );
};

export { HrIcon };
