import { Skeleton } from "antd";

const SkeletonOrders = (props: any) => {
  return Array.from({ length: props.items }, (_, index) => index + 1).map(
    (_, i) => (
      <div className="col-12 p-0" key={i}>
        <div className="d-flex flex-column">
          <Skeleton.Input
            style={{
              width: "100%",
              height: 90,
              borderRadius: 10,
            }}
            className="my-2"
            active
          />
        </div>
      </div>
    )
  );
};

export default SkeletonOrders;
