import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleRightDrawer } from "../../actions/actionCreators";
import progress_icon from "../../img/progress_icon.png";
import moment from "moment";
import { getProduct } from "../../actions/products-actions/actions";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import LazyImg from "../Library/LazyImage";

interface Props {
  notif: any;
  inPage: boolean;
  fabric?: boolean;
  position: boolean;
}

export const AddedProduct = ({ notif, inPage, fabric, position }: Props) => {
  const [img, setImg] = useState<any>(null);
  const [prod, setProd] = useState<any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!fabric) {
      axios
        .get(process.env.REACT_APP_API_URL + `/products/${notif.data.id}`)
        .then((res: any) => setProd(res.data))
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    if (prod) {
      setImg(prod.images[0] && prod.images[0].path);
    }
  }, [prod]);

  const imageToDisplay = () => {
    if (fabric) {
      if (notif.data.image) {
        return process.env.REACT_APP_STORAGE_URL + notif.data.image;
      } else {
        return placeholder_jeans;
      }
    } else {
      if (img) {
        return process.env.REACT_APP_STORAGE_URL + img;
      } else {
        return placeholder_jeans;
      }
    }
  };

  return inPage ? (
      <Link
          to={
            fabric
                ? `/library/${notif.data.id}`
                : `/new/${notif.data.name}/${notif.data.id}`
          }
          key={notif.id}
          className="notif_item_page p-2 my-2"
      >
        <div
            className="d-flex align-items-center icon_notif_container"
            style={{
              borderRadius: "8px",
              background: position ? "#e5f1fa" : "white",
              transition: "all 5s ease-in-out",
            }}
        >

          {/*  <h2 className="h4_medium mb-0 mx-2">
          New {fabric ? "fabric" : "product"} added by DEMCO
        </h2> */}
          {fabric ?
              <h2 className="h4_medium mb-0 mx-2">
                New fabrics have been added 👀
              </h2>
              :
              <h2 className="h4_medium mb-0 mx-2">
                Our newest collection is out now! 🏃‍♀️🏃‍♂️
              </h2>
          }

        </div>
        <p
            className="selection_date align-self-end"
            style={{fontSize: "0.7rem", fontWeight: 400}}
        >
          {moment(notif.created_at).fromNow()}
        </p>
      </Link>
  ) : (
      <Link
          to={
            fabric
                ? `/library/${notif.data.id}`
                : `/new/${notif.data.name}/${notif.data.id}`
          }
          key={notif.id}
          onClick={() => dispatch(handleRightDrawer())}
      className="notif_added_prod_container"
    >
      <article
        className="notif_selection notif_added_prod_container my-2 border p-3"
        style={{
          borderRadius: "8px",
          background: position ? "#e5f1fa" : "white",
          transition: "all 5s ease-in-out",
        }}
      >

        <div className="notif_details">
      {/*     <h2 className="h4_medium">
            New {fabric ? "fabric" : "product"} added by DEMCO
          </h2> */}
           {fabric ? 
          <h2 className="h4_medium mb-0 mx-2">New fabrics have been added 👀</h2> 
          : 
          <h2 className="h4_medium mb-0 mx-2">Our newest collection is out now! 🏃‍♀️🏃‍♂️</h2> 
          }
          <div className="notif_details m-0">

            <p
                className="selection_date align-self-end"
                style={{fontSize: "0.7rem", fontWeight: 400}}
            >
              {moment(notif.created_at).fromNow()}
            </p>
          </div>
        </div>
      </article>
    </Link>
  );
};
