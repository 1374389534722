import { Tabs } from "antd";

const { TabPane } = Tabs;

const WashTabs = ({ editMode, washes, oneProduct }: any) => {
  return (
    <Tabs type="card" defaultActiveKey={washes && washes[0] && washes[0].id}>
      {washes &&
        washes.map((wash: any, i: number) => (
          <TabPane
            tab={`Option ${i + 1}`}
            key={wash.id}
            closable={editMode ? wash.closable : false}
          >
            <>
              <div className="details_wash">
                <table style={{ width: "100%" }}>
                  <tbody>
                    {wash && (
                      <tr>
                        <th>Wash Type / Dye</th>

                        <td>
                          <p id="wash_desc_table">{wash.name}</p>
                        </td>
                      </tr>
                    )}
                    {wash && wash.description && (
                      <tr>
                        <th>Wash Description</th>
                        <td>
                          <p id="wash_desc_table">{wash.description}</p>
                        </td>
                      </tr>
                    )}
                    {wash && (
                      <>
                        {wash.colors?.length > 0 && (
                          <tr>
                            <th>Colour</th>
                            {wash && (
                              <td>
                                {wash.colors?.length > 1
                                  ? wash.colors.map((color: any, key: number) =>
                                      key === wash.colors?.length - 1
                                        ? `${color.name}`
                                        : `${color.name} • `
                                    )
                                  : `${wash.colors[0].name}`}
                              </td>
                            )}
                          </tr>
                        )}
                      </>
                    )}
                    {wash && (
                      <tr>
                        <th>Eim Score</th>
                        <td>{wash.eim_score}</td>
                      </tr>
                    )}
                    {wash && (
                      <tr>
                        <th>Price </th>
                        <td className="pr-5">
                          {Math.fround(wash.wash_price).toFixed(2) + "€"}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="details_fit">
                <h3 className="fit_title mr-3">FIT</h3>
                {oneProduct && <p style={{ fontSize: 20 }}>{oneProduct.fit}</p>}
              </div>
              <hr />
              <div className="total_price_container wash_tabs_price">
                <h3 className="total_price_title">TOTAL PRICE</h3>
                <p className="total_price">{`${Math.fround(
                  i === 0 ? oneProduct.total_price : oneProduct.total_price_two
                ).toFixed(2)}€`}</p>
              </div>
            </>
          </TabPane>
        ))}
    </Tabs>
  );
};

export default WashTabs;
