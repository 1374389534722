import Bell from "../../icons/Bell";
import Calender from "../../icons/Calender";
import Library from "../../icons/Library";
import TeamManagement from "../../icons/TeamManagement";
import Close from "../../icons/Close";
import Folder from "../../icons/Folder";
import Information from "../../icons/Information";
import Language from "../../icons/Language";
import Logout from "../../icons/Logout";
import ShoppingCart from "../../icons/ShoppingCart";
import "./MySpace.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth-actions/actions";
import { Link } from "react-router-dom";
import { handleRightDrawer } from "../../actions/actionCreators";
import { Drawer, Dropdown, Menu } from "antd";
import company_placeholder from "../../img/company_placeholder.png";
import Folders from "../../icons/Folders";
import Stars from "../../icons/Stars";
import Pdf from "../../icons/Pdf";
import Faq from "../../icons/Faq";
import ChartIcon from "../../icons/ChartIcon";

import {LineChartOutlined} from "@ant-design/icons";

import { useState, useEffect } from "react";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
interface Props {
  closeDrawer: () => void;
}

export const MySpace = ({ closeDrawer }: Props) => {
  const { user, isLoading, isAdmin } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  // avatar to display, if user dont have avatar, show company's logo. If the company dont have a logo, show placeholder
  const showAvatar: any = () => {
    if (user && user.logo) {
      return process.env.REACT_APP_STORAGE_URL + user.logo;
    } else if (user && user.company && user.company.logo) {
      return process.env.REACT_APP_STORAGE_URL + user.company.logo;
    } else if (user && user.avatar) {
      return process.env.REACT_APP_STORAGE_URL + user.avatar;
    } else {
      return company_placeholder;
    }
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    user && (
      <aside className="myspace_aside">
        <header>
          <div>
            <img
              src={isLoading ? company_placeholder : showAvatar()}
              alt="avatar"
            />
            <div>
              <span className={"hello_title"}>Hello!</span>
              <p className="user_name">{user.name}</p>
            </div>
          </div>
          <div className="close_btn" onClick={closeDrawer}>
            <p>Close</p>
            <Close />
          </div>
        </header>
        <main>
          <div>
            <p className={"welcome"}>Welcome to your space !</p>
            <div className="links_list">
              {user && isAdmin && (
                  <Link
                      to="/report"
                      className="link_item"
                      onClick={() => {
                        dispatch(handleRightDrawer());
                      }}
                  >

                    <ChartIcon />
                    <div>
                      <h2   className={"link_animation_myspace"}>Dashboard</h2>

                    </div>
                  </Link>
              )}
              <Link
                to="/account"
                className="link_item"
                onClick={() => dispatch(handleRightDrawer())}
              >
                <Information />
                <div>
                  <h2 className={"link_animation_myspace"}>Personal Information</h2>
                  {/*   <p>
                    You will be able to edit your informations and manage your
                    account.
                  </p> */}
                </div>
              </Link>
              {user && !isAdmin && !user.is_client_clients && (
                <Link
                  to="/my-orders"
                  className="link_item"
                  onClick={() => dispatch(handleRightDrawer())}
                >
                  <ShoppingCart />
                  <div>
                    <h2 className={"link_animation_myspace"}>My Orders</h2>
                    {/*                     <p>Track your order history and manage your activities.</p>
                     */}{" "}
                  </div>
                </Link>
              )}
              {user && !isAdmin && (
                <Link
                  // to="/myCollection"
                  to="/myCollection2"
                  className="link_item"
                  onClick={() => dispatch(handleRightDrawer())}
                >
                  <Folder />
                  <div>
{/*
                    <h2 className={"myspace_elememt"}>My Collections</h2>
*/}
                    <h2   className={"link_animation_myspace"}>My Collections</h2>

                    {/*   <p>
                      Find all of your products organized into categories which
                      you can manage as a file browser.
                    </p> */}
                  </div>
                </Link>
              )}
              {user && user.type !== "agent" && !user.is_client_clients && (
                <Link
                  to="/bookings"
                  className="link_item"
                  onClick={() => dispatch(handleRightDrawer())}
                >
                  <Calender />
                  <div>
                    <h2 className={"link_animation_myspace"}>
                      {user && isAdmin
                        ? "Manage Meetings"
                        : "Schedule a meeting"}
                    </h2>
                    {/*  <p>
                      {user && isAdmin
                        ? "Manage your upcoming meetings."
                        : "Plan a meeting with the ID Team."}
                    </p> */}
                  </div>
                </Link>
              )}
              {user.company && user.id === user.company.owner_id && (
                <Link
                  to="/team-management"
                  className="link_item"
                  onClick={() => dispatch(handleRightDrawer())}
                >
                  <TeamManagement />
                  <div>
                    <h2 className={"link_animation_myspace"}>Team management</h2>
                    {/*       <p>
                      Manage your team and collaborate together on the platform.
                    </p> */}
                  </div>
                </Link>
              )}

                <Link
                  to="/library?fabric_type=denim&page=1"
                  className="link_item"
                  onClick={() => dispatch(handleRightDrawer())}
                >
                  <Library />
                  <div>
                    <h2  className={"link_animation_myspace"}>DEMCO’s library</h2>
                    {/*                     <p>Fabrics and Trims digital library..</p>
                     */}{" "}
                  </div>
                </Link>

              {user && !isAdmin && (
                <Link
                  to="/notif-settings"
                  onClick={() => dispatch(handleRightDrawer())}
                  className="link_item"
                >
                  <Bell />
                  <div>
                    <h2 className={"link_animation_myspace"}>Notification Settings</h2>
                    {/*    <p>
                      Decide what and when you want to receive as notifications
                      from our platform.
                    </p> */}
                  </div>
                </Link>
              )}
              {user && isAdmin && (
                <Link
                  to="/notif-settings-admin"
                  onClick={() => dispatch(handleRightDrawer())}
                  className="link_item"
                >
                  <Bell />
                  <div >
                    <h2 className={"link_animation_myspace"}>Notification Settings</h2>
                    {/*    <p>
                      Decide what and when you want to receive as notifications
                      from our platform.
                    </p> */}
                  </div>
                </Link>
              )}
              {user && isAdmin && (
                <Link
                  to="/foryou-all-companies"
                  className="link_item"
                  onClick={() => dispatch(handleRightDrawer())}
                >
                  <Pdf />
                  <div>
                    <h2     className={"link_animation_myspace"}>Moodboard</h2>
                    {/*                     <p>You can view companies’ moodboards.</p>
                     */}{" "}
                  </div>
                </Link>
              )}
              {user && isAdmin && (
                <Link
                  to="/all-company"
                  className="link_item"
                  onClick={() => dispatch(handleRightDrawer())}
                >
                  <TeamManagement />
                  <div>
                    <h2   className={"link_animation_myspace"}>Team management</h2>
                    {/*  <p>
                      Manage your team and collaborate together on the platform.
                    </p> */}
                  </div>
                </Link>
              )}
                <Link
                to="/faq"
                className="link_item"
                onClick={() => {
                  dispatch(handleRightDrawer());
                }}
              >
                <Faq />
                <div>
                  <h2   className={"link_animation_myspace"}>Frequenty asked question</h2>
{/*
                                   <p>Find answers to the most asked questions.</p>
*/}

                </div>
              </Link>

              {/*                     Hide FAQ watting alison
               */}{" "}
              {/*     <Link
                to="/faq"
                className="link_item"
                onClick={() => {
                  dispatch(handleRightDrawer());
                }}
              >
                <Faq />
                <div>
                  <h2>Frequenty asked question</h2>
             
                </div>
              </Link> */}
              {/* DO NOT DELETE !*/}
              {/* <Dropdown
                overlay={menu}
                placement="bottomRight"
                arrow
                overlayStyle={{ borderRadius: 50 }}
                
              >
                <Link
                  to={{ hash: "#" }}
                  className="link_item dropdown_link"
                  onClick={() => dispatch(handleRightDrawer())}
                >
                  <div>
                    <Language />
                    <div>
                      <h2>Languages</h2>
                      <p>Choose your appropriate language.</p>
                    </div>
                  </div>
                  <FontAwesomeIcon icon={faCaretDown} />
                </Link>
              </Dropdown> */}
            </div>
          </div>
          <div
            className="link_item link_item_logout"
            onClick={() => dispatch(logout())}
          >
            <Logout />
            <div>
              <h2   className={"link_animation_myspace"}>Logout</h2>
              {/*               <p>You will be logged out of your space.</p>
               */}{" "}
            </div>
          </div>
        </main>
      </aside>
    )
  );
};

const menu = (
  <Menu className="language_dropdown">
    <Menu.Item>
      <a>English</a>
    </Menu.Item>
    <Menu.Item>
      <a>French</a>
    </Menu.Item>
  </Menu>
);
