import { Avatar, Descriptions, PageHeader, Tag, Tooltip } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import moment from "moment";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import { useHistory, useParams } from "react-router-dom";
import { history } from "../../index";

const MoodboardHearder = ({ moodboard, moodboard_type, goTo }: any) => {
  const routerHistory = useHistory();
  const params = useParams<{ id: string }>();

  return (
    <PageHeader
      ghost={false}
      className="site-page-header mt-3 rounded"
      onBack={() => routerHistory.push(`/foryou-all-companies/${params?.id}/1`)}
      subTitle={`Moodboard added by ${
        moodboard[0]?.client && moodboard[0]?.client.name
      }`}
      style={{ border: "1px solid #ddd" }}
    >
      <Descriptions size="small" column={3}>
        <Descriptions.Item label="Theme" labelStyle={{ fontWeight: 500 }}>
          {moodboard[0]?.theme}
        </Descriptions.Item>

        <Descriptions.Item label="Division" labelStyle={{ fontWeight: 500 }}>
          {moodboard[0]?.division}
        </Descriptions.Item>
        <Descriptions.Item label="Created at" labelStyle={{ fontWeight: 500 }}>
          {moment(moodboard[0]?.created_at).format("MMMM DD YYYY")}
        </Descriptions.Item>

        <Descriptions.Item label="Client" labelStyle={{ fontWeight: 500 }}>
          <Avatar.Group>
            <Tooltip
              title={moodboard[0]?.client.name}
              placement="bottom"
              key={moodboard[0]?.client.id}
            >
              <Avatar
                key={moodboard[0]?.client.id}
                alt={moodboard[0]?.client.name}
                src={
                  moodboard[0]?.client.logo
                    ? process.env.REACT_APP_STORAGE_URL +
                      moodboard[0]?.client.logo
                    : avatar_placeholder
                }
              />
            </Tooltip>
          </Avatar.Group>
        </Descriptions.Item>
        <Descriptions.Item label="Session" labelStyle={{ fontWeight: 500 }}>
          {moodboard[0]?.saisson}{" "}
        </Descriptions.Item>
        <Descriptions.Item label="Year" labelStyle={{ fontWeight: 500 }}>
          {moodboard[0]?.year}{" "}
        </Descriptions.Item>
      </Descriptions>
    </PageHeader>
  );
};

export default MoodboardHearder;
