import { Avatar, Button, Collapse, Modal, Popconfirm, Tag, Tooltip } from "antd";
import moment from "moment";
import Folder from "../../icons/Folder";
import Jeans from "../../icons/Jeans";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./style.css";
import { useEffect, useState } from "react";
import {
  PlusSquareOutlined,
  PlusOutlined,
  MinusSquareOutlined,
  MinusOutlined,
  FolderOutlined,
  FolderAddOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import axiosInstance from "../../config/axios-instance";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";

const { Panel } = Collapse;

export default function TableRawCompletedCustomizedOrder({ item, url,refetch }: any) {
  const [showTable, setShowTable] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [showTr, setShowTr] = useState("");
  const [isModalRestore, setIsModalRestore] = useState(false);
  const [orderToRestore, setOrderToRestore] = useState<any>("");
  const [productToRestore, setProductToRestore] = useState<any>("");
  const genStatusMsg = (status_name: string) => {
    switch (status_name) {
      case "PROTO":
        return {
          color: "#BCBCBD",
          msg: "Your prototype is being prepared",
        };
      case "PRODUCTION":
      case "OK PROD":
        return {
          msg: "Your order is in process",
          color: "#06C170",
        };
      case "CANCELLED":
        return { color: "#FF3A3A", msg: "Your order is canceled" };
      default:
        return {
          color: "#FF872A",
          msg: "We are waiting for your answer",
        };
    }
  };

  const handleClick = (rowId: any) => {
    // Only set showTable to true for the clicked row
    setShowTr(rowId);
  };

  const showModalRestore = (order_id:any,product_id: any) => {
    setIsModalRestore(true);
    setOrderToRestore(order_id)
    setProductToRestore(product_id);
  };


  const handleCancel = () => {
    setIsModalRestore(false);
  };

  const relaunchProduction = () => {


    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/customized_orders/relaunch/${orderToRestore}`,
      data: {
        product_id: productToRestore,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Relaunched with successfully.", "success");
        setIsModalRestore(false);
        refetch();
      }
    });
    //   setIsModalRestore(false);
  };

  const relaunchPreSMS = () => {


    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/customized_orders/relaunch/presms/${orderToRestore}`,
      data: {
        product_id: productToRestore,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Relaunched with successfully.", "success");
        setIsModalRestore(false);
        refetch();
      }
    });
    //   setIsModalRestore(false);
  };

console.log(item)
  return (
    <>
    {item.products?.length > 0 && 
     <tr onClick={() => setShowTable(!showTable)}>
      
        <td
          width={"5%"}
          className={showTable ? "top_article active_tr" : "top_article"}
        >
          <button onClick={() => setShowTable(!showTable)}>
            {showTable ? (
              <>
                {/*                   <MinusSquareOutlined style={{ color: "#00468C" }} />
                 */}{" "}
                <FolderOpenOutlined
                  style={{ color: "#00468C", fontSize: "20px" }}
                />
              </>
            ) : (
              <>
                <FolderAddOutlined
                  style={{ color: "#00468C", fontSize: "20px" }}
                />
                {/*                   <PlusSquareOutlined style={{ color: "#00468C" }} />
                 */}{" "}
              </>
            )}
          </button>
        </td>

        <td className={showTable ? "top_article active_tr" : "top_article"}>
          {item.reference}
        </td>

        <td
          className={showTable ? "top_article active_tr" : "top_article"}
          style={{ paddingLeft: 0 }}
        >
          {item.collection_name || "-"}
        </td>
        <td
          className={showTable ? "top_article active_tr" : "top_article"}
          style={{ paddingLeft: 0 }}
        >
        </td>

        <td
          className={showTable ? "top_article active_tr" : "top_article"}
          style={{ paddingLeft: 0 }}
        >
     <p className="mb-0" style={{ marginLeft: "50px" }}>  {item.products.length}</p>
        </td>
        <td
          className={showTable ? "top_article active_tr" : "top_article"}
          style={{ paddingLeft: 0 }}
        >
         
        
        </td>
        <td
          className={showTable ? "top_article active_tr" : "top_article"}
        ></td>
        <td
          className={showTable ? "top_article active_tr" : "top_article"}
        ></td>
        <td className={showTable ? "top_article active_tr" : "top_article"}>
          <Avatar.Group
            maxCount={2}
            size="large"
            maxStyle={{ color: "white", backgroundColor: "#7a9ec5" }}
          >
            {item.collaborators.map((collab: any) => (
              <Tooltip title={collab.name} key={collab.id} placement="bottom">
                {collab.logo ? (
                  <Avatar
                    style={{
                      boxShadow: "1px 0px 5px rgba(0,0,0,0.4)",
                    }}
                    src={process.env.REACT_APP_STORAGE_URL + collab.logo}
                  />
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: "#ddd",
                      boxShadow: "1px 0px 5px rgba(0,0,0,0.2)",
                    }}
                    icon={<UserOutlined style={{ verticalAlign: "2px" }} />}
                  />
                )}
              </Tooltip>
            ))}
          </Avatar.Group>
        </td>
      </tr>}
      {showTable && (
        <tr>
          <td
            colSpan={10}
            style={{
              backgroundColor: "#FCFDFF",
              padding: "0.2rem",
              paddingLeft: "4%",
            }}
          >
        {/*     <table className="tableArticle">
              {item.products?.map((elm: any) => (
                <>
              

<tr>
                    <td
                      style={{
                        //   width: "50%",

                        borderTop: "none",
                      }}
                      className="details_td"
                    >
                      {elm.name}
                    </td>

                    <td
                      style={{
                        //   width: "5%",
                        paddingLeft: "0px",
                        borderTop: "none",
                      }}
                    ></td>
                    <td
                      style={{
                        //    width: "21%",
                        paddingLeft: "0px",
                        borderTop: "none",
                      }}
                    >
                      {elm.delivery_date_item
                        ? moment(elm.delivery_date_item).format("DD/MM/YYYY")
                        : "-"}
                    </td>
                    <td
                      style={{
                        //  width: "8%",
                        paddingLeft: "0px",
                        borderTop: "none",
                      }}
                    >
                      {" "}
                      {elm.qty_item}
                    </td>
                    <td
                      style={{
                        // width: "22%",
                        paddingLeft: "0px",
                        borderTop: "none",
                      }}
                    >
                      <div>
                        <Link to={`${url}/${item.id}/${elm.id}/progress`}>
                          View History
                        </Link>

                        <Button
                                              size={"middle"}
                                              style={{ borderRadius: "4px" }}
                                              onClick={() =>
                                                showModalRestore(
                                                   elm.id,
                                                  elm.product_id,
                                                )
                                              }
                                            >
                                              Relaunch
                                            </Button>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </table> */}

{item.products?.map((elm: any) => (

<>
                                 
                                 <div className="row row_product_custom_completed">
                                   <div className="product_name_completed_custom_order">
                                   {elm.name}
                                   </div>
                                   <div className="delivery_date_completed_custom_order">
                                     {" "}
                                     {elm.delivery_date_item
                        ? moment(elm.delivery_date_item).format("DD/MM/YYYY")
                        : "-"}
                                   </div>

                                   <div className="qty_completed_custom_order">
                                   {elm.qty_item ?? "-"} 
                                   </div>

                                   <div >
                                 

                                       <button  className="relaunch"       onClick={() =>
                                                showModalRestore(
                                                   elm.id,
                                                  elm.product_id,
                                                )}>Relaunch</button>
                                       <Link  className="view_history" to={`${url}/${item.id}/${elm.id}/progress`}>
                          View History
                        </Link>
                                   </div>
                                 </div>
                               
                             </>
                               ))}
          </td>
        </tr>
      )}
      <Modal
            title="Relaunch production"
            visible={isModalRestore}
            onCancel={handleCancel}
            footer={[
             
              <Button
                className="restore_2"
                //   loading={loading}
                onClick={relaunchPreSMS}
              >
                Start again from PRE SMS
              </Button>,
               <Button
               className="restore"
               //  loading={loading}
               onClick={relaunchProduction}
             >
               Straight to PRODUCTION
             </Button>,
            ]}
          >
            <p>In what stage would you like to relaunch the production?	 </p>
          </Modal>
      {/*  <tr>
        <td width={"20%"}>
          <ul id="myUL">
            <li>
              <span
                className="caret"
                style={{ color: "#8894A1", fontSize: "16px" }}
              >
                {item.ref}
              </span>

              {item.articles && item.articles.length > 0 ? (
                <>
                  {" "}
                  <ul className="nested">
                    {item.articles.map((elm: any) => (
                      <li
                        style={{
                          fontSize: "14px",
                          marginLeft: "0px",
                        }}
                      >
                        <span className="caret">{elm.name}</span>
                        <ul className="nested">
                          {elm.options.map((option: any) => (
                            <>
                              <li
                                style={{
                                  fontSize: "14px",
                                  marginLeft: "10px",
                                  marginTop: "5px",
                                }}
                              >
                                <table className="border-details">
                                  <tr>
                                    <td>
                                      <Link
                                        to={`${url}/${item.id}/${elm.id}/${option.id}/progress`}
                                      >
                                        {option.name}
                                      </Link>
                                    </td>
                                    <td>
                                      <Tag
                                        color="processing"
                                        style={{
                                          borderRadius: "50px",
                                          cursor: "pointer",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {option.status.name}
                                      </Tag>
                                    </td>
                                  </tr>
                                </table>
                              </li>
                            </>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  {" "}
                  <ul className="nested" style={{ color: "#7C7C7C" }}>
                    No Articles
                  </ul>
                  <></>
                </>
              )}
            </li>
          </ul>
        </td>

        <td scope="row" className="top_article">
          <span>{item.name}</span>
        </td>
        <td className="top_article">
          {moment(item.updated_at).format("DD/MM/YYYY")}
        </td>
        <td className="top_article">
          <Avatar.Group
            maxCount={2}
            size="large"
            maxStyle={{ color: "white", backgroundColor: "#7a9ec5" }}
          >
            {item.collaborators.map((collab: any) => (
              <Tooltip title={collab.name} key={collab.id} placement="bottom">
                {collab.logo ? (
                  <Avatar
                    style={{
                      boxShadow: "1px 0px 5px rgba(0,0,0,0.4)",
                    }}
                    src={process.env.REACT_APP_STORAGE_URL + collab.logo}
                  />
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: "#ddd",
                      boxShadow: "1px 0px 5px rgba(0,0,0,0.2)",
                    }}
                    icon={<UserOutlined style={{ verticalAlign: "2px" }} />}
                  />
                )}
              </Tooltip>
            ))}
          </Avatar.Group>
        </td>
        <td className="top_article">
          <p className="mb-0">
            {item.articles.length} article{item.articles.length > 1 ? "s" : ""}
          </p>
        </td>
      </tr> */}
    </>
  );
}
