import { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  handleBottomDrawer,
  handleBottomDrawerDeletedItems,
} from "../../actions/actionCreators";
import {
  clearFolderToMoveToOnDrag,
  clearSelectedFolders,
  deleteFolder,
  getAllFolders,
  getArchivedFolders,
} from "../../actions/folders-actions/actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import HeartOutline from "../../icons/HeartOutline";
import FolderMiniature from "../FolderMiniature/FolderMiniature";
import DeletedItems from "../DeletedItems/DeletedItems";
import { AnimatePresence } from "framer-motion";
import Attention from "../../icons/Attention";
import Trash from "../../icons/Trash";
import { selectSelection } from "../../actions/myselection-actions/actions";
import { Spinner } from "../Spinner";
import { Popover, Tooltip, Tree } from "antd";
import Folder from "../../icons/Folder";
import {
  HeartFilled,
  FolderOpenFilled,
  FormatPainterFilled,
  LayoutFilled,
  LeftOutlined,
  AimOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import backArrow from "../../img/backArrow.png";
import ForwardDirectoryTree from "antd/lib/tree/DirectoryTree";
import "./CollectionFolders.scss";
import {
  getOneFolder,
  clearRightSideFolder,
  clearSelectedProducts,
  archiveFolder,
  clearOneFolder,
} from "../../actions/folders-actions/actions";
import { deselectSelection } from "../../actions/myselection-actions/actions";
import { getOrders, selectOrder } from "../../actions/order-actions/actions";
import Bag from "../../icons/Bag";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import BottomDrawer from "../BottomDrawer/BottomDrawer";

export interface ICollectionFoldersProps {
  setfolder: (x: any) => void;
  subFolderId: any;
  setsubfolders: (t: any) => void;
  setParentFolderId: (t: any) => void;
  setHover: (t: boolean) => void;
  hover: boolean;
  selectScreen: string;
  setSelectScreen: (screen: string) => void;
}

export default function CollectionFolders({
  setfolder,
  subFolderId,
  setsubfolders,
  setParentFolderId,
  setHover,
  hover,
  selectScreen,
  setSelectScreen,
}: ICollectionFoldersProps) {
  // const [selectScreen, setSelectScreen] = useState("");

  const dispatch = useDispatch();
  const drawers = useSelector((state: RootStateOrAny) => state.drawers);
  const folders = useSelector((state: RootStateOrAny) => state.folders);
  const selection = useSelector((state: RootStateOrAny) => state.selection);
  const [height, setHeight] = useState(window.innerHeight);
  // const simulateClick(e) {
  //   e.click()
  // }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramValue = urlParams.get("section");

  const { user, isAdmin } = useSelector((state: RootStateOrAny) => state.auth);
  const [mode, setMode] = useState("sss");

  const { orders, isLoading, newOrder } = useSelector(
    (state: any) => state.orders
  );

  useEffect(() => {
    if (!isAdmin) {
      dispatch(getOrders("waiting"));
    }
  }, []);
  console.log(paramValue);
  useEffect(() => {
    if (paramValue == "myselection") {
      setSelectScreen("selections");
    } else if (paramValue == "mycustomized") {
      setSelectScreen("customization");
    }
  }, []);
  useEffect(() => {
    setHeight(window.innerHeight);
  }, [window.innerHeight]);
  useEffect(() => {
    dispatch(getArchivedFolders());
    selection.directRender && dispatch(selectSelection());
  }, []);
  useEffect(() => {
    dispatch(getArchivedFolders());
  }, [folders.folders]);
  useEffect(() => {
    // dispatch(getF)
  }, [folders.folderUpdated]);

  const getTrashStyle = (isDraggingOver: boolean) => ({
    borderColor: isDraggingOver ? "#ff0045" : "#c8d4f0",
  });

  useEffect(() => {
    var toggler = document.getElementsByClassName("caret");
    var i;

    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function (e: any) {
        e.target.parentElement
          .querySelector(".nested")
          .classList.toggle("active");
        e.target.classList.toggle("caret-down");
      });
    }
  });

  const handleOrderClick = (selectedOrder: any) => {
    console.log("Clicked Order:", selectedOrder);
    dispatch(selectOrder(selectedOrder));
  };

  return (
    <div className="my_collection_folders">
      {selectScreen === "collections" ? (
        <>
          <div className="my_collection_folders_header">
            <div className="my_collection_header">
              <div className="d-flex align-items-center">
                <img
                  src={backArrow}
                  alt="Back Arrow"
                  width={"40px"}
                  height={"40px"}
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectScreen("")}
                />
                <h2 className="collection_header_title ml-2">My collections</h2>
              </div>
              <p className="number_folders">
                <span>
                  {folders.folders &&
                    folders.folders.filter((el: any) => !el.parent_id).length}
                </span>{" "}
                items
              </p>
            </div>
            <Button
              innerButton="Add New Collection"
              buttonClass="submit_button_blue submit_button_blue_add_folder"
              onClick={() => {
                // setshowdrawer(true);
                dispatch(handleBottomDrawer());
              }}
              icon={<FontAwesomeIcon icon={faFolderPlus} />}
              // reverse={false}
            />

            {/* <div
              className="folder_miniature folder_my_selection"
              onClick={() => {
                dispatch(selectSelection());
                dispatch(clearSelectedFolders());
              }}
            >
              <div
                className="folder_miniature_header"
                style={{ justifyContent: "flex-start" }}
              >
                {<HeartOutline className="heart_outline" />}
                <h2 className="folder_name">My Selections</h2>
              </div>
              <p className="paragraphe folder_miniature_description">
                {selection.selectionProducts
                  ? `contains ${selection.selectionProducts.length} products`
                  : "Your Selection is Empty"}
              </p>
            </div> */}
          </div>

          <div className="collection_list_folders" style={{ width: "100%" }}>
            {/*   {folders.folders &&
              !folders.isLoading &&
              folders.folders
                .filter((el: any) => !el.parent_id)
                .map((el: any, i: number) => (
                  <>
                    <ul id="myUL">
                      <li>
                        <span
                          onClick={() => {
                            dispatch(clearSelectedFolders());
                            //  dispatch(deselectSelection());
                            dispatch(clearRightSideFolder());
                            el.name && dispatch(getOneFolder(el.id));
                            dispatch(clearSelectedProducts());
                          }}
                          className="caret"
                        >
                          {el.name}
                        </span>

                        {el.folders_sub && el.folders_sub.length > 0 ? (
                          <>
                            {" "}
                            <ul className="nested">
                              {el.folders_sub.map((elm: any) => (
                                <>
                                  {" "}
                                  <li
                                    onClick={() => {
                                      dispatch(clearSelectedFolders());
                                      // dispatch(deselectSelection());
                                      dispatch(clearRightSideFolder());
                                      elm.name &&
                                        dispatch(getOneFolder(elm.id));
                                      dispatch(clearSelectedProducts());
                                    }}
                                    className="nestedli"
                                    style={{ margin: "6px" }}
                                  >
                                    {elm.name}
                                  </li>
                                </>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <>
                            {" "}
                            <ul className="nested" style={{ color: "#7C7C7C" }}>
                              No subfolders
                            </ul>
                            <></>
                          </>
                        )}
                      </li>
                    </ul>
                  </>
                ))} */}
            {folders.folders && !folders.isLoading ? (
              folders.folders
                .filter((el: any) => !el.parent_id)
                .map((el: any, i: number) => (
                  <FolderMiniature
                    setParentFolderId={setParentFolderId}
                    setsubfolders={setsubfolders}
                    subFolderId={subFolderId}
                    setfolder={setfolder}
                    folder={el}
                    key={i}
                  />
                ))
            ) : (
              <Spinner />
            )}
          </div>
          <div style={{ width: "100%" }}>
            <>
              <div className="delete_folder_container">
                {" "}
                <div className={" "}></div>
              </div>
              <div className="view_deleted_items"></div>
            </>
            <Droppable droppableId={`droppable-trash`}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="delete_folder_container"
                >
                  <div
                    className={
                      hover
                        ? "folder_miniature delete_folder delete_hovered"
                        : "folder_miniature delete_folder"
                    }
                    style={getTrashStyle(snapshot.isDraggingOver)}
                  >
                    <Trash />
                    <p>Drag {"&"} drop here to archive any folder </p>
                  </div>
                  <div className="view_deleted_items">
                    <p
                      className="view_deleted_items_button"
                      onClick={() => {
                        folders.archivedFolders.length > 0 &&
                          dispatch(handleBottomDrawerDeletedItems());
                      }}
                    >
                      View Archived items ({folders.archivedFolders.length})
                    </p>
                    <Tooltip
                      placement="right"
                      title="See all archived collections"
                    >
                      <div>
                        <Attention />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              )}
            </Droppable>
          </div>

          <AnimatePresence initial={true} exitBeforeEnter={true}>
            {drawers.openDeletedItems && <DeletedItems />}
          </AnimatePresence>
        </>
      ) : selectScreen === "direct_order" ? (
        <>
          <>
            <div className="my_collection_folders_header">
              <div className="my_collection_header">
                <div className="d-flex align-items-center">
                  <img
                    src={backArrow}
                    alt="Back Arrow"
                    width={"40px"}
                    height={"40px"}
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectScreen("")}
                  />
                  <h2 className="collection_header_title ml-2">Direct order</h2>
                </div>
              </div>
              {/*  <Button
                innerButton="Add New order"
                buttonClass="submit_button_blue submit_button_blue_add_folder"
                onClick={() => {
                  // setshowdrawer(true);
                  dispatch(handleBottomDrawer());
                  setMode("add_new_order");
                }}
                icon={<FontAwesomeIcon icon={faFolderPlus} />}
                // reverse={false}
              /> */}
              <Button
                innerButton="Add New Order"
                buttonClass="submit_button_blue submit_button_blue_add_folder"
                icon={<FontAwesomeIcon icon={faFolderPlus} />}
                onClick={() => {
                  dispatch(handleBottomDrawer());
                  setMode("add_new_order");
                }}
              ></Button>
            </div>

            <div
              style={{ width: "100%", marginTop: "25px" }}
              className="scrol_order"
            >
              {orders ? (
                orders
                  .filter((el: any) => el.client_id !== user.id)
                  .map((el: any, i: number) => (
                    <>
                      <div
                        key={i}
                        onClick={() => handleOrderClick(el)}
                        className="order_miniature "
                      >
                        <div className="order_miniature_info">
                          <div className="order_miniature_name">
                            {/* <img src={shoppingBag} alt="order icon" /> */}
                            <Bag />
                            <h2>{el?.name}</h2>
                          </div>
                          <p className="paragraphe">
                            Contains {el.articles_count} articles
                          </p>
                        </div>

                        <div className="collab_list">
                          {el.collaborators ? (
                            <div className="d-flex">
                              {el.collaborators
                                .filter((el: any) => el.id !== user.id)
                                .slice(0, 3)
                                .map((el: any) => (
                                  <a
                                    key={el.id}
                                    className="collab_profile"
                                    style={{
                                      backgroundImage: `url(${
                                        el.logo
                                          ? process.env.REACT_APP_STORAGE_URL +
                                            el.logo
                                          : avatar_placeholder
                                      })`,
                                    }}
                                    //   replace id with the collaborator name
                                    id={el.name}
                                  ></a>
                                ))}
                              {el.collaborators.filter(
                                (el: any) => el.id !== user.id
                              ).length > 3 && (
                                <Tooltip
                                  placement="right"
                                  title={el.collaborators
                                    .filter((el: any) => el.id !== user.id)
                                    .slice(
                                      3,
                                      el.collaborators.filter(
                                        (el: any) => el.id !== user.id
                                      ).length + 1
                                    )
                                    .map((x: any) => x.name + `, `)}
                                >
                                  <p className="and_others_collabs mb-0 collab_profile">
                                    +
                                    {el.collaborators.filter(
                                      (el: any) => el.id !== user.id
                                    ).length - 3}
                                  </p>
                                </Tooltip>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  ))
              ) : (
                <Spinner />
              )}
            </div>
            {drawers.openBottom && (
              <BottomDrawer mode="add_new_order" setMode={setMode} />
            )}
          </>
        </>
      ) : (
        <div className="d-flex flex-column w-100 h-100">
          {/*   <SidebarItem
            icon={<AimOutlined />}
            title={"Direct order"}
            count={0}
            onClick={() => setSelectScreen("direct_order")}
            isActive={selectScreen === "direct_order"}
          />

          <SidebarItem
            icon={<FolderOpenFilled />}
            title={"Client product"}
            count={0}
            onClick={() => setSelectScreen("collections")}
            isActive={selectScreen === "collections"}
          /> */}
          <SidebarItem
            icon={<AppstoreOutlined />}
            title={"My collections"}
            count={0}
            onClick={() => setSelectScreen("collections")}
            isActive={selectScreen === "collections"}
          />

          <SidebarItem
            icon={<HeartFilled />}
            title={"My selection"}
            count={selection?.selectionProducts?.length}
            onClick={() => {
              dispatch(selectSelection());
              dispatch(clearSelectedFolders());
              setSelectScreen("selections");
            }}
            isActive={selectScreen === "selections"}
          />
          <SidebarItem
            icon={<FormatPainterFilled />}
            title={"My customized products"}
            count={0}
            onClick={() => {
              setSelectScreen("customization");
              // dispatch(selectSelection());
              // dispatch(clearSelectedFolders());
            }}
            isActive={selectScreen === "customization"}
          />
          <SidebarItem
            icon={<LayoutFilled />}
            title={"My moodboards"}
            count={0}
            onClick={() => {
              setSelectScreen("moodboards");
              dispatch(selectSelection());
              dispatch(clearSelectedFolders());
            }}
            isActive={selectScreen === "moodboards"}
          />
        </div>
      )}
    </div>
  );
}

const SidebarItem = ({ onClick, count, title, icon, isActive }: any) => {
  return (
    <div
      className="folder_miniature folder_my_selection bg-white"
      style={{
        borderStyle: "solid",
        flex: 1,
        position: "relative",
        overflow: "hidden",
        border: isActive ? "2px solid #00468c" : "2px solid #c8d4f0",
      }}
      onClick={onClick}
    >
      <div
        className="folder_miniature_header"
        style={{ justifyContent: "flex-start" }}
      >
        <div
          style={{
            position: "absolute",
            right: "-30px",
            bottom: "-20px",
            fontSize: "6rem",
            opacity: isActive ? 1 : 0.2,
            color: "#00468c",
          }}
        >
          {icon}
        </div>
        <h2
          className="folder_name m-0 mt-2"
          style={{ fontSize: "1.5rem", color: isActive ? "#00468c" : "black" }}
        >
          {title}
        </h2>
      </div>
      <p className="paragraphe folder_miniature_description">
        {count ? `Contains ${count} products` : ""}
      </p>
    </div>
  );
};
