function Jeans() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.136"
      height="29.917"
      viewBox="0 0 20.136 29.917"
    >
      <g transform="translate(-83.693)">
        <g data-name="Groupe 5560" transform="translate(83.693)">
          <path
            fill="#00468c"
            d="M102.966 0h-18.41a.863.863 0 00-.863.863v28.191a.863.863 0 00.863.863h5.753a.863.863 0 00.847-.7l2.605-13.243 2.605 13.246a.863.863 0 00.847.7h5.753a.863.863 0 00.863-.863V.863a.863.863 0 00-.863-.863zM85.419 5.466h1.726v2.127l-1.726 1.15zM102.1 28.191h-4.177L94.608 11.34a.863.863 0 00-1.694 0L89.6 28.191h-4.181V10.818l3.068-2.045a.863.863 0 00.384-.718V5.466h9.78v2.588a.863.863 0 00.384.718l3.068 2.045v17.374zm0-19.447l-1.726-1.151V5.466h1.726zm0-5H85.419V1.726H102.1z"
            data-name="Tracé 354"
            transform="translate(-83.693)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Jeans;
