import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Dispatch } from "redux";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  handleRightDrawer,
  handleLeftDrawer,
  handleBottomDrawer,
} from "../../actions/actionCreators";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const drawerState = useSelector((state: RootStateOrAny) => state.drawers);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (drawerState.openRight) {
          dispatch(handleRightDrawer());
        } else if (drawerState.openBottom) {
          dispatch(handleBottomDrawer());
        } else {
          dispatch(handleLeftDrawer());
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props: any) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="outside_click" ref={wrapperRef}>
      {props.children}
    </div>
  );
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default OutsideAlerter;
