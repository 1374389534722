import React from "react";

export type IEditBrushIconProps = {};

const EraseIcon: React.FC<IEditBrushIconProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 33.78 29.53"
    >
      <path
        d="M32.938,0a1.063,1.063,0,0,1,0,2.125H18.063V2.118c-.073-.053-.146.007-.219.007H9.782a3.725,3.725,0,0,1-2.63-1.089L1.31-4.808a3.719,3.719,0,0,1,0-5.259L17.558-26.315a3.719,3.719,0,0,1,5.259,0l9.875,9.873a3.715,3.715,0,0,1,0,5.259L21.509,0ZM21.257-24.813a1.527,1.527,0,0,0-2.2,0l-7.431,7.375L23.813-5.312l7.378-7.431a1.535,1.535,0,0,0,0-2.2ZM2.812-6.368,8.653-.525A1.436,1.436,0,0,0,9.782,0h8.062a1.436,1.436,0,0,0,1.129-.525l3.34-3.287L10.187-16,2.812-8.566A1.527,1.527,0,0,0,2.812-6.368Z"
        transform="translate(-0.22 27.405)"
        fill="#182844"
      />
    </svg>
  );
};

export { EraseIcon };
