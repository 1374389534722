import {
  Alert,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Space,
  Spin,
  Typography,
} from "antd";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios-instance";
import useWindowWidth from "../../config/useWindowWidth";
import LazyImg from "../Library/LazyImage";
import watermak from "../../img/watermark.png";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import React, { useState, useEffect } from "react";
import {
  deleteCustomizedProduct,
  orderNewCustomizedProduct,
} from "../../actions/order-actions/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  FilterOutlined,
  ClearOutlined,
  EyeOutlined,
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import {
  Drawer,
  Tabs,
  Row,
  Collapse,
  Col,
  Select,
  Modal,
  Popover,
  Input,
  Popconfirm,
} from "antd";
import { useForm, Controller } from "react-hook-form";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import Meta from "antd/lib/card/Meta";
import moment from "moment";
import { default as SelectCollaborator } from "react-select";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { useSelector } from "react-redux";
import Bag from "../../icons/Bag";
import { Spinner } from "../Spinner";
import { motion } from "framer-motion";
import avatar_placeholder from "../../img/pdf_placeholder.png";

const { Title } = Typography;
const { Panel } = Collapse;
const { Option, OptGroup } = Select;

export default function Moodboards() {
  const { register, control, handleSubmit, errors, setValue, getValues } =
    useForm<any>();
  const [productsToOrder, setProductsToOrder] = useState([]);
  const [moodboard, setMooadboard] = useState<Array<any>>([]);
  const [products, setProducts] = useState([]);

  const [type, setType] = useState("new");

  const [theme, setTheme] = useState("");
  const [year, setYear] = useState("");

  const [saisson, setSaisson] = useState("");

  const [division, setDivision] = useState("");
  const [file, setFile] = useState("");
  const [cover, setCover] = useState("");

  const [id, setId] = useState("");
  const [preview, setPreview] = useState("");
  const [done, setDone] = useState(false);
  const [date_delivery, setDeliveryDate] = useState("");
  const [saison, setSaison] = useState("");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [gender, setGender] = useState("");

  const [disable, setDisable] = useState(false);
  const [collection_name, setOrderName] = useState("");
  const [orderDetails, setOrderDetails] = useState("");
  const [collaborator, setCollaborator] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const [loadingForYou, setloadingForYou] = useState(false);
  const [loadingMoodboard, setloadingMoodboard] = useState(false);

  const [open, setOpen] = useState(false);

  const { collaborators, newOrder, orders, createOrderLoading } = useSelector(
    (state: any) => state.orders
  );

  const handleCollabChange = (e: any) => {
    let arryColl: any = [];
    e &&
      e.map((el: any) => {
        arryColl.push(el.value);
      });
    setCollaborator(arryColl);
  };
  const showDrawer = (el: any) => {
    console.log(el);
    setTheme(el?.theme);
    setYear(el?.year);
    setId(el.id);
    setSaisson(el?.saisson);

    setDivision(el?.division);
    setFile(el?.file_storage);
    setCover(el?.cover);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  function handleChangeSeason(e: any) {
    setSaison(e.target.value);
  }

  const orderNow = (productsToOrder: any) => {
    if (date_delivery != "" && collection_name != "") {
      setDisable(true);
      let token = localStorage.getItem("token");
      if (productsToOrder.length == 1) {
        axiosInstance({
          method: "post",
          url: `/orders/myselection`,
          data: {
            products: productsToOrder,
            collection_name,
            date_delivery,
            collaborator,
            saison,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(function (response) {
          // window.location.href = `/my-orders/demco-product-orders/in-progress/${response?.data?.id}/${productsToOrder[0].id}/progress`;
          window.location.href = `/my-orders/demco-product-orders/in-progress`;
        });
      } else {
        axiosInstance({
          method: "post",
          url: `/orders/myselection`,
          data: {
            products: productsToOrder,
            collection_name,
            date_delivery,
            collaborator,
            saison,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(function (response) {
          window.location.href = `/my-orders/demco-product-orders/in-progress`;
        });
      }
      setDisable(false);
    } else {
      setIsRequired(true);
    }
  };
  const getMyCustomizedProducts = async () => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      url: "/customized_products/getdraft",
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const queryClient = useQueryClient();

  const fetchMoadboard = () => {
    setloadingMoodboard(true);
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/moodboards`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setMooadboard(res.data);
      setloadingMoodboard(false);
    });
  };

  const fetchForYouProducts = () => {
    setloadingForYou(true);
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/clients/me/products`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setProducts(res.data.data);
      setloadingForYou(false);
    });
  };

  useEffect(() => {
    fetchMoadboard();
    fetchForYouProducts();
  }, []);
  const errorBorder = (field: string) => {
    return {
      borderBottom: errors[field]?.type === "required" ? "1px 1px red" : "",
      boxShadow:
        errors[field]?.type === "required"
          ? "0 0 0 2px rgb(255,0,0, 0.4)"
          : "none",
    };
  };

  const onSubmit = async (data: any) => {
    let token = localStorage.getItem("token");

    if (type == "new") {
      setDone(true);
      let formData = new FormData();

      Object.keys(data).map((el: string) => {
        formData.append(el, data[el]);
      });
      const { data: response } = await axiosInstance({
        method: "post",
        url: "/moodboards",
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDone(false);
      await MyNotif("Moodboard published successfully.", "success");
      fetchMoadboard();
      onClose();
      /*    if (response.status == 200) {
        setDone(false);
        await MyNotif("Moodboard published successfully.", "success");
        fetchMoadboard();
        onClose();
      } */

      return response.data;
    } else {
      //  setDone(true);

      let formData2 = new FormData();
      Object.keys(data).map((el: string) => formData2.append(el, data[el]));

      const { data: response } = await axiosInstance({
        method: "post",
        url: "/moodboard/" + id,
        data: formData2,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDone(false);
      await MyNotif("Moodboard edited successfully.", "success");
      fetchMoadboard();
      onClose();
      /*   if (response.status == 200) {
        setDone(false);
        await MyNotif("Moodboard edited successfully.", "success");
        fetchMoadboard();
        onClose();
      } */
      /*    fetchMoadboard();
      onClose(); */
      return response.data;
    }
  };

  //lunches to get the products for you of the user's fabric type

  const deleteMoodboard = async (id: any) => {
    let token = localStorage.getItem("token");
    const { data: response } = await axiosInstance({
      method: "delete",
      url: "/moodboards/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await MyNotif("Moodboad Delete successfully.", "success");
    fetchMoadboard();
    onClose();
    return response.data;
  };
  const handleRemoveProduct = (itemToRemove: any) => {
    const updatedProducts = productsToOrder.filter(
      (item: any) => item !== itemToRemove
    );
    setProductsToOrder(updatedProducts);
  };

  function handleChangeOrderName(e: any) {
    setOrderName(e.target.value);
  }

  const content = (el: any) => {
    return (
      <div style={{ padding: "15px" }}>
        <button
          onClick={() => {
            setType("edit");
            showDrawer(el);
          }}
        >
          <EditOutlined style={{ marginRight: "7px" }} />
          Edit
        </button>
        <br />
        <Popconfirm
          title="Are you sure, you want to delete this moodboard  ?"
          onConfirm={() => deleteMoodboard(el.id)}
        >
          <button>
            {" "}
            <DeleteOutlined style={{ marginRight: "5px" }} /> Delete
          </button>
        </Popconfirm>
      </div>
    );
  };

  const genExtra = (el: any) => (
    <>
      <span style={{ marginRight: "15px" }}>
        <a
          target="_blank"
          href={process.env.REACT_APP_STORAGE_URL + el.file_storage}
          style={{ color: "#00468c" }}
        >
          <EyeOutlined
            style={{
              fontSize: "25px",
            }}
          />
        </a>
      </span>
      <Popover placement="top" content={content(el)} trigger="click">
        <MoreOutlined
          style={{
            fontSize: "25px",
          }}
        />
      </Popover>
    </>
  );

  return (
    <div
      style={{ padding: "20px", paddingBottom: "50px", height: "100vh" }}
      className={"selection_container"}
    >
      <div className="row" style={{ justifyContent: "space-between" }}>
        <div>
          {/*  <Title level={2} style={{ color: "#00468c", marginBottom: "0px" }}>
            Moodboards
          </Title>*/}
          {/*           <h4 className={"title_myselection"}> My Moodboards</h4>
           */}{" "}
        </div>
        <div>
          <div>
            {/* <button
              className="selectAll"
              onClick={() => {
                setProductsToOrder(products);
                setIsOpenDrawer(true);
                //openDrawer();
              }}
            >
              <Bag />{" "}
              <span style={{ marginLeft: "10px" }}>Order Collection</span>
            </button>*/}

            <button
              className="addToCollectionMySelection"
              onClick={() => {
                setType("new");
                showDrawer("new");
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span style={{ marginLeft: "10px" }} className={"text_button"}>
                Add new Moodboard
              </span>
            </button>
          </div>
        </div>
      </div>
      <div>
        {/*    <Row>
          <Swiper
            slidesPerView={4.5}
            spaceBetween={30}
            navigation={true}
            pagination={true}
            className="mySwiper"
            style={{ overflow: "hidden" }}
          >
            {moodboard?.slice(0, 9)?.map((el: any) => {
              return (
                <>
                  <SwiperSlide>
                    {" "}
                    <Collapse
                      defaultActiveKey={el.id}
                      className="options_collapse_details mt-4"
                      accordion={true}
                    >
                      <Panel
                        header={
                          el.saisson +
                          " " +
                          el.year +
                          " " +
                          el.division +
                          " " +
                          ": " +
                          el.theme
                        }
                        key={1}
                        extra={genExtra(el)}
                      >
                        <iframe
                          src={
                            process.env.REACT_APP_STORAGE_URL + el.file_storage
                          }
                          width={"100%"}
                          height={400}
                        ></iframe>
                      </Panel>
                    </Collapse>
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </Row> */}

        {loadingMoodboard ? (
          <div>
            <Spinner></Spinner>
          </div>
        ) : (
          <>
            <div
              className="list_products "
              style={{ gap: "16px", paddingTop: "25px" }}
            >
              {moodboard?.slice(0, 9)?.map((el: any, index: number) => {
                return (
                  <div className={"post_card"} key={index}>
                    <Card
                      hoverable
                      style={{ maxWidth: 400 }}
                      cover={
                        <>
                          <div>
                            <button
                              onClick={() => {
                                setType("edit");
                                showDrawer(el);
                              }}
                              style={{
                                right: "30px",
                                top: "5px",
                                position: "absolute",
                              }}
                            >
                              <EditOutlined
                                style={{
                                  fontSize: "16px",
                                }}
                              />
                            </button>
                            <Popconfirm
                              title="Are you sure, you want to delete this moodboard  ?"
                              onConfirm={() => deleteMoodboard(el.id)}
                            >
                              <button
                                style={{
                                  right: "5px",
                                  top: "5px",
                                  position: "absolute",
                                }}
                              >
                                {" "}
                                <DeleteOutlined
                                  style={{
                                    fontSize: "16px",
                                  }}
                                />
                              </button>
                            </Popconfirm>

                            <a
                              href={
                                process.env.REACT_APP_STORAGE_URL +
                                el.file_storage
                              }
                              target="_blank"
                            >
                              <img
                                src={
                                  el.file
                                    ? process.env.REACT_APP_STORAGE_URL +
                                      el?.cover
                                    : avatar_placeholder
                                }
                                className="imgCover"
                              />
                            </a>
                          </div>
                        </>
                      }
                    >
                      <Meta
                        title={
                          el.theme && (
                            <>
                              {" "}
                              <a
                                href={
                                  process.env.REACT_APP_STORAGE_URL +
                                  el.file_storage
                                }
                                target="_blank"
                                style={{ textDecoration: "none" }}
                                className={"link_moodboard"}
                              >
                                <div className="masonry_item_description">
                                  <span className={"theme"}>
                                    {el.theme}{" "}
                                    <span className="text-muted">
                                      -{el.year}
                                    </span>
                                  </span>
                                </div>
                              </a>
                            </>
                          )
                        }
                      />
                    </Card>
                  </div>
                );
              })}
            </div>
          </>
          /*
                      <Row gutter={[16, 8]} style={{ marginBottom: "25px" }}>
                        {moodboard?.slice(0, 9)?.map((el: any) => {
                          return (
                            <>
                              <Col className="gutter-row" span={8}>
                                <Collapse
                                  defaultActiveKey={el.id}
                                  className="options_collapse_details mt-4"
                                  accordion={true}
                                >
                                  <Panel
                                    header={

                                      el.year +
                                      " " +

                                      ": " +
                                      el.theme
                                    }
                                    key={1}
                                    extra={genExtra(el)}
                                  >
                                    <iframe
                                      src={
                                        process.env.REACT_APP_STORAGE_URL + el.file_storage
                                      }
                                      width={"100%"}
                                      height={400}
                                    ></iframe>
                                  </Panel>
                                </Collapse>
                              </Col>
                            </>
                          );
                        })}
                      </Row>
            */
        )}
        {/*  {loadingForYou ? (
          <div className="products-grid ">loading</div>
        ) : (
          <div className="products-grid " style={{ paddingBottom: "200px" }}>
            {products?.map((el: any, i: number) => (
              <div key={el.id}>
                <CustomizedCard
                  product={{
                    front: el.product_front_image,
                    back: el.product_back_image,
                    name: el.name,
                    code: el.product_code,
                    id: el.id,
                  }}
                  setProductsToOrder={setProductsToOrder}
                  productsToOrder={productsToOrder}
                />
              </div>
            ))}
          </div>
        )}*/}
        {productsToOrder.length > 0 && (
          <Alert
            message={`${productsToOrder.length} Product(s) selected`}
            type="info"
            style={{
              position: "fixed",
              left: "50%",
              //    top: "100%",

              bottom: "-12px",
              width: "30vw",
              borderRadius: "8px",
              border: "2px solid #809ec2",
              color: "#00468c",
              backgroundColor: "#f4f7ff",
              minWidth: "400px",
              transform: "translate(-50%,-50%)",
              zIndex: 20,
            }}
            action={
              <Space direction="horizontal">
                <Button
                  size="middle"
                  type="primary"
                  style={{
                    background: "#00468c",
                    borderColor: "#00468c",
                    borderRadius: "5px",
                  }}
                  onClick={() => setIsOpenDrawer(true)}
                  // loading={orderLoading}
                >
                  Order now
                </Button>
              </Space>
            }
            closable
            onClose={() => setProductsToOrder([])}
          />
        )}
      </div>

      {open && (
        <div style={{ width: "100px" }}>
          <Drawer
            title={type == "new" ? "Add new moodboard" : "Edit Moodboard"}
            placement={"bottom"}
            closable={true}
            onClose={onClose}
            visible={open}
            key={"bottom"}
            style={{ zIndex: 1050 }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container mt-6">
                <div className="form_add_folder">
                  <div style={{ width: "80%" }} className="container-fluid">
                    <p
                      className="text-center add_order_drawer_subtitle "
                      style={{ marginTop: "7px" }}
                    >
                      {type == "new"
                        ? "Enter your moodboard information"
                        : "Edit your moodboard information"}
                    </p>
                    <div className="row">
                      <div className=" col-md-6 col-12">
                        <div className="row">
                          <div className="col-12 mb-4">
                            <div className="field field_article field_article_name">
                              <Controller
                                name="theme"
                                defaultValue={type == "new" ? "" : theme}
                                control={control}
                                render={(field) => (
                                  /*<Input
                                        {...field}
                                        size="large"
                                        type="text"
                                        style={errorBorder("theme")}
                                      />*/
                                  <>
                                    <div className="field">
                                      <input
                                        {...field}
                                        type="text"
                                        name="moodboard"
                                        id="moodboard"
                                        placeholder="Name of Moodboard"
                                      />
                                      <label
                                        htmlFor="moodboard"
                                        className="login_email_label"
                                      >
                                        Name of Moodboard
                                      </label>
                                    </div>
                                  </>
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_add_article_left col-md-6 col-12">
                        <div className="row">
                          <div className="col-12 mb-4">
                            <div className="field">
                              <Controller
                                name="year"
                                defaultValue={type == "new" ? "" : year}
                                control={control}
                                render={(field) => (
                                  /*  <Input
                                          {...field}
                                          size="large"
                                          type="text"
                                          style={errorBorder("year")}
                                        />*/
                                  <div className="field">
                                    <input
                                      {...field}
                                      type="text"
                                      name="year"
                                      id="year"
                                      placeholder="Season"
                                    />
                                    <label
                                      htmlFor="year"
                                      className="login_email_label"
                                    >
                                      Season
                                    </label>
                                  </div>
                                )}
                                rules={{ required: true }}
                              />
                              {/* <label htmlFor="year" className="folder_label">
                                Season
                              </label>*/}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*    <div className="col-6 p-4 field field_article">
                        <div className="d-flex flex-column mt-3">
                          <label>
                           Cover
                            {type == "edit" && (
                                <span style={{opacity: 0.4}}>
                                (if not changed you previous file will be
                                attached)
                              </span>
                            )}
                          </label>
                          <Controller
                              name="file"
                              control={control}
                              render={(field) => (
                                  <Input
                                      type="file"
                                      {...field}
                                      onChange={(e: any) => {
                                        setValue("cover", e.target.files[0]);

                                      }}
                                      style={errorBorder("cover")}
                                      size="large"
                                      value={getValues("cover")?.filename}
                                  />
                              )}
                              rules={
                                cover ? {required: false} : {required: true}
                              }
                          />
                        </div>
                      </div>*/}
                      <div className="col-12 p-4 field field_article">
                        <div className="d-flex flex-column mt-3">
                          <label className="login_email_label">
                            File (PDF) MAX Size : 200 MB{" "}
                            {type == "edit" && (
                              <span
                                style={{ opacity: 0.4 }}
                                className={"login_email_label"}
                              >
                                (if not changed you previous file will be
                                attached)
                              </span>
                            )}
                          </label>
                          <Controller
                            name="file"
                            control={control}
                            render={(field) => (
                              <Input
                                type="file"
                                {...field}
                                onChange={(e: any) => {
                                  setValue("file", e.target.files[0]);
                                  // setPreview(URL.createObjectURL(e.target.files[0]));
                                  setPreview(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                }}
                                style={errorBorder("file")}
                                size="large"
                                value={getValues("file")?.filename}
                              />
                            )}
                            rules={
                              file ? { required: false } : { required: true }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Row
                  gutter={[48, 8]}
                  style={{ paddingRight: 135, paddingLeft: 135 }}
                >
                  <Col span={12}>
                    {" "}
                    {getValues("file")?.name.split(".")[1] == "pdf" && (
                      <>
                        <iframe
                          src={preview}
                          frameBorder="0"
                          height="500px"
                          width="500px"
                        ></iframe>
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    {" "}
                    <div style={{ width: "100%" }} className="text-right">
                      <button
                        className={
                          done ? "submitButtonWaiting" : "submitButton"
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </Drawer>

          {/*    <Modal
            title="Basic Modal"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
          </Modal> */}
        </div>
      )}

      <Drawer
        // className="order_sent_drawer"
        placement={"bottom"}
        closable={true}
        onClose={() => {
          setProductsToOrder([]);
          setIsOpenDrawer(false);
        }}
        visible={isOpenDrawer}
        key={"bottom"}
        style={{ paddingTop: "25px" }}
      >
        <div className="row">
          {/* left container */}

          <div
            className="col-6 "
            style={{
              paddingTop: "88px",
              paddingLeft: "112px",
              paddingRight: "112px",
            }}
          >
            <div className="titledrawerConfirm">Selected products</div>
            <div style={{ fontSize: "16px", marginBottom: "32px" }}>
              Please review your selected items below before confirming your
              order.
            </div>
            {productsToOrder.length > 0 ? (
              <div
                className="border"
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#F8FAFC",
                  padding: "12px",
                }}
              >
                {productsToOrder.map((item: any, key: any) => {
                  return (
                    <>
                      <div
                        className="row rowProdcut"
                        style={{ position: "relative" }}
                      >
                        <div
                          style={{
                            borderRadius: "8px",
                            marginRight: "16px",
                          }}
                        >
                          <img
                            src={process.env.REACT_APP_STORAGE_URL + item.front}
                            width="100px"
                            height="72px"
                            className="orderBoxImage"
                          />
                        </div>
                        <div className="">
                          <span className="titleorderContainerH1">
                            {item.name}
                          </span>
                          <br></br>
                          <span className="titleorderContainerH2">
                            {item.code}
                          </span>
                        </div>
                        <div style={{ position: "absolute", right: "0" }}>
                          <button
                            onClick={() => {
                              handleRemoveProduct(item);
                              if (productsToOrder.length == 1) {
                                setProductsToOrder([]);
                                setIsOpenDrawer(false);
                              }
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "5px",
                              backgroundColor: "#E5ECF3",
                              padding: "16px",
                              width: "32px",
                              height: "32px",
                            }}
                          >
                            <DeleteOutlined />
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            ) : (
              <>The list is empty </>
            )}
          </div>
          <div
            className="col-6 "
            style={{
              paddingTop: "88px",
              paddingLeft: "112px",
              paddingRight: "112px",
            }}
          >
            {" "}
            <div className="titledrawerConfirm">Order details</div>
            <div style={{ fontSize: "16px", marginBottom: "32px" }}>
              Complete your order by providing the details below.
            </div>
            <div>
              <label className="mr-3 mb-1">Collection name</label>
              <Input
                type="text"
                style={{
                  borderRadius: "5px",
                  marginBottom: "16px",
                }}
                onChange={handleChangeOrderName}
              ></Input>
              {isRequired && collection_name === "" && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
            <label className="mr-3 mb-1">Season</label>
            <Input
              type="text"
              style={{
                borderRadius: "5px",
                marginBottom: "16px",
              }}
              defaultValue={saison}
              onChange={handleChangeSeason}
            ></Input>
            <label className="mr-3 mb-0">Select Collaborators</label>
            <SelectCollaborator
              isMulti
              name="colors"
              placeholder="Select your collaborators"
              options={
                collaborators &&
                collaborators.map((el: any) => {
                  return {
                    label: el.name,
                    value: el.id,
                  };
                })
              }
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleCollabChange}
            />
            <div>
              <label className="mr-3 mb-0 mt-3">Delivery date</label>
              <DatePicker
                disabledDate={(current) =>
                  current.isBefore(moment().add(15, "days"))
                }
                format={"DD/MM/YYYY"}
                onChange={(date: any, dateString: any) =>
                  setDeliveryDate(dateString)
                }
                style={{
                  borderRadius: "5px",
                  width: "100%",
                  marginBottom: "16px",
                }}
              />

              {isRequired && date_delivery === "" && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
            <div>
              <button
                className="view-details"
                onClick={() => orderNow(productsToOrder)}
                disabled={disable}
                style={{ marginTop: "16px" }}
              >
                {disable ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 24, color: "white" }}
                        spin
                      />
                    }
                  />
                ) : (
                  <>Confirm order</>
                )}
              </button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

const CustomizedCard = ({
  product,
  setProductsToOrder,
  productsToOrder,
}: any) => {
  const width = useWindowWidth();
  const history = useHistory();

  const toggle = () => {
    const id = product.id;
    const isExist = productsToOrder.find((el: any) => el.id === id);

    let updatedList = [...productsToOrder];
    if (!isExist) {
      updatedList = [...productsToOrder, product];
    } else {
      updatedList.splice(productsToOrder.indexOf(id), 1);
    }
    setProductsToOrder(updatedList);
  };

  return (
    <Card
      style={{
        width: width < 1200 ? "100%" : "14rem",
        // padding: 15,
        paddingTop: 15,
        borderRadius: "16px",
      }}
      bordered={true}
      // bodyStyle={{ padding: "0px" }}
      className="grid-item-product"
      cover={
        <div className={`product_item_new mb-2`}>
          <LazyImg
            alt={product.name}
            style={{
              position: "absolute",
              zIndex: 2,
              opacity: 0.2,
              objectFit: "contain",
              width: "100%",
              height: "260px",
            }}
            src={watermak}
            thumb={watermak}
          />
          <LazyImg
            className="img-front"
            alt={product?.name}
            style={{
              objectFit: "contain",
              width: "100%",
              height: "260px",
            }}
            src={
              product?.front
                ? process.env.REACT_APP_STORAGE_URL + product?.front
                : placeholder_jeans
            }
            thumb={
              product?.front
                ? process.env.REACT_APP_STORAGE_URL + product?.front
                : placeholder_jeans
            }
          />
          <LazyImg
            style={{ height: "260px", objectFit: "contain" }}
            className="img-back"
            alt={product?.name}
            src={
              product?.back
                ? process.env.REACT_APP_STORAGE_URL + product?.back
                : placeholder_jeans
            }
            thumb={
              product?.back
                ? process.env.REACT_APP_STORAGE_URL + product?.back
                : placeholder_jeans
            }
          />
        </div>
      }
    >
      <Meta
        description={
          <div
            className="d-flex mt-2 justify-content-between"
            style={{ height: "60px" }}
          >
            <div className="labels_list">
              <div
                /*     onClick={() =>
                  history.push(`/customization/edit/${product.id}`)
                } */
                style={{ cursor: "pointer" }}
              >
                <h4 className="h4_medium mb-0" style={{ fontSize: "1rem" }}>
                  {product?.code}
                </h4>
                <h4
                  className="h4_medium"
                  style={{ fontWeight: 400, fontSize: "1rem" }}
                >
                  {product?.name}
                </h4>
              </div>
            </div>
            <div className="align-self-center">
              <Checkbox
                onChange={toggle}
                //  checked={productsToOrder.includes(product.id)}
                checked={productsToOrder.some((p: any) => p.id === product.id)}
              />
            </div>
          </div>
        }
      />
    </Card>
  );
};
