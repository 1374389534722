import "./DeletedItems.scss";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Trash from "../../icons/Trash";
import GetBack from "../../icons/GetBack";
import Folders from "../../icons/Folders";
import Folder from "../../icons/Folder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { handleBottomDrawerDeletedItems } from "../../actions/actionCreators";
import {
  getArchivedFolders,
  restoreFolder,
  deleteFolder,
} from "../../actions/folders-actions/actions";
import { Popconfirm, message } from "antd";

export interface IDeletedItemsProps {
  // setMode: ((mode: string) => void) | undefined;
}

export default function DeletedItems(props: IDeletedItemsProps) {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const folders = useSelector((state: any) => state.folders);
  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  useEffect(() => {
    dispatch(getArchivedFolders());
  }, [folders.msg]);
  useEffect(() => {
    if (folders.archivedFolders.length === 0) {
      dispatch(handleBottomDrawerDeletedItems());
    }
  }, [folders.archivedFolders]);
  const variants = {
    enter: { y: height },
    center: { y: 0 },
    exit: { y: height },
  };
  return (
    <motion.div
      className="deleted_items_container"
      transition={{ type: "tween" }}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      <div
        className="close_btn"
        onClick={() => {
          dispatch(handleBottomDrawerDeletedItems());
        }}
      >
        <p>Close</p>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className="deleted_items_title_container">
        <p className="deleted_items_title">Deleted Items</p>
      </div>

      <div className="container-fluid">
        {width > 575 && (
          <div className="row items_titles">
            <div
              className={
                width <= 1024 ? "col-4 item_col_title" : "col-3 item_col_title"
              }
            >
              Items
            </div>
            <div className="col-3 item_col_title">Archived on</div>
            <div className="col-3 item_col_title">Deleted on</div>
            <div
              className={
                width <= 1024 ? "col-2 item_col_title" : "col-3 item_col_title"
              }
            >
              Actions
            </div>
          </div>
        )}
        <div className="container-fluid list_items">
          {folders.archivedFolders.map((el: any) => (
            <div className="row item_container">
              {width <= 575 ? (
                <>
                  <div className="col-7 ">
                    <div className=" item_element item_element_name ">
                      {el.folders_count > 0 ? <Folders /> : <Folder />}
                      <p>{el.name}</p>
                    </div>
                    <div className=" item_element item_element_archived_at">
                      {el.updated_at.substr(0, 10)}
                    </div>
                    <div className=" item_element item_element_removed_on">
                      {el.delete_time.substr(0, 10)}
                    </div>
                  </div>

                  <div className="col-5 item_element item_element_actions">
                    <Popconfirm
                      title="Are you sure to delete this folder?"
                      onConfirm={() => {
                        dispatch(deleteFolder(el.id));
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button>
                        <Trash />
                        {width > 1024 && "Delete"}
                      </button>
                    </Popconfirm>

                    <Popconfirm
                      title="Are you sure to restore this folder?"
                      onConfirm={() => {
                        dispatch(restoreFolder(el.id));
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button>
                        <FontAwesomeIcon icon={faRedoAlt} />
                        {width > 1024 && "Restore"}
                      </button>
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div
                    className={
                      width <= 1024
                        ? "col-4 item_element item_element_name "
                        : "col-3 item_element item_element_name "
                    }
                  >
                    {el.folders_count > 0 ? <Folders /> : <Folder />}
                    <p>{el.name}</p>
                  </div>
                  <div className="col-3 item_element ">
                    {el.updated_at.substr(0, 10)}
                  </div>
                  <div className="col-3 item_element item_element_removed_on">
                    {el.delete_time.substr(0, 10)}
                  </div>
                  <div
                    className={
                      width <= 1024
                        ? "col-2 item_element item_element_actions"
                        : "col-3 item_element item_element_actions"
                    }
                  >
                    <Popconfirm
                      title="Are you sure to delete this folder?"
                      onConfirm={() => {
                        dispatch(deleteFolder(el.id));
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button>
                        <Trash />
                        {width > 1024 && "Delete"}
                      </button>
                    </Popconfirm>

                    <Popconfirm
                      title="Are you sure to restore this folder?"
                      onConfirm={() => {
                        dispatch(restoreFolder(el.id));
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button>
                        <FontAwesomeIcon icon={faRedoAlt} />
                        {width > 1024 && "Restore"}
                      </button>
                    </Popconfirm>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}
