import { Collapse, InputNumber, Select } from "antd";
import { useSelector } from "react-redux";

const { Panel } = Collapse;
const { Option } = Select;

const TechnicsCollapse = ({
  index,
  tech,
  productToUpdate,
  genExtraForTech,
  setProductToUpdate,
  setData,
  data,
  options,
  handleChangeTech,
}: any) => {
  const { user } = useSelector((state: any) => state.auth);
  function comparer(otherArray: any) {
    return function (current: any) {
      return (
        otherArray.filter(function (other: any) {
          return other.id == current.id;
        }).length == 0
      );
    };
  }

  return (
    <Collapse defaultActiveKey={["0"]} key={index + 2}>
      <Panel
        header={tech.name || `Technic num ${index + 1}`}
        key={index}
        extra={genExtraForTech(index)}
      >
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <th>Technic</th>
              <td>
                <Select
                  disabled={!user.permissions.includes("product.update")}
                  value={tech.name}
                  style={{ width: "100%" }}
                  placeholder="Select Type of Fabric"
                  onChange={(val: any) => {
                    setProductToUpdate({
                      ...productToUpdate,
                      techniques: productToUpdate.techniques.map(
                        (technique: any) =>
                          technique.id === tech.id ? JSON.parse(val) : technique
                      ),
                    });
                    setData({
                      ...data,
                      technics: data.technics.map((technic: any) =>
                        technic.technic_id === tech.id
                          ? {
                              ...technic,
                              technic_id: JSON.parse(val).id,
                            }
                          : technic
                      ),
                    });
                  }}
                >
                  {options.technics_options &&
                    options.technics_options
                      .filter(comparer(productToUpdate.techniques))
                      .map((el: any) => (
                        <Option value={JSON.stringify(el)} key={el.id}>
                          {el.name}
                        </Option>
                      ))}
                </Select>
              </td>
            </tr>
            <tr>
              <th>Price</th>
              <td>
                <InputNumber
                  disabled={!user.permissions.includes("product.update")}
                  style={{ width: "100%" }}
                  min={0}
                  defaultValue={tech.price}
                  name="technic_price"
                  onChange={(num: number) => handleChangeTech(num, tech.id)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Panel>
    </Collapse>
  );
};

export default TechnicsCollapse;
