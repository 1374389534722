import React from "react";
import { useDispatch } from "react-redux";
import { handleRightDrawer } from "../../actions/actionCreators";
import moment from "moment";
import Folder from "../../icons/Folder";
import { Link } from "react-router-dom";

interface Props {
  notif: any;
  inPage: boolean;
  position: boolean;
}

export const NewCollectionDemco = ({ inPage, notif, position }: Props) => {
  const dispatch = useDispatch();
  return inPage ? (
   /*  <div className="notif_item_page p-2 my-2">
      <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center icon_notif_container">
          <Folder />
        </div>
        <h2 className="h4_medium m-0">
          A new collection has just been Uploaded
        </h2>
      </div>
      <p className="selection_date m-0">{moment(notif.created_at).fromNow()}</p>
    </div> */

    <article
    className="notif_selection notif_added_prod_container my-2 border p-3"
    style={{
      borderRadius: "8px",
      background: position ? "#e5f1fa" : "white",
      transition: "all 5s ease-in-out",
    }}
  >
   {/*  <LazyImg
      src={imageToDisplay()}
      style={{
        width: "5.5rem",
        height: "5.5rem",
        objectFit: "cover",
        borderRadius: "8px",
      }}
      className="added_product_img"
      alt={notif.data.name}
    /> */}
    <div className="notif_details">

    
      <h2 className="h4_medium mb-0 mx-2">Our newest collection is out now! 🏃‍♀️🏃‍♂️</h2>
      
      <div className="notif_details m-0">

        <p
          className="selection_date mb-0"
          style={{ fontSize: "0.7rem", fontWeight: 400 }}
        >
          {moment(notif.created_at).fromNow()}
        </p>
      </div>
    </div>
  </article>
  ) : (
    <Link
    to={`collection/${notif?.data?.id}/${notif?.data?.type}`}
 
    onClick={() => dispatch(handleRightDrawer())}
     
      target="_blank"
    >
    <div
      className="notif_added_prod_container"
      onClick={() => dispatch(handleRightDrawer())}
      style={{
        borderRadius: "8px",
        background: position ? "#e5f1fa" : "white",
        transition: "all 5s ease-in-out",
      }}
    >
        <article
    className="notif_selection notif_added_prod_container my-2 border p-3"
    style={{
      borderRadius: "8px",
      background: position ? "#e5f1fa" : "white",
      transition: "all 5s ease-in-out",
    }}
  >
   {/*  <LazyImg
      src={imageToDisplay()}
      style={{
        width: "5.5rem",
        height: "5.5rem",
        objectFit: "cover",
        borderRadius: "8px",
      }}
      className="added_product_img"
      alt={notif.data.name}
    /> */}
    <div className="notif_details">
  {/*     <h2 className="h4_medium">
        New {fabric ? "fabric" : "product"} added by DEMCO
      </h2> */}
     
    
      <h2 className="h4_medium mb-0 mx-2">Our newest collection is out now! 🏃‍♀️🏃‍♂️</h2> 
      
      <div className="notif_details m-0">

        <p
          className="selection_date mb-0"
          style={{ fontSize: "0.7rem", fontWeight: 400 }}
        >
          {moment(notif.created_at).fromNow()}
        </p>
      </div>
    </div>
  </article>
    </div>
    </Link>
  );
};
