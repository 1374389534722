import {
  Drawer,
  Avatar,
  Divider,
  Button,
  Result,
  Spin,
  Form,
  Row,
  Col,
  Input,
  Select,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../../config/axios-instance";
import { useDropzone } from "react-dropzone";
import "../../components/Library/StyleLibarby.css";
import {
  DeleteOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import CategoriesServices from "../../actions/left-menu-actions/services";

import { useMutation, useQuery } from "react-query";
import { MyNotif } from "../NotifIcons/NotifIcons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import AddNewCompany from "./AddNewCompany";
const { Option, OptGroup } = Select;

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

interface IData {
  fullname: string;
  email: string;
  title: string;
  logo: string;
  genders: number[];
}

const AddNewClient = ({ onClose, visible, refetch }: any) => {
  const [data, setData] = useState<any>();
  const [isVisible, setIsVisible] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [typeFilter, setTypeFilter] = useState("denim");
  const [visibleAdd, setVisibleAdd] = useState(false);

  const [files, setFiles] = useState<any>([]);
  

 


 
  const { register, control, handleSubmit, errors, setValue, getValues } =
    useForm<any>();
  const width = useBreakpoint();
  const params = useParams<any>();
  const { user, isLoading, isAdmin } = useSelector((state: any) => state.auth);

  const onCloseAdd = () => {
    setVisibleAdd(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values: IData) => {
    console.log("Success:", values);
    // addCompany(values);
    sendInvitation(values);
  };

  const { data: clients, isLoading: gendersIsLoading } = useQuery(
    "clients",
    CategoriesServices.getClients
  );
  const errorBorder = (field: string) => {
    return {
      borderBottom: errors[field]?.type === "required" ? "1px 1px red" : "",
      boxShadow:
        errors[field]?.type === "required"
          ? "0 0 0 2px rgb(255,0,0, 0.4)"
          : "none",
    };
  };
  const addNewComapany = (data: any) => {
    let token = localStorage.getItem("token");

    console.log(data)
    let formData = new FormData();
    Object.keys(data).map((el: string) => formData.append(el, data[el]));
    if (params.id) {
      return axiosInstance({
        method: "post",
        //   url: `/clients/me/company/inviteMembers/${params.id}/${user.type}`,
        url: "/admin/addNewCompanyClient",
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      return axiosInstance({
        method: "post",
        url: "/admin/addNewCompanyClient",
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        MyNotif("Company added successfully.", "success");
        onClose();
        refetch();
      })
      .catch((err) => {
        console.log(err.response.data.errors.email);
        MyNotif(err.response.data.errors.email, "danger");
       /*   setRequestError(err); */
      });
    }
  };

  const inviteMember = (data: any) => {
    let token = localStorage.getItem("token");
    console.log(data);
    let formData = new FormData();
    Object.keys(data).map((el: string) => formData.append(el, data[el]));

    return axiosInstance({
      method: "post",
      url: `/clients/me/company/invite`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { mutateAsync: sendInvitation, isLoading: invitationLoading } =
    useMutation(inviteMember, {
      onSuccess: () => {
        MyNotif("Invitation sent.", "success");
        onClose();
        refetch();
      },
      onError: (err: any) => {
        MyNotif(err.response.data.errors.email[0], "danger");
      },
    });

  const { data: genders } = useQuery("genders", CategoriesServices.getGenders);

  const getCompany = () => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "get",
      url: `/admin/companies`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    refetch: refetch2,
  } = useQuery("companies", getCompany, {
    refetchOnWindowFocus: false,
  });

  /*  const { mutateAsync: addCompany, isLoading: invitationLoading } =
    useMutation(addNewComapany, {
      onSuccess: () => {
        MyNotif("Invitation sent.", "success");
        onClose();
        refetch();
      },
      onError: (err: any) => {
        MyNotif(err.response.data.errors.email[0], "danger");
      },
    }); */
  return (
    <>
      <Drawer
        //   title="Invite new member"
        placement={"right"}
        //   closable={true}
        onClose={onClose}
        closable={true}
        visible={visible}
        width={width.md ? "33%" : "100%"}
        bodyStyle={{ padding: 20, paddingBottom: 80, background: "#fcfdff " }}>
        <h1 className="add_new_client_title" >
          Add New Client
        </h1>

     
        <form
          className="invite-teammate-drawer p-3"
          onSubmit={handleSubmit(addNewComapany)}>
          <Row>

            <Col span={24}>
              <label htmlFor="Name" className="add_client_label">
                Full name
              </label>
              <Controller
                  name="name"
                  className="h2-blue-hj my-2 "
                  control={control}
                  render={(field) => (
                      <Input
                          {...field}
                          size="large"
                          type="text"
                          style={errorBorder("name")}
                          placeholder="Please enter name"
                       className="add_client_filed"
                      />
                  )}
                  rules={{required: true}}
              />
            </Col>
          </Row>
        {/*  <Controller
              name="full_name"  // Ensure this matches the name in your input field
              control={control}
              render={(field) => (
                  <div className="field">
                    <input
                        {...field}

                        type="text"
                    //   name="company" // This is managed by React Hook Form so you'll use field.name
                        id="company"
                        style={errorBorder("name")}
                        placeholder="Please enter name"
                    />
                    <label htmlFor="company" className="account_company_name_label">
                      Company Name
                    </label>
                  </div>
              )}
              rules={{ required: true }} // Apply your validation rules
          />*/}
          <Row className="mt-2">
            <Col span={24}>
              <label htmlFor="Name" className="add_client_label">
                Email address
              </label>
              <Controller
                  name="email"
                  className="h2-blue-hj my-2"
                  control={control}
                  render={(field) => (
                      <Input
                          {...field}
                          size="large"
                          type="text"
                          style={errorBorder("email")}
                          placeholder="Please enter email"
                          className="add_client_filed"
                      />
                  )}
                  rules={{required: true}}
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col span={24}>
              <label htmlFor="Name" className="add_client_label">
                Job title
              </label>
              <Controller
                name="job_title"
                className="h2-blue-hj my-2"
                control={control}
                render={(field) => (
                  <Input
                    {...field}
                    size="large"
                    type="text"
                    style={errorBorder("job_title")}
                    placeholder="Please enter job title"
                    className="add_client_filed"
                  />
                )}
                rules={{ required: true }}
              />
            </Col>
          </Row>

          {visibleAdd === false && (
            <>
              <Row className="mt-2"> 
                <Col span={24}>
                  <label htmlFor="year" className="add_client_label">
                    Company
                  </label>

                  <Controller
                    name="company_id"
                    control={control}
                    render={(field) => (
                      <Select
                        size="large"
                        className="add_client_filed"
                        {...field}
                        //  style={errorBorder("clients")}
                        placeholder="Please choose at least one company"
                        style={{ width: "100%" }}>
                        {companies &&
                          companies.data.data.map((client: any, i: number) => (
                            <Option value={client.id} key={i}>
                              {client.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  />
                </Col>
              </Row>
              <div className="row">
                <div className="col-md-12 bg-light text-right">
                  <button
                    onClick={() => setVisibleAdd(true)}

                    className="add_new_client_btn_company">

                    {" "}
                    Add new company
                  </button>
                </div>
              </div>
            </>
          )}

          {visibleAdd && (
            <>
              <div className="row">
                <div className="col-md-12 bg-light text-right">
                  <button
                    onClick={() => setVisibleAdd(false)}
                    style={{ color: "#04468c" }}>
                    x
                  </button>
                </div>
              </div>
              <Row>
                <Col span={24}>
                  <label htmlFor="Name" className="add_client_label">
                    Company Name
                  </label>
                  <Controller
                    name="company_name"
                    className="h2-blue-hj my-2"
                    control={control}
                    render={(field) => (
                      <Input
                        {...field}
                        size="large"
                        type="text"
                       // style={errorBorder("company_name")}
                        className="add_client_filed"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col span={24}>
                  <label className={"add_client_label"}>Logo Company</label>
                  <Controller
                    name="logo"
                    control={control}
                    render={(field) => (
                      <Input
                        type="file"
                        {...field}
                        onChange={(e: any) => {
                          setValue("logo", e.target.files[0]);
                        }}
                        style={errorBorder("logo")}
                        size="large"
                        value={getValues("logo")?.filename}
                        className="add_client_filed"
                      />
                    )}
                  />
                </Col>
              </Row>
            </>
          )}

     

          <Row className="mt-2">
                <Col span={24}>
                  <label htmlFor="fabric_type" className="add_client_label">
                  Fabric type
                  </label>

                  <Controller
                    name="fabric_type"
                    control={control}
                    render={(field) => (
                      <Select
                        size="large"
                        className="add_client_filed"
                        {...field}
                        //  style={errorBorder("clients")}
                        placeholder="Please choose at least one fabric"
                        style={{ width: "100%" }}
                        >
                     <Option value="denim">Denim</Option>
                    <Option value="knit">Knit</Option>
                    <Option value="knit_denim">Both</Option>
                      </Select>
                    )}
                  />
                </Col>
              </Row>
        
       

          <Row className="mt-2">
                <Col span={24}>
                  <label htmlFor="gender" className="add_client_label">
                    Gender
                  </label>

                  <Controller
                    name="gender"
                    control={control}
                    render={(field) => (
                      <Select
                        size="large"
                        mode="multiple"
                        className="add_client_filed"
                        {...field}
                        //  style={errorBorder("clients")}
                        placeholder="Please choose at least one company"
                        style={{ width: "100%" }}>
                        {genders &&
                          genders?.data.map((client: any, i: number) => (
                            <Option value={client.id} key={i}  className="add_client_filed">
                              {client.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  />
                </Col>
              </Row>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                // flexWrap: "wrap",
              }}
              className="d-flex flex-column flex-md-row">
              <button
                type="reset"
                className="cancel_btn flex-grow-1 my-3 mr-md-2"
                //  disabled={invitationLoading}
                onClick={onClose}>
                Cancel
              </button>
              <button
                type="submit"
                className="invite_btn flex-grow-1 my-3 ml-md-2"
                //  disabled={invitationLoading}
              >

                Save & send information to client
              </button>
            </Col>
          </Row>
        </form>

        {/*       <button onClick={() => setVisibleAdd(true)}> Add new company</button>
         */}
        {/* <AddNewCompany
        refetch={refetch}
        onClose={() => {
          onCloseAdd();
        }}
        visible={visibleAdd}></AddNewCompany> */}
      </Drawer>
    </>
  );
};

export default AddNewClient;
