export const INVITE_MEMBER_REQUEST = "INVITE_MEMBER_REQUEST";
export const INVITE_MEMBER_SUCCESS = "INVITE_MEMBER_SUCCESS";
export const INVITE_MEMBER_FAIL = "INVITE_MEMBER_FAIL";

export const REMOVE_MEMBER_REQUEST = "REMOVE_MEMBER_REQUEST";
export const REMOVE_MEMBER_SUCCESS = "REMOVE_MEMBER_SUCCESS";
export const REMOVE_MEMBER_FAIL = "REMOVE_MEMBER_FAIL";

export const GET_COMPANY_WITH_MEMBERS_REQUEST =
  "GET_COMPANY_WITH_MEMBERS_REQUEST";
export const GET_COMPANY_WITH_MEMBERS_SUCCESS =
  "GET_COMPANY_WITH_MEMBERS_SUCCESS";
export const GET_COMPANY_WITH_MEMBERS_FAIL = "GET_COMPANY_WITH_MEMBERS_FAIL";
