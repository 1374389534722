import {
  faCheck,
  faEllipsisH,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Spin } from "antd";
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneOption,
  updateOption,
  deleteOneOption,
  createNewOption,
} from "../../actions/order-actions/actions";
import { motion } from "framer-motion";
import { LoadingOutlined } from "@ant-design/icons";

interface Props {
  optionSelected: any;
  option: any;
  setOptionSelected: (x: any) => void;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const OptionCard = ({
  optionSelected,
  option,
  setOptionSelected,
}: Props) => {
  const [showInput, setshowInput] = useState(false);
  const [optionToUpdate, setOptionToUpdate] = useState<any>(null);

  const inputRef = useRef<any>();
  const dispatch = useDispatch();
  const { selectedOption, deleteOptionLoading } = useSelector(
    (state: any) => state.orders
  );

  useEffect(() => {
    if (showInput) {
      inputRef.current.focus();
    }
  }, [showInput]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        boxShadow:
          selectedOption && selectedOption.id === option.id
            ? "0px 2px 8px rgba(0,0,0,.15)"
            : "none",
        border:
          selectedOption && selectedOption.id === option.id
            ? "1px solid rgba(0,0,0,.2)"
            : "none",
      }}
      onClick={() => {
        setOptionSelected(option);
        dispatch(getOneOption(option.id));
      }}
      className="article_option_item"
      key={option.id}
    >
      {showInput ? (
        <div className="option_input_update_wrapper">
          <input
            type="text"
            value={optionToUpdate}
            onChange={(e: any) => setOptionToUpdate(e.target.value)}
            onClick={(e: any) => e.stopPropagation()}
            className="option_input_update"
            ref={inputRef}
          />
          <FontAwesomeIcon
            icon={faCheck}
            onClick={() => {
              dispatch(
                updateOption(
                  { name: optionToUpdate, _method: "patch" },
                  option.id
                )
              );
              dispatch(getOneOption(option.id));
              setshowInput(false);
            }}
          />
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(e: any) => {
              e.stopPropagation();
              setOptionToUpdate(selectedOption.name);
              setshowInput(false);
            }}
          />
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-between">
          <h2>{optionToUpdate ? optionToUpdate : option.name}  </h2>
        </div>
      )}
      {DropMenu(
        setshowInput,
        setOptionToUpdate,
        dispatch,
        showInput,
        option,
        deleteOptionLoading
      )}
    </motion.div>
  );
};

const DropMenu = (
  setshowInput: any,
  setOptionToUpdate: any,
  dispatch: any,
  showInput: any,
  option: any,
  deleteOptionLoading: any
) => {
  // Rename Function (toggle between input and title)
  const rename = (event: any) => {
    event.stopPropagation();
    setshowInput(!showInput);
    if (!showInput) {
      setOptionToUpdate(option.name);
    }
  };
  // Delete Option function
  const deleteOpt = (event: any) => {
    event.stopPropagation();
    dispatch(deleteOneOption(option));
  };

  // Duplicate Option Function
  const duplicateOpt = (event: any) => {
    event.stopPropagation();
    // delete option.id;
    const {
      style,
      isSimple,
      fabric,
      trims,
      thread,
      qty,
      order_article_id,
      style_file,
      fabric_file,
      wash_file,
      trims_file,
      thread_file,
    } = option;
    dispatch(
      createNewOption({
        style,
        isSimple,
        fabric,
        trims,
        thread,
        qty,
        order_article_id,
        style_file,
        fabric_file,
        wash_file,
        trims_file,
        thread_file,
        name: option.name + " -copy",
      })
    );
  };
  return (
    <Popover
      placement="right"
      content={() => (
        <div className="popover_edit_order">
          {/* DO NOT DELETE */}
          <p onClick={duplicateOpt}>Duplicate</p>
          <p onClick={rename}>Rename</p>
          <p onClick={deleteOpt}>Delete</p>
        </div>
      )}
      trigger="click"
    >
      {deleteOptionLoading && deleteOptionLoading.id === option.id ? (
        <Spin indicator={antIcon} />
      ) : (
        <button
          className="article_popover_btn"
          onClick={(event) => event.stopPropagation()}
        >
          <FontAwesomeIcon
            icon={faEllipsisH}
            opacity={0.7}
            color={"#00468c"}
            style={{ fontSize: "15px" }}
          />
        </button>
      )}
    </Popover>
  );
};
