import { faFile, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import "../DropZone/dropzone.scss";

interface Props {
  optionKey: string;
  files: any;
  setAlerts?: (x: any) => void;
  attachFile: (e: any, file: any) => void;
  detachFile: (fieldName: any) => void;
}

const thumbsContainer: any = {
  width: "100%",
  height: "100%",
};

export const DropZoneFile = ({
  setAlerts,
  optionKey,
  attachFile,
  detachFile,
  files,
}: Props) => {
  const containerRef = useRef<any>();
  const { getRootProps, getInputProps }: any = useDropzone({
    accept: "image/*, .pdf",
    onDrop: (acceptedFiles: any, e: any) => {
      attachFile(
        getInputProps().ref.current.name,
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )[0]
      );
    },
    maxFiles: 1,
    onDragEnter: () => (containerRef.current.style.background = "#e5f1fa"),
    onDragLeave: () => (containerRef.current.style.background = "#f4f7ff"),
    onDropRejected: () =>
      setAlerts &&
      setAlerts([
        {
          msg: "Please drop only one file.",
          type: "danger",
          id: uuidv4(),
        },
      ]),
  });

  return (
    <section className="container dropzone_wrapper" ref={containerRef}>
      {!files[`${optionKey}_file`] && (
        <div {...getRootProps({ className: "dropzones" })}>
          <input {...getInputProps({ name: `${optionKey}_file` })} />
          <p className={"label_drawer_mycollection"}>Drag and drop file here, or click to select file</p>
        </div>
      )}
      {files[`${optionKey}_file`] && (
        <aside style={thumbsContainer}>
          <div className="file_uploaded_miniature">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faFile} />
              <div className="file_uploaded_details">
                <p className="m-0">{files[`${optionKey}_file`].name}</p>
              </div>
            </div>
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => detachFile(`${optionKey}_file`)}
            />
          </div>
        </aside>
      )}
    </section>
  );
};
