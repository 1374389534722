import React, {useEffect, useRef, useState} from "react";
import {Col, Collapse, Row, Select} from "antd";
import axios from "axios";
import AxiosInstance from "../../config/axios-instance";
import axiosInstance from "../../config/axios-instance";


export const View3D = (seasonCollectionRes:any) => {

    const chartContainer = useRef(null);
    const [chartLoaded, setChartLoaded] = useState(false);
    const [chart, setChart] = useState(null);
    const [timeframe, setTimeframe] = useState('month');
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState<any>(null);
    const [selectedWeek, setSelectedWeek] = useState<any>(null);
    const [weeksInSelectedMonth, setWeeksInSelectedMonth] = useState<any>([]);

    const [selectedSeasson, setSelectedSeasson] = useState<any>(null);

    useEffect(() => {
        // Reset selected week when month is changed
        setSelectedWeek(null);

        // Calculate weeks in the selected month
        const weeks = calculateWeeksInMonth(selectedYear, selectedMonth);
        setWeeksInSelectedMonth(weeks);

        // If the month and year are selected, update the chart
        if (selectedYear && selectedMonth) {
            // Replace with actual chart update function
            updateChart('month', selectedYear, selectedMonth);
        }
    }, [selectedMonth, selectedYear]);

    const calculateWeeksInMonth = (year:any, month:any) => {
        if (!year || !month) return [];

        // Assuming you consider the first week as the one containing the 1st of the month
        const lastDayOfMonth = new Date(year, month, 0).getDate();
        const weeks = [];
        for (let i = 1; i <= lastDayOfMonth; i += 7) {
            const weekStart = new Date(year, month - 1, i);
            const weekEnd = new Date(year, month - 1, Math.min(i + 6, lastDayOfMonth));

            // Format dates as dd/mm/yyyy
            const formattedStartDate = `${weekStart.getDate()}/${weekStart.getMonth() + 1}/${weekStart.getFullYear()}`;
            const formattedEndDate = `${weekEnd.getDate()}/${weekEnd.getMonth() + 1}/${weekEnd.getFullYear()}`;

            weeks.push(`${formattedStartDate}-${formattedEndDate}`);
        }
        return weeks;
    };

    const currentYear = new Date().getFullYear();

    // Create an array of years from two years before the current year to the current year
    const years = Array.from({ length: 3 }, (_, index) => currentYear - (2 - index));

    const months = [
        { label: 'January', value: '1' },
        { label: 'February', value: '2' },
        { label: 'March', value: '3' },
        { label: 'April', value: '4' },
        { label: 'May', value: '5' },
        { label: 'June', value: '6' },
        { label: 'July', value: '7' },
        { label: 'August', value: '8' },
        { label: 'September', value: '9' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' }
    ];


    const weeksInYear = Array.from({length: 52}, (_, i) => (i + 1).toString());

    // For a complete list of months
    const monthsInYear = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const createYearRange = (startYear:any, endYear:any) => {
        const years = [];
        for (let year = startYear; year <= endYear; year++) {
            years.push(year.toString());
        }
        return years;
    };

    // range from 2 years before to 1 years after the current year
 //   const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth()+1;
    const [x, setX] = useState<any>(currentMonth);

    const yearsRange = createYearRange(currentYear - 2, currentYear + 1);


    useEffect(() => {
        if (chartLoaded) {
            // load chart here as we previously described
            updateChart('month');
        }
        // Ensure to run this hook when `chartLoaded` changes
    }, [chartLoaded]); // Dependency array


    useEffect(() => {
        // Check if Chart.js script is already loaded
        if (!document.querySelector('script#chartjs-view-3d-script')) {
            // Create script element
            const script = document.createElement('script');
            script.id = 'chartjs-view-3d-script';
            script.src = 'https://cdn.jsdelivr.net/npm/chart.js';
            script.onload = () => setChartLoaded(true); // Set flag when script is loaded

            // Append the script to the document body
            document.body.appendChild(script);

            // Remove the script when the component unmounts
            return () => {
                document.body.removeChild(script);
            };
        } else {
            // If the script is already in the document, set the flag to true
            setChartLoaded(true);

        }
    }, []);

    const fetchData = async (timeframe:any, year:any, month:any, week:any,season:any) => {
        try {
            const responseVideo = await AxiosInstance.get(`/report-number-post-tracked/${year}/${month}/null?week=${week}&season=${season}`);



            const data = responseVideo.data;

            return {
                labels: data.map((d:any) => d.name), // Assuming each item has a label property
                datasets: [
                    // Assuming the data holds a structure compatible with Chart.js datasets
                    {
                        label: '3D Video',
                        data: data.map((d:any) => d.visit_count), // Assuming each item has a value property
                        backgroundColor: 'rgb(24,40,68)',
                        borderColor: 'rgb(15,25,42)',
                        borderWidth: 1,
                    },



                ],
            };
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const updateChart = async (timeframe:any, year = selectedYear, month = null, week = null,season=null) => {
        const chartData = await fetchData(timeframe, year, month, week,season);
        if (chart) {
            //@ts-ignore
            chart.data.labels = chartData?.labels;
            //@ts-ignore
            chart.data.datasets = chartData?.datasets;
            //@ts-ignore
            chart.update();
        } else if (chartContainer.current) {
            //@ts-ignore
            const newChart = new Chart(chartContainer.current, {
                type: 'bar',
                data: chartData,
                options: {
                   /* scales: {
                        xAxes: [{
                            // Here, you can configure your x-axis
                            // For example, you can add an x-axis label
                            scaleLabel: {
                                display: true,
                                labelString: 'Product Code'
                            }
                        }],
                        yAxes: [{
                            // Configure the y-axis as needed
                            scaleLabel: {
                                display: true,
                                labelString: 'Visit Count'
                            },

                        }]
                    },*/
                    scales: {
                        x: {
                            // Here, you can configure your x-axis
                            // For example, you can add an x-axis label
                            /*  title: {
                                  display: true,
                                  text: 'Product Code'
                              }*/
                        },
                        y: {
                            // Configure the y-axis as needed
                            /* title: {
                                 display: true,
                                 text: 'Visit Count'
                             },*/
                            ticks: {
                                stepSize: 1 // This will enforce the step size of 1 on the y-axis
                            }
                        }
                    },


                    responsive: true,
                    maintainAspectRatio: false,
                },
            });
            setChart(newChart);
        }
    };

    useEffect(() => {

        // updateChart('month'); // Load the default chart data on mount

        // Clean up the chart instance when the component unmounts
        return () => {
            if (chart) {
                //@ts-ignore
                chart.destroy();
            }
        };
    }, [chartLoaded, chart]);


    const handleTimeframeChange = (e: any) => {

        const newTimeframe = e;


     //   updateChart(e, x);

        setTimeframe(newTimeframe);
    };
    const handleTimeX = (e: any) => {


        updateChart(timeframe, e);


    };


    const handleYearChange = (year:any) => {
        setSelectedYear(year);
        setSelectedMonth(null);
        updateChart(null, year);
    };

    const handleMonthChange = (month:any) => {
        setSelectedMonth(month);
        updateChart("month", selectedYear, month);
    };

    const handleWeekChange = (week:any) => {
        setSelectedWeek(week);
        updateChart('week', selectedYear, selectedMonth, week);

    };

    const handleSeassonChange = (season:any) => {
        setSelectedSeasson(season);
        updateChart('week', selectedYear, selectedMonth, selectedWeek,season);

    };


    return (

        <div style={{textAlign:'center'}}>
            <div className={"title-chart"}>3D View</div>
            <Row gutter={[8, 16]} style={{textAlign:'center'}}>
                <Col span={6}>
                    <Select defaultValue={selectedYear} onChange={handleYearChange} style={{width: '100%'}}>
                        {years.map((year) => (
                            <Select.Option key={year} value={year}>{year}</Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={6}>
                    <Select value={selectedMonth} onChange={handleMonthChange} defaultValue={"null"}  style={{width: '100%'}} placeholder="Select Month">
                        <Select.Option value="null">-</Select.Option>
                        {months.map((month) => (
                            <Select.Option key={month.value} value={month.value}>{month.label}</Select.Option>
                        ))}
                    </Select>
                </Col>
               <Col span={6}>
                    <Select value={selectedWeek} onChange={handleWeekChange} defaultValue={"null"}  style={{width: '100%'}} placeholder="Select Week" disabled={!selectedYear || !selectedMonth}>
                        <Select.Option value="null">-</Select.Option>
                        {weeksInSelectedMonth.map((week:any, index:any) => (
                            <Select.Option key={index} value={week}>{week}</Select.Option>
                        ))}
                    </Select>
                </Col>

                <Col span={6}>


                    <Select value={selectedSeasson} onChange={handleSeassonChange} defaultValue={"null"}  style={{width: '100%'}} placeholder="Select Season " >
                        <Select.Option value="null">-</Select.Option>

                        {
                            seasonCollectionRes?.seasonCollectionRes?.filter((item:any) => item.collection_id === 25)
                                .map((item: any, i: number) => (
                                    <Select.Option value={item.name} key={i}>{item.name}</Select.Option>
                                ))


                        }
                    </Select>
                </Col>
            </Row>


            <div className="order">

                <canvas ref={chartContainer} width={250} height={250}/>


            </div>
        </div>
    )

}