function Attention() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.5"
      height="17.5"
      viewBox="0 0 17.5 17.5"
    >
      <path
        fill="#03468c"
        d="M17.5-14.375a1.875 1.875 0 00-1.875-1.875H1.875A1.875 1.875 0 000-14.375v13.75A1.875 1.875 0 001.875 1.25h13.75A1.875 1.875 0 0017.5-.625zM15.625-.859a.234.234 0 01-.234.234H2.109a.234.234 0 01-.234-.234v-13.282a.234.234 0 01.234-.234h13.282a.234.234 0 01.234.234zM8.75-12.891a1.641 1.641 0 00-1.641 1.641A1.641 1.641 0 008.75-9.609a1.641 1.641 0 001.641-1.641 1.641 1.641 0 00-1.641-1.641zm2.188 9.922v-.937a.469.469 0 00-.469-.469H10v-3.906a.469.469 0 00-.469-.469h-2.5a.469.469 0 00-.469.469v.938a.469.469 0 00.469.469H7.5v2.5h-.469a.469.469 0 00-.469.469v.938a.469.469 0 00.469.469h3.438a.469.469 0 00.469-.471z"
        data-name="Tracé 3326"
        transform="translate(0 16.25)"
      ></path>
    </svg>
  );
}

export default Attention;
