import { useQuery } from "react-query";
import {
  getMyCancelledDemcoOrders,
  getMyDemcoOrders,
  getCompletdDemcoOrder,
} from "../../actions/my-orders/services";
import MyOrdersTable from "./MyOrdersTable";
import React, { useState } from "react";
import {HrIcon} from "../../components/product-3d-config-tabs/forms/canvas/icon/HrIcon";
import {SearchOutlined} from "@ant-design/icons";

const columns = [
  /*   "Reference",
  // "Product Name",
  "Collection Name",
  "Order Date",
  "Progress",
  "Order Details", */
  "",
  "Reference",
  "Collection Name",
  "Order Date",
  "Delivery Date",
  "Number of article",
  "Status",
  "Description of status",
  "Team",
];
const canceleldColumns = [
  "",
  "Reference",
  "Collection Name",
  "Delete Date",
  "Status",
  "Number of article",
  "Action",
  "Team",
];

const completedColumns = [
  "",
  "Reference",
  "Collection Name",
  "Delivery Date",
  "Number of article",
  "Quantity",
  "Action",
  "Team",

  /*   "Order Details",
   */
];

export default function DemcoProductOrders({
  orderType,
  url,
  orderStatus,
  selectedDate,
}: {
  orderType: string;
  url: string;
  orderStatus: string;
  selectedDate: any;
}) {
  const [searchQuery, setSearchQuery] = useState("");

  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["my_demco_orders", orderStatus, selectedDate],
    /* orderStatus === "cancelled"
      ? () => getMyCancelledDemcoOrders(selectedDate)
      : () => getMyDemcoOrders(selectedDate), */
    orderStatus === "cancelled"
      ? () => getMyCancelledDemcoOrders(selectedDate)
      : orderStatus === "completed"
      ? () => getCompletdDemcoOrder(selectedDate)
      : () => getMyDemcoOrders(selectedDate),
    {
      keepPreviousData: true,
    }
  );
  console.log(data?.data);
  const handleSearchInputChange = (event: any) => {
    setSearchQuery(event.target.value);
  };
  console.log(data?.data);

  const filteredData = data?.data?.data?.filter((order: any) => {
    console.log(order);

    const nameMatch = order?.collection_name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase());
    const refMatch = order?.reference
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase());

    return refMatch || nameMatch;
  });

  if (isError) {
    return <p>Error!</p>;
  }

  return (
    <div className={"mt-2"}>
      <div className="search-bar searchfaq" style={{width: "100%"}}>
        <div className="input-container">
          <input type="text" placeholder="Search your order" onChange={handleSearchInputChange}
                 className={"search_input"}/>
          <HrIcon></HrIcon>
          <button type="submit" className={"submit_input"}><SearchOutlined/></button>
        </div>
      </div>
      <MyOrdersTable
          columns={
            orderStatus === "cancelled"
                ? canceleldColumns
                : orderStatus === "completed"
                    ? completedColumns
                    : columns
          }
          isLoading={isLoading || isFetching}
          orderType={orderType}
          data={orderStatus === "cancelled" ? data?.data : filteredData}
          orderStatus={orderStatus}
          url={url}
        refetch={refetch}
      />
    </div>
  );
}
