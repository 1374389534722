import React, { useRef } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { faFile, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../DropZone/dropzone.scss";

interface Props {
  optionKey: string;
  files: any;
  setAlerts?: (x: any) => void;
  attachFile: (name: string, file: any) => void;
  detachFile: (fieldName: string) => void;
}

export const DropZoneFile2: React.FC<Props> = ({
  setAlerts,
  optionKey,
  attachFile,
  detachFile,
  files,
}: Props) => {
  const containerRef = useRef<any>();
  const fileKey = `${optionKey}_file`;

  // Dropzone configuration
  const { getRootProps, getInputProps }: any = useDropzone({
    accept: "image/*, .pdf",
    onDrop: (acceptedFiles: any) => {
      // Attach the file using the attachFile function
   
      attachFile(fileKey, acceptedFiles[0]);
    },
    maxFiles: 1,
    onDragEnter: () => (containerRef.current.style.background = "#e5f1fa"),
    onDragLeave: () => (containerRef.current.style.background = "#f4f7ff"),
    onDropRejected: () =>
      setAlerts &&
      setAlerts([
        {
          msg: "Please drop only one file.",
          type: "danger",
          id: uuidv4(),
        },
      ]),
  });
  console.log(files)
  return (
    <section className="container dropzone_wrapper" ref={containerRef}>
      {!files[fileKey] && (
        <div {...getRootProps({ className: "dropzones" })}>
          <input {...getInputProps({ name: fileKey })} />
          <p>Drag and drop file here, or click to select file</p>
        </div>
      )}
      {files[fileKey] && (
        <aside style={{ width: "100%", height: "100%" }}>
          <div className="file_uploaded_miniature">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faFile} />
              <div className="file_uploaded_details">
                {/* Display file name */}
                <p className="m-0">{files[fileKey].name}</p>
              </div>
            </div>
            {/* Remove file button */}
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => detachFile(fileKey)}
            />
          </div>
        </aside>
      )}
    </section>
  );
};

