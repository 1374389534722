import {
    Button,
    Col,
    Drawer,
    Input,
    Row,
    Form,
    Select,
    DatePicker,
    Spin,
  } from "antd";
  import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
  import { useMutation, useQuery } from "react-query";
  import CategoriesServices from "../../actions/left-menu-actions/services";
  import axiosInstance from "../../config/axios-instance";
  import { MyNotif } from "../NotifIcons/NotifIcons";
  import { LoadingOutlined } from "@ant-design/icons";
  import "./TeamManagement.scss";
import { useEffect, useState } from "react";
  const { Option } = Select;
  
  interface IData {
    fullname: string;
    email: string;
    title: string;
    fabric_type: string;
    genders: number[];
  }
  
  export default function EditTeammateDrawer({
    visible,
    onClose,
    client,
    refetchMembers
  }: any) {

    const [name,setName]=useState<any>("");
    const [email,setEmail]=useState('');

    const [title,setTitle]=useState('');

    const width = useBreakpoint();
    const onFinishFailed = (errorInfo: any) => {
      console.log("Failed:", errorInfo);
    };
  
    const onFinish = (values: IData) => {
      console.log("Success:", values);
      editInvitation(values);
    };
  
    const { data: genders, isLoading: gendersIsLoading } = useQuery(
      "genders",
      CategoriesServices.getGenders
    );
    useEffect(()=>{
       setName(client?.name);
       console.log(name)
      setEmail(client?.email);

      setTitle(client?.job_title);

    },[client])
  
    const editMember = (data: IData) => {
      let token = localStorage.getItem("token");
      return axiosInstance({
        method: "post",
        url: `/clients/me/company/edit/${client.id}`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    };
  
    const { mutateAsync: editInvitation, isLoading: invitationLoading } =
      useMutation(editMember, {
        onSuccess: () => {
          MyNotif("Updated successfully.", "success");
          onClose(); 
          refetchMembers();
        },
        onError: (err: any) => {
          MyNotif(err.response.data.errors.email[0], "danger");
        },
      });
  
    return (
      <Drawer
        //   title="Invite new member"
        placement={"right"}
        //   closable={true}
        onClose={onClose}
        closable={true}
        visible={visible}
        width={width.md ? "33%" : "100%"}
        bodyStyle={{ padding: 20, paddingBottom: 80, background: "#fcfdff " }}
      >
        <h1 className="h1-blue-hj" style={{ marginTop: "20px", padding: 20 }}>
          Edit member
        </h1>
        <Form
          layout="vertical"
          hideRequiredMark
          style={{ padding: 24, paddingTop: 0 }}
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}
          initialValues={{ remember: true }}
          className="invite-teammate-drawer"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Full name"
                style={{ color: "#00468c" }}
                className="h2-blue-hj my-2"
             /*    rules={[
                  { required: true, message: "Please enter user full name" },
                ]} */
              >
               <Input placeholder="Please enter user name" defaultValue={name} value={name} />
              </Form.Item>
            </Col>
          </Row>
       <Row>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email address"
                style={{ color: "#00468c" }}
                className="h2-blue-hj my-2"
               /*  rules={[
                  { required: true, message: "Please enter user email address" },
                ]} */
              >
                <Input placeholder="Please enter user email address" disabled  defaultValue={email} value={email}/>
              </Form.Item>
            </Col>
          </Row> 
          <Row>
            <Col span={24}>
              <Form.Item
                name="job_title"
                label="Job title"
                style={{ color: "#00468c" }}
                className="h2-blue-hj my-2"
           /*      rules={[
                  { required: true, message: "Please enter user job title" },
                ]} */
              >
                <Input placeholder="Please enter user job title"  defaultValue={title} value={title}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="fabric_type"
                label="Fabric type"
                className="h2-blue-hj my-2"
                style={{ color: "#00468c" }}
             /*    rules={[
                  { required: true, message: "Please select a fabric type" },
                ]} */
              >
                <Select
                  placeholder="Please select an owner"
                  className="select-invite-teammate"
                  defaultValue={client?.fabric_type}
                >
                  <Option value="denim">Denim</Option>
                  <Option value="knit">Knit</Option>
                  <Option value="knit_denim">Both</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="genders"
                label="Gender"
                className="h2-blue-hj my-2"
                style={{ color: "#00468c" }}
              /*   rules={[
                  {
                    required: true,
                    message: "Please choose at least one gender",
                  },
                ]} */
              >
                <Select
                  mode="multiple"
                  placeholder="Please choose at least one gender"
                  className="select-invite-teammate"
                  loading={gendersIsLoading}
                  onChange={(x: any, y: any) => console.log(x)}
                  style={{ width: "100%" }}
                >
                  {genders?.data.map((item: any) =>
                    item.childs.length > 0 ? (
                      item.childs.map((child: any) => (
                        <Select.Option value={child.id} key={child.id}>
                          {child.name}
                        </Select.Option>
                      ))
                    ) : (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                // flexWrap: "wrap",
              }}
              className="d-flex flex-column flex-md-row"
            >
              <button
                type="reset"
                className="cancel_btn flex-grow-1 my-3 mr-md-2"
                disabled={invitationLoading}
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="invite_btn flex-grow-1 my-3 ml-md-2"
                disabled={invitationLoading}
              >
                {invitationLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 22,
                          color: "white",
                          verticalAlign: "middle",
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  "Save"
                )}
              </button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
  