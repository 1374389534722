import "./LabelCard.scss";
export interface ILabelCardProps {
  label: {
    img: string;
    imgColored: string;
    text: string;
  };
}

export default function LabelCard({ label }: ILabelCardProps) {

  return (
    <div className="label_card_container">
      <div className="label_img_container">
        <img src={process.env.REACT_APP_STORAGE_URL+label.img} alt=""width={150} />
      </div>
      <div className="label_card">
        <img src={process.env.REACT_APP_STORAGE_URL+label.imgColored} alt="" className="label_img_colored" width={120}/>
        <div className="text_container">
          <p className="label_text paragraphe"> {label.text}</p>
        </div>
      </div>
    </div>
  );
}
