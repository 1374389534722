export interface IBagProps {
    className?: string;
}

function ChartIcon({ className }: IBagProps) {
    return (
        /*    <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.25"
                height="14"
                viewBox="0 0 12.25 14"
            >
                <path
                    fill="#182844"
                    d="M3.5-8.75a3.5 3.5 0 00-3.5-3.5 3.5 3.5 0 00-3.5 3.5h-2.625v8.313A2.188 2.188 0 00-3.937 1.75h7.875A2.188 2.188 0 006.125-.437V-8.75zM0-11.375A2.628 2.628 0 012.625-8.75h-5.25A2.628 2.628 0 010-11.375zM5.25-.437A1.314 1.314 0 013.938.875h-7.875A1.314 1.314 0 01-5.25-.437v-7.438h1.75v1.313a.438.438 0 00.438.438.438.438 0 00.438-.437v-1.314h5.25v1.313a.437.437 0 00.438.438.437.437 0 00.436-.438v-1.313h1.75z"
                    data-name="Tracé 3388"
                    transform="translate(6.125 12.25)"
                ></path>
            </svg>*/
        <svg fill="#182844" height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 282.772 282.772" ><g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> <g> <path
                d="M61.472,143.036H23.881c-4.971,0-9,4.029-9,9v94.185c0,4.971,4.029,9,9,9h37.591c4.971,0,9-4.029,9-9v-94.185 C70.472,147.065,66.443,143.036,61.472,143.036z M52.472,237.22H32.881v-76.185h19.591V237.22z"></path>
                <path
                    d="M132.238,93.194H94.648c-4.971,0-9,4.029-9,9V246.22c0,4.971,4.029,9,9,9h37.591c4.971,0,9-4.029,9-9V102.194 C141.238,97.224,137.209,93.194,132.238,93.194z M123.238,237.22h-19.591V111.194h19.591V237.22z"></path>
                <path
                    d="M203.005,150.471h-37.591c-4.971,0-9,4.029-9,9v86.749c0,4.971,4.029,9,9,9h37.591c4.971,0,9-4.029,9-9v-86.749 C212.005,154.5,207.976,150.471,203.005,150.471z M194.005,237.22h-19.591v-68.749h19.591V237.22z"></path>
                <path
                    d="M273.772,96.516H236.18c-4.971,0-9,4.029-9,9V246.22c0,4.971,4.029,9,9,9h37.591c4.971,0,9-4.029,9-9V105.516 C282.772,100.545,278.742,96.516,273.772,96.516z M264.772,237.22H245.18V114.516h19.591V237.22z"></path>
                <path
                    d="M178.918,112.796c3.276,2.381,7.739,2.28,10.905-0.246l67.269-53.682l-0.298,8.847c-0.167,4.968,3.724,9.131,8.692,9.298 c0.104,0.003,0.206,0.005,0.309,0.005c4.831,0,8.826-3.833,8.99-8.697l1.061-31.466c0.083-2.491-0.869-4.905-2.631-6.667 c-1.762-1.763-4.184-2.719-6.667-2.631l-31.466,1.061c-4.968,0.167-8.859,4.331-8.692,9.298c0.167,4.967,4.314,8.85,9.298,8.692 l8.261-0.278l-59.993,47.876l-68.22-49.585c-2.988-2.172-7-2.298-10.117-0.317L4.176,108.734 c-4.196,2.665-5.437,8.227-2.772,12.422c1.715,2.7,4.628,4.176,7.605,4.176c1.65,0,3.321-0.454,4.817-1.404l96.276-61.15 L178.918,112.796z"></path> </g> </g></svg>
    );
}

export default ChartIcon;
