import { Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { handleRightDrawer } from "../../actions/actionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import logo_green from "../../img/logo_green.svg";

const BookingNotification = ({ notif, inPage, status, position }: any) => {
  const dispatch = useDispatch();
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  return inPage ? (
    <div className="notif_item_page p-2 my-2">
      <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center icon_notif_container">

        </div>
        <h2 className="h4_medium m-0">
          {status === "new"
            ? `${
                user && !isAdmin ? "DEMCO team " : notif.data.client.name
              } sent you a meeting request`
            : status === "accepted"
            ? `Your meeting with ${
                user && !isAdmin ? "DEMCO team " : notif.data.client.name
              } has been confirmed`
            : ""}
        </h2>
      </div>
      <div className="d-flex flex-column align-items-start">
        <button className="light_button m-0 ml-0 mr-3 p-0">
          <Link
            to="/bookings"
            className="light_button"
            style={{ fontSize: "1rem" }}
          >
            See details
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </Link>
        </button>
        <p className="selection_date m-0" style={{ fontSize: "0.7rem" }}>
          {moment(notif.created_at).fromNow()}
        </p>
      </div>
    </div>
  ) : (
    <div
      className="notif_added_prod_container my-2 border p-4"
      style={{
        borderRadius: "8px",
        background: position ? "#e5f1fa" : "white",
        transition: "all 5s ease-in-out",
      }}
    >
      <article className="notif_selection order_changes w-100">
        <div>

        </div>
        <div className="notif_details" style={{ flex: 1 }}>
          <h2 className="h4_medium">
            {status === "new"
              ? `${
                  user && !isAdmin ? "DEMCO team " : notif.data.client.name
                } sent you a meeting request`
              : status === "accepted"
              ? `Your meeting with ${
                  user && !isAdmin ? "DEMCO team " : notif.data.client.name
                } has been confirmed`
              : ""}
          </h2>
          <div className="d-flex justify-content-between align-items-center flex-row-reverse">
            <Link
              to="/bookings"
              className="light_button align-self-end"
              style={{ fontSize: "0.8rem" }}
              onClick={() => dispatch(handleRightDrawer())}
            >
              See details
              <small>
                <FontAwesomeIcon icon={faArrowRight} className="ml-2 " />
              </small>
            </Link>
            <p
              className="selection_date mb-0"
              style={{ fontSize: "0.7rem", fontWeight: 400 }}
            >
              {moment(notif.created_at).fromNow()}
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default BookingNotification;
