import React, { useState, useEffect } from "react";
import "./AddFolder.scss";
import { motion } from "framer-motion";
import folder from "../../img/folder.png";
import { Button } from "../Button/Button";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  handleBottomDrawer,
  handleBottomDrawerAddFolder,
} from "../../actions/actionCreators";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  createFolder,
  getAllFolders,
} from "../../actions/folders-actions/actions";
import {
  clearAllAlerts,
  removeAlert,
  setAlert,
} from "../../actions/alerts-actions/actions";
// import { Select } from "antd";
import Select, { components } from "react-select";
import { v4 as uuidv4 } from "uuid";
import Alert from "../Alert/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderPlus,
  faFolderOpen,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { getGenders } from "../../actions/left-menu-actions/actions";
import shoppingBag from "../../img/shoppingBag.svg";
import { AddOrderBottomDrawer } from "./AddOrderBottomDrawer";
import {
  createArticle,
  getCollaborators,
} from "../../actions/order-actions/actions";
import { addOrder } from "../../actions/order-actions/actions";
import { BrowserRouter, useParams, useLocation } from "react-router-dom";
import { AddArticleBottomDrawer } from "./AddArticleBottomDrawer";
import { history } from "../../index";
import Bag from "../../icons/Bag";

export interface IAddFolderProps {
  setMode: ((mode: string) => void) | undefined;
  withRoot: boolean;
  addFolder?: boolean;
  addArticle?: boolean;
  addOrderDrawer?: boolean;
}

export default function AddFolder({
  setMode,
  withRoot,
  addArticle,
  addOrderDrawer,
  addFolder,
}: IAddFolderProps) {
  const CaretDownIcon = () => {
    return <FontAwesomeIcon icon={faCaretDown} />;
  };

  const FolderIcon = () => {
    return <FontAwesomeIcon icon={faFolderOpen} />;
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {addArticle || addOrderDrawer ? <CaretDownIcon /> : <FolderIcon />}
      </components.DropdownIndicator>
    );
  };
  const [options, setoptions] = useState([]);
  const [genders, setgenders] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [fodlerSelected, setfodlerSelected] = useState<any>("");
  const [selectDivision, setSelectDivision] = useState<any>("");
  const [height, setHeight] = useState(window.innerHeight);
  const [allGenders, setAllGenders] = useState([]);
  const [folderData, setfolderData] = useState({
    name: "",
    description: "",
    collaborator: [],
    season:'',
    year:""
  });
  const [tags, setTags] = useState([]);
  const [data, setData] = useState<any>({
    name: "",
    details: "",
    collaborator: [],
    season:'',
    year:""
  });
  const [lastFetchId, setLastFetchId] = useState(0);
  const [state, setState] = useState({
    users: [],
    value: [],
    fetching: false,
  });

  useEffect(() => {
    dispatch(getCollaborators(""));
  

}, []);
  const params: any = useParams();

  const location: any = useLocation();
  // GET COLLEAGUES
  const fetchUser = (value: any) => {
    setLastFetchId(lastFetchId + 1);
    setState({ ...state, users: [], fetching: true });
    fetch("https://randomuser.me/api/?results=5")
      .then((response) => response.json())
      .then((body) => {
        if (lastFetchId !== lastFetchId) {
          // for fetch callback order
          return;
        }
        const users = body.results.map((user: any) => ({
          text: `${user.name.first} ${user.name.last}`,
          value: user.login.username,
        }));
        setState({ ...state, users, fetching: false });
      });
  };

  const handleChangeOrder = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleChangeDeliveryDate = (e: any) => {
    setData({ ...data, delivery_date: e.target.value });
  };

  const handleChangeGender = (e: any) => {
    setData({ ...data, gender: e.value });
  };
  const handleType = (e: any) => {
    setData({ ...data, type: e.value });
  };
  const { collaborators, newOrder, orders, createOrderLoading } = useSelector(
    (state: any) => state.orders
  );
  const alerts = useSelector((state: any) => state.alerts);
  const folders = useSelector((state: any) => state.folders);
  const leftMenu = useSelector((state: any) => state.leftMenu);
  const [orderCreated, setOrderCreated] = useState<any>(null);
  const handleChange2 = (value: any) => {
    setData({ ...data, collaborator: value });
    setState({
      ...state,
      value,
      users: [],
      fetching: false,
    });
  };

  useEffect(() => {
    if (addOrderDrawer) {
      dispatch(getCollaborators(""));
    }
    if (addArticle) {
      dispatch(getGenders());
    }
  }, []);

  useEffect(() => {
    if (leftMenu.allGenders.data) {
      setAllGenders(
        leftMenu.allGenders.data.map((el: any) => {
          return {
            label: el.name,
            value: el.id,
          };
        })
      );
    }
  }, [leftMenu.allGenders]);

  const handleCollabChange = (e: any) => {
    setData({
      ...data,
      collaborator: e && e.map((el: any) => el.value),
    });
  };

  // useEffect(() => {
  //   if (newOrder) {
  //     setOrderCreated(newOrder);
  //     history.push(`/edit-order/${newOrder.id}`);
  //   }
  // }, [newOrder]);

  // const filterColors = (inputValue: any) => {
  //   return (
  //     collaborators &&
  //     collaborators.filter((i: any) =>
  //       i.name.toLowerCase().includes(inputValue.toLowerCase())
  //     )
  //   );
  // };

  // const promiseOptions = (inputValue: any) =>
  //   new Promise((resolve) => {
  //     dispatch(getCollaborators(inputValue));
  //     resolve(
  //       collaborators
  //         .map((el: any) => {
  //           return {
  //             label: el.name,
  //             value: el.name,
  //           };
  //         })
  //         .filter((i: any) =>
  //           i.label.toLowerCase().includes(inputValue.toLowerCase())
  //         )
  //     );
  //     setData({
  //       ...data,
  //       collaborator: [
  //         ...data.collaborator,
  //         collaborators
  //           .map((el: any) => {
  //             return {
  //               label: el.name,
  //               value: el.name,
  //             };
  //           })
  //           .filter((i: any) =>
  //             i.label.toLowerCase().includes(inputValue.toLowerCase())
  //           ),
  //       ],
  //     });
  // setTimeout(() => {
  // resolve(filterColors(inputValue));
  // }, 1000);
  // });

  // useEffect(() => {
  //   filterColors(collaborators);
  // }, [collaborators]);

  const variants = {
    enter: { y: 1900 },
    center: { y: height },
    exit: { y: 1900 },
  };

  const handleChange = (e: any) => {
    setfolderData({ ...folderData, [e.target.name]: e.target.value });
  };
  const handleSeasonChange = (e: any) => {
    setfolderData({
      ...folderData,
      season: e.value,
    });
  };
  const handleYearChange = (e: any) => {
    setfolderData({
      ...folderData,
      year: e.value,
    });
  };
  const handleCollabChangeFolder = (e: any) => {
    setfolderData({
      ...folderData,
      collaborator: e && e.map((el: any) => el),
    });
  };
  const createNewFolder = () => {
    if (folderData.name === "") {
      var id = uuidv4();

      dispatch(setAlert("Please specify the folder name!", "danger", id));
      setTimeout(() => {
        dispatch(removeAlert(id));
      }, 5000);
      return null;
    } else if (withRoot) {
      dispatch(
        createFolder({
          ...folderData,
          ...tags,
          parent_id: fodlerSelected.value,
          product_id: params.productId,
        })
      );
      dispatch(handleBottomDrawer());
      // dispatch(getAllFolders());
      dispatch(clearAllAlerts());
      var id = uuidv4();
      dispatch(setAlert("Folder added successfully", "success", id));
      setTimeout(() => {
        dispatch(removeAlert(id));
      }, 5000);
    } else {
      dispatch(createFolder({ ...folderData, ...tags }));
      dispatch(handleBottomDrawer());
      // dispatch(getAllFolders());
      dispatch(clearAllAlerts());
      var id = uuidv4();
      dispatch(setAlert("Folder added successfully", "success", id));
      setTimeout(() => {
        dispatch(removeAlert(id));
      }, 5000);
    }

    setfodlerSelected("");
    setfolderData({
      name: "",
      description: "",
      collaborator: [],
      season:'',
      year:""
    });
    setTags([]);
  };

  const discard = () => {
    setfolderData({
      name: "",
      description: "",
      collaborator: [],
      season:'',
      year:""
    });
    setTags([]);
    dispatch(clearAllAlerts());
  };

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  useEffect(() => {
    setHeight(window.innerHeight);
  }, [window.innerHeight]);
  useEffect(() => {
    console.log(folders);
    /* folders.folders &&
      setoptions(
        folders.folders.map((el: any) => {
          return { value: el.id, label: el.name };
        })
      ); */
    folders.sortedFolders &&
      setoptions(
        folders.sortedFolders.map((el: any) => {
          return { value: el.id, label: el.name };
        })
      );
    if (addArticle) {
      dispatch(getGenders());
    }
    leftMenu.allGenders.data &&
      setgenders(
        leftMenu.allGenders.data.map((el: any) => {
          return { value: el.id, label: el.name };
        })
      );
    if (withRoot) {
      dispatch(getAllFolders());
    }
  }, []);
  const dispatch = useDispatch();

  const customStyles = {
    //@ts-ignore
    option: (styles: any, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#00468c" : null,
        color: isSelected ? "white" : "black",
      };
    },
    control: (base: any) => ({
      ...base,
      border: 0,
      borderBottom: "1px solid #ccc",
      borderRadius: 0,
      padding: 0,
      // This line disable the blue border
      boxShadow: "none",
      "&:hover": {
        outine: "none",
      },
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingLeft: 0,
      paddingBottom: 0,
    }),
    menu: (base: any) => ({
      ...base,
      marginTop: 0,
      color: "#00468c",
    }),
    valueContainer: (base: any) => ({
      ...base,
      paddingLeft: 0,
      fontSize: 18,
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: "none",
    }),
    colors: (base: any) => ({
      ...base,
      primary: "#00468c",
      primary25: "#00468c",
    }),
  };

  const currentYear = new Date().getFullYear();

  const options_year = [



    { value: String(currentYear).slice(2), label: String(currentYear) },


    { value: String(currentYear + 1).slice(2), label: String(currentYear + 1) },
    { value: String(currentYear + 2).slice(2), label: String(currentYear + 2) },
    { value: String(currentYear + 3).slice(2), label: String(currentYear + 3) },
    { value: String(currentYear + 4).slice(2), label: String(currentYear + 4) },
  ];


  const options_season = [
    { value: 'ps', label: 'PS' },
    { value: 'ss', label: 'SS' },
    { value: 'pf', label: 'PF' },
    { value: 'fw', label: 'FW' }
  ]
  return (
    <BrowserRouter>
      <motion.div
        className={
          addOrderDrawer
            ? "add_folder_container add_order_container"
            : "add_folder_container"
        }
        style={{
          height: addOrderDrawer ? "100vh" : "calc(100vh - 109px)",
          justifyContent: addOrderDrawer ? "flex-start" : "space-between",
        }}
        transition={{ type: "tween" }}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
      >
        <div className="add_folder_top">
          {!addArticle && !addOrderDrawer && (
            <img src={folder} alt="folder image" className="folder_img" />
          )}
          {addOrderDrawer && (
            // <img src={shoppingBag} alt="order icon" className="order_img" />
            <Bag />
          )}
          {addArticle ? (<>
              <span className={"add-to-collection-title"}>
               Add new Article</span>
            </>
          ) : addOrderDrawer ? (
              <><br/>
              <span className={"add-to-collection-title"}>
                Add New Order</span>
                </>
          ) : (
              <><br/>
            <span className={"add-to-collection-title"}>Add New Collection</span>
              </>
          )}
        </div>
        <div className="container">
          {addArticle ? (
            <AddArticleBottomDrawer
              handleChange={(x: any) => handleChange(x)}
              options={options}
              DropdownIndicator={DropdownIndicator}
              customStyles={customStyles}
              genders={allGenders}
              setfodlerSelected={setfodlerSelected}
              setSelectDivision={setSelectDivision}
            />
          ) : addOrderDrawer ? (
            <AddOrderBottomDrawer
              handleChangeGender={handleChangeGender}
              handleChangeOrder={handleChangeOrder}
              state={state}
              handleChange2={handleChange2}
              fetchUser={fetchUser}
              // promiseOptions={promiseOptions}
              handleCollabChange={handleCollabChange}
              collaborators={collaborators}
              handlerType={handleType}
              handleChangeDeliveryDate={handleChangeDeliveryDate}
            />
          ) : (
            <div style={{ width: "100%" }} className="container-fluid">
              <div className="row">
                <div className="form_add_folder_left col-12 col-md-12">
                  {/*   <div className={"row"}>
                  <div className={"col-6"}>

                    <div className="field">
                      <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder=" "
                          onChange={handleChange}
                      />
                      <label htmlFor="name" className="folder_label">
                        Collection Name
                      </label>
                    </div>
                  </div>

                  <div className={"col-6"}>
                    <div className="field">

                      <input
                          type="text"
                          name="season"
                          id="season"
                          placeholder=" "
                          onChange={handleChange}
                      />
                      <label htmlFor="season" className="folder_label">
                        Season
                      </label>
                    </div>
                  </div>
                  </div>*/}
                  <div className="container">
                    <div style={{width: "100%"}}>
                      <div className="row my-2">

                        <div className="col-md-12">
                          <div className="field">
                            <input
                                type="text"
                                name="name"
                                id="name"
                                placeholder=" "
                                onChange={handleChange}
                            />
                            <label htmlFor="name" className="folder_label">
                              Collection Name
                            </label>
                          </div>

                        </div>
                   {/*     <div className={
                          "col-md-6"
                        }>
                          <div className="field ">


                            <input
                                type="text"
                                name="season"
                                id="season"
                                placeholder=" "
                                onChange={handleChange}
                            />
                            <label htmlFor="season" className="folder_label">
                              Season
                            </label>
                          </div>
                        </div>*/}

                      </div>

                      <div className="row ">

                          <div className={
                            "col-md-6"
                          }>
                            <div className="field ">



                              <Select

                                  name="seasons"
                                  placeholder="Select season"
                                  options={
                                    options_season
                                  }
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={handleSeasonChange}
                              />
                            </div>
                          </div>


                        <div className={
                          "col-md-6"
                        }>
                          <div className="field ">




                            <Select

                                name="years"
                                placeholder="Select year"
                                options={
                                  options_year
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handleYearChange}
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="field tags_field my-3 p-3">
                    <Select
                        isMulti
                        name="colors"
                        placeholder="Select your collaborators"
                        options={
                            collaborators &&
                            collaborators.map((el: any) => {
                              return {
                                label: el.name,
                                value: el.id,
                              };
                            })
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleCollabChangeFolder}
                    />
                  </div>

                </div>

              </div>

            </div>
          )}
        </div>

        <div
            className="buttons buttons_form container"
            style={{bottom: addOrderDrawer ? "86px" : "0px"}}
        >
          <Button
              innerButton="Discard"
              buttonClass="submit_button_white_discard_collection"
              onClick={() => {
                if (addFolder) {
                  // dispatch(handleBottomDrawerAddFolder());
                  setMode && setMode("add_to_collection");
                } else {
                  setMode && setMode("add_to_collection");
                  dispatch(clearAllAlerts());
                  discard();
                  dispatch(handleBottomDrawer());
                }
              }}
          />
          <Button
              innerButton={createOrderLoading ? "Confirming..." : "Confirm"}
              buttonClass="submit_button_add_to_collection"
              onClick={async () => {
                if (addOrderDrawer) {
                  if (data.details && data.name) {
                    // dispatch(handleBottomDrawer());
                    dispatch(addOrder(data, true));
                  } else {
                    // dispatch(handleBottomDrawer());
                    dispatch(addOrder(data, true));
                  }

                  // history.push("/new-order");
                  // return <Redirect to="/new-order" />;
                }
                    // else if (addArticle) {
                    //   if (folderData.name) {
                    //     dispatch(
                    //       createArticle(
                    //         {
                    //           ...folderData,
                    //           gender: fodlerSelected.value,
                    //           division: selectDivision.label,
                    //           order_id:
                    //             location.pathname === "/new-order"
                    //               ? orders[0].id
                    //               : Number(params.id),
                    //         },
                    //         true
              //       )
              //     );
              //     dispatch(handleBottomDrawer());
              //   } else {
              //     dispatch(
              //       createArticle(
              //         {
              //           ...folderData,
              //           gender: fodlerSelected.value,
              //           order_id:
              //             location.pathname === "/new-order"
              //               ? orders[0].id
              //               : Number(params.id),
              //         },
              //         true
              //       )
              //     );
              //   }
              // }
              else {
                createNewFolder();
              }
            }}
          />
        </div>
        {/* <div className="alerts_position">
          {alerts.alerts.map((el: any) => (
            <Alert alert={el} className="alert_missing_fodler_name" />
          ))}
        </div> */}
      </motion.div>
    </BrowserRouter>
  );
}
