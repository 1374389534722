import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    addFilter,
    filter,
    getAllProducts,
    getProductsFiltered,
    deleteFilter,
    getProductsNextPage,
    filterAndKeepAll,
    clearAllFilters,
} from "../../actions/products-actions/actions";
import { Filter } from "../../components/Filter/Filter";
import { ProductCard } from "../../components/ProductCard/ProductCard";
import { Link } from "react-router-dom";
import { handleLeftDrawer } from "../../actions/actionCreators";
import { Card, Drawer, Skeleton } from "antd";
import { useParams, useLocation } from "react-router-dom";
import "./MixProducts.scss";
import { FilterOutlined, ClearOutlined } from "@ant-design/icons";
import { Spinner } from "../../components/Spinner";
import backArrow from "../../img/backArrow.png";
import { history } from "../../index";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { addProducttoFolder } from "../../actions/folders-actions/actions";
import { StandardDraggableProductCard } from "../ProductCard/StandardDraggableProductCard";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import ExampleProductCard from "../ProductCard/ExampleProductCard";
import InfiniteScroll from "react-infinite-scroll-component";
import axiosInstance from "../../config/axios-instance";
interface Props {}

const { Meta } = Card;

export const MixProducts = (props: Props) => {
    const location = useLocation();
    const params: any = useParams();
    const [min_price_allowed, setMin_price_allowed] = useState(0);
    const [max_price_allowed, setMax_price_allowed] = useState(1000);
    const [min_price, setMin_price] = useState(0);
    const [max_price, setMax_price] = useState(1000);
    const [min_priceBefore, setMin_priceBefore] = useState(1);
    const [max_priceBefore, setMax_priceBefore] = useState(1000);
    const [min_elasticity, setMin_elasticity] = useState(1);
    const [max_elasticity, setMax_elasticity] = useState(1000);
    const [min_elasticity_before, setMin_elasticity_before] = useState(1);
    const [max_elasticity_before, setMax_elasticity_before] = useState(1000);
    const [min_elasticity_allowed, setMin_elasticity_allowed] = useState(1);
    const [max_elasticity_allowed, setMax_elasticity_allowed] = useState(1000);

    const [min_weight, setMin_weight] = useState(1);
    const [max_weight, setMax_weight] = useState(1000);
    const [min_weight_before, setMin_weight_before] = useState(1);
    const [max_weight_before, setMax_weight_before] = useState(1000);
    const [min_weight_allowed, setMin_weight_allowed] = useState(1);
    const [max_weight_allowed, setMax_weight_allowed] = useState(1000);
    const [forYouCompany, setForYouCompany] = useState<any>(null);

    const [count, setcount] = useState(0);
    const [highestPrice, setHighestPrice] = useState(0);
    const [queries, setQueries] = useState<Array<string>>([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [url, setUrl] = useState(`/products`);
    const dispatch = useDispatch();
    const {
        products,
        productsAll,
        isLoading,
        next_page,
        filters,
        isLoadingNext,
        prices,
        elasticity_fabric,
        weight_fabric,
    } = useSelector((state: any) => state.products);

    useEffect(() => {
        if (count > 0 && url.includes("fabric_types")) {
            dispatch(filter(url.replace("&&", "&")));
        }
        dispatch(addFilter(queries));
    }, [url]);
    useEffect(() => {
        setMax_price(0);
        setMin_price(0);
        setMax_price_allowed(0);
        setMin_price_allowed(0);
    }, []);

    useEffect(() => {
        dispatch(
            filterAndKeepAll(`/products?fabric_types=${params.fabric}`)
        );
    }, [location.pathname]);

    useEffect(() => {

        let resize = () =>
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        resize();
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, [window.innerWidth]);

    useEffect(() => {
        if (productsAll.length) {
            setMax_price(prices.max_price);
            setMin_price(prices.min_price);
            setMax_price_allowed(prices.max_price);
            setMin_price_allowed(prices.min_price);

            setMin_priceBefore(prices.min_price);
            setMax_priceBefore(prices.max_price);
            setMin_elasticity(elasticity_fabric.min_elasticity_value);
            setMax_elasticity(elasticity_fabric.max_elasticity_value);
            setMin_elasticity_before(elasticity_fabric.min_elasticity_value);
            setMax_elasticity_before(elasticity_fabric.max_elasticity_value);
            setMax_elasticity_allowed(elasticity_fabric.max_elasticity_value);
            setMin_elasticity_allowed(elasticity_fabric.min_elasticity_value);

            setMin_weight(weight_fabric.min_weight_value);
            setMax_weight(weight_fabric.max_weight_value);
            setMin_weight_before(weight_fabric.min_weight_value);
            setMax_weight_before(weight_fabric.max_weight_value);
            setMax_weight_allowed(weight_fabric.max_weight_value);
            setMin_weight_allowed(weight_fabric.min_weight_value);
        }
    }, [productsAll]);

    useEffect(() => {
        const company_id = localStorage.getItem("company_id");

        if (!forYouCompany && company_id) {
            getForYouCompany(company_id);
        }
    }, []);

    const getForYouCompany = async (company_id: string) => {
        let token = localStorage.getItem("token");
        if (company_id) {
            await axiosInstance({
                method: "get",
                url: `/admin/forYouCompany/${company_id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(function (response) {
                setForYouCompany(response.data);
            });
            return "ok";
        }
    };

    useEffect(() => {
        // if (!max_price && !min_price) {
        //   setUrl(
        //     params.fabric === "denim"
        //       ? `/products?new=true&fabric_types=${params.fabric}&${queries.join(
        //           "&"
        //         )}&elasticity_min=${min_elasticity}&elasticity_max=${max_elasticity}`
        //       : `/products?new=true&fabric_types=${params.fabric}&${queries.join(
        //           "&"
        //         )} `
        //   );
        // } else {
        if (max_price || min_price) {
            setUrl(
                params.fabric === "denim"
                    ? `/products?fabric_types=${params.fabric}&${queries.join(
                        "&"
                    )}&min_price=${min_price}&max_price=${max_price}
            &elasticity_min=${min_elasticity}&elasticity_max=${max_elasticity}&weight_min=${min_weight}&weight_max=${max_weight}`
                    : `/products?fabric_types=${params.fabric}&${queries.join(
                        "&"
                    )}&min_price=${min_price}&max_price=${max_price}`
            );
        }
        // }
        // setUrl(
        //   `/products?new=true&${queries.join(
        //     "&"
        //   )}&min_price=${min_price}&max_price=${max_price}`
        // );
        setcount(count + 1);
    }, [
        min_price,
        max_price,
        min_elasticity,
        max_elasticity,
        min_weight,
        max_weight,
        queries,
    ]);

    const getNextPageProducts = () => {
        dispatch(
            params.fabric === "denim"
                ? getProductsNextPage(
                    next_page.substr(process.env.REACT_APP_URL_LENGTH) +
                    `&fabric_types=${params.fabric}&${queries.join(
                        "&"
                    )}&min_price=${min_price}&max_price=${max_price}
        &elasticity_min=${min_elasticity}&elasticity_max=${max_elasticity}`
                )
                : getProductsNextPage(
                    next_page.substr(process.env.REACT_APP_URL_LENGTH) +
                    `&fabric_types=${params.fabric}&${queries.join(
                        "&"
                    )}&min_price=${min_price}&max_price=${Number(
                        max_price.toString()
                    )}`
                )
        );
    };

    const handleChangeWeight = (val: any) => {
        let [min, max] = val;
        // setMin_elasticity(min === 0 ? "0.00" : min);
        setMin_weight(min);
        setMax_weight(max);
    };

    const fetchData = async () => {
        //   console.log("isLoadingNext" + isLoadingNext);
        if (next_page) {
            dispatch(
                params.fabric === "denim"
                    ? getProductsNextPage(
                        //test vesion 67
                        //prod version 69
                        //local version 40
                        next_page.substr(45) +
                        `&fabric_types=${params.fabric}&${queries.join(
                            "&"
                        )}&min_price=${min_price}&max_price=${max_price}
          &elasticity_min=${min_elasticity}&elasticity_max=${max_elasticity}`
                    )
                    : getProductsNextPage(
                        //test vesion 67
                        //prod version 69
                        //local version 40
                        next_page.substr(45) +
                        `&fabric_types=${params.fabric}&${queries.join(
                            "&"
                        )}&min_price=${min_price}&max_price=${Number(
                            max_price.toString()
                        )}`
                    )
            );
        }
    };

    var regInteger = /^-?\d+$/;
    //@ts-ignore
    const pathnames = props.location.pathname
        .split("/")
        .filter((element: any) => !regInteger.test(element))
        .map((el: string) => {
            return el.charAt(0).toUpperCase() + el.slice(1);
        })
        .filter((x: string) => x);

    useEffect(() => {
        setUrl(`/products?${queries.join("&")}`.replace("&&", "&"));
    }, [params]);

    //works fine
    const handleChangePrice = (val: any) => {
        let [min, max] = val;
        setMin_price(min);
        setMax_price(max);
    };
    // //works fine
    // const addQuery = (target: any) => {
    //   const element = target.name + "=" + target.id;
    //   if (target.checked) {
    //     setQueries([...queries, element]);
    //   } else if (!target.checked) {
    //     setQueries(queries.filter((el) => el !== element));
    //   }
    // };
    // //works fine
    // const deleteFilterTag = (obj: any) => {
    //   setQueries(queries.filter((el) => el !== obj.filteredBy + "=" + obj.id));
    // };

    const handleChangePriceAfter = (val: any) => {
        let [min, max] = val;
        setMin_priceBefore(min);
        setMax_priceBefore(max);
    };

    const handleChangeElasticity = (val: any) => {
        let [min, max] = val;
        setMin_elasticity(min);
        setMax_elasticity(max);
    };

    const addQuery = (target: any) => {
        const element = target.name + "=" + target.id;
        if (target.checked) {
            var exists = queries.filter((query: any) => query.includes(target.name));
            if (exists.length === 0) {
                setQueries([...queries, element]);
            } else {
                var newQueryVal = exists[0].concat("," + target.id);
                setQueries(
                    queries.map((el: any) =>
                        el.includes(target.name) ? newQueryVal : el
                    )
                );
            }
        } else if (!target.checked) {
            var exist = queries.filter((query: any) =>
                query.includes(target.name)
            )[0];

            if (exist.includes(",") === false) {
                setQueries(queries.filter((el) => !el.includes(target.name)));
            } else if (
                exist.indexOf(target.id) ===
                exist.length - target.id.toString().length
            ) {
                var newQuery = exist.substr(
                    0,
                    exist.length - (target.id.toString().length + 1)
                );
                setQueries(
                    queries.map((el: any) => (el.includes(target.name) ? newQuery : el))
                );
            } else {
                var newQuery = exist.replace(target.id + ",", "");
                setQueries(
                    queries.map((el: any) => (el.includes(target.name) ? newQuery : el))
                );
            }
        }
    };

    const deleteFilterTag = (obj: any) => {
        // setQueries(queries.filter((el) => el !== obj.filteredBy + "=" + obj.id));
        var exist = queries.filter((query: any) =>
            query.includes(obj.filteredBy)
        )[0];
        // if (obj.filteredBy.length === exist.length - 2) {
        if (exist.includes(",") === false) {
            setQueries(queries.filter((el) => !el.includes(obj.filteredBy)));
        } else if (
            exist.indexOf(obj.id) ===
            exist.length - obj.id.toString().length
        ) {
            var newQuery = exist.substr(
                0,
                exist.length - (obj.id.toString().length + 1)
            );
            setQueries(
                queries.map((el: any) => (el.includes(obj.filteredBy) ? newQuery : el))
            );
        } else {
            var newQuery = exist.replace(obj.id + ",", "");
            setQueries(
                queries.map((el: any) => (el.includes(obj.filteredBy) ? newQuery : el))
            );
        }
    };

    const onDragEnd = useCallback((draggable: any) => {
        const { destination, draggableId } = draggable;

        if (draggableId) {
            if (draggableId.includes("draggable-product")) {
                const productData = JSON.parse(draggableId);

                const productId = productData.draggableId.substring(
                    "draggable-product".length
                );
                const currentproductParentId = productData.parentId;
                if (destination && destination.droppableId) {
                    if (destination.droppableId.includes("droppable-minature")) {
                        const newParentIdDroppable = destination.droppableId.substring(
                            "droppable-minature".length
                        );
                        if (newParentIdDroppable !== currentproductParentId) {
                            dispatch(addProducttoFolder(newParentIdDroppable, productId));
                            setIsDragging(false);
                        }
                    }
                }
            }
        }
        setIsDragging(false);
    }, []);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const onDragStart = () => {
        setIsDragging(true);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <div className="products_page_container_product">

                <div className={`container-fluid products_page`}>
                    {width >= 1000 && (
                        <div className="filter_collection">
                            <Filter
                                setMax_elasticity_before={setMax_elasticity_before}
                                setMin_elasticity_before={setMin_elasticity_before}
                                max_elasticity_before={max_elasticity_before}
                                min_elasticity_before={min_elasticity_before}
                                isDragging={isDragging}
                                fabric=""
                                categoryId=""
                                genderId=""
                                handleChangePrice={handleChangePrice}
                                min_price={min_price}
                                max_price={max_price}
                                urlProp={url}
                                setUrl={setUrl}
                                queries={queries}
                                addQuery={addQuery}
                                highestPrice={highestPrice}
                                min_price_allowed={min_price_allowed}
                                max_price_allowed={max_price_allowed}
                                setMax_price={setMax_price}
                                setMin_price={setMin_price}
                                setMax_price_allowed={setMax_price_allowed}
                                setMin_price_allowed={setMin_price_allowed}
                                handleChangePriceAfter={handleChangePriceAfter}
                                max_priceBefore={max_priceBefore}
                                min_priceBefore={min_priceBefore}
                                max_elasticity={max_elasticity}
                                min_elasticity={min_elasticity}
                                handleChangeElasticity={handleChangeElasticity}
                                setMax_weight_before={setMax_weight_before}
                                setMin_weight_before={setMin_weight_before}
                                max_weight_before={max_weight_before}
                                min_weight_before={min_weight_before}
                                max_weight={max_weight}
                                min_weight={min_weight}
                                handleChangeWeight={handleChangeWeight}
                            />
                        </div>
                    )}
                    <div className="products_list">
                        <div className="filter_tags_wrapper">
                            <div style={{ width: "90%" }}>
                                {width >= 1000 ? (
                                    ((filters && filters.length > 0) ||
                                        max_price_allowed !== max_price ||
                                        min_price_allowed !== min_price ||
                                        max_elasticity !== max_elasticity_allowed ||
                                        min_elasticity !== min_elasticity_allowed ||
                                        max_weight !== max_weight_allowed ||
                                        min_weight !== min_weight_allowed) && (
                                        <p>Selected Filters:</p>
                                    )
                                ) : (
                                    <button
                                        className="filter_product_button"
                                        onClick={() => setOpenFilterDrawer(true)}
                                    >
                                        <FilterOutlined className="mr-2" /> Filter
                                    </button>
                                )}
                                <Drawer
                                    title="FILTER"
                                    placement={"left"}
                                    closable={true}
                                    onClose={() => setOpenFilterDrawer(false)}
                                    visible={openFilterDrawer}
                                    key={"left"}
                                >
                                    <div className="p-3">
                                        <Filter
                                            setMax_elasticity_before={setMax_elasticity_before}
                                            setMin_elasticity_before={setMin_elasticity_before}
                                            max_elasticity_before={max_elasticity_before}
                                            min_elasticity_before={min_elasticity_before}
                                            isDragging={isDragging}
                                            fabric={params.fabric}
                                            categoryId={params.categoryId}
                                            genderId={params.genderId}
                                            handleChangePrice={handleChangePrice}
                                            min_price={min_price}
                                            max_price={max_price}
                                            urlProp={url}
                                            setUrl={setUrl}
                                            queries={queries}
                                            addQuery={addQuery}
                                            setMax_price={setMax_price}
                                            setMin_price={setMin_price}
                                            setMax_price_allowed={setMax_price_allowed}
                                            setMin_price_allowed={setMin_price_allowed}
                                            handleChangePriceAfter={handleChangePriceAfter}
                                            max_priceBefore={max_priceBefore}
                                            min_priceBefore={min_priceBefore}
                                            max_elasticity={max_elasticity}
                                            min_elasticity={min_elasticity}
                                            handleChangeElasticity={handleChangeElasticity}
                                            setMax_weight_before={setMax_weight_before}
                                            setMin_weight_before={setMin_weight_before}
                                            max_weight_before={max_weight_before}
                                            min_weight_before={min_weight_before}
                                            max_weight={max_weight}
                                            min_weight={min_weight}
                                            handleChangeWeight={handleChangeWeight}
                                        />
                                    </div>
                                </Drawer>
                                <div className="filter_tags">

                                    {(max_price_allowed !== max_price ||
                                        min_price_allowed !== min_price) && (
                                        <div className="filter_tag">
                                            <p>{`${min_price} - ${max_price}`}</p>
                                            <span
                                                onClick={() => {
                                                    setMax_price(prices.max_price);
                                                    setMin_price(prices.min_price);
                                                    setMin_priceBefore(prices.min_price);
                                                    setMax_priceBefore(prices.max_price);
                                                }}
                                            >
                        X
                      </span>
                                        </div>
                                    )}
                                    {(max_elasticity !== max_elasticity_allowed ||
                                        min_elasticity !== min_elasticity_allowed) && (
                                        <div className="filter_tag">
                                            <p>{`${min_elasticity} - ${max_elasticity}`}</p>
                                            <span
                                                onClick={() => {
                                                    setMax_elasticity(
                                                        elasticity_fabric.max_elasticity_value
                                                    );
                                                    setMin_elasticity(
                                                        elasticity_fabric.min_elasticity_value
                                                    );
                                                    setMax_elasticity_before(
                                                        elasticity_fabric.max_elasticity_value
                                                    );
                                                    setMin_elasticity_before(
                                                        elasticity_fabric.min_elasticity_value
                                                    );
                                                }}
                                            >
                        X
                      </span>
                                        </div>
                                    )}
                                    {(max_weight !== max_weight_allowed ||
                                        min_weight !== min_weight_allowed) && (
                                        <div className="filter_tag">
                                            <p>Weight : {`${min_weight} - ${max_weight}`}</p>
                                            <span
                                                onClick={() => {
                                                    setMax_weight(weight_fabric.max_weight_value);
                                                    setMin_weight(weight_fabric.min_weight_value);
                                                    setMax_weight_before(weight_fabric.max_weight_value);
                                                    setMin_weight_before(weight_fabric.min_weight_value);
                                                }}
                                            >
                        X
                      </span>
                                        </div>
                                    )}
                                    {filters &&
                                        filters.map((el: any, i: number) => (
                                            <div className="filter_tag" key={i}>
                                                <p>{el.name}</p>
                                                <span
                                                    onClick={() => {
                                                        deleteFilterTag(el);
                                                        dispatch(deleteFilter(el.name));
                                                    }}
                                                >
                          X
                        </span>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                        {
                            isLoading ? (
                                <div className="row ">
                                    {Array.from({ length: 10 }, (_, index) => index + 1).map(
                                        (_, i) => (
                                            <div className="col-12 col-md-2" key={i}>
                                                <div className="d-flex flex-column">
                                                    <Card
                                                        loading={isLoading}
                                                        bordered={false}
                                                        style={{ width: "100%" }}
                                                        bodyStyle={{ padding: "5px" }}
                                                        cover={
                                                            <img
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "contain",
                                                                }}
                                                                src={placeholder_jeans}
                                                            />
                                                        }
                                                    >
                                                        <Meta title={"Code"} description={"Name"} />
                                                    </Card>
                                                </div>
                                            </div>
                                        )
                                    )}{" "}
                                </div>
                            ) : products &&
                            products.filter((product: any) => {
                                if (params.fabric) {
                                    return product.fabric_type === params.fabric;
                                } else return product;
                            }).length > 0 ? (
                                <Droppable
                                    droppableId={`products-list-droppable`}
                                    direction="horizontal"
                                >
                                    {(provided, snapshot) => (
                                        <InfiniteScroll
                                            dataLength={products.length} //This is important field to render the next data
                                            next={fetchData}
                                            hasMore={true}
                                            loader={""}
                                        >
                                            <div
                                                // className="row products_elements"
                                                className="products-grid"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {products.map((product: any, index: number) => (
                                                    <ExampleProductCard
                                                        key={index}
                                                        index={index}
                                                        productPage={true}
                                                        foryou={forYouCompany}
                                                        product={product}
                                                        item={{
                                                            main: product.images[0]
                                                                ? product.images[0].path
                                                                : "",
                                                            onHover: product.images[1]
                                                                ? product.images[1].path
                                                                : "",
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        </InfiniteScroll>
                                    )}
                                </Droppable>
                            ) : filters && filters.length !== 1 && products.length === 0 ? (
                                <div className="unavailable_products_container">
                                    <h3 className="unavailable_products">
                                        No products available with the selected filters !
                                    </h3>
                                </div>
                            ) : (
                                filters &&
                                filters.length === 0 &&
                                products.length === 0 && (
                                    <div className="unavailable_products_container">
                                        <h3 className="unavailable_products">
                                            No products available for you for the moment !
                                        </h3>
                                    </div>
                                )
                            )

                        }


                    </div>
                </div>
            </div>
        </DragDropContext>
    );
};
