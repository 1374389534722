import "./OptionDetails.scss";
import { useEffect, useState } from "react";
import { SelectItemOptionDetails } from "./SelectItemOptionDetails";
import { DropZone } from "../DropZone/DropZone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../Alert/Alert";
import { LoadingOutlined } from "@ant-design/icons";
import { CloseCircleOutlined } from "@ant-design/icons";

import { Skeleton, Popconfirm, message, notification, Spin, Radio } from "antd";
import { AddNewOption } from "./AddNewOption";
import {
  createNewOption,
  deleteOneOption,
  deleteOptionFile,
  updateOption,
} from "../../actions/order-actions/actions";
import { DropZoneFile } from "./DropZoneFile";
import { Link, useParams } from "react-router-dom";
import { faFileImage, faFilePdf } from "@fortawesome/free-regular-svg-icons";

interface Props {
  optionSelected?: any;
  setOptionSelected: (x: any) => void;
  width: number;
}

export const OptionDetails = ({
  optionSelected,
  width,
  setOptionSelected,
}: Props) => {
  const [optionToUpdate, setOptionToUpdate] = useState<any>(optionSelected);
  const [selectedSelect, setSelectedSelect] = useState<any>(null);
  const [emptyFields, setEmptyFields] = useState<any>([]);
  const [alerts, setAlerts] = useState<any>(null);
  const [files, setFiles] = useState<any>({});
  const [updatedFields, setUpdatedFields] = useState<any>({});
  const [detachedFiles, setDetachedFiles] = useState<any>([]);
  const [isSimple, setIsSimple] = useState<any>(null);

  const { selectedOption, orders, getOptionLoading, updateOptionLoading } =
    useSelector((state: any) => state.orders);

  const params: any = useParams();
  var order = orders.find((obj: any) => {
    return obj.id == params.id;
  });

  const dispatch = useDispatch();

  // Clear alerts
  useEffect(() => {
    // dispatch(setAlerts(alerts))
    if (alerts) {
      setTimeout(() => {
        setAlerts(null);
      }, 5000);
    }
  }, [alerts]);

  useEffect(() => {
    setOptionToUpdate(selectedOption);
  }, [selectedOption]);
  useEffect(() => {
    setIsSimple(optionToUpdate.isSimple);
  }, []);

  // handle text input value
  const handleChange = (e: any) => {
    setUpdatedFields({ ...updatedFields, [e.target.name]: e.target.value });
    setOptionToUpdate({ ...optionToUpdate, [e.target.name]: e.target.value });
  };

  // delete attached file
  const deleteFile = (file: any) => {
    setFiles({ ...files, [file]: null });
    setOptionToUpdate({ ...optionToUpdate, [file]: null });
  };

  // attach files
  const attachFile = (name: any, file: any) => {
    setFiles({ ...files, [name]: file });
  };

  // handle select input value
  const selectHandleChange = (e: any) => {
    setUpdatedFields({
      ...updatedFields,
      [selectedSelect]: e,
    });
    setOptionToUpdate({
      ...optionToUpdate,
      [selectedSelect]: e,
    });
  };

  // Edit option
  const editOption = () => {
    {
      if (!optionToUpdate.style) {
        const optionKeys = ["style"].filter(
          (el: any) => optionToUpdate[el] === ""
        );
        // const fileKeys = Object.keys(optionToUpdate)
        //   .filter((el: any) => String(el).includes("_file"))
        //   .filter(
        //     (el: any) =>
        //       el !== "wash_file" && el !== "trims_file" && el !== "thread_file"
        //   )
        //   .filter((el: any) => !optionToUpdate[el]);

        setEmptyFields(optionKeys);
        setTimeout(() => {
          setEmptyFields([]);
        }, 4000);
        notification.error({
          placement: "bottomLeft",
          bottom: 50,
          duration: 3,
          message: "",
          description: "Please fill in the required fields",
          className: "danger_notif",
          icon: <CloseCircleOutlined />,
        });
      } else {
        let formData = new FormData();

        Object.keys(files).map((el: any) =>
          formData.append(el, files[el] ? files[el] : null)
        );
        Object.keys(updatedFields).map((el: any) =>
          formData.append(el, updatedFields[el] ? updatedFields[el] : null)
        );

        formData.append("_method", "patch");
        dispatch(deleteOptionFile(optionToUpdate, detachedFiles));
        dispatch(updateOption(formData, optionToUpdate.id));
        setFiles({});
        setEmptyFields([]);
        setUpdatedFields({});
        setOptionToUpdate({});
      }
    }
  };

  //Duplicate option

  const duplicateOption = () => {
    {
      if (!optionToUpdate.style) {
        const optionKeys = ["style"].filter(
          (el: any) => optionToUpdate[el] === ""
        );
        // const fileKeys = Object.keys(optionToUpdate)
        //   .filter((el: any) => String(el).includes("_file"))
        //   .filter(
        //     (el: any) =>
        //       el !== "wash_file" && el !== "trims_file" && el !== "thread_file"
        //   )
        //   .filter((el: any) => !optionToUpdate[el]);

        setEmptyFields(optionKeys);
        setTimeout(() => {
          setEmptyFields([]);
        }, 4000);
        notification.error({
          placement: "bottomLeft",
          bottom: 50,
          duration: 3,
          message: "",
          description: "Please fill in the required fields",
          className: "danger_notif",
          icon: <CloseCircleOutlined />,
        });
      } else {
        let formData = new FormData();

        Object.keys(files).map((el: any) =>
          formData.append(el, files[el] ? files[el] : null)
        );
        Object.keys(updatedFields).map((el: any) =>
          formData.append(el, updatedFields[el] ? updatedFields[el] : null)
        );

        formData.append("_method", "patch");
        dispatch(deleteOptionFile(optionToUpdate, detachedFiles));
        dispatch(updateOption(formData, optionToUpdate.id));

        dispatch(
          createNewOption({
            style: optionToUpdate.style,
            isSimple: optionToUpdate.isSimple,
            fabric: optionToUpdate.fabric,
            trims: optionToUpdate.trims,
            thread: optionToUpdate.thread,
            qty: 1,
            order_article_id: optionToUpdate.order_article_id,
            style_file: optionToUpdate.style_file,
            fabric_file: optionToUpdate.fabric_file,
            wash_file: optionToUpdate.wash_file,
            trims_file: optionToUpdate.trims_file,
            thread_file: optionToUpdate.thread_file,
            name: optionToUpdate.name + " -copy",
          })
        );
        setFiles({});
        setEmptyFields([]);
        setUpdatedFields({});
        setOptionToUpdate({});
      }
    }
  };
  const { user, isLoading, isAdmin } = useSelector((state: any) => state.auth);

  if (order.type == "denim") {
    var keys = ["style", "fabric", "wash", "size", "length"];
  } else {
    var keys = ["style", "fabric", "color", "size", "length"];
  }
  let file_keys = [
    "style_file",
    "fabric_file",
    "wash_file",
    "trims_file",
    "thread_file",
  ];
  return optionToUpdate && selectedOption !== "new" ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="order_option_details"
    >
      <div
        className="d-flex align-items-center mb-2"
        onClick={() => width <= 575 && setOptionSelected(null)}
      >
        {width <= 575 && <FontAwesomeIcon icon={faArrowLeft} />}
        <h1 className="option_details_title ml-1 mb-0 ml-md-0">
          {optionToUpdate.name}
        </h1>
      </div>
      {getOptionLoading ? (
        <Skeleton.Input style={{ width: 350 }} active />
      ) : (
        <>
          <div className="col-7 mt-3 one_option_details">
            <Radio.Group
              name={"isSimple"}
              value={optionToUpdate.isSimple}
              onChange={handleChange}
            >
              <Radio.Button value={2}>Sample</Radio.Button>
              <Radio.Button
                style={{ width: "100px", textAlign: "center" }}
                value={1}
              >
                3D
              </Radio.Button>
            </Radio.Group>
          </div>
        </>
      )}

      {keys.map((el: any, i: number) => (
        <div className="row one_option_details" key={i}>
          <div className="col-12">
            <label className="my-2" style={{ textTransform: "capitalize" }}>
              {el === "qty" ? "Quantity" : el === "style" ? "Style Name" : el}
            </label>
          </div>
          <div className="col-12 col-md-7 option_details_select_wrapper">
            {getOptionLoading ? (
              <Skeleton.Input style={{ width: 350 }} active />
            ) : el == "wash" ? (
              <SelectItemOptionDetails
                optionToUpdate={optionToUpdate}
                handleChange={handleChange}
                optionKey={el}
                selectHandleChange={selectHandleChange}
                setSelectedSelect={setSelectedSelect}
                optionSelected={optionSelected}
              />
            ) : (
              <input
                type="text"
                value={updatedFields[el] || optionToUpdate[el]}
                onChange={handleChange}
                name={el}
                className={`option_details_input ${
                  emptyFields && emptyFields.includes(el)
                    ? "option_details_input_error"
                    : undefined
                }`}
              />
            )}
          </div>
          <div className="col-12 col-md-5 mt-3 mt-md-0">
            {getOptionLoading ? (
              <Skeleton.Input style={{ width: 350 }} active />
            ) : optionToUpdate[file_keys[i]] ? (
              <div className="file_uploaded_miniature">
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={
                      optionToUpdate[file_keys[i]].slice(-3) === "pdf"
                        ? faFilePdf
                        : faFileImage
                    }
                  />
                  <div className="file_uploaded_details">
                    <p className="m-0">
                      {width > 1100 || width <= 575
                        ? optionToUpdate[file_keys[i]].slice(20)
                        : optionToUpdate[file_keys[i]].slice(20).length > 8
                        ? optionToUpdate[file_keys[i]].slice(20, 28) + "..."
                        : optionToUpdate[file_keys[i]].slice(20)}
                    </p>
                    <p className="m-0">
                      {new Date(optionToUpdate.created_at).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <div className="file_icons d-flex align-items-baseline">
                  <Link
                    to={
                      process.env.REACT_APP_STORAGE_URL +
                      optionToUpdate[file_keys[i]]
                    }
                    target="_blank"
                    download={optionToUpdate[file_keys[i]].slice(20)}
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faCloudDownloadAlt} />
                  </Link>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    onClick={() => {
                      setDetachedFiles([...detachedFiles, file_keys[i]]);
                      deleteFile(file_keys[i]);
                    }}
                  />
                </div>
              </div>
            ) : (
              i <= 2 && (
                <div>
                  <DropZoneFile
                    setAlerts={setAlerts}
                    attachFile={attachFile}
                    detachFile={deleteFile}
                    optionKey={el}
                    files={files}
                  />
                </div>
              )
            )}
          </div>
        </div>
      ))}

      {/*  <div className="col-7 one_option_details">
        <label className="my-2">SIZE 'S QUANTITY</label>
        <input
          type="text"
          value={updatedFields.size_qty || optionToUpdate.size_qty}
          onChange={handleChange}
          name={"size_qty"}
          className={"option_details_input mt-2"}
        />
      </div>
      <div className="col-7 one_option_details">
        <label className="my-2">Length</label>

        <input
          type="text"
          value={updatedFields.crotch || optionToUpdate.crotch}
          onChange={handleChange}
          name={"crotch"}
          className={"option_details_input mt-2"}
        />
      </div>
      <div className="col-7 one_option_details">
        <label className="my-2">Delivery Date</label>

        <input
          type="date"
          value={updatedFields.delivery_date || optionToUpdate.delivery_date}
          onChange={handleChange}
          name={"delivery_date"}
          className={"option_details_input mt-2"}
        />
      </div> */}
      <div
        className="option_details_btns my-5 my-md-0 mt-md-5 "
        style={{ marginRight: "5%" }}
      >
        <Popconfirm
          placement="left"
          title={"Are you sure you want to delete this option?"}
          onConfirm={() => dispatch(deleteOneOption(selectedOption))}
          okText="Yes"
          cancelText="No"
        >
          <button className="option_btn option_remove mr-3">Remove</button>
        </Popconfirm>
        <button
          className="option_btn option_save  mr-3"
          onClick={duplicateOption}
        >
          {updateOptionLoading ? (
            <Spin indicator={antIcon} />
          ) : (
            <>Save & duplicate</>
          )}
        </button>
        <button
          className="option_btn option_save main_option"
          onClick={editOption}
        >
          {updateOptionLoading ? <Spin indicator={antIcon} /> : <>Save</>}
        </button>
      </div>
    </motion.div>
  ) : (
    <AddNewOption
      width={width}
      setOptionSelected={setOptionSelected}
      emptyFields={emptyFields}
      setEmptyFields={setEmptyFields}
    />
  );
};
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
