import { CheckCircleOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { CloseCircleOutlined } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";

export const IconNotif = () => <CheckCircleOutlined />;

export const MyNotif = (msg: string, type: string) => {
  // SET CLASSNAME AND ICON FOR NOTIFICATION BASED ON THE PARAM TYPE
  const getNotifType = () => {
    switch (type) {
      case "success":
        return {
          classname: "success_notif",
          icon: <CheckCircleOutlined />,
        };
      case "danger":
        return {
          classname: "danger_notif",
          icon: <CloseCircleOutlined />,
        };
      case "info":
        return {
          classname: "info_notif",
          icon: <InfoCircleOutlined />,
        };
      default:
        return {
          classname: "warning_notif",
          icon: <ExclamationCircleOutlined />,
        };
    }
  };

  return notification.open({
    placement: "bottomLeft",
    bottom: 50,
    duration: 5,
    message: "",
    description: msg,
    className: getNotifType().classname,
    icon: getNotifType().icon,
  });
};
