function Logout() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 28 28"
    >
      <path
        fill="#182844"
        d="M6.211-18.83a.562.562 0 00-.792.2l-.553.98a.56.56 0 00.183.745A9.364 9.364 0 019.375-9 9.372 9.372 0 010 .375 9.372 9.372 0 01-9.375-9a9.378 9.378 0 014.327-7.908.56.56 0 00.183-.745l-.553-.98a.562.562 0 00-.792-.2 11.624 11.624 0 00-5.414 9.848A11.651 11.651 0 00.023 2.625 11.627 11.627 0 0011.625-9a11.625 11.625 0 00-5.414-9.83zM1.125-8.062v-12.375A.564.564 0 00.563-21H-.562a.564.564 0 00-.562.563v12.375a.564.564 0 00.562.562H.563a.564.564 0 00.562-.562z"
        data-name="Tracé 3322"
        transform="translate(11.625 21)"
      ></path>
    </svg>
  );
}

export default Logout;
