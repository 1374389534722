import { Pagination, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNormalOrder, getOrders } from "../../actions/order-actions/actions";
import { CancelledArticlesTable } from "./CancelledArticlesTable";

import { MyOrdersTableRow } from "./MyOrdersTableRow";

interface Props {
  date: any;
  orderType: any;
  orderSource: string;
  setAlerts: (x: any) => void;
  width: any;
}

export const MyOrdersTable = ({
  date,
  orderType,
  setAlerts,
  orderSource,
  width,
}: Props) => {
  const { orders, isLoading } = useSelector((state: any) => state.orders);
  const [filteredOrders, setFilteredOrders] = useState<any>([]);
  const [values, setValues] = useState({
    maxValue: 6,
    minValue: 0,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    switch (orderSource) {
      case "direct_order":
        dispatch(getOrders("completed"));
        break;
      case "normal_order":
        dispatch(getNormalOrder());
        break;
      default:
        console.log("CUSTOMIZATION");
    }
  }, [orderSource]);

  // Filter on date change from parent (MyOrders)
  useEffect(() => {
    if (
      orderType === "In progress" &&
      (orderSource === "direct_order" || orderSource === "normal_order")
    ) {
      setFilteredOrders(
        orders.filter((el: any) => el.updated_at.includes(date))
      );
    } else if (
      orderType === "Cancelled" &&
      orderSource === "direct_order" &&
      orders
    ) {
      setFilteredOrders(
        orders
          .map((el: any) => el.articles)
          .flat()
          .filter((x: any) => x && x.article_status_id === 6)
      );
    } else if (orderType === "Cancelled" && orderSource === "normal_order") {
      let filtered =
        orders &&
        orders
          .map((order: any) => order.products)
          .flat()
          .filter((product: any) => product && product.status.id === 6);

      setFilteredOrders(filtered);
    }
  }, [orders, date, orderType]);

  // Handle Page Change
  const handleChange = (value: number) => {
    setValues({
      minValue: (value - 1) * 6,
      maxValue: value * 6,
    });
  };
  return orderType === "In progress" ? (
    <div className="container-fluid">
      <table className="table mt-2">
        {width > 590 && (
          <thead>
            <tr>
              <th scope="col">
                {orderSource === "direct_order" ? "Order Name" : "Items"}
              </th>
              {width > 768 && <th scope="col">Sent Date</th>}
              {width > 590 && orderSource !== "normal_order" && (
                <th scope="col">Team</th>
              )}
              {width > 590 && orderSource !== "direct_order" ? (
                <th scope="col">Progress</th>
              ) : (
                <th scope="col">Articles Count</th>
              )}
              <th>Order details</th>
            </tr>
          </thead>
        )}
        <tbody>
          {isLoading
            ? Array.from({ length: 6 }, (_, index) => index + 1).map((_, i) => (
                <tr key={i}>
                  {Array.from({ length: 5 }, (_, index) => index + 1).map(
                    (_, j) => (
                      <td key={j} style={{ background: "white" }}>
                        <Skeleton.Input
                          style={{ width: 200, borderRadius: 10 }}
                          className="my-2"
                          active
                        />
                      </td>
                    )
                  )}
                </tr>
              ))
            : filteredOrders.length !== 0 &&
              filteredOrders
                .slice(values.minValue, values.maxValue)
                .map(
                  (el: any, i: number) =>
                    (el.products || el.articles) && (
                      <MyOrdersTableRow order={el} key={i} width={width} />
                    )
                )}
        </tbody>
        <tfoot>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={6}
            total={filteredOrders.length}
            hideOnSinglePage
            onChange={handleChange}
          />
        </tfoot>
      </table>
      {!filteredOrders.length && (
        <p className="text-center bg-white border p-3 rounded">
          No Orders Found
        </p>
      )}
    </div>
  ) : (
    <CancelledArticlesTable
      filteredOrders={filteredOrders}
      handleChange={handleChange}
      setAlerts={(x: any) => setAlerts(x)}
      orderType={orderSource}
    />
  );
};
