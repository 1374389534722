import { OPEN_CLOSE_SEARCH_SCREEN } from "../actions/search-actions/types";

const initState = {
  searchScreen: false,
};

const searchReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case OPEN_CLOSE_SEARCH_SCREEN:
      return {
        searchScreen: !state.searchScreen,
      };
    default:
      return state;
  }
};

export default searchReducer;
