import {
  Drawer,
  PageHeader,
  Tag,
  Comment,
  Avatar,
  DatePicker,
  TimePicker,
  Button,
  Typography,
  Select,
  Input,
} from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import axiosInstance from "../../config/axios-instance";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import demcosvg from "../../img/logo_green.svg";
import { MyNotif } from "../NotifIcons/NotifIcons";
const { Text } = Typography;
const { Option } = Select;

function ConfirmDrawerClient({
  meet,
  clientConfirmDrawer,
  setClientConfirmDrawer,
  onCloseConfirmDrawerClient,
  refetch,
}: any) {
  const [rescheduleIt, setRescheduleIt] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { user } = useSelector((state: any) => state.auth);
  const [data, setData] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);

  const [collaborator, setCollaborators] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const onChangeLocation = (value: any) => {
    console.log(value);
    setLocation(value);
  };

  useEffect(() => {
    if (meet) {
      setData(meet.start);
      setIsConfirmed(meet.details.status === "accepted");
    }
  }, [meet, clientConfirmDrawer]);
  const format = "HH:mm";

  const genStatus = (status: any) => {
    switch (status) {
      case "new":
        return {
          text: "New",
          color: "blue",
        };
      case "waiting":
        return {
          text: "Pending",
          color: "orange",
        };
      case "accepted":
        return {
          text: "Confirmed",
          color: "green",
        };
      default:
        return {
          text: "Cancelled",
          color: "red",
        };
    }
  };

  const rescheduleMeeting = async (type: string) => {
    let token = localStorage.getItem("token");
    if (type === "cancel") {
      await axiosInstance({
        method: "delete",
        url: `/bookings/${meet.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      await axiosInstance({
        method: "post",
        url: `/bookings/${meet.id}`,
        data: {
          status: type === "confirm" ? "accepted" : "waiting",
          date: data,
          date2: endTime, // Utilisez l'heure de fin ici
          client_id: user.id,
          _method: "patch",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    MyNotif(
      type === "cancel"
        ? "Meeting cancelled successfully."
        : type === "confirm"
        ? "Meeting confirmed successfully."
        : "Meeting rescheduled successfully.",
      "success"
    );
    setClientConfirmDrawer(false);
    refetch();
    // return response.data;
  };

  const {
    mutate: mutateUpdateBooking,
    isLoading: updateLoading,
    variables,
  } = useMutation((type: string) => rescheduleMeeting(type));

  const closeDrawer = useCallback(() => {
    onCloseConfirmDrawerClient();
    setIsConfirmed(false);
    setRescheduleIt(true);
    setRescheduleIt(false);
  }, [meet]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  return (
    <Drawer
      placement={"right"}
      closable={true}
      onClose={closeDrawer}
      visible={clientConfirmDrawer}
      key={"right"}
      width={width >768 ? "400" : "300"}
      className="booking-drawer"
      maskClosable={false}
    >
      {meet && (
        <div className="left-calendar-container">
          <h1 className="h1-blue-hj title-drawer-booking" style={{ marginTop: "20px" }}>
            Meeting details
          </h1>
          <PageHeader
            title={meet.subject}
            className="site-page-header  title-drawer-booking p-0 my-5"
            tags={
              <Tag color={genStatus(meet.status).color}>
                {genStatus(meet.status).text}
              </Tag>
            }
            avatar={{
              src: demcosvg,
              className: "demco_drawer_confirmation_logo",
              alt: "Demco Logo",
            }}
          >
            <Comment
              className="meeting_notes border rounded pl-2 bg-white"
              author={meet.details.user && <a>{meet.details.user.name}</a>}
              avatar={
                <Avatar
                  shape="square"
                  src={
                    meet.details.user
                      ? process.env.REACT_APP_STORAGE_URL +
                        meet.details.user.avatar
                      : avatar_placeholder
                    // meet.commercial && meet.commercial.logo
                    //   ? process.env.REACT_APP_STORAGE_URL + meet.commercial.logo
                    //   : avatar_placeholder
                  }
                  alt={meet.details.user && meet.details.user.name}
                />
              }
              content={<p>{meet.notes}</p>}
            />
          </PageHeader>
          <h3 className="h2-blue-hj label-booking">Select Date</h3>
          <DatePicker
            disabled={isConfirmed || !rescheduleIt}
            defaultValue={moment(meet.start)}
            showToday={false}
            disabledDate={function disabledDate(current: any) {
              // Can not select days before today and today
              return (
                (current &&
                  current <= moment().subtract(1, "d").endOf("day")) ||
                ["Saturday", "Sunday"].find(
                  (date) => date === moment(current).format("dddd")
                )
              );
            }}
            onChange={(x: any) =>
              setData(
                moment(x)
                  .set({
                    hour: moment(data).get("hour"),
                    minute: moment(data).get("minute"),
                    second: moment(data).get("second"),
                  })
                  .format()
              )
            }
            style={{ width: "100%" }}
          />
          <h3 className="h2-blue-hj label-booking">Start meeting time</h3>

          <TimePicker
            disabled={isConfirmed || !rescheduleIt}
            onChange={(x: any) =>
              setData(
                moment(data)
                  .set({
                    hour: x.get("hour"),
                    minute: x.get("minute"),
                    second: x.get("second"),
                  })
                  .format()
              )
            }
            style={{ width: "100%" }}
            defaultValue={moment(meet.start).utc()}
            showSecond={false}
            disabledHours={() => [
              0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23,
            ]}
            hideDisabledOptions
            minuteStep={15}
            format={format}
          />

          <h3 className="h2-blue-hj label-booking">End meeting time</h3>
          <TimePicker
            disabled={isConfirmed || !rescheduleIt}
            onChange={(x: any) =>
              setEndTime(
                moment(data)
                  .set({
                    hour: x.get("hour"),
                    minute: x.get("minute"),
                    second: x.get("second"),
                  })
                  .format()
              )
            }
            style={{ width: "100%" }}
            defaultValue={moment(meet.details.date2).utc()}
            showSecond={false}
            disabledHours={() => [
              0, 1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23,
            ]}
            hideDisabledOptions
            minuteStep={15}
            format={format}
          />

          {/*    {meet.duration && (
            <>
              <h3 className="h2-blue-hj">Meeting Duration</h3>
              <div className="d-flex align-items-center border p-2 bg-white mb-4">
                <Text strong>{meet.duration} Hour(s)</Text>
              </div>
            </>
          )} */}

          <h3 className="h2-blue-hj label-booking">Time Zone</h3>
          <Select
            disabled
            style={{ width: "100%" }}
            onChange={(value: any) => setData(value)}
            value={meet.details.timezone}
            placeholder="Select TimeZone"
          >
            <option value="-12">(GMT -12:00) Eniwetok, Kwajalein</option>
            <option value="-11">(GMT -11:00) Midway Island, Samoa</option>
            <option value="-10">(GMT -10:00) Hawaii</option>
            <option value="-09">(GMT -9:00) Alaska</option>
            <option value="-08">
              (GMT -8:00) Pacific Time (US &amp; Canada)
            </option>
            <option value="-07">
              (GMT -7:00) Mountain Time (US &amp; Canada)
            </option>
            <option value="-06">
              (GMT -6:00) Central Time (US &amp; Canada), Mexico City
            </option>
            <option value="-05">
              (GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima
            </option>
            <option value="-04">
              (GMT -4:00) Atlantic Time (Canada), Caracas, La Paz
            </option>
            <option value="-03">
              (GMT -3:00) Brazil, Buenos Aires, Georgetown
            </option>
            <option value="-02">(GMT -2:00) Mid-Atlantic</option>
            <option value="-01">(GMT -1:00) Azores, Cape Verde Islands</option>
            <option value="+00" selected>
              (GMT) Western Europe Time, London, Lisbon, Casablanca
            </option>
            <option value="+01">
              (GMT +1:00) Brussels, Copenhagen, Madrid, Paris
            </option>
            <option value="+02">(GMT +2:00) Kaliningrad, South Africa</option>
            <option value="+03">
              (GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg
            </option>
            <option value="+04">
              (GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi
            </option>
            <option value="+05">
              (GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent
            </option>
            <option value="+06">(GMT +6:00) Almaty, Dhaka, Colombo</option>
            <option value="+07">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
            <option value="+08">
              (GMT +8:00) Beijing, Perth, Singapore, Hong Kong
            </option>
            <option value="+09">
              (GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk
            </option>
            <option value="+10">
              (GMT +10:00) Eastern Australia, Guam, Vladivostok
            </option>
            <option value="+11">
              (GMT +11:00) Magadan, Solomon Islands, New Caledonia
            </option>
            <option value="+12">
              (GMT +12:00) Auckland, Wellington, Fiji, Kamchatka
            </option>
            <option value="+13">(GMT +13:00) Apia, Nukualofa</option>
            <option value="+14">(GMT +14:00) Line Islands, Tokelau</option>
          </Select>

          <h3 className="h2-blue-hj label-booking">Your meeting Subject</h3>
          <Input
            placeholder="Subject Meeting"
            disabled
            onChange={(e: any) => setData(e.target.value)}
            value={meet.subject}
          />

          <h3 className="h2-blue-hj label-booking">Location</h3>
          <Select
            style={{ width: "100%" }}
            onChange={(value: any) => onChangeLocation(value)}
            placeholder="Select Location"
            disabled
            // defaultValue={"Meeting Room"}
          >
            <Option value="1" key={1}>
              Meerting Room
            </Option>
            <Option value="2" key={2} selected={true}>
              VC Link
            </Option>
          </Select>
          <div className="mb-3">
            {location == 2 ? (
              <>
                <h3 className="h2-blue-hj">VC Link</h3>
                <Input
                  disabled
                  placeholder="Google Meet , Zoom"
                  onChange={(e: any) => setData(e.target.value)}
                  value={meet.details.link}
                />
              </>
            ) : (
              <>
                <h3 className="h2-blue-hj label-booking">Meeting Room</h3>
                <Input
                  placeholder="Room ..."
                  disabled
                  onChange={(e: any) => setData(e.target.value)}
                  value={meet.details.link}
                />
              </>
            )}
          </div>
          {/*    {meet.details?.members && (
            <>
              <h3 className="h2-blue-hj">Select Collaborators</h3>
              <Select
                disabled
                mode="multiple"
                className="w-100"
           
                placeholder="Select Collaborators"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                defaultValue={
                  meet.details?.members &&
                  meet.details?.members?.map((collaborator: any) => [
                    collaborator.name,
                  ])
                }
              >
                {meet.details?.members &&
                  meet.details?.members?.map((collaborator: any) => (
                    <Option value={collaborator?.id}>
                      {collaborator.name}
                    </Option>
                  ))}
              </Select>
            </>
          )} */}
          {
            user &&
              meet?.details?.updated_by === "demco" &&
              !isConfirmed &&
              !rescheduleIt && (
                // (!meet.details.user && meet.status === "new" && (
                <Button
                  // type="button"
                  disabled={variables === "cancel"}
                  className="mt-1 reschedule-btn-drawer confirm w-100"
                  onClick={() => mutateUpdateBooking("confirm")}
                >
                  {variables === "confirm" && updateLoading
                    ? "Confirming..."
                    : "Confirm Meeting"}
                </Button>
              )
            // ))
          }
          {!isConfirmed && !rescheduleIt && (
            // !(
            //   user.id === meet.details.client_id &&
            //   meet.details.status === "waiting"
            // ) &&
            <Button
              // type="button"
              disabled={!data}
              className={`reschedule-btn-drawer discard w-100 mt-3`}
              // onClick={() => mutateUpdateBooking("waiting")}
              onClick={() => {
                if (!rescheduleIt) {
                  setRescheduleIt(true);
                } else {
                  mutateUpdateBooking("waiting");
                }
              }}
            >
              {variables === "waiting" && updateLoading
                ? "Rescheduling..."
                : "Reschedule Meeting"}
            </Button>
          )}
          {!isConfirmed && !rescheduleIt && (
            <Button
              type="link"
              className="d-flex justify-content-center cancel-btn-drawer mx-auto mt-3"
              onClick={() => mutateUpdateBooking("cancel")}
            >
              {variables === "cancel" && updateLoading
                ? "Cancelling..."
                : "Cancel Meeting"}
            </Button>
          )}
          {isConfirmed && (
            <Button
              className="reschedule-btn-drawer confirm w-100"
              onClick={() => mutateUpdateBooking("cancel")}
            >
              {variables === "cancel" && updateLoading
                ? "Cancelling..."
                : "Cancel Meeting"}
            </Button>
          )}
          {rescheduleIt && (
            <div style={{ display: "flex", width: "100%" }}>
              <Button
                className="reschedule-btn-drawer discard"
                onClick={() => {
                  onCloseConfirmDrawerClient();
                  setIsConfirmed(false);
                  setRescheduleIt(false);
                }}
              >
                Discard
              </Button>
              <Button
                style={{
                  marginLeft: "1rem",
                }}
                className="reschedule-btn-drawer confirm"
                onClick={() => {
                  if (!rescheduleIt) {
                    setRescheduleIt(true);
                  } else {
                    mutateUpdateBooking("waiting");
                  }
                }}
                loading={updateLoading && variables === "waiting"}
              >
                {variables === "waiting" && updateLoading
                  ? "Rescheduling..."
                  : "Confirm"}
              </Button>
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
}

export default ConfirmDrawerClient;
