import { Divider, Typography, Avatar } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LinesSkeleton from "./LinesSkeleton";
import { Collapse, Slider, Skeleton } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderPlus,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import "./StyleLibarby.css";

import { useState } from "react";
import { closeSideMenuExtension } from "../../actions/left-menu-actions/actions";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const { Text, Title } = Typography;
const { Panel } = Collapse;
const FabricFilter = ({
                        setIsDenim,
                        isDenim,
                        colors,
                        colorsIsLoading,
                        elasticityIsLoading,
                        elasticity,
                        weightIsLoading,
                        weight,
                        fiberIsLoading,
                        fibers,
                        checkboxChange,
                        queryFabricType,
                        compositionsIsLoading,
                        compositions,
                        fabricmill,
                        fabriccolors,
                        fabricType,
                        specialFiber,
                        prices,
                        handleChangePriceAfter,
                        handleChangeWeightAfter,
                        handleChangeElasticityAfter,

                        weight_value,
                        elasticity_value,
                      }: any) => {
  const history = useHistory();
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fabricType_query = queryParams.get("fabric_type");

  const showElasticities = () => {
    let rigid = elasticity.find((el: any) => el.name === "Rigid");
    let comfort = elasticity.find((el: any) => el.name === "Comfort");
    let stretch = elasticity.find((el: any) => el.name === "Stretch");
    let powStretch = elasticity.find((el: any) => el.name === "Power Stretch");
    let hyperStretch = elasticity.find(
        (el: any) => el.name === "Hyper Stretch"
    );
    return [rigid, comfort, stretch, powStretch, hyperStretch].map(
        (el: any) => (
            <div
                className="d-flex align-items-center justify-content-between my-2"
                key={el.id}
            >
              <Text>{el.name}</Text>
              <input
                  type="checkbox"
                  className="filter_checkbox"
                  id="elasticity"
                  name="elasticities"
                  onChange={({ target }: any) =>
                      checkboxChange(target.name, el.id, target.checked)
                  }
              />
            </div>
        )
    );
  };

  type CheckedItems = {
    [category: string]: {
      [id: number]: boolean;
    };
  };
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});

  const handleCheckboxChange = (
      category: string,
      id: number,
      checked: boolean
  ) => {
    setCheckedItems((prev) => ({
      ...prev,
      [category]: {
        //@ts-ignore
        ...prev[category],
        [id]: checked,
      },
    }));
    checkboxChange(category, id, checked);
  };
  return (
      <div>
        <div className="my-4 pl-3">
          <div className="denim_knit_library_head">
            <p
                //@ts-ignore
                onClick={() => {
                  setIsDenim(true);
                  history.push("/library?fabric_type=denim&page=1");
                }}
                style={{
                  color: queryFabricType === "denim" ? "#182844" : "inherit",
                }}
                className={"denim_knit_library"}
            >
              DENIM
            </p>
            <div className="denim_knit_seperator"></div>
            <p
                onClick={() => {
                  setIsDenim(false);
                  history.push("/library?fabric_type=knit&page=1");
                }}
                style={{
                  color: queryFabricType === "knit" ? "#182844" : "inherit",
                }}
                className={"denim_knit_library"}
            >
              KNIT
            </p>
          </div>
        </div>
        <Divider />
        <div>
          <aside className="aside_fabric">
            <Collapse
                defaultActiveKey={["1"]}
                ghost
                expandIconPosition={"right"}
                expandIcon={(iconProp) =>
                    iconProp.isActive ? (
                        <FontAwesomeIcon icon={faMinus} />
                    ) : (
                        <FontAwesomeIcon icon={faPlus} />
                    )
                }
            >
              <Panel header={"Color"} key="color-panel">
                {colorsIsLoading ? (
                    <LinesSkeleton />
                ) : (
                    fabriccolors
                        ?.filter((el: any) => el.type === fabricType_query)
                        ?.map((el: any) => (
                            <div
                                className="d-flex align-items-center justify-content-between my-2"
                                key={el.id}
                            >
                              <div>
                                <Avatar
                                    src={process.env.REACT_APP_STORAGE_URL + el.logo}
                                    className="mr-2"
                                    size="small"
                                />
                                <Text className={"text_size_filter"}>{el.name}</Text>
                              </div>
                              <input
                                  type="checkbox"
                                  className="filter_checkbox"
                                  id={`color-${el.id}`} // Unique ID for each checkbox
                                  name="colors"
                                  checked={
                                    !!(
                                        checkedItems["colors"] &&
                                        checkedItems["colors"][el.id]
                                    )
                                  }
                                  onChange={(e) =>
                                      handleCheckboxChange(
                                          "colors",
                                          el.id,
                                          e.target.checked
                                      )
                                  }
                              />
                            </div>
                        ))
                )}
              </Panel>
              <Divider />
            </Collapse>
          </aside>

          <aside className="aside_fabric">
            <Collapse
                defaultActiveKey={["1"]}
                ghost
                expandIconPosition={"right"}
                expandIcon={(iconProp) =>
                    iconProp.isActive ? (
                        <FontAwesomeIcon icon={faMinus} />
                    ) : (
                        <FontAwesomeIcon icon={faPlus} />
                    )
                }
            >
              <Panel header={"Fabric Mill"} key={uuidv4()}>
                {colorsIsLoading ? (
                    <LinesSkeleton />
                ) : (
                    fabricmill
                        ?.filter((el: any) => el.type === fabricType_query)
                        ?.map((el: any) => (
                            <div
                                className="d-flex align-items-center justify-content-between my-2"
                                key={el.id}
                            >
                              <div>
                                <Text className={"text_size_filter"}>{el.name}</Text>
                              </div>
                              <input
                                  type="checkbox"
                                  className="filter_checkbox"
                                  id="fabricmill"
                                  name="fabricmill"
                                  checked={
                                    !!(
                                        checkedItems["fabricmill"] &&
                                        checkedItems["fabricmill"][el.id]
                                    )
                                  }
                                  onChange={(e) =>
                                      handleCheckboxChange(
                                          "fabricmill",
                                          el.id,
                                          e.target.checked
                                      )
                                  }
                              />
                            </div>
                        ))
                )}
              </Panel>
              <Divider />
            </Collapse>
          </aside>

          {queryFabricType === "denim" && (
              <aside className="aside_fabric">
                <Collapse
                    defaultActiveKey={["1"]}
                    ghost
                    expandIconPosition={"right"}
                    expandIcon={(iconProp) =>
                        iconProp.isActive ? (
                            <FontAwesomeIcon icon={faMinus} />
                        ) : (
                            <FontAwesomeIcon icon={faPlus} />
                        )
                    }
                >
                  <Panel header={"Elasticity"} key={uuidv4()}>
                    <div className="price_range_wrapper">
                      <Slider
                          step={0.01}
                          range
                          defaultValue={[
                            elasticity_value?.min_elasticity.elasticity_value,
                            elasticity_value?.max_elasticity.elasticity_value,
                          ]}
                          max={elasticity_value?.max_elasticity.elasticity_value}
                          min={elasticity_value?.min_elasticity.elasticity_value}
                          // onAfterChange={handleChangePrice}
                          onChange={handleChangeElasticityAfter}
                      />
                      <div className="d-flex justify-content-between prices_filter">
                        <p>
                          {elasticity_value?.min_elasticity.elasticity_value} %{" "}
                        </p>
                        <p>
                          {elasticity_value?.max_elasticity.elasticity_value} %{" "}
                        </p>
                      </div>
                    </div>
                  </Panel>
                  <Divider />
                </Collapse>
              </aside>
          )}

          <aside className="aside_fabric">
            <Collapse
                defaultActiveKey={["1"]}
                ghost
                expandIconPosition={"right"}
                expandIcon={(iconProp) =>
                    iconProp.isActive ? (
                        <FontAwesomeIcon icon={faMinus} />
                    ) : (
                        <FontAwesomeIcon icon={faPlus} />
                    )
                }
            >
              <Panel header={"Weight"} key={uuidv4()}>
                <div className="price_range_wrapper">
                  <Slider
                      step={0.01}
                      range
                      defaultValue={[
                        weight_value?.min_weight_value.weight_value,
                        weight_value?.max_weight_value.weight_value,
                      ]}
                      min={10}
                      max={90}
                      // onAfterChange={handleChangePrice}
                      onChange={handleChangeWeightAfter}
                  />
                  <div className="d-flex justify-content-between prices_filter">
                    <p className={"text_size_filter"}>
                      {" "}
                      {weight_value?.min_weight_value.weight_value}
                    </p>
                    <p className={"text_size_filter"}>
                      {weight_value?.max_weight_value.weight_value}
                    </p>
                  </div>
                </div>
              </Panel>
              <Divider />
            </Collapse>
          </aside>
          {!user?.is_client_clients && (
              <>
                <aside className="aside_fabric">
                  <Collapse
                      defaultActiveKey={["1"]}
                      ghost
                      expandIconPosition={"right"}
                      expandIcon={(iconProp) =>
                          iconProp.isActive ? (
                              <FontAwesomeIcon icon={faMinus} />
                          ) : (
                              <FontAwesomeIcon icon={faPlus} />
                          )
                      }
                  >
                    <Panel header={"Price"} key={uuidv4()}>
                      <div className="price_range_wrapper">
                        <Slider
                            step={0.01}
                            range
                            defaultValue={[
                              prices?.max_price.price,
                              prices?.min_price.price,
                            ]}
                            max={prices?.max_price.price}
                            min={prices?.min_price.price}
                            // onAfterChange={handleChangePrice}
                            onChange={handleChangePriceAfter}
                        />
                        <div className="d-flex justify-content-between prices_filter">
                          <p className={"text_size_filter"}>
                            {prices?.min_price.price} €
                          </p>
                          <p className={"text_size_filter"}>
                            {prices?.max_price.price} €
                          </p>
                        </div>
                      </div>
                    </Panel>
                    <Divider />
                  </Collapse>
                </aside>
              </>
          )}
          <aside className="aside_fabric">
            <Collapse
                defaultActiveKey={["1"]}
                ghost
                expandIconPosition={"right"}
                expandIcon={(iconProp) =>
                    iconProp.isActive ? (
                        <FontAwesomeIcon icon={faMinus} />
                    ) : (
                        <FontAwesomeIcon icon={faPlus} />
                    )
                }
            >
              {console.log(specialFiber)}
              <Panel header={"Special Fiber"} key={uuidv4()}>
                {colorsIsLoading ? (
                    <LinesSkeleton />
                ) : (
                    specialFiber
                        ?.filter((el: any) => el.fabric_type === fabricType_query)
                        ?.map((el: any) => (
                            <div
                                className="d-flex align-items-center justify-content-between my-2"
                                key={el.id}
                            >
                              <div>
                                <Text className={"text_size_filter"}>{el.name} </Text>
                              </div>
                              <input
                                  type="checkbox"
                                  className="filter_checkbox"
                                  id="special_fiber"
                                  name="special_fiber"
                                  checked={
                                    !!(
                                        checkedItems["special_fiber"] &&
                                        checkedItems["special_fiber"][el.id]
                                    )
                                  }
                                  onChange={(e) =>
                                      handleCheckboxChange(
                                          "special_fiber",
                                          el.id,
                                          e.target.checked
                                      )
                                  }
                              />
                            </div>
                        ))
                )}
              </Panel>
              <Divider />
            </Collapse>
          </aside>
          <aside className="aside_fabric">
            <Collapse
                defaultActiveKey={["1"]}
                ghost
                expandIconPosition={"right"}
                expandIcon={(iconProp) =>
                    iconProp.isActive ? (
                        <FontAwesomeIcon icon={faMinus} />
                    ) : (
                        <FontAwesomeIcon icon={faPlus} />
                    )
                }
            >
              {console.log(fabricType)}
              <Panel header={"Fabric Type"} key={uuidv4()}>
                {colorsIsLoading ? (
                    <LinesSkeleton />
                ) : (
                    fabricType
                        ?.filter((el: any) => el.type === fabricType_query)
                        ?.map((el: any) => (
                            <div
                                className="d-flex align-items-center justify-content-between my-2"
                                key={el.id}
                            >
                              <div>
                                <Text className={"text_size_filter"}>{el.name}</Text>
                              </div>
                              <input
                                  type="checkbox"
                                  className="filter_checkbox"
                                  id="fabric_type_id"
                                  name="fabric_type_id"
                                  checked={
                                    !!(
                                        checkedItems["fabric_type_id"] &&
                                        checkedItems["fabric_type_id"][el.id]
                                    )
                                  }
                                  onChange={(e) =>
                                      handleCheckboxChange(
                                          "fabric_type_id",
                                          el.id,
                                          e.target.checked
                                      )
                                  }
                              />
                            </div>
                        ))
                )}
              </Panel>
              <Divider />
            </Collapse>
          </aside>
        </div>
      </div>
  );
};

export default FabricFilter;