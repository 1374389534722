import React from "react";

export type IEditBrushIconProps = {};

const EditBrushIcon: React.FC<IEditBrushIconProps> = ({}) => {
 return (
  <svg
   xmlns="http://www.w3.org/2000/svg"
   width={24}
   height={24}
   viewBox="0 0 24 24"
  >
   <path
    data-name="Path 3306"
    d="M22.93 6.752c2.731-3-.276-6.752-3.312-6.752a3.63 3.63 0 00-3.032 1.68L8.808 13.313a5.337 5.337 0 00-5.126 3.813.8.8 0 01-.769.526c-.585 0-2.397-1.459-2.913-1.812C0 20.183 2 24 6.75 24c6.619 0 6.839-5.486 6.7-6.82zM10.826 21.055A5.248 5.248 0 016.75 22.5a4.7 4.7 0 01-4.808-3.576 2.37 2.37 0 00.971.228 2.3 2.3 0 002.172-1.494c.322-.848.826-2.658 4.186-2.872l2.667 2.37a5.183 5.183 0 01-1.112 3.899zm1.847-5.255l-2.337-2.074 7.479-11.185a2.157 2.157 0 011.8-1.042 3.144 3.144 0 012.689 1.822 2.11 2.11 0 01-.487 2.421z"
    fill="#00468c"
   />
  </svg>
 );
};

export { EditBrushIcon };
