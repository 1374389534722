import "./ResetPasswordForm.scss";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faEyeDropper,
} from "@fortawesome/free-solid-svg-icons";
import {
  clearMsg,
  resetPasswordConnected,
} from "../../actions/auth-actions/actions";
import { useDispatch } from "react-redux";
import Alert from "../../components/Alert/Alert";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { setAlert } from "../../actions/alerts-actions/actions";
import { Button as AntButton } from "antd";

export interface IResetPasswordFormProps {
  setShowReset: (show: boolean) => void;
}

export default function ResetPasswordForm({
  setShowReset,
}: IResetPasswordFormProps) {
  const dispatch = useDispatch();
  const [height, setHeight] = useState(window.innerHeight);

  const [data, setData] = useState({
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const [current, setcurrent] = useState(false);
  const [newpassword, setnewpassword] = useState(false);
  const [passwordconfirm, setpasswordconfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setalert] = useState<any>(undefined);
  const auth = useSelector((state: any) => state.auth);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    setHeight(window.innerHeight);
  }, [window.innerHeight]);
  useEffect(() => {
    {
      auth.msg &&
        setalert({
          msg: auth.msg,
          type: "danger",
          id: uuidv4(),
        });
      setTimeout(() => {
        setalert(undefined);
        dispatch(clearMsg());
      }, 5000);
    }
  }, [auth.msg]);
  const variants = {
    enter: { y: height, opacity: 0, width: "100vw" },
    center: { y: 0, opacity: 1, width: "100vw" },
    exit: { y: height, opacity: 0, width: "100vw" },
  };
  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      className="reset_password_container"
    >
      <div className="container reset_password_container_block">
        <div className="reset_header">
          <p>Change Your Password</p>
        </div>

        <div className="container-fluid">
          <div className="reset_form_container row">

            <div className=" col-12 padding_change_password">
              <div className="field">
                <input
                    type={current ? "text" : "password"}
                    name="old_password"
                    id="old_password"
                    placeholder=" "
                    // value={infos.name}
                    onChange={handleChange}
                />
                <label
                    htmlFor="old_password"
                    className="current_password_label"
                >
                  Current Password
                </label>
                {current ? (
                    <FontAwesomeIcon
                        icon={faEye}
                        onClick={() => setcurrent(false)}
                        className="icon_eye"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faEyeSlash}
                        onClick={() => setcurrent(true)}
                        className="icon_eye"
                    />
                )}
              </div>
              <div className="field new_password_contanier">
                <input
                    type={newpassword ? "text" : "password"}
                    name="new_password"
                    id="new_password"
                    placeholder=" "
                    // value={infos.email}
                    onChange={handleChange}
                />
                <label htmlFor="new_password" className="new_password">
                  New Password
                </label>
                {newpassword ? (
                    <FontAwesomeIcon
                        icon={faEye}
                        onClick={() => setnewpassword(false)}
                        className="icon_eye"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faEyeSlash}
                        onClick={() => setnewpassword(true)}
                        className="icon_eye"
                    />
                )}
              </div>
              <div className="field">
                <input
                    type={passwordconfirm ? "text" : "password"}
                    name="new_password_confirmation"
                    id="new_password_confirmation"
                    placeholder=" "
                    // value={infos.confirmPassword}
                    onChange={handleChange}
                />
                <label
                    htmlFor="new_password_confirmation"
                    className="account_location_label"
                >
                  Confirm Password
                </label>
                {passwordconfirm ? (
                    <FontAwesomeIcon
                        icon={faEye}
                        onClick={() => setpasswordconfirm(false)}
                        className="icon_eye"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faEyeSlash}
                        onClick={() => setpasswordconfirm(true)}
                        className="icon_eye"
                    />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 d-flex buttons_form_reset">
          {/* <Button
            innerButton="Cancel"
            buttonClass="submit_button_white submit_button_white_cancel"
            onClick={() => {
              setShowReset(false);
            }}
          /> */}
          <AntButton
              type="default"
              className="cancel_reset_pwd_btn"
              onClick={() => {
                setShowReset(false);
              }}
          >
            Cancel
          </AntButton>
          <AntButton
              type="default"
              className="confirm_reset_pwd_btn"
              loading={isLoading}
              onClick={async () => {
                setIsLoading(true);
                dispatch(
                    resetPasswordConnected(
                        {...data, email: auth.user.email},
                        setIsLoading
                    )
                );
              }}
          >
            Save
          </AntButton>
          {/* <Button
            innerButton={isLoading ? "Loading" : "Save"}
            buttonClass="submit_button_blue submit_button_blue_confirm"
            onClick={async () => {
              setIsLoading(true);
              dispatch(
                resetPasswordConnected(
                  { ...data, email: auth.user.email },
                  setIsLoading
                )
              );
            }}
          /> */}
        </div>
      </div>
      {/* <div className="alerts_position">
        {alert && <Alert alert={alert} className="alert_missing_fodler_name" />}
      </div> */}
    </motion.div>
  );
}
