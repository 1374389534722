import { Drawer, Avatar, Divider, Button, Result } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../../config/axios-instance";
import LazyImg from "./LazyImage";
import { useDropzone } from "react-dropzone";
import "./StyleLibarby.css";
import { DeleteOutlined } from "@ant-design/icons";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const AddLibraryDrawer = ({
  onClose,
  visible,
  fabricmill,
  item,
  elasticity,
  fibers,
  colors,
  washes,
  weights,
  refetch,
  fabriccolors,
  compositions,
  elasticities,
  fabricType,
  specialFiber,
}: any) => {
  const [data, setData] = useState<any>();
  const [isVisible, setIsVisible] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [typeFilter, setTypeFilter] = useState("denim");
  const [width, setWidth] = useState(window.innerWidth);

  const [files, setFiles] = useState<any>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",

    onDrop: (acceptedFiles: any) => {
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const removeFile = (index: any) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const thumbs = files.map((file: any, index: any) => (
    <>
      <div
        style={{
          display: "inline-flex",
          borderRadius: 2,
          border: "1px solid #eaeaea",
          marginBottom: 8,
          marginRight: 8,
          width: 100,
          height: 100,
          boxSizing: "border-box",
          position: "relative",
        }}
        key={file.name}
      >
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
        {/*   <button>
          Remove
        </button> */}

        <DeleteOutlined
          className="btn-remove"
          onClick={() => removeFile(index)}
        />
      </div>
    </>
  ));
  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);
  const onChange = (e: any) => {
    setTypeFilter(e.target.value);
  };
  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const submit = (e: any) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    const formData = new FormData();
    files.forEach((file: any) => {
      formData.append("files[]", file);
    });

    formData.append(
      "data",
      JSON.stringify({ ...data, _method: "post", typeFilter })
    );

    setUpdateLoading(true);

    return axiosInstance({
      method: "post",
      url: `/admin/fabrics`,
      // data: { ...data, _method: "post", typeFilter },
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async () => {
        setUpdateLoading(false);
        await refetch();
        onClose();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Drawer
        title={`Add new fabric`}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={width > 768 ? "510" : "310"}
        mask
        maskClosable={false}
        className={"title_new_fabric"}
      >
        <>
          <div className="d-flex justify-content-center flex-column align-items-center mt-4"></div>

          <form
            className="d-flex flex-column px-5"
            style={{ position: "relative", paddingBottom: 90 }}
          >
            <div className="d-flex flex-column my-2">
              <label  className={"label_fabric"}>Fabric type filter</label>
              <select
                className="input-items-update label_fabric"
                name="wash_id"
                onChange={onChange}

              >
                <option disabled value="">
                  Select Fabric
                </option>
                <option value="denim">Denim</option>
                <option value="knit">Knit</option>
              </select>
            </div>
            <div className="d-flex flex-column my-2">
              <label  className={"label_fabric"}>Fabric Mill</label>

              <select
                className="input-items-update"
                name="fabric_mill_id"
                onChange={handleChange}
              >
                <option disabled value="">
                  Select Mill
                </option>
                {fabricmill?.filter((el: any) => el.type == typeFilter).map((el: any) => (
             
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="d-flex flex-column">
              <label className={"label_fabric"}>Fabric Name</label>
              <input
                type="text"
                className="input-items-update"
                required
                name="name"
                onChange={handleChange}
              />
            </div>

            <div className="d-flex flex-column">
              <label className={"label_fabric"}>Fabric Color</label>
              <input
                type="text"
                className="input-items-update"
                required
                name="color"
                onChange={handleChange}
              />
            </div>
            <div className="d-flex flex-column my-2">
              <label className={"label_fabric"}>Fabric description</label>
              <textarea
                className="input-items-update"
                name="description"
                onChange={handleChange}
              />
            </div>
            <div className="d-flex flex-column my-2">
              <label className={"label_fabric"}>Color</label>
              {/*     <input
                type="text"
                className="input-items-update"
                name="color"
                onChange={handleChange}
              /> */}
              <select
                className="input-items-update"
                name="fabric_color_id"
                onChange={handleChange}
              >
                <option disabled value="">
                  Select color
                </option>
                {fabriccolors?.map((el: any) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex flex-column my-2">
              <label className={"label_fabric"}>Fabric Type</label>
              <select
                className="input-items-update"
                name="fabric_type_id"
                onChange={handleChange}
              >
                <option disabled value="">
                  Select fabric type
                </option>
                {fabricType?.filter((el: any) => el.type == typeFilter).map((el: any) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex flex-column my-2">
              <label className={"label_fabric"}>Width</label>
              <input
                type="text"
                name="width"
                onChange={handleChange}
                className="input-items-update"
              />
            </div>
            {/*   <div className="d-flex flex-column my-2">
              <label style={{ fontWeight: 600 }}>Fabric Type</label>
              <input
                type="text"
                className="input-items-update"
                name="fabric"
                onChange={handleChange}
              />
            </div> */}
            <div className="d-flex flex-column my-2">
              <label className={"label_fabric"}>Composition</label>

           {/*    <select
                className="input-items-update"
                name="composition_id"
                onChange={handleChange}
              >
                <option disabled value="">
                  Select compositions
                </option>
                {compositions?.map((el: any) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select> */}
                <input
                  type="text"
                  className="input-items-update"
                  name="composition_value"
                  onChange={handleChange}
                />

            </div>
            {typeFilter === "denim" && (
              <div className="d-flex flex-column my-2">
                <label className={"label_fabric"}>Elasticity</label>
                <input
                  type="text"
                  className="input-items-update"
                  name="elasticity_value"
                  onChange={handleChange}
                />
                {/*   <select
                    className="input-items-update"
                    name="elasticity_id"
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      Select elasticity
                    </option>
                    {elasticities?.map((el: any) => (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                  </select> */}
              </div>
            )}
            <div className="d-flex flex-column my-2">
              <label className={"label_fabric"}>Weight</label>

              <input
                type="text"
                name="weight_value"
                onChange={handleChange}
                className="input-items-update"
              />
              {/*    <select
                className="input-items-update"
                name="weight_id"
                onChange={handleChange}
              >
                <option disabled value="">
                  Select weights
                </option>
                {weights?.map((el: any) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select> */}
            </div>
            <div className="d-flex flex-column my-2">
              <label className={"label_fabric"}>Special Fiber</label>
              <select
                className="input-items-update"
                name="special_fiber_id"
                onChange={handleChange}
              >
                <option disabled value="">
                  Select Special Fiber
                </option>
                {specialFiber
                  ?.filter((el: any) => el.fabric_type == typeFilter)
                  .map((el: any) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
              </select>
            </div>
            {typeFilter === "denim" && (
              <>
                <div className="d-flex flex-column my-2">
                  <label className={"label_fabric"}>Growth</label>
                  <input
                    type="text"
                    className="input-items-update"
                    name="growth"
                    onChange={handleChange}
                  />
                </div>
                <div className="d-flex flex-column my-2">
                  <label className={"label_fabric"}>Shrinkage warp </label>
                  <input
                    type="text"
                    className="input-items-update"
                    name="retrait_warp_max"
                    onChange={handleChange}
                  />
                </div>
                <div className="d-flex flex-column my-2">
                  <label className={"label_fabric"}>Shrinkage weft </label>
                  <input
                    type="text"
                    className="input-items-update"
                    name="retrait_weft_max"
                    onChange={handleChange}
                  />
                </div>

                <div className="d-flex flex-column my-2">
                  <label className={"label_fabric"}>Tearing weft</label>
                  <input
                    type="text"
                    className="input-items-update"
                    name="tearing_weft"
                    onChange={handleChange}
                  />
                </div>
                <div className="d-flex flex-column my-2">
                  <label className={"label_fabric"}>Tearing wrap</label>
                  <input
                    type="text"
                    className="input-items-update"
                    name="tearing_wrap"
                    onChange={handleChange}
                  />
                </div>
                <div className="d-flex flex-column my-2">
                  <label className={"label_fabric"}>Price</label>
                  <input
                    type="text"
                    name="price"
                    onChange={handleChange}
                    className="input-items-update"
                  />
                </div>
                <div className="d-flex flex-column my-2">
                  <label className={"label_fabric"}>Price/150 </label>
                  <input
                    type="text"
                    name="price_150"
                    onChange={handleChange}
                    className="input-items-update"
                  />
                </div>
              </>
            )}
            {typeFilter === "knit" && (
              <>
                <div className="d-flex flex-column my-2">
                  <label className={"label_fabric"}>Price/kg </label>
                  <input
                    type="text"
                    className="input-items-update"
                    name="price_kg"
                    onChange={handleChange}
                  />
                </div>
                <div className="d-flex flex-column my-2">
                  <label className={"label_fabric"}>Price/metre</label>
                  <input
                    type="text"
                    className="input-items-update"
                    name="price_metre"
                    onChange={handleChange}
                  />
                </div>
                <div className="d-flex flex-column my-2">
                  <label className={"label_fabric"}>Price BCI Cotton</label>
                  <input
                    type="text"
                    name="price_bci"
                    onChange={handleChange}
                    className="input-items-update"
                  />
                </div>
                <div className="d-flex flex-column my-2">
                  <label className={"label_fabric"}>
                    Price Organic cotton
                  </label>
                  <input
                    type="text"
                    name="price_organic"
                    onChange={handleChange}
                    className="input-items-update"
                  />
                </div>
              </>
            )}
            <section className="container">
              <label className={"label_fabric"}>Images of fabric</label>

              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some images, or click to select images</p>
              </div>
              <aside className="row">{thumbs}</aside>
            </section>
            <footer
              className="library-drawer-footer footer_library d-flex justify-content-center pl-0 border-top mt-3 py-3 position-abosulte bg-white"
/*
              style={{ position: "fixed", bottom: "0", width: width >768  ? "420" :"120" }}
*/
            >
              <Button
                type="default"
                className="cancel_btn"
                onClick={() => (data ? setIsVisible(true) : onClose())}
              >
                Cancel
              </Button>
              <Button
                type="default"
                className="approve_btn"
                loading={updateLoading}
                onClick={submit}
              >
                Save
              </Button>
            </footer>
          </form>
        </>
      </Drawer>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        visible={isVisible}
        width={width > 768 ? "510" : "310"}
        mask
        maskClosable={false}
        className="cancel-update-drawer"
      >
        <Result
          status="warning"
          title="You have made some changes to this item, are you sure you want to cancel these changes ?"
          extra={[
            <Button
              type="primary"
              onClick={() => {
                setIsVisible(false);
                onClose();
              }}
              style={{
                borderRadius: 5,
                background: "#00468c",
                borderColor: "#00468c",
              }}
              className="px-5"
            >
              Yes
            </Button>,
            <Button
              type="ghost"
              onClick={() => setIsVisible(false)}
              style={{ borderRadius: 5 }}
              className="px-5"
            >
              No
            </Button>,
          ]}
        />
      </Drawer>
    </>
  );
};

export default AddLibraryDrawer;
