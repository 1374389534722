import { Collapse, Select, Tag, Input, InputNumber } from "antd";
import { useSelector } from "react-redux";

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

const WashCollapse = ({
  genExtra,
  i,
  el,
  setProductToUpdate,
  productToUpdate,
  data,
  setData,
  options,
  handleChangeMultiWash,
  handleChange,
}: any) => {
  const { user } = useSelector((state: any) => state.auth);

  function comparer(otherArray: any) {
    return function (current: any) {
      return (
        otherArray.filter(function (other: any) {
          return other.id == current.id;
        }).length == 0
      );
    };
  }
  return (
    <Collapse defaultActiveKey={["0"]} key={i + 2}>
      <Panel
        header={el.name || `Wash num ${i + 1}`}
        key={i}
        extra={genExtra(i)}
      >
        <table
          style={{
            width: "100%",
            borderRight: "none",
            borderLeft: "none",
          }}
        >
          <tbody>
            <tr>
              <th>Colour</th>
              <td>
                <Select
                  disabled={
                    !user.permissions.includes("product.wash.management") &&
                    !user.permissions.includes("product.update")
                  }
                  mode="multiple"
                  showArrow
                  tagRender={({ label, onClose, closable }: any) => (
                    <Tag
                      color="#00468c"
                      closable={closable}
                      onClose={onClose}
                      style={{ marginRight: 3 }}
                    >
                      {label}
                    </Tag>
                  )}
                  defaultValue={
                    el.colors &&
                    productToUpdate.washes.find((x: any) => x.id === el.id) &&
                    productToUpdate.washes
                      .find((x: any) => x.id === el.id)
                      .colors.map((y: any) => y.name)
                  }
                  style={{ width: "100%" }}
                  onChange={(x: any, value: any) => {
                    setProductToUpdate({
                      ...productToUpdate,
                      washes: productToUpdate.washes.map((wash: any) =>
                        wash.id === el.id
                          ? {
                              ...wash,
                              colors: value,
                            }
                          : wash
                      ),
                    });
                    setData({
                      ...data,
                      washes: data.washes.map((wash: any) =>
                        wash.wash_id === el.id
                          ? {
                              ...wash,
                              colors: value.map((o: any) => o.id),
                            }
                          : wash
                      ),
                    });
                  }}
                  options={
                    options.colors_options &&
                    options.colors_options.map((cl: any) => {
                      return { ...cl, value: cl.name };
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <th>Wash Type</th>
              <td>
                <Select
                  disabled={
                    !user.permissions.includes("product.wash.management") &&
                    !user.permissions.includes("product.update")
                  }
                  value={el.name}
                  style={{ width: "100%" }}
                  placeholder="Select Wash"
                  onChange={(val: any) => {
                    setProductToUpdate({
                      ...productToUpdate,
                      washes: productToUpdate.washes.map((wash: any) =>
                        wash.id === el.id ? JSON.parse(val) : wash
                      ),
                    });
                    setData({
                      ...data,
                      washes: data.washes.map((wash: any) =>
                        wash.wash_id === el.id
                          ? { ...wash, wash_id: JSON.parse(val).id }
                          : wash
                      ),
                    });
                  }}
                >
                  {options.washes_options &&
                    options.washes_options
                      .filter(comparer(productToUpdate.washes))
                      .map((wash: any) => (
                        <Option value={JSON.stringify(wash)} key={wash.id}>
                          {wash.name}
                        </Option>
                      ))}
                </Select>
              </td>
            </tr>
            <tr>
              <th>Eim Score</th>
              <td>
                <InputNumber
                  disabled={
                    !user.permissions.includes("product.wash.management") &&
                    !user.permissions.includes("product.update")
                  }
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  name="eim_score"
                  placeholder="Eim Score"
                  value={
                    productToUpdate.washes.find(
                      (wash: any) => wash.id === el.id
                    )?.eim_score || 0
                  }
                  onChange={(num: any) =>
                    handleChangeMultiWash(num, "eim_score", el)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>Wash Price</th>
              <td>
                <InputNumber
                  disabled={
                    !user.permissions.includes("product.wash.management") &&
                    !user.permissions.includes("product.update")
                  }
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  name="wash_price"
                  placeholder="Wash Price"
                  value={
                    productToUpdate.washes.find(
                      (wash: any) => wash.id === el.id
                    )?.wash_price || 0
                  }
                  onChange={(num: any) =>
                    handleChangeMultiWash(num, "wash_price", el)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>Wash Description</th>
              <td>
                <TextArea
                  disabled={
                    !user.permissions.includes("product.wash.management") &&
                    !user.permissions.includes("product.update")
                  }
                  name="description"
                  onChange={(e: any) =>
                    handleChangeMultiWash(e, "description", el)
                  }
                  placeholder="Wash Description"
                  value={
                    productToUpdate.washes.find(
                      (wash: any) => wash.id === el.id
                    )?.description || productToUpdate.wash_description
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
        {productToUpdate.with_options && (
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <th>Total Price</th>
                <td>
                  <Input
                    disabled={
                      !user.permissions.includes("product.wash.management") &&
                      !user.permissions.includes("product.update")
                    }
                    type="number"
                    min={0}
                    name={i === 0 ? "total_price" : "total_price_two"}
                    placeholder="Total Price"
                    value={
                      i === 0
                        ? productToUpdate.total_price
                        : productToUpdate.total_price_two
                    }
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </Panel>
    </Collapse>
  );
};

export default WashCollapse;
