import React, { useState } from "react";
import { Modal, Button } from "antd";
import "./ConfirmPasswordModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {  } from "@fortawesome/free-regular-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
export interface IConfirmPasswordModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (etat: boolean) => void;
  setInfos: (etat: string) => void;
  updateAccount: () => void;
  infos: any;
}

export default function ConfirmPasswordModal({
  isModalVisible,
  setIsModalVisible,
  setInfos,
  infos,
  updateAccount,
}: IConfirmPasswordModalProps) {
  const [current, setcurrent] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <Modal
      title="Confirm account updates"
      visible={isModalVisible}
      onOk={updateAccount}
      onCancel={handleCancel}
      wrapClassName="modal_update_account"
    >
      {/* <div className="field">
        <input
          type={current ? "text" : "password"}
          name="password"
          id="password"
          placeholder="Your password"
          onChange={(e) => setInfos({ ...infos, password: e.target.value })}
        />
        <label htmlFor="password" className="account_job_title_label">
          Password
        </label>
        {current ? (
          <FontAwesomeIcon
            icon={faEye}
            onClick={() => setcurrent(false)}
            className="icon_eye"
          />
        ) : (
          <FontAwesomeIcon
            icon={faEyeSlash}
            onClick={() => setcurrent(true)}
            className="icon_eye"
          />
        )}
      </div> */}
      Are you sure you want to confirm these changes?
    </Modal>
  );
}
