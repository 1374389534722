import { Skeleton } from "antd";

const LinesSkeleton = () => {
  return (
    <div>
      <Skeleton
        //    style={{ width: 300, height: "40px" }}
        active
      />
    </div>
  );
};

export default LinesSkeleton;
