import { Avatar, Tooltip } from "antd";
import company_placeholder from "../../img/company_placeholder.png";
import { time } from "console";
import { useEffect } from "react";

export default function AvatarGroup({ meet, table = false }: any) {
  /*   let hours = new Date(meet.date).getHours();
  let minutes = new Date(meet.date).getMinutes();
  let hours2 = new Date(meet.date2).getHours();
  let minutes2 = new Date(meet.date2).getMinutes();

  const timeZone = meet.timezone; */
  /*   const hourUser = hours + ":" + minutes;
  const hourUser2 = hours2 + ":" + minutes2; */

  function convertHour(timeZoneOffset: any, hourUser: any) {
    // Split the hourUser string into hours and minutes
    const [hour, minutes] = hourUser.split(":").map(Number);

    // Check if the input hourUser is valid
    if (
      isNaN(hour) ||
      isNaN(minutes) ||
      hour < 0 ||
      hour >= 24 ||
      minutes < 0 ||
      minutes >= 60
    ) {
      return "Invalid time format";
    }

    // Calculate the offset in minutes
    const offsetMinutes = timeZoneOffset * 60;

    // Create a new Date object for the current date and time
    const currentDate = new Date();

    // Set the hours and minutes based on user input
    currentDate.setHours(hour);
    currentDate.setMinutes(minutes);

    // Apply the time zone offset
    currentDate.setMinutes(currentDate.getMinutes() + offsetMinutes);

    // Format the adjusted time
    const hourCorresponding = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return hourCorresponding;
  }

  /*   const hourCorr = convertHour(timeZone, hourUser);
  const hourCorrEnd = convertHour(timeZone, hourUser2); */

  function returnHour(date: any) {
    //  var date = date.toString();
    if (date && typeof date === "string") {
      var dateParts = date.split(/[- :]/);
      var year = parseInt(dateParts[0], 10);
      var month = parseInt(dateParts[1], 10) - 1; // Months are zero-based
      var day = parseInt(dateParts[2], 10);
      var hour = parseInt(dateParts[3], 10);
      var result = hour + 2;
      var minute = parseInt(dateParts[4], 10);
      var second = parseInt(dateParts[5], 10);
      return result;
    }
  }

  const dataStart = new Date(meet.date);

  const yearsStart = dataStart.getFullYear();
  const monthStart = dataStart.getMonth() + 1; // Month is zero-based
  const dayStart = dataStart.getDate();

  let hours = new Date(meet.date).getUTCHours();

  let minutes = new Date(meet.date).getMinutes();
  let hours2 = new Date(meet.date2).getHours();

  let minutes2 = new Date(meet.date2).getMinutes();
  const timeZone = meet.timezone;
  /*   var endHour = returnHour(meet.date2);
   */
  const heureUtilisateur = hours + ":" + minutes;
  const heureUtilisateur2 = hours2 + ":" + minutes2;
  /*   const heureUtilisateur = Number(hours) + 2 + ":" + minutes;
  const heureUtilisateur2 = endHour + ":" + minutes2; */

  const heureCorrespondante = convertHour(timeZone, heureUtilisateur);
  const heureCorrespondanteend = convertHour(timeZone, heureUtilisateur2);

  /*   const heureCorrespondante = hours + ":" + minutes;
  const heureCorrespondanteend = hours2 + ":" + minutes2; */

  if (timeZone >= 0) {
    hours -= timeZone;
  } else {
    hours += Math.abs(timeZone);
  }

  const startDate = new Date(
    Date.UTC(yearsStart, monthStart - 1, dayStart, hours, minutes, 0)
  );

  const startTime2 = startDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const startTime = new Date(meet.date).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const inputDate = new Date(meet.date2);

  // Extract the year, month, day, and hour from the original date
  const year = inputDate.getFullYear();
  const month = inputDate.getMonth() + 1; // Month is zero-based
  const day = inputDate.getDate();
  let hour = inputDate.getHours();
  const minut = inputDate.getMinutes();

  if (timeZone >= 0) {
    hour -= timeZone;
  } else {
    hour += Math.abs(timeZone);
  }

  // Create a new date with the same year, month, day, and hour, but modified minutes and seconds
  const modifiedDate = new Date(Date.UTC(year, month - 1, day, hour, minut, 0));

  const endTime = modifiedDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    <>
      <Avatar.Group
        maxCount={2}
        maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
      >
        <Tooltip
          title={
            meet?.client?.company
              ? meet?.client?.company?.name
              : meet?.client?.name
          }
          placement="top"
          overlayInnerStyle={{ background: "white", color: "black" }}
        >
          <Avatar
            size={15}
            src={
              meet?.client?.company
                ? meet?.client?.company?.logo
                  ? process.env.REACT_APP_STORAGE_URL +
                    meet?.client?.company?.logo
                  : company_placeholder
                : company_placeholder
            }
          />
          {/* <Tooltip
            title={meet.client.name}
            placement="top"
            overlayInnerStyle={{
              background: "white",
              color: "black",
            }}
          >
            <Avatar
              size={20}
              src={
                meet.client.logo
                  ? process.env.REACT_APP_STORAGE_URL + meet.client.logo
                  : avatar_placeholder
              }
            />
          </Tooltip> */}
        </Tooltip>
      </Avatar.Group>
      {!table && (
        <div>
          <span>{meet.subject} </span>
          <br />
          <span>
            {/* {hours} : {minutes} ({meet.duration} h) */}
            {startTime2} - {endTime}
          </span>
        </div>
      )}
    </>
  );
}
