import { Avatar, Button, Modal, Popconfirm, Tag, Tooltip } from "antd";

import moment from "moment";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import { useMutation } from "react-query";
import {
  deleteOneProduct,
  restoreProduct,
} from "../../actions/my-orders/services";
import { useState } from "react";
import {
  PlusSquareOutlined,
  PlusOutlined,
  MinusSquareOutlined,
  MinusOutlined,
  FolderOutlined,
  FolderAddOutlined,
  FolderOpenOutlined,
  UserOutlined,
} from "@ant-design/icons";
import axiosInstance from "../../config/axios-instance";

export default function TableRowCancelledDemcoProduct({
  product,
  refetch,
}: any) {
  const { mutateAsync: deleteProduct, isLoading: deleteLoading } = useMutation(
    deleteOneProduct,
    {
      onSuccess: () => {
        MyNotif("Order deleted successfully.", "success");
        refetch();
      },
    }
  );
  const { mutateAsync: restoreOpt, isLoading: restoreLoading } = useMutation(
    restoreProduct,
    {
      onSuccess: () => {
        MyNotif("Order restored successfully.", "success");
        refetch();
      },
    }
  );

  const [showTable, setShowTable] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [showTr, setShowTr] = useState("");
  const [isModalRestore, setIsModalRestore] = useState(false);
  const [orderToRestore, setOrderToRestore] = useState<any>("");
  const [productToRestore, setProductToRestore] = useState<any>("");

  const showModalRestore = (order_id: any, product_id: any) => {
    setIsModalRestore(true);
    setOrderToRestore(order_id);
    setProductToRestore(product_id);
  };

  const restoreProdLeft = () => {
    let token = localStorage.getItem("token");

    axiosInstance({
      method: "post",
      url: `/orders/${orderToRestore}/products/${productToRestore}/status/left`,
      data: {
        status_id: 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Restore with successfully.", "success");
        setIsModalRestore(false);
        refetch();
      }
    });
  };

  const restore = () => {
    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/orders/${orderToRestore}/products/${productToRestore}/status`,
      data: {
        status_id: 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Restore with successfully.", "success");
        setIsModalRestore(false);
        refetch();
      }
    });
  };

  const handleCancel = () => {
    setIsModalRestore(false);
  };

  const genStatusMsg = (status_name: string) => {
    switch (status_name) {
      case "PROTO":
        return {
          color: "#BCBCBD",
          msg: "Your prototype is being prepared",
        };
      case "PRODUCTION":
      case "OK PROD":
        return {
          msg: "Your order is in process",
          color: "#06C170",
        };
      case "CANCELLED":
        return { color: "#FF3A3A", msg: "Your order is canceled" };
      default:
        return {
          color: "#FF872A",
          msg: "We are waiting for your answer",
        };
    }
  };
  console.log(product);

  return (
    /*     <tr key={product.id}>
      <td scope="row">
        <span className="myorder_order_name">{product.name}</span>
      </td>
      <td scope="row">
        <span>{product.order_ref}</span>
      </td>
      <td scope="row">
        <span>{moment(product?.canceld_at).format("DD/MM/YYYY")}</span>
      </td>
      <td>
        <div>
          <Popconfirm
            placement="top"
            title={"Are you sure you want to delete this order ?"}
            onConfirm={() =>
              deleteProduct({
                order_id: product.order_id,
                product_id: product.id,
              })
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="ghost"
              size={"middle"}
              className="mr-2"
              style={{ borderRadius: "4px" }}
              icon={<DeleteOutlined style={{ color: "#00468c" }} />}
              loading={deleteLoading}
            >
              Delete
            </Button>
          </Popconfirm>
          <Popconfirm
            placement="top"
            title={"Are you sure you want to restore this order ?"}
            onConfirm={() =>
              restoreOpt({
                order_id: product.status.order_id,
                product_id: product.id,
              })
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              size={"middle"}
              style={{ borderRadius: "4px" }}
              icon={
                <InboxOutlined
                  style={{ color: "#00468c", verticalAlign: "1px" }}
                />
              }
              loading={restoreLoading}
            >
              Restore
            </Button>
          </Popconfirm>
        </div>
      </td>
    </tr> */
    <>
      {product.products.length > 0 && (
        <tr onClick={() => setShowTable(!showTable)}>
          <td
            width={"5%"}
            className={showTable ? "top_article active_tr" : "top_article"}
          >
            <button onClick={() => setShowTable(!showTable)}>
              {showTable ? (
                <>
                  <FolderOpenOutlined
                    style={{ color: "#00468C", fontSize: "20px" }}
                  />
                </>
              ) : (
                <>
                  <FolderAddOutlined
                    style={{ color: "#00468C", fontSize: "20px" }}
                  />
                </>
              )}
            </button>
          </td>

          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: 0 }}
          >
            {product.reference}
          </td>

          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: 0 }}
          >
            {product?.products[0]?.collection_name || "-"}
          </td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: 0 }}
          >
            {/*   {moment(product.updated_at).format("DD/MM/YYYY")} */}
          </td>

          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: 0 }}
          ></td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: 0 }}
          >
            <p style={{ marginLeft: "50px" }}>{product.products.length}</p>
            
          </td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
          ></td>

          <td className={showTable ? "top_article active_tr" : "top_article"}>
            <Avatar.Group
              maxCount={2}
              size="large"
              maxStyle={{ color: "white", backgroundColor: "#7a9ec5" }}
            >
              {product?.collaborators?.map((collab: any) => (
                <Tooltip title={collab.name} key={collab.id} placement="bottom">
                  {collab.logo ? (
                    <Avatar
                      style={{
                        boxShadow: "1px 0px 5px rgba(0,0,0,0.4)",
                      }}
                      src={process.env.REACT_APP_STORAGE_URL + collab.logo}
                    />
                  ) : (
                    <Avatar
                      style={{
                        backgroundColor: "#ddd",
                        boxShadow: "1px 0px 5px rgba(0,0,0,0.2)",
                      }}
                      icon={<UserOutlined style={{ verticalAlign: "2px" }} />}
                    />
                  )}
                </Tooltip>
              ))}
            </Avatar.Group>
          </td>
        </tr>
      )}
      {showTable && (
        <tr>
          <td
            colSpan={10}
            style={{
              backgroundColor: "#FCFDFF",
              padding: "0.2rem",
              paddingLeft: "4%",
            }}
          >
{/* 
            <table className="tableArticle">
              {product.products?.map((elm: any) => (
                <>
                  <tr>
                    <td
                      style={{
                        //   width: "50%",

                        borderTop: "none",
                      }}
                      className="details_td_cancelled"
                    >
                      {elm.name}
                    </td>

                    <td
                      style={{
                        //   width: "5%",
                        paddingLeft: "0px",
                        borderTop: "none",
                      }}
                    ></td>
                    <td
                      style={{
                        //    width: "21%",
                        paddingLeft: "0px",
                        borderTop: "none",
                      }}
                      className="details_td_status"
                    >
                      {moment(elm.updated_at).format("DD/MM/YYYY")}
                    </td>
                    <td
                      style={{
                        //  width: "8%",
                        paddingLeft: "0px",
                        borderTop: "none",
                      }}
                      className="details_td_action"
                    >
                      <Tag
                        color="processing"
                        style={{
                          borderRadius: "50px",
                          cursor: "pointer",
                          fontWeight: "normal",
                        }}
                      >
                        {elm.status.name}
                      </Tag>
                    </td>
                    <td
                      style={{
                        // width: "22%",
                        paddingLeft: "0px",
                        borderTop: "none",
                      }}
                    >
                      <div>
                       
                        <Button
                          onClick={() => showModalRestore(product.id, elm.id)}
                          icon={
                            <InboxOutlined
                              style={{
                                color: "#00468c",
                                verticalAlign: "1px",
                              }}
                            />
                          }
                        >
                          Restore
                        </Button>
                      </div>
                    </td>
                  </tr>

                 
                </>
              ))}
            </table> */}
             {product.products?.map((elm: any) => (
                                <>
                                 
                                    <div className="row row_product_name_cancelled">
                                      <div className="product_name_cancelled_demco_order">
                                      {elm.name}
                                      </div>
                                      <div className="deleted_date_cancelled_demco_order">
                                        {" "}
                                        {moment(elm.updated_at).format("DD/MM/YYYY")}
                                      </div>

                                      <div className="status_cancelled_demco_order">
                                      <Tag
                                          color="#182844"
                                          style={{
                                            borderRadius: "50px",
                                            cursor: "pointer",
                                            fontWeight: "normal",
                                          }}
                                        >
                                              {elm.status.name}
                                        </Tag>
                                      </div>

                                      <div >
                                     

                                          <Button
                                           onClick={() => showModalRestore(product.id, elm.id)}
                                           className="restoreBtn"
                                          >
                                            Restore
                                          </Button>
                                      </div>
                                    </div>
                                  
                                </>
                              ))}
          </td>
        </tr>
      )}
       <Modal
                    title="Restore Order"
                    visible={isModalRestore}
                    onCancel={handleCancel}
                    footer={[
                      <Button
                      className="restore_2"
                      // loading={loading}
                      onClick={restore}
                    >
                      Start again from PROTO
                    </Button>,
                      <Button
                        className="restore"
                        //  loading={loading}
                        onClick={restoreProdLeft}
                      >
                        Where I left off please!
                      </Button>,
                     
                    ]}
                  >
                    <p className={"text_par_model"}>In what stage would you like to restore your order? </p>
                  </Modal>
    </>
  );
}
