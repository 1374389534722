import React from "react";

export type IFileIconProps = {};

const FileIcon: React.FC<IFileIconProps> = ({}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M31.6406 8.35938H8.35938V31.6406H31.6406V8.35938ZM31.6406 5C33.5156 5 35 6.48438 35 8.35938V31.6406C35 33.5156 33.5156 35 31.6406 35H8.35938C6.48438 35 5 33.5156 5 31.6406V8.35938C5 6.48438 6.48438 5 8.35938 5H31.6406ZM23.5938 19.7656L30 28.3594H10L15 21.875L18.5938 26.25L23.5938 19.7656Z"
        fill="#182844"
      />
    </svg>
  );
};

export { FileIcon };
