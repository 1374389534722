import React, { useCallback, useEffect } from "react";
import "./product-3d-config-tabs.scss";
import "../AddFolder/AddFolder.scss";
import folder from "../../img/folder.png";

import { motion } from "framer-motion";

import { Checkbox, DatePicker, Drawer, Input, Row, Spin, Tabs } from "antd";
import "antd/dist/antd.css";
import { CustomTab } from "./custom-tab/CustomTab";
import { PantsIcon } from "./icons/PantsIcon";
import { FabricIcon } from "./icons/FabricIcon";
import { ColorsIcon } from "./icons/ColorsIcon";
import { LazerIcon } from "./icons/LazerIcon";
import { DamageIcon } from "./icons/DamageIcon";
import { CustomDesignIcon } from "./icons/CustomDesignIcon";
import { StylingForm } from "./forms/StylingForm";
import { FabricForm } from "./forms/FabricForm";
import { ColorsForm } from "./forms/ColorsForm";
import { LazerForm } from "./forms/LazerForm";
import { DamageForm } from "./forms/DamageForm";
import { MyOwnDesignForm } from "./forms/MyOwnDesignForm";
import { OrderButton } from "./forms/buttons/OrderButton";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {
  createCustomizedProduct,
  getCollaborators,
  orderCustomizedProduct,
} from "../../actions/order-actions/actions";
import order_sent from "../../img/order_sent.jpg";
import { history } from "../../index";
import "./forms/buttons/order-button-h.scss";
import BottomDrawer from "../BottomDrawer/BottomDrawer";
import { handleBottomDrawer } from "../../actions/actionCreators";
import OrderServices from "../../actions/order-actions/services";
import {
  CREATE_CUSTOMIZED_PRODUCT_FAIL,
  CREATE_CUSTOMIZED_PRODUCT_REQUEST,
  CREATE_CUSTOMIZED_PRODUCT_SUCCESS,
} from "../../actions/order-actions/types";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { FolderOpenFilled, LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import Folder from "../Folder/Folder";
import {
  createFolder,
  getAllFolders,
} from "../../actions/folders-actions/actions";
import { Button } from "../Button/Button";
import { removeAlert, setAlert } from "../../actions/alerts-actions/actions";
import { v4 as uuidv4 } from "uuid";
import Bag from "../../icons/Bag";
import { Spinner } from "../Spinner";
import moment from "moment";
import MyCustomSelect from "react-select";
import axiosInstance from "../../config/axios-instance";
import folder_content from "../../img/folder_content_2.png";
import TagsInput from "react-tagsinput";
import customizeIcon from "../../img/customize_icon.svg";
import { BrushIcon } from "./forms/canvas/icon/BrushIcon";
import { BrushIconWhite } from "../../icons/BrushIconWhite";
import PlusIcon from "../../icons/PlusIcon";
import Select from "react-select";

const { TabPane } = Tabs;

export type IProduct3dConfigTabsProps = {
  selectedProduct: any;
  // originalProduct: any;
};

const Product3dConfigTabs: React.FC<IProduct3dConfigTabsProps> = ({
  selectedProduct,
  // originalProduct,
}) => {
  const params = useParams<any>();
  console.log(params);
  const [activeTabKey, setActiveTabKey] = React.useState("1");
  const [notApproveIsVisible, setNotApproveIsVisible] = React.useState(false);
  const [addToCollection, setAddToCollection] = React.useState(false);
  const [orderNow, setOrderNow] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const { user, isAdmin } = useSelector((state: RootStateOrAny) => state.auth);

  const [createCollection, setCreateCollection] = React.useState(false);

  const [selectedFolderId, setSelectedFolderId] = React.useState<any>(null);

  const [productsToOrder, setProductsToOrder] = React.useState<any>([]);

  const [disable, setDisable] = React.useState(false);
  const [collection_name, setOrderName] = React.useState("");
  const [orderDetails, setOrderDetails] = React.useState("");
  const [collaborator, setCollaborator] = React.useState([]);
  const [isRequired, setIsRequired] = React.useState(false);
  const [date_delivery, setDeliveryDate] = React.useState("");
  const [startCustom, setStartCustom] = React.useState(false);

  const [saison, setSaison] = React.useState("");

  const { collaborators, newOrder, orders, createOrderLoading, selectedOrder } =
    useSelector((state: any) => state.orders);

  useEffect(() => {
    dispatch(getCollaborators(""));
  }, []);

  const handleCollabChange = (e: any) => {
    let arryColl: any = [];
    e &&
      e.map((el: any) => {
        arryColl.push(el.value);
      });
    setCollaborator(arryColl);
  };

  function handleChangeSeason(e: any) {
    setSaison(e.target.value);
  }

  function handleChangeOrderName(e: any) {
    setOrderName(e.target.value);
  }

  const handleCheckboxChange = (folderId: any) => {
    // Update the selected folder ID
    setSelectedFolderId(folderId);
  };

  const folders = useSelector((state: any) => state.folders);

  const [folderData, setfolderData] = React.useState({
    name: "",
    description: "",
    collaborator: [],
    season: "",
    year: "",
  });

  const handleChangeName = (e: any) => {
    setfolderData({ ...folderData, [e.target.name]: e.target.value });
  };

  const handleCollabChangeFolder = (e: any) => {
    setfolderData({
      ...folderData,
      collaborator: e && e.map((el: any) => el),
    });
  };
  const createNewFolder = () => {
    console.log(folderData.name);
    if (folderData.name === "") {
      var id = uuidv4();
      console.log(folderData.name === "");
      MyNotif("Folder name is required.", "danger");
    } else {
      dispatch(createFolder({ ...folderData }));
      // dispatch(getAllFolders());
      setCreateCollection(false);
    }

    setfolderData({
      name: "",
      description: "",
      collaborator: [],
      season: "",
      year: "",
    });
  };

  useEffect(() => {
    dispatch(getAllFolders());
  }, []);

  const discard = () => {
    setfolderData({
      name: "",
      description: "",
      collaborator: [],
      season: "",
      year: "",
    });
  };

  const [width, setWidth] = React.useState(window.innerWidth);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState<any>({
    style: params.status === "edit" ? selectedProduct.style : "",
    style_details:
      params.status === "edit" ? selectedProduct.style_details : "",
    style_files: [],
    fabric: params.status === "edit" ? selectedProduct.fabric : "none",
    fabric_image: "",
    fabric_details:
      params.status === "edit" ? selectedProduct.fabric_details : "",
    fabric_files: [],
    color: params.status === "edit" ? selectedProduct.color : "",
    color_details:
      params.status === "edit" ? selectedProduct.color_details : "",
    color_files: [],
    // laser_images: "",
    laser_images_design: "",
    laser_images_drawing: "",
    laser_type_images: "",
    laser_details:
      params.status === "edit" ? selectedProduct.laser_details : "",
    damage_images: "",
    damage_details:
      params.status === "edit" ? selectedProduct.damage_details : "",
    damage_type_images: "",
    design_details:
      params.status === "edit" ? selectedProduct.design_details : "",
    design_images: "",
    design_type_images: [],
    feedback: params.status === "edit" ? selectedProduct.feedback : "",
    ordered: false,
    pantone_code_knit: "",
    name: selectedProduct && selectedProduct.name,

    tmp_laser_images_drawing_front:
      params.status === "edit"
        ? selectedProduct?.tmp_laser_images_drawing_front
          ? JSON.parse(
              JSON.parse(selectedProduct?.tmp_laser_images_drawing_front)
            )
          : []
        : [],
    tmp_laser_images_drawing_back:
      params.status === "edit"
        ? selectedProduct?.tmp_laser_images_drawing_back
          ? JSON.parse(
              JSON.parse(selectedProduct?.tmp_laser_images_drawing_back)
            )
          : []
        : [],

    tmp_laser_images_design_front:
      params.status === "edit"
        ? selectedProduct?.tmp_laser_images_design_front
          ? JSON.parse(
              JSON.parse(selectedProduct?.tmp_laser_images_design_front)
            )
          : []
        : [],
    tmp_laser_images_design_back:
      params.status === "edit"
        ? selectedProduct?.tmp_laser_images_design_back
          ? JSON.parse(
              JSON.parse(selectedProduct?.tmp_laser_images_design_back)
            )
          : []
        : [],

    tmp_design_front:
      params.status === "edit"
        ? selectedProduct?.tmp_design_front
          ? JSON.parse(JSON.parse(selectedProduct?.tmp_design_front))
          : []
        : [],
    tmp_design_back:
      params.status === "edit"
        ? selectedProduct?.tmp_design_back
          ? JSON.parse(JSON.parse(selectedProduct?.tmp_design_back))
          : []
        : [],

    tmp_damage_front:
      params.status === "edit"
        ? selectedProduct?.tmp_damage_front
          ? JSON.parse(JSON.parse(selectedProduct?.tmp_damage_front))
          : []
        : [],
    tmp_damage_back:
      params.status === "edit"
        ? selectedProduct?.tmp_damage_back
          ? JSON.parse(JSON.parse(selectedProduct?.tmp_damage_back))
          : []
        : [],
  });
  const [newDamageTypeImages, setNewDamageTypeImages] = React.useState<any>([]);

  const deletePositionFiles = (id: string, attribute: string) => {
    setData({
      ...data,
      [attribute]: data[attribute].filter((el: any) => el.id !== id),
    });
  };

  function createFile(section: any) {
    console.log(section.name);
    section?.files?.forEach(async (file: any) => {
      let response = await axios.get(
        process.env.REACT_APP_STORAGE_URL + file.file,
        { responseType: "blob" }
      );

      let metadata = {
        type: file.file.slice(-3) === "pdf" ? "application/pdf" : "image/jpeg",
      };
      const blob = new Blob([response.data]);
      let newFile = new File([blob], file.file, metadata);

      Object.defineProperties(newFile, {
        preview: {
          value: URL.createObjectURL(blob),
          writable: true,
        },
        name: {
          value: file.file,
          writable: true,
        },
        color: {
          value: file?.color,
          writable: true,
        },
      });
      setData((x: any) => {
        console.log({ sectionName: section.name, file: newFile });
        return {
          ...x,
          [section.name]: [...x[section.name], newFile],
        };
      });
    });
  }

  useEffect(() => {
    if (params.status === "edit") {
      let styleFiles = selectedProduct?.style_files
        ? JSON.parse(selectedProduct.style_files)
        : [];
      let fabricFiles = selectedProduct?.fabric_files
        ? JSON.parse(selectedProduct?.fabric_files)
        : [];
      let colorFiles = selectedProduct?.color_files
        ? JSON.parse(selectedProduct?.color_files)
        : [];
      let laserTypeFiles = selectedProduct?.laser_type_images
        ? JSON.parse(selectedProduct?.laser_type_images)
        : [];
      let damageTypeFiles = selectedProduct?.damage_type_images
        ? JSON.parse(selectedProduct?.damage_type_images)
        : [];
      let designTypeFiles = selectedProduct?.design_type_images
        ? JSON.parse(selectedProduct?.design_type_images)
        : [];

      [
        { files: styleFiles, name: "style_files" },
        { files: fabricFiles, name: "fabric_files" },
        { files: colorFiles, name: "color_files" },
        { files: Object.values(laserTypeFiles), name: "laser_type_images" },
        { files: Object.values(damageTypeFiles), name: "damage_type_images" },
        { files: Object.values(designTypeFiles), name: "design_type_images" },
      ].forEach((el: any) => {
        createFile(el);
      });
    }
  }, [params.productId, params.status]);

  const drawers = useSelector((state: any) => state.drawers);

  const dispatch = useDispatch();

  const mouseMoveFrontLazer = useCallback(
    (mousePosition: { x: number; y: number }) => {
      setData({
        ...data,
        tmp_laser_images_drawing_front: [
          ...data.tmp_laser_images_drawing_front,
          mousePosition,
        ],
      });
    },
    [data]
  );

  const mouseMoveBackLazer = useCallback(
    (mousePosition: { x: number; y: number }) => {
      setData({
        ...data,
        tmp_laser_images_drawing_back: [
          ...data.tmp_laser_images_drawing_back,
          mousePosition,
        ],
      });
    },
    [data]
  );

  const mouseClickFrontLazerDesign = useCallback(
    (mousePosition: { id: string; x: number; y: number; color: string }) => {
      setData({
        ...data,
        tmp_laser_images_design_front: [
          ...data.tmp_laser_images_design_front,
          mousePosition,
        ],
      });
    },
    [data]
  );

  const mouseClickBackLazerDesign = useCallback(
    (mousePosition: { id: string; x: number; y: number; color: string }) => {
      setData({
        ...data,
        tmp_laser_images_design_back: [
          ...data.tmp_laser_images_design_back,
          mousePosition,
        ],
      });
    },
    [data]
  );

  const mouseClickFrontDamage = useCallback(
    (mousePosition: { id: string; x: number; y: number; color: string }) => {
      setData({
        ...data,
        tmp_damage_front: [...data.tmp_damage_front, mousePosition],
      });
    },
    [data]
  );

  const mouseClickBackDamage = useCallback(
    (mousePosition: { id: string; x: number; y: number; color: string }) => {
      setData({
        ...data,
        tmp_damage_back: [...data.tmp_damage_back, mousePosition],
      });
    },
    [data]
  );

  const mouseClickFrontDesign = useCallback(
    (mousePosition: { id: string; x: number; y: number; color: string }) => {
      setData({
        ...data,
        tmp_design_front: [...data.tmp_design_front, mousePosition],
      });
    },
    [data]
  );

  const mouseClickBackDesign = useCallback(
    (mousePosition: { id: string; x: number; y: number; color: string }) => {
      setData({
        ...data,
        tmp_design_back: [...data.tmp_design_back, mousePosition],
      });
    },
    [data]
  );

  const handleImageTypes = (image: { file: any; color: string }) => {
    setData((x: any) => ({
      ...x,
      laser_type_images: [...x.laser_type_images, image],
    }));
  };

  const handleImageTypesDamage = (image: { file: any; color: string }) => {
    setData((x: any) => ({
      ...x,
      damage_type_images: [...x.damage_type_images, image],
    }));
  };

  const handleImageTypesDesign = (image: { file: any; color: string }) => {
    setData((prevState: any) => ({
      ...prevState,
      design_type_images: [...prevState.design_type_images, image],
    }));
  };

  React.useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  useEffect(() => {
    setData({
      ...data,
      selectedProduct,
      // style_files: JSON.parse(JSON.parse(selectedProduct.style_files)),
    });
  }, [params]);

  // handle input values
  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // handle type_images
  /* const handleImageTypes = (image: any, field: string) => {
    setData({ ...data, [field]: [...data[field], image] });
  }; */

  // delete type_images
  const deleteImageTypes = (color: string, field: string) => {
    setData({
      ...data,
      [field]: data[field].filter((el: any) => el.color !== color),
    });
  };
  // handle fabric image
  const handleFabricImage = (image: any, name: any) => {
    setData({ ...data, fabric_image: image, fabric: name });
  };

  // attach file
  const attachFile = (name: string, files: any) => {
    setData({ ...data, [name]: files });
  };

  // delete attached file
  const deleteFile = (name: string, file: any) => {
    setData({
      ...data,
      //@ts-ignore
      [name]: data[name].filter((el: any) => el.name !== file.name),
    });
  };

  // handle Color img
  const handleColorImg = (color: any) => {
    setData({ ...data, color: color });
  };

  const handleSceenShorts = (name: string, img: any) => {
    setData({ ...data, [name]: img });
  };
  const handleSceenShortsDamage = (name: string, img: any) => {
    setData({ ...data, [name]: img });
  };
  const handleSceenShortsTypeLazer = async (name: string, img: any) => {
    await setData({ ...data, [name]: img });
  };

  const handleSceenShortsRight = async (name: string, img: any) => {
    await setData({ ...data, [name]: img });
  };
  const handleSceenShortsLeft = async (name: string, img: any) => {
    await setData({ ...data, [name]: img });
  };
  const handleSceenShortsDesign = (name: string, img: any) => {
    setData({ ...data, [name]: img });
  };

  const openDrawerCustom = (val: any) => {
    setStartCustom(val);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (selectedFolderId != null) {
      var canvasDataBack = localStorage.getItem(
        "canvasDataBack" + selectedProduct.code
      );
      var canvasDataFront = localStorage.getItem(
        "canvasDataFront" + selectedProduct.code
      );
      let formData = new FormData();
      formData.append("folder_id", selectedFolderId);

      Object.keys(data)
        .filter((x: any) => !x.includes("files"))
        .map((el: any) => data[el] && formData.append(el, data[el]));

      /*    data.laser_images = [
      ...(data.laser_images ? data.laser_images : []),
      ...(data.laser_type_images ? data.laser_type_images : []),
    ]; */

      data.laser_images_design = [
        ...(data.laser_images_design ? data.laser_images_design : []),
        ...(data.laser_type_images
          ? data.laser_type_images.map((el: any) => el.file)
          : []),
      ];

      data.damage_images = [
        ...(data.damage_images ? data.damage_images : []),
        ...(data.damage_type_images
          ? data.damage_type_images.map((el: any) => el.file)
          : []),
      ];

      data.design_images = [
        ...(data.design_images ? data.design_images : []),
        ...(data.design_type_images
          ? data.design_type_images.map((el: any) => el.file)
          : []),
      ];

      if (data.laser_type_images.length) {
        for (let i = 0; i < data.laser_type_images.length; i++) {
          if (!data.laser_type_images[i].file) {
            continue;
          }
          formData.append(
            `laser_type_images[${i}][file]`,
            data.laser_type_images[i].file
          );
          formData.append(
            `laser_type_images[${i}][color]`,
            data.laser_type_images[i].color
          );
        }
      }

      if (data.damage_type_images.length) {
        for (let i = 0; i < data.damage_type_images.length; i++) {
          if (!data.damage_type_images[i].file) {
            continue;
          }
          formData.append(
            `damage_type_images[${i}][file]`,
            data.damage_type_images[i].file
          );
          formData.append(
            `damage_type_images[${i}][color]`,
            data.damage_type_images[i].color
          );
        }
      }

      if (data.design_type_images.length) {
        for (let i = 0; i < data.design_type_images.length; i++) {
          if (!data.design_type_images[i].file) {
            continue;
          }
          formData.append(
            `design_type_images[${i}][file]`,
            data.design_type_images[i].file
          );
          formData.append(
            `design_type_images[${i}][color]`,
            data.design_type_images[i].color
          );
        }
      }

      if (data.style_files.length) {
        for (let i = 0; i < data.style_files.length; i++) {
          formData.append(`style_files[${i}]`, data.style_files[i]);
        }
      }
      if (data.fabric_files.length) {
        for (let i = 0; i < data.fabric_files.length; i++) {
          formData.append(`fabric_files[${i}]`, data.fabric_files[i]);
        }
      }
      if (data.color_files.length) {
        for (let i = 0; i < data.color_files.length; i++) {
          formData.append(`color_files[${i}]`, data.color_files[i]);
        }
      }

      if (data.damage_images) {
        for (let i = 0; i < data.damage_images.length; i++) {
          formData.append(`damage_images[${i}]`, data.damage_images[i]);
        }
      }
      /*   if (data.laser_images) {
      for (let i = 0; i < data.laser_images.length; i++) {
        formData.append(`laser_images[${i}]`, data.laser_images[i]);
      }
    }
 */

      if (data.laser_images_design) {
        for (let i = 0; i < data.laser_images_design.length; i++) {
          formData.append(
            `laser_images_design[${i}]`,
            data.laser_images_design[i]
          );
        }
      }
      if (data.laser_images_drawing) {
        for (let i = 0; i < data.laser_images_drawing.length; i++) {
          formData.append(
            `laser_images_drawing[${i}]`,
            data.laser_images_drawing[i]
          );
        }
      }
      if (data.design_images) {
        for (let i = 0; i < data.design_images.length; i++) {
          formData.append(`design_images[${i}]`, data.design_images[i]);
        }
      }
      formData.append(
        "product_id",
        params.status === "new"
          ? selectedProduct.id
          : selectedProduct.product_id
      );
      // create a customized product and order it
      // dispatch(createCustomizedProduct(formData, true));
      // dispatch({
      // formData.append("product_id", selectedProduct.id);
      formData.append(
        "tmp_laser_images_drawing_front",
        JSON.stringify(data.tmp_laser_images_drawing_front)
      );
      formData.append(
        "tmp_laser_images_drawing_back",
        JSON.stringify(data.tmp_laser_images_drawing_back)
      );

      formData.append(
        "tmp_damage_front",
        JSON.stringify(data.tmp_damage_front)
      );
      formData.append("tmp_damage_back", JSON.stringify(data.tmp_damage_back));

      formData.append(
        "tmp_design_front",
        JSON.stringify(data.tmp_design_front)
      );
      formData.append("tmp_design_back", JSON.stringify(data.tmp_design_back));

      formData.append(
        "tmp_laser_images_design_front",
        JSON.stringify(data.tmp_laser_images_design_front)
      );
      formData.append(
        "tmp_laser_images_design_back",
        JSON.stringify(data.tmp_laser_images_design_back)
      );
      formData.append("tmp_canvas_front", JSON.stringify(canvasDataFront));
      formData.append("tmp_canvas_back", JSON.stringify(canvasDataBack));
      // create a customized product and order it
      //  dispatch(createCustomizedProduct(formData, true));

      dispatch({
        type: CREATE_CUSTOMIZED_PRODUCT_REQUEST,
      });
      setIsLoading(true);
      try {
        const response = await OrderServices.createCustomizedProduct(
          formData,
          params.status === "edit",
          params.status === "edit" ? selectedProduct.id : null
        );
        dispatch({
          type: CREATE_CUSTOMIZED_PRODUCT_SUCCESS,
          payload: response.data,
        });
        // await dispatch(orderCustomizedProduct(response.data.id));
        setIsLoading(false);
        setNotApproveIsVisible(true);
        setAddToCollection(false);
        setTimeout(() => {
          setNotApproveIsVisible(false);
        }, 2000);

        /*  var newUrl =
        window.location.origin + "/myCollection?section=mycustomized";
      window.location.href = newUrl; */
      } catch (error: any) {
        // await dispatch({
        //   type: CREATE_CUSTOMIZED_PRODUCT_FAIL,
        //   payload: error.response,
        // });
        setIsLoading(false);
        MyNotif("Make at least one modification", "danger");
      }
    } else {
      MyNotif("Please select the collection", "danger");
    }
  };

  const orderNowProduct = async (e: any) => {
    e.preventDefault();

    console.log(data);

    if (date_delivery != "" && collection_name != "") {
      setDisable(true);

      let token = localStorage.getItem("token");
      console.log(disable);

      var canvasDataBack = localStorage.getItem(
        "canvasDataBack" + selectedProduct.code
      );
      var canvasDataFront = localStorage.getItem(
        "canvasDataFront" + selectedProduct.code
      );
      let formData = new FormData();

      Object.keys(data)
        .filter((x: any) => !x.includes("files"))
        .map((el: any) => data[el] && formData.append(el, data[el]));

      /*    data.laser_images = [
        ...(data.laser_images ? data.laser_images : []),
        ...(data.laser_type_images ? data.laser_type_images : []),
      ]; */

      data.laser_images_design = [
        ...(data.laser_images_design ? data.laser_images_design : []),
        ...(data.laser_type_images
          ? data.laser_type_images.map((el: any) => el.file)
          : []),
      ];

      data.damage_images = [
        ...(data.damage_images ? data.damage_images : []),
        ...(data.damage_type_images
          ? data.damage_type_images.map((el: any) => el.file)
          : []),
      ];

      data.design_images = [
        ...(data.design_images ? data.design_images : []),
        ...(data.design_type_images
          ? data.design_type_images.map((el: any) => el.file)
          : []),
      ];

      if (data.laser_type_images.length) {
        for (let i = 0; i < data.laser_type_images.length; i++) {
          if (!data.laser_type_images[i].file) {
            continue;
          }
          formData.append(
            `laser_type_images[${i}][file]`,
            data.laser_type_images[i].file
          );
          formData.append(
            `laser_type_images[${i}][color]`,
            data.laser_type_images[i].color
          );
        }
      }

      if (data.damage_type_images.length) {
        for (let i = 0; i < data.damage_type_images.length; i++) {
          if (!data.damage_type_images[i].file) {
            continue;
          }
          formData.append(
            `damage_type_images[${i}][file]`,
            data.damage_type_images[i].file
          );
          formData.append(
            `damage_type_images[${i}][color]`,
            data.damage_type_images[i].color
          );
        }
      }

      if (data.design_type_images.length) {
        for (let i = 0; i < data.design_type_images.length; i++) {
          if (!data.design_type_images[i].file) {
            continue;
          }
          formData.append(
            `design_type_images[${i}][file]`,
            data.design_type_images[i].file
          );
          formData.append(
            `design_type_images[${i}][color]`,
            data.design_type_images[i].color
          );
        }
      }

      if (data.style_files.length) {
        for (let i = 0; i < data.style_files.length; i++) {
          formData.append(`style_files[${i}]`, data.style_files[i]);
        }
      }
      if (data.fabric_files.length) {
        for (let i = 0; i < data.fabric_files.length; i++) {
          formData.append(`fabric_files[${i}]`, data.fabric_files[i]);
        }
      }
      if (data.color_files.length) {
        for (let i = 0; i < data.color_files.length; i++) {
          formData.append(`color_files[${i}]`, data.color_files[i]);
        }
      }

      if (data.damage_images) {
        for (let i = 0; i < data.damage_images.length; i++) {
          formData.append(`damage_images[${i}]`, data.damage_images[i]);
        }
      }
      /*   if (data.laser_images) {
        for (let i = 0; i < data.laser_images.length; i++) {
          formData.append(`laser_images[${i}]`, data.laser_images[i]);
        }
      }
   */

      if (data.laser_images_design) {
        for (let i = 0; i < data.laser_images_design.length; i++) {
          formData.append(
            `laser_images_design[${i}]`,
            data.laser_images_design[i]
          );
        }
      }
      if (data.laser_images_drawing) {
        for (let i = 0; i < data.laser_images_drawing.length; i++) {
          formData.append(
            `laser_images_drawing[${i}]`,
            data.laser_images_drawing[i]
          );
        }
      }
      if (data.design_images) {
        for (let i = 0; i < data.design_images.length; i++) {
          formData.append(`design_images[${i}]`, data.design_images[i]);
        }
      }
      formData.append(
        "product_id",
        params.status === "new"
          ? selectedProduct.id
          : selectedProduct.product_id
      );
      // create a customized product and order it
      // dispatch(createCustomizedProduct(formData, true));
      // dispatch({
      // formData.append("product_id", selectedProduct.id);
      formData.append(
        "tmp_laser_images_drawing_front",
        JSON.stringify(data.tmp_laser_images_drawing_front)
      );
      formData.append(
        "tmp_laser_images_drawing_back",
        JSON.stringify(data.tmp_laser_images_drawing_back)
      );

      formData.append(
        "tmp_damage_front",
        JSON.stringify(data.tmp_damage_front)
      );
      formData.append("tmp_damage_back", JSON.stringify(data.tmp_damage_back));

      formData.append(
        "tmp_design_front",
        JSON.stringify(data.tmp_design_front)
      );
      formData.append("tmp_design_back", JSON.stringify(data.tmp_design_back));

      formData.append(
        "tmp_laser_images_design_front",
        JSON.stringify(data.tmp_laser_images_design_front)
      );
      formData.append(
        "tmp_laser_images_design_back",
        JSON.stringify(data.tmp_laser_images_design_back)
      );
      formData.append("tmp_canvas_front", JSON.stringify(canvasDataFront));
      formData.append("tmp_canvas_back", JSON.stringify(canvasDataBack));
      // create a customized product and order it
      //  dispatch(createCustomizedProduct(formData, true));

      dispatch({
        type: CREATE_CUSTOMIZED_PRODUCT_REQUEST,
      });

      const response = await OrderServices.createCustomizedProduct(
        formData,
        params.status === "edit",
        params.status === "edit" ? selectedProduct.id : null
      );

      axiosInstance({
        method: "post",
        url: `/customized_products/order`,
        data: {
          customized_product_ids: [response.data],

          collection_name,
          date_delivery,
          collaborator,
          saison,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        window.location.href = `/my-orders/customized-orders/in-progress`;
      });

      setDisable(false);
    } else {
      setIsRequired(true);
    }
  };

  const currentYear = new Date().getFullYear();

  const options_year = [
    { value: String(currentYear).slice(2), label: String(currentYear) },

    { value: String(currentYear + 1).slice(2), label: String(currentYear + 1) },
    { value: String(currentYear + 2).slice(2), label: String(currentYear + 2) },
    { value: String(currentYear + 3).slice(2), label: String(currentYear + 3) },
    { value: String(currentYear + 4).slice(2), label: String(currentYear + 4) },
  ];

  const options_season = [
    { value: "ps", label: "PS" },
    { value: "ss", label: "SS" },
    { value: "pf", label: "PF" },
    { value: "fw", label: "FW" },
  ];

  const handleSeasonChange = (e: any) => {
    setfolderData({
      ...folderData,
      season: e.value,
    });
  };
  const handleYearChange = (e: any) => {
    setfolderData({
      ...folderData,
      year: e.value,
    });
  };
  return (
    <div className="product-3d-config-tabs">
      <div className="row top-header ml-2 mb-5 justify-content-between">
        {/* <div className="product-name">{selectedProduct.name}</div> */}
        <div className={"col-6"}>
          <h4 className="product-code h4_medium mb-0 mt-2">
            {params.status === "new"
              ? selectedProduct?.code
              : selectedProduct?.original_product?.code}
          </h4>
          <h4 className="product-name" style={{ fontWeight: 400 }}>
            {params.status === "new"
              ? selectedProduct?.name
              : selectedProduct?.original_product?.name}
          </h4>
        </div>

        <div
          className={"col-6 mb-0 "}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <button
            className="order-btn-h-outlined-add-to-collection-icon "
            onClick={(e: any) => {
              e.preventDefault();
              setAddToCollection(true);
            }}
            /*
              style={{width: "100%"}}
*/
          >
            {/*Add to collection*/}
            <FolderOpenFilled style={{ fontSize: "24px" }}></FolderOpenFilled>
          </button>
        </div>
      </div>
      <form>
        <div>
          <Row className="p-2">
            <StylingForm
              {...{ setActiveTabKey }}
              handleChange={handleChange}
              attachFile={attachFile}
              deleteFile={deleteFile}
              globalData={data}
              selectedProduct={selectedProduct}
            />

            <FabricForm
              {...{ setActiveTabKey }}
              handleChange={handleChange}
              handleFabricImage={handleFabricImage}
              attachFile={attachFile}
              deleteFile={deleteFile}
              globalData={data}
            />
          </Row>
          <div className="pl-4 pr-4">
            <LazerForm
              handleSceenShorts={handleSceenShorts}
              handleSceenShortsTypeLazer={handleSceenShortsTypeLazer}
              handleSceenShortsRight={handleSceenShortsRight}
              handleSceenShortsLeft={handleSceenShortsLeft}
              handleChange={handleChange}
              handleImageTypes={handleImageTypes}
              deleteImageTypes={deleteImageTypes}
              globalData={data}
              mouseMoveFrontLazer={mouseMoveFrontLazer}
              mouseMoveBackLazer={mouseMoveBackLazer}
              mouseClickFrontLazerDesign={mouseClickFrontLazerDesign}
              mouseClickBackLazerDesign={mouseClickBackLazerDesign}
              deletePositionFiles={(id: string, attribute: string) =>
                deletePositionFiles(id, attribute)
              }
              startCustom={startCustom}
              openDrawerCustom={openDrawerCustom}
            />
          </div>
        </div>
        {/* <Tabs
            tabPosition={"left"}
            activeKey={activeTabKey}
            onChange={(key) => setActiveTabKey(key)}
          >
            <TabPane
              tab={
                <CustomTab
                  name={width <= 575 ? "" : "Style"}
                  icon={<PantsIcon />}
                />
              }
              key="1"
            >
              <StylingForm
                {...{ setActiveTabKey }}
                handleChange={handleChange}
                attachFile={attachFile}
                deleteFile={deleteFile}
                globalData={data}
                selectedProduct={selectedProduct}
              />
            </TabPane>
            <TabPane
              tab={
                <CustomTab
                  name={width <= 575 ? "" : "FABRIC"}
                  icon={<FabricIcon />}
                />
              }
              key="2"
            >
              <FabricForm
                {...{ setActiveTabKey }}
                handleChange={handleChange}
                handleFabricImage={handleFabricImage}
                attachFile={attachFile}
                deleteFile={deleteFile}
                globalData={data}
              />
            </TabPane>
                   <TabPane
              tab={
                <CustomTab
                  name={
                    params.status === "edit"
                      ? selectedProduct?.original_product?.fabric_type ===
                        "knit"
                        ? "COLOR"
                        : selectedProduct?.original_product?.fabric_type ===
                            "denim" && "BASE COLOR"
                      : selectedProduct?.fabric_type === "knit"
                      ? "COLOR"
                      : "BASE COLOR"
                  }
                  icon={<ColorsIcon />}
                />
              }
              key="3"
            >
              <ColorsForm
                {...{ setActiveTabKey }}
                handleChange={handleChange}
                handleColorImg={handleColorImg}
                attachFile={attachFile}
                deleteFile={deleteFile}
                globalData={data}
                isDenim={
                  params.status === "edit"
                    ? selectedProduct?.original_product?.fabric_type === "denim"
                    : selectedProduct?.fabric_type === "denim"
                }
              />
            </TabPane> 

            <TabPane
              tab={
                <CustomTab
                  name={width <= 575 ? "" : "LASER INTENSITY"}
                  icon={<LazerIcon />}
                />
              }
              key="4"
            >
              <LazerForm
                handleSceenShorts={handleSceenShorts}
                handleSceenShortsTypeLazer={handleSceenShortsTypeLazer}
                handleChange={handleChange}
                handleImageTypes={handleImageTypes}
                deleteImageTypes={deleteImageTypes}
                globalData={data}
                mouseMoveFrontLazer={mouseMoveFrontLazer}
                mouseMoveBackLazer={mouseMoveBackLazer}
                mouseClickFrontLazerDesign={mouseClickFrontLazerDesign}
                mouseClickBackLazerDesign={mouseClickBackLazerDesign}
                deletePositionFiles={(id: string, attribute: string) =>
                  deletePositionFiles(id, attribute)
                }
              />
            </TabPane>
               <TabPane
              tab={
                <CustomTab
                  name={
                    params.status === "edit"
                      ? selectedProduct?.original_product?.fabric_type ===
                        "knit"
                        ? "Graphic "
                        : selectedProduct?.original_product?.fabric_type ===
                            "denim" && "DAMAGES"
                      : selectedProduct?.fabric_type === "knit"
                      ? "Graphic"
                      : "DAMAGES"
                  }
                  icon={<DamageIcon />}
                />
              }
              key="5"
            >
              <DamageForm
                handleSceenShortsDamage={handleSceenShortsDamage}
                handleChange={handleChange}
                handleImageTypes={handleImageTypesDamage}
                deleteImageTypes={deleteImageTypes}
                globalData={data}
                mouseClickFrontDamage={mouseClickFrontDamage}
                mouseClickBackDamage={mouseClickBackDamage}
                initialDesign={{
                  images: data.damage_type_images || [],
                  front: data.tmp_damage_front || [],
                  back: data.tmp_damage_back || [],
                }}
                deletePositionFiles={(id: string, attribute: string) =>
                  deletePositionFiles(id, attribute)
                }
              />
            </TabPane> 
          {selectedProduct?.fabric_type !== "knit" && (
              <TabPane
                tab={
                  <CustomTab
                    name={width <= 575 ? "" : `ADD YOUR \r\n OWN DESIGN`}
                    icon={<CustomDesignIcon />}
                  />
                }
                key="6"
              >
                <MyOwnDesignForm
                  handleSceenShortsDesign={handleSceenShortsDesign}
                  handleChange={handleChange}
                  handleImageTypes={handleImageTypesDesign}
                  deleteImageTypes={deleteImageTypes}
                  globalData={data}
                  mouseClickFrontDesign={mouseClickFrontDesign}
                  mouseClickBackDesign={mouseClickBackDesign}
                  initialDesign={{
                    images: data?.design_type_images || [],
                    front: data?.tmp_design_front || [],
                    back: data?.tmp_design_back || [],
                  }}
                  deletePositionFiles={(id: string, attribute: string) =>
                    deletePositionFiles(id, attribute)
                  }
                />
              </TabPane>
            )}
          </Tabs> */}

        <div className="btn-configurator-holder-custom-product ">
          {!user.is_client_clients ? (
            <>
              <div className={"col-6 justify-content-end"}>
                <button
                  className=" order-btn-h-outlined-custom"
                  style={{ fontSize: "1rem", width: "100%" }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    setOrderNow(true);
                  }}
                >
                  Order product
                </button>
              </div>
              <div className={"col-6 "}>
                <button
                  type="button"
                  className="order-btn-h-custom-prodcut "
                  onClick={() => {
                    if (width >= 992) setStartCustom(true);
                    else
                      MyNotif(
                        "This feature is only available on desktop site",
                        "danger"
                      );
                  }}
                  style={{ fontSize: "1rem", width: "100%" }}
                >
                  <span className={"button-text "}>
                    <img src={customizeIcon} alt="" /> Customize your product!
                  </span>
                  <span className="button-icon">
                    <img src={customizeIcon} alt="" />
                  </span>
                </button>
              </div>
            </>
          ) : (
            <>
              <div className={"col-12 "}>
                <button
                  type="button"
                  className="order-btn-h-custom-prodcut "
                  onClick={() => {
                    if (width >= 992) setStartCustom(true);
                    else
                      MyNotif(
                        "This feature is only available on desktop site",
                        "danger"
                      );
                  }}
                  style={{ fontSize: "1rem", width: "100%" }}
                >
                  <span className={"button-text "}>
                    <img src={customizeIcon} alt="" /> Customize your product!
                  </span>
                  <span className="button-icon">
                    <img src={customizeIcon} alt="" />
                  </span>
                </button>
              </div>
            </>
          )}
          {/*   <button
            className="order-btn-h"
            style={{ fontSize: "1rem" }}
         
            onClick={handleSubmit}
            
          >
            {isLoading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    spin
                  />
                }
              />
            ) : (
              "Order product"
            )}
          </button>  */}
          {/* <button
            onClick={handleSubmit}
            className="order-btn-h"
            style={{ fontSize: "1rem" }}
          >
            {isLoading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    spin
                  />
                }
              />
            ) : (
              "Order Now"
            )}
          </button> */}
        </div>
      </form>
      <Drawer
        className="order_sent_drawer"
        placement={"bottom"}
        closable={true}
        onClose={() => setNotApproveIsVisible(false)}
        visible={notApproveIsVisible}
        key={"bottom"}
      >
        <div className="my-2">
          <h1>Saved!</h1>
          <p>Your customized product have been saved in your collection</p>
        </div>
        <img src={order_sent} alt="order sent" className="img-fluid my-2" />
      </Drawer>

      <Drawer
        className="order_sent_drawer_customized"
        placement={"bottom"}
        closable={false}
        /*         onClose={() => setAddToCollection(false)}
         */ visible={addToCollection}
        key={"bottom"}
      >
        <div className="my-2 head">
          <span className={"add-to-collection-title"}>Add to collection !</span>
          <p className={"add-to-collection-description"}>
            Select the collection where you want to move your products
          </p>
        </div>
        <div className="p-5">
          <div className="row justify-content-end">
            <button
              onClick={() => setCreateCollection(true)}
              className="order-btn-h-add-new-collection"
            >
              <span className="button-text">Create New Collection</span>
              <span className="button-icon">
                <PlusIcon></PlusIcon>
              </span>
            </button>
          </div>
          {folders?.folders?.length > 0 ? (
            <div className="row scrol_order">
              {folders.folders ? (
                folders.folders
                  .filter((el: any) => el.client_id == user.id)
                  .map((folder: any, index: number) => (
                    <>
                      <div className="row">
                        <div className="col-md-4 col-sm-3 col-auto col-lg-4 col-xl-5">
                          <div
                            className={"one_folder one_folder_selected"}
                            key={index}
                          >
                            {folder.products.length > 0 ? (
                              <div className="list_folder_elements">
                                {folder.products.map(
                                  (product: any, i: number) =>
                                    i < 7 ? (
                                      <div
                                        key={i}
                                        className="product_image_container"
                                      >
                                        {product.image ? (
                                          <img
                                            src={
                                              process.env
                                                .REACT_APP_STORAGE_URL +
                                              product.image.path
                                            }
                                            alt=""
                                            className="img_product_folder"
                                          />
                                        ) : (
                                          <img
                                            src={"placeholder"}
                                            alt=""
                                            className="img_product_folder"
                                          />
                                        )}
                                      </div>
                                    ) : null
                                )}

                                {folder.products.length > 7 && (
                                  <div className=" product_image_container product_image_container_plus">
                                    <p className="paragraphe products_plus_folder_value ">
                                      +
                                    </p>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="list_folder_elements empty_folder">
                                <p className="paragraphe">Empty Folder</p>
                              </div>
                            )}

                            <div className="folder_footer">
                              <div
                                style={{
                                  borderRight: "1px solid #ccc",
                                  paddingRight: "10px",
                                  marginRight: "8px",
                                }}
                              >
                                <Checkbox
                                  onChange={() =>
                                    handleCheckboxChange(folder.id)
                                  }
                                  checked={folder.id === selectedFolderId}
                                ></Checkbox>
                              </div>

                              <p className="folder_name">{folder.name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner />
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="row  justify-content-center">
                <div style={{ textAlign: "center" }}>
                  <img
                    src={folder_content}
                    alt="folders image"
                    className="mb-5"
                  />

                  <div className="paragraphe collection_welcome_description">
                    You don't have any collections yet. Please create your
                    collection. Thank you!
                  </div>
                </div>
              </div>
            </>
          )}
          {width > 916 && (
            <>
              <div className="d-flex align-items-center justify-content-center footer-add-to-collection">
                <button
                  //   innerButton="Cancel"
                  onClick={() => {
                    setAddToCollection(false);
                    setSelectedFolderId(null);
                  }}
                  className="submit_button_white_discard_collection "
                >
                  Discard
                </button>
                {folders?.folders?.length > 0 && (
                  <button
                    onClick={handleSubmit}
                    className="submit_button_add_to_collection"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 24, color: "#03468c" }}
                            spin
                          />
                        }
                      />
                    ) : (
                      "Add to collection"
                    )}
                  </button>
                )}
              </div>
            </>
          )}
        </div>

        {width < 915 && (
          <>
            <div className={"row footer-add-to-collection_mobile"}>
              <div className={"col-6"}>
                <button
                  //   innerButton="Cancel"
                  onClick={() => {
                    setAddToCollection(false);
                    setSelectedFolderId(null);
                  }}
                  style={{ width: "100%" }}
                  className="submit_button_white_discard_collection_mobile "
                >
                  Discard
                </button>
              </div>
              <div className={"col-6"} style={{ backgroundColor: "#182844" }}>
                {folders?.folders?.length > 0 && (
                  <button
                    onClick={handleSubmit}
                    className="submit_button_add_to_collection_mobile"
                    disabled={isLoading}
                    style={{ width: "100%" }}
                  >
                    {isLoading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 24, color: "#03468c" }}
                            spin
                          />
                        }
                      />
                    ) : (
                      "Add to collection"
                    )}
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </Drawer>

      <Drawer
        className="order_sent_drawer_customized"
        placement={"bottom"}
        closable={false}
        onClose={() => setCreateCollection(false)}
        visible={createCollection}
        key={"bottom"}
      >
        <motion.div
          className={"add_folder_container"}
          style={{
            height: "calc(100vh - 109px)",
            justifyContent: "space-between",
          }}
          transition={{ type: "tween" }}
          // variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
        >
          <div className="add_folder_top">
            {/* <Bag /> */}
            <img src={folder} alt="folder image" className="folder_img" />
            <br />

            <span className={"add-to-collection-title"}>
              Add New Collection
            </span>
          </div>
          <div className="container">
            <div style={{ width: "100%" }}>
              <div className="row my-2">
                <div className="col-md-12">
                  <div className="field">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder=" "
                      onChange={handleChangeName}
                    />
                    <label htmlFor="name" className="folder_label">
                      Collection Name
                    </label>
                  </div>
                  <div className="row ">
                    <div className={"col-md-6"}>
                      <div className="field ">
                        <Select
                          name="seasons"
                          placeholder="Select season"
                          options={options_season}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={handleSeasonChange}
                        />
                      </div>
                    </div>

                    <div className={"col-md-6"}>
                      <div className="field ">
                        <Select
                          name="years"
                          placeholder="Select year"
                          options={options_year}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={handleYearChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/*   <div className={
                  "col-md-6"
                }>
                  <div className="field ">


                    <input
                        type="text"
                        name="season"
                        id="season"
                        placeholder=" "
                        onChange={handleChange}
                    />
                    <label htmlFor="season" className="folder_label">
                      Season
                    </label>
                  </div>
                </div>*/}
              </div>

              <div className="field tags_field p-2 collaborator-add-to-collection">
                <Select
                  isMulti
                  name="colors"
                  placeholder="Select your collaborators"
                  options={
                    collaborators &&
                    collaborators.map((el: any) => {
                      return {
                        label: el.name,
                        value: el.id,
                      };
                    })
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleCollabChangeFolder}
                />
              </div>
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center "
            style={{ gap: "20px" }}
          >
            <Button
              innerButton="Discard"
              buttonClass="submit_button_white_discard_collection"
              onClick={() => {
                discard();
                setCreateCollection(false);
              }}
            />

            <Button
              innerButton="Confirm"
              buttonClass="submit_button_add_to_collection"
              onClick={() => {
                discard();
                createNewFolder();
              }}
            />
          </div>
          {/*  <div className="my-2 head">
          <h1>Create new collection !</h1>
          <div style={{ width: "100%" }} className={"add_folder_container"}>
            <div className="row">
              <div className=" col-12 col-md-6">
                <div className="field">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder=" "
                    onChange={handleChangeName}
                  />
                  <label htmlFor="name" className="folder_label">
                    Folder Name
                  </label>
                </div>
              </div>

              <div className=" col-12 col-md-6">
                <div>
                  <label htmlFor="a" className="folder_description_label">
                    Folder Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    placeholder=""
                    onChange={handleChangeName}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ gap: "20px" }}
          >
            <Button
              innerButton="Discard"
              buttonClass="submit_button_white submit_button_white_discard"
              onClick={() => {
                discard();
                setCreateCollection(false);
              }}
            />

            <Button
              innerButton="Create new collection"
              buttonClass="submit_button_white submit_button_white_confirm"
              onClick={() => {
                discard();
                createNewFolder();
              }}
            />
          </div>
        </div> */}
        </motion.div>
      </Drawer>

      <Drawer
        className="order_sent_drawer_customized"
        placement={"bottom"}
        closable={true}
        onClose={() => setOrderNow(false)}
        visible={orderNow}
        key={"bottom"}
      >
        <div className="row">
          {/* left container */}

          <div
            className="col-12"
            style={{
              paddingTop: "88px",
              paddingLeft: "112px",
              paddingRight: "112px",
            }}
          >
            <div className="titledrawerConfirm">Order details</div>
            <div style={{ fontSize: "16px", marginBottom: "32px" }}>
              Complete your order by providing the details below.
            </div>
            <div className="row">
              <div className="col-6">
                <div>
                  <label className="mr-3 mb-1">Collection names</label>
                  <Input
                    type="text"
                    style={{
                      borderRadius: "5px",
                      marginBottom: "16px",
                    }}
                    onChange={handleChangeOrderName}
                  ></Input>
                  {isRequired && collection_name === "" && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
                <div>
                  <label className="mr-3 mb-1">Season</label>
                  <Input
                    type="text"
                    style={{
                      borderRadius: "5px",
                      marginBottom: "16px",
                    }}
                    defaultValue={saison}
                    onChange={handleChangeSeason}
                  ></Input>
                </div>
              </div>

              <div className="col-6">
                {" "}
                <div>
                  <label className="mr-3 mb-0">Select Collaborators</label>
                  <MyCustomSelect
                    isMulti
                    name="colors"
                    placeholder="Select your collaborators"
                    options={
                      collaborators &&
                      collaborators.map((el: any) => {
                        return {
                          label: el.name,
                          value: el.id,
                        };
                      })
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleCollabChange}
                  />
                </div>
                <div>
                  <label className="mr-3 mb-0 mt-3">Delivery date</label>
                  <DatePicker
                    disabledDate={(current) =>
                      current.isBefore(moment().add(15, "days"))
                    }
                    format={"DD/MM/YYYY"}
                    onChange={(date: any, dateString: any) =>
                      setDeliveryDate(dateString)
                    }
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      marginBottom: "16px",
                    }}
                  />

                  {isRequired && date_delivery === "" && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex " style={{ gap: "50px" }}>
                <button
                  //   innerButton="Cancel"
                  onClick={(e: any) => setOrderNow(false)}
                  style={{ marginTop: "16px" }}
                  className="submit_button_white submit_button_white_cancel"
                >
                  Discard
                </button>

                <button
                  className="submit_button_add_to_collection"
                  onClick={(e: any) => orderNowProduct(e)}
                  disabled={disable}
                  style={{ marginTop: "16px" }}
                >
                  {disable ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 24, color: "white" }}
                          spin
                        />
                      }
                    />
                  ) : (
                    <>Confirm order</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      {/* {drawers.openBottom && (
        <BottomDrawer
          mode={"add_to_collection"}
          addCustomizedProduct
          customizedOrder={{
            ...data,
            product_id:
              params.status === "new"
                ? selectedProduct.id
                : selectedProduct.product_id,
          }}
        />
      )} */}
    </div>
  );
};

export { Product3dConfigTabs };
