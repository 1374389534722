export interface IIconProps {
  className?: string;
}

function Folder({ className }: IIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 28 28"
      className="subfolder_icon"
    >
      <path
        fill="#182844"
        d="M9.75-15h-9l-2.561-2.561A1.5 1.5 0 00-2.872-18H-9.75A2.25 2.25 0 00-12-15.75v13.5A2.25 2.25 0 00-9.75 0h19.5A2.25 2.25 0 0012-2.25v-10.5A2.25 2.25 0 009.75-15zm0 12.75h-19.5v-13.5h6.568l2.561 2.561a1.5 1.5 0 001.061.439h9.31z"
        data-name="Tracé 3316"
        transform="translate(12 18)"
      ></path>
    </svg>
  );
}

export default Folder;
