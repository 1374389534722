import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilter,
  filter,
  getAllProducts,
  getProductsFiltered,
  deleteFilter,
  getProductsNextPage,
  filterAndKeepAll,
  clearAllFilters,
} from "../../actions/products-actions/actions";
import { Filter } from "../../components/Filter/Filter";
import { handleBottomDrawer } from "../../actions/actionCreators";

import { ProductCard } from "../../components/ProductCard/ProductCard";
import { Link } from "react-router-dom";
import { handleLeftDrawer } from "../../actions/actionCreators";
import {
  Drawer,
  Card,
  Tabs,
  Row,
  Collapse,
  Col,
  Select,
  Modal,
  Popover,
  Input,
  Popconfirm,
  Space,
  Divider,
  Tag,
  Spin,
} from "antd";
import { useParams, useLocation } from "react-router-dom";
import "./ForYou.scss";
import {
  FilterOutlined,
  ClearOutlined,
  EyeOutlined,
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Spinner } from "../../components/Spinner";
import backArrow from "../../img/backArrow.png";
import { history } from "../../index";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { addProducttoFolder } from "../../actions/folders-actions/actions";
import { StandardDraggableProductCard } from "../ProductCard/StandardDraggableProductCard";
import Head from "../Head";
import ExampleProductCard from "../ProductCard/ExampleProductCard";
import CardProductForYou from "../ProductCard/CardProductForYou";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import { Button } from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderPlus,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import BottomDrawer from "../BottomDrawer/BottomDrawer";
import axiosInstance from "../../config/axios-instance";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import { SettingOutlined } from "@ant-design/icons";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useQuery } from "react-query";

interface Props {}
const { Meta } = Card;

export const ForYouUser = (props: Props) => {
  const location = useLocation();
  const params: any = useParams();
  const [min_price_allowed, setMin_price_allowed] = useState(0);
  const [max_price_allowed, setMax_price_allowed] = useState(1000);
  const [min_price, setMin_price] = useState(0);
  const [max_price, setMax_price] = useState(1000);
  const [min_priceBefore, setMin_priceBefore] = useState(1);
  const [max_priceBefore, setMax_priceBefore] = useState(1000);
  const [min_elasticity, setMin_elasticity] = useState(1);
  const [max_elasticity, setMax_elasticity] = useState(1000);
  const [min_elasticity_before, setMin_elasticity_before] = useState(1);
  const [max_elasticity_before, setMax_elasticity_before] = useState(1000);
  const [min_elasticity_allowed, setMin_elasticity_allowed] = useState(1);
  const [max_elasticity_allowed, setMax_elasticity_allowed] = useState(1000);

  const [min_weight, setMin_weight] = useState(1);
  const [max_weight, setMax_weight] = useState(1000);
  const [min_weight_before, setMin_weight_before] = useState(1);
  const [max_weight_before, setMax_weight_before] = useState(1000);
  const [min_weight_allowed, setMin_weight_allowed] = useState(1);
  const [max_weight_allowed, setMax_weight_allowed] = useState(1000);

  const [count, setcount] = useState(0);
  const [highestPrice, setHighestPrice] = useState(0);
  const [queries, setQueries] = useState<Array<string>>([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [url, setUrl] = useState(`/admin/foryou/products/${params.id}?`);
  //const [moodboard, setMooadboard] = useState([]);
  const [moodboard, setMooadboard] = useState<Array<any>>([]);
  const [fabricChoice, setFabricChoice] = useState("");
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [checkMoodboard, setcheckMoodboard] = useState("");
  const [client, setClient] = useState("");

  const [forYouCompany, setForYouCompany] = useState<any>(null);

  /*   const toggleClass = () => {
    setcheckMoodboard(!checkMoodboard);
  }; */
  const dispatch = useDispatch();
  /*  const {
    products,
    productsAll,
    // isLoading,
    next_page,
    filters,
    isLoadingNext,
    prices,
    elasticity_fabric,
    weight_fabric,
  } = useSelector((state: any) => state.products); */

  const {
    products,
    productsAll,
    //   isLoading,
    next_page,
    filters,
    isLoadingNext,
    prices,
    elasticity_fabric,
    weight_fabric,
  } = useSelector((state: any) => state.products);
  const { isLoading } = useSelector((state: any) => state.foryou);
  const { user } = useSelector((state: any) => state.auth);
  const { TabPane } = Tabs;
  const { Panel } = Collapse;
  const { Option, OptGroup } = Select;
  // useEffect(() => {
  //   setUrl(
  //     `/clients/me/products?&${queries.join(
  //       "&"
  //     )}&min_price=${min_price}&max_price=${max_price}`
  //   );
  // }, [queries]);

  // useEffect(() => {
  //   setUrl(url + queries.join("&"));
  // }, []);
  const { register, control, handleSubmit, errors, setValue, getValues } =
    useForm<any>();
  useEffect(() => {
    if (count > 1) {
      dispatch(filter(url));
    }
    dispatch(addFilter(queries));
  }, [url]);

  useEffect(() => {
    console.log("parms", params.id);
    console.log(products);
    dispatch(filterAndKeepAll(`/admin/foryou/products/${params.id}`));
  }, []);

  const getForYouCompany = async (company_id: string) => {
    let token = localStorage.getItem("token");
    if (company_id) {
      await axiosInstance({
        method: "get",
        url: `/admin/forYouCompany/${company_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        setForYouCompany(response.data);
      });
      return "ok";
    }
  };

  useEffect(() => {
    const company_id = localStorage.getItem("company_id");

    if (company_id) {
      getForYouCompany(company_id);
    }
  }, []);

  useEffect(() => {
    let resize = () =>
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    console.log(weight_fabric);
    setMax_price(prices.max_price);
    setMin_price(prices.min_price);
    setMax_price_allowed(prices.max_price);
    setMin_price_allowed(prices.min_price);
    setMin_priceBefore(prices.min_price);
    setMax_priceBefore(prices.max_price);
    setMin_elasticity(elasticity_fabric?.min_elasticity_value);
    setMax_elasticity(elasticity_fabric?.max_elasticity_value);
    setMin_elasticity_before(elasticity_fabric?.min_elasticity_value);
    setMax_elasticity_before(elasticity_fabric.max_elasticity_value);
    setMax_elasticity_allowed(elasticity_fabric?.max_elasticity_value);
    setMin_elasticity_allowed(elasticity_fabric?.min_elasticity_value);

    setMin_weight(weight_fabric?.min_weight_value);
    setMax_weight(weight_fabric?.max_weight_value);
    setMin_weight_before(weight_fabric?.min_weight_value);
    setMax_weight_before(weight_fabric?.max_weight_value);
    setMax_weight_allowed(weight_fabric?.max_weight_value);
    setMin_weight_allowed(weight_fabric?.min_weight_value);
  }, [productsAll]);

  const handleChangeWeight = (val: any) => {
    let [min, max] = val;
    // setMin_elasticity(min === 0 ? "0.00" : min);
    setMin_weight(min);
    setMax_weight(max);
  };
  useEffect(() => {
    setUrl(
      `/admin/foryou/products/${params.id}?&${queries.join(
        "&"
        // )}&min_price=${min_price}&max_price=${max_price}&elasticity_min=${min_elasticity}&elasticity_max=${max_elasticity}&weight_min=${min_weight}&weight_max=${max_weight}`
      )}&min_price=${min_price}&elasticity_min=${min_elasticity}&elasticity_max=${max_elasticity}&max_price=${max_price}&weight_min=${min_weight}&weight_max=${max_weight}`
    );
    setcount(count + 1);
  }, [
    min_price,
    max_price,
    min_elasticity,
    max_elasticity,
    min_weight,
    max_weight,
    queries,
  ]);

  const fetchClients = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/clients/companies/${params.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { isLoading: loading2, data: clients } = useQuery<any>(
    "clients",
    () => fetchClients(),
    { keepPreviousData: true }
  );

  const fetchMoadboard = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/moodboards`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setMooadboard(res.data);
    });
  };

  const fetchLast4Moadboard = () => {
    const company_id = localStorage.getItem("company_id");
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/moodboards/companies/${company_id}/last`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setMooadboard(res.data);
    });
  };

  const getNextPageProducts = () => {
    dispatch(
      getProductsNextPage(
        next_page.substr(process.env.REACT_APP_URL_LENGTH) +
          "&" +
          queries.join("&")
      )
    );
  };

  var regInteger = /^-?\d+$/;
  //@ts-ignore
  const pathnames = props.location.pathname
    .split("/")
    .filter((element: any) => !regInteger.test(element))
    .map((el: string) => {
      return el.charAt(0).toUpperCase() + el.slice(1);
    })
    .filter((x: string) => x);

  const handleChangePrice = (val: any) => {
    let [min, max] = val;
    setMin_price(min);
    setMax_price(max);
  };
  const [type, setType] = useState("new");
  const [theme, setTheme] = useState("");
  const [year, setYear] = useState("");

  const [saisson, setSaisson] = useState("");

  const [division, setDivision] = useState("");
  const [file, setFile] = useState("");
  const [id, setId] = useState("");
  const [preview, setPreview] = useState("");
  const [company_name, setCompanyName] = useState("Moon");
  const [done, setDone] = useState(false);
  const [activeMoodboard, setActiveMoodboard] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const showDrawer = (el: any) => {
    console.log(el);
    setTheme(el?.theme);
    setYear(el?.year);
    setId(el.id);
    setSaisson(el?.saisson);

    setDivision(el?.division);
    setFile(el?.file_storage);
    setClient(el?.client_id);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  // const addQuery = (target: any) => {
  //   const element = target.name + "=" + target.id;
  //   if (target.checked) {
  //     setQueries([...queries, element]);
  //   } else if (!target.checked) {
  //     setQueries(queries.filter((el) => el !== element));
  //   }
  // };

  // const deleteFilterTag = (obj: any) => {
  //   setQueries(queries.filter((el) => el !== obj.filteredBy + "=" + obj.id));
  // };

  const handleChangePriceAfter = (val: any) => {
    let [min, max] = val;
    setMin_priceBefore(min);
    setMax_priceBefore(max);
  };

  const handleChangeElasticity = (val: any) => {
    let [min, max] = val;
    setMin_elasticity(min);
    setMax_elasticity(max);
  };
  const addQuery2 = (target: any) => {
    console.log(target.name);
    if (target.name == "check_moodbroad") {
    }
  };
  const addQuery = (target: any) => {
    setActiveMoodboard(null);
    setcheckMoodboard("");
    /*     setcheckMoodboard(false);
     */ if (target.name != "check_moodbroad") {
      console.log(target.name);
      const element = target.name + "=" + target.id;
      if (target.checked) {
        var exists = queries.filter((query: any) =>
          query.includes(target.name)
        );
        if (exists.length === 0) {
          setQueries([...queries, element]);
        } else {
          var newQueryVal = exists[0].concat("," + target.id);
          setQueries(
            queries.map((el: any) =>
              el.includes(target.name) ? newQueryVal : el
            )
          );
        }
      } else if (!target.checked) {
        var exist = queries.filter((query: any) =>
          query.includes(target.name)
        )[0];

        if (exist.includes(",") === false) {
          setQueries(queries.filter((el) => !el.includes(target.name)));
        } else if (
          exist.indexOf(target.id) ===
          exist.length - target.id.toString().length
        ) {
          var newQuery = exist.substr(
            0,
            exist.length - (target.id.toString().length + 1)
          );
          setQueries(
            queries.map((el: any) => (el.includes(target.name) ? newQuery : el))
          );
        } else {
          var newQuery = exist.replace(target.id + ",", "");
          setQueries(
            queries.map((el: any) => (el.includes(target.name) ? newQuery : el))
          );
        }
      }
    }
  };

  const deleteFilterTag = (obj: any) => {
    // setQueries(queries.filter((el) => el !== obj.filteredBy + "=" + obj.id));
    var exist = queries.filter((query: any) =>
      query.includes(obj.filteredBy)
    )[0];
    // if (obj.filteredBy.length === exist.length - 2) {
    if (exist.includes(",") === false) {
      setQueries(queries.filter((el) => !el.includes(obj.filteredBy)));
    } else if (
      exist.indexOf(obj.id) ===
      exist.length - obj.id.toString().length
    ) {
      var newQuery = exist.substr(
        0,
        exist.length - (obj.id.toString().length + 1)
      );
      setQueries(
        queries.map((el: any) => (el.includes(obj.filteredBy) ? newQuery : el))
      );
    } else {
      var newQuery = exist.replace(obj.id + ",", "");
      setQueries(
        queries.map((el: any) => (el.includes(obj.filteredBy) ? newQuery : el))
      );
    }
  };

  const onDragEnd = useCallback((draggable: any) => {
    return false;
  }, []);

  const onDragStart = () => {
    setIsDragging(false);
  };

  const errorBorder = (field: string) => {
    return {
      borderBottom: errors[field]?.type === "required" ? "1px 1px red" : "",
      boxShadow:
        errors[field]?.type === "required"
          ? "0 0 0 2px rgb(255,0,0, 0.4)"
          : "none",
    };
  };

  const onSubmit = async (data: any) => {
    let token = localStorage.getItem("token");

    if (type == "new") {
      setDone(true);
      let formData = new FormData();
      Object.keys(data).map((el: string) => formData.append(el, data[el]));

      const { data: response } = await axiosInstance({
        method: "post",
        url: "/moodboards/companies",
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDone(false);
      await MyNotif("Moodboard published successfully.", "success");
      fetchLast4Moadboard();
      onClose();
      /*    if (response.status == 200) {
        setDone(false);
        await MyNotif("Moodboard published successfully.", "success");
        fetchMoadboard();
        onClose();
      } */

      return response.data;
    } else {
      //  setDone(true);

      let formData2 = new FormData();
      Object.keys(data).map((el: string) => formData2.append(el, data[el]));

      const { data: response } = await axiosInstance({
        method: "post",
        url: "/moodboards/companies/" + id,
        data: formData2,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setActiveMoodboard(response);
      setDone(false);
      await MyNotif("Moodboard edited successfully.", "success");
      //
      fetchLast4Moadboard();
      onClose();

      return response.data;
    }
  };

  //lunches to get the products for you of the user's fabric type

  useEffect(() => {
    //  fetchMoadboard();

    getCompany();
    fetchLast4Moadboard();
    setUrl(`/admin/foryou/products/${params.id}?&` + queries.join("&"));
  }, [location.pathname]);

  const callback = (key: any) => {
    switch (key) {
      case "1":
        dispatch(filterAndKeepAll(`/admin/foryou/products/${params.id}`));
        // setOrderSource("direct_order");
        break;
      case "2":
        // setOrderSource("normal_order");
        break;
      default:
      // setOrderSource("customized");
    }
  };

  const deleteMoodboard = async (id: any) => {
    let token = localStorage.getItem("token");
    const { data: response } = await axiosInstance({
      method: "delete",
      url: "/moodboards/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await MyNotif("Moodboad Delete successfully.", "success");
    fetchLast4Moadboard();
    onClose();
    return response.data;
  };

  const getCompany = () => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "get",
      url: `/admin/getcompany/${params.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      localStorage.setItem("company_name", response?.data?.name);
      localStorage.setItem("company_id", response?.data?.id);
    });
  };

  const content = (el: any) => {
    return (
      <div style={{ padding: "15px" }}>
        <button
          onClick={() => {
            setType("edit");
            showDrawer(el);
          }}
        >
          <EditOutlined style={{ marginRight: "7px" }} />
          Edit
        </button>
        <br />
        <Popconfirm
          title="Are you sure, you want to delete this moodboard  ?"
          onConfirm={() => deleteMoodboard(el.id)}
        >
          <button>
            {" "}
            <DeleteOutlined style={{ marginRight: "5px" }} /> Delete
          </button>
        </Popconfirm>
      </div>
    );
  };

  const genExtra = (el: any) => (
    <>
      <span style={{ marginRight: "15px" }}>
        <a
          target="_blank"
          href={process.env.REACT_APP_STORAGE_URL + el.file_storage}
          style={{ color: "#00468c" }}
        >
          <EyeOutlined
            style={{
              fontSize: "25px",
            }}
          />
        </a>
      </span>
      <Popover placement="top" content={content(el)} trigger="click">
        <MoreOutlined
          style={{
            fontSize: "25px",
          }}
        />
      </Popover>
    </>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Head title="For You" />

      <div className="products_page_container_for_you_user">
        <div className={`container-fluid products_page`}>
          {width >= 1000 && (
            <div className="filter_collection">
              {/* moodboard block */}
              <aside className="aside"></aside>
              <div
                className={width <= 1366 && width >= 100 ? "" : "mt-foryou-1"}
              >
                <Filter
                  setMax_elasticity_before={setMax_elasticity_before}
                  setMin_elasticity_before={setMin_elasticity_before}
                  max_elasticity_before={max_elasticity_before}
                  min_elasticity_before={min_elasticity_before}
                  isDragging={isDragging}
                  fabric=""
                  categoryId=""
                  genderId=""
                  handleChangePrice={handleChangePrice}
                  min_price={min_price}
                  max_price={max_price}
                  urlProp={url}
                  setUrl={setUrl}
                  queries={queries}
                  addQuery={addQuery}
                  highestPrice={highestPrice}
                  min_price_allowed={min_price_allowed}
                  max_price_allowed={max_price_allowed}
                  setMax_price={setMax_price}
                  setMin_price={setMin_price}
                  setMax_price_allowed={setMax_price_allowed}
                  setMin_price_allowed={setMin_price_allowed}
                  handleChangePriceAfter={handleChangePriceAfter}
                  max_priceBefore={max_priceBefore}
                  min_priceBefore={min_priceBefore}
                  max_elasticity={max_elasticity}
                  min_elasticity={min_elasticity}
                  handleChangeElasticity={handleChangeElasticity}
                  setMax_weight_before={setMax_weight_before}
                  setMin_weight_before={setMin_weight_before}
                  max_weight_before={max_weight_before}
                  min_weight_before={min_weight_before}
                  max_weight={max_weight}
                  min_weight={min_weight}
                  handleChangeWeight={handleChangeWeight}
                />
              </div>
            </div>
          )}

          <div
            className="products_list_collection"
            style={{ paddingTop: "0px" }}
          >
            <Row>
              {/*  for you demco  */}
              <div className="filter_tags_wrapper">
                <div style={{ width: "90%" }}>
                  {width >= 1000 ? (
                    ((filters && filters.length > 0) ||
                      max_price_allowed !== max_price ||
                      min_price_allowed !== min_price ||
                      max_elasticity !== 100 ||
                      min_elasticity !== 0) && (
                      <p style={{ fontSize: "16px" }}></p>
                    )
                  ) : (
                    <button
                      className="filter_product_button"
                      onClick={() => setOpenFilterDrawer(true)}
                    >
                      <FilterOutlined className="mr-2" /> Filter
                    </button>
                  )}
                  {/* FILTER DRAWER */}
                  <Drawer
                    title="FILTER"
                    placement={"left"}
                    closable={true}
                    onClose={() => setOpenFilterDrawer(false)}
                    visible={openFilterDrawer}
                    key={"left"}
                  >
                    <div className="p-3">
                      <aside>
                        <Collapse
                          defaultActiveKey={["1"]}
                          ghost
                          expandIconPosition={"right"}
                          expandIcon={(iconProp) =>
                            iconProp.isActive ? (
                              <FontAwesomeIcon icon={faMinus} />
                            ) : (
                              <FontAwesomeIcon icon={faPlus} />
                            )
                          }
                          onChange={() => setActiveMoodboard(null)}
                        >
                          <Panel header={"Moodboard"} key={uuidv4()}>
                            <button
                              style={{
                                alignItems: "center",
                                color: "white",
                                border: "none",
                                background: "#00468c",
                                borderRadius: "5px",
                                margin: "0 10px !important",
                                outline: "none !important",
                                fontSize: "12px",
                                padding: "10px",
                              }}
                              onClick={() => {
                                setType("new");
                                showDrawer("new");
                              }}
                            >
                              Add new Moodboardsss{" "}
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          </Panel>
                          <Divider key={uuidv4()} />
                        </Collapse>
                      </aside>
                      <Filter
                        setMax_elasticity_before={setMax_elasticity_before}
                        setMin_elasticity_before={setMin_elasticity_before}
                        max_elasticity_before={max_elasticity_before}
                        min_elasticity_before={min_elasticity_before}
                        isDragging={isDragging}
                        fabric={params.fabric}
                        categoryId={params.categoryId}
                        genderId={params.genderId}
                        handleChangePrice={handleChangePrice}
                        min_price={min_price}
                        max_price={max_price}
                        urlProp={url}
                        setUrl={setUrl}
                        queries={queries}
                        addQuery={addQuery}
                        min_price_allowed={min_price_allowed}
                        max_price_allowed={max_price_allowed}
                        setMax_price={setMax_price}
                        setMin_price={setMin_price}
                        setMax_price_allowed={setMax_price_allowed}
                        setMin_price_allowed={setMin_price_allowed}
                        handleChangePriceAfter={handleChangePriceAfter}
                        max_priceBefore={max_priceBefore}
                        min_priceBefore={min_priceBefore}
                        max_elasticity={max_elasticity}
                        min_elasticity={min_elasticity}
                        handleChangeElasticity={handleChangeElasticity}
                        setMax_weight_before={setMax_weight_before}
                        setMin_weight_before={setMin_weight_before}
                        max_weight_before={max_weight_before}
                        min_weight_before={min_weight_before}
                        max_weight={max_weight}
                        min_weight={min_weight}
                        handleChangeWeight={handleChangeWeight}
                      />
                    </div>
                  </Drawer>
                  <div className="filter_tags">
                    {((filters && filters.length > 0) ||
                      max_price_allowed !== max_price ||
                      min_price_allowed !== min_price) && (
                      <div
                        className="filter_tag"
                        onClick={() => {
                          setMax_price(prices.max_price);
                          setMin_price(prices.min_price);
                          setMin_priceBefore(prices.min_price);
                          setMax_priceBefore(prices.max_price);
                          setMax_elasticity(100);
                          setMin_elasticity(0);
                          setMax_elasticity_before(100);
                          setMin_elasticity_before(0);
                          dispatch(clearAllFilters());
                          setQueries([]);
                        }}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "rgba(255, 0, 69, 0.1)",
                          border: "2px solid rgba(255, 0, 69, 0.4)",
                          boxSizing: "border-box",
                          padding: "8px 13px",
                        }}
                      >
                        <p>Clear filters</p>
                        <span>
                          <ClearOutlined
                            style={{ transform: "translateY(-3px)" }}
                          />
                        </span>
                      </div>
                    )}
                    {(max_price_allowed !== max_price ||
                      min_price_allowed !== min_price) && (
                      <div className="filter_tag">
                        <p>{`${min_price} - ${max_price}`}</p>
                        <span
                          onClick={() => {
                            setMax_price(max_price_allowed);
                            setMin_price(min_price_allowed);
                            setMin_priceBefore(min_price_allowed);
                            setMax_priceBefore(max_price_allowed);
                          }}
                        >
                          X
                        </span>
                      </div>
                    )}
                    {(max_elasticity !== max_elasticity_allowed ||
                      min_elasticity !== min_elasticity_allowed) && (
                      <div className="filter_tag">
                        <p>
                          {" "}
                          Elasticity : {`${min_elasticity} - ${max_elasticity}`}
                        </p>
                        <span
                          onClick={() => {
                            setMax_elasticity(
                              elasticity_fabric.max_elasticity_value
                            );
                            setMin_elasticity(
                              elasticity_fabric.min_elasticity_value
                            );
                            setMax_elasticity_before(
                              elasticity_fabric.max_elasticity_value
                            );
                            setMin_elasticity_before(
                              elasticity_fabric.min_elasticity_value
                            );
                          }}
                        >
                          X
                        </span>
                      </div>
                    )}

                    {(max_weight !== max_weight_allowed ||
                      min_weight !== min_weight_allowed) && (
                      <div className="filter_tag">
                        <p>Weight : {`${min_weight} - ${max_weight}`}</p>
                        <span
                          onClick={() => {
                            setMax_weight(weight_fabric.max_weight_value);
                            setMin_weight(weight_fabric.min_weight_value);
                            setMax_weight_before(
                              weight_fabric.max_weight_value
                            );
                            setMin_weight_before(
                              weight_fabric.min_weight_value
                            );
                          }}
                        >
                          X
                        </span>
                      </div>
                    )}
                    {filters &&
                      filters.map((el: any, i: number) => (
                        <div className="filter_tag" key={i}>
                          <p>{el.name}</p>
                          <span
                            onClick={() => {
                              deleteFilterTag(el);
                              dispatch(deleteFilter(el.name));
                            }}
                          >
                            X
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
                <button
                  onClick={() => {
                    dispatch(handleLeftDrawer());
                    localStorage.setItem("company_id", params.id);
                  }}
                  className="view-details"
                >
                  Select Products
                </button>
                {/* <p className="num_items ml-2">
                {products &&
                  products.filter((product: any) => {
                    if (params.fabric) {
                      return product.fabric_type === params.fabric;
                    } else return product;
                  }).length}{" "}
                ITEMS
              </p> */}
              </div>
              {isLoading && activeMoodboard == null ? (
                <div className="row ">
                  {Array.from({ length: 10 }, (_, index) => index + 1).map(
                    (_, i) => (
                      <div className="col-12 col-md-2" key={i}>
                        <div className="d-flex flex-column">
                          <Card
                            loading={isLoading}
                            bordered={false}
                            style={{ width: "100%" }}
                            bodyStyle={{ padding: "5px" }}
                            cover={
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                                src={placeholder_jeans}
                              />
                            }
                          >
                            <Meta title={"Code"} description={"Name"} />
                          </Card>
                        </div>
                      </div>
                    )
                  )}{" "}
                </div>
              ) : products &&
                activeMoodboard == null &&
                products.filter((product: any) => {
                  if (params.fabric) {
                    return product.fabric_type === params.fabric;
                  } else return product;
                }).length > 0 ? (
                <Droppable
                  droppableId={`products-list-droppable`}
                  direction="horizontal"
                >
                  {(provided, snapshot) => (
                    <div
                      // className="row products_elements"
                      className="products-grid"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {products
                        /*  .filter((product: any) => {
                          if (params.fabric) {
                            return product.fabric_type === params.fabric;
                          } else return product;
                        }) */
                        .map((product: any, index: number) => (
                          <>
                            <CardProductForYou
                              key={index}
                              index={index}
                              productPage={true}
                              product={product}
                              foryou={forYouCompany}
                              item={{
                                main: product.images[0]
                                  ? product.images[0].path
                                  : "",
                                onHover: product.images[1]
                                  ? product.images[1].path
                                  : "",
                              }}
                            />
                          </>
                        ))}
                    </div>
                  )}
                </Droppable>
              ) : filters && filters.length !== 0 && products.length === 0 ? (
                <div className="unavailable_products_container">
                  <h3 className="unavailable_products">
                    No products available with the selected filters !
                  </h3>
                </div>
              ) : (
                filters &&
                filters.length === 0 &&
                products.length === 0 && (
                  <div className="unavailable_products_container">
                    <h3 className="unavailable_products">
                      No products available for you for the moment !
                    </h3>
                  </div>
                )
              )}
              {activeMoodboard != null && (
                <div className="row1">
                  <div className="row title_container_post">
                    <div className="col-12 post_header">
                      <Row>
                        <h1 className="post_title">{activeMoodboard.theme}</h1>

                        <button
                          onClick={() => {
                            setType("edit");
                            showDrawer(activeMoodboard);
                          }}
                        >
                          <EditOutlined
                            style={{ marginRight: "7px", fontSize: "20px" }}
                          />
                        </button>
                        <Popconfirm
                          title="Are you sure, you want to delete this moodboard  ?"
                          onConfirm={() => deleteMoodboard(activeMoodboard.id)}
                        >
                          <button>
                            {" "}
                            <DeleteOutlined
                              style={{ marginRight: "5px", fontSize: "20px" }}
                            />{" "}
                          </button>
                        </Popconfirm>
                      </Row>
                      <div className="divider_post"></div>

                      <Row>
                        <Tag color="#00468C" style={{ borderRadius: "5px" }}>
                          {" "}
                          {(activeMoodboard.division &&
                            activeMoodboard.division.toUpperCase()) ||
                            "-"}
                        </Tag>
                        <Tag color="#00468C" style={{ borderRadius: "5px" }}>
                          {" "}
                          {(activeMoodboard.saisson &&
                            activeMoodboard.saisson.toUpperCase()) ||
                            "-"}
                        </Tag>
                        <Tag color="#00468C" style={{ borderRadius: "5px" }}>
                          {" "}
                          {(activeMoodboard.year && activeMoodboard.year) ||
                            "-"}
                        </Tag>
                      </Row>
                      <p className="post_date" style={{ marginTop: "12px" }}>
                        {new Date(
                          activeMoodboard.created_at
                        ).toLocaleDateString()}
                      </p>

                      <div className="col-12">
                        <iframe
                          src={
                            process.env.REACT_APP_STORAGE_URL +
                            activeMoodboard.file_storage
                          }
                          frameBorder="0"
                          width="100%"
                          height="600"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*       </TabPane> */}
              {/*     <TabPane tab="Moodboard" key="2">
                  <button
                    style={{
                      alignItems: "center",
                      color: "white",
                      border: "none",
                      background: "#00468c",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      margin: "0 10px !important",
                      outline: "none !important",
                    }}
                    onClick={() => {
                      setType("new");
                      showDrawer("new");
                    }}
                  >
                    Add new Moodboard <FontAwesomeIcon icon={faPlus} />
                  </button>

                  {moodboard?.map((el: any) => {
                    return (
                      <>
                        <Collapse
                          defaultActiveKey={el.id}
                          className="options_collapse_details mt-4"
                          accordion={true}
                        >
                          <Panel
                            header={
                              el.saisson +
                              " " +
                              el.year +
                              " " +
                              el.division +
                              " " +
                              ": " +
                              el.theme
                            }
                            key={1}
                            extra={genExtra(el)}
                          >
                            <Droppable
                              droppableId={`products-list-droppable`}
                              direction="horizontal"
                            >
                              {(provided, snapshot) => (
                                <div
                                  // className="row products_elements"
                                  className="products-grid"
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {el?.products?.map(
                                    (product: any, index: number) => (
                                      <div>
                                        <ExampleProductCard
                                          key={index}
                                          index={index}
                                          productPage={true}
                                          product={product}
                                          item={{
                                            main: product.images[0]
                                              ? product.images[0].path
                                              : "",
                                            onHover: product.images[1]
                                              ? product.images[1].path
                                              : "",
                                          }}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </Droppable>
                          </Panel>
                        </Collapse>
                      </>
                    );
                  })}
                </TabPane> */}
              {/*    </Tabs> */}
            </Row>

            {/* {next_page && (
              <div style={{ textAlign: "center" }}>
                <button
                  className="submit_button_white"
                  onClick={() => {
                    getNextPageProducts();
                  }}
                >
                  LOAD MORE
                </button>
                {isLoadingNext && <Spinner />}
              </div>
            )} */}
          </div>
        </div>
      </div>
      {open && (
        <div style={{ width: "100px" }}>
          <Drawer
            title={type == "new" ? "Add new moodboard" : "Edit Moodboard"}
            placement={"bottom"}
            closable={true}
            onClose={onClose}
            visible={open}
            key={"bottom"}
            style={{ zIndex: 1050 }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container mt-6">
                <div className="form_add_folder">
                  <div style={{ width: "80%" }} className="container-fluid">
                    <p
                      className="text-center add_order_drawer_subtitle "
                      style={{ marginTop: "7px" }}
                    >
                      {type == "new"
                        ? "Enter your moodboard information"
                        : "Edit your moodboard information"}
                    </p>
                    <div className="row">
                      <div className=" col-md-6 col-12">
                        <div className="row">
                          <div className="col-12 mb-4">
                            <div className="field field_article field_article_name">
                              {/*    <input
                                type="text"
                                name="theme"
                                id="theme"
                                placeholder=""
                                value={type == "new" ? "" : "test"}
                                // onChange={(e: any) => handleChangeOrder(e)}
                              /> */}
                              <Controller
                                name="theme"
                                defaultValue={type == "new" ? "" : theme}
                                control={control}
                                render={(field) => (
                                  <Input
                                    {...field}
                                    size="large"
                                    type="text"
                                    style={errorBorder("theme")}
                                  />
                                )}
                                rules={{ required: true }}
                              />
                              <label htmlFor="theme" className="folder_label">
                                Name of Moodboard
                              </label>
                            </div>
                          </div>
                          {/* <div className="col-12 mb-4 mt-4">
                            <div className="field">
                             
                              <Controller
                                name="saisson"
                                defaultValue={type == "new" ? "" : saisson}
                                control={control}
                                render={(field) => (
                                  <Select
                                    size="large"
                                    {...field}
                                    style={errorBorder("saisson")}
                                  >
                                    <Option value="">--</Option>
                                    <Option value="AW">AW</Option>
                                    <Option value="FW">FW</Option>
                                    <Option value="SS">SS</Option>
                                    <Option value="SU">SU</Option>
                                    <Option value="PF">PF</Option>
                                  </Select>
                                )}
                                rules={{ required: true }}
                              />
                              <label htmlFor="theme" className="folder_label">
                                Season
                              </label>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="form_add_article_left col-md-6 col-12">
                        <div className="row">
                          <div className="col-12 mb-4">
                            <div className="field">
                              <Controller
                                name="year"
                                defaultValue={type == "new" ? "" : year}
                                control={control}
                                render={(field) => (
                                  <Input
                                    {...field}
                                    size="large"
                                    type="number"
                                    style={errorBorder("year")}
                                  />
                                )}
                                rules={{ required: true }}
                              />
                              <label htmlFor="year" className="folder_label">
                                Season
                              </label>
                            </div>
                          </div>
                          {/*  <div className="col-12 mb-4 mt-4">
                            <div className="field field_article">
                              <Controller
                                name="division"
                                defaultValue={type == "new" ? "" : division}
                                control={control}
                                render={(field) => (
                                  <Select
                                    size="large"
                                    {...field}
                                    style={errorBorder("division")}
                                  >
                                    <Option value="">--</Option>

                                    <Option value="Women">Women</Option>
                                    <Option value="Men">Men</Option>
                                    <Option value="Unisex">Unisex</Option>
                                    <Option value="Kids">Kids</Option>
                                  </Select>
                                )}
                                rules={{ required: true }}
                              />
                              <label htmlFor="theme" className="folder_label">
                                Division
                              </label>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="form_add_article_left col-md-6 col-12">
                        <div className="row">
                          <div className="col-12 mb-4 ">
                            <div className="field">
                              <Controller
                                name="clients"
                                defaultValue={type == "new" ? "" : client}
                                control={control}
                                render={(field) => (
                                  <Select
                                    size="large"
                                    {...field}
                                    style={errorBorder("clients")}
                                  >
                                    <Option value={0}>All clients</Option>
                                    {clients &&
                                      clients.data.data.map(
                                        (client: any, i: number) => (
                                          <Option value={client.id} key={i}>
                                            {client.name}
                                          </Option>
                                        )
                                      )}
                                  </Select>
                                )}
                                rules={{ required: true }}
                              />
                              <label htmlFor="year" className="folder_label">
                                Clients
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 p-4 field field_article">
                        <div className="d-flex flex-column mt-3">
                          <label>
                            File (PDF) MAX Size : 200 MB{" "}
                            {type == "edit" && (
                              <span style={{ opacity: 0.4 }}>
                                (if not changed you previous file will be
                                attached)
                              </span>
                            )}
                          </label>
                          <Controller
                            name="file"
                            control={control}
                            render={(field) => (
                              <Input
                                type="file"
                                {...field}
                                onChange={(e: any) => {
                                  setValue("file", e.target.files[0]);
                                  // setPreview(URL.createObjectURL(e.target.files[0]));
                                  setPreview(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                }}
                                style={errorBorder("file")}
                                size="large"
                                value={getValues("file")?.filename}
                              />
                            )}
                            rules={
                              file ? { required: false } : { required: true }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Row
                  gutter={[48, 8]}
                  style={{ paddingRight: 135, paddingLeft: 135 }}
                >
                  <Col span={12}>
                    {" "}
                    {getValues("file")?.name.split(".")[1] == "pdf" && (
                      <>
                        <iframe
                          src={preview}
                          frameBorder="0"
                          height="500px"
                          width="500px"
                        ></iframe>
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    {" "}
                    <div style={{ width: "100%" }} className="text-right">
                      <button
                        /*         style={{
                          alignItems: "center",
                          //   color: "white",
                          border: "none",
                          // background: "#00468c",
                          padding: "10px 15px",
                          borderRadius: "5px",
                          margin: "0 10px !important",
                          outline: "none !important",
                          pointerEvents: "none",
                          color: "#AAA",
                          background: "#F5F5F5",
                        }} */
                        className={
                          done ? "submitButtonWaiting" : "submitButton"
                        }
                      >
                        Submit {done ? <Spin size="small" /> : ""}
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </Drawer>

          {/*    <Modal
            title="Basic Modal"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
          </Modal> */}
        </div>
      )}
    </DragDropContext>
  );
};
