import { Reducer, AnyAction } from "redux";

import {
  GET_FORYOU_PRODUCTS_REQUEST,
  GET_FORYOU_PRODUCTS_SUCCESS,
} from "../actions/foryou-actions/types";
import {
  FILTER_PRODUCTS_REQUEST,
  FILTER_PRODUCTS_SUCCESS,
} from "../actions/products-actions/types";

const init_state: any = {
  foryouProducts: null,
  isLoading: false,
  msg: null,
  prices: { min_price: 0, max_price: 0 },
};

const foryouReducer: Reducer<any, AnyAction> = (
  state: any = init_state,
  action
): boolean => {
  switch (action.type) {
    case GET_FORYOU_PRODUCTS_REQUEST:
    case FILTER_PRODUCTS_REQUEST:
      return { ...state, isLoading: true };
    case GET_FORYOU_PRODUCTS_SUCCESS:
      if (action.payload === "You do not belongs to any company") {
        return {
          ...state,
          msg: action.payload,
          isLoading: false,
        };
      } else
        return {
          ...state,
          foryouProducts: action.payload.data,
          isLoading: false,
        };
    case FILTER_PRODUCTS_SUCCESS:
      return {
        ...state,
        foryouProducts: action.payload.data.data,
        isLoading: false,
        prices: action.payload.data.prices,
      };
    default:
      return state;
  }
};

export default foryouReducer;
