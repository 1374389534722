import CategoriesServices from "./services";
import {
  GET_CAT_UNDER_GENDER_SUCCESS,
  GET_CAT_UNDER_GENDER_FAIL,
  GET_CAT_UNDER_GENDER_REQUEST,
  GET_GENDERS_UNDER_FABRIC_REQUEST,
  GET_GENDERS_UNDER_FABRIC_SUCCESS,
  GET_GENDERS_UNDER_FABRIC_FAIL,
  CLOSE_SIDE_MENU_EXTENSION_REQUEST,
  GET_ALL_GENDERS_REQUEST,
  GET_ALL_GENDERS_SUCCESS,
  GET_ALL_GENDERS_FAIL,
  GET_ALL_COLLECTIONS_REQUEST,
  GET_ALL_COLLECTIONS_SUCCESS,
  GET_ALL_COLLECTIONS_FAIL,
} from "./types";

//GET CATEGORIES UNDER A GENDER
export const getCategoriesUnderGender =
  (idGender: any, fabric: any) => async (dispatch: any) => {
    await dispatch({
      type: GET_CAT_UNDER_GENDER_REQUEST,
    });
    try {
      const response = await CategoriesServices.getCategoriesUnderGender(
        idGender,
        fabric
      );
      await dispatch({
        type: GET_CAT_UNDER_GENDER_SUCCESS,
        payload: response.data,
      });
    } catch (e: any) {
      dispatch({
        type: GET_CAT_UNDER_GENDER_FAIL,
        payload: e.response,
      });
    }
  };

//GET CATEGORIES UNDER A GENDER
export const getGendersUnderfabric = (fabric: any) => async (dispatch: any) => {
  await dispatch({
    type: GET_GENDERS_UNDER_FABRIC_REQUEST,
  });
  try {
    const response = await CategoriesServices.getGendersUnderfabric(fabric);
    await dispatch({
      type: GET_GENDERS_UNDER_FABRIC_SUCCESS,
      payload: { data: response.data, fabric },
    });
  } catch (e: any) {
    dispatch({
      type: GET_GENDERS_UNDER_FABRIC_FAIL,
      payload: e.response,
    });
  }
};

//GET CATEGORIES UNDER A GENDER
export const getGenders = () => async (dispatch: any) => {
  await dispatch({
    type: GET_ALL_GENDERS_REQUEST,
  });
  try {
    const response = await CategoriesServices.getGenders();
    await dispatch({
      type: GET_ALL_GENDERS_SUCCESS,
      payload: { data: response.data },
    });
  } catch (e: any) {
    dispatch({
      type: GET_ALL_GENDERS_FAIL,
      payload: e.response,
    });
  }
};

//GET CATEGORIES UNDER A GENDER
export const getCollections = () => async (dispatch: any) => {
  await dispatch({
    type: GET_ALL_COLLECTIONS_REQUEST,
  });
  try {
    const response = await CategoriesServices.getCollections();
    await dispatch({
      type: GET_ALL_COLLECTIONS_SUCCESS,
      payload: { data: response.data },
    });
  } catch (e: any) {
    dispatch({
      type: GET_ALL_COLLECTIONS_FAIL,
      payload: e.response,
    });
  }
};

//CLOSE SIDE MENU EXTENSION
export const closeSideMenuExtension = () => async (dispatch: any) => {
  await dispatch({
    type: CLOSE_SIDE_MENU_EXTENSION_REQUEST,
  });
};

// Load Connected User Information
// export const loadConnectedUser = () => async (dispatch: any) => {
//   await dispatch({
//     type: LOAD_USER_REQUEST,
//   });

//   try {
//     const response = await AuthServices.loadUser();
//     await dispatch({
//       type: LOAD_USER_SUCCESS,
//       payload: response.data,
//     });
//   } catch (e: any) {
//     dispatch({
//       type: LOAD_USER_FAIL,
//       payload: e.response,
//     });
//   }
// };

// Forgot Password
// export const forgotPwd = (email: string) => async (dispatch: any) => {
//   await dispatch({
//     type: FORGOT_PWD_REQUEST,
//   });

//   try {
//     const response = await AuthServices.forgotPwdSendEmail(email);
//     await dispatch({
//       type: FORGOT_PWD_SUCCESS,
//       payload: `An email has been sent to ${email}`,
//     });
//   } catch (e: any) {
//     dispatch({
//       type: FORGOT_PWD_FAIL,
//       payload: "Please put a valid email address.",
//     });
//   }
// };

// Reset Password
// export const resetPassword = (data: object) => async (dispatch: any) => {
//   await dispatch({
//     type: ADD_NEW_PWD_REQUEST,
//   });
//   try {
//     const response = await AuthServices.addNewPwd(data);
//     await dispatch({
//       type: ADD_NEW_PWD_SUCCESS,
//     });
//   } catch (e: any) {
//     dispatch({
//       type: ADD_NEW_PWD_FAIL,
//       payload: e,
//     });
//   }
// };

// Logout User
// export const logout = () => (dispatch?: any) => {
//   dispatch({
//     type: LOGOUT_USER,
//   });
// };
