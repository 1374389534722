import {
  Button,
  Col,
  Drawer,
  Input,
  Pagination,
  Popconfirm,
  Result,
  Row,
  Select,
  Spin,
  Tabs,
  Modal
} from "antd";
import { useParams, useHistory } from "react-router";
import axiosInstance from "../../config/axios-instance";
import CustomizedOrdersCompany from "./CustomizedOrdersCompany";
import DirectOrdersCompany from "./DirectOrdersCompany";
import NormalOrdersCompany from "./NormalOrdersCompany";
import React, {useState, useEffect, useCallback, useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";
import moment from "moment";
import { CloudUploadOutlined ,FileZipOutlined ,FileExcelOutlined} from "@ant-design/icons";

import {
  LoadingOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  FilePdfOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PaperClipOutlined, SearchOutlined,
} from "@ant-design/icons";
import { faCheckCircle, faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { Controller, useForm } from "react-hook-form";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import avatar from "../../img/avatar_placeholder.jpg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {HrIcon} from "../product-3d-config-tabs/forms/canvas/icon/HrIcon";
import {useDropzone} from "react-dropzone";
import {v4 as uuidv4} from "uuid";
import success from "../../icons/Success";

const { TabPane } = Tabs;

const ListProducts = () => {
  const history = useHistory();
  const params = useParams<any>();
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  function callback(key: any) {
    history.push(`/foryou-all-companies/${params.id}/${key}`);
  }
  const [product, setProducts] = useState<Array<any>>([]);
  const [type, setType] = useState("denim");
  const [gender, setGender] = useState("2");

  const fetchProducts = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/getallproducts`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setProducts(res.data);
      console.log(product);
    });
  };

  useEffect(() => {
    // fetchMoadboard();
  }, []);

  const [theme, setTheme] = useState("");
  const [year, setYear] = useState("");

  const [saisson, setSaisson] = useState("");

  const [division, setDivision] = useState("");
  const [file, setFile] = useState("");
  const [id, setId] = useState("");
  const [preview, setPreview] = useState("");
  const [client, setClient] = useState("");

  const [done, setDone] = useState(false);
  const { Option, OptGroup } = Select;

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenImage, setIsModalOpenImage] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const containerRef = useRef<any>();
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    accept: ".xlsx",

    maxFiles: 1,
    onDragEnter: () => (containerRef.current.style.background = "#e5f1fa"),
    onDragLeave: () => (containerRef.current.style.background = "#f4f7ff"),

  });


  const containerRefImage = useRef<any>();
  const {acceptedFiles:acceptedFilesImage, getRootProps:getRootPropsImage, getInputProps:getInputPropsImgae} = useDropzone({
    accept: ".zip",

    maxFiles: 1,
    onDragEnter: () => (containerRefImage.current.style.background = "#e5f1fa"),
    onDragLeave: () => (containerRefImage.current.style.background = "#f4f7ff"),

  });

  const files = acceptedFiles.map((file:any )=> (
      <div key={file.path}>
        {file.path}
      </div>
  ));

  const filesImage = acceptedFilesImage.map((file:any )=> (
      <div key={file.path}>
        {file.path}
      </div>
  ));

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalImage = () => {
    setIsModalOpenImage(true);
  };

  const handleUpload = useCallback(() => {
    if (acceptedFiles.length > 0) {
      const formData = new FormData();
      // Append the file to the FormData instance
      // 'file' is the name of the input expected by your Laravel backend
      formData.append('file', acceptedFiles[0]);

      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint
      axiosInstance.post('YOUR_API_ENDPOINT', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for files, especially if your server checks for this header
          // Other headers like Authorization can be added here as well
        },
      })
          .then(response => {
            // Handle the successful upload response here
            console.log('File uploaded successfully:', response.data);
          })
          .catch(error => {
            // Handle errors here
            console.error('Error uploading file:', error);
          });
    }
  }, [acceptedFiles]);

  const handleOk = () => {
    let token = localStorage.getItem("token");

    if (acceptedFiles.length > 0) {
      setConfirmLoading(true);
      const formData = new FormData();
      // Append the file to the FormData instance
      // 'file' is the name of the input expected by your Laravel backend

      formData.append('file', acceptedFiles[0]);
      formData.append('type', type);
      if(type=="denim"){

      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint
      axiosInstance.post('/import-products-denim', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for files, especially if your server checks for this header
          Authorization: `Bearer ${token}`,
        },
      })
          .then(response => {
            // Handle the successful upload response here
            setIsModalOpen(false);
            fetchProducts();
            setConfirmLoading(false);

            MyNotif('Products imported successfully.',"success");

          })
          .catch(error => {
            // Handle errors here
            console.error('Error uploading file:', error);
          });
    }else if (type=="knit") {

        axiosInstance.post('/import-products-knit', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Important for files, especially if your server checks for this header
            Authorization: `Bearer ${token}`,
          },
        })
            .then(response => {
              // Handle the successful upload response here
              setIsModalOpen(false);
              fetchProducts();
              setConfirmLoading(false);

              MyNotif('Products imported successfully.',"success")
            })
            .catch(error => {
              // Handle errors here
              console.error('Error uploading file:', error);
            });

      } else {

        axiosInstance.post('/import-products-mix', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Important for files, especially if your server checks for this header
            Authorization: `Bearer ${token}`,
          },
        })
            .then(response => {
              // Handle the successful upload response here
              setIsModalOpen(false);
              fetchProducts();
              setConfirmLoading(false);

              MyNotif('Products imported successfully.',"success")
            })
            .catch(error => {
              // Handle errors here
              console.error('Error uploading file:', error);
            });
      }
    }

  };

  const handleOkImage = () => {
    let token = localStorage.getItem("token");

    if (acceptedFilesImage.length > 0) {
      setConfirmLoading(true);
      const formData = new FormData();
      // Append the file to the FormData instance
      // 'file' is the name of the input expected by your Laravel backend

      formData.append('file', acceptedFilesImage[0]);
      formData.append('type', type);


        axiosInstance.post('/import-products-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Important for files, especially if your server checks for this header
            Authorization: `Bearer ${token}`,
          },
        })
            .then(response => {
              // Handle the successful upload response here
              setIsModalOpenImage(false);
              fetchProducts();
              setConfirmLoading(false);

              MyNotif('Products imported successfully.',"success");

            })
            .catch(error => {
              // Handle errors here
              console.error('Error uploading file:', error);
            });

    }

  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelImage = () => {
    setIsModalOpenImage(false);
  };


  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };
  const showDrawer = (el: any) => {
    setTheme(el?.theme);
    setYear(el?.year);
    setId(el.id);
    setSaisson(el?.saisson);

    setDivision(el?.division);
    setFile(el?.file_storage);
    setClient(el?.client_id);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const { register, control, handleSubmit, errors, setValue, getValues } =
    useForm<any>();
  const { isLoading: loading, data: products } = useQuery<any>(
    "products",
    () => fetchProducts(),
    { keepPreviousData: true }
  );

  /*   const filteredData = product.filter(
    (el: any) =>
      el.fabric_type.toLowerCase() === type.toLowerCase() &&
      el.code.toLowerCase().includes(searchTerm.toLowerCase()) 
  ); */

  const filteredData = product.filter((el) => {
    const isNameMatch = el.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const isTypeMatch = el.fabric_type.toLowerCase() === type.toLowerCase();
    const isCodeMatch = el.code
      ? el.code.toLowerCase().includes(searchTerm.toLowerCase())
      : false;

    if (el.code === null && searchTerm === null) {
      return isNameMatch && isTypeMatch;
    }

    const isGender = el.gender_id == gender;
    return (isNameMatch && isTypeMatch && isGender) || (isCodeMatch && isTypeMatch && isGender);
  });

  const handleChangeSelect = (value: any) => {
    setType(value);
  };

  const handleChangeSelectGender = (value: any) => {
    setGender(value);
  };

  const deleteProduct = (id: any) => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "delete",
      url: `/admin/deleteproduct/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      fetchProducts();
      MyNotif("Product delelet succsfully ", "success");
    });
  };
  return (
    <>
      {loading ? (
        <>
          {" "}
          <div
            className="loading_orders"
            style={{ height: "50vh", width: "50vw", margin: "0 auto" }}>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        </>
      ) : (
        <>
          <div className="my_orders">
            <div className="container">
              {/* list of products */}

              {product && product.length > 0 ? (
                  <>
                    <div className={"row justify-content-between "} style={{alignItems: "center"}}>
                      <div>
                        <Link
                            to="/add-new-product"
                            className="add-new-product-button"
                            style={{width: "230px"}}>
                          Add new product <span style={{marginLeft: "25px"}}> <FontAwesomeIcon icon={faPlus}/></span>
                        </Link>
                      </div>
                      <div className={" row"} style={{alignItems: "center", display: "flex", gap: "10px"}}>

                        <button onClick={showModal} className={"view_history"} style={{
                          alignItems: "center",
                          display: "flex",
                          gap: "10px"
                        }}>Upload Sheet<FileExcelOutlined/></button>


                        <Modal title="Upload your file" okText="Upload" confirmLoading={confirmLoading}
                               visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                          <Select
                              onChange={handleChangeSelect}
                              style={{width: "100%"}}
                              defaultValue={"denim"}
                              size={"large"}
                              className={"select_add_product"}
                          >
                            <option value="denim" selected>
                              Denim
                            </option>
                            <option value="knit">Knit</option>
                            <option value="mix">Mix</option>
                          </Select>
                          <section className="container" style={{marginTop: "25px"}}>
                            <div {...getRootProps({className: 'dropzone'})}>
                              <input {...getInputProps()} />
                              {acceptedFiles.length > 0 ?
                                  <>
                                    <ul>{files}</ul>
                                  </>
                                  :
                                  <>
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                  </>
                              }

                            </div>

                          </section>
                        </Modal>


                        <button onClick={showModalImage} className={"view_history"} style={{
                          alignItems: "center",
                          display: "flex",
                          gap: "10px"
                        }}>Upload Image <FileZipOutlined /></button>


                        <Modal title="Upload your file Zip" okText="Upload" confirmLoading={confirmLoading}
                               visible={isModalOpenImage} onOk={handleOkImage} onCancel={handleCancelImage}>

                          <Select
                              onChange={handleChangeSelect}
                              style={{width: "100%"}}
                              defaultValue={"denim"}
                              size={"large"}
                              className={"select_add_product"}
                          >
                            <option value="denim" selected>
                              Denim
                            </option>
                            <option value="knit">Knit</option>
                          </Select>
                          <section className="container" style={{marginTop: "25px"}}>
                            <div {...getRootPropsImage({className: 'dropzone'})}>
                              <input {...getInputPropsImgae()} />
                              {acceptedFilesImage.length > 0 ?
                                  <>
                                    <ul>{filesImage}</ul>
                                  </>
                                  :
                                  <>
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                  </>
                              }

                            </div>

                          </section>
                        </Modal>
                      </div>


                    </div>
                    <div className={"row marginTopAddnewproduct"}>
                      <div className="col-6 col-xs-6" style={{height: "100%"}}>
                        <Select
                            onChange={handleChangeSelect}
                            style={{width: "100%"}}
                            defaultValue={"denim"}
                            size={"large"}
                            className={"select_add_product"}
                        >
                          <option value="denim" selected>
                            Denim
                          </option>
                          <option value="knit">Knit</option>
                          <option value="mix">Mix</option>
                        </Select>
                      </div>
                      <div className="col-6 col-xs-6" style={{height: "100%"}}>
                        <Select
                            onChange={handleChangeSelectGender}
                            style={{width: "100%"}}
                            defaultValue={"Women"}
                            size={"large"}
                            className={"select_add_product"}
                        >

                          <option value="1">
                            Men
                          </option>
                          <option value="2" selected>Women</option>
                          <option value="3">Unisex</option>
                          <option value="4">Kids</option>
                          <option value="5">Boys</option>

                          <option value="6">Girls</option>

                        </Select>
                      </div>
                    </div>

                    <div className="row marginTopAddnewproduct">

                      <div className="col-12 ">

                        <div className="search-bar searchfaq" style={{width: "100%"}}>
                          <div className="input-container">
                            <input type="text" placeholder="Search your product..." onChange={handleSearchChange}
                                   className={"search_input  select_add_product"}/>
                            <HrIcon></HrIcon>
                            <button type="submit" className={"submit_input"}><SearchOutlined/></button>
                          </div>
                        </div>
                      </div>
                    </div>
                {filteredData.length > 0 ? (

                        <div style={{overflowY: "auto"}}>
                        <table className="table table_list_ofproduct">
                            <thead>
                            <tr>
                              <th>Type</th>
                              <th>Name</th>
                              <th>Code</th>
                              <th>Published</th>
                              <th>Gender</th>
                              {type == "denim" ?
                                  <>
                                    <th>Total Price </th>
{/*
                                    <th>Total Price 2</th>
*/}
                                  </>
                                  :
                                  <>
                                    <th>Total Price</th>
                                  </>
                              }
                              <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredData &&
                                filteredData.map((prod: any) => (
                                    <>
                                      <tr key={prod.id}>
                                        <td>
                                          {" "}
                                          <p className="mb-0">{prod.fabric_type}</p>
                                        </td>
                                        <td width="250px">
                                          {" "}
                                          <p key={prod.id} className="mb-0">
                                            {prod.name}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">{prod.code}</p>
                                        </td>
                                        <td width="50px">
                                          <p style={{textAlign: "center"}}>
                                            {prod.masked == false ? (
                                                <>
                                                  <CheckCircleOutlined
                                                      className="favorite_icon_green"
                                                      style={{
                                                        fontSize: 24,
                                                      }}></CheckCircleOutlined>
                                                </>
                                            ) : (
                                                <>
                                                  {" "}
                                                  <CloseCircleOutlined
                                                      className="favorite_icon_red"
                                                      style={{
                                                        fontSize: 24,
                                                      }}></CloseCircleOutlined>{" "}
                                                </>
                                            )}
                                          </p>
                                        </td>
                                        <td>
                                          {(() => {
                                            switch (prod.gender_id) {
                                              case 1:
                                                return <span>Men</span>;
                                                break;
                                              case 2:
                                                return <span>Women</span>;
                                                break;
                                              case 3:
                                                return <span>Unisex</span>;
                                                break;
                                              case 4:
                                                return <span> Kids</span>;
                                                break;

                                              case 5:
                                                return <span> Boys</span>;
                                                break;
                                              case 6:
                                                return <span> Girls</span>;
                                                break;
                                              default:
                                                return <span>-</span>;
                                            }
                                          })()}
                                        </td>
                                        {type == "denim" ?
                                            <>
                                              <td> {prod.total_price} €</td>
{/*
                                              <td> {prod.total_price_two ?? "-"}</td>
*/}
                                            </>
                                            : <>
                                              <td> {prod.total_pricing} €</td>
                                            </>
                                        }
                                        <td>
                                             <button>

                                    </button>
                                          <Link
                                              to={`/product/details/${prod.fabric_type}/${prod.id}`}>
                                            {" "}
                                            <EditOutlined
                                                style={{
                                                  top: "7px",
                                                  fontSize: "25px",
                                                }}
                                            />{" "}
                                          </Link>
                                             {user &&
                                      user.permissions.includes(
                                        "product.delete"
                                      ) && (
                                        <button
                                          onClick={() => deleteProduct(prod.id)}
                                        >
                                          {" "}
                                          <DeleteOutlined
                                            style={{
                                              top: "7px",
                                              fontSize: "25px",
                                            }}
                                          />{" "}
                                        </button>
                                      )}

                                          <Popconfirm
                                              title="Are you sure, you want to delete this product  ?"
                                              onConfirm={() =>
                                                  deleteProduct(prod.id)
                                              }>
                                            <button>
                                              {" "}
                                              <DeleteOutlined
                                                  style={{
                                                    top: "7px",
                                                    fontSize: "25px",
                                                    color: "red"
                                                  }}
                                              />{" "}
                                            </button>
                                          </Popconfirm>

                                        </td>
                                      </tr>
                                    </>
                                ))}
                            </tbody>
                          </table>
                        </div>
                    ) : (
                        <Result
                            status="404"
                            title="No Product"
                            style={{width: "100%"}}
                            subTitle="Sorry, there's no Product."
                        />
                    )}
                  </>
              ) : (
                  <>
                  {" "}
                  <Result
                    status="404"
                    title="No Product"
                    style={{ width: "100%" }}
                    subTitle="Sorry, there's no Product."
                    extra={
                      <>
                        <button
                          style={{
                            alignItems: "center",
                            color: "white",
                            border: "none",
                            background: "#00468c",
                            padding: "10px 15px",
                            borderRadius: "5px",
                            margin: "0 10px !important",
                            outline: "none !important",
                            marginTop: "28px",
                          }}
                          onClick={() => {
                            setType("new");
                            showDrawer("new");
                          }}>
                          Add New Product <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </>
                    }
                  />
                </>
              )}

            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ListProducts;
