import { Switch } from "antd";
import React from "react";
import subfolders from "../../img/subfolders.png";
import trousers from "../../img/trousers.png";
import leather from "../../img/leather.png";
import washing from "../../img/washing.png";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

interface Props {
  collectionSwitch: boolean;
  productsSwitch: boolean;
  fabricSwitch: boolean;
  washSwitch: boolean;
  products: any;
  setCollectionSwitch: (x: boolean) => void;
  setResult: (x: any) => void;
  setProductsSwitch: (x: boolean) => void;
  setWashSwitch: (x: boolean) => void;
  setFabricSwitch: (x: boolean) => void;
}

export const SearchFilter = ({
  collectionSwitch,
  productsSwitch,
  fabricSwitch,
  washSwitch,
  products,
  setCollectionSwitch,
  setResult,
  setProductsSwitch,
  setWashSwitch,
  setFabricSwitch,
}: Props) => {
  const params: any = useParams();
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  return (
    <>
      {user && !isAdmin && (
        <div className="result_search_filter_item">
          <div>
            <img src={subfolders} alt="collections" />
            <label>Collections</label>
          </div>
          <Switch
            defaultChecked
            checked={collectionSwitch}
            className="collection"
            onChange={(checked) => setCollectionSwitch(checked)}
          />
        </div>
      )}
      <div className="result_search_filter_item">
        <div>
          <img src={trousers} alt="products" />
          <label>Products</label>
        </div>
        <Switch
          defaultChecked
          checked={productsSwitch}
          onChange={(checked) => {
            if (!checked) {
              setResult(
                products.filter(
                  (el: any) =>
                    !String(el.name)
                      .toLowerCase()
                      .includes(String(params.keyword).toLowerCase())
                )
              );
            } else {
              setResult(products);
            }
            setProductsSwitch(checked);
          }}
        />
      </div>
      {/* <div className="result_search_filter_item">
        <div>
          <img src={leather} alt="collections" />
          <label>Fabric</label>
        </div>
        <Switch
          defaultChecked
          checked={fabricSwitch}
          onChange={(checked) => setFabricSwitch(checked)}
        />
      </div> */}
      {/* <div className="result_search_filter_item">
        <div>
          <img src={washing} alt="collections" />
          <label>Wash</label>
        </div>
        <Switch
          defaultChecked
          checked={washSwitch}
          onChange={(checked) => setWashSwitch(checked)}
        />
      </div> */}
    </>
  );
};
