import { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
const LightBoxImage = ({ files_imgs, file, index = 0, single }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  return !single ? (
    <div
      style={{ height: "fit-content" }}
      className="lightbox_cust_preview mr-2"
      onClick={() => setIsOpen(true)}
    >
      <img
        src={process.env.REACT_APP_STORAGE_URL + files_imgs[index].file}
        width="150"
        className="rounded"
      />
      <div className="img_preview_overlay"></div>
      {isOpen && (
        <Lightbox
          mainSrc={process.env.REACT_APP_STORAGE_URL + file.file}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </div>
  ) : (
    <div
      style={{ height: "fit-content" }}
      className="lightbox_cust_preview mr-2"
      onClick={() => setIsOpen(true)}
    >
      <img
        src={process.env.REACT_APP_STORAGE_URL + file}
        width="150"
        className="rounded"
      />
      <div className="img_preview_overlay"></div>
      {isOpen && (
        <Lightbox
          mainSrc={process.env.REACT_APP_STORAGE_URL + file}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default LightBoxImage;
