import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useParams, useHistory, useLocation, Link} from "react-router-dom";
import {deletePost, getOnePost} from "../actions/creative-station-actions/actions";
import { ImgItem } from "../components/MasonryBlock/ImgItem";
import backArrow from "../img/backArrow.png";
import "../sass/SinglePost.scss";
import {Spin, Tooltip, Button, Divider, Tag, Popconfirm} from "antd";
import {ArrowLeftOutlined, EditOutlined ,DeleteOutlined} from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import VideoComponent from "../components/MasonryBlock/VideoComponent";
import { Helmet } from "react-helmet";
import Head from "../components/Head";
import axiosInstance from "../config/axios-instance";

interface Props {}

export const SinglePost = (props: Props) => {
  const [post, setPost] = useState<any>(null);
  const { onePost, getOnePostLoading } = useSelector(
    (state: any) => state.posts
  );
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const params = useParams<any>();
  console.log(params)
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    dispatch(getOnePost(params.post_id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (onePost) {
      setPost(onePost);
    }
  }, [onePost]);

  function showMarkup(data: string) {
    return { __html: onePost[data] };
  }
    useEffect(() => {
        // You can call your Laravel API to increment the product view counter here

    const trackVisit = async () => {
        try {
            /*    await axiosInstance.post('/products/track-visit', {
                  product_id: params.productId
                });*/


            let token = localStorage.getItem("token");
            return axiosInstance({
                method: "post",
                url: `posts/track-visit`,
                data: {
                    post_id: params.post_id
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (error) {
            console.error("Error tracking visit", error);
        }
    };

    trackVisit();
}, []);
  return getOnePostLoading ? (
    <div className="loading_article">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    post &&
      (post.type === "video" ? (
          <div className="single_post">
            <Head title={onePost.name}/>
            <Helmet>
              <meta charSet="utf-8"/>
              <meta
                  name="description"
                  content={
                      onePost.short_description.replace(/<[^>]*>?/gm, "") || ""
                  }
              />
              <meta
                  property="og:description"
                  content={
                      onePost.short_description.replace(/<[^>]*>?/gm, "") || ""
                  }
              />
              <meta
                  property="og:image"
                  content={process.env.REACT_APP_STORAGE_URL + onePost.file}
              />
              <title>{onePost.name}</title>
            </Helmet>
           {/* <div className="container">
              <div className="row align-items-center">

                <div className={
                  "faq_go_back"
                }
                     onClick={() => history.push("/creative-station")}
                >

                  <ArrowLeftOutlined

                  ></ArrowLeftOutlined>{" "}
                  <button
                      className={"go_back_text"}

                  >
                    Go back
                  </button>
                </div>

                <div className="col-12 title_container_post">
                  <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-end back_cs">
                      {user && isAdmin && user.type === "admin" && (
                          <div>
                            <Tooltip placement="bottom" title="Delete">

                              <Popconfirm
                                  placement="top"
                                  title={
                                    "Are you sure you want to delete this post?"
                                  }
                                  onConfirm={(e: any) => {
                                    e.stopPropagation();
                                    dispatch(deletePost(onePost?.id, onePost?.category));
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                              >

                                <Button
                                    type="ghost"
                                    shape="circle"
                                    size="large"
                                    className="update_product_btn"
                                    icon={<DeleteOutlined/>}

                                />
                              </Popconfirm>
                            </Tooltip>
                            <Tooltip placement="bottom" title="Update">
                              <Button
                                  type="ghost"
                                  shape="circle"
                                  size="large"
                                  className="update_product_btn"
                                  icon={<EditOutlined/>}
                                  onClick={() =>
                                      history.push(`/single-post/${onePost.id}/update`)
                                  }
                              />
                            </Tooltip>
                          </div>
                      )}
                    </div>
                    <div className="col-12 post_header">
                      <p className="post_type_title">
                        {onePost.category.toUpperCase()}
                      </p>
                      <h1 className="post_title">{onePost.name}</h1>
                      <div className="divider_post"></div>
                      <p className="post_by">
                        BY{" "}
                        {(onePost.author && onePost.author.toUpperCase()) ||
                            "DEMCO"}
                      </p>
                      <p className="post_date">
                        {new Date(onePost.created_at).toLocaleDateString()}
                      </p>
                      <p
                          dangerouslySetInnerHTML={showMarkup("short_description")}
                      ></p>
                      <Divider plain dashed style={{opacity: 0.4}}>
                        Share this article
                      </Divider>
                      <div className="d-flex align-items-center">
                        <a
                            className="pinterest_btn mr-2"
                            href={`https://www.pinterest.com/pin/create/button/?url=${
                                window.location.href
                            }&media=${
                                process.env.REACT_APP_STORAGE_URL + onePost.file
                            }&description=${onePost.short_description.replace(
                                /<[^>]*>?/gm,
                                ""
                            )}`}
                            data-pin-description={onePost.short_description}
                            data-pin-url={window.location.href}
                            data-pin-media={
                                process.env.REACT_APP_STORAGE_URL + onePost.file
                            }
                            target="_blank"
                        >
                          <i className="fab fa-pinterest"></i> Pinterest
                        </a>
                        <a
                            className="linkedin_btn"
                            target="_blank"
                            href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}&title=${onePost.name}&source=${window.location.host}`}
                        >
                          <i className="fab fa-linkedin"></i> LinkedIn
                        </a>
                      </div>

                    </div>
                    <div
                        className="col-12 d-flex justify-content-center"
                        style={{maxHeight: 500, position: "relative"}}
                    >
                      <div style={{position: "relative", width: "50%"}}>
                        <VideoComponent post={post}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}

            <div style={{padding: "25px"}}>
              <div className="row align-items-center">
                {/*               <div className="col-12 col-md-6 img_container_post">
               */}

                <div className="col-12 col-md-12 d-flex flex-column align-self-start">
                  <div className="row my-3 mb-3">
                    <div className="col-12 d-flex align-items-center justify-content-between back_cs">

                      <div className={
                        "faq_go_back"
                      }
                           onClick={() => history.push("/creative-station")}
                      >

                        <ArrowLeftOutlined

                        ></ArrowLeftOutlined>{" "}
                        <button
                            className={"go_back_text"}

                        >
                          Go back
                        </button>
                      </div>
                      {user && isAdmin && user.type === "admin" && (
                          <div>

                            <Tooltip placement="bottom" title="Delete">

                              <Popconfirm
                                  placement="top"
                                  title={
                                    "Are you sure you want to delete this post?"
                                  }
                                  onConfirm={(e: any) => {
                                    e.stopPropagation();
                                    dispatch(deletePost(onePost?.id, onePost?.category));
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                              >

                                <Button
                                    type="ghost"
                                    shape="circle"
                                    size="large"
                                    className="update_product_btn"
                                    icon={<DeleteOutlined/>}

                                />
                              </Popconfirm>
                            </Tooltip>
                            <Tooltip placement="bottom" title="Update">
                              <Button
                                  type="ghost"
                                  shape="circle"
                                  size="large"
                                  className="update_product_btn"
                                  icon={<EditOutlined/>}
                                  onClick={() =>
                                      history.push(`/single-post/${onePost.id}/update`)
                                  }
                              />
                            </Tooltip>
                          </div>
                      )}
                    </div>
                  </div>
                  <div className="row title_container_post">
                    <div className="col-12 post_header">


                        <p className="post_type_title">
                          {onePost.category.toUpperCase()}
                        </p>
                        <h1 className="post_title">{onePost.name}</h1>
                        <div className="divider_post"></div>
                        <p className="post_by">
                          BY{" "}
                          {(onePost.author && onePost.author.toUpperCase()) ||
                              "DEMCO"}
                        </p>
                        <p className="post_date">
                          {new Date(onePost.created_at).toLocaleDateString()}
                        </p>
                        <p
                            dangerouslySetInnerHTML={showMarkup("short_description")}
                        ></p>

                      <Divider plain dashed style={{opacity: 0.4}}>
                        Share this article
                      </Divider>
                      <div className="d-flex align-items-center">
                        <a
                            className="pinterest_btn mr-2"
                            href={`https://www.pinterest.com/pin/create/button/?url=${
                                window.location.href
                            }&media=${
                                process.env.REACT_APP_STORAGE_URL + onePost.file
                            }&description=${
                                onePost.short_description &&
                                onePost.short_description.replace(/<[^>]*>?/gm, "")
                            }`}
                            data-pin-description={onePost.short_description}
                            data-pin-url={window.location.href}
                            data-pin-media={
                                process.env.REACT_APP_STORAGE_URL + onePost.file
                            }
                            target="_blank"
                            data-pin-id={onePost.id}
                        >
                          <i className="fab fa-pinterest"></i> Pinterest
                        </a>
                        <a
                            className="linkedin_btn"
                            target="_blank"
                            href={`https://www.linkedin.com/sharing/share-offsite/?status=READY&url=${
                                window.location.href
                            }&media=${
                                process.env.REACT_APP_STORAGE_URL + onePost.file
                            }&title=${onePost.name}&description=${
                                onePost.short_description &&
                                onePost.short_description.replace(/<[^>]*>?/gm, "")
                            }&source=${window.location.host}`}
                        >
                          <i className="fab fa-linkedin"></i>LinkedIn
                        </a>
                      </div>

                      <div
                          className="col-12 d-flex justify-content-center"
                          style={{maxHeight: 500, position: "relative" ,marginTop:"25px"}}
                      >
                        <div style={{position: "relative", width: "50%"}}>
                          <VideoComponent post={post}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
      ) : (
          <>
            {onePost?.file ?
                <>
                  <div className="single_post">
                    <Helmet>
                      <meta charSet="utf-8"/>
                      <meta
                          name="description"
                          content={
                              (onePost?.short_description &&
                                  onePost?.short_description.replace(/<[^>]*>?/gm, "")) ||
                              ""
                          }
                          data-react-helmet="true"
                      />
                      <meta
                          property="og:description"
                          content={
                              (onePost?.short_description &&
                                  onePost?.short_description.replace(/<[^>]*>?/gm, "")) ||
                              ""
                          }
                      />
                      <meta
                          property="og:image"
                          content={process.env.REACT_APP_STORAGE_URL + onePost.file}
                      />

                      <title>{onePost.name}</title>
                    </Helmet>
                    <div style={{padding: "25px"}}>
                      <div className="row align-items-center">
                        {/*               <div className="col-12 col-md-6 img_container_post">
               */}

                        <div className="col-12 col-md-12 d-flex flex-column align-self-start">
                          <div className="row my-3 mb-3">
                            <div className="col-12 d-flex align-items-center justify-content-between back_cs">
                              {/*   <div className="d-flex align-items-center">
                      <img
                        src={backArrow}
                        className="back_arrow_folder mr-2"
                        alt="back arrow"
                        onClick={() => history.push("/creative-station")}
                      />
                      <h1>Creative Station</h1>
                    </div>*/}
                              <div className={
                                "faq_go_back"
                              }
                                   onClick={() => history.push("/creative-station")}
                              >

                                <ArrowLeftOutlined

                                ></ArrowLeftOutlined>{" "}
                                <button
                                    className={"go_back_text"}

                                >
                                  Go back
                                </button>
                              </div>
                              {user && isAdmin && user.type === "admin" && (
                                  <div>

                                    <Tooltip placement="bottom" title="Delete">

                                      <Popconfirm
                                          placement="top"
                                          title={
                                            "Are you sure you want to delete this post?"
                                          }
                                          onConfirm={(e: any) => {
                                            e.stopPropagation();
                                            dispatch(deletePost(onePost?.id, onePost?.category));
                                          }}
                                          okText="Yes"
                                          cancelText="No"
                                      >

                                        <Button
                                            type="ghost"
                                            shape="circle"
                                            size="large"
                                            className="update_product_btn"
                                            icon={<DeleteOutlined/>}

                                        />
                                      </Popconfirm>
                                    </Tooltip>
                                    <Tooltip placement="bottom" title="Update">
                                      <Button
                                          type="ghost"
                                          shape="circle"
                                          size="large"
                                          className="update_product_btn"
                                          icon={<EditOutlined/>}
                                          onClick={() =>
                                              history.push(`/single-post/${onePost.id}/update`)
                                          }
                                      />
                                    </Tooltip>
                                  </div>
                              )}
                            </div>
                          </div>
                          <div className="row title_container_post">
                            <div className="col-12 post_header">
                              {/*   <p className="post_type_title">
                      {onePost.category.toUpperCase()}
                    </p> */}
                              <h1 className="post_title">{onePost.name}</h1>
                              <div className="divider_post"></div>
                              <p className="post_by">
                                {onePost.session && (
                                    <Tag color="#00468C">
                                      {" "}
                                      {onePost.session.toUpperCase()}
                                    </Tag>
                                )}
                              </p>

                              {onePost.short_description != "undefined" ? (
                                  <p
                                      /*  dangerouslySetInnerHTML={showMarkup(
                                          "short_description"
                                        )} */
                                  ></p>
                              ) : (
                                  ""
                              )}
                              <Divider plain dashed style={{opacity: 0.4}}>
                                Share this article
                              </Divider>
                              <div className="d-flex align-items-center">
                                <a
                                    className="pinterest_btn mr-2"
                                    href={`https://www.pinterest.com/pin/create/button/?url=${
                                        window.location.href
                                    }&media=${
                                        process.env.REACT_APP_STORAGE_URL + onePost.file
                                    }&description=${
                                        onePost.short_description &&
                                        onePost.short_description.replace(/<[^>]*>?/gm, "")
                                    }`}
                                    data-pin-description={onePost.short_description}
                                    data-pin-url={window.location.href}
                                    data-pin-media={
                                        process.env.REACT_APP_STORAGE_URL + onePost.file
                                    }
                                    target="_blank"
                                    data-pin-id={onePost.id}
                                >
                                  <i className="fab fa-pinterest"></i> Pinterest
                                </a>
                                <a
                                    className="linkedin_btn"
                                    target="_blank"
                                    href={`https://www.linkedin.com/sharing/share-offsite/?status=READY&url=${
                                        window.location.href
                                    }&media=${
                                        process.env.REACT_APP_STORAGE_URL + onePost.file
                                    }&title=${onePost.name}&description=${
                                        onePost.short_description &&
                                        onePost.short_description.replace(/<[^>]*>?/gm, "")
                                    }&source=${window.location.host}`}
                                >
                                  <i className="fab fa-linkedin"></i>LinkedIn
                                </a>
                              </div>

                              <div
                                  className={`  ${
                                      onePost?.file?.split(".")[1] == "pdf"
                                          ? "col-12"
                                          : "col-6 img_container_post"
                                  }`}
                                  style={{marginTop: "25px"}}
                              >
                                {onePost.file.split(".")[1] == "pdf" ? (
                        <iframe
                          src={process.env.REACT_APP_STORAGE_URL + onePost.file+`#zoom=57`}
                          frameBorder="0"
                          width="100%"
                          height="700"
                        ></iframe>
                      ) : (
                        <ImgItem
                          src={process.env.REACT_APP_STORAGE_URL + onePost.file}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="divider_posts" />
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {onePost?.short_description != "<p>undefined</p>" && (
                    <p
                      className="post_article"
                      dangerouslySetInnerHTML={showMarkup("short_description")}
                    ></p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
</>
 :
 <>
   <div className="single_post">
     <div id="notfound">
       <div className="notfound">
         <div className="notfound-404">
           <h1>Oops!</h1>
           <h2>404 - The Post can't be found</h2>
         </div>
         <Link to="/">Go TO Homepage</Link>
       </div>
     </div>
   </div>
 </>
            }
          </>

      ))
  );
};
