import { Button, Tabs } from "antd";
import { useParams, useHistory } from "react-router";
import CustomizedOrdersCompany from "./CustomizedOrdersCompany";
import DirectOrdersCompany from "./DirectOrdersCompany";
import NormalOrdersCompany from "./NormalOrdersCompany";
import { useState } from "react";
import {Route} from "react-router-dom";
import DirectOrdersContainer from "../../pages/my orders/DirectOrdersContainer";
import DemcoProductOrdersContainer from "../../pages/my orders/DemcoProductOrdersContainer";
import CustomizedOrdersContainer from "../../pages/my orders/CustomizedOrdersContainer";

const { TabPane } = Tabs;

const Client = () => {
  const history = useHistory();
  const params = useParams<any>();
  const [key, setKey] = useState(
      "direct-orders"
  );
  const [orderStatus, setOrderStatus] = useState("in-progress");
  function callback(key: any) {
    history.push(`/all-clients/${params.id}/${key}`);
  }

  const handleChangeTab = (activeKey: string) => {
    setKey(activeKey);
    history.push(`/all-clients/${params.id}/${key}`);
  };


  return (
      <div className="my_orders">
        <div style={{display: "flex", justifyContent: "center"}}>
          <h1 className={"title_myorder"}>Orders</h1>
        </div>
        {/*     <div className="my_orders_header">
       <div className="my_orders_header_left">

            <div className="my_orders_header_btns mt-2 mt-md-0">*/}
        <div>
          <div className="my_orders_header_type ">

            <div className="my_orders_header_type_btns">
              <Button
                  type="ghost"
                  onClick={() => {
                    // history.push(`${path}/${tabKey}/in-progress`);
                    setOrderStatus("in-progress");
                  }}
                  className={orderStatus === "in-progress" ? "--selected" : ""}
              >
                In progress
              </Button>
              <Button
                  type="ghost"
                  onClick={() => {
                    // history.push(`${path}/${tabKey}/completed`);
                    setOrderStatus("completed");
                  }}
                  className={orderStatus === "completed" ? "--selected" : ""}
              >
                Completed
              </Button>
              <Button
                  type="ghost"
                  onClick={() => {
                    //  history.push(`${path}/${tabKey}/cancelled`);
                    setOrderStatus("cancelled");
                  }}
                  className={orderStatus === "cancelled" ? "--selected" : ""}
              >
                Cancelled
              </Button>
            </div>
          </div>
        </div>

        <div className="my_orders_header_type mt-4">
          <div className="my_orders_header_type_btns">
            <Button
                type="ghost"
                onClick={() => handleChangeTab("direct-orders")}

                className={key === "direct-orders" ? "--selected" : ""}

            >
              Direct Orders
            </Button>
            <Button
                type="ghost"
                onClick={() => handleChangeTab("demco-product-orders")}

                className={key === "demco-product-orders" ? "--selected" : ""}

            >
              Demco Orders
            </Button>
            <Button
                type="ghost"
                onClick={() => handleChangeTab("customized-orders")}

                className={key === "customized-orders" ? "--selected" : ""}

            >
              Customized Orders
            </Button>
          </div>
        </div>
        {key == "direct-orders" ?
            (
                <>
                  <DirectOrdersCompany status={orderStatus}/>
                </>
            ) : key == "demco-product-orders" ?
                (
                    <>
                      <NormalOrdersCompany status={orderStatus}/>
                    </>
                )
                :

                (
                    <>
                      <CustomizedOrdersCompany status={orderStatus}/>
                    </>
                )


        }
        {/*<Tabs defaultActiveKey={params.tab_key} onChange={callback} >
        <TabPane tab="Direct Orders" key="1">
          <DirectOrdersCompany status={orderStatus} />
        </TabPane>
        <TabPane tab="Demco Orders" key="2">
          <NormalOrdersCompany status={orderStatus} />
        </TabPane>
        <TabPane tab="Customized Orders" key="3">
          <CustomizedOrdersCompany status={orderStatus} />
        </TabPane>
      </Tabs>*/}
      </div>
  );
};

export default Client;
