const TrimsFilter = () => {
  return (
    <div>
      <p>METTALIC BUTTONS</p>
      <p>SNAP BUTTONS</p>
      <p>A CLOU</p>
      <p>LABELS</p>
      <p>ZIPPERS</p>
    </div>
  );
};

export default TrimsFilter;
