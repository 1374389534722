import React, { useState, useEffect } from "react";
import placeholder from "../../img/img_placeholder.svg";
import logo360 from "../../img/360.png";
//@ts-ignore
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import {ArrowLeftOutlined, CloseOutlined} from "@ant-design/icons";
import {history} from "../../index";

const ProductImage = ({ oneProduct, width, setVisible, visible }: any) => {
  const [link, setLink] = useState("");
  const [url, setUrl] = useState<any>(null);

  useEffect(() => {
    if (oneProduct) {
      if (oneProduct.link_360) {
        setLink(oneProduct.link_360);
        setUrl(new URL(oneProduct.link_360));
      }
    }
  }, [visible, oneProduct]);
  if (url && url.hostname === "localhost")
    return (
      <a target="_blank" href={link}>
        {link}
      </a>
    );
  if (oneProduct && oneProduct?.images?.length) {
    return (
        <>
            {/* {!visible && oneProduct.link_360 && (
          <button className="btn-360" onClick={() => setVisible(true)}>
            <img src={logo360} alt="360 degree" width="60" />
          </button>
        )} */}
            <div
                style={{
                    display: visible ? "inline-block" : "none",
                    position: "relative",
                    height: "100%",
                    minHeight: width <= 1024 ? "calc(100vh - 227px)" : "auto",
                    width: "100%",
                }}
            >
                <button className="btn-360-close" onClick={() => setVisible(false)}>
                    <CloseOutlined
                        style={{
                            verticalAlign: -1,
                            color: "#7c7c7c",
                            fontSize: "1.2rem",
                        }}
                    />
                </button>
                <iframe
                    src={link}
                    style={{
                        width: "100%",
                        height: width <= 1024 ? "calc(100vh - 227px)" : "100%",
                        border: "none",
                    }}
                />
            </div>
            <div className={
                "go_back"
            }

                 onClick={(e: any) => {
                     e.preventDefault();
                     history.back();
                 }}
                 style={{fontSize: "1rem", position: "absolute", zIndex: 99, top: "10px", margin: "25px"}}
            >

                <ArrowLeftOutlined

                ></ArrowLeftOutlined>{" "}

            </div>
            {!visible &&
                oneProduct.images.map((el: any, i: number) => (
                    <div
                        className="product_images"
                        key={i}
                        style={{position: "relative"}}
                    >


                        {oneProduct.link_360 && (
                            <button
                                className="btn-360"
                                style={{
                                    position: width <= 1024 ? "absolute" : "fixed",
                                    left: "10px",
                                    bottom: width <= 1024 ? "10px" : "10px",
                                    /* top: width <= 1024 ? "10px" : "unset",*/
                                }}
                                onClick={() => setVisible(true)}
                            >
                                <img src={logo360} alt="360 degree" width="60"/>
                            </button>
                        )}
                        <InnerImageZoom
                            src={process.env.REACT_APP_STORAGE_URL + el.path}
                            zoomSrc={process.env.REACT_APP_STORAGE_URL + el.path}
                            fullscreenOnMobile={true}
                            zoomScale={width <= 640 ? 1.3 : width >= 2560 ? 2.2 : 1.45}
                            className="lazy-load-image-background zoomed_img"
                            afterZoomIn={() => {
                                document
                                    .querySelectorAll(".lazy-load-image-background")
                                    [i]?.classList.remove("lazy-load-image-background");
                                if (width <= 640) {
                                    let x: any = document.querySelector(".iiz__zoom-portal");
                                    if (x) {
                                        x.style.background = "rgba(0,0,0,.5)";
                                        x.style.backdropFilter = "blur(5px)";
                                    }
                                }
                            }}
                            afterZoomOut={() =>
                                document
                                    .querySelectorAll(".zoomed_img")
                                    [i]?.classList.add("lazy-load-image-background")
                            }
                        />
                    </div>
                ))}
        </>
    );
  } else {
      return (
          <img
              src={placeholder}
              className="product_img"
              style={{width: "100%", height: "100%"}}
          />
      );
  }
};

export default ProductImage;
