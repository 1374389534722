import { Button, Popconfirm, Tooltip } from "antd";
import {
  HistoryOutlined,
  CheckOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useMutation } from "react-query";
import { MyNotif } from "../NotifIcons/NotifIcons";
import axiosInstance from "../../config/axios-instance";
import useWindowWidth from "../../config/useWindowWidth";
import { useState } from "react";
import ConfirmDrawer from "./ConfirmDrawer";

export default function DropdownActions({
  meet,
  refetch,
  confirmCancel,
  meetingToCancel,
  deleteLoading,
}: any) {
  const [visible, setVisible] = useState(false);
  const [meetToConfirm, setMeetToConfirm] = useState(null);
  const width = useWindowWidth();

  const onClose = () => setVisible(false);

  const acceptMeeting = async () => {
    let token = localStorage.getItem("token");
    setMeetToConfirm(meet.id);
    const { data: response } = await axiosInstance({
      method: "post",
      url: `/admin/bookings/${meet.id}`,
      data: {
        status: "accepted",
        client_id: meet.client.id,
        _method: "patch",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await MyNotif("Meeting accepted successfully.", "success");
    refetch();
    setMeetToConfirm(null);
    return response.data;
  };

  const { mutate: mutateUpdateBooking, isLoading: updateLoading } =
    useMutation(acceptMeeting);

  function confirm() {
    mutateUpdateBooking();
  }
  return (
    <>
      <Tooltip
        title="Reschedule"
        placement="right"
        overlayInnerStyle={{
          opacity: 0.8,
          color: "black",
          display: width > 1000 ? "none" : "inherit",
        }}
        color="white"
      >
        <Button
          type="ghost"
          style={{ color: "black", borderRadius: 5 }}
          icon={<HistoryOutlined style={{ verticalAlign: "1px" }} />}
          onClick={() => setVisible(true)}
        >
          {width > 1000 && "Reschedule"}
        </Button>
      </Tooltip>
      <ConfirmDrawer
        visible={visible}
        onClose={onClose}
        meet={meet}
        setVisible={setVisible}
        refetch={refetch}
      />
    </>
  );
}
