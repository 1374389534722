import { Avatar, Descriptions, PageHeader, Tag, Tooltip } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import moment from "moment";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import { useHistory, useParams } from "react-router-dom";
import { history } from "../../index";

const OrderHeader = ({ order, order_type, goTo }: any) => {
  const routerHistory = useHistory();
  const params = useParams<{ id: string }>();

  const statusColor = (name: string) => {
    if (order_type === "3") {
      switch (name) {
        case "PROTO":
          return {
            color: "#BCBCBD",
            msg: "Your prototype is being prepared",
          };
        case "PRODUCTION":
        case "OK PROD":
          return { color: "#06C170", msg: "Your order is in process" };

        case "CANCELLED":
          return { color: "#FF3A3A", msg: "Your order is canceled" };

        default:
          return {
            color: "#FF872A",
            msg: "We are waiting for your answer",
          };
      }
    }
  };

  console.log(order);

  return (
    <PageHeader
      ghost={false}
      className="site-page-header mt-3 rounded"
      onBack={() =>
        routerHistory.push(
          goTo === "order-overview"
            ? `/all-clients/${params?.id}/1/${order.id}`
            : `/all-clients/${params?.id}/1`
        )
      }
      title={order_type === "1" ? order.name : order.reference || order.ref}
      subTitle={`Order sent by ${order.client && order.client.name}`}
      style={{ border: "1px solid #ddd" }}
      extra={
        order_type === "3" && [
          <button
            key={order.id}
            className="rounded see_details_option_preview"
            onClick={() =>
              history.push(`${history.location.pathname}/progress/customized`)
            }
          >
            See details <ArrowRightOutlined style={{ verticalAlign: "0px" }} />
          </button>,
        ]
      }
    >
      <Descriptions size="small" column={3}>
        <Descriptions.Item label="Reference" labelStyle={{ fontWeight: 500 }}>
          {order_type === "2"
            ? order.reference
            : order_type === "3"
            ? order?.data?.[0].ref
            : order.ref}
        </Descriptions.Item>
        {order_type === "3" && (
          <Descriptions.Item
            label="Product name"
            labelStyle={{ fontWeight: 500 }}
          >
            {order.name || "n/a"}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Details" labelStyle={{ fontWeight: 500 }}>
          {order?.details || "n/a"}
        </Descriptions.Item>
        <Descriptions.Item label="Sent at" labelStyle={{ fontWeight: 500 }}>
          {moment(order?.updated_at).format("MMMM DD YYYY")}
        </Descriptions.Item>
        {order_type === "3" && (
          <Descriptions.Item
            label="Start production date"
            labelStyle={{ fontWeight: 500 }}
          >
            {order?.start_production_date || "n/a"}
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={order_type === "1" ? "Season" : "Shipping date"}
          labelStyle={{ fontWeight: 500 }}
        >
          {order_type === "1" ? order?.season : order?.shipping_date || "n/a"}
        </Descriptions.Item>
        {order_type === "1" && order?.collaborators && (
          <Descriptions.Item
            label="Collaborators"
            labelStyle={{ fontWeight: 500 }}
          >
            <Avatar.Group>
              {order?.collaborators.map((collab: any) => (
                <Tooltip title={collab.name} placement="bottom" key={collab.id}>
                  <Avatar
                    key={collab.id}
                    alt={collab.name}
                    src={
                      collab.logo
                        ? process.env.REACT_APP_STORAGE_URL + collab.logo
                        : avatar_placeholder
                    }
                  />
                </Tooltip>
              ))}
            </Avatar.Group>
          </Descriptions.Item>
        )}

        {/* {order_type === "3" && (
          <>
            <Descriptions.Item label="Status" labelStyle={{ fontWeight: 500 }}>
              <Tag color={statusColor(order.status.name)?.color}>
                {order.status.name || "n/a"}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item
              label="Quantity"
              labelStyle={{ fontWeight: 500 }}
            >
              {order.qty || "n/a"}
            </Descriptions.Item>
          </>
        )} */}
      </Descriptions>
    </PageHeader>
  );
};

export default OrderHeader;
