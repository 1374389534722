import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import {
  ADD_PRODUCT_TO_SELECTION_REQUEST,
  ADD_PRODUCT_TO_SELECTION_SUCCESS,
  ADD_PRODUCT_TO_SELECTION_FAIL,
  REMOVE_PRODUCT_FROM_SELECTION_REQUEST,
  REMOVE_PRODUCT_FROM_SELECTION_SUCCESS,
  REMOVE_PRODUCT_FROM_SELECTION_FAIL,
  GET_SELECTION_REQUEST,
  GET_SELECTION_SUCCESS,
  GET_SELECTION_FAIL,
  DESELECT_SELECTION,
  SELECT_SELECTION,
  DIRECT_RENDER,
} from "../myselection-actions/types";
import FoldersServices from "./services";

// get connected user selection
export const getSelectionProducts = () => async (dispatch: any) => {
  await dispatch({
    type: GET_SELECTION_REQUEST,
  });
  try {
    const response = await FoldersServices.getMySelection();
    await dispatch({
      type: GET_SELECTION_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: GET_SELECTION_FAIL,
      payload: e.response,
    });
  }
};

// add product to the connected user selection
export const addProductToSelection =
  (idProduct: any) => async (dispatch: any) => {
    await dispatch({
      type: ADD_PRODUCT_TO_SELECTION_REQUEST,
      payload: idProduct,
    });
    try {
      const response = await FoldersServices.addProductToSelection(idProduct);
      await dispatch({
        type: ADD_PRODUCT_TO_SELECTION_SUCCESS,
        payload: response.data,
      });
      dispatch(getSelectionProducts());
      MyNotif("Product added to your selection successfully.", "success");
    } catch (e: any) {
      dispatch({
        type: ADD_PRODUCT_TO_SELECTION_FAIL,
        payload: e.response,
      });
    }
  };

// remove product from the connected user selection
export const removeProductFromSelection =
  (idProduct: any, hideAlert?: boolean) => async (dispatch: any) => {
    await dispatch({
      type: REMOVE_PRODUCT_FROM_SELECTION_REQUEST,
      payload: idProduct,
    });
    try {
      const response = await FoldersServices.removeProductFromSelection(
        idProduct
      );
      await dispatch({
        type: REMOVE_PRODUCT_FROM_SELECTION_SUCCESS,
        payload: response.data,
      });
      dispatch(getSelectionProducts());
      if (!hideAlert) {
        MyNotif("Product deleted from your selection successfully.", "success");
      }
    } catch (e: any) {
      dispatch({
        type: REMOVE_PRODUCT_FROM_SELECTION_FAIL,
        payload: e.response,
      });
    }
  };

// change selectionSlected to true
export const selectSelection = () => (dispatch: any) => {
  dispatch({
    type: SELECT_SELECTION,
    payload: true,
  });
};

// change selectionSlected to false
export const deselectSelection = () => (dispatch: any) => {
  dispatch({
    type: DESELECT_SELECTION,
  });
};

// change selectionSlected to false
export const directRenderSelection = () => (dispatch: any) => {
  dispatch({
    type: DIRECT_RENDER,
  });
};
