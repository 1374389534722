import { Card, Col, Collapse, Divider, Row, Spin } from "antd";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios-instance";
import { LoadingOutlined } from "@ant-design/icons";
import LightBoxImage from "./LightBoxImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import OrderHeader from "./OrderHeader";
import { ArrowRightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const DirectOrderOptions = () => {
  const params = useParams<any>();
  const history = useHistory();

  const fetchArticleOptions = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/order_articles/${params.article_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const fetchDirectOrder = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/direct_orders/${params.order_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { isLoading, data } = useQuery<any>(
    "aricles_option",
    () => fetchArticleOptions(),
    {
      keepPreviousData: true,
    }
  );

  const { isLoading: loadingOrder, data: direct_order } = useQuery(
    "single_order",
    () => fetchDirectOrder(),
    {
      keepPreviousData: true,
    }
  );

  return isLoading ? (
    <div
      className="loading_orders"
      style={{ height: "50vh", width: "50vw", margin: "0 auto" }}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <div className="my_orders">
      <div className="container pb-4">
        {direct_order && (
          <OrderHeader
            order_type="1"
            order={direct_order.data}
            goTo="order-overview"
          />
        )}
        {data && (
          <Collapse
            defaultActiveKey={"0"}
            className="options_collapse_details mt-4"
          >
            {data.data.options.map((option: any, index: number) => (
              <Panel
                header={option.name}
                key={index}
                extra={
                  <button
                    className="rounded see_details_option_preview"
                    onClick={() =>
                      history.push(
                        `${history.location.pathname}/${option.id}/progress`
                      )
                    }
                  >
                    See details{" "}
                    <ArrowRightOutlined style={{ verticalAlign: "0px" }} />
                  </button>
                }
              >
                <div>
                  <Row gutter={[16, 16]}>
                    <Col md={8} sm={12} xs={24}>
                      <Card
                        title="STYLE"
                        headStyle={{
                          textAlign: "center",
                          color: "#00468c",
                          fontWeight: "bold",
                        }}
                        bordered={true}
                        className="rounded"
                        style={{ borderColor: "#dddddd", height: "100%" }}
                      >
                        <p>
                          <strong>Style: </strong>
                          {option.style}
                        </p>
                        <p>
                          <strong>Images: </strong>
                        </p>
                        <div className="d-flex mt-3 py-4 text-center flex-row justify-content-start w-100 overflow-auto preview_imgs_wrapper">
                          {option.style_file !== null &&
                            (option.style_file.slice(-4) !== ".pdf" ? (
                              <LightBoxImage file={option.style_file} single />
                            ) : (
                              <a
                                href={
                                  process.env.REACT_APP_STORAGE_URL +
                                  option.style_file
                                }
                                className="file_option_miniature mb-3 py-4 px-4 text-center"
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "gray",
                                }}
                              >
                                <FontAwesomeIcon icon={faFilePdf} />
                                {option.style_file}
                              </a>
                            ))}
                        </div>
                      </Card>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Card
                        title="FABRIC"
                        headStyle={{
                          textAlign: "center",
                          color: "#00468c",
                          fontWeight: "bold",
                        }}
                        bordered={true}
                        className="rounded"
                        style={{ borderColor: "#dddddd", height: "100%" }}
                      >
                        <p>
                          <strong>Fabric: </strong>
                          {option.fabric}
                        </p>
                        <p>
                          <strong>Images: </strong>
                        </p>
                        <div className="d-flex mt-3 py-4 text-center flex-row justify-content-start w-100 overflow-auto preview_imgs_wrapper">
                          {option.fabric_file !== null &&
                            (option.fabric_file.slice(-4) !== ".pdf" ? (
                              <LightBoxImage file={option.fabric_file} single />
                            ) : (
                              <a
                                href={
                                  process.env.REACT_APP_STORAGE_URL +
                                  option.fabric_file
                                }
                                className="file_option_miniature mb-3 py-4 px-4 text-center"
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "gray",
                                }}
                              >
                                <FontAwesomeIcon icon={faFilePdf} />
                                {option.fabric_file}
                              </a>
                            ))}
                        </div>
                      </Card>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Card
                        title="THREAD"
                        headStyle={{
                          textAlign: "center",
                          color: "#00468c",
                          fontWeight: "bold",
                        }}
                        bordered={true}
                        className="rounded"
                        style={{ borderColor: "#dddddd", height: "100%" }}
                      >
                        <p>
                          <strong>Thread: </strong>
                          {option.thread}
                        </p>
                        <p>
                          <strong>Images: </strong>
                        </p>
                        <div className="d-flex mt-3 py-4 text-center flex-row justify-content-start w-100 overflow-auto preview_imgs_wrapper">
                          {option.thread_file !== null &&
                            (option.thread_file.slice(-4) !== ".pdf" ? (
                              <LightBoxImage file={option.thread_file} single />
                            ) : (
                              <a
                                href={
                                  process.env.REACT_APP_STORAGE_URL +
                                  option.thread_file
                                }
                                className="file_option_miniature mb-3 py-4 px-4 text-center"
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "gray",
                                }}
                              >
                                <FontAwesomeIcon icon={faFilePdf} />
                                {option.thread_file}
                              </a>
                            ))}
                        </div>
                      </Card>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Card
                        title="TRIMS"
                        headStyle={{
                          textAlign: "center",
                          color: "#00468c",
                          fontWeight: "bold",
                        }}
                        bordered={true}
                        className="rounded"
                        style={{ borderColor: "#dddddd", height: "100%" }}
                      >
                        <p>
                          <strong>Trims: </strong>
                          {option.trims}
                        </p>
                        <p>
                          <strong>Images: </strong>
                        </p>
                        <div className="d-flex mt-3 py-4 text-center flex-row justify-content-start w-100 overflow-auto preview_imgs_wrapper">
                          {option.trims_file !== null &&
                            (option.trims_file.slice(-4) !== ".pdf" ? (
                              <LightBoxImage file={option.trims_file} single />
                            ) : (
                              <a
                                href={
                                  process.env.REACT_APP_STORAGE_URL +
                                  option.trims_file
                                }
                                className="file_option_miniature mb-3 py-4 px-4 text-center"
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "gray",
                                }}
                              >
                                <FontAwesomeIcon icon={faFilePdf} />
                                {option.trims_file}
                              </a>
                            ))}
                        </div>
                      </Card>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Card
                        title="WASH"
                        headStyle={{
                          textAlign: "center",
                          color: "#00468c",
                          fontWeight: "bold",
                        }}
                        bordered={true}
                        className="rounded"
                        style={{ borderColor: "#dddddd", height: "100%" }}
                      >
                        <p>
                          <strong>Trims: </strong>
                          {option.wash}
                        </p>
                        <p>
                          <strong>Images: </strong>
                        </p>
                        <div className="d-flex mt-3 py-4 text-center flex-row justify-content-start w-100 overflow-auto preview_imgs_wrapper">
                          {option.wash_file !== null &&
                            (option.wash_file.slice(-4) !== ".pdf" ? (
                              <LightBoxImage file={option.wash_file} single />
                            ) : (
                              <a
                                href={
                                  process.env.REACT_APP_STORAGE_URL +
                                  option.wash_file
                                }
                                className="file_option_miniature mb-3 py-4 px-4 text-center"
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "gray",
                                }}
                              >
                                <FontAwesomeIcon icon={faFilePdf} />
                                {option.wash_file}
                              </a>
                            ))}
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Panel>
            ))}
          </Collapse>
        )}
      </div>
    </div>
  );
};

export default DirectOrderOptions;
