import {
  Avatar,
  Button,
  Collapse,
  Modal,
  Popconfirm,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import Folder from "../../icons/Folder";
import Jeans from "../../icons/Jeans";
import { UserOutlined } from "@ant-design/icons";
import OrderServices from "../../actions/order-actions/services";

import { Link } from "react-router-dom";
import "./style.css";
import { useEffect, useState } from "react";
import {
  PlusSquareOutlined,
  PlusOutlined,
  MinusSquareOutlined,
  MinusOutlined,
  FolderOutlined,
  FolderAddOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import { useMutation } from "react-query";
import axiosInstance from "../../config/axios-instance";

const { Panel } = Collapse;

export default function TableRawCompletedDirectOrder({
  item,
  url,
  refetch,
}: any) {
  const [showTable, setShowTable] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [showTr, setShowTr] = useState("");
  const [isModalRestore, setIsModalRestore] = useState(false);
  const [orderToRestore, setOrderToRestore] = useState<any>("");
  const [productToRestore, setProductToRestore] = useState<any>("");

  const genStatusMsg = (status_name: string) => {
    switch (status_name) {
      case "PROTO":
        return {
          color: "#BCBCBD",
          msg: "Your prototype is being prepared",
        };
      case "PRODUCTION":
      case "OK PROD":
        return {
          msg: "Your order is in process",
          color: "#06C170",
        };
      case "CANCELLED":
        return { color: "#FF3A3A", msg: "Your order is canceled" };
      default:
        return {
          color: "#FF872A",
          msg: "We are waiting for your answer",
        };
    }
  };

  const showModalRestore = (product_id: any) => {
    setIsModalRestore(true);

    setProductToRestore(product_id);
  };

  const handleClick = (rowId: any) => {
    // Only set showTable to true for the clicked row
    setShowTr(rowId);
  };

  const handleCancel = () => {
    setIsModalRestore(false);
  };

  const relaunchProduction = () => {
    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/direct_orders/relaunch/${productToRestore}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Relaunched with successfully.", "success");
        setIsModalRestore(false);
        refetch();
      }
    });
    //   setIsModalRestore(false);
  };

  const relaunchPreSMS = () => {
    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/direct_orders/relaunch/presms/${productToRestore}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Relaunched with successfully.", "success");
        setIsModalRestore(false);
        refetch();
      }
    });
    //   setIsModalRestore(false);
  };

  const { mutateAsync: relauchOption, isLoading: relaunchLoading } =
    useMutation(OrderServices.relauchOption, {
      onSuccess: () => {
        MyNotif("Option deleted successfully.", "success");
        refetch();
      },
    });
  console.log(item);
  return (
    <>
      {/*   {item.articles.filter(
        (article: any) =>
          article.article_options && article.article_options.length > 0
      ).length > 0 && (
        <>
          <tr>
            <td
              width={"1%"}
              className={showTable ? "top_article active_tr" : "top_article"}
            >
              <button onClick={() => setShowTable(!showTable)}>
                {showTable ? (
                  <>
                    <MinusSquareOutlined style={{ color: "#00468C" }} />
                  </>
                ) : (
                  <>
                    <PlusSquareOutlined style={{ color: "#00468C" }} />
                  </>
                )}
              </button>
            </td>
            <td className={showTable ? "top_article active_tr" : "top_article"}>
              <span style={{ color: "#8894A1", fontSize: "16px" }}>
                {item.ref}
              </span>
            </td>

            <td
              scope="row"
              className={showTable ? "top_article active_tr" : "top_article"}
            >
              <span>{item.name}</span>
            </td>
            <td className={showTable ? "top_article active_tr" : "top_article"}>
              <p className="mb-0">
                {
                  item.articles.filter(
                    (article: any) =>
                      article.article_options &&
                      article.article_options.length > 0
                  ).length
                }{" "}
                article
                {item.articles.filter(
                  (article: any) =>
                    article.article_options &&
                    article.article_options.length > 0
                ).length > 1
                  ? "s"
                  : ""}
              </p>
            </td>
            <td className={showTable ? "top_article active_tr" : "top_article"}>
              {item.delivery_date
                ? moment(item.delivery_date).format("DD/MM/YYYY")
                : "-"}
            </td>
            <td className={showTable ? "top_article active_tr" : "top_article"}>
              {item.qty ? item.qty : "-"}
            </td>
            <td className={showTable ? "top_article active_tr" : "top_article"}>
              <Avatar.Group
                maxCount={2}
                size="large"
                maxStyle={{ color: "white", backgroundColor: "#7a9ec5" }}
              >
                {item.collaborators.map((collab: any) => (
                  <Tooltip
                    title={collab.name}
                    key={collab.id}
                    placement="bottom"
                  >
                    {collab.logo ? (
                      <Avatar
                        style={{
                          boxShadow: "1px 0px 5px rgba(0,0,0,0.4)",
                        }}
                        src={process.env.REACT_APP_STORAGE_URL + collab.logo}
                      />
                    ) : (
                      <Avatar
                        style={{
                          backgroundColor: "#ddd",
                          boxShadow: "1px 0px 5px rgba(0,0,0,0.2)",
                        }}
                        icon={<UserOutlined style={{ verticalAlign: "2px" }} />}
                      />
                    )}
                  </Tooltip>
                ))}
              </Avatar.Group>
            </td>
          </tr>
        </>
      )}

      {showTable && (
        <td
          colSpan={7}
          style={{
            backgroundColor: "#FCFDFF",
            padding: "0rem",
            borderTop: "none",
          }}
        >
          <table className="tableArticle">
            <tbody>
              {item.articles
                .filter((elm: any) => elm.article_options?.length > 0)
                .map((elm: any) => (
                  <>
                    <tr key={elm.id}>
                      <td
                        width={"1%"}
                        style={{
                          backgroundColor: "#FCFDFF",
                          borderTop: "none",
                        }}
                      >
                        <button onClick={() => setShowOption(!showOption)}>
                          {showOption ? (
                            <>
                              <MinusOutlined style={{ color: "#00468C" }} />
                            </>
                          ) : (
                            <>
                              <PlusOutlined style={{ color: "#00468C" }} />
                            </>
                          )}
                        </button>
                      </td>
                      <td
                        style={{
                          backgroundColor: "#FCFDFF",
                          borderTop: "none",
                        }}
                      >
                        {elm.name}
                      </td>
                    </tr>
                    {showOption && elm.article_options?.length > 0 && (
                      <tr>
                        <td
                          colSpan={6}
                          style={{
                            backgroundColor: "#FCFDFF",
                            padding: "0.2rem",
                            paddingLeft: "3%",
                          }}
                        >
                          <table className="tableArticle">
                            <tr>
                              <th
                                style={{
                                  backgroundColor: "#FCFDFF",
                                  borderTop: "none",
                                }}
                              >
                                Option name
                              </th>

                              <th
                                style={{
                                  backgroundColor: "#FCFDFF",
                                  borderTop: "none",
                                }}
                              >
                                Deleviry date
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#FCFDFF",
                                  borderTop: "none",
                                }}
                              >
                                Quantity
                              </th>
                            </tr>
                            <tbody>
                              {elm.article_options?.map((option: any) => (
                                <>
                                  <tr key={elm.id}>
                                    <td
                                      style={{
                                        backgroundColor: "#FCFDFF",
                                        borderTop: "none",
                                      }}
                                    >
                                      <Link
                                        to={`${url}/${item.id}/${elm.id}/${option.id}/progress`}
                                      >
                                        {option.name}
                                      </Link>
                                    </td>

                                    <td
                                      style={{
                                        backgroundColor: "#FCFDFF",
                                        borderTop: "none",
                                      }}
                                    >
                                      {option.status.pivot.delivery_date
                                        ? moment(
                                            option.status.pivot.delivery_date
                                          ).format("DD-MM-YYYY")
                                        : "-"}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: "#FCFDFF",
                                        borderTop: "none",
                                      }}
                                    >
                                      {option.status.pivot.qty}
                                    </td>

                                    <td
                                      style={{
                                        backgroundColor: "#FCFDFF",
                                        borderTop: "none",
                                      }}
                                    ></td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
            </tbody>
          </table>
        </td>
      )} */}

      {item.articles.filter(
        (article: any) =>
          article.article_options && article.article_options.length > 0
      ).length > 0 && (
        <tr onClick={() => setShowTable(!showTable)}>
          <td
            width={"5%"}
            className={showTable ? "top_article active_tr" : "top_article"}>
            <button onClick={() => setShowTable(!showTable)}>
              {showTable ? (
                <>
                  <FolderOpenOutlined
                    style={{ color: "#00468C", fontSize: "20px" }}
                  />
                </>
              ) : (
                <>
                  <FolderAddOutlined
                    style={{ color: "#00468C", fontSize: "20px" }}
                  />
                </>
              )}
            </button>
          </td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ width: "10%", paddingLeft: 0 }}>
            <span style={{ color: "#8894A1", fontSize: "16px" }}>
              {item.ref}
            </span>
          </td>

          <td
            scope="row"
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ width: "15%", paddingLeft: "0px" }}>
            <span>{item.name}</span>
          </td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: "0px" }}></td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: "0px" }}>
            <p className="mb-0" style={{ marginLeft: "50px" }}>{item.articles.length}</p>
          </td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: "0px" }}></td>

          <td
            className={
              showTable ? "top_article active_tr" : "top_article"
            }></td>
          <td
            className={
              showTable ? "top_article active_tr" : "top_article"
            }></td>
          <td className={showTable ? "top_article active_tr" : "top_article"}>
            <Avatar.Group
              maxCount={2}
              size="large"
              maxStyle={{ color: "white", backgroundColor: "#7a9ec5" }}>
              {item.collaborators.map((collab: any) => (
                <Tooltip title={collab.name} key={collab.id} placement="bottom">
                  {collab.logo ? (
                    <Avatar
                      style={{
                        boxShadow: "1px 0px 5px rgba(0,0,0,0.4)",
                      }}
                      src={process.env.REACT_APP_STORAGE_URL + collab.logo}
                    />
                  ) : (
                    <Avatar
                      style={{
                        backgroundColor: "#ddd",
                        boxShadow: "1px 0px 5px rgba(0,0,0,0.2)",
                      }}
                      icon={<UserOutlined style={{ verticalAlign: "2px" }} />}
                    />
                  )}
                </Tooltip>
              ))}
            </Avatar.Group>
          </td>
        </tr>
      )}
      {showTable && (
        <>
          <td
            colSpan={10}
            style={{
              backgroundColor: "#FCFDFF",
              padding: "0rem",
              borderTop: "none",
            }}>
            <div className="scrollDiv">
              <table className="tableArticle" style={{ width: "100%" }}>
                {item.articles
                  .filter((elm: any) => elm.article_options?.length > 0)
                  .map((elm: any) => (
                    <>
                      <tr
                        key={elm.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleClick(elm.id);
                          setShowOption(!showOption);
                        }}>
                        <td
                          width={"5%"}
                          style={{
                            backgroundColor: "#FCFDFF",
                            borderTop: "none",
                          }}>
                          <button
                            onClick={() => {
                              handleClick(elm.id);
                              setShowOption(!showOption);
                            }}>
                            {showOption && showTr == elm.id ? (
                              <>
                                <FolderOpenOutlined
                                  style={{
                                    color: "#00468C",
                                    fontSize: "20px",
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <FolderAddOutlined
                                  style={{
                                    color: "#00468C",
                                    fontSize: "20px",
                                  }}
                                />
                              </>
                            )}
                          </button>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#FCFDFF",
                            borderTop: "none",
                            paddingLeft: 0,
                          }}>
                          <span>{elm.name}</span>
                        </td>
                      </tr>
                      {showOption &&
                        showTr == elm.id &&
                        elm.article_options?.length > 0 && (
                          <tr>
                            <td
                              colSpan={10}
                              style={{
                                backgroundColor: "#FCFDFF",
                                padding: "0.2rem",
                                paddingLeft: "4%",
                              }}>
                             {/*  <table className="tableArticle">
                                {elm.article_options?.map((option: any) => (
                                  <>
                                    <tr>
                                      <td
                                        style={{
                                          //   width: "50%",
                                          paddingLeft: "20px",
                                          borderTop: "none",
                                        }}
                                        className="details_td_direct_completed">
                                        {option.name}
                                      </td>

                                      <td
                                        style={{
                                          //   width: "5%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}></td>
                                      <td
                                        style={{
                                          //    width: "21%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}
                                        className="details_td_direct_delivery_date_completed">
                                        {option.status.pivot.delivery_date
                                          ? moment(
                                              option.status.pivot.delivery_date
                                            ).format("DD/MM/YYYY")
                                          : "-"}
                                      </td>
                                      <td
                                        style={{
                                          //  width: "8%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}
                                        className="details_td_direct_qty_completed">
                                        {option.status.pivot.qty}
                                      </td>

                                      <td
                                        style={{
                                          // width: "22%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}>
                                        <div>
                                          <Link
                                            to={`${url}/${item.id}/${elm.id}/${option.id}/progress`}>
                                            View History
                                          </Link>

                                          <Button
                                            size={"middle"}
                                            style={{ borderRadius: "4px" }}
                                            loading={relaunchLoading}
                                            onClick={() =>
                                              showModalRestore(option.id)
                                            }>
                                            Relaunch
                                          </Button>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </table> */}
                              {elm.article_options?.map((option: any) => (
                                <>
                                 
                                    <div className="row row_option_name_completed">
                                      <div className="option_name_completed_direct_order">
                                        {option.name}
                                      </div>
                                      <div className="deleviry_date_completed_direct_order">
                                        {" "}
                                        {option?.status?.pivot?.delivery_date
                                          ? moment(
                                              option?.status?.pivot
                                                ?.delivery_date
                                            ).format("DD/MM/YYYY")
                                          : "-"}
                                      </div>

                                      <div className="qty_completed_direct_order">
                                      {option.status.pivot.qty}
                                      </div>

                                      <div >
                                     {/*  <Link
                                            to={`${url}/${item.id}/${elm.id}/${option.id}/progress`}>
                                            View History
                                          </Link>

                                          <Button
                                            size={"middle"}
                                            style={{ borderRadius: "4px" }}
                                            loading={relaunchLoading}
                                            onClick={() =>
                                              showModalRestore(option.id)
                                            }>
                                            Relaunch
                                          </Button> */}

                                          <button  className="relaunch"  onClick={() =>
                                              showModalRestore(option.id)
                                            }>Relaunch</button>

<Link className="view_history"
                                            to={`${url}/${item.id}/${elm.id}/${option.id}/progress`}>
                                            View History
                                          </Link>
                                      </div>
                                    </div>
                                  
                                </>
                              ))}
                            </td>
                          </tr>
                        )}
                    </>
                  ))}
              </table>
            </div>
          </td>

          <Modal
            title="Relaunch production"
            visible={isModalRestore}
            onCancel={handleCancel}
            footer={[
              <Button
                className="restore_2"
                //   loading={loading}
                onClick={relaunchPreSMS}>
                Start again from PRE SMS
              </Button>,
              <Button
                className="restore"
                //  loading={loading}
                onClick={relaunchProduction}>
                Straight to PRODUCTION
              </Button>,
            ]}>
            <p>In what stage would you like to relaunch the production? </p>
          </Modal>
        </>
      )}

      {/*  <tr>
        <td width={"20%"}>
          <ul id="myUL">
            <li>
              <span
                className="caret"
                style={{ color: "#8894A1", fontSize: "16px" }}
              >
                {item.ref}
              </span>

              {item.articles && item.articles.length > 0 ? (
                <>
                  {" "}
                  <ul className="nested">
                    {item.articles.map((elm: any) => (
                      <li
                        style={{
                          fontSize: "14px",
                          marginLeft: "0px",
                        }}
                      >
                        <span className="caret">{elm.name}</span>
                        <ul className="nested">
                          {elm.options.map((option: any) => (
                            <>
                              <li
                                style={{
                                  fontSize: "14px",
                                  marginLeft: "10px",
                                  marginTop: "5px",
                                }}
                              >
                                <table className="border-details">
                                  <tr>
                                    <td>
                                      <Link
                                        to={`${url}/${item.id}/${elm.id}/${option.id}/progress`}
                                      >
                                        {option.name}
                                      </Link>
                                    </td>
                                    <td>
                                      <Tag
                                        color="processing"
                                        style={{
                                          borderRadius: "50px",
                                          cursor: "pointer",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {option.status.name}
                                      </Tag>
                                    </td>
                                  </tr>
                                </table>
                              </li>
                            </>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  {" "}
                  <ul className="nested" style={{ color: "#7C7C7C" }}>
                    No Articles
                  </ul>
                  <></>
                </>
              )}
            </li>
          </ul>
        </td>

        <td scope="row" className="top_article">
          <span>{item.name}</span>
        </td>
        <td className="top_article">
          {moment(item.updated_at).format("DD/MM/YYYY")}
        </td>
        <td className="top_article">
          <Avatar.Group
            maxCount={2}
            size="large"
            maxStyle={{ color: "white", backgroundColor: "#7a9ec5" }}
          >
            {item.collaborators.map((collab: any) => (
              <Tooltip title={collab.name} key={collab.id} placement="bottom">
                {collab.logo ? (
                  <Avatar
                    style={{
                      boxShadow: "1px 0px 5px rgba(0,0,0,0.4)",
                    }}
                    src={process.env.REACT_APP_STORAGE_URL + collab.logo}
                  />
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: "#ddd",
                      boxShadow: "1px 0px 5px rgba(0,0,0,0.2)",
                    }}
                    icon={<UserOutlined style={{ verticalAlign: "2px" }} />}
                  />
                )}
              </Tooltip>
            ))}
          </Avatar.Group>
        </td>
        <td className="top_article">
          <p className="mb-0">
            {item.articles.length} article{item.articles.length > 1 ? "s" : ""}
          </p>
        </td>
      </tr> */}
    </>
  );
}
