import { Button } from "antd";
import WashCollapse from "./WashCollapse";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const EditKnitWash = ({
  productToUpdate,
  genExtraWash,
  setProductToUpdate,
  data,
  setData,
  options,
  handleChangeMultiWash,
  handleChange,
  addNewWash,
}: any) => {
  const { user } = useSelector((state: any) => state.auth);
  return (
    <div>
      {productToUpdate.washes.length === 0 ? (
        <h6 className="text-center p-2 mt-2 text-muted product_doesnt_have">
          This product doesn't have wash
        </h6>
      ) : (
        productToUpdate.washes.map((el: any, i: number) => (
          <WashCollapse
            genExtra={genExtraWash}
            i={i}
            el={el}
            setProductToUpdate={setProductToUpdate}
            productToUpdate={productToUpdate}
            data={data}
            setData={setData}
            options={options}
            handleChangeMultiWash={handleChangeMultiWash}
            key={i}
            handleChange={handleChange}
          />
        ))
      )}
      <Button
        type="primary"
        disabled={
          !user.permissions.includes("product.wash.management") &&
          !user.permissions.includes("product.update")
        }
        icon={<PlusOutlined style={{ verticalAlign: 0 }} />}
        className="add_new_one"
        onClick={addNewWash}
      >
        Add Another Wash
      </Button>
    </div>
  );
};

export default EditKnitWash;
