import { Reducer, AnyAction } from "redux";
import {
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOAD_USER_SUCCESS,
} from "../actions/auth-actions/types";
import {
  NEXT_STEP,
  PREV_STEP,
  FINISH,
} from "../actions/guided-tour-actions/types";

const init_state: any = {
  step: 1,
  guided_tour: false,
};

const GuidedTourReducer: Reducer<any, AnyAction> = (
  state: any = init_state,
  action
): boolean => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        step: 1,
        guided_tour: action.payload.client
          ? action.payload.client.last_login
            ? false
            : true
          : false,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        step: 1,
        guided_tour: action.payload.last_login ? false : true,
      };
    case LOGOUT_USER:
      return {
        ...state,
        step: 1,
        guided_tour: false,
      };
    case NEXT_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case PREV_STEP:
      return {
        ...state,
        step: state.step - 1,
      };
    default:
      return state;
  }
};

export default GuidedTourReducer;
