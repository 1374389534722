function Close() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <path
        fill="#182844"
        d="M9.091-9l4.809-4.807.991-.991a.375.375 0 000-.53l-1.062-1.062a.375.375 0 00-.53 0l-5.8 5.8-5.8-5.8a.375.375 0 00-.53 0L.11-15.33a.375.375 0 000 .53L5.909-9 .11-3.2a.375.375 0 000 .53l1.06 1.06a.375.375 0 00.53 0l5.8-5.8 4.807 4.81.991.991a.375.375 0 00.53 0l1.062-1.062a.375.375 0 000-.53z"
        data-name="Tracé 3309"
        transform="translate(0 16.5)"
      ></path>
    </svg>
  );
}

export default Close;
