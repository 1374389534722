import { useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Home } from "./pages/Home";
import { Navbar } from "./components/Navbar/Navbar";
import Login from "./pages/Login";
import { CreativeStation } from "./pages/CreativeStation";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import { Products } from "./pages/Products";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "./components/Search/Search";
import { SearchResult } from "./pages/SearchResult";
import AccountInfos from "./pages/AccountInfos";
import { TechPackCutsom } from "./pages/my orders/TechPackCutsom";
import MyCollection from "./pages/MyCollection";
import { NotifSettings } from "./pages/NotifSettings";
import { NotifSettingsAdmin } from "./pages/NotifSettingsAdmin";

import { DirectOrder } from "./pages/DirectOrder";
import { AddNewOrder } from "./pages/AddNewOrder";
import { EditOrder } from "./pages/EditOrder";
import { MyOrders } from "./pages/MyOrders";
import { MyOrderDetails } from "./pages/MyOrderDetails";
import { MyOrderConfirmation } from "./pages/MyOrderConfirmation";
import TeamManagement from "./components/TeamManagement/TeamManagement";
import { Notifications } from "./pages/Notifications";
import ProductView from "./components/product-view/ProductView";
import { SinglePost } from "./pages/SinglePost";
import { ForYou } from "./components/ForYou/ForYou";
import { ForYouUser } from "./components/ForYou/ForYouUser";

import { BookingsPage } from "./pages/BookingsPage";
import { CollectionProducts } from "./components/CollectionProducts/CollectionProducts";
import { NewProducts } from "./components/NewProducts/NewProducts";
import { pushNewNotification } from "./actions/notifications-actions/actions";
import moment from "moment";
import { MyCustomizedOrderConfirmation } from "./pages/MyCustomizedOrderConfirmation";
import { AddCreativeStation } from "./pages/AddCreativeStation";
import AllClients from "./pages/AllClients";
import AllCompany from "./pages/AllCompany";

import { QueryClient, QueryClientProvider } from "react-query";
import Client from "./components/AllClients/Client";
import OrderOverview from "./components/AllClients/OrderOverview";
import DirectOrderOptions from "./components/AllClients/DirectOrderOptions";
import OrderProgress from "./components/AllClients/OrderProgress";
import NormalOrderProgress from "./components/AllClients/NormalOrderProgress";
import CustomizedProgress from "./components/AllClients/CustomizedProgress";
import DraftCreativeStation from "./pages/DraftCreativeStation";
import Bookings from "./pages/Bookings";
import Library from "./pages/Library";
import LibraryItem from "./pages/LibraryItem";
import { OptionsTable } from "./components/MyOrders/OptionsTable";
import { OptionConfirmation } from "./pages/OptionConfirmation";
import TagResult from "./pages/TagResult";
import MyOrders2 from "./pages/MyOrders2";
import MyCollection2 from "./pages/MyCollection2";
import Pusher from "pusher-js";
import { loadConnectedUser } from "./actions/auth-actions/actions";
import { ProductsResponsible } from "./pages/ProductsResponsible";
import { RepsonsibleChoice } from "./pages/RepsonsibleChoice";

import AllCompanies from "./pages/AllCompanies";
import Moodboard from "./components/AllClients/Moodboard";
import ListProducts from "./components/AllClients/ListProducts";
import AddNewProduct from "./components/AllClients/AddNewProduct";

import MoodboardProducts from "./components/AllClients/MoodboardProducts";
import { Faq } from "./pages/Faq";
import { FaqQuestion } from "./pages/FaqQuestion";
import TeamManagementAdmin from "./components/TeamManagement/TeamManagementAdmin";
import AllCompaniesForYou from "./pages/AllCompaniesForYou";
import { ChatButton } from "./components/ChatBot/ChatButton";

import { Report } from "./pages/Report";
import Customizationv2 from "./components/product-view/Customizationv2";
import {MixProducts} from "./components/MixProducts/MixProducts";
// import Echo from "laravel-echo";

const App = () => {
  const [scrolled, setScrolled] = useState(false);
  const { searchScreen } = useSelector((state: any) => state.search);
  const { isAdmin, user, token } = useSelector((state: any) => state.auth);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let scrolling: any = window.addEventListener("scroll", () => {
      let headerHight = document.querySelector("header")?.offsetHeight || 0;
      const isTop = window.scrollY;
      if (isTop < headerHight) {
        setScrolled(false);
      } else {
        setScrolled(true);
      }
    });

    return () => {
      window.removeEventListener("scroll", scrolling);
    };
  }, []);

  useEffect(() => {
    if (searchScreen) {
      document.body.style.overflow = "hidden";
    }
  }, [searchScreen]);

  useEffect(function mount() {
    /*  if (user && token) {
      //@ts-ignore
      window.$crisp = [];
      //@ts-ignore
      window.CRISP_WEBSITE_ID = "12c84c38-f488-46cb-9542-bff7416fff49";
      (function () {
        var d = document;
        var s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        //@ts-ignore
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    }*/
  });

  // const queryClient = new QueryClient()

  useEffect(() => {
    console.log(user);
    if (user && token) {
      //a6c3499d61d7ce61a2e5
      var pusher = new Pusher("a6c3499d61d7ce61a2e5", {
        // authEndpoint: "http://127.0.0.1:8000/api/broadcasting/auth",
        authEndpoint: process.env.REACT_APP_API_URL + "/broadcasting/auth",
        cluster: "eu",
        forceTLS: true,
        auth: {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      });
      if (isAdmin) {
        var privateChannel = pusher.subscribe(
          `private-App.Models.User.${user.id}`
        );
        privateChannel.bind(
          "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
          function (notification: any) {
            const _notification = notification[0];
            _notification.data = JSON.parse(_notification.data);
            console.log(_notification.data);
            dispatch(
              pushNewNotification({
                ..._notification,
                created_at: moment(_notification.created_at).add(1, "hours"),
              })
            );
            dispatch(loadConnectedUser(true));
            queryClient.invalidateQueries("notifications");
          }
        );
      } else {
        var privateChannel = pusher.subscribe(`private-clients.${user.id}`);
        privateChannel.bind(
          "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
          function (notification: any) {
            const _notification = notification[0];
            _notification.data = JSON.parse(_notification.data);
            console.log(_notification.data);
            dispatch(
              pushNewNotification({
                ..._notification,
                created_at: moment(_notification.created_at).add(1, "hours"),
              })
            );
            dispatch(loadConnectedUser(true));
            queryClient.invalidateQueries("notifications");
          }
        );
      }
    }
  }, [token]);

  // useEffect(() => {
  //   if (user && token) {
  //     (window as any).Echo.options.auth.Authorization = "Bearer " + token;
  //     (window as any).Echo.private(
  //       `${isAdmin ? "App.Models.User" : "clients"}.${user.id}`
  //     ).notification((notification: any) => {
  //       console.log({ notification });
  //       const _notification = notification[0];
  //       _notification.data = JSON.parse(_notification.data);
  //       dispatch(
  //         pushNewNotification({
  //           ..._notification,
  //           created_at: moment(_notification.created_at).add(1, "hours"),
  //         })
  //       );
  //     });
  //   }
  // }, [user, token]);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {pathname === "/home" && !searchScreen ? (
        <Navbar top={scrolled} />
      ) : (
        pathname !== "/" && !searchScreen && <Navbar top={true} />
      )}
      {searchScreen && <Search />}
      <div>
        <Switch>
          {/* <QueryClientProvider client={queryClient}> */}
          <Route exact path="/" component={Login} />
          <PrivateRoute path="/home" component={Home} />
          <PrivateRoute exact path="/products" component={TagResult} />
          <PrivateRoute
            exact
            path="/search/:keyword"
            component={SearchResult}
          />
          <PrivateRoute
            exact
            path="/new/:productName/:productId"
            component={ProductDetails}
          />
          <PrivateRoute
            path="/search/result/:keyword/:productName/:productId"
            component={ProductDetails}
          />
          <PrivateRoute path="/account" component={AccountInfos} />
          <PrivateRoute
            path="/techpackcutom/:product_id"
            component={TechPackCutsom}
          />

          <PrivateRoute path="/myCollection" component={MyCollection} />
          <PrivateRoute path="/myCollection2" component={MyCollection2} />
          <PrivateRoute path="/notif-settings" component={NotifSettings} />
          <PrivateRoute
            path="/notif-settings-admin"
            component={NotifSettingsAdmin}
          />
          <PrivateRoute path="/notifications" component={Notifications} />
          <PrivateRoute
            exact
            path="/product/details/:productName/:productId"
            component={ProductDetails}
          />
          <PrivateRoute
            exact
            path="/product/details/:category/:gendre/:style/:productName/:productId"
            component={ProductDetails}
          />
          <PrivateRoute
            exact
            path="/product/MySelection/:productId"
            component={ProductDetails}
          />
          <PrivateRoute
            exact
            path="/product/:fabric/:gendre/:genderId/:category/:categoryId"
            component={Products}
          />
          <PrivateRoute
            exact
            path="/responsible/:id/:fabric?/:name?"
            component={ProductsResponsible}
          />

          <PrivateRoute
            exact
            path="/responsible"
            component={RepsonsibleChoice}
          />
          <PrivateRoute exact path="/faq" component={Faq} />
          <PrivateRoute exact path="/faq/:id" component={FaqQuestion} />

          {/*report*/}
          <PrivateRoute exact path="/report" component={Report} />
          <PrivateRoute
            exact
            path="/collection/:collectionId/:fabric?"
            component={CollectionProducts}
          />
          <PrivateRoute exact path="/new/:fabric?" component={NewProducts} />
          <PrivateRoute exact path="/products/:fabric?" component={MixProducts} />
          <PrivateRoute
            path={
              // user && user.fabric_type
              //   ? `/for-you/:${user.fabric_type}?`
              //   :
              "/for-you/:fabric?"
            }
            component={ForYou}
          />
          <PrivateRoute exact path="/library" component={Library} />
          <PrivateRoute exact path="/library/:id" component={LibraryItem} />
          <PrivateRoute path="/edit-order/:id" component={EditOrder} />
          {/* <PrivateRoute
              exact
              path="/my-orders/:id"
              component={MyOrderDetails}
            />
            <PrivateRoute
              exact
              path="/my-orders/original-product/:id"
              component={MyOrderDetails}
            />
            <PrivateRoute
              exact
              path="/my-orders/customized-product/:productId"
              component={MyCustomizedOrderConfirmation}
            />
            <PrivateRoute
              exact
              path="/my-orders/original-product/:id/:articleId"
              component={MyOrderConfirmation}
            />
            <PrivateRoute
              exact
              path="/my-orders/direct-order/:id/:articleId"
              component={OptionsTable}
            />
            <PrivateRoute
              path="/my-orders/direct-order/:id/:articleId/:optionId"
              component={OptionConfirmation}
            /> */}
          <PrivateRoute path="/my-orders2" component={MyOrders} />
          <PrivateRoute path="/my-orders" component={MyOrders2} />

          <PrivateRoute path="/direct-order" component={DirectOrder} />
          <PrivateRoute path="/new-order" component={AddNewOrder} />
          <PrivateRoute
            exact
            path="/creative-station"
            component={CreativeStation}
          />
          <PrivateRoute
            path="/creative-station/draft"
            component={DraftCreativeStation}
          />
          <PrivateRoute
            path="/add-creative-station"
            component={AddCreativeStation}
          />
          <PrivateRoute path="/team-management" component={TeamManagement} />
          <PrivateRoute
            path="/customization/:status/:productId"
            component={ProductView}
          />
          <PrivateRoute
            path="/customization-v2/:status/:productId"
            component={Customizationv2}
          />
          <PrivateRoute
            exact
            path="/single-post/:post_id"
            component={SinglePost}
          />
          <PrivateRoute
            path="/single-post/:post_id/update"
            component={AddCreativeStation}
          />
          {/* <PrivateRoute path="/bookings" component={MyComponent} /> */}
          <PrivateRoute path="/bookings" component={Bookings} />

          <PrivateRoute exact path="/all-clients" component={AllClients} />
          <PrivateRoute exact path="/all-company" component={AllCompany} />
          <PrivateRoute
            exact
            path="/teamcompany/:id"
            component={TeamManagementAdmin}
          />

          <PrivateRoute
            exact
            path="/foryou-all-companies"
            component={AllCompanies}
          />
          <PrivateRoute
            exact
            path="/foryou-all-users"
            component={AllCompaniesForYou}
          />
          <PrivateRoute
            exact
            path="/all-clients/:id/:tab_key"
            component={Client}
          />
          <PrivateRoute
            exact
            path="/foryou-all-companies/:id/:tab_key"
            component={Moodboard}
          />
          <PrivateRoute exact path="/list-products" component={ListProducts} />
          <PrivateRoute
            exact
            path="/add-new-product"
            component={AddNewProduct}
          />

          <PrivateRoute
            exact
            path="/all-clients/:id/:tab_key/:order_id"
            component={OrderOverview}
          />
          <PrivateRoute
            exact
            path="/all-clients/:id/:tab_key/:order_id/:article_id"
            component={DirectOrderOptions}
          />
          <PrivateRoute
            exact
            path="/foryou-all-companies/:id/:tab_key/:moodboard_id/"
            component={MoodboardProducts}
          />
          <PrivateRoute
            exact
            path="/foryou-all-users/:id/:fabric?"
            component={ForYouUser}
          />
          <PrivateRoute
            exact
            path="/all-clients/:id/:tab_key/:order_id/:article_id/:option_id/progress"
            component={OrderProgress}
          />
          <PrivateRoute
            exact
            path="/all-clients/:id/:tab_key/:order_id/:product_id/progress/order"
            component={NormalOrderProgress}
          />
          <PrivateRoute
            exact
            path="/all-clients/:id/:tab_key/:order_id/:product_id/progress/customized"
            // path="/all-clients/:id/:tab_key/:product_id/progress/customized"
            component={CustomizedProgress}
          />

          {/* </QueryClientProvider> */}
        </Switch>
      </div>
      {user && <ChatButton />}
    </QueryClientProvider>
  );
};

export default App;
