import axiosInstance from "../../config/axios-instance";

//  Get Notifications
const getNotification = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/notifications`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
//  Get Notifications admin
const getNotificationAdmin = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/admin/notifications`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Load More Notifications
const loadMoreNotifications = (url: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Load More Notifications
const getMyNotifSettings = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: "/notifications/settings",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Load More Notifications
const getMyNotifSettingsAdmin = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: "/admin/notifications/settings",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Activate / Deactivate All Notifications
const changeStatusNotifSettings = (settings: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: "/notifications/settings/group",
    data: {
      group: settings,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Change one Setting Status
const changeOneSettingStatus = (notif_name: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/notifications/settings/${notif_name}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Change one Setting Status
const changeOneSettingStatusAdmin = (notif_name: string) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/admin/notifications/settings/${notif_name}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
const NotificationsServices = {
  getNotification,
  getNotificationAdmin,
  loadMoreNotifications,
  getMyNotifSettings,
  getMyNotifSettingsAdmin,
  changeStatusNotifSettings,
  changeOneSettingStatus,
  changeOneSettingStatusAdmin,
};

export default NotificationsServices;
