import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import "./Footer.scss";
import { FooterData, FooterData2 } from "../../StaticData/FooterData";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
export interface IFooterProps {}

export default function Footer(props: IFooterProps) {
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const [containsGirls, setContainsGirls] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const containsGirls =
      user &&
      !isAdmin &&
      user.genders.find(
        (gender: any) =>
          gender.id === 6 || gender.name.toLowerCase() === "girls"
      );
    setContainsGirls(containsGirls);
  });

  const FooterData = [
    {
      title: "Top searches",
      navItems: [
        {
          navItemValue: "Edesigner x 3D CLO",
          link:"/collection/78/denim"
        },
        {
          navItemValue: "Our Responsible Choices ",
          link:"/responsible",
        },
        {
          navItemValue: "Creative Station",
          link: user && !isAdmin ? "/creative-station" : "#",
        },
        // do not delete
        // {
        //   navItemValue: "Get Inspired",
        //   link: "/creative-station",
        // },
      ],
    },
    {
      title: "Contact Us",
      navItems: [
        {
          navItemValue: "BP47 ZI Moknine",
          link: "",
        },
        {
          navItemValue: "5050 Monastir, Tunisia",
          link: "",
        },
        {
          navItemValue: "+216 73 248 100",
          link: "",
        },
        {
          navItemValue: "IDD@demcointer.com",
          link: "",
        },
      ],
    },

  ];

  const FooterData2 = {
    title: "Menu",
    navItems1: [
      {
        navItemValue: "Women",
        link: "/product/denim/Women/2/JEANS/1",
      },
      {
        navItemValue: "Men",
        link: `/product/denim/Men/1/JEANS/1`,
      },
      {
        navItemValue: "Unisex",
        link: "/product/denim/Unisex/3/JEANS/1",
      },
   /*   {
        navItemValue: "Kids",
        link: containsGirls
          ? "/product/denim/Girls/6/JEANS/1"
          : "/product/denim/Boys/5/JEANS/1",
      },

      {
        navItemValue: "Seasons",
        link: "/collection/25/denim",
      },*/

    ],
    navItems2: [
      {
        navItemValue: "New",
        link: "",
      },
      {
        navItemValue: "Special Collections",
        link: "",
      },
      {
        navItemValue: "Collection",
        link: "",
      },
    ],
  };

  const links = [
    {
      name: "Kids",
      link: containsGirls
          ? "/product/denim/Girls/6/JEANS/1"
          : "/product/denim/Boys/5/JEANS/1",
    },
    {
      name: "Seasons",
      link: "/collection/25/denim",
    },

  ];

  return (
    <div className="footer">
      <div className="footer_nav">
        <div className="nav_item_container nav_item_container_menu">
          <div className="title_container">
            <div className="white_line"></div>
            <h3 className="">{FooterData2.title}</h3>
          </div>
          <div className="menu_nav_item">
            <div className="nav_items menu-footer-responsive">
              {FooterData2.navItems1.map((navItem, i) => (
                <Link to={navItem.link} key={i} className="link_footer">
                  <p className="nav_item">{navItem.navItemValue}</p>
                </Link>
              ))}
            </div>
            <div className="nav_items mmenu_nav_items_second menu-footer-responsive">
              {links.map((navItem, i) => (
                <Link to={navItem.link} key={i} className="link_footer">
                  <p className="nav_item">{navItem.name}</p>
                </Link>
              ))}
            </div>
          </div>
        </div>
        {FooterData.map((el, i) => (
          <div
            className={
              el.title === "CONTACT Us"
                ? "nav_item_container nav_item_container_contact"
                : "nav_item_container "
            }
            key={i}
          >
            <div className="title_container">
              <div className="white_line"></div>
              <h3>{el.title}</h3>
            </div>
            <div className="nav_items">
              {el.navItems.map((navItem, i) =>
                navItem.link ? (
                  <Link to={navItem.link} key={i} className="link_footer">
                    {" "}
                    <p className="nav_item" key={i}>
                      {navItem.navItemValue}
                    </p>
                  </Link>
                ) : (
                  <p className="nav_item" key={i}>
                    {navItem.navItemValue}
                  </p>
                )
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="footer_all_right_reserved">
        <p className="reserved">© {currentYear} DEMCO - All Rights Reserved.</p>
      {/*  <p>
          Made with <FontAwesomeIcon icon={faHeart} color="#4384FF" />
          <b>
            {" "}
            by{" "}
            <a
              href="https://www.moon.tn/"
              target="_blank"
              className="moon_link"
            >
              Weare Moon Agency
            </a>
          </b>
        </p>*/}
      </div>
    </div>
  );
}
