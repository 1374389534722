import { AnimatePresence, motion } from "framer-motion";
//@ts-ignore
import PincodeInput from "react-pinco";
import { Button } from "../Button/Button";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import AuthServices from "../../actions/auth-actions/services";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

interface Props {
  handleChangeCode: (e: any) => void;
  setReset: (arg: any) => void;
  code: any;
  email: string;
}

export const CodePassword = ({
  handleChangeCode,
  code,
  setReset,
  email,
}: Props) => {
  const [time, setTime] = useState(0);
  const [minutes, setMinutes] = useState(30);
  const [seconds, setSeconds] = useState(0);
  const [error, setError] = useState<any>(false);
  const emailPattern = /^([a-z\d\.-]{3,})@([a-z]+)\.([a-z]{2,6})$/;

  const { mutateAsync: sendResetCode, isLoading: sendResetCodeLoading } =
    useMutation(AuthServices.confirmCode, {
      onError: (err: any) => {
        setError(true);
        MyNotif(err.response.data.error, "danger");
      },
      onSuccess: () => {
        setError(false);
        setReset("reset password");
        handleChangeCode({ otp: [] });
      },
    });

  const { mutateAsync: sendEmailReset, isLoading: sendEmailResetLoading } =
    useMutation(AuthServices.emailReset, {
      onError: (err: any) => {
        setError("Please put a valid email address");
        MyNotif(err.response.data.error, "danger");
      },
      onSuccess: () => {
        MyNotif("Code has been sent to this email: " + email, "success");
        setError("");
        setMinutes(30);
        setSeconds(0);
      },
    });

  const sendEmail = () => {
    if (email && emailPattern.test(email)) {
      sendEmailReset(email);
    } else {
      setError("Please put a valid email address");
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds, minutes]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      var timeLs = localStorage.getItem("date");
      setTime(Date.now() - Number(timeLs));
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("date", (Date.now() + 60 * 60 * 1000).toString());
  }, []);

  return (
    <AnimatePresence initial={true} exitBeforeEnter={true}>
      <div style={{ width: "100%" }}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <h2 className="h2_subtitle_blue h2_subtitle_blue_reset">
            Verify Your Email
          </h2>
          <p className="paragraphe parag_code">
            Please enter the 4 digit code sent to your email{" "}
            <span className="email_code">{email}</span>
          </p>
          <form className="login_form_forgot_password">
            {/* @ts-ignore */}
            <PincodeInput
              onChange={handleChangeCode}
              numInputs={4}
              containerStyle="pin_code_container"
              inputStyle={
                error ? "pin_code_input pin_code_input_error" : "pin_code_input"
              }
              initialValue={code}
              shouldAutoFocus={true}
            />
          </form>
          <p className="expire_text">
            Code expires in :{" "}
            <span className="time_expiration">
              {" "}
              {minutes.toString().padStart(2, "0")}:
              {seconds.toString().padStart(2, "0")}{" "}
            </span>
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="d-flex flex-column"
        >
          <p className="resend_question">
            Didn't get the code :{" "}
            {sendEmailResetLoading ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              <span className="resend_text" onClick={sendEmail}>
                Resend Code
              </span>
            )}
          </p>
          <Button
            buttonClass="submit_button_blue submit_button_white_full_width verif_button"
            innerButton={
              sendResetCodeLoading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                "Confirm"
              )
            }
            onClick={() => sendResetCode(code.otp.join(""))}
          />
        </motion.div>
      </div>
    </AnimatePresence>
  );
};
