import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Drawer,
  Input,
  List,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Tag,
  Tooltip,
} from "antd";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getOption, getSingleArticle } from "../../actions/my-orders/services";
import {DownloadOutlined, LoadingOutlined} from "@ant-design/icons";
import proto_illustration from "../../img/Prototype_2.png";
import backArrow from "../../img/backArrow.png";
import { history } from "../..";
import OrderServices from "../../actions/order-actions/services";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextEditor } from "../../components/TextEditor/TextEditor";
//@ts-ignore
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { Collapse } from "antd";
import {
  faCaretDown,
  faFilePdf,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import logo360 from "../../img/360.png";

import {
  CheckOutlined,
  MinusOutlined,
  PlusOutlined,
  CloseOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { CancelOrderDrawer } from "../../components/MyOrderConfirmation/CancelOrderDrawer";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  approveOptionAndComment,
  rejectOrAcceptProto,
} from "../../actions/order-actions/actions";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import "../../sass/MyOrderDetails.scss";
import { FileLightBox } from "../../components/OrderDetails/FileLightBox";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import axiosInstance from "../../config/axios-instance";
import { borderRadius } from "react-select/src/theme";
import icon3D from "../../img/3d_icon.svg";

const { Step } = Steps;
const { Option } = Select;
const { Panel } = Collapse;
export default function DirectOrderProgress() {
  const [isOwner, setIsOwner] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [size_qty, setSizeQty] = useState("");
  const [delivery_date, setDeliviry_date] = useState("");
  const [startDate, setStartDate] = useState("");
  const [is360, set360] = useState(false);

  const [comment, setComment] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [notApproveIsVisible, setNotApproveIsVisible] = useState(false);
  const [approved, setApproved] = useState(false);
  const width = useBreakpoint();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [matrix, setmatrix] = useState([]);
  const [isOpened, setIsOpened] = useState(false);

  const [isEditableFabric, setIsEditableFabric] = useState(false);
  const [detailsFabric, setDetailsFabric] = useState("");
  const [isEditableStyle, setIsEditableStyle] = useState(false);
  const [detailsStyle, setDetailsStyle] = useState("");
  const [width2, setWidth] = useState(window.innerWidth);

  const toggleEditFabric = () => {
    setIsEditableFabric((prevIsEditable) => !prevIsEditable);
  };

  const setDetaisFabric = (e: any) => {
    setDetailsFabric(e.target.value);
  };

  const toggleEditStyle = () => {
    setIsEditableStyle((prevIsEditable) => !prevIsEditable);
  };

  const setDetaisStyle = (e: any) => {
    setDetailsStyle(e.target.value);
  };

  function edit() {
    setIsOpened((wasOpened) => !wasOpened);
  }
  const showDrawer = () => {
    setOpen(true);
  };

  const showDrawer2 = () => {
    setOpen2(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen2(false);
  };

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width2]);

  const { user } = useSelector((state: any) => state.auth);

  const {
    id: order_id,
    article_id,
    option_id,
  } = useParams<{ id: string; article_id: string; option_id: string }>();

  const {
    data: option,
    isLoading: loadingOption,
    refetch,
  } = useQuery(`option-${option_id}`, () => getOption(option_id));
  const { data: article, isLoading: loadingArticle } = useQuery(
    `article-${article_id}`,
    () => getSingleArticle(article_id)
  );
  const { data: order, isLoading: loadingOrder } = useQuery(
    `order-${order_id}`,
    () => OrderServices.getOneOrder(order_id)
  );
  /*   console.log("option" + JSON.stringify(option?.data.status.pivot.size_qty));
   */ const [step, setSetp] = useState(option?.data.status.id);
  const [arr, setarry] = useState(["", "", ""]);
  const [inputs, setInputs] = useState(["", "", ""]);
  // const arr = ["", "", ""];

  useEffect(() => {
    if (option) {
      const initialInputs = [];
      var array = JSON.parse(option?.data.status.pivot.size_qty);
      if (array?.length > 0) {
        for (let i = 0; i < array?.length; i += 3) {
          initialInputs.push(...array?.slice(i, i + 3));
        }
        setInputs(initialInputs);
      }
      setSetp(option?.data.status.id);
    }
  }, [option]);

  useEffect(() => {
    if (order) {
      setIsOwner(order?.data.client?.id === user?.id);
    }
  }, [order]);

  const selectHandleChange = (e: any) => {
    setQuantity(e.target.value);
    console.log(quantity);
  };

  const showCancelDrawer = () => {
    setIsVisible(true);
  };

  function handleChangeStartDate(e: any) {
    setStartDate(e.target.value);
  }

  const notApprovedOption = async (feedback: string = "") => {
    console.log(`Option ${option?.data.name} is not approved!!`);
    if (!feedback) {
      approveOptAndComment({
        article_option_id: option_id,
        comment: `Not Approved: ${comment}`,
        status_id: 6,
      });
    } else {
      await approveOptAndComment({
        article_option_id: option_id,
        comment: `Not Approved: ${comment}`,
        status_id: 6,
        feedback,
      });
    }
    await rejectOrAcceptPrototype({
      response: "reject",
      id: +option_id,
      comment: `Not Approved: ${comment}`,
    });

    setIsVisible(false);
    setComment("");
    setNotApproveIsVisible(true);
    setTimeout(() => {
      setNotApproveIsVisible(false);
    }, 2000);
  };

  const approveArticleAndSetQuantity = async () => {
    console.log(
      `Option ${option?.data.name} is approved and the quantity is ${quantity}!!`
    );
    await approveOption();
    await setQuantity("");
  };

  const saveChange = async () => {
    setNotApproveIsVisible(true);
    setApproved(true);
    await approveOptAndComment({
      order_id: order_id,
      article_option_id: option_id,
      comment: `${comment}`,
      status_id: option?.data.status.id,
      qty:
        option?.data.status.name === "SMS" ||
        option?.data.status.name === "OK PROD" ||
        option?.data.status.name === "PRE SMS" ||
        option?.data.status.name === "PRODUCTION"
          ? Number(quantity)
          : 0,
      size_qty:
        option?.data.status.name === "SMS" ||
        option?.data.status.name === "OK PROD" ||
        option?.data.status.name === "PRE SMS" ||
        option?.data.status.name === "PRODUCTION"
          ? // ? size_qty
            inputs
          : null,

      delivery_date:
        option?.data.status.name === "PROTO" ||
        option?.data.status.name === "SMS" ||
        option?.data.status.name === "OK PROD" ||
        option?.data.status.name === "PRE SMS" ||
        option?.data.status.name === "PRODUCTION"
          ? delivery_date
          : null,
      startDate: option?.data.status.name === "PRODUCTION" ? startDate : null,
      quantity: option?.data.status.name === "PRODUCTION" ? quantity : null,
    });
    /*   await rejectOrAcceptPrototype({
      response: "accept",
      id: +option_id,
      comment: `Approved: ${comment}`,
    }); */

    setIsVisible(false);
    setComment("");
    setTimeout(() => {
      setNotApproveIsVisible(false);
    }, 2000);
  };

  const approveOption = async () => {
    await approveOpt({
      order_id: order_id,
      article_option_id: option_id,
      comment: `${comment}`,
      status_id: option?.data.status.id,
      qty:
        option?.data.status.name === "SMS" ||
        option?.data.status.name === "OK PROD" ||
        option?.data.status.name === "PRE SMS" ||
        option?.data.status.name === "PRODUCTION"
          ? Number(quantity)
          : 0,
      size_qty:
        option?.data.status.name === "SMS" ||
        option?.data.status.name === "OK PROD" ||
        option?.data.status.name === "PRE SMS" ||
        option?.data.status.name === "PRODUCTION"
          ? // ? size_qty
            inputs
          : null,

      delivery_date:
        option?.data.status.name === "PROTO" ||
        option?.data.status.name === "SMS" ||
        option?.data.status.name === "OK PROD" ||
        option?.data.status.name === "PRE SMS" ||
        option?.data.status.name === "PRODUCTION"
          ? delivery_date
          : null,
      startDate: option?.data.status.name === "PRODUCTION" ? startDate : null,
      quantity: option?.data.status.name === "PRODUCTION" ? quantity : null,
    });
    setComment("");
    await rejectOrAcceptPrototype({
      response: "accept",
      id: +option_id,
      comment: `Approved: ${comment}`,
    });
  };

  const {
    mutateAsync: approveOptAndComment,
    isLoading: approvingAndCommentLoading,
  } = useMutation(approveOptionAndComment, {
    onSuccess: (res) => {
      MyNotif(` Successfully Saved`, "success");
      refetch();
    },
  });

  const { mutateAsync: approveOpt } = useMutation(approveOptionAndComment, {
    onSuccess: (res) => {
      refetch();
    },
  });
  const {
    mutateAsync: rejectOrAcceptPrototype,
    isLoading: rejectOrAcceptProtoLoading,
  } = useMutation(rejectOrAcceptProto, {
    onSuccess: (res) => {
      MyNotif(`Option ${res}ed `, "success");
      refetch();
    },
  });

  if (loadingOption || loadingOrder) {
    return (
      <div
        style={{ width: "100%", height: "50vh" }}
        className="d-flex justify-content-center align-items-center">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  const handleAddInput = () => {
    const newInputs = [...inputs, "", "", ""];
    setInputs(newInputs);
  };

  const handleInputChange = (event: any, index: any) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
  };

  const handleRemoveInput = (index: any) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 3);
    setInputs(newInputs);
  };

  const showMatrix = (name: any) => {
    // console.log(inputs);
    let found = false;
    option?.data.allStatus.map((el: any) => {
      // Calculate the number of rows
      console.log(name);
      if (el.pivot?.size_qty && el.id == name) {
        console.log("aaaa");
        const length = JSON.parse(el.pivot?.size_qty).length;
        const rows = Math.ceil(length / 3);

        // Create a new matrix from the input values
        const newMatrix = [];
        let count = 0;

        for (let i = 0; i < rows; i++) {
          const row = [];
          for (let j = 0; j < 3; j++) {
            row.push(JSON.parse(el.pivot?.size_qty)[count] || "");
            count++;
          }
          newMatrix.push(row);
        }

        // Update the matrix state
        //@ts-ignore
        setmatrix(newMatrix);
        found = true;
      }
      if (!found) {
        // Reset matrix state
        setmatrix([]);
      }
    });
  };

  const nextStep = async (id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/article_options/next/${option?.data.id}`,
      data: {
        status_id: id + 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
    return "ok";
  };

  const nextProto = async (id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/article_options/next/${option?.data.id}`,
      data: {
        status_id: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
    return "ok";
  };

  const complete = async (id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/article_options/complete/${option?.data.id}`,
      data: {
        status_id: id,
        completed: "complete",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
    return "ok";
  };

  const saveDetails = async (status_id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/article_options/savedetails/${option?.data.id}`,
      data: {
        status_id: status_id,
        fabric_details: detailsFabric,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    refetch();
    return "ok";
  };

  const saveDetailsStyle = async (status_id: any) => {
    let token = localStorage.getItem("token");

    const { data: response } = await axiosInstance({
      method: "put",
      url: `/article_options/savedetailsstyle/${option?.data.id}`,
      data: {
        status_id: status_id,
        style_details: detailsStyle,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    refetch();
    return "ok";
  };

  const content = (
    <div className="p-3">
      <p>Congratulations! You Approved your Proto. Where do you wish to GO?</p>
      <div className="proto_footer_btns p-3">
        {" "}
        <Button
          type="default"
          className="cancel_btn"
          onClick={() => nextProto(2)}>
          PRE SMS
        </Button>
        <Button
          type="default"
          className="approve_btn"
          onClick={() => nextProto(3)}>
          SMS
        </Button>
      </div>
    </div>
  );

  return (
    <div className="container-fluid">
      <div className="row pt-0">
        <div
          className="col-12 col-md-5 p-0  align-items-center"
          style={{ background: "#fcfdff", height: "100%" }}>
          {/* <div>
            {option?.data.is_client_product == 1 && is360 == false ? (
              <iframe
                src={
                  option?.data.status.pivot.proto
                    ? option?.data.status.pivot.proto
                    : proto_illustration
                }
                style={{
                  width: "100%",
                  height: "calc(100vh - 227px)",
                  border: "none",
                }}
              />
            ) : (
              <>
                {" "}
                {option?.data.is_client_product == 1 &&  (
                <div className="product_images_wrapper ">
                  <div
                    className="product_images"
                    style={{ position: "relative" }}>
                    <InnerImageZoom
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        option?.data.front_image_file
                      }
                      zoomSrc={
                        process.env.REACT_APP_STORAGE_URL +
                        option?.data.front_image_file
                      }
                      fullscreenOnMobile={true}
                      className="lazy-load-image-background zoomed_img"
                      afterZoomIn={() => {
                        document.querySelectorAll(
                          ".lazy-load-image-background"
                        );

                      }}
                      afterZoomOut={() =>
                        document.querySelectorAll(".zoomed_img")
                      }
                    />
                  </div>

                  <div
                    className="product_images"
                    style={{ position: "relative" }}>
                    <InnerImageZoom
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        option?.data.back_image_file
                      }
                      zoomSrc={
                        process.env.REACT_APP_STORAGE_URL +
                        option?.data.back_image_file
                      }
                      fullscreenOnMobile={true}
                      className="lazy-load-image-background zoomed_img"
                      afterZoomIn={() => {
                        document.querySelectorAll(
                          ".lazy-load-image-background"
                        );

                    
                      }}
                      afterZoomOut={() =>
                        document.querySelectorAll(".zoomed_img")
                      }
                    />
                  </div>
                </div>)}
                <br />
              </>
            )}

            {option?.data.is_client_product == 0 && (
              <>
                <iframe
                  src={
                    option?.data.status.pivot.proto
                      ? option?.data.status.pivot.proto
                      : proto_illustration
                  }
                  style={{
                    width: "100%",
                    height: "calc(100vh - 227px)",
                    border: "none",
                  }}
                />{" "}
              </>
            )}
            {option?.data.is_client_product == 1 && (
              <>
                {is360 == false ? (
                  <button className="prototype" onClick={() => set360(true)}>
                    <FileImageOutlined
                      style={{ fontSize: "40px", color: "white" }}
                    />
                  </button>
                ) : (
                  <button className="prototype" onClick={() => set360(false)}>
                    <img src={logo360} alt="360 degree" width="60" />
                  </button>
                )}
              </>
            )}
          </div> */}

          { option?.data.status.pivot.proto ?

              <>
                <iframe
                    src={
                      option?.data.status.pivot.proto

                    }
                    style={{
                      width: "100%",
                      height: "calc(100vh - 227px)",
                      border: "none",
                    }}
                />
              </> :
              <>
                <div style={{
                  height: "calc(100vh - 227px)",
                  border: "none",
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection:"column"
                }}>

                  <img src={proto_illustration} alt="proto_illustration" style={{

                    border: "none",
                  }}></img>
                  <span style={{textAlign: "center", marginTop: "20px"}}>Your product is being prepared</span>
                </div>
              </>}

          <div
              className="row "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
            {" "}
            {/*<a
              href={
                process.env.REACT_APP_API_URL +
                `/generate-pdf/direct-order/${order_id}/${article_id}/${option_id}`
              }
              style={{ color: "white" }}>
              <button className="exportPDF">Export tech pack</button>{" "}
            </a>*/}
            <a
                href={
                    process.env.REACT_APP_API_URL +
                    `/generate-pdf/direct-order/${order_id}/${article_id}/${option_id}`
                }
                className="exportPDF2">
              Export tech pack <DownloadOutlined style={{fontSize: "16px"}}/>
              {" "}
            </a>
          </div>
        </div>
        <div className="col-12 col-md-7 proto_container pl-3">
          <div className="proto_header m-4">
            <img
                src={backArrow}
                className="back_arrow_folder"
                alt="back arrow"
                onClick={() => history.back()}
            />
            {loadingArticle ? (
                <h1>My orders</h1>
            ) : (
              <>
                <h1>{article?.data.name}</h1>

                <div style={{position: "absolute", right: "10px"}}>
             {/*     <a
                      href={
                          process.env.REACT_APP_API_URL +
                          `/generate-pdf/direct-order/${order_id}/${article_id}/${option_id}`
                      }
                      className="exportPDF2">
               Export tech pack <DownloadOutlined  style={{fontSize:"16px"}}/>
                    {" "}
                  </a>*/}
                  <Avatar.Group
                      maxCount={5}
                      size="large"
                      maxStyle={{color: "#f56a00", backgroundColor: "#fde3cf"}}>
                    {order?.data.collaborators.map((colab: any) => (
                        <Tooltip
                            title={colab.name}
                            placement="bottom"
                            key={colab.id}>
                          <Avatar
                              src={process.env.REACT_APP_STORAGE_URL + colab.logo}
                          />
                        </Tooltip>
                    ))}
                  </Avatar.Group>
                </div>
              </>
            )}
          </div>
          <div className="proto_breadcrumb_container my-4">
            <div className="row">
              <div className="col-10">
                <div className="d-flex align-items-center mb-2">
                  <Avatar
                      src={
                      process.env.REACT_APP_STORAGE_URL +
                      order?.data?.client?.logo
                    }
                    size="default"
                  />
                  <p className="proto_breadcrumb mb-0 ml-2">{`${order?.data.client?.name} > ${order?.data.name} > ${article?.data.name} > ${option?.data.name}`}</p>
                </div>
              </div>
              <div className="col-2">
                {" "}
                {option?.data.status.pivot.proto_confirmation === "ACCEPTED" &&
                option?.data.status.pivot.step === "complete" ? (
                  <>
                    <div className="row">
                      <Tag
                        icon={<CheckOutlined />}
                        style={{
                          borderRadius: "15px",
                          padding: "5px 9px 5px 9px",
                        }}
                        color="#039855">
                        Completed
                      </Tag>
                    </div>
                  </>
                ) : (
                  option?.data.status.pivot.proto_confirmation ===
                    "ACCEPTED" && (
                    <Tag
                      icon={<CheckOutlined />}
                      style={{
                        borderRadius: "15px",
                        padding: "5px 9px 5px 9px",
                      }}
                      color="#A5AB36">
                      Approved
                    </Tag>
                  )
                )}
              </div>
            </div>
            {option?.data.status.id !== 6 && (
              <>
                <p className="proto_msg_welcome">
                  Welcome, we are waiting for your confirmation.
                </p>
                <div className="row">
                  {order?.data.season && (
                    <div style={{ marginBottom: "16px" }}>
                      <Tag
                        style={{
                          borderRadius: "15px",
                          padding: "5px 9px 5px 9px",
                        }}
                        color="#182844">
                        {order?.data.season}
                      </Tag>
                    </div>
                  )}
                  {order?.data.type && (
                    <div style={{ marginBottom: "16px" }}>
                      <Tag
                        style={{
                          borderRadius: "15px",
                          padding: "5px 9px 5px 9px",
                        }}
                        color="#182844">
                        {order?.data.type}
                      </Tag>
                    </div>
                  )}
                </div>
                <p>Click on the step to View History</p>
              </>
            )}
          </div>
          <div className="steps_progress mt-4 d-flex justify-content-center">
            <Steps
              className={
                option?.data.status.id === 6
                  ? "d-flex justify-content-center cancelled"
                  : ""
              }
              current={option?.data.status.id - 1}
              labelPlacement={"vertical"}
              direction={width.md ? "horizontal" : "vertical"}>
              {option?.data.status.id !== 6 ? (
                <>
                  <Step
                    title="PROTO"
                    onClick={() => setSetp(1)}
                    style={{
                      cursor: option?.data.status.id >= 1 ? "pointer" : "",
                    }}
                    description={
                      option?.data.status.id >= 1 ? "View History" : ""
                    }
                    className={"step-size"}
                  />
                  <Step
                    title="PRE SMS"
                    onClick={() => {
                      setSetp(2);
                      showMatrix(2);
                    }}
                    style={{
                      cursor: option?.data.status.id >= 2 ? "pointer" : "",
                    }}
                    description={
                      option?.data.status.id >= 2 ? "View History" : ""
                    }
                  />
                  <Step
                    title="SMS"
                    onClick={() => {
                      setSetp(3);
                      showMatrix(3);
                    }}
                    style={{
                      cursor: option?.data.status.id >= 3 ? "pointer" : "",
                    }}
                    description={
                      option?.data.status.id >= 3 ? "View History" : ""
                    }
                  />
                  <Step
                    title="OK PROD"
                    onClick={() => {
                      setSetp(4);
                      showMatrix(4);
                    }}
                    style={{
                      cursor: option?.data.status.id >= 4 ? "pointer" : "",
                    }}
                    description={
                      option?.data.status.id >= 4 ? "View History" : ""
                    }
                  />
                  <Step
                    title="PRODUCTION"
                    style={{
                      cursor: option?.data.status.id >= 5 ? "pointer" : "",
                    }}
                    onClick={() => {
                      setSetp(5);
                      showMatrix(5);
                    }}
                    description={
                      option?.data.status.id >= 5 ? "View History" : ""
                    }
                  />
                </>
              ) : (
                <Step title="CANCELLED" />
              )}
            </Steps>
          </div>
          {step != option?.data.status.id && (
            <>
              <div className="proto_footer mt-5">
                {option?.data.allStatus
                  .filter((el: any) => el.id !== 5)
                  .map((el: any) => {
                    return (
                      <>
                        {option?.data && el.id == step && el.id !== 5 && (
                          <>
                            <table
                              style={{ width: "100%", marginBottom: "25px" }} className={"table-progress"}>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Fabric{" "}
                                  </span>{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <div
                                    style={{
                                      justifyContent: "space-between",
                                      display: "flex",
                                    }}>
                                    <span style={{ fontWeight: "bold" }}>
                                      {" "}
                                      Details{" "}
                                    </span>
                                    <button
                                      disabled={isEditableFabric}
                                      className={
                                        isEditableFabric
                                          ? " add_details_disabled"
                                          : "add_details"
                                      }
                                      onClick={toggleEditFabric}>
                                      <PlusOutlined></PlusOutlined>
                                    </button>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <span>{option?.data.fabric} </span>{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  {isEditableFabric ? (
                                    <>
                                      <div>
                                        <textarea
                                          style={{ width: "100%" }}
                                          rows={3}
                                          className="textarea_content"
                                          defaultValue={el.pivot.details_fabric}
                                          onChange={(e) => setDetaisFabric(e)}
                                        />
                                        <div
                                          className="row"
                                          style={{ float: "right" }}>
                                          <button
                                            onClick={() => {
                                              setIsEditableFabric(false);
                                            }}
                                            className="check_details"
                                            style={{ marginRight: "10px" }}>
                                            <CloseOutlined
                                              style={{
                                                color: "#f37b7b",
                                              }}></CloseOutlined>
                                          </button>
                                          <button
                                            onClick={() => {
                                              saveDetails(el.id);
                                              setIsEditableFabric(false);
                                            }}
                                            className="check_details">
                                            <CheckOutlined
                                              style={{
                                                color: "#00BA6C",
                                              }}></CheckOutlined>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div style={{ whiteSpace: "pre-line" }}>
                                        {el.pivot.details_fabric}
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Style{" "}
                                  </span>{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <div
                                    style={{
                                      justifyContent: "space-between",
                                      display: "flex",
                                    }}>
                                    <span style={{ fontWeight: "bold" }}>
                                      {" "}
                                      Details{" "}
                                    </span>
                                    <button
                                      disabled={isEditableStyle}
                                      className={
                                        isEditableStyle
                                          ? " add_details_disabled"
                                          : "add_details"
                                      }
                                      onClick={toggleEditStyle}>
                                      <PlusOutlined></PlusOutlined>
                                    </button>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  <span>{option?.data.style} </span>{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "50%",
                                  }}>
                                  {isEditableStyle ? (
                                    <>
                                      <div>
                                        <textarea
                                          style={{ width: "100%" }}
                                          rows={3}
                                          className="textarea_content"
                                          defaultValue={el.pivot.details_style}
                                          onChange={(e) => setDetaisStyle(e)}
                                        />
                                        <div
                                          className="row"
                                          style={{ float: "right" }}>
                                          <button
                                            onClick={() => {
                                              setIsEditableStyle(false);
                                            }}
                                            className="check_details"
                                            style={{ marginRight: "10px" }}>
                                            <CloseOutlined
                                              style={{
                                                color: "#f37b7b",
                                              }}></CloseOutlined>
                                          </button>
                                          <button
                                            onClick={() => {
                                              saveDetailsStyle(el.id);
                                              setIsEditableStyle(false);
                                            }}
                                            className="check_details">
                                            <CheckOutlined
                                              style={{
                                                color: "#00BA6C",
                                              }}></CheckOutlined>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div style={{ whiteSpace: "pre-line" }}>
                                        {el.pivot.details_style}
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            </table>
                          </>
                        )}
                        {el.id == step && el.name != "PROTO" ? (
                          <>
                            <div style={{ marginBottom: "16px" }}>
                              Size {el.name}
                            </div>
                            <div style={{ marginBottom: "16px" }}>
                              {" "}
                              {matrix.length > 0 ? (
                                <div>
                                  <table style={{ width: "100%" }} className={"table-progress"}>
                                    <th
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                      }}>
                                      Size
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                      }}>
                                      Length
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid #CCCCCC",
                                        padding: "9px",
                                      }}>
                                      Qty
                                    </th>
                                    <tbody>
                                      {matrix.map((row: any, rowIndex: any) => (
                                        <tr
                                          key={rowIndex}
                                          style={{
                                            border: "1px solid #CCCCCC",
                                          }}>
                                          {row.map(
                                            (cell: any, cellIndex: any) => (
                                              <td
                                                key={cellIndex}
                                                style={{
                                                  border: "1px solid #CCCCCC",
                                                  padding: "9px",
                                                }}>
                                                {cell}
                                              </td>
                                            )
                                          )}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className="p-3">-</div>
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}

                {step == "1" && (
                  <>
                    <div style={{ display: "flex", marginBottom: "16px" }}>
                      <div
                        style={{
                          color: "#182844",
                          marginRight: "9px",
                          fontWeight: "bold",
                        }}>
                        Quantity Proto:
                      </div>
                      <div>1</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "16px",
                      }}>
                      <div
                        style={{
                          color: "#182844",
                          marginRight: "9px",
                          fontWeight: "bold",
                        }}>
                        Size:
                      </div>
                      <div>
                        {option && option.data.size ? option.data.size : "-"}
                      </div>
                    </div>
                  </>
                )}
                {option?.data.allStatus
                  .filter((el: any) => el.id !== 5)
                  .map((el: any) => {
                    {
                      return (
                        <>
                          {el.id == step ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "16px",
                                }}>
                                <div
                                  style={{
                                    color: "#182844",
                                    marginRight: "9px",
                                    fontWeight: "bold",
                                  }}>
                                  Delivery date :
                                </div>
                                <div>
                                  {console.log(el)}
                                  {option && el?.pivot?.delivery_date
                                    ? moment(el?.pivot?.delivery_date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "-"}
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    }
                  })}
                {option?.data.allComment.map((el: any) => {
                  return (
                    <>
                      {el.status_id == step && el.name != "PROTO" ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              marginBottom: "16px",
                            }}>
                            {el.client_id == order?.data.client.id &&
                            el.commercial_id == null ? (
                              <>
                                <Avatar
                                  src={
                                    process.env.REACT_APP_STORAGE_URL +
                                    order?.data.client.logo
                                  }
                                  size="default"
                                  style={{ marginRight: "5px" }}
                                />{" "}
                                <div
                                  style={{
                                    backgroundColor: "#F8FAFC",
                                    borderRadius: "5px",
                                    border: "1px solid #CDD5DF",
                                    width: "100%",
                                    padding: "16px",
                                  }}>
                                  <div
                                    className="row"
                                    style={{
                                      justifyContent: "space-between",
                                    }}>
                                    <div
                                      style={{
                                        lineHeight: "24px",
                                        fontWeight: "bold",
                                      }}>
                                      {order?.data.client.name}
                                    </div>
                                    <div>
                                      <p>
                                        {moment(el.created_at).format(
                                          "DD-MM-YYYY HH:mm"
                                        )}{" "}
                                      </p>
                                    </div>
                                  </div>
                                  <div>{el.comment}</div>
                                </div>
                              </>
                            ) : (
                              <>
                                <Avatar
                                  src={
                                    process.env.REACT_APP_STORAGE_URL +
                                    el.commercial.avatar
                                  }
                                  size="default"
                                  style={{ marginRight: "5px" }}
                                />{" "}
                                <div
                                  style={{
                                    backgroundColor: "#F8FAFC",
                                    borderRadius: "5px",
                                    border: "1px solid #CDD5DF",
                                    width: "100%",
                                    padding: "16px",
                                  }}>
                                  <div
                                    className="row"
                                    style={{
                                      justifyContent: "space-between",
                                    }}>
                                    <div
                                      style={{
                                        lineHeight: "24px",
                                        fontWeight: "bold",
                                      }}>
                                      {el.commercial.name}
                                    </div>
                                    <div>
                                      <p>
                                        {moment(el.created_at).format(
                                          "DD-MM-YYYY HH:mm"
                                        )}{" "}
                                      </p>
                                    </div>
                                  </div>
                                  <div>{el.comment}</div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
            </>
          )}

          {option?.data.status.id == step &&
            option?.data.status.pivot.proto_confirmation !== "ACCEPTED" && (
              <>
                {option?.data.status.id !== 5 && (
                  <div className="proto_footer">
                    <table style={{ width: "100%" }} className={"table-progress"}>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span style={{ fontWeight: "bold" }}>Fabric </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <div
                            style={{
                              justifyContent: "space-between",
                              display: "flex",
                            }}>
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              Details{" "}
                            </span>
                            <button
                              disabled={isEditableFabric}
                              className={
                                isEditableFabric
                                  ? " add_details_disabled"
                                  : "add_details"
                              }
                              onClick={toggleEditFabric}>
                              <PlusOutlined></PlusOutlined>
                            </button>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span>{option?.data.fabric} </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          {isEditableFabric ? (
                            <>
                              <div>
                                <textarea
                                  style={{ width: "100%" }}
                                  rows={3}
                                  className="textarea_content"
                                  defaultValue={
                                    option?.data.status.pivot.details_fabric
                                  }
                                  onChange={(e) => setDetaisFabric(e)}
                                />
                                <div className="row" style={{ float: "right" }}>
                                  <button
                                    onClick={() => {
                                      setIsEditableFabric(false);
                                    }}
                                    className="check_details"
                                    style={{ marginRight: "10px" }}>
                                    <CloseOutlined
                                      style={{
                                        color: "#f37b7b",
                                      }}></CloseOutlined>
                                  </button>
                                  <button
                                    onClick={() => {
                                      saveDetails(option?.data.status.id);
                                      setIsEditableFabric(false);
                                    }}
                                    className="check_details">
                                    <CheckOutlined
                                      style={{
                                        color: "#00BA6C",
                                      }}></CheckOutlined>
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ whiteSpace: "pre-line" }}>
                                {option?.data.status.pivot.details_fabric}
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span style={{ fontWeight: "bold" }}>Style </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <div
                            style={{
                              justifyContent: "space-between",
                              display: "flex",
                            }}>
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              Details{" "}
                            </span>
                            <button
                              disabled={isEditableStyle}
                              className={
                                isEditableStyle
                                  ? " add_details_disabled"
                                  : "add_details"
                              }
                              onClick={toggleEditStyle}>
                              <PlusOutlined></PlusOutlined>
                            </button>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          <span>{option?.data.style} </span>{" "}
                        </td>
                        <td
                          style={{
                            border: "1px solid #CCCCCC",
                            padding: "9px",
                            width: "50%",
                          }}>
                          {isEditableStyle ? (
                            <>
                              <div>
                                <textarea
                                  style={{ width: "100%" }}
                                  rows={3}
                                  className="textarea_content"
                                  defaultValue={
                                    option?.data.status.pivot.details_style
                                  }
                                  onChange={(e) => setDetaisStyle(e)}
                                />
                                <div className="row" style={{ float: "right" }}>
                                  <button
                                    onClick={() => {
                                      setIsEditableStyle(false);
                                    }}
                                    className="check_details"
                                    style={{ marginRight: "10px" }}>
                                    <CloseOutlined
                                      style={{
                                        color: "#f37b7b",
                                      }}></CloseOutlined>
                                  </button>
                                  <button
                                    onClick={() => {
                                      saveDetailsStyle(option?.data.status.id);
                                      setIsEditableStyle(false);
                                    }}
                                    className="check_details">
                                    <CheckOutlined
                                      style={{
                                        color: "#00BA6C",
                                      }}></CheckOutlined>
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ whiteSpace: "pre-line" }}>
                                {option?.data.status.pivot.details_style}
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
                <div className="proto_footer">
                  {option?.data.status.name !== "CANCELLED" &&
                    isOwner &&
                    (option?.data.status.name === "SMS" ||
                    option?.data.status.name === "OK PROD" ||
                    option?.data.status.name === "PRODUCTION" ||
                    option?.data.status.name === "PRE SMS" ||
                    option?.data.status.name === "PROTO" ? (
                      <div>
                        <div
                          style={{
                            padding: "24px",
                            backgroundColor: "#F1F5FE",
                            borderRadius: "5px",
                          }}
                        className={"action-comment"}
                        >
                          {option?.data.status.name === "SMS" ||
                          option?.data.status.name === "OK PROD" ||
                          /*                         option?.data.status.name === "PRODUCTION" ||
                           */ option?.data.status.name === "PRE SMS" ? (
                            <div>

                              <div
                                style={{
                                  marginBottom: "15px",
                                }}>
                                {inputs.map((input, index) =>
                                  index % 3 === 0 ? (
                                    <div key={index} className="row grid_size">
                                      <div className=" col-9">
                                        <div className="row">
                                          <div
                                            className=" col-4"
                                            style={{
                                              display: "flex",
                                              /* marginRight: "1.2em", */
                                              width: "30%",
                                            }}>
                                            <span
                                              style={{
                                                backgroundColor: "#182844",
                                                color: "white",
                                                padding: "8px",
                                                borderTopLeftRadius: "5px",
                                                borderBottomLeftRadius: "5px",
                                                borderColor: "#182844",
                                              }}>
                                              Size
                                            </span>
                                            <Input
                                              value={input}
                                              onChange={(event) =>
                                                handleInputChange(event, index)
                                              }
                                              style={{
                                                width: "150px",
                                                borderTopRightRadius: "5px",
                                                borderBottomRightRadius: "5px",
                                              }}
                                            />
                                          </div>

                                          {inputs[index + 1] !== undefined && (
                                            <div
                                              style={{
                                                display: "flex",
                                                /*  marginRight: "1.2em", */
                                                width: "30%",
                                              }}>
                                              <span
                                                style={{
                                                  backgroundColor: "#182844",
                                                  color: "white",
                                                  padding: "8px",
                                                  borderTopLeftRadius: "5px",
                                                  borderBottomLeftRadius: "5px",
                                                }}>
                                                Length
                                              </span>
                                              <Input
                                                value={inputs[index + 1]}
                                                onChange={(event) =>
                                                  handleInputChange(
                                                    event,
                                                    index + 1
                                                  )
                                                }
                                                style={{
                                                  width: "150px",
                                                  borderTopRightRadius: "5px",
                                                  borderBottomRightRadius:
                                                    "5px",
                                                }}
                                              />
                                            </div>
                                          )}
                                          {inputs[index + 2] !== undefined && (
                                            <div
                                              className=" col-4"
                                              style={{
                                                display: "flex",
                                                /* marginRight: "1.2em", */
                                                width: "30%",
                                              }}>
                                              <span
                                                style={{
                                                  backgroundColor: "#182844",
                                                  color: "white",
                                                  padding: "8px",
                                                  borderTopLeftRadius: "5px",
                                                  borderBottomLeftRadius: "5px",
                                                }}>
                                                Qty
                                              </span>
                                              <Input
                                                value={inputs[index + 2]}
                                                onChange={(event) =>
                                                  handleInputChange(
                                                    event,
                                                    index + 2
                                                  )
                                                }
                                                style={{
                                                  width: "150px",
                                                  borderTopRightRadius: "5px",
                                                  borderBottomRightRadius:
                                                    "5px",
                                                }}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="col-3">
                                        {" "}
                                        {inputs.length - 3 ? (
                                          <button
                                            className="remove_input"
                                            onClick={() =>
                                              handleRemoveInput(index)
                                            }>
                                            <MinusOutlined />
                                          </button>
                                        ) : (
                                          <></>
                                        )}
                                        {index === inputs.length - 3 && (
                                          <button
                                            className="add_input"
                                            style={{ marginLeft: "1.2em" }}
                                            onClick={handleAddInput}>
                                            <PlusOutlined></PlusOutlined>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  ) : null
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {option.data.status.id == 5 && (
                            <>
                              <div className="final_order_select my-3">
                                <label
                                  className="mr-3 mb-0"
                                  style={{ color: "#182844" }}>
                                  Quantity
                                </label>

                                <Input
                                  value={
                                    quantity || option.data.status.pivot.qty
                                  }
                                  onChange={(e: any) =>
                                    setQuantity(e.target.value)
                                  }></Input>
                              </div>
                              <div className="final_order_select">
                                <label
                                  className="mr-3 mb-0"
                                  style={{ color: "#182844" }}>
                                  Actual Delivery date
                                </label>

                                <DatePicker
                                  defaultValue={moment(
                                    option.data.status.pivot
                                      .start_production_date === null
                                      ? new Date()
                                      : option.data.status.pivot
                                          .start_production_date
                                  )}
                                  disabledDate={(current) =>
                                    current.isBefore(moment().add(15, "days"))
                                  }
                                  format={"YYYY-MM-DD"}
                                  onChange={(date: any, dateString: any) =>
                                    setStartDate(dateString)
                                  }
                                  style={{
                                    borderRadius: "5px",
                                    width: "100%",
                                  }}
                                />
                              </div>
                            </>
                          )}
                          <div className="final_order_select my-3">
                            <label
                              className="mr-3 mb-0"
                              style={{ color: "#182844" }}>
                              Delivery date
                            </label>

                            <DatePicker
                              defaultValue={moment(
                                order?.data.delivery_date === null
                                  ? new Date()
                                  : order?.data.delivery_date
                              )}
                              disabledDate={(current) =>
                                current.isBefore(moment().add(15, "days"))
                              }
                              format={"YYYY-MM-DD"}
                              onChange={(date: any, dateString: any) =>
                                setDeliviry_date(dateString)
                              }
                              style={{
                                borderRadius: "5px",
                                width: "100%",
                              }}
                            />
                          </div>

                          <>
                            {option && option.data.allComment.length > 0 && (
                              <>
                                <div
                                  style={{
                                    color: "#182844",
                                    marginRight: "9px",
                                    fontWeight: "bold",
                                    marginBottom: "16px",
                                  }}>
                                  Comments
                                </div>
                                {option.data.allComment.map((el: any) => {
                                  return (
                                    <>
                                      {el.status_id == step &&
                                      el.name != "PROTO" ? (
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              marginBottom: "16px",
                                            }}>
                                            {el.client_id ==
                                              order?.data.client.id &&
                                            el.commercial_id == null ? (
                                              <>
                                                <Avatar
                                                  src={
                                                    process.env
                                                      .REACT_APP_STORAGE_URL +
                                                    order?.data.client.logo
                                                  }
                                                  size="default"
                                                  style={{ marginRight: "5px" }}
                                                />{" "}
                                                <div
                                                  style={{
                                                    backgroundColor: "#F8FAFC",
                                                    borderRadius: "5px",
                                                    border: "1px solid #CDD5DF",
                                                    width: "100%",
                                                    padding: "16px",
                                                  }}>
                                                  <div
                                                    className="row"
                                                    style={{
                                                      justifyContent:
                                                        "space-between",
                                                    }}>
                                                    <div
                                                      style={{
                                                        lineHeight: "24px",
                                                        fontWeight: "bold",
                                                      }}>
                                                      {order?.data.client.name}
                                                    </div>
                                                    <div>
                                                      <p>
                                                        {moment(
                                                          el.created_at
                                                        ).format(
                                                          "DD-MM-YYYY HH:mm"
                                                        )}{" "}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div>{el.comment}</div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <Avatar
                                                  src={
                                                    process.env
                                                      .REACT_APP_STORAGE_URL +
                                                    el.commercial.avatar
                                                  }
                                                  size="default"
                                                  style={{ marginRight: "5px" }}
                                                />{" "}
                                                <div
                                                  style={{
                                                    backgroundColor: "#F8FAFC",
                                                    borderRadius: "5px",
                                                    border: "1px solid #CDD5DF",
                                                    width: "100%",
                                                    padding: "16px",
                                                  }}>
                                                  <div
                                                    className="row"
                                                    style={{
                                                      justifyContent:
                                                        "space-between",
                                                    }}>
                                                    <div
                                                      style={{
                                                        lineHeight: "24px",
                                                        fontWeight: "bold",
                                                      }}>
                                                      {el.commercial.name}
                                                    </div>
                                                    <div>
                                                      <p>
                                                        {moment(
                                                          el.created_at
                                                        ).format(
                                                          "DD-MM-YYYY HH:mm"
                                                        )}{" "}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div>{el.comment}</div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </>
                          <textarea
                            rows={4}
                            name="comment"
                            placeholder="Add your comment"
                            className="comment_proto_order my-4"
                            onChange={(e: any) => setComment(e.target.value)}
                            value={comment}></textarea>
                          <div
                            className=" footer_btns_form"
                            style={{ marginTop: "16px" }}>
                            <Button
                              type="default"
                              className="save-changes"
                              loading={approvingAndCommentLoading}
                              onClick={saveChange}
                              disabled={
                                option?.data.status.pivot.proto_confirmation ===
                                "ACCEPTED"
                              }>
                              Save Changes
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    ))}
                  {isOwner &&
                    (option?.data.status.name !== "CANCELLED" ? (
                      <div className="proto_footer_btns my-4">
                        {option?.data.status.id < 4 && (
                          <Button
                            type="default"
                            className="cancel_btn"
                            onClick={() => showCancelDrawer()}>
                            Cancel
                          </Button>
                        )}

                        <Button
                          type="default"
                          className="approve_btn"
                          loading={rejectOrAcceptProtoLoading}
                          onClick={() => approveOption()}
                          disabled={
                            option?.data.status.pivot.proto_confirmation ===
                            "ACCEPTED"
                          }>
                          Approve & send
                        </Button>
                      </div>
                    ) : (
                      <div
                        className="proto_footer_btns my-4"
                        style={{ justifyContent: "center" }}>
                        <Button
                          type="default"
                          className="approve_btn"
                          onClick={() => history.back()}>
                          Go back to orders
                        </Button>
                      </div>
                    ))}
                </div>
              </>
            )}

          {step == option?.data.status.id &&
            option?.data.status.pivot.proto_confirmation === "ACCEPTED" && (
              <>
                <div className="proto_footer mt-5">
                  {option?.data.allStatus
                    .filter((el: any) => el.id !== 5)
                    .map((el: any) => {
                      return (
                        <>
                          {el.id == step && el.name != "PROTO" ? (
                            <>
                              <div
                                style={{
                                  marginBottom: "16px",
                                }}>
                                Size {el.name}
                              </div>
                              <div
                                style={{
                                  marginBottom: "16px",
                                }}>
                                {" "}
                                {matrix.length > 0 ? (
                                  <div>
                                    <table style={{ width: "100%" }} className={"table-progress"}>
                                      <th
                                        style={{
                                          border: "1px solid #CCCCCC",
                                          padding: "9px",
                                        }}>
                                        Length
                                      </th>
                                      <th
                                        style={{
                                          border: "1px solid #CCCCCC",
                                          padding: "9px",
                                        }}>
                                        Size
                                      </th>
                                      <th
                                        style={{
                                          border: "1px solid #CCCCCC",
                                          padding: "9px",
                                        }}>
                                        Qty
                                      </th>
                                      <tbody>
                                        {matrix.map(
                                          (row: any, rowIndex: any) => (
                                            <tr
                                              key={rowIndex}
                                              style={{
                                                border: "1px solid #CCCCCC",
                                              }}>
                                              {row.map(
                                                (cell: any, cellIndex: any) => (
                                                  <td
                                                    key={cellIndex}
                                                    style={{
                                                      border:
                                                        "1px solid #CCCCCC",
                                                      padding: "9px",
                                                    }}>
                                                    {cell}
                                                  </td>
                                                )
                                              )}
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  <div className="p-3">-</div>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {el.id == step && el.name == "PRODUCTION" && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "16px",
                                }}>
                                <div
                                  style={{
                                    color: "#182844",
                                    marginRight: "9px",
                                    fontWeight: "bold",
                                  }}>
                                  Quantity Production :
                                </div>
                                <div>{option?.data.status.pivot.qty}</div>
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}

                  {step == "PROTO" && (
                    <>
                      {" "}
                      <div style={{ display: "flex", marginBottom: "16px" }}>
                        <div
                          style={{
                            color: "#182844",
                            marginRight: "9px",
                            fontWeight: "bold",
                          }}>
                          Quantity Proto
                        </div>
                        <div>1</div>
                      </div>
                    </>
                  )}

                  {option?.data.allStatus
                    .filter((el: any) => el.id !== 5)
                    .map((el: any) => {
                      {
                        return (
                          <>
                            {el.id == step ? (
                              <>
                                {option.data.status.id == 1 && (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBottom: "16px",
                                      }}>
                                      <div
                                        style={{
                                          color: "#182844",
                                          marginRight: "9px",
                                          fontWeight: "bold",
                                        }}>
                                        Size:
                                      </div>
                                      <div>
                                        {option && option.data.size
                                          ? option.data.size
                                          : "-"}
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "16px",
                                  }}>
                                  <div
                                    style={{
                                      color: "#182844",
                                      marginRight: "9px",
                                      fontWeight: "bold",
                                    }}>
                                    Delivery date:
                                  </div>
                                  <div>
                                    {option && order?.data.delivery_date
                                      ? moment(
                                          order?.data.delivery_date
                                        ).format("DD-MM-YYYY")
                                      : "-"}
                                  </div>
                                </div>

                                {option.data.status.id == 5 &&
                                option?.data.status.id == step ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "16px",
                                    }}>
                                    <div
                                      style={{
                                        color: "#182844",
                                        marginRight: "9px",
                                        fontWeight: "bold",
                                      }}>
                                      Actual Delivery Date:{" "}
                                    </div>
                                    <div>
                                      {option &&
                                      option?.data.status.pivot
                                        .start_production_date
                                        ? moment(
                                            option?.data.status.pivot
                                              .start_production_date
                                          ).format("YYYY-MM-DD")
                                        : "-"}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      }
                    })}

                  <>
                    {option?.data.allStatus?.filter((el: any) => el.id === 5) &&
                    option?.data.status.id == step ? (
                      <>
                        {step === 5 && (
                          <>
                            <div
                              style={{
                                marginTop: "-50px",
                                marginBottom: "16px",
                              }}>
                              <table
                                style={{ width: "100%", marginBottom: "25px" }} className={"table-progress"}>
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                      width: "50%",
                                    }}>
                                    <span style={{ fontWeight: "bold" }}>
                                      Fabric{" "}
                                    </span>{" "}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                      width: "50%",
                                    }}>
                                    <span style={{ fontWeight: "bold" }}>
                                      Details{" "}
                                    </span>{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                    }}>
                                    {option?.data.fabric}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                    }}>
                                    {option.data.allStatus
                                      .filter((el: any) => el.status_id !== 5)
                                      .map((el: any) => {
                                        return (
                                          <>
                                            <ul id="myUL2">
                                              <li
                                                style={{
                                                  whiteSpace: "pre-line",
                                                }}>
                                                {el.pivot.details_fabric ?? "-"}
                                              </li>
                                            </ul>
                                          </>
                                        );
                                      })}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                    }}>
                                    <span style={{ fontWeight: "bold" }}>
                                      Style{" "}
                                    </span>{" "}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                    }}>
                                    <span style={{ fontWeight: "bold" }}>
                                      Details{" "}
                                    </span>{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                    }}>
                                    {option?.data.style}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #CCCCCC",
                                      padding: "9px",
                                    }}>
                                    {option.data.allStatus
                                      .filter((el: any) => el.status_id !== 5)
                                      .map((el: any) => {
                                        return (
                                          <>
                                            <ul id="myUL2">
                                              <li
                                                style={{
                                                  whiteSpace: "pre-line",
                                                }}>
                                                {el.pivot.details_style ?? "-"}
                                              </li>
                                            </ul>
                                          </>
                                        );
                                      })}
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <table
                              style={{ width: "100%", marginBottom: "25px" }} className={"table-progress"}>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "25%",
                                    fontWeight: "bold",
                                  }}>
                                  Production
                                </td>{" "}
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "25%",
                                    fontWeight: "bold",
                                  }}>
                                  Delivery Date
                                </td>{" "}
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "25%",
                                    fontWeight: "bold",
                                  }}>
                                  Actual Delivery Date
                                </td>{" "}
                                <td
                                  style={{
                                    border: "1px solid #CCCCCC",
                                    padding: "9px",
                                    width: "25%",
                                    fontWeight: "bold",
                                  }}>
                                  Quantity
                                </td>{" "}
                              </tr>
                              {option?.data.allStatus
                                ?.filter((el: any) => el.id === 5)
                                .map((el: any, index: any) => {
                                  return (
                                    <>
                                      <tr>
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                          }}>
                                          {option?.data.allStatus?.filter(
                                            (el: any) => el.id === 5
                                          ).length - index}
                                        </td>{" "}
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                          }}>
                                          {moment(
                                            el.pivot.delivery_date
                                          ).format("DD-MM-YYYY ")}
                                        </td>{" "}
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                          }}>
                                          {moment(
                                            el.pivot.start_production_date
                                          ).format("DD-MM-YYYY")}
                                        </td>{" "}
                                        <td
                                          style={{
                                            border: "1px solid #CCCCCC",
                                            padding: "9px",
                                          }}>
                                          {el.pivot.qty}
                                        </td>{" "}
                                      </tr>
                                    </>
                                  );
                                })}
                            </table>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>

                  <>
                    {option && option.data.allComment.length > 0 && (
                      <>
                        Comments
                        {option?.data.allComment.map((el: any) => {
                          return (
                            <>
                              {el.status_id == step && el.name != "PROTO" ? (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "16px",
                                    }}>
                                    {el.client_id == order?.data.client.id &&
                                    el.commercial_id == null ? (
                                      <>
                                        <Avatar
                                          src={
                                            process.env.REACT_APP_STORAGE_URL +
                                            order?.data.client.logo
                                          }
                                          size="default"
                                          style={{ marginRight: "5px" }}
                                        />{" "}
                                        <div
                                          style={{
                                            backgroundColor: "#F8FAFC",
                                            borderRadius: "5px",
                                            border: "1px solid #CDD5DF",
                                            width: "100%",
                                            padding: "16px",
                                          }}>
                                          <div
                                            className="row"
                                            style={{
                                              justifyContent: "space-between",
                                            }}>
                                            <div
                                              style={{
                                                lineHeight: "24px",
                                                fontWeight: "bold",
                                              }}>
                                              {order?.data.client.name}
                                            </div>
                                            <div>
                                              <p>
                                                {moment(el.created_at).format(
                                                  "DD-MM-YYYY HH:mm"
                                                )}{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div>{el.comment}</div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <Avatar
                                          src={
                                            process.env.REACT_APP_STORAGE_URL +
                                            el.commercial.avatar
                                          }
                                          size="default"
                                          style={{ marginRight: "5px" }}
                                        />{" "}
                                        <div
                                          style={{
                                            backgroundColor: "#F8FAFC",
                                            borderRadius: "5px",
                                            border: "1px solid #CDD5DF",
                                            width: "100%",
                                            padding: "16px",
                                          }}>
                                          <div
                                            className="row"
                                            style={{
                                              justifyContent: "space-between",
                                            }}>
                                            <div
                                              style={{
                                                lineHeight: "24px",
                                                fontWeight: "bold",
                                              }}>
                                              {el.commercial.name}
                                            </div>
                                            <div>
                                              <p>
                                                {moment(el.created_at).format(
                                                  "DD-MM-YYYY HH:mm"
                                                )}{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div>{el.comment}</div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                      </>
                    )}
                  </>
                </div>
              </>
            )}
          {step == option?.data.status.id &&
            option?.data.status.pivot.proto_confirmation === "ACCEPTED" &&
            option?.data.status.id != 5 && (
              <>
                <div className="proto_footer_btns ">
                  {option?.data.status.pivot.step !== "complete" && (
                    <>
                      {option?.data.status.id == 1 && (
                        <Button
                          type="default"
                          className="approve_btn"
                          onClick={showDrawer2}>
                          Next
                        </Button>
                      )}
                      {option?.data.status.id != 1 && (
                        <Button
                          type="default"
                          className="approve_btn"
                          onClick={() => nextStep(option?.data.status.id)}>
                          Next
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
        </div>
      </div>
      <CancelOrderDrawer
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        article={article}
        notApprovedOption={notApprovedOption}
        orderType={"direct_order"}
        productToCancel={option?.data.id}
        approvingAndCommentLoading={approvingAndCommentLoading}
        rejectOrAcceptProtoLoading={rejectOrAcceptProtoLoading}
      />

      <Modal
        title="Congratulations"
        visible={open2}
        closable={true}
        centered
        onCancel={handleCancel}
        footer={[
          <div className="proto_footer_btns p-3">
            <Button
              type="default"
              className="approve_btn"
              onClick={() => nextProto(2)}>
              {" "}
              PRE SMS{" "}
            </Button>

            <Button
              type="default"
              className="approve_btn"
              onClick={() => nextProto(3)}>
              {" "}
              SMS{" "}
            </Button>
          </div>,
        ]}>
        <p className={"pop-up-text"}> You Approved your Proto. Where do you wish to GO?</p>
      </Modal>
    </div>
  );
}
