import { Avatar, Button, Tag, Tooltip } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import Folder from "../../icons/Folder";
import Jeans from "../../icons/Jeans";
import { useEffect, useState } from "react";

import {
  PlusSquareOutlined,
  PlusOutlined,
  MinusSquareOutlined,
  MinusOutlined,
  FolderOutlined,
  FolderAddOutlined,
  FolderOpenOutlined,
  UserOutlined,
} from "@ant-design/icons";
export default function TableRowCustomizedOrder({ item, url }: any) {
  const [showTable, setShowTable] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [showTr, setShowTr] = useState("");

  const genStatusMsg = (status_name: string) => {
    console.log(status_name);
    switch (status_name) {
      case "PROTO":
        return {
          color: "#BCBCBD",
          msg: "Waiting for your confirmation",
        };
      case "PRODUCTION":
      case "OK PROD":
        return {
          msg: "Waiting for your confirmation",
          color: "#BCBCBD",
        };
      case "CANCELLED":
        return { color: "#FF3A3A", msg: "Your order is canceled" };
      default:
        return {
          color: "#BCBCBD",
          msg: "Waiting for your confirmation",
        };
    }
  };
  return (
    /*  <tr>
      <td className="top_article">
        <ul id="myUL">
          <li>
            {item.products && item.products.length > 1 ? (
              <>
                <span
                  className="caret"
                  style={{ color: "#8894A1", fontSize: "18px" }}
                >
                  {item.reference}
                </span>
              </>
            ) : (
              <>
                <span style={{ color: "#8894A1", fontSize: "18px" }}>
                  {item.reference}
                </span>
              </>
            )}
            {item.products && item.products.length > 0 ? (
              <>
                {" "}
                <ul className="nested">
                  {item.products.map((elm: any) => (
                    <li
                      style={{
                        fontSize: "16px",
                        marginLeft: "10px",
                        marginTop: "5px",
                      }}
                    >
                      <Link to={`${url}/${item.id}/${elm.id}/progress`}>
                        {elm.name}
                      </Link>
                   
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                {" "}
                <ul className="nested" style={{ color: "#7C7C7C" }}>
                  No Products
                </ul>
                <></>
              </>
            )}
          </li>
        </ul>
      </td>
      
      <td scope="row" className="top_article">
        <span>
          {item?.products.length} product{item?.products.length > 1 ? "s" : ""}
        </span>
      </td>
      <td className="top_article">
        {moment(item.created_at).format("DD/MM/YYYY")}
      </td>
      <td className="top_article">
        {item.products.length > 1 ? (
          "---"
        ) : (
          <Link
            to={`${url}/${item.id}/${
              item.products[0] && item.products[0].id
            }/progress`}
          >
            <Button
              style={{
                color:
                  item &&
                  item.products.length &&
                  item.products?.[0]?.status?.name === "PROTO"
                    ? "#7C7C7C"
                    : "#00468C",
                background:
                  item &&
                  item.products.length &&
                  item.products?.[0]?.status?.name === "PROTO"
                    ? "#E0E0E0"
                    : "rgba(0, 70, 140, 0.2)",
              }}
              className="status_btn_order"
            >
              {item.products.length && item.products?.[0]?.status?.name}
            </Button>
          </Link>
        )}
      </td>
     
    </tr> */
    <>
      {item.products.length > 0 && (
        <tr onClick={() => setShowTable(!showTable)}>
          <td
            width={"5%"}
            className={showTable ? "top_article active_tr" : "top_article"}>
            <button onClick={() => setShowTable(!showTable)}>
              {showTable ? (
                <>
                  {/*                   <MinusSquareOutlined style={{ color: "#00468C" }} />
                   */}{" "}
                  <FolderOpenOutlined
                    style={{ color: "#00468C", fontSize: "20px" }}
                  />
                </>
              ) : (
                <>
                  <FolderAddOutlined
                    style={{ color: "#00468C", fontSize: "20px" }}
                  />
                  {/*                   <PlusSquareOutlined style={{ color: "#00468C" }} />
                   */}{" "}
                </>
              )}
            </button>
          </td>

          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: 0 }}>
            {item.reference}
          </td>

          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: 0 }}>
            {item?.products[0]?.collection_name || "-"}
          </td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: 0 }}>
            {moment(item.created_at).format("DD/MM/YYYY")}
          </td>

          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: 0 }}></td>
          <td
            className={showTable ? "top_article active_tr" : "top_article"}
            style={{ paddingLeft: 0 }}>
            <p className="mb-0" style={{ marginLeft: "50px" }}>
              {item.products.length}
            </p>
            {/*    {item.products.length > 1 ? (
            "---"
          ) : (
            <Link
              to={`${url}/${item.id}/${
                item.products[0] && item.products[0].id
              }/progress`}
            >
              <Button
                style={{
                  color:
                    item &&
                    item.products.length &&
                    item.products[0].status.name === "PROTO"
                      ? "#7C7C7C"
                      : "#00468C",
                  background:
                    item &&
                    item.products.length &&
                    item.products[0].status.name === "PROTO"
                      ? "#E0E0E0"
                      : "rgba(0, 70, 140, 0.2)",
                }}
                className="status_btn_order"
              >
                {item.products.length && item.products[0].status.name}
              </Button>
            </Link>
          )} */}
          </td>
          <td
            className={
              showTable ? "top_article active_tr" : "top_article"
            }></td>
          <td
            className={
              showTable ? "top_article active_tr" : "top_article"
            }></td>
          <td className={showTable ? "top_article active_tr" : "top_article"}>
            <Avatar.Group
              maxCount={2}
              size="large"
              maxStyle={{ color: "white", backgroundColor: "#7a9ec5" }}>
              {item?.collaborators?.map((collab: any) => (
                <Tooltip title={collab.name} key={collab.id} placement="bottom">
                  {collab.logo ? (
                    <Avatar
                      style={{
                        boxShadow: "1px 0px 5px rgba(0,0,0,0.4)",
                      }}
                      src={process.env.REACT_APP_STORAGE_URL + collab.logo}
                    />
                  ) : (
                    <Avatar
                      style={{
                        backgroundColor: "#ddd",
                        boxShadow: "1px 0px 5px rgba(0,0,0,0.2)",
                      }}
                      icon={<UserOutlined style={{ verticalAlign: "2px" }} />}
                    />
                  )}
                </Tooltip>
              ))}
            </Avatar.Group>
          </td>
        </tr>
      )}
      {showTable && (
        <tr>
          <td
            colSpan={10}
            style={{
              backgroundColor: "#FCFDFF",
              padding: "0.2rem",
              paddingLeft: "4%",
            }}>
            {/*     <table className="tableArticle">
              {item.products?.map((elm: any) => (
                <>
                  <Link to={`${url}/${item.id}/${elm.id}/progress`}>
                    <tr style={{ cursor: "pointer" }}>
                      <td
                        style={{
                          //   width: "50%",

                          borderTop: "none",
                        }}
                        className="details_td_custom_order"
                      >
                        {elm.name}
                      </td>

                      <td
                        style={{
                          //   width: "5%",
                          paddingLeft: "0px",
                          borderTop: "none",
                        }}
                      ></td>
                      <td
                        style={{
                          //    width: "21%",
                          paddingLeft: "0px",
                          borderTop: "none",
                        }}
                      >
                        {" "}
                        {elm.delivery_date_item
                          ? moment(elm.delivery_date_item).format("DD/MM/YYYY")
                          : "-"}
                      </td>
                      <td
                        style={{
                          //  width: "8%",
                          paddingLeft: "0px",
                          borderTop: "none",
                        }}
                        className="status_td_custom_order"
                      >
                        <Tag
                          color="processing"
                          style={{
                            borderRadius: "50px",
                            cursor: "pointer",
                            fontWeight: "normal",
                          }}
                        >
                          {elm.status.name}
                        </Tag>
                      </td>
                      <td
                        style={{
                          // width: "22%",
                          paddingLeft: "0px",
                          borderTop: "none",
                        }}
                      >
                        <span
                          style={{
                            color: genStatusMsg(elm.status.name).color,
                          }}
                          className="status_msg"
                        >
                          {genStatusMsg(elm.status.name).msg}
                        </span>
                      </td>
                    </tr>
                  </Link>
                </>
              ))}
            </table> */}

            {item.products?.map((elm: any) => (
              <>
                <Link to={`${url}/${item.id}/${elm.id}/progress`}>
                  <div className="row row_custom_product_progress">
                    <div className="product_custom_prog_custom_order">
                      {" "}
                      {elm.name}
                    </div>
                    <div className="deleviry_date_prog_custom_order">
                      {" "}
                      {elm.delivery_date_item
                        ? moment(elm.delivery_date_item).format("DD/MM/YYYY")
                        : "-"}
                    </div>

                    <div className="status_prog_custom_order">
                      <Tag
                        color="#182844"
                        style={{
                          borderRadius: "50px",
                          cursor: "pointer",
                          fontWeight: "normal",
                        }}>
                        {elm?.status?.name}
                      </Tag>
                    </div>

                    <div>
                      <span
                        style={{
                          color: genStatusMsg(elm.status?.name).color,
                        }}
                        className="status_msg">
                        {genStatusMsg(elm.status?.name).msg}
                      </span>
                    </div>
                  </div>
                </Link>
              </>
            ))}
          </td>
        </tr>
      )}
    </>
  );
}
