import axiosInstance from "../../config/axios-instance";

const getAllFolders = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: "/folders",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getArchivedFolders = () => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: "/folders/archived",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getOneFolders = (id: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "get",
    url: `/folders/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createFolder = (data: any) => {
  let token = localStorage.getItem("token");
  return axiosInstance({
    method: "post",
    url: `/folders`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const addProducttoFolder = (idFolder: any, idProduct: any) => {
  let token = localStorage.getItem("token");
  var data = {
    products_ids: [idProduct],
  };
  return axiosInstance({
    method: "put",
    url: `/folders/${idFolder}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const moveProductToFolder = (
  idFolderMoveTo: any,
  idFolder: any,
  idProduct: any
) => {
  let token = localStorage.getItem("token");
  var data = {
    from: idFolder,
    to: idFolderMoveTo,
  };
  return axiosInstance({
    method: "post",
    url: `/folders/products/${idProduct}/dnd`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const editFolder = (data: any, idFolder: any) => {
  let token = localStorage.getItem("token");

  return axiosInstance({
    method: "patch",
    url: `/folders/${idFolder}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteProductFromFolder = (datas: any, idFolder: any) => {
  let token = localStorage.getItem("token");
  var data = { products: datas };
  return axiosInstance({
    method: "delete",
    url: `/folders/${idFolder}/products`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const changeFolderRoot = (idFolderParent: any, idFolder: any) => {
  let token = localStorage.getItem("token");
  let data = { parent_id: idFolderParent };
  return axiosInstance({
    method: "patch",
    url: `/folders/${idFolder}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const archiveFolder = (idFolder: any) => {
  let token = localStorage.getItem("token");
  let data = { is_archived: 1 };
  return axiosInstance({
    method: "post",
    url: `/folders/${idFolder}/archive`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteFolder = (idFolder: any) => {
  let token = localStorage.getItem("token");

  return axiosInstance({
    method: "delete",
    url: `/folders/${idFolder}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const restoreFolder = (idFolder: any) => {
  let token = localStorage.getItem("token");
  let data = { is_archived: 0 };
  return axiosInstance({
    method: "post",
    url: `/folders/${idFolder}/unarchive`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const inviteToEditFolder = (clientId: any, idFolder: any) => {
  let token = localStorage.getItem("token");
  var data = {
    clients_ids: [clientId],
  };
  return axiosInstance({
    method: "post",
    url: `/folders/invite_to_edit/${idFolder}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const FoldersServices = {
  getAllFolders,
  getOneFolders,
  createFolder,
  addProducttoFolder,
  editFolder,
  inviteToEditFolder,
  changeFolderRoot,
  getArchivedFolders,
  archiveFolder,
  restoreFolder,
  deleteFolder,
  moveProductToFolder,
  deleteProductFromFolder,
};

export default FoldersServices;
