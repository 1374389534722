import { Button } from "antd";
import { useDispatch } from "react-redux";
import { NavHashLink } from "react-router-hash-link";
import {
  finish,
  goNextStep,
  goPrevStep,
} from "../../actions/guided-tour-actions/actions";

const Popup = ({ content, next_hash, prev_hash, position }: any) => {
  const dispatch = useDispatch();
  return (
    <div
      className="bg-white rounded"
      style={{
        position: "absolute",
        bottom: position === "right" ? "70px" : "-20px",
        [position]: position === "right" ? "20%" : "35%",
        border: "1px solid #f1f1f1",
        zIndex: 5,
        padding: "15px 20px",
        boxShadow: "0px 10px 15px rgba(0, 70, 140, 0.1)",
        width: "40%",
        maxWidth: "400px",
      }}
    >
      <p>{content}</p>
      <div className="d-flex justify-content-end">
        <NavHashLink
          smooth
          to={`/home#${prev_hash}`}
          className="mr-2"
          onClick={() => dispatch(finish())}
        >
          <Button
            style={{
              borderRadius: "5px",
            }}
          >
            Skip
          </Button>
        </NavHashLink>
        <NavHashLink
          smooth
          to={`/home#${next_hash}`}
          onClick={() => dispatch(goNextStep())}
        >
          <Button
            style={{
              background: "#00468c",
              borderColor: "#00468c",
              borderRadius: "5px",
              color: "white",
            }}
          >
            Continue
          </Button>
        </NavHashLink>
      </div>
    </div>
  );
};

export default Popup;
