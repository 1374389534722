import avatar from "../../img/avatar_placeholder.jpg";
import moment from "moment";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../config/axios-instance";
import { useQuery } from "react-query";
import {LoadingOutlined, FilterOutlined, SearchOutlined} from "@ant-design/icons";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import {
  Avatar,
  Button,
  Collapse,
  Descriptions,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Result,
  Select,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  InboxOutlined,
  FolderOpenOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";
import { useMutation } from "react-query";
import OrderServices from "../../actions/order-actions/services";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import {
  restoreOption,
  restoreOptionLeft,
  relaunchDirectOption,
} from "../../actions/my-orders/services";
import {
  PlusSquareOutlined,
  PlusOutlined,
  MinusSquareOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import {HrIcon} from "../product-3d-config-tabs/forms/canvas/icon/HrIcon";
const { Option } = Select;
const { Panel } = Collapse;

const DirectOrdersCompany = (props: any) => {
  const { status } = props;

  const [filterBy, setFilterBy] = useState({
    reference: "",
    client: 0,
    type: 0,
  });
  const [orders, setOrders] = useState<any>(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [ordersCancel, serOrdersCancel] = useState<any>(null);
  const [showTable, setShowTable] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [showTr, setShowTr] = useState("");
  const [showTableId, setShowTableId] = useState("");

  const [isModalRestore, setIsModalRestore] = useState(false);
  const [optionToRestore, setoptionToRestore] = useState<any>("");

  const [isModalRestoreCompleted, setIsModalRestoreCompleted] = useState(false);
  const [productToRestore, setProductToRestore] = useState<any>("");

  const params = useParams<any>();
  const history = useHistory<any>();
  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQueryy();
  const fetchDirectOrders = () => {
    setFilterLoading(true);

    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/direct_orders/companies/${params.id}?status=completed&page=${
        query.get("page") || 1
      }&order_status=${status}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setOrders(res.data);
      setFilterLoading(false);
    });
  };

  const showModalRestoreCompleted = (product_id: any) => {
    setIsModalRestoreCompleted(true);

    setProductToRestore(product_id);
  };

  const showModalRestore = (option_id: any) => {
    setIsModalRestore(true);
    console.log("optionToRestore" + option_id);

    setoptionToRestore(option_id);
    console.log("optionToRestore" + optionToRestore);
  };

  const handleCancel = () => {
    setIsModalRestore(false);
  };

  const handleCancelComplted = () => {
    setIsModalRestoreCompleted(false);
  };

  const relaunchProduction = () => {
    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/direct_orders/relaunch/${productToRestore}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Relaunched with successfully.", "success");
        setIsModalRestore(false);
        window.location.reload();
      }
    });
    //   setIsModalRestore(false);
  };

  const relaunchPreSMS = () => {
    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/direct_orders/relaunch/presms/${productToRestore}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Relaunched with successfully.", "success");
        setIsModalRestore(false);
        window.location.reload();
      }
    });
    //   setIsModalRestore(false);
  };

  useEffect(() => {
    if (status == "in-progress") {
      fetchDirectOrders();
    } else if (status == "cancelled") {
      fetchDirectOrders();
    } else if (status == "completed") {
      fetchDirectOrders();
    }
  }, [status]);
  const fetchClients = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/clients/companies/${params.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { isLoading: loading, data: clients } = useQuery<any>(
    "clients",
    () => fetchClients(),
    { keepPreviousData: true }
  );
  const { isLoading, isError, data, error } = useQuery(
    ["direct_orders", query.get("page")],
    () => fetchDirectOrders(),
    { keepPreviousData: true }
  );

  const onFilter = (e: any, filters: any, page: number) => {
    e.preventDefault();
    setFilterLoading(true);
    console.log(filterBy.type);
    if (!filters.client && !filters.reference && !filterBy.type) {
      fetchDirectOrders();
      setFilterLoading(false);
    } else {
      let token = localStorage.getItem("token");
      return axiosInstance({
        method: "get",
        url: `/admin/search/direct_orders?reference=${filterBy.reference}&type=${filterBy.type}&ownerId=${filterBy.client}&order_status=${status}&status=completed&page=${page}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res: any) => {
        if (filters.type === 0) {
          setOrders(res.data);
          setFilterLoading(false);
        } else {
          serOrdersCancel(res.data);
          setFilterLoading(false);
        }
      });
    }
  };

  const { mutateAsync: deleteOpt, isLoading: deleteLoading } = useMutation(
    OrderServices.deleteOption,
    {
      onSuccess: () => {
        MyNotif("Option deleted successfully.", "success");
      },
    }
  );
  const { mutateAsync: restoreOpt, isLoading: restoreLoading } = useMutation(
    restoreOption,
    {
      onSuccess: () => {
        MyNotif(
          `Option restored successfully. You can check it in order`,
          "success"
        );
      },
    }
  );

  /*   const { mutateAsync: restoreOptLeft, isLoading: restoreLeftLoading } =
    useMutation(restoreOptionLeft, {
      onSuccess: () => {
        MyNotif(
          `Option restored successfully. You can check it in order`,
          "success"
        );
      },
    });
 */

  const restoreOptLeft = () => {
    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/article_options/${optionToRestore}/update_status_left`,
      data: {
        status_id: 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Restore with successfully.", "success");
        setIsModalRestore(false);
        window.location.reload();
      }
    });
  };

  const restore = () => {
    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/article_options/${optionToRestore}/update_status`,
      data: {
        status_id: 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Restore with successfully.", "success");
        setIsModalRestore(false);
        window.location.reload();
      }
    });
  };

  const { mutateAsync: relaunchOpt, isLoading: relaunchLoading } = useMutation(
    relaunchDirectOption,
    {
      onSuccess: () => {
        MyNotif(
          `Option relaunched successfully. You can check it in order`,
          "success"
        );
        // window.location.reload;
      },
    }
  );
  const [searchQuery, setSearchQuery] = useState("");

  const handleClick = (rowId: any) => {
    // Only set showTable to true for the clicked row
    setShowTr(rowId);
  };

  const handleClickTable = (orderId: any) => {
    // Only set showTable to true for the clicked row
    setShowTableId(orderId);
  };

  useEffect(() => {
    console.log(orders);
    var toggler = document.getElementsByClassName("caret");
    var i;
    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function (e: any) {
        e.target.parentElement
          .querySelector(".nested")
          .classList.toggle("active");
        e.target.classList.toggle("caret-down");
      });
    }
  });

  const handleSearchInputChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  // Assuming your data is an array of orders with a property 'orderName' representing the name of the order.

  const filteredData = orders?.data?.filter((order: any) =>
    /*     order?.name?.toLowerCase().includes(searchQuery.toLowerCase())
     */

    {
      console.log(order);

      const nameMatch = order?.name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const refMatch = order?.ref
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());

      return refMatch || nameMatch;
    }
  );
  console.log(status);
  const genStatusMsg = (status_name: string) => {
    console.log(status_name);
    switch (status_name) {
      case "PROTO":
        return {
          color: "#BCBCBD",
          msg: "Your sample is being prepared",
        };
      case "PRODUCTION":
        return {
          msg: "The production is in process",
          color: "#BCBCBD",
        };
      case "OK PROD":
        return {
          msg: "Your sample is being prepared",
          color: "#BCBCBD",
        };
      case "CANCELLED":
        return { color: "#FF3A3A", msg: "Your order is canceled" };
      default:
        return {
          color: "#BCBCBD",
          msg: "Waiting for your confirmation",
        };
    }
  };

  const OptionInPrograss = ({ elm, order_id }: any) => {
    const genStatusMsg = (status_name: string) => {
      console.log(status_name);
      switch (status_name) {
        case "PROTO":
          return {
            color: "#BCBCBD",
            msg: "Your prototype is being prepared",
          };
        case "PRODUCTION":
        case "OK PROD":
          return {
            msg: "Your order is in process",
            color: "#06C170",
          };
        case "CANCELLED":
          return { color: "#FF3A3A", msg: "Your order is canceled" };
        default:
          return {
            color: "#FF872A",
            msg: "We are waiting for your answer",
          };
      }
    };
    return (
      <table className="tableArticle">
        <tr>
          <th
            style={{
              backgroundColor: "#FCFDFF",
              borderTop: "none",
            }}>
            Option name
          </th>
          <th
            style={{
              backgroundColor: "#FCFDFF",
              borderTop: "none",
            }}>
            Status
          </th>
        </tr>
        {elm.article_options?.map((option: any) => (
          <>
            <tr key={elm.id}>
              <td
                style={{
                  backgroundColor: "#FCFDFF",
                  borderTop: "none",
                }}>
                <Link
                  to={`${history.location.pathname}/${order_id}/${elm.id}/${option.id}/progress`}>
                  {option.name}
                </Link>
              </td>
              <td
                style={{
                  backgroundColor: "#FCFDFF",
                  borderTop: "none",
                }}>
                <Tag
                  color="processing"
                  style={{
                    borderRadius: "50px",
                    cursor: "pointer",
                    fontWeight: "normal",
                  }}>
                  {option.status.name}
                </Tag>
              </td>

              <td
                style={{
                  backgroundColor: "#FCFDFF",
                  borderTop: "none",
                }}>
                <span
                  style={{
                    color: genStatusMsg(option.status.name).color,
                  }}
                  className="status_msg">
                  {genStatusMsg(option.status.name).msg}
                </span>
              </td>
              <td
                style={{
                  backgroundColor: "#FCFDFF",
                  borderTop: "none",
                }}></td>
              <td
                style={{
                  backgroundColor: "#FCFDFF",
                  borderTop: "none",
                }}></td>
            </tr>
          </>
        ))}
      </table>
    );
  };

  const OptionCompleted = ({ elm, order_id }: any) => {
    return (
      <table className="tableArticle">
        <tr>
          <th
            style={{
              backgroundColor: "#FCFDFF",
              borderTop: "none",
            }}>
            Option name
          </th>

          <th
            style={{
              backgroundColor: "#FCFDFF",
              borderTop: "none",
            }}>
            Deleviry date
          </th>
          <th
            style={{
              backgroundColor: "#FCFDFF",
              borderTop: "none",
            }}>
            Quantity
          </th>
        </tr>
        <tbody>
          {elm.article_options?.map((option: any) => (
            <>
              <tr key={elm.id}>
                <td
                  style={{
                    backgroundColor: "#FCFDFF",
                    borderTop: "none",
                  }}>
                  <Link
                    to={`${history.location.pathname}/${order_id}/${elm.id}/${option.id}/progress`}>
                    {option.name}
                  </Link>
                </td>

                <td
                  style={{
                    backgroundColor: "#FCFDFF",
                    borderTop: "none",
                  }}>
                  {option.status.pivot.delivery_date
                    ? moment(option.status.pivot.delivery_date).format(
                        "DD-MM-YYYY"
                      )
                    : "-"}
                </td>
                <td
                  style={{
                    backgroundColor: "#FCFDFF",
                    borderTop: "none",
                  }}>
                  {option.status.pivot.qty}
                </td>

                <td
                  style={{
                    backgroundColor: "#FCFDFF",
                    borderTop: "none",
                  }}></td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    );
  };

  const OptionCancelled = ({ elm, order_id }: any) => {
    const genStatusMsg = (status_name: string) => {
      console.log(status_name);
      switch (status_name) {
        case "PROTO":
          return {
            color: "#BCBCBD",
            msg: "Your prototype is being prepared",
          };
        case "PRODUCTION":
        case "OK PROD":
          return {
            msg: "Your order is in process",
            color: "#06C170",
          };
        case "CANCELLED":
          return { color: "#FF3A3A", msg: "Your order is canceled" };
        default:
          return {
            color: "#FF872A",
            msg: "We are waiting for your answer",
          };
      }
    };
    return (
      <table className="tableArticle">
        <tr>
          <th
            style={{
              backgroundColor: "#FCFDFF",
              borderTop: "none",
            }}>
            Option name
          </th>
          <th
            style={{
              backgroundColor: "#FCFDFF",
              borderTop: "none",
            }}>
            Status
          </th>
          <th
            style={{
              backgroundColor: "#FCFDFF",
              borderTop: "none",
            }}>
            Delete Date
          </th>
        </tr>
        <tbody>
          {elm.article_options?.map((option: any) => (
            <>
              <tr key={elm.id}>
                <td
                  style={{
                    backgroundColor: "#FCFDFF",
                    borderTop: "none",
                  }}>
                  {option.name}
                </td>
                <td
                  style={{
                    backgroundColor: "#FCFDFF",
                    borderTop: "none",
                  }}>
                  <Tag
                    color="processing"
                    style={{
                      borderRadius: "50px",
                      cursor: "pointer",
                      fontWeight: "normal",
                    }}>
                    {option.status.name}
                  </Tag>
                </td>

                <td
                  style={{
                    backgroundColor: "#FCFDFF",
                    borderTop: "none",
                  }}>
                  {option.updated_at
                    ? moment(option.updated_at).format("DD-MM-YYYY")
                    : "-"}
                </td>
                <td
                  style={{
                    backgroundColor: "#FCFDFF",
                    borderTop: "none",
                  }}>
                  {" "}
                  <span
                    style={{
                      color: genStatusMsg(option.status.name).color,
                    }}
                    className="status_msg">
                    {genStatusMsg(option.status.name).msg}
                  </span>
                </td>
                <td
                  style={{
                    backgroundColor: "#FCFDFF",
                    borderTop: "none",
                  }}>
                  {" "}
                  <div>
                    <Popconfirm
                      placement="top"
                      title={"Are you sure you want to delete this order ?"}
                      onConfirm={() => deleteOpt(option.id)}
                      okText="Yes"
                      cancelText="No">
                      <Button
                        type="ghost"
                        size={"middle"}
                        className="mr-2"
                        style={{ borderRadius: "4px" }}
                        icon={<DeleteOutlined style={{ color: "#00468c" }} />}
                        loading={deleteLoading}>
                        Delete
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      placement="top"
                      title={"Are you sure you want to restore this order ?"}
                      onConfirm={() => restoreOpt(option.id)}
                      okText="Yes"
                      cancelText="No">
                      <Button
                        size={"middle"}
                        style={{ borderRadius: "4px" }}
                        icon={
                          <InboxOutlined
                            style={{
                              color: "#00468c",
                              verticalAlign: "1px",
                            }}
                          />
                        }
                        loading={restoreLoading}>
                        Restore
                      </Button>
                    </Popconfirm>
                  </div>
                </td>
                <td
                  style={{
                    backgroundColor: "#FCFDFF",
                    borderTop: "none",
                  }}></td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    );
  };

  const [sortOrder, setSortOrder] = useState("desc");

  const handleSortByDeliveryDate = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Function to sort the data based on the delivery date
  const sortDataByDeliveryDate = (data: any) => {
    const sortedData = data?.sort((a: any, b: any) => {
      console.log(a);
      const dateA = moment(a.created_at);
      const dateB = moment(b.created_at);

      if (dateA.isValid() && dateB.isValid()) {
        //@ts-ignore
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      }

      return sortOrder === "asc"
        ? dateA.isValid()
          ? 1
          : -1
        : dateA.isValid()
        ? -1
        : 1;
      /*     //@ts-ignore
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA; */
    });
    return sortedData;
  };

  // Apply sorting before mapping through the data
  const sortedData = sortDataByDeliveryDate(filteredData);

  return isLoading ? (
    <div
      className="loading_orders"
      style={{ height: "50vh", width: "50vw", margin: "0 auto" }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <>
      {/*       <Collapse
        ghost
        expandIcon={() => (
          <FilterOutlined
            style={{ fontSize: 20, verticalAlign: 0, paddingTop: 8 }}
          />
        )}
      >
        <Panel header="Filter Orders" key="1">
          <form
            className="d-flex"
            onSubmit={(e: any) => onFilter(e, filterBy, 1)}
          >
            <Input
              type="text"
              className="w-50 mr-3 rounded"
              placeholder="Order reference"
              value={filterBy.reference}
              onChange={(e) =>
                setFilterBy({ ...filterBy, reference: e.target.value })
              }
            />
            <Select
              loading={loading}
              style={{ borderRadius: 5 }}
              className="w-25 mr-3 select_filter_client"
              placeholder="Order sent by"
              value={+filterBy.client}
              onChange={(x: any) => setFilterBy({ ...filterBy, client: x })}
            >
              <Option value={0}>All clients</Option>
              {clients &&
                clients.data.data.map((client: any, i: number) => (
                  <Option value={client.id} key={i}>
                    {client.name}
                  </Option>
                ))}
            </Select>

            <Select
              loading={loading}
              className="w-25 mr-3 select_filter_client"
              placeholder="Order type"
              value={+filterBy.type}
              onChange={(x: any) => setFilterBy({ ...filterBy, type: x })}
            >
              <Option value={0}>All</Option>
              <Option value={6}>Option Cancelled</Option>
              <Option value={7}>Option Closed</Option>
            </Select> 
            <div className="d-flex w-25">
              <Input
                type="submit"
                value="Filter"
                className="w-50 mr-2 rounded"
              />
              <Input
                type="reset"
                value="Clear"
                className="w-50 rounded"
                onClick={(e: any) => {
                  onFilter(e, { reference: "", client: 0, type: 0 }, 1);
                  setFilterBy({ reference: "", client: 0, type: 0 });
                }}
              />
            </div>
          </form>
        </Panel>
      </Collapse> */}

      {filterBy.type === 0 ? (
        <>
          {" "}
          {filterLoading ? (
            <div
              className="loading_orders"
              style={{ height: "50vh", width: "50vw", margin: "0 auto" }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : orders && orders.data.length > 0 ? (
            <>
              {status == "in-progress" ? (
                <div className={"mt-3"}>
                  <div className="search-bar searchfaq" style={{width: "100%"}}>
                    <div className="input-container">
                      <input type="text" placeholder="Search your order" onChange={handleSearchInputChange}
                             className={"search_input"}/>
                      <HrIcon></HrIcon>
                      <button type="submit" className={"submit_input"}><SearchOutlined/></button>
                    </div>
                  </div>
                  <div style={{overflowY:"auto"}}>
                  <table className="table table-direct-order">
                    <thead>
                    <tr>
                      <th></th>
                      <th style={{paddingLeft: "0px"}}>Reference</th>
                      <th style={{paddingLeft: "0px"}}>Name</th>

                      <th
                          style={{paddingLeft: "0px", cursor: "pointer"}}
                          onClick={handleSortByDeliveryDate}>
                          Order date
                          {sortOrder === "asc" ? (
                            <CaretUpOutlined />
                          ) : (
                            <CaretDownOutlined />
                          )}
                        </th>
                        <th style={{ paddingLeft: "0px", cursor: "pointer" }}>
                          Delivery date
                        </th>
                        <th style={{ paddingLeft: "0px" }}>
                          Number of article{" "}
                        </th>
                        <th style={{ paddingLeft: "0px" }}>Status</th>
                        <th style={{ paddingLeft: "0px" }}>
                          Description of status
                        </th>
                        <th style={{ paddingLeft: "0px" }}>Team members</th>
                        {/*  <th></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {orders &&
                        filterLoading == false &&
                        sortedData.map((order: any) => (
                          <>
                            {order.articles.filter(
                              (article: any) =>
                                article.article_options &&
                                article.article_options.length > 0
                            ).length > 0 && (
                              <>
                                <tr
                                  key={order.id}
                                  onClick={() => {
                                    setShowTable(!showTable);
                                    handleClickTable(order.id);
                                  }}>
                                  <td
                                    width={"5%"}
                                    className={
                                      showTable && showTableId == order.id
                                        ? "top_article active_tr"
                                        : "top_article"
                                    }>
                                    <button
                                      onClick={() => {
                                        setShowTable(!showTable);
                                        handleClickTable(order.id);
                                      }}>
                                      {showTable && showTableId == order.id ? (
                                        <>
                                          <FolderOpenOutlined
                                            style={{
                                              color: "#00468C",
                                              fontSize: "20px",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <FolderAddOutlined
                                            style={{
                                              color: "#00468C",
                                              fontSize: "20px",
                                            }}
                                          />
                                          {/*                   <PlusSquareOutlined style={{ color: "#00468C" }} />
                                           */}{" "}
                                        </>
                                      )}
                                    </button>
                                  </td>
                                  <td
                                    className={
                                      showTable && showTableId == order.id
                                        ? "top_article active_tr"
                                        : "top_article"
                                    }
                                    style={{ width: "10%", paddingLeft: 0 }}>
                                    <span
                                      style={{
                                        color: "#8894A1",
                                        fontSize: "16px",
                                      }}>
                                      {order.ref}
                                    </span>
                                  </td>

                                  <td
                                    scope="row"
                                    className={
                                      showTable && showTableId == order.id
                                        ? "top_article active_tr"
                                        : "top_article"
                                    }
                                    style={{
                                      width: "15%",
                                      paddingLeft: "0px",
                                    }}>
                                    <span>{order.name}</span>
                                  </td>
                                  <td
                                    className={
                                      showTable && showTableId == order.id
                                        ? "top_article active_tr"
                                        : "top_article"
                                    }
                                    style={{ paddingLeft: "0px" }}>
                                    {moment(order.updated_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td
                                    className={
                                      showTable && showTableId == order.id
                                        ? "top_article active_tr"
                                        : "top_article"
                                    }
                                    style={{ paddingLeft: "0px" }}></td>
                                  <td
                                    className={
                                      showTable && showTableId == order.id
                                        ? "top_article active_tr"
                                        : "top_article"
                                    }
                                    style={{ paddingLeft: "0px" }}>
                                    <p
                                      className="mb-0"
                                      style={{ marginLeft: "50px" }}>
                                      {
                                        order.articles.filter(
                                          (article: any) =>
                                            article.article_options &&
                                            article.article_options.length > 0
                                        ).length
                                      }{" "}
                                    </p>
                                  </td>
                                  <td
                                    className={
                                      showTable && showTableId == order.id
                                        ? "top_article active_tr"
                                        : "top_article"
                                    }></td>
                                  <td
                                    className={
                                      showTable && showTableId == order.id
                                        ? "top_article active_tr"
                                        : "top_article"
                                    }></td>
                                  <td
                                    className={
                                      showTable && showTableId == order.id
                                        ? "top_article active_tr"
                                        : "top_article"
                                    }>
                                    {order?.collaborators?.length > 0
                                      ? order?.collaborators?.map(
                                          (collab: any) => (
                                            <Tooltip
                                              title={collab.name}
                                              placement="bottom"
                                              key={collab.id}>
                                              <Avatar
                                                key={collab.id}
                                                alt={collab.name}
                                                src={
                                                  collab.logo
                                                    ? process.env
                                                        .REACT_APP_STORAGE_URL +
                                                      collab.logo
                                                    : "col"
                                                }
                                              />
                                            </Tooltip>
                                          )
                                        )
                                      : "-"}
                                  </td>
                                </tr>
                              </>
                            )}

                            {showTable && showTableId == order.id && (
                              <>
                                <td
                                  colSpan={10}
                                  style={{
                                    backgroundColor: "#FCFDFF",
                                    padding: "0rem",
                                    borderTop: "none",
                                  }}>
                                  <div className="scrollDiv">
                                    <table
                                      className="tableArticle"
                                      style={{ width: "100%" }}>
                                      {order.articles
                                        .filter(
                                          (elm: any) =>
                                            elm.article_options?.length > 0
                                        )
                                        .map((elm: any) => (
                                          <>
                                            <tr
                                              key={elm.id}
                                              onClick={() => {
                                                handleClick(elm.id);
                                                setShowOption(!showOption);
                                              }}
                                              style={{ cursor: "pointer" }}>
                                              <td
                                                width={"5%"}
                                                style={{
                                                  backgroundColor: "#FCFDFF",
                                                  borderTop: "none",
                                                }}>
                                                <button
                                                  onClick={() => {
                                                    handleClick(elm.id);
                                                    setShowOption(!showOption);
                                                  }}>
                                                  {showOption &&
                                                  showTr == elm.id ? (
                                                    <>
                                                      <FolderOpenOutlined
                                                        style={{
                                                          color: "#00468C",
                                                          fontSize: "20px",
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <FolderAddOutlined
                                                        style={{
                                                          color: "#00468C",
                                                          fontSize: "20px",
                                                        }}
                                                      />
                                                    </>
                                                  )}
                                                </button>
                                              </td>
                                              <td
                                                style={{
                                                  backgroundColor: "#FCFDFF",
                                                  borderTop: "none",
                                                  paddingLeft: 0,
                                                }}>
                                                <span>{elm.name}</span>
                                              </td>
                                            </tr>
                                            {showOption &&
                                              showTr == elm.id &&
                                              elm.article_options?.length >
                                                0 && (
                                                <tr>
                                                  <td
                                                    colSpan={10}
                                                    style={{
                                                      backgroundColor:
                                                        "#FCFDFF",
                                                      padding: "0.2rem",
                                                      paddingLeft: "4%",
                                                    }}>
                                                   {/*  <table className="tableArticle">
                                                      {elm.article_options?.map(
                                                        (option: any) => (
                                                          <>
                                                            <Link
                                                              to={`${history.location.pathname}/${order.id}/${elm.id}/${option.id}/progress`}>
                                                              <tr
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}>
                                                                <td
                                                                  style={{
                                                                    //   width: "50%",

                                                                    borderTop:
                                                                      "none",
                                                                  }}
                                                                  className="details_td">
                                                                  {option.name}
                                                                </td>

                                                                <td
                                                                  style={{
                                                                    //   width: "5%",
                                                                    paddingLeft:
                                                                      "0px",
                                                                    borderTop:
                                                                      "none",
                                                                  }}>
                                                                  {" "}
                                                                  {option
                                                                    ?.status
                                                                    ?.pivot
                                                                    ?.delivery_date
                                                                    ? moment(
                                                                        option
                                                                          ?.status
                                                                          ?.pivot
                                                                          ?.delivery_date
                                                                      ).format(
                                                                        "DD/MM/YYYY"
                                                                      )
                                                                    : "-"}
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    //    width: "21%",
                                                                    paddingLeft:
                                                                      "0px",
                                                                    borderTop:
                                                                      "none",
                                                                  }}></td>
                                                                <td
                                                                  style={{
                                                                    //  width: "8%",
                                                                    paddingLeft:
                                                                      "0px",
                                                                    borderTop:
                                                                      "none",
                                                                  }}>
                                                                  <Tag
                                                                    color="processing"
                                                                    style={{
                                                                      borderRadius:
                                                                        "50px",
                                                                      cursor:
                                                                        "pointer",
                                                                      fontWeight:
                                                                        "normal",
                                                                    }}>
                                                                    {
                                                                      option
                                                                        .status
                                                                        .name
                                                                    }
                                                                  </Tag>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    // width: "22%",
                                                                    paddingLeft:
                                                                      "0px",
                                                                    borderTop:
                                                                      "none",
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        genStatusMsg(
                                                                          option
                                                                            .status
                                                                            .name
                                                                        ).color,
                                                                    }}
                                                                    className="status_msg">
                                                                    {
                                                                      genStatusMsg(
                                                                        option
                                                                          .status
                                                                          .name
                                                                      ).msg
                                                                    }
                                                                  </span>
                                                                </td>
                                                              </tr>
                                                            </Link>
                                                          </>
                                                        )
                                                      )}
                                                    </table> */}
                                                     {elm.article_options?.map((option: any) => (
                                <>
                                  <Link
                                     to={`${history.location.pathname}/${order.id}/${elm.id}/${option.id}/progress`}>
                                    <div className="row row_option_name_progress">
                                      <div className="option_name_prog_direct_order">
                                        {option.name} 
                                      </div>
                                      <div className="deleviry_date_prog_direct_order">
                                        {" "}
                                        {option?.status?.pivot?.delivery_date
                                          ? moment(
                                              option?.status?.pivot
                                                ?.delivery_date
                                            ).format("DD/MM/YYYY")
                                          : "-"}
                                      </div>

                                      <div className="status_prog_direct_order">
                                        <Tag
                                          color="#182844"
                                          style={{
                                            borderRadius: "50px",
                                            cursor: "pointer",
                                            fontWeight: "normal",
                                          }}>
                                          {option.status.name}
                                        </Tag>
                                      </div>

                                      <div>
                                        <span
                                          style={{
                                            color: genStatusMsg(
                                              option.status.name
                                            ).color,
                                          }}
                                          className="status_msg">
                                          {genStatusMsg(option.status.name).msg}
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </>
                              ))}
                                                  </td>
                                                </tr>
                                              )}
                                          </>
                                        ))}
                                    </table>
                                  </div>
                                </td>
                              </>
                            )}
                            {/*    {showTable && showTr == order.id && (
                        <td
                          colSpan={7}
                          style={{
                            backgroundColor: "#FCFDFF",
                            padding: "0rem",
                            borderTop: "none",
                          }}
                        >
                          <table className="tableArticle">
                            <tbody>
                              {order.articles
                                .filter(
                                  (elm: any) => elm.article_options?.length > 0
                                )
                                .map((elm: any) => (
                                  <>
                                    <tr key={elm.id}>
                                      <td
                                        style={{
                                          backgroundColor: "#FCFDFF",
                                          borderTop: "none",
                                        }}
                                      >
                                        {elm.name}
                                      </td>
                                      <td
                                        width={"1%"}
                                        style={{
                                          backgroundColor: "#FCFDFF",
                                          borderTop: "none",
                                        }}
                                      >
                                        <button
                                          onClick={() =>
                                            setShowOption(!showOption)
                                          }
                                        >
                                          {showOption ? (
                                            <>
                                              <MinusOutlined
                                                style={{ color: "#00468C" }}
                                              ></MinusOutlined>
                                            </>
                                          ) : (
                                            <PlusOutlined
                                              style={{ color: "#00468C" }}
                                            ></PlusOutlined>
                                          )}
                                        </button>
                                      </td>
                                    </tr>
                                    {showOption &&
                                      elm.article_options?.length > 0 && (
                                        <tr>
                                          <td
                                            colSpan={7}
                                            style={{
                                              backgroundColor: "#FCFDFF",
                                              padding: "0.2rem",
                                            }}
                                          >
                                            {status == "completed" ? (
                                              <>
                                                <OptionCompleted
                                                  elm={elm}
                                                  order_id={order.id}
                                                ></OptionCompleted>
                                              </>
                                            ) : status == "in-progress" ? (
                                              <OptionInPrograss
                                                elm={elm}
                                                order_id={order.id}
                                              ></OptionInPrograss>
                                            ) : (
                                              <OptionCancelled
                                                elm={elm}
                                                order_id={order.id}
                                              ></OptionCancelled>
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                  </>
                                ))}
                            </tbody>
                          </table>
                        </td>
                      )} */}
                          </>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={10}>
                          {/*  <Pagination
                      defaultCurrent={orders && orders.current_page}
                      defaultPageSize={10}
                      total={orders && orders.total}
                      hideOnSinglePage
                      responsive
                      onChange={(page: any) =>
                        !filterBy.client && !filterBy.client
                          ? history.push(
                              `/all-clients/${params.id}/${params.tab_key}?page=${page}`
                            )
                          : onFilter(null, filterBy, page)
                      }
                    /> */}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  </div>
                </div>
              ) : status == "cancelled" ? (
                  < div className={"mt-3"}>
                    <div className="search-bar searchfaq" style={{width: "100%"}}>
                      <div className="input-container">
                        <input type="text" placeholder="Search your order" onChange={handleSearchInputChange}
                               className={"search_input"}/>
                        <HrIcon></HrIcon>
                        <button type="submit" className={"submit_input"}><SearchOutlined/></button>
                      </div>
                    </div>
                    <div style={{overflowY:"auto"}}>
                    <table className="table table-direct-order">
                      <thead>
                      <tr>
                        <th></th>
                        <th style={{paddingLeft: "0px"}}>Reference</th>
                        <th style={{paddingLeft: "0px"}}>Collection Name</th>
                        <th style={{paddingLeft: "0px"}}>Delete date</th>
                        <th style={{paddingLeft: "0px"}}>Status</th>
                        <th style={{paddingLeft: "0px"}}>
                          Number of article
                        </th>
                        <th style={{paddingLeft: "0px"}}>Action</th>
                        <th style={{paddingLeft: "0px"}}>Team members</th>
                        {/*  <th></th> */}
                      </tr>
                      </thead>
                      <tbody>
                      {orders &&
                          filterLoading == false &&
                          filteredData.map((order: any) => (
                              <>
                                {order.articles.filter(
                                    (article: any) =>
                                        article.article_options &&
                                        article.article_options.length > 0
                                ).length > 0 && (
                                    <>
                                      <tr
                                          key={order.id}
                                          onClick={() => {
                                            setShowTable(!showTable);
                                            handleClickTable(order.id);
                                          }}
                                          style={{cursor: "pointer"}}>
                                        <td
                                            width={"5%"}
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }>
                                          <button
                                              onClick={() => {
                                                setShowTable(!showTable);
                                                handleClickTable(order.id);
                                              }}>
                                            {showTable && showTableId == order.id ? (
                                                <>
                                                  <FolderOpenOutlined
                                                      style={{
                                                        color: "#00468C",
                                                        fontSize: "20px",
                                                      }}
                                                  />
                                                </>
                                            ) : (
                                                <>
                                                  <FolderAddOutlined
                                                      style={{
                                                        color: "#00468C",
                                                        fontSize: "20px",
                                                      }}
                                                  />
                                                  {/*                   <PlusSquareOutlined style={{ color: "#00468C" }} />
                                           */}{" "}
                                                </>
                                            )}
                                          </button>
                                        </td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }
                                            style={{width: "10%", paddingLeft: 0}}>
                                    <span
                                        style={{
                                          color: "#8894A1",
                                          fontSize: "16px",
                                        }}>
                                      {order.ref}
                                    </span>
                                        </td>

                                        <td
                                            scope="row"
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }
                                            style={{
                                              width: "15%",
                                              paddingLeft: "0px",
                                            }}>
                                          <span>{order.name}</span>
                                        </td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }
                                            style={{paddingLeft: "0px"}}>

                                        </td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }
                                            style={{paddingLeft: "0px"}}></td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }
                                            style={{paddingLeft: "0px"}}>
                                          <p
                                              className="mb-0"
                                              style={{marginLeft: "50px"}}>
                                            {
                                              order.articles.filter(
                                                  (article: any) =>
                                                      article.article_options &&
                                                      article.article_options.length > 0
                                              ).length
                                            }{" "}
                                          </p>
                                        </td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }></td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }></td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }>
                                          {order?.collaborators?.length > 0
                                              ? order?.collaborators?.map(
                                                  (collab: any) => (
                                                      <Tooltip
                                                          title={collab.name}
                                                          placement="bottom"
                                                          key={collab.id}>
                                                        <Avatar
                                                            key={collab.id}
                                                            alt={collab.name}
                                                            src={
                                                              collab.logo
                                                                  ? process.env
                                                                      .REACT_APP_STORAGE_URL +
                                                                  collab.logo
                                                                  : "col"
                                                            }
                                                        />
                                                      </Tooltip>
                                                  )
                                              )
                                              : "-"}
                                        </td>
                                      </tr>
                                    </>
                                )}

                                {showTable && showTableId == order.id && (
                                    <>
                                      <td
                                          colSpan={10}
                                          style={{
                                            backgroundColor: "#FCFDFF",
                                            padding: "0rem",
                                            borderTop: "none",
                                          }}>
                                        <div className="scrollDiv">
                                          <table
                                              className="tableArticle"
                                              style={{width: "100%"}}>
                                            {order.articles
                                                .filter(
                                                    (elm: any) =>
                                                        elm.article_options?.length > 0
                                                )
                                                .map((elm: any) => (
                                                    <>
                                                      <tr
                                                          key={elm.id}
                                                          style={{cursor: "pointer"}}
                                                          onClick={() => {
                                                            handleClick(elm.id);
                                                            setShowOption(!showOption);
                                                          }}>
                                                        <td
                                                            width={"5%"}
                                                            style={{
                                                              backgroundColor: "#FCFDFF",
                                                              borderTop: "none",
                                                            }}>
                                                          <button
                                                              onClick={() => {
                                                                handleClick(elm.id);
                                                                setShowOption(!showOption);
                                                              }}>
                                                            {showOption &&
                                                            showTr == elm.id ? (
                                                                <>
                                                                  <FolderOpenOutlined
                                                                      style={{
                                                                        color: "#00468C",
                                                                        fontSize: "20px",
                                                                      }}
                                                                  />
                                                                </>
                                                            ) : (
                                                                <>
                                                                  <FolderAddOutlined
                                                                      style={{
                                                                        color: "#00468C",
                                                                        fontSize: "20px",
                                                                      }}
                                                                  />
                                                                </>
                                                            )}
                                                          </button>
                                                        </td>
                                                        <td
                                                            style={{
                                                              backgroundColor: "#FCFDFF",
                                                              borderTop: "none",
                                                              paddingLeft: 0,
                                                            }}>
                                                          <span>{elm.name}</span>
                                                        </td>
                                                      </tr>
                                                      {showOption &&
                                                          showTr == elm.id &&
                                                          elm.article_options?.length >
                                                          0 && (
                                                              <tr>
                                                                <td
                                                                    colSpan={10}
                                                                    style={{
                                                                      backgroundColor:
                                                                          "#FCFDFF",
                                                                      padding: "0.2rem",
                                                                      paddingLeft: "4%",
                                                                    }}>
                                                                  {/* <table className="tableArticle">
                                                      {elm.article_options?.map(
                                                        (option: any) => (
                                                          <>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  //   width: "50%",

                                                                  borderTop:
                                                                    "none",
                                                                }}
                                                                className="details_td_direct_order_cancelled">
                                                                {option.name}
                                                              </td>

                                                              <td
                                                                style={{
                                                                  //   width: "5%",
                                                                  paddingLeft:
                                                                    "0px",
                                                                  borderTop:
                                                                    "none",
                                                                }}></td>
                                                              <td
                                                                style={{
                                                                  //    width: "21%",
                                                                  paddingLeft:
                                                                    "0px",
                                                                  borderTop:
                                                                    "none",
                                                                }}></td>
                                                              <td
                                                                style={{
                                                                  //  width: "8%",
                                                                  paddingLeft:
                                                                    "0px",
                                                                  borderTop:
                                                                    "none",
                                                                }}
                                                                className="status_td_direct_order_cancelled">
                                                                <Tag
                                                                  color="processing"
                                                                  style={{
                                                                    borderRadius:
                                                                      "50px",
                                                                    cursor:
                                                                      "pointer",
                                                                    fontWeight:
                                                                      "normal",
                                                                  }}>
                                                                  {
                                                                    option
                                                                      .status
                                                                      .name
                                                                  }
                                                                </Tag>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  // width: "22%",
                                                                  paddingLeft:
                                                                    "0px",
                                                                  borderTop:
                                                                    "none",
                                                                }}>
                                                                <div>
    
                                                                  <Button
                                                                    onClick={() =>
                                                                      showModalRestore(
                                                                        option.id
                                                                      )
                                                                    }
                                                                    icon={
                                                                      <InboxOutlined
                                                                        style={{
                                                                          color:
                                                                            "#00468c",
                                                                          verticalAlign:
                                                                            "1px",
                                                                        }}
                                                                      />
                                                                    }>
                                                                    Restore
                                                                  </Button>
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        )
                                                      )}
                                                    </table> */}
                                                                  {elm.article_options?.map((option: any) => (
                                                                      <>

                                                                        <div className="row row_option_name_cancelled">
                                                                          <div
                                                                              className="option_name_completed_direct_order">
                                                                            {option.name}
                                                                          </div>
                                                                          <div
                                                                              className="deleted_date_cancelled_direct_order">
                                                                            {" "}
                                                                            {option?.status?.pivot?.updated_at
                                                                                ? moment(
                                                                                    option?.status?.pivot
                                                                                        ?.updated_at
                                                                                ).format("DD/MM/YYYY")
                                                                                : "-"}
                                                                          </div>

                                                                          <div
                                                                              className="status_cancelled_direct_order">
                                                                            <Tag
                                                                                color="#182844"
                                                                                style={{
                                                                                  borderRadius: "50px",
                                                                                  cursor: "pointer",
                                                                                  fontWeight: "normal",
                                                                                }}
                                                                            >
                                                                              {option.status.name}
                                                                            </Tag>
                                                                          </div>

                                                                          <div>


                                                                            <Button
                                                                                onClick={() =>
                                                                                    showModalRestore(
                                                                                        option.id
                                                                                    )
                                                                                }
                                                                                className="restoreBtn"
                                                                            >
                                                                              Restore
                                                                            </Button>
                                                                          </div>
                                                                        </div>

                                                                      </>
                                                                  ))}
                                                                </td>
                                                              </tr>
                                                          )}
                                                    </>
                                                ))}
                                          </table>
                                        </div>
                                      </td>
                                    </>
                                )}
                                {/*    {showTable && showTr == order.id && (
                        <td
                          colSpan={7}
                          style={{
                            backgroundColor: "#FCFDFF",
                            padding: "0rem",
                            borderTop: "none",
                          }}
                        >
                          <table className="tableArticle">
                            <tbody>
                              {order.articles
                                .filter(
                                  (elm: any) => elm.article_options?.length > 0
                                )
                                .map((elm: any) => (
                                  <>
                                    <tr key={elm.id}>
                                      <td
                                        style={{
                                          backgroundColor: "#FCFDFF",
                                          borderTop: "none",
                                        }}
                                      >
                                        {elm.name}
                                      </td>
                                      <td
                                        width={"1%"}
                                        style={{
                                          backgroundColor: "#FCFDFF",
                                          borderTop: "none",
                                        }}
                                      >
                                        <button
                                          onClick={() =>
                                            setShowOption(!showOption)
                                          }
                                        >
                                          {showOption ? (
                                            <>
                                              <MinusOutlined
                                                style={{ color: "#00468C" }}
                                              ></MinusOutlined>
                                            </>
                                          ) : (
                                            <PlusOutlined
                                              style={{ color: "#00468C" }}
                                            ></PlusOutlined>
                                          )}
                                        </button>
                                      </td>
                                    </tr>
                                    {showOption &&
                                      elm.article_options?.length > 0 && (
                                        <tr>
                                          <td
                                            colSpan={7}
                                            style={{
                                              backgroundColor: "#FCFDFF",
                                              padding: "0.2rem",
                                            }}
                                          >
                                            {status == "completed" ? (
                                              <>
                                                <OptionCompleted
                                                  elm={elm}
                                                  order_id={order.id}
                                                ></OptionCompleted>
                                              </>
                                            ) : status == "in-progress" ? (
                                              <OptionInPrograss
                                                elm={elm}
                                                order_id={order.id}
                                              ></OptionInPrograss>
                                            ) : (
                                              <OptionCancelled
                                                elm={elm}
                                                order_id={order.id}
                                              ></OptionCancelled>
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                  </>
                                ))}
                            </tbody>
                          </table>
                        </td>
                      )} */}
                              </>
                          ))}
                      </tbody>
                      <tfoot>
                      <tr>
                        <td colSpan={10}>
                          {/*  <Pagination
                      defaultCurrent={orders && orders.current_page}
                      defaultPageSize={10}
                      total={orders && orders.total}
                      hideOnSinglePage
                      responsive
                      onChange={(page: any) =>
                        !filterBy.client && !filterBy.client
                          ? history.push(
                              `/all-clients/${params.id}/${params.tab_key}?page=${page}`
                            )
                          : onFilter(null, filterBy, page)
                      }
                    /> */}
                        </td>
                      </tr>
                      </tfoot>
                    </table>
                    </div>
                  </div>
              ) : (
                  <div className={"mt-3"}>
                    <div className="search-bar searchfaq" style={{width: "100%"}}>
                      <div className="input-container">
                        <input type="text" placeholder="Search your order" onChange={handleSearchInputChange}
                               className={"search_input"}/>
                        <HrIcon></HrIcon>
                        <button type="submit" className={"submit_input"}><SearchOutlined/></button>
                      </div>
                    </div>
                    <div style={{overflowY:"auto"}}>
                    <table className="table table-direct-order">
                      <thead>
                      <tr>
                        <th></th>
                        <th style={{paddingLeft: "0px"}}>Reference</th>
                        <th style={{paddingLeft: "0px"}}>Collection Name</th>
                        <th style={{paddingLeft: "0px"}}>Delivery Date</th>
                        <th style={{paddingLeft: "0px"}}>
                          Number of article{" "}
                        </th>
                        <th style={{paddingLeft: "0px"}}>Quantity</th>
                        <th style={{paddingLeft: "0px"}}>Action</th>
                        <th style={{paddingLeft: "0px"}}>Team members</th>
                        {/*  <th></th> */}
                      </tr>
                      </thead>
                      <tbody>
                      {orders &&
                          filterLoading == false &&
                          filteredData.map((order: any) => (
                              <>
                                {order.articles.filter(
                                    (article: any) =>
                                        article.article_options &&
                                        article.article_options.length > 0
                                ).length > 0 && (
                                    <>
                                      <tr
                                          key={order.id}
                                          onClick={() => {
                                            setShowTable(!showTable);
                                            handleClickTable(order.id);
                                          }}
                                          style={{cursor: "pointer"}}>
                                        <td
                                            width={"5%"}
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }>
                                          <button
                                              onClick={() => {
                                                setShowTable(!showTable);
                                                handleClickTable(order.id);
                                              }}>
                                            {showTable && showTableId == order.id ? (
                                                <>
                                                  <FolderOpenOutlined
                                                      style={{
                                                        color: "#00468C",
                                                        fontSize: "20px",
                                                      }}
                                                  />
                                                </>
                                            ) : (
                                                <>
                                                  <FolderAddOutlined
                                                      style={{
                                                        color: "#00468C",
                                                        fontSize: "20px",
                                                      }}
                                                  />
                                                  {/*                   <PlusSquareOutlined style={{ color: "#00468C" }} />
                                           */}{" "}
                                                </>
                                            )}
                                          </button>
                                        </td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }
                                            style={{width: "10%", paddingLeft: 0}}>
                                    <span
                                        style={{
                                          color: "#8894A1",
                                          fontSize: "16px",
                                        }}>
                                      {order.ref}
                                    </span>
                                        </td>

                                        <td
                                            scope="row"
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }
                                            style={{
                                              width: "15%",
                                              paddingLeft: "0px",
                                            }}>
                                          <span>{order.name}</span>
                                        </td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }
                                            style={{paddingLeft: "0px"}}></td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }
                                            style={{paddingLeft: "0px"}}></td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }
                                            style={{paddingLeft: "0px"}}>
                                          <p
                                              className="mb-0"
                                              style={{marginLeft: "50px"}}></p>
                                        </td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }></td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }></td>
                                        <td
                                            className={
                                              showTable && showTableId == order.id
                                                  ? "top_article active_tr"
                                                  : "top_article"
                                            }>
                                          {order?.collaborators?.length > 0
                                              ? order?.collaborators?.map(
                                                  (collab: any) => (
                                                      <Tooltip
                                                          title={collab.name}
                                                          placement="bottom"
                                                          key={collab.id}>
                                                        <Avatar
                                                            key={collab.id}
                                                            alt={collab.name}
                                                            src={
                                                              collab.logo
                                                                  ? process.env
                                                                      .REACT_APP_STORAGE_URL +
                                                                  collab.logo
                                                                  : "col"
                                                            }
                                                        />
                                                      </Tooltip>
                                                  )
                                              )
                                              : "-"}
                                        </td>
                                      </tr>
                                    </>
                                )}

                                {showTable && showTableId == order.id && (
                                    <>
                                      <td
                                          colSpan={10}
                                          style={{
                                            backgroundColor: "#FCFDFF",
                                            padding: "0rem",
                                            borderTop: "none",
                                          }}>
                                        <div className="scrollDiv">
                                          <table
                                              className="tableArticle"
                                              style={{width: "100%"}}>
                                            {order.articles
                                                .filter(
                                                    (elm: any) =>
                                                        elm.article_options?.length > 0
                                                )
                                                .map((elm: any) => (
                                                    <>
                                                      <tr
                                                          key={elm.id}
                                                          style={{cursor: "pointer"}}
                                                          onClick={() => {
                                                            handleClick(elm.id);
                                                            setShowOption(!showOption);
                                                          }}>
                                                        <td
                                                            width={"5%"}
                                                            style={{
                                                              backgroundColor: "#FCFDFF",
                                                              borderTop: "none",
                                                            }}>
                                                          <button
                                                              onClick={() => {
                                                                handleClick(elm.id);
                                                                setShowOption(!showOption);
                                                              }}>
                                                            {showOption &&
                                                            showTr == elm.id ? (
                                                                <>
                                                                  <FolderOpenOutlined
                                                                      style={{
                                                                        color: "#00468C",
                                                                        fontSize: "20px",
                                                                      }}
                                                                  />
                                                                </>
                                                            ) : (
                                                                <>
                                                                  <FolderAddOutlined
                                                                      style={{
                                                                        color: "#00468C",
                                                                        fontSize: "20px",
                                                                      }}
                                                                  />
                                                                </>
                                                            )}
                                                          </button>
                                                        </td>
                                                        <td
                                                            style={{
                                                              backgroundColor: "#FCFDFF",
                                                              borderTop: "none",
                                                              paddingLeft: 0,
                                                            }}>
                                                          <span>{elm.name} </span>
                                                        </td>
                                                      </tr>
                                                      {showOption &&
                                                          showTr == elm.id &&
                                                          elm.article_options?.length >
                                                          0 && (
                                                              <tr>
                                                                <td
                                                                    colSpan={10}
                                                                    style={{
                                                                      backgroundColor:
                                                                          "#FCFDFF",
                                                                      padding: "0.2rem",
                                                                      paddingLeft: "4%",
                                                                    }}>
                                                                  {/* <table className="tableArticle">
                                                      {elm.article_options?.map(
                                                        (option: any) => (
                                                          <>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  //   width: "50%",

                                                                  borderTop:
                                                                    "none",
                                                                }}
                                                                className="details_td_direct_order_cancelled">
                                                                {option.name}
                                                              </td>

                                                              <td
                                                                style={{
                                                                  //   width: "5%",
                                                                  paddingLeft:
                                                                    "0px",
                                                                  borderTop:
                                                                    "none",
                                                                }}></td>
                                                              <td
                                                                style={{
                                                                  //    width: "21%",
                                                                  paddingLeft:
                                                                    "0px",
                                                                  borderTop:
                                                                    "none",
                                                                }}>
                                                                {moment(
                                                                  option.status
                                                                    .pivot
                                                                    .delivery_date
                                                                ).format(
                                                                  "DD/MM/YYYY"
                                                                )}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  //  width: "8%",
                                                                  paddingLeft:
                                                                    "0px",
                                                                  borderTop:
                                                                    "none",
                                                                }}
                                                                className="status_td_direct_order_cancelled">
                                                                {
                                                                  option.status
                                                                    .pivot.qty
                                                                }
                                                              </td>
                                                              <td
                                                                style={{
                                                                  // width: "22%",
                                                                  paddingLeft:
                                                                    "0px",
                                                                  borderTop:
                                                                    "none",
                                                                }}>
                                                                <div>
                                                                  <Link
                                                                    to={`${history.location.pathname}/${order.id}/${elm.id}/${option.id}/progress`}>
                                                                    View History
                                                                  </Link>
                                                                 
                                                                  <Button
                                                                    size={
                                                                      "middle"
                                                                    }
                                                                    style={{
                                                                      borderRadius:
                                                                        "4px",
                                                                    }}
                                                                    loading={
                                                                      relaunchLoading
                                                                    }
                                                                    onClick={() =>
                                                                      showModalRestore(
                                                                        option.id
                                                                      )
                                                                    }>
                                                                    Relaunch
                                                                  </Button>
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        )
                                                      )}
                                                    </table> */}
                                                                  {elm.article_options?.map((option: any) => (
                                                                      <>

                                                                        <div
                                                                            className="row row_option_name_completed_user">
                                                                          <div
                                                                              className="option_name_completed_direct_order_user">
                                                                            {option.name}
                                                                          </div>
                                                                          <div
                                                                              className="deleviry_date_completed_direct_order_user">
                                                                            {" "}
                                                                            {option?.status?.pivot?.delivery_date
                                                                                ? moment(
                                                                                    option?.status?.pivot
                                                                                        ?.delivery_date
                                                                                ).format("DD/MM/YYYY")
                                                                                : "-"}
                                                                          </div>

                                                                          <div
                                                                              className="qty_completed_direct_order_user">
                                                                            {option.status.pivot.qty}
                                                                          </div>

                                                                          <div>


                                                                            <button className="relaunch" onClick={() =>
                                                                                showModalRestore(
                                                                                    option.id
                                                                                )
                                                                            }>Relaunch
                                                                            </button>

                                                                            <Link className="view_history"
                                                                                  to={`${history.location.pathname}/${order.id}/${elm.id}/${option.id}/progress`}>
                                                                              View History
                                                                            </Link>
                                                                          </div>
                                                                        </div>

                                                                      </>
                                                                  ))}
                                                                </td>
                                                              </tr>
                                                          )}
                                                    </>
                                                ))}
                                          </table>
                                        </div>
                                      </td>
                                    </>
                                )}
                              </>
                          ))}
                      </tbody>
                      <tfoot>
                      <tr>
                        <td colSpan={10}></td>
                      </tr>
                      </tfoot>
                    </table>
                    </div>
                    <Modal
                        title="Relaunch production"
                        visible={isModalRestoreCompleted}
                        onCancel={handleCancelComplted}
                        footer={[

                          <Button
                              className="restore_2"
                              //   loading={loading}
                              onClick={relaunchPreSMS}
                          >
                            Start again from PRE SMS
                          </Button>,
                          <Button
                              className="restore"
                              //  loading={loading}
                              onClick={relaunchProduction}
                          >
                            Straight to PRODUCTION
                          </Button>,
                        ]}
                    >
                      <p>In what stage would you like to relaunch the production? </p>
                    </Modal>
                  </div>
              )}

              <Modal
                  title="Restore Order"
                  visible={isModalRestore}
                  onCancel={handleCancel}
                  footer={[
                    <Button
                        className="restore_2"
                        loading={loading}
                        onClick={restore}>
                      Start again from PROTO
                    </Button>,
                    <Button
                        className="restore"
                        loading={loading}
                    onClick={restoreOptLeft}>
                    Where I left off please!
                  </Button>,
                 
                ]}>
                <p className={"text_par_model"}>In what stage would you like to restore your order? </p>
              </Modal>
            </>
          ) : (
            <Result
              status="404"
              title="No Orders"
              style={{ width: "100%" }}
              subTitle="Sorry, there's no orders sent with these options."
              extra={
                filterBy.client || filterBy.reference ? (
                  <Button
                    type="primary"
                    onClick={(e: any) => {
                      onFilter(e, { reference: "", client: 0, type: 0 }, 1);
                      setFilterBy({ reference: "", client: 0, type: 0 });
                    }}>
                    Clear Filter
                  </Button>
                ) : null
              }
            />
          )}
        </>
      ) : filterBy.type === 6 ? (
        <>
          {" "}
          {filterLoading ? (
            <div
              className="loading_orders"
              style={{ height: "50vh", width: "50vw", margin: "0 auto" }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : ordersCancel && ordersCancel.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Option Name</th>
                  <th>Article Name</th>
                  <th>Order Name</th>
                  <th>Delete Date </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {ordersCancel &&
                  ordersCancel.map((order: any) => (
                    <tr key={order.name}>
                      <td>{order.name}</td>
                      <td>{order.article_name}</td>
                      <td>
                        <p className="mb-0">{order.order_name}</p>
                      </td>
                      <td>
                        <p className="mb-0">
                          {moment(order.updated_at).format("MMMM DD YYYY")}
                        </p>{" "}
                      </td>
                      <td>
                        {" "}
                        <div>
                          <Popconfirm
                            placement="top"
                            title={
                              "Are you sure you want to delete this order ?"
                            }
                            onConfirm={() => deleteOpt(order.id)}
                            okText="Yes"
                            cancelText="No">
                            <Button
                              type="ghost"
                              size={"middle"}
                              className="mr-2"
                              style={{ borderRadius: "4px" }}
                              icon={
                                <DeleteOutlined style={{ color: "#00468c" }} />
                              }
                              loading={deleteLoading}>
                              Delete
                            </Button>
                          </Popconfirm>
                          <Popconfirm
                            placement="top"
                            title={
                              "Are you sure you want to restore this order ?"
                            }
                            onConfirm={() => restoreOpt(order.id)}
                            okText="Yes"
                            cancelText="No">
                            <Button
                              size={"middle"}
                              style={{ borderRadius: "4px" }}
                              icon={
                                <InboxOutlined
                                  style={{
                                    color: "#00468c",
                                    verticalAlign: "1px",
                                  }}
                                />
                              }
                              loading={restoreLoading}>
                              Restore
                            </Button>
                          </Popconfirm>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5}>
                    {/*   <Pagination
                    defaultCurrent={orders && orders.current_page}
                    defaultPageSize={10}
                    total={orders && orders.total}
                    hideOnSinglePage
                    responsive
                    onChange={(page: any) =>
                      !filterBy.client && !filterBy.client
                        ? history.push(
                            `/all-clients/${params.id}/${params.tab_key}?page=${page}`
                          )
                        : onFilter(null, filterBy, page)
                    }
                  /> */}
                  </td>
                </tr>
              </tfoot>
            </table>
          ) : (
            <Result
              status="404"
              title="No Orders"
              style={{ width: "100%" }}
              subTitle="Sorry, there's no orders sent with these options."
              extra={
                filterBy.client || filterBy.reference ? (
                  <Button
                    type="primary"
                    onClick={(e: any) => {
                      onFilter(e, { reference: "", client: 0, type: 0 }, 1);
                      setFilterBy({ reference: "", client: 0, type: 0 });
                    }}>
                    Clear Filter
                  </Button>
                ) : null
              }
            />
          )}
        </>
      ) : (
        <>
          {" "}
          {filterLoading ? (
            <div
              className="loading_orders"
              style={{ height: "50vh", width: "50vw", margin: "0 auto" }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : ordersCancel && ordersCancel.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Option Name</th>
                  <th>Article Name</th>
                  {filterBy.client == 0 && <th>Owner</th>}
                  <th>Order Name</th>
                  <th>Delivery date </th>
                </tr>
              </thead>
              <tbody>
                {ordersCancel &&
                  ordersCancel.map((order: any) => (
                    <tr key={order.name}>
                      <td>{order.name}</td>
                      <td className="d-flex">{order.article_name}</td>
                      {filterBy.client == 0 && <td>{order?.client?.name}</td>}
                      <td>
                        <p className="mb-0">{order.order_name}</p>
                      </td>
                      <td>
                        <p className="mb-0">
                          {moment(order?.status?.delivery_date).format(
                            "MMMM DD YYYY"
                          )}
                        </p>{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5}>
                    {/*   <Pagination
                  defaultCurrent={orders && orders.current_page}
                  defaultPageSize={10}
                  total={orders && orders.total}
                  hideOnSinglePage
                  responsive
                  onChange={(page: any) =>
                    !filterBy.client && !filterBy.client
                      ? history.push(
                          `/all-clients/${params.id}/${params.tab_key}?page=${page}`
                        )
                      : onFilter(null, filterBy, page)
                  }
                /> */}
                  </td>
                </tr>
              </tfoot>
            </table>
          ) : (
            <Result
              status="404"
              title="No Orders"
              style={{ width: "100%" }}
              subTitle="Sorry, there's no orders sent with these options."
              extra={
                filterBy.client || filterBy.reference ? (
                  <Button
                    type="primary"
                    onClick={(e: any) => {
                      onFilter(e, { reference: "", client: 0, type: 0 }, 1);
                      setFilterBy({ reference: "", client: 0, type: 0 });
                    }}>
                    Clear Filter
                  </Button>
                ) : null
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default DirectOrdersCompany;
