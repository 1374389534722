import { useEffect, useState } from "react";
import ProductHeader from "./ProductHeader";
import { Input, Spin, Popconfirm, Button, Divider, Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getType, updateProduct } from "../../actions/products-actions/actions";
import TagsTable from "./TagsTable";
import SimilarProductSelect from "./SimilarProductSelect";
import axios from "axios";
import {
  LoadingOutlined,
  DeleteOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import EditKnitFabric from "./EditKnitFabric";
import EditTechnics from "./EditTechnics";
import EditKnitWash from "./EditKnitWash";
import EditAccessories from "./EditAccessories";
const { TextArea } = Input;

const EditKnitProduct = ({ oneProduct, setEditMode, editMode }: any) => {
  const [productToUpdate, setProductToUpdate] = useState(oneProduct);
  const [data, setData] = useState<any>();
  const [options, setOptions] = useState<any>({
    fabric_types_options: null,
    colors_options: null,
    technics_options: null,
    washes_options: null,
    accessories_options: null,
    dress_codes_options: null,
    collections_options: null,
    fabrics_options: null,
  });
  const [prod, setProd] = useState<any>({
    similar_products: [],
    total_look_products: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    fabric_types,
    colors,
    techniques,
    washes,
    tags,
    labels,
    accessories,
    isLoadingUpdate,
    dress_codes,
    collections,
    fabrics,
  } = useSelector((state: any) => state.products);
  const { user } = useSelector((state: any) => state.auth);
  const [isUpdated, setIsUpdated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    //   dispatch(getType("fabric_types"));
    //   dispatch(getType("colors"));
    if (collections.length === 0) {
      dispatch(getType("collections"));
    }
    if (techniques.length === 0) {
      dispatch(getType("technics"));
    }
    if (washes.length === 0) {
      dispatch(getType("washes"));
    }
    if (tags.length === 0) {
      dispatch(getType("tags"));
    }
    //   dispatch(getType("labels"));
    if (accessories.length === 0) {
      dispatch(getType("accessories"));
    }
    //   dispatch(getType("dress_codes"));
    if (fabrics === undefined) {
      dispatch(getType("fabrics"));
    }
  }, []);

  useEffect(() => {
    setOptions({
      ...options,
      fabric_types_options: fabric_types,
      colors_options: colors,
      technics_options: techniques,
      washes_options: washes,
      tags_options: tags,
      labels_options: labels,
      accessories_options: accessories,
      dress_codes_options: dress_codes,
      collections_options: collections,
      fabrics_options: fabrics,
    });
    setData({
      ...data,
      fabric_types: productToUpdate.fabric_types.map((el: any) => {
        return {
          fabric_type_id: el.id,
          weight: el.weight,
          price: el.price?.toFixed(2),
          price_two: el.price_two?.toFixed(2) || 0,
        };
      }),
      washes: productToUpdate.washes.map((el: any) => {
        return {
          wash_id: el.id,
          eim_score: el.eim_score,
          wash_price: el.wash_price?.toFixed(2),
          description:
            el.description || productToUpdate?.wash_description || "",
        };
      }),
      technics: productToUpdate.techniques.map((el: any) => {
        return {
          technic_id: el.id,
          technic_price: +el.price?.toFixed(2),
        };
      }),
      accessories: productToUpdate.accessories.map((el: any) => {
        return {
          accessory_id: el.id,
          price: el.price?.toFixed(2),
        };
      }),
    });
  }, [
    fabric_types,
    colors,
    techniques,
    washes,
    tags,
    labels,
    accessories,
    dress_codes,
    collections,
    fabrics,
  ]);

  const getProducts = async () => {
    let token = localStorage.getItem("token");
    setIsLoading(true);
    let similar =
      process.env.REACT_APP_API_URL + `/products/${oneProduct.id}/similar`;
    let total_look =
      process.env.REACT_APP_API_URL + `/products/${oneProduct.id}/total-look`;
    const similarRequest = axios.get(similar, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const totalRequest = axios.get(total_look, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    axios
      .all([similarRequest, totalRequest])
      .then(
        axios.spread((...responses) => {
          const similar_products = responses[0].data.data;
          const total_look_products = responses[1].data.data;

          setProd({ similar_products, total_look_products });
          setIsLoading(false);
        })
      )
      .catch((errors) => {
        setError(errors);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getProducts();
  }, []);

  const onChangeSimilarProducts = (value: any, type: string) => {
    setProd({ ...prod, [type]: value });
    setData({ ...data, [type]: value.map((x: any) => x.key) });
    setIsUpdated(true);
  };

  const handleChange = (e: any) => {
    setProductToUpdate({ ...productToUpdate, [e.target.name]: e.target.value });
    setData({ ...data, [e.target.name]: e.target.value });
    setIsUpdated(true);
  };

  const selectFavBy = (value: string, key: string) => {
    setProductToUpdate({ ...productToUpdate, [key]: +value });
    setData({ ...data, [key]: +value });
    setIsUpdated(true);
  };

  const isMasked = (value: boolean, key: string) => {
    setProductToUpdate({ ...productToUpdate, [key]: +value });
    setData({ ...data, [key]: +value });
    setIsUpdated(true);
  };

  const genExtra = (i: number) => (
    <Popconfirm
      placement="right"
      title={"Are you sure ?"}
      onConfirm={() => {
        setIsUpdated(true);
        setProductToUpdate({
          ...productToUpdate,
          fabric_types: productToUpdate.fabric_types.filter(
            (el: any, idx: number) => idx !== i
          ),
        });
        setData({
          ...data,
          fabric_types: data.fabric_types.filter(
            (el: any, idx: number) => idx !== i
          ),
        });
      }}
      okText="Yes"
      cancelText="No"
      disabled={
        !user.permissions.includes("product.fabric.management") &&
        !user.permissions.includes("product.update")
      }
    >
      <Button
        type="default"
        className="delete_wash_update"
        disabled={
          !user.permissions.includes("product.fabric.management") &&
          !user.permissions.includes("product.update")
        }
        onClick={(event: any) => event.stopPropagation()}
      >
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  );
  const genExtraWash = (i: number) => (
    <Popconfirm
      placement="right"
      title={"Are you sure ?"}
      onConfirm={() => {
        setIsUpdated(true);
        setProductToUpdate({
          ...productToUpdate,
          washes: productToUpdate.washes.filter(
            (el: any, idx: number) => idx !== i
          ),
        });
        setData({
          ...data,
          washes: data.washes.filter((el: any, idx: number) => idx !== i),
        });
      }}
      disabled={
        !user.permissions.includes("product.wash.management") &&
        !user.permissions.includes("product.update")
      }
      okText="Yes"
      cancelText="No"
    >
      <Button
        type="default"
        className="delete_wash_update"
        onClick={(event: any) => event.stopPropagation()}
        disabled={
          !user.permissions.includes("product.wash.management") &&
          !user.permissions.includes("product.update")
        }
      >
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  );
  const genExtraForTech = (i: number) => (
    <Popconfirm
      placement="right"
      title={"Are you sure ?"}
      onConfirm={() => {
        setIsUpdated(true);
        setProductToUpdate({
          ...productToUpdate,
          techniques: productToUpdate.techniques.filter(
            (el: any, idx: number) => idx !== i
          ),
        });
        setData({
          ...data,
          technics: data.technics.filter((el: any, idx: number) => idx !== i),
        });
      }}
      disabled={!user.permissions.includes("product.update")}
      okText="Yes"
      cancelText="No"
    >
      <Button
        type="default"
        className="delete_wash_update"
        onClick={(event: any) => event.stopPropagation()}
        disabled={!user.permissions.includes("product.update")}
      >
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  );
  const genExtraForAccess = (i: number) => (
    <Popconfirm
      placement="right"
      title={"Are you sure ?"}
      onConfirm={() => {
        setIsUpdated(true);
        setProductToUpdate({
          ...productToUpdate,
          accessories: productToUpdate.accessories.filter(
            (el: any, idx: number) => idx !== i
          ),
        });
        setData({
          ...data,
          accessories: data.accessories.filter(
            (el: any, idx: number) => idx !== i
          ),
        });
      }}
      okText="Yes"
      cancelText="No"
      disabled={!user.permissions.includes("product.update")}
    >
      <Button
        type="default"
        className="delete_wash_update"
        onClick={(event: any) => event.stopPropagation()}
        disabled={!user.permissions.includes("product.update")}
      >
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  );

  const handleChangeFabric = (num: number, key: string, id: any) => {
    setIsUpdated(true);
    setData({
      ...data,
      fabric_types: data.fabric_types.map((ft: any) =>
        ft.fabric_type_id === id ? { ...ft, [key]: num } : ft
      ),
    });
  };
  const handleChangeTech = (num: number, id: any) => {
    setIsUpdated(true);
    setData({
      ...data,
      technics: data.technics.map((tech: any) =>
        tech.technic_id === id ? { ...tech, technic_price: num } : tech
      ),
    });
  };
  const handleChangeAccess = (num: number, id: any) => {
    setIsUpdated(true);
    setData({
      ...data,
      accessories: data.accessories.map((access: any) =>
        access.accessory_id === id ? { ...access, price: num } : access
      ),
    });
  };

  const addNewFabric = () => {
    setIsUpdated(true);
    setProductToUpdate({
      ...productToUpdate,
      fabric_types: [
        ...productToUpdate.fabric_types,
        {
          id: undefined,
          name: "",
          weight: 0,
          price: 0,
          price_two: 0,
        },
      ],
    });
    setData({
      ...data,
      fabric_types: [
        ...data.fabric_types,
        {
          fabric_type_id: undefined,
          weight: 0,
          price: 0,
          price_two: 0,
        },
      ],
    });
  };
  const addNewTech = () => {
    setIsUpdated(true);
    setProductToUpdate({
      ...productToUpdate,
      techniques: [
        ...productToUpdate.techniques,
        {
          id: undefined,
          name: "",
          price: 0,
        },
      ],
    });
    setData({
      ...data,
      technics: [
        ...data.technics,
        {
          technic_id: undefined,
          technic_price: 0,
        },
      ],
    });
  };
  const addNewAccess = () => {
    setIsUpdated(true);
    setProductToUpdate({
      ...productToUpdate,
      accessories: [
        ...productToUpdate.accessories,
        {
          id: undefined,
          name: "",
          price: 0,
        },
      ],
    });
    setData({
      ...data,
      accessories: [
        ...data.accessories,
        {
          accessory_id: undefined,
          price: 0,
        },
      ],
    });
  };
  const addNewWash = () => {
    setIsUpdated(true);
    setProductToUpdate({
      ...productToUpdate,
      washes: [
        ...productToUpdate.washes,
        {
          id: undefined,
          colors: [],
          name: "",
          eim_score: 0,
          wash_price: 0,
          description: "",
        },
      ],
    });
    setData({
      ...data,
      washes: [
        ...data.washes,
        {
          wash_id: undefined,
          colors: [],
          eim_score: 0,
          wash_price: 0,
          description: "",
        },
      ],
    });
  };

  const handleChangeMultiWash = (num: any, key: string, el: any) => {
    if (num.target) {
      setProductToUpdate({
        ...productToUpdate,
        washes: productToUpdate.washes.map((wash: any) =>
          wash.id === el.id
            ? { ...wash, [num.target.name]: num.target.value }
            : wash
        ),
      });
      setData({
        ...data,
        washes: data.washes.map((wash: any) =>
          wash.wash_id === el.id
            ? { ...wash, [num.target.name]: num.target.value }
            : wash
        ),
      });
    } else {
      setProductToUpdate({
        ...productToUpdate,
        washes: productToUpdate.washes.map((wash: any) =>
          wash.id === el.id ? { ...wash, [key]: num } : wash
        ),
      });
      setData({
        ...data,
        washes: data.washes.map((wash: any) =>
          wash.wash_id === el.id ? { ...wash, [key]: num } : wash
        ),
      });
    }
    setIsUpdated(true);
  };

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    if (isUpdated) {
      setVisible(true);
      return;
    } else {
      setEditMode(false);
      return;
    }
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    oneProduct && (
      <>
        <ProductHeader
          oneProduct={productToUpdate}
          setEditMode={setEditMode}
          editMode={editMode}
          handleChange={handleChange}
        />
        <div className="details details_update">
          <div className="details_fabric">
            {/*    <div>
              <Divider orientation="left" plain>
                <h3 className="fabric_title rubric_title">Fabric</h3>
              </Divider>
              {productToUpdate && productToUpdate.fabric_details && (
                <TextArea
                  disabled={
                    !user.permissions.includes("product.fabric.management") &&
                    !user.permissions.includes("product.update")
                  }
                  rows={4}
                  className="paragraphe fabric_text "
                  value={productToUpdate.fabric_details}
                  onChange={(e: any) => {
                    setProductToUpdate({
                      ...productToUpdate,
                      fabric_details: e.target.value,
                    });
                    setData({ ...data, fabric_details: e.target.value });
                  }}
                />
              )}
            </div> */}
            {/*   <EditKnitFabric
              productToUpdate={productToUpdate}
              genExtra={genExtra}
              setProductToUpdate={setProductToUpdate}
              setData={setData}
              data={data}
              options={options}
              handleChange={handleChange}
              handleChangeFabric={handleChangeFabric}
              addNewFabric={addNewFabric}
            /> */}
            <Divider orientation="left" plain>
              <h3 className="rubric_title">Techniques</h3>
            </Divider>
            <EditTechnics
              productToUpdate={productToUpdate}
              genExtraForTech={genExtraForTech}
              setProductToUpdate={setProductToUpdate}
              setData={setData}
              data={data}
              options={options}
              handleChangeTech={handleChangeTech}
              addNewTech={addNewTech}
            />
            <Divider orientation="left" plain>
              <h3 className="rubric_title">Washes</h3>
            </Divider>
            <EditKnitWash
              productToUpdate={productToUpdate}
              genExtraWash={genExtraWash}
              setProductToUpdate={setProductToUpdate}
              data={data}
              setData={setData}
              options={options}
              handleChangeMultiWash={handleChangeMultiWash}
              handleChange={handleChange}
              addNewWash={addNewWash}
            />
            <Divider orientation="left" plain>
              <h3 className="rubric_title">Accessories</h3>
            </Divider>
            <EditAccessories
              productToUpdate={productToUpdate}
              genExtraForAccess={genExtraForAccess}
              setProductToUpdate={setProductToUpdate}
              setData={setData}
              data={data}
              options={options}
              handleChangeAccess={handleChangeAccess}
              addNewAccess={addNewAccess}
            />
            <Divider orientation="left" plain>
              <h3 className="rubric_title">Details</h3>
            </Divider>
            <table style={{ width: "100%" }}>
              <tbody>
                <TagsTable
                  handleChange={handleChange}
                  productToUpdate={productToUpdate}
                  setProductToUpdate={setProductToUpdate}
                  data={data}
                  setData={setData}
                  options={options}
                  selectFavBy={selectFavBy}
                  isMasked={isMasked}
                  mode="knit"
                />
                <SimilarProductSelect
                  prod={prod}
                  type={"similar_products"}
                  onChangeSimilarProducts={onChangeSimilarProducts}
                  isLoading={isLoading}
                />
                <SimilarProductSelect
                  prod={prod}
                  type={"total_look_products"}
                  onChangeSimilarProducts={onChangeSimilarProducts}
                  isLoading={isLoading}
                />
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-end update_product_footer">
          <button
            className="submit_button_white submit_button_white_add"
            onClick={showDrawer}
            style={{ padding: "20px 70px", fontSize: "1rem" }}
          >
            Cancel
          </button>
          <button
            className="submit_button_blue submit_button_white_add ml-3"
            style={{ padding: "20px 70px", fontSize: "1rem" }}
            onClick={() =>
              dispatch(updateProduct(data, productToUpdate.id, setEditMode))
            }
            disabled={!isUpdated}
          >
            {isLoadingUpdate ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
        <div>
          <Drawer
            placement="bottom"
            closable={false}
            onClose={onClose}
            mask
            className="cancel_drawer_confirm"
            visible={visible}
            getContainer={false}
            height={200}
            style={{ position: "absolute" }}
          >
            <div className="d-flex align-items-center mt-1 px-4">
              <WarningOutlined className="warn-icon-cancel mr-4" />
              <h5 className="mb-0">
                Are you sure you want to discard these changes? Your changes
                will be lost.
              </h5>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-center">
              <button
                className="submit_button_white cancel_drawer_btn px-0 py-3 mr-4"
                style={{ fontSize: "1rem", fontWeight: "bold" }}
                onClick={() => setVisible(false)}
              >
                NO
              </button>
              <button
                className="submit_button_blue cancel_drawer_btn px-0 py-3"
                style={{ fontWeight: "bold", fontSize: "1rem" }}
                onClick={() => {
                  setVisible(false);
                  setEditMode(false);
                }}
              >
                YES
              </button>
            </div>
          </Drawer>
        </div>
      </>
    )
  );
};

export default EditKnitProduct;
