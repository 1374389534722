import { useRouteMatch, Route, Switch } from "react-router-dom";
import "../../sass/MyOrders.scss";
import DirectOrders from "./DirectOrders";
import DirectOrdersDetails from "./DirectOrdersDetails";

export default function DirectOrdersContainer({
  orderStatus,
  selectedDate,
}: {
  orderStatus: string;
  selectedDate: any;
}) {
  const { path, url } = useRouteMatch();

  return (
    <div className="my_orderss">
      <Switch>
        <Route path={`${path}`} exact>
          <DirectOrders
            orderType="direct_orders"
            url={url}
            orderStatus={orderStatus}
            selectedDate={selectedDate}
          />
        </Route>
        <Route path={`${path}/:id`}>
          <DirectOrdersDetails orderStatus={orderStatus} />
        </Route>
        <Route>
          <h1>Page Not Found</h1>
        </Route>
      </Switch>
    </div>
  );
}
