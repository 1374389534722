import { faFileImage, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popconfirm } from "antd";
import { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

interface Props {
  file: any;
  customization?: boolean;
  detachFile?: any;
  objectKey?: string;
}

export const FileLightBox = ({
  file,
  customization,
  detachFile,
  objectKey,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return file ? (
    <>
      <div
        className={
          objectKey === "style_files"
            ? "d-flex flex-column"
            : "col-md-4 col-6 p-1"
        }
      >
        {customization && (
          <Popconfirm
            placement="topLeft"
            title={"Delete this file ?"}
            onConfirm={() => detachFile(objectKey, file)}
            okText="Yes"
            cancelText="No"
          >
            <div
              className="delete_attached_cusomization w-100 m-0 py-1 rounded text-center"
              style={{ background: "#f4f7ff" }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ color: "#00468c" }} />
            </div>
          </Popconfirm>
        )}
        {!customization ? (
          file?.includes(".pdf") ? (
            <a
              href={process.env.REACT_APP_STORAGE_URL + file}
              className="file_option_miniature mb-3 py-4 px-4 text-center"
              target="_blank"
              style={{ textDecoration: "none", color: "gray" }}
            >
              <FontAwesomeIcon icon={faFilePdf} />
              {file.slice(20)}
            </a>
          ) : (
            <div
              className="file_option_miniature mb-3 py-4 px-4 text-center"
              onClick={() => setIsOpen(true)}
            >
              <FontAwesomeIcon icon={faFileImage} />
              <p className="m-0 file_name mt-2" style={{ fontSize: 12 }}>
                {customization ? file.path : file.slice(20)}
              </p>
            </div>
          )
        ) : file?.path?.includes(".pdf") ? (
          <a
            href={file.preview}
            className="file_option_miniature mb-3 py-4 px-4 text-center"
            target="_blank"
            style={{ textDecoration: "none", color: "gray" }}
          >
            <FontAwesomeIcon icon={faFilePdf} />
            {file.path}
          </a>
        ) : file?.type?.includes("pdf") ? (
          <a
            href={file.preview}
            className="file_option_miniature mb-3 py-4 px-4 text-center"
            target="_blank"
            style={{ textDecoration: "none", color: "gray" }}
          >
            <FontAwesomeIcon icon={faFilePdf} />
            {file.path}
          </a>
        ) : (
          <div
            className="file_option_miniature mb-3 py-4 px-4 text-center"
            onClick={() => setIsOpen(true)}
          >
            <FontAwesomeIcon icon={faFileImage} />
            <p
              className="m-0 file_name mt-2"
              style={{
                fontSize: 12,

                whiteSpace: "pre-wrap",
                maxWidth: "100px",
                wordBreak: "break-all",
              }}
            >
              {customization ? file.path : file.slice(20)}
            </p>
          </div>
        )}
      </div>
      {isOpen && customization && !file.path?.includes(".pdf") && (
        <Lightbox
          mainSrc={
            customization
              ? file.preview
              : process.env.REACT_APP_STORAGE_URL + file
          }
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
      {isOpen && !customization && !file?.includes(".pdf") && (
        <Lightbox
          mainSrc={
            customization
              ? file.preview
              : process.env.REACT_APP_STORAGE_URL + file
          }
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </>
  ) : null;
};
