import React from "react";

export type ILazerIconProps = {};

const EnterIcon: React.FC<ILazerIconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
    >
      <path
        d="M3.55664 8.18359L1.15039 5.94922C0.935547 5.75586 0.935547 5.39062 1.15039 5.19727L3.55664 2.96289C3.70703 2.8125 3.92188 2.79102 4.11523 2.87695C4.30859 2.94141 4.4375 3.13477 4.4375 3.34961L4.41602 5.04688H10.9473V0.578125C10.9473 0.298828 11.1621 0.0625 11.4629 0.0625C11.7422 0.0625 11.9785 0.298828 11.9785 0.578125V5.5625C11.9785 5.86328 11.7422 6.07812 11.4629 6.07812H4.41602V7.79688C4.41602 8.01172 4.28711 8.20508 4.09375 8.26953C3.90039 8.35547 3.70703 8.33398 3.55664 8.18359Z"
        fill="#00468C"
      />
    </svg>
  );
};

export { EnterIcon };
