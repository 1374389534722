import React from "react";

export type ICopyIconProps = {};

const CopyIcon: React.FC<ICopyIconProps> = ({}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9844 0.984375V3H3.98438V17.0156H2.01562V3C2.01562 1.92188 2.90625 0.984375 3.98438 0.984375H15.9844ZM18.9844 5.01562C20.1094 5.01562 21 5.90625 21 6.98438V21C21 22.0781 20.1094 23.0156 18.9844 23.0156H8.01562C6.89062 23.0156 6 22.0781 6 21V6.98438C6 5.90625 6.89062 5.01562 8.01562 5.01562H18.9844ZM18.9844 21V6.98438H8.01562V21H18.9844Z"
        fill="#182844"
      />
    </svg>
  );
};

export { CopyIcon };
