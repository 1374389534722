import { useState, useEffect, useCallback, useRef } from "react";
import { Tabs, Row, Col, Result, Collapse, Divider, Input } from "antd";
import { useParams, useLocation, Link } from "react-router-dom";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import axiosInstance from "../../config/axios-instance";
import { useQuery } from "react-query";
import { getCustomizedProduct } from "../../actions/order-actions/actions";
import logo_green from "../../img/logo_green.svg";
import moment from "moment";

interface Props {}

export const TechPackCutsom = (props: Props) => {
  const location = useLocation();
  const params: any = useParams();

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  const [matrix, setmatrix] = useState([]);
  const [imageCutomization, setCustomizedProduct] = useState("");

  const { product_id, order_id } = useParams<{
    product_id: string;
    order_id: string;
  }>();
  const {
    data: product,
    isLoading: loadingProduct,
    isError,
    refetch,
  } = useQuery(
    `customized-product-${product_id}`,
    () => getCustomizedProduct(+product_id),
    {
      retry: false,
    }
  );

  useEffect(() => {
    showMatrix();
    if (product?.data?.laser_images_drawing) {
      const filePathArray = JSON.parse(product?.data?.laser_images_drawing);

      const filePath = filePathArray[0].file;
      setCustomizedProduct(filePath);
    }
  }, [product]);

  const showMatrix = () => {
    let found = false;

    product?.data.orders_status.map((el: any) => {
      if (el.size_qty) {
        const length = JSON.parse(el.size_qty).length;
        const rows = Math.ceil(length / 3);

        const newMatrix = [];
        let count = 0;

        for (let i = 0; i < rows; i++) {
          const row = [];
          for (let j = 0; j < 3; j++) {
            row.push(JSON.parse(el.size_qty)[count] || "");
            count++;
          }
          newMatrix.push(row);
        }

        //@ts-ignore
        setmatrix(newMatrix);
        found = true;
      }
      if (!found) {
        setmatrix([]);
      }
    });
  };

  return (
    <>
      <div
        style={{
          paddingTop: "130px",
          paddingRight: "50px",
          paddingLeft: "50px",
        }}
      >
        <div className="products_page_container_faq">
          <Row>
            <Col span={8}>
              <div style={{ marginBottom: "20px" }}>
                <img
                  src={logo_green}
                  alt="logo Demco"
                  className="login_form_logo"
                />
              </div>
            </Col>
            <Col span={8} offset={8}>
              <div>
                <span> Order Date : </span>
                {moment(product?.data.updated_at).format("DD-MM-YYYY")}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div>
                {" "}
                <span> Collection Name : </span> {product?.data.collection_name}
              </div>
              <div>
                {" "}
                <span> Article Name : </span>
                {product?.data.name}
              </div>
              <div>
                {" "}
                <span> Season : </span>
                {product?.data.saison ?? "-"}
              </div>
              <div>
                <span> Delivery Date : </span>{" "}
                {moment(product?.data.delivery_date).format("DD-MM-YYYY")}
              </div>
            </Col>
            <Col span={8} offset={8}>
              <div>
                {" "}
                <span> Company : </span>
                {product?.data.company.name}
              </div>
              <div>
                {" "}
                <span> Client Name : </span>
                {product?.data.client.name}
              </div>
              <div>
                {" "}
                <span> Client Email : </span>
                {product?.data.client.email}
              </div>
              <div>
                {" "}
                <span> Client Collaborators : </span>
                {product?.data.client.email}
              </div>
            </Col>
          </Row>
          <Row>
            <p>
              {matrix.length > 0 ? (
                <div>
                  <table style={{ width: "100%" }}>
                    <th
                      style={{
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      Size
                    </th>
                    <th
                      style={{
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      Length
                    </th>
                    <th
                      style={{
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      Qty
                    </th>
                    <tbody>
                      {matrix.map((row: any, rowIndex: any) => (
                        <tr
                          key={rowIndex}
                          style={{
                            border: "1px solid #CCCCCC",
                          }}
                        >
                          {row.map((cell: any, cellIndex: any) => (
                            <td
                              key={cellIndex}
                              style={{
                                border: "1px solid #CCCCCC",
                              }}
                            >
                              {cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="p-3">-</div>
              )}
            </p>
          </Row>

          <Row>
            <div className="col-12">
              <div>
                <span> Style Name : </span>
                {product?.data.style}
              </div>
              <div>
                <span> Style Details : </span>
                {product?.data.style}
              </div>
              <div>
                <span> Fabric Name : </span>
                {product?.data.fabric}
              </div>
              <div>
                <span> Fabric Details : </span>
                {product?.data.fabric_details}
              </div>
            </div>
          </Row>
          <Row>
            <img
              src={process.env.REACT_APP_STORAGE_URL + imageCutomization}
              alt=""
            />
          </Row>
        </div>
      </div>
    </>
  );
};
