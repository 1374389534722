import {
  Drawer,
  Avatar,
  Divider,
  Button,
  Result,
  Spin,
  Form,
  Row,
  Col,
  Input,
  Select,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../../config/axios-instance";
import { useDropzone } from "react-dropzone";
import "../../components/Library/StyleLibarby.css";
import {
  DeleteOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import CategoriesServices from "../../actions/left-menu-actions/services";

import { useMutation, useQuery } from "react-query";
import { MyNotif } from "../NotifIcons/NotifIcons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
const { Option, OptGroup } = Select;

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

interface IData {
  fullname: string;
  email: string;
  title: string;
  logo: string;
  genders: number[];
}

const EditCompany = ({ onClose, visible, company, refetch }: any) => {
  const [data, setData] = useState<any>();
  const [isVisible, setIsVisible] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [typeFilter, setTypeFilter] = useState("denim");
  const [companyEdit, setCompany] = useState<any>("");
  const [editname,setEditName]=useState<any>('');
  const [files, setFiles] = useState<any>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",

    onDrop: (acceptedFiles: any) => {
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const removeFile = (index: any) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const thumbs = files.map((file: any, index: any) => (
    <>
      <div
        style={{
          display: "inline-flex",
          borderRadius: 2,
          border: "1px solid #eaeaea",
          marginBottom: 8,
          marginRight: 8,
          width: 100,
          height: 100,
          boxSizing: "border-box",
          position: "relative",
        }}
        key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
        {/*   <button>
          Remove
        </button> */}

        <DeleteOutlined
          className="btn-remove"
          onClick={() => removeFile(index)}
        />
      </div>
    </>
  ));

  useEffect(() => {
    setCompany(company);
   /*  console.log(company);
    console.log(companyEdit); */
  }, [company]);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  const { register, control, handleSubmit, errors, setValue, getValues } =
    useForm<any>();
  const width = useBreakpoint();
  const params = useParams<any>();
  const { user, isLoading, isAdmin } = useSelector((state: any) => state.auth);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values: IData) => {
    console.log("Success:", values);
    // addCompany(values);
  };

  

  const { data: clients, isLoading: gendersIsLoading } = useQuery(
    "clients",
    CategoriesServices.getClients
  );
  const errorBorder = (field: string) => {
    return {
      borderBottom: errors[field]?.type === "required" ? "1px 1px red" : "",
      boxShadow:
        errors[field]?.type === "required"
          ? "0 0 0 2px rgb(255,0,0, 0.4)"
          : "none",
    };
  };

  const editName =(e:any)=>{
    e.preventDefault();
    setEditName(e.target.value);
  }

  const EditComapany = (data: any) => {
    let token = localStorage.getItem("token");
    let formData = new FormData();
    Object.keys(data).map((el: string) => formData.append(el, data[el]));
    console.log(formData);

      return axiosInstance({
        method: "post",
        url: `/admin/editcompany/${companyEdit.id}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        MyNotif("Company edited successfully.", "success");
        onClose();
        refetch();
      });
    
  };

 
  return (
    <>
      <Drawer
        //   title="Invite new member"
        placement={"right"}
        //   closable={true}
        onClose={onClose}
        closable={true}
        visible={visible}
        width={width.md ? "33%" : "100%"}
        bodyStyle={{ padding: 20, paddingBottom: 80, background: "#fcfdff " }}>
        <h1 className="h1-blue-hj" style={{ marginTop: "20px", padding: 20 }}>
          Edit Company
        </h1>
        <form
       
          className="invite-teammate-drawer p-3"
          onSubmit={handleSubmit(EditComapany)}>
          <Row>
            <Col span={24}>
              <label htmlFor="Name" className="folder_label">
                Name
              </label>
              <Controller
                name="name"
                className="h2-blue-hj my-2"
                control={control}
                render={(field) => (
                  <Input
                    {...field}
                    size="large"
                    type="text"
                    style={errorBorder("name")}

                   value={companyEdit.name}
                    />
                )}
                rules={{ required: true }}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <label htmlFor="year" className="folder_label">
                Owner
              </label>

              <Controller
                name="owner_id"
                control={control}
                defaultValue={companyEdit.owner_id}
                render={(field) => (
                  <Select
                    size="large"
                    {...field}
                    //  style={errorBorder("clients")}
                    style={{ width: "100%" }}
                    value={companyEdit.owner_id}
                    >
                    <Option value={0}>All clients</Option>
                    {clients &&
                      clients.data.map((client: any, i: number) => (
                        <Option value={client.id} key={i}>
                          {client.name}
                        </Option>
                      ))}
                  </Select>
                )}
                rules={{ required: true }}
              />
            </Col>
          </Row>

          <label>Logo</label>
          <Controller
            name="logo"
            control={control}
            render={(field) => (
              <Input
                type="file"
                {...field}
                onChange={(e: any) => {
                  setValue("logo", e.target.files[0]);
                }}
                style={errorBorder("logo")}
                size="large"
                value={getValues("logo")?.filename}
              />
            )}
          />
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                // flexWrap: "wrap",
              }}
              className="d-flex flex-column flex-md-row">
              <button
                type="reset"
                className="cancel_btn flex-grow-1 my-3 mr-md-2"
                onClick={onClose}>
                Cancel
              </button>
              <button
                type="submit"
                className="invite_btn flex-grow-1 my-3 ml-md-2">
                Edit
              </button>
            </Col>
          </Row>
        </form>
      </Drawer>
    </>
  );
};

export default EditCompany;
