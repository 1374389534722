import "./MySelection.scss";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShoppingBag from "../../icons/ShoppingBag";
import Trash from "../../icons/Trash";
import placeholder from "../../img/img_placeholder.svg";
import { removeProductFromSelection } from "../../actions/myselection-actions/actions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { addProdDemcoToOrder } from "../../actions/order-actions/actions";
import { SendOutlined } from "@ant-design/icons";
import { AnimatePresence, motion } from "framer-motion";
import { DeleteOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import color_circle from "../../img/color-circle.svg";
import Bag from "../../icons/Bag";
import { Popconfirm, Spin } from "antd";
import { Link } from "react-router-dom";

interface Props {
  item: any;
  orderSelection: (x: number) => void;
}

export const SelectionItem = ({ item, orderSelection }: Props) => {
  const [orderedItem, setOrderedItem] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const dispatch = useDispatch();
  const { removeProductLoading } = useSelector((state: any) => state.selection);
  return (
    <AnimatePresence exitBeforeEnter={false}>
      <motion.div
        initial={{ x: 1000 }}
        animate={{ x: 0 }}
        exit={{ x: -1000 }}
        className="selection_item"
      >
        {
          showMsg ? (
            <motion.div
              initial={{ x: -1000, background: "white" }}
              animate={{ x: 0, background: "#8080800d" }}
              exit={{ x: 1000, background: "white" }}
              className="msg_order_sent"
            >
              <SendOutlined />
              <p>Your product has been ordered</p>
            </motion.div>
          ) : (
            // item.images.length > 0 ? (
            //   <>
            <div
              className="d-flex align-items-start border p-4 w-100"
              style={{ borderRadius: "8px" }}
            >
              <div
                style={{ flex: 0.4, maxWidth: "170px" }}
                className="d-flex justify-content-center"
              >
                <Link
                  to={`/product/details/${item.name}/${item.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <LazyLoadImage
                    effect="blur"
                    className="img_product_selection myselection-img-product"
                    src={
                      item.images.length > 0
                        ? process.env.REACT_APP_STORAGE_URL +
                            item.images[0].path || placeholder
                        : placeholder
                    }
                    alt={item.name + item.id}
                  />
                </Link>
              </div>
              <div style={{ flex: 1 }} className="ml-2">
                <div>
                  <Link
                    to={`/product/details/${item.name}/${item.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <h4 className=" mb-0 wish_list_link_code">{item.code}</h4>
                    <h4 className=" wish_list_link_name" >
                      {item.name}
                    </h4>
                  </Link>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <a
                  href={`/customization/new/${item.id}`}
                  style={{ textDecoration: "none", paddingTop: "2px" }}
                >
                  <img src={color_circle} className=" mr-2" />
                </a>
                <Popconfirm
                  placement="leftTop"
                  title={
                    <p>
                      Are you sure you want to remove this product <br /> from
                      your selection?
                    </p>
                  }
                  onConfirm={(e: any) => {
                    e.stopPropagation();
                    dispatch(removeProductFromSelection(item.id));
                  }}
                >
                  <button className="delete-selection-btn-drawer">
                    {removeProductLoading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    ) : (
                      <DeleteOutlined />
                    )}
                  </button>
                </Popconfirm>
              </div>
            </div>
          )
          /* <LazyLoadImage
              effect="blur"
              className="img_product_selection"
              src={
                process.env.REACT_APP_STORAGE_URL + item.images[0].path ||
                placeholder
              }
              alt={item.name + item.id}
            />
            <div className="selection_details">
              <div>
                <h4 className="h4_medium mb-0">{item.code}</h4>
                <h4 className="h4_medium" style={{ fontWeight: 400 }}>
                  {item.name}
                </h4>
              </div>
              <div className="selection_btns">
                <button
                  onClick={() => {
                    dispatch(addProdDemcoToOrder([item]));
                    setShowMsg(true);
                    setTimeout(() => {
                      orderSelection(item.id);
                      dispatch(removeProductFromSelection(item.id, true));
                      setShowMsg(false);
                    }, 3000);
                  }}
                >
                  <Bag />
                  <p>Order</p>
                </button>
                <button
                  onClick={() => dispatch(removeProductFromSelection(item.id))}
                >
                  <Trash />
                  <p>Delete</p>
                </button>
              </div>
            </div> */
          //   </>
          // ) : (
          //   <div
          //     className="d-flex align-items-start p-4 border"
          //     style={{ borderRadius: "8px" }}
          //   >
          //     <LazyLoadImage
          //       src={placeholder}
          //       effect="blur"
          //       className="img_product_selection"
          //       alt={item.name + item.id}
          //     />
          //     <div className="selection_details">
          //       <div>
          //         <h2 className="h4_medium">{item.name}</h2>
          //       </div>
          //       <div className="selection_btns">
          //         {!orderedItem && (
          //           <button
          //             onClick={() => {
          //               dispatch(addProdDemcoToOrder([item]));
          //               dispatch(removeProductFromSelection(item.id, true));
          //               setOrderedItem(true);
          //               setShowMsg(true);
          //               setTimeout(() => {
          //                 setShowMsg(false);
          //               }, 3000);
          //             }}
          //           >
          //             <ShoppingBag />
          //             <p>Order</p>
          //           </button>
          //         )}
          //         <button
          //           onClick={() => dispatch(removeProductFromSelection(item.id))}
          //         >
          //           <Trash />
          //           <p>Delete</p>
          //         </button>
          //       </div>
          //     </div>
          //   </div>
          // )
        }
      </motion.div>
    </AnimatePresence>
  );
};
