import { Button, Pagination } from "antd";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TableRow from "./TableRow";
import TableSkeleton from "./TableSkeleton";
import TableRowCancelledDirectOrder from "./TableRowCancelledDirectOrder";
import TableRowCancelledDemcoProduct from "./TableRowCancelledDemcoProduct";
import TableRowCancelledCustomizedOrder from "./TableRowCancelledCustomizedOrder";
import TableRawCompletedDirectOrder from "./TableRawCompletedDirectOrder";
import Jeans from "../../icons/Jeans";
import moment from "moment";
import Folder from "../../icons/Folder";
import { useEffect } from "react";
import TableRawCancelledDirectOrder from "./TableRawCancelledDirectOrder";
import TableRawCompletedDemcoOrder from "./TableRawCompletedDemcoOrder";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import TableRawCompletedCustomizedOrder from "./TableRawCompletedCustomizedOrder";

export default function MyOrdersTable({
  data,
  columns,
  isLoading,
  orderType,
  url,
  directOrder = false,
  orderStatus,
  refetch,
}: {
  data: any;
  columns: string[];
  isLoading: boolean;
  orderType: string;
  url: string;
  directOrder?: boolean;
  orderStatus: string;
  refetch: any;
}) {
  const [values, setValues] = useState({
    maxValue: 6,
    minValue: 0,
  });
  const history = useHistory();
  function useQueryParams() {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQueryParams();

  const handleChange = (page: number) => {
    setValues({
      minValue: (page - 1) * 6,
      maxValue: page * 6,
    });
    history.push(`${url}?page=${page}`);
  };

  useEffect(() => {
    var toggler = document.getElementsByClassName("caret");
    var i;
    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function (e: any) {
        e.target.parentElement
          .querySelector(".nested")
          .classList.toggle("active");
        e.target.classList.toggle("caret-down");
      });
    }
  });
  const [sortOrder, setSortOrder] = useState("desc");

  const handleSortByDeliveryDate = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Function to sort the data based on the delivery date
  const sortDataByDeliveryDate = (data: any) => {
    console.log(orderType + orderStatus);
    if (orderType == "demco-product-orders" && orderStatus == "cancelled") {
      const sortedData = data?.data?.sort((a: any, b: any) => {
        const dateA = moment(a.updated_at);
        const dateB = moment(b.updated_at);

        if (dateA.isValid() && dateB.isValid()) {
          //@ts-ignore
          return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        }

        return sortOrder === "asc"
          ? dateA.isValid()
            ? 1
            : -1
          : dateA.isValid()
          ? -1
          : 1;
        /*  //@ts-ignore
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA; */
      });
      return sortedData;
    } else {
      const sortedData = data?.sort((a: any, b: any) => {
        const dateA = moment(a.updated_at);
        const dateB = moment(b.updated_at);

        if (dateA.isValid() && dateB.isValid()) {
          //@ts-ignore
          return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        }

        return sortOrder === "asc"
          ? dateA.isValid()
            ? 1
            : -1
          : dateA.isValid()
          ? -1
          : 1;
        /*  //@ts-ignore
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA; */
      });
      return sortedData;
    }
  };

  // Apply sorting before mapping through the data
  const sortedData = sortDataByDeliveryDate(data);

  return (
    <div style={{ overflowY: "auto" }}>
      <table className="table table-direct-order">
        <thead>
          <tr>
            {columns.map((col: any, index: number) => (
              <th
                scope="col"
                key={index}
                style={{
                  fontWeight: 500,
                  paddingLeft: "0px",
                  color: "#182844",
                  cursor: index === 3 ? "pointer" : "default",
                }}
                onClick={() => {
                  if (index === 3 && col === "Order Date") {
                    handleSortByDeliveryDate(); // Update the key to 'deliveryDate'
                  }
                }}
              >
                {col}
                {col === "Order Date" && index === 3 && (
                  <span>
                    {sortOrder === "asc" ? (
                      <CaretUpOutlined />
                    ) : (
                      <CaretDownOutlined />
                    )}
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <TableSkeleton nbColumns={columns.length} />
          ) : data?.length === 0 ? (
            <tr>
              <td colSpan={10} style={{ textAlign: "center" }}>
                No orders found
              </td>
            </tr>
          ) : orderType === "direct_orders_content" ? (
            data.articles.map((article: any, index: number) => (
              <TableRow
                orderType={orderType}
                item={article}
                url={url}
                key={index}
              />
            ))
          ) : orderType === "demco_product_content" ? (
            data.products.map((product: any, index: number) => (
              <TableRow
                orderType={orderType}
                item={product}
                url={url}
                key={index}
                data={data}
              />
            ))
          ) : orderType === "direct_orders" && orderStatus === "cancelled" ? (
            data.map((product: any) => (
              /*  <TableRowCancelledDirectOrder
                key={product.id}
                product={product}
                refetch={refetch}
              /> */
              <TableRawCancelledDirectOrder
                key={product.id}
                item={product}
                url={url}
                refetch={refetch}
              />
            ))
          ) : orderType === "direct_orders" && orderStatus === "completed" ? (
            data?.map((product: any) => (
              <TableRawCompletedDirectOrder
                key={product.id}
                item={product}
                url={url}
                refetch={refetch}
              />
            ))
          ) : orderType === "demco-product-orders" &&
            orderStatus === "completed" ? (
            data?.map((product: any) => (
              <TableRawCompletedDemcoOrder
                key={product.id}
                item={product}
                url={url}
                refetch={refetch}
              />
            ))
          ) : orderType === "customized-orders" &&
            orderStatus === "completed" ? (
            data?.map((product: any) => (
              <TableRawCompletedCustomizedOrder
                key={product.id}
                item={product}
                url={url}
                refetch={refetch}
              />
            ))
          ) : orderType === "demco-product-orders" &&
            orderStatus === "cancelled" ? (
            data.map((product: any) => (
              <TableRowCancelledDemcoProduct
                key={product.id}
                product={product}
                refetch={refetch}
              />
            ))
          ) : orderType === "customized-orders" &&
            orderStatus === "cancelled" ? (
            data?.map((product: any) => (
              <TableRowCancelledCustomizedOrder
                key={product.id}
                product={product}
                refetch={refetch}
              />
            ))
          ) : orderType === "direct_orders_article_content" ? (
            data.article_options.map((option: any) => (
              <TableRow
                orderType={orderType}
                item={option}
                url={url}
                key={option.id}
              />
            ))
          ) : orderType === "customized-orders" ? (
            data
              .slice(values.minValue, values.maxValue)
              .map((order: any, index: number) => {
                if (order?.products?.length === 0) {
                  return <EmptyRow demcoOrder={order} key={index} />;
                }
                return (
                  <TableRow
                    orderType={orderType}
                    item={order}
                    url={url}
                    key={order.id}
                  />
                );
              })
          ) : orderType === "customized_product_content" ? (
            data.data.map((el: any, index: number) => {
              if (el?.products?.length === 0) {
                return <EmptyRow demcoOrder={el} key={index} />;
              }
              return (
                <TableRow
                  orderType={orderType}
                  item={el}
                  url={url}
                  key={index}
                />
              );
            })
          ) : (
            sortedData
              // .slice(values.minValue, values.maxValue)
              .map((el: any, index: number) => {
                console.log(el);
                if (el?.products?.length === 0) {
                  return <EmptyRow demcoOrder={el} key={index} />;
                }
                return (
                  <TableRow
                    orderType={orderType}
                    item={el}
                    url={url}
                    key={index}
                  />
                );
              })
          )}
        </tbody>
      </table>
      {/*  {!directOrder && (
        <Pagination
          current={
            queryParams.get("page") ? Number(queryParams.get("page")) : 1
          }
          defaultPageSize={6}
          total={data && data.length}
          hideOnSinglePage
          onChange={handleChange}
        />
      )}  */}
    </div>
  );
}

const EmptyRow = ({ demcoOrder }: any) => {
  return (
    <>
      {demcoOrder?.products.length > 0 && (
        <tr>
          <td scope="row">
            <Folder />
            <span className="ml-2 myorder_order_name">
              {demcoOrder.reference}
            </span>{" "}
          </td>
          {/* <td>
        <span>---</span>
      </td> */}
          <td>{demcoOrder.collection_name || "-"}</td>
          <td>{moment(demcoOrder.created_at).format("DD/MM/YYYY")}</td>
          <td>
            <Button
              style={{
                color: "#FF3A3A",
                background: "rgba(0, 70, 140, 0.2)",
              }}
              className="status_btn_order"
            >
              Canceled
            </Button>
          </td>
          {/*   <td>
        <span style={{ color: "#FF3A3A" }} className="status_msg">
          All products in this order are cancelled
        </span>
      </td> */}
        </tr>
      )}
    </>
  );
};
