import {
  Button,
  Col,
  Drawer,
  Input,
  Pagination,
  Popconfirm,
  Result,
  Row,
  Select,
  Spin,
  Tabs,
} from "antd";
import { useParams, useHistory } from "react-router";
import axiosInstance from "../../config/axios-instance";
import CustomizedOrdersCompany from "./CustomizedOrdersCompany";
import DirectOrdersCompany from "./DirectOrdersCompany";
import NormalOrdersCompany from "./NormalOrdersCompany";
import { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";
import moment from "moment";

import {
  LoadingOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { Controller, useForm } from "react-hook-form";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import avatar from "../../img/avatar_placeholder.jpg";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

const Moodboard = () => {
  const history = useHistory();
  const params = useParams<any>();

  function callback(key: any) {
    history.push(`/foryou-all-companies/${params.id}/${key}`);
  }
  const [moodboard, setMooadboard] = useState<Array<any>>([]);
  const [type, setType] = useState("new");
  const fetchMoadboard = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/moodboards/companies/${params.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setMooadboard(res.data.data);
      console.log("aaaaabb" + res.data);
    });
  };

  useEffect(() => {
    // fetchMoadboard();
  }, []);

  const [theme, setTheme] = useState("");
  const [year, setYear] = useState("");

  const [saisson, setSaisson] = useState("");

  const [division, setDivision] = useState("");
  const [file, setFile] = useState("");
  const [id, setId] = useState("");
  const [preview, setPreview] = useState("");
  const [client, setClient] = useState("");

  const [done, setDone] = useState(false);
  const { Option, OptGroup } = Select;

  const [open, setOpen] = useState(false);
  const showDrawer = (el: any) => {
    setTheme(el?.theme);
    setYear(el?.year);
    setId(el.id);
    setSaisson(el?.saisson);

    setDivision(el?.division);
    setFile(el?.file_storage);
    setClient(el?.client_id);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const { register, control, handleSubmit, errors, setValue, getValues } =
    useForm<any>();
  const { isLoading: loading, data: moodboards } = useQuery<any>(
    "moodboards",
    () => fetchMoadboard(),
    { keepPreviousData: true }
  );
  const onSubmit = async (data: any) => {
    let token = localStorage.getItem("token");

    if (type == "new") {
      setDone(true);
      let formData = new FormData();
      Object.keys(data).map((el: string) => formData.append(el, data[el]));

      const { data: response } = await axiosInstance({
        method: "post",
        url: "/moodboards/companies",
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDone(false);
      await MyNotif("Moodboard published successfully.", "success");
      fetchMoadboard();
      onClose();
      /*    if (response.status == 200) {
        setDone(false);
        await MyNotif("Moodboard published successfully.", "success");
        fetchMoadboard();
        onClose();
      } */

      return response.data;
    } else {
      //  setDone(true);

      let formData2 = new FormData();
      Object.keys(data).map((el: string) => formData2.append(el, data[el]));

      const { data: response } = await axiosInstance({
        method: "post",
        url: "/moodboards/companies/" + id,
        data: formData2,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDone(false);
      await MyNotif("Moodboard edited successfully.", "success");
      fetchMoadboard();
      onClose();
      /*   if (response.status == 200) {
        setDone(false);
        await MyNotif("Moodboard edited successfully.", "success");
        fetchMoadboard();
        onClose();
      } */
      /*    fetchMoadboard();
      onClose(); */
      return response.data;
    }
  };
  const errorBorder = (field: string) => {
    return {
      borderBottom: errors[field]?.type === "required" ? "1px 1px red" : "",
      boxShadow:
        errors[field]?.type === "required"
          ? "0 0 0 2px rgb(255,0,0, 0.4)"
          : "none",
    };
  };
  const deleteMoodboard = async (id: any) => {
    let token = localStorage.getItem("token");
    const { data: response } = await axiosInstance({
      method: "delete",
      url: "/moodboards/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await MyNotif("Moodboad Delete successfully.", "success");
    fetchMoadboard();
    onClose();
    return response.data;
  };

  const fetchClients = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/clients/companies/${params.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { isLoading: loading2, data: clients } = useQuery<any>(
    "clients",
    () => fetchClients(),
    { keepPreviousData: true }
  );
  return (
    <>
      {loading ? (
        <>
          {" "}
          <div
            className="loading_orders"
            style={{ height: "50vh", width: "50vw", margin: "0 auto" }}
          >
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        </>
      ) : (
        <>
          <div className="my_orders">
            <div className="container">
              {/* list of moodboard */}

              {moodboard && moodboard.length > 0 ? (
                <>
                  <button
                    style={{
                      alignItems: "center",
                      color: "white",
                      border: "none",
                      background: "#00468c",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      margin: "0 10px !important",
                      outline: "none !important",
                      marginTop: "28px",
                    }}
                    onClick={() => {
                      setType("new");
                      showDrawer("new");
                    }}
                  >
                    Add new Moodboard <FontAwesomeIcon icon={faPlus} />
                  </button>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Theme</th>
                        <th>Session</th>
                        <th>Divsion</th>
                        <th>Client</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {moodboard &&
                        moodboard.map((mood: any) => (
                          <tr key={mood.id}>
                            <td>
                              {" "}
                              <p className="mb-0">{mood.theme}</p>
                            </td>
                            <td>
                              <p className="mb-0">{mood.saisson}</p>
                            </td>
                            <td>
                              <p className="mb-0">{mood.division}</p>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <a
                                  key={mood.id}
                                  className="collab_profile mr-2"
                                  style={{
                                    backgroundImage: `url(${
                                      mood.client?.logo
                                        ? process.env.REACT_APP_STORAGE_URL +
                                          mood.client?.logo
                                        : avatar
                                    })`,
                                  }}
                                  //   replace id with the collaborator name
                                  id={mood.client?.name}
                                ></a>
                                <p className="mb-0">{mood.client?.name}</p>
                              </div>
                            </td>
                            <td>
                              {/*   <p className="mb-0">
                            {moment(order.updated_at).format("MMMM DD YYYY")}
                          </p> */}
                              {moment(mood.created_at).format("YYYY-MM-DD")}
                            </td>

                            <td>
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_STORAGE_URL +
                                  mood.file_storage
                                }
                                style={{ color: "#000000" }}
                              >
                                <FilePdfOutlined
                                  style={{
                                    marginRight: "7px",
                                    fontSize: "25px",
                                  }}
                                />
                              </a>

                              <Link
                                to={`${history.location.pathname}/${mood.id}`}
                                style={{ color: "#000000" }}
                              >
                                <EyeOutlined
                                  style={{
                                    marginRight: "7px",
                                    fontSize: "25px",
                                  }}
                                />
                              </Link>

                              <button
                                onClick={() => {
                                  setType("edit");
                                  showDrawer(mood);
                                }}
                              >
                                <EditOutlined
                                  style={{
                                    marginRight: "7px",
                                    fontSize: "25px",
                                  }}
                                />
                              </button>
                              <Popconfirm
                                title="Are you sure, you want to delete this moodboard  ?"
                                onConfirm={() => deleteMoodboard(mood.id)}
                              >
                                <button>
                                  {" "}
                                  <DeleteOutlined
                                    style={{
                                      top: "7px",
                                      fontSize: "25px",
                                    }}
                                  />{" "}
                                </button>
                              </Popconfirm>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        {/*      <td colSpan={5}>
                          <Pagination
                            defaultCurrent={moodboard && moodboard.current_page}
                            defaultPageSize={10}
                            total={orders && orders.total}
                            hideOnSinglePage
                            responsive
                            onChange={(page: any) =>
                              !filterBy.client && !filterBy.client
                                ? history.push(
                                    `/all-clients/${params.id}/${params.tab_key}?page=${page}`
                                  )
                                : onFilter(null, filterBy, page)
                            }
                          />
                        </td> */}
                      </tr>
                    </tfoot>
                    {/*    <tfoot>
                      <tr>
                        <td colSpan={5}> s</td>
                      </tr>
                    </tfoot> */}
                  </table>
                </>
              ) : (
                <>
                  {" "}
                  <Result
                    status="404"
                    title="No Moodboard"
                    style={{ width: "100%" }}
                    subTitle="Sorry, there's no moodboard."
                    extra={
                      <>
                        <button
                          style={{
                            alignItems: "center",
                            color: "white",
                            border: "none",
                            background: "#00468c",
                            padding: "10px 15px",
                            borderRadius: "5px",
                            margin: "0 10px !important",
                            outline: "none !important",
                            marginTop: "28px",
                          }}
                          onClick={() => {
                            setType("new");
                            showDrawer("new");
                          }}
                        >
                          Add new Moodboard <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </>
                    }
                  />
                </>
              )}
              {/*      {moodboard && moodboard.data.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Order Ref</th>
                  <th>Client</th>
                  <th>Name</th>
                  <th>Articles</th>
                  <th>Team</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orders &&
                  orders.data.map((order: any) => (
                    <tr key={order.id}>
                      <td>{order.ref || "n/a"}</td>
                      <td className="d-flex">
                        <div className="d-flex align-items-center">
                          <a
                            key={order.id}
                            className="collab_profile mr-2"
                            style={{
                              backgroundImage: `url(${
                                order.client.logo
                                  ? process.env.REACT_APP_STORAGE_URL +
                                    order.client.logo
                                  : avatar
                              })`,
                            }}
                            //   replace id with the collaborator name
                            id={order.client.name}
                          ></a>
                          <p className="mb-0">{order.client.name}</p>
                        </div>
                      </td>
                      <td>
                        <p className="mb-0">{order.name}</p>
                      </td>
                      <td>
                        <p className="mb-0">{order.articles_count}</p>
                      </td>
                      <td>
                        {order?.collaborators?.length > 0
                          ? order?.collaborators?.map((collab: any) => (
                              <Tooltip
                                title={collab.name}
                                placement="bottom"
                                key={collab.id}
                              >
                                <Avatar
                                  key={collab.id}
                                  alt={collab.name}
                                  src={
                                    collab.logo
                                      ? process.env.REACT_APP_STORAGE_URL +
                                        collab.logo
                                      : "col"
                                  }
                                />
                              </Tooltip>
                            ))
                          : "-"}
                      </td>
                      <td>
                        <p className="mb-0">
                          {moment(order.updated_at).format("MMMM DD YYYY")}
                        </p>
                      </td>
                      <td>
                        <Link to={`${history.location.pathname}/${order.id}`}>
                          See Details
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5}>
                    <Pagination
                      defaultCurrent={orders && orders.current_page}
                      defaultPageSize={10}
                      total={orders && orders.total}
                      hideOnSinglePage
                      responsive
                      onChange={(page: any) =>
                        !filterBy.client && !filterBy.client
                          ? history.push(
                              `/all-clients/${params.id}/${params.tab_key}?page=${page}`
                            )
                          : onFilter(null, filterBy, page)
                      }
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          ) : (
            <Result
              status="404"
              title="No Orders"
              style={{ width: "100%" }}
              subTitle="Sorry, there's no orders sent with these options."
         
            />
          )} */}
            </div>
          </div>
        </>
      )}

      {open && (
        <div style={{ width: "100px" }}>
          <Drawer
            title={type == "new" ? "Add new moodboard" : "Edit Moodboard"}
            placement={"bottom"}
            closable={true}
            onClose={onClose}
            visible={open}
            key={"bottom"}
            style={{ zIndex: 1050 }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container mt-6">
                <div className="form_add_folder">
                  <div style={{ width: "80%" }} className="container-fluid">
                    <p
                      className="text-center add_order_drawer_subtitle "
                      style={{ marginTop: "7px" }}
                    >
                      {type == "new"
                        ? "Enter your moodboard information"
                        : "Edit your moodboard information"}
                    </p>
                    <div className="row">
                      <div className=" col-md-6 col-12">
                        <div className="row">
                          <div className="col-12 mb-4">
                            <div className="field field_article field_article_name">
                       
                              <Controller
                                name="theme"
                                defaultValue={type == "new" ? "" : theme}
                                control={control}
                                render={(field) => (
                                  <Input
                                    {...field}
                                    size="large"
                                    type="text"
                                    style={errorBorder("theme")}
                                  />
                                )}
                                rules={{ required: true }}
                              />
                              <label htmlFor="theme" className="folder_label">
                              Name of Moodboard
                              </label>
                            </div>
                          </div>
                       {/*    <div className="col-12 mb-4 mt-4">
                            <div className="field">
                              <Controller
                                name="saisson"
                                defaultValue={type == "new" ? "" : saisson}
                                control={control}
                                render={(field) => (
                                  <Select
                                    size="large"
                                    {...field}
                                    style={errorBorder("saisson")}
                                  >
                                    <Option value="">--</Option>
                                    <Option value="AW">AW</Option>
                                    <Option value="FW">FW</Option>
                                    <Option value="SS">SS</Option>
                                    <Option value="SU">SU</Option>
                                    <Option value="PF">PF</Option>
                                  </Select>
                                )}
                                rules={{ required: true }}
                              />
                              <label htmlFor="theme" className="folder_label">
                                Season
                              </label>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="form_add_article_left col-md-6 col-12">
                        <div className="row">
                          <div className="col-12 mb-4">
                            <div className="field">
                              <Controller
                                name="year"
                                defaultValue={type == "new" ? "" : year}
                                control={control}
                                render={(field) => (
                                  <Input
                                    {...field}
                                    size="large"
                                    type="text"
                                    style={errorBorder("year")}
                                  />
                                )}
                                rules={{ required: true }}
                              />
                              <label htmlFor="year" className="folder_label">
                              Season
                              </label>
                            </div>
                          </div>
                       {/*    <div className="col-12 mb-4 mt-4">
                            <div className="field field_article">
                              <Controller
                                name="division"
                                defaultValue={type == "new" ? "" : division}
                                control={control}
                                render={(field) => (
                                  <Select
                                    size="large"
                                    {...field}
                                    style={errorBorder("division")}
                                  >
                                    <Option value="">--</Option>

                                    <Option value="Women">Women</Option>
                                    <Option value="Men">Men</Option>
                                    <Option value="Unisex">Unisex</Option>
                                    <Option value="Kids">Kids</Option>
                                  </Select>
                                )}
                                rules={{ required: true }}
                              />
                              <label htmlFor="theme" className="folder_label">
                                Division
                              </label>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="form_add_article_left col-md-6 col-12">
                        <div className="row">
                          <div className="col-12 mb-4 ">
                            <div className="field">
                              <Controller
                                name="clients"
                                defaultValue={type == "new" ? "" : client}
                                control={control}
                                render={(field) => (
                                  <Select
                                    size="large"
                                    {...field}
                                    style={errorBorder("clients")}
                                  >
                                    <Option value={0}>All clients</Option>
                                    {clients &&
                                      clients.data.data.map(
                                        (client: any, i: number) => (
                                          <Option value={client.id} key={i}>
                                            {client.name}
                                          </Option>
                                        )
                                      )}
                                  </Select>
                                )}
                                rules={{ required: true }}
                              />
                              <label htmlFor="year" className="folder_label">
                                Clients
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_add_article_left col-md-6 col-12">
                        <div className="row">
                          <div className="col-12 mb-4">
                            <div className="col-12 field field_article">
                              <div className="d-flex flex-column">
                                <label>
                                  File (PDF) MAX Size : 200 MB{" "}
                                  {type == "edit" && (
                                    <span style={{ opacity: 0.4 }}>
                                      (if not changed you previous file will be
                                      attached)
                                    </span>
                                  )}
                                </label>
                                <Controller
                                  name="file"
                                  control={control}
                                  render={(field) => (
                                    <Input
                                      type="file"
                                      {...field}
                                      onChange={(e: any) => {
                                        setValue("file", e.target.files[0]);
                                        // setPreview(URL.createObjectURL(e.target.files[0]));
                                        setPreview(
                                          URL.createObjectURL(e.target.files[0])
                                        );
                                      }}
                                      style={errorBorder("file")}
                                      size="large"
                                      value={getValues("file")?.filename}
                                    />
                                  )}
                                  rules={
                                    file
                                      ? { required: false }
                                      : { required: true }
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*    <div className="col-12 p-4 field field_article">
                        <div className="d-flex flex-column mt-3">
                          <label>
                            File (PDF) MAX Size : 200 MB{" "}
                            {type == "edit" && (
                              <span style={{ opacity: 0.4 }}>
                                (if not changed you previous file will be
                                attached)
                              </span>
                            )}
                          </label>
                          <Controller
                            name="file"
                            control={control}
                            render={(field) => (
                              <Input
                                type="file"
                                {...field}
                                onChange={(e: any) => {
                                  setValue("file", e.target.files[0]);
                                  // setPreview(URL.createObjectURL(e.target.files[0]));
                                  setPreview(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                }}
                                style={errorBorder("file")}
                                size="large"
                                value={getValues("file")?.filename}
                              />
                            )}
                            rules={
                              file ? { required: false } : { required: true }
                            }
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <Row
                  gutter={[48, 8]}
                  style={{ paddingRight: 135, paddingLeft: 135 }}
                >
                  <Col span={12}>
                    {" "}
                    {getValues("file")?.name.split(".")[1] == "pdf" && (
                      <>
                        <iframe
                          src={preview}
                          frameBorder="0"
                          height="500px"
                          width="500px"
                        ></iframe>
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    {" "}
                    <div style={{ width: "100%" }} className="text-right">
                      <button
                        /*         style={{
                          alignItems: "center",
                          //   color: "white",
                          border: "none",
                          // background: "#00468c",
                          padding: "10px 15px",
                          borderRadius: "5px",
                          margin: "0 10px !important",
                          outline: "none !important",
                          pointerEvents: "none",
                          color: "#AAA",
                          background: "#F5F5F5",
                        }} */
                        className={
                          done ? "submitButtonWaiting" : "submitButton"
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </Drawer>

          {/*    <Modal
            title="Basic Modal"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
          </Modal> */}
        </div>
      )}
    </>
  );
};

export default Moodboard;
