import {
  GET_CREATIVE_STATION_REQUEST,
  GET_CREATIVE_STATION_SUCCESS,
  GET_CREATIVE_STATION_FAIL,
  GET_MORE_NEWEST_CREATIVE_STATION_REQUEST,
  GET_MORE_NEWEST_CREATIVE_STATION_SUCCESS,
  GET_MORE_NEWEST_CREATIVE_STATION_FAIL,
  GET_MORE_TRENDING_CREATIVE_STATION_REQUEST,
  GET_MORE_TRENDING_CREATIVE_STATION_SUCCESS,
  GET_MORE_TRENDING_CREATIVE_STATION_FAIL,
  GET_MORE_FASHION_CREATIVE_STATION_REQUEST,
  GET_MORE_FASHION_CREATIVE_STATION_SUCCESS,
  GET_MORE_FASHION_CREATIVE_STATION_FAIL,
  GET_ONE_POST_REQUEST,
  GET_ONE_POST_SUCCESS,
  GET_ONE_POST_FAIL,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAIL,
} from "./types";
import CreativeStaionServices from "./services";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";

// Get All Creative Station Posts
export const getAllCreativeStationPosts = () => async (dispatch: any) => {
  await dispatch({
    type: GET_CREATIVE_STATION_REQUEST,
  });
  try {
    const response = await CreativeStaionServices.getCreativeStation();
    await dispatch({
      type: GET_CREATIVE_STATION_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: GET_CREATIVE_STATION_FAIL,
      payload: e.response,
    });
  }
};

// Get MORE Creative Station Posts
export const loadMoreCreativeStation =
  (url: string, type: string) => async (dispatch: any) => {
    switch (type) {
      case "threeDVideos":
        await dispatch({
          type: GET_MORE_NEWEST_CREATIVE_STATION_REQUEST,
        });
        try {
          const response = await CreativeStaionServices.loadMoreCreativeStation(
            url
          );
          await dispatch({
            type: GET_MORE_NEWEST_CREATIVE_STATION_SUCCESS,
            payload: response.data,
          });
        } catch (e: any) {
          dispatch({
            type: GET_MORE_NEWEST_CREATIVE_STATION_FAIL,
            payload: e.response,
          });
        }
        break;
      case "trending":
        await dispatch({
          type: GET_MORE_TRENDING_CREATIVE_STATION_REQUEST,
        });
        try {
          const response = await CreativeStaionServices.loadMoreCreativeStation(
            url
          );
          await dispatch({
            type: GET_MORE_TRENDING_CREATIVE_STATION_SUCCESS,
            payload: response.data,
          });
        } catch (e: any) {
          dispatch({
            type: GET_MORE_TRENDING_CREATIVE_STATION_FAIL,
            payload: e.response,
          });
        }
        break;
      default:
        await dispatch({
          type: GET_MORE_FASHION_CREATIVE_STATION_REQUEST,
        });
        try {
          const response = await CreativeStaionServices.loadMoreCreativeStation(
            url
          );
          await dispatch({
            type: GET_MORE_FASHION_CREATIVE_STATION_SUCCESS,
            payload: response.data,
          });
        } catch (e: any) {
          dispatch({
            type: GET_MORE_FASHION_CREATIVE_STATION_FAIL,
            payload: e.response,
          });
        }
    }
  };

export const getOnePost = (id: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_ONE_POST_REQUEST,
  });
  try {
    const response = await CreativeStaionServices.getOnePost(id);
    await dispatch({
      type: GET_ONE_POST_SUCCESS,
      payload: response.data,
    });
  } catch (e: any) {
    dispatch({
      type: GET_ONE_POST_FAIL,
      payload: e.response,
    });
  }
};

export const deletePost =
  (id: number, category: string) => async (dispatch: any) => {
    await dispatch({
      type: DELETE_POST_REQUEST,
    });
    try {
      await CreativeStaionServices.deletePost(id);
      await dispatch({
        type: DELETE_POST_SUCCESS,
        payload: { id, category },
      });
      MyNotif("Post deleted successfully.", "success");
    } catch (e: any) {
      dispatch({
        type: DELETE_POST_FAIL,
        payload: e.response,
      });
    }
  };
