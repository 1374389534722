import { useQuery } from "react-query";
import { Card, Spin, Pagination, Empty } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../config/axios-instance";
import { LoadingOutlined } from "@ant-design/icons";
import backArrow from "../img/backArrow.png";
import moment from "moment";
import placeholder_jeans from "../img/placeholder_jeans.svg";
import VideoComponent from "../components/MasonryBlock/VideoComponent";
import Head from "../components/Head";

const { Meta } = Card;

const DraftCreativeStation = () => {
  const history = useHistory();
  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQueryy();

  const fetchDraftPosts = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/posts?in_draft=true&page=${query.get("page") || 1}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { isLoading, isError, data, error } = useQuery(
    ["draft_posts", query.get("page")],
    () => fetchDraftPosts(),
    { keepPreviousData: true }
  );

  const isImage = (filename: string) => {
    let extension = filename?.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "png":
        return true;
      case "jpg":
        return true;
      case "jpeg":
        return true;
      case "svg":
        return true;
      case "webp":
        return true;
      default:
        return false;
    }
  };

  return isLoading ? (
    <div className="loading_orders">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <main className="creative_station pb-3">
      <Head title="Draft Articles" />
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex align-items-center back_cs mb-2">
            <img
              src={backArrow}
              className="back_arrow_folder mr-2"
              alt="back arrow"
              onClick={() => history.push("/creative-station")}
            />
            <h1 style={{ fontSize: 16 }}>Creative Station</h1>
          </div>
          {data &&
            (data.data.data.length === 0 ? (
              <div className="d-flex justify-content-center w-100 h-100">
                <Empty description={"There's no articles saved in draft!"} />
              </div>
            ) : (
              data.data.data.map((draft: any) => (
                <div
                  className="col-3 my-2"
                  key={draft.id}
                  onClick={() =>
                    history.push(`/single-post/${draft.id}/update?draft=true`)
                  }
                >
                  <Card
                    hoverable
                    className="rounded"
                    style={{ width: "100%", height: "100%" }}
                    cover={
                      isImage(draft.file) ? (
                        <img
                          alt={draft.name}
                          src={
                            draft.file
                              ? process.env.REACT_APP_STORAGE_URL + draft.file
                              : placeholder_jeans
                          }
                        />
                      ) : (
                        <div style={{ position: "relative", width: "100%" }}>
                          <VideoComponent post={draft} />
                        </div>
                      )
                    }
                  >
                    <Meta
                      title={draft.name}
                      description={
                        <>
                          <span>
                            <strong>Created at: </strong>
                            {moment(draft.created_at).format(
                              "MMM DD YYYY, HH:mm"
                            )}
                          </span>
                          <br />
                          <span>
                            <strong>Last update: </strong>
                            {moment(draft.updated_at).format(
                              "MMM DD YYYY, HH:mm"
                            )}
                          </span>
                        </>
                      }
                    />
                  </Card>
                </div>
              ))
            ))}
        </div>
        <Pagination
          defaultCurrent={1}
          defaultPageSize={10}
          total={data && data.data.total}
          className="text-center my-3"
          hideOnSinglePage
          responsive
          onChange={(page: any) =>
            history.push(`/creative-station/draft?page=${page}`)
          }
        />
      </div>
    </main>
  );
};

export default DraftCreativeStation;
