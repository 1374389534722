import {
  Pagination,
  Spin,
  Select,
  Collapse,
  Input,
  Result,
  Button,
  Popconfirm,
  Tooltip,
  Avatar,
  Tag,
  Modal,
} from "antd";
import { useQuery } from "react-query";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../config/axios-instance";
import {LoadingOutlined, FilterOutlined, SearchOutlined} from "@ant-design/icons";
import avatar from "../../img/avatar_placeholder.jpg";
import moment from "moment";
import { useMutation } from "react-query";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import React, { useEffect, useState } from "react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import {
  FolderAddOutlined,
  UserOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import {
  deleteCustomizedOrder,
  restoreCustomizedOrder,
  relaunchCustomProduct,
  restoreCustomizedOrderLeft,
  restoreCustomizedproduct,
} from "../../actions/my-orders/services";
import {HrIcon} from "../product-3d-config-tabs/forms/canvas/icon/HrIcon";

const { Option } = Select;
const { Panel } = Collapse;

const CustomizedOrdersCompany = (props: any) => {
  const [filterBy, setFilterBy] = useState({
    reference: "",
    client: 0,
    type: 0,
  });
  const [orders, setOrders] = useState<any>(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [ordersCancel, serOrdersCancel] = useState<any>(null);
  const { status } = props;
  const params = useParams<any>();
  const history = useHistory<any>();
  const [searchQuery, setSearchQuery] = useState("");

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQueryy();
  const [showTable, setShowTable] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [showTr, setShowTr] = useState("");
  const [isModalRestore, setIsModalRestore] = useState(false);
  const [orderToRestore, setOrderToRestore] = useState<any>("");
  const [productToRestore, setProductToRestore] = useState<any>("");
  const [isModalRelaunch, setIsModalRelaunch] = useState(false);

  const genStatusMsg = (status_name: string) => {
    console.log(status_name);
    switch (status_name) {
      case "PROTO":
        return {
          color: "#BCBCBD",
          msg: "Your sample is being prepared",
        };
      case "PRODUCTION":
        return {
          msg: "The production is in process",
          color: "#BCBCBD",
        };
      case "OK PROD":
        return {
          msg: "Your sample is being prepared",
          color: "#BCBCBD",
        };
      case "CANCELLED":
        return { color: "#FF3A3A", msg: "Your order is canceled" };
      default:
        return {
          color: "#BCBCBD",
          msg: "Waiting for your confirmation",
        };
    }
  };
  const handleClick = (rowId: any) => {
    // Only set showTable to true for the clicked row
    setShowTr(rowId);
  };
  const fetchCustomizedOrders = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/customized_products/companies/${params.id}?page=${
        query.get("page") || 1
      }&order_status=${status}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => setOrders(res.data));
  };

  const fetchClients = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/clients/companies/${params.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  useEffect(() => {
    if (status == "in-progress") {
      fetchCustomizedOrders();
    } else if (status == "cancelled") {
      fetchCustomizedOrders();
    } else if (status == "completed") {
      fetchCustomizedOrders();
    }
  }, [status]);
  const { isLoading: loading, data: clients } = useQuery<any>(
    "clients",
    () => fetchClients(),
    { keepPreviousData: true }
  );

  const { isLoading, isError, data, error } = useQuery(
    ["customized_product", query.get("page")],
    () => fetchCustomizedOrders(),
    { keepPreviousData: true }
  );

  const onFilter = (e: any, filters: any, page: number) => {
    e?.preventDefault();
    console.log(filters.type);
    setFilterLoading(true);
    if (!filters.client && !filters.reference && !filters.type) {
      fetchCustomizedOrders();
      setFilterLoading(false);
    } else {
      let token = localStorage.getItem("token");
      return axiosInstance({
        method: "get",
        url: `/admin/search/customized_products?reference=${filterBy.reference}&ownerId=${filterBy.client}&type=${filterBy.type}&page=${page}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res: any) => {
        if (filters.type === 0) {
          console.log(res.data);
          setOrders(res.data);

          setFilterLoading(false);
        } else {
          serOrdersCancel(res.data);
          setFilterLoading(false);
        }
      });
    }
  };

  const { mutateAsync: deleteProduct, isLoading: deleteLoading } = useMutation(
    deleteCustomizedOrder,
    {
      onSuccess: () => {
        MyNotif("Product deleted successfully.", "success");
      },
    }
  );
  const { mutateAsync: restoreProduct, isLoading: restoreLoading } =
    useMutation(restoreCustomizedOrder, {
      onSuccess: () => {
        MyNotif("Order restored successfully.", "success");
      },
    });

  const {
    mutateAsync: restoreProductCustom,
    isLoading: restorecustomproductLoading,
  } = useMutation(restoreCustomizedproduct, {
    onSuccess: () => {
      MyNotif("Order restored successfully.", "success");
    },
  });

  const {
    mutateAsync: restoreProductCutomLeft,
    isLoading: restoreProductLoading,
  } = useMutation(restoreCustomizedOrderLeft, {
    onSuccess: () => {
      MyNotif("Order restored successfully.", "success");
    },
  });

  const { mutateAsync: relaunchProduct, isLoading: relaunchLoading } =
    useMutation(relaunchCustomProduct, {
      onSuccess: () => {
        MyNotif("Order restored successfully.", "success");
      },
    });

  const showModalRestore = (order_id: any, product_id: any) => {
    setIsModalRestore(true);
    setOrderToRestore(order_id);
    setProductToRestore(product_id);
  };

  const restoreProdLeft = () => {
    let token = localStorage.getItem("token");

    axiosInstance({
      method: "post",
      url: `/customized_products/${productToRestore}/update/left`,
      data: {
        status_id: 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Restore with successfully.", "success");
        setIsModalRestore(false);
        window.location.reload();
      }
    });
  };

  const restore = () => {
    let token = localStorage.getItem("token");

    axiosInstance({
      method: "post",
      url: `/customized_products/${orderToRestore}/restore/${productToRestore}`,
      data: {
        status_id: 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Restore with successfully.", "success");
        setIsModalRestore(false);
        window.location.reload();
      }
    });
  };

  const handleCancel = () => {
    setIsModalRestore(false);
  };



  const showModalRelaunch = (order_id:any,product_id: any) => {
    setIsModalRelaunch(true);
    setOrderToRestore(order_id)
    setProductToRestore(product_id);
  };


  const handleCancelRelaunch = () => {
    setIsModalRelaunch(false);
  };

  const relaunchProduction = () => {


    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/customized_orders/relaunch/${orderToRestore}`,
      data: {
        product_id: productToRestore,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Relaunched with successfully.", "success");
        setIsModalRelaunch(false);
        window.location.reload();
      }
    });
    //   setIsModalRestore(false);
  };

  const relaunchPreSMS = () => {


    let token = localStorage.getItem("token");
    axiosInstance({
      method: "post",
      url: `/customized_orders/relaunch/presms/${orderToRestore}`,
      data: {
        product_id: productToRestore,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status == 200) {
        MyNotif("Relaunched with successfully.", "success");
        setIsModalRelaunch(false);
        window.location.reload();
      }
    });
    //   setIsModalRestore(false);
  };


  console.log(orders);
  useEffect(() => {
    var toggler = document.getElementsByClassName("caret");
    var i;
    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function (e: any) {
        e.target.parentElement
          .querySelector(".nested")
          .classList.toggle("active");
        e.target.classList.toggle("caret-down");
      });
    }
  });

  const handleSearchInputChange = (event: any) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = orders?.data?.filter((order: any) => {
    console.log(order);

    /*     const nameMatch = order?.collection_name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase()); */
    const refMatch = order?.reference
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase());

    const nameMatch = order.products.some((p: any) =>
      /* article.article_options?.some(
          (option:any) => option.delivery_date === filteredData
        ) */
      p?.collection_name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return refMatch || nameMatch;
  });

  const [sortOrder, setSortOrder] = useState("desc");

  const handleSortByDeliveryDate = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Function to sort the data based on the delivery date
  const sortDataByDeliveryDate = (data: any) => {
    const sortedData = data?.sort((a: any, b: any) => {
      console.log(a);
      const dateA = moment(a.created_at);
      const dateB = moment(b.created_at);

      if (dateA.isValid() && dateB.isValid()) {
        //@ts-ignore
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      }

      return sortOrder === "asc"
        ? dateA.isValid()
          ? 1
          : -1
        : dateA.isValid()
        ? -1
        : 1;
      /*     //@ts-ignore
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA; */
    });
    return sortedData;
  };

  // Apply sorting before mapping through the data
  const sortedData = sortDataByDeliveryDate(filteredData);
  return isLoading ? (
    <div
      className="loading_orders"
      style={{ height: "50vh", width: "50vw", margin: "0 auto" }}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <>
      {/* <Collapse
        ghost
        expandIcon={() => (
          <FilterOutlined
            style={{ fontSize: 20, verticalAlign: 0, paddingTop: 8 }}
          />
        )}>
        <Panel header="Filter Orders" key="1">
          <form
            className="d-flex"
            onSubmit={(e: any) => onFilter(e, filterBy, 1)}>
            <Input
              type="text"
              className="w-50 mr-3 rounded"
              placeholder="Order reference"
              value={filterBy.reference}
              onChange={(e) =>
                setFilterBy({ ...filterBy, reference: e.target.value })
              }
            />
            <Select
              loading={loading}
              className="w-25 mr-3 select_filter_client"
              placeholder="Order sent by"
              value={+filterBy.client}
              onChange={(x: any) => setFilterBy({ ...filterBy, client: x })}>
              <Option value={0}>All clients</Option>
              {clients &&
                clients.data.data.map((client: any, i: number) => (
                  <Option value={client.id} key={i}>
                    {client.name}
                  </Option>
                ))}
            </Select>
            <Select
              loading={loading}
              className="w-25 mr-3 select_filter_client"
              placeholder="Order type"
              value={+filterBy.type}
              onChange={(x: any) => setFilterBy({ ...filterBy, type: x })}>
              <Option value={0}>All</Option>
              <Option value={6}>Product Cancelled</Option>
              <Option value={7}>Product Closed</Option>
            </Select>
            <div className="w-25 d-flex">
              <Input
                type="submit"
                value="Filter"
                className="w-50 rounded mr-2"
              />
              <Input
                type="reset"
                value="Clear"
                className="w-50 rounded"
                onClick={(e: any) => {
                  onFilter(e, { reference: "", client: 0, type: 0 }, 1);
                  setFilterBy({ reference: "", client: 0, type: 0 });
                }}
              />
            </div>
          </form>
        </Panel>
      </Collapse> */}
      {filterBy.type === 0 ? (
        <>
          {filterLoading ? (
            <div
              className="loading_orders"
              style={{ height: "50vh", width: "50vw", margin: "0 auto" }}
            >
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : orders && orders.data.length > 0 && status == "in-progress" ? (
              <div className={"mt-3"}>
                <div className="search-bar searchfaq" style={{width: "100%"}}>
                  <div className="input-container">
                    <input type="text" placeholder="Search your order" onChange={handleSearchInputChange}
                           className={"search_input"}/>
                    <HrIcon></HrIcon>
                    <button type="submit" className={"submit_input"}><SearchOutlined/></button>
                  </div>
                </div>
                <div style={{overflowY: "auto"}}>
              <table className="table table-direct-order">
                <thead>
                  <tr>
                    <th></th>
                    <th style={{ paddingLeft: "0px" }}>Reference</th>
                    <th style={{ paddingLeft: "0px" }}>Name</th>
                    <th
                      style={{ paddingLeft: "0px", cursor: "pointer" }}
                      onClick={handleSortByDeliveryDate}
                    >
                      Order date{" "}
                      {sortOrder === "asc" ? (
                        <CaretUpOutlined />
                      ) : (
                        <CaretDownOutlined />
                      )}
                    </th>
                    <th>Delivery date </th>{" "}
                    <th style={{ paddingLeft: "0px" }}>Number of article</th>
                    <th style={{ paddingLeft: "0px" }}>Status</th>
                    <th style={{ paddingLeft: "0px" }}>
                      Description of status
                    </th>
                    <th style={{ paddingLeft: "0px" }}>Team members</th>
                  </tr>
                </thead>
                <tbody>
                  {/*  {orders &&
                  orders.data.map((order: any, i: number) => (
                    <tr key={i}>
                 
                      <td>
                        <ul id="myUL">
                          <li>
                            <span
                              className="caret"
                              style={{ color: "#8894A1", fontSize: "18px" }}
                            >
                              {order?.ref || order?.reference}
                            </span>

                            {order.products && order.products.length > 0 ? (
                              <>
                                {" "}
                                <ul className="nested">
                                  {order.products.map((elm: any) => (
                                    <li style={{ fontSize: "16px" }}>
                                      <Link
                                        to={`${history.location.pathname}/${order.id}/${elm.id}/progress/customized`}
                                      >
                                        {elm.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </>
                            ) : (
                              <>
                                {" "}
                                <ul
                                  className="nested"
                                  style={{ color: "#7C7C7C" }}
                                >
                                  No Products
                                </ul>
                                <></>
                              </>
                            )}
                          </li>
                        </ul>
                      </td>
                      <td className="top_article">
                        <div className="align-items-center">
                          <a
                            key={order.id}
                            className="collab_profile mr-2"
                            style={{
                              backgroundImage: `url(${
                                order.client.logo
                                  ? process.env.REACT_APP_STORAGE_URL +
                                    order.client.logo
                                  : avatar
                              })`,
                            }}
                            //   replace id with the collaborator name
                            id={order.client.name}
                          ></a>
                          <p className="mb-0">{order.client.name}</p>
                        </div>
                      </td>
                   
                      <td className="top_article">
                        <p className="mb-0">
                          {moment(order.updated_at).format("MMMM DD YYYY")}
                        </p>
                      </td>
                  
                    </tr>
                  ))} */}

                  {orders &&
                    sortedData.map((order: any, i: number) => (
                      <>
                        {order.products.length > 0 && (
                          <tr
                            onClick={() => {
                              setShowTable(!showTable);
                              handleClick(order.id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <td
                              width={"5%"}
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            >
                              <button
                                onClick={() => {
                                  setShowTable(!showTable);
                                  handleClick(order.id);
                                }}
                              >
                                {showTable && showTr == order.id ? (
                                  <>
                                    <FolderOpenOutlined
                                      style={{
                                        color: "#00468C",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <FolderAddOutlined
                                      style={{
                                        color: "#00468C",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </>
                                )}
                              </button>
                            </td>

                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            >
                              {order.reference}
                            </td>

                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            >
                              {order.products[0].collection_name || "-"}
                            </td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            >
                              {moment(order.created_at).format("DD/MM/YYYY")}
                            </td>

                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            ></td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            >
                              <p
                                className="mb-0"
                                style={{ marginLeft: "50px" }}
                              >
                                {order?.products?.length}
                              </p>
                            </td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            ></td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            ></td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            >
                              <Avatar.Group
                                maxCount={2}
                                size="large"
                                maxStyle={{
                                  color: "white",
                                  backgroundColor: "#7a9ec5",
                                }}
                              >
                                {order?.collaborators?.map((collab: any) => (
                                  <Tooltip
                                    title={collab.name}
                                    key={collab.id}
                                    placement="bottom"
                                  >
                                    {collab.logo ? (
                                      <Avatar
                                        style={{
                                          boxShadow:
                                            "1px 0px 5px rgba(0,0,0,0.4)",
                                        }}
                                        src={
                                          process.env.REACT_APP_STORAGE_URL +
                                          collab.logo
                                        }
                                      />
                                    ) : (
                                      <Avatar
                                        style={{
                                          backgroundColor: "#ddd",
                                          boxShadow:
                                            "1px 0px 5px rgba(0,0,0,0.2)",
                                        }}
                                        icon={
                                          <UserOutlined
                                            style={{ verticalAlign: "2px" }}
                                          />
                                        }
                                      />
                                    )}
                                  </Tooltip>
                                ))}
                              </Avatar.Group>
                            </td>
                          </tr>
                        )}
                        {showTable && showTr == order.id && (
                          <tr>
                            <td
                              colSpan={10}
                              style={{
                                backgroundColor: "#FCFDFF",
                                padding: "0.2rem",
                                paddingLeft: "4%",
                              }}
                            >
                            {/*   <table className="tableArticle">
                                {order.products?.map((elm: any) => (
                                  <>
                                    <Link
                                      to={`${history.location.pathname}/${order.id}/${elm.id}/progress/customized`}
                                    >
                                      <tr style={{ cursor: "pointer" }}>
                                        <td
                                          style={{
                                            //   width: "50%",

                                            borderTop: "none",
                                          }}
                                          className="details_custom_prog_td"
                                        >
                                          {elm.name}
                                        </td>

                                        <td
                                          style={{
                                            //   width: "5%",
                                            paddingLeft: "0px",
                                            borderTop: "none",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            //    width: "21%",
                                            paddingLeft: "0px",
                                            borderTop: "none",
                                          }}
                                        >
                                          {" "}
                                          {elm.delivery_date_item
                                            ? moment(
                                                elm.delivery_date_item
                                              ).format("DD/MM/YYYY")
                                            : "-"}
                                        </td>
                                        <td
                                          style={{
                                            //  width: "8%",
                                            paddingLeft: "0px",
                                            borderTop: "none",
                                          }}
                                          className="details_custom_prog_status_td"
                                        >
                                          <Tag
                                            color="processing"
                                            style={{
                                              borderRadius: "50px",
                                              cursor: "pointer",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {elm.status?.name}
                                          </Tag>
                                        </td>
                                        <td
                                          style={{
                                            // width: "22%",
                                            paddingLeft: "0px",
                                            borderTop: "none",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: genStatusMsg(
                                                elm.status?.name
                                              ).color,
                                            }}
                                            className="status_msg"
                                          >
                                            {genStatusMsg(elm.status?.name).msg}
                                          </span>
                                        </td>
                                      </tr>
                                    </Link>
                                  </>
                                ))}
                              </table> */}

{order.products?.map((elm: any) => (
              <>
                <Link to={`${history.location.pathname}/${order.id}/${elm.id}/progress/customized`}>
                  <div className="row row_custom_product_progress_user">
                    <div className="product_custom_prog_custom_order_user">
                      {" "}
                      {elm.name}
                    </div>
                    <div className="deleviry_date_prog_custom_order_user">
                      {" "}
                      {elm.delivery_date_item
                        ? moment(elm.delivery_date_item).format("DD/MM/YYYY")
                        : "-"}
                    </div>

                    <div className="status_prog_custom_order_user">
                      <Tag
                        color="#182844"
                        style={{
                          borderRadius: "50px",
                          cursor: "pointer",
                          fontWeight: "normal",
                        }}>
                        {elm?.status?.name}
                      </Tag>
                    </div>

                    <div>
                      <span
                        style={{
                          color: genStatusMsg(elm.status?.name).color,
                        }}
                        className="status_msg">
                        {genStatusMsg(elm.status?.name).msg}
                      </span>
                    </div>
                  </div>
                </Link>
              </>
            ))}
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={10}>
                      {/*  <Pagination
                      defaultCurrent={orders && orders.current_page}
                      defaultPageSize={10}
                      total={orders && orders.total}
                      hideOnSinglePage
                      responsive
                      onChange={(page: any) =>
                        !filterBy.client && !filterBy.client
                          ? history.push(
                              `/all-clients/${params.id}/${params.tab_key}?page=${page}`
                            )
                          : onFilter(null, filterBy, page)
                      }
                    /> */}
                    </td>
                  </tr>
                </tfoot>
              </table>
                </div>
            </div>
          ) : orders && orders.data.length > 0 && status == "cancelled" ? (
              <div className={"mt-3"}>
                <div className="search-bar searchfaq" style={{width: "100%"}}>
                  <div className="input-container">
                    <input type="text" placeholder="Search your order" onChange={handleSearchInputChange}
                           className={"search_input"}/>
                    <HrIcon></HrIcon>
                    <button type="submit" className={"submit_input"}><SearchOutlined/></button>
                  </div>
                </div>
                <div style={{overflowY: "auto"}}>
              <table className="table table-direct-order">
                <thead>
                  <tr>
                    <th></th>
                    <th style={{ paddingLeft: "0px" }}>Reference</th>
                    <th style={{ paddingLeft: "0px" }}>Collection Name</th>
                    <th style={{ paddingLeft: "0px" }}>Delete date</th>
                    <th style={{ paddingLeft: "0px" }}>Status</th>
                    <th style={{ paddingLeft: "0px" }}>Number of article</th>
                    <th style={{ paddingLeft: "0px" }}>Action</th>
                    <th style={{ paddingLeft: "0px" }}>Team members</th>
                  </tr>
                </thead>
                <tbody>
                  {orders &&
                    filteredData.map((order: any, i: number) => (
                      <>
                        {order.products.length > 0 && (
                          <tr
                            onClick={() => {
                              setShowTable(!showTable);
                              handleClick(order.id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <td
                              width={"5%"}
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            >
                              <button
                                onClick={() => {
                                  setShowTable(!showTable);
                                  handleClick(order.id);
                                }}
                              >
                                {showTable && showTr == order.id ? (
                                  <>
                                    <FolderOpenOutlined
                                      style={{
                                        color: "#00468C",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <FolderAddOutlined
                                      style={{
                                        color: "#00468C",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </>
                                )}
                              </button>
                            </td>

                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            >
                              {order.reference}
                            </td>

                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            >
                              {order.products[0].collection_name || "-"}
                            </td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            >
                              {moment(order.updated_at).format("DD/MM/YYYY")}
                            </td>

                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            ></td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            >
                              <p
                                className="mb-0"
                                style={{ marginLeft: "50px" }}
                              >
                                {order?.products?.length}
                              </p>
                            </td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            ></td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            ></td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            >
                              <Avatar.Group
                                maxCount={2}
                                size="large"
                                maxStyle={{
                                  color: "white",
                                  backgroundColor: "#7a9ec5",
                                }}
                              >
                                {order?.collaborators?.map((collab: any) => (
                                  <Tooltip
                                    title={collab.name}
                                    key={collab.id}
                                    placement="bottom"
                                  >
                                    {collab.logo ? (
                                      <Avatar
                                        style={{
                                          boxShadow:
                                            "1px 0px 5px rgba(0,0,0,0.4)",
                                        }}
                                        src={
                                          process.env.REACT_APP_STORAGE_URL +
                                          collab.logo
                                        }
                                      />
                                    ) : (
                                      <Avatar
                                        style={{
                                          backgroundColor: "#ddd",
                                          boxShadow:
                                            "1px 0px 5px rgba(0,0,0,0.2)",
                                        }}
                                        icon={
                                          <UserOutlined
                                            style={{ verticalAlign: "2px" }}
                                          />
                                        }
                                      />
                                    )}
                                  </Tooltip>
                                ))}
                              </Avatar.Group>
                            </td>
                          </tr>
                        )}
                        {showTable && showTr == order.id && (
                          <tr>
                            <td
                              colSpan={10}
                              style={{
                                backgroundColor: "#FCFDFF",
                                padding: "0.2rem",
                                paddingLeft: "4%",
                              }}
                            >
                         {/*      <table className="tableArticle">
                                {order.products?.map((elm: any) => (
                                  <>
                                    <tr style={{ cursor: "pointer" }}>
                                      <td
                                        style={{
                                          //   width: "50%",

                                          borderTop: "none",
                                        }}
                                        className="details_td_custom_order_cancelled"
                                      >
                                        {elm.name}
                                      </td>

                                      <td
                                        style={{
                                          //   width: "5%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          //    width: "21%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          //  width: "8%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}
                                        className="status_td_custom_order_cancelled"
                                      >
                                        <Tag
                                          color="processing"
                                          style={{
                                            borderRadius: "50px",
                                            cursor: "pointer",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {elm.status?.name}
                                        </Tag>
                                      </td>
                                      <td
                                        style={{
                                          // width: "22%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}
                                      >
                                       

                                        <Button
                                          onClick={() =>
                                            showModalRestore(order.id, elm.id)
                                          }
                                          icon={
                                            <InboxOutlined
                                              style={{
                                                color: "#00468c",
                                                verticalAlign: "1px",
                                              }}
                                            />
                                          }
                                        >
                                          Restore
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </table> */}


{order.products?.map((elm: any) => (
                                <>
                                 
                                    <div className="row row_product_custom_cancelled_user">
                                      <div className="product_name_cancelled_custom_order_user">
                                      {elm.name}
                                      </div>
                                      <div className="deleted_date_cancelled_custom_order_user">
                                        {" "}
                                        {moment(elm.updated_at).format("DD/MM/YYYY")}
                                      </div>

                                      <div className="status_cancelled_custom_order_user">
                                      <Tag
                                          color="#182844"
                                          style={{
                                            borderRadius: "50px",
                                            cursor: "pointer",
                                            fontWeight: "normal",
                                          }}
                                        >
                                              {elm.status.name}
                                        </Tag>
                                      </div>

                                      <div >
                                     

                                          <Button
    onClick={() =>
      showModalRestore(order.id, elm.id)
    }                                        className="restoreBtn"
                                          >
                                            Restore
                                          </Button>
                                      </div>
                                    </div>
                                  
                                </>
                              ))}
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                </tbody>
              </table>
                </div>
              <Modal
                title="Restore Order"
                visible={isModalRestore}
                onCancel={handleCancel}
                footer={[
                  <Button
                  className="restore_2"
                  loading={loading}
                  onClick={restore}
                >
                  Start again from PROTO
                </Button>,
                  <Button
                    className="restore"
                    loading={loading}
                    onClick={restoreProdLeft}
                  >
                    Where I left off please!
                  </Button>,
                
                ]}
              >
                <p className={"text_par_model"}>In what stage would you like to restore your order? </p>
              </Modal>
            </div>
          ) : orders && orders.data.length > 0 && status == "completed" ? (
              <div className={"mt-3"}>
                <div className="search-bar searchfaq" style={{width: "100%"}}>
                  <div className="input-container">
                    <input type="text" placeholder="Search your order" onChange={handleSearchInputChange}
                           className={"search_input"}/>
                    <HrIcon></HrIcon>
                    <button type="submit" className={"submit_input"}><SearchOutlined/></button>
                  </div>
                </div>
                <div style={{overflowY: "auto"}}>
              <table className="table table-direct-order">
                <thead>
                  <tr>
                    <th></th>
                    <th style={{ paddingLeft: "0px" }}>Reference</th>
                    <th style={{ paddingLeft: "0px" }}>Collection Name</th>
                    <th style={{ paddingLeft: "0px" }}>Delivery Date</th>

                    <th style={{ paddingLeft: "0px" }}>Number of article</th>
                    <th style={{ paddingLeft: "0px" }}>Quantity</th>
                    <th style={{ paddingLeft: "0px" }}>Action</th>
                    <th style={{ paddingLeft: "0px" }}>Team members</th>
                  </tr>
                </thead>
                <tbody>
                  {orders &&
                    filteredData.map((order: any, i: number) => (
                      <>
                        {order.products.length > 0 && (
                          <tr
                            onClick={() => {
                              setShowTable(!showTable);
                              handleClick(order.id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <td
                              width={"5%"}
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            >
                              <button
                                onClick={() => {
                                  setShowTable(!showTable);
                                  handleClick(order.id);
                                }}
                              >
                                {showTable && showTr == order.id ? (
                                  <>
                                    <FolderOpenOutlined
                                      style={{
                                        color: "#00468C",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <FolderAddOutlined
                                      style={{
                                        color: "#00468C",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </>
                                )}
                              </button>
                            </td>

                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            >
                              {order.reference}
                            </td>

                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            >
                              {order.products[0].collection_name || "-"}
                            </td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            ></td>

                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            ><p
                            className="mb-0"
                            style={{ marginLeft: "50px" }}
                          >{order.products.length}</p></td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                              style={{ paddingLeft: 0 }}
                            >
                              
                            </td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            ></td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            ></td>
                            <td
                              className={
                                showTable && showTr == order.id
                                  ? "top_article active_tr"
                                  : "top_article"
                              }
                            >
                              <Avatar.Group
                                maxCount={2}
                                size="large"
                                maxStyle={{
                                  color: "white",
                                  backgroundColor: "#7a9ec5",
                                }}
                              >
                                {order?.collaborators?.map((collab: any) => (
                                  <Tooltip
                                    title={collab.name}
                                    key={collab.id}
                                    placement="bottom"
                                  >
                                    {collab.logo ? (
                                      <Avatar
                                        style={{
                                          boxShadow:
                                            "1px 0px 5px rgba(0,0,0,0.4)",
                                        }}
                                        src={
                                          process.env.REACT_APP_STORAGE_URL +
                                          collab.logo
                                        }
                                      />
                                    ) : (
                                      <Avatar
                                        style={{
                                          backgroundColor: "#ddd",
                                          boxShadow:
                                            "1px 0px 5px rgba(0,0,0,0.2)",
                                        }}
                                        icon={
                                          <UserOutlined
                                            style={{ verticalAlign: "2px" }}
                                          />
                                        }
                                      />
                                    )}
                                  </Tooltip>
                                ))}
                              </Avatar.Group>
                            </td>
                          </tr>
                        )}
                        {showTable && showTr == order.id && (
                          <tr>
                            <td
                              colSpan={10}
                              style={{
                                backgroundColor: "#FCFDFF",
                                padding: "0.2rem",
                                paddingLeft: "4%",
                              }}
                            >
                            {/*   <table className="tableArticle">
                                {order.products?.map((elm: any) => (
                                  <>
                                    <tr style={{ cursor: "pointer" }}>
                                      <td
                                        style={{
                                          //   width: "50%",

                                          borderTop: "none",
                                        }}
                                        className="details_td_custom_order_cancelled"
                                      >
                                        {elm.name}
                                      </td>

                                      <td
                                        style={{
                                          //   width: "5%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          //    width: "21%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}
                                      >
                                        {moment(elm.delivery_date_item).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          //  width: "8%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}
                                        className="status_td_custom_order_cancelled"
                                      >
                                        {elm.qty_item}
                                      </td>
                                      <td
                                        style={{
                                          // width: "22%",
                                          paddingLeft: "0px",
                                          borderTop: "none",
                                        }}
                                      >
                                        <Link
                                          to={`${history.location.pathname}/${order.id}/${elm.id}/progress/customized`}
                                        >
                                          View History
                                        </Link>
                                      
                                            <Button
                                              size={"middle"}
                                              style={{ borderRadius: "4px" }}
                                              onClick={() =>
                                                showModalRelaunch(
                                                   elm.id,
                                                  elm.product_id,
                                                )
                                              }
                                            >
                                              Relaunch
                                            </Button>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </table> */}


{order.products?.map((elm: any) => (

<>
                                 
                                 <div className="row row_product_custom_completed_user">
                                   <div className="product_name_completed_custom_order_user">
                                   {elm.name}
                                   </div>
                                   <div className="delivery_date_completed_custom_order_user">
                                     {" "}
                                     {elm.delivery_date_item
                        ? moment(elm.delivery_date_item).format("DD/MM/YYYY")
                        : "-"}
                                   </div>

                                   <div className="qty_completed_custom_order_user">
                                   {elm.qty_item ?? "-"} 
                                   </div>

                                   <div >
                                 

                                       <button  className="relaunch"       onClick={() =>
                                                showModalRestore(
                                                   elm.id,
                                                  elm.product_id,
                                                )}>Relaunch</button>
                                       <Link  className="view_history" to={`${history.location.pathname}/${order.id}/${elm.id}/progress/customized`}>
                          View History
                        </Link>
                                   </div>
                                 </div>
                               
                             </>
                               ))}
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                </tbody>
              </table>
                </div>
              <Modal
            title="Relaunch production"
            visible={isModalRelaunch}
            onCancel={handleCancelRelaunch}
            footer={[
             
              <Button
                className="restore_2"
                //   loading={loading}
                onClick={relaunchPreSMS}
              >
                Start again from PRE SMS
              </Button>,
               <Button
               className="restore"
               //  loading={loading}
               onClick={relaunchProduction}
             >
               Straight to PRODUCTION
             </Button>,
            ]}
          >
            <p>In what stage would you like to relaunch the production?	 </p>
          </Modal>
            </div>
          ) : (
            <Result
              status="404"
              title="No Orders"
              style={{ width: "100%" }}
              subTitle="Sorry, there's no orders sent with these options."
              extra={
                filterBy.client || filterBy.reference ? (
                  <Button
                    type="primary"
                    onClick={(e: any) => {
                      onFilter(e, { reference: "", client: 0, type: 0 }, 1);
                      setFilterBy({ reference: "", client: 0, type: 0 });
                    }}
                  >
                    Clear Filter
                  </Button>
                ) : null
              }
            />
          )}
        </>
      ) : filterBy.type === 6 ? (
        <>
          {filterLoading ? (
            <div
              className="loading_orders"
              style={{ height: "50vh", width: "50vw", margin: "0 auto" }}
            >
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : ordersCancel && ordersCancel.data.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Client</th>

                  <th>Delete Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {ordersCancel &&
                  ordersCancel.data.map((order: any, i: number) => (
                    <tr key={i}>
                      <td>{order.name}</td>
                      <td>{order.client.name}</td>

                      <td>
                        <p>{moment(order.updated_at).format("MMMM DD YYYY")}</p>
                      </td>
                      <td>
                        <div>
                          <Popconfirm
                            placement="top"
                            title={
                              "Are you sure you want to delete this order ?"
                            }
                            onConfirm={() => deleteProduct(order.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              type="ghost"
                              size={"middle"}
                              className="mr-2"
                              style={{ borderRadius: "4px" }}
                              icon={
                                <DeleteOutlined style={{ color: "#00468c" }} />
                              }
                              loading={deleteLoading}
                            >
                              Delete
                            </Button>
                          </Popconfirm>
                          <Popconfirm
                            placement="top"
                            title={
                              "Are you sure you want to restore this order ?"
                            }
                            //    onConfirm={() => restoreProduct(order.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              size={"middle"}
                              style={{ borderRadius: "4px" }}
                              icon={
                                <InboxOutlined
                                  style={{
                                    color: "#00468c",
                                    verticalAlign: "1px",
                                  }}
                                />
                              }
                              loading={restoreLoading}
                            >
                              Restore
                            </Button>
                          </Popconfirm>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5}>
                    {/*     <Pagination
                      defaultCurrent={orders && orders.current_page}
                      defaultPageSize={10}
                      total={orders && orders.total}
                      hideOnSinglePage
                      responsive
                      onChange={(page: any) =>
                        !filterBy.client && !filterBy.client
                          ? history.push(
                              `/all-clients/${params.id}/${params.tab_key}?page=${page}`
                            )
                          : onFilter(null, filterBy, page)
                      }
                    /> */}
                  </td>
                </tr>
              </tfoot>
            </table>
          ) : (
            <Result
              status="404"
              title="No Orders"
              style={{ width: "100%" }}
              subTitle="Sorry, there's no orders sent with these options."
              extra={
                filterBy.client || filterBy.reference ? (
                  <Button
                    type="primary"
                    onClick={(e: any) => {
                      onFilter(e, { reference: "", client: 0, type: 0 }, 1);
                      setFilterBy({ reference: "", client: 0, type: 0 });
                    }}
                  >
                    Clear Filter
                  </Button>
                ) : null
              }
            />
          )}
        </>
      ) : (
        <>
          {filterLoading ? (
            <div
              className="loading_orders"
              style={{ height: "50vh", width: "50vw", margin: "0 auto" }}
            >
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          ) : ordersCancel && ordersCancel.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Client</th>
                  <th>Quantity</th>
                  <th>Delivery Date</th>
                </tr>
              </thead>
              <tbody>
                {ordersCancel &&
                  ordersCancel.map((order: any, i: number) => (
                    <tr key={i}>
                      <td>{order.name}</td>
                      <td>{order.client.name}</td>
                      <td>{order.orders_status.qty}</td>
                      <td>
                        {moment(order.orders_status.delivery_date).format(
                          "MMMM DD YYYY"
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5}>
                    {/*     <Pagination
                    defaultCurrent={orders && orders.current_page}
                    defaultPageSize={10}
                    total={orders && orders.total}
                    hideOnSinglePage
                    responsive
                    onChange={(page: any) =>
                      !filterBy.client && !filterBy.client
                        ? history.push(
                            `/all-clients/${params.id}/${params.tab_key}?page=${page}`
                          )
                        : onFilter(null, filterBy, page)
                    }
                  /> */}
                  </td>
                </tr>
              </tfoot>
            </table>
          ) : (
            <Result
              status="404"
              title="No Orders"
              style={{ width: "100%" }}
              subTitle="Sorry, there's no orders sent with these options."
              extra={
                filterBy.client || filterBy.reference ? (
                  <Button
                    type="primary"
                    onClick={(e: any) => {
                      onFilter(e, { reference: "", client: 0, type: 0 }, 1);
                      setFilterBy({ reference: "", client: 0, type: 0 });
                    }}
                  >
                    Clear Filter
                  </Button>
                ) : null
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomizedOrdersCompany;
