import { useEffect, useState } from "react";
import "./ToolBarProducts.scss";
import Trash from "../../icons/Trash";
import Download from "../../icons/Download";
import Bag from "../../icons/Bag";
import Folder from "../../icons/Folder";
import MooveTool from "../../icons/MooveTool";
import { motion } from "framer-motion";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { handleBottomAddToCollection } from "../../actions/actionCreators";
import {
  archiveFolder,
  clearSelectedFolders,
  clearSelectedProducts,
  deleteProductsfromFolder,
  handleMovingState,
  handleMovingStateProduct,
  handleMovingStateProducts,
} from "../../actions/folders-actions/actions";
import { Popconfirm, message, Input, DatePicker } from "antd";
import { addProdDemcoToOrder } from "../../actions/order-actions/actions";
import { Spin, Drawer } from "antd";
import order_sent from "../../img/order_sent.jpg";
import { LoadingOutlined } from "@ant-design/icons";
import { history } from "../../index";
import store from "../../store/store";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { DeleteOutlined } from "@ant-design/icons";
import {
  createArticle,
  getCollaborators,
} from "../../actions/order-actions/actions";
import Select from "react-select";
export interface IToolBarProductsProps {
  setProductsToOrder: (p: any) => void;
  productsToOrder: any;
}

export default function ToolBarProducts({
  setProductsToOrder,
  productsToOrder,
}: IToolBarProductsProps) {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [name, setName] = useState("");
  const [date_delivery, setDeliveryDate] = useState("");
  const [saison, setSaison] = useState("");

  const [year, setYear] = useState("");

  const [gender, setGender] = useState("");

  const [theme, setTheme] = useState("");
  const [disable, setDisable] = useState(false);
  const [orderName, setOrderName] = useState("");
  const [orderDetails, setOrderDetails] = useState("");

  const [collaborator, setCollaborator] = useState([]);

  const { collaborators, newOrder, orders, createOrderLoading } = useSelector(
    (state: any) => state.orders
  );

  const { register, control, handleSubmit, errors, setValue, getValues } =
    useForm<any>();

  const [folderId, setfolderId] = useState("");
  const [folder, setfolder] = useState<any>("folder");
  const [subfolders, setsubfolders] = useState<any>([]);
  const [isRequired, setIsRequired] = useState(false);

  const folders = useSelector((state: RootStateOrAny) => state.folders);
  const sortedFolders = useSelector(
    (state: RootStateOrAny) => state.folders.sortedFolders
  );
  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };
  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  useEffect(() => {
    if (folders.oneFolder) {
      setfolder(folders.oneFolder);
      setName(folders.oneFolder.name);
    } else {
      setName(folders.oneFolder.name);
      setfolderId(folders.oneFolder.id);
    }

    setsubfolders(
      folders.folders.filter((el: any) => el.parent_id === folders.oneFolder.id)
    );
  }, []);

  useEffect(() => {
    dispatch(getCollaborators(""));
  }, []);

  const handleCollabChange = (e: any) => {
    let arryColl: any = [];
    e &&
      e.map((el: any) => {
        arryColl.push(el.value);
      });
    setCollaborator(arryColl);
  };

  const errorBorder = (field: string) => {
    return {
      borderBottom: errors[field]?.type === "required" ? "1px 1px red" : "",
      boxShadow:
        errors[field]?.type === "required"
          ? "0 0 0 2px rgb(255,0,0, 0.4)"
          : "none",
    };
  };

  useEffect(() => {
    // fetch new state of productsToOrder here
  }, [productsToOrder]);

  const { addDemcoProductOrderLoading } = useSelector(
    (state: any) => state.orders
  );
  const substructSubProducts = (folder: any, products: any) => {
    console.log("folder: ", folder);
    if (folder && folder.products) {
      products.push(folder.products);
    }

    if (folder && folder.folders && folder.folders.length > 0) {
      folder.folders.forEach((_folder: any) => {
        substructSubProducts(_folder, products);
      });
    }
    console.log(products);
    return;
    // return folders;
  };
  const sendOrder = () => {
    const folderId = folders.oneFolder.id;
    let arr: any = [];
    console.log(productsToOrder.length);

    const selectedFolderFromSortedFolders = sortedFolders.find(
      (el: any) => el.id === folderId
    );
    substructSubProducts(selectedFolderFromSortedFolders, arr);

    dispatch(
      addProdDemcoToOrder(
        productsToOrder.map((el: any) => {
          return {
            ...el,
            qty: 0,
          };
        }),
        /*  arr.flat().map((el: any) => {
          return {
            ...el,
            qty: 0,
          };
        }), */
        false,
        name,
        date_delivery
      )
    );

    setIsOpenDrawer(true);
    setTimeout(() => {
      setIsOpenDrawer(false);
      dispatch(clearSelectedProducts());
    }, 2000);
  };

  function handleChangeDeliveryDate(e: any) {
    setDeliveryDate(e.target.value);

    console.log(date_delivery);
  }

  function handleChangeTheme(e: any) {
    setTheme(e.target.value);
  }
  function handleChangeGender(e: any) {
    console.log(e);

    setGender(e);
    console.log(gender);
  }

  function handleChangeYear(e: any) {
    setYear(e.target.value);
  }
  function handleChangeSaison(e: any) {
    console.log(e);
    setSaison(e);
  }

  function handleChangeOrderName(e: any) {
    setOrderName(e.target.value);
  }

  function handleChangeSeason(e: any) {
    setSaison(e.target.value);
  }

  function handleChangeDetails(e: any) {
    setOrderDetails(e.target.value);
  }

  const sendOrderNew = async () => {
    if (date_delivery != "" && name != "") {
      setDisable(true);
      const folderId = folders.oneFolder.id;
      let arr: any = [];
      let nameoforder = orderName == "" ? name : orderName;

      if (productsToOrder.length == 1) {
        const selectedFolderFromSortedFolders = sortedFolders.find(
          (el: any) => el.id === folderId
        );
        substructSubProducts(selectedFolderFromSortedFolders, arr);

        const response = await dispatch(
          addProdDemcoToOrder(
            productsToOrder.map((el: any) => {
              return {
                ...el,
                qty: 0,
              };
            }),

            false,
            nameoforder,
            date_delivery,
            collaborator,
            saison,
            year,
            gender,
            theme,
            orderDetails
          )
        );
        setDisable(false);
        setIsOpenDrawer(true);

        setIsOpenDrawer(false);
        dispatch(clearSelectedProducts());
        console.log(response);
        window.location.href = `/my-orders/demco-product-orders/in-progress`;
      } else {
        const selectedFolderFromSortedFolders = sortedFolders.find(
          (el: any) => el.id === folderId
        );
        substructSubProducts(selectedFolderFromSortedFolders, arr);

        const response = await dispatch(
          addProdDemcoToOrder(
            productsToOrder.map((el: any) => {
              return {
                ...el,
                qty: 0,
              };
            }),

            false,
            nameoforder,
            date_delivery,
            collaborator,
            saison,
            year,
            gender,
            theme,
            orderDetails
          )
        );
        setDisable(false);

        setIsOpenDrawer(true);

        setIsOpenDrawer(false);
        dispatch(clearSelectedProducts());

        window.location.href = `/my-orders/demco-product-orders/in-progress`;
      }
    } else {
      setIsRequired(true);
    }

    /*   const selectedFolderFromSortedFolders = sortedFolders.find(
      (el: any) => el.id === folderId
    );
    substructSubProducts(selectedFolderFromSortedFolders, arr);
 */
    /*     dispatch(
      addProdDemcoToOrder(
        productsToOrder.map((el: any) => {
          return {
            ...el,
            qty: 0,
          };
        }), */
    /*  arr.flat().map((el: any) => {
          return {
            ...el,
            qty: 0,
          };
        }), */
    /*         false,
        name
      )
    ); */

    /*    setIsOpenDrawer(true);
    setTimeout(() => {
      setIsOpenDrawer(false);
      dispatch(clearSelectedProducts());
    }, 2000); */
  };

  const handleRemoveProduct = (itemToRemove: any) => {
    const updatedProducts = productsToOrder.filter(
      (item: any) => item !== itemToRemove
    );
    setProductsToOrder(updatedProducts);
  };

  return (
    <motion.div
      transition={{ type: "tween" }}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      className="tool_bar_container"
    >
      <div className="left_side">
        <p>
          <span>({folders.selectedProducts.length})</span>{" "}
          {width > 768 && "Products selected"}
        </p>
      </div>
      <div className="right_side">
        {/* <div className="functionality_container">
         
          <p className="functionality_text">Unselect</p>
        </div> */}
        <div
          className="functionality_container"
          onClick={() => {
            dispatch(handleBottomAddToCollection());
            dispatch(handleMovingStateProducts());
          }}
        >
          <MooveTool />
          {width > 1024 && <p className="functionality_text">Move to</p>}
        </div>
        {/* <div className="functionality_container">
          <Download />
          {width > 1024 && <p className="functionality_text">Download</p>}
        </div> */}
        {/*    <Popconfirm
          title="Are you sure you want to order the selected products?"
          //      onConfirm={sendOrder}
          onCancel={() => {}}
          onConfirm={() => setIsOpenDrawer(true)}
          okText="Yes"
          cancelText="No"
        > */}
        <div
          className="functionality_container"
          // onClick={() => {
          //   sendOrder();
          // }}

          onClick={() => {
            setIsOpenDrawer(true);
          }}
        >
          {addDemcoProductOrderLoading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            <>
              <Bag />
              {width > 1024 && <p className="functionality_text">Order</p>}
            </>
          )}
        </div>
        {/* </Popconfirm> */}
        <Popconfirm
          placement="topRight"
          title="Are you sure to delete the selected products?"
          onConfirm={() => {
            var idsProducts = folders.selectedProducts.map((item: any) => {
              return item["id"];
            });
            folders.selectedProducts.length > 0 &&
              dispatch(
                deleteProductsfromFolder(
                  idsProducts,
                  folders.selectedProducts[0].idParentFolder
                )
              );
            dispatch(clearSelectedProducts());
          }}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <div className="functionality_container">
            <Trash />
            {width > 1024 && <p className="functionality_text">Delete</p>}
          </div>
        </Popconfirm>
      </div>
      {/*  <Drawer
        className="order_sent_drawer"
        placement={"bottom"}
        closable={true}
        onClose={() => {
          setIsOpenDrawer(false);
        }}
        visible={isOpenDrawer}
        key={"bottom"}
      >
        <div className="my-2">
          <h1>Your order has been sent</h1>
          <p>
            We will train you in the coming days on the advancement of your
            product.{" "}
          </p>
        </div>
        <img src={order_sent} alt="order sent" className="img-fluid my-2" />
      </Drawer> */}

      <Drawer
        // className="order_sent_drawer"
        placement={"bottom"}
        closable={true}
        onClose={() => {
          dispatch(clearSelectedProducts());
          setProductsToOrder([]);
          setIsOpenDrawer(false);
        }}
        visible={isOpenDrawer}
        key={"bottom"}
        style={{ paddingTop: "25px" }}
      >
        <div className="row">
          {/* left container */}

          <div
            className="col-6 "
            style={{
              paddingTop: "88px",
              paddingLeft: "112px",
              paddingRight: "112px",
            }}
          >
            <div className="titledrawerConfirm">Selected products</div>
            <div style={{ fontSize: "16px", marginBottom: "32px" }}>
              Please review your selected items below before confirming your
              order.
            </div>
            {productsToOrder.length > 0 ? (
              <div
                className="border"
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#F8FAFC",
                  padding: "12px",
                }}
              >
                {productsToOrder.map((item: any, key: any) => {
                  return (
                    <>
                      <div
                        className="row rowProdcut"
                        style={{ position: "relative" }}
                      >
                        <div
                          style={{
                            borderRadius: "8px",
                            marginRight: "16px",
                          }}
                        >
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              item.front_image
                            }
                            width="100px"
                            height="72px"
                            className="orderBoxImage"
                          />
                        </div>
                        <div className="">
                          <span className="titleorderContainerH1">
                            {item.name}
                          </span>
                          <br></br>
                          <span className="titleorderContainerH2">
                            {item.code}
                          </span>
                        </div>
                        <div style={{ position: "absolute", right: "0" }}>
                          <button
                            onClick={() => {
                              handleRemoveProduct(item);
                              if (productsToOrder.length == 1) {
                                dispatch(clearSelectedProducts());
                                setProductsToOrder([]);
                                setIsOpenDrawer(false);
                              }
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "5px",
                              backgroundColor: "#E5ECF3",
                              padding: "16px",
                              width: "32px",
                              height: "32px",
                            }}
                          >
                            <DeleteOutlined />
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            ) : (
              <>The list is empty </>
            )}
          </div>
          <div
            className="col-6 "
            style={{
              paddingTop: "88px",
              paddingLeft: "112px",
              paddingRight: "112px",
            }}
          >
            {" "}
            <div className="titledrawerConfirm">Order details</div>
            <div style={{ fontSize: "16px", marginBottom: "32px" }}>
              Complete your order by providing the details below.
            </div>
            <div>
              <label className="mr-3 mb-1">Collection name </label>
              <Input
                type="text"
                style={{
                  borderRadius: "5px",
                  marginBottom: "16px",
                }}
                defaultValue={name}
                onChange={handleChangeOrderName}
              ></Input>
              {isRequired && name === "" && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
            <label className="mr-3 mb-1">Season</label>
            <Input
              type="text"
              style={{
                borderRadius: "5px",
                marginBottom: "16px",
              }}
              defaultValue={saison}
              onChange={handleChangeSeason}
            ></Input>
            {/*    <label className="mr-3 mb-0">Season</label>
            <Select
              size="large"
              className="selectGender"
              style={{ width: "100%", marginBottom: "16px" }}
              onSelect={(value: any) => setSaison(value)}
            >
              <Select.Option value="">--</Select.Option>
              <Select.Option value="AW">AW</Select.Option>
              <Select.Option value="FW">FW</Select.Option>
              <Select.Option value="SS">SS</Select.Option>
              <Select.Option value="SU">SU</Select.Option>
              <Select.Option value="PF">PF</Select.Option>
            </Select>
            <label className="mr-3 mb-0">Year</label>
            <Input
              type="text"
              style={{
                borderRadius: "5px",
                marginBottom: "16px",
              }}
              onChange={handleChangeYear}
            ></Input>
            <label className="mr-3 mb-0">Gender</label>
            <Select
              size="large"
              className="selectGender"
              style={{ width: "100%", marginBottom: "16px" }}
              onSelect={(value: any) => {
                setGender(value);
                console.log(gender);
              }}
            >
              <Select.Option value="">--</Select.Option>
              <Select.Option value="Men">Men</Select.Option>
              <Select.Option value="Woman">Woman</Select.Option>
              <Select.Option value="Kids">Kids</Select.Option>
            </Select>
          
       
            <label className="mr-3 mb-0">Theme</label>
            <Input
              type="text"
              style={{
                borderRadius: "5px",
                marginBottom: "24px",
              }}
              onChange={handleChangeTheme}
            ></Input>
            <br />
            <label className="mr-3 mb-0">Details</label>
            <TextArea
              showCount
              maxLength={100}
              style={{ marginBottom: 16, borderRadius: "5px" }}
              className="textareaDetailsOrder"
              onChange={handleChangeDetails}
              placeholder="your details "
            />
            */}
            <label className="mr-3 mb-0">Select Collaborators</label>
            <Select
              isMulti
              name="colors"
              placeholder="Select your collaborators"
              options={
                collaborators &&
                collaborators.map((el: any) => {
                  return {
                    label: el.name,
                    value: el.id,
                  };
                })
              }
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleCollabChange}
            />
            <div>
              <div>
                <label className="mr-3 mb-0 mt-3">Delivery date</label>
                <DatePicker
                  disabledDate={(current) =>
                    current.isBefore(moment().add(15, "days"))
                  }
                  format={"DD/MM/YYYY"}
                  onChange={(date: any, dateString: any) =>
                    setDeliveryDate(dateString)
                  }
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    marginBottom: "16px",
                  }}
                />
                {isRequired && date_delivery === "" && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </div>
              <button
                className="view-details"
                onClick={sendOrderNew}
                disabled={disable}
                style={{ marginTop: "16px" }}
              >
                {disable ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 24, color: "white" }}
                        spin
                      />
                    }
                  />
                ) : (
                  <>Confirm order</>
                )}
              </button>
            </div>
          </div>
        </div>
      </Drawer>
    </motion.div>
  );
}
