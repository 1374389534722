import React from "react";

export type IPastIconProps = {};

const PastIcon: React.FC<IPastIconProps> = ({}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9844 2.01562C20.1094 2.01562 21 2.90625 21 3.98438V20.0156C21 21.0938 20.1094 21.9844 18.9844 21.9844H5.01562C3.89062 21.9844 3 21.0938 3 20.0156V3.98438C3 2.90625 3.89062 2.01562 5.01562 2.01562H9.1875C9.60938 0.84375 10.6875 0 12 0C13.3125 0 14.3906 0.84375 14.8125 2.01562H18.9844ZM12 2.01562C11.4375 2.01562 11.0156 2.4375 11.0156 3C11.0156 3.5625 11.4375 3.98438 12 3.98438C12.5625 3.98438 12.9844 3.5625 12.9844 3C12.9844 2.4375 12.5625 2.01562 12 2.01562ZM18.9844 20.0156V3.98438H17.0156V6.98438H6.98438V3.98438H5.01562V20.0156H18.9844Z"
        fill="#182844"
      />
    </svg>
  );
};

export { PastIcon };
