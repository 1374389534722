import { useEffect, useState } from "react";
import "../sass/AccountInfos.scss";
import { Button } from "../components/Button/Button";
import ResetPasswordForm from "../components/ResetPasswordForm/ResetPasswordForm";
import { AnimatePresence } from "framer-motion";
import ConfirmPasswordModal from "../components/ConfirmPasswordModal/ConfirmPasswordModal";
import { useSelector, useDispatch } from "react-redux";
import {
  loadConnectedUser,
  updateAccountAction,
} from "../actions/auth-actions/actions";
import { removeAlert, setAlert } from "../actions/alerts-actions/actions";
import { v4 as uuidv4 } from "uuid";
import logoGreen from "../img/logo_green.svg";
import { LoadingOutlined } from "@ant-design/icons";

import { Popconfirm, Select, Spin, Button as AntButton } from "antd";
import Head from "../components/Head";

const { Option } = Select;
export interface IAccountInfosProps {}

export default function AccountInfos(props: IAccountInfosProps) {
  const [showReset, setShowReset] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const auth = useSelector((state: any) => state.auth);
  const { user, isAdmin, updateError } = useSelector(
    (state: any) => state.auth
  );
  const [preview, setPreview] = useState<any>(null);
  const [infos, setInfos] = useState<any>({
    name: "",
    logo: "",
    company: "",
    division: "",
    title: "",
    email: "",
    address: "",
    password: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setInfos({
        ...infos,
        name: user?.name,
        company: user?.company?.name,
        title: user?.title,
        email: user?.email,
        address: user?.address,
      });
    }
  }, [user]);

  const updateAccount = () => {
    let obj: any = {};
    for (const [key, value] of Object.entries(infos)) {
      if (value) {
        obj[key] = value;
      }
    }
    if (!obj.hasOwnProperty("name") || !obj.hasOwnProperty("email")) {
      var id = uuidv4();
      dispatch(
        setAlert(
          "Your name and your email address are required!",
          "success",
          id
        )
      );
      setTimeout(() => {
        dispatch(removeAlert(id));
      }, 6000);
      return;
    } else {
      let formData = new FormData();
      // delete infos.company;
      Object.keys(obj)
        .filter((e: any) => e !== "company")
        .map((el: any) => formData.append(el, obj[el]));

      if (!isAdmin) {
        formData.append("_method", "patch");
      }
      dispatch(updateAccountAction(formData, auth.user.id, isAdmin));
      var id = uuidv4();
      dispatch(setAlert("Accounts Infos Updated Successfully", "success", id));
      setIsModalVisible(false);
      setTimeout(() => {
        dispatch(removeAlert(id));
      }, 6000);
    }
  };
  useEffect(() => {
    dispatch(loadConnectedUser(false));
  }, []);

  console.log(updateError?.response?.data);

  // useEffect(() => {
  //   if (user) {
  //     setInfos({
  //       ...user,
  //       division_id: user?.company?.divisions.find(
  //         (el: any) => el.id === user.division_id
  //       ),
  //     });
  //   }
  // }, [user]);

  useEffect(() => {
    if (showReset) {
      //@ts-ignore
      document.querySelector("body").style.overflowY = "hidden";
      //@ts-ignore
      document.querySelector("body").style.height = "100vh";
    } else {
      //@ts-ignore
      document.querySelector("body").style.overflowY = "auto";
      //@ts-ignore
      document.querySelector("body").style.height = "auto";
    }
  });
  const handleChange = (e: any) => {
    setInfos({ ...infos, [e.target.name]: e.target.value });
  };

  const handleChangeFile = (e: any) => {
    setInfos({ ...infos, logo: e.target.files[0] });
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  // const handleChangeDivision = (e: any) => {
  //   setInfos({ ...infos, division_id: e });
  // };
  return (
    user && (
      <div className="container account_info">
        <Head title="Account Information" />
        <div className="account_info_container">
          <div className="account_header">
            {!preview && <div
                className="account_header_img"
                style={{
                  backgroundImage: `url("${
                      isAdmin
                          ? user.avatar
                              ? process.env.REACT_APP_STORAGE_URL + user.avatar
                              : logoGreen
                          : user.logo
                              ? process.env.REACT_APP_STORAGE_URL + user.logo
                              : process.env.REACT_APP_STORAGE_URL + user.company.logo
                  }")`,
                }}
            ></div>
            }
            {preview && (
                <img
                    src={preview}
                    style={{
                      width: 50,
                      height: 50,
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    className={
                      "account_header_img"
                    }
                />
            )}
            {/* <img
            src={process.env.REACT_APP_STORAGE_URL + user.logo}
            alt="brand logo"
          /> */}
            {/*   <p>
              {user.name}
              <span className="personnal_info">/ Personal Information</span>
            </p>
*/}
            <p>

              <span className="personnal_info">  {user.name}</span><br/>
              <div><label
                  htmlFor="avatar"
                  className="account_fullname_label label_avatar_update_profil"
              >
                <input
                    type="file"
                    name="avatar"
                    id="avatar"
                    className="upload_avatar_update_profil"
                    placeholder="Upload Your Profile Picture"
                    onChange={handleChangeFile}
                />
                Upload Your Profile Picture
              </label></div>
            </p>
          </div>
          <h2 className="account_infos_title">Account information</h2>
          <div className="container-fluid">

            <div className="account_form_container row">
              <div className="account_infos col-md-6 col-12 mt-3 mt-md-0">
                {/*<h2 className="account_infos_title">Account information</h2>*/}
                <div>
                  <div className="field margin_acount_filed" style={{marginBottom: "92px"}}>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Your Full Name"
                        // value={infos.name}
                        defaultValue={user.name}
                        onChange={handleChange}
                    />
                    <label htmlFor="name" className="account_fullname_label">
                      Full Name
                    </label>
                  </div>
                  {!isAdmin && (
                      <div className="field">
                        <input
                            disabled
                            type="text"
                            name="company"
                            id="company"
                            placeholder="Your Company's Name"
                            defaultValue={user.company.name}
                            onChange={handleChange}
                        />
                        <label
                            htmlFor="company"
                            className="account_company_name_label"
                        >
                          Company Name
                        </label>
                      </div>
                  )}
                  {/* {!isAdmin && (
                    <div className="field">
                      <Select
                        className="select_division"
                        showSearch
                        style={{ borderBottom: "1px solid #e0e0e0" }}
                        placeholder="Select your division"
                        optionFilterProp="children"
                        defaultValue={infos.division_id && infos.division_id.id}
                        onChange={handleChangeDivision}
                      >
                        {user.company.divisions &&
                          user.company.divisions.map((division: any) => (
                            <Option value={division.id} key={division.id}>
                              {division.name}
                            </Option>
                          ))}
                      </Select>
                      <label
                        htmlFor="division"
                        className="account_divion_label"
                      >
                        Division
                      </label>
                    </div>
                  )} */}
                  {/* <div className="field">
                    <input
                      type="text"
                      name="title"
                      id="title"
                      placeholder="Your Job Title"
                      value={infos.title}
                      onChange={handleChange}
                    />
                    <label htmlFor="title" className="account_job_title_label">
                      Job Title
                    </label>
                  </div> */}
                </div>
              </div>
              <div className="account_login_location col-md-6 col-12 mt-3 mt-md-0">
                <div className="account_login_infos">
                  {/*  <h2 className="account_login_infos_title">
                    Login Information
                  </h2>*/}
                  <div>
                    <div className="field">
                      <input
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Your Email"
                          defaultValue={user.email}
                          onChange={handleChange}
                      />
                      <label htmlFor="email" className="account_email_label">
                        E-Mail
                      </label>
                    </div>

                    <div className="reset_password_button">
                      <p
                          onClick={() => setShowReset(true)}
                          className="reset_password"
                      >
                        Change  password
                      </p>
                    </div>
                  </div>
                </div>
                {!isAdmin && (
                    <div className="location_shipping">
                      {/*  <h2 className="location_shipping_title">
                      Location Shipping
                    </h2>*/}
                      <div>
                        <div className="field">
                          <input
                              type="text"
                              name="address"
                              id="address"
                              placeholder="Your Full Name"
                              defaultValue={user.address}
                              onChange={handleChange}
                          />
                          <label
                              htmlFor="address"
                              className="account_location_label"
                          >
                            Shipping Address
                          </label>
                        </div>
                      </div>
                    </div>
                )}
              </div>
            </div>
            <div className="row">
              {/*   <div className={`${preview ? "col-auto" : "col-12"}`}>
                {preview && (
                  <img
                    src={preview}
                    style={{
                      width: 50,
                      height: 50,
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </div>
            <div className={`${preview ? "col" : "col-12"}`}>
                <div className="field">
                  <label
                    htmlFor="avatar"
                    className="account_fullname_label label_avatar_update_profil"
                  >
                    <input
                      type="file"
                      name="avatar"
                      id="avatar"
                      className="upload_avatar_update_profil"
                      placeholder="Upload Your Profile Picture"
                      onChange={handleChangeFile}
                    />
                    Upload Your Profile Picture
                  </label>
                </div>
              </div>*/}
            </div>
          </div>
          <Popconfirm
              placement="top"
              title="Are you sure you want to save changes?"
              onConfirm={updateAccount}
          >
            {/* <Button
              innerButton={
                auth.userUpdateIsLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 24, color: "white" }}
                        spin
                      />
                    }
                  />
                ) : (
                  "Save"
                )
              }
              buttonClass="submit_button_blue submit_button_blue_save"
              // onClick={updateAccount}
            /> */}
            <AntButton
                type="default"
                className="confirm_update_account_btn mx-auto"
                loading={auth.userUpdateIsLoading}
            >
              Save
            </AntButton>
          </Popconfirm>
        </div>
        <AnimatePresence initial={true} exitBeforeEnter={true}>
          {showReset && <ResetPasswordForm setShowReset={setShowReset} />}
        </AnimatePresence>
        <ConfirmPasswordModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setInfos={setInfos}
          infos={infos}
          updateAccount={updateAccount}
        />
        {/* <div className="alerts_position">
          {alerts.alerts.map((el: any) => (
            <Alert alert={el} className="alert_missing_fodler_name" />
          ))}
        </div> */}
      </div>
    )
  );
}
