import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { handleRightDrawer } from "../../actions/actionCreators";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";

interface Props {
  notif: any;
  inPage: boolean;
  position: boolean;
}

export const OrderSentNotif = ({ notif, inPage, position }: Props) => {
  const dispatch = useDispatch();
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  const redirectUser = () => {
    if (notif.data.status === "completed") {
      // user is the owner of the order or collab
      if (user && !isAdmin) {
        return `/my-orders/${notif.data.id}`;
        // if admin
      } else if (user && isAdmin) {
        return `/all-clients/${notif.data?.client?.company_id}/1/${notif.data?.id}`;
      }
    } else {
      return `/edit-order/${notif.data.id}`;
    }
  };

  return inPage ? (
    <Link to={redirectUser()} className="notif_item_page p-2 my-2">
      <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center icon_notif_container">
          {/* <FontAwesomeIcon
            icon={faPaperPlane}
            className="order_sent_notif_icon"
          /> */}

        </div>
{/*         <h2 className="h4_medium m-0">Your order has been sent</h2>
 */}        
 <h2 className="h4_medium mb-0">You succesfully launched a new order, we will confirm it as soon as possible 🛍️</h2>


      </div>
      <p className="selection_date m-0">{moment(notif.created_at).fromNow()}</p>
    </Link>
  ) : (
    <div
      className="border my-2 px-4 py-3"
      style={{
        borderRadius: "8px",
        background: position ? "#e5f1fa" : "white",
        transition: "all 5s ease-in-out",
      }}
    >
      <Link
        to={redirectUser()}
        className="notif_added_prod_container"
        onClick={() => dispatch(handleRightDrawer())}
      >
        <article className="notif_selection order_changes">
         {/*  <FontAwesomeIcon
            icon={faPaperPlane}
            className="order_sent_notif_icon"
          /> */}

          <div className="notif_details flex-grow-1">
{/*             <h2 className="h4_medium mb-0">Your order has been sent</h2>
 */}       

<h2 className="h4_medium mb-0">You succesfully launched a new order, we will confirm it as soon as possible 🛍️</h2>
 
      <div className="d-flex flex-column w-100">

              <p
                className="selection_date mb-0"
                style={{
                  fontSize: "0.7rem",
                  fontWeight: 400,
                  alignSelf: "flex-end",
                }}
              >
                {moment(notif.created_at).fromNow()}
              </p>
            </div>
          </div>
        </article>
      </Link>
    </div>
  );
};
