import React from "react";
import "../sass/CreativeStation.scss";
import { useDispatch, useSelector } from "react-redux";
import { MasonryBlock } from "../components/MasonryBlock/MasonryBlock";
import { getAllCreativeStationPosts } from "../actions/creative-station-actions/actions";
import { useHistory } from "react-router-dom";
import { SnippetsOutlined } from "@ant-design/icons";
import Head from "../components/Head";

interface Props {}

export const CreativeStation = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { moodboars, trending, threeDVideos, isLoading } = useSelector(
    (state: any) => state.posts
  );
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  React.useEffect(() => {
    dispatch(getAllCreativeStationPosts());
  }, []);

  console.log(moodboars);

  return (
    <main className="creative_station">
      <Head title="Creative Station" />
      <header className="cs_header flex-row flex-wrap">
        <div className="d-flex flex-column ml-md-auto mx-auto">
          <p className="h1_huge_creative_station">RELEASE YOUR </p>
          <p className="h1_huge_creative_station_2">INSPIRATION</p>
        </div>
      </header>
      {user && isAdmin && user.permissions.includes("creative-station.update") && (
        <section className="d-flex justify-content-center">
          <button
            onClick={() => history.push("/add-creative-station")}
            className="add_post mr-1"
          >
            <i className="fas fa-pen-nib"></i> ADD NEW POST
          </button>
          <button
            onClick={() => history.push("/creative-station/draft")}
            className="add_post ml-1"
          >
            <SnippetsOutlined style={{ verticalAlign: 0 }} /> GO TO DRAFTS
          </button>
        </section>
      )}

      <section>
        {/* NEWEST = MOODBOARDS*/}
        <MasonryBlock
          data={moodboars}
          title="MOODBOARDS"
          isLoading={isLoading}
        />

        {/* TRENDING = 3D VIDEOS*/}
        <MasonryBlock
          data={threeDVideos}
          title="3D VIDEOS"
          isLoading={isLoading}
        />

        {/* FASHION = TRENDING */}
        <MasonryBlock data={trending} title="TRENDING" isLoading={isLoading} />
      </section>
    </main>
  );
};
