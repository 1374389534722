import "./BannerInfo.scss";
import { Button } from "../Button/Button";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
export interface BannerInfoProps {
  bannerInfo: any;
  type?: string;
}

export default function BannerInfo({ bannerInfo, type }: BannerInfoProps) {
  const [width, setWidth] = useState(window.innerWidth);
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  let men =
    user && !isAdmin && user.genders.find((el: any) => el.name === "Men");
  let women =
    user && !isAdmin && user.genders.find((el: any) => el.name === "Women");
  let kids =
    user && !isAdmin && user.genders.find((el: any) => el.name === "Kids");

  return (
    <div className="banner_container">
      {width > 768 && (
        <div className="Banner_header">
          <div className="blue_line"></div>
          <h1 className="our_label text-center" style={{ color: "#182844" }}>
{/*
            {bannerInfo?.BannerTitle}
*/}
            {bannerInfo?.title_1}
          </h1>
        </div>
      )}

      <div className={bannerInfo?.shadowBlock ? "banner_info_shadow_block " : ""}>
        {width <= 768 && (
            <div className="Banner_header text-center">
              {/*
            <div className="blue_line"></div>
*/}
            {/*  <h1 className="h2_subtitle " style={{color: "#182844"}}>
                {bannerInfo?.BannerTitle}
              </h1>*/}
              <h1 className="our_label " style={{color: "#182844"}}>
               {/* {bannerInfo?.BannerTitle}*/}
                {bannerInfo?.title_1}
              </h1>
            </div>
        )}
        {bannerInfo?.BannerSubTitleFollow ? (
            <div>
              <h2
                  className={
                    bannerInfo?.BannerSubTitle === "We stand for"
                  ? "h2_subtitle_light_black"
                  : "h2_subtitle_black"
              }
            >
              {/*{bannerInfo?.BannerSubTitle}*/}
                {bannerInfo?.title_2}
            </h2>

            <h2
              className={
                bannerInfo?.BannerSubTitle === "We stand for"
                  ? "our_label_title_2"
                  : "h2_subtitle_light_black"
              }
            >
             {/* {bannerInfo?.BannerSubTitleFollow}{" "}*/}
              {bannerInfo?.title_2}
            </h2>
          </div>
        ) : (
          <h2 className="our_label_title_2">
            {/*{bannerInfo?.BannerSubTitle}*/}
            {bannerInfo?.title_2}
          </h2>
        )}
        <p
          className={
            bannerInfo?.shadowBlock
              ? "paragraphe"
              : "paragraphe paragraphe_margin"
          }
        >
          {/*{bannerInfo?.BannerDiscription}*/}
          {bannerInfo?.description	}
        </p>
      </div>
      {user && !isAdmin && bannerInfo?.BannerButtonText && (
        <Link
          to={
            type === "products"
              ? `/product/${
                  user && user.fabric_type === "knit" ? "knit" : "denim"
                }/${
                  user && men
                    ? men.name
                    : women
                    ? women.name
                    : kids
                    ? kids.name
                    : "Men"
                }/${
                  user && men ? men.id : women ? women.id : kids ? kids.id : 1
                }/${
                  user && user.fabric_type === "knit" ? "POLO SHIRTS" : "JEANS"
                }/${user && user.fabric_type === "knit" ? "13" : "1"}`
              : type === "for you"
              ? user && user.fabric_type === "denim"
                ? `/for-you/denim`
                : "/for-you/knit"
              : // ? "/for-you"
              type === "direct orders"
              ? "/direct-order"
              : "/creative-station"
          }
        >
          <Button
            // margin={bannerInfo?.buttonDistance}
            buttonClass="submit_button_white submit_button_white_margin"
            innerButton={bannerInfo?.BannerButtonText}
          />
        </Link>
      )}
    </div>
  );
}
