import { useQuery } from "react-query";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { getSingleCustomizedOrders } from "../../actions/my-orders/services";
import MyOrdersTable from "./MyOrdersTable";
import backArrow from "../../img/backArrow.png";
import { history } from "../..";

export default function CustomizedOrderDetails({
  orderStatus,
}: {
  orderStatus: string;
}) {
  const { path, url } = useRouteMatch();
  const params = useParams<{ id: string; status: string }>();

  const { data, isLoading, refetch } = useQuery(
    `demco_product_${params.id}`,
    () => getSingleCustomizedOrders(params.id)
  );

  const columns = ["Product Name", "Sent Date", "Progress"];

  return (
    <div className="my_orderss">
      {!isLoading && (
        <div className="myorder_details_header my-3">
          <img
            src={backArrow}
            className="back_arrow_folder"
            alt="back arrow"
            onClick={() => history.back()}
          />
          <h1 className="ml-2">
            {data?.data?.data?.[0]?.ref}{" "}
            <span style={{ fontWeight: 400 }}>
              ({data?.data?.data?.length}{" "}
              {data?.data?.data?.length
                ? data.data?.data?.length > 1
                  ? "Products"
                  : "Product"
                : data?.data?.data?.length > 1
                ? "Products"
                : "Product"}
              )
            </span>
          </h1>
        </div>
      )}
      <Switch>
        <Route path={`${path}`} exact>
          <MyOrdersTable
            columns={columns}
            isLoading={isLoading}
            orderType="customized_product_content"
            data={data?.data}
            url={url}
            orderStatus={orderStatus}
            refetch={refetch}
          />
        </Route>
        <Route>
          <h1>Page Not Found</h1>
        </Route>
      </Switch>
    </div>
  );
}
