import { Avatar } from "antd";
import moment from "moment";
import {Link} from "react-router-dom";

const ClientClientsInvitation = ({ inPage, notif, position }: any) => {
    return inPage ? (
        <Link
            to={`/teamcompany/${
                notif.data.id ? notif.data.id : "#"
            }`}

        >
        <div className="notif_item_page p-2 my-2">

            <div className="d-flex align-items-center">
                <div className="mx-2 d-flex align-items-center icon_notif_container">

                </div>
                <h2 className="h4_medium m-0">
                    {`Client requested to have a new client on the plateform `}
                </h2>
            </div>
            <p className="selection_date m-0">{moment(notif.created_at).fromNow()}</p>
        </div>
        </Link>

    ) : (

        <div
            className="notif_added_prod_container my-2 border p-4"
            style={{
                borderRadius: "8px",

                transition: "all 5s ease-in-out",
            }}
        >
            <Link
                to={`/teamcompany/${
                    notif.data.id ? notif.data.id : "#"
                }`}

            >
            <article className="notif_selection order_changes w-100">


                <div className="notif_details" style={{ flex: 1 }}>
                    <h2 className="h4_medium">
                        {`Client requested to have a new client on the plateform `}
                    </h2>
                    <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                        <p
                            className="selection_date mb-0"
                            style={{ fontSize: "0.7rem", fontWeight: 400 }}
                        >
                            {moment(notif.created_at).fromNow()}
                        </p>
                    </div>
                </div>

            </article>
            </Link>
        </div>

    );
};

export default ClientClientsInvitation;
