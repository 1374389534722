export const GET_ALL_GENDERS_REQUEST = "GET_ALL_GENDERS_REQUEST";
export const GET_ALL_GENDERS_SUCCESS = "GET_ALL_GENDERS_SUCCESS";
export const GET_ALL_GENDERS_FAIL = "GET_ALL_GENDERS_FAIL";

export const GET_ALL_COLLECTIONS_REQUEST = "GET_ALL_COLLECTIONS_REQUEST";
export const GET_ALL_COLLECTIONS_SUCCESS = "GET_ALL_COLLECTIONS_SUCCESS";
export const GET_ALL_COLLECTIONS_FAIL = "GET_ALL_COLLECTIONS_FAIL";

export const GET_GENDERS_UNDER_FABRIC_REQUEST =
  "GET_GENDERS_UNDER_FABRIC_REQUEST ";
export const GET_GENDERS_UNDER_FABRIC_SUCCESS =
  "GET_GENDERS_UNDER_FABRIC_SUCCESS ";
export const GET_GENDERS_UNDER_FABRIC_FAIL = "GET_GENDERS_UNDER_FABRIC_FAIL ";

export const GET_CAT_UNDER_GENDER_REQUEST = "GET_CAT_UNDER_GENDER_REQUEST ";
export const GET_CAT_UNDER_GENDER_SUCCESS = "GET_CAT_UNDER_GENDER_SUCCESS ";
export const GET_CAT_UNDER_GENDER_FAIL = "GET_CAT_UNDER_GENDER_FAIL ";

export const CLOSE_SIDE_MENU_EXTENSION_REQUEST =
  "CLOSE_SIDE_MENU_EXTENSION_REQUEST";
