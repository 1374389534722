import { useQuery } from "react-query";
import {
  getCancelledOptions,
  getMyDirectOrders,
  getCompletdOptions,
} from "../../actions/my-orders/services";
import MyOrdersTable from "./MyOrdersTable";
import React, { useState } from "react";
import {HrIcon} from "../../components/product-3d-config-tabs/forms/canvas/icon/HrIcon";
import {SearchOutlined} from "@ant-design/icons";

const columns = [
  "",
  "Reference",
  "Collection Name",
  "Order Date",
  "Delivery Date",
  "Number of article",
  "Status",
  "Description of status",
  "Team",

  /*   "Order Details",
   */
];
const completedColumns = [
  "",
  "Reference",
  "Collection Name",
  "Delivery Date",
  "Number of article",
  "Quantity",
  "Action",
  "Team",

  /*   "Order Details",
   */
];
const cancelledColumns = [
  /*  "Option Name",
  "Article Name",
  "Order Name",
  "Delete Date",
  "Actions", */
  "",
  "Reference",
  "Collection Name",
  "Delete Date",
  "Status",
  "Number of article",
  "Action",
  "Team",
];

export default function DirectOrders({
  orderType,
  url,
  orderStatus,
  selectedDate,
}: {
  orderType: string;
  url: string;
  orderStatus: string;
  selectedDate: any;
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [dateFilter, setDateFilter] = useState("");

  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ["my_direct_orders", orderStatus, selectedDate],
    /*     orderStatus === "cancelled"
      ? () => getCancelledOptions(selectedDate)
      : () => getMyDirectOrders(selectedDate), */
    orderStatus === "cancelled"
      ? () => getCancelledOptions(selectedDate)
      : orderStatus === "completed"
      ? () => getCompletdOptions(selectedDate)
      : () => getMyDirectOrders(selectedDate),
    {
      keepPreviousData: true,
    }
  );

  if (isError) {
    return <p>Error!</p>;
  }
  console.log(data?.data);

  const handleSearchInputChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  // Assuming your data is an array of orders with a property 'orderName' representing the name of the order.

  const filteredData = data?.data.filter((order: any) =>
    /*     order?.name?.toLowerCase().includes(searchQuery.toLowerCase())
     */
  
    {
      console.log(order)
      
      const nameMatch = order?.name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
       const refMatch = order?.ref
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()); 
        /* const dateMatch = dateFilter === "" || order.articles.some((article:any) => 
        article.article_options?.some(
          (option:any) => option.delivery_date === dateFilter
        )
      ); 

    /*    const dateMatch =
      dateFilter === "" ||
      new Date(order.delivery_date).toLocaleDateString() ===
        new Date(dateFilter).toLocaleDateString("en-GB");
        console.log( new Date(dateFilter).toLocaleDateString("en-GB")) 
        console.log(dateMatch);
 */

        // Date filtering logic
 /*    const dateMatch =
    dateFilter === "" || !order.delivery_date || new Date(order.delivery_date).toLocaleDateString("en-GB") === new Date(dateFilter).toLocaleDateString("en-GB");
          console.log(dateMatch)
  return nameMatch || dateMatch; */
      return refMatch || nameMatch ;
    }
  );

  const handleDateFilterChange = (event:any) => {
    setDateFilter(event.target.value);
  };

  return (
      <div className={"mt-3"}>
        {/* <input
        type="text"
        placeholder="Search your order"
        value={searchQuery}
        onChange={handleSearchInputChange}
        className="option_details_input"

      />*/}

        <div className="search-bar searchfaq" style={{width: "100%"}}>
          <div className="input-container">
            <input type="text" placeholder="Search your order" onChange={handleSearchInputChange}
                   className={"search_input"}/>
            <HrIcon></HrIcon>
            <button type="submit" className={"submit_input"}><SearchOutlined/></button>
          </div>
        </div>

        {/*  <input
          type="date"
          value={dateFilter}
          onChange={handleDateFilterChange}
          pattern="\d{2}/\d{2}/\d{4}"
        /> */}
        <MyOrdersTable
            columns={
              orderStatus === "cancelled"
                  ? cancelledColumns
                  : orderStatus === "completed"
                      ? completedColumns
                      : columns
            }
            isLoading={isLoading || isFetching}
            orderType={orderType}
            data={filteredData}
            orderStatus={orderStatus}
            url={url}
            refetch={refetch}
        />
      </div>
  );
}
