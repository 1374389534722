import { useEffect, useState } from "react";
import "./ToolBarFolders.scss";
import Trash from "../../icons/Trash";
import Download from "../../icons/Download";
import Bag from "../../icons/Bag";
import Folder from "../../icons/Folder";
import MooveTool from "../../icons/MooveTool";
import { motion } from "framer-motion";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { handleBottomAddToCollection } from "../../actions/actionCreators";
import {
  archiveFolder,
  clearSelectedFolders,
  handleMovingState,
} from "../../actions/folders-actions/actions";
import { Popconfirm, message } from "antd";

export interface IToolBarFoldersProps {}

export default function ToolBarFolders(props: IToolBarFoldersProps) {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const folders = useSelector((state: RootStateOrAny) => state.folders);
  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };
  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  return (
    <motion.div
      transition={{ type: "tween" }}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      className="tool_bar_container"
    >
      <div className="left_side">
        <p>
          <span>({folders.selectedFolders.length})</span>{" "}
          {width > 768 && "Folders selected"}
        </p>
      </div>
      <div className="right_side">
        {/* <div className="functionality_container">
         
          <p className="functionality_text">Unselect</p>
        </div> */}
        <div
          className="functionality_container"
          onClick={() => {
            dispatch(handleBottomAddToCollection());
            dispatch(handleMovingState());
          }}
        >
          <MooveTool />
          {width > 1024 && <p className="functionality_text">Move to</p>}
        </div>
        {/* <div className="functionality_container">
          <Download />
          {width > 1024 && <p className="functionality_text">Download</p>}
        </div> */}
        <div className="functionality_container">
          <Bag />
          {width > 1024 && <p className="functionality_text">Order</p>}
        </div>
        <Popconfirm
          placement="topRight"
          title="Are you sure to archive this folder?"
          onConfirm={() => {
            folders.selectedFolders.length > 0 &&
              folders.selectedFolders.map((el: any) =>
                dispatch(archiveFolder(el))
              );
            dispatch(clearSelectedFolders());
          }}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <div className="functionality_container">
            <Trash />
            {width > 1024 && <p className="functionality_text">Archive</p>}
          </div>
        </Popconfirm>
      </div>
    </motion.div>
  );
}
