import { Button, Input, Tooltip } from "antd";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Stars from "../../icons/Stars";
import { useSelector } from "react-redux";

const { TextArea } = Input;

const ProductHeader = ({
  oneProduct,
  setEditMode,
  editMode,
  handleChange,
}: any) => {
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="product_details_header mt-3"
      >
        {editMode ? (
          <div className="w-100">
            <Input
              disabled={!user.permissions.includes("product.update")}
              type="text"
              value={oneProduct.code}
              name="code"
              placeholder="Code"
              onChange={handleChange}
              style={{
                textTransform: "uppercase",
                color: "#00468c",
                fontWeight: 800,
                marginBottom: 25,
              }}
            />
            <Input
              disabled={!user.permissions.includes("product.update")}
              type="text"
              value={oneProduct.name}
              name="name"
              placeholder="Name"
              onChange={handleChange}
              style={{
                textTransform: "uppercase",
                color: "#00468c",
                fontWeight: 500,
              }}
            />
          </div>
        ) : (
          <div>
            <h2 className="product_details_title">
              {oneProduct && oneProduct.code}{" "}
              {oneProduct?.gender_id == 2 ? (
                <>- Women</>
              ) : oneProduct?.gender_id == 1 ? (
                <>- Men</>
              ) : oneProduct?.gender_id == 3 ? (
                <>- Unisex</>
              ) : oneProduct?.gender_id == 4 ? (
                <>- Kids</>
              ) : oneProduct?.gender_id == 5 ? (
                <>- Boys</>
              ) : oneProduct?.gender_id == 6 ? (
                <>- Girls</>
              ) : (
                ""
              )}
            </h2>
            <h2 className="product_details_title_name" style={{ fontWeight: 500 }}>
              {oneProduct && oneProduct.name}
            </h2>
          </div>
        )}
        {oneProduct &&
          !editMode &&
          oneProduct.labels.find(
            (el: any) => el.name.toUpperCase() === "LIMITED EDITION"
          ) && (
            <div
              style={{ color: "#DAA520", display: "flex" }}
              className="limited_edition"
            >
              <Stars /> <p>Limited edition</p>
            </div>
          )}
        {user &&
          isAdmin &&
          user.permissions.find(
            (el: string) =>
              el === "product.update" ||
              el === "product.fabric.management" ||
              el === "product.wash.management"
          ) &&
          !editMode && (
            <div>
              <Tooltip
                placement="bottom"
                title={editMode ? "Save changes" : "Update"}
              >
                <Button
                  type="ghost"
                  shape="circle"
                  size="large"
                  className="update_product_btn"
                  icon={<EditOutlined />}
                  onClick={() => setEditMode(true)}
                />
              </Tooltip>
            </div>
          )}
      </div>
      {editMode ? (
        <TextArea
          value={oneProduct.description}
          name="description"
          onChange={handleChange}
          rows={4}
          disabled={!user.permissions.includes("product.update")}
          className="product_description"
        />
      ) : (
        <p className="paragraphe product_description">
          {oneProduct && oneProduct.description}
        </p>
      )}
    </>
  );
};

export default ProductHeader;
