import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton, Popconfirm, Card, Avatar } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
  deletePost,
  loadMoreCreativeStation,
} from "../../actions/creative-station-actions/actions";
import { ImgItem } from "./ImgItem";
import VideoComponent from "./VideoComponent";
import { DeleteOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import avatar_placeholder from "../../img/pdf_placeholder.png";
import Meta from "antd/lib/card/Meta";
import { EyeOutlined } from "@ant-design/icons";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import { color } from "html2canvas/dist/types/css/types/color";
import {useEffect, useState} from "react";

interface Props {
  data: object[];
  title: string;
  isLoading: boolean;
}

export const MasonryBlock = ({ data, title, isLoading }: Props) => {
  const { next_page_newest, next_page_trending, next_page_moodboars } =
    useSelector((state: any) => state.posts);
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);
  const [showAllData, setShowAllData] = useState(false);
  const [elementsToDisplay, setElementsToDisplay] = useState<any>([]);
  const [visibleElements, setVisibleElements] = useState(3);

  const history = useHistory();
 useEffect(()=>{
    setElementsToDisplay (data?.slice(0,3))

  },[data])


     useEffect(() => {

         const resize: any = window.addEventListener("resize", () => {
             setWidth(window.innerWidth);



         });


         return () => {
             window.removeEventListener("resize", resize);
         };
     }, [width]);

  function goto(el:any) {
    history.push(`/single-post/${el.id}`);
  }

  function seeMore() {
   // setShowAllData(true)

      setElementsToDisplay(data)
    setVisibleElements(prevVisibleElements => prevVisibleElements + 3);


  }

  // Flag to determine whether to show all data or just the last three elements


    return (
    <div className="masnory_block" style={{marginBottom:'120px'}}>
      <header>
        <h1 className="h1_title" style={{ whiteSpace: "nowrap" }}>
          {title}
        </h1>
        <div className="blue_line"></div>
      </header>
      {width >768 ?
<>
      <main
        className={"post_list"}
      >
        {isLoading
          ? Array.from(
              {
                length: title === "TRENDING" || title === "MOODBOARDS" ? 3 : 4,
              },
              (_, index) => index + 1
            ).map((_, i) => (
              <div
                className={
                  title === "TRENDING" || title === "MOODBOARDS"
                    ? "col-6"
                    : "col-12"
                }
                key={i}
              >
                <div className="d-flex flex-column">
                  <Skeleton.Avatar active={true} shape={"square"} />
                  <Skeleton active={true} title={true} />
                </div>
              </div>
            ))
          : data &&
            data.map((el: any) =>
              el.type === "video" ? (
                < div  className={"post_card"}>

                  < div  className={"post_card"}>
                    <Card
                        onClick={()=>goto(el)}
                        hoverable
                        style={{ maxWidth: 400 }}

                        cover={
                          <div>

                           {/* {user &&
                                isAdmin &&
                                user.permissions.includes(
                                    "creative-station.delete"
                                ) && (
                                   <Popconfirm
                                        placement="top"
                                        title={
                                          "Are you sure you want to delete this post?"
                                        }
                                        onConfirm={(e: any) => {
                                          e.stopPropagation();
                                          dispatch(deletePost(el.id, el.category));
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                      <button
                                          className="delete_post_small_btn"
                                          style={{ right: 15, padding: "2px" }}
                                      >
                                        <DeleteOutlined
                                            style={{
                                              color: "white",
                                              verticalAlign: 0,
                                              fontSize: "20px",
                                            }}
                                        />
                                      </button>
                                    </Popconfirm>
                                )}*/}
                            <VideoComponent post={el} />
                          </div>
                        }
                    >

                      <Meta
                          title={
                              el.name && (
                                  <div className="masonry_item_description">
                                      <span>{el.name} <span className="text-muted">

                              {el.session}
                            </span></span>


                                  </div>
                              )
                          }
                      />

                    </Card>

                  </div>


                </div>
              ) : el.type === "article" ? (

                  < div  className={title === "TRENDING"?"post_card_trending":"post_card" }>
                      <Card
                          onClick={()=>goto(el)}
                          hoverable
                          style={{ maxWidth: 400 }}

                          cover={
                            <>

                              <div>
                              {/*  {user &&
                                    isAdmin &&
                                    user.permissions.includes(
                                        "creative-station.delete"
                                    ) && (
                                        <Popconfirm
                                            placement="top"
                                            title={
                                              "Are you sure you want to delete this post?"
                                            }
                                            onConfirm={(e: any) => {
                                              e.stopPropagation();
                                              dispatch(deletePost(el.id, el.category));
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                          <button
                                              className="delete_post_small_btn"
                                              style={{ right: 15, padding: "2px" }}
                                          >
                                            <DeleteOutlined
                                                style={{
                                                  color: "white",
                                                  verticalAlign: 0,
                                                  fontSize: "20px",
                                                }}
                                            />
                                          </button>
                                        </Popconfirm>
                                    )}*/}

                                {el.file.split(".")[1] == "pdf" ? (
                                    <img
                                        src={
                                          el.file
                                              ? process.env.REACT_APP_STORAGE_URL + el.cover
                                              : avatar_placeholder
                                        }
                                        className="imgCover"
                                    />
                                ) : (
                                    <img
                                        src={
                                          el.file
                                              ? process.env.REACT_APP_STORAGE_URL + el.file
                                              : placeholder_jeans
                                        }
                                        className={
                                          title === "TRENDING" ? "imgCover" : "imgCover"
                                        }
                                    />
                                )}
                              </div>
                            </>
                          }

                      >
                        <Meta
                            title={
                                el.name && (
                                    <div className="masonry_item_description">
                                      <span>{el.name} <span className="text-muted">

                              {el.session}
                            </span></span>


                                    </div>
                                )
                            }
                        />

                      </Card>

                </div>
              ) : (
                el.type === "image" && (

                      < div  className={"post_card"}>
                        <Card
                            onClick={()=>goto(el)}
                            hoverable
                            style={{ maxWidth: 400 }}

                            cover={
                              <div>

                         {/*       {user &&
                                    isAdmin &&
                                    user.permissions.includes(
                                        "creative-station.delete"
                                    ) && (
                                        <Popconfirm
                                            placement="top"
                                            title={
                                              "Are you sure you want to delete this post?"
                                            }
                                            onConfirm={(e: any) => {
                                              e.stopPropagation();
                                              dispatch(deletePost(el.id, el.category));
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                          <button
                                              className="delete_post_small_btn"
                                              style={{ right: 15, padding: "2px" }}
                                          >
                                            <DeleteOutlined
                                                style={{
                                                  color: "white",
                                                  verticalAlign: 0,
                                                  fontSize: "20px",
                                                }}
                                            />
                                          </button>
                                        </Popconfirm>
                                    )}*/}
                                <VideoComponent post={el} />
                              </div>
                            }
                        >

                          <Meta
                              title={
                                  el.name && (
                                      <div className="masonry_item_description">
                                      <span>{el.name} <span className="text-muted">

                              {el.session}
                            </span></span>


                                      </div>
                                  )
                              }
                          />

                        </Card>

                    </div>
                )
              )
            )}



      </main>

</>

          : <div key={"2"}>
            <main
                className={"post_list"}

            >
              {isLoading
                  ? Array.from(
                      {
                        length: title === "TRENDING" || title === "MOODBOARDS" ? 3 : 4,
                      },
                      (_, index) => index + 1
                  ).map((_, i) => (
                      <div
                          className={
                            title === "TRENDING" || title === "MOODBOARDS"
                                ? "col-6"
                                : "col-12"
                          }
                          key={i}
                      >
                        <div className="d-flex flex-column">
                          <Skeleton.Avatar active={true} shape={"square"}/>
                          <Skeleton active={true} title={true}/>
                        </div>
                      </div>
                  ))
                  : elementsToDisplay &&
                  elementsToDisplay.slice(0, visibleElements).map((el: any) =>
                      el.type === "video" ? (
                          < div className={"post_card"}>

                            < div className={"post_card"}>
                              <Card
                                  onClick={() => goto(el)}
                                  hoverable
                                  style={{maxWidth: 470}}

                                  cover={
                                    <div>

                                {/*      {user &&
                                          isAdmin &&
                                          user.permissions.includes(
                                              "creative-station.delete"
                                          ) && (
                                              <Popconfirm
                                                  placement="top"
                                                  title={
                                                    "Are you sure you want to delete this post?"
                                                  }
                                                  onConfirm={(e: any) => {
                                                    e.stopPropagation();
                                                    dispatch(deletePost(el.id, el.category));
                                                  }}
                                                  okText="Yes"
                                                  cancelText="No"
                                              >
                                                <button
                                                    className="delete_post_small_btn"
                                                    style={{right: 15, padding: "2px"}}
                                                >
                                                  <DeleteOutlined
                                                      style={{
                                                        color: "white",
                                                        verticalAlign: 0,
                                                        fontSize: "20px",
                                                      }}
                                                  />
                                                </button>
                                              </Popconfirm>
                                          )}*/}
                                      <VideoComponent post={el}/>
                                    </div>
                                  }
                              >

                                <Meta
                                    title={
                                        el.name && (
                                            <div className="masonry_item_description">
                                      <span>{el.name} <span className="text-muted">

                              {el.session}
                            </span></span>


                                            </div>
                                        )
                                    }
                                />

                              </Card>

                            </div>


                          </div>
                      ) : el.type === "article" ? (

                          < div className={"post_card"}>
                            <Card
                                onClick={() => goto(el)}
                                hoverable
                                className={title === "TRENDING" ?"card-post-trending":"card-post"}
                                cover={
                                  <>

                                    <div>
                                    {/*  {user &&
                                          isAdmin &&
                                          user.permissions.includes(
                                              "creative-station.delete"
                                          ) && (
                                              <Popconfirm
                                                  placement="top"
                                                  title={
                                                    "Are you sure you want to delete this post?"
                                                  }
                                                  onConfirm={(e: any) => {
                                                    e.stopPropagation();
                                                    dispatch(deletePost(el.id, el.category));
                                                  }}
                                                  okText="Yes"
                                                  cancelText="No"
                                              >
                                                <button
                                                    className="delete_post_small_btn"
                                                    style={{right: 15, padding: "2px"}}
                                                >
                                                  <DeleteOutlined
                                                      style={{
                                                        color: "white",
                                                        verticalAlign: 0,
                                                        fontSize: "20px",
                                                      }}
                                                  />
                                                </button>
                                              </Popconfirm>
                                          )}*/}

                                      {el.file.split(".")[1] == "pdf" ? (
                                          <img
                                              src={
                                                el.file
                                                    ? process.env.REACT_APP_STORAGE_URL + el.cover
                                                    : avatar_placeholder
                                              }
                                              className="imgCover"
                                          />
                                      ) : (
                                          <img
                                              src={
                                                el.file
                                                    ? process.env.REACT_APP_STORAGE_URL + el.file
                                                    : placeholder_jeans
                                              }
                                              className={
                                                "imgCover"
                                              }
                                          />
                                      )}
                                    </div>
                                  </>
                                }

                            >
                              <Meta
                                  title={
                                      el.name && (
                                          <div className="masonry_item_description">
                                      <span>{el.name} <span className="text-muted">

                              {el.session}
                            </span></span>


                                          </div>
                                      )
                                  }
                              />

                            </Card>

                          </div>
                      ) : (
                          el.type === "image" && (

                              < div className={"post_card"}>
                                <Card
                                    onClick={() => goto(el)}
                                    hoverable
                                    style={{maxWidth: 300}}

                                    cover={
                                      <div>

                                       {/* {user &&
                                            isAdmin &&
                                            user.permissions.includes(
                                                "creative-station.delete"
                                            ) && (
                                                <Popconfirm
                                                    placement="top"
                                                    title={
                                                      "Are you sure you want to delete this post?"
                                                    }
                                                    onConfirm={(e: any) => {
                                                      e.stopPropagation();
                                                      dispatch(deletePost(el.id, el.category));
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                  <button
                                                      className="delete_post_small_btn"
                                                      style={{right: 15, padding: "2px"}}
                                                  >
                                                    <DeleteOutlined
                                                        style={{
                                                          color: "white",
                                                          verticalAlign: 0,
                                                          fontSize: "20px",
                                                        }}
                                                    />
                                                  </button>
                                                </Popconfirm>
                                            )}*/}
                                        <VideoComponent post={el}/>
                                      </div>
                                    }
                                >

                                  <Meta
                                      title={
                                          el.name && (
                                              <div className="masonry_item_description">
                                      <span>{el.name} <span className="text-muted">

                              {el.session}
                            </span></span>


                                              </div>
                                          )
                                      }
                                  />

                                </Card>

                              </div>
                          )
                      )
                  )}
              {visibleElements < data?.length  && (
<div className={"text-center"}>
              <button
                  className="btn-show-more"
          onClick={()=>seeMore()}
              >
                Show more
              </button>
</div>)}
            </main>

          </div>
      }
      {title === "newest" && next_page_newest && (
          <button
              className="see_more_btn_masnoary"
              onClick={() => {
                dispatch(
                    loadMoreCreativeStation(
                        next_page_newest + "&draft=false",
                        "newest"
              )
            );
          }}
        >
          See More <FontAwesomeIcon icon={faArrowDown} className="ml-2" />
        </button>
      )}
      {title === "moodboars" && next_page_moodboars && (
        <button
          className="see_more_btn_masnoary"
          onClick={() => {
            dispatch(loadMoreCreativeStation(next_page_moodboars, "moodboars"));
          }}
        >
          See More <FontAwesomeIcon icon={faArrowDown} className="ml-2" />
        </button>
      )}
      {title === "trending" && next_page_trending && (
        <button
          className="see_more_btn_masnoary"
          onClick={() => {
            dispatch(loadMoreCreativeStation(next_page_trending, "trending"));
          }}
        >
          See More <FontAwesomeIcon icon={faArrowDown} className="ml-2" />
        </button>
      )}
    </div>
  );
};
