import {
  Button,
  Col,
  Collapse,
  Divider,
  Drawer,
  Input,
  InputNumber,
  Pagination,
  Popconfirm,
  Result,
  Row,
  Select,
  Spin,
  Tabs,
  Tag,
  Switch,
  DatePicker,
} from "antd";
import { useHistory, useParams } from "react-router-dom";

import axiosInstance from "../../config/axios-instance";
import CustomizedOrdersCompany from "./CustomizedOrdersCompany";
import DirectOrdersCompany from "./DirectOrdersCompany";
import NormalOrdersCompany from "./NormalOrdersCompany";
import { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";
import moment from "moment";
import placeholder from "../../img/img_placeholder.svg";

import {
  PlusOutlined,
  WarningOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { faCheckCircle, faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { Controller, useForm } from "react-hook-form";
import { MyNotif } from "../../components/NotifIcons/NotifIcons";
import avatar from "../../img/avatar_placeholder.jpg";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import TextArea from "antd/lib/input/TextArea";
const { TabPane } = Tabs;

interface Wash {
  wash_id: string;
  colors: string[];
  eim_score: number;
  wash_price: number;
  description: string;
}

const AddNewProduct = () => {
  const params = useParams<any>();

  const [width, setWidth] = useState(window.innerWidth);

  const [product, setProducts] = useState<Array<any>>([]);
  const [collection, setCollections] = useState<Array<any>>([]);

  const [fabric, setFabrics] = useState<Array<any>>([]);
  const [tag, setTags] = useState<Array<any>>([]);
  const [wash, setWashes] = useState<Array<any>>([]);
  const [color, setColors] = useState<Array<any>>([]);
  const [technic, setTechnics] = useState<Array<any>>([]);
  const [accesory, setAccesory] = useState<Array<any>>([]);

  const [Label, setLabels] = useState<Array<any>>([]);
  const [company, setCompanies] = useState<Array<any>>([]);
  const [responsibleChoice, setResponsibleChoice] = useState<Array<any>>([]);

  const [activePanels, setActivePanels] = useState(["1"]); // Initialize with the first panel open
  const [activePanelsTechnic, setActivePanelsTechnic] = useState(["1"]); // Initialize with the first panel open
  const [activePanelsAccessory, setActivePanelsAccessory] = useState(["1"]); // Initialize with the first panel open

  const [panelCount, setPanelCount] = useState(1); // Initialize with one panel

  const [nameRequired, setNameRequired] = useState("");
  const [typeRequired, setTypeRequired] = useState("");
  const [codeRequired, setCodeRequired] = useState("");

  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [type, setType] = useState<any>("denim");
  const [link_360_front, setLink360] = useState("");
  const [previewFront, setPreviewFront] = useState("");
  const [previewBack, setPreviewBack] = useState("");
  const [preview, setPreview] = useState<Array<any>>([]);

  const [washBlocks, setWashBlocks] = useState<any>([]);
  const [washOption, setWashOption] = useState("");
  const [colorOption, setColorOption] = useState("");

  const [priceValue, setPriceValue] = useState("");
  const [scoreValue, setScoreValue] = useState("");

  const [accessoryBlocks, setAccessoryBlocks] = useState<any>([]);
  const [accessoryOption, setAccessoryOption] = useState("");

  const [accessoryPrice, setAccessoryValue] = useState("");
  const [unitValue, setUnitValue] = useState("");

  const [technicBlocks, setTechniquesBlocks] = useState<any>([]);
  const [technicOption, setTechnicOption] = useState("");

  const [technicPrice, setTechnicValue] = useState("");
  const routerHistory = useHistory();

  const addNewTechniques = () => {
    const newTechnicBlock = {
      id: technicBlocks.length,

      technic_id: technicOption,

      technic_price: technicPrice,
    };

    setTechniquesBlocks([...technicBlocks, newTechnicBlock]);

    setTechnicOption("");

    setTechnicValue("");
  };

  const handleOptionTechnicChange = (blockId: any, value: any) => {
    const updatedBlocks = technicBlocks.map((block: any) => {
      if (block.id === blockId) {
        return { ...block, technic_id: value };
      }
      return block;
    });
    setTechniquesBlocks(updatedBlocks);
  };

  const handleInputPriceTechnic = (blockId: any, value: any) => {
    const updatedBlocks = technicBlocks.map((block: any) => {
      if (block.id === blockId) {
        return { ...block, technic_price: value };
      }
      return block;
    });
    setTechniquesBlocks(updatedBlocks);
  };

  const addNewAccessories = () => {
    const newAccessoryBlock = {
      id: accessoryBlocks.length,

      accesory_id: accessoryOption,

      accessory_price: accessoryPrice,
      unit: unitValue,
    };

    setAccessoryBlocks([...accessoryBlocks, newAccessoryBlock]);

    setAccessoryOption("");

    setAccessoryValue("");
    setUnitValue("");
  };

  const handleOptionAccesoryChange = (blockId: any, value: any) => {
    const updatedBlocks = accessoryBlocks.map((block: any) => {
      if (block.id === blockId) {
        return { ...block, accesory_id: value };
      }
      return block;
    });
    setAccessoryBlocks(updatedBlocks);
  };

  const handleInputPriceAccesory = (blockId: any, value: any) => {
    const updatedBlocks = accessoryBlocks.map((block: any) => {
      if (block.id === blockId) {
        return { ...block, accessory_price: value };
      }
      return block;
    });
    setAccessoryBlocks(updatedBlocks);
  };

  const handleInputUnitAccesory = (blockId: any, value: any) => {
    const updatedBlocks = accessoryBlocks.map((block: any) => {
      if (block.id === blockId) {
        return { ...block, unit: value };
      }
      return block;
    });
    setAccessoryBlocks(updatedBlocks);
  };

  const addNewWashs = () => {
    const newWashBlock = {
      id: washBlocks.length,

      wash_id: washOption,
      colors: colorOption,

      wash_price: priceValue,
      eim_score: scoreValue,
    };

    setWashBlocks([...washBlocks, newWashBlock]);

    setWashOption("");
    setColorOption("");

    setPriceValue("");
    setScoreValue("");
  };
  const handleOptionWashChange = (blockId: any, value: any) => {
    const updatedBlocks = washBlocks.map((block: any) => {
      if (block.id === blockId) {
        return { ...block, wash_id: value };
      }
      return block;
    });
    setWashBlocks(updatedBlocks);
  };

  const handleOptionColorChange = (blockId: any, value: any) => {
    const updatedBlocks = washBlocks.map((block: any) => {
      if (block.id === blockId) {
        return { ...block, colors: value };
      }
      return block;
    });
    setWashBlocks(updatedBlocks);
  };
  const handleInputPriceWash = (blockId: any, value: any) => {
    const updatedBlocks = washBlocks.map((block: any) => {
      if (block.id === blockId) {
        return { ...block, wash_price: value };
      }
      return block;
    });
    setWashBlocks(updatedBlocks);
  };

  const handleInputEimScore = (blockId: any, value: any) => {
    const updatedBlocks = washBlocks.map((block: any) => {
      if (block.id === blockId) {
        return { ...block, eim_score: value };
      }
      return block;
    });
    setWashBlocks(updatedBlocks);
  };
  const [data, setData] = useState<any>({
    washes: [],
    tags: [],
    technic: [],
    accessory: [],
    code: "",
    name: "",
    front_image: [],
    back_image: [],
    images: [],
    files: [],
    link_360: "",
    fabric_type: "",
    total_price: "",
    total_bci_price: "",
    total_organic_cotton: "",
    masked: "",
    sustainable: "",
    gender: "",
    collection_select: [],
    fabrics: [],
    foryou: [],
    responsible_choice: "",
    is_3d: "",
    description: "",
    total_look: [],
  });

  const [visible, setVisible] = useState(false);

  const [panelData, setPanelData] = useState<any>([]);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const fetchProducts = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/getallproducts`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setProducts(res.data);
    });
  };

  const fetchCollections = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/collections`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setCollections(res.data);
    });
  };

  const fetchFabrics = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/fabrics`,
      data: {
        fabric_type: type,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setFabrics(res.data.data);
    });
  };
  const fetchTags = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/tags`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setTags(res.data);
    });
  };

  const fetchWahes = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/washes`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setWashes(res.data);
    });
  };

  const fetchColors = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/colors`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setColors(res.data);
    });
  };

  const fetchTechnics = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/technics`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setTechnics(res.data);
    });
  };

  const fetchAccecories = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/accessories`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setAccesory(res.data);
    });
  };

  const fetchLabels = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/labels`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setLabels(res.data);
    });
  };

  const fetchCompanies = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/admin/companies`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setCompanies(res.data.data);
    });
  };

  const fetchResponsible = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/responsible_choice`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setResponsibleChoice(res.data);
    });
  };
  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  const { register, control, handleSubmit, errors, setValue, getValues } =
    useForm<any>();
  const { isLoading: loadingCollections, data: collections } = useQuery<any>(
    "collections",
    () => fetchCollections(),
    { keepPreviousData: true }
  );

  const { isLoading: loadingFabric, data: fabrics } = useQuery<any>(
    "fabric",
    () => fetchFabrics(),
    { keepPreviousData: true }
  );

  const { isLoading: loadingTag, data: tags } = useQuery<any>(
    "tags",
    () => fetchTags(),
    { keepPreviousData: true }
  );

  const { isLoading: loadingWashes, data: washes } = useQuery<any>(
    "washes",
    () => fetchWahes(),
    { keepPreviousData: true }
  );

  const { isLoading: loadingColors, data: colors } = useQuery<any>(
    "colors",
    () => fetchColors(),
    { keepPreviousData: true }
  );

  const { isLoading: loadingtechnic, data: technics } = useQuery<any>(
    "technics",
    () => fetchTechnics(),
    { keepPreviousData: true }
  );

  const { isLoading: loadingAccesory, data: accesories } = useQuery<any>(
    "accesory",
    () => fetchAccecories(),
    { keepPreviousData: true }
  );

  const { isLoading: loadingLabel, data: labels } = useQuery<any>(
    "labels",
    () => fetchLabels(),
    { keepPreviousData: true }
  );
  const { isLoading: loadingCompanies, data: companies } = useQuery<any>(
    "companies",
    () => fetchCompanies(),
    { keepPreviousData: true }
  );

  const { isLoading: loading, data: products } = useQuery<any>(
    "products",
    () => fetchProducts(),
    { keepPreviousData: true }
  );

  const { isLoading: loadingResponsible, data: responsible } = useQuery<any>(
    "responsible",
    () => fetchResponsible(),
    { keepPreviousData: true }
  );
  const handleInputChange = (e: any) => {
    setLink360(e.target.value);
  };

  const addNewWash = () => {
    const newPanelKey = `${panelCount}`;

    const newPanelData = {
      wash_id: newPanelKey,
      colors: [],
      eim_score: 0,
      wash_price: 0,
      description: "",
    };

    setPanelData((prevData: any) => [...prevData, newPanelData]);
    setActivePanels((prevPanels) => [...prevPanels, newPanelKey]);
    setPanelCount(panelCount + 1);
  };

  const onChange = (date: any, dateString: any) => {
    console.log(date, dateString);
  };

  const addNewTechnic = () => {
    const newPanelKey = `${panelCount}`;

    const newPanelData = {
      technic_id: newPanelKey,
      price: 0,
    };

    setData((prevData: any) => ({
      ...prevData,
      technic: [...prevData.technic, newPanelData],
    }));
    setActivePanelsTechnic((prevPanels) => [...prevPanels, newPanelKey]);
    setPanelCount(panelCount + 1);
  };

  const addNewAccessory = () => {
    const newPanelKey = `${panelCount}`;

    const newPanelData = {
      accessory_id: newPanelKey,
      price: 0,
    };

    setData((prevData: any) => ({
      ...prevData,
      accessory: [...prevData.accessory, newPanelData],
    }));
    setActivePanelsAccessory((prevPanels) => [...prevPanels, newPanelKey]);
    setPanelCount(panelCount + 1);
  };

  const genExtra = (i: number) => {
    return (
      <Popconfirm
        placement="right"
        title={"Are you sure ?"}
        onConfirm={() => {
          /*     setData({
            ...data,
            washes: data.washes.filter((el: any, idx: number) => idx !== i),
          }); */
          handleDeletePanelWash(i);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="default"
          className="delete_wash_update"
          onClick={(event: any) => event.stopPropagation()}
        >
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    );
  };
  const genExtraTechnic = (i: number) => {
    return (
      <Popconfirm
        placement="right"
        title={"Are you sure ?"}
        onConfirm={() => {
          /*     setData({
            ...data,
            washes: data.washes.filter((el: any, idx: number) => idx !== i),
          }); */
          handleDeletePanelTechnic(i);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="default"
          className="delete_wash_update"
          onClick={(event: any) => event.stopPropagation()}
        >
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    );
  };
  const genExtraAccessory = (i: number) => {
    return (
      <Popconfirm
        placement="right"
        title={"Are you sure ?"}
        onConfirm={() => {
          /*     setData({
            ...data,
            washes: data.washes.filter((el: any, idx: number) => idx !== i),
          }); */
          handleDeletePanelAccessory(i);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="default"
          className="delete_wash_update"
          onClick={(event: any) => event.stopPropagation()}
        >
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    );
  };

  const handleDeletePanelWash = (panelKey: any) => {
    const updatedPanels = activePanels.filter((key) => key !== panelKey);
    setActivePanels(updatedPanels);
    setData((prevData: any) => ({
      ...prevData,
      washes: prevData.washes.filter((wash: any) => wash.wash_id !== panelKey),
    }));
  };

  const handleDeletePanelTechnic = (panelKey: any) => {
    const updatedPanels = activePanels.filter((key) => key !== panelKey);
    setActivePanelsTechnic(updatedPanels);
    setData((prevData: any) => ({
      ...prevData,
      washes: prevData.technic.filter(
        (technic: any) => technic.technic_id !== panelKey
      ),
    }));
  };

  const handleDeletePanelAccessory = (panelKey: any) => {
    const updatedPanels = activePanels.filter((key) => key !== panelKey);
    setActivePanelsAccessory(updatedPanels);
    setData((prevData: any) => ({
      ...prevData,
      washes: prevData.accessory.filter(
        (accessory: any) => accessory.wash_id !== panelKey
      ),
    }));
  };

  const handleWashChange = (index: number, field: string, value: any) => {
    setData((prevData: any) => {
      const updatedWashes = [...prevData.washes];
      updatedWashes[index][field] = value;
      return { ...prevData, washes: updatedWashes };
    });
  };
  const handleRemoveBlock = (blockId: any) => {
    const updatedBlocks = washBlocks.filter(
      (block: any) => block.id !== blockId
    );
    setWashBlocks(updatedBlocks);
  };

  const handleRemoveBlockAccessory = (blockId: any) => {
    const updatedBlocksAccessory = accessoryBlocks.filter(
      (block: any) => block.id !== blockId
    );
    setAccessoryBlocks(updatedBlocksAccessory);
  };

  const handleRemoveBlockTechnic = (blockId: any) => {
    const updatedBlocks = technicBlocks.filter(
      (block: any) => block.id !== blockId
    );
    setTechniquesBlocks(updatedBlocks);
  };
  const addNewProduct = async () => {
    let token = localStorage.getItem("token");

    let formData = new FormData();

    const collectedData = data.washes;
    // Iterate through the collected data and display the values
    collectedData.forEach((washData: any, index: any) => {});
    const code = data.code;
    const name = data.name;
    // const washes = data.washes;
    const link_360 = data.link_360;
    const front_image = data.front_image[0]; // This should be a File object
    const back_image = data.back_image[0]; // This should be a File object
    const images = data.images; // This should be a File object

    const fabric_type = data.fabric_type;
    const collection_select = data.collection_select;

    const fabrics = data.fabrics;
    const responsible_choice = data.responsible_choice;
    const foryou = data.foryou;
    const tags = data.tags;
    const sustainable = data.sustainable;
    const masked = data.masked;
    const is_3d = data.is_3d;
    const total_price = data.total_price;
    const total_bci_price = data.total_bci_price;
    const total_organic_cotton = data.total_organic_cotton;
    const gender = data.gender;
    const description = data.description;
    const total_look = data.total_look;
    const newProduct = {
      code: code,
      name: name,
      washes: washes,
      front_image: front_image,
      back_image: back_image,
      link_360: link_360,
      fabric_type: fabric_type,
      collection_select: collection_select,
      fabrics: fabrics,
      responsible_choice: responsible_choice,
      foryou: foryou,
      tags: tags,
      sustainable: sustainable,
      masked: masked,
      is_3d: is_3d,
      total_organic_cotton: total_organic_cotton,
      total_bci_price: total_bci_price,
      total_price: total_price,
      gender: gender,
      // images: images,
      description: description,
      total_look: total_look,
    };
    /*  let imagesArray = [];
    for (let i = 0; i < images.length; i++) {
      formData.append("images" + i, images[i]);
      imagesArray.push(images[i]);
    } */

    const imagesArray = Array.isArray(data.images)
      ? data.images
      : [data.images];

    imagesArray.forEach((file: any, index: any) => {
      formData.append(`images[${index}]`, file);
    });
    Object.keys(newProduct).map((el: any) => formData.append(el, data[el]));
    formData.append("washes", JSON.stringify(washBlocks));
    formData.append("accerssories", JSON.stringify(accessoryBlocks));
    formData.append("techniques", JSON.stringify(technicBlocks));

    setIsLoadingAdd(true);

    await axiosInstance({
      method: "post",
      url: "/admin/addproduct",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        MyNotif("product added successfully.", "success");
        setIsLoadingAdd(false);

        setTimeout(() => {
          routerHistory.push(`/list-products`);
        }, 2000);

        return response.data;
      })
      .catch((error) => {
        setTypeRequired(error.response.data.errors.fabric_type);
        setNameRequired(error.response.data.errors.name);

        setCodeRequired(error.response.data.errors.code);
        setIsLoadingAdd(false);
      });
  };

  const handleFileChange = (e: any) => {
    const selectedFiles = e.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      // Convert FileList to array
      const filesArray = Array.from(selectedFiles);

      // Define the custom order of file names without extensions
      const customOrderWithoutExtensions = ["1", "4", "2", "3"];

      // Create an array of objects with files and their corresponding order
      const filesWithOrder = filesArray.map((file: any, index) => ({
        file,
        order: customOrderWithoutExtensions.indexOf(
          file.name.replace(/\.[^/.]+$/, "")
        ),
      }));

      // Sort files based on the custom order
      const sortedFiles = filesWithOrder.sort((a, b) => a.order - b.order);

      // Update data state with the sorted files
      setData({
        ...data,
        images: sortedFiles.map((fileWithOrder) => fileWithOrder.file),
      });

      // Update preview state
      const previewArray = sortedFiles.map((fileWithOrder) =>
        URL.createObjectURL(fileWithOrder.file)
      );
      setPreview(previewArray);
    }
  };

  const handleRemovePreview = (index: any) => {
    const updatedPreviews = [...preview];
    updatedPreviews.splice(index, 1); // Remove the preview at the specified index
    setPreview(updatedPreviews);

    // Additionally, you might want to clear the corresponding file from the data state as well
    const updatedFiles = [...data.files];
    updatedFiles.splice(index, 1);
    setData({ ...data, files: updatedFiles });
  };

  return (
    <>
      <>
        <div className="product_details_block container-fluid">
          <div className="product_details_contanier row">
            <div className="product_images_wrapper col-6 ">
              {link_360_front ? (
                <>
                  <iframe
                    src={link_360_front}
                    style={{
                      width: "100%",
                      height: width <= 1024 ? "calc(100vh - 227px)" : "100%",
                      border: "none",
                    }}
                  />
                </>
              ) : (
                <img
                  src={placeholder}
                  className="product_img"
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </div>
            <div className="product_details_infos_scroll col-6">
              <div></div>
              <div
                className="product_details_infos"
                style={{ paddingTop: "20px" }}
              >
                <div className="mt-4">
                  <label className={"lable_add_new_product"}> Fabric Type</label>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select fabric type"
                    onChange={(value) => {
                      setType(value);
                      setData({ ...data, fabric_type: value });
                    }}
                    className={typeRequired ? "errorInput select_add_product " : "select_add_product"}
                  >
                    <option value="denim">Denim</option>

                    <option value="knit">Knit</option>
                  </Select>

                  {typeRequired && (
                    <span style={{ color: "red" }}>{typeRequired}</span>
                  )}
                </div>

                <Row gutter={[16, 8]} className="mt-4">
                  <Col className="gutter-row" span={12}>
                    <div>
                      <label  className={"lable_add_new_product"}> ID Code</label>

                      <Input
                        type="text"
                        onChange={(e) =>
                          setData({ ...data, code: e.target.value })
                        }
                        className={codeRequired ? "errorInput lable_add_new_product" : "lable_add_new_product"}
                      />
                      {codeRequired && (
                        <span style={{ color: "red" }}>{codeRequired}</span>
                      )}
                    </div>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <div>
                      <label className={"lable_add_new_product"}> Style Name</label>

                      <Input
                        type="text"
                        onChange={(e) =>
                          setData({ ...data, name: e.target.value })
                        }
                        className={nameRequired ? "errorInput lable_add_new_product" : "lable_add_new_product"}
                      />
                      {nameRequired && (
                        <span style={{ color: "red" }}>{nameRequired}</span>
                      )}
                    </div>
                  </Col>
                </Row>

                <div className="mt-4">
                  <label className={"lable_add_new_product"}>General Description</label>

                  <TextArea
                    onChange={(e) => {
                      setData({ ...data, description: e.target.value });
                    }}
                    className={"lable_add_new_product"}
                  ></TextArea>
                </div>
                <div className="mt-4">
                  <label className={"lable_add_new_product"}>Fabric</label>

                  <Select
                    mode="multiple"
                    showArrow
                    placeholder="Select Fabric"
                    className={"lable_add_new_product"}
                    tagRender={({ label, onClose, closable }: any) => (
                      <Tag
                        color="#00468c"
                        closable={closable}
                        onClose={onClose}
                        style={{ marginRight: 3 }}
                        key={label}
                      >
                        {label}
                      </Tag>
                    )}
                    style={{ width: "100%" }}
                    onChange={(x: any, value: any) => {
                      setData({
                        ...data,
                        fabrics: value.map((x: any) => x.id),
                      });
                    }}
                    options={
                      fabric &&
                      fabric.map((fab: any) => {
                        return {
                          ...fab,
                          value: fab.name,
                          key: fab.id,
                        };
                      })
                    }
                  />
                </div>
                <Divider orientation="left" plain>
                  <h3 className="rubric_title title_add_new_product">Techniques</h3>
                </Divider>
                <div className="mt-2">
                  <div>
                    {technicBlocks.length == 0 && (
                      <h6 className="text-center p-2 mt-2 text-muted product_doesnt_have">
                        This product doesn't have technic
                      </h6>
                    )}
                    {technicBlocks.map((block: any, index: number) => (
                      <>
                        <Divider orientation="left" plain>
                          Technic Num {index + 1}{" "}
                          <Popconfirm
                            placement="top"
                            title={"Are you sure ?"}
                            onConfirm={() => {
                              handleRemoveBlockTechnic(block.id);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              type="default"
                              className="delete_wash_update"
                              onClick={(event: any) => event.stopPropagation()}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        </Divider>
                        <table
                          style={{
                            width: "100%",
                            borderRight: "none",
                            borderLeft: "none",
                          }}
                        >
                          <tbody>
                            <tr>
                              <th className={"lable_add_new_product"}>Technic</th>
                              <td>
                                <Select
                                  showArrow
                                  tagRender={({
                                    label,
                                    onClose,
                                    closable,
                                  }: any) => (
                                    <Tag
                                      color="#00468c"
                                      closable={closable}
                                      onClose={onClose}
                                      style={{ marginRight: 3 }}
                                    >
                                      {label}
                                    </Tag>
                                  )}
                                  options={
                                    technic &&
                                    technic.map((tag: any) => {
                                      return {
                                        label: tag.name,
                                        value: tag.id,
                                        key: tag.id,
                                      };
                                    })
                                  }
                                  onChange={(value) =>
                                    handleOptionTechnicChange(block.id, value)
                                  }
                                  style={{ width: "100%" }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th className={"lable_add_new_product"}> Price</th>
                              <td>
                                <InputNumber
                                  style={{ width: "100%" }}
                                  min={0}
                                  placeholder="Accessory Price"
                                  onChange={(value) =>
                                    handleInputPriceTechnic(block.id, value)
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    ))}

                    <div className="mt-2">
                      <Button
                        type="primary"
                        icon={<PlusOutlined style={{ verticalAlign: 0 }} />}
                        className="add_new_one"
                        onClick={addNewTechniques}
                      >
                        Add Another Technic
                      </Button>
                    </div>
                  </div>
                </div>
                <Divider orientation="left" plain>
                  <h3 className="rubric_title title_add_new_product">Accessories</h3>
                </Divider>

                <div>
                  {accessoryBlocks.length == 0 && (
                    <h6 className="text-center p-2 mt-2 text-muted product_doesnt_have">
                      This product doesn't have accessory
                    </h6>
                  )}
                  {accessoryBlocks.map((block: any, index: number) => (
                    <>
                      <Divider orientation="left" plain>
                        Accessory Num {index + 1}{" "}
                        <Popconfirm
                          placement="top"
                          title={"Are you sure ?"}
                          onConfirm={() => {
                            handleRemoveBlockAccessory(block.id);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="default"
                            className="delete_wash_update"
                            onClick={(event: any) => event.stopPropagation()}
                          >
                            <DeleteOutlined />
                          </Button>
                        </Popconfirm>
                      </Divider>
                      <table
                        style={{
                          width: "100%",
                          borderRight: "none",
                          borderLeft: "none",
                        }}
                      >
                        <tbody>
                          <tr>
                            <th className={"lable_add_new_product"}>Accessory</th>
                            <td>
                              <Select
                                showArrow
                                tagRender={({
                                  label,
                                  onClose,
                                  closable,
                                }: any) => (
                                  <Tag
                                    color="#00468c"
                                    closable={closable}
                                    onClose={onClose}
                                    style={{ marginRight: 3 }}
                                  >
                                    {label}
                                  </Tag>
                                )}
                                options={
                                  accesory &&
                                  accesory.map((tag: any) => {
                                    return {
                                      label: tag.name,
                                      value: tag.id,
                                      key: tag.id,
                                    };
                                  })
                                }
                                onChange={(value) =>
                                  handleOptionAccesoryChange(block.id, value)
                                }
                                style={{ width: "100%" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th className={"lable_add_new_product"}> Price</th>
                            <td>
                              <InputNumber
                                style={{ width: "100%" }}
                                min={0}
                                placeholder="Accessory Price"
                                onChange={(value) =>
                                  handleInputPriceAccesory(block.id, value)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th className={"lable_add_new_product"}>Unit</th>
                            <td>
                              <Select
                                showArrow
                                tagRender={({
                                  label,
                                  onClose,
                                  closable,
                                }: any) => (
                                  <Tag
                                    color="#00468c"
                                    closable={closable}
                                    onClose={onClose}
                                    style={{ marginRight: 3 }}
                                  >
                                    {label}
                                  </Tag>
                                )}
                                style={{ width: "100%" }}
                                onChange={(value) =>
                                  handleInputUnitAccesory(block.id, value)
                                }
                              >
                                <option value="meter">per meter</option>

                                <option value="piece">per piece</option>
                              </Select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ))}

                  <div className="mt-2">
                    <Button
                      type="primary"
                      icon={<PlusOutlined style={{ verticalAlign: 0 }} />}
                      className="add_new_one"
                      onClick={addNewAccessories}
                    >
                      Add Another Accessory
                    </Button>
                  </div>
                </div>
                <Divider orientation="left" plain>
                  <h3 className="rubric_title title_add_new_product">Washes</h3>
                </Divider>

                <div>
                  {washBlocks.length == 0 && (
                    <h6 className="text-center p-2 mt-2 text-muted product_doesnt_have">
                      This product doesn't have wash
                    </h6>
                  )}
                  {washBlocks.map((block: any, index: number) => (
                    <>
                      <Divider orientation="left" plain>
                        Washe Num {index + 1}{" "}
                        <Popconfirm
                          placement="top"
                          title={"Are you sure ?"}
                          onConfirm={() => {
                            handleRemoveBlock(block.id);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="default"
                            className="delete_wash_update"
                            onClick={(event: any) => event.stopPropagation()}
                          >
                            <DeleteOutlined />
                          </Button>
                        </Popconfirm>
                      </Divider>
                      <table
                        style={{
                          width: "100%",
                          borderRight: "none",
                          borderLeft: "none",
                        }}
                        key={block.id}
                      >
                        <tbody>
                          <tr>
                            <th className={"lable_add_new_product"}>Colour</th>
                            <td>
                              <Select
                                mode="multiple"
                                showArrow
                                tagRender={({
                                  label,
                                  onClose,
                                  closable,
                                }: any) => (
                                  <Tag
                                    color="#00468c"
                                    closable={closable}
                                    onClose={onClose}
                                    style={{ marginRight: 3 }}
                                  >
                                    {label}
                                  </Tag>
                                )}
                                style={{ width: "100%" }}
                                options={
                                  color &&
                                  color
                                    .filter((el: any) => el.fabric_type == type)
                                    .map((tag: any) => {
                                      return {
                                        label: tag.name,
                                        value: tag.id,
                                        key: tag.id,
                                      };
                                    })
                                }
                                onChange={(value) =>
                                  handleOptionColorChange(block.id, value)
                                }
                              ></Select>
                            </td>
                          </tr>
                          <tr>
                            <th className={"lable_add_new_product"}> Wash Type</th>
                            <td>
                              <Select
                                showArrow
                                placeholder="Select Washes"
                                tagRender={({
                                  label,
                                  onClose,
                                  closable,
                                }: any) => (
                                  <Tag
                                    color="#00468c"
                                    closable={closable}
                                    onClose={onClose}
                                    style={{ marginRight: 3 }}
                                    key={label}
                                  >
                                    {label}
                                  </Tag>
                                )}
                                style={{ width: "100%" }}
                                options={
                                  wash &&
                                  wash.map((tag: any) => {
                                    return {
                                      label: tag.name,
                                      value: tag.id,
                                      key: tag.id,
                                    };
                                  })
                                }
                                onChange={(value) =>
                                  handleOptionWashChange(block.id, value)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th className={"lable_add_new_product"}>Eim Score</th>
                            <td>
                              <InputNumber
                                style={{ width: "100%" }}
                                type="number"
                                min={0}
                                name="eim_score"
                                placeholder="Eim Score"
                                onChange={(value) =>
                                  handleInputEimScore(block.id, value)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th className={
                              "lable_add_new_product"
                            }>Wash Price</th>
                            <td>
                              <InputNumber
                                style={{ width: "100%" }}
                                type="number"
                                min={0}
                                name="wash_price"
                                placeholder="Wash Price"
                                onChange={(value) =>
                                  handleInputPriceWash(block.id, value)
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ))}

                  <div className="mt-2">
                    <Button
                      type="primary"
                      icon={<PlusOutlined style={{ verticalAlign: 0 }} />}
                      className="add_new_one"
                      onClick={addNewWashs}
                    >
                      Add Another Wash
                    </Button>
                  </div>
                </div>
                <div className="mt-4">
                  <label className={"lable_add_new_product"}> Total Price</label>

                  <Input
                    type="number"
                    min={0}
                    name="total_price"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setData({ ...data, total_price: e.target.value });
                    }}
                    className={"lable_add_new_product"}
                  />
                </div>
                {type == "knit" && (
                  <>
                    <div className="mt-4">
                      <label className={"lable_add_new_product"}> Total Price BCI Cotton</label>

                      <Input
                        type="number"
                        min={0}
                        placeholder="Total Price BCI Cotton"
                        name="total_bci_price"
                        onChange={(e) => {
                          setData({ ...data, total_bci_price: e.target.value });
                        }}
                        className={"lable_add_new_product"}
                      />
                    </div>
                    <div className="mt-4">
                      <label className={"lable_add_new_product"}> Total Price Organic Cotton</label>

                      <Input
                        type="number"
                        min={0}
                        placeholder="Total Price Organic Cotton"
                        name="total_organic_cotton"
                        onChange={(e) => {
                          setData({
                            ...data,
                            total_organic_cotton: e.target.value,
                          });
                        }}
                        className={"lable_add_new_product"}
                      />
                    </div>
                  </>
                )}
                <div className="mt-4">
                  <label className={"lable_add_new_product"}> Tags</label>

                  <Select
                    mode="multiple"
                    showArrow
                    placeholder="Select tags"
                    className={"lable_add_new_product"}
                    tagRender={({ label, onClose, closable }: any) => (
                      <Tag
                        color="#00468c"
                        closable={closable}
                        onClose={onClose}
                        style={{ marginRight: 3 }}
                        key={label}
                      >
                        {label}
                      </Tag>
                    )}
                    style={{ width: "100%" }}
                    onChange={(x: any, value: any) => {
                      setData({
                        ...data,
                        tags: value.map((x: any) => x.id),
                      });
                    }}
                    options={
                      tag &&
                      tag.map((tag: any) => {
                        return { ...tag, value: tag.name, key: tag.id };
                      })
                    }
                  />
                </div>
                <div className="row mt-4">
                  <div className="col-6 lable_add_new_product" >
                    3D{" "}
                    <Switch
                      onChange={(x: any, value: any) => {
                        setData({
                          ...data,
                          is_3d: x,
                        });
                      }}
                    />
                  </div>
                  <div className="col-6 lable_add_new_product">
                    Sustainable{" "}
                    <Switch
                      onChange={(x: any, value: any) => {
                        setData({
                          ...data,
                          sustainable: x,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <label className={"lable_add_new_product"}> Collections</label>
                  <Select
                    mode="multiple"
                    showArrow
                    placeholder="Select Collection"
                    className={"lable_add_new_product"}
                    tagRender={({ label, onClose, closable }: any) => (
                      <Tag
                        color="#00468c"
                        closable={closable}
                        onClose={onClose}
                        style={{ marginRight: 3 }}
                        key={label}
                      >
                        {label}
                      </Tag>
                    )}
                    style={{ width: "100%" }}
                    onChange={(x: any, value: any) => {
                      setData({
                        ...data,
                        collection_select: value.map((x: any) => x.id),
                      });
                    }}
                    options={
                      collection &&
                      collection.map((col: any) => {
                        return {
                          ...col,
                          value: col.name,
                          key: col.id,
                        };
                      })
                    }
                  />
                </div>
                <div className="mt-4">
                  <label className={"lable_add_new_product"}> For you</label>
                  <Select
                    mode="multiple"
                    showArrow
                    placeholder="Select companies"
                    className={"lable_add_new_product"}
                    tagRender={({ label, onClose, closable }: any) => (
                      <Tag
                        color="#00468c"
                        closable={closable}
                        onClose={onClose}
                        style={{ marginRight: 3 }}
                        key={label}
                      >
                        {label}
                      </Tag>
                    )}
                    style={{ width: "100%" }}
                    onChange={(x: any, value: any) => {
                      setData({
                        ...data,
                        foryou: value.map((x: any) => x.id),
                      });
                    }}
                    options={
                      company &&
                      company.map((tag: any) => {
                        return { ...tag, value: tag.name, key: tag.id };
                      })
                    }
                  />
                </div>
                <div className="mt-4">
                  <label className={"lable_add_new_product"}> Responsible choice</label>
                  <Select
                    mode="multiple"
                    showArrow
                    placeholder="Select responsible choice"
                    tagRender={({ label, onClose, closable }: any) => (
                      <Tag
                        color="#00468c"
                        closable={closable}
                        onClose={onClose}
                        style={{ marginRight: 3 }}
                        key={label}
                      >
                        {label}
                      </Tag>
                    )}
                    style={{ width: "100%" }}
                    /*   onChange={(x: any, value: any) => {
                      
                      setData({
                        ...data,
                        responsible_choice: value.id,
                      });
                    }} */
                    onChange={(x: any, value: any) => {
                      setData({
                        ...data,
                        responsible_choice: value.map((x: any) => x.id),
                      });
                    }}
                    options={
                      responsibleChoice &&
                      responsibleChoice.map((tag: any) => {
                        return { ...tag, value: tag.name, key: tag.id };
                      })
                    }
                  />
                </div>

                <div className="mt-4">
                  <label className={"lable_add_new_product"}>Front Image</label>

                  <Input
                    type="file"
                    onChange={(e) => {
                      const selectedFileFront = e.target.files
                        ? e.target.files[0]
                        : null;
                      if (selectedFileFront) {
                        setData({ ...data, front_image: selectedFileFront });
                        setPreviewFront(URL.createObjectURL(selectedFileFront));
                      }
                    }}
                    accept="image/png, image/jpeg"
                  />
                  {previewFront && (
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 preview-container">
                      <img
                        src={previewFront}
                        alt={`Preview ${previewFront}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="mt-4">
                  <label className={"lable_add_new_product"}>Back Image</label>
                  <Input
                    type="file"
                    onChange={(e) => {
                      const selectedFileBack = e.target.files
                        ? e.target.files[0]
                        : null;
                      if (selectedFileBack) {
                        setData({ ...data, back_image: selectedFileBack });
                        setPreviewBack(URL.createObjectURL(selectedFileBack));
                      }
                    }}
                    accept="image/png, image/jpeg"
                  />
                  {previewBack && (
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 preview-container">
                      <img
                        src={previewBack}
                        alt={`Preview ${previewBack}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="mt-4">
                  <label className={"lable_add_new_product"}> Images</label>

                  <Input
                    type="file"
                    multiple
                    onChange={(e) => {
                      handleFileChange(e);
                      const selectedFiles = e.target.files;

                      if (selectedFiles && selectedFiles.length > 0) {
                        const imagesArray = [];
                        for (let i = selectedFiles.length - 1; i >= 0; i--) {
                          imagesArray.push(selectedFiles[i]);
                        }

                        /*    const previewArray = [];
                        for (let i = selectedFiles.length - 1; i >= 0; i--) {
                          previewArray.push(
                            URL.createObjectURL(selectedFiles[i])
                          );
                        }
                        setPreview(previewArray); */
                      }
                    }}
                    accept="image/png, image/jpeg"
                  />
                  {preview && (
                    <div className="row ">
                      {preview.map((previewUrl: any, index: any) => (
                        <div
                          key={index}
                          className="col-12 col-md-6 col-lg-4 col-xl-3 preview-container"
                        >
                          <img
                            src={previewUrl}
                            alt={`Preview ${index}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                          {/*         <button onClick={() => handleRemovePreview(index)}>Remove Preview</button>
                           */}{" "}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mt-4">
                  <label className={"lable_add_new_product"}>Total look</label>

                  <Select
                    mode="multiple"
                    showArrow
                    placeholder="Select product"
                    className={"lable_add_new_product"}
                    tagRender={({ label, onClose, closable }: any) => (
                      <Tag
                        color="#00468c"
                        closable={closable}
                        onClose={onClose}
                        style={{ marginRight: 3 }}
                        key={label}
                      >
                        {label}
                      </Tag>
                    )}
                    style={{ width: "100%" }}
                    onChange={(x: any, value: any) => {
                      setData({
                        ...data,
                        total_look: value.map((x: any) => x.value),
                      });
                    }}
                    options={
                      product &&
                      product.map((prod: any) => {
                        return {
                          label: prod.code + " " + prod.name,
                          value: prod.id,
                          key: prod.id,
                        };
                      })
                    }
                  />
                </div>
                <div className="row mt-4">
                  <label className={"lable_add_new_product"}>Link 360°</label>

                  <Input
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e);

                      setData({ ...data, link_360: e.target.value });
                    }}
                  />
                </div>
                <div className="d-flex justify-content-start update_product_footer mt-4">
                  <div className="col-6 lable_add_new_product" >Published</div>
                  <div className="col-6">
                    <Switch
                      onChange={(x: any, value: any) => {
                        setData({
                          ...data,
                          masked: x,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end update_product_footer mt-4">
                  <button
                    className="submit_button_white submit_button_white_add lable_add_new_product py-3 px-4"
                    onClick={showDrawer}
                    style={{ padding: "20px 70px" }}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit_button_blue submit_button_white_add lable_add_new_product ml-3 py-3 px-5"
                    onClick={() => addNewProduct()}
                    disabled={isLoadingAdd}
                  >
                    {isLoadingAdd ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 24, color: "white" }}
                            spin
                          />
                        }
                      />
                    ) : (
                      "Add new product"
                    )}
                  </button>


                </div>
                <div>
                  <Drawer
                    placement="bottom"
                    closable={false}
                    onClose={onClose}
                    mask
                    className="cancel_drawer_confirm"
                    visible={visible}
                    getContainer={false}
                    height={200}
                    style={{ position: "absolute" }}
                  >
                    <div className="d-flex align-items-center mt-1 px-4">
                      <WarningOutlined className="warn-icon-cancel mr-4" />
                      <h5 className="mb-0">
                        Are you sure you want to discard these changes? Your
                        changes will be lost.
                      </h5>
                    </div>
                    <div className="d-flex w-100 mt-3 justify-content-center">
                      <button
                        className="submit_button_white cancel_drawer_btn px-0 py-3 mr-4"
                        style={{ fontSize: "1rem", fontWeight: "bold" }}
                        onClick={() => setVisible(false)}
                      >
                        NO
                      </button>
                      <button
                        className="submit_button_blue cancel_drawer_btn px-0 py-3"
                        style={{ fontWeight: "bold", fontSize: "1rem" }}
                        onClick={() => {
                          setVisible(false);
                        }}
                      >
                        YES
                      </button>
                    </div>
                  </Drawer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AddNewProduct;
