import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  handleBottomDrawer,
  handleBottomDrawerDeletedItems,
} from "../../actions/actionCreators";
import {
  clearFolderToMoveToOnDrag,
  clearSelectedFolders,
  deleteFolder,
  getAllFolders,
  getArchivedFolders,
} from "../../actions/folders-actions/actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderPlus,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import HeartOutline from "../../icons/HeartOutline";
import FolderMiniature from "../FolderMiniature/FolderMiniature";
import DeletedItems from "../DeletedItems/DeletedItems";
import { AnimatePresence } from "framer-motion";
import Attention from "../../icons/Attention";
import Trash from "../../icons/Trash";
import { selectSelection } from "../../actions/myselection-actions/actions";
import { Spinner } from "../Spinner";
import { Popconfirm, Popover, Tooltip, Tree } from "antd";
import Folder from "../../icons/Folder";
import {
  HeartFilled,
  FolderOpenFilled,
  FormatPainterFilled,
  LayoutFilled,
  LeftOutlined,
  AimOutlined,
  AppstoreOutlined, ArrowLeftOutlined, LockOutlined, DeleteOutlined
} from "@ant-design/icons";
import backArrow from "../../img/backArrow.png";
import ForwardDirectoryTree from "antd/lib/tree/DirectoryTree";
import "./CollectionFolders.scss";
import {
  getOneFolder,
  clearRightSideFolder,
  clearSelectedProducts,
  archiveFolder,
  clearOneFolder,
} from "../../actions/folders-actions/actions";
import { deselectSelection } from "../../actions/myselection-actions/actions";
import {
  getOrders,
  selectOrder,
  sendFolderAccessRequest,
} from "../../actions/order-actions/actions";
import Bag from "../../icons/Bag";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import BottomDrawer from "../BottomDrawer/BottomDrawer";
import { useQuery } from "react-query";
import axiosInstance from "../../config/axios-instance";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { SharedIcon } from "../../icons/SharedIcon";
import { faUser as faFilledUser } from "@fortawesome/free-regular-svg-icons";

export interface ICollectionFoldersProps {
  setfolder: (x: any) => void;
  subFolderId: any;
  setsubfolders: (t: any) => void;
  setParentFolderId: (t: any) => void;
  setHover: (t: boolean) => void;
  hover: boolean;
  selectScreen: string;
  setSelectScreen: (screen: string) => void;
}

export default function CollectionFolders2({
  setfolder,
  subFolderId,
  setsubfolders,
  setParentFolderId,
  setHover,
  hover,
  selectScreen,
  setSelectScreen,
}: ICollectionFoldersProps) {
  // const [selectScreen, setSelectScreen] = useState("");

  const dispatch = useDispatch();
  const drawers = useSelector((state: RootStateOrAny) => state.drawers);
  const folders = useSelector((state: RootStateOrAny) => state.folders);
  const selection = useSelector((state: RootStateOrAny) => state.selection);
  const [height, setHeight] = useState(window.innerHeight);
  // const simulateClick(e) {
  //   e.click()
  // }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramValue = urlParams.get("section");

  const { user, isAdmin } = useSelector((state: RootStateOrAny) => state.auth);
  const [mode, setMode] = useState("sss");
  const [selected, setSelected] = useState<any>(null);
  const [send, setSend] = useState(false);
  const [sharedOrders, setSharedOrders] = useState(true);

  const [elm, setElement] = useState<any>(null);
  const { orders, isLoading, newOrder } = useSelector(
    (state: any) => state.orders
  );

  useEffect(() => {
    dispatch(getAllFolders());
  }, []);
  useEffect(() => {
    if (!isAdmin) {
      dispatch(getOrders("waiting"));
    }
  }, []);
  useEffect(() => {
    if (paramValue == "myselection") {
      setSelectScreen("selections");
    } else if (paramValue == "mycustomized") {
      setSelectScreen("customization");
    }
  }, []);
  useEffect(() => {
    setHeight(window.innerHeight);
  }, [window.innerHeight]);
  /*   useEffect(() => {
    dispatch(getArchivedFolders());
    selection.directRender && dispatch(selectSelection());
  }, []); */
  useEffect(() => {
    dispatch(getArchivedFolders());
  }, [folders.folders]);
  useEffect(() => {
    // dispatch(getF)
  }, [folders.folderUpdated]);

  const getTrashStyle = (isDraggingOver: boolean) => ({
    borderColor: isDraggingOver ? "#ff0045" : "#c8d4f0",
  });

  useEffect(() => {
    var toggler = document.getElementsByClassName("caret");
    var i;

    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function (e: any) {
        e.target.parentElement
          .querySelector(".nested")
          .classList.toggle("active");
        e.target.classList.toggle("caret-down");
      });
    }
  });

  /*   const handleOrderClick = async (selectedOrder: any) => {
    console.log("Clicked Order:", selectedOrder);
     setSelected(selectedOrder);
    dispatch(selectOrder(selectedOrder));  

    let token = localStorage.getItem("token");
 
     const response = await axiosInstance({
      method: "get",
      url: `/folders/` + selectedOrder,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }); 

   
    
  }; */

  useEffect(() => {
    dispatch(selectOrder(selected));
  }, [selected]);
  const handleOrderClick = async (selectedOrder: any) => {
    let token = localStorage.getItem("token");

    const response = await axiosInstance({
      method: "get",
      url: `/folders/` + selectedOrder,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log(response);
      if (response.status === 200) {
        console.log(response);
        setSelected(response.data);
      } else {
      //  alert("you don't have permision");
      }
    }).catch((error)=>{
      if (error.response && error.response.status === 403) {
        console.log(error.response.data)
    setSelected(error.response.data);
       // alert("You do not have permission to access this resource.");
      }
    });
  };
  const handleDeleteClick = async (selectedOrder: any) => {
    let token = localStorage.getItem("token");

    const response = await axiosInstance({
      method: "delete",
      url: `/folders/` + selectedOrder,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    MyNotif("Collection deleted successfully.", "success");
    dispatch(getAllFolders());
    setSelected(null);
  };

  const fetchCustomized = async (selectedOrder: any) => {
    let token = localStorage.getItem("token");
    /*  return axiosInstance({
      method: "get",
      url: `/folders/${params.product_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }); */

    const response = await axiosInstance({
      method: "get",
      url: `/folders/` + selectedOrder.id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(() => {
      setSelected(selectedOrder);
    });
  };

  /* const { isLoading, data, refetch } = useQuery(
    "customized_product",
    () => fetchCustomized(),
    {
      keepPreviousData: true,
    }
  ); */
  return (
    <div className="my_collection2_folders">
      {selectScreen === "collections2" ? (
        <>
          <div className="my_collection2_folders_header">

          <div className="direct_order_list_header_wrapper">
            <div className="direct_order_list_header mb-3">
             {/* <h2 className="direct_order_list_title">
              <img
                  src={backArrow}
                  alt="Back Arrow"
                  width={"40px"}
                  height={"40px"}
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectScreen("")}
                />
                My Collections</h2>*/}
              <>

                <div className={
                  "faq_go_back"
                }
                     style={{ cursor: "pointer" }}
                     onClick={() => setSelectScreen("")}
                   >

                  <ArrowLeftOutlined

                  ></ArrowLeftOutlined>{" "}
                  <button
                      className={"go_back_text"}

                  >
                    Go back
                  </button>
                </div>

              </>
              <div className="direct_order_header_icons">
              {sharedOrders ? (
                  <Tooltip title="Shared Collections">
                    <button
                      onClick={() => setSharedOrders(false)}
                      className="shared_order_btn"
                    >
                      <SharedIcon />
                    </button>
                  </Tooltip>
                ) : (
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="direct_order_header_icon"
                  />
                )}
                {!sharedOrders ? (
                  <Tooltip title="My Collections">
                    <FontAwesomeIcon
                      icon={faFilledUser}
                      className="direct_order_header_icon"
                      onClick={() => setSharedOrders(true)}
                      style={{ zIndex: 11 }}
                    />
                  </Tooltip>
                ) : (
                  <FontAwesomeIcon
                    icon={faUser}
                    className="direct_order_header_icon"
                    style={{ zIndex: 11 }}
                  />
                )}
                   
              </div>
            </div>
          {/*  <Button
              innerButton="Add New Collection"
              buttonClass="submit_button_blue submit_button_blue_add_folder2"
              onClick={() => {
                // setshowdrawer(true);
                dispatch(handleBottomDrawer());
              }}
              icon={<FontAwesomeIcon icon={faFolderPlus} />}
              //reverse={false}
            />*/}

            <button
                className="submit_button_blue submit_button_blue_add_folder2"
                onClick={() => {
                  // setshowdrawer(true);
                  dispatch(handleBottomDrawer());
                }}
                //reverse={false}
            >
              <span>Add New Collection</span><FontAwesomeIcon icon={faFolderPlus} />
            </button>
          </div>
          {/*   <div className="my_collection2_header">
              <div className="d-flex align-items-center">
              <div className="direct_order_list_header mb-3">
                <img
                  src={backArrow}
                  alt="Back Arrow"
                  width={"40px"}
                  height={"40px"}
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectScreen("")}
                />
                <h2 className="collection2_header_title ml-2">
                  My collections
                </h2>
                <div className="direct_order_header_icons">
                {sharedOrders ? (
                  <Tooltip title="Shared Orders">
                    <button
                      onClick={() => setSharedOrders(false)}
                      className="shared_order_btn"
                    >
                      <SharedIcon />
                    </button>
                  </Tooltip>
                ) : (
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="direct_order_header_icon"
                  />
                )}
                {!sharedOrders ? (
                  <Tooltip title="My orders">
                    <FontAwesomeIcon
                      icon={faFilledUser}
                      className="direct_order_header_icon"
                      onClick={() => setSharedOrders(true)}
                      style={{ zIndex: 11 }}
                    />
                  </Tooltip>
                ) : (
                  <FontAwesomeIcon
                    icon={faUser}
                    className="direct_order_header_icon"
                    style={{ zIndex: 11 }}
                  />
                )}
                </div>
              </div>
              </div>
            </div>
            <Button
              innerButton="Add New Folder"
              buttonClass="submit_button_blue submit_button_blue_add_folder2"
              onClick={() => {
                // setshowdrawer(true);
                dispatch(handleBottomDrawer());
              }}
              icon={<FontAwesomeIcon icon={faFolderPlus} />}
              //reverse={false}
            /> */}
          </div>

          {sharedOrders ? (
            <>
           <div style={{ width: "100%" }} className="scrol_order">
                {folders.folders ? (
                  folders.folders
                    .filter((el: any) => el.client_id === user.id)
                    .map((el: any, i: number) => (
                      <>
                        <div
                          key={i}
                          onClick={() => handleOrderClick(el.id)}


                          className={`${
                            el.can_see === undefined || el.can_see === true
                              ? "order_miniature single_folder"
                              : "order_miniature_not_collab single_folder"
                          } mt-4 ${
                            selected && selected.id === el.id
                              ? "order_selected"
                              : undefined
                          }`}
                          style={{ position: "relative" }}>
                           <Popconfirm
                          title="Are you sure to delete this Collection?"
                          onConfirm={(e: any) => {
                            e.stopPropagation();
                            handleDeleteClick(el.id);
                          }}
                          okText="Yes"
                          cancelText="No">

                             <DeleteOutlined   className="delete_button_collection" style={{zIndex:2}}/>
{/*
                          <button className="delete_button" style={{zIndex:2}}>Delete</button>
*/}
                        </Popconfirm>

                          <div className="order_miniature_info">
                            <div className="order_miniature_name">
                              <Bag />
                              <h2>{el?.name}</h2>
                            </div>
                            <div className="row season_position">
                              {el?.season}
                            </div>
                          </div>
                          {
                            <div className="collab_list">
                              {el.collaborators && el.can_see ? (
                                <div className="d-flex">
                                  {el.collaborators
                                    .filter((el: any) => el.id !== user.id)
                                    .slice(0, 3)
                                    .map((el: any) => (
                                      <a
                                        key={el.id}
                                        className="collab_profile"
                                        style={{
                                          backgroundImage: `url(${
                                            el.logo
                                              ? process.env
                                                  .REACT_APP_STORAGE_URL +
                                                el.logo
                                              : avatar_placeholder
                                          })`,
                                        }}
                                        //   replace id with the collaborator name
                                        id={el.name}></a>
                                    ))}
                                  {el.collaborators.filter(
                                    (el: any) => el.id !== user.id
                                  ).length > 3 && (
                                    <Tooltip
                                      placement="right"
                                      title={el.collaborators
                                        .filter((el: any) => el.id !== user.id)
                                        .slice(
                                          3,
                                          el.collaborators.filter(
                                            (el: any) => el.id !== user.id
                                          ).length + 1
                                        )
                                        .map((x: any) => x.name + `, `)}>
                                      <p className="and_others_collabs mb-0 collab_profile">
                                        +
                                        {el.collaborators.filter(
                                          (el: any) => el.id !== user.id
                                        ).length - 3}
                                      </p>
                                    </Tooltip>
                                  )}
                                </div>
                              ) : (
                                // can_see === false &&
                                // !send &&
                                el.request_waiting !== undefined &&
                                (!el.request_waiting ? (
                                  <h2
                                    className="request_access_order"
                                    onClick={() => {
                                      dispatch(
                                        sendFolderAccessRequest(el.id, user.id)
                                      );

                                      dispatch(getAllFolders());
                                    }}>
                                    Request access
                                  </h2>
                                ) : (
                                  <h2 className="request_access_order request_access_order_sent">
                                    Request Sent
                                  </h2>
                                ))
                              )}
                            </div>
                          }
                        </div>
                      </>
                    ))
                ) : (
                  <Spinner />
                )}
              </div>
            </>
          ) : (
            <>
              <div style={{ width: "100%" }} className="scrol_order">
                {folders.folders ? (
                  folders.folders
                    .filter((el: any) => el.client_id !== user.id)
                    .map((el: any, i: number) => (
                      <>
                        <div
                          key={i}
                          onClick={() => handleOrderClick(el.id)}

                         /* className={`"order_miniature single_folder mt-4" ${selected && selected.id === el.id
                            ? "order_selected"
                            : undefined
                          }`}*/
                          className={`${
                            el.can_see === undefined || el.can_see === true
                              ? "order_miniature  single_folder"
                              : "order_miniature single_folder"
                          } mt-4 ${
                            selected && selected.id === el.id
                              ? "order_selected"
                              : undefined
                          }`}
                          style={{ position: "relative" }}>


                          <div className="order_miniature_info">
                            <div className="order_miniature_name">
                              <Bag />
                              <h2>{el?.name}</h2>
                            </div>
                            <div className="row season_position">
                              {el?.season}
                            </div>
                          </div>
                          {
                            <div className="collab_lista">
                              {el.collaborators && el.can_see ? (
                                <div className="d-flex">
                                  {el.collaborators
                                    .filter((el: any) => el.id !== user.id)
                                    .slice(0, 3)
                                    .map((el: any) => (
                                      <a
                                        key={el.id}
                                        className="collab_profile"
                                        style={{
                                          backgroundImage: `url(${
                                            el.logo
                                              ? process.env
                                                  .REACT_APP_STORAGE_URL +
                                                el.logo
                                              : avatar_placeholder
                                          })`,
                                        }}
                                        //   replace id with the collaborator name
                                        id={el.name}></a>
                                    ))}
                                  {el.collaborators.filter(
                                    (el: any) => el.id !== user.id
                                  ).length > 3 && (
                                    <Tooltip
                                      placement="right"
                                      title={el.collaborators
                                        .filter((el: any) => el.id !== user.id)
                                        .slice(
                                          3,
                                          el.collaborators.filter(
                                            (el: any) => el.id !== user.id
                                          ).length + 1
                                        )
                                        .map((x: any) => x.name + `, `)}>
                                      <p className="and_others_collabs mb-0 collab_profile">
                                        +
                                        {el.collaborators.filter(
                                          (el: any) => el.id !== user.id
                                        ).length - 3}
                                      </p>
                                    </Tooltip>
                                  )}
                                </div>
                              ) : (
                                // can_see === false &&
                                // !send &&
                                el.request_waiting !== undefined &&
                                (!el.request_waiting ? (

                                    <span>
                                   <LockOutlined className={"lockfld"} /></span>

                                ) : (

                                    <span className="request_access_order request_access_order_sent lockfld" > <LockOutlined /></span>

                                ))
                              )}
                            </div>
                          }
                        </div>
                      </>
                    ))
                ) : (
                  <Spinner />
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <div className="d-flex flex-column w-100 h-100">
         {/*  <SidebarItem
            icon={<FolderOpenFilled />}
            title={"My collections"}
            count={0}
            onClick={() => setSelectScreen("collections")}
            isActive={selectScreen === "collections"}
          /> */}
            <SidebarItem
            icon={<FolderOpenFilled />}
            title={"My collections"}
            count={0}
            onClick={() => setSelectScreen("collections2")}
            isActive={selectScreen === "collections2"}
          />

          <SidebarItem
            icon={<HeartFilled />}
            title={"My selections"}
            count={selection?.selectionProducts?.length}
            onClick={() => {
              dispatch(selectSelection());
              dispatch(clearSelectedFolders());
              setSelectScreen("selections");
            }}
            isActive={selectScreen === "selections"}
          />

          <SidebarItem
            icon={<LayoutFilled />}
            title={"My moodboards"}
            count={0}
            onClick={() => {
              setSelectScreen("moodboards");
              dispatch(selectSelection());
              dispatch(clearSelectedFolders());
            }}
            isActive={selectScreen === "moodboards"}
          />
        </div>
      )}
    </div>
  );
}

const SidebarItem = ({ onClick, count, title, icon, isActive }: any) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    /*     fetchDenim();
    fetchKnit(); */
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);
  return (
    <div
      className="folder_miniature folder_my_selection folder_my_selection_mobile bg-white"
      style={{
        borderStyle: "solid",
        flex: width > 768 ? 1 : "",
        position: "relative",
        overflow: "hidden",
        border: isActive ? "2px solid #00468c" : "2px solid #c8d4f0",
      }}
      onClick={onClick}>
      <div
        className="folder_miniature_header"
        style={{ justifyContent: "flex-start" }}>
        <div
            className={'mycollection_folder_icon'}
          style={{
            position: "absolute",
            right: "-30px",
            bottom: "-20px",
            fontSize: "6rem",
            opacity: isActive ? 1 : 0.2,
            color: "#182844",
          }}>
          {icon}
        </div>
        <h2
          className="folder_name myorder_collection  m-0 mt-2"
          style={{ color: isActive ? "#182844" : "black" }}>
          {title}
        </h2>
      </div>
      <p className="paragraphe folder_miniature_description">
        {count ? `Contains ${count} products` : ""}
      </p>
    </div>
  );
};
