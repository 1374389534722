import "../../sass/Home.scss";
import philo1 from "../../img/philo1.png";
import philo2 from "../../img/philo2.png";
import philo3 from "../../img/philo3.png";
interface Props {
  mediaArea: any;
  mediaSide: string;
  bannerArea: any;
  position?: string;
}

export const Features = ({
  mediaArea,
  bannerArea,
  mediaSide,
  position,
}: Props) => {
  const positioning = (): string => {
    if (position === "center") {
      return "justify-content-center order_feature";
    } else if (position === "left_space") {
      return "justify-content-end";
    } else {
      return "";
    }
  };

  return mediaSide === "left" ? (
/*    <div className="row features">
      <div className="col-12 col-md-7">{mediaArea} </div>
      <div className="col-12 col-md-5">{bannerArea}</div>
    </div>*/
      <div className={`row features ${positioning()}`}>
          <div
              className={`col-12 col-md-5 p-md-2 ${
                  positioning().includes("order_feature")
                      ? "order-2 order-md-1"
                      : "order-2 order-md-1"
              }`}
          >
              {mediaArea}

          </div>
          <div
              className={`col-12 ${
                  position === "center" || position === "left_space"
                      ? "col-md-6 "
                      : "col-md-7"
              } ${
                  positioning().includes("order_feature")
                      ? "order-1 order-md-2"
                      : "order-1 order-md-2"
              }`}
          >
              {bannerArea}
          </div>
      </div>
  ) : (
    <div className={`row features ${positioning()}`}>
      <div
        className={`col-12 col-md-5 p-md-2 ${
          positioning().includes("order_feature")
            ? "order-1 order-md-1"
            : "order-1 order-md-1"
        }`}
      >
        {bannerArea}
      </div>
      <div
        className={`col-12 ${
          position === "center" || position === "left_space"
            ? "col-md-6 pr-md-0"
            : "col-md-7"
        } ${
          positioning().includes("order_feature")
            ? "order-2 order-md-2"
            : "order-2 order-md-2"
        }`}
      >
        {position !== "left_space" ? (
          mediaArea
        ) : (
          <div className="grid_philosophy">
            <img src={philo3} alt="our philosophy" />
            <div>
              <img src={philo2} alt="our philosophy" />
              <img src={philo1} alt="our philosophy" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
