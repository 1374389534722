import React, { useState, useEffect } from "react";
import Trash from "../../icons/Trash";
import { Spinner } from "../Spinner";
import { Col, Form, Modal, Popconfirm, Row, Select, Spin } from "antd";
import moment from "moment";
import AddTeammateDrawer from "./AddTeammateDrawer";
import { useMutation, useQuery } from "react-query";
import axiosInstance from "../../config/axios-instance";
import {LoadingOutlined, CheckOutlined, EditOutlined, UserAddOutlined} from "@ant-design/icons";
import { MyNotif } from "../NotifIcons/NotifIcons";
import "./TeamManagement.scss";
import EditTeammateDrawer from "./EditTeammateDrawer";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CategoriesServices from "../../actions/left-menu-actions/services";
import { Tabs } from 'antd';

const { Option } = Select;


export default function TeamManagementAdmin() {
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [memberToEdit, setMemberToEdit] = useState(null);
  const [open, setOpen] = useState(false);

  const [client_id, setClientid] = useState<any>("");
  const [fabric_type, setFabicType] = useState<any>("");
  const [gender, setGenders] = useState<any>([]);
  const [company,setCompany]=useState<any>("")
  const [is_client, setIsClient] = useState(false);

  const history = useHistory();
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
   // sendInvitation(values);
  };

  const showModal = (id:any) => {
    setClientid(id)
    setOpen(true);
  };


  
  const getCompany = () => {
    let token = localStorage.getItem("token");
    
      return axiosInstance({
        method: "get",
        url: `/clients/me/company/${params.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response)=>
        setCompany(response.data)
      );
    
    
  };

  useEffect(()=>{
      getCompany();
    
      
  },[])

  const handleOk = () => {


    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "post",
      url: `/clients/me/company/approve/${client_id}`,
      data:{fabric_type,gender,is_client},
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response)=>{
      MyNotif("Member approved successfully.", "success");
      handleCancel();
     refetchMembers();
    });
  };


  const handleChangeFabric = (value:any)=>{
    setFabicType(value)
  }

  const handleChangeGender = (values:any)=>{
    setGenders(values) 
 }

 const { data: genders, isLoading: gendersIsLoading } = useQuery(
  "genders",
  CategoriesServices.getGenders
);
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  const [width, setWidth] = useState(window.innerWidth);
  const params = useParams<any>();

  const getCompanyAndMembers = () => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "get",
      url: `/clients/me/company/${params.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const {
    data: members,
    isLoading: loadingMembers,
    refetch: refetchMembers,
  } = useQuery("members", getCompanyAndMembers, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const resize: any = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [width]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerEdit, setOpenDrawerEdit] = useState(false);

  const removeMember = (clientId: any) => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "delete",
      url: `/clients/me/company/client/${clientId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const approvedMember = (clientId: any) => {
    let token = localStorage.getItem("token");

    return axiosInstance({
      method: "post",
      url: `/clients/me/company/approve/${clientId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const { mutateAsync: deleteMember, isLoading: deleteMemberLoading } =
    useMutation(removeMember, {
      onSuccess: () => {
        MyNotif("Member deleted.", "success");
        setMemberToDelete(null);
        refetchMembers();
      },
      onError: (err: any) => {
        MyNotif("Something went wrong!", "danger");
        setMemberToDelete(null);
      },
    });

    const { mutateAsync: approveMember, isLoading: approveMemberLoading } =
    useMutation(approvedMember, {
      onSuccess: () => {
        MyNotif("Member approved.", "success");
        refetchMembers();
      },
      onError: (err: any) => {
        MyNotif("Something went wrong!", "danger");
      },
    });

  return (
    <div style={{ background: "#f4f7ff4a", minHeight: "100vh" }}>
      <div className="container team_management_container">
        <div className="d-flex align-items-center justify-content-between pr-1 mb-4">
          <h1 className="team_management_title mb-0" style={{fontSize: '30px'}}>TEAM MANAGEMENT</h1>
          <div style={{display: "flex", gap: "10px"}}>
            <button
                className="invite_btn px-3 px-md-3 mt-3"
                onClick={() => {
                  setIsClient(false)
                  setOpenDrawer(true)
                }}>

             <span className={"add-user-text"}>
                  Invite new member</span>
              <span className="add-user-icon"><UserAddOutlined/></span>
            </button>
            <button
                className="invite_btn px-3 px-md-3 mt-3"
                onClick={() => {
                  setOpenDrawer(true)
                  setIsClient(true)
                }}>

             <span className={"add-user-text"}>
                  Add your client</span>
              <span className="add-user-icon"><UserAddOutlined/></span>
            </button>
          </div>
        </div>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Member" key="1">
            <div className="row items_titles mt-5">
              <div className="col-3 item_col_title">Name</div>
              <div className="col-2 item_col_title">Job Title</div>
              <div className="col-3 item_col_title">Email</div>
              <div className="col-2 item_col_title">Last Active</div>
              <div className="col-2 item_col_title">Actions</div>
            </div>
            {loadingMembers ? (

                <Spinner/>
            ) : (
                members?.data.clients
                    .filter((client: any) => client.is_client_clients !== 1)
                    .map((client: any) => (
                        <div
                            key={client.id}
                            className={"row item_container item_container_last my-3"}
                            style={{ borderRadius: "0.5rem" }}
                        >
                          {width > 768 ? (
                              <>
                                {" "}
                                <div className="col-3 item_element member_name ">
                                  <img
                                      src={process.env.REACT_APP_STORAGE_URL + client.logo}
                                      alt=""
                                      className="member_img"
                                  />
                                  <p className="ml-2" style={{ fontSize: "0.9rem" }}>
                                    {client.name}
                                  </p>
                                </div>
                                <div className="col-2 item_element pl-0">
                                  <p
                                      className="email_member"
                                      style={{ fontSize: "0.9rem" }}
                                  >
                                    { client.job_title}
                                  </p>
                                </div>
                                <div className="col-3 item_element pl-0">
                                  <p
                                      className="email_member"
                                      style={{ fontSize: "0.9rem" }}
                                  >
                                    {client.email}
                                  </p>
                                </div>
                                <div className="col-2 item_element member_last_active pl-0">
                                  <p style={{ fontSize: "0.9rem" }}>
                                    {client.last_login &&
                                        moment(client.last_login).format("DD/MM/YYYY H:mm")}
                                  </p>
                                </div>
                              </>
                          ) : (
                              <>
                                {" "}
                                <div className="col-8 item_element member_name ">
                                  <img
                                      src={process.env.REACT_APP_STORAGE_URL + client.logo}
                                      alt=""
                                      className="member_img"
                                  />

                                  <div className="member_infos">
                                    <p className="member_name_text">{client.name}</p>

                                    <p className="email_member">{client.email}</p>
                                    <p style={{ fontSize: "0.9rem" }}>
                                      {client.last_login &&
                                          moment(client.last_login).format("DD/MM/YYYY H:mm")}
                                    </p>
                                  </div>
                                </div>
                              </>
                          )}
                          <div
                              className={
                                width > 768
                                    ? "col-2 item_element item_actions"
                                    : "col-2 item_element item_actions"
                              }
                          >

                            {/*     {user && user.type !== "commercial"&& (
                    <button className={client.approved ==0 ? "button_delete_member"  : "button_delete_member approved_active"}  onClick={()=>approveMember(client.id)}>
                              <CheckOutlined  className={client.approved ==0 ? "not-approved"  : "approved"}/>
                            </button>)} */}
                            {user && user.type !== "commercial" && (
                                <button
                                    className={
                                      client.approved == 0
                                          ? "button_member"
                                          : "button_member approved_active"
                                    }
                                    //onClick={()=>approveCompany(el.id)}
                                    onClick={()=>showModal(client.id)}
                                >
                                  <CheckOutlined
                                      className={
                                        client.approved == 0 ? "not-approved" : "approved"
                                      }
                                  />
                                </button>
                            )}

                            {/* edit bloc */}
                            {/*        <button
           className="button_delete_member"
            onClick={() => {setOpenDrawerEdit(true)
              setMemberToEdit(client)
            }}
          >
            <EditOutlined  style={{color:"#00468c"}}/>
          </button>
                 */}
                            <>
                              <Popconfirm
                                  title="Are you sure to delete this member?"
                                  onConfirm={() => {
                                    setMemberToDelete(client.id);
                                    deleteMember(client.id);
                                  }}
                                  onCancel={() => {}}
                                  okText="Yes"
                                  cancelText="No"
                              >
                                {deleteMemberLoading && memberToDelete === client.id ? (
                                    <Spin
                                        indicator={
                                          <LoadingOutlined
                                              style={{
                                                color: "#00468c",
                                              }}
                                              spin
                                          />
                                        }
                                    />
                                ) : (
                                    <button className="button_delete_member">
                                      <Trash />
                                    </button>
                                )}
                              </Popconfirm>
                            </>

                          </div>
                        </div>
                    ))
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Client" key="2">
            <div className="row items_titles mt-5">
              <div className="col-3 item_col_title">Name</div>
              <div className="col-2 item_col_title">Job Title</div>
              <div className="col-3 item_col_title">Email</div>
              <div className="col-2 item_col_title">Last Active</div>
              <div className="col-2 item_col_title">Actions</div>
            </div>
            {loadingMembers ? (

                <Spinner />
            ) : (
                members?.data.clients
                    //  .filter((client: any) => members?.data.owner_id !== client.id)
                    .filter((client: any) =>  client.is_client_clients===1)
                    .map((client: any) => (
                        <div
                            key={client.id}
                            className={"row item_container item_container_last my-3"}
                            style={{ borderRadius: "0.5rem" }}
                        >
                          {width > 768 ? (
                              <>
                                {" "}
                                <div className="col-3 item_element member_name ">
                                  <img
                                      src={process.env.REACT_APP_STORAGE_URL + client.logo}
                                      alt=""
                                      className="member_img"
                                  />
                                  <p className="ml-2" style={{ fontSize: "0.9rem" }}>
                                    {client.name}
                                  </p>
                                </div>
                                <div className="col-2 item_element pl-0">
                                  <p
                                      className="email_member"
                                      style={{ fontSize: "0.9rem" }}
                                  >
                                    { client.job_title}
                                  </p>
                                </div>
                                <div className="col-3 item_element pl-0">
                                  <p
                                      className="email_member"
                                      style={{ fontSize: "0.9rem" }}
                                  >
                                    {client.email}
                                  </p>
                                </div>
                                <div className="col-2 item_element member_last_active pl-0">
                                  <p style={{ fontSize: "0.9rem" }}>
                                    {client.last_login &&
                                        moment(client.last_login).format("DD/MM/YYYY H:mm")}
                                  </p>
                                </div>
                              </>
                          ) : (
                              <>
                                {" "}
                                <div className="col-8 item_element member_name ">
                                  <img
                                      src={process.env.REACT_APP_STORAGE_URL + client.logo}
                                      alt=""
                                      className="member_img"
                                  />

                                  <div className="member_infos">
                                    <p className="member_name_text">{client.name}</p>

                                    <p className="email_member">{client.email}</p>
                                    <p style={{ fontSize: "0.9rem" }}>
                                      {client.last_login &&
                                          moment(client.last_login).format("DD/MM/YYYY H:mm")}
                                    </p>
                                  </div>
                                </div>
                              </>
                          )}
                          <div
                              className={
                                width > 768
                                    ? "col-2 item_element item_actions"
                                    : "col-2 item_element item_actions"
                              }
                          >

                            {/*     {user && user.type !== "commercial"&& (
                    <button className={client.approved ==0 ? "button_delete_member"  : "button_delete_member approved_active"}  onClick={()=>approveMember(client.id)}>
                              <CheckOutlined  className={client.approved ==0 ? "not-approved"  : "approved"}/>
                            </button>)} */}
                            {user && user.type !== "commercial" && (
                                <button
                                    className={
                                      client.approved == 0
                                          ? "button_member"
                                          : "button_member approved_active"
                                    }
                                    //onClick={()=>approveCompany(el.id)}
                                    onClick={()=>showModal(client.id)}
                                >
                                  <CheckOutlined
                                      className={
                                        client.approved == 0 ? "not-approved" : "approved"
                                      }
                                  />
                                </button>
                            )}

                            {/* edit bloc */}
                            {/*        <button
           className="button_delete_member"
            onClick={() => {setOpenDrawerEdit(true)
              setMemberToEdit(client)
            }}
          >
            <EditOutlined  style={{color:"#00468c"}}/>
          </button>
                 */}
                            <>
                              <Popconfirm
                                  title="Are you sure to delete this member?"
                                  onConfirm={() => {
                                    setMemberToDelete(client.id);
                                    deleteMember(client.id);
                                  }}
                                  onCancel={() => {}}
                                  okText="Yes"
                                  cancelText="No"
                              >
                                {deleteMemberLoading && memberToDelete === client.id ? (
                                    <Spin
                                        indicator={
                                          <LoadingOutlined
                                              style={{
                                                color: "#00468c",
                                              }}
                                              spin
                                          />
                                        }
                                    />
                                ) : (
                                    <button className="button_delete_member">
                                      <Trash />
                                    </button>
                                )}
                              </Popconfirm>
                            </>

                          </div>
                        </div>
                    ))
            )}
          </Tabs.TabPane>
        </Tabs>


        <AddTeammateDrawer
          visible={openDrawer}
          onClose={() => setOpenDrawer(false)}
          refetchMembers={refetchMembers}
          company={company}
          is_client={is_client}
        />

      <EditTeammateDrawer
          visible={openDrawerEdit}
          onClose={() => setOpenDrawerEdit(false)}
          client={memberToEdit}
          refetchMembers={refetchMembers}
        />


<Modal
        title="Approve Member"
        visible={open}
        onOk={handleOk}
        //confirmLoading={confirmLoading}
        onCancel={handleCancel}
        maskClosable={false}
        keyboard={false}
      >
        <div>
        <Form
        layout="vertical"
        hideRequiredMark
        style={{ padding: 24, paddingTop: 0 }}
         onFinishFailed={onFinishFailed}
        onFinish={onFinish} 
        initialValues={{ remember: true }}
        className="invite-teammate-drawer"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="fabric_type"
              label="Fabric type"
              className="h2-blue-hj my-2"
              style={{ color: "#00468c" }}
              rules={[
                { required: true, message: "Please select a fabric type" },
              ]}
            >
              <Select
                placeholder="Please select an owner"
                className="select-invite-teammate"
                onChange={(e)=>handleChangeFabric(e)}
              >
                <Option value="denim">Denim</Option>
                <Option value="knit">Knit</Option>
                <Option value="knit_denim">Both</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="genders"
              label="Gender"
              className="h2-blue-hj my-2"
              style={{ color: "#00468c" }}
              rules={[
                {
                  required: true,
                  message: "Please choose at least one gender",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please choose at least one gender"
                className="select-invite-teammate"
                loading={gendersIsLoading}
              //  onChange={(x: any, y: any) => console.log(x)}
                style={{ width: "100%" }}
                onChange={handleChangeGender}

              >
               {genders?.data.map((item: any) =>
                  item?.childs?.length > 0 ? (
                    item?.childs?.map((child: any) => (
                      <Select.Option value={child.id} key={child.id}>
                        {child.name}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  )
                )} 
              </Select>
            </Form.Item>
          </Col>
        </Row> 
</Form>
        </div>
      </Modal>
      </div>
    </div>
  );
}
